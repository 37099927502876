import React from 'react';
import classNames from 'classnames';
import Chip from '@components/Chip';
import FileIcon from '@components/FileIcon';
import TruncatedText from '@components/TruncatedText';
import styles from './fileRecord.module.scss';
import FileRecordActionLink from './FileRecordActionLink';
import { FileTag } from './types';

interface FileRecordProps {
  filename: string;
  description?: string;
  link?: string;
  disabled?: boolean;
  children?: React.ReactNode;
  tags?: FileTag[];
  classes?: {
    root?: string;
    description?: string;
  };
}

const FileRecord = ({
  filename,
  description,
  link,
  disabled = false,
  children,
  tags = [],
  classes,
}: FileRecordProps) => (
  <div className={classNames(styles.container, classes?.root)}>
    <FileIcon filename={filename} disabled={disabled} />
    <div className={styles.info}>
      <div className={styles.filenameWrapper}>
        <div className={styles.filename}>
          <TruncatedText>{filename}</TruncatedText>
        </div>
        {tags.length > 0 && (
          <div className={styles.tags}>
            {tags.map((tag) => (
              <Chip key={tag.value} color={tag.color} size="small" className={styles.tag}>
                {tag.value}
              </Chip>
            ))}
          </div>
        )}
      </div>
      {description && (
        <div className={classNames(styles.description, classes?.description)}>{description}</div>
      )}
    </div>
    {(children || link) && (
      <div className={styles.actions}>
        {link && <FileRecordActionLink label="View" link={link} />}
        {children}
      </div>
    )}
  </div>
);

export default FileRecord;
