import React from 'react';
// Libraries
import PropTypes from 'prop-types';
// components
import colors from 'dependencies/materialStyles/Colors';
import Icon from 'components/atoms/Icon';

const propTypes = {
  toggleCheckbox: PropTypes.func,
  open: PropTypes.bool,
  checkedLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.string,
  onRemove: PropTypes.func,
  showCloseIcon: PropTypes.bool,
  disabled: PropTypes.bool,
};

const defaultProps = {
  toggleCheckbox: () => {},
  open: false,
  checkedLabel: null,
  label: '',
  onRemove: undefined,
  showCloseIcon: false,
  disabled: false,
};

const DropdownHeader = props => {
  const { checkedLabel, label, toggleCheckbox, open, onRemove, showCloseIcon, disabled } = props;

  const isSelected = checkedLabel && checkedLabel !== '';
  const isCloseIconShown = showCloseIcon && isSelected;

  return (
    <div
      className={`checkboxContainer_header ${!isCloseIconShown && !disabled ? 'cp' : ''} ${
        disabled ? 'disabled' : ''
      }`}
      onClick={toggleCheckbox}
      role="presentation"
    >
      <div className={`checkboxContainer_header-${isSelected ? 'short' : 'italic'}`}>
        {isSelected ? checkedLabel : label}
      </div>
      <div className="checkboxContainer_header_icons">
        {isCloseIconShown && (
          <React.Fragment>
            <span onClick={onRemove} className="cp" role="presentation">
              <Icon name="close" color={colors.gray} size={18} className="p-r-5 display-flex-row" />
            </span>
            <span className="checkboxContainer_header-separator" />
          </React.Fragment>
        )}
        <span onClick={toggleCheckbox} role="presentation" className="cp">
          <Icon
            name={open ? 'less' : 'expandMore'}
            color={colors.gray}
            size={23}
            className="checkboxContainer_header_icons-right"
          />
        </span>
      </div>
    </div>
  );
};

DropdownHeader.propTypes = propTypes;
DropdownHeader.defaultProps = defaultProps;

export default DropdownHeader;
