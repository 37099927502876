export const MRI_IMPACT = {
  LOW: 'LOW',
  MEDIUM: 'MEDIUM',
  HIGH: 'HIGH',
};

export const MRI_LEVEL = {
  LOW: 'LOW',
  MODERATE: 'MODERATE',
  HIGH: 'HIGH',
};

export const MRI_SECTION = {
  LICENSES_AND_CERTIFICATIONS: 'LICENSES AND CERTIFICATIONS',
  LANGUAGE: 'LANGUAGE',
  EDUCATION: 'EDUCATION',
  WORK_HISTORY: 'WORK HISTORY',
  CLEARANCE: 'CLEARANCE',
  ALL_SECTIONS: 'ALL SECTIONS',
};

export const IMPACT_ORDER = {
  [MRI_IMPACT.LOW]: 1,
  [MRI_IMPACT.MEDIUM]: 2,
  [MRI_IMPACT.HIGH]: 3,
};

export const SECTION_IMPACTS = {
  [MRI_SECTION.LICENSES_AND_CERTIFICATIONS]: MRI_IMPACT.MEDIUM,
  [MRI_SECTION.LANGUAGE]: MRI_IMPACT.LOW,
  [MRI_SECTION.EDUCATION]: MRI_IMPACT.MEDIUM,
  [MRI_SECTION.WORK_HISTORY]: MRI_IMPACT.HIGH,
  [MRI_SECTION.CLEARANCE]: MRI_IMPACT.LOW,
  [MRI_SECTION.ALL_SECTIONS]: MRI_IMPACT.HIGH,
};

export const MRI_ERROR_CODE_NO_SECTIONS_FOUND = '412';
