import client from '@services/client';
import { gql } from '@services/gql';
import { DataFetchResolver } from '@localTypes/fetch';
import { SupplierRecord, SupplierFilter } from '../types';

const FETCH_SUPPLIERS = gql(/* GraphQL */ `
  query fetchSupplierRecords($limit: Int!, $skip: Int, $filter: NewSupplierFilter) {
    suppliersList(filter: $filter, limit: $limit, skip: $skip) {
      count
      items {
        id
        createdAt
        deleted
        category
        includeCSAFee
        company {
          id
          name
        }
      }
    }
  }
`);

const fetchRecords: DataFetchResolver<SupplierRecord, SupplierFilter> = async (options) => {
  const { csaFee, ...restFilter } = options.filterBy || {};
  const response = await client.query({
    query: FETCH_SUPPLIERS,
    variables: {
      limit: options.limit || 20,
      skip: options.skip || 0,
      filter: options.filterBy ? { ...restFilter, includeCSAFee: csaFee === 'ibm' } : null,
    },
    fetchPolicy: 'no-cache',
  });

  return {
    count: response?.data.suppliersList.count,
    data: response?.data.suppliersList.items.map((item) => ({
      id: item.id!,
      name: item.company?.name || '',
      createdAt: item.createdAt!,
      status: item.deleted ? 'deleted' : 'active',
      category: item.category === 'NOT_DIRECT' ? 'notDirect' : 'direct',
      includeCSAFee: item.includeCSAFee!,
    })),
  };
};

export default fetchRecords;
