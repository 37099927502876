import flatten from 'lodash/flatten';
import omit from 'lodash/omit';
// constants
import { STAFF_ROLE } from 'constants';
import { POSITION_FILTERS } from 'constants/filters';
// helpers
import { parseAllowedFilterOptions, parseSelectedFilterOptionsLabel } from 'helpers/Filter';
// data
import { FILTER_COMBINATION_MAP, FILTERS_WITHOUT_CONDITIONS } from './data/FilterCombinations';
import {
  DUE_DATE_FILTERS,
  POSITION_STATUS_FILTERS_MAP,
  SUBMISSIONS_FILTERS_MAP,
  PUBLISH_STATUS_FILTERS,
  CONTRACT_TYPE_FILTERS,
  DELIVERY_OPTION_FILTERS,
} from './data/PositionFilter';

/**
 * Get white list filter options
 * @param {string} role
 * @param {string[]} projects List user project names
 * @returns {object}
 */
export const getPositionFiltersWhiteList = (role, projects) => {
  const dueDateOptions = DUE_DATE_FILTERS.map(option => option.value);
  const publishStatusOptions = PUBLISH_STATUS_FILTERS.map(option => option.value);
  const submissionOptions = SUBMISSIONS_FILTERS_MAP[role].map(option => option.value);
  const contractTypeOptions = CONTRACT_TYPE_FILTERS.map(option => option.value);
  const deliveryTypeOptions = DELIVERY_OPTION_FILTERS.map(option => option.value);
  const statusOptions = flatten(
    POSITION_STATUS_FILTERS_MAP[role].map(option => {
      return option.items.length
        ? [...option.items.map(subOption => subOption.value), option.value]
        : option.value;
    })
  );

  const options = {
    [POSITION_FILTERS.DUE_DATE]: dueDateOptions,
    [POSITION_FILTERS.STATUS]: statusOptions,
    [POSITION_FILTERS.SUBMISSION]: submissionOptions,
    [POSITION_FILTERS.CONTRACT_TYPE]: contractTypeOptions,
    [POSITION_FILTERS.DELIVERY_OPTION]: deliveryTypeOptions,
    [POSITION_FILTERS.PROJECT]: projects,
    [POSITION_FILTERS.LOCATION]: '*',
    [POSITION_FILTERS.CLEARANCE]: '*',
  };

  if (role === STAFF_ROLE) {
    options[POSITION_FILTERS.PUBLISH_STATUS] = publishStatusOptions;
  }

  return options;
};

/**
 * Get selected filter options label
 * @param {object} filter Selected filter options
 * @param {string} role
 * @param {object[]} clearances
 * @returns {string[]}
 */
export const parseDataForCollapsedFilter = (filter, role, clearances) => {
  const filterCopy = { ...filter };
  const submissionFilters = SUBMISSIONS_FILTERS_MAP[role];
  const positionStatusesFilters = POSITION_STATUS_FILTERS_MAP[role].map(status => {
    return status.items.length > 0 ? [status, ...status.items] : status;
  });

  const options = flatten([
    ...DUE_DATE_FILTERS,
    ...positionStatusesFilters,
    ...submissionFilters,
    ...PUBLISH_STATUS_FILTERS,
    ...CONTRACT_TYPE_FILTERS,
    ...DELIVERY_OPTION_FILTERS,
    ...clearances,
  ]);

  // clearances not loaded -> don't show selected clearances
  if (clearances.length === 0) {
    delete filterCopy[POSITION_FILTERS.CLEARANCE];
  }

  return parseSelectedFilterOptionsLabel(filterCopy, options);
};

/**
 * Get list of selected options
 * @param {object} selectedFilter
 * @returns {string[]}
 */
export const getSelectedFilterOptions = selectedFilter => {
  return flatten(Object.values(omit(selectedFilter, FILTERS_WITHOUT_CONDITIONS)));
};

/**
 * Get list of filter options which are allowed to select
 * @param {object} selectedFilter
 * @param {string} role
 * @returns {string[]} Array of allowed filter options
 */
export const getAllowedFilterOptions = (selectedFilter, role) => {
  const combinationsMap = FILTER_COMBINATION_MAP[role];
  // get selected options which are relevant for enabling/disabling other options
  const selectedOptions = Object.values(omit(selectedFilter, FILTERS_WITHOUT_CONDITIONS));

  return parseAllowedFilterOptions(selectedOptions, combinationsMap);
};
