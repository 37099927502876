import React from 'react';
// libraries
import PropTypes from 'prop-types';
import shortid from 'shortid';
// components
import { ToastProvider } from 'context/ToastContext';
import ToastItem from './ToastItem';
// styles
import './toasts.scss';

const propTypes = {
  children: PropTypes.element.isRequired,
};

class Toast extends React.PureComponent {
  state = {
    toasts: [],
  };

  addToast = (type, text) => {
    this.setState(({ toasts }) => ({
      toasts: [
        ...toasts,
        {
          id: shortid.generate(),
          type,
          text,
        },
      ],
    }));
  };

  removeToast = id => {
    this.setState(({ toasts }) => ({ toasts: toasts.filter(toast => toast.id !== id) }));
  };

  render() {
    const { children } = this.props;
    const { toasts } = this.state;

    return (
      <ToastProvider
        value={{
          addToast: {
            success: msg => this.addToast('success', msg),
            error: msg => this.addToast('error', msg),
            warning: msg => this.addToast('warning', msg),
          },
        }}
      >
        {children}
        <div className="toasts">
          {toasts.map(toast => (
            <ToastItem key={toast.id} onClick={() => this.removeToast(toast.id)} {...toast} />
          ))}
        </div>
      </ToastProvider>
    );
  }
}

Toast.propTypes = propTypes;

export default Toast;
