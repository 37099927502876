import { useReactiveVar } from '@apollo/client';
// local states
import { reactiveVar, getItemCount } from 'localStates/itemsCount';

const useItemsCount = type => {
  // TODO: Add selector after selector/derived state release by Apollo
  // https://github.com/apollographql/apollo-feature-requests/issues/281
  // https://github.com/apollographql/apollo-feature-requests/issues/305
  // re-render on items count update
  useReactiveVar(reactiveVar);

  return getItemCount(type);
};

export default useItemsCount;
