import React, { useContext } from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// components
import Button from 'components/atoms/Button';
// context
import ModalContext from './ModalContext';
// styles
import styles from './modalWindow.module.scss';

const propTypes = {
  children: PropTypes.any.isRequired,
  centered: PropTypes.bool,
  disableClose: PropTypes.bool,
  className: PropTypes.string,
};

const defaultProps = {
  centered: false,
  disableClose: false,
  className: '',
};

const Header = ({ children, centered, disableClose, className }) => {
  const { loading, onClose } = useContext(ModalContext);

  return (
    <div className={className}>
      <div className={classNames(styles.header, { [styles.centered]: centered })}>
        <div>{children}</div>
        {!loading && (
          <div className={styles.closeButton}>
            <Button type="close" size={24} disabled={disableClose} onClick={onClose} />
          </div>
        )}
      </div>
    </div>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
