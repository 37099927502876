import React, { useState } from 'react';
import { MdUpload } from 'react-icons/md';
import Page from '@components/Page';
import Heading from '@components/Heading';
import SearchInput from '@components/SearchInput';
import Button from '@components/Button';
import Chip from '@components/Chip';
import Alert from '@components/Alert';
import InfiniteScroll from '@components/InfiniteScroll';
import UploadModal from './UploadModal';
import RecordsTable from './RecordsTable';
import App from '../../../App';
import useData from './useData';
import styles from './importsPage.module.scss';

const ImportsPage = () => {
  const [isOpen, setOpen] = useState(false);
  const {
    count,
    items,
    loading,
    loadingMore,
    error,
    hasMore,
    fetchMore,
    fetchRecord,
    searchByText,
  } = useData();

  return (
    <App>
      <Page>
        <div className={styles.header}>
          <Heading>Imports</Heading>
          {!loading && count > 0 && (
            <span className={styles.count}>
              <Chip size="small">{count}</Chip>
            </span>
          )}
        </div>
        <div className={styles.toolbar}>
          <SearchInput
            placeholder="Search by document name, RFQ or request title"
            onChange={searchByText}
          />
          <Button icon={MdUpload} onClick={() => setOpen(true)}>
            Upload document
          </Button>
        </div>
        {!loading && !count && (
          <Alert type="info" classes={{ root: styles.alert }}>
            No records were found
          </Alert>
        )}
        {error && (
          <Alert type="error" classes={{ root: styles.alert }}>
            Something went wrong during data fetching
          </Alert>
        )}
        {!error && (loading || count > 0) && (
          <InfiniteScroll
            hasMore={hasMore}
            itemsLength={items.length}
            onFetchMore={fetchMore}
            hasItems={items.length > 0}
          >
            <RecordsTable items={items} loading={loading && !loadingMore} />
          </InfiniteScroll>
        )}
        <UploadModal
          isOpen={isOpen}
          onClose={() => setOpen(false)}
          onDocumentUploaded={fetchRecord}
        />
      </Page>
    </App>
  );
};

export default ImportsPage;
