import i18n from 'i18next';
// services
import logger from 'services/Logger';
import notification from 'services/Notification';
// helpers
import { changePositionStatus } from 'helpers/Position';
import { changeSubmissionStatus } from 'helpers/Submission';
import { getAbsoluteUrl } from 'helpers/Link';
// constants
import { POSITION_STATUS } from 'constants/positionStatus';
import { SUBMISSION_STATUS } from 'constants/submissionStatus';

const getNewSubmissionStatus = currentStatus => {
  switch (currentStatus) {
    case SUBMISSION_STATUS.NEW:
      return SUBMISSION_STATUS.NOT_SUBMITTED;
    case SUBMISSION_STATUS.SUBMITTED:
      return SUBMISSION_STATUS.NOT_SELECTED;
    case SUBMISSION_STATUS.OFFER:
      return SUBMISSION_STATUS.ONBOARDING_CANCELLED;
    case SUBMISSION_STATUS.ONBOARDING:
      return SUBMISSION_STATUS.ONBOARDING_CANCELLED;
    case SUBMISSION_STATUS.DELIVERY:
      return SUBMISSION_STATUS.OFF_BOARDING;
    case SUBMISSION_STATUS.INTERVIEW:
      return SUBMISSION_STATUS.NOT_SELECTED;
    default:
      return SUBMISSION_STATUS.NOT_SUBMITTED;
  }
};

/**
 * @param {object} params
 * @param {object} params.position
 * @param {string} params.reason
 * @param {boolean} params.skipSupplierNotification
 * @param {string} params.userId Logged in user
 * @returns {Promise}
 */
export const cancelPosition = async ({ position, reason, skipSupplierNotification, userId }) => {
  try {
    const promises = [
      changePositionStatus({
        positionId: position.id,
        status: POSITION_STATUS.CANCELLED,
        oldStatusId: position.getStatus().id,
        note: reason,
      }),
    ];

    position.getParsedSubmissions().forEach(submission => {
      const currentStatus = submission.getStatus();

      if (
        [
          SUBMISSION_STATUS.NEW,
          SUBMISSION_STATUS.SUBMITTED,
          SUBMISSION_STATUS.OFFER,
          SUBMISSION_STATUS.ONBOARDING,
          SUBMISSION_STATUS.DELIVERY,
          SUBMISSION_STATUS.INTERVIEW,
        ].includes(currentStatus.value)
      ) {
        promises.push(
          changeSubmissionStatus({
            submissionId: submission.id,
            status: getNewSubmissionStatus(currentStatus.value),
            oldStatusId: currentStatus.id,
            note: i18n.t('cancelPositionReason'),
            isProposal: position.isFixedPrice(),
          })
        );
      }
    });

    await Promise.all(promises);

    notification.positionCancelled({
      position: position.id,
      link: getAbsoluteUrl(`/position/${position.id}`),
      skipSupplierNotification,
      createdBy: userId,
    });
  } catch (error) {
    logger.exception(error, { position: position.id, reason, skipSupplierNotification });
    throw error;
  }
};
