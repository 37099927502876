import React from 'react';
// libraries
import PropTypes from 'prop-types';
// components
import PageTitle from 'components/atoms/PageTitle';
// styles
import './simplePage.scss';

const propTypes = {
  title: PropTypes.string,
  children: PropTypes.any.isRequired,
};

const defaultProps = {
  title: '',
};

const SimplePage = ({ title, children }) => {
  return (
    <div className="simplePage">
      {title && (
        <div className="simplePage_title">
          <PageTitle>{title}</PageTitle>
        </div>
      )}
      {children}
    </div>
  );
};

SimplePage.propTypes = propTypes;
SimplePage.defaultProps = defaultProps;

export default SimplePage;
