/* eslint-disable import/order */
import React from 'react';
// libraries
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import useAuth from 'hooks/useAuth';
// components
import TextSection from 'components/atoms/TextSection';
import Link from 'components/atoms/Link';
// constants
import { SUPPLIER_ROLE } from 'constants';
import { PO_INACTIVE, PO_PENDING, PO_FINISHED } from './constants';
// helpers
import { boxFileUrl } from 'helpers/Box';
import { formatPurchaseOrder } from './helpers';
// styles
import poLogo from './po.png';
import './purchaseOrder.scss';

const propTypes = {
  purchaseOrder: PropTypes.object,
  type: PropTypes.oneOf([PO_INACTIVE, PO_PENDING, PO_FINISHED]),
  candidateName: PropTypes.string.isRequired,
  rfq: PropTypes.string.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  purchaseOrder: {},
  type: PO_INACTIVE,
  className: '',
};

const PurchaseOrderTicket = ({ className, purchaseOrder, type, candidateName, rfq }) => {
  const { t } = useTranslation();
  const { role } = useAuth();
  const po = formatPurchaseOrder(purchaseOrder, [PO_FINISHED, PO_PENDING].includes(type));

  return (
    <div
      className={`${className} purchaseOrder ${type === PO_PENDING ? 'blue' : ''} ${
        type === PO_INACTIVE ? 'isInactive' : ''
      }`}
    >
      <div className="purchaseOrder_foreground">
        <div className="display-flex-row purchaseOrder_header">
          <div style={{ paddingRight: '15px' }}>
            <img src={poLogo} className="purchaseOrder_logo" alt="Purchase Order" />
          </div>
          <div>
            <div className="purchaseOrder_header_title">{candidateName}</div>
            <div className="purchaseOrder_header_subTitle">{rfq}</div>
          </div>
        </div>
        <div className="display-flex-row">
          <div
            className="border-right"
            style={{ margin: '15px 20px 20px 20px', minWidth: '400px' }}
          >
            <div className="p-b-15">
              <TextSection title={t('purchaseOrder')}>
                {po.number || <i>{t('notProvided')}</i>}
              </TextSection>
            </div>
            <div className={role !== SUPPLIER_ROLE ? 'p-b-15' : ''}>
              <TextSection title={t('poAmendment')}>
                {po.amendment || <i>{t('notProvided')}</i>}
              </TextSection>
            </div>
            {role !== SUPPLIER_ROLE && (
              <div className="m-b-6">
                <TextSection title={t('poDocument')}>
                  {po.documentName ? (
                    <Link href={boxFileUrl(po.document)}>{po.documentName}</Link>
                  ) : (
                    <i>{t('notProvided')}</i>
                  )}
                </TextSection>
              </div>
            )}
          </div>
          <div className="" style={{ margin: '15px 20px 20px 0px' }}>
            <div className="p-b-15">
              <TextSection title={t('periodOfPerformance')}>
                {po.pop || <i>{t('notProvided')}</i>}
              </TextSection>
            </div>
            <div className={role !== SUPPLIER_ROLE ? 'p-b-15' : ''}>
              <TextSection title={t('levelOfEffort')}>
                {po.effort || <i>{t('notProvided')}</i>}
              </TextSection>
            </div>
            {role !== SUPPLIER_ROLE && (
              <div className="display-flex-row">
                <div className="border-right p-r-20">
                  <TextSection title={t('rate')}>
                    {po.rate || <i>{t('notProvided')}</i>}
                  </TextSection>
                </div>
                <div className="p-l-20">
                  <TextSection title={t('totalBudget')}>
                    {po.budget || <i>{t('notProvided')}</i>}
                  </TextSection>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

PurchaseOrderTicket.propTypes = propTypes;
PurchaseOrderTicket.defaultProps = defaultProps;

export default PurchaseOrderTicket;
