import React from 'react';
// libraries
import PropTypes from 'prop-types';
// hooks
import useAuth from 'hooks/useAuth';
// constants
import { MANAGER_ROLE, STAFF_ROLE } from 'constants';
import { SUBMITTED_BY, SUBMITTED_TO_CLIENT_BY } from './constants';
// components
import SubmittedByInfo from './SubmittedByInfo';

const Person = PropTypes.shape({
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
});

const propTypes = {
  reviewer: Person,
  submitter: Person,
  className: PropTypes.string,
};

const defaultProps = {
  reviewer: null,
  submitter: null,
  className: '',
};

const SubmittedBy = ({ reviewer, submitter, className }) => {
  const { role } = useAuth();

  if (role === MANAGER_ROLE && reviewer) {
    return (
      <div className={className}>
        <SubmittedByInfo name={reviewer.name} date={reviewer.date} type={SUBMITTED_BY} />
      </div>
    );
  }

  if (role === STAFF_ROLE) {
    return (
      <div className={className}>
        {submitter && (
          <SubmittedByInfo name={submitter.name} date={submitter.date} type={SUBMITTED_BY} />
        )}
        {reviewer && (
          <div className="m-t-20">
            <SubmittedByInfo
              name={reviewer.name}
              date={reviewer.date}
              type={SUBMITTED_TO_CLIENT_BY}
            />
          </div>
        )}
      </div>
    );
  }

  if (submitter) {
    return <SubmittedByInfo name={submitter.name} date={submitter.date} type={SUBMITTED_BY} />;
  }

  return null;
};

SubmittedBy.propTypes = propTypes;
SubmittedBy.defaultProps = defaultProps;

export default SubmittedBy;
