import React, { useState } from 'react';
// libraries
import PropTypes from 'prop-types';
import CollapseMui from '@material-ui/core/Collapse';
import classNames from 'classnames';
import { useComponentSize } from 'react-use-size';
// styles
import styles from './collapse.module.scss';

const propTypes = {
  button: PropTypes.func.isRequired,
  collapsed: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
  collapsedSize: PropTypes.number,
};

const defaultProps = {
  className: '',
  collapsed: false,
  collapsedSize: 0,
};

const Collapse = ({ button, className, collapsed, collapsedSize, children }) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);
  const { ref, height } = useComponentSize();

  const toggleSection = () => {
    setIsCollapsed(!isCollapsed);
  };

  if (!height || height < collapsedSize) {
    return (
      <div className={className} ref={ref}>
        {children}
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className={classNames(className, { [styles.blur]: isCollapsed })} ref={ref}>
        <CollapseMui in={!isCollapsed} collapsedHeight={`${collapsedSize}px`} timeout="auto">
          {children}
        </CollapseMui>
      </div>
      <div className="m-t-5">{button(isCollapsed, toggleSection)}</div>
    </React.Fragment>
  );
};

Collapse.propTypes = propTypes;
Collapse.defaultProps = defaultProps;

export default Collapse;
