import { gql } from '@apollo/client';
import getProperty from 'lodash/get';
// services
import client from 'services/Client';
import logger from 'services/Logger';

const FETCH_DATA = gql`
  query fetchCandidateToReplaceCV($id: ID) {
    candidate(id: $id) {
      id
      supplier {
        id
        company {
          id
          name
        }
      }
      reports(sort: { createdAt: DESC }, first: 1, filter: { isCandidateCv: { equals: true } }) {
        items {
          id
          cvReadabilityLevel {
            id
            level
          }
          shPersonId
          shCandidateId
          cv {
            id
            name
            boxId
            createdAt
          }
        }
      }
    }
  }
`;

/**
 * Fetch submission data for Replace modal CV
 * @param {string} candidateId
 * @returns {Promise<object>}
 */
const fetchData = async candidateId => {
  try {
    const response = await client.query({ query: FETCH_DATA, variables: { id: candidateId } });
    const candidate = getProperty(response, 'data.candidate');

    return candidate;
  } catch (error) {
    logger.exception(error, { candidateId });
    throw error;
  }
};

export default fetchData;
