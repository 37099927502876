import React from 'react';
// libraries
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// components
import Button from 'components/atoms/Button';
// helpers
import { hydrateTemplate } from 'helpers/Template';
import { formatDateText } from './helper';
// styles
import styles from './maintenanceBanner.module.scss';

const propTypes = {
  text: PropTypes.string,
  date: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

const defaultProps = {
  text: '',
};

const MaintenanceBanner = ({ text, date, onClose }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.banner}>
      <div className={styles.text}>
        {text ? (
          hydrateTemplate(text, { date: formatDateText(date) })
        ) : (
          <Trans i18nKey="maintenanceText">
            Due to <b>planned maintenance</b> the system will be unavailable
            <br />
            for approx. 10 minutes <span>{{ date: formatDateText(date) }}</span>
          </Trans>
        )}
      </div>
      <div className={styles.button}>
        <Button type="close" onClick={onClose} tooltip={t('hide')} color="white" />
      </div>
    </div>
  );
};

MaintenanceBanner.propTypes = propTypes;
MaintenanceBanner.defaultProps = defaultProps;

export default MaintenanceBanner;
