import React from 'react';
import PropTypes from 'prop-types';
// components
import NumberInput from '../NumberInput';

const propTypes = {
  id: PropTypes.string,
  icon: PropTypes.element,
  className: PropTypes.string,
  highlight: PropTypes.bool,
  disabled: PropTypes.bool,
};

const defaultProps = {
  id: '',
  className: '',
  icon: null,
  highlight: false,
  disabled: false,
};

const NumberFormInput = ({ id, icon, disabled, highlight, className, ...props }) => {
  const classes = [`dashboard-input-div dashboard-input-group ${className}`];

  if (highlight) {
    classes.push('dashboard-input-highlight');
  }
  if (disabled) {
    classes.push('dashboard-input-disabled');
  }

  return (
    <div className={classes.join(' ')}>
      <span
        className={`${
          icon ? '' : 'p-l-0'
        } blackBorder dashboard-icon-div dashboard-input-style dashboard-input-group-addon`}
      >
        {icon}
      </span>
      <NumberInput
        {...props}
        name={id}
        disabled={disabled}
        useStyle={false}
        className={`dashboard-input-style dashboard-input test_${id}`}
      />
    </div>
  );
};

NumberFormInput.propTypes = propTypes;
NumberFormInput.defaultProps = defaultProps;

export default NumberFormInput;
