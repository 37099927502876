/* eslint-disable dot-notation */
import loggerService from '@alaka/logger';
import hasProperty from 'lodash/has';
import { LOGGER_API_KEY, LOGGER_ENDPOINT } from 'constants/logger';
import { IS_DEVELOPMENT } from 'constants';

const logger = loggerService(LOGGER_API_KEY, {
  endpoint: LOGGER_ENDPOINT,
  hostname: window.location.hostname,
  app: 'Alaka dashboard',
  isDevelopment: IS_DEVELOPMENT,
  tags: ['alaka', 'dashboard', 'frontend'],
});

const exceptionFn = logger.exception;

logger.exception = (error, payload = {}) => {
  // Don't log graphql errors, because they are already logged once in Apollo client via "apollo-link-error"
  if (!hasProperty(error, 'networkError') && !hasProperty(error, 'graphQLErrors')) {
    exceptionFn(error, payload);
  }
};

export default logger;
