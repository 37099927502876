/* eslint-disable no-plusplus */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TechnicalMeasuresRow from './TechnicalMeasuresRow';
import { technicalMeasuresList } from './Texts';

const propTypes = {
  tableDescription: PropTypes.string,
};

const defaultProps = {
  tableDescription: null,
};

const TechnicalMeasuresTable = ({ tableDescription }) => {
  const { t } = useTranslation();

  const isRowEven = (descIndex, totalIndex) => {
    let count = 0;

    if (totalIndex > 0) {
      for (let i = 0; i < totalIndex; i++) {
        count += technicalMeasuresList[i].content.length;
      }
    }
    const final = count + descIndex + 1;
    return final % 2;
  };

  return (
    <div className="m-t-10 technicalMeasuresTable">
      {tableDescription && (
        <div className="technicalMeasuresTable_description">{tableDescription}</div>
      )}
      <div className="technicalMeasuresTable_header">
        <div className="technicalMeasuresTable_column_first">{t('groupName')}</div>
        <div className="technicalMeasuresTable_column_second">{t('TOMNumber')}</div>
        <div className="technicalMeasuresTable_column_third">{t('TOMDescription')}</div>
      </div>
      {technicalMeasuresList.map(({ title, increment, content }, index) => {
        return (
          <TechnicalMeasuresRow
            title={title}
            increment={increment}
            content={content}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            isRowEven={desc => isRowEven(desc, index)}
          />
        );
      })}
    </div>
  );
};

TechnicalMeasuresTable.propTypes = propTypes;
TechnicalMeasuresTable.defaultProps = defaultProps;

export default TechnicalMeasuresTable;
