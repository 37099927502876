import isEmail from 'validator/lib/isEmail';
import normalizeEmailHelper from 'validator/lib/normalizeEmail';
import uniq from 'lodash/uniq';
import every from 'lodash/every';
import isObject from 'lodash/isObject';
import cloneDeep from 'lodash/cloneDeep';
import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import getProperty from 'lodash/get';
import i18n from 'i18next';

const ERROR_REQUIRED = i18n.t('errorRequired');
const ERROR_INVALID = i18n.t('errorInvalidEmail');
const ERROR_DUPLICATED_EMAILS = i18n.t('errorDuplicatedEmails');

const initEmailOption = emailOption =>
  isObject(emailOption) ? emailOption : { value: emailOption, error: '' };

export const initOptions = emailOptions => {
  const mergedOptions = {
    subject: '',
    from: '',
    to: '',
    cc: [''],
    bcc: [''],
    replyTo: '',
    ...emailOptions,
  };

  if (mergedOptions.cc.length === 0) {
    mergedOptions.cc = [''];
  }
  if (mergedOptions.bcc.length === 0) {
    mergedOptions.bcc = [''];
  }

  const parsedOptions = Object.keys(mergedOptions).reduce((options, key) => {
    return {
      ...options,
      [key]: Array.isArray(mergedOptions[key])
        ? mergedOptions[key].map(initEmailOption)
        : initEmailOption(mergedOptions[key]),
    };
  }, {});

  if (!parsedOptions.replyTo.value) {
    parsedOptions.replyTo.value = parsedOptions.from.value;
  }

  return parsedOptions;
};

export const extractOptions = emailOptions => {
  return Object.keys(emailOptions).reduce((options, key) => {
    return {
      ...options,
      [key]: Array.isArray(emailOptions[key])
        ? uniq(emailOptions[key])
            .filter(email => email.value)
            .map(email => email.value.trim())
        : emailOptions[key].value.trim(),
    };
  }, {});
};

const isEmailValid = email => isEmail(email.trim(), { allow_display_name: true });

const normalizeEmail = email => {
  const emailMatch = /<(?<email>.+@.+\..+)>/.exec(email);
  const emailAddress = getProperty(emailMatch, 'groups.email', email);

  return normalizeEmailHelper(emailAddress);
};

const findDuplicates = list => {
  const filtered = list.filter((item, index) => list.indexOf(item) !== index);

  return [...new Set(filtered)];
};

const validateEmailsUniqueness = emailsList => {
  const emails = emailsList.filter(email => email.value).map(email => normalizeEmail(email.value));
  const duplicatedEmails = findDuplicates(emails);

  return emailsList.map(email => {
    if (duplicatedEmails.includes(normalizeEmail(email.value))) {
      return {
        ...email,
        error: ERROR_DUPLICATED_EMAILS,
      };
    }

    return email;
  });
};

const validateEmailGroupsUniqueness = emailsGroup => {
  const emailList = Object.keys(emailsGroup).map(type => {
    return emailsGroup[type].map(email => ({ ...email, type }));
  });

  const validatedEmails = validateEmailsUniqueness(flatten(emailList));

  return groupBy(validatedEmails, 'type');
};

export const validateOptions = options => {
  const validatedOptions = cloneDeep(options);

  // reset previously set errors
  validatedOptions.subject.error = '';
  validatedOptions.from.error = '';
  validatedOptions.to.error = '';
  validatedOptions.replyTo.error = '';
  validatedOptions.cc = validatedOptions.cc.map(email => ({ ...email, error: '' }));
  validatedOptions.bcc = validatedOptions.bcc.map(email => ({ ...email, error: '' }));

  const { subject, to, cc, bcc, replyTo } = validatedOptions;

  if (!subject.value) {
    validatedOptions.subject.error = ERROR_REQUIRED;
  }
  if (!to.value) {
    validatedOptions.to.error = ERROR_REQUIRED;
  }
  if (to.value && !isEmailValid(to.value)) {
    validatedOptions.to.error = ERROR_INVALID;
  }
  if (replyTo.value && !isEmailValid(replyTo.value)) {
    validatedOptions.replyTo.error = ERROR_INVALID;
  }

  validatedOptions.cc = cc.map(email => {
    if (email.value && !isEmailValid(email.value)) {
      return {
        ...email,
        error: ERROR_INVALID,
      };
    }

    return email;
  });

  validatedOptions.bcc = bcc.map(email => {
    if (email.value && !isEmailValid(email.value)) {
      return {
        ...email,
        error: ERROR_INVALID,
      };
    }

    return email;
  });

  // "to", "cc" and "bcc" addresses must be unique
  const optionsToValidate = {};
  if (to.value) {
    optionsToValidate.to = [validatedOptions.to];
  }
  if (cc.length > 0) {
    optionsToValidate.cc = validatedOptions.cc;
  }
  if (bcc.length > 0) {
    optionsToValidate.bcc = validatedOptions.bcc;
  }

  const validatedEmailsGroup = validateEmailGroupsUniqueness(optionsToValidate);
  if (validatedEmailsGroup.to) {
    validatedOptions.to = getProperty(validatedEmailsGroup, 'to.0', {});
  }
  if (validatedEmailsGroup.cc) {
    validatedOptions.cc = validatedEmailsGroup.cc;
  }
  if (validatedEmailsGroup.bcc) {
    validatedOptions.bcc = validatedEmailsGroup.bcc;
  }

  return validatedOptions;
};

export const isOptionsValid = options => {
  return (
    every(options, option => !option.error) &&
    options.cc.every(option => !option.error) &&
    options.bcc.every(option => !option.error)
  );
};
