import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import getProperty from 'lodash/get';
// hooks
import useAuth from 'hooks/useAuth';
// components
import ClickableElement from 'components/atoms/ClickableElement';
import Icon from 'components/atoms/Icon';
import Score from 'components/atoms/Score';
import TruncatedText from 'components/atoms/TruncatedText';
import Tooltip from 'components/atoms/Tooltip';
import Chip, { COLOR } from 'components/atoms/NewChip';
import TooltipIcon from 'components/molecules/TooltipIcon';
import ExcellentMatchIcon from 'components/molecules/ExcellentMatchIcon';
import SubmissionSettings from 'components/organisms/SubmissionSettings';
import SubmissionStatusFlow from 'components/molecules/SubmissionStatusFlow';
import { MatchMetricsPopper } from 'components/organisms/MatchMetrics';
// helpers
import { getCurrencyText } from 'helpers/NameMapping';
import { getRevisionsStatusInfoText } from 'helpers/Revision';
import { preventDefault } from 'helpers/HtmlDOM';
import { getRelativeTime, getTimeYear } from 'helpers/RelativeTimeFormatter';
// constants
import { POSITION_TAB } from 'constants/pageTabs';
import {
  STAFF_ROLE,
  SUPPLIER_ROLE,
  MANAGER_ROLE,
  RATES_NTE_RATE,
  RATES_SUPPLIER_RATE,
  RATES_SELL_PRICE,
} from 'constants';
// styles
import colors from 'dependencies/materialStyles/Colors';
import styles from './submissionTable.module.scss';

const propTypes = {
  submission: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const SubmissionRow = ({ className, submission, position }) => {
  const { t } = useTranslation();
  const { role } = useAuth();
  const history = useHistory();

  const { candidate } = submission;
  const { project, client } = position;
  const currency = getCurrencyText(project.currency);
  const nteRate = getProperty(position.getRate(RATES_NTE_RATE), 'value');
  const partnerRate = getProperty(submission.getRate(RATES_SUPPLIER_RATE), 'value');
  const sellRate = getProperty(submission.getRate(RATES_SELL_PRICE), 'value');
  const displayExcellentMatch = submission.isExcellentMatch() && submission.hasEnoughDataPoints();
  const startDate = getProperty(submission, 'hiringProcess.purchaseOrder.start');
  const endDate = getProperty(submission, 'hiringProcess.purchaseOrder.end');

  const handlePositionClick = () => {
    const tab = role === STAFF_ROLE ? POSITION_TAB.RATES : POSITION_TAB.DETAIL;

    history.push(`/position/${position.id}?tab=${tab}`);
  };

  const renderRevisionIcon = () => {
    const revisionStatus = getRevisionsStatusInfoText(submission.revisions, role);

    if (revisionStatus && [STAFF_ROLE, SUPPLIER_ROLE].includes(role)) {
      return (
        <TooltipIcon
          icon="revision"
          tooltip={revisionStatus}
          size={17}
          className="m-l-5 display-inline-flex"
        />
      );
    }

    return null;
  };

  return (
    <Link to={`/submission/${submission.id}`}>
      <div
        className={classNames(styles.grid, styles.row, className, {
          [styles.partner]: role === SUPPLIER_ROLE,
          [styles.matchManager]: role === STAFF_ROLE,
          [styles.projectManager]: role === MANAGER_ROLE,
        })}
      >
        {/* Score */}
        <div className={styles.score}>
          <MatchMetricsPopper
            metrics={submission.getMetrics()}
            score={submission.score}
            candidateName={candidate.name}
            positionTitle={position.title}
            project={position.project.name}
            occupations={position.getOccupationsName()}
            experienceLevel={position.experienceLevel.value}
            placement="right-start"
            offsetX={10}
          >
            <Score
              score={submission.score}
              icon={displayExcellentMatch ? <ExcellentMatchIcon bordered /> : null}
            />
          </MatchMetricsPopper>
        </div>

        {/* Name */}
        <div className={styles.columnCell}>
          <div className="bolder">
            <TruncatedText>{candidate.name}</TruncatedText>
          </div>
          <div className={styles.status}>
            <SubmissionStatusFlow submission={submission} />
            {renderRevisionIcon()}
          </div>
        </div>

        {/* MM role - Partner */}
        {role === STAFF_ROLE && (
          <div className={styles.cell}>
            <TruncatedText>{submission.partner.name}</TruncatedText>
          </div>
        )}
        {/* PM role - MM who submitted the candidate */}
        {role === MANAGER_ROLE && (
          <div className={styles.cell}>
            <Tooltip title={submission.reviewedBy.name} className="overflowHidden">
              <div>
                <TruncatedText disableTooltip>{submission.reviewedBy.company}</TruncatedText>
              </div>
            </Tooltip>
          </div>
        )}

        {/* Position + RFQ + project + client */}
        <div className={styles.columnCell}>
          <ClickableElement onClick={preventDefault(handlePositionClick)}>
            <div className={styles.cell}>
              <div className={styles.rfq}>{position.rfqLabel}</div>
              <Tooltip title={`${project.name} - ${client.name}`}>
                <div className="overflowHidden">
                  <Chip size="sm" color={COLOR.GRAY} className={styles.projectChip}>
                    <TruncatedText>{project.shortName}</TruncatedText>
                  </Chip>
                </div>
              </Tooltip>
            </div>
            <div>
              <TruncatedText>{position.title}</TruncatedText>
            </div>
          </ClickableElement>
        </div>

        {/* Location */}
        <div className={styles.cell}>
          <div className={styles.locationIcon}>
            <Icon size={15} name="location" color={COLOR.GRAY} />
          </div>
          <TruncatedText>
            {position.isOffsite() ? t('offsite') : position.location.city}
          </TruncatedText>
        </div>

        {/* Submission Rate */}
        <div className={classNames(styles.cell, styles.right)}>
          {role === MANAGER_ROLE && sellRate && (
            <React.Fragment>
              {sellRate}
              <span className={styles.currency}>{currency}</span>
            </React.Fragment>
          )}
          {role !== MANAGER_ROLE && partnerRate && (
            <React.Fragment>
              {partnerRate}
              <span className={styles.currency}>{currency}</span>
            </React.Fragment>
          )}
        </div>

        {/* MM role - NTE rate */}
        {role === STAFF_ROLE && (
          <div className={classNames(styles.cell, styles.right)}>
            {nteRate && (
              <React.Fragment>
                {nteRate}
                <span className={styles.currency}>{currency}</span>
              </React.Fragment>
            )}
          </div>
        )}

        {/* Submission date (CV submission) */}
        <div className={classNames(styles.columnCell, styles.right)}>
          <div className="bolder">{getTimeYear(submission.submissionDate)}</div>
          <div className="m-t-2">{getRelativeTime(submission.submissionDate)}</div>
        </div>

        {/* Start & end date */}
        {startDate && endDate ? (
          <div className={classNames(styles.columnCell, styles.right)}>
            <div className="bolder">{getTimeYear(startDate)}</div>
            <div className="bolder m-t-2">{getTimeYear(endDate)}</div>
          </div>
        ) : (
          <div className={classNames(styles.cell, styles.right, 'f-s-14')}>
            {t('notApplicable')}
          </div>
        )}

        <div role="presentation" className={styles.settings} onClick={preventDefault}>
          <SubmissionSettings submission={submission} position={position} color={colors.gray} />
        </div>
      </div>
    </Link>
  );
};

SubmissionRow.propTypes = propTypes;
SubmissionRow.defaultProps = defaultProps;

export default SubmissionRow;
