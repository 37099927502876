import React, { useContext, useState } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// hooks
import useAuth from 'hooks/useAuth';
// context
import ToastContext from 'context/ToastContext';
// components
import Checkbox from 'components/atoms/Checkbox';
import Textarea from 'components/atoms/Textarea';
import Modal from 'components/molecules/ModalWindow';
// helpers
import { cancelPosition } from './helpers';

const propTypes = {
  position: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const CancelPosition = ({ position, isOpen, onClose }) => {
  const { t } = useTranslation();
  const { addToast } = useContext(ToastContext);
  const { id: userId } = useAuth();
  const [notifySuppliers, setNotifySuppliers] = useState(true);
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState('');

  const handleSubmit = async () => {
    if (!reason) {
      addToast.error('missingCancelRequestReasonError');
      return;
    }

    try {
      setLoading(true);
      await cancelPosition({
        position,
        reason,
        skipSupplierNotification: !notifySuppliers,
        userId,
      });

      addToast.success(t('cancelPositionSuccess'));
      onClose();
    } catch (error) {
      addToast.error(t('errorPlaceholderText'));
      setLoading(false);
    }
  };

  const handleClose = () => {
    setReason('');
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="lg" loading={loading}>
      <Modal.Header>
        {t('cancelRequestHeader')} - {position.rfqLabel}
      </Modal.Header>
      <Modal.Body>
        <Textarea
          value={reason}
          onChange={setReason}
          placeholder={t('cancelPositionPlaceholderKey')}
          rows={8}
        />
        {position.hasBeenPublishedBefore() && (
          <div className="m-t-15">
            <Checkbox
              checked={notifySuppliers}
              onChange={setNotifySuppliers}
              label={t('positionCancelledNotifyPartners')}
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Modal.Buttons
          primaryLabel={t('submit')}
          primaryAction={handleSubmit}
          secondaryAction={handleClose}
        />
      </Modal.Footer>
    </Modal>
  );
};

CancelPosition.propTypes = propTypes;

export default CancelPosition;
