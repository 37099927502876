import React, { useEffect } from 'react';
import useToast from '@hooks/useToast';
import usePrevious from '@hooks/usePrevious';
import Button from '@components/Button';
import FileUpload, { DroppedFile } from '@components/FileUpload';
import { Dialog, DialogHeader, DialogBody, DialogFooter } from '@components/Dialog';
import useFiles from './useFiles';

interface UploadModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDocumentUploaded: (id: string) => void;
}

const UploadModal = ({ isOpen, onClose, onDocumentUploaded }: UploadModalProps) => {
  const toast = useToast();
  const previouslyOpen = usePrevious(isOpen);
  const { files, reset, upload, uploading } = useFiles({
    onDocumentUploaded,
    onDocumentUploadFailed: (document) => {
      toast.warning(`Document "${document.filename}" hasn't been uploaded`);
    },
  });

  useEffect(() => {
    if (!previouslyOpen && isOpen) {
      reset();
    }
  }, [isOpen]);

  const handleUpload = (droppedFiled: DroppedFile[]) => {
    droppedFiled.map(upload);
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      size="md"
      disableCloseOnBackdropClick
      disableCloseOnEscape={uploading}
    >
      <DialogHeader title="Upload documents" disableCloseButton={uploading} />
      <DialogBody>
        <FileUpload
          multiple
          accept={{ 'application/pdf': [] }}
          onUpload={handleUpload}
          files={files}
        />
      </DialogBody>
      <DialogFooter>
        <Button onClick={onClose} secondary disabled={uploading}>
          Close
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default UploadModal;
