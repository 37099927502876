import React from 'react';
// libraries
import PropTypes from 'prop-types';
// hooks
import useAuth from 'hooks/useAuth';
// components
import ErrorPage from 'components/pages/ErrorPage';
import PositionDetails from 'components/organisms/PositionDetails';
import PositionManagement from 'components/organisms/PositionManagement';
import PositionSubmissions, { PositionProposals } from 'components/organisms/PositionSubmissions';
import CandidatesMatch from 'components/organisms/CandidatesMatch';
// constants
import { POSITION_TAB } from 'constants/pageTabs';

const propTypes = {
  tab: PropTypes.string.isRequired,
  position: PropTypes.object.isRequired,
};

const PositionContent = ({ position, tab }) => {
  const { supplierId } = useAuth();

  if (tab === POSITION_TAB.DETAIL) {
    return <PositionDetails position={position} />;
  }
  if (tab === POSITION_TAB.RATES) {
    return <PositionManagement position={position} />;
  }
  if (tab === POSITION_TAB.CANDIDATES) {
    return position.isFixedPrice() ? (
      <PositionProposals position={position} />
    ) : (
      <PositionSubmissions position={position} />
    );
  }
  if (tab === POSITION_TAB.RANKING) {
    return <CandidatesMatch position={position} supplierId={supplierId} />;
  }

  return <ErrorPage code={404} />;
};

PositionContent.propTypes = propTypes;

export default PositionContent;
