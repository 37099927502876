import i18n from 'i18next';
// constants
import { COOKIE_TYPE } from './constants';

export const getCookieTypeLabel = type => {
  const map = {
    [COOKIE_TYPE.ESSENTIALS]: i18n.t('essentials'),
    [COOKIE_TYPE.FUNCTIONALITY]: i18n.t('functionality'),
    // [COOKIE_TYPE.ANALYTICS]: i18n.t('analytics'),
  };

  return map[type] || type;
};
