import React, { useState, useEffect, useContext } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
// hooks
import useAuth from 'hooks/useAuth';
// context
import ToastContext from 'context/ToastContext';
// services
import notification from 'services/Notification';
// components
import Button from 'components/atoms/Button';
import Link from 'components/atoms/Link';
import Modal from 'components/molecules/ModalWindow';
// constants
import { SUPPORT_EMAIL } from 'constants';
// helpers
import { getAbsoluteUrl } from 'helpers/Link';
import { hasSubmission as hasSubmissionFn, deleteCandidate } from './helpers';
// styles
import styles from './deleteCandidate.module.scss';

const propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDeleted: PropTypes.func,
};

const defaultProps = {
  onDeleted: null,
};

const DeleteCandidate = ({ id, name, isOpen, onClose, onDeleted }) => {
  const { t } = useTranslation();
  const { id: userId, supplierId } = useAuth();
  const { addToast } = useContext(ToastContext);
  const [hasSubmission, setHasSubmission] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    const fetchDataFn = async () => {
      try {
        setLoading(true);
        const hasSubmissionValue = await hasSubmissionFn(id);
        setHasSubmission(hasSubmissionValue);
      } catch {
        addToast.error(t('errorPlaceholderText'));
        onClose();
      } finally {
        setLoading(false);
      }
    };

    if (isOpen && hasSubmission === null && !loading) {
      fetchDataFn();
    }
  }, [isOpen]);

  const handleDelete = async () => {
    try {
      setDeleting(true);
      await deleteCandidate(id, hasSubmission);
      addToast.success(t('candidateDeletedSuccess'));
      setDeleting(false);

      // notify users
      notification.candidateDeleted({
        supplier: supplierId,
        candidateName: name,
        termsAndConditionLink: getAbsoluteUrl('/legal/terms'),
        createdBy: userId,
      });

      if (onDeleted) {
        onDeleted();
      } else {
        onClose();
      }
    } catch (error) {
      setDeleting(false);
      addToast.error(t('errorActionText'));
    }
  };

  return (
    <Modal
      size="md"
      isOpen={isOpen}
      onClose={onClose}
      closeOnBackdrop
      loading={loading || deleting}
      loadingText={loading ? t('fetchingDataLoader') : t('deletingRecordLoader')}
    >
      <Modal.Header>{t('deleteCandidateFromPool')}</Modal.Header>
      <Modal.Body className={styles.body}>
        <div className={styles.question}>
          <Trans i18nKey="deleteCandidateQuestion">
            Are you sure you want to delete {{ candidate: name }}&#39;s candidate record?
          </Trans>
        </div>
        <div className="m-t-12">
          {!hasSubmission ? (
            <Trans i18nKey="deleteCandidateDescription">
              This action will permanently delete the candidate&#39;s record from your Candidate
              Pool and <b>cannot be undone</b>.
            </Trans>
          ) : (
            <Trans i18nKey="deleteCandidateDescriptionWithSubmission">
              This action will permanently delete the candidate&#39;s record from your Candidate
              Pool and <b>cannot be undone.</b> Existing submissions will not be deleted.
              <div className="m-t-12">
                To remove all data pertaining to this candidate, please contact us by using the{' '}
                <i>&quot;Help&quot;</i> button, or by sending an email to{' '}
                <Link href={`mailto:${SUPPORT_EMAIL}`}>{{ email: SUPPORT_EMAIL }}</Link>
              </div>
            </Trans>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className={styles.footer}>
        <div className="m-r-12">
          <Button label={t('cancel')} onClick={onClose} disabled={deleting} secondary color="red" />
        </div>
        <Button label={t('deleteRecord')} disabled={deleting} onClick={handleDelete} color="red" />
      </Modal.Footer>
    </Modal>
  );
};

DeleteCandidate.propTypes = propTypes;
DeleteCandidate.defaultProps = defaultProps;

export default DeleteCandidate;
