import getProperty from 'lodash/get';
import moment from 'moment';
// constants
import { MAIN_ONBOARDING, MAIN_DELIVERY } from 'constants/positionStatus';

export const getLatestStartDate = (role, supplierId, position) => {
  const mainStatus = position.getMainStatus(role, supplierId);

  // when status onboarding or delivery use start date and level of effort from purchase order
  if ([MAIN_ONBOARDING, MAIN_DELIVERY].includes(mainStatus.value)) {
    const hiredCandidate = position.getSubmissions(role, supplierId).find(candidate => {
      const newDuration = getProperty(candidate, 'hiringProcess.purchaseOrder', {});
      return newDuration && newDuration.start && newDuration.effort;
    });

    if (hiredCandidate) {
      const po = getProperty(hiredCandidate, 'hiringProcess.purchaseOrder');
      return { start: po.start, hours: po.effort };
    }
  }

  return position.duration;
};

/**
 * @param {string} dueDate
 * @param {boolean}
 */
export const dueToday = dueDate => {
  return moment().isSame(moment(dueDate), 'day');
};
