// services
import logger from 'services/Logger';
import { getCandidateMRI } from 'services/Report';

/**
 * @param {string} shCandidateId
 * @returns {Promise<object>}
 */
export const fetchData = async shCandidateId => {
  try {
    return await getCandidateMRI(shCandidateId);
  } catch (error) {
    logger.exception(error);
    throw error;
  }
};
