import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// components
import Placeholder from 'components/atoms/Placeholder';

const propTypes = {
  filterBy: PropTypes.string,
};

const defaultProps = {
  filterBy: '',
};

const NoCandidatesPlaceholder = ({ filterBy }) => {
  const { t } = useTranslation();

  if (!filterBy) {
    return (
      <Placeholder size="lg" icon="candidates">
        {t('noSubmissions')}
      </Placeholder>
    );
  }

  return <Placeholder icon="candidates">{t('noSubmissionsPlaceholder')}</Placeholder>;
};

NoCandidatesPlaceholder.propTypes = propTypes;
NoCandidatesPlaceholder.defaultProps = defaultProps;

export default NoCandidatesPlaceholder;
