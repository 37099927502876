// services
import notification from 'services/Notification';
// helpers
import { changeSubmissionStatus } from 'helpers/Submission';
import { changePositionStatus } from 'helpers/Position';
import { getSubmissionLink } from 'helpers/Link';
// constants
import { SUBMISSION_STATUS } from 'constants/submissionStatus';
import { POSITION_STATUS } from 'constants/positionStatus';

/**
 * @param {object} params
 * @param {string} params.submissionId
 * @param {string} params.statusId
 * @param {string} params.statusValue
 * @param {string} params.reason
 * @param {string} params.userId Logged in user id
 * @param {boolean} params.isProposal
 * @param {object} [params.position]
 * @returns {Promise}
 */
export const rejectSubmission = async ({
  submissionId,
  statusId,
  statusValue,
  reason,
  userId,
  isProposal,
  position,
}) => {
  const promises = [
    changeSubmissionStatus({
      submissionId,
      status:
        statusValue === SUBMISSION_STATUS.OFFER
          ? SUBMISSION_STATUS.ONBOARDING_CANCELLED
          : SUBMISSION_STATUS.NOT_SUBMITTED,
      oldStatusId: statusId,
      note: reason,
      isProposal,
    }),
  ];

  if (position && position.getStatus().value === POSITION_STATUS.OFFER) {
    promises.push(
      changePositionStatus({
        positionId: position.id,
        status: POSITION_STATUS.ONBOARDING_CANCELLED,
        oldStatusId: position.getStatus().id,
      })
    );
  }

  await Promise.all(promises);

  notification.submissionRejectedByMatchManager({
    submission: submissionId,
    link: getSubmissionLink(submissionId, isProposal),
    createdBy: userId,
    isProposal,
  });
};
