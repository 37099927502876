export const POSITION_TAB = {
  DETAIL: 'TAB_POSITION_DETAILS',
  RATES: 'TAB_RATES',
  CANDIDATES: 'TAB_CANDIDATES',
  RANKING: 'TAB_RANKING',
};

export const SUBMISSION_TAB = {
  POSITION: 'POSITION_DETAILS',
  DETAIL: 'CANDIDATE_DETAILS',
  ONBOARDING: 'ONBOARDING',
};
