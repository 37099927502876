import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import getProperty from 'lodash/get';
// components
import Icon from 'components/atoms/Icon';
// helper
import colors from 'dependencies/materialStyles/Colors';
import { getFutureRelativeTime } from 'helpers/RelativeTimeFormatter';
import { getDraftStructure } from 'helpers/Draft';
import DraftSettings from './DraftSettings';

const Draft = props => {
  const { draft, t } = props;
  const { name, id, form, params, updatedAt } = draft;

  const { type, name: typeName, color, icon } = getDraftStructure(getProperty(form, 'type'));
  const project = getProperty(form, 'project.name');
  const url = `/${type}/draft/${id}${params || ''}`;

  return (
    <div className="draft_container">
      <Link to={url}>
        <div className={`draft ${color}`}>
          <div className="draft_foreground">
            <div className="draft_name">{name}</div>
            <div className="draft_type">
              <div>{t(typeName)}</div>
              {project && <div className="draft_project">({project})</div>}
            </div>
            <div className="draft_date element_addLabel">
              {t('updated')} {getFutureRelativeTime(updatedAt)}
            </div>
            <div className="draft_settings" role="presentation" onClick={e => e.preventDefault()}>
              <DraftSettings draftId={id} />
            </div>
          </div>
          <div className="draft_background">
            <Icon name={icon} color={colors.gray4} size={120} />
          </div>
        </div>
      </Link>
    </div>
  );
};

Draft.propTypes = {
  draft: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Draft);
