import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// queries
import { GET_MY_DRAFTS } from 'queries/DraftQueries';
// constants
import { LIMIT_SIZE_TABLE } from 'constants';
// context
import { PageFiltersConsumer } from 'context/PageFiltersContext';
// components
import Placeholder from 'components/atoms/Placeholder';
import { CardsLoader } from 'components/molecules/ContentLoaders';
import OffsetBasedList from 'components/molecules/OffsetBasedList';
import ErrorPage from 'components/pages/ErrorPage';
import Draft from './Draft';
// helper
import { formatFilters, formatSorts } from './Helpers';
// styles
import './drafts.scss';

const propTypes = {
  userId: PropTypes.string.isRequired,
  filteredBy: PropTypes.array,
};

const defaultProps = {
  filteredBy: null,
};

const DraftList = ({ userId, filteredBy }) => {
  const { t } = useTranslation();
  return (
    <PageFiltersConsumer>
      {({ searchText, sortedBy }) => {
        const filter = formatFilters(userId, searchText, filteredBy);
        const sort = formatSorts(sortedBy);

        return (
          <OffsetBasedList
            query={GET_MY_DRAFTS}
            variables={{ filter, sort }}
            size={LIMIT_SIZE_TABLE}
          >
            {({ data, error, loading }) => {
              if (loading) {
                return <CardsLoader />;
              }
              if (error) {
                return <ErrorPage code={500} />;
              }
              if (!data.length) {
                return (
                  <Placeholder icon="edit" size="lg">
                    {t('noDrafts')}
                  </Placeholder>
                );
              }

              return (
                <div className="draftGrid">
                  {data.map(draft => (
                    <Draft key={draft.id} draft={draft} />
                  ))}
                </div>
              );
            }}
          </OffsetBasedList>
        );
      }}
    </PageFiltersConsumer>
  );
};

DraftList.propTypes = propTypes;
DraftList.defaultProps = defaultProps;

export default DraftList;
