import getProperty from 'lodash/get';
import uniq from 'lodash/uniq';
// services
import client from 'services/Client';
import logger from 'services/Logger';
import { getTemplate } from 'services/Mailer';
// queries
import { GET_EMAIL_TEMPLATE } from 'queries/TemplateQueries';

/**
 * Replace template placeholders with provided values
 * @param {string} template
 * @param {object} data Key -> placeholder to replace, Value -> value for placeholder
 * @returns {string}
 */
export const hydrateTemplate = (template, data) => {
  return Object.entries(data).reduce((text, [placeholder, value]) => {
    return text.replace(new RegExp(`\\{\\{${placeholder}\\}\\}`, 'g'), value);
  }, template);
};

/**
 * @typedef {Object} User
 * @property {string} id
 * @property {string} name
 * @property {string} email
 * @property {string} phone
 */

/**
 * @typedef {Object} Template
 * @property {string} id sendgrid Id
 * @property {string} type
 * @property {User[]} users
 */

/**
 * @typedef {Object} EmailTemplate
 * @property {string} html
 * @property {string} subject
 * @property {object} emails
 * @property {string[]} emails.cc
 * @property {string[]} emails.bcc
 * @property {object[]} users
 * @property {string} users.name
 * @property {string} users.email
 */

/**
 * Format email template data from DB
 * @param {object} templateData email template type
 * @returns {Template}
 */
const formatTemplate = templateData => {
  return {
    id: templateData.templateId,
    type: templateData.type,
    emails: getProperty(templateData, 'userGroup.emails'),
    users: getProperty(templateData, 'userGroup.users.items', []).map(user => {
      const phone = getProperty(user, 'person.phone', '');
      const mobilePhone = getProperty(user, 'person.mobilePhone', '');

      return {
        id: user.id,
        name: getProperty(user, 'person.fullName', ''),
        email: getProperty(user, 'user.email', ''),
        phone: phone || mobilePhone,
      };
    }),
  };
};

/**
 * Get email template for project by type
 * @param {string} type email template type
 * @param {string} [project] Project id
 * @returns {Promise<Template|null>}
 */
export const getEmailTemplate = async (type, project) => {
  const response = await client.query({
    query: GET_EMAIL_TEMPLATE,
    variables: {
      type,
    },
  });

  const templates = getProperty(response, 'data.emailTemplatesList.items', []);

  if (!templates.length) {
    return null;
  }

  if (project) {
    const projectTemplate = templates.find(template =>
      getProperty(template, 'projects.items', []).some(projectItem => projectItem.id === project)
    );

    if (projectTemplate) {
      return formatTemplate(projectTemplate);
    }
  }

  const matchedTemplate = templates.find(template => template.isDefault);

  return matchedTemplate ? formatTemplate(matchedTemplate) : null;
};

// ckeditor not allows add styles for table so we need to it manually
export const formatHtmlTable = html =>
  html.replace(/<table>/g, '<table border="1" cellspacing="0" cellpadding="10">');

/**
 * Get and build template
 * @param {string} emailType template type (8base EmailTemplate->type)
 * @param {string} project Project id
 * @param {object} [data] Template data to build template
 * @returns {Promise<EmailTemplate>}
 */
export const buildEmailTemplate = async (emailType, project, data) => {
  try {
    const templateData = await getEmailTemplate(emailType, project);

    if (!templateData) {
      throw new Error('Email template is not set for project');
    } else {
      const emailTemplate = await getTemplate(templateData.id);
      const subject = data ? hydrateTemplate(emailTemplate.subject, data) : emailTemplate.subject;
      const { emails } = templateData;
      const users = templateData.users || [];
      const ccUserEmails = users.map(user => user.email);
      const ccAdditionalEmails = getProperty(emails, 'cc', []);
      const recipient = getProperty(emails, 'to', '');

      return {
        users,
        emails,
        subject,
        html: data ? hydrateTemplate(emailTemplate.html, data) : emailTemplate.html,
        options: {
          subject,
          from: getProperty(emails, 'from', ''),
          to: recipient,
          cc: uniq([...ccUserEmails, ...ccAdditionalEmails]).filter(email => email !== recipient),
          bcc: getProperty(emails, 'bcc', []).filter(email => email !== recipient),
          replyTo: getProperty(emails, 'replyTo', ''),
        },
      };
    }
  } catch (error) {
    logger.exception(error);
    throw error;
  }
};
