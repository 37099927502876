// libraries
import getProperty from 'lodash/get';
import orderBy from 'lodash/orderBy';
// helpers
import { isGrantedStatusNote } from 'helpers/Permission';
import { getStatusText } from 'helpers/NameMapping';
// constants
import { STAFF_ROLE, SUPPLIER_ROLE } from 'constants';
import { SUBMISSION_STATUS, SUBMISSION_STATUS_TYPE } from 'constants/submissionStatus';

/**
 * @param {object} status
 * @param {string} [role]
 * @returns {Status}
 */
export const formatStatus = (status, role) => {
  const value = getProperty(status, 'name', '');
  const noteAuthor = getProperty(status, 'note.author.person.fullName', '');

  return {
    id: getProperty(status, 'id', ''),
    value,
    label: getStatusText(value, role),
    start: getProperty(status, 'start', ''),
    end: getProperty(status, 'end', ''),
    note:
      !role || isGrantedStatusNote(value, role)
        ? {
            id: getProperty(status, 'note.id', ''),
            text: getProperty(status, 'note.note', ''),
          }
        : null,
    author: !role || isGrantedStatusNote(value, role) ? noteAuthor : '',
  };
};

export const getBasicStatusHistoryByRole = (statuses, role) => {
  const basicStatuses = orderBy(
    statuses.filter(status => status.type === SUBMISSION_STATUS_TYPE.BASIC),
    'start',
    'asc'
  ).map(status => formatStatus(status, role));

  if ([STAFF_ROLE, SUPPLIER_ROLE].includes(role)) {
    return basicStatuses;
  }

  // PM should not see NEW status
  return basicStatuses.filter(status => status.value !== SUBMISSION_STATUS.NEW);
};
