/* eslint-disable import/order */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import PropTypes from 'prop-types';
// icons
import Icon from 'components/atoms/Icon';
import ChipNote from './ChipNote';
// styles
import './chip.scss';
import colors from 'dependencies/materialStyles/Colors';
import { POSITION } from 'constants';
import { SUPPORTED_COLORS, COLOR_GRAY, COLOR_BLUE } from 'constants/colors';

const propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]).isRequired,
  color: PropTypes.oneOf(SUPPORTED_COLORS),
  secondColor: PropTypes.string,
  type: PropTypes.string,
  note: PropTypes.any,
  noteTitle: PropTypes.any,
  noteContentClassName: PropTypes.string,
  // 'iconSize' and 'iconColor' added for implement different style for candidate statuses
  // TODO: Remove them after all status chip will be unified
  iconSize: PropTypes.number,
  iconColor: PropTypes.string,
};

const defaultProps = {
  className: '',
  color: COLOR_GRAY,
  secondColor: COLOR_BLUE,
  type: null,
  note: null,
  noteTitle: null,
  noteContentClassName: '',
  iconSize: 18,
  iconColor: '',
};

// TODO: Consider to refactor/split this component into multiple more specific and generic components with cleaner API
const Chip = props => {
  const {
    className,
    label,
    color,
    type,
    secondColor,
    note,
    noteTitle,
    noteContentClassName,
    iconSize,
    iconColor,
  } = props;

  if (note && label) {
    return (
      <ChipNote
        note={note}
        title={noteTitle}
        label={label}
        color={color}
        iconSize={iconSize}
        iconColor={iconColor}
        noteContentClassName={noteContentClassName}
        className={`${className} cp chip chip-bg-${color} text-${
          color === 'white' ? secondColor : 'white'
        }`}
      />
    );
  }

  if (type === 'header' || !type) {
    return (
      <span
        className={`${className} chip chip-bg-${color} text-${
          color === 'white' ? secondColor : 'white'
        }`}
      >
        {label}
      </span>
    );
  }
  if (type === POSITION) {
    return (
      <span
        className={`${className} chip--small chip-bg-${color === 'white' ? 'blue' : 'white'} text-${
          color === 'white' ? 'white' : color
        }`}
      >
        <Icon
          name="assignment"
          color={color === 'white' ? 'white' : colors[color]}
          style={{ height: '12px', width: '12px' }}
        />
        <span className="chip_label">{label}</span>
      </span>
    );
  }

  return <span className={className}>{label}</span>;
};

Chip.propTypes = propTypes;
Chip.defaultProps = defaultProps;

export default Chip;
