import React, { useState, useEffect } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import getProperty from 'lodash/get';
import hasProperty from 'lodash/has';
import setProperty from 'lodash/set';
import Collapse from '@material-ui/core/Collapse';
// constants
import { REVISION_TYPES } from 'constants';
// components
import Button from 'components/atoms/Button';
import Textarea from 'components/atoms/Textarea';
import CollapsibleContent from 'components/molecules/CollapsibleContent';
import DropdownCheckbox from 'components/molecules/DropdownCheckbox';
import Field from 'components/molecules/Field';
import RateInputs from 'components/molecules/RateInputs';
import RatesSimulation from 'components/organisms/RatesSimulation/Revision';
// styles
import './revisionTypeRequest.scss';

const propTypes = {
  onChange: PropTypes.func,
  component: PropTypes.object.isRequired,
  formData: PropTypes.object,
};

const defaultProps = {
  onChange: () => {},
  formData: {},
};

const RevisionTypeRequest = ({ onChange, formData, component }) => {
  const { t } = useTranslation();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const key = getProperty(component, 'key');
  const collapsed = getProperty(formData, [key, 'collapsed'], false);

  useEffect(() => {
    if (collapsed !== isCollapsed) {
      setIsCollapsed(collapsed);
    }
  }, [collapsed]);

  const handleDropdownSelect = (componentKey, value) => {
    const selectedId = getProperty(value, 'value');
    const selectedLabel = getProperty(value, 'label');

    if (selectedId) {
      const compoundKey = `${componentKey}.type`;
      onChange(compoundKey, {
        ...formData[componentKey],
        value: {
          ...formData[componentKey].value,
          type: { value: selectedId, label: selectedLabel },
        },
      });
    }
  };

  const handleTextAreaChange = (value, componentKey) => {
    if (value || value === '') {
      const compoundKey = `${componentKey}.note`;
      onChange(compoundKey, {
        ...formData[componentKey],
        value: {
          ...formData[componentKey].value,
          note: {
            value,
          },
        },
      });
    }
  };

  const handleRateChange = (value, componentKey) => {
    if (value || value === '') {
      const compoundKey = `${componentKey}.rates`;
      onChange(compoundKey, {
        ...formData[componentKey],
        value: {
          ...formData[componentKey].value,
          rates: {
            ...formData[componentKey].value.rates,
            value,
          },
        },
      });
    }
  };

  const onApplyRates = simulatedPartnerRates => {
    const compoundKey = `${key}.rates`;
    const value = formData[key];
    const newValues = {};
    const years = getProperty(formData, [key, 'rateSimulationProps', 'years'], []);
    const currency = getProperty(formData, [key, 'rateSimulationProps', 'currency'], '');

    years.forEach((year, index) => {
      const rate = simulatedPartnerRates[index];
      const initId = `${key}_${year}`;
      const rateObject = {
        currency,
        value: rate,
        year,
      };
      setProperty(newValues, initId, rateObject);
    });

    setProperty(value, 'value.rates.value', newValues);
    onChange(compoundKey, value);
  };

  const getRateComponent = rateError => {
    const { currency, startYear, numberOfYears } = getProperty(formData, [key, 'rates']);
    const currentValue = getProperty(formData, [key, 'value', 'rates', 'value']);
    const rateSimulationProps = {
      ...getProperty(formData, [key, 'rateSimulationProps'], {}),
      onApplyRates,
    };
    const newRates = currentValue
      ? Object.values(currentValue)
          .filter(content => hasProperty(content, 'value'))
          .map(rate => rate.value)
      : [];

    return (
      <React.Fragment>
        <Field
          required
          label={t('proposeNewRates')}
          error={rateError}
          className={classNames({ redForm: rateError })}
          fieldNotFilled={rateError}
          content={
            <RateInputs
              values={currentValue}
              requiredError
              onChange={value => handleRateChange(value, key)}
              name={key}
              currency={currency}
              numberOfYears={numberOfYears}
              startYear={startYear}
              highlight
              required
            />
          }
        />
        <div className="border">
          <CollapsibleContent
            collapsed
            contentClassName="m-t-0"
            headerClassName="revisionTypeRequest_ratesSimulationHeader"
            header={
              <React.Fragment>
                <div className="f-s-15 bold">{t('ratesSimulation')}</div>
                <div className="m-t-5">{t('rateSimulationDescription')}</div>
              </React.Fragment>
            }
          >
            <RatesSimulation {...rateSimulationProps} newRates={newRates} />
          </CollapsibleContent>
        </div>
      </React.Fragment>
    );
  };

  const handleRemoveClick = componentKey => {
    const compoundKey = `${componentKey}.remove`;

    onChange(compoundKey, null);
  };

  const dropdownList = getProperty(component, 'values', []);
  const placeholder = getProperty(component, 'placeholder');
  const required = getProperty(component, 'reasonRequired', false);
  const noteValue = getProperty(formData, [key, 'value', 'note', 'value']);
  const noteError = getProperty(formData, [key, 'value', 'note', 'error'], false);
  const selectedType = getProperty(formData, [key, 'value', 'type', 'value']);
  const selectedTypeLabel = getProperty(formData, [key, 'value', 'type', 'label']);
  const selectedTypeValue = selectedType
    ? {
        value: selectedType,
        label: selectedTypeLabel || t(selectedType.toLowerCase()),
      }
    : null;

  const dropdownError = getProperty(formData, [key, 'value', 'type', 'error']);
  const rateError = getProperty(formData, [key, 'value', 'rates', 'error']);
  const hasError = dropdownError || noteError || rateError;
  const isRemoveDisabled = Object.keys(formData).length === 1;

  return (
    <div className={classNames('revisionTypeRequest', { 'revisionTypeRequest--error': hasError })}>
      {(!isRemoveDisabled || selectedType) && (
        <div className={classNames('space-between verticalCenter', { flexEnd: !isCollapsed })}>
          {isCollapsed && (
            <div className="m-r-15">
              <div className="revisionTypeRequest_title">{t(selectedTypeValue.label)}</div>
              {noteValue && <div className="revisionTypeRequest_subTitle">{noteValue}</div>}
            </div>
          )}
          <div
            className={classNames('revisionTypeRequest_buttons', {
              'revisionTypeRequest_buttons--fixed': !isCollapsed,
            })}
          >
            {!isRemoveDisabled && (
              <Button
                type="close"
                size={17}
                tooltip={t('removeRevisionType')}
                onClick={() => {
                  handleRemoveClick(key);
                }}
              />
            )}
            {selectedType && (
              <Button
                type={isCollapsed ? 'expandMore' : 'less'}
                tooltip={isCollapsed ? t('expand') : t('collapse')}
                onClick={() => setIsCollapsed(!isCollapsed)}
              />
            )}
          </div>
        </div>
      )}
      <Collapse in={!isCollapsed} timeout="auto">
        <Field
          required
          label={t('selectType')}
          error={dropdownError}
          className={classNames({ redForm: dropdownError })}
          fieldNotFilled={dropdownError}
          content={
            <DropdownCheckbox
              list={dropdownList}
              width={256}
              selected={selectedTypeValue}
              onChange={value => handleDropdownSelect(key, value)}
              label={placeholder}
              checkedLabel={selectedTypeValue && selectedTypeValue.label}
              name={key}
            />
          }
        />
        <Field
          label={t('reasonForRevision')}
          required
          error={noteError}
          className={classNames(
            { redForm: noteError },
            { 'm-b-0': selectedType !== REVISION_TYPES.RATES }
          )}
          fieldNotFilled={noteError}
          content={
            <Textarea
              id={key}
              value={noteValue}
              placeholder={t('reasonForRevisionDescription')}
              required={required}
              onChange={value => handleTextAreaChange(value, key)}
            />
          }
        />
        {selectedType === REVISION_TYPES.RATES && getRateComponent(rateError)}
      </Collapse>
    </div>
  );
};

RevisionTypeRequest.propTypes = propTypes;
RevisionTypeRequest.defaultProps = defaultProps;

export default RevisionTypeRequest;
