import React from 'react';
import PropTypes from 'prop-types';
// components
import ListBullet from './ListBullet';

const propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.any,
  index: PropTypes.number,
  preIndex: PropTypes.string,
  className: PropTypes.string,
};

const defaultProps = {
  type: null,
  title: null,
  content: null,
  index: 0,
  preIndex: '',
  className: '',
};

const ListItem = ({ type, content, title, index, preIndex, className }) => {
  return (
    <div className={`${className} listItem`}>
      {type && (
        <div className="listItem_bullet">
          <ListBullet type={type} number={index + 1} preIndex={preIndex} />
        </div>
      )}
      <div className="listItem_content">
        {title && (
          <div>
            <b>{title}</b>
          </div>
        )}
        <div>{content}</div>
      </div>
    </div>
  );
};

ListItem.propTypes = propTypes;
ListItem.defaultProps = defaultProps;

export default ListItem;
