import React from 'react';
// libraries
import PropTypes from 'prop-types';
import getProperty from 'lodash/get';
// components
import Placeholder from 'components/atoms/Placeholder';
import Loader from 'components/atoms/Loader';
import Button from 'components/atoms/Button';
import Banner, { BANNER_TYPE } from 'components/molecules/Banner';
import Modal from 'components/molecules/Modal';
import Stepper from 'components/molecules/Stepper';
import StepperButtons from './StepperButtons';
// styles
import styles from './modalStepper.module.scss';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSwitch: PropTypes.func,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      error: PropTypes.bool,
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
  header: PropTypes.any,
  footer: PropTypes.any,
  content: PropTypes.object.isRequired,
  error: PropTypes.bool,
  errorContent: PropTypes.any,
  displayErrorContent: PropTypes.bool,
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
  submitDisabled: PropTypes.bool,
  modalSize: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', 'full']),
  closeOnBackdrop: PropTypes.bool,
  activeTab: PropTypes.string,
  submitButtonLabel: PropTypes.string,
  newDesign: PropTypes.bool, // remove after all components which use modal stepper will have the new design
};

const defaultProps = {
  onSwitch: () => true,
  header: null,
  footer: null,
  error: false,
  errorContent: null,
  displayErrorContent: false,
  loading: false,
  loadingText: '',
  submitDisabled: false,
  modalSize: 'lg',
  closeOnBackdrop: false,
  activeTab: '',
  submitButtonLabel: '',
  newDesign: false,
};

/**
 * NOTE: This component currently supports 2 designs. To use the new design, use "newDesign" flag.
 * TODO: Unify design for all components which use this component. Then we can drop the support for
 * the old design
 */
const ModalStepper = ({
  isOpen,
  onClose,
  title,
  header,
  footer,
  tabs,
  content,
  onSubmit,
  onSwitch,
  error,
  errorContent,
  displayErrorContent,
  loading,
  loadingText,
  submitDisabled,
  modalSize,
  closeOnBackdrop,
  activeTab,
  submitButtonLabel,
  newDesign,
}) => {
  const step = tabs.findIndex(tab => tab.id === activeTab) + 1 || 1;
  const activeTabId = getProperty(tabs, [step - 1, 'id'], '');

  const changeStep = stepValue => {
    const tab = getProperty(tabs, [stepValue - 1, 'id'], '');
    onSwitch(tab, activeTabId);
  };

  const setTab = tabId => {
    const index = tabs.findIndex(tab => tab.id === tabId);
    changeStep(index + 1);
  };

  const onContinue = () => {
    if (step < tabs.length) {
      changeStep(step + 1);
    }
  };

  const onBack = () => {
    if (step > 1) {
      changeStep(step - 1);
    }
  };

  const renderHeader = () => {
    if (!newDesign) {
      return <Modal.Header>{title}</Modal.Header>;
    }

    return (
      <React.Fragment>
        <div className={styles.header}>
          <div className={styles.title}>{title}</div>
          {!loading && <Button type="close" size={24} onClick={onClose} />}
        </div>
        {header && !loading && !displayErrorContent && <div className="m-t-20">{header}</div>}
      </React.Fragment>
    );
  };

  const renderBody = () => {
    const body = (
      <React.Fragment>
        {loading ? (
          <div className="space-30">
            <Loader />
            {loadingText && <Placeholder className="m-t-20">{loadingText}</Placeholder>}
          </div>
        ) : (
          <React.Fragment>
            <Stepper tabs={tabs} activeTab={activeTabId} onSwitch={setTab} error={error}>
              {content[activeTabId]}
            </Stepper>
            <div className={styles.footer}>
              {footer && <div className="m-r-12">{footer}</div>}
              <StepperButtons
                step={step}
                stepAmount={tabs.length}
                onContinue={onContinue}
                onBack={onBack}
                onSubmit={onSubmit}
                submitDisabled={submitDisabled}
                submitLabel={submitButtonLabel}
              />
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );

    if (!newDesign) {
      return (
        <Modal.Body>
          <div className={styles.oldBody}>{body}</div>
        </Modal.Body>
      );
    }

    return (
      <div className={styles.body}>
        {displayErrorContent ? <Banner type={BANNER_TYPE.ERROR}>{errorContent}</Banner> : body}
      </div>
    );
  };

  const renderContent = () => {
    if (newDesign) {
      return (
        <div className={styles.container}>
          {renderHeader()}
          {renderBody()}
        </div>
      );
    }

    return (
      <React.Fragment>
        {renderHeader()}
        {renderBody()}
      </React.Fragment>
    );
  };

  return (
    <Modal isOpen={isOpen} size={modalSize} onClose={onClose} closeOnBackdrop={closeOnBackdrop}>
      {renderContent()}
    </Modal>
  );
};

ModalStepper.propTypes = propTypes;
ModalStepper.defaultProps = defaultProps;

export default ModalStepper;
