import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// components
import Radio from 'components/atoms/Radio';

const propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
      description: PropTypes.string,
      disabled: PropTypes.bool,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.any,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

const defaultProps = {
  disabled: false,
  selected: '',
  className: '',
};

const RadioList = ({ options, selected, onChange, disabled, className }) => {
  return (
    <div className={className}>
      {options.map((option, index) => (
        <div key={option.value} className={classNames({ 'm-t-12': index })}>
          <Radio
            label={option.label}
            description={option.description}
            onCheck={() => onChange(option.value)}
            checked={selected === option.value}
            disabled={disabled || option.disabled}
          />
        </div>
      ))}
    </div>
  );
};

RadioList.propTypes = propTypes;
RadioList.defaultProps = defaultProps;

export default RadioList;
