import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Menu from '@material-ui/core/Menu';
import differenceWith from 'lodash/differenceWith';
// components
import Button from 'components/atoms/Button';
import MenuItem from 'components/atoms/MenuItem';
import ActionButton from './ActionButton';

const propTypes = {
  iconColor: PropTypes.string,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string,
      icon: PropTypes.string.isRequired,
      stayOpenAfterClick: PropTypes.bool,
      onClick: PropTypes.func.isRequired,
      loading: PropTypes.bool,
    })
  ),
  t: PropTypes.func.isRequired,
  actionButton: PropTypes.bool,
  anchorOrigin: PropTypes.object,
  transformOrigin: PropTypes.object,
  onOpen: PropTypes.func,
};

const defaultProps = {
  iconColor: 'white',
  menuItems: [],
  actionButton: false,
  anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
  transformOrigin: { vertical: 'top', horizontal: 'right' },
  onOpen: () => {},
};

class IconDropdown extends React.PureComponent {
  state = {
    isMenuOpen: false,
    anchorEl: null,
  };

  componentDidUpdate(prevProps, prevState) {
    const { isMenuOpen } = this.state;
    const { menuItems } = this.props;

    // recalculate menu position when item text or loader has changed
    if (prevState.isMenuOpen && isMenuOpen && this.popoverActions) {
      if (menuItems.length !== prevProps.menuItems.length) {
        this.popoverActions.updatePosition();
        return;
      }

      const hasChanged =
        differenceWith(menuItems, prevProps.menuItems, (item, oldItem) => {
          return item.id === oldItem.id && item.loading === oldItem.loading;
        }).length > 0;

      if (hasChanged) {
        this.popoverActions.updatePosition();
      }
    }
  }

  openMenu = event => {
    const { onOpen } = this.props;

    // This prevents ghost click.
    event.preventDefault();
    onOpen();

    this.setState({
      isMenuOpen: true,
      anchorEl: event.currentTarget,
    });
  };

  closeMenu = event => {
    if (event) {
      event.preventDefault();
    }

    this.setState({
      isMenuOpen: false,
      anchorEl: null,
    });
  };

  onMenuClick = (e, { stayOpenAfterClick, onClick, loading }) => {
    if (e) e.preventDefault();

    if (!loading && !stayOpenAfterClick) {
      this.closeMenu(e);
    }
    if (!loading) {
      onClick(e);
    }
  };

  render() {
    const { iconColor, menuItems, actionButton, anchorOrigin, transformOrigin } = this.props;
    const { isMenuOpen, anchorEl } = this.state;
    const { t } = this.props;

    if (!menuItems.length) {
      return null;
    }

    return (
      <React.Fragment>
        {actionButton ? (
          <ActionButton onClick={this.openMenu} />
        ) : (
          <Button
            type="moreVert"
            tooltip={t('open')}
            tooltipPosition="bottom"
            onClick={this.openMenu}
            color={iconColor}
            iconStyle="icon20"
          />
        )}
        <Menu
          action={actions => {
            this.popoverActions = actions;
          }}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          getContentAnchorEl={null}
          open={isMenuOpen}
          anchorEl={anchorEl}
          onClose={this.closeMenu}
          disableAutoFocusItem
        >
          {menuItems.map(item => {
            return (
              <MenuItem
                iconName={item.icon}
                primaryText={item.label || t(item.id)}
                loading={item.loading}
                includeLoader={!!item.stayOpenAfterClick}
                onClick={e => this.onMenuClick(e, item)}
                key={item.id}
              />
            );
          })}
        </Menu>
      </React.Fragment>
    );
  }
}

IconDropdown.propTypes = propTypes;
IconDropdown.defaultProps = defaultProps;

export default withTranslation('translation', { withRef: true })(IconDropdown);
