import { gql } from '@apollo/client';
import getProperty from 'lodash/get';
// services
import client from 'services/Client';
import logger from 'services/Logger';

const GET_MATCH_REPORT = gql`
  query fetchMatchReport($id: ID!) {
    matchReport(id: $id) {
      id
      score
      positionReport {
        id
        shPositionId
        position {
          id
          rfq {
            id
            name
          }
          title
          project {
            id
            name
            shProjectId
            client {
              id
              name
              config
            }
          }
          report {
            id
          }
        }
      }
      candidateReport {
        id
        shCandidateId
      }
      submission {
        id
        profile {
          id
          fullName
          email
          phone
        }
      }
    }
  }
`;

/**
 * @param {string} id
 * @returns {Promise<object>}
 */
const fetchMatchReport = async id => {
  try {
    const response = await client.query({ query: GET_MATCH_REPORT, variables: { id } });
    const report = getProperty(response, 'data.matchReport');
    const position = getProperty(report, 'positionReport.position');
    const profile = getProperty(report, 'submission.profile');

    return {
      score: getProperty(report, 'score'),
      shPositionId: getProperty(report, 'positionReport.shPositionId'),
      shCandidateId: getProperty(report, 'candidateReport.shCandidateId'),
      candidate: {
        name: getProperty(profile, 'fullName', ''),
        email: getProperty(profile, 'email', ''),
        phone: getProperty(profile, 'phone', ''),
      },
      position: {
        id: getProperty(position, 'id', ''),
        rfq: getProperty(position, 'rfq.name', ''),
        title: getProperty(position, 'title', ''),
        client: getProperty(position, 'project.client.name', ''),
        config: getProperty(position, 'project.client.config'),
        reportId: getProperty(position, 'report.id', ''),
        project: {
          id: getProperty(position, 'project.id', ''),
          name: getProperty(position, 'project.name', ''),
          shProjectId: getProperty(position, 'project.shProjectId'),
        },
      },
    };
  } catch (error) {
    logger.exception(error, { id });
    throw error;
  }
};

export default fetchMatchReport;
