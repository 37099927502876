import React, { useState, useContext } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import getProperty from 'lodash/get';
// helpers
import { openLink } from 'helpers/Link';
import { boxFileUrl } from 'helpers/Box';
import { getTimeYear } from 'helpers/RelativeTimeFormatter';
// contexts
import ToastContext from 'context/ToastContext';
// hooks
import useAuth from 'hooks/useAuth';
// components
import Banner, { BANNER_TYPE } from 'components/molecules/Banner';
import Icon from 'components/atoms/Icon';
import Button from 'components/atoms/Button';
import LinkButton from 'components/atoms/LinkButton';
import { ReplaceCV, commitNewCv } from 'components/organisms/CandidateSettings';
// styles
import colors from 'dependencies/materialStyles/Colors';
import styles from './candidatePage.module.scss';

const propTypes = {
  candidate: PropTypes.object.isRequired,
};

const LatestCVBanner = ({ candidate }) => {
  const { t } = useTranslation();
  const { id: userId } = useAuth();
  const { addToast } = useContext(ToastContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const handleClick = () => {
    const id = candidate.getCv().boxId;
    openLink(boxFileUrl(id));
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleReplaceCv = async newCvData => {
    try {
      setLoading(true);

      await commitNewCv({
        candidateId: getProperty(candidate, 'id', ''),
        shCandidateId: getProperty(newCvData, 'shCandidateId', ''),
        shPersonId: getProperty(newCvData, 'shPersonId', ''),
        boxId: getProperty(newCvData, 'boxId', ''),
        filename: getProperty(newCvData, 'file.name', ''),
        mriScore: getProperty(newCvData, 'mri.score', 0),
        userId,
      });

      addToast.success(t('replaceCandidateCVSuccess'));
    } catch (error) {
      addToast.error(t('errorPlaceholderText'));
    } finally {
      setModalOpen(false);
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <p className={styles.bannerTitle}>{t('mostRecentCv')}</p>
      <Banner
        type={BANNER_TYPE.ANNOUNCEMENT}
        className={styles.banner}
        customIcon={<Icon name="fileText" icon="fileText" color={colors.blue} size={24} />}
      >
        <div className="flex_space">
          <div className="m-r-15">
            <LinkButton onClick={handleClick} className={styles.bannerReportLink}>
              {candidate.getCv().name}
            </LinkButton>
            <p className={styles.bannerSubtitle}>
              {t('lastUpdated', { date: getTimeYear(candidate.getCv().updatedAt) })}
            </p>
          </div>
          <Button secondary label={t('replaceCV')} onClick={() => setModalOpen(true)} />
          <ReplaceCV
            isOpen={modalOpen}
            submitting={isLoading}
            candidateId={candidate.id}
            candidateName={candidate.name}
            onClose={closeModal}
            onReplace={handleReplaceCv}
          />
        </div>
      </Banner>
    </React.Fragment>
  );
};

LatestCVBanner.propTypes = propTypes;

export default LatestCVBanner;
