export const SIGN_UP_FORM = [
  { id: 'firstName', icon: 'person', title: 'firstName', type: 'text', required: true },
  { id: 'lastName', icon: 'person', title: 'lastName', type: 'text', required: true },
  { id: 'phoneNumber', icon: 'phone', title: 'phoneNumber', type: 'tel' },
  { id: 'email', icon: 'email', title: 'email', type: 'email', required: true },
  {
    id: 'password',
    icon: 'lock',
    title: 'password',
    type: 'password',
    required: true,
  },
  {
    id: 'confirmedPassword',
    icon: 'lock',
    title: 'confirmedPassword',
    type: 'password',
    required: true,
  },
];
