import React, { forwardRef } from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FlipMove from 'react-flip-move';
// styles
import styles from './table.module.scss';

const propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  forwardedRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
};

const defaultProps = {
  className: '',
  forwardedRef: null,
};

const Body = ({ children, className, forwardedRef, ...props }) => {
  return (
    <div ref={forwardedRef} className={classNames(styles.body, className)} {...props}>
      <FlipMove
        maintainContainerHeight
        enterAnimation="fade"
        leaveAnimation={false}
        typeName={null}
      >
        {children}
      </FlipMove>
    </div>
  );
};

Body.propTypes = propTypes;
Body.defaultProps = defaultProps;

// eslint-disable-next-line react/prop-types
export default forwardRef(({ children, ...props }, ref) => (
  <Body forwardedRef={ref} {...props}>
    {children}
  </Body>
));
