// libraries
import moment from 'moment';
import getProperty from 'lodash/get';
import i18n from 'i18next';
import { getTimeYear, getCurrentYear } from 'helpers/RelativeTimeFormatter';
import { formatCurrencyRate } from 'helpers/StringParser';
// constants
import { RATES_SELL_PRICE } from 'constants';
import { DATE_APPROVED } from '../constants';

const formatRate = (purchaseOrder, field) => {
  return formatCurrencyRate(
    getProperty(purchaseOrder, `${field}.value`),
    getProperty(purchaseOrder, `${field}.currency`)
  );
};

const getPoP = purchaseOrder => {
  const start = getProperty(purchaseOrder, 'start');
  const end = getProperty(purchaseOrder, 'end');
  return `${getTimeYear(start)}${start ? ` - ${getTimeYear(end)}` : ''}`;
};

export const formatPurchaseOrder = (purchaseOrder, isActive) => {
  if (isActive && purchaseOrder) {
    const number = getProperty(purchaseOrder, 'number');
    const amendment = getProperty(purchaseOrder, 'amendment');
    const document = getProperty(purchaseOrder, 'file.boxId');
    const documentName = getProperty(purchaseOrder, 'file.name');
    const pop = getPoP(purchaseOrder);
    const effort = `${getProperty(purchaseOrder, 'effort')} ${i18n.t('hours')}`;
    const rate = formatRate(purchaseOrder, 'rate');
    const budget = formatRate(purchaseOrder, 'totalBudget');

    return {
      number,
      amendment,
      document,
      documentName,
      pop,
      effort,
      rate,
      budget,
    };
  }

  return {};
};

export const getDefaultData = (submission, position) => {
  const { duration } = position;
  const effort = duration.hours;
  const sellRate = submission.getRate(RATES_SELL_PRICE, getCurrentYear());
  const totalBudget = sellRate.value * effort;
  const start = getProperty(submission, 'hiringProcess.dates.items', []).find(
    date => date.status === DATE_APPROVED
  );

  return {
    start: { value: getProperty(start, 'date', moment()) },
    end: { value: duration.end },
    effort: { value: duration.hours },
    rate: { value: sellRate.value },
    totalBudget: { value: parseFloat(totalBudget.toFixed(2)) },
  };
};
