import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// components
import Note from 'components/molecules/Note';

const propTypes = {
  notes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      author: PropTypes.string,
    })
  ),
};

const defaultProps = {
  notes: [],
};

const PositionNotes = ({ notes }) => {
  return notes.map((note, index) => (
    <div key={note.id} className={classNames({ 'm-t-15': index })}>
      <Note label={note.label} text={note.text} author={note.author} date={note.date} />
    </div>
  ));
};

PositionNotes.propTypes = propTypes;
PositionNotes.defaultProps = defaultProps;

export default PositionNotes;
