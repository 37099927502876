import React, { useState, useEffect } from 'react';
// libraries
import PropTypes from 'prop-types';
import getProperty from 'lodash/get';
import { useTranslation } from 'react-i18next';
// components
import LinkButton from 'components/atoms/LinkButton';
import Banner, { BANNER_TYPE } from 'components/molecules/Banner';
// helpers
import { preventDefault } from 'helpers/HtmlDOM';
// hooks
import useAuth from 'hooks/useAuth';
// styles
import styles from './privacyBanner.module.scss';

const PRIVACY_KEY = 'privacyVisibility';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const PrivacyBanner = ({ className }) => {
  const { t } = useTranslation();
  const user = useAuth();
  const userId = getProperty(user, 'id');
  const storedPrivacy = localStorage.getItem(PRIVACY_KEY);
  const [showBanner, setShowBanner] = useState(false);

  const handleDisplay = () => {
    localStorage.setItem(PRIVACY_KEY, userId);
    setShowBanner(false);
  };

  useEffect(() => {
    if (storedPrivacy && storedPrivacy === userId) {
      setShowBanner(false);
    } else {
      setShowBanner(true);
    }
  }, [storedPrivacy, userId]);

  if (!showBanner) {
    return null;
  }

  return (
    <Banner type={BANNER_TYPE.ANNOUNCEMENT} className={className}>
      <div className={styles.privacyBannerContainer}>
        <p>{t('candidatePoolPrivacyMessage')}</p>
        <LinkButton onClick={preventDefault(handleDisplay)}>
          {t('candidatePoolPrivacyDismissLink')}
        </LinkButton>
      </div>
    </Banner>
  );
};

PrivacyBanner.propTypes = propTypes;
PrivacyBanner.defaultProps = defaultProps;

export default PrivacyBanner;
