import React, { useRef } from 'react';
// libraries
import PropTypes from 'prop-types';

const propTypes = {
  accept: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  multiple: PropTypes.bool,
  onUpload: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
};

const defaultProps = {
  accept: null,
  multiple: false,
};

const FileBrowser = ({ accept, onUpload, multiple, children }) => {
  const input = useRef();

  const open = () => {
    input.current.click();
  };

  const handleFileUpload = files => {
    if (multiple) {
      onUpload(files);
      return;
    }

    onUpload(files[0]);
  };

  return (
    <React.Fragment>
      <input
        type="file"
        className="display-none"
        ref={input}
        accept={accept}
        onChange={event => handleFileUpload(event.target.files)}
        multiple={multiple}
      />
      {children(open)}
    </React.Fragment>
  );
};

FileBrowser.propTypes = propTypes;
FileBrowser.defaultProps = defaultProps;

export default FileBrowser;
