// libraries
import i18n from 'i18next';
import getProperty from 'lodash/get';
// constants
import { SECTION_IMPACTS, MRI_SECTION } from 'constants/report';
import { getPenaltyLevel } from 'helpers/MRI';

export const getSectionTitle = key => {
  switch (key) {
    case MRI_SECTION.LICENSES_AND_CERTIFICATIONS:
      return i18n.t('licensesAndCertifications');
    case MRI_SECTION.LANGUAGE:
      return i18n.t('language');
    case MRI_SECTION.EDUCATION:
      return i18n.t('education');
    case MRI_SECTION.WORK_HISTORY:
      return i18n.t('workHistory');
    case MRI_SECTION.CLEARANCE:
      return i18n.t('clearance');
    case MRI_SECTION.ALL_SECTIONS:
      return i18n.t('allSections');
    default:
      return null;
  }
};

export const parseImprovement = improvement => {
  const sectionKey = getProperty(improvement, 'section');
  const penalty = getProperty(improvement, 'penalty');

  return {
    impact: getProperty(SECTION_IMPACTS, sectionKey),
    name: sectionKey,
    level: getPenaltyLevel(penalty, improvement.id),
    penalty,
  };
};
