/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
// hooks
import usePrevious from 'hooks/usePrevious';
// Libraries
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
// components
import AutoCompleteCustom from 'components/atoms/AutoCompleteCustom';

const propTypes = {
  id: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onChange: PropTypes.func,
};

const defaultProps = {
  id: '',
  defaultValue: null,
  onChange: () => {},
};

const AutoCompleteSearch = props => {
  const { defaultValue, id, onChange } = props;
  const [value, onLocalChange] = useState(defaultValue);
  const prevDefaultValue = usePrevious(defaultValue);
  const previousValue = usePrevious(value);

  useEffect(() => {
    if ((defaultValue && !prevDefaultValue) || (!defaultValue && prevDefaultValue)) {
      onLocalChange(defaultValue);
    } else if ((!!value || !!previousValue) && !isEqual(value, previousValue)) {
      onChange(value, id);
    }
  }, [value, defaultValue]);

  return <AutoCompleteCustom {...props} value={value} onChange={onLocalChange} />;
};

AutoCompleteSearch.propTypes = propTypes;
AutoCompleteSearch.defaultProps = defaultProps;

export default AutoCompleteSearch;
