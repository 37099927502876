import React, { useState, useContext } from 'react';
// libraries
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import getProperty from 'lodash/get';
// hooks
import useAuth from 'hooks/useAuth';
// context
import ToastContext from 'context/ToastContext';
// components
import Button from 'components/atoms/Button';
import ConfirmStartDate from './ConfirmStartDate';
import EditForm from '../EditForm';
import JobOffer from './JobOffer';
import TextContent from '../TextContent';
// helpers
import { withdrawSubmission } from '../helpers';
// forms
import { withdrawCandidateForm } from '../forms';

const propTypes = {
  submission: PropTypes.object.isRequired,
  confirmedStartDate: PropTypes.object,
  title: PropTypes.any.isRequired,
  label: PropTypes.any.isRequired,
  subTitle: PropTypes.any,
};

const defaultProps = {
  confirmedStartDate: {},
  subTitle: '',
};

const CONTENT_CHANGE_DATE = 'CONTENT_CHANGE_DATE';
const CONTENT_WITHDRAW_CANDIDATE = 'CONTENT_WITHDRAW_CANDIDATE';

const StartDateConfirmed = ({ submission, confirmedStartDate, title, subTitle, label }) => {
  const { t } = useTranslation();
  const { addToast } = useContext(ToastContext);
  const user = useAuth();

  const [loading, setLoading] = useState(false);
  const [contentType, setContentType] = useState(null);

  const onStartDateChange = () => {
    // TODO: FINISH IN FUTURE
    // eslint-disable-next-line no-console
    setContentType(null);
  };

  const withdrawCandidate = async data => {
    setLoading(true);
    try {
      const rejectionReason = getProperty(data, 'withdrawReason.value');
      await withdrawSubmission({
        id: submission.id,
        reason: rejectionReason,
        userId: user.id,
        isProposal: submission.isProposal(),
      });
      addToast.success(t('candidateWithdrawalSuccessToast'));
    } catch (error) {
      addToast.error(t('errorWhileWithdrawingCandidate'));
      setLoading(false);
      setContentType(null);
    }
  };

  const getContent = () => {
    if (contentType === CONTENT_CHANGE_DATE) {
      return (
        <ConfirmStartDate
          onCancel={() => setContentType(null)}
          onSubmit={onStartDateChange}
          defaultStartDate={confirmedStartDate.date}
          onSubmitLabel={t('change')}
        />
      );
    }
    if (contentType === CONTENT_WITHDRAW_CANDIDATE) {
      return (
        <EditForm
          onCancel={() => setContentType(null)}
          onSubmit={withdrawCandidate}
          form={withdrawCandidateForm}
          onSubmitLabel={t('withdrawCandidate')}
          loading={loading}
        />
      );
    }
    return (
      <React.Fragment>
        <div className={`display-flex-row flexEnd ${contentType ? 'p-t-30' : 'p-t-15'}`}>
          <Button
            label={t('withdrawCandidate')}
            onClick={() => setContentType(CONTENT_WITHDRAW_CANDIDATE)}
            secondary
          />
        </div>
        <JobOffer submission={submission} className="w100" date={confirmedStartDate} />
      </React.Fragment>
    );
  };

  return <TextContent title={title} label={label} subTitle={subTitle} content={getContent()} />;
};

StartDateConfirmed.propTypes = propTypes;
StartDateConfirmed.defaultProps = defaultProps;

export default StartDateConfirmed;
