import React from 'react';
// libraries
import PropTypes from 'prop-types';
// components
import ReplaceSubmissionCvModal from 'components/organisms/ReplaceSubmissionCvModal';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  submitting: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onReplace: PropTypes.func.isRequired,
  candidateName: PropTypes.string.isRequired,
  submissionId: PropTypes.string.isRequired,
  matchReportId: PropTypes.string.isRequired,
};

const defaultProps = {
  submitting: false,
};

const ReplaceCV = ({
  isOpen,
  submissionId,
  matchReportId,
  onClose,
  submitting,
  candidateName,
  onReplace,
}) => {
  return (
    <ReplaceSubmissionCvModal
      isOpen={isOpen}
      onReplace={onReplace}
      onClose={onClose}
      submitting={submitting}
      candidateName={candidateName}
      matchReportId={matchReportId}
      submissionId={submissionId}
    />
  );
};

ReplaceCV.propTypes = propTypes;
ReplaceCV.defaultProps = defaultProps;

export default ReplaceCV;
