import React, { useContext, useState } from 'react';
// libraries
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
// context
import ToastContext from 'context/ToastContext';
// components
import Modal from 'components/molecules/ModalWindow';
import SubmissionRejection, {
  validate as validateReason,
} from 'components/molecules/SubmissionRejection';
// helpers
import { updateRejectionReason } from './helpers';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  note: PropTypes.string.isRequired,
  noteId: PropTypes.string.isRequired,
};

const EditRejectionReason = ({ isOpen, name, onClose, note: reason, noteId }) => {
  const { t } = useTranslation();
  const { addToast } = useContext(ToastContext);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [note, setNote] = useState(reason || '');

  const handleReasonChange = value => {
    if (errors) {
      setErrors([]);
    }

    setNote(value);
  };

  const handleSubmit = async () => {
    const reasonErrors = validateReason(note);

    if (reasonErrors.length) {
      setErrors(reasonErrors);
      return;
    }

    try {
      setLoading(true);

      await updateRejectionReason(noteId, note);
      addToast.success(t('candidateRejectionNoteUpdateSuccessToast'));
      onClose();
    } catch (error) {
      addToast.error(t('errorPlaceholderText'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} loading={loading}>
      <Modal.Header>
        <Trans i18nKey="editCandidateRejectionReason">
          Edit submission {{ name }} rejection reason
        </Trans>
      </Modal.Header>
      <Modal.Body>
        <SubmissionRejection reason={note} onChange={handleReasonChange} errors={errors} />
      </Modal.Body>
      <Modal.Footer>
        <Modal.Buttons
          primaryLabel={t('save')}
          primaryAction={handleSubmit}
          secondaryAction={onClose}
        />
      </Modal.Footer>
    </Modal>
  );
};

EditRejectionReason.propTypes = propTypes;

export default EditRejectionReason;
