import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// hooks
import useAuth from 'hooks/useAuth';
// helpers
import { getReadableYearDateTime } from 'helpers/RelativeTimeFormatter';
// constants
import { STAFF_ROLE, SUPPLIER_ROLE } from 'constants';
import { MAIN_RECRUITING } from 'constants/positionStatus';
// components
import Tooltip from 'components/atoms/Tooltip';
// styles
import styles from './publishStatus.module.scss';

const propTypes = {
  position: PropTypes.object.isRequired,
  className: PropTypes.string,
  tooltipPlacement: PropTypes.string,
};

const defaultProps = {
  className: '',
  tooltipPlacement: 'bottom',
};

const PublishStatus = ({ position, className, tooltipPlacement }) => {
  const { role, supplierId } = useAuth();
  const { t } = useTranslation();
  const positionStatus = position.getMainStatus(role, supplierId);
  const status = position.getStatus(role, supplierId);
  const isPublished = position.isPublished(role, supplierId);

  if (role === STAFF_ROLE && positionStatus.value === MAIN_RECRUITING) {
    const unPublishedOn = position.hasBeenPublishedBefore() ? status.start : null;
    const publishedDate = getReadableYearDateTime(position.supplierDueDate);
    const unPublishDate = unPublishedOn ? getReadableYearDateTime(unPublishedOn) : null;
    const unPublishTooltip = unPublishDate ? `${t('unPublishedOn')} ${unPublishDate}` : '';

    const title = isPublished ? `${t('publishedUntil')} ${publishedDate}` : unPublishTooltip;
    const label = isPublished ? t('published') : t('unpublished');

    return (
      <div className={className}>
        <Tooltip title={title} placement={tooltipPlacement}>
          <div className={classNames(styles.publishStatus, { [styles.published]: isPublished })}>
            {label}
          </div>
        </Tooltip>
      </div>
    );
  }

  if (
    role === SUPPLIER_ROLE &&
    positionStatus.value === MAIN_RECRUITING &&
    !isPublished &&
    position.hasBeenPublishedBefore(supplierId)
  ) {
    return (
      <div className={className}>
        <div className={classNames(styles.publishStatus)}>{t('closedForCvSubmission')}</div>
      </div>
    );
  }

  return null;
};

PublishStatus.propTypes = propTypes;
PublishStatus.defaultProps = defaultProps;

export default PublishStatus;
