import getProperty from 'lodash/get';
// constants
import { NOTE_FIELD, REASON_FIELD, REJECTION_REASONS } from './constants';

const PREDEFINED_REASONS_VALUE = REJECTION_REASONS.map(reason => reason.value);

/**
 * @typedef {Object} ParsedReason
 * @property {string} note Additional rejection note
 * @property {string[]} reasons Selected reasons from pre-defined options
 */

/**
 * Check if from list of reasons only "Custom" reason was selected
 * @param {string[]} reasons List of all selected reasons
 * @returns {bool}
 */
export const isCustomReason = reasons => reasons.length === 1 && reasons.includes('Custom');

/**
 * Join reasons in one string/text
 * @param {string[]} reasons
 * @param {string} note
 * @returns {string}
 */
export const buildReason = (reasons, note) => {
  let reasonText = reasons.join(', ');

  if (note) {
    if (reasonText) {
      reasonText += ` / ${note}`;
    } else {
      reasonText = note;
    }
  }

  return reasonText;
};

/**
 * Parse additional note and selected pre-defined options
 * @param {string} reason
 * @returns {ParsedReason}
 */
export const parseReason = reason => {
  const result = {
    [NOTE_FIELD]: '',
    [REASON_FIELD]: [],
  };

  /*
    Possible reason formats:
    1. [Predefined reason1], [Predefined reason2] - Predefined reasons separated by comma
    2. [Predefined reason1] / [Custom reason] - Predefined reasons separated by comma + custom reason separates by slash
    3. [Custom reason] - Just custom reason
  */

  // Custom reason is separated by "/" from predefined reasons which are located at the beginning of the text
  const firstPart = getProperty(reason.split('/'), '0', '');
  const hasPredefinedReasons = firstPart
    .split(',')
    .some(parsedReason => PREDEFINED_REASONS_VALUE.includes(parsedReason.trim()));

  if (hasPredefinedReasons) {
    result[REASON_FIELD] = firstPart.split(',').map(parsedReason => parsedReason.trim());
    result[NOTE_FIELD] = reason
      .split('/')
      .slice(1) // Remove predefined reasons part
      .join('/') // Put back together custom reason parts
      .trimLeft();
  } else {
    result[NOTE_FIELD] = reason;
  }

  return result;
};

/**
 * Validate if reason has valid form
 * @param {string} reason
 * @returns {string[]} Array of fields name which have error
 */
export const validate = reason => {
  const errors = [];
  const { [NOTE_FIELD]: note, [REASON_FIELD]: reasons } = parseReason(reason);

  if (reasons.length === 0) {
    errors.push(REASON_FIELD);
  }
  if (isCustomReason(reasons) && !note) {
    errors.push(NOTE_FIELD);
  }

  return errors;
};
