export const FIELD = {
  SUBMISSION_WON: 'submissionWon',
  SUBMISSIONS: 'submissions',
  TOTAL_EVALUATED_PRICE: 'totalEvaluatedPrice',
  HAS_REJECTION_REASON: 'hasRejectionReason',
  SAME_REJECTION_REASON: 'sameRejectionReason',
  SELECTED_SUBMISSION: 'selectedSubmission',
  NOT_SELECTED_SUBMISSIONS: 'notSelectedSubmissions',
  JUSTIFICATION: 'justification',
  REASON: 'reason',
  REASONS: 'reasons',
  NOTES: 'notes',
  START_DATE: 'startDate',
  RE_SELECTION_JUSTIFICATION: 'reSelectionJustification',
};

export const STEP = {
  SELECT_WINNER: 'SELECT_WINNER',
  START_DATE: 'START_DATE',
  NOTES: 'NOTES',
};

export const STEP_FIELDS = {
  [STEP.SELECT_WINNER]: [
    FIELD.SUBMISSION_WON,
    FIELD.SUBMISSIONS,
    FIELD.TOTAL_EVALUATED_PRICE,
    FIELD.HAS_REJECTION_REASON,
    FIELD.SAME_REJECTION_REASON,
    FIELD.SELECTED_SUBMISSION,
    FIELD.NOT_SELECTED_SUBMISSIONS,
    FIELD.JUSTIFICATION,
    FIELD.REASON,
    FIELD.REASONS,
    FIELD.RE_SELECTION_JUSTIFICATION,
  ],
  [STEP.START_DATE]: [FIELD.START_DATE],
  [STEP.NOTES]: [FIELD.NOTES],
};
