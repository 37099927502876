/* eslint-disable import/order */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import shortId from 'shortid';
import getProperty from 'lodash/get';
import setProperty from 'lodash/set';
import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
// context
import ToastContext from 'context/ToastContext';
// components
import Icon from 'components/atoms/Icon';
import Button from 'components/atoms/Button';
import Field from 'components/molecules/Field';
import ComplexComponents from '../ComponentParser/ComplexComponents';
// helpers
import {
  validate,
  closePreviouslyOpen,
  getHeaderName,
  getErrorFromFormData,
  getAvailableRevisionTypes,
} from './helpers';
// constants
import { REVISION_TYPES } from 'constants';
import { REVISION_TYPE_COMPONENT } from './constants';
// styles
import colors from 'dependencies/materialStyles/Colors';

const propTypes = {
  rates: PropTypes.object,
  openRevisionTypes: PropTypes.array,
  rateSimulationProps: PropTypes.object,
  formData: PropTypes.object,
  setFormData: PropTypes.func.isRequired,
};

const defaultProps = {
  rates: {},
  openRevisionTypes: [],
  rateSimulationProps: {},
  formData: {},
};

const RequestRevisionForm = ({
  rates,
  openRevisionTypes,
  rateSimulationProps,
  formData,
  setFormData,
}) => {
  const { t } = useTranslation();
  const { addToast } = useContext(ToastContext);
  const isMaximumNumberOfRevisions = Object.keys(formData).length === 3;

  const removeRevisionType = key => {
    const newFormData = { ...formData };
    delete newFormData[key];
    setFormData(newFormData);
  };

  const updateFormDataValue = (key, value) => {
    const [componentKey, subItem] = key.split('.');

    const newFormData = {
      ...formData,
      [componentKey]: {
        ...value,
        headerName: getHeaderName(value),
      },
    };

    // If user selects revision type rate set data required for rate components
    if (
      subItem === 'type' &&
      getProperty(newFormData, [componentKey, 'value', subItem, 'value'], '') ===
        REVISION_TYPES.RATES
    ) {
      setProperty(newFormData, [componentKey, 'rates'], rates);
      setProperty(newFormData, [componentKey, 'rateSimulationProps'], rateSimulationProps);
    }

    const validatedFormData = validate(newFormData, key);
    setFormData(validatedFormData);
  };

  const updateFormData = (key, value) => {
    const [componentKey, subItem] = key.split('.');

    if (subItem === 'remove') {
      removeRevisionType(componentKey);
    } else {
      updateFormDataValue(key, value);
    }
  };

  const onAddRevisionTypeClick = () => {
    if (isMaximumNumberOfRevisions) {
      addToast.warning(t('maximumNumberOfRevisionTypes'));
    }

    const initialId = shortId();
    const validated = validate(formData);
    const error = getErrorFromFormData(validated);

    if (error) {
      setFormData(validated);
      addToast.error(t(error));
      return;
    }

    const newFormData = closePreviouslyOpen(validated);
    setFormData({
      ...newFormData,
      [initialId]: {
        id: initialId,
        collapsed: false,
        value: {
          note: {
            value: '',
          },
          type: '',
        },
        error: false,
        errorMessage: '',
      },
    });
  };

  const buttonTooltip = isMaximumNumberOfRevisions ? t('maximumNumberOfRevisionTypes') : '';
  const availableRevisionTypes = getAvailableRevisionTypes(openRevisionTypes, formData);

  const renderWarnings = () => {
    return openRevisionTypes.map((revisionType, index) => {
      const type = t(getProperty(revisionType, 'type', '').toLowerCase());
      const id = getProperty(revisionType, 'id');

      return (
        <div
          className={classNames('requestRevisionModal_warning m-b-10', { 'm-t-10': !index })}
          key={id}
        >
          <Icon className="m-r-10" name="warning" color={colors.yellow} />
          <div>
            <Trans i18nKey="pendingRevisionExists">
              You cannot request a new revision for <b>{{ type }}</b> while the previous revision
              has not been completed by the partner.
            </Trans>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="requestRevisionModal">
      <div className="field_question">{t('defineRevisions')}</div>
      {renderWarnings()}
      {Object.values(formData).map(({ id }) => {
        const revisionTypeComponent = {
          ...REVISION_TYPE_COMPONENT,
          values: availableRevisionTypes,
          key: id,
        };
        return (
          <Field
            key={id}
            required
            hideLabel
            className="m-b-10"
            content={
              <ComplexComponents
                component={revisionTypeComponent}
                updateFormData={updateFormData}
                formData={formData}
              />
            }
          />
        );
      })}
      {availableRevisionTypes.length !== 0 && (
        <div className="m-t-15">
          <Button
            iconButton="add"
            label={t('addNewRevisionType')}
            onClick={onAddRevisionTypeClick}
            tooltip={buttonTooltip}
          />
        </div>
      )}
    </div>
  );
};

RequestRevisionForm.propTypes = propTypes;
RequestRevisionForm.defaultProps = defaultProps;

export default RequestRevisionForm;
