import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
// components
import RadioCustom from 'components/molecules/RadioCustom';
import ErrorPlaceholder from 'components/atoms/ErrorPlaceholder';
import RadioLoader from 'components/atoms/ContentLoader/RadioLoader';
// helpers
import { formatRadioData, getQuery } from './Helpers';

const propTypes = {
  tableName: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  orderBy: PropTypes.object,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

const defaultProps = {
  selected: {},
  orderBy: null,
};

const RadioTableOptions = ({ tableName, fieldName, orderBy, selected, onChange, name }) => {
  const { data, error, loading } = useQuery(getQuery(tableName, fieldName, orderBy));

  if (error) {
    return <ErrorPlaceholder />;
  }
  if (loading) {
    return <RadioLoader />;
  }

  return (
    <RadioCustom
      list={formatRadioData(data, tableName, fieldName)}
      selected={selected}
      onChange={onChange}
      name={name}
    />
  );
};

RadioTableOptions.propTypes = propTypes;
RadioTableOptions.defaultProps = defaultProps;

export default RadioTableOptions;
