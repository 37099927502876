import { gql } from '@apollo/client';
import getProperty from 'lodash/get';
import setProperty from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
// services
import client from 'services/Client';
import logger from 'services/Logger';
// queries
import { RevisionStatus } from 'queries/Fragments';
// constants
import { REVISION_STATUS, REVISION_TYPES } from 'constants';
// helpers
import { getRevisionStatus } from 'helpers/Revision';
import uploadFiles from './uploadFiles';

const uploadAttestationToBox = async (formData, revision) => {
  const existingBoxId = getProperty(formData, [REVISION_TYPES.ATTESTATION, 'boxFileId']);

  if (existingBoxId) return existingBoxId;

  const uploadedFiles = await uploadFiles(formData, revision);

  return getProperty(uploadedFiles, [REVISION_TYPES.ATTESTATION, 'boxFileId']);
};

const formatFiles = async (formData, revision) => {
  const newFormData = cloneDeep(formData);
  const attestationBoxId = await uploadAttestationToBox(formData, revision);

  [REVISION_TYPES.ATTESTATION, REVISION_TYPES.CV].forEach(type => {
    const filePath = [type, 'revisedFile', 'value', 'file', '0', 'file'];
    const fileName = getProperty(formData, [...filePath, 'name'], '');
    if (fileName) {
      if (type === REVISION_TYPES.ATTESTATION) {
        setProperty(newFormData, [type, 'boxFileId'], attestationBoxId);
      }
      setProperty(newFormData, filePath, { name: fileName });
    }
  });

  return newFormData;
};

const SAVE_PROGRESS = gql`
  mutation saveRevisionProgress($id: ID!, $data: RevisionUpdateInput!) {
    revisionUpdate(filter: { id: $id }, data: $data) {
      id
      workInProgress
      statuses {
        items {
          ...RevisionStatus
        }
      }
    }
  }
  ${RevisionStatus}
`;

const saveProgress = async (formData, revision) => {
  try {
    const statuses = getProperty(revision, 'statuses.items', []);
    const currentStatus = getRevisionStatus(statuses);
    const filesUpdate = await formatFiles(formData, revision);
    const mutationData = {
      statuses: {
        update: {
          filter: {
            id: currentStatus.id,
          },
          data: {
            end: moment().toISOString(),
          },
        },
        create: {
          status: REVISION_STATUS.IN_PROGRESS,
          start: moment().toISOString(),
        },
      },
      workInProgress: {
        ...formData,
        ...filesUpdate,
      },
    };

    return client.mutate({
      mutation: SAVE_PROGRESS,
      variables: {
        id: revision.id,
        data: mutationData,
      },
    });
  } catch (error) {
    logger.exception(error);
    throw error;
  }
};

export default saveProgress;
