import orderBy from 'lodash/orderBy';
import getProperty from 'lodash/get';
import mean from 'lodash/mean';
// helpers
import { getSubmissionCurrentStatus, isSubmitted, isRejected } from 'helpers/Submission';
// data
import {
  SORT_IMB_GP,
  SORT_SCORE,
  SORT_SUPPLIER,
  SORT_RECEIVED,
  SORT_STATUS,
} from './SortingOptions';
import { FILTER_REJECTED, FILTER_SUBMITTED } from './FilteringOptions';

const sortByGP = (submissions, candidateGPs) => {
  // create array of objects - submission + average GP
  const candidatesWithGP = submissions.map(submission => ({
    candidate: submission,
    GP: mean(getProperty(candidateGPs, submission.id, [])),
  }));

  return orderBy(candidatesWithGP, 'GP', 'desc').map(({ candidate }) => candidate);
};

const sortByScore = submissions => {
  return orderBy(submissions, 'score', 'desc');
};

const sortBySupplier = submissions => {
  return orderBy(submissions, submission => {
    const supplier = submission.candidate ? submission.candidate.supplier : submission.supplier;

    return supplier.company.name;
  });
};

const sortByReceived = submissions => {
  return orderBy(submissions, 'submissionDate');
};

const sortByStatus = submissions => {
  return orderBy(submissions, submission => {
    const statuses = getProperty(submission, 'statuses.items', []);
    const currentStatus = getSubmissionCurrentStatus(statuses);

    return currentStatus ? currentStatus.name : '';
  });
};

/**
 * @param {Candidate[]} submissions
 * @param {string} sortBy
 * @param {object} GPs Object where key is submission id, and value is array of submission GPs
 * @returns {Candidate[]}
 */
export const sortSubmissions = (submissions, sortBy, GPs) => {
  switch (sortBy) {
    case SORT_IMB_GP:
      return sortByGP(submissions, GPs);
    case SORT_SCORE:
      return sortByScore(submissions);
    case SORT_SUPPLIER:
      return sortBySupplier(submissions);
    case SORT_RECEIVED:
      return sortByReceived(submissions);
    case SORT_STATUS:
      return sortByStatus(submissions);
    default:
      return submissions;
  }
};

/**
 * Returns submissions which are not hidden
 * @param {Candidate[]} submissions
 * @param {string[]} hiddenSubmissions list of submissions id
 * @returns {Candidate[]}
 */
export const filterOutHiddenSubmissions = (submissions, hiddenSubmissions) => {
  return submissions.filter(candidate => !hiddenSubmissions.includes(candidate.id));
};

/**
 * Filter submissions by status
 * @param {Candidate[]} Submissions
 * @param {string} filterBy Submission status
 * @returns {Candidate[]}
 */
export const filterSubmissions = (submissions, filterBy) => {
  return !filterBy
    ? submissions
    : submissions.filter(submission => {
        const statuses = getProperty(submission, 'statuses.items', []);
        const currentStatus = getSubmissionCurrentStatus(statuses);
        const currentStatusValue = currentStatus ? currentStatus.name : '';

        if (filterBy === FILTER_SUBMITTED) {
          return isSubmitted(currentStatusValue) && !isRejected(currentStatusValue);
        }
        if (filterBy === FILTER_REJECTED) {
          return isRejected(currentStatusValue);
        }

        return currentStatusValue === filterBy;
      });
};
