// services
import notification from 'services/Notification';
// helpers
import { changeSubmissionStatus } from 'helpers/Submission';
import { getAbsoluteUrl } from 'helpers/Link';
import { withdrawSubmission as withdrawOnboardingSubmission } from 'components/organisms/Onboarding';
// constants
import { SUBMISSION_STATUS } from 'constants/submissionStatus';

/**
 * @param {object} params
 * @param {string} params.submissionId
 * @param {object} params.status
 * @param {string} params.reason
 * @param {string} params.userId Logged in user id
 * @param {boolean} params.isProposal
 * @returns {Promise}
 */
export const withdrawSubmission = async ({ submissionId, status, reason, userId, isProposal }) => {
  const isOnboarding = [SUBMISSION_STATUS.ONBOARDING, SUBMISSION_STATUS.OFFER].includes(
    status.value
  );

  if (isOnboarding) {
    await withdrawOnboardingSubmission({ id: submissionId, reason, userId, isProposal });
  } else {
    await changeSubmissionStatus({
      submissionId,
      status: SUBMISSION_STATUS.WITHDRAWN,
      oldStatusId: status.id,
      note: reason,
      isProposal,
    });
  }

  notification.submissionWithdrawn({
    submission: submissionId,
    link: getAbsoluteUrl(`/${isProposal ? 'proposal' : 'submission'}/${submissionId}`),
    createdBy: userId,
    isProposal,
  });
};
