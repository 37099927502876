import React, { useState, useContext } from 'react';
import queryString from 'query-string';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory, useLocation, Link } from 'react-router-dom';
// context
import ToastContext from 'context/ToastContext';
// Components
import Input from 'components/atoms/Input';
import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import Loader from 'components/atoms/Loader';
// helpers
import colors from 'dependencies/materialStyles/Colors';
// services
import { resetPassword } from 'services/Authentication';

const ForgotPasswordForm = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { addToast } = useContext(ToastContext);

  const location = useLocation();
  const { linkexpired } = queryString.parse(location.search);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState('');

  const handleChange = value => {
    setEmail(value);
    if (error) {
      setError(false);
    }
  };

  const handleResetPasswordClick = async () => {
    if (email !== '') {
      try {
        setLoading(true);
        await resetPassword(email);
        history.push(`/reset-success?email=${email}`);
      } catch (err) {
        setLoading(false);
        if (err.code === 404) {
          addToast.error(t('userNotExists'));
        } else {
          addToast.error(t(err));
        }
      }
    } else {
      addToast.error(t('fillEmailFirst'));
      setError(true);
    }
  };
  const formHeader = linkexpired ? 'linkExpired' : 'forgotPassword';
  const formSubheader = linkexpired ? 'passwordLinkExpired' : 'passwordEmailInstructions';

  return (
    <div className="authForm">
      <div className="authForm_header">{t(formHeader)}</div>
      <div className="authForm_label formWidth m-b-30">{t(formSubheader)}</div>
      <div className={`formWidth ${error && email === '' ? 'redForm' : ''}`}>
        <Input
          id="email"
          placeholder={t('email')}
          icon={<Icon color={colors.gray} size={18} name="person" />}
          value={email}
          inputType="email"
          className="formWidth"
          onChange={handleChange}
        />
      </div>
      {loading ? (
        <div className="m-t-20">
          <Loader />
        </div>
      ) : (
        <div className="authForm_button">
          <Button label={t('send')} onClick={handleResetPasswordClick} />
        </div>
      )}
      <div className="authForm_statement">
        <Trans i18nKey="genericRedirectLogin">
          Return to{' '}
          <Link to="/login" className="authLink">
            Sign in
          </Link>
        </Trans>
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
