import React from 'react';
// libraries
import PropTypes from 'prop-types';
// components
import Tooltip, { TOOLTIP_POSITION } from 'components/atoms/Tooltip';
// styles
import styles from './rateDisplay.module.scss';

const propTypes = {
  tooltip: PropTypes.string,
  rate: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  rateDescription: PropTypes.any.isRequired,
};

const defaultProps = {
  tooltip: '',
};

const RateDisplay = ({ tooltip, rate, currency, rateDescription }) => {
  return (
    <Tooltip placement={TOOLTIP_POSITION.TOP_END} title={tooltip}>
      <div>
        <div className={styles.rates}>
          {rate} {currency}
        </div>
        {rateDescription && <div className={styles.subtitle}>{rateDescription}</div>}
      </div>
    </Tooltip>
  );
};

RateDisplay.propTypes = propTypes;
RateDisplay.defaultProps = defaultProps;

export default RateDisplay;
