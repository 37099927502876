import React from 'react';
// libraries
import PropTypes from 'prop-types';
import times from 'lodash/times';
import classNames from 'classnames';
// constants
import { MRI_IMPACT } from 'constants/report';
// helpers
import { getImpactDetails } from 'helpers/MRI';
// styles
import styles from './mriImprovements.module.scss';

const propTypes = {
  impact: PropTypes.oneOf([MRI_IMPACT.LOW, MRI_IMPACT.MEDIUM, MRI_IMPACT.HIGH]).isRequired,
};

const ImpactChip = ({ impact }) => {
  const { color, label, dots } = getImpactDetails(impact);

  return (
    <div className={styles.impact}>
      <div className={styles.impactDots}>
        {times(3, index => {
          return (
            <div
              key={index}
              className={classNames(styles.impactDot, { [styles[color]]: index + 1 <= dots })}
            />
          );
        })}
      </div>
      {label}
    </div>
  );
};

ImpactChip.propTypes = propTypes;

export default ImpactChip;
