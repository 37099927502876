import React from 'react';
// libraries
import PropTypes from 'prop-types';
// styles
import './pageTitle.scss';

const propTypes = {
  children: PropTypes.string.isRequired,
};

const PageTitle = ({ children }) => {
  return <div className="pageTitle fs-unmask">{children}</div>;
};

PageTitle.propTypes = propTypes;

export default PageTitle;
