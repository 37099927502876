import { gql } from '@apollo/client';
import getProperty from 'lodash/get';
import setProperty from 'lodash/set';
import moment from 'moment';
// services
import client from 'services/Client';
import logger from 'services/Logger';
import notification from 'services/Notification';
import { bulkUpload } from 'services/Box';
// queries
import { SubmissionStatus, File, Note } from 'queries/Fragments';
// helpers
import { getSubmissionLink } from 'helpers/Link';
// constants
import { SUBMISSION_STATUS_TYPE } from 'constants/submissionStatus';
import { SUBMISSION_TAB } from 'constants/pageTabs';
import { ONBOARDING_TAB, ONBOARDING_STATUS_FORMS } from 'constants/onboarding';
import {
  ONBOARDING_FORMS_NOTE,
  ONBOARDING_FILE_HR72,
  ONBOARDING_FILE_PASSPORT,
  ONBOARDING_FILE_NDA,
  ONBOARDING_FILE_NHF,
  ONBOARDING_FILE_ATTESTATION,
  ONBOARDING_FILE_AAS_PLUS_NDA,
  ONBOARDING_FILE_NR_AIS_SECOPS_FORM,
} from '../constants';

/**
 * Upload Files to Box and return array of formatted file that are prepared for mutation
 * @param {object} params.formData
 * @param {string} params.folder
 * @param {string} params.rfq
 * @param {string} params.userId
 * @param {string} [params.submissionName]
 * @param {boolean} [params.isProposal]
 */
const uploadFiles = async ({ formData, folder, submissionName, rfq, userId, isProposal }) => {
  const DOCUMENTS_MAP = {
    [ONBOARDING_FILE_HR72]: 'HR72',
    [ONBOARDING_FILE_PASSPORT]: 'ID',
    [ONBOARDING_FILE_NDA]: 'NDA',
    [ONBOARDING_FILE_NHF]: 'NHF',
    [ONBOARDING_FILE_ATTESTATION]: 'ONBOARDING_ATTESTATION]',
    [ONBOARDING_FILE_AAS_PLUS_NDA]: 'AAS_PLUS_NDA',
    [ONBOARDING_FILE_NR_AIS_SECOPS_FORM]: 'NR_AIS_SECOPS_FORM',
  };

  const DOCUMENTS_LABEL_MAP = {
    [ONBOARDING_FILE_HR72]: 'HR72',
    [ONBOARDING_FILE_PASSPORT]: 'ID',
    [ONBOARDING_FILE_NDA]: 'NDA',
    [ONBOARDING_FILE_NHF]: 'NHF',
    [ONBOARDING_FILE_ATTESTATION]: 'Attestation',
    [ONBOARDING_FILE_AAS_PLUS_NDA]: 'AAS+ NCIA NDA',
    [ONBOARDING_FILE_NR_AIS_SECOPS_FORM]: 'NR SecOps D2 form',
  };

  const DOCUMENTS_KEY = Object.keys(DOCUMENTS_MAP);

  const files = Object.keys(formData).reduce((list, key) => {
    if (DOCUMENTS_KEY.includes(key)) {
      const filesData = getProperty(formData, `${key}`, []).map(fileData => fileData.file);
      const parsedFiles = filesData.map(file => {
        const originalFilename = getProperty(file, 'name', '');
        const fileExtension = originalFilename.split('.').pop();
        const name = !isProposal ? submissionName : originalFilename.split('.').shift();
        const newFilename = `${DOCUMENTS_LABEL_MAP[key]} ${name} RFQ ${rfq}.${fileExtension}`;

        return {
          file,
          filename: newFilename,
          type: key,
        };
      });

      return [...list, ...parsedFiles];
    }

    return list;
  }, []);

  if (!files.length) {
    return null;
  }

  try {
    const fileIds = await bulkUpload(files, folder);

    return fileIds.map((id, index) => ({
      boxId: id,
      name: files[index].filename,
      type: files[index].type,
      author: { connect: { id: userId } },
    }));
  } catch (error) {
    logger.exception(error, { files, folder });
    throw error;
  }
};

const SUBMIT_FORMS_BY_SUPPLIER_SUBMISSION = gql`
  mutation submitFormsBySupplierSubmission($submissionId: ID!, $data: SubmissionUpdateInput!) {
    submissionUpdate(filter: { id: $submissionId }, data: $data) {
      id
      statuses(sort: { createdAt: DESC }) {
        items {
          ...SubmissionStatus
        }
      }
      hiringProcess {
        id
        notes {
          items {
            ...Note
          }
        }
        files(sort: { updatedAt: DESC }) {
          items {
            ...File
          }
        }
      }
    }
  }
  ${SubmissionStatus}
  ${File}
  ${Note}
`;

const SUBMIT_FORMS_BY_SUPPLIER_PROPOSAL = gql`
  mutation submitFormsBySupplierProposal($submissionId: ID!, $data: ProposalUpdateInput!) {
    proposalUpdate(filter: { id: $submissionId }, data: $data) {
      id
      statuses(sort: { createdAt: DESC }) {
        items {
          ...SubmissionStatus
        }
      }
      hiringProcess {
        id
        notes {
          items {
            ...Note
          }
        }
        files(sort: { updatedAt: DESC }) {
          items {
            ...File
          }
        }
      }
    }
  }
  ${SubmissionStatus}
  ${File}
  ${Note}
`;

/**
 * Submit Onboarding Forms
 * Step: Onboarding Forms - Submit Onboarding Forms
 * Role: Supplier
 *
 * 1. Add provided onboarding forms to hiring process / files field
 * 2. If notes provided, add it
 * 3. Create new hiring status
 * 4. Add end date to latest hiring status
 *
 * @param {object} data
 * @param {string} data.submission
 * @param {string} data.currentOnboardingStatusId
 * @param {object} data.formData
 * @param {string} data.folder Folder where forms will be stored
 * @param {string} data.userId Logged in user id
 * @param {string} data.rfq RFQ number
 */
const submitFormsBySupplier = async ({
  submission,
  currentOnboardingStatusId,
  formData,
  folder,
  userId,
  rfq,
}) => {
  try {
    const note = getProperty(formData, `${ONBOARDING_FORMS_NOTE}.value`);
    const filesMutationData = await uploadFiles({
      formData,
      folder,
      submissionName: submission.getName(),
      isProposal: submission.isProposal(),
      rfq,
      userId,
    });

    const mutationData = {
      statuses: {
        update: {
          filter: { id: currentOnboardingStatusId },
          data: { end: moment().toISOString() },
        },
        create: [
          {
            start: moment().toISOString(),
            name: ONBOARDING_STATUS_FORMS.SEND_TO_PM,
            type: SUBMISSION_STATUS_TYPE.ONBOARDING_FORMS,
          },
        ],
      },
    };

    if (!submission.isDirectPartner()) {
      mutationData.statuses.create.push({
        start: moment().toISOString(),
        name: ONBOARDING_STATUS_FORMS.SEND_TO_AUSY,
        type: SUBMISSION_STATUS_TYPE.ONBOARDING_FORMS,
      });
    }

    if (filesMutationData) {
      setProperty(mutationData, 'hiringProcess.update.files.create', filesMutationData);
    }

    if (note) {
      const noteMutationData = {
        create: {
          note,
          noteType: ONBOARDING_FORMS_NOTE,
          author: { connect: { id: userId } },
        },
      };

      setProperty(mutationData, 'hiringProcess.update.notes', noteMutationData);
    }

    await client.mutate({
      mutation: submission.isProposal()
        ? SUBMIT_FORMS_BY_SUPPLIER_PROPOSAL
        : SUBMIT_FORMS_BY_SUPPLIER_SUBMISSION,
      variables: {
        submissionId: submission.id,
        data: mutationData,
      },
      update: cache => {
        cache.evict({ fieldName: 'submissionsList' });
        cache.gc();
      },
    });

    const link = getSubmissionLink(submission.id, submission.isProposal(), {
      queryParams: {
        step: ONBOARDING_TAB.FORMS,
        tab: SUBMISSION_TAB.ONBOARDING,
      },
    });

    notification.onboardingFormsUploaded({
      submission: submission.id,
      link,
      createdBy: userId,
      isProposal: submission.isProposal(),
    });

    return null;
  } catch (error) {
    logger.exception(error, { submissionId: submission.id });

    throw error;
  }
};

export default submitFormsBySupplier;
