import React, { useState, useRef, useLayoutEffect } from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
// styles
import './truncatedText.scss';

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  tooltipPosition: PropTypes.string,
  disableTooltip: PropTypes.bool,
};

const defaultProps = {
  className: '',
  children: '',
  tooltipPosition: 'top',
  disableTooltip: false,
};

const TruncatedText = React.memo(({ children, className, tooltipPosition, disableTooltip }) => {
  const element = useRef();
  const [isOverflown, setIsOverflown] = useState(false);

  const isElementOverflown = () => {
    if (!element.current) {
      return false;
    }

    const { scrollWidth, clientWidth } = element.current;

    return scrollWidth > clientWidth;
  };

  useLayoutEffect(() => {
    const handleElementResize = () => {
      if (!disableTooltip) {
        setIsOverflown(isElementOverflown());
      }
    };

    // create debounce fn
    const debouncedElementResize = debounce(handleElementResize, 300);

    // check text overflown after render
    handleElementResize();

    // re-run overflown check after window was resized
    window.addEventListener('resize', debouncedElementResize);

    return () => {
      window.removeEventListener('resize', debouncedElementResize);
    };
  }, []);

  return (
    <Tooltip
      title={isOverflown && !disableTooltip ? children : ''}
      placement={tooltipPosition}
      disableFocusListener
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 600 }}
    >
      <div className={classNames('truncatedText', { [className]: className })} ref={element}>
        {children}
      </div>
    </Tooltip>
  );
});

TruncatedText.propTypes = propTypes;
TruncatedText.defaultProps = defaultProps;

export default TruncatedText;
