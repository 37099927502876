import React from 'react';
import PropTypes from 'prop-types';
import {
  LIST_TYPE_NUMBER,
  LIST_TYPE_BULLET,
  LIST_TYPE_BULLET_EMPTY,
  LIST_TYPE_SQUARE_BULLET,
  LIST_TYPE_SQUARE_BULLET_EMPTY,
} from 'constants/list';

const propTypes = {
  type: PropTypes.string,
  number: PropTypes.number,
  preIndex: PropTypes.string,
};

const defaultProps = {
  type: null,
  number: 1,
  preIndex: '',
};

const ListBullet = ({ type, number, preIndex }) => {
  if (type === LIST_TYPE_NUMBER) {
    return (
      <span>
        {preIndex}
        {number}.
      </span>
    );
  }
  if (type === LIST_TYPE_BULLET) {
    return <span className="p-l-10">●</span>;
  }
  if (type === LIST_TYPE_SQUARE_BULLET) {
    return <span className="p-l-10">▪</span>;
  }
  if (type === LIST_TYPE_BULLET_EMPTY) {
    return <span className="p-l-10">○</span>;
  }
  if (type === LIST_TYPE_SQUARE_BULLET_EMPTY) {
    return <span className="p-l-10">▫</span>;
  }
  return <span className="p-l-10">●</span>;
};

ListBullet.propTypes = propTypes;
ListBullet.defaultProps = defaultProps;

export default ListBullet;
