import React, { useState, useRef } from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// components
import Icon from 'components/atoms/Icon';
import ClickableElement from 'components/atoms/ClickableElement';
import Loader from 'components/atoms/ElementLoader';
import Popper from 'components/atoms/Popper';
// styles
import colors from 'dependencies/materialStyles/Colors';
import styles from './buttonDropdown.module.scss';

const propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
  secondary: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

const defaultProps = {
  secondary: false,
  loading: false,
  disabled: false,
};

const ButtonDropdown = ({ label, onClick, items, secondary, loading, disabled }) => {
  const anchorEl = useRef();
  const [isOpen, setOpen] = useState(false);
  const isLoading = !disabled && loading;

  const handleItemClick = callback => event => {
    event.stopPropagation();
    setOpen(false);
    callback();
  };

  const handleClick = () => {
    if (disabled) {
      return;
    }

    if (isOpen) {
      setOpen(false);
    }

    onClick();
  };

  return (
    <div ref={anchorEl}>
      <div
        className={classNames(styles.button, {
          [styles.opened]: isOpen,
          [styles.secondary]: secondary,
          [styles.loading]: isLoading,
          [styles.disabled]: disabled,
        })}
      >
        <ClickableElement className={styles.label} onClick={handleClick}>
          {isLoading && (
            <div className={styles.loader}>
              <Loader color={secondary ? colors.blue : colors.white} />
            </div>
          )}
          <span className={classNames({ [styles.hidden]: isLoading })}>{label}</span>
        </ClickableElement>
        <ClickableElement
          className={classNames(styles.control, { [styles.hidden]: isLoading })}
          onClick={() => (!disabled ? setOpen(!isOpen) : null)}
        >
          <Icon
            size={20}
            name={isOpen ? 'less' : 'expandMore'}
            color={!secondary || disabled ? colors.white : colors.blue}
          />
        </ClickableElement>
      </div>
      <Popper
        isOpen={isOpen}
        anchorEl={anchorEl.current}
        className={styles.menu}
        onClose={() => setOpen(false)}
      >
        {items.map(item => (
          <ClickableElement
            onClick={handleItemClick(item.onClick)}
            className={styles.item}
            key={item.label}
          >
            {item.label}
          </ClickableElement>
        ))}
      </Popper>
    </div>
  );
};

ButtonDropdown.propTypes = propTypes;
ButtonDropdown.defaultProps = defaultProps;

export default ButtonDropdown;
