// libraries
import i18n from 'i18next';
import hasProperty from 'lodash/has';
// services
import logger from 'services/Logger';
// constants
import { STAFF_ROLE, SUPPLIER_ROLE } from 'constants';
import { POSITION_TAB } from 'constants/pageTabs';

export const getTabs = role => {
  const tabs = [
    {
      id: POSITION_TAB.DETAIL,
      label: i18n.t('positionDetails'),
    },
    {
      id: role === STAFF_ROLE ? POSITION_TAB.RATES : POSITION_TAB.CANDIDATES,
      label: i18n.t('submissions'),
    },
  ];

  if (role === SUPPLIER_ROLE) {
    tabs.push({
      id: POSITION_TAB.RANKING,
      label: i18n.t('candidatePoolRanking'),
    });
  }

  return tabs;
};

/**
 * Access and permissions are primary handled via 8base roles.
 * This is an extra layer for to for correct permission for access in case something permission
 * is missing or is incorrect in alaka-roles
 * @param {object} candidate
 * @param {object} user Logged in user
 * @returns {bool}
 */
export const isUnauthorizedAccess = (position, user) => {
  const projectIds = user.projects.map(({ id: projectId }) => projectId);

  if (hasProperty(position, 'project.id') && !projectIds.includes(position.project.id)) {
    logger.warn('Security risk', { user: user.id, position: position.id });

    return true;
  }

  return false;
};
