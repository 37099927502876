import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
// components
import Chip from 'components/atoms/Chip';
import Loader from 'components/atoms/Loader';
import Modal from 'components/molecules/Modal';
import Placeholder from 'components/atoms/Placeholder';
import FileHistory from './FileHistory';
import RateHistory from './RateHistory';
// helpers
import { parseData } from './helpers';
// constants
import { FILES_HISTORY, RATES_HISTORY } from './constants';
// styles
import './historyModal.scss';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  query: PropTypes.object.isRequired,
  variables: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string,
};

const defaultProps = {
  title: null,
};

const HistoryModal = props => {
  const { isOpen, title, onClose, type, query, variables } = props;
  const { t } = useTranslation();

  const { data, error, loading } = useQuery(query, {
    variables,
  });
  const versions = parseData(data, type);

  const getContent = () => {
    if (FILES_HISTORY.includes(type)) {
      return <FileHistory className="m-t-20" versions={versions} />;
    }
    if (RATES_HISTORY.includes(type)) {
      return <RateHistory className="m-t-20" versions={versions} />;
    }
    return null;
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header>
        {title ? `${title} ` : ''}
        {t('history')}
      </Modal.Header>
      <Modal.Body>
        {loading && (
          <div className="space-30">
            <Loader />
          </div>
        )}
        {/* TODO: Finish styling when Design Guidelines for error states are completed  */}
        {!loading && error && <div>{t('errorWhileLoadingHistory')}</div>}
        {!loading && !error && (
          <div className="p-t-20 p-b-30">
            {versions.length > 0 ? (
              <React.Fragment>
                <Chip label={t('versionCount', { count: versions.length })} color="blue" />
                {getContent()}
              </React.Fragment>
            ) : (
              <Placeholder size="lg" icon="empty">
                {t('noPreviousHistoryToView')}
              </Placeholder>
            )}
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

HistoryModal.propTypes = propTypes;
HistoryModal.defaultProps = defaultProps;

export default HistoryModal;
