/* eslint-disable import/order */
import React from 'react';
// libraries
import { useQuery } from '@apollo/client';
import getProperty from 'lodash/get';
import queryString from 'query-string';
import { useHistory, useLocation, useParams } from 'react-router-dom';
// hooks
import useAuth from 'hooks/useAuth';
// components
import ContentLoader from 'components/atoms/ContentLoader';
import { ProposalSettings } from 'components/organisms/SubmissionSettings';
import { ProposalMainInfo } from 'components/organisms/SubmissionMainInfo';
import ErrorPage from 'components/pages/ErrorPage';
import DetailPage from 'components/templates/DetailPage';
import ProposalContent from './ProposalContent';
// constants
import { SUBMISSION_TAB } from 'constants/pageTabs';
import { SUBMISSION_TYPE } from 'constants';
// helpers
import parseProposal from 'helpers/ProposalParser';
import parsePosition from 'helpers/PositionParser';
import { getTabs, isUnauthorizedAccess } from './helpers';
// query
import GET_PROPOSAL from './query';

const ProposalPage = () => {
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams();
  const user = useAuth();

  const query = queryString.parse(location.search);
  const currentTab = getProperty(query, 'tab', SUBMISSION_TAB.DETAIL);

  const navigateToTab = newTab => {
    history.push(`${location.pathname}?tab=${newTab}`);
  };

  const { loading, error, data } = useQuery(GET_PROPOSAL, {
    variables: {
      id,
    },
  });

  if (loading) {
    return <ContentLoader type={SUBMISSION_TYPE} />;
  }

  if (!data.proposal || isUnauthorizedAccess(data.proposal, user)) {
    return <ErrorPage code={404} />;
  }

  if (error) {
    return <ErrorPage code={500} />;
  }

  const proposal = parseProposal(data.proposal);
  const position = parsePosition(proposal.data.position);
  const tabs = getTabs(proposal.hasOnboarding());

  return (
    <DetailPage>
      <DetailPage.Aside>
        <ProposalMainInfo proposal={proposal} position={position} />
      </DetailPage.Aside>
      <DetailPage.Body>
        <DetailPage.Navigation tabs={tabs} activeTab={currentTab} onSwitch={navigateToTab}>
          <ProposalSettings
            hideSecondaryMenuItems
            position={position}
            proposal={proposal}
            actionButton
          />
        </DetailPage.Navigation>
        <DetailPage.Content>
          <ProposalContent tab={currentTab} proposal={proposal} position={position} />
        </DetailPage.Content>
      </DetailPage.Body>
    </DetailPage>
  );
};

export default ProposalPage;
