import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// helpers
import { prettyNumber } from 'helpers/Number';
import { getYear } from 'helpers/RelativeTimeFormatter';
import { getCurrencyText } from 'helpers/NameMapping';
// styles
import styles from './rates.module.scss';

const propTypes = {
  rates: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      startDate: PropTypes.string.isRequired,
      currency: PropTypes.string.isRequired,
    })
  ).isRequired,
  hideYears: PropTypes.bool,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
  hideYears: false,
};

const Rates = ({ rates, hideYears, className }) => {
  return (
    <div className={classNames(styles.rates, { [className]: className })}>
      {rates.map((rate, index) => (
        <div key={rate.startDate} className={classNames(styles.rate, { 'm-l-5': index })}>
          {!hideYears && <div className={styles.year}>{getYear(rate.startDate)}</div>}
          <div>
            {prettyNumber(rate.value)} {getCurrencyText(rate.currency)}
          </div>
        </div>
      ))}
    </div>
  );
};

Rates.propTypes = propTypes;
Rates.defaultProps = defaultProps;

export default Rates;
