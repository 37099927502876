import React from 'react';
// libraries
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import getProperty from 'lodash/get';
// hooks
import useAuth from 'hooks/useAuth';
// constants
import { STAFF_ROLE } from 'constants';
import { ONBOARDING_CANCELLED_TYPES } from './constants';
// components
import NotifyPM from './NotifyPM';
import TextContent from '../TextContent';

const propTypes = {
  type: PropTypes.oneOf(Object.values(ONBOARDING_CANCELLED_TYPES)),
  reason: PropTypes.string,
  subject: PropTypes.string,
  submission: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
};

const defaultProps = {
  type: ONBOARDING_CANCELLED_TYPES.CANDIDATE_WITHDRAWN,
  reason: '',
  subject: '',
};

const OfferDeclined = ({ type, reason, subject, submission, position }) => {
  const { t } = useTranslation();
  const { role } = useAuth();
  const withdrawalNotified = getProperty(submission, 'hiringProcess.withdrawalNotified', false);

  return (
    <React.Fragment>
      <TextContent
        title={t('onboardingCancelled')}
        label={
          (subject || reason) && (
            <span>
              <b>
                {subject}
                {reason ? ':' : ''}
              </b>
              <br />
              {reason}
            </span>
          )
        }
      />
      {role === STAFF_ROLE &&
        type !== ONBOARDING_CANCELLED_TYPES.START_DATE_REJECTED_BY_PM &&
        !withdrawalNotified && (
          <NotifyPM type={type} reason={reason} submission={submission} position={position} />
        )}
    </React.Fragment>
  );
};

OfferDeclined.propTypes = propTypes;
OfferDeclined.defaultProps = defaultProps;

export default OfferDeclined;
