// libraries
import hasProperty from 'lodash/has';
import getProperty from 'lodash/get';
// helpers
import { isOnboardingFormsStepFinished } from 'helpers/Onboarding';
import { getSubmissionLink } from 'helpers/Link';
// constants
import { SUBMISSION_STATUS_TYPE } from 'constants/submissionStatus';
import { SUBMISSION_TAB } from 'constants/pageTabs';
import { START_DATE_TYPE, ONBOARDING_STATUS_FORMS } from 'constants/onboarding';
import {
  ACTION_INACTIVE,
  ACTION_PENDING,
  ACTION_OWN_PENDING,
  ACTION_FINISHED,
  ACTION_CANCELLED,
} from 'constants';
import TABS_OPTION from '../tabOptions';

const parseStep = (submission, step, role) => {
  const status = submission.findStatus(step.id);
  const cancelledStatus = getProperty(submission, 'hiringProcess.cancelledStep.name');

  if (status) {
    if (status.value === cancelledStatus) {
      return { type: ACTION_CANCELLED, start: status.start };
    }
    if (status.end) {
      if (status.value === ONBOARDING_STATUS_FORMS.SEND_TO_PM) {
        if (isOnboardingFormsStepFinished(submission)) {
          return { type: ACTION_FINISHED, start: status.end };
        }
      } else {
        return { type: ACTION_FINISHED, start: status.end };
      }
    }
    if (status.start && !cancelledStatus) {
      return {
        type: role === step.role ? ACTION_OWN_PENDING : ACTION_PENDING,
      };
    }
  }

  return { type: ACTION_INACTIVE };
};

export const generateTabs = (submission, position, role) => {
  const hiringProcess = position.getConfig('onboarding', []);

  return hiringProcess.map(({ type }) => {
    const tab = TABS_OPTION[type];
    const steps = tab.steps.map(step => {
      const parsedStep = parseStep(submission, step, role);

      return {
        id: step.id,
        label: step.label,
        role: step.role,
        type: parsedStep.type,
        start: parsedStep.start,
        finished: parsedStep.type === ACTION_FINISHED,
      };
    });

    return {
      id: tab.id,
      icon: tab.icon,
      label: tab.label,
      finished: steps.every(step => step.finished),
      finishedStepsCount: steps.reduce((count, step) => {
        return step.type === ACTION_FINISHED ? count + 1 : count;
      }, 0),
      steps,
    };
  });
};

export const findCancelledStep = submission => {
  return getProperty(submission, 'hiringProcess.cancelledStep.name');
};

export const isTabFinished = (tabs, tabId) => {
  const tab = tabs.find(({ id }) => id === tabId);

  return tab.finished;
};

export const getStartDate = (submission, status, type = START_DATE_TYPE.SUPPLIER) => {
  const dates = getProperty(submission, 'hiringProcess.dates.items', []);

  return dates.find(date => date.status === status && date.type === type);
};

export const checkRequired = (formData, fields) => {
  return (
    fields.filter(
      reqField =>
        getProperty(reqField, 'validate.required') &&
        (!getProperty(formData, `${reqField.key}`) ||
          (hasProperty(formData, `${reqField.key}.value`) &&
            !getProperty(formData, `${reqField.key}.value`)) ||
          getProperty(formData, `${reqField.key}.requiredError`))
    ).length === 0
  );
};

/**
 * @param {object} submission
 * @param {string} positionStartDate
 * @returns {string}
 */
export const parseExpectedPositionStartDate = (submission, positionStartDate) => {
  const newStartDate = getProperty(
    getProperty(submission, 'hiringProcess.dates.items', []).find(
      date => date.type === START_DATE_TYPE.EXPECTED
    ),
    'date'
  );

  return newStartDate || positionStartDate;
};

/**
 * Parse email template data from submission
 * @param {object} submission
 * @param {object} position
 * @param {object} user Logged in user
 * @param {string} tab Tab type from './Constants.js' tab types
 * @returns {object}
 */
export const parseEmailTemplateData = (submission, position, user, tab) => {
  const { title, rfq, project } = position;
  const projectManager = position.getProjectManager();
  const link = getSubmissionLink(submission.id, submission.isProposal(), {
    queryParams: { tab: SUBMISSION_TAB.ONBOARDING },
  });

  return {
    manager: getProperty(projectManager, 'name', ''),
    managerEmail: getProperty(projectManager, 'email', ''),
    candidate: submission.getName(),
    project: project.name,
    rfq,
    position: title,
    company: user.company,
    matchManager: user.name,
    link: tab ? `${link}&step=${tab}` : link,
  };
};

const findTabIndex = (statuses, tabs, role) => {
  if (statuses.length > 0) {
    // filter all active steps
    const activeStatuses = statuses.filter(status => !status.end);
    if (activeStatuses.length > 0) {
      if (activeStatuses.length > 1) {
        // find active step based on role - if at least one step is pending for role
        // if there are more active steps and no active step for given role,
        // return first one based on tabs order
        return tabs.findIndex(tab =>
          activeStatuses.find(status => {
            const roleTabs = tab.steps.filter(step => step.role === role).map(step => step.id);
            return roleTabs.includes(status.name);
          })
        );
      }

      const ONBOARDING_TYPE_MAP = {
        HIRING_START_DATE: SUBMISSION_STATUS_TYPE.ONBOARDING_START_DATE,
        HIRING_ONBOARDING: SUBMISSION_STATUS_TYPE.ONBOARDING_FORMS,
        HIRING_PO: SUBMISSION_STATUS_TYPE.ONBOARDING_PO,
      };

      // if there is only one active step, return that step
      return tabs.findIndex(tab => ONBOARDING_TYPE_MAP[tab.id] === activeStatuses[0].type);
    }

    // if there is no active step and all steps were finished, return last step
    return tabs.length - 1;
  }

  // if there are no statuses started for onboarding, return first tab
  return 0;
};

export const getLatestActiveTab = (submission, tabs, role) => {
  const onboardingStatuses = submission.statuses.filter(
    status => status.type !== SUBMISSION_STATUS_TYPE.BASIC
  );
  const tabIndex = findTabIndex(onboardingStatuses, tabs, role);

  // in case tabIndex will be -1 because of return value of findIndex, return first tab
  return getProperty(tabs, `${tabIndex >= 0 ? tabIndex : 0}.id`);
};
