/* eslint-disable import/order */
import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Trans } from 'react-i18next';
// components
import Tooltip from 'components/atoms/Tooltip';
import StatusStep from './StatusStep';
// helpers
import { getTimeYear } from 'helpers/RelativeTimeFormatter';
import { getProgressValue } from './helpers';
// constants
import { STATUS_STATE } from './constants';
// style
import styles from './statusFlow.module.scss';

const propTypes = {
  statuses: PropTypes.arrayOf(
    PropTypes.shape({
      state: PropTypes.oneOf(Object.values(STATUS_STATE)).isRequired,
      label: PropTypes.string.isRequired,
      start: PropTypes.string,
    }).isRequired
  ).isRequired,
  color: PropTypes.any.isRequired,
};

const Flow = ({ statuses, color }) => {
  const progress = getProgressValue(statuses);

  return (
    <div className={styles.progress}>
      <progress className={classNames(styles.line, styles[color])} value={progress} max="100" />
      {statuses.map(({ value, label, state, start, isCompleted }) => {
        const date = getTimeYear(start);

        if ([STATUS_STATE.FINISHED, STATUS_STATE.ACTIVE].includes(state)) {
          return (
            <Tooltip key={value} title={<Trans i18nKey="statusStarted">Started {{ date }}</Trans>}>
              <div
                className={classNames(styles.step, styles[color], {
                  [styles.pending]: state === STATUS_STATE.TO_DO,
                })}
              >
                <StatusStep color={color} state={state} label={label} isCompleted={isCompleted} />
              </div>
            </Tooltip>
          );
        }

        return (
          <div className={classNames(styles.step, styles[color])} key={value}>
            <StatusStep color={color} state={state} label={label} isCompleted={isCompleted} />
          </div>
        );
      })}
    </div>
  );
};

Flow.propTypes = propTypes;

export default Flow;
