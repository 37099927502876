import React, { useEffect, useRef } from 'react';
// libraries
import PropTypes from 'prop-types';
import InfiniteScrollLib from 'react-infinite-scroll-component';
import classNames from 'classnames';
// components
import Loader from 'components/atoms/Loader';
// helpers
import { hasVerticalScrollbar } from 'helpers/HtmlDOM';
// styles
import styles from './infiniteScroll.module.scss';

const propTypes = {
  next: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  dataLength: PropTypes.number.isRequired,
  children: PropTypes.any.isRequired,
  loader: PropTypes.any,
  scrollableTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Element)]),
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
  loader: null,
  scrollableTarget: null,
};

const InfiniteScroll = ({
  next,
  hasMore,
  scrollableTarget,
  dataLength,
  children,
  loader,
  className,
}) => {
  const wrapper = useRef();

  useEffect(() => {
    // skip fist run
    if (dataLength && scrollableTarget) {
      const element =
        scrollableTarget instanceof Element
          ? scrollableTarget
          : document.getElementById(scrollableTarget);

      // if items not fullfil all the scrollable element heigh -> next items load is never called.
      // call next until the scrollable element heigh is fully filled
      if (!hasVerticalScrollbar(element) && hasMore) {
        next();
      }
    }
  }, [dataLength]);

  return (
    <div ref={wrapper} className={classNames(styles.wrapper, className)}>
      {(wrapper.current || scrollableTarget) && (
        <InfiniteScrollLib
          dataLength={dataLength}
          hasMore={hasMore}
          next={next}
          scrollableTarget={scrollableTarget || wrapper.current}
          loader={loader || <Loader className={styles.loader} fixedSize />}
        >
          {children}
        </InfiniteScrollLib>
      )}
    </div>
  );
};

InfiniteScroll.propTypes = propTypes;
InfiniteScroll.defaultProps = defaultProps;

export default InfiniteScroll;
