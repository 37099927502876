import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import getProperty from 'lodash/get';
// helpers
import { getRelativeTime, getReadableYearDateTime } from 'helpers/RelativeTimeFormatter';
// constants
import {
  MAIN_RECRUITING,
  MAIN_SELECTION,
  MAIN_ONBOARDING,
  MAIN_DELIVERY,
  MAIN_CLOSED,
  POSITION_STATUS,
} from 'constants/positionStatus';
import { BASIC_ROLE } from 'constants/roles';
// styles
import styles from './statusDate.module.scss';

const propTypes = {
  position: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
};

const StatusDate = ({ position, role }) => {
  const { t } = useTranslation();
  const status = position.getMainStatus(role);

  const getDate = () => {
    // Recruiting
    if (status.value === MAIN_RECRUITING) {
      const publishStatus = position.findStatus(POSITION_STATUS.PUBLISHED);
      const createdDate = position.created;
      const publishedDate = getProperty(publishStatus, 'start');
      const openedLabel = t('opened');
      const publishedLabel = t('published');

      if (role === BASIC_ROLE.PROJECT_MANAGER) {
        return {
          label: openedLabel,
          date: createdDate,
        };
      }
      if (role === BASIC_ROLE.MATCH_MANAGER) {
        return {
          label: position.isPublished() ? publishedLabel : openedLabel,
          date: position.isPublished() ? publishedDate : createdDate,
        };
      }
      if (role === BASIC_ROLE.SUPPLIER) {
        return {
          label: publishedLabel,
          date: publishedDate,
        };
      }
    }

    // Selection
    if (status.value === MAIN_SELECTION) {
      if (role === BASIC_ROLE.SUPPLIER) {
        return {
          label: t('due'),
          date: position.supplierDueDate,
        };
      }

      return {
        label: t('due'),
        date: position.dueDate,
      };
    }

    // Onboarding
    if (status.value === MAIN_ONBOARDING) {
      return {
        label: t('selected'),
        date: status.start,
      };
    }

    // Delivery
    if (status.value === MAIN_DELIVERY) {
      return {
        label: t('started'),
        date: status.start,
      };
    }

    // Closed
    if (status.value === MAIN_CLOSED) {
      return {
        label: t('closed'),
        date: status.start,
      };
    }

    // Cancelled
    return {
      label: t('cancelled'),
      date: status.start,
    };
  };

  const { label, date } = getDate();
  if (!date) {
    return null;
  }

  return (
    <div className={styles.statusDate}>
      {label} {getRelativeTime(date)} - {getReadableYearDateTime(date)}
    </div>
  );
};

StatusDate.propTypes = propTypes;

export default StatusDate;
