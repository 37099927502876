/* eslint-disable import/order */
import React, { useState, useContext } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// context
import ToastContext from 'context/ToastContext';
// hooks
import useAuth from 'hooks/useAuth';
// components
import Button from 'components/atoms/Button';
import PurchaseOrderTicket from './PurchaseOrderTicket';
import TextContent from '../TextContent';
import EmailModal from '../EmailModal';
// constants
import { PO_PENDING } from './constants';
import { SUBMISSION_STATUS_TYPE } from 'constants/submissionStatus';
import { ON_BOARDING_PO_CONFIRMED } from 'constants/templates';
import { ONBOARDING_TAB } from 'constants/onboarding';
// helpers
import { parseEmailTemplateData, confirmPurchaseOrder } from '../helpers';

const propTypes = {
  purchaseOrder: PropTypes.object,
  submission: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
};

const defaultProps = {
  purchaseOrder: {},
};

const ConfirmPurchaseOrder = ({ purchaseOrder, submission, position }) => {
  const [isOpen, setOpen] = useState(false);
  const { addToast } = useContext(ToastContext);
  const { t } = useTranslation();
  const user = useAuth();

  const { rfq, project } = position;
  const onboardingStatus = submission.getStatus(user.role, SUBMISSION_STATUS_TYPE.ONBOARDING_PO);
  const { managerEmail, ...templateData } = parseEmailTemplateData(
    submission,
    position,
    user,
    ONBOARDING_TAB.PO
  );

  const handlePOConfirm = async email => {
    try {
      await confirmPurchaseOrder({
        submission,
        currentOnboardingStatusId: onboardingStatus.id,
        email,
        userId: user.id,
      });
      setOpen(false);
    } catch (error) {
      addToast.error(t('errorWhileConfirmingPO'));
    }
  };

  return (
    <React.Fragment>
      <TextContent
        title={t('POisReadyForYourReview')}
        label={t('POisReadyForYourReviewDescription')}
        content={
          <div className="display-flex w100">
            <div className="horizontalCenter p-t-30">
              <React.Fragment>
                <Button label={t('confirmPO')} onClick={() => setOpen(true)} />
              </React.Fragment>
            </div>
            <PurchaseOrderTicket
              type={PO_PENDING}
              className="w100 m-t-30"
              purchaseOrder={purchaseOrder}
              candidateName={submission.getName()}
              rfq={rfq}
            />
          </div>
        }
      />
      <EmailModal
        isOpen={isOpen}
        projectId={project.id}
        title={t('confirmPurchaseOrderTitle')}
        recipient={managerEmail}
        onClose={() => setOpen(false)}
        onSubmit={handlePOConfirm}
        templateType={ON_BOARDING_PO_CONFIRMED}
        templateData={{ ...templateData, purchaseOrder: purchaseOrder.number || '' }}
      />
    </React.Fragment>
  );
};

ConfirmPurchaseOrder.propTypes = propTypes;
ConfirmPurchaseOrder.defaultProps = defaultProps;

export default ConfirmPurchaseOrder;
