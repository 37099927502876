export const LIST_TYPE = {
  POSITION: 'positionsList',
  SUBMISSION: 'submissionsList',
  DRAFT: 'draftsList',
  USER: 'alakaUsersList',
  CLIENT: 'clientsList',
  IMPORT: 'importsList',
  NOTIFICATION: 'notificationsList',
  CANDIDATES: 'candidatesList',
  CANDIDATE_MATCHES_LIST: 'candidateMatchesList',
};

export const OFFSET_PAGINATION_LIST_TYPES = [
  LIST_TYPE.POSITION,
  LIST_TYPE.SUBMISSION,
  LIST_TYPE.DRAFT,
  LIST_TYPE.USER,
  LIST_TYPE.CLIENT,
  LIST_TYPE.IMPORT,
  LIST_TYPE.CANDIDATES,
  LIST_TYPE.CANDIDATE_MATCHES_LIST,
];
