import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// helpers
import { boxFileUrl } from 'helpers/Box';
import { openLink } from 'helpers/Link';
// components
import ClickableElement from 'components/atoms/ClickableElement';
import TruncatedText from 'components/atoms/TruncatedText';
import FileIcon from 'components/atoms/FileIcon';
import Link from 'components/atoms/Link';
// styles
import styles from './document.module.scss';
import moduleStyles from '../submitCandidates.module.scss';

const propTypes = {
  boxId: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  bordered: PropTypes.bool,
};

const defaultProps = {
  disabled: false,
  bordered: false,
};

const Document = ({ boxId, filename, disabled, bordered }) => {
  const renderDocument = () => {
    return (
      <React.Fragment>
        <span className={classNames(styles.icon, { [styles.disabled]: disabled })}>
          <FileIcon fileExtension={filename} />
        </span>
        <span className={classNames(styles.filename, { [moduleStyles.disabledText]: disabled })}>
          <TruncatedText>{filename}</TruncatedText>
        </span>
      </React.Fragment>
    );
  };

  if (disabled) {
    return <div className={styles.document}>{renderDocument()}</div>;
  }

  if (bordered) {
    return (
      <ClickableElement className={styles.bordered} onClick={() => openLink(boxFileUrl(boxId))}>
        {renderDocument()}
      </ClickableElement>
    );
  }

  return (
    <Link className={styles.document} href={boxFileUrl(boxId)} disableStyle>
      {renderDocument()}
    </Link>
  );
};

Document.propTypes = propTypes;
Document.defaultProps = defaultProps;

export default Document;
