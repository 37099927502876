import { onError } from '@apollo/client/link/error';
import getProperty from 'lodash/get';
// services
import logger from 'services/Logger';
// helpers
import { refreshTokenAndRetryRequest } from '../helpers';

// Log all errors middleware
const errorLink = () =>
  // eslint-disable-next-line consistent-return
  onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      if (graphQLErrors.some(({ code }) => code === 'TokenExpiredError')) {
        return refreshTokenAndRetryRequest(operation, forward);
      }

      graphQLErrors.forEach(({ code, message, details }) => {
        logger.error(`[GraphQL ${code || ''}]: ${message}`, {
          code: code || '',
          details: details || null,
          operationName: operation.operationName,
          variables: operation.variables,
        });
      });
    }

    if (networkError) {
      logger.error(`[Network error]: ${networkError}`, {
        operationName: getProperty(operation, 'operationName'),
        variables: getProperty(operation, 'variables'),
      });
    }
  });

export default errorLink;
