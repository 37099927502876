import React from 'react';
// libraries
import getProperty from 'lodash/get';
import PropTypes from 'prop-types';
// constants
import { ONBOARDING_TAB, HIRING_PROCESS_STATUS } from 'constants/onboarding';
// components
import OnboardingForms from './OnboardingForms';
import PurchaseOrder from './PurchaseOrder';
import StartDate from './StartDate';
import CancelledProcess from './CancelledProcess';

const propTypes = {
  tab: PropTypes.string.isRequired,
  tabs: PropTypes.array.isRequired,
  submission: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
  tabDetailsOpen: PropTypes.bool,
};

const defaultProps = {
  tabDetailsOpen: true,
};

const OnboardingContent = ({ tab, tabs, tabDetailsOpen, submission, position }) => {
  const renderContent = () => {
    const isProcessCancelled =
      getProperty(submission, 'hiringProcess.status') === HIRING_PROCESS_STATUS.CANCELLED;

    if (isProcessCancelled) {
      return <CancelledProcess submission={submission} position={position} />;
    }
    if (tab === ONBOARDING_TAB.JOB_OFFER) {
      return null;
    }
    if (tab === ONBOARDING_TAB.START_DATE) {
      return <StartDate tabs={tabs} submission={submission} position={position} />;
    }
    if (tab === ONBOARDING_TAB.FORMS) {
      return <OnboardingForms submission={submission} position={position} />;
    }
    if (tab === ONBOARDING_TAB.PO) {
      return <PurchaseOrder tabs={tabs} submission={submission} position={position} />;
    }

    return null;
  };

  return (
    <div className="circleTabsContent" style={tabDetailsOpen ? { paddingTop: '10px' } : null}>
      {renderContent()}
    </div>
  );
};

OnboardingContent.propTypes = propTypes;
OnboardingContent.defaultProps = defaultProps;

export default OnboardingContent;
