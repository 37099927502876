import React, { useRef, useMemo } from 'react';
import { nanoid } from 'nanoid';
import type { ModalProps } from '@mui/material/Modal';
import MuDialog from '@mui/material/Dialog';
import DialogContext from './DialogContext';
import styles from './dialog.module.scss';

interface DialogProps {
  isOpen: boolean;
  onClose: () => void;
  loading?: boolean;
  loadingText?: string;
  disableCloseOnEscape?: boolean;
  disableCloseOnBackdropClick?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full';
  children: React.ReactNode;
}

const Dialog = ({
  isOpen,
  onClose,
  loading = false,
  loadingText = 'Loading...',
  disableCloseOnEscape = false,
  disableCloseOnBackdropClick = false,
  size = 'md',
  children,
}: DialogProps) => {
  const labelId = useRef(nanoid(8));
  const context = useMemo(
    () => ({ labelId: labelId.current, loading, loadingText, onClose }),
    [loading, loadingText, onClose]
  );

  const handleClose: ModalProps['onClose'] = (_event, reason) => {
    if (loading) {
      return;
    }

    if (reason === 'backdropClick' && disableCloseOnBackdropClick) {
      return;
    }

    if (reason === 'escapeKeyDown' && disableCloseOnEscape) {
      return;
    }

    onClose();
  };

  return (
    <MuDialog
      scroll="body"
      open={isOpen}
      aria-labelledby={labelId.current}
      fullWidth
      fullScreen={size === 'full'}
      maxWidth={size !== 'full' ? size : 'md'}
      onClose={handleClose}
      classes={{ paper: styles.container }}
      slotProps={{
        backdrop: {
          'aria-label': 'Dialog backdrop',
        },
      }}
    >
      <DialogContext.Provider value={context}>{children}</DialogContext.Provider>
    </MuDialog>
  );
};

export default Dialog;
