import React from 'react';
import classNames from 'classnames';
import type { IconType } from 'react-icons';
import CircularProgress from '@mui/material/CircularProgress';
import MuiButton from '@mui/material/Button';
import Icon from '@components/Icon';
import styles from './button.module.scss';

interface ButtonProps {
  disabled?: boolean;
  secondary?: boolean;
  loading?: boolean;
  loadingText?: string;
  color?: 'primary' | 'error' | 'warning' | 'success';
  size?: 'medium' | 'large';
  icon?: IconType;
  iconPlacement?: 'start' | 'end';
  children: React.ReactNode;
  onClick: () => void;
}

const Button = ({
  children,
  onClick,
  loading = false,
  loadingText = 'Loading...',
  disabled = false,
  secondary = false,
  color = 'primary',
  size = 'medium',
  icon,
  iconPlacement = 'start',
}: ButtonProps) => (
  <MuiButton
    disabled={disabled || loading}
    aria-describedby={loading ? 'button-loader' : ''}
    aria-busy={loading}
    variant={secondary ? 'outlined' : 'contained'}
    color={color}
    startIcon={!loading && icon && iconPlacement === 'start' ? <Icon component={icon} /> : null}
    endIcon={!loading && icon && iconPlacement === 'end' ? <Icon component={icon} /> : null}
    onClick={onClick}
    classes={{
      root: classNames(styles.container, styles[size]),
      contained: classNames(styles.contained, styles[color]),
      outlined: classNames(styles.outlined, styles[color]),
    }}
  >
    {loading ? (
      <div id="button-loader">
        <CircularProgress size={12} classes={{ root: styles.loadingSpinner }} />
        <span className={styles.loadingText}>{loadingText}</span>
      </div>
    ) : (
      children
    )}
  </MuiButton>
);

export default Button;
