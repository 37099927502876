import React from 'react';
// libraries
import MuiTooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
// constants
import { TOOLTIP_POSITION } from './constants';

const propTypes = {
  children: PropTypes.any.isRequired,
  title: PropTypes.any,
  placement: PropTypes.oneOf(Object.values(TOOLTIP_POSITION)),
  noMaxWidth: PropTypes.bool,
  noTransition: PropTypes.bool,
};

const defaultProps = {
  title: null,
  noMaxWidth: false,
  noTransition: false,
  placement: TOOLTIP_POSITION.TOP,
};

const Tooltip = ({ children, title, placement, noMaxWidth, noTransition, ...props }) => {
  const classes = makeStyles({
    tooltip: {
      maxWidth: noMaxWidth ? 'none' : 250,
    },
  })();

  if (!title) return children;

  return (
    <MuiTooltip
      placement={placement}
      disableFocusListener
      TransitionComponent={Fade}
      TransitionProps={{ timeout: noTransition ? 0 : 600 }}
      title={title}
      classes={classes}
      {...props}
    >
      {children}
    </MuiTooltip>
  );
};

Tooltip.propTypes = propTypes;
Tooltip.defaultProps = defaultProps;

export default Tooltip;
