import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// hooks
import useAuth from 'hooks/useAuth';
// helpers
import parsePosition from 'helpers/PositionParser';
import parseProposal from 'helpers/ProposalParser';
// constants
import { STAFF_ROLE, SUPPLIER_ROLE, MANAGER_ROLE } from 'constants';
// components
import ProposalRow from './ProposalRow';
// styles
import styles from './proposalTable.module.scss';

const propTypes = {
  proposals: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const ProposalTable = ({ proposals }) => {
  const { t } = useTranslation();
  const { role } = useAuth();

  if (!proposals.length) {
    return null;
  }

  const getRateLabel = () => {
    if (role === SUPPLIER_ROLE) {
      return t('submissionRate');
    }

    if (role === STAFF_ROLE) {
      return t('partnerRate');
    }

    return t('rate');
  };

  return (
    <div className={styles.table}>
      <div
        className={classNames(styles.grid, {
          [styles.partner]: role === SUPPLIER_ROLE,
          [styles.matchManager]: role === STAFF_ROLE,
          [styles.projectManager]: role === MANAGER_ROLE,
        })}
      >
        <div className={styles.head}>{t('name')}</div>
        {role === STAFF_ROLE && <div className={styles.head}>{t('partner')}</div>}
        {role === MANAGER_ROLE && <div className={styles.head}>{t('company')}</div>}
        <div className={styles.head}>{t('position')}</div>
        <div className={styles.head}>{t('positionLocation')}</div>
        <div className={classNames(styles.head, styles.alignRight)}>{getRateLabel()}</div>
        {role === STAFF_ROLE && (
          <div className={classNames(styles.head, styles.alignRight)}>{t('nteRate')}</div>
        )}
        <div className={classNames(styles.head, styles.alignRight)}>{t('submissionDate')}</div>
        <div className={classNames(styles.head, styles.alignRight)}>{t('start&endDate')}</div>
      </div>

      {proposals.map((proposal, index) => {
        const position = parsePosition(proposal.position);

        return (
          <ProposalRow
            key={proposal.id}
            proposal={parseProposal(proposal)}
            position={position}
            className={proposals.length - 1 === index ? 'border-bottom' : ''}
          />
        );
      })}
    </div>
  );
};

ProposalTable.propTypes = propTypes;

export default ProposalTable;
