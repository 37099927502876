import React, { useState, useContext } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// context
import ToastContext from 'context/ToastContext';
// components
import Button from 'components/atoms/Button';
import ButtonDropdown from 'components/molecules/ButtonDropdown';
import FileBrowser from 'components/molecules/FileBrowser';
import Document from '../Document';
// helpers
import { uploadDocument } from '../helpers';
// constants
import { DOCUMENT_TYPE } from '../constants';
// styles
import styles from './documents.module.scss';
import moduleStyles from '../submitCandidates.module.scss';

const propTypes = {
  label: PropTypes.string.isRequired,
  itemLabel: PropTypes.string.isRequired,
  onUpload: PropTypes.func.isRequired,
  onUseCurrent: PropTypes.func.isRequired,
  onRemoveSelected: PropTypes.func.isRequired,
  document: PropTypes.shape({
    boxId: PropTypes.string,
    filename: PropTypes.string.isRequired,
  }),
  folder: PropTypes.string.isRequired,
  rfq: PropTypes.string.isRequired,
  submission: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(DOCUMENT_TYPE)).isRequired,
  disabled: PropTypes.bool,
};

const defaultProps = {
  document: null,
  disabled: false,
};

const DocumentUploadBox = ({
  label,
  itemLabel,
  onUpload,
  onUseCurrent,
  onRemoveSelected,
  document,
  folder,
  rfq,
  submission,
  type,
  disabled,
}) => {
  const { t } = useTranslation();
  const { addToast } = useContext(ToastContext);
  const [loading, setLoading] = useState(false);

  const handleUpload = async file => {
    try {
      setLoading(true);
      const { filename, id } = await uploadDocument({ file, folder, rfq, submission, type });

      onUpload({
        boxId: id,
        filename,
      });
    } catch (error) {
      addToast.error(t('errorPlaceholderText'));
    } finally {
      setLoading(false);
    }
  };

  // if document exits -> display document, else display upload button
  if (document) {
    return (
      <div className={styles.releasableDocument}>
        <Document boxId={document.boxId} filename={document.filename} disabled={disabled} />
        {!disabled && <Button type="close" size={13} onClick={onRemoveSelected} iconStyle="xs" />}
      </div>
    );
  }

  return (
    <FileBrowser onUpload={handleUpload}>
      {open => (
        <div className={classNames({ [moduleStyles.disabledElement]: disabled })}>
          <ButtonDropdown
            secondary
            label={label}
            onClick={open}
            loading={loading}
            disabled={disabled}
            items={[
              {
                label: itemLabel,
                onClick: onUseCurrent,
              },
            ]}
          />
        </div>
      )}
    </FileBrowser>
  );
};

DocumentUploadBox.propTypes = propTypes;
DocumentUploadBox.defaultProps = defaultProps;

export default DocumentUploadBox;
