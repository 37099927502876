// HTMLElement
// LinkElement
import React from 'react';
// Libraries
import PropTypes from 'prop-types';
// components
import HTMLElement from 'components/atoms/HTMLElement';
import LinkElement from 'components/atoms/LinkElement';

const propTypes = {
  component: PropTypes.object,
};

const defaultProps = {
  component: {},
};

function NoInputElements(props) {
  const { component } = props;
  const { type } = component;

  switch (type) {
    case 'htmlelement':
      return (
        <HTMLElement
          content={component.content}
          className={`${component.className} form_htmlElement`}
          translation={component.properties ? component.properties.translationKey : null}
        />
      );
    case 'linkElement':
      return (
        <LinkElement
          link={component.link}
          translation={component.translation}
          className={`${component.className} htmlElement-link form_htmlElement`}
        />
      );
    default:
      return null;
  }
}

NoInputElements.propTypes = propTypes;
NoInputElements.defaultProps = defaultProps;

export default NoInputElements;
