// constants
import { POSITION_FILTERS } from 'constants/filters';
import { SUPPLIER_ROLE } from 'constants';
// helpers
import { getParsedSearchText } from 'helpers/Position';

/**
 * Format filters
 * @param {string} searchText
 * @param {object} filteredBy
 * @param {object} user
 * @param {string} user.role
 * @param {string} user.id
 * @param {string} user.supplierId
 * @param {object[]} user.projects
 * @param {object[]} user.clients
 */
export const formatFilters = (searchText, filteredBy, user) => {
  const filter = {
    location: filteredBy[POSITION_FILTERS.LOCATION],
    project: filteredBy[POSITION_FILTERS.PROJECT],
    publishStatus: filteredBy[POSITION_FILTERS.PUBLISH_STATUS],
    contractType: filteredBy[POSITION_FILTERS.CONTRACT_TYPE],
    deliveryOption: filteredBy[POSITION_FILTERS.DELIVERY_OPTION],
    dueDates: filteredBy[POSITION_FILTERS.DUE_DATE],
    statuses: filteredBy[POSITION_FILTERS.STATUS],
    submissions: filteredBy[POSITION_FILTERS.SUBMISSION],
    clearances: filteredBy[POSITION_FILTERS.CLEARANCE],
  };

  // if there's search text typed that has at least 3 characters
  if (searchText && searchText.length > 2) {
    const newSearchText = getParsedSearchText(searchText, user.clients);

    if (newSearchText.length > 2) {
      filter.fullText = newSearchText;
    }
  }

  return filter;
};

/**
 * Format sorts
 */
export const formatSorts = (sortedBy, role) => {
  switch (sortedBy.id) {
    case 'dueDate':
      return role === SUPPLIER_ROLE ? { supplierDueDate: 'DESC' } : { dueOn: 'DESC' };
    case 'title':
      return { title: 'ASC' };
    case 'project':
      return { project: { name: 'ASC' } };
    default:
      return { dueOn: 'ASC' };
  }
};
