import flatten from 'lodash/flatten';
import omit from 'lodash/omit';
// helpers
import { parseAllowedFilterOptions, parseSelectedFilterOptionsLabel } from 'helpers/Filter';
// constants
import { STAFF_ROLE } from 'constants';
import { SUBMISSION_FILTERS } from 'constants/filters';
// data
import { DUE_DATE_FILTERS, SUBMISSION_STATUS_FILTERS_MAP } from './data/filter';
import { FILTER_COMBINATION_MAP, FILTERS_WITHOUT_CONDITIONS } from './data/filterCombinations';

/**
 * Get white list filter options
 * @param {string[]} projects List user project names
 * @returns {object}
 */
export const getSubmissionFiltersWhiteList = (role, projects) => {
  const dueDateOptions = DUE_DATE_FILTERS.map(option => option.value);
  const statusOptions = flatten(
    SUBMISSION_STATUS_FILTERS_MAP[role].map(option => {
      return option.items.length
        ? [...option.items.map(subOption => subOption.value), option.value]
        : option.value;
    })
  );

  const options = {
    [SUBMISSION_FILTERS.DUE_DATE]: dueDateOptions,
    [SUBMISSION_FILTERS.STATUS]: statusOptions,
    [SUBMISSION_FILTERS.PROJECT]: projects,
    [SUBMISSION_FILTERS.LOCATION]: '*',
  };

  if (role === STAFF_ROLE) {
    options[SUBMISSION_FILTERS.PARTNER] = '*';
  }

  return options;
};

export const parseDataForCollapsedFilter = (filter, role) => {
  const candidateStatusesFilters = SUBMISSION_STATUS_FILTERS_MAP[role].map(status => {
    return status.items.length > 0 ? [status, ...status.items] : status;
  });

  const options = flatten([...DUE_DATE_FILTERS, ...candidateStatusesFilters]);

  return parseSelectedFilterOptionsLabel(filter, options);
};

/**
 * Get list of selected options
 * @param {object} selectedFilter
 * @returns {string[]}
 */
export const getSelectedFilterOptions = selectedFilter => {
  return flatten(Object.values(omit(selectedFilter, FILTERS_WITHOUT_CONDITIONS)));
};

/**
 * Get list of filter options which are allowed to select
 * @param {object} selectedFilter
 * @param {string} role
 * @returns {string[]} Array of allowed filter options
 */
export const getAllowedFilterOptions = (selectedFilter, role) => {
  const combinationsMap = FILTER_COMBINATION_MAP[role];
  // get selected options which are relevant for enabling/disabling other options
  const selectedOptions = Object.values(omit(selectedFilter, FILTERS_WITHOUT_CONDITIONS));

  return parseAllowedFilterOptions(selectedOptions, combinationsMap);
};
