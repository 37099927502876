/* eslint-disable import/order */
import React from 'react';
// Libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import getProperty from 'lodash/get';
import queryString from 'query-string';
// constants
import { TAB_NOTIFICATIONS } from 'constants';
// components
import Tabs from 'components/atoms/Tabs';
import Page from 'components/templates/Page';
import SettingsContent from './SettingsContent';
// helpers
import tabs from './Tabs';
// styles
import styles from './settingsPage.module.scss';

const TAB_DEFAULT = TAB_NOTIFICATIONS;

const SettingsPage = ({ location }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const query = queryString.parse(location.search);
  const tab = getProperty(query, 'tab', TAB_DEFAULT);

  const setCurrentTab = newTab => {
    history.push(`${location.pathname}?tab=${newTab}`);
  };

  return (
    <div className="display-flex-row h100">
      <div className="scrollableContent" id="scrollableContent">
        <Page
          headerName={t('settings')}
          noClearFilters
          noSearch
          className="h100 display-flex"
          location={location}
          pageHeaderClassName="page_header"
          content={
            <React.Fragment>
              <div className="flex1 display-flex p-t-20">
                <div className={styles.tabs}>
                  <Tabs selected={tab} onSwitch={setCurrentTab} items={tabs} />
                </div>
                <div className="page_content border-top-none p-t-40 p-b-40 flex1 display-flex">
                  <SettingsContent currentTab={tab} />
                </div>
              </div>
            </React.Fragment>
          }
        />
      </div>
    </div>
  );
};

SettingsPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default SettingsPage;
