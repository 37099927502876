import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// constants
import { LIMIT_SIZE_TABLE } from 'constants';
// components
import Placeholder from 'components/atoms/Placeholder';
import { TableLoader } from 'components/molecules/ContentLoaders';
import OffsetBasedList from 'components/molecules/OffsetBasedList';
import ProposalTable from 'components/organisms/ProposalTable';
import ErrorPage from 'components/pages/ErrorPage';
// queries
import GET_PROPOSALS from './query';

const propTypes = {
  filter: PropTypes.object.isRequired,
  sort: PropTypes.object,
};

const defaultProps = {
  sort: { score: 'DESC' },
};

const SubmissionListTable = ({ filter, sort }) => {
  const { t } = useTranslation();

  return (
    <OffsetBasedList
      query={GET_PROPOSALS}
      variables={{ filter, sort }}
      size={LIMIT_SIZE_TABLE}
      className="tableView"
    >
      {({ data, error, loading }) => {
        if (loading) {
          return <TableLoader />;
        }
        if (error) {
          return <ErrorPage code={500} />;
        }
        if (!data.length) {
          return (
            <Placeholder size="lg" icon="candidates">
              {t('noSubmissions')}
            </Placeholder>
          );
        }

        return <ProposalTable proposals={data} />;
      }}
    </OffsetBasedList>
  );
};

SubmissionListTable.propTypes = propTypes;
SubmissionListTable.defaultProps = defaultProps;

export default React.memo(SubmissionListTable);
