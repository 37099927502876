import getProperty from 'lodash/get';
import sortBy from 'lodash/sortBy';
// constants
import {
  SUPPLIER_ROLE,
  MANAGER_ROLE,
  SUPPLIER_DIRECT,
  SUPPLIER_IBM,
  SUBMITTER,
  REVIEWER,
  FILE_PROPOSAL,
  FILE_RELEASABLE_PROPOSAL,
  FILE_ATTESTATION,
  FILE_RELEASABLE_ATTESTATION,
} from 'constants';
import { COLOR } from 'constants/colors';
import { SUBMISSION_STATUS_TYPE, SUBMISSION_STATUS } from 'constants/submissionStatus';
// helpers
import { getCurrencyText } from 'helpers/NameMapping';
import { formatStatus, getBasicStatusHistoryByRole } from '../SubmissionParser/status';
import { getAvailabilityLabel } from '../SubmissionParser/label';

/**
 * @typedef {Object} Status
 * @property {string} id
 * @property {string} value
 * @property {string} label
 * @property {string} start
 * @property {string} end
 * @property {string} note
 * @property {string} author Author name
 */

/**
 * @typedef {Object} File
 * @property {string} id
 * @property {string} boxId
 * @property {string} filename
 * @property {string} type
 * @property {string} createdAt
 * @property {string} author
 */

/**
 * @typedef {Object} Note
 * @property {string} id
 * @property {string} type
 * @property {string} text
 * @property {string} author
 */

const formatFile = file => ({
  id: file.id,
  boxId: file.boxId,
  filename: file.name,
  type: file.type,
  createdAt: file.createdAt,
  author: getProperty(file, 'author.person.fullName', ''),
});

const formatNote = note => ({
  id: note.id,
  type: note.noteType,
  text: note.note,
  date: note.createdAt,
  author: getProperty(note, 'author.person.fullName', ''),
});

class Proposal {
  constructor(data) {
    // TODO: list and format all values manually like in position parser
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(data)) {
      this[key] = value;
    }

    const submissionRoles = getProperty(data, 'roles.items', []);
    const submitter = submissionRoles.find(submissionRole => submissionRole.role === SUBMITTER);
    const reviewer = submissionRoles.find(submissionRole => submissionRole.role === REVIEWER);
    const availability = getProperty(data, 'availability.leadTimeFromSelection', '');

    this.data = data;
    this.id = getProperty(data, 'id', '');
    this.name = getProperty(data, 'name', '');
    this.email = getProperty(data, 'email', '');
    this.phone = getProperty(data, 'phone', '');
    this.submissionDate = getProperty(data, 'submissionDate', '');
    this.statuses = getProperty(data, 'statuses.items', []);
    this.documents = getProperty(data, 'documents.items', []);
    this.rates = getProperty(data, 'rates.items', []);
    this.clearance = {
      type: getProperty(data, 'clearance.type.name', ''),
      validUntil: getProperty(data, 'clearance.validUntil', ''),
      note: getProperty(data, 'clearance.notes.items.0.note', ''),
    };
    this.partner = {
      id: getProperty(data, 'supplier.id', ''),
      name: getProperty(data, 'supplier.company.name', ''),
      category: getProperty(data, 'supplier.category', ''),
      includeCSAFee: getProperty(data, 'supplier.includeCSAFee', false),
    };
    this.availability = {
      value: availability,
      label: getAvailabilityLabel(availability),
      start: getProperty(data, 'availability.start', ''),
    };
    this.submittedBy = {
      id: getProperty(submitter, 'user.id', ''),
      name: getProperty(submitter, 'user.person.fullName', ''),
      email: getProperty(submitter, 'user.user.email', ''),
    };
    this.reviewedBy = {
      id: getProperty(reviewer, 'user.id', ''),
      name: getProperty(reviewer, 'user.person.fullName', ''),
      email: getProperty(reviewer, 'user.user.email', ''),
      company: getProperty(reviewer, 'user.organization.company.name', ''),
    };
  }

  /**
   * Get proposal name
   * @returns {string}
   */
  getName = () => {
    return this.name;
  };

  /**
   * Get proposal email
   * @returns {string}
   */
  getEmail = () => {
    return this.email;
  };

  /**
   * Get proposal phone
   * @returns {string}
   */
  getPhone = () => {
    return this.phone;
  };

  /**
   * Get proposal clearance
   * @returns {string}
   */
  getClearance = () => {
    return this.clearance;
  };

  /**
   * Find status (even if status is already finished)
   * @param {string} status
   * @param {string} [role]
   * @return {?Status}
   */
  findStatus = (status, role) => {
    const proposalStatus = this.statuses.find(({ name }) => name === status);

    return proposalStatus ? formatStatus(proposalStatus, role) : null;
  };

  /**
   * Get current status
   * @param {string} role
   * @param {string} [type] Status type
   * @returns {?Status} Current status
   */
  getStatus = (role, type = SUBMISSION_STATUS_TYPE.BASIC) => {
    const currentStatus = this.statuses.find(status => status.type === type && !status.end);

    return currentStatus ? formatStatus(currentStatus, role) : null;
  };

  /**
   * Proposal has status?
   * @param {string|string[]} status Submission status
   * @param {boolean} [filterByCurrentStatus=true] Check only submissions' current status
   * @returns {boolean}
   */
  hasStatus = (status, filterByCurrentStatus = true) => {
    const searchingStatuses = [].concat(status);

    if (filterByCurrentStatus) {
      const currentStatus = this.getStatus();

      return searchingStatuses.includes(currentStatus.value);
    }

    return this.statuses.some(({ name: submissionStatus }) =>
      searchingStatuses.includes(submissionStatus)
    );
  };

  /**
   * Get history of basic statuses
   * @param {string} role
   * @returns {Status[]}
   */
  getBasicStatusHistory = role => {
    return getBasicStatusHistoryByRole(this.statuses, role);
  };

  /**
   * Get color for status by role
   * @param {string} role
   * @returns {string} - Color
   */
  getColor = role => {
    const currentStatus = getProperty(this.getStatus(role), 'value', '');

    const statusMap = {
      [SUBMISSION_STATUS.NEW]: COLOR.RED,
      [SUBMISSION_STATUS.SUBMITTED]: {
        [MANAGER_ROLE]: COLOR.RED,
        default: COLOR.YELLOW,
      },
      [SUBMISSION_STATUS.OFFER]: COLOR.GREEN,
      [SUBMISSION_STATUS.ONBOARDING]: COLOR.GREEN,
      [SUBMISSION_STATUS.ONBOARDING_CANCELLED]: COLOR.GRAY,
      [SUBMISSION_STATUS.NOT_SUBMITTED]: COLOR.GRAY,
      [SUBMISSION_STATUS.NOT_SELECTED]: COLOR.GRAY,
      [SUBMISSION_STATUS.WITHDRAWN]: COLOR.GRAY,
      [SUBMISSION_STATUS.INTERVIEW]: COLOR.BLUE,
      [SUBMISSION_STATUS.DELIVERY]: COLOR.BLUE,
      [SUBMISSION_STATUS.OFF_BOARDING]: COLOR.GRAY,
      [SUBMISSION_STATUS.OFF_BOARDED]: COLOR.GRAY,
    };

    return (
      getProperty(statusMap, [currentStatus, role]) ||
      getProperty(statusMap, [currentStatus, 'default']) ||
      getProperty(statusMap, currentStatus, '')
    );
  };

  /**
   * Find document by type
   * @param {string} type
   * @returns {?File}
   */
  getDocument = type => {
    const file = this.documents.find(document => document.type === type);

    if (file) {
      return formatFile(file);
    }

    return null;
  };

  /**
   * Find documents by type
   * @param {string} [type] If not provided, all proposal documents are returned
   * @returns {File[]}
   */
  getDocuments = type => {
    if (!type) {
      return this.documents.map(formatFile);
    }

    return this.documents.filter(document => document.type === type).map(formatFile);
  };

  /**
   * Get proposal file based on role
   * @param {string} role
   * @returns {?File}
   */
  getSubmissionFile = role => {
    const proposal = this.getDocument(FILE_PROPOSAL);
    const releasableProposal = this.getDocument(FILE_RELEASABLE_PROPOSAL);

    if (role === SUPPLIER_ROLE) {
      return proposal;
    }

    // releasable proposal has higher priority
    return releasableProposal || proposal;
  };

  /**
   * Get attestation file based on role
   * @param {string} role
   * @returns {?File}
   */
  getAttestation = role => {
    const attestation = this.getDocument(FILE_ATTESTATION);
    const releasableAttestation = this.getDocument(FILE_RELEASABLE_ATTESTATION);

    if (role === SUPPLIER_ROLE) {
      return attestation;
    }

    // releasable attestation has higher priority
    return releasableAttestation || attestation;
  };

  /**
   * Get rates
   * @param {string} type Rate type
   * @returns {object[]}
   */
  getRates = type => {
    return sortBy(
      this.rates
        .filter(({ rateType }) => rateType === type)
        .map(rate => ({ ...rate, year: new Date(rate.startDate).getFullYear() })),
      'startDate'
    );
  };

  /**
   * Get rate by type for given year
   * @param {string} type Rate type
   * @param {number} [year] Rate for given year. By default it uses current year or 1st rate if rate for current year is not found
   * @returns {?object}
   */
  getRate = (type, year) => {
    const rates = this.getRates(type);
    const rateYear = year || new Date().getFullYear();
    const foundRate = rates.find(rate => new Date(rate.startDate).getFullYear() === rateYear);
    const rate = foundRate || getProperty(rates, '0');

    return rate ? { ...rate, currencyLabel: getCurrencyText(rate.currency) } : null;
  };

  /**
   * Find note by type
   * @param {string} type
   * @returns {?Note}
   */
  getNote = type => {
    const note = getProperty(this.data, 'notes.items', []).find(
      ({ noteType }) => noteType === type
    );

    if (note) {
      return formatNote(note);
    }

    return null;
  };

  /**
   * Find onboarding document by type
   * @param {string} type
   * @returns {File}
   */
  getOnboardingDocument = type => {
    const documents = getProperty(this.data, 'hiringProcess.files.items', []);
    const file = documents.find(document => document.type === type);

    if (file) {
      return formatFile(file);
    }

    return null;
  };

  /**
   * Find onboarding documents
   * @param {string} [type]
   * @returns {File}
   */
  getOnboardingDocuments = type => {
    const documents = getProperty(this.data, 'hiringProcess.files.items', []);

    if (type) {
      return documents.filter(document => document.type === type).map(formatFile);
    }

    return documents.map(formatFile);
  };

  /**
   * Submitted by direct partner?
   * @returns {boolean}
   */
  isDirectPartner = () => {
    return [SUPPLIER_DIRECT, SUPPLIER_IBM].includes(this.partner.category);
  };

  /**
   * Was proposal in onboarding state ever
   * @returns {boolean}
   */
  hasOnboarding = () => {
    const offerStatus = this.findStatus(SUBMISSION_STATUS.OFFER);

    return !!offerStatus;
  };

  isRejectable = role => {
    const currentStatus = this.getStatus(role);

    return ![
      SUBMISSION_STATUS.DELIVERY,
      SUBMISSION_STATUS.WITHDRAWN,
      SUBMISSION_STATUS.NOT_SUBMITTED,
      SUBMISSION_STATUS.NOT_SELECTED,
      SUBMISSION_STATUS.ONBOARDING_CANCELLED,
    ].includes(currentStatus.value);
  };

  isProposal = () => {
    return true;
  };
}

/**
 * Parse and normalize proposal data from 8base
 * @param {object} data Proposal data from 8base
 * @returns {Proposal}
 */
const parseProposal = data => new Proposal(data);

export default parseProposal;
