import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// components
import ClickableElement from 'components/atoms/ClickableElement';
import Icon from 'components/atoms/Icon';
// styles
import styles from './iconDropdown.module.scss';

const propTypes = {
  onClick: PropTypes.func.isRequired,
};

const ActionButton = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <ClickableElement onClick={onClick} className={styles.actionButton}>
      <div className={styles.actionButtonLabel}>{t('actions')}</div>
      <Icon name="more" size={20} />
    </ClickableElement>
  );
};

ActionButton.propTypes = propTypes;

export default ActionButton;
