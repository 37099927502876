import React, { useEffect, useRef } from 'react';
// libraries
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.element.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

/**
 * Component that alerts if you click outside of it
 */
const OutsideDetector = ({ children, onClick, className }) => {
  const wrapperRef = useRef();

  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      onClick();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <div ref={wrapperRef} className={className}>
      {children}
    </div>
  );
};

OutsideDetector.propTypes = propTypes;
OutsideDetector.defaultProps = defaultProps;

export default OutsideDetector;
