import { gql } from '@apollo/client';
import getProperty from 'lodash/get';
// services
import client from 'services/Client';
import logger from 'services/Logger';
import { getMatchMetrics } from 'services/Report';
// queries
import { MatchMetric, MatchReport, File } from 'queries/Fragments';

const FETCH_DATA_FOR_COMMIT_CV = gql`
  query fetchDataForCommitCV($id: ID!, $reportId: ID!) {
    submission(id: $id) {
      id
      reports(sort: { createdAt: DESC }, first: 1) {
        items {
          ...MatchReport
        }
      }
      candidate {
        id
      }
      metrics {
        items {
          id
        }
      }
      position {
        id
        level {
          id
          shLevel
        }
        shOccupation
        project {
          id
          shProjectId
        }
      }
    }
    pendingReport(id: $reportId) {
      id
      score
      shPersonId
      shCandidateId
      positionReport {
        id
      }
      cv {
        id
      }
    }
  }
  ${MatchReport}
`;

const COMMIT_NEW_CV = gql`
  mutation commitNewCV($submissionId: ID!, $data: SubmissionUpdateInput!) {
    submissionUpdate(filter: { id: $submissionId }, data: $data) {
      id
      score
      documents(sort: { createdAt: DESC }) {
        items {
          ...File
        }
      }
      reports(sort: { createdAt: DESC }) {
        items {
          ...MatchReport
        }
      }
      metrics {
        items {
          ...MatchMetric
        }
      }
    }
  }
  ${File}
  ${MatchMetric}
  ${MatchReport}
`;

/**
 * @param {string} submissionId
 * @param {string} pendingReportId
 * @param {string} supplierId
 * @returns {Promise}
 */
const commitNewCv = async (submissionId, pendingReportId, supplierId) => {
  try {
    const response = await client.query({
      query: FETCH_DATA_FOR_COMMIT_CV,
      variables: { id: submissionId, reportId: pendingReportId },
    });

    const submission = getProperty(response, 'data.submission');
    const report = getProperty(response, 'data.pendingReport');
    const position = getProperty(response, 'data.submission.position');
    const candidateId = getProperty(submission, 'candidate.id');
    const metricId = getProperty(submission, 'metrics.items.0.id');
    const shPersonId = getProperty(report, 'shPersonId');
    const positionReportId = getProperty(report, 'positionReport.id');
    const newShCandidateId = getProperty(report, 'shCandidateId');
    const newCVId = getProperty(report, 'cv.id');
    const newScore = getProperty(report, 'score');
    const shOccupation = getProperty(position, 'shOccupation') || '';
    const filter = {
      projectId: getProperty(position, 'project.id'),
      occupation: shOccupation,
    };

    const newMetrics = shOccupation
      ? await getMatchMetrics(filter, newScore, newShCandidateId)
      : null;

    const data = {
      score: newScore,
      documents: { connect: { id: newCVId } },
      reports: {
        create: {
          score: newScore,
          supplier: { connect: { id: supplierId } },
          positionReport: { connect: { id: positionReportId } },
          candidateReport: {
            create: {
              cv: { connect: { id: newCVId } },
              shPersonId,
              shCandidateId: newShCandidateId,
              candidate: { connect: { id: candidateId } },
            },
          },
        },
      },
    };

    // has existing metrics -> update percentile
    if (metricId && newMetrics && newMetrics.percentile) {
      data.metrics = {
        update: { filter: { id: metricId }, data: { percentile: newMetrics.percentile } },
      };
    }

    await client.mutate({
      mutation: COMMIT_NEW_CV,
      variables: { submissionId, data },
    });
  } catch (error) {
    logger.exception(error, { submissionId, pendingReportId });
    throw error;
  }
};

export default commitNewCv;
