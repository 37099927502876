import React, { useContext } from 'react';
// libraries
import PropTypes from 'prop-types';
import i18n from 'i18next';
import classNames from 'classnames';
// components
import Button from 'components/atoms/Button';
// context
import ModalContext from './ModalContext';
// styles
import styles from './modalWindow.module.scss';

const propTypes = {
  primaryLabel: PropTypes.string.isRequired,
  primaryAction: PropTypes.func.isRequired,
  primaryDisabled: PropTypes.bool,
  secondaryLabel: PropTypes.string,
  secondaryAction: PropTypes.func,
  className: PropTypes.string,
};

const defaultProps = {
  primaryDisabled: false,
  secondaryLabel: i18n.t('cancel'),
  secondaryAction: null,
  className: '',
};

const Buttons = ({
  primaryLabel,
  primaryAction,
  primaryDisabled,
  secondaryLabel,
  secondaryAction,
  className,
}) => {
  const { onClose } = useContext(ModalContext);

  return (
    <div className={classNames(styles.buttons, className)}>
      <div className="m-r-12">
        <Button label={secondaryLabel} onClick={secondaryAction || onClose} secondary />
      </div>
      <Button label={primaryLabel} onClick={primaryAction} disabled={primaryDisabled} />
    </div>
  );
};

Buttons.propTypes = propTypes;
Buttons.defaultProps = defaultProps;

export default Buttons;
