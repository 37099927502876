/**
 * Get regex for validation
 * @param {bool} negative
 * @param {bool} decimalLimit
 * @returns {string}
 */
const getRegex = (negative, decimalLimit) => {
  const DECIMALS = `^\\d+\\.?\\d{0,${decimalLimit}}$`;
  const DECIMALS_NEG = `^-?\\d+\\.?\\d{0,${decimalLimit}}$`;
  const INTEGERS = '^\\d+$';
  const INTEGERS_NEG = '^-?\\d+$';

  if (negative) {
    return decimalLimit ? DECIMALS_NEG : INTEGERS_NEG;
  }

  return decimalLimit ? DECIMALS : INTEGERS;
};

/**
 * @param {number} value
 * @param {object} config
 * @param {bool} config.allowNegative
 * @param {bool} config.decimalLimit
 * @param {bool} config.min
 * @param {bool} config.max
 * @returns {bool}
 */
export const isValid = (value, { allowNegative, decimalLimit, min, max }) => {
  const regex = getRegex(allowNegative, decimalLimit);
  const parsedValue = value ? value.toString() : '';

  if (value !== 0 && !new RegExp(regex).test(parsedValue)) {
    return false;
  }

  if (min !== null && Number(value) < min) {
    return false;
  }

  if (max !== null && Number(value) > max) {
    return false;
  }

  return true;
};
