import i18n from 'i18next';
// helpers
import { getStatusText } from 'helpers/NameMapping';
// constants
import { SUBMISSION_STATUS } from 'constants/submissionStatus';

export const FILTER_SUBMITTED = 'submitted';
export const FILTER_REJECTED = 'rejected';

const options = [
  {
    label: i18n.t('all'),
    value: '',
  },
  {
    label: getStatusText(SUBMISSION_STATUS.NEW),
    value: SUBMISSION_STATUS.NEW,
  },
  {
    label: i18n.t('rejected'),
    value: FILTER_REJECTED,
  },
  {
    label: i18n.t('submitted'),
    value: FILTER_SUBMITTED,
  },
];

export default options;
