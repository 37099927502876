import React, { useMemo } from 'react';
import { MdDelete, MdEdit } from 'react-icons/md';
import LoadingWrapper from '@components/LoadingWrapper';
import Chip, { ChipProps } from '@components/Chip';
import IconButton from '@components/IconButton';
import TruncatedText from '@components/TruncatedText';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableBodySkeleton,
} from '@components/Table';
import { formatDateTime } from '@utils/formatDate';
import { SupplierRecord, SupplierStatus } from './types';
import styles from './suppliersPage.module.scss';

interface RecordsTableProps {
  items: SupplierRecord[];
  loading: boolean;
  onEdit: (item: SupplierRecord) => void;
  onDelete: (item: SupplierRecord) => void;
}

const STATUS_COLOR: Record<SupplierStatus, ChipProps['color']> = {
  active: 'green',
  deleted: 'red',
};

const RecordsTable = ({ items, loading, onEdit, onDelete }: RecordsTableProps) => {
  const classes = useMemo(
    () => ({
      root: styles.table,
      row: styles.row,
    }),
    []
  );

  return (
    <LoadingWrapper loading={loading} ariaLabel="Loading imports..">
      <Table classes={classes}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Supplier Type</TableCell>
            <TableCell>CSA Fee paid by</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>
              <span>&nbsp;</span>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableBodySkeleton columns={6} />
          ) : (
            items.map((item) => (
              <TableRow key={item.id}>
                <TableCell>
                  <TruncatedText>{item.name}</TruncatedText>
                </TableCell>
                <TableCell>{formatDateTime(item.createdAt)}</TableCell>
                <TableCell>
                  <Chip color={item.category === 'direct' ? 'green' : 'gray'} size="medium">
                    {item.category === 'direct' ? 'Direct' : 'Not Direct'}
                  </Chip>
                </TableCell>
                <TableCell>
                  <Chip color={item.includeCSAFee ? 'blue' : 'yellow'} size="medium">
                    {item.includeCSAFee ? 'IBM' : 'Supplier'}
                  </Chip>
                </TableCell>
                <TableCell>
                  <Chip color={STATUS_COLOR[item.status]} size="medium">
                    {item.status === 'active' ? 'Active' : 'Deleted'}
                  </Chip>
                </TableCell>
                <TableCell align="right">
                  {item.status === 'active' && (
                    <React.Fragment>
                      <IconButton
                        size="small"
                        label="Edit"
                        icon={MdEdit}
                        className={styles.editSupplierButton}
                        onClick={() => onEdit(item)}
                      />
                      <IconButton
                        size="small"
                        label="Delete"
                        icon={MdDelete}
                        onClick={() => onDelete(item)}
                      />
                    </React.Fragment>
                  )}
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </LoadingWrapper>
  );
};

export default RecordsTable;
