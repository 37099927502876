import React from 'react';
// libraries
import PropTypes from 'prop-types';

const propTypes = {
  content: PropTypes.any,
  color: PropTypes.string,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
};

const defaultProps = {
  content: null,
  color: 'grayLight',
  isFirst: true,
  isLast: true,
};

const Arrow = ({ content, color, isFirst, isLast }) => {
  return (
    <div
      className={`arrowBasic arrow${isFirst ? '-first' : ''}${isLast ? '-last' : ''} ${color}`}
      style={isLast ? { marginRight: 0 } : null}
    >
      {content}
    </div>
  );
};

Arrow.propTypes = propTypes;
Arrow.defaultProps = defaultProps;

export default Arrow;
