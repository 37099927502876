import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import getProperty from 'lodash/get';
// hooks
import useAuth from 'hooks/useAuth';
// constants
import { COLOR_RED, COLOR_YELLOW, COLOR_GREEN, COLOR_BLUE, COLOR_GRAY } from 'constants/colors';
import {
  MAIN_RECRUITING,
  MAIN_SELECTION,
  MAIN_ONBOARDING,
  MAIN_DELIVERY,
  MAIN_CLOSED,
  MAIN_CANCELLED,
  POSITION_STATUS,
} from 'constants/positionStatus';
import { POSITION_TAB, SUBMISSION_TAB } from 'constants/pageTabs';
import { SUBMISSION_STATUS } from 'constants/submissionStatus';
import { STAFF_ROLE, SUPPLIER_ROLE, MANAGER_ROLE } from 'constants';
// helpers
import { getSubmissionLink } from 'helpers/Link';
import { filterSubmissionsByStatus } from 'helpers/Submission';
import { capitalizeText } from 'helpers/StringParser';
import { filterAndSortSubmissions } from './helpers';
// components
import AudienceBox from './AudienceBox';

const propTypes = {
  position: PropTypes.object.isRequired,
};

const Audience = ({ position }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { role, supplierId } = useAuth();
  const { value: mainStatus } = position.getMainStatus(role, supplierId);
  const { value: status } = position.getStatus(role, supplierId);
  const submissions = position.getSubmissions(role, supplierId);

  const redirectToPositionCandidates = event => {
    event.preventDefault();

    if (mainStatus === MAIN_ONBOARDING && status !== POSITION_STATUS.ONBOARDING_CANCELLED) {
      const onboardingCandidates = filterSubmissionsByStatus(submissions, [
        SUBMISSION_STATUS.ONBOARDING,
        SUBMISSION_STATUS.OFFER,
      ]);
      const onboardingCandidate = getProperty(onboardingCandidates, '0');

      if (onboardingCandidate) {
        const link = getSubmissionLink(onboardingCandidate.id, position.isFixedPrice(), {
          absoluteUrl: false,
          queryParams: {
            tab: SUBMISSION_TAB.ONBOARDING,
          },
        });

        history.push(link);
      }
    } else {
      const candidateTab = role === STAFF_ROLE ? POSITION_TAB.RATES : POSITION_TAB.CANDIDATES;

      history.push(`/position/${position.id}?tab=${candidateTab}`);
    }
  };

  // Recruiting + Cancelled
  if ([MAIN_RECRUITING, MAIN_CANCELLED].includes(mainStatus)) {
    if (role === MANAGER_ROLE) {
      const receivedCandidates = filterAndSortSubmissions(
        submissions,
        SUBMISSION_STATUS.SUBMITTED,
        false
      );
      const color = mainStatus === MAIN_RECRUITING ? COLOR_RED : COLOR_GRAY;

      return (
        <AudienceBox
          color={color}
          label={t('submissionsReceived')}
          candidates={receivedCandidates}
          onClick={redirectToPositionCandidates}
        />
      );
    }

    // Partner + MM role
    const submittedCandidates = filterAndSortSubmissions(submissions, SUBMISSION_STATUS.NEW, false);

    return (
      <AudienceBox
        color={COLOR_RED}
        label={role === SUPPLIER_ROLE ? t('submissionsMade') : t('submissionsReceived')}
        candidates={submittedCandidates}
        onClick={redirectToPositionCandidates}
      />
    );
  }

  // Selection
  if (mainStatus === MAIN_SELECTION) {
    const submittedCandidates = filterAndSortSubmissions(submissions, [
      SUBMISSION_STATUS.SUBMITTED,
      SUBMISSION_STATUS.INTERVIEW,
    ]);

    return (
      <AudienceBox
        color={COLOR_YELLOW}
        label={role === MANAGER_ROLE ? t('submissionsReceived') : t('submissionsMade')}
        candidates={submittedCandidates}
        onClick={redirectToPositionCandidates}
      />
    );
  }

  // Onboarding
  if (mainStatus === MAIN_ONBOARDING) {
    // For PM onboarding cancelled still belongs under onboarding main status
    const onboardingStatuses =
      role === MANAGER_ROLE
        ? [
            SUBMISSION_STATUS.OFFER,
            SUBMISSION_STATUS.ONBOARDING,
            SUBMISSION_STATUS.ONBOARDING_CANCELLED,
          ]
        : [SUBMISSION_STATUS.OFFER, SUBMISSION_STATUS.ONBOARDING];
    const onboardingCandidates = filterAndSortSubmissions(submissions, onboardingStatuses);

    return (
      <AudienceBox
        color={COLOR_GREEN}
        label={
          status === POSITION_STATUS.ONBOARDING_CANCELLED
            ? capitalizeText(t('onboardingCancelled'))
            : t('onboarding')
        }
        candidates={onboardingCandidates}
        onClick={redirectToPositionCandidates}
      />
    );
  }

  // Delivery
  if (mainStatus === MAIN_DELIVERY) {
    const deliveredCandidates = filterAndSortSubmissions(submissions, [
      SUBMISSION_STATUS.DELIVERY,
      SUBMISSION_STATUS.OFF_BOARDING,
      SUBMISSION_STATUS.OFF_BOARDED,
    ]);

    return (
      <AudienceBox
        color={COLOR_BLUE}
        label={t('delivering')}
        candidates={deliveredCandidates}
        onClick={redirectToPositionCandidates}
      />
    );
  }

  // Closed
  if (mainStatus === MAIN_CLOSED && [STAFF_ROLE, SUPPLIER_ROLE].includes(role)) {
    // No candidates submitted to client
    if (status === POSITION_STATUS.NO_SUBMISSIONS) {
      const notSubmittedCandidates = filterAndSortSubmissions(submissions, [
        SUBMISSION_STATUS.NOT_SUBMITTED,
        SUBMISSION_STATUS.WITHDRAWN,
      ]);

      return (
        <AudienceBox
          color={COLOR_GRAY}
          label={t('submissions')}
          candidates={notSubmittedCandidates}
          onClick={redirectToPositionCandidates}
        />
      );
    }

    // No candidate selected by client
    if (status === POSITION_STATUS.NO_SELECTION) {
      const notSelectedCandidates = filterAndSortSubmissions(
        submissions,
        SUBMISSION_STATUS.SUBMITTED,
        false
      );

      return (
        <AudienceBox
          color={COLOR_GRAY}
          label={capitalizeText(t('submissionsMade'))}
          candidates={notSelectedCandidates}
          onClick={redirectToPositionCandidates}
        />
      );
    }

    // Onboarding cancelled either by partner or PM
    if (status === POSITION_STATUS.ONBOARDING_CANCELLED) {
      const cancelledCandidates = filterAndSortSubmissions(
        submissions,
        SUBMISSION_STATUS.ONBOARDING_CANCELLED
      );

      return (
        <AudienceBox
          color={COLOR_GRAY}
          label={capitalizeText(t('selectionMade'))}
          candidates={cancelledCandidates}
          onClick={redirectToPositionCandidates}
        />
      );
    }
  }

  return null;
};

Audience.propTypes = propTypes;

export default Audience;
