import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// styles
import './level.scss';

const propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  description: PropTypes.string,
  highlighted: PropTypes.bool,
  className: PropTypes.string,
};

const defaultProps = {
  description: '',
  color: 'blue',
  highlighted: false,
  className: '',
};

const Level = ({ title, color, description, highlighted, className }) => {
  return (
    <div
      className={classNames('level', {
        [`level--highlighted bg-${color}`]: highlighted,
        [className]: className,
      })}
    >
      <div className="level_title">{title}</div>
      {description && <div className="level_description">{description}</div>}
    </div>
  );
};

Level.propTypes = propTypes;
Level.defaultProps = defaultProps;

export default Level;
