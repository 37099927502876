import { FILE_SIGNED_JOB_OFFER } from 'constants';
import {
  ONBOARDING_FILE_HR72,
  ONBOARDING_FILE_PASSPORT,
  ONBOARDING_FILE_NDA,
  ONBOARDING_FILE_NHF,
  ONBOARDING_FILE_ATTESTATION,
  ONBOARDING_FILE_AAS_PLUS_NDA,
  ONBOARDING_FILE_NR_AIS_SECOPS_FORM,
  ONBOARDING_FORMS_NOTE,
  ONBOARDING_NR_SECOPS_CHECKBOX,
} from './constants';

export const rejectDateForm = [
  {
    type: 'textarea',
    key: 'rejectionReason',
    properties: {
      labelKey: 'rejectStartDateLabel',
      descriptionKey: 'rejectStartDateDescription',
    },
    validate: {
      required: true,
    },
  },
];

export const declineDateForm = [
  {
    type: 'textarea',
    key: 'rejectionReason',
    properties: {
      labelKey: 'declineJobOfferLabel',
      descriptionKey: 'declineJobOfferDescription',
    },
    validate: {
      required: true,
    },
  },
];

export const withdrawCandidateForm = [
  {
    type: 'textarea',
    key: 'withdrawReason',
    properties: {
      labelKey: 'withdrawReasonLabelKey',
      descriptionKey: `withdrawReasonDescriptionKey`,
    },
    validate: {
      required: true,
    },
  },
];

export const startDateForm = [
  {
    type: 'datetime',
    key: 'startDate',
    properties: {
      labelKey: 'startDateLabel',
      descriptionKey: 'startDateDescription',
      minDate: 'today',
    },
    validate: { required: true },
  },
  {
    type: 'textarea',
    key: 'note',
    properties: {
      labelKey: 'additionalNotes',
      descriptionKey: 'additionalNotesDescription',
    },
    validate: {
      required: false,
    },
  },
];

export const startDateFormRequiredOffer = [
  {
    type: 'datetime',
    key: 'startDate',
    properties: {
      labelKey: 'startDateLabel',
      descriptionKey: 'startDateDescription',
      minDate: 'today',
    },
    validate: { required: true },
  },
  {
    type: 'file',
    key: FILE_SIGNED_JOB_OFFER,
    properties: {
      labelKey: 'signedJobOffer',
      descriptionKey: 'signedJobOfferDescription',
    },
    validate: {
      required: true,
    },
  },
  {
    type: 'textarea',
    key: 'note',
    properties: {
      labelKey: 'additionalNotes',
      descriptionKey: 'additionalNotesDescription',
    },
    validate: {
      required: false,
    },
  },
];

export const rejectStartDateForm = [
  {
    type: 'datetime',
    key: 'startDate',
    properties: {
      labelKey: 'proposeStartDateLabel',
      descriptionKey: 'proposeStartDateDescription',
      minDate: 'today',
    },
    validate: { required: true },
  },
  {
    type: 'textarea',
    key: 'note',
    properties: {
      labelKey: 'startDateJustificationLabel',
      descriptionKey: 'startDateJustificationDescription',
    },
    validate: {
      required: true,
    },
  },
];

export const onboardingForm = {
  [ONBOARDING_FILE_HR72]: {
    type: 'file',
    key: ONBOARDING_FILE_HR72,
    properties: {
      labelKey: 'hr72',
      descriptionKey: 'hr72Description',
    },
    validate: {
      required: true,
    },
  },
  [ONBOARDING_FILE_PASSPORT]: {
    type: 'file',
    key: ONBOARDING_FILE_PASSPORT,
    properties: {
      labelKey: 'passportCopy/Id',
      descriptionKey: 'passportDescription',
    },
    validate: {
      required: true,
    },
  },
  [ONBOARDING_FILE_NDA]: {
    type: 'file',
    key: ONBOARDING_FILE_NDA,
    properties: {
      labelKey: 'nda',
      descriptionKey: 'ndaDescription',
    },
    validate: {
      required: true,
    },
  },
  [ONBOARDING_FILE_NHF]: {
    type: 'file',
    key: ONBOARDING_FILE_NHF,
    properties: {
      labelKey: 'nhf',
      descriptionKey: 'nhfDescription',
    },
    validate: {
      required: true,
    },
  },
  [ONBOARDING_FILE_ATTESTATION]: {
    type: 'file',
    key: ONBOARDING_FILE_ATTESTATION,
    properties: {
      labelKey: 'onboardingAttestationLabel',
    },
    validate: {
      required: true,
    },
  },
  [ONBOARDING_FILE_AAS_PLUS_NDA]: {
    type: 'file',
    key: ONBOARDING_FILE_AAS_PLUS_NDA,
    properties: {
      labelKey: 'aasPlusNDALabel',
    },
    validate: {
      required: true,
    },
  },
  [ONBOARDING_FILE_NR_AIS_SECOPS_FORM]: {
    type: 'file',
    key: ONBOARDING_FILE_NR_AIS_SECOPS_FORM,
    properties: {
      labelKey: 'NR_AIS_SECOPS_Label',
    },
    validate: {
      required: true,
    },
  },
  [ONBOARDING_NR_SECOPS_CHECKBOX]: {
    type: 'checkbox',
    key: ONBOARDING_NR_SECOPS_CHECKBOX,
    properties: {
      labelKey: 'secopsPolicyLabel',
      valueKey: 'secopsPolicyValue',
    },
    validate: {
      required: true,
    },
  },
  [ONBOARDING_FORMS_NOTE]: {
    type: 'textarea',
    key: ONBOARDING_FORMS_NOTE,
    properties: {
      labelKey: 'additionalNotes',
      descriptionKey: 'additionalNotesDescription',
    },
    validate: {
      required: false,
    },
  },
};

export const disabledPurchaseOrderComponents = ['rate', 'totalBudget'];

export const purchaseOrderForm = [
  {
    type: 'textfield',
    key: 'number',
    properties: {
      labelKey: 'purchaseOrderFormLabel',
      descriptionKey: 'purchaseOrderFormDescription',
    },
    validate: {
      required: true,
    },
  },
  {
    type: 'textfield',
    key: 'amendment',
    properties: {
      labelKey: 'purchaseOrderAmendmentLabel',
      descriptionKey: 'purchaseOrderAmendmentDescription',
    },
    validate: {
      required: false,
    },
  },
  {
    type: 'file',
    key: 'file',
    properties: {
      labelKey: 'purchaseOrderDocumentLabel',
      descriptionKey: 'purchaseOrderDocumentDescription',
    },
    validate: {
      required: true,
    },
  },
  {
    type: 'number',
    key: 'effort',
    properties: {
      labelKey: 'levelOfEffort',
      descriptionKey: 'levelOfEffortDescription',
    },
    decimalLimit: 2,
    validate: {
      required: true,
    },
  },
  {
    type: 'number',
    key: 'rate',
    properties: {
      labelKey: 'rate',
    },
    decimalLimit: 2,
    readOnly: true,
    validate: {
      required: true,
    },
  },
  {
    type: 'number',
    key: 'totalBudget',
    properties: {
      labelKey: 'totalBudget',
    },
    decimalLimit: 2,
    readOnly: true,
    validate: {
      required: true,
    },
  },
  {
    type: 'datetime',
    key: 'start',
    properties: {
      labelKey: 'startDate',
      descriptionKey: 'projectStartDateDescription',
    },
    validate: { required: true },
  },
  {
    type: 'datetime',
    key: 'end',
    properties: {
      labelKey: 'endDate',
      descriptionKey: 'projectEndDateDescription',
    },
    validate: { required: true },
  },
];
