import { gql } from '@apollo/client';
import moment from 'moment';
import uniqBy from 'lodash/uniqBy';
import getProperty from 'lodash/get';
import difference from 'lodash/difference';
// services
import client from 'services/Client';
import logger from 'services/Logger';
import notification from 'services/Notification';
// queries
import { PositionStatus, PositionRatesSettings, Rate, Note } from 'queries/Fragments';
// constants
import { RATES_TARGET_RATE, NOTE_PUBLISH_NOTES, SUPPLIER_ROLE } from 'constants';
import { POSITION_STATUS } from 'constants/positionStatus';
// helpers
import { getAbsoluteUrl } from 'helpers/Link';
import { getYearDate } from 'helpers/RelativeTimeFormatter';
import { triggerPositionBulkMatching } from 'helpers/BulkMatching';
import { getChangedFields } from './actions';

const GET_ALL_SUPPLIERS_BY_POSITION = gql`
  query getAllSuppliersByPosition($id: ID!) {
    position(id: $id) {
      id
      project {
        id
        projectRole(filter: { role: { equals: "${SUPPLIER_ROLE}" } }) {
          items {
            id
            user {
              id
              supplier {
                id
                company {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

const getAllSuppliersByPosition = async positionId => {
  const response = await client.query({
    query: GET_ALL_SUPPLIERS_BY_POSITION,
    variables: {
      id: positionId,
    },
  });

  const users = getProperty(response, 'data.position.project.projectRole.items', []).map(
    ({ user }) => user
  );

  return users.reduce((list, user) => {
    if (!user.supplier || list.includes(user.supplier)) {
      return list;
    }

    return [...list, user.supplier];
  }, []);
};

export const PUBLISH_POSITION = gql`
  mutation publishPosition($position: ID!, $data: PositionUpdateInput!) {
    positionUpdate(filter: { id: $position }, data: $data) {
      id
      ...PositionRatesSettings
      publishProgress
      supplierDueDate
      publishedToAll
      statuses(sort: { createdAt: DESC }) {
        items {
          ...PositionStatus
        }
      }
      rates(filter: { endDate: { is_empty: true } }) {
        items {
          ...Rate
        }
      }
      notes {
        items {
          ...Note
        }
      }
      hasBeenPublishedTo {
        items {
          id
          company {
            id
            name
          }
        }
      }
      publishedTo {
        items {
          id
          company {
            id
            name
          }
        }
      }
    }
  }
  ${PositionRatesSettings}
  ${PositionStatus}
  ${Rate}
  ${Note}
`;

/**
 * @param {object} position
 * @param {string} userId Logged in user id
 * @param {object} data
 * @param {string} data.partnerDueDate ISO string
 * @param {string} [data.publishNote]
 * @param {object[]} data.targetRates
 * @param {number} data.targetRates[].value
 * @param {number} data.targetRates[].year
 * @param {boolean} data.showTargetRatesToPartners
 * @param {boolean} data.showNteRatesToPartners
 * @param {boolean} data.allowSubmissionAboveTargetRates
 */
const publishPosition = async (
  position,
  userId,
  {
    partnerDueDate,
    publishNote,
    targetRates,
    publishToAll,
    audience,
    showTargetRatesToPartners,
    showNteRatesToPartners,
    allowSubmissionAboveTargetRates,
  }
) => {
  const positionStatus = position.getStatus();
  const allPartners = await getAllSuppliersByPosition(position.id);
  const data = {
    showTargetRatesToPartners,
    showNteRatesToPartners,
    allowSubmissionAboveTargetRates,
    supplierDueDate: partnerDueDate,
    publishProgress: null,
    publishedToAll: publishToAll,
    statuses: {
      create: {
        name: POSITION_STATUS.PUBLISHED,
        start: moment().toISOString(),
      },
      update: {
        filter: { id: positionStatus.id },
        data: { end: moment().toISOString() },
      },
    },
  };

  // target rates
  if (showTargetRatesToPartners) {
    data.rates = {
      create: targetRates.map(rate => ({
        value: rate.value,
        currency: position.project.currency,
        rateType: RATES_TARGET_RATE,
        startDate: getYearDate(rate.year),
      })),
    };

    // disconnect old target rates if there is any.
    // TODO: in future old target rates will be moved in rates group for publish history
    const oldTargetRates = position.getRates(RATES_TARGET_RATE);
    if (oldTargetRates.length > 0) {
      data.rates.disconnect = oldTargetRates.map(rate => ({ id: rate.id }));
    }
  }

  // disconnect previous audience and connect new
  if (audience.length > 0 && !publishToAll) {
    data.publishedTo = {
      reconnect: audience.map(({ value }) => ({ id: value })),
    };
  } else {
    data.publishedTo = {
      reconnect: [],
    };
  }

  // Position is published to all partners -> connect all of them
  if (publishToAll) {
    data.hasBeenPublishedTo = {
      reconnect: allPartners.map(({ id }) => ({ id })),
    };
  } else {
    // Merge already published partners with newly added partners
    const newPartners = audience.map(({ value }) => ({ id: value }));

    data.hasBeenPublishedTo = {
      reconnect: uniqBy([...position.hasBeenPublishedTo, ...newPartners], 'id').map(({ id }) => ({
        id,
      })),
    };
  }

  // publish note
  if (publishNote) {
    data.notes = {
      create: {
        note: publishNote,
        noteType: NOTE_PUBLISH_NOTES,
        author: {
          connect: {
            id: userId,
          },
        },
      },
    };
  }

  try {
    const hasBeenPublishedBefore = position.hasBeenPublishedBefore();
    const changedFields = getChangedFields(position, {
      publishToAll,
      audience,
      partnerDueDate,
      targetRates,
      publishNote,
      showTargetRatesToPartners,
      showNteRatesToPartners,
      allowSubmissionAboveTargetRates,
    });

    await client.mutate({
      mutation: PUBLISH_POSITION,
      variables: {
        position: position.id,
        data,
      },
      update: cache => {
        cache.evict({ fieldName: 'positionsList' });
        cache.gc();
      },
    });

    // bulk matching for current audience
    if (!hasBeenPublishedBefore && position.isTimeAndMaterials()) {
      triggerPositionBulkMatching(position.id);
    }

    // bulk matching when position's audience has changed
    if (
      hasBeenPublishedBefore &&
      changedFields.includes('audience') &&
      position.isTimeAndMaterials()
    ) {
      const addedSuppliers = (() => {
        const newAudienceIds = audience ? audience.map(({ value }) => value) : [];
        const prevAudienceIds = position.isPublishedToAll
          ? allPartners.map(({ id }) => id)
          : position.audience.map(({ id }) => id);

        if (publishToAll) {
          return difference(
            allPartners.map(({ id }) => id),
            prevAudienceIds
          );
        }

        return difference(newAudienceIds, prevAudienceIds);
      })();

      if (addedSuppliers.length > 0) {
        triggerPositionBulkMatching(position.id, addedSuppliers);
      }
    }

    // first publish of the position
    if (!hasBeenPublishedBefore) {
      notification.positionPublished({
        position: position.id,
        link: getAbsoluteUrl(`/position/${position.id}`),
        createdBy: userId,
      });
    }

    // publish information has been updated
    if (hasBeenPublishedBefore && position.isPublished()) {
      notification.positionPublishUpdated({
        position: position.id,
        link: getAbsoluteUrl(`/position/${position.id}`),
        changedFields,
        createdBy: userId,
        previousAudience: position.audience.map(({ id }) => id),
        wasPublishedToAll: position.isPublishedToAll,
      });
    }

    // position is republished
    if (hasBeenPublishedBefore && !position.isPublished()) {
      notification.positionRepublished({
        position: position.id,
        changedFields,
        link: getAbsoluteUrl(`/position/${position.id}`),
        createdBy: userId,
      });
    }
  } catch (error) {
    logger.exception(error, { id: position.id, data });
    throw error;
  }
};

export default publishPosition;
