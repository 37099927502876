import queryString from 'query-string';
import isObject from 'lodash/isPlainObject';
import intersection from 'lodash/intersection';

export const parseParams = (location, whiteList) => {
  const params = queryString.parse(location.search, { arrayFormat: 'comma' });

  if (!whiteList) {
    return params;
  }

  const allowedFilterTypes = Object.keys(whiteList);
  const filteredParams = Object.keys(params).reduce((list, filterType) => {
    if (!allowedFilterTypes.includes(filterType)) {
      return list;
    }

    const paramValue = params[filterType];
    const allowedParamValues = whiteList[filterType];

    if (allowedParamValues === '*') {
      return {
        ...list,
        [filterType]: paramValue,
      };
    }

    if (Array.isArray(paramValue)) {
      const filteredParamValues = intersection(paramValue, allowedParamValues);

      if (filteredParamValues.length > 0) {
        return {
          ...list,
          [filterType]: filteredParamValues,
        };
      }
    } else if (allowedParamValues.includes(paramValue)) {
      return {
        ...list,
        [filterType]: paramValue,
      };
    }

    return list;
  }, {});

  return filteredParams;
};

export const buildParams = params => {
  return queryString.stringify(params, { arrayFormat: 'comma' });
};

export const addParam = (key, value, location, history) => {
  const params = queryString.parse(location.search);
  const newParams = { ...params, [key]: isObject(value) ? value.id : value };

  history.push(`${location.pathname}?${buildParams(newParams)}`);
};

export const setParams = (params, location, history) => {
  history.push(`${location.pathname}?${buildParams(params)}`);
};

export const removeParam = (key, value, location, history) => {
  const params = parseParams(location);

  if (Array.isArray(params[key])) {
    params[key] = params[key].filter(item => item !== value);
  } else {
    delete params[key];
  }

  history.push(`${location.pathname}?${buildParams(params)}`);
};

export const clearParams = (history, location) => {
  history.push(location.pathname);
};

export const parseParamData = (filterTypes, location, additionalType = null) => {
  const params = queryString.parse(location.search);
  let filterData = {};
  Object.keys(params).forEach(key => {
    const type = filterTypes[params[key]];
    filterData = {
      ...filterData,
      [key]: type || { label: params[key], id: key, type: additionalType },
    };
  });
  return filterData;
};
