import React, { useState, useCallback } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// components
import Button from 'components/atoms/Button';
import Loader from 'components/atoms/Loader';

const propTypes = {
  isDiscardActive: PropTypes.bool.isRequired,
  isPublishActive: PropTypes.bool.isRequired,
  isPublishButtonVisible: PropTypes.bool.isRequired,
  onDiscard: PropTypes.func.isRequired,
  onPublish: PropTypes.func.isRequired,
};

const Header = ({
  isPublishActive,
  isDiscardActive,
  onDiscard,
  onPublish,
  isPublishButtonVisible,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handlePublish = useCallback(async () => {
    setLoading(true);
    try {
      await onPublish();
    } finally {
      setLoading(false);
    }
  }, [onPublish]);

  return (
    <div className="oneRow space-between">
      <div className="space-12 pageHeading">{t('rates')}</div>
      {loading ? (
        <Loader fixedWidth />
      ) : (
        <div className="oneRow">
          {isDiscardActive && (
            <div className="m-r-10">
              <Button label={t('discardChanges')} onClick={onDiscard} secondary />
            </div>
          )}
          {isPublishButtonVisible && (
            <Button
              label={t('publishPosition')}
              onClick={handlePublish}
              disabled={!isPublishActive}
            />
          )}
        </div>
      )}
    </div>
  );
};

Header.propTypes = propTypes;

export default Header;
