import React, { useContext } from 'react';
import { MdClose } from 'react-icons/md';
import IconButton from '@components/IconButton';
import Heading from '@components/Heading';
import DialogContext from './DialogContext';
import styles from './dialog.module.scss';

interface DialogHeaderProps {
  title: string;
  children?: React.ReactNode;
  disableCloseButton?: boolean;
}

const DialogHeader = ({ title, children, disableCloseButton }: DialogHeaderProps) => {
  const { labelId, loading, onClose } = useContext(DialogContext);

  return (
    <div className={styles.header}>
      <div className={styles.titleWrapper}>
        <Heading id={labelId} level={2}>
          {title}
        </Heading>
        {!loading && (
          <IconButton
            label="Close"
            icon={MdClose}
            onClick={onClose}
            disabled={disableCloseButton}
            className={styles.closeIcon}
          />
        )}
      </div>
      {children}
    </div>
  );
};

export default DialogHeader;
