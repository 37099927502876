import React, { useState } from 'react';
// libraries
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
// styles
import colors from 'dependencies/materialStyles/Colors';
// components
import Button from 'components/atoms/Button';
import Chips from './Chips';
import EditableLabel from './EditableLabel';
// style
import style from './SurveyLine.module.scss';

const propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  removeItem: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  className: PropTypes.string,
  selected: PropTypes.string,
  chips: PropTypes.array,
  color: PropTypes.string,
};

const defaultProps = {
  value: '',
  className: '',
  selected: null,
  chips: [],
  color: null,
};

const SurveyLine = props => {
  const { className, id, removeItem, onChange, chips, selected, color, t, value } = props;
  const [isHover, setIsHover] = useState(false);

  const selectedChip = chips.find(({ value: chipValue }) => chipValue === selected);

  const handleLineEdit = textValue => {
    onChange({
      ...selectedChip,
      description: textValue,
      color,
      id,
    });
  };

  return (
    <div
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      className={classNames(style.surveyLine, className)}
    >
      {chips && chips.length > 0 && (
        <Chips
          chips={chips}
          onChange={chip => onChange({ ...chip, description: value, id })}
          selected={selected}
        />
      )}
      {!!color && (
        <div
          style={{
            background: color,
          }}
          className={style.colorBox}
        />
      )}
      <EditableLabel id={id} label={value} onChange={handleLineEdit} isHover={isHover} />
      <Button
        type="close"
        size="18"
        color={colors.gray}
        tooltip={t('remove')}
        tooltipPosition="left"
        iconStyle="iconW18"
        onClick={() => removeItem(id)}
      />
    </div>
  );
};

SurveyLine.propTypes = propTypes;
SurveyLine.defaultProps = defaultProps;

export default withTranslation()(SurveyLine);
