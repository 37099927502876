import React from 'react';
import PropTypes from 'prop-types';
import getProperty from 'lodash/get';
// icons
import Icon from 'components/atoms/Icon';
// colors
import { SUPPORTED_COLORS, COLOR_WHITE } from 'constants/colors';
import contrastColor from 'dependencies/materialStyles/ContrastColors';

const propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  color: PropTypes.oneOf(SUPPORTED_COLORS),
  iconSize: PropTypes.number,
  iconColor: PropTypes.string,
};

const defaultProps = {
  onClick: () => {},
  color: COLOR_WHITE,
  iconColor: '',
  iconSize: 18,
};

const ChipNote = ({ onClick, label, className, color, iconColor, iconSize }) => {
  const onPopoverClick = e => {
    e.preventDefault();
    onClick(e);
  };

  const iconFinalColor = iconColor || getProperty(contrastColor, `${color}.color`, COLOR_WHITE);

  return (
    <div className="chip_noteWrapper">
      <span className={`chip_note ${className}`} onClick={onPopoverClick} role="presentation">
        {label}
        <Icon name={iconColor ? 'info' : 'infoOutline'} color={iconFinalColor} size={iconSize} />
      </span>
    </div>
  );
};

ChipNote.propTypes = propTypes;
ChipNote.defaultProps = defaultProps;

export default ChipNote;
