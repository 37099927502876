import { STATUS_STATE } from './constants';

/**
 * Get percentage value from statuses that are completed
 * @param {object[]} historyStatuses - Array of status objects
 * @returns {number}
 */
export const getProgressValue = historyStatuses => {
  const total = historyStatuses.length - 1;
  const active = historyStatuses.filter(({ state }) => state === STATUS_STATE.FINISHED).length;

  return (100 / total) * active;
};
