import getProperty from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
// constants
import { BASIC_ROLE, SYSTEM_ROLE } from 'constants/roles';

/**
 * @typedef {Object} UserAccount
 * @property {string} id
 */

/**
 * @typedef {Object} UserProjectRole
 * @property {string} id
 * @property {string} role
 * @property {string[]} projects Projects Id
 */

/**
 * @typedef {Object} UserProject
 * @property {string} id
 * @property {string} name
 */

/**
 * @typedef {Object} UserLastViewNotification
 * @property {string} id Last viewed notification id
 * @property {string} date Last viewed date
 */

class User {
  constructor(data, role) {
    const projectRoles = getProperty(data, 'alakaUser.projectRoles.items', []);
    const currentProjectRole = projectRoles.find(projectRole => projectRole.role === role);
    const projects = getProperty(currentProjectRole, 'projects.items', []);
    const clients = uniqBy(
      projects.map(project => project.client),
      'id'
    );

    this.role = role;
    this.id = getProperty(data, 'alakaUser.id', '');
    this.systemRole = getProperty(data, 'alakaUser.systemRole', '');
    this.email = getProperty(data, 'email', '');
    this.name = getProperty(data, 'alakaUser.person.fullName', '');
    this.preferredName = getProperty(data, 'alakaUser.person.preferredName', '');
    this.supplierId = getProperty(data, 'alakaUser.supplier.id', '');
    this.company = getProperty(data, 'alakaUser.organization.name', '');

    /**
     * @property {UserLastViewNotification}
     */
    this.lastViewedNotification = {
      id: getProperty(data, 'alakaUser.lastViewed.id'),
      date: getProperty(data, 'alakaUser.lastViewed.date'),
    };

    /**
     * List of projects which user is part of
     * @property {UserProject[]}
     */
    this.projects = projects.map(project => ({
      id: project.id,
      name: project.name,
    }));

    /**
     * List of clients which user is part of
     */
    this.clients = clients;

    /**
     * User 8base account
     * @property {UserAccount}
     */
    this.account = {
      id: getProperty(data, 'id', ''),
    };

    /**
     * @property {UserProjectRole}
     */
    this.projectRoles = projectRoles.map(projectRole => ({
      id: projectRole.id,
      role: projectRole.role,
      projects: getProperty(projectRole, 'projects.items', []).map(project => project.id),
    }));
  }

  /**
   * @returns {bool}
   */
  isSupplier = () => {
    return this.role === BASIC_ROLE.SUPPLIER;
  };

  /**
   * @returns {bool}
   */
  isMatchManager = () => {
    return this.role === BASIC_ROLE.MATCH_MANAGER;
  };

  /**
   * @returns {bool}
   */
  isProjectManager = () => {
    return this.role === BASIC_ROLE.PROJECT_MANAGER;
  };

  /**
   * @returns {bool}
   */
  isSuperAdmin = () => {
    return this.systemRole === SYSTEM_ROLE.SUPER_ADMIN;
  };

  /**
   * @param {string|string[]}
   * @returns {bool}
   */
  hasRole = role => {
    return [].concat(role).includes(this.role);
  };

  /**
   * Return all user's role
   * @returns {string[]}
   */
  getRoles = () => {
    return this.projectRoles.map(projectRole => projectRole.role);
  };
}

/**
 * Parse and normalize logged in user data
 * @param {object} data user data from 8base
 * @param {string} role current user's role
 * @returns {User}
 */
const parseUser = (data, role) => new User(data, role);

export default parseUser;
