import React from 'react';
// Libraries
import PropTypes from 'prop-types';

const Name = ({ className, name, required }) => {
  return (
    <div className={`${className} names_name rateInputs_cell element_name`}>
      {name}
      {required && <div className="text-red p-l-2">*</div>}
    </div>
  );
};

Name.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  required: PropTypes.bool,
};
Name.defaultProps = {
  name: null,
  className: '',
  required: false,
};

export default Name;
