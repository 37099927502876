import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// components
import LinkButton from 'components/atoms/LinkButton';
import TextSection from 'components/atoms/TextSection';
// helpers
import { openLink } from 'helpers/Link';
import { boxFileUrl } from 'helpers/Box';
// constants
import {
  SUPPLIER_ROLE,
  MANAGER_ROLE,
  FILE_PROPOSAL,
  FILE_RELEASABLE_PROPOSAL,
  FILE_ATTESTATION,
  FILE_RELEASABLE_ATTESTATION,
} from 'constants';
// styles
import styles from './submissionDetails.module.scss';

const propTypes = {
  proposal: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
};

const Documents = ({ proposal, role }) => {
  const { t } = useTranslation();
  const proposalFile = proposal.getDocument(FILE_PROPOSAL);
  const releasableProposal = proposal.getDocument(FILE_RELEASABLE_PROPOSAL);
  const attestation = proposal.getDocument(FILE_ATTESTATION);
  const releasableAttestation = proposal.getDocument(FILE_RELEASABLE_ATTESTATION);

  return (
    <div className={styles.documents}>
      {(role !== MANAGER_ROLE || (role === MANAGER_ROLE && !releasableProposal)) && (
        <TextSection title={t('proposal')}>
          <LinkButton onClick={() => openLink(boxFileUrl(proposalFile.boxId))}>
            {proposalFile.filename}
          </LinkButton>
        </TextSection>
      )}
      {role !== SUPPLIER_ROLE && releasableProposal && (
        <TextSection title={role === MANAGER_ROLE ? t('proposal') : t('releasableProposal')}>
          <LinkButton onClick={() => openLink(boxFileUrl(releasableProposal.boxId))}>
            {releasableProposal.filename}
          </LinkButton>
        </TextSection>
      )}
      {(role !== MANAGER_ROLE || (role === MANAGER_ROLE && !releasableAttestation)) && (
        <TextSection title={t('attestation')}>
          <LinkButton onClick={() => openLink(boxFileUrl(attestation.boxId))}>
            {attestation.filename}
          </LinkButton>
        </TextSection>
      )}
      {role !== SUPPLIER_ROLE && releasableAttestation && (
        <TextSection title={role === MANAGER_ROLE ? t('attestation') : t('releasableAttestation')}>
          <LinkButton onClick={() => openLink(boxFileUrl(releasableAttestation.boxId))}>
            {releasableAttestation.filename}
          </LinkButton>
        </TextSection>
      )}
    </div>
  );
};

Documents.propTypes = propTypes;

export default Documents;
