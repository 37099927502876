import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// components
import Switch from 'components/atoms/Switch';
import Tooltip from 'components/atoms/Tooltip';
// constants
import { RATE_SETTING } from './constants';
// styles
import styles from './positionRatesSettings.module.scss';

const propTypes = {
  settings: PropTypes.shape({
    [RATE_SETTING.SHOW_TARGET_RATES_TO_PARTNERS]: PropTypes.bool.isRequired,
    [RATE_SETTING.SHOW_NTE_RATES_TO_PARTNERS]: PropTypes.bool.isRequired,
    [RATE_SETTING.ALLOW_SUBMISSION_ABOVE_TARGET_RATES]: PropTypes.bool.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

const defaultProps = {
  disabled: false,
};

const RatesSettings = ({ settings, onChange, disabled }) => {
  const { t } = useTranslation();
  const showTargetRatesToPartners = settings[RATE_SETTING.SHOW_TARGET_RATES_TO_PARTNERS];

  const handleChange = setting => checked => {
    const newSettings = {
      ...settings,
      [setting]: checked,
    };

    // enable "Allow submissions above target rates" when "Show target rates to partner" is disabled
    if (setting === RATE_SETTING.SHOW_TARGET_RATES_TO_PARTNERS && !checked) {
      newSettings[RATE_SETTING.ALLOW_SUBMISSION_ABOVE_TARGET_RATES] = true;
    }

    onChange(newSettings);
  };

  return (
    <React.Fragment>
      <div className={classNames(styles.rateSetting, 'm-b-15')}>
        <span>{t('showTargetRatesToPartners')}</span>
        <div className="m-l-12">
          <Switch
            checked={showTargetRatesToPartners}
            onChange={handleChange(RATE_SETTING.SHOW_TARGET_RATES_TO_PARTNERS)}
            disabled={disabled}
          />
        </div>
      </div>
      <div className={classNames(styles.rateSetting, 'm-b-15')}>
        <span>{t('allowSubmissionAboveTargetRates')}</span>
        <Tooltip title={!showTargetRatesToPartners ? t('enableShowTargetRatesToPartners') : ''}>
          <div className="m-l-12">
            <Switch
              checked={settings[RATE_SETTING.ALLOW_SUBMISSION_ABOVE_TARGET_RATES]}
              onChange={handleChange(RATE_SETTING.ALLOW_SUBMISSION_ABOVE_TARGET_RATES)}
              disabled={!showTargetRatesToPartners || disabled}
            />
          </div>
        </Tooltip>
      </div>
      <div className={styles.rateSetting}>
        <span>{t('showNteRatesToPartners')}</span>
        <div className="m-l-12">
          <Switch
            checked={settings[RATE_SETTING.SHOW_NTE_RATES_TO_PARTNERS]}
            onChange={handleChange(RATE_SETTING.SHOW_NTE_RATES_TO_PARTNERS)}
            disabled={disabled}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

RatesSettings.propTypes = propTypes;
RatesSettings.defaultProps = defaultProps;

export default RatesSettings;
