import getProperty from 'lodash/get';
import orderBy from 'lodash/orderBy';
// helpers
import { filterSubmissionsByStatus } from 'helpers/Submission';

/**
 * Sort submissions by status date. Desc order
 * @param {object[]} submissions
 * @param {string} status
 * @returns {object[]}
 */
const sortSubmissionsByStatus = (submissions, status) => {
  return orderBy(
    submissions,
    submission => {
      const foundStatus = getProperty(submission, 'statuses.items', []).find(
        submissionStatus => submissionStatus.name === status
      );

      return getProperty(foundStatus, 'start', '');
    },
    'desc'
  );
};

/**
 * Filter submissions by status and then sorting submissions by status date. Desc order
 * @param {object[]} submissions
 * @param {string\string[]} filterStatus
 * @param {boolean} [filterByCurrentStatus=true] Filter by current submission status (where end date == null)
 * @returns {string[]} Filtered and sorted candidates' name
 */
export const filterAndSortSubmissions = (
  submissions,
  filterStatus,
  filterByCurrentStatus = true
) => {
  const filteredSubmissions = filterSubmissionsByStatus(
    submissions,
    filterStatus,
    filterByCurrentStatus
  );
  const sortBy = Array.isArray(filterStatus) ? filterStatus[0] : filterStatus;

  return sortSubmissionsByStatus(filteredSubmissions, sortBy).map(
    submission => getProperty(submission, 'profile.fullName') || getProperty(submission, 'name')
  );
};
