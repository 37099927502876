import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
// components
import Banner, { BANNER_TYPE } from 'components/molecules/Banner';
// helpers
import { getPositionPublishedCount } from './helpers';
// styles
import styles from './publishPosition.module.scss';

const propTypes = {
  position: PropTypes.object.isRequired,
};

const Header = ({ position }) => {
  const { t } = useTranslation();

  const renderPublishedInfo = () => {
    const publishedCount = getPositionPublishedCount(position.statuses);

    if (!publishedCount) {
      return null;
    }
    if (publishedCount === 1) {
      return <div className={styles.publishedInfo}>{t('positionHasBeenPublishedOnceBefore')}</div>;
    }

    return (
      <div className={styles.publishedInfo}>
        <Trans i18nKey="positionHasBeenPublishedMultipleTimesBefore">
          This request has already been published{' '}
          <span className={styles.publishedCount}>{{ publishedCount }} times</span>
        </Trans>
      </div>
    );
  };

  return (
    <Link to={`/position/${position.id}`}>
      <Banner type={BANNER_TYPE.ANNOUNCEMENT}>
        <div className={styles.header}>
          <div className="m-r-12">
            <div className={styles.rfq}>{position.rfqLabel}</div>
            <div className={styles.title}>{position.title}</div>
            {renderPublishedInfo()}
          </div>
          <div className={styles.evaluationMethod}>{position.evaluationMethod.label}</div>
        </div>
      </Banner>
    </Link>
  );
};

Header.propTypes = propTypes;

export default Header;
