import React from 'react';
import PropTypes from 'prop-types';
// Libraries
import { Button as MaterialButton, IconButton, Tooltip } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import { withStyles } from '@material-ui/core/styles';
// components
import Icon from 'components/atoms/Icon';
// styles
import colors from 'dependencies/materialStyles/Colors';
import buttonStyle from './ButtonStyle';
import './button.scss';

const propTypes = {
  disabled: PropTypes.bool,
  size: PropTypes.any,
  color: PropTypes.string,
  iconButton: PropTypes.string,
  secondary: PropTypes.bool,
  tooltipPosition: PropTypes.string,
  tooltip: PropTypes.string,
  type: PropTypes.any,
  label: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  iconStyle: PropTypes.string,
};

const defaultProps = {
  disabled: false,
  size: 22,
  color: null,
  iconButton: null,
  secondary: false,
  tooltipPosition: 'top',
  tooltip: '',
  type: false,
  label: '',
  className: '',
  onClick: () => {},
  iconStyle: null,
};

const Button = props => {
  const {
    className,
    label,
    onClick,
    type,
    tooltip,
    tooltipPosition,
    size,
    secondary,
    disabled,
    iconButton,
    color,
    iconStyle,
    // eslint-disable-next-line react/prop-types
    classes,
  } = props;

  const handledColor = color || 'blue';

  const buttonClasses = {
    root: classes[disabled ? 'buttongray' : `button${secondary ? 'white' : ''}${handledColor}`],
    label:
      classes[disabled ? 'buttonLabel' : `buttonLabel${secondary ? `white${handledColor}` : ''}`],
  };
  const getButton = () => {
    if (type) {
      const iconClasses = {
        root: classes[iconStyle || 'icon'],
      };

      return (
        <Tooltip
          title={tooltip}
          placement={tooltipPosition}
          disableFocusListener
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
        >
          <IconButton onClick={onClick} classes={iconClasses} disabled={disabled}>
            <Icon
              name={type}
              color={disabled ? colors.disabledGray : color || colors.gray}
              size={size || 22}
              disabled={disabled}
            />
          </IconButton>
        </Tooltip>
      );
    }

    if (iconButton) {
      return (
        <MaterialButton
          className={`${className} iconButton`}
          classes={buttonClasses}
          onClick={onClick}
          disabled={disabled}
        >
          <Icon name={iconButton} color={secondary ? color : 'white'} size={size || 22} />
          {label}
        </MaterialButton>
      );
    }
    return (
      <Tooltip
        title={tooltip}
        placement={tooltipPosition}
        disableFocusListener
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 600 }}
      >
        <MaterialButton
          className={className}
          classes={buttonClasses}
          onClick={onClick}
          disabled={disabled}
        >
          {label}
        </MaterialButton>
      </Tooltip>
    );
  };

  return <span className="fs-unmask">{getButton()}</span>;
};

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default withStyles(buttonStyle)(Button);
