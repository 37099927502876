import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
// components
import Section from 'components/atoms/Section';
import LevelList from 'components/molecules/LevelList';
// constants
import { TERMS_OF_USE_UPDATE_DATE } from 'constants';
// helpers
import { getTimeYear } from 'helpers/RelativeTimeFormatter';
import { definitions, licenseeObligations, licenseeObligationsTerms } from './Texts';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const LegalTerms = ({ className }) => {
  const { t } = useTranslation();
  const lastUpdatedDate = getTimeYear(TERMS_OF_USE_UPDATE_DATE);

  return (
    <div className={className}>
      <Section title={t('termsOfUse')} className="p-b-0" titleClassName="mainHeader" />
      <br />
      <div className="lastUpdateDate">{t('lastUpdated', { date: lastUpdatedDate })}</div>
      <br />
      <br />
      <Section title={`1. ${t('definitions')}`} content={<LevelList content={definitions} />} />
      <Section
        content={
          <Trans i18nKey="termsOfUseDescription">
            IBM is providing the Licensee with this Product, as a result of a business engagements
            on the Project that requires the use of the Product to work collaboratively and support
            the business aim of the Project. The existing Terms of Use are applicable for the use of
            the Product and they are an addition to the Procurement agreements signed for the
            purpose of the Project.
            <br />
            <br />
            This Agreement does not constitute the entire agreement between the parties related to
            the subject matter hereof and does not supersede all prior oral and written and all
            contemporaneous oral negotiations, commitments and understandings of the parties, all of
            which are signed and agreed for the scope of the Project.
            <br />
            <br />
            These terms of use are provided to enforce the obligations and responsibilities with
            reference to the use of the Product in support of the business needs of the Project.
          </Trans>
        }
      />
      <Section
        title={`2. ${t('overview')}`}
        content={
          <Trans i18nKey="termsOfUseOverview">
            The following are the terms of an agreement between the Licensee and IBM. By accessing,
            or using the Product, Licensee acknowledges to have read, understood, and agreed to be
            bound by these terms and to comply with all applicable laws and regulations, including
            export and re-export control laws and regulations. If Licensee does not agree to these
            terms, please do not use or access the Product.
            <br />
            <br />
            IBM may, without notice to Licensee, at any time, revise these Terms of Use and any
            other information contained in or provided by the Product. IBM may also make
            improvements or changes to the Product at any time without notice.
          </Trans>
        }
      />
      <Section
        title={`3. ${t('scopeOfThisAgreement')}`}
        content={t('scopeOfThisAgreementDescription')}
      />
      <Section
        title={`4. ${t('disclaimer')}`}
        content={
          <Trans i18nKey="disclaimerDescription">
            The following information is provided to familiarize you with the contents and use of
            the Product and is not intended as legal advice.
            <br />
            <br />
            If any term in this Terms of Use is found by competent judicial authority to be
            unenforceable in any respect, the validity of the remainder of this Terms of Use will be
            unaffected, provided that such unenforceability does not materially affect the parties’
            rights under this Terms of Use.
            <br />
            <br />
            From time to time, the Product may contain technical inaccuracies or typographical
            errors, and IBM does not warrant the accuracy of any posted information.
          </Trans>
        }
      />
      <Section
        title={`5. ${t('general')}`}
        content={
          <Trans i18nKey="generalDescription">
            IBM grants Licensee, a limited non-exclusive, non-transferable, limited permission to
            use the Product provided Licensee complies with these Terms of Use, and all copyright,
            trademark, and other proprietary notices. The use authorized under this agreement is
            non-commercial in nature (e.g., Licensee may not sell the content obtained through the
            Product).
            <br />
            <br />
            Except for the limited permission in the preceding paragraph, IBM does not grant
            Licensee any express or implied rights or licenses under any patents, trademarks,
            copyrights, or other proprietary or intellectual property rights. Licensee may not
            mirror any of the content from the Product on another web site or in any other media.
            Any Content that is made available for downloading, access, or other use from the
            Product with their own license terms will be governed by such terms, conditions, and
            notices. Failure to comply with such terms or any of the terms of the Product will
            result in automatic termination of any rights granted to Licensee, without prior notice,
            and Licensee must immediately destroy all copies of downloaded materials in possession,
            custody or control.
          </Trans>
        }
      />
      <Section
        title={`6. ${t('licenseeObligations')}`}
        content={
          <React.Fragment>
            {t('licenseeObligationsDescriptionTerms1')}
            <br />
            <br />
            {t('licenseeObligationsDescriptionTerms2')}
            <br />
            <br />
            {t('licenseeObligationsDescriptionTerms3')}
            <br />
            <br />
            <Trans i18nKey="licenseeObligationsDescriptionTerms4">
              <b>Fair and Lawful with transparency</b> - Data Processing activities involving
              personal information must be performed in a fair and lawful way, as well as in a
              manner that provides transparency to the data subject.
            </Trans>
            <br />
            {t('licenseeObligationsDescriptionTerms5')}
            <br />
            <br />
            <Trans i18nKey="licenseeObligationsDescriptionTerms6">
              <b>Fair and Lawful with transparency</b> - Data Processing activities involving
              personal information must be performed in a fair and lawful way, as well as in a
              manner that provides transparency to the data subject.
              <LevelList content={licenseeObligationsTerms} firstLevelClassName="p-t-20" />
            </Trans>
            <br />
            {t('licenseeObligationsDescriptionTerms7')}
            <br />
            <br />
            <Trans i18nKey="licenseeObligationsDescriptionTerms8">
              <b>Only what is necessary</b> - Data Controllers are further limited to the extent in
              which they may use the data in their data processing activities. Data controllers may
              only use that data which is necessary for achieving their purpose. In other words,
              Controllers must minimize the amount of personal data they process, limiting
              processing activities to only critical personal data. Relevant to our Product, we
              limit the amount of data we collect through the use of standardized forms,and we have
              identified what information is critical. However, each process and Project is unique
              and any non-essential information inputted in the Product is at the sole
              responsibility of the Data Controller.With regards to the ‘Candidate Pool’ feature
              offered through our Product, the Licensor must ensure that only the Licensee can
              access the Candidate information available in their‘Candidate Pool’, and that only a
              limited number of employees from the Licensor can access theLicensee’sCandidate Pool
              as necessary only for the purposes specified in thePrivacy Policy, Subject 5.6.
            </Trans>
            <br />
            <br />
            <Trans i18nKey="licenseeObligationsDescriptionTerms9">
              <b>Current and Accurate</b>-The GDPR states that Data Controllers must take “every
              reasonable step” for ensuring the personal data processed is current and accurate.
              Where it isn’t, the Data Subjects have the right of rectification, compelling the Data
              Controller to remedy any inaccuracies. Our Product provides Licensees with features
              like the ‘Candidate RecordPage’, ‘Edit Candidate Record’,and ‘Candidate Submission’ to
              promote current, credible, and accurate candidate data maintenance.
            </Trans>
            <br />
            <br />
            <Trans i18nKey="licenseeObligationsDescriptionTerms10">
              <b>Limited Retention</b> - Personal data may be retained “for only as long as is
              necessary.” GDPR does not specify the length of time, as it depends on the nature of
              the data processing activity and/or local requirements.It is the responsibility of the
              Data Controller to show that such limits are considered and followed.For the data
              processed and stored by our Product, we can ensure that any data is removed at the
              request of the Data Controller.Otherwise, the data related to Candidate Submissions
              for specific positions will be stored as per the duration agreed and specified in your
              signed agreements for the Project that you are part of (e.g.for the AAS Project, these
              can be retained for the full duration of the Project unless otherwise requested by the
              Data Controller or Data Subjects).
            </Trans>
            <br />
            <br />
            <Trans i18nKey="licenseeObligationsDescriptionTerms11">
              In the context of the ‘Candidate Pool’ feature provided by our Product that allows the
              matching of stored candidates to new positions, we provide a ‘Delete Candidate Record’
              feature that the Licensee can use to permanently delete all data from the ‘Candidate
              Pool’ for those.
              <br />
              <br />
              In cases where a Candidate that exists in the ‘Candidate Pool’ has been submitted for
              a position, but the Candidate withdraws consent from being contacted by Licensee for
              future positions, the ‘Delete Candidate Record’ feature will only remove the Candidate
              from the Candidate Pool so that the candidate can no longer be considered or matched
              to a new position. However, the Candidate data regarding submissions will not be
              deleted unless specifically requested by the Licensee (Data Controller) via email to
              the Licensor. This is to permit Candidates to still be contacted and considered for
              positions where they have already given consent to be submitted, but submitted but
              have opted out from being contacted for new positions.
              <br />
              In the future, we will introduce features that will allow the Data Controllers to
              remove all relevant data, including submissions, directly in the Product
            </Trans>
            <br />
            <br />
            {t('licenseeObligationsDescriptionTerms12')}
            <br />
            <br />
            <Trans i18nKey="licenseeObligationsDescriptionTerms13">
              Licensee ensures that candidates offered to IBM accept that IBM and its authorized
              companies may transfer personal information to any of the countries where IBM does
              business.
              <LevelList content={licenseeObligations} firstLevelClassName="p-t-20" />
            </Trans>
          </React.Fragment>
        }
      />
      <Section
        title={`7. ${t('intellectualPropertyRights')}`}
        content={
          <React.Fragment>
            {t('intellectualPropertyRightsDescription1')}
            <br />
            <br />
            {t('intellectualPropertyRightsDescription2')}
            <br />
            <br />
            {t('intellectualPropertyRightsDescription3')}
            <br />
            <br />
            {t('intellectualPropertyRightsDescription4')}
            <br />
            <br />
            <Trans i18nKey="intellectualPropertyRightsDescription5">
              Licensee shall ensure that its collection and use of any personal data, accessed
              through the Product or the Services, complies with the data protection laws identified
              at
              <a href="www.ibm.com/dpa/dpl" target="_blank" rel="noopener noreferrer">
                www.ibm.com/dpa/dpl
              </a>
              . The Licensee hereby indemnifies IBM against all losses, liabilities, costs and
              expenses (including but not limited to legal costs) arising from or incurred by reason
              of the Licensee’s failure to comply with this clause.
            </Trans>
          </React.Fragment>
        }
      />
      <Section
        title={`8. ${t('disclaimerOfWarranty')}`}
        content={
          <Trans i18nKey="disclaimerOfWarrantyDescription">
            Licensee usage of this Product is at Licensee’s sole risk. All materials, information,
            products, software, programs, and services are provided “AS IS”, with no warranties or
            guarantees whatsoever. IBM expressly disclaims to the fullest extent permitted by law
            all express, implied, statutory, and other warranties, guarantees, or representations,
            including, without limitation, the warranties of merchantability, fitness for a
            particular purpose, and non-infringement of proprietary and intellectual property
            rights. Without limitation, IBM makes no warranty or guarantee that the Product service
            will be uninterrupted, timely, secure, or error-free.
            <br />
            <br />
            Licensee understands and agrees that if downloading or otherwise obtaining material,
            information, products, software, programs, or services from the Product is at Licensee’s
            own discretion and risk and that Licensee will be solely responsible for any damages
            that may result, including loss of data or damage to Licensee’s computer system. Some
            jurisdictions do not allow the exclusion of warranties, so the above exclusion may not
            apply to Licensee.
          </Trans>
        }
      />
      <Section
        title={`9. ${t('limitationOfLiability')}`}
        content={t('limitationOfLiabilityDescription')}
      />
      <Section title={`10. ${t('feedback')}`} content={t('feedbackDescription')} />
      <Section
        title={`11. ${t('alphaBetaProduct')}`}
        content={
          <React.Fragment>
            {t('alphaBetaProduct1')}
            <br />
            <br />
            {t('alphaBetaProduct2')}
            <br />
            <br />
            <b>1.11 {t('alphaBetaProductDescriptionTitle1')}</b>
            <br />
            <div className="p-t-5">{t('alphaBetaProductDescription1')}</div>
          </React.Fragment>
        }
      />
      <Section
        title={`12. ${t('contact')}`}
        content={
          <Trans
            i18nKey="contactDescription"
            components={[
              <br />,
              <a href="mailto:talent.matching.hub@be.ibm.com">talent.matching.hub@be.ibm.com</a>,
            ]}
          />
        }
      />
      <Section
        title={`13. ${t('changesToPrivacyPolicy')}`}
        content={
          <Trans
            i18nKey="changesToPrivacyPolicyDescription"
            components={[<br />]}
            values={{ lastUpdatedDate }}
          />
        }
      />
    </div>
  );
};

LegalTerms.propTypes = propTypes;
LegalTerms.defaultProps = defaultProps;

export default LegalTerms;
