import React, { useState, useEffect } from 'react';
// libraries
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import flow from 'lodash/flow';
// components
import CKEditor from 'components/molecules/CKEditor';
import Attachments from 'components/molecules/Attachments';
import EmailOptions from './EmailOptions';
// helpers
import { initOptions, validateOptions, extractOptions, isOptionsValid } from './Helpers';

const propTypes = {
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      boxId: PropTypes.string,
      filename: PropTypes.string.isRequired,
      onClick: PropTypes.func,
    })
  ),
  options: PropTypes.shape({
    subject: PropTypes.string,
    from: PropTypes.string,
    to: PropTypes.string,
    cc: PropTypes.arrayOf(PropTypes.string),
    bcc: PropTypes.arrayOf(PropTypes.string),
    replyTo: PropTypes.string,
  }),
  html: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onOptionsChange: PropTypes.func,
  showErrors: PropTypes.bool,
  emailOptionsOpen: PropTypes.bool,
};

const defaultProps = {
  options: {
    subject: '',
    from: '',
    to: '',
    cc: [''],
    bcc: [''],
    replyTo: '',
  },
  html: '',
  attachments: [],
  onOptionsChange: () => {},
  showErrors: false,
  emailOptionsOpen: false,
};

const processOptions = flow(initOptions, validateOptions);

const Email = ({
  options,
  html,
  onChange,
  onOptionsChange,
  showErrors,
  attachments,
  emailOptionsOpen,
}) => {
  const [localOptions, setLocalOptions] = useState(processOptions(options));

  useEffect(() => {
    if (!isEqual(extractOptions(localOptions), options)) {
      const processedOptions = processOptions(options);

      setLocalOptions(processedOptions);
      onOptionsChange(extractOptions(processedOptions), isOptionsValid(processedOptions));
    }
  }, [options]);

  const handleOptionsChange = opts => {
    const validatedOptions = validateOptions(opts);

    setLocalOptions(validatedOptions);
    onOptionsChange(extractOptions(opts), isOptionsValid(validatedOptions));
  };
  return (
    <React.Fragment>
      <EmailOptions
        options={localOptions}
        onChange={handleOptionsChange}
        showErrors={showErrors}
        emailOptionsOpen={emailOptionsOpen}
      />
      <CKEditor data={html} onChange={onChange} />
      {attachments.length > 0 && (
        <div className="m-t-20">
          <Attachments items={attachments} />
        </div>
      )}
    </React.Fragment>
  );
};

Email.propTypes = propTypes;
Email.defaultProps = defaultProps;

export default Email;
