import React, { useState } from 'react';
// Libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Collapse from '@material-ui/core/Collapse';
// components
import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import colors from 'dependencies/materialStyles/Colors';

const propTypes = {
  className: PropTypes.string,
  note: PropTypes.string.isRequired,
  requestor: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};

const defaultProps = {
  className: '',
};

const RevisionNote = ({ className, note, requestor, date }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <div
      className={`${className} cp historyModal_revision`}
      onClick={() => setOpen(!open)}
      role="presentation"
    >
      <div className="historyModal_revision_header">
        <div className="verticalCenter">
          <span className="historyModal_revision_header_icon">
            <Icon name="info" color={colors.gray} size={14} />
          </span>
          &nbsp;{t('revisionVersionStatement')}
        </div>
        <Button type={!open ? 'expandMore' : 'less'} tooltip={open ? t('collapse') : t('expand')} />
      </div>
      <Collapse in={open} timeout="auto">
        <div className="historyModal_revision_body">
          <div>&quot;{note}&quot;</div>
          <div className="historyModal_revision_requestor">
            <b>{requestor}</b> {date}
          </div>
        </div>
      </Collapse>
    </div>
  );
};

RevisionNote.propTypes = propTypes;
RevisionNote.defaultProps = defaultProps;

export default RevisionNote;
