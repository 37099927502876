import React from 'react';
import styles from './formField.module.scss';

interface FormFieldErrorProps {
  message: string;
}

const FormFieldError = ({ message }: FormFieldErrorProps) => (
  <p className={styles.errorText}>{message}</p>
);

export default FormFieldError;
