import { gql } from '@apollo/client';
// services
import client from 'services/Client';
import notification from 'services/Notification';
import logger from 'services/Logger';
import { sendEmail } from 'services/Mailer';
// helpers
import { getSubmissionLink } from 'helpers/Link';
// constants
import { SUBMISSION_TAB } from 'constants/pageTabs';

export const ONBOARDING_WITHDRAWAL_NOTIFIED_SUBMISSION = gql`
  mutation candidateWithdrawalNotifiedSubmission($submissionId: ID!) {
    submissionUpdate(
      filter: { id: $submissionId }
      data: { hiringProcess: { update: { withdrawalNotified: true } } }
    ) {
      id
      hiringProcess {
        id
        withdrawalNotified
      }
    }
  }
`;

export const ONBOARDING_WITHDRAWAL_NOTIFIED_PROPOSAL = gql`
  mutation candidateWithdrawalNotifiedProposal($submissionId: ID!) {
    proposalUpdate(
      filter: { id: $submissionId }
      data: { hiringProcess: { update: { withdrawalNotified: true } } }
    ) {
      id
      hiringProcess {
        id
        withdrawalNotified
      }
    }
  }
`;

/**
 * Notify PM about candidate withdrawal
 * Step: Any
 * Role: Match Manager
 *
 * Actions:
 * 1. Update field 'withdrawalNotified' in 'Hiring process' table
 *
 * @param {object} submission
 * @param {object} email Html + email options (to, from, subject..)
 * @param {string} userId Logged in user id
 */
const notifyCandidateWithdrawal = async (submission, email, userId) => {
  try {
    await sendEmail(email);
    await client.mutate({
      mutation: submission.isProposal()
        ? ONBOARDING_WITHDRAWAL_NOTIFIED_PROPOSAL
        : ONBOARDING_WITHDRAWAL_NOTIFIED_SUBMISSION,
      variables: { submissionId: submission.id },
    });

    const link = getSubmissionLink(submission.id, submission.isProposal(), {
      queryParams: {
        tab: SUBMISSION_TAB.ONBOARDING,
      },
    });

    notification.onboardingCancelledByMatchManager({
      submission: submission.id,
      link,
      createdBy: userId,
      isProposal: submission.isProposal(),
    });
  } catch (error) {
    logger.exception(error);
    throw error;
  }
};

export default notifyCandidateWithdrawal;
