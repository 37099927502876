import React from 'react';
// libraries
import PropTypes from 'prop-types';

const propTypes = {
  title: PropTypes.any,
  subTitle: PropTypes.any,
  label: PropTypes.any,
  content: PropTypes.any,
  children: PropTypes.any,
};

const defaultProps = {
  title: null,
  subTitle: null,
  label: null,
  content: null,
  children: null,
};

const TextContent = ({ title, subTitle, label, content, children }) => {
  return (
    <div
      className={`${!content && !label ? 'p-t-20' : ''} textContent textContent_info display-flex`}
    >
      <div className="textContent_header">{title}</div>
      {subTitle && <div className="textContent_label">{subTitle}</div>}
      {label && <div className="textContent_label">{label}</div>}
      {content || children}
    </div>
  );
};

TextContent.propTypes = propTypes;
TextContent.defaultProps = defaultProps;

export default TextContent;
