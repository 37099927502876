import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// constants
import { MRI_LEVEL, MRI_IMPACT } from 'constants/report';
// helpers
import { getLevelDetails } from 'helpers/MRI';
// components
import TruncatedText from 'components/atoms/TruncatedText';
import Chip from 'components/atoms/NewChip';
import ImpactChip from './ImpactChip';
// styles
import styles from './mriImprovements.module.scss';

const propTypes = {
  title: PropTypes.string.isRequired,
  impact: PropTypes.oneOf([MRI_IMPACT.LOW, MRI_IMPACT.MEDIUM, MRI_IMPACT.HIGH]).isRequired,
};

const GoodSection = ({ title, impact }) => {
  const levelDetails = getLevelDetails(MRI_LEVEL.HIGH);
  const { color } = levelDetails;

  return (
    <div className={classNames(styles.section, styles[color])}>
      <div className="flex_space">
        <div className={styles.sectionHeader}>
          <TruncatedText>{title}</TruncatedText>
        </div>
        <div className={styles.impactBox}>
          <div className="m-r-5">
            <ImpactChip impact={impact} />
          </div>
          <div className={styles.penalty}>
            <Chip color={color} className={styles.chip}>
              {levelDetails.label}
            </Chip>
          </div>
        </div>
      </div>
    </div>
  );
};

GoodSection.propTypes = propTypes;

export default GoodSection;
