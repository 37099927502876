/* eslint-disable import/order */
import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// hooks
import useAuth from 'hooks/useAuth';
// components
import Score from 'components/atoms/Score';
import Icon from 'components/atoms/Icon';
import TextSection from 'components/atoms/TextSection';
import Link from 'components/atoms/Link';
import LinkButton from 'components/atoms/LinkButton';
import ExcellentMatchIcon from 'components/molecules/ExcellentMatchIcon';
import SubmissionStatusFlow from 'components/molecules/SubmissionStatusFlow';
import { MatchMetricsPopper } from 'components/organisms/MatchMetrics';
import Section from './Section';
// constants
import { POSITION_TAB } from 'constants/pageTabs';
import {
  STAFF_ROLE,
  SUPPLIER_ROLE,
  MANAGER_ROLE,
  RATES_SUPPLIER_RATE,
  RATES_SELL_PRICE,
} from 'constants';
// helpers
import { getScoreColor } from 'helpers/Colors';
// styles
import styles from './submissionMainInfo.module.scss';

const propTypes = {
  submission: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
};

const SubmissionMainInfo = ({ submission, position }) => {
  const { role } = useAuth();
  const { t } = useTranslation();
  const history = useHistory();

  const displayExcellentMatch = submission.isExcellentMatch() && submission.hasEnoughDataPoints();
  const scoreColor = getScoreColor(submission.score);
  const sellRate = submission.getRate(RATES_SELL_PRICE);
  const partnerRate = submission.getRate(RATES_SUPPLIER_RATE);

  const navigateToPosition = () => {
    const positionLinkParams = role === STAFF_ROLE ? `?tab=${POSITION_TAB.RATES}` : '';
    history.push(`/position/${position.id}${positionLinkParams}`);
  };

  const formatRate = rate => {
    return `${rate.currencyLabel}${rate.value}/hr`;
  };

  return (
    <React.Fragment>
      <div className={classNames(styles.banner, styles[scoreColor])} />
      <div className={styles.content}>
        <Section className={styles.scoreSection}>
          <div className={styles.score}>
            <MatchMetricsPopper
              metrics={submission.getMetrics()}
              score={submission.score}
              candidateName={submission.name}
              positionTitle={position.title}
              project={position.project.name}
              occupations={position.getOccupationsName()}
              experienceLevel={position.experienceLevel.value}
              placement="right-start"
              offsetX={15}
            >
              <Score
                score={submission.score}
                size={90}
                strokeWidth={6}
                icon={displayExcellentMatch ? <ExcellentMatchIcon bordered size={25} /> : null}
              />
            </MatchMetricsPopper>
          </div>
          <div className={styles.name}>{submission.candidate.name}</div>
          <div className="m-t-15">
            <LinkButton className={styles.positionLink} onClick={navigateToPosition}>
              <div className={styles.rfq}>{position.rfqLabel}</div>
              {position.title}
            </LinkButton>
          </div>
          <div className="m-t-20">
            <SubmissionStatusFlow submission={submission} />
          </div>
          <div className="m-t-20">
            <Link
              href={`/report/match/${submission.report.id}`}
              className={classNames(styles.reportLink, 'fs-unmask')}
            >
              <Icon name="openInNew" size={16} />
              <span className="m-l-2">{t('viewMatchReport')}</span>
            </Link>
          </div>
        </Section>

        <Section>
          <TextSection title={t('project')} icon="project">
            {position.client.name}, {position.project.name}
          </TextSection>
          <div className="m-t-20">
            <TextSection title={t('location')} icon="location">
              {position.getLocation()}
            </TextSection>
          </div>
          {role === STAFF_ROLE && (
            <div className="m-t-20">
              <TextSection title={t('company')} icon="company">
                {submission.partner.name}
              </TextSection>
            </div>
          )}
          {role === STAFF_ROLE && (
            <React.Fragment>
              <div className="m-t-20">
                <TextSection title={t('partnerRate')} icon="money">
                  {formatRate(partnerRate)}
                </TextSection>
              </div>
              <div className="m-t-20">
                <TextSection title={t('mySellPrice')} icon="money">
                  {sellRate ? formatRate(sellRate) : <i>{t('notProvided')}</i>}
                </TextSection>
              </div>
            </React.Fragment>
          )}
          {role === SUPPLIER_ROLE && (
            <div className="m-t-20">
              <TextSection title={t('mySellRate')} icon="money">
                {formatRate(partnerRate)}
              </TextSection>
            </div>
          )}
          {role === MANAGER_ROLE && (
            <div className="m-t-20">
              <TextSection title={t('sellRate')} icon="money">
                {formatRate(sellRate)}
              </TextSection>
            </div>
          )}
        </Section>
      </div>
    </React.Fragment>
  );
};

SubmissionMainInfo.propTypes = propTypes;

export default SubmissionMainInfo;
