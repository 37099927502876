import React, { Suspense } from 'react';
// components
import Loader from 'components/atoms/Loader';
import ErrorBoundary from 'components/molecules/ErrorBoundary';

// lazy components
const Editor = React.lazy(() => import(/* webpackChunkName: "ckEditor" */ './Editor'));

const CKEditor = props => (
  <ErrorBoundary>
    <Suspense fallback={<Loader />}>
      <Editor {...props} />
    </Suspense>
  </ErrorBoundary>
);

export default CKEditor;
