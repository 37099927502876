import React from 'react';
import PropTypes from 'prop-types';
// hooks
import { useTranslation } from 'react-i18next';
// components
import AvatarList from 'components/molecules/AvatarList';
// constants
import { SHAPE_OPTIONS } from 'components/atoms/Avatar';
// styles
import styles from './publishBanner.module.scss';

const propTypes = {
  publishedToAll: PropTypes.bool.isRequired,
  publishAudience: PropTypes.array.isRequired,
};

const Audience = ({ publishedToAll, publishAudience }) => {
  const { t } = useTranslation();
  const audience = publishAudience.map(({ company }) => company);

  if (publishedToAll) {
    return <b>{t('publishedToAllLabel')}</b>;
  }

  return (
    <div className={styles.audienceInfo}>
      <b>{t('positionPublishedTo')}</b>
      &nbsp;
      <AvatarList
        items={audience}
        limit={publishAudience.length}
        avatarShape={SHAPE_OPTIONS.HEXAGON}
        size={8}
      />
    </div>
  );
};

Audience.propTypes = propTypes;

export default Audience;
