import React from 'react';
// libraries
import PropTypes from 'prop-types';
import CircularProgressbar from 'react-circular-progressbar';
import classNames from 'classnames';
// helpers
import { getScoreColor } from 'helpers/Colors';
import { roundNumber } from 'helpers/Number';
// constants
import { COLOR } from 'constants/colors';
// styles
import styles from './score.module.scss';

const propTypes = {
  score: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.number,
  strokeWidth: PropTypes.number,
  white: PropTypes.bool,
  icon: PropTypes.any,
  outlined: PropTypes.bool,
};

const defaultProps = {
  score: null,
  size: 38,
  strokeWidth: 9,
  white: false,
  icon: null,
  outlined: false,
};

const Score = ({ score, size, strokeWidth, white, icon, outlined }) => {
  const parsedScore = parseInt(score);
  const validScore = Number.isInteger(parsedScore);
  const roundedScore = roundNumber(score, 1);
  const color = white ? COLOR.WHITE : getScoreColor(roundedScore);

  return (
    <div
      className={classNames(styles.wrapper, styles[color], { [styles.outlined]: outlined })}
      style={{ '--size': size }}
    >
      <CircularProgressbar
        percentage={validScore ? roundedScore : 0}
        textForPercentage={pct => `${validScore ? pct : '?'}`}
        strokeWidth={strokeWidth}
        classes={{
          root: styles.container,
          path: styles.path,
          trail: styles.trail,
          text: styles.text,
        }}
      />
      {icon && <div className={styles.icon}>{icon}</div>}
    </div>
  );
};

Score.propTypes = propTypes;
Score.defaultProps = defaultProps;

export default Score;
