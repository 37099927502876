import React, { useState, useEffect, useContext } from 'react';
// libraries
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';
import getProperty from 'lodash/get';
import Collapse from '@material-ui/core/Collapse';
// hooks
import useToggle from 'hooks/useToggle';
import usePrevious from 'hooks/usePrevious';
// context
import ToastContext from 'context/ToastContext';
// services
import { replaceCandidateCV, getCandidate } from 'services/Report';
// components
import Icon from 'components/atoms/Icon';
import Button from 'components/atoms/Button';
import Link from 'components/atoms/Link';
import LinkButton from 'components/atoms/LinkButton';
import Modal from 'components/molecules/ModalWindow';
import FileUpload from 'components/molecules/FileUpload';
import MRILevelChip from 'components/molecules/MRILevelChip';
import MRIImprovements from 'components/molecules/MRIImprovements';
import { ErrorDialog } from 'components/molecules/Dialogs';
import Banner, { BANNER_TYPE } from 'components/molecules/Banner';
import IdentityMatch from 'components/molecules/IdentityMatch';
// constants
import { CV_FORMAT_MANUAL_LINK } from 'constants';
import { CV_TEMPLATE_LINK } from 'constants/box';
import { SH_ERRORS } from 'constants/error';
import { SUPPORTED_CV_FORMATS } from 'constants/file';
// helpers
import { boxFileUrl } from 'helpers/Box';
import { getLevelByScore } from 'helpers/MRI';
import { resolveCandidateIdentity } from 'helpers/IdentityResolver';
import { fetchData } from './helpers';
// styles
import styles from './replaceCandidateCvModal.module.scss';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  submitting: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onReplace: PropTypes.func,
  onSubmit: PropTypes.func,
  checkIdentity: PropTypes.bool,
  candidate: PropTypes.shape({
    id: PropTypes.string,
    shCandidateId: PropTypes.string.isRequired,
    shPersonId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    supplierName: PropTypes.string.isRequired,
    supplierId: PropTypes.string.isRequired,
  }).isRequired,
};

const defaultProps = {
  submitting: false,
  loading: false,
  checkIdentity: true,
  onSubmit: null,
  onReplace: null,
};

const ReplaceCandidateCvModal = ({
  isOpen,
  submitting,
  loading,
  checkIdentity,
  onClose,
  onReplace,
  onSubmit,
  candidate,
}) => {
  const { t } = useTranslation();
  const { addToast } = useContext(ToastContext);
  const prevIsOpen = usePrevious(isOpen);

  const [cv, setCv] = useState(null);
  const [candidateData, setCandidateData] = useState(null);
  const [newReport, setNewReport] = useState(null);
  const [missingCv, setMissingCv] = useState(false);
  const [identityMismatch, setIdentityMismatch] = useState(false);
  const [hasInvalidFormat, setInvalidFormatError] = useState(false);
  const [analysing, setAnalysing] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [reportCollapsed, toggleReport] = useToggle(true);
  const [newReportCollapsed, toggleNewReport] = useToggle(true);

  const score = getProperty(candidateData, 'mri.score', 0) || 0;
  const badSections = getProperty(candidateData, 'mri.badSections', []) || [];
  const newScore = getProperty(newReport, 'mri.score', 0) || 0;
  const newBadSections = getProperty(newReport, 'mri.badSections', []) || [];

  useEffect(() => {
    const handleModalClose = () => {
      // reset state after close
      setCv(null);
      setNewReport(null);
      setMissingCv(false);
      setInvalidFormatError(false);
      setAnalysing(false);

      if (!newReportCollapsed) {
        toggleNewReport();
      }
    };

    if (prevIsOpen && !isOpen) {
      handleModalClose();
    }
  }, [isOpen]);

  useEffect(() => {
    const fetchDataFn = async () => {
      try {
        setFetchingData(true);
        const data = await fetchData(candidate.shCandidateId, candidate.id);
        setCandidateData(data);
      } catch (error) {
        addToast.error(t('errorPlaceholderText'));
      } finally {
        setFetchingData(false);
      }
    };

    if (isOpen && candidate.shCandidateId) {
      fetchDataFn();
    }
  }, [isOpen, candidate.shCandidateId]);

  const handleFileChange = async files => {
    if (!files.length) {
      setIdentityMismatch(false);
      setCv(null);
      setNewReport(null);
      return;
    }

    try {
      const newCv = files[0];

      setCv(newCv);
      setMissingCv(false);
      setAnalysing(true);

      const pendingReport = await replaceCandidateCV(
        newCv.file,
        candidate.supplierName,
        candidate.supplierId,
        candidate.id
      );

      if (checkIdentity) {
        const [resolvedCandidateId, candidateResponse] = await Promise.all([
          resolveCandidateIdentity(
            candidate.supplierId,
            pendingReport.shPersonId,
            pendingReport.shCandidateId
          ),
          getCandidate(pendingReport.shCandidateId),
        ]);

        // Prevent replacing candidate with a different identity
        if (candidate.id && candidate.id !== resolvedCandidateId) {
          setIdentityMismatch(true);
        }

        setNewReport({
          ...pendingReport,
          candidate: {
            name: getProperty(candidateResponse, 'person.name', ''),
            email: getProperty(candidateResponse, 'person.email', ''),
            phone: getProperty(candidateResponse, 'person.phone', ''),
          },
        });
      }

      if (!checkIdentity && pendingReport.isExisting) {
        addToast.warning(t('existingCandidateWarning'));
        setCv(null);
        return;
      }

      setCv({ ...newCv, link: boxFileUrl(pendingReport.boxId) });
    } catch (error) {
      setCv(null);

      if (error.type === SH_ERRORS.UNPROCESSABLE_ENTITY) {
        setInvalidFormatError(true);
      } else {
        addToast.error(t('errorPlaceholderText'));
      }
    } finally {
      setAnalysing(false);
    }
  };

  const handleSubmit = () => {
    if (checkIdentity && identityMismatch) {
      addToast.error(t('candidateMismatchIdentityError'));
      return;
    }

    if (!cv) {
      setMissingCv(true);
      return;
    }

    onSubmit(newReport.id, newReport, cv);
  };

  const handleReplace = () => {
    if (checkIdentity && identityMismatch) {
      addToast.error(t('candidateMismatchIdentityError'));
      return;
    }

    if (!cv) {
      setMissingCv(true);
      return;
    }

    onReplace({ ...newReport, file: { name: cv.name } });
  };

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      onClose={onClose}
      loading={loading || submitting || fetchingData}
      loadingText={loading || fetchingData ? t('fetchingDataLoader') : t('replacingLoader')}
    >
      <Modal.Header disableClose={analysing}>
        <Trans i18nKey="replaceCVTitle">Replace CV for {{ candidate: candidate.name }}</Trans>
      </Modal.Header>
      <Modal.Body>
        <div className={classNames(styles.banner, styles.bordered)}>
          <div className={styles.bannerLeft}>
            <div className={styles.reportLinkWrapper}>
              <MRILevelChip level={getLevelByScore(score)} className="m-t-4" />
            </div>
          </div>
          <LinkButton onClick={toggleReport} className={styles.reportLink}>
            <span className="m-r-2">{t('viewCVReadability')}</span>
            <Icon name={reportCollapsed ? 'expandMore' : 'less'} size={16} />
          </LinkButton>
        </div>
        <Collapse in={!reportCollapsed} timeout="auto">
          <div className={styles.mri}>
            <div className={styles.mriTitle}>{t('improveCvReadability')}</div>
            <MRIImprovements badSections={badSections} hideSectionsDescription />
          </div>
        </Collapse>

        <div className={styles.upload}>
          <Trans
            i18nKey="replaceCvDescription"
            components={[
              <Link href={CV_TEMPLATE_LINK} className={styles.link}>
                {t('cvTemplate')}
              </Link>,
              <Link href={CV_FORMAT_MANUAL_LINK} className={styles.link}>
                {t('howToFormatCv')}
              </Link>,
            ]}
          />
          <div className="m-t-12">
            <FileUpload
              selected={cv ? [cv] : []}
              onChange={handleFileChange}
              loading={analysing}
              error={missingCv}
              accept={SUPPORTED_CV_FORMATS}
            />
            {missingCv && <div className={styles.error}>* {t('cvIsRequired')}</div>}
            <div className={styles.uploadLegalNote}>
              <Trans
                i18nKey="analyseCvLegalNote"
                components={[
                  <Link href="/legal/terms" className={styles.link}>
                    {t('termsOfUse')}
                  </Link>,
                  <Link href="/legal/privacy" className={styles.link}>
                    {t('privacyPolicy')}
                  </Link>,
                ]}
              />
            </div>
          </div>

          {newReport && (
            <React.Fragment>
              <div className={styles.banner}>
                <div className={styles.bannerLeft}>
                  <div className={styles.reportLinkWrapper}>
                    <MRILevelChip level={getLevelByScore(newScore)} className="m-t-4" />
                  </div>
                </div>
                <LinkButton onClick={toggleNewReport} className={styles.reportLink}>
                  <span className="m-r-2">{t('viewCVReadability')}</span>
                  <Icon name={newReportCollapsed ? 'expandMore' : 'less'} size={16} />
                </LinkButton>
              </div>
              <Collapse in={!newReportCollapsed} timeout="auto">
                <div className={styles.mri}>
                  <div className={styles.mriTitle}>{t('improveCvReadability')}</div>
                  <MRIImprovements badSections={newBadSections} hideSectionsDescription />
                </div>
              </Collapse>
            </React.Fragment>
          )}

          {identityMismatch && newReport && (
            <React.Fragment>
              <div className="m-t-15">
                <IdentityMatch currentIdentity={candidateData} newIdentity={newReport.candidate} />
              </div>
              <div className="m-t-10">
                <Banner type={BANNER_TYPE.WARNING}>{t('candidateMismatchIdentityError')}</Banner>
              </div>
            </React.Fragment>
          )}

          <ErrorDialog
            isOpen={hasInvalidFormat}
            title={t('wrongCvFormat')}
            label={t('tryAgain')}
            secondaryLabel={t('cancel')}
            onClose={() => setInvalidFormatError(false)}
            onClick={() => setInvalidFormatError(false)}
            onSecondaryClick={() => {
              setInvalidFormatError(false);
              onClose();
            }}
          >
            <div className="text-center">{t('cvFormatNotSupportedCaption')}</div>
          </ErrorDialog>
        </div>

        <Modal.Footer className={styles.footer}>
          <div className="m-r-12">
            <Button label={t('cancel')} onClick={onClose} disabled={analysing} secondary />
          </div>
          {onSubmit && (
            <div className="m-r-12">
              <Button label={t('useNewCV')} disabled={analysing} onClick={handleSubmit} />
            </div>
          )}
          {onReplace && (
            <Button label={t('replaceCV')} disabled={analysing} onClick={handleReplace} />
          )}
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
};

ReplaceCandidateCvModal.propTypes = propTypes;
ReplaceCandidateCvModal.defaultProps = defaultProps;

export default ReplaceCandidateCvModal;
