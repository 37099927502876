/* eslint-disable react/no-array-index-key */
import React from 'react';
// libraries
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// components
import Chip from 'components/atoms/Chip';
import Level from 'components/atoms/Level';
import TextSection from 'components/atoms/TextSection';
// helpers
import { trimLabel } from 'helpers/StringParser';

const propTypes = {
  position: PropTypes.object.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const Requirements = ({ position, className }) => {
  const { t } = useTranslation();
  const { skills, activities, experienceLevel, project } = position;

  const getImportance = isRequired => {
    const text = isRequired ? t('required') : t('desired');

    return trimLabel(text, 3);
  };

  const renderExperienceLevel = () => {
    if (!experienceLevel.id) {
      return <i>{t('notProvided')}</i>;
    }

    return project.experienceLevels.map(level => (
      <Level
        key={level.label}
        className="positionDetails_requirements_level"
        title={level.label}
        highlighted={experienceLevel.title === level.label}
      />
    ));
  };

  const renderSkills = () => {
    if (skills.length === 0) {
      return <i>{t('notProvided')}</i>;
    }

    return skills.map(skill => (
      <div key={skill.id} className="display-flex-row m-t-10">
        <div className="m-r-5">
          <Chip
            label={getImportance(skill.required)}
            color="blue"
            type="header"
            className="space-2-8"
          />
        </div>
        {skill.value}
      </div>
    ));
  };

  const renderActivities = () => {
    if (activities.length === 0) {
      return <i>{t('notProvided')}</i>;
    }

    return activities.map(activity => (
      <div key={activity.id} className="display-flex-row m-t-10">
        - {activity.value}
      </div>
    ));
  };

  return (
    <div className={`positionDetails_requirements ${className}`}>
      <TextSection title={t('level')}>
        <div className="positionDetails_requirements_levels">{renderExperienceLevel()}</div>
      </TextSection>
      <div className="m-t-15">
        <TextSection title={t('skills')}>{renderSkills()}</TextSection>
      </div>
      <div className="m-t-15">
        <TextSection title={t('activities')}>{renderActivities()}</TextSection>
      </div>
    </div>
  );
};

Requirements.propTypes = propTypes;
Requirements.defaultProps = defaultProps;

export default Requirements;
