import isInteger from 'lodash/isInteger';

/**
 * @param {string|number} number
 * @param {number} [precision=2] Decimal precision
 * @returns {number}
 */
export const formatNumber = (number, precision = 2) => {
  if (isInteger(number)) return number;
  if (!number) return 0;
  if (!precision) return Math.trunc(number);

  return Number(parseFloat(number).toFixed(precision));
};

/**
 * @param {string|number} number
 * @param {number} [step=0.5]
 * @returns {number}
 */
export const roundNumber = (number, step = 0.5) => {
  const inv = 1.0 / step;

  return Math.round(Number(number) * inv) / inv;
};

/**
 * @param {string|number} number
 * @param {number} [step=0.5]
 * @returns {number}
 */
export const ceilNumber = (number, step = 0.5) => {
  const inv = 1.0 / step;

  return Math.ceil(Number(number) * inv) / inv;
};

/**
 * Return human readable formatted number. 10000 -> 10 000
 * @param {number} number
 * @returns {number}
 */
export const prettyNumber = number => {
  return number.toLocaleString('en-US').replace(',', ' ');
};
