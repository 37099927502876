import React from 'react';
// libraries
import PropTypes from 'prop-types';
// styles
import styles from './candidatePage.module.scss';

const propTypes = {
  children: PropTypes.any.isRequired,
};

const Card = ({ children }) => {
  return <div className={styles.card}>{children}</div>;
};

Card.propTypes = propTypes;

export default Card;
