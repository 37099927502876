/* eslint-disable import/order */
import React, { useState, useEffect, useRef, useContext } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash/isEqual';
import getProperty from 'lodash/get';
// hooks
import usePrevious from 'hooks/usePrevious';
import useAuth from 'hooks/useAuth';
import useIsMounted from 'hooks/useIsMounted';
import { useHistory, useLocation } from 'react-router';
// context
import ToastContext from 'context/ToastContext';
// components
import LinkButton from 'components/atoms/LinkButton';
import ModalStepper from 'components/molecules/ModalStepper';
import PositionRatesSettings, { RATE_SETTING } from 'components/molecules/PositionRatesSettings';
import Section from 'components/molecules/FieldSection';
import { Prompt } from 'components/molecules/Dialogs';
import Header from './Header';
import TargetRates from './TargetRates';
import PartnerDueDate from './PartnerDueDate';
import Audience from './Audience';
import Notes from './Notes';
import Review from './Review';
// helpers
import {
  getDefaultTargetRates,
  publishPosition,
  isPartnerDueDateValid as isPartnerDueDateValidHelper,
  getPartnerDueDateErrorMessage,
  getSupplierDueDate,
  getChangedFields,
  parseProgressData,
  saveProgress,
} from './helpers';
// constants
import { NOTE_PUBLISH_NOTES, PARTNER_DUE_DATE_OFFSET } from 'constants';
import { TABS, STEPS } from './constants';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool,
  viewSummary: PropTypes.bool,
  position: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  targetRates: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      year: PropTypes.number.isRequired,
    })
  ),
};

const defaultProps = {
  isEdit: false,
  viewSummary: false,
  targetRates: [],
};

const PublishPosition = ({ isOpen, position, onClose, targetRates, isEdit, viewSummary }) => {
  const { t } = useTranslation();
  const { addToast } = useContext(ToastContext);
  const history = useHistory();
  const location = useLocation();
  const { id: userId } = useAuth();
  const isMounted = useIsMounted();
  const prevIsOpen = usePrevious(isOpen);
  const initialTargetRates = useRef(targetRates);
  const { audience, isPublishedToAll } = position;

  const getInitialData = () => {
    return {
      [STEPS.RATES_SETTING]: {
        [RATE_SETTING.SHOW_TARGET_RATES_TO_PARTNERS]: position.showTargetRatesToPartners,
        [RATE_SETTING.SHOW_NTE_RATES_TO_PARTNERS]: position.showNteRatesToPartners,
        [RATE_SETTING.ALLOW_SUBMISSION_ABOVE_TARGET_RATES]:
          position.allowSubmissionAboveTargetRates,
      },
      [STEPS.TARGET_RATES]: targetRates,
      [STEPS.PARTNER_DUE_DATE]: position.supplierDueDate || getSupplierDueDate(position.dueDate),
      [STEPS.AUDIENCE]: {
        publishedToAll: isPublishedToAll,
        audience: audience.map(({ id, company }) => ({
          value: id,
          label: company,
        })),
      },
      [STEPS.NOTES]: getProperty(position.getNote(NOTE_PUBLISH_NOTES), 'text', ''),
    };
  };

  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [hasError, setError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [activeTab, setActiveTab] = useState('');
  const [tabs, setTabs] = useState(TABS);
  const [data, setData] = useState(getInitialData());
  const [committedProgress, setCommittedProgress] = useState(null);
  const [isPromptOpen, setPromptOpen] = useState(false);

  const setParam = (key, value, callback) => {
    const newData = {
      ...data,
      [key]: value,
    };

    setData(newData);

    if (callback) {
      callback(newData);
    }
  };

  useEffect(() => {
    // if no target rates were provided -> calculate from NTE rate
    if (!targetRates.length) {
      const defaultTargetRates = getDefaultTargetRates(position);

      initialTargetRates.current = defaultTargetRates;
      setParam(STEPS.TARGET_RATES, defaultTargetRates);
    }
  }, []);

  useEffect(() => {
    // if view summary -> navigate to review step on modal open
    if (isOpen && viewSummary) {
      setActiveTab(STEPS.REVIEW);
    }
  }, [viewSummary, isOpen]);

  useEffect(() => {
    if (!prevIsOpen && isOpen) {
      // handle target rates coming from rates simulation in position detail page
      const incomingTargetRates = targetRates.map(rate => rate.value);
      const localTargetRates = data[STEPS.TARGET_RATES].map(rate => rate.value);

      if (incomingTargetRates.length > 0 && !isEqual(incomingTargetRates, localTargetRates)) {
        initialTargetRates.current = targetRates;
        setParam(STEPS.TARGET_RATES, targetRates);
      }

      // set saved progress
      if (position.publishProgress) {
        setActiveTab(position.publishProgress.lastStep);
        setData(position.publishProgress.data);
        setCommittedProgress(position.publishProgress.data);
      }
    }
  }, [isOpen]);

  const getRateSetting = (setting, source = data) => {
    return getProperty(source, [STEPS.RATES_SETTING, setting]);
  };

  const isPartnerDueDateValid = (source = data) => {
    const partnerDueDate = source[STEPS.PARTNER_DUE_DATE];

    return isPartnerDueDateValidHelper(partnerDueDate, position.dueDate);
  };

  const isTargetRatesValid = (source = data) => {
    const targetRatesValue = source[STEPS.TARGET_RATES];
    const showTargetRatesToPartnersValue = getRateSetting(
      RATE_SETTING.SHOW_TARGET_RATES_TO_PARTNERS,
      source
    );

    return !showTargetRatesToPartnersValue || targetRatesValue.every(rate => !rate.error);
  };

  const isAudienceValid = (source = data) => {
    const { publishedToAll, audience: selectedAudience } = source[STEPS.AUDIENCE];
    if (!publishedToAll && !selectedAudience.length) return false;

    return true;
  };

  const handleChange = key => value => {
    setParam(key, value, newData => {
      // handle error state on data change after submission
      if (submitted) {
        const hasErrorAfterDataChange =
          !isTargetRatesValid(newData) ||
          !isPartnerDueDateValid(newData) ||
          !isAudienceValid(newData);

        if (hasErrorAfterDataChange !== hasError) {
          setError(hasErrorAfterDataChange);
        }
      }
    });
  };

  const showTargetRatesToPartners = getRateSetting(RATE_SETTING.SHOW_TARGET_RATES_TO_PARTNERS);
  const showNteRatesToPartners = getRateSetting(RATE_SETTING.SHOW_NTE_RATES_TO_PARTNERS);
  const allowSubmissionAboveTargetRates = getRateSetting(
    RATE_SETTING.ALLOW_SUBMISSION_ABOVE_TARGET_RATES
  );

  const getContent = () => {
    return {
      [STEPS.TARGET_RATES]: (
        <React.Fragment>
          <div className="m-b-30">
            <Section title={t('ratesSettings')}>
              <PositionRatesSettings
                settings={data[STEPS.RATES_SETTING]}
                onChange={handleChange(STEPS.RATES_SETTING)}
                disabled={viewSummary}
              />
            </Section>
          </div>
          <TargetRates
            position={position}
            targetRates={data[STEPS.TARGET_RATES]}
            initialTargetRates={initialTargetRates.current}
            onChange={handleChange(STEPS.TARGET_RATES)}
            disabled={!showTargetRatesToPartners}
            error={submitted && !viewSummary && !isTargetRatesValid()}
            readOnly={viewSummary}
          />
        </React.Fragment>
      ),
      [STEPS.PARTNER_DUE_DATE]: (
        <PartnerDueDate
          value={data[STEPS.PARTNER_DUE_DATE]}
          onChange={handleChange(STEPS.PARTNER_DUE_DATE)}
          positionDueDate={position.dueDate}
          timezone={position.project.timezone}
          error={submitted && !viewSummary && !isPartnerDueDateValid()}
          readOnly={viewSummary}
        />
      ),
      [STEPS.AUDIENCE]: (
        <Audience
          publishedToAll={getProperty(data, [STEPS.AUDIENCE, 'publishedToAll'], true)}
          audience={getProperty(data, [STEPS.AUDIENCE, 'audience'], [])}
          projectId={position.project.id}
          readOnly={viewSummary}
          error={submitted && !viewSummary && !isAudienceValid()}
          onChange={handleChange(STEPS.AUDIENCE)}
        />
      ),
      [STEPS.NOTES]: (
        <Notes
          value={data[STEPS.NOTES]}
          onChange={handleChange(STEPS.NOTES)}
          readOnly={viewSummary}
        />
      ),
      [STEPS.REVIEW]: (
        <Review
          position={position}
          targetRates={data[STEPS.TARGET_RATES]}
          showTargetRatesToPartners={showTargetRatesToPartners}
          showNteRatesToPartners={showNteRatesToPartners}
          allowSubmissionAboveTargetRates={allowSubmissionAboveTargetRates}
          partnerDueDate={data[STEPS.PARTNER_DUE_DATE]}
          audience={data[STEPS.AUDIENCE].audience}
          publishToAll={data[STEPS.AUDIENCE].publishedToAll}
          note={data[STEPS.NOTES]}
          onSectionClick={setActiveTab}
          displayErrors={!viewSummary}
        />
      ),
    };
  };

  const handleSwitch = newTab => {
    // handle error state for tabs after submission
    if (submitted && !viewSummary) {
      const tabsWithError = [];
      const targetRatesTab = tabs.find(tab => tab.id === STEPS.TARGET_RATES);
      const partnerDueDateTab = tabs.find(tab => tab.id === STEPS.PARTNER_DUE_DATE);

      // rates re-validation
      if (targetRatesTab && !isTargetRatesValid()) {
        tabsWithError.push(STEPS.TARGET_RATES);
      }

      // partner due date re-validation
      if (partnerDueDateTab && !isPartnerDueDateValid()) {
        tabsWithError.push(STEPS.PARTNER_DUE_DATE);
      }

      if (!isAudienceValid()) {
        tabsWithError.push(STEPS.AUDIENCE);
      }

      if (tabsWithError.length > 0 || tabs.some(tab => tab.error)) {
        setTabs(
          tabs.map(tab => ({
            ...tab,
            error: tabsWithError.includes(tab.id),
          }))
        );
      }
    }

    setActiveTab(newTab);
  };

  const validate = () => {
    const tabsWithError = [];
    const partnerDueDate = data[STEPS.PARTNER_DUE_DATE];

    // validate target rates
    if (!isTargetRatesValid()) {
      tabsWithError.push(STEPS.TARGET_RATES);
    }

    // validate partner due date
    if (!isPartnerDueDateValid()) {
      addToast.error(getPartnerDueDateErrorMessage(partnerDueDate, position.dueDate));
      tabsWithError.push(STEPS.PARTNER_DUE_DATE);
    }

    if (!isAudienceValid()) {
      tabsWithError.push(STEPS.AUDIENCE);
    }

    // set validated data and tabs
    if (tabsWithError.length > 0) {
      setActiveTab(tabsWithError[0]);
      setTabs(
        tabs.map(tab => ({
          ...tab,
          error: tabsWithError.includes(tab.id),
        }))
      );

      addToast.error(t('notValidFields'));

      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    const isValid = validate();

    if (!isValid) {
      setError(true);
      setSubmitted(true);
      return;
    }

    try {
      setError(false);
      setLoading(true);
      await publishPosition(position, userId, {
        partnerDueDate: data[STEPS.PARTNER_DUE_DATE],
        publishNote: data[STEPS.NOTES],
        targetRates: data[STEPS.TARGET_RATES],
        audience: data[STEPS.AUDIENCE].audience,
        publishToAll: data[STEPS.AUDIENCE].publishedToAll,
        showTargetRatesToPartners,
        showNteRatesToPartners,
        allowSubmissionAboveTargetRates,
      });

      addToast.success(isEdit ? t('publishUpdate') : t('publishSuccess'));

      // Already on single position page
      if (location.pathname.includes('/position/')) {
        onClose();
      } else {
        history.push(`/position/${position.id}`);
      }
    } catch (error) {
      addToast.error(t('errorPlaceholderText'));
    } finally {
      if (isMounted()) {
        setLoading(false);
      }
    }
  };

  const getModalTitle = () => {
    if (viewSummary) return t('viewPublishInfoTitle');
    if (isEdit) return t('editPublishInfoTitle');

    return t('publishPosition');
  };

  const publishInformationHasChanged = () => {
    return (
      getChangedFields(position, {
        partnerDueDate: data[STEPS.PARTNER_DUE_DATE],
        publishNote: data[STEPS.NOTES],
        targetRates: data[STEPS.TARGET_RATES],
        audience: data[STEPS.AUDIENCE].audience,
        publishToAll: data[STEPS.AUDIENCE].publishedToAll,
        showTargetRatesToPartners,
        showNteRatesToPartners,
        allowSubmissionAboveTargetRates,
      }).length > 0
    );
  };

  const handleSaveProgress = async () => {
    try {
      setLoading(true);
      setLoadingText(t('savingProgressLoader'));
      await saveProgress(position.id, parseProgressData(data), activeTab);
      onClose();
      addToast.success(t('savePositionPublishProgressSuccess'));
    } catch (error) {
      addToast.error(t('errorPlaceholderText'));
    } finally {
      setLoading(false);
      setLoadingText('');
    }
  };

  const hasProgress = () => {
    const progressData = parseProgressData(data);

    return publishInformationHasChanged() && !isEqual(committedProgress, progressData);
  };

  const resetState = () => {
    setData(getInitialData());
    setTabs(TABS);
    setActiveTab('');
  };

  const handleClose = () => {
    // if has no saved progress -> show prompt
    if (hasProgress()) {
      setPromptOpen(true);
      return;
    }

    // if edit -> reset changes
    if (isEdit) {
      resetState();
    }

    onClose();
  };

  const handleDiscard = () => {
    setPromptOpen(false);
    resetState();
    onClose();
  };

  const handleSave = () => {
    setPromptOpen(false);
    handleSaveProgress();
  };

  const renderSaveProgressButton = () => {
    if (hasProgress()) {
      return (
        <LinkButton onClick={handleSaveProgress} className="f-s-15">
          {t('saveProgressAndExit')}
        </LinkButton>
      );
    }

    return null;
  };

  return (
    <React.Fragment>
      <ModalStepper
        isOpen={isOpen}
        title={getModalTitle()}
        header={<Header position={position} />}
        footer={renderSaveProgressButton()}
        tabs={tabs}
        activeTab={activeTab}
        error={hasError}
        loading={loading}
        loadingText={loadingText}
        content={getContent()}
        onSwitch={handleSwitch}
        submitDisabled={viewSummary || (isEdit && !publishInformationHasChanged())}
        onSubmit={handleSubmit}
        onClose={handleClose}
        closeOnBackdrop={false}
        submitButtonLabel={t('publish')}
        newDesign
      />
      <Prompt
        isOpen={isPromptOpen}
        title={t('saveProgress')}
        label={t('save')}
        onClose={() => setPromptOpen(false)}
        onClick={handleSave}
        secondaryLabel={t('discard')}
        onSecondaryClick={handleDiscard}
      >
        {t('pendingProgressPrompt')}
      </Prompt>
    </React.Fragment>
  );
};

PublishPosition.propTypes = propTypes;
PublishPosition.defaultProps = defaultProps;

export default PublishPosition;
