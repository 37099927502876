import React from 'react';
// libraries
import PropTypes from 'prop-types';
// components
import Icon from 'components/atoms/Icon';
// helpers
import { getIconType, getColorByFileType } from './helpers';
// styles
import styles from './fileIcon.module.scss';

const propTypes = {
  /** File extension or file name */
  fileExtension: PropTypes.string.isRequired,
  size: PropTypes.number,
  disabled: PropTypes.bool,
};

const defaultProps = {
  size: 18,
  disabled: false,
};

const FileIcon = ({ fileExtension, size, disabled }) => {
  const extension = fileExtension.indexOf('.') ? fileExtension.split('.').pop() : fileExtension;
  const iconType = getIconType(extension);
  const iconColor = getColorByFileType(iconType);

  return (
    <span className={disabled ? styles.gray : styles[iconColor]}>
      <Icon name={iconType} size={size} />
    </span>
  );
};

FileIcon.propTypes = propTypes;
FileIcon.defaultProps = defaultProps;

export default FileIcon;
