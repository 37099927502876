import React, { useMemo } from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// components
import Modal from 'components/molecules/Modal';
import Header from './Header';
import Body from './Body';
import Footer from './Footer';
import Buttons from './Buttons';
// context
import { ModalProvider } from './ModalContext';
// styles
import styles from './modalWindow.module.scss';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', 'full']),
  closeOnEsc: PropTypes.bool,
  closeOnBackdrop: PropTypes.bool,
  className: PropTypes.string,
};

const defaultProps = {
  loading: false,
  loadingText: '',
  size: 'lg',
  closeOnEsc: true,
  closeOnBackdrop: false,
  className: '',
};

const ModalWindow = ({
  isOpen,
  onClose,
  children,
  loading,
  loadingText,
  size,
  closeOnEsc,
  closeOnBackdrop,
  className,
  ...props
}) => {
  const context = useMemo(() => {
    return {
      loading,
      loadingText,
      onClose,
      size,
    };
  }, [loading, loadingText]);

  return (
    <Modal
      isOpen={isOpen}
      size={size}
      onClose={onClose}
      closeOnEsc={loading ? false : closeOnEsc}
      closeOnBackdrop={loading ? false : closeOnBackdrop}
      {...props}
    >
      <ModalProvider value={context}>
        <div className={classNames(styles.container, className)}>{children}</div>
      </ModalProvider>
    </Modal>
  );
};

ModalWindow.propTypes = propTypes;
ModalWindow.defaultProps = defaultProps;
ModalWindow.Header = Header;
ModalWindow.Body = Body;
ModalWindow.Footer = Footer;
ModalWindow.Buttons = Buttons;

export default ModalWindow;
