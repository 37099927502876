// Types
export const CANDIDATE_TYPE = 'CANDIDATE';
export const PROPOSAL_TYPE = 'PROPOSAL';
export const CANDIDATE_CREATE_TYPE = 'CANDIDATE_CREATE';
export const POSITION = 'POSITION';
export const POSITION_REPORT_TYPE = 'POSITION';
export const POSITION_TYPE_FIXED_PRICE = 'POSITION_FIXED_PRICE';
export const SINGLE_POSITION_TYPE = 'SINGLE_POSITION_TYPE';
export const LAZY_PAGE_TYPE = 'LAZY_PAGE_TYPE';
export const REPORT_TYPE = 'REPORT_TYPE';
export const ANALYSIS_TYPE = 'ANALYSIS';
export const SUBMISSION_TYPE = 'SUBMISSION';
export const PREVIEW_TYPE = 'PREVIEW';
export const MATCH_TYPE = 'MATCH';
export const USER_TYPE = 'USER';
export const CLIENT_TYPE = 'CLIENT';
export const PROJECT_TYPE = 'PROJECT';
export const LOCATION_TYPE = 'LOCATION';
export const FORM_TYPE = 'FORM';
export const COMMON_TYPE = 'COMMON';
export const DRAFT_TYPE = 'DRAFT';
export const IMPORT_TYPE = 'IMPORT';

// Roles
export const ADMIN_ROLE = 'ADMIN';
export const STAFF_ROLE = 'MATCH_MANAGER';
export const SUPPLIER_ROLE = 'SUPPLIER';
export const MANAGER_ROLE = 'MANAGER';

// File Types
export const FILE_CV = 'CV';
export const FILE_RELEASABLE_CV = 'RELEASABLE_CV';
export const FILE_PROPOSAL = 'PROPOSAL';
export const FILE_RELEASABLE_PROPOSAL = 'RELEASABLE_PROPOSAL';
export const FILE_HR72 = 'HR72';
export const FILE_ATTESTATION = 'ATTESTATION';
export const FILE_RELEASABLE_ATTESTATION = 'RELEASABLE_ATTESTATION';
export const FILE_IBM_HR = 'IBM_HR';
export const FILE_POSITION_DOCUMENT = 'POSITION_DOCUMENT';
export const FILE_JOB_OFFER = 'JOB_OFFER';
export const FILE_SIGNED_JOB_OFFER = 'SIGNED_JOB_OFFER';
export const FILE_PURCHASE_ORDER = 'PURCHASE_ORDER';
export const FILE_RATE_CARD = 'RATE_CARD';

// Filter Types
export const FILTER_TYPE_STATUS = 'FILTER_TYPE_STATUS';

// Filter constants
export const IMPORT_FILTER_TIME_LIMIT = {
  amount: 15,
  unit: 'm',
};

// Candidate Role Types
export const SUBMITTER = 'SUBMITTER';
export const REVIEWER = 'REVIEWER';

// Rate Types
export const RATES_SUPPLIER_RATE = 'SUPPLIER_RATE';
export const RATES_SELL_PRICE = 'SELL_PRICE';
export const RATES_TARGET_RATE = 'TARGET_RATE';
export const RATES_NTE_RATE = 'NTE_RATE';
export const RATES_TOTAL_SUBMISSION_PRICE = 'TOTAL_SUBMISSION_PRICE';
export const RATES_PROPOSED_REVISION_RATE = 'PROPOSED_REVISION_RATE';
export const RATES_EXPECTED_RATE = 'CANDIDATE_EXPECTED_RATE';

// Note Types
export const NOTE_IBM_EXPLANATION = 'EX_IBM_EXPLANATION';
export const NOTE_CANDIDATE_COMMENT = 'CANDIDATE_COMMENT';
export const NOTE_CLEARANCE = 'CLEARANCE_NOTES';
export const NOTE_PUBLISH_NOTES = 'PUBLISH_NOTES';
export const NOTE_POSITION_STATUS = 'POSITION_STATUS';
export const NOTE_SUBMISSION_STATUS = 'CANDIDATE_STATUS';
export const NOTE_REVISION_TYPE = 'REVISION_TYPE';
export const NOTE_OFFER = 'OFFER_NOTE';
export const NOTE_ONBOARDING_CANCELLED = 'REJECTION_REASON';
export const NOTE_POSITION = 'POSITION_NOTE';

// Work arrangement
export const WORK_ARRANGEMENT_FULL_TIME = 'FULL_TIME';
export const WORK_ARRANGEMENT_PART_TIME = 'PART_TIME';

export const CURRENT_ACTIVITY = 'CURRENT_ACTIVITY';

// Requirement Type
export const REQUIREMENT_ACTIVITY = 'ACTIVITY';
export const REQUIREMENT_SKILL = 'SKILL';

// Actor Types
export const ACTOR_PRIMARY_OWNER = 'PRIMARY_OWNER';
export const ACTOR_REQUESTER = 'REQUESTER';
export const ACTOR_PROJECT_MANAGER = 'PROJECT_MANAGER';
export const ACTOR_WATCHER = 'WATCHER';

// Component Types
export const SELECT_TYPE = 'select';

// Method
export const METHOD_BEST_VALUE = 'BEST_VALUE';
export const METHOD_LOWEST_PRICE = 'LOWEST_PRICE';

// Generic statuses
export const STATUS_ACTIVE = 'ACTIVE';
export const STATUS_FINISHED = 'FINISHED';

// Views
export const VIEW_TABLE = 'TABLE';
export const VIEW_CARD = 'CARD';

// Numbers
export const LIMIT_SIZE = 8;
export const LIMIT_SIZE_TABLE = 20;

// Environment
// eslint-disable-next-line prefer-destructuring
export const IS_DEVELOPMENT = window.process.env.IS_DEVELOPMENT;

// Imports
// eslint-disable-next-line prefer-destructuring
export const IMPORTS_PROJECT_ID = window.process.env.IMPORTS_PROJECT_ID;

// Action Types
export const ACTION_DELETE = 'delete';
export const ACTION_EDIT = 'edit';
export const ACTION_CREATE_NEW = 'createNew';

// Toast timeout in ms
export const TOAST_TIMEOUT = 7000;

// Report Identifiers
export const REPORT_PAGE_SKILLS = 'skillMatchSummary';
export const REPORT_PAGE_CV = 'annotatedCV';
export const REPORT_PAGE_SOW = 'annotatedSOW';

// List of graphql operations which need to store items count in cache
export const COUNT_DRAFT = 'draftsList';
export const COUNT_POSITION = 'positionsList';
export const COUNT_SUBMISSIONS = 'submissionsList';
export const COUNT_PROPOSALS = 'proposalsList';
export const COUNT_CANDIDATES = 'candidatesList';
const COUNT_CANDIDATE_MATCHES_LIST = 'candidateMatchesList';
export const LIST_COUNT_OPERATIONS = [
  COUNT_POSITION,
  COUNT_CANDIDATES,
  COUNT_SUBMISSIONS,
  COUNT_DRAFT,
  'projectsList',
  COUNT_CANDIDATE_MATCHES_LIST,
];

// Component types
export const COMPONENT_ACTIVITIES = 'activities';
export const COMPONENT_SKILLS = 'skills';
export const COMPONENT_FILE = 'file';

// Sort Types
export const SORT_SCORE = 'score';
export const SORT_CV_DATE = 'cvDate';
export const SORT_DUE_DATE = 'dueDate';
export const SORT_AVAILABILITY = 'availability';
export const SORT_SUBMISSION_PRICE = 'submissionPrice';
export const SORT_FORM_TYPE = 'formType';
export const SORT_DRAFT_NAME = 'draftName';
export const SORT_UPDATED_DATE = 'updatedAtDate';
export const SORT_LAST_UPDATED_DATE = 'updatedAtDate';

// Supplier categories
export const SUPPLIER_IBM = 'IBM';
export const SUPPLIER_DIRECT = 'DIRECT';
export const SUPPLIER_NOT_DIRECT = 'NOT_DIRECT';

// Default offset sub-tracked from position due date to get default partner due date
export const PARTNER_DUE_DATE_OFFSET = 7;

// Allowed word length
export const MAX_ALLOWED_NAME_LENGTH = 100;
export const NOT_FOUND = 'NOT_FOUND';

// Tabs
export const TAB_POSITIONS = 'POSITION';
export const TAB_PURCHASE_ORDERS = 'PURCHASE_ORDER';
export const TAB_AWARDS = 'AWARD';
export const TAB_NOTIFICATIONS = 'NOTIFICATIONS';

// Delivery Option
export const ONSITE = 'ONSITE';
export const OFFSITE = 'OFFSITE';
export const REMOTE = 'REMOTE';
export const HYBRID = 'HYBRID';

// RFQ types
export const POSITION_TYPE = {
  FIXED_PRICE: 'FIXED_PRICE',
  TIME_AND_MATERIALS: 'TIME_AND_MATERIALS',
};

// Action types
export const ACTION_INACTIVE = 'ACTION_INACTIVE';
export const ACTION_PENDING = 'ACTION_PENDING';
export const ACTION_OWN_PENDING = 'ACTION_OWN_PENDING';
export const ACTION_FINISHED = 'ACTION_FINISHED';
export const ACTION_OVERDUE = 'ACTION_OVERDUE';
export const ACTION_CANCELLED = 'ACTION_CANCELLED';

// App semantic version
// eslint-disable-next-line prefer-destructuring
export const APP_VERSION = window.process.env.APP_VERSION;

const ROUTE_LEGAL = '/legal';
export const ROUTE_LEGAL_TERMS = '/legal/terms';
export const ROUTE_LEGAL_PRIVACY = '/legal/privacy';
export const ROUTE_LEGAL_COOKIES = '/legal/cookies';

export const PUBLIC_ROUTES = [
  '/login',
  '/logout',
  '/signup',
  '/forgot-password',
  '/email-verification',
  '/reset-success',
  '/password-reset',
  ROUTE_LEGAL,
  ROUTE_LEGAL_TERMS,
  ROUTE_LEGAL_PRIVACY,
  ROUTE_LEGAL_COOKIES,
];
export const PUBLIC_ROUTES_WITH_HEADER = [
  ROUTE_LEGAL,
  ROUTE_LEGAL_TERMS,
  ROUTE_LEGAL_PRIVACY,
  ROUTE_LEGAL_COOKIES,
];
export const ROUTES_WITH_HEADER = [
  ROUTE_LEGAL,
  ROUTE_LEGAL_TERMS,
  ROUTE_LEGAL_PRIVACY,
  ROUTE_LEGAL_COOKIES,
];

export const REVISION_STATUS = {
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
};

export const REVISION_TYPES = {
  ATTESTATION: 'ATTESTATION',
  CV: 'CV',
  RATES: 'RATES',
};

// user status
export const USER_PENDING = 'PENDING';
export const USER_ACTIVE = 'ACTIVE';

// position travel requirement
export const TRAVEL_FULL_TIME = 'FULL_TIME';
export const TRAVEL_VERY_FREQUENTLY = 'VERY_FREQUENTLY';
export const TRAVEL_SOMETIMES = 'SOMETIMES';
export const TRAVEL_INFREQUENTLY = 'INFREQUENTLY';
export const TRAVEL_NOT_REQUIRED = 'NO_TRAVEL_REQUIRED';

// sorting hat experience levels
export const SH_EXPERIENCE_LEVEL = {
  STUDENT: 'STUDENT',
  ENTRY_LEVEL: 'ENTRY_LEVEL',
  RECENT_GRADUATE: 'RECENT_GRADUATE',
  JUNIOR: 'JUNIOR',
  MID_CAREER: 'MID_CAREER',
  SENIOR: 'SENIOR',
  EXPERIENCED_NON_MANAGER: 'EXPERIENCED_NON_MANAGER',
  MANAGER: 'MANAGER',
  SENIOR_EXECUTIVE: 'SENIOR_EXECUTIVE',
  OTHER: 'OTHER',
};

// position skill importance
export const SKILLS_IMPORTANCE = {
  REQUIRED: 'REQUIRED',
  DESIRED: 'DESIRED',
};

// runtime constants
export const {
  ZENDESK_KEY,
  SUPPORT_EMAIL,
  TERMS_OF_USE_UPDATE_DATE,
  PRIVACY_POLICY_UPDATE_DATE,
  COOKIE_POLICY_UPDATE_DATE,
} = window.process.env;
export const COOKIE_POLICY_VERSION = parseFloat(window.process.env.COOKIE_POLICY_VERSION);
export const PRIVACY_POLICY_VERSION = parseFloat(window.process.env.PRIVACY_POLICY_VERSION);
export const TERMS_OF_USE_VERSION = parseFloat(window.process.env.TERMS_OF_USE_VERSION);

export const { SCORE_EXPLANATION_LINK, CV_FORMAT_MANUAL_LINK } = window.process.env;

export const SCROLL_TO_ELEMENT_PREFIX = 'js-scrolling-field-';

export const METRICS_TYPE = {
  BASIC: 'BASIC',
};
