import React from 'react';
import ContentLoader from 'react-content-loader';

const ListLoader = () => (
  <ContentLoader height={160} width={350} speed={1} primaryColor="#f1f1f1" secondaryColor="#e6e6e6">
    <rect x="5" y="15" rx="5" ry="5" width="220" height="5" />
    <rect x="5" y="45" rx="5" ry="5" width="260" height="5" />
    <rect x="5" y="75" rx="5" ry="5" width="320" height="5" />
    <rect x="5" y="105" rx="5" ry="5" width="290" height="5" />
  </ContentLoader>
);

export default ListLoader;
