import React, { forwardRef } from 'react';
// libraries
import PropTypes from 'prop-types';
// styles
import styles from './table.module.scss';

const propTypes = {
  children: PropTypes.any.isRequired,
  forwardedRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
};

const defaultProps = {
  forwardedRef: null,
};

const Table = ({ children, forwardedRef, ...props }) => {
  return (
    <div ref={forwardedRef} className={styles.table} {...props}>
      {children}
    </div>
  );
};

Table.propTypes = propTypes;
Table.defaultProps = defaultProps;

// eslint-disable-next-line react/prop-types
export default forwardRef(({ children, ...props }, ref) => (
  <Table forwardedRef={ref} {...props}>
    {children}
  </Table>
));
