import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// components
import Textarea from 'components/atoms/Textarea';
import Section from 'components/molecules/FieldSection';

const propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

const Notes = ({ value, onChange, readOnly }) => {
  const { t } = useTranslation();

  return (
    <Section
      title={t('positionPublishAdditionalNotesTitle')}
      description={t('positionPublishAdditionalNotesDescription')}
      optional
    >
      <Textarea id="additionalNotes" value={value} onChange={onChange} disabled={readOnly} />
    </Section>
  );
};

Notes.propTypes = propTypes;

export default Notes;
