import React from 'react';
// libraries
import ContentLoader from 'react-content-loader';

const FilterOptionsLoader = () => (
  <ContentLoader height={140} width={220} speed={1} primaryColor="#f1f1f1" secondaryColor="#e6e6e6">
    <rect x="0" y="0" rx="5" ry="5" width="150" height="10" />
    <rect x="0" y="30" rx="5" ry="5" width="180" height="10" />
    <rect x="0" y="60" rx="5" ry="5" width="180" height="10" />
    <rect x="0" y="90" rx="5" ry="5" width="150" height="10" />
    <rect x="0" y="120" rx="5" ry="5" width="170" height="10" />
  </ContentLoader>
);

export default FilterOptionsLoader;
