import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// components
import Score from 'components/atoms/Score';
// styles
import styles from './matchMetricsPopper.module.scss';

const propTypes = {
  score: PropTypes.number.isRequired,
};

const NotEnoughCandidatesMatch = ({ score }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Score size={45} score={score} outlined />
      <div className={styles.text}>
        <div className={styles.title}>{t('notEnoughCandidateForMatchComparisonTitle')}</div>
        {t('notEnoughCandidateForMatchComparisonDescription')}
      </div>
    </div>
  );
};

NotEnoughCandidatesMatch.propTypes = propTypes;

export default NotEnoughCandidatesMatch;
