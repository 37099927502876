import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import getProperty from 'lodash/get';
// hooks
import useAuth from 'hooks/useAuth';
// components
import Chip from 'components/atoms/NewChip';
import Popover from 'components/molecules/Popover';
import StatusFlow from 'components/molecules/StatusFlow';
import ClickableElement from 'components/atoms/ClickableElement';
import Icon from 'components/atoms/Icon';
import TruncatedText from 'components/atoms/TruncatedText';
// helpers
import { preventDefault } from 'helpers/HtmlDOM';
import { formatHistory } from './helpers';
// styles
import styles from './submissionStatusFlow.module.scss';

const propTypes = {
  submission: PropTypes.object.isRequired,
  size: PropTypes.oneOf(['sm', 'md']),
};

const defaultProps = {
  size: 'md',
};

const SubmissionStatusFlow = ({ submission, size }) => {
  const { role } = useAuth();
  const { t } = useTranslation();

  const status = submission.getStatus(role);
  const statusHistory = submission.getBasicStatusHistory(role);
  const statuses = formatHistory(statusHistory, role);
  const color = submission.getColor(role);

  const getIconSize = () => {
    switch (size) {
      case 'sm':
        return 13;
      default:
        return 16;
    }
  };

  return (
    <Popover
      className="overflowHidden"
      placement="bottom-start"
      button={onClick => (
        <ClickableElement onClick={preventDefault(onClick)}>
          <Chip className={styles.chipContainer} color={color} size="sm">
            <div className={classNames(styles.chipContent, styles[size])}>
              <TruncatedText className={styles.chipLabel} disableTooltip>
                {status.label}
              </TruncatedText>
              <div className={styles.icon}>
                <Icon name="infoOutline" size={getIconSize()} />
              </div>
            </div>
          </Chip>
        </ClickableElement>
      )}
    >
      <div className={styles.statusFlowContent}>
        <div className={styles.statusFlowTitle}>
          <b>{t('statusProgress')}</b>
        </div>
        <StatusFlow color={color} statuses={statuses} note={getProperty(status, 'note.text', '')} />
      </div>
    </Popover>
  );
};

SubmissionStatusFlow.propTypes = propTypes;
SubmissionStatusFlow.defaultProps = defaultProps;

export default SubmissionStatusFlow;
