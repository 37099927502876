import React, { useState } from 'react';
// libraries
import PropTypes from 'prop-types';
import i18n from 'translations/i18n';
import TruncateMarkup from 'react-truncate-markup';
// components
import LinkButton from 'components/atoms/LinkButton';
// styles
import styles from './truncatedTextBlock.module.scss';

const propTypes = {
  maxLines: PropTypes.number.isRequired,
  showMoreText: PropTypes.string,
  showLessText: PropTypes.string,
  children: PropTypes.string.isRequired,
};

const defaultProps = {
  showMoreText: i18n.t('seeMore'),
  showLessText: i18n.t('seeLess'),
};

const TruncatedTextBlock = ({ maxLines, showMoreText, showLessText, children }) => {
  const [showAll, setShowAll] = useState(false);

  const handleDisplay = display => event => {
    event.preventDefault();
    event.stopPropagation();
    setShowAll(display);
  };

  if (showAll) {
    return (
      <div>
        {children}
        <LinkButton onClick={handleDisplay(false)} className={styles.button}>
          {' '}
          {showLessText}
        </LinkButton>
      </div>
    );
  }

  return (
    <TruncateMarkup
      lines={maxLines}
      ellipsis={
        <span>
          ...{' '}
          <LinkButton className={styles.button} onClick={handleDisplay(true)}>
            {showMoreText}
          </LinkButton>
        </span>
      }
    >
      <div>{children}</div>
    </TruncateMarkup>
  );
};

TruncatedTextBlock.propTypes = propTypes;
TruncatedTextBlock.defaultProps = defaultProps;

export default TruncatedTextBlock;
