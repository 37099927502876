import client from 'services/Client';
import logger from 'services/Logger';
// queries
import { UPDATE_LAST_USED_ROLE } from 'queries/UserQueries';

/**
 * Change user role
 * @param {string} userId
 * @param {string} roleId
 * @param {string} newRole
 * @returns {Promise}
 */
export const switchRole = async (userId, roleId, newRole) => {
  try {
    return client.mutate({
      mutation: UPDATE_LAST_USED_ROLE,
      variables: { userId, projectRoleId: roleId, role: newRole },
    });
  } catch (error) {
    logger.exception(error, { role: newRole, user: userId });
    throw error;
  }
};
