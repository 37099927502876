// libraries
import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import { withTranslation } from 'react-i18next';
// components
import Button from 'components/atoms/Button';

const propTypes = {
  onClick: PropTypes.func,
  isOpen: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

const defaultProps = {
  onClick: () => {},
  isOpen: false,
};

const ToggleButton = props => {
  const { onClick, isOpen, t } = props;
  return (
    <Tooltip
      title={isOpen ? t('closeMenu') : t('openMenu')}
      placement="right"
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 600 }}
    >
      <div
        className={`menu_bottomButton ${isOpen ? 'active' : ''}`}
        role="presentation"
        onClick={onClick}
      >
        <Button type={isOpen ? 'left' : 'right'} color="white" size={25} />
      </div>
    </Tooltip>
  );
};

ToggleButton.propTypes = propTypes;
ToggleButton.defaultProps = defaultProps;

export default withTranslation()(ToggleButton);
