import React, { useCallback } from 'react';
import classNames from 'classnames';
import MuiCheckbox from '@mui/material/Checkbox';
import styles from './checkbox.module.scss';

interface CheckboxProps {
  ariaLabel?: string;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  children?: React.ReactNode;
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const Checkbox = ({
  checked,
  ariaLabel,
  required,
  disabled,
  readOnly,
  children,
  onChange,
}: CheckboxProps) => {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.checked);
    },
    [onChange]
  );

  if (children) {
    return (
      // eslint-disable-next-line jsx-a11y/label-has-associated-control
      <label className={classNames(styles.label, { [styles.readOnly]: readOnly })}>
        <MuiCheckbox
          size="small"
          readOnly={readOnly}
          checked={checked}
          required={required}
          disabled={disabled}
          onChange={!readOnly ? handleChange : undefined}
          classes={{ root: styles.checkbox }}
        />
        <div className={styles.content}>{children}</div>
      </label>
    );
  }

  return (
    <MuiCheckbox
      size="small"
      checked={checked}
      required={required}
      disabled={disabled}
      onChange={handleChange}
      classes={{ root: styles.checkbox }}
      inputProps={{
        'aria-label': ariaLabel,
      }}
    />
  );
};

export default Checkbox;
