import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// components
import Loader from 'components/atoms/Loader';
import Chip from 'components/atoms/NewChip';
import InfiniteScroll from 'components/atoms/InfiniteScroll';
import Banner, { BANNER_TYPE } from 'components/molecules/Banner';
import PrivacyBanner from 'components/molecules/PrivacyBanner';
import { TableLoader } from 'components/molecules/ContentLoaders';
import ErrorPage from 'components/pages/ErrorPage';
// constants
import { COLOR } from 'constants/colors';
import { MAIN_RECRUITING } from 'constants/positionStatus';
// components
import CandidatesMatchTable from './CandidatesMatchTable';
import CandidatesMatchFilter from './CandidatesMatchFilter';
// hooks
import useCandidatesMatch from './useCandidatesMatch';
// styles
import styles from './candidatesMatch.module.scss';

const propTypes = {
  position: PropTypes.object.isRequired,
  supplierId: PropTypes.string.isRequired,
};

const CandidatesMatch = ({ position, supplierId }) => {
  const { t } = useTranslation();
  const {
    data,
    filter,
    sort,
    fetchMore,
    filteredBy,
    sortedBy,
    loading,
    processing,
    error,
    count,
    candidatesCount,
    clearances,
  } = useCandidatesMatch(position.id, position.project.id, supplierId);

  const renderContent = () => {
    const currentStatus = position.getMainStatus();

    if (position.isFixedPrice()) {
      return (
        <Banner className={styles.banner} type={BANNER_TYPE.ANNOUNCEMENT}>
          {t('candidatesRankingNotAvailableForFixedPrice')}
        </Banner>
      );
    }

    if (currentStatus.value !== MAIN_RECRUITING) {
      return (
        <Banner className={styles.banner} type={BANNER_TYPE.ANNOUNCEMENT}>
          {t('candidatesRankingNotAvailableForClosedRequests')}
        </Banner>
      );
    }

    // loader for initial data fetching. Table loader will be used later during filtering and sorting
    if (loading && !candidatesCount) {
      return (
        <div className={styles.loader}>
          <Loader fixedSize />
        </div>
      );
    }

    if (error) {
      return <ErrorPage code={500} />;
    }

    if (processing) {
      return (
        <Banner className={styles.banner} type={BANNER_TYPE.ANNOUNCEMENT}>
          {t('candidatesRankingProcessing')}
        </Banner>
      );
    }

    if (!candidatesCount && !count) {
      return (
        <Banner className={styles.banner} type={BANNER_TYPE.ANNOUNCEMENT}>
          {t('noCandidatesInPool')}
        </Banner>
      );
    }

    return (
      <div className={styles.list}>
        <CandidatesMatchFilter
          filteredBy={filteredBy}
          sortedBy={sortedBy}
          onFilterChange={filter}
          onSortChange={sort}
          clearanceOptions={clearances}
        />
        <div className="m-t-30">
          {loading ? (
            <TableLoader />
          ) : (
            <InfiniteScroll
              dataLength={data.length}
              hasMore={count > data.length}
              next={fetchMore}
              scrollableTarget="js-scrollable-content"
            >
              <CandidatesMatchTable position={position} supplierId={supplierId} items={data} />
            </InfiniteScroll>
          )}
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className={styles.head}>
        <div className={styles.title}>{t('candidatePoolRanking')}</div>
        {count > 0 && position.isTimeAndMaterials() && (
          <div className="m-l-5">
            <Chip color={COLOR.GRAY} size="md">
              {count}
            </Chip>
          </div>
        )}
      </div>
      <div className="m-t-30">
        {position.isTimeAndMaterials() && (
          <div className={classNames(styles.banner, 'm-b-20')}>
            <PrivacyBanner />
          </div>
        )}
        {renderContent()}
      </div>
    </React.Fragment>
  );
};

CandidatesMatch.propTypes = propTypes;

export default CandidatesMatch;
