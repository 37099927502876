import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import MaterialTextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
// components
import Icon from 'components/atoms/Icon';
// styles
import colors from 'dependencies/materialStyles/Colors';

const propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  className: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  icon: '',
  label: '',
  value: '',
  error: '',
  className: '',
  required: false,
  disabled: false,
};

const useStyles = makeStyles({
  label: {
    fontSize: 14,
    fontFamily: 'Lato-Bold',
    textTransform: 'uppercase',
    color: colors.gray,
    '&$labelFocused:not($labelError)': {
      color: `${colors.gray}`,
    },
  },
  labelFocused: {},
  labelError: {},
  asterisk: {
    color: colors.red,
    marginLeft: 2,
  },
  input: {
    fontFamily: 'Lato',
    fontSize: 14,
    color: colors.black,
  },
  inputFocused: {},
  inputError: {},
  inputDisabled: {},
  inputUnderline: {
    '&:before': {
      borderBottomColor: colors.gray4,
    },
    '&:after': {
      borderBottomColor: colors.gray,
    },
    '&:hover:not($inputFocused):not($inputError):not($inputDisabled):before': {
      borderBottomColor: `${colors.gray}`,
    },
  },
});

const TextField = ({ icon, label, value, required, disabled, onChange, error, className }) => {
  const classes = useStyles();

  return (
    <MaterialTextField
      classes={{ root: classes.root }}
      className={className}
      label={label}
      value={value}
      required={required}
      error={!!error}
      disabled={disabled}
      helperText={error}
      onChange={e => onChange(e.target.value)}
      fullWidth
      InputProps={{
        classes: {
          root: classes.input,
          underline: classes.inputUnderline,
          error: classes.inputError,
          focused: classes.inputFocused,
          disabled: classes.inputDisabled,
        },
        startAdornment: icon ? (
          <InputAdornment position="start">
            <Icon name={icon} color={colors.gray} />
          </InputAdornment>
        ) : null,
      }}
      InputLabelProps={{
        shrink: true,
        FormLabelClasses: {
          root: classes.label,
          focused: classes.labelFocused,
          error: classes.labelError,
          asterisk: classes.asterisk,
        },
      }}
    />
  );
};

TextField.propTypes = propTypes;
TextField.defaultProps = defaultProps;

export default TextField;
