import React from 'react';
// libraries
import PropTypes from 'prop-types';
// styles
import styles from './detailPage.module.scss';

const propTypes = {
  children: PropTypes.any.isRequired,
};

const Body = ({ children }) => {
  return <div className={styles.body}>{children}</div>;
};

Body.propTypes = propTypes;

export default Body;
