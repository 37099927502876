import React from 'react';
// Libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// components
import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import Textarea from 'components/atoms/Textarea';
import DropdownCheckbox from 'components/molecules/DropdownCheckbox';
// constants
import { SELECT_TYPE } from 'constants';
// helpers
import { filterList } from './Helpers';

const propTypes = {
  className: PropTypes.string,
  content: PropTypes.any,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  addInput: PropTypes.func,
  list: PropTypes.array,
  type: PropTypes.string,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  id: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  multiline: PropTypes.bool,
};

const defaultProps = {
  className: '',
  content: null,
  selected: null,
  onChange: () => {},
  addInput: () => {},
  placeholder: null,
  list: null,
  type: '',
  loading: false,
  multiline: false,
};

const NewField = props => {
  const { t } = useTranslation();
  const {
    className,
    placeholder,
    content,
    selected,
    addInput,
    loading,
    onChange,
    list,
    type,
    id,
    multiline,
  } = props;

  const renderInput = () => {
    if (multiline) {
      return <Textarea placeholder={placeholder} value={selected} id={id} onChange={onChange} />;
    }

    return <Input placeholder={placeholder} value={selected} id={id} onChange={onChange} />;
  };

  return (
    <div className={`${className}`}>
      <div>{content}</div>
      <div>
        {type === SELECT_TYPE ? (
          <DropdownCheckbox
            search
            label={placeholder}
            list={filterList(list, selected)}
            onChange={onChange}
            maxHeight={200}
            name={id}
            loading={loading}
          />
        ) : (
          renderInput()
        )}
      </div>
      {type !== SELECT_TYPE && (
        <div className="p-t-10">
          <Button onClick={addInput} label={t('add')} />
        </div>
      )}
    </div>
  );
};

NewField.propTypes = propTypes;
NewField.defaultProps = defaultProps;

export default NewField;
