import React, { useState, useEffect, useContext } from 'react';
// libraries
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import getProperty from 'lodash/get';
// context
import ToastContext from 'context/ToastContext';
// hooks
import useAuth from 'hooks/useAuth';
import useIsMounted from 'hooks/useIsMounted';
// components
import EmailModalComponent from 'components/organisms/EmailModal';
// helpers
import { buildEmailTemplate } from 'helpers/Template';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  projectId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  recipient: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  templateType: PropTypes.string.isRequired,
  templateData: PropTypes.object.isRequired,
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      boxId: PropTypes.string.isRequired,
      filename: PropTypes.string.isRequired,
    })
  ),
};

const defaultProps = {
  attachments: [],
};

const EmailModal = ({
  isOpen,
  projectId,
  title,
  recipient,
  onClose,
  onSubmit,
  templateType,
  templateData,
  attachments,
}) => {
  const { t } = useTranslation();
  const { addToast } = useContext(ToastContext);
  const { email: matchManagerEmail } = useAuth();
  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [template, setTemplate] = useState('');
  const [emailOptions, setEmailOptions] = useState();

  useEffect(() => {
    const initTemplate = async () => {
      try {
        setLoading(true);
        setLoadingText(t('loadingTemplate'));

        const emailTemplate = await buildEmailTemplate(templateType, projectId, templateData);
        const emailOptionList = {
          ...emailTemplate.options,
          from: getProperty(emailTemplate, 'options.from') || matchManagerEmail,
          to: getProperty(emailTemplate, 'options.to') || recipient,
        };

        setTemplate(emailTemplate.html);
        setEmailOptions(emailOptionList);
      } catch (error) {
        addToast.warning(t('emailTemplateNotFound'));
      } finally {
        setLoading(false);
      }
    };

    if (isOpen && !template) {
      initTemplate();
    }
  }, [isOpen]);

  const handleSubmit = async email => {
    try {
      setLoading(true);
      setLoadingText(t('sending'));

      await onSubmit(email);
    } finally {
      if (isMounted()) {
        setLoading(false);
      }
    }
  };

  return (
    <EmailModalComponent
      isOpen={isOpen}
      title={title}
      loading={loading}
      loadingText={loadingText}
      html={template || ''}
      onClose={onClose}
      options={emailOptions}
      onSubmit={handleSubmit}
      attachments={attachments}
    />
  );
};

EmailModal.propTypes = propTypes;
EmailModal.defaultProps = defaultProps;

export default EmailModal;
