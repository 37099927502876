/* eslint-disable react/no-array-index-key */
import React from 'react';
import { subProcessorsList } from './Texts';

const SubProcessorsTable = () => {
  return (
    <div className="technicalMeasuresTable">
      {subProcessorsList.map(({ left, right }, index) => {
        return (
          <div
            className={
              index === 0
                ? 'technicalMeasuresTable_header'
                : 'personalDataTable_row_content technicalMeasuresTable_row_content m-t-2'
            }
            key={index}
          >
            <div className="technicalMeasuresTable_column_first technicalMeasuresTable_row_content">
              {Array.isArray(left) ? (
                <React.Fragment>
                  {left.map((l, lIndex) => {
                    return (
                      <div key={lIndex} className={`${lIndex < left.length - 1 ? 'p-b-5' : ''}`}>
                        {l}
                      </div>
                    );
                  })}
                </React.Fragment>
              ) : (
                <div className="">{left}</div>
              )}
            </div>
            <div
              className={` ${
                index > 0 && index % 2 === 0 ? 'technicalMeasuresTable_row_gray' : ''
              } personalDataTable_column_second technicalMeasuresTable_column_second`}
            >
              {right}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SubProcessorsTable;
