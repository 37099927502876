/* eslint-disable import/order */
import React, { useState, useContext, useEffect, useCallback, useMemo, useRef } from 'react';
// libraries
import PropTypes from 'prop-types';
import getProperty from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'react-i18next';
// context
import ToastContext from 'context/ToastContext';
import PositionContext from '../PositionContext';
// hooks
import useAuth from 'hooks/useAuth';
// services
import logger from 'services/Logger';
// constants
import { DEFAULT_IBM_FEE, DEFAULT_IBM_FEE_FP } from 'constants/rates';
// helpers
import {
  calculateYearPurchased,
  calculateTargetRate,
  getTargetMargin,
  calculateSellPricesFromTargetRates,
  calculatePositionGPsFromSellPrice,
} from 'helpers/RatesCalculation';
import { getYear } from 'helpers/RelativeTimeFormatter';
import MultipliersContext from '../MultipliersContext';
// components
import { PublishPosition } from 'components/organisms/PositionSettings';
import { showPublishBanner } from 'components/organisms/PublishBanner';
import Header from './Header';
import Allowance from './Allowance';
import Rates from './Rates';
// styles
import './rfqRates.scss';

const propTypes = {
  id: PropTypes.string.isRequired,
  hours: PropTypes.number,
  nteRates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      startDate: PropTypes.string.isRequired,
    })
  ).isRequired,
  targetRates: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
    })
  ).isRequired,
  isFixedPrice: PropTypes.bool.isRequired,
  onFeeChange: PropTypes.func.isRequired,
};

const defaultProps = {
  hours: 0,
};

const RFQRates = React.memo(({ id, hours, nteRates, targetRates, onFeeChange, isFixedPrice }) => {
  const defaultTargetMargin = getTargetMargin(nteRates, targetRates);
  const user = useAuth();
  const [isPublishModalOpen, setPublishModalOpen] = useState(false);
  const [localTargetRates, setTargetRates] = useState(targetRates);
  const [targetMargin, setTargetMargin] = useState(defaultTargetMargin);
  const [ibmGPs, setIbmGPs] = useState([]);
  const { t } = useTranslation();
  const { ibmFee, manYearDiscount } = useContext(MultipliersContext);
  const initialSellPrices = useRef(
    calculateSellPricesFromTargetRates(
      localTargetRates.map(rate => rate.value),
      ibmFee
    )
  );
  const [sellPrices, setSellPrices] = useState(initialSellPrices.current);
  const { addToast } = useContext(ToastContext);
  const position = useContext(PositionContext);

  useEffect(() => {
    setTargetRates(targetRates);
    setTargetMargin(getTargetMargin(nteRates, targetRates));

    const targetRatesValue = targetRates.map(rate => rate.value);
    const newSellPrices = calculateSellPricesFromTargetRates(targetRatesValue, ibmFee);
    initialSellPrices.current = newSellPrices;
    setIbmGPs(calculatePositionGPsFromSellPrice(targetRatesValue, newSellPrices, manYearDiscount));
  }, [targetRates]);

  const targetRatesForPublish = localTargetRates.map(rate => ({
    value: rate.value,
    year: parseInt(getYear(rate.startDate)),
  }));

  const applyTargetMargin = useCallback(
    targetMarginVal => {
      setTargetMargin(targetMarginVal);
      setTargetRates(
        localTargetRates.map((rate, index) => ({
          ...rate,
          value: calculateTargetRate(nteRates[index].value, targetMarginVal),
        }))
      );
    },
    [localTargetRates, nteRates]
  );

  const isFeeDirty = () => {
    if (isFixedPrice) {
      return ibmFee !== DEFAULT_IBM_FEE_FP;
    }

    return ibmFee !== DEFAULT_IBM_FEE;
  };

  const isDiscardActive = useMemo(() => {
    if (
      !isFeeDirty() &&
      targetMargin === defaultTargetMargin &&
      isEqual(targetRates, localTargetRates) &&
      isEqual(initialSellPrices.current, sellPrices)
    ) {
      return false;
    }

    return true;
  }, [targetRates, sellPrices, localTargetRates, ibmFee, targetMargin]);

  const discardTargetRatesChanges = useCallback(() => {
    setTargetRates(targetRates);
    setTargetMargin(defaultTargetMargin);
    onFeeChange(isFixedPrice ? DEFAULT_IBM_FEE_FP : DEFAULT_IBM_FEE);
    setSellPrices(initialSellPrices.current);
    setIbmGPs(
      calculatePositionGPsFromSellPrice(
        targetRates.map(rate => rate.value),
        initialSellPrices.current,
        manYearDiscount
      )
    );
  }, [targetRates, sellPrices, id, defaultTargetMargin]);

  const handlePublish = useCallback(async () => {
    try {
      // don't allow to save target rates if some of them are not filled
      const targetRatesNotFilled = localTargetRates.some(rate => !rate.value);

      if (targetRatesNotFilled) {
        addToast.error(t('targetRatesNotFilled'));
        return;
      }

      // don't allow to save target rates if they exceed client NTE
      const targetRatesExceedNte = localTargetRates.some(
        (rate, index) => rate.value > getProperty(nteRates, [index, 'value'], 0)
      );

      if (targetRatesExceedNte) {
        addToast.error(t('targetRatesExceedClientNTE'));
        return;
      }

      setPublishModalOpen(true);
    } catch (error) {
      addToast.error(t('errorPlaceholderText'));
      logger.exception(error);
    }
  }, [targetRates, localTargetRates, id]);

  return (
    <React.Fragment>
      <Header
        isPublishActive={!position.isPublished()}
        isDiscardActive={isDiscardActive}
        onDiscard={discardTargetRatesChanges}
        onPublish={handlePublish}
        isPublishButtonVisible={!showPublishBanner(position, user)}
      />
      <div className="rfqRates">
        <Allowance
          targetMargin={targetMargin}
          onTargetMarginChange={applyTargetMargin}
          onFeeChange={onFeeChange}
          yearPurchased={calculateYearPurchased(hours)}
          showTargetMargin={position.showTargetRatesToPartners}
        />
        <Rates
          currency={getProperty(nteRates, '0.currency', 'EURO')}
          nteRates={nteRates}
          targetRates={localTargetRates}
          onTargetRatesChange={setTargetRates}
          showTargetRates={position.showTargetRatesToPartners}
          ibmGPs={ibmGPs}
          setIbmGPs={setIbmGPs}
          sellPrices={sellPrices}
          setSellPrices={setSellPrices}
          isFixedPrice={isFixedPrice}
        />
      </div>
      <PublishPosition
        isOpen={isPublishModalOpen}
        position={position}
        onClose={() => setPublishModalOpen(false)}
        targetRates={targetRatesForPublish}
      />
    </React.Fragment>
  );
});

RFQRates.propTypes = propTypes;
RFQRates.defaultProps = defaultProps;

export default RFQRates;
