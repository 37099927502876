import axios from 'axios';
import axiosRetry from 'axios-retry';
// libraries
import isObject from 'lodash/isPlainObject';
import isString from 'lodash/isString';
import omit from 'lodash/omit';
// services
import logger from 'services/Logger';
// helpers
import { redirectToLogin } from 'helpers/Link';

// Props to not log in case of error
const BLACK_LISTED_PROPS = ['password', 'confirmedPassword', 'phone'];

const extractRequestData = data => {
  // if data is form data
  if (data instanceof FormData) {
    const formData = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of data.entries()) {
      // don't extract files and black listed props
      if (!(value instanceof File) && !BLACK_LISTED_PROPS.includes(key)) {
        formData[key] = value;
      }
    }

    return formData;
  }

  // if data is an object
  if (isObject(data)) {
    return omit(data, BLACK_LISTED_PROPS);
  }

  // if data is a json string
  if (data && isString(data)) {
    try {
      const jsonData = JSON.parse(data);

      return omit(jsonData, BLACK_LISTED_PROPS);
    } catch (error) {
      return data;
    }
  }

  return data;
};

axios.interceptors.request.use(
  request => request,
  error => {
    if (error.request) {
      logger.error(error.request);
    } else {
      logger.error(error);
    }

    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => response,
  error => {
    const url = new URL(error.config.url, document.baseURI);
    const isAPIRequest = /^\/api\/.*/.test(url.pathname);

    if (error.response) {
      // all 401 response for API redirect to login page
      if (isAPIRequest && error.response.status === 401) {
        return redirectToLogin();
      }

      if (error.response.status === 500) {
        logger.error(error.message, {
          url: error.config.url,
          req: {
            data: extractRequestData(error.config.data),
            headers: error.config.headers,
          },
          res: {
            data: error.response.data,
            headers: error.response.headers,
          },
        });
      }
    }

    return Promise.reject(error);
  }
);

axiosRetry(axios, {
  retries: 3,
  retryDelay: retryCount => {
    return retryCount * 1500;
  },
  retryCondition: error =>
    axiosRetry.isNetworkError(error) ||
    !error.response ||
    [502, 504, 524].includes(error.response.status),
});

export default axios;
