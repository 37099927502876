import React, { forwardRef } from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// styles
import styles from './table.module.scss';

const propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  highlighted: PropTypes.bool,
  disabled: PropTypes.bool,
  forwardedRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
};

const defaultProps = {
  className: '',
  onClick: null,
  highlighted: false,
  disabled: false,
  forwardedRef: null,
};

const Row = ({ children, className, onClick, highlighted, disabled, forwardedRef }) => {
  return (
    <div
      ref={forwardedRef}
      onClick={!disabled ? onClick : null}
      onKeyPress={event => (event.keyCode === 13 && onClick && !disabled ? onClick() : null)}
      role="tab"
      tabIndex="-1"
      className={classNames(styles.row, {
        [className]: className,
        [styles.hoverable]: !disabled,
        [styles.highlighted]: highlighted,
        cp: onClick && !disabled,
      })}
    >
      {children}
    </div>
  );
};

Row.propTypes = propTypes;
Row.defaultProps = defaultProps;

// eslint-disable-next-line react/prop-types
export default forwardRef(({ children, ...props }, ref) => (
  <Row {...props} forwardedRef={ref}>
    {children}
  </Row>
));
