import React from 'react';
import ContentLoader from 'react-content-loader';

const PositionLoader = () => (
  <ContentLoader height={160} width={350} speed={1} primaryColor="#f1f1f1" secondaryColor="#e6e6e6">
    <rect x="40" y="20" rx="4" ry="4" width="117" height="6" />
    <rect x="40" y="35" rx="4" ry="4" width="90" height="4" />
    <rect x="10" y="60" rx="8" ry="8" width="70" height="12" />
    <rect x="10" y="90" rx="3" ry="3" width="330" height="6" />
    <rect x="10" y="110" rx="3" ry="3" width="330" height="6" />
    <rect x="10" y="130" rx="3" ry="3" width="201" height="6" />
    <rect x="0" y="0" rx="0" ry="0" width="2" height="160" />
    <rect x="348" y="0" rx="0" ry="0" width="2" height="160" />
    <rect x="0" y="0" rx="0" ry="0" width="350" height="2" />
    <rect x="0" y="158" rx="0" ry="0" width="350" height="2" />
    <rect x="10" y="16" rx="4" ry="4" width="20" height="25" />
  </ContentLoader>
);

export default PositionLoader;
