// libraries
import i18n from 'i18next';
import hasProperty from 'lodash/has';
// services
import logger from 'services/Logger';
// constants
import { SUBMISSION_TAB } from 'constants/pageTabs';

export const getTabs = isOnboarding => {
  const tabs = [
    {
      id: SUBMISSION_TAB.POSITION,
      label: i18n.t('positionDetails'),
    },
    {
      id: SUBMISSION_TAB.DETAIL,
      label: i18n.t('proposalDetails'),
    },
  ];

  if (isOnboarding) {
    tabs.push({
      id: SUBMISSION_TAB.ONBOARDING,
      label: i18n.t('onboarding'),
    });
  }

  return tabs;
};

/**
 * Access and permissions are primary handled via 8base roles.
 * This is an extra layer to check for correct permission for access in case something permission
 * is missing or is incorrect in alaka-roles
 * @param {object} proposal
 * @param {object} user Logged in user
 * @returns {bool}
 */
export const isUnauthorizedAccess = (proposal, user) => {
  const projectIds = user.projects.map(({ id: projectId }) => projectId);

  if (
    hasProperty(proposal, 'position.project.id') &&
    !projectIds.includes(proposal.position.project.id)
  ) {
    logger.warn('Security risk', { user: user.id, proposal: proposal.id });

    return true;
  }

  return false;
};
