import React from 'react';
// components
import Icon from 'components/atoms/Icon';
import MenuItem from './MenuItem';

const MenuGroup = props => {
  const { currentLink, onClick, routes, isOpen } = props;
  return routes.map((route, index) => (
    <MenuItem
      key={route.link !== '' ? route.link : index}
      label={route.label}
      link={`${route.link}${route.defaultParams || ''}`}
      icon={<Icon name={route.icon} color="white" size={27} />}
      chip={route.chip}
      active={currentLink === route.link}
      bigMenu={isOpen}
      onClick={event => onClick(event, route.link)}
      className={route.className}
    />
  ));
};

export default MenuGroup;
