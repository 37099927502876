import { STAFF_ROLE, SUPPLIER_ROLE, MANAGER_ROLE } from 'constants';
import {
  POSITION_FILTERS,
  POSITION_STATUS_FILTERS,
  POSITION_SUBMISSION_FILTERS,
  DUE_DATE_FILTERS,
  PUBLISH_STATUS_FILTERS,
} from 'constants/filters';

const DUE_DATE_FAMILY = Object.values(DUE_DATE_FILTERS);
const STATUS_FAMILY = Object.values(POSITION_STATUS_FILTERS);
const SUBMISSION_FAMILY = Object.values(POSITION_SUBMISSION_FILTERS);
const PUBLISH_STATUS_FAMILY = Object.values(PUBLISH_STATUS_FILTERS);

const FILTER_COMBINATIONS_SUPPLIER = {
  [DUE_DATE_FILTERS.DUE_TODAY]: [
    ...DUE_DATE_FAMILY,
    ...SUBMISSION_FAMILY,
    POSITION_STATUS_FILTERS.RECRUITING,
  ],
  [DUE_DATE_FILTERS.DUE_TOMORROW]: [
    ...DUE_DATE_FAMILY,
    ...SUBMISSION_FAMILY,
    POSITION_STATUS_FILTERS.RECRUITING,
  ],
  [DUE_DATE_FILTERS.DUE_NEXT_7_DAYS]: [
    ...DUE_DATE_FAMILY,
    ...SUBMISSION_FAMILY,
    POSITION_STATUS_FILTERS.RECRUITING,
  ],
  [DUE_DATE_FILTERS.EXPIRED]: [
    ...DUE_DATE_FAMILY,
    ...SUBMISSION_FAMILY,
    POSITION_STATUS_FILTERS.SELECTION,
    POSITION_STATUS_FILTERS.ONBOARDING,
    POSITION_STATUS_FILTERS.OFFER,
    POSITION_STATUS_FILTERS.ONBOARDING_IN_PROGRESS,
    POSITION_STATUS_FILTERS.ONBOARDING_COMPLETED,
    POSITION_STATUS_FILTERS.DELIVERY,
    POSITION_STATUS_FILTERS.CLOSED,
    POSITION_STATUS_FILTERS.NO_SUBMISSIONS,
    POSITION_STATUS_FILTERS.NO_SELECTION,
  ],
  [DUE_DATE_FILTERS.NOT_EXPIRED]: [
    ...DUE_DATE_FAMILY,
    ...SUBMISSION_FAMILY,
    POSITION_STATUS_FILTERS.RECRUITING,
  ],
  [POSITION_STATUS_FILTERS.RECRUITING]: [
    ...STATUS_FAMILY,
    ...SUBMISSION_FAMILY,
    DUE_DATE_FILTERS.DUE_TODAY,
    DUE_DATE_FILTERS.DUE_TOMORROW,
    DUE_DATE_FILTERS.DUE_NEXT_7_DAYS,
    DUE_DATE_FILTERS.NOT_EXPIRED,
  ],
  [POSITION_STATUS_FILTERS.SELECTION]: [
    ...STATUS_FAMILY,
    DUE_DATE_FILTERS.EXPIRED,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_RECEIVED,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_SUBMITTED,
  ],
  [POSITION_STATUS_FILTERS.ONBOARDING]: [
    ...STATUS_FAMILY,
    DUE_DATE_FILTERS.EXPIRED,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_RECEIVED,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_SUBMITTED,
  ],
  [POSITION_STATUS_FILTERS.OFFER]: [
    ...STATUS_FAMILY,
    DUE_DATE_FILTERS.EXPIRED,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_RECEIVED,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_SUBMITTED,
  ],
  [POSITION_STATUS_FILTERS.ONBOARDING_IN_PROGRESS]: [
    ...STATUS_FAMILY,
    DUE_DATE_FILTERS.EXPIRED,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_RECEIVED,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_SUBMITTED,
  ],
  [POSITION_STATUS_FILTERS.ONBOARDING_COMPLETED]: [
    ...STATUS_FAMILY,
    DUE_DATE_FILTERS.EXPIRED,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_RECEIVED,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_SUBMITTED,
  ],
  [POSITION_STATUS_FILTERS.DELIVERY]: [
    ...STATUS_FAMILY,
    DUE_DATE_FILTERS.EXPIRED,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_RECEIVED,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_SUBMITTED,
  ],
  [POSITION_STATUS_FILTERS.CLOSED]: [
    ...STATUS_FAMILY,
    ...SUBMISSION_FAMILY,
    DUE_DATE_FILTERS.EXPIRED,
  ],
  [POSITION_STATUS_FILTERS.NO_SUBMISSIONS]: [
    ...STATUS_FAMILY,
    DUE_DATE_FILTERS.EXPIRED,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_RECEIVED,
    POSITION_SUBMISSION_FILTERS.NO_CANDIDATE_RECEIVED,
    POSITION_SUBMISSION_FILTERS.NO_CANDIDATE_SUBMITTED,
  ],
  [POSITION_STATUS_FILTERS.NO_SELECTION]: [
    ...STATUS_FAMILY,
    DUE_DATE_FILTERS.EXPIRED,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_RECEIVED,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_SUBMITTED,
  ],
  [POSITION_STATUS_FILTERS.CANCELLED]: [...STATUS_FAMILY, ...SUBMISSION_FAMILY],
  [POSITION_SUBMISSION_FILTERS.NO_CANDIDATE_RECEIVED]: [
    ...DUE_DATE_FAMILY,
    ...SUBMISSION_FAMILY,
    POSITION_STATUS_FILTERS.RECRUITING,
    POSITION_STATUS_FILTERS.CLOSED,
    POSITION_STATUS_FILTERS.NO_SUBMISSIONS,
    POSITION_STATUS_FILTERS.NO_SELECTION,
  ],
  [POSITION_SUBMISSION_FILTERS.CANDIDATE_RECEIVED]: [
    ...DUE_DATE_FAMILY,
    ...SUBMISSION_FAMILY,
    POSITION_STATUS_FILTERS.RECRUITING,
    POSITION_STATUS_FILTERS.SELECTION,
    POSITION_STATUS_FILTERS.ONBOARDING,
    POSITION_STATUS_FILTERS.OFFER,
    POSITION_STATUS_FILTERS.ONBOARDING_IN_PROGRESS,
    POSITION_STATUS_FILTERS.ONBOARDING_COMPLETED,
    POSITION_STATUS_FILTERS.DELIVERY,
    POSITION_STATUS_FILTERS.CLOSED,
    POSITION_STATUS_FILTERS.NO_SELECTION,
    POSITION_STATUS_FILTERS.CANCELLED,
  ],
  [POSITION_SUBMISSION_FILTERS.NO_CANDIDATE_SUBMITTED]: [
    ...DUE_DATE_FAMILY,
    ...SUBMISSION_FAMILY,
    POSITION_STATUS_FILTERS.RECRUITING,
    POSITION_STATUS_FILTERS.CLOSED,
    POSITION_STATUS_FILTERS.NO_SUBMISSIONS,
    POSITION_STATUS_FILTERS.NO_SELECTION,
    POSITION_STATUS_FILTERS.CANCELLED,
  ],
  [POSITION_SUBMISSION_FILTERS.CANDIDATE_SUBMITTED]: [
    ...DUE_DATE_FAMILY,
    POSITION_STATUS_FILTERS.RECRUITING,
    POSITION_STATUS_FILTERS.SELECTION,
    POSITION_STATUS_FILTERS.ONBOARDING,
    POSITION_STATUS_FILTERS.OFFER,
    POSITION_STATUS_FILTERS.ONBOARDING_IN_PROGRESS,
    POSITION_STATUS_FILTERS.ONBOARDING_COMPLETED,
    POSITION_STATUS_FILTERS.DELIVERY,
    POSITION_STATUS_FILTERS.CLOSED,
    POSITION_STATUS_FILTERS.NO_SELECTION,
    POSITION_STATUS_FILTERS.CANCELLED,
  ],
};

const FILTER_COMBINATIONS_MM = {
  [DUE_DATE_FILTERS.DUE_TODAY]: [
    ...DUE_DATE_FAMILY,
    ...SUBMISSION_FAMILY,
    ...PUBLISH_STATUS_FAMILY,
    POSITION_STATUS_FILTERS.RECRUITING,
  ],
  [DUE_DATE_FILTERS.DUE_TOMORROW]: [
    ...DUE_DATE_FAMILY,
    ...SUBMISSION_FAMILY,
    ...PUBLISH_STATUS_FAMILY,
    POSITION_STATUS_FILTERS.RECRUITING,
  ],
  [DUE_DATE_FILTERS.DUE_NEXT_7_DAYS]: [
    ...DUE_DATE_FAMILY,
    ...SUBMISSION_FAMILY,
    ...PUBLISH_STATUS_FAMILY,
    POSITION_STATUS_FILTERS.RECRUITING,
  ],
  [DUE_DATE_FILTERS.EXPIRED]: [
    ...DUE_DATE_FAMILY,
    ...SUBMISSION_FAMILY,
    POSITION_STATUS_FILTERS.SELECTION,
    POSITION_STATUS_FILTERS.ONBOARDING,
    POSITION_STATUS_FILTERS.OFFER,
    POSITION_STATUS_FILTERS.ONBOARDING_IN_PROGRESS,
    POSITION_STATUS_FILTERS.ONBOARDING_COMPLETED,
    POSITION_STATUS_FILTERS.DELIVERY,
    POSITION_STATUS_FILTERS.CLOSED,
    POSITION_STATUS_FILTERS.NO_SUBMISSIONS,
    POSITION_STATUS_FILTERS.NO_SELECTION,
  ],
  [DUE_DATE_FILTERS.NOT_EXPIRED]: [
    ...DUE_DATE_FAMILY,
    ...SUBMISSION_FAMILY,
    ...PUBLISH_STATUS_FAMILY,
    POSITION_STATUS_FILTERS.RECRUITING,
  ],
  [POSITION_STATUS_FILTERS.RECRUITING]: [
    ...STATUS_FAMILY,
    ...SUBMISSION_FAMILY,
    ...PUBLISH_STATUS_FAMILY,
    DUE_DATE_FILTERS.DUE_TODAY,
    DUE_DATE_FILTERS.DUE_TOMORROW,
    DUE_DATE_FILTERS.DUE_NEXT_7_DAYS,
    DUE_DATE_FILTERS.NOT_EXPIRED,
  ],
  [POSITION_STATUS_FILTERS.SELECTION]: [
    ...STATUS_FAMILY,
    DUE_DATE_FILTERS.EXPIRED,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_RECEIVED,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_SUBMITTED,
  ],
  [POSITION_STATUS_FILTERS.ONBOARDING]: [
    ...STATUS_FAMILY,
    DUE_DATE_FILTERS.EXPIRED,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_RECEIVED,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_SUBMITTED,
  ],
  [POSITION_STATUS_FILTERS.OFFER]: [
    ...STATUS_FAMILY,
    DUE_DATE_FILTERS.EXPIRED,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_RECEIVED,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_SUBMITTED,
  ],
  [POSITION_STATUS_FILTERS.ONBOARDING_IN_PROGRESS]: [
    ...STATUS_FAMILY,
    DUE_DATE_FILTERS.EXPIRED,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_RECEIVED,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_SUBMITTED,
  ],
  [POSITION_STATUS_FILTERS.ONBOARDING_COMPLETED]: [
    ...STATUS_FAMILY,
    DUE_DATE_FILTERS.EXPIRED,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_RECEIVED,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_SUBMITTED,
  ],
  [POSITION_STATUS_FILTERS.DELIVERY]: [
    ...STATUS_FAMILY,
    DUE_DATE_FILTERS.EXPIRED,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_RECEIVED,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_SUBMITTED,
  ],
  [POSITION_STATUS_FILTERS.CLOSED]: [
    ...STATUS_FAMILY,
    ...SUBMISSION_FAMILY,
    DUE_DATE_FILTERS.EXPIRED,
  ],
  [POSITION_STATUS_FILTERS.CANCELLED]: [...STATUS_FAMILY, ...SUBMISSION_FAMILY],
  [POSITION_STATUS_FILTERS.NO_SUBMISSIONS]: [
    ...STATUS_FAMILY,
    DUE_DATE_FILTERS.EXPIRED,
    POSITION_SUBMISSION_FILTERS.NO_CANDIDATE_RECEIVED,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_RECEIVED,
    POSITION_SUBMISSION_FILTERS.NO_CANDIDATE_SUBMITTED,
  ],
  [POSITION_STATUS_FILTERS.NO_SELECTION]: [
    ...STATUS_FAMILY,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_RECEIVED,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_SUBMITTED,
    DUE_DATE_FILTERS.EXPIRED,
  ],
  [POSITION_SUBMISSION_FILTERS.NO_CANDIDATE_RECEIVED]: [
    ...DUE_DATE_FAMILY,
    ...SUBMISSION_FAMILY,
    ...PUBLISH_STATUS_FAMILY,
    POSITION_STATUS_FILTERS.RECRUITING,
    POSITION_STATUS_FILTERS.CLOSED,
    POSITION_STATUS_FILTERS.CANCELLED,
    POSITION_STATUS_FILTERS.NO_SUBMISSIONS,
    POSITION_STATUS_FILTERS.NO_SELECTION,
  ],
  [POSITION_SUBMISSION_FILTERS.CANDIDATE_RECEIVED]: [
    ...DUE_DATE_FAMILY,
    ...SUBMISSION_FAMILY,
    ...PUBLISH_STATUS_FAMILY,
    POSITION_STATUS_FILTERS.RECRUITING,
    POSITION_STATUS_FILTERS.SELECTION,
    POSITION_STATUS_FILTERS.ONBOARDING,
    POSITION_STATUS_FILTERS.OFFER,
    POSITION_STATUS_FILTERS.ONBOARDING_IN_PROGRESS,
    POSITION_STATUS_FILTERS.ONBOARDING_COMPLETED,
    POSITION_STATUS_FILTERS.DELIVERY,
    POSITION_STATUS_FILTERS.CLOSED,
    POSITION_STATUS_FILTERS.NO_SELECTION,
    POSITION_STATUS_FILTERS.CANCELLED,
  ],
  [POSITION_SUBMISSION_FILTERS.NO_CANDIDATE_SUBMITTED]: [
    ...DUE_DATE_FAMILY,
    ...SUBMISSION_FAMILY,
    ...PUBLISH_STATUS_FAMILY,
    POSITION_STATUS_FILTERS.RECRUITING,
    POSITION_STATUS_FILTERS.CLOSED,
    POSITION_STATUS_FILTERS.NO_SUBMISSIONS,
    POSITION_STATUS_FILTERS.CANCELLED,
  ],
  [POSITION_SUBMISSION_FILTERS.CANDIDATE_SUBMITTED]: [
    ...DUE_DATE_FAMILY,
    ...SUBMISSION_FAMILY,
    ...PUBLISH_STATUS_FAMILY,
    POSITION_STATUS_FILTERS.RECRUITING,
    POSITION_STATUS_FILTERS.SELECTION,
    POSITION_STATUS_FILTERS.ONBOARDING,
    POSITION_STATUS_FILTERS.OFFER,
    POSITION_STATUS_FILTERS.ONBOARDING_IN_PROGRESS,
    POSITION_STATUS_FILTERS.ONBOARDING_COMPLETED,
    POSITION_STATUS_FILTERS.DELIVERY,
    POSITION_STATUS_FILTERS.CLOSED,
    POSITION_STATUS_FILTERS.NO_SELECTION,
    POSITION_STATUS_FILTERS.CANCELLED,
  ],
  [PUBLISH_STATUS_FILTERS.PUBLISHED]: [
    ...SUBMISSION_FAMILY,
    ...PUBLISH_STATUS_FAMILY,
    DUE_DATE_FILTERS.DUE_TODAY,
    DUE_DATE_FILTERS.DUE_TOMORROW,
    DUE_DATE_FILTERS.DUE_NEXT_7_DAYS,
    DUE_DATE_FILTERS.NOT_EXPIRED,
    POSITION_STATUS_FILTERS.RECRUITING,
  ],
  [PUBLISH_STATUS_FILTERS.UNPUBLISHED]: [
    ...SUBMISSION_FAMILY,
    ...PUBLISH_STATUS_FAMILY,
    DUE_DATE_FILTERS.DUE_TODAY,
    DUE_DATE_FILTERS.DUE_TOMORROW,
    DUE_DATE_FILTERS.DUE_NEXT_7_DAYS,
    DUE_DATE_FILTERS.NOT_EXPIRED,
    POSITION_STATUS_FILTERS.RECRUITING,
  ],
};

const FILTER_COMBINATIONS_PM = {
  [DUE_DATE_FILTERS.DUE_TODAY]: [
    ...DUE_DATE_FAMILY,
    ...SUBMISSION_FAMILY,
    POSITION_STATUS_FILTERS.RECRUITING,
  ],
  [DUE_DATE_FILTERS.DUE_TOMORROW]: [
    ...DUE_DATE_FAMILY,
    ...SUBMISSION_FAMILY,
    POSITION_STATUS_FILTERS.RECRUITING,
  ],
  [DUE_DATE_FILTERS.DUE_NEXT_7_DAYS]: [
    ...DUE_DATE_FAMILY,
    ...SUBMISSION_FAMILY,
    POSITION_STATUS_FILTERS.RECRUITING,
  ],
  [DUE_DATE_FILTERS.EXPIRED]: [
    ...DUE_DATE_FAMILY,
    ...SUBMISSION_FAMILY,
    POSITION_STATUS_FILTERS.SELECTION,
    POSITION_STATUS_FILTERS.PENDING_SELECTION,
    POSITION_STATUS_FILTERS.NO_SELECTION,
    POSITION_STATUS_FILTERS.ONBOARDING,
    POSITION_STATUS_FILTERS.OFFER,
    POSITION_STATUS_FILTERS.ONBOARDING_IN_PROGRESS,
    POSITION_STATUS_FILTERS.ONBOARDING_COMPLETED,
    POSITION_STATUS_FILTERS.ONBOARDING_CANCELLED,
    POSITION_STATUS_FILTERS.DELIVERY,
    POSITION_STATUS_FILTERS.CLOSED,
  ],
  [DUE_DATE_FILTERS.NOT_EXPIRED]: [
    ...DUE_DATE_FAMILY,
    ...SUBMISSION_FAMILY,
    POSITION_STATUS_FILTERS.RECRUITING,
  ],
  [POSITION_STATUS_FILTERS.RECRUITING]: [
    ...STATUS_FAMILY,
    ...SUBMISSION_FAMILY,
    DUE_DATE_FILTERS.DUE_TODAY,
    DUE_DATE_FILTERS.DUE_TOMORROW,
    DUE_DATE_FILTERS.DUE_NEXT_7_DAYS,
    DUE_DATE_FILTERS.NOT_EXPIRED,
  ],
  [POSITION_STATUS_FILTERS.SELECTION]: [
    ...STATUS_FAMILY,
    DUE_DATE_FILTERS.EXPIRED,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_RECEIVED,
    POSITION_SUBMISSION_FILTERS.NO_CANDIDATE_SUBMITTED,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_SUBMITTED,
  ],
  [POSITION_STATUS_FILTERS.PENDING_SELECTION]: [
    ...STATUS_FAMILY,
    DUE_DATE_FILTERS.EXPIRED,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_RECEIVED,
    POSITION_SUBMISSION_FILTERS.NO_CANDIDATE_SUBMITTED,
  ],
  [POSITION_STATUS_FILTERS.NO_SELECTION]: [
    ...STATUS_FAMILY,
    DUE_DATE_FILTERS.EXPIRED,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_RECEIVED,
    POSITION_SUBMISSION_FILTERS.NO_CANDIDATE_SUBMITTED,
  ],
  [POSITION_STATUS_FILTERS.ONBOARDING]: [
    ...STATUS_FAMILY,
    DUE_DATE_FILTERS.EXPIRED,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_RECEIVED,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_SUBMITTED,
  ],
  [POSITION_STATUS_FILTERS.OFFER]: [
    ...STATUS_FAMILY,
    DUE_DATE_FILTERS.EXPIRED,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_RECEIVED,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_SUBMITTED,
  ],
  [POSITION_STATUS_FILTERS.ONBOARDING_IN_PROGRESS]: [
    ...STATUS_FAMILY,
    DUE_DATE_FILTERS.EXPIRED,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_RECEIVED,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_SUBMITTED,
  ],
  [POSITION_STATUS_FILTERS.ONBOARDING_COMPLETED]: [
    ...STATUS_FAMILY,
    DUE_DATE_FILTERS.EXPIRED,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_RECEIVED,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_SUBMITTED,
  ],
  [POSITION_STATUS_FILTERS.ONBOARDING_CANCELLED]: [
    ...STATUS_FAMILY,
    DUE_DATE_FILTERS.EXPIRED,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_RECEIVED,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_SUBMITTED,
  ],
  [POSITION_STATUS_FILTERS.DELIVERY]: [
    ...STATUS_FAMILY,
    DUE_DATE_FILTERS.EXPIRED,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_RECEIVED,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_SUBMITTED,
  ],
  [POSITION_STATUS_FILTERS.CLOSED]: [
    ...STATUS_FAMILY,
    DUE_DATE_FILTERS.EXPIRED,
    POSITION_SUBMISSION_FILTERS.CANDIDATE_RECEIVED,
    POSITION_SUBMISSION_FILTERS.NO_CANDIDATE_SUBMITTED,
  ],
  [POSITION_STATUS_FILTERS.CANCELLED]: [...STATUS_FAMILY, ...SUBMISSION_FAMILY],
  [POSITION_SUBMISSION_FILTERS.NO_CANDIDATE_RECEIVED]: [
    ...DUE_DATE_FAMILY,
    ...SUBMISSION_FAMILY,
    POSITION_STATUS_FILTERS.RECRUITING,
    POSITION_STATUS_FILTERS.CANCELLED,
  ],
  [POSITION_SUBMISSION_FILTERS.CANDIDATE_RECEIVED]: [
    ...DUE_DATE_FAMILY,
    ...SUBMISSION_FAMILY,
    POSITION_STATUS_FILTERS.RECRUITING,
    POSITION_STATUS_FILTERS.SELECTION,
    POSITION_STATUS_FILTERS.PENDING_SELECTION,
    POSITION_STATUS_FILTERS.NO_SELECTION,
    POSITION_STATUS_FILTERS.ONBOARDING,
    POSITION_STATUS_FILTERS.OFFER,
    POSITION_STATUS_FILTERS.ONBOARDING_IN_PROGRESS,
    POSITION_STATUS_FILTERS.ONBOARDING_COMPLETED,
    POSITION_STATUS_FILTERS.ONBOARDING_CANCELLED,
    POSITION_STATUS_FILTERS.DELIVERY,
    POSITION_STATUS_FILTERS.CLOSED,
    POSITION_STATUS_FILTERS.CANCELLED,
  ],
  [POSITION_SUBMISSION_FILTERS.NO_CANDIDATE_SUBMITTED]: [
    ...DUE_DATE_FAMILY,
    ...SUBMISSION_FAMILY,
    POSITION_STATUS_FILTERS.RECRUITING,
    POSITION_STATUS_FILTERS.SELECTION,
    POSITION_STATUS_FILTERS.PENDING_SELECTION,
    POSITION_STATUS_FILTERS.NO_SELECTION,
    POSITION_STATUS_FILTERS.CANCELLED,
  ],
  [POSITION_SUBMISSION_FILTERS.CANDIDATE_SUBMITTED]: [
    ...DUE_DATE_FAMILY,
    ...SUBMISSION_FAMILY,
    POSITION_STATUS_FILTERS.ONBOARDING,
    POSITION_STATUS_FILTERS.OFFER,
    POSITION_STATUS_FILTERS.ONBOARDING_IN_PROGRESS,
    POSITION_STATUS_FILTERS.ONBOARDING_COMPLETED,
    POSITION_STATUS_FILTERS.ONBOARDING_CANCELLED,
    POSITION_STATUS_FILTERS.DELIVERY,
    POSITION_STATUS_FILTERS.CLOSED,
    POSITION_STATUS_FILTERS.CANCELLED,
  ],
};

export const FILTER_COMBINATION_MAP = {
  [STAFF_ROLE]: FILTER_COMBINATIONS_MM,
  [SUPPLIER_ROLE]: FILTER_COMBINATIONS_SUPPLIER,
  [MANAGER_ROLE]: FILTER_COMBINATIONS_PM,
};

export const FILTERS_WITHOUT_CONDITIONS = [
  POSITION_FILTERS.PROJECT,
  POSITION_FILTERS.LOCATION,
  POSITION_FILTERS.CONTRACT_TYPE,
  POSITION_FILTERS.DELIVERY_OPTION,
  POSITION_FILTERS.CLEARANCE,
];
