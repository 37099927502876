import React, { useEffect } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import getProperty from 'lodash/get';
// hooks
import usePrevious from 'hooks/usePrevious';
// components
import Field from 'components/molecules/Field';
// constants
import { SCROLL_TO_ELEMENT_PREFIX } from 'constants';
// helpers
import { isComponentHidden } from 'helpers/Form';

const propTypes = {
  component: PropTypes.shape({
    key: PropTypes.string.isRequired,
    labelKey: PropTypes.string,
    descriptionKey: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    restrictUpdate: PropTypes.bool,
  }).isRequired,
  customComponent: PropTypes.shape({
    label: PropTypes.string,
    description: PropTypes.string,
    getComponent: PropTypes.any.isRequired,
    validate: PropTypes.func,
    hasError: PropTypes.func,
    hasMissingError: PropTypes.func,
    getErrorMessage: PropTypes.func,
  }).isRequired,
  formData: PropTypes.object.isRequired,
  updateFormData: PropTypes.func.isRequired,
  showErrors: PropTypes.bool.isRequired,
};

const CustomComponentParser = ({
  component,
  customComponent,
  formData,
  updateFormData,
  showErrors,
}) => {
  const { t } = useTranslation();
  const prevShowErrors = usePrevious(showErrors);
  const { key, labelKey, descriptionKey, required, disabled } = component;
  const {
    label,
    description,
    getComponent,
    validate,
    hasError,
    hasMissingError,
    getErrorMessage,
  } = customComponent;

  if (!getComponent) {
    throw new Error(`Missing "getComponent" function for ${key} custom component`);
  }

  // component functions
  const validateFn = validate || (value => value);
  const hasErrorFn = hasError || ((value, _data, requiredField) => requiredField && !value);
  const hasMissingErrorFn = hasMissingError || (value => !value);
  const getErrorMessageFn = getErrorMessage || (() => t('thisFieldIsRequired'));

  const value = getProperty(formData, [key, 'value']);
  const error = hasErrorFn(value, formData, required);
  const errorMessage = getErrorMessageFn(value, formData)
    ? `* ${getErrorMessageFn(value, formData)}`
    : '';

  const handleChange = newValue => {
    updateFormData(key, {
      value: showErrors ? validateFn(newValue) : newValue,
      error: hasErrorFn(newValue, formData, required),
    });
  };

  useEffect(() => {
    if (!prevShowErrors && showErrors) {
      handleChange(value);
    }
  }, [showErrors]);

  if (isComponentHidden(component, formData)) {
    return null;
  }

  return (
    <Field
      className={classNames(SCROLL_TO_ELEMENT_PREFIX + key, {
        redForm: !disabled && showErrors && error,
      })}
      label={label || t(labelKey)}
      description={description || t(descriptionKey)}
      required={required}
      content={getComponent({
        value,
        onChange: handleChange,
        required,
        disabled,
        error: !disabled && showErrors && error,
      })}
      fieldNotFilled={required && showErrors && hasMissingErrorFn(value, formData)}
      error={!disabled && showErrors && error}
      errorMessage={!disabled && showErrors ? errorMessage : ''}
    />
  );
};

CustomComponentParser.propTypes = propTypes;

export default CustomComponentParser;
