import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// components
import Icon from 'components/atoms/Icon';
// styles
import colors from 'dependencies/materialStyles/Colors';
import styles from './review.module.scss';

const propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  emptyLabel: PropTypes.string,
  isEmpty: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.any,
};

const defaultProps = {
  className: '',
  emptyLabel: '',
  isEmpty: false,
  error: false,
  errorMessage: '',
  children: null,
};

const ReviewSection = ({
  className,
  icon,
  label,
  emptyLabel,
  isEmpty,
  error,
  errorMessage,
  onClick,
  children,
}) => {
  return (
    <div className={classNames({ [className]: className })}>
      <div
        className={classNames(styles.container, { [styles.error]: error })}
        onClick={onClick}
        role="presentation"
      >
        <div className={styles.header}>
          <Icon name={icon} size={18} color={error ? colors.red : colors.blue} />
          <span className={styles.label}>{label}</span>
        </div>
        <div className={styles.content}>
          {isEmpty ? <span className={styles.emptyLabel}>{emptyLabel}</span> : children}
        </div>
      </div>
      {error && errorMessage && (
        <div className={styles.errorMessage}>
          <sup>*</sup> {errorMessage}
        </div>
      )}
    </div>
  );
};

ReviewSection.propTypes = propTypes;
ReviewSection.defaultProps = defaultProps;

export default ReviewSection;
