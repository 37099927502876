import getProperty from 'lodash/get';
import orderBy from 'lodash/orderBy';
// helpers
import { filterSubmissionsByStatus } from 'helpers/Submission';
// constants
import { START_DATE_STATUS } from 'constants/onboarding';
import { SUBMISSION_STATUS } from 'constants/submissionStatus';
import {
  RATES_NTE_RATE,
  RATES_TARGET_RATE,
  STAFF_ROLE,
  MANAGER_ROLE,
  SUPPLIER_ROLE,
} from 'constants';

/**
 * @param {object} position
 * @param {string} role
 * @returns {?string}
 */
export const getMainRate = (position, role) => {
  const targetRate = position.getRate(RATES_TARGET_RATE);
  const nteRate = position.getRate(RATES_NTE_RATE);

  if ([STAFF_ROLE, SUPPLIER_ROLE].includes(role) && position.showTargetRatesToPartners) {
    return getProperty(targetRate, 'value', '');
  }
  if (role === MANAGER_ROLE) {
    return getProperty(nteRate, 'value', '');
  }

  return '';
};

/**
 * @param {object} position
 * @param {string} role
 * @returns {?string}
 */
export const getSecondaryRate = (position, role) => {
  const nteRate = position.getRate(RATES_NTE_RATE);

  if (role === STAFF_ROLE) {
    return getProperty(nteRate, 'value', '');
  }
  if (role === MANAGER_ROLE) {
    return '';
  }

  return position.showNteRatesToPartners ? getProperty(nteRate, 'value', '') : '';
};

/**
 * Get expected onboarding candidate start date
 * @param {object} position
 * @param {object[]} submissions
 * @returns {string}
 */
export const getExpectedCandidateStartDate = (position, submissions) => {
  const positionStartDate = position.duration.start;
  const onboardingCandidates = filterSubmissionsByStatus(submissions, [
    SUBMISSION_STATUS.OFFER,
    SUBMISSION_STATUS.ONBOARDING,
  ]);

  if (!onboardingCandidates.length) {
    return positionStartDate;
  }

  // if some candidate onboarding -> find pending or approved start date for candidate
  const expectedStartDates = onboardingCandidates.map(candidate => {
    const startDates = getProperty(candidate, 'hiringProcess.dates.items', []);

    // approved start date has the higher priority then pending date
    const approvedStartDate = startDates.find(date => date.status === START_DATE_STATUS.APPROVED);
    if (approvedStartDate) return approvedStartDate;

    // if no approved start date -> return pending date
    return startDates.find(date => date.status === START_DATE_STATUS.PENDING);
  });

  // multiple candidates could be on-boarded on position so find the most recent date
  const sortedExpectedStartDates = orderBy(expectedStartDates.filter(date => date), 'date');

  // if no candidate start date -> fallback is position start date
  return getProperty(sortedExpectedStartDates, '0.date', positionStartDate);
};

/**
 * Get delivered position end date from PO
 * @param {object} position
 * @param {object[]} submissions
 * @return {string}
 */
export const getPositionEndDate = (position, submissions) => {
  const positionEndDate = position.duration.end;
  const deliveredCandidates = filterSubmissionsByStatus(submissions, SUBMISSION_STATUS.DELIVERY);

  // if some candidate delivered -> find end date from PO
  const poEndDates = deliveredCandidates.map(candidate => {
    return getProperty(candidate, 'hiringProcess.purchaseOrder.end');
  });

  // multiple candidates could be delivered to position so find the most the latest date
  const sortedPoEndDates = orderBy(poEndDates.filter(date => date), null, 'desc');

  // if no po end date -> fallback is position end date
  return getProperty(sortedPoEndDates, '0', positionEndDate);
};
