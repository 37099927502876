import React, { useEffect, useRef, forwardRef } from 'react';
// libraries
import PropTypes from 'prop-types';

const propTypes = {
  node: PropTypes.instanceOf(Element),
  forwardedRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
};

const defaultProps = {
  node: null,
  forwardedRef: null,
};

const NodeComponent = ({ node, forwardedRef, ...props }) => {
  const localRef = useRef();
  const element = forwardedRef || localRef;

  useEffect(() => {
    if (node) {
      element.current.replaceChildren(node);
    }
  }, [node]);

  return <div ref={element} {...props} />;
};

NodeComponent.propTypes = propTypes;
NodeComponent.defaultProps = defaultProps;

export default React.memo(
  forwardRef((props, ref) => <NodeComponent forwardedRef={ref} {...props} />)
);
