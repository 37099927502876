import i18n from 'i18next';
// helpers
import camelCase from 'lodash/camelCase';

const parseOptions = (data, fieldName) => {
  if (data && data.table && data.table.fields) {
    const tableField = data.table.fields.find(field => field.name === fieldName);
    if (
      tableField &&
      tableField.fieldTypeAttributes &&
      tableField.fieldTypeAttributes.listOptions
    ) {
      return tableField.fieldTypeAttributes.listOptions.map(opt => {
        return {
          value: opt,
          label: i18n.t(camelCase(opt)),
        };
      });
    }
    return [];
  }
  return [];
};

export default parseOptions;
