import React from 'react';
// libraries
import PropTypes from 'prop-types';
// components
import ClickableElement from 'components/atoms/ClickableElement';
import Icon from 'components/atoms/Icon';
// styles
import colors from 'dependencies/materialStyles/Colors';
import styles from './rateInput.module.scss';

const propTypes = {
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

const Button = ({ icon, onClick }) => {
  return (
    <ClickableElement onClick={onClick} className={styles.button}>
      <Icon name={icon} size={18} color={colors.gray} />
    </ClickableElement>
  );
};

Button.propTypes = propTypes;

export default Button;
