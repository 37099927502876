import React, { useContext } from 'react';
import DialogContext from './DialogContext';
import styles from './dialog.module.scss';

interface DialogFooterProps {
  children: React.ReactNode;
}

const DialogFooter = ({ children }: DialogFooterProps) => {
  const { loading } = useContext(DialogContext);

  if (loading) {
    return null;
  }

  return <div className={styles.footer}>{children}</div>;
};

export default DialogFooter;
