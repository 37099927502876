import React from 'react';
// libraries
import getProperty from 'lodash/get';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
// components
import InfiniteScroll from 'components/atoms/InfiniteScroll';

const propTypes = {
  variables: PropTypes.object,
  children: PropTypes.any.isRequired,
  query: PropTypes.object.isRequired,
  scrollableTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Element)]),
};

const defaultProps = {
  variables: null,
  scrollableTarget: null,
};

const CursorBasedList = ({ variables, children, query, scrollableTarget }) => {
  const { networkStatus, data, error, fetchMore } = useQuery(query, {
    variables,
    fetchPolicy: 'cache-first',
  });

  const list = data ? getProperty(Object.values(data), '0') : [];
  const fetchedItems = getProperty(list, 'items', []);
  const cursor = getProperty(list, 'cursor');

  const handleFetchMore = () => {
    fetchMore({
      query,
      variables: {
        ...variables,
        after: cursor,
      },
    });
  };

  return (
    <InfiniteScroll
      hasMore={!!cursor}
      dataLength={fetchedItems.length}
      next={handleFetchMore}
      scrollableTarget={scrollableTarget}
    >
      {children({
        data: fetchedItems,
        error,
        loading: networkStatus === 1 || networkStatus === 2 || networkStatus === 4,
      })}
    </InfiniteScroll>
  );
};

CursorBasedList.propTypes = propTypes;
CursorBasedList.defaultProps = defaultProps;

export default CursorBasedList;
