import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
import shortid from 'shortid';
// components
import Chip from 'components/atoms/Chip';
// Helpers
import { trimLabel } from 'helpers/StringParser';
// style
import style from './SurveyLine.module.scss';

const propTypes = {
  chips: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.string,
};
const defaultProps = {
  selected: null,
};

const Chips = ({ chips, onChange, selected }) => {
  return (
    <div className={classNames(style.chips, 'cp')}>
      {chips.map((chip, index) => {
        return (
          <div
            key={shortid.generate()}
            onClick={() => onChange(chip)}
            className={index < chips.length - 1 ? 'p-r-5' : ''}
            role="presentation"
          >
            <Chip
              label={trimLabel(chip.label, 3)}
              color={`${selected === chip.value ? 'blue' : 'lightGray'}`}
              type="header"
            />
          </div>
        );
      })}
    </div>
  );
};

Chips.propTypes = propTypes;
Chips.defaultProps = defaultProps;

export default Chips;
