import React, { useEffect, useRef, useMemo } from 'react';
// libraries
import PropTypes from 'prop-types';
import Fade from '@material-ui/core/Fade';
import PopperMui from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import classNames from 'classnames';
// hooks
import usePrevious from 'hooks/usePrevious';
// styles
import styles from './popper.module.scss';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
  disableBackdrop: PropTypes.bool,
  anchorEl: PropTypes.object,
  onClose: PropTypes.func,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  style: PropTypes.object,
  offsetX: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  offsetY: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  modifiers: PropTypes.object,
  placement: PropTypes.oneOf([
    'bottom-end',
    'bottom-start',
    'bottom',
    'left-end',
    'left-start',
    'left',
    'right-end',
    'right-start',
    'right',
    'top-end',
    'top-start',
    'top',
  ]),
};

const defaultProps = {
  disableBackdrop: false,
  onClose: () => {},
  placement: 'bottom-start',
  anchorEl: null,
  className: '',
  containerClassName: '',
  style: null,
  modifiers: null,
  offsetX: 0,
  offsetY: 0,
};

const Popper = ({
  isOpen,
  disableBackdrop,
  anchorEl,
  onClose,
  children,
  placement,
  className,
  containerClassName,
  style,
  modifiers,
  offsetX,
  offsetY,
  ...props
}) => {
  const backdropElement = useRef();
  const bodyElement = useRef(document.body);
  const prevIsOpen = usePrevious(isOpen);

  useEffect(() => {
    backdropElement.current = document.getElementById('popperBackdrop');

    if (!backdropElement.current) {
      // displaying a popup in a modal must have higher z-index than modal window so we need to add
      // a top level container with higher z-index. This container acts also as a backdrop.
      backdropElement.current = document.createElement('div');
      backdropElement.current.setAttribute('id', 'popperBackdrop');
      backdropElement.current.setAttribute('role', 'presentation');
      backdropElement.current.setAttribute('class', styles.backdrop);
      document.body.appendChild(backdropElement.current);
    }

    backdropElement.current.style.display = isOpen && !disableBackdrop ? 'block' : 'none';

    if (!prevIsOpen && isOpen && !disableBackdrop) {
      bodyElement.current.style.overflow = 'hidden';
    }

    if (prevIsOpen && !isOpen) {
      bodyElement.current.style.overflow = 'unset';
    }

    return () => {
      if (isOpen) {
        backdropElement.current.style.display = 'none';
        bodyElement.current.style.overflow = 'unset';
      }
    };
  }, [isOpen]);

  const modifiersOptions = useMemo(() => {
    return {
      offset: {
        enabled: !!offsetX || !!offsetY,
        offset: `${offsetY}, ${offsetX}`,
      },
      ...(modifiers || {}),
    };
  }, [offsetX, offsetY, modifiers]);

  return (
    <PopperMui
      open={isOpen}
      placement={placement}
      anchorEl={anchorEl}
      container={!disableBackdrop ? backdropElement.current : null}
      className={containerClassName}
      modifiers={modifiersOptions}
      transition
      {...props}
    >
      {({ TransitionProps }) => (
        <ClickAwayListener onClickAway={onClose}>
          <Fade {...TransitionProps} timeout={250}>
            <div
              role="textbox"
              tabIndex="-1"
              onClick={e => (disableBackdrop ? e.stopPropagation() : null)}
              onKeyPress={e => (disableBackdrop ? e.stopPropagation() : null)}
              className={classNames(styles.content, className)}
              style={style}
            >
              {children}
            </div>
          </Fade>
        </ClickAwayListener>
      )}
    </PopperMui>
  );
};

Popper.propTypes = propTypes;
Popper.defaultProps = defaultProps;

export default Popper;
