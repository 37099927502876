export const COLOR_BLUE = 'blue';
export const COLOR_GREEN = 'green';
export const COLOR_RED = 'red';
export const COLOR_YELLOW = 'yellow';
export const COLOR_ORANGE = 'orange';
export const COLOR_GRAY = 'gray';
export const COLOR_DARK_GRAY = 'darkGray';
export const COLOR_LIGHT_GRAY = 'lightGray';
export const COLOR_WHITE = 'white';

export const COLOR = {
  BLUE: COLOR_BLUE,
  GREEN: COLOR_GREEN,
  RED: COLOR_RED,
  YELLOW: COLOR_YELLOW,
  ORANGE: COLOR_ORANGE,
  GRAY: COLOR_GRAY,
  DARK_GRAY: COLOR_DARK_GRAY,
  LIGHT_GRAY: COLOR_LIGHT_GRAY,
  WHITE: COLOR_WHITE,
};

export const SUPPORTED_COLORS = [
  COLOR_BLUE,
  COLOR_GREEN,
  COLOR_RED,
  COLOR_YELLOW,
  COLOR_LIGHT_GRAY,
  COLOR_GRAY,
  COLOR_DARK_GRAY,
  COLOR_WHITE,
];
