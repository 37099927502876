import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
// hooks
import useAuth from 'hooks/useAuth';
// constants
import { STAFF_ROLE, SUPPLIER_ROLE, MANAGER_ROLE } from 'constants';
// components
import PositionRow from './PositionRow';
// styles
import styles from './positionTable.module.scss';

const propTypes = {
  positions: PropTypes.arrayOf(PropTypes.object),
};

const defaultProps = {
  positions: [],
};

const PositionTable = ({ positions }) => {
  const { t } = useTranslation();
  const { role } = useAuth();

  if (!positions.length) {
    return null;
  }

  const getRateLabel = () => {
    if (role === MANAGER_ROLE) {
      return t('nteRate');
    }

    return t('targetRate');
  };

  return (
    <div className={styles.table}>
      <div
        className={classNames(styles.grid, {
          [styles.partner]: role === SUPPLIER_ROLE,
          [styles.matchManager]: role === STAFF_ROLE,
          [styles.projectManager]: role === MANAGER_ROLE,
        })}
      >
        <div className={classNames(styles.head)}>{t('request')}</div>
        <div className={styles.head}>{t('status')}</div>
        <div className={classNames(styles.head, styles.alignRight)}>{t('dueDate')}</div>
        <div className={classNames(styles.head, styles.alignRight)}>{getRateLabel()}</div>
        {role === STAFF_ROLE && (
          <div className={classNames(styles.head, styles.alignRight)}>{t('nteRate')}</div>
        )}
        <div className={styles.head}>{t('location')}</div>
        <div className={classNames(styles.head, styles.alignRight)}>{t('submissions')}</div>
        {role !== STAFF_ROLE && (
          <div className={classNames(styles.head, styles.alignRight)}>{t('startDate')}</div>
        )}
        <div className={styles.head}>{t('resolution')}</div>
      </div>

      {positions.map((position, index) => {
        return (
          <PositionRow
            key={position.id}
            position={position}
            className={positions.length - 1 === index ? 'border-bottom' : ''}
          />
        );
      })}
    </div>
  );
};

PositionTable.propTypes = propTypes;
PositionTable.defaultProps = defaultProps;

export default PositionTable;
