import React from 'react';
// Libraries
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// helpers
import { boxFileUrl } from 'helpers/Box';
import { getPassedRelativeTime } from 'helpers/RelativeTimeFormatter';
// components
import Chip from 'components/atoms/Chip';
import RevisionNote from './RevisionNote';

const propTypes = {
  className: PropTypes.string,
  versions: PropTypes.array,
};

const defaultProps = {
  className: '',
  versions: [],
};

const FileHistory = ({ className, versions }) => {
  return (
    <div className={`historyModal ${className}`}>
      {versions.map((file, index) => {
        const url = boxFileUrl(file.boxId);
        const { version, fileName, revision, name, date } = file;

        return (
          <div
            key={file.id}
            className={`historyModal_item ${
              versions.length - 1 <= index ? 'border-bottom-none' : ''
            }`}
          >
            <Chip label={`V${version}`} color="blue" />
            <div className="historyModal_content">
              <Link to={url} target="_blank">
                <div className="element_link">{fileName}</div>
              </Link>
              <div className={`historyModal_item_info p-0 ${revision ? 'p-b-5' : ''}`}>
                {name && <div className="historyModal_item_info_name">{name}</div>}
                <div className="historyModal_item_info_date">{getPassedRelativeTime(date)}</div>
              </div>
              {revision && (
                <RevisionNote
                  note={revision.note}
                  requestor={revision.requestor}
                  date={getPassedRelativeTime(revision.date)}
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

FileHistory.propTypes = propTypes;
FileHistory.defaultProps = defaultProps;

export default FileHistory;
