import React, { useState, useEffect } from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isNaN from 'lodash/isNaN';
// helpers
import { formatNumber } from 'helpers/Number';
import { isValid as isValidHelper } from './helpers';
// styles
import styles from './numberInput.module.scss';

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  decimalLimit: PropTypes.number,
  allowNegative: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onError: PropTypes.func,
  placeholder: PropTypes.string,
  border: PropTypes.bool,
  highlight: PropTypes.bool,
  className: PropTypes.string,
  useStyle: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  floatLeft: PropTypes.bool,
  formatter: PropTypes.func,
};

const defaultProps = {
  value: '',
  decimalLimit: 0,
  allowNegative: false,
  min: null,
  max: null,
  step: 1,
  onFocus: () => {},
  onBlur: () => {},
  onError: () => {},
  placeholder: '',
  border: true,
  highlight: false,
  className: '',
  useStyle: true,
  disabled: false,
  readOnly: false,
  floatLeft: false,
  formatter: value => value,
};

const NumberInput = ({
  value,
  decimalLimit,
  allowNegative,
  min,
  max,
  step,
  onChange,
  onBlur,
  onFocus,
  onError,
  placeholder,
  border,
  highlight,
  useStyle,
  disabled,
  readOnly,
  floatLeft,
  className,
  formatter,
  ...props
}) => {
  const [internalValue, setValue] = useState('');
  const [focused, setFocused] = useState(false);

  const isValid = val => {
    return isValidHelper(val, {
      decimalLimit,
      allowNegative,
      min,
      max,
    });
  };

  useEffect(() => {
    const parsedValue = value ? value.toString() : '';

    if ((value === 0 || value === '0') && isValid(0)) {
      setValue('0');
      return;
    }

    if (Number(value) === Number(internalValue)) {
      return;
    }

    if (parsedValue && parsedValue.substr(-1) !== '.' && isValid(value)) {
      setValue(parsedValue);
    } else {
      setValue('');
    }
  }, [value]);

  const getValue = () => {
    if (focused) {
      return internalValue;
    }

    const parsedValue = parseFloat(internalValue);

    if (isNaN(parsedValue)) {
      return internalValue;
    }

    return formatter(parsedValue);
  };

  const handleFocus = () => {
    setFocused(true);
    onFocus();
  };

  const handleBlur = () => {
    setFocused(false);
    onBlur();
  };

  const handleChange = newValue => {
    if (!newValue) {
      onChange(null);
      setValue('');
    }

    if (isValid(newValue)) {
      onChange(Number(newValue));
      setValue(newValue.toString());
    } else {
      onError(newValue);
    }
  };

  const handleKeyPress = event => {
    // arrow up
    if (event.keyCode === 38) {
      const currentValue = Number(value) || 0;
      const addedValue = currentValue + step;
      const newValue = max === null ? addedValue : Math.min(addedValue, max);

      handleChange(formatNumber(newValue, decimalLimit));
    }

    // arrow down
    if (event.keyCode === 40) {
      const currentValue = Number(value) || 0;
      const subtractedValue = currentValue - step;
      const newValue = min === null ? subtractedValue : Math.max(subtractedValue, min);

      handleChange(formatNumber(newValue, decimalLimit));
    }
  };

  const classes = classNames({
    [styles.input]: useStyle,
    [styles.highlighted]: highlight && useStyle,
    [styles.bordered]: border && useStyle,
    [styles.disabled]: disabled,
    [styles.readOnly]: readOnly,
    [styles.floatLeft]: floatLeft,
    [className]: className,
  });

  return (
    <input
      className={classes}
      type="text"
      value={getValue()}
      onChange={event => handleChange(event.target.value)}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onKeyDown={handleKeyPress}
      placeholder={placeholder}
      autoComplete="off"
      disabled={disabled || readOnly}
      {...props}
    />
  );
};

NumberInput.propTypes = propTypes;
NumberInput.defaultProps = defaultProps;

export default NumberInput;
