import React from 'react';
// Libraries
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
// constants
import { VIEW_TABLE, VIEW_CARD } from 'constants';
// components
import Button from 'components/atoms/Button';
// styles
import colors from 'dependencies/materialStyles/Colors';
import './viewSwitch.scss';

const ViewSwitch = props => {
  const { toggleView, t, tableView } = props;
  return (
    <div className="viewSwitch">
      <div className={classNames('fs-unmask', { active: tableView === VIEW_TABLE })}>
        <Button
          tooltip={t('tableView')}
          type="tableView"
          size={18}
          onClick={() => toggleView(VIEW_TABLE)}
          color={colors[`${tableView === VIEW_TABLE ? 'blue' : 'gray'}`]}
        />
      </div>
      <div className={classNames('fs-unmask', { active: tableView === VIEW_CARD })}>
        <Button
          tooltip={t('cardView')}
          type="cardView"
          size={18}
          onClick={() => toggleView(VIEW_CARD)}
          color={colors[`${tableView === VIEW_CARD ? 'blue' : 'gray'}`]}
        />
      </div>
    </div>
  );
};

ViewSwitch.propTypes = {
  toggleView: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  tableView: PropTypes.string.isRequired,
};

export default withTranslation()(ViewSwitch);
