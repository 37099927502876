import React from 'react';
// libraries
import PropTypes from 'prop-types';
import MaterialMenu from '@material-ui/core/Menu';
// components
import Item from './Item';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.any,
  anchorOrigin: PropTypes.object,
  transformOrigin: PropTypes.object,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      label: PropTypes.string,
      onClick: PropTypes.func,
      loading: PropTypes.bool,
      disabled: PropTypes.bool,
      selected: PropTypes.bool,
    })
  ),
};

const defaultProps = {
  anchorEl: null,
  anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
  transformOrigin: { vertical: 'top', horizontal: 'right' },
  items: [],
};

const Menu = ({ isOpen, onClose, items, anchorEl, anchorOrigin, transformOrigin }) => {
  return (
    <MaterialMenu
      open={isOpen}
      onClose={onClose}
      disableAutoFocusItem
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      getContentAnchorEl={null}
    >
      {items.map(item => (
        <Item key={item.label} {...item} />
      ))}
    </MaterialMenu>
  );
};

Menu.propTypes = propTypes;
Menu.defaultProps = defaultProps;

export default Menu;
