// libraries
import getProperty from 'lodash/get';
import i18n from 'i18next';
// services
import client from 'services/Client';
// constants
import {
  TOGGLE_NOTIFICATION_EVENT,
  TOGGLE_NOTIFICATION_EVENT_LIST,
} from 'queries/NotificationQueries';
import {
  NOTIFICATION_GROUP_POSITION,
  NOTIFICATION_GROUP_CANDIDATE,
  NOTIFICATION_GROUP_SUBMISSION,
  NOTIFICATION_GROUP_ONBOARDING,
  NOTIFICATION_GROUP_REVISION,
  NOTIFICATION_GROUP_PURCHASE_ORDER,
  NOTIFICATION_GROUP_PURCHASE_PROJECT,
  NOTIFICATION_TYPE_IN_APP,
  NOTIFICATION_TYPE_EMAIL,
  NOTIFICATION_GROUP_IMPORT,
} from './Constants';
import { eventGroups } from './EventMapping';

// Currently supported notification event types
export const types = [
  {
    id: NOTIFICATION_TYPE_IN_APP,
    label: i18n.t('inApp'),
  },
  {
    id: NOTIFICATION_TYPE_EMAIL,
    label: i18n.t('email'),
  },
];

// Helper function to format menu tab parameters into object
const formatTab = (id, icon, label) => {
  return { id, icon, label };
};

/**
 * Mapping of notification group with id, icon and name that are used for menu tabs
 * @param {string} name - name of group
 * @returns {string}
 */
const getTabByName = name => {
  switch (name) {
    case NOTIFICATION_GROUP_POSITION:
      return formatTab(name, 'assignment', i18n.t('positions'));
    case NOTIFICATION_GROUP_CANDIDATE:
      return formatTab(name, 'users', i18n.t('candidatePool'));
    case NOTIFICATION_GROUP_SUBMISSION:
      return formatTab(name, 'candidates', i18n.t('submissions'));
    case NOTIFICATION_GROUP_ONBOARDING:
      return formatTab(name, 'onboarding', i18n.t('onboarding'));
    case NOTIFICATION_GROUP_REVISION:
      return formatTab(name, 'revision', i18n.t('revisions'));
    case NOTIFICATION_GROUP_PURCHASE_ORDER:
      return formatTab(name, 'purchaseOrder', i18n.t('purchaseOrders'));
    case NOTIFICATION_GROUP_PURCHASE_PROJECT:
      return formatTab(name, 'project', i18n.t('projects'));
    case NOTIFICATION_GROUP_IMPORT:
      return formatTab(name, 'import', i18n.t('imports'));
    default:
      return {};
  }
};

/**
 * Get group of provided notification event
 *
 * @param {string} event - name of notification event
 */
const parseGroup = event => {
  return Object.keys(eventGroups).find(key => eventGroups[key].includes(event));
};

/**
 * Helper function to order events/groups by their index in provided template
 *
 * @param {string} eventKey1 - first event key
 * @param {string} eventKey2 - second event key
 * @param {object} notificationGroup - array of notification events/groups
 */
const compareEventsOrGroupsByIndex = (eventKey1, eventKey2, notificationGroup) => {
  if (notificationGroup.indexOf(eventKey1) < notificationGroup.indexOf(eventKey2)) {
    return -1;
  }
  if (notificationGroup.indexOf(eventKey1) > notificationGroup.indexOf(eventKey2)) {
    return 1;
  }
  return 0;
};

/**
 * Helper function to sort notification menu tabs based on provided template
 *
 * @param {*} unsortedTabs - array of unsorted tabs
 */
const toSortedTabs = unsortedTabs => {
  return unsortedTabs
    .sort((a, b) => {
      return compareEventsOrGroupsByIndex(a, b, Object.keys(eventGroups));
    })
    .map(tab => getTabByName(tab));
};

/**
 * Helper function to sort notification events within groups based on provided template
 *
 * @param {object} eventList - object of grouped notification events
 */
const toSortedEventList = eventList => {
  Object.keys(eventList).forEach(groupKey => {
    eventList[groupKey].sort((a, b) => {
      return compareEventsOrGroupsByIndex(a.event, b.event, eventGroups[groupKey]);
    });
  });
  return eventList;
};

/**
 * Get dynamic tabs and parsed/grouped event list based on user's notification events
 *
 * @param {object} data - raw object from event list GET request
 */
export const parseEvents = data => {
  const events = getProperty(data, 'subscribedEventsList.items', []);

  const parsedEvents = events.reduce(
    (tabsAndEvents, event) => {
      const group = parseGroup(event.event);
      if (group) {
        const { tabs, eventList } = tabsAndEvents;

        if (tabs.includes(group)) {
          return {
            ...tabsAndEvents,
            eventList: {
              ...eventList,
              [group]: [...eventList[group], event],
            },
          };
        }

        return {
          tabs: [...tabs, group],
          eventList: { ...eventList, [group]: [event] },
        };
      }
      return tabsAndEvents;
    },
    { tabs: [], eventList: {} }
  );

  return {
    tabs: toSortedTabs(parsedEvents.tabs),
    eventList: toSortedEventList(parsedEvents.eventList),
  };
};

/**
 * Get 'enabled' and 'exists' values for the whole group of notification events
 * based on provided type
 *
 * @param {*} list - array of events that belong to provided group
 * @param {string} type - type of notification event (i.e. web, email)
 */
const getHeaderTypeSetup = (list, type) => {
  return {
    enabled: list.some(
      event => getProperty(event, `${type}.exists`) && getProperty(event, `${type}.enabled`)
    ),
    exists: list.some(event => getProperty(event, `${type}.exists`)),
  };
};

/**
 * Get properties of notification events for the whole group/section
 * i.e. all Position notification events, all Candidate notification events...
 *
 * @param {*} list - array of events that belong to provided group
 * @param {string} event - name of events group
 */
const getHeaderById = (list, event) => {
  return {
    id: event,
    event,
    web: {
      ...getHeaderTypeSetup(list, 'web'),
    },
    email: {
      ...getHeaderTypeSetup(list, 'email'),
    },
  };
};

/**
 * Get notification events content based on selected tab that consists of
 * list of notification events that belong to provided group
 * and header setup
 *
 * @param {object} eventList - notification events grouped into sections based on common area
 * @param {object} tab - selected tab
 */
export const getTabEvents = (eventList, tab) => {
  const list = getProperty(eventList, tab.id);
  const header = getHeaderById(list, tab.id);

  return {
    header,
    list,
  };
};

/**
 * Get all notification events that needs to be changed
 * based on group type setup and are not disabled
 */
export const getGroupEvents = ({ enabled, type, eventList, eventItem }) => {
  const filteredEventList = getProperty(eventList, `${eventItem.event}`, []).filter(
    event => event[type].enabled !== enabled && event[type].exists
  );
  const events = filteredEventList.map(event => event.event);
  const optimisticEvents = filteredEventList.map(event => ({
    ...event,
    [type]: { ...event[type], enabled },
  }));
  return { events, optimisticEvents };
};

/**
 * Mutation for turning on/off notification event based on provided event name and type
 *
 * @param {object} notificationEvent - object with notification event data
 */
export const toggleNotificationEvent = notificationEvent => {
  const { type, enabled, eventVariables } = notificationEvent;
  return client.mutate({
    mutation: TOGGLE_NOTIFICATION_EVENT,
    variables: { event: eventVariables.event, type, enabled },
    optimisticResponse: {
      __typename: 'Mutation',
      notificationEnable: {
        ...eventVariables,
      },
    },
  });
};

/**
 * Mutation for turning on/off notification event list
 *
 * @param {object} notificationEventList - object with notification events data
 */
export const toggleNotificationEventList = notificationEventList => {
  const { events, enabled, type, optimisticEvents } = notificationEventList;
  return client.mutate({
    mutation: TOGGLE_NOTIFICATION_EVENT_LIST,
    variables: { events, type, enabled },
    optimisticResponse: {
      __typename: 'Mutation',
      notificationsListEnable: optimisticEvents,
    },
  });
};
