import React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import {
  FaFilePdf,
  FaFileImage,
  FaFileCsv,
  FaFileExcel,
  FaFileAlt,
  FaFileWord,
  FaFileArchive,
  FaFile,
} from 'react-icons/fa';
import Icon from '@components/Icon';
import getFileType from './helpers/getFileType';
import getIconColor from './helpers/getIconColor';

interface FileIconProps {
  filename: string;
  size?: SvgIconProps['fontSize'];
  disabled?: boolean;
}

const FileIcon = ({ filename, size, disabled = false }: FileIconProps) => {
  const extension = filename.indexOf('.') ? filename.split('.').pop() : '';
  const fileType = getFileType(extension || '');
  const iconColor = getIconColor(fileType, disabled);

  switch (fileType) {
    case 'pdf':
      return (
        <Icon
          component={FaFilePdf}
          data-testid={fileType}
          color={iconColor}
          size={size || 'medium'}
        />
      );
    case 'image':
      return (
        <Icon
          component={FaFileImage}
          data-testid={fileType}
          color={iconColor}
          size={size || 'medium'}
        />
      );
    case 'csv':
      return (
        <Icon
          component={FaFileCsv}
          data-testid={fileType}
          color={iconColor}
          size={size || 'medium'}
        />
      );
    case 'spreadsheet':
      return (
        <Icon
          component={FaFileExcel}
          data-testid={fileType}
          color={iconColor}
          size={size || 'medium'}
        />
      );
    case 'text':
      return (
        <Icon
          component={FaFileAlt}
          data-testid={fileType}
          color={iconColor}
          size={size || 'medium'}
        />
      );
    case 'msWord':
      return (
        <Icon
          component={FaFileWord}
          data-testid={fileType}
          color={iconColor}
          size={size || 'medium'}
        />
      );
    case 'compress':
      return (
        <Icon
          component={FaFileArchive}
          data-testid={fileType}
          color={iconColor}
          size={size || 'medium'}
        />
      );
    case 'other':
      return (
        <Icon component={FaFile} data-testid={fileType} color={iconColor} size={size || 'medium'} />
      );
    default:
      return <Icon component={FaFile} data-testid={fileType} size={size || 'medium'} />;
  }
};

export default FileIcon;
