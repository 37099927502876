import React from 'react';
import { useTranslation } from 'react-i18next';
// hooks
import useAuth from 'hooks/useAuth';
// components
import MenuGroup from './MenuGroup';

const Staff = ({ onClick, currentLink, isOpen }) => {
  const { t } = useTranslation();
  const user = useAuth();

  const routes = [
    {
      label: t('positions'),
      items: [
        {
          label: t('positions'),
          link: '/positions/all',
          icon: 'assignment',
          className: 'fs-unmask',
        },
      ],
    },
    {
      label: t('submissions'),
      items: [
        {
          label: t('submissions'),
          link: '/candidates/all',
          icon: 'candidates',
          className: 'fs-unmask',
        },
        {
          label: t('proposals'),
          link: '/proposals/all',
          icon: 'proposal',
          className: 'fs-unmask',
        },
      ],
    },
  ];

  if (user.isSuperAdmin()) {
    routes.push({
      label: t('management'),
      items: [
        {
          label: t('projects'),
          link: '/projects',
          icon: 'project',
        },
        {
          label: t('users'),
          link: '/users',
          icon: 'users',
        },
        {
          label: t('suppliers'),
          link: '/suppliers',
          icon: 'suppliers',
        },
      ],
    });
  }

  routes.push(
    ...[
      {
        label: t('drafts'),
        items: [
          {
            label: t('myDrafts'),
            link: '/drafts',
            icon: 'edit',
          },
        ],
      },
    ]
  );

  return routes.map(route => {
    return (
      <div className="group" key={`staff_${route.label}`}>
        {isOpen && <div className="group_label">{route.label}</div>}
        <MenuGroup
          className={route.className}
          routes={route.items}
          onClick={onClick}
          currentLink={currentLink}
          isOpen={isOpen}
        />
      </div>
    );
  });
};

export default Staff;
