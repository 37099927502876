import colors from 'dependencies/materialStyles/Colors';

const autoCompleteControl = {
  border: `1px solid ${colors.gray4}`,
  borderRadius: 0,
  fontFamily: 'Lato-Light',
  boxShadow: 'none',
  backgroundColor: 'transparent',
  fontSize: '13px',
  paddingLeft: '2px',
  '&:hover': {
    border: `1px solid ${colors.gray4}`,
    boxShadow: 'none',
  },
  '&:focus': {
    border: `1px solid ${colors.gray4}`,
    boxShadow: 'none',
  },
};

export const activeAutoCompleteStyles = {
  control: provided => ({
    ...provided,
    ...autoCompleteControl,
  }),
  input: provided => ({
    ...provided,
    fontFamily: 'Lato-Light !important',
    fontSize: 13,
  }),
  placeholder: provided => ({
    ...provided,
    color: colors.gray,
    fontFamily: 'Lato-Italic',
    fontSize: '13px',
  }),
  menu: {
    position: 'absolute',
    zIndex: 101,
    marginTop: 0,
    left: 0,
    right: 0,
    backgroundColor: 'white',
    border: `1px solid ${colors.gray4}`,
    borderTop: 'none',
  },
  menuList: provided => ({
    ...provided,
    maxHeight: '200px',
  }),
  menuItem: {
    fontFamily: 'Lato-Light',
    fontSize: '13px',
    lineHeight: '25px',
    minHeight: '25px',
    display: 'flex',
  },
  singleValue: {
    fontSize: 16,
    fontFamily: 'Lato-Light',
  },
  clearIcon: {
    paddingRight: '5px',
    cursor: 'pointer',
  },
  dropdownIcon: {
    padding: '0 4px',
    cursor: 'pointer',
  },
  flex: {
    display: 'flex',
  },
};

export const disabledAutoCompleteStyles = {
  ...activeAutoCompleteStyles,
  control: provided => ({
    ...provided,
    ...autoCompleteControl,
    backgroundColor: colors.gray2,
  }),
};
