/* eslint-disable import/order */
import React, { useState } from 'react';
// libraries
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import getProperty from 'lodash/get';
// helpers
import { getPartnerCategoryText } from 'helpers/NameMapping';
import { getDefaultSellRates, initSellRates, initDocuments } from '../helpers';
// components
import Checkbox from 'components/atoms/Checkbox';
import Section from 'components/molecules/FieldSection';
import Table from '../Table';
import Errors from '../Errors';
import SortPopover, { SORT_TYPE } from '../SortPopover';
import PreviouslySavedLegend from '../PreviouslySavedLegend';
import Submission from './Submission';
// constants
import { SUBMISSION_STATUS } from 'constants/submissionStatus';
import { RATES_SUPPLIER_RATE } from 'constants';
import { SORT_BY } from '../constants';
// styles
import styles from './selection.module.scss';

const propTypes = {
  isProposal: PropTypes.bool.isRequired,
  submissions: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelect: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  selectedSubmissions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  sellRates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      rates: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.number,
          year: PropTypes.number.isRequired,
        })
      ),
    })
  ),
  position: PropTypes.shape({
    title: PropTypes.string.isRequired,
    rfq: PropTypes.string.isRequired,
    project: PropTypes.string.isRequired,
    experienceLevel: PropTypes.string.isRequired,
    occupations: PropTypes.arrayOf(PropTypes.string).isRequired,
    currency: PropTypes.string.isRequired,
    manYearDiscount: PropTypes.number.isRequired,
    nteRates: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number.isRequired,
        year: PropTypes.number.isRequired,
      })
    ).isRequired,
  }).isRequired,
  error: PropTypes.bool,
  hasSavedSubmissions: PropTypes.bool.isRequired,
};

const defaultProps = {
  error: false,
  sellRates: [],
};

const Selection = ({
  isProposal,
  submissions,
  onSelect,
  onSort,
  position,
  selectedSubmissions,
  sellRates,
  error,
  hasSavedSubmissions,
}) => {
  const { t } = useTranslation();
  const [showOnlyNewSubmissions, setShowOnlyNewSubmissions] = useState(false);

  const { rfq, manYearDiscount, nteRates } = position;
  const newSubmissions = submissions.filter(submission =>
    submission.hasStatus(SUBMISSION_STATUS.NEW)
  );
  const filteredSubmissions = showOnlyNewSubmissions ? newSubmissions : submissions;

  const handleSelect = (submission, selected) => {
    if (!selected) {
      // sell rates from rates simulation table (position detail)
      const submissionSellRates = sellRates.find(ratesList => ratesList.id === submission.id);
      const providedSellRates = getProperty(submissionSellRates, 'rates');
      const { submissionFile, attestation } = initDocuments(submission, rfq, isProposal);

      onSelect([
        ...selectedSubmissions,
        {
          id: submission.id,
          sellRates: initSellRates(submission, providedSellRates, nteRates, manYearDiscount),
          defaultSellRates: getDefaultSellRates(submission, manYearDiscount),
          releasableSubmissionFile: submissionFile,
          releasableAttestation: attestation,
        },
      ]);
    } else {
      onSelect(selectedSubmissions.filter(({ id }) => id !== submission.id));
    }
  };

  const handleSort = sortBy => order => {
    onSort(sortBy, order);
  };

  return (
    <Section
      title={t('submitCandidatesSelectionTitle')}
      description={t('submitCandidatesSelectionDescription')}
      error={error}
    >
      <Table>
        <Table.Head className={classNames(styles.row, { [styles.proposal]: isProposal })}>
          <Table.Title className="text-center">
            {`${selectedSubmissions.length}/${newSubmissions.length}`}
          </Table.Title>
          {!isProposal && (
            <Table.Title>
              <SortPopover type={SORT_TYPE.NUMBER} onSort={handleSort(SORT_BY.SCORE)}>
                {t('score')}
              </SortPopover>
            </Table.Title>
          )}
          <Table.Title>
            <SortPopover type={SORT_TYPE.STRING} onSort={handleSort(SORT_BY.NAME)}>
              {t('name')}
            </SortPopover>
          </Table.Title>
          <Table.Title className="text-right">
            <SortPopover type={SORT_TYPE.NUMBER} onSort={handleSort(SORT_BY.RATE)}>
              {t('averagePartnerRate')}
            </SortPopover>
          </Table.Title>
          <Table.Title>{t('proposal')}</Table.Title>
          {!isProposal && <Table.Title>{t('matchReport')}</Table.Title>}
        </Table.Head>
        <Table.Body>
          {filteredSubmissions.map(submission => {
            const currentStatus = submission.getStatus();
            const disabled = currentStatus.value !== SUBMISSION_STATUS.NEW;
            const partnerRates = submission.getRates(RATES_SUPPLIER_RATE);
            const submissionFile = submission.getSubmissionFile();
            const selectedSubmissionData = selectedSubmissions.find(
              selectedSubmission => selectedSubmission.id === submission.id
            );
            const previouslySaved = getProperty(selectedSubmissionData, 'previouslySaved', false);
            const selected = !!selectedSubmissionData;

            return (
              <Table.Row
                key={submission.id}
                className={classNames(styles.row, { [styles.proposal]: isProposal })}
                disabled={disabled}
                highlighted={selected}
                onClick={() => handleSelect(submission, selected)}
              >
                <Submission
                  checked={selected}
                  disabled={disabled}
                  onCheck={() => handleSelect(submission, selected)}
                  score={submission.score}
                  name={submission.getName()}
                  company={submission.partner.name}
                  partnerType={getPartnerCategoryText(submission.partner.category)}
                  clearance={submission.getClearance().type}
                  status={currentStatus}
                  partnerRates={partnerRates}
                  position={position}
                  reportId={!isProposal ? submission.report.id : null}
                  metrics={!isProposal ? submission.getMetrics() : null}
                  previouslySaved={previouslySaved}
                  proposal={{
                    filename: submissionFile.filename,
                    boxId: submissionFile.boxId,
                  }}
                />
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      {error && (
        <div className="m-t-8">
          <Errors messages={t('candidateSubmissionNoCandidatesSelectedError')} />
        </div>
      )}
      {(hasSavedSubmissions || newSubmissions.length < submissions.length) && (
        <div className={styles.footer}>
          <div className={styles.checkbox}>
            <Checkbox
              checked={showOnlyNewSubmissions}
              smallFontSize
              onChange={() => setShowOnlyNewSubmissions(!showOnlyNewSubmissions)}
              label={`${t('candidateSubmissionDisplayOnlyNewCandidates')} (${
                newSubmissions.length
              })`}
            />
          </div>
          {hasSavedSubmissions && <PreviouslySavedLegend />}
        </div>
      )}
    </Section>
  );
};

Selection.propTypes = propTypes;
Selection.defaultProps = defaultProps;

export default Selection;
