import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// components
import Icon from 'components/atoms/Icon';
import ClickableElement from 'components/atoms/ClickableElement';
// styles
import styles from './radio.module.scss';

const propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  onCheck: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

const defaultProps = {
  description: '',
  disabled: false,
};

const Radio = ({ label, description, checked, onCheck, disabled }) => {
  const getLabel = () => {
    if (!description) {
      return label;
    }

    return (
      <div className={styles.labelWrapper}>
        {label}
        <span className={styles.description}>{`(${description})`}</span>
      </div>
    );
  };

  return (
    <ClickableElement
      onClick={() => onCheck(!checked)}
      disabled={disabled}
      className={classNames(styles.container, { [styles.disabled]: disabled })}
    >
      <div className={classNames(styles.icon, { [styles.checked]: checked })}>
        <Icon name={checked ? 'radioChecked' : 'radioUnchecked'} size={18} />
      </div>
      <div className={classNames(styles.label, { [styles.thicker]: description })}>
        {getLabel()}
      </div>
    </ClickableElement>
  );
};

Radio.propTypes = propTypes;
Radio.defaultProps = defaultProps;

export default Radio;
