/* eslint-disable import/order */
import moment from 'moment';
import getProperty from 'lodash/get';
import orderBy from 'lodash/orderBy';
// helpers
import { getPositionMainStatusText } from 'helpers/NameMapping';
import { getStatusLabel } from './label';
import { getSubmissionCurrentStatus, filterSubmissionsByStatus } from 'helpers/Submission';
import { isGrantedStatusNote } from 'helpers/Permission';
// constants
import { SUBMISSION_STATUS } from 'constants/submissionStatus';
import { SUPPLIER_ROLE, MANAGER_ROLE } from 'constants';
import {
  POSITION_STATUS,
  MAIN_RECRUITING,
  MAIN_SELECTION,
  MAIN_ONBOARDING,
  MAIN_DELIVERY,
  MAIN_CLOSED,
  MAIN_CANCELLED,
} from 'constants/positionStatus';

const findStatus = (statuses, status, last = true) => {
  return orderBy(statuses, 'start', last ? 'desc' : 'asc').find(({ name }) => name === status);
};

/**
 * Get position status
 * @param {object[]} statuses
 * @param {string} [supplierDueDate] Applicable only for supplier role
 * @param {object[]} [submissions=[]] Applicable only for supplier role
 * @returns {object}
 */
export const getCurrentStatus = (statuses, supplierDueDate = '', submissions = []) => {
  const currentStatus = statuses.find(status => !status.end) || {};

  // for PM and MM
  if (!supplierDueDate) {
    return currentStatus;
  }

  // find status for supplier
  const isAfterDueDate = moment().isAfter(moment(supplierDueDate));
  const submittedCandidates = filterSubmissionsByStatus(
    submissions,
    SUBMISSION_STATUS.SUBMITTED,
    false
  );
  const selectedCandidates = filterSubmissionsByStatus(submissions, SUBMISSION_STATUS.OFFER, false);
  const onboardingCancelledCandidates = filterSubmissionsByStatus(
    submissions,
    SUBMISSION_STATUS.ONBOARDING_CANCELLED
  );

  // No submissions
  if (isAfterDueDate && (!submissions.length || !submittedCandidates.length)) {
    return {
      ...currentStatus,
      name: POSITION_STATUS.NO_SUBMISSIONS,
      start: supplierDueDate,
    };
  }

  // No selection
  if (
    currentStatus.name !== POSITION_STATUS.PENDING_SELECTION &&
    submittedCandidates.length &&
    !selectedCandidates.length
  ) {
    const offerStatus = findStatus(statuses, POSITION_STATUS.OFFER);

    if (offerStatus) {
      return {
        ...currentStatus,
        name: POSITION_STATUS.NO_SELECTION,
        start: offerStatus.start,
      };
    }
  }

  // Onboarding cancelled
  if (
    selectedCandidates.length > 0 &&
    selectedCandidates.length === onboardingCancelledCandidates.length
  ) {
    // find date when onboarding was cancelled for last candidate
    const onboardingCancelledStatuses = orderBy(
      onboardingCancelledCandidates.map(submission =>
        getSubmissionCurrentStatus(getProperty(submission, 'statuses.items', []))
      ),
      'start',
      'desc'
    );

    return {
      ...currentStatus,
      name: POSITION_STATUS.ONBOARDING_CANCELLED,
      start: getProperty(onboardingCancelledStatuses, '0.start', ''),
    };
  }

  return currentStatus;
};

export const formatStatus = (status, role) => {
  const value = getProperty(status, 'name', '');
  const note = getProperty(status, 'note.note', '');
  const noteAuthor = getProperty(status, 'note.author.person.fullName', '');

  return {
    id: getProperty(status, 'id', ''),
    value,
    label: role ? getStatusLabel(value, role) : getPositionMainStatusText(value),
    start: getProperty(status, 'start', ''),
    end: getProperty(status, 'end', ''),
    note: !role || isGrantedStatusNote(value, role) ? note : '',
    author: !role || isGrantedStatusNote(value, role) ? noteAuthor : '',
  };
};

/**
 * @param {object[]} statuses Position statuses
 * @param {object[]} candidates Supplier candidates
 * @param {string} dueDate Supplier due date
 * @returns {boolean}
 */
const isPositionClosedForSupplier = (statuses, candidates, dueDate, isPublished) => {
  const currentStatus = getCurrentStatus(statuses);

  // current position status is closed, onboarding-cancelled, no submissions OR no selection
  const closedStatuses = [
    POSITION_STATUS.CLOSED,
    POSITION_STATUS.ONBOARDING_CANCELLED,
    POSITION_STATUS.NO_SUBMISSIONS,
    POSITION_STATUS.NO_SELECTION,
  ];
  if (closedStatuses.includes(currentStatus.name)) {
    return true;
  }

  const newOrSubmittedCandidateStatuses = [SUBMISSION_STATUS.NEW, SUBMISSION_STATUS.SUBMITTED];
  const newOrSubmittedCandidates = filterSubmissionsByStatus(
    candidates,
    newOrSubmittedCandidateStatuses
  );

  // position was once published but current status is unpublished
  const hasPublished = statuses.some(status => status.name === POSITION_STATUS.PUBLISHED);
  if (
    hasPublished &&
    currentStatus.name === POSITION_STATUS.UNPUBLISHED &&
    !newOrSubmittedCandidates.length
  ) {
    return true;
  }

  // position not published and no candidates submitted
  if (!isPublished && candidates.length === 0) {
    return true;
  }

  // no candidate submitted to position and supplier due date has passed
  const isAfterDueDate = moment().isAfter(moment(dueDate));
  if (isAfterDueDate && !candidates.length) {
    return true;
  }

  // some candidate was submitted but none was selected OR onboarding was cancelled for candidate
  const pendingOrDeliveredCandidateStatuses = [
    SUBMISSION_STATUS.SUBMITTED,
    SUBMISSION_STATUS.OFFER,
    SUBMISSION_STATUS.INTERVIEW,
    SUBMISSION_STATUS.ONBOARDING,
    SUBMISSION_STATUS.DELIVERY,
  ];
  const pendingOrDeliveredCandidates = filterSubmissionsByStatus(
    candidates,
    pendingOrDeliveredCandidateStatuses
  );

  if (currentStatus.name !== POSITION_STATUS.PUBLISHED && !pendingOrDeliveredCandidates.length) {
    return true;
  }

  return false;
};

/**
 * @param {object[]} statuses Position statuses
 * @param {object[]} candidates Supplier candidates
 * @param {string} dueDate Supplier due date
 * @returns {object}
 */
const getClosedPositionStatusForSupplier = (statuses, candidates, dueDate) => {
  const currentStatus = getCurrentStatus(statuses);
  const closedStatus = formatStatus({
    ...currentStatus,
    name: MAIN_CLOSED,
  });

  // position was once published but current status is unpublished
  const hasPublished = statuses.some(status => status.name === POSITION_STATUS.PUBLISHED);
  if (hasPublished && currentStatus.name === POSITION_STATUS.UNPUBLISHED) {
    const unpublishedStatus = findStatus(statuses, POSITION_STATUS.UNPUBLISHED);

    return {
      ...closedStatus,
      start: unpublishedStatus.start,
    };
  }

  // no candidate submitted and supplier due date has passed
  const isAfterDueDate = moment().isAfter(moment(dueDate));
  if (isAfterDueDate && !candidates.length) {
    return {
      ...closedStatus,
      start: dueDate,
    };
  }

  // no candidate submitted to client and position is not in recruiting state
  const submittedCandidates = filterSubmissionsByStatus(
    candidates,
    SUBMISSION_STATUS.SUBMITTED,
    false
  );
  if (
    currentStatus.name !== POSITION_STATUS.PUBLISHED &&
    candidates.length &&
    !submittedCandidates.length
  ) {
    const pendingSelectionStatus = findStatus(statuses, POSITION_STATUS.PENDING_SELECTION);

    if (pendingSelectionStatus) {
      return {
        ...closedStatus,
        start: pendingSelectionStatus.start,
      };
    }
  }

  // no supplier's candidate selected by client and position is no longer in selection state
  const selectedCandidates = filterSubmissionsByStatus(candidates, SUBMISSION_STATUS.OFFER, false);
  if (
    currentStatus.name !== POSITION_STATUS.PENDING_SELECTION &&
    submittedCandidates.length &&
    !selectedCandidates.length
  ) {
    const offerStatus = findStatus(statuses, POSITION_STATUS.OFFER);

    if (offerStatus) {
      return {
        ...closedStatus,
        start: offerStatus.start,
      };
    }
  }

  // some supplier's candidate was selected by client but onboarding was cancelled
  const onboardingCancelledCandidates = filterSubmissionsByStatus(
    candidates,
    SUBMISSION_STATUS.ONBOARDING_CANCELLED
  );

  if (
    selectedCandidates.length > 0 &&
    selectedCandidates.length === onboardingCancelledCandidates.length
  ) {
    // find date when onboarding was cancelled for last candidate
    const onboardingCancelledStatuses = orderBy(
      onboardingCancelledCandidates.map(submission =>
        getSubmissionCurrentStatus(getProperty(submission, 'statuses.items', []))
      ),
      'start',
      'desc'
    );

    return {
      ...closedStatus,
      start: getProperty(onboardingCancelledStatuses, '0.start', ''),
    };
  }

  return closedStatus;
};

export const getMainStatusForPM = (statuses, candidates, dueDate) => {
  const currentStatus = getCurrentStatus(statuses);
  const isBeforeDueDate = moment().isBefore(moment(dueDate));
  const submittedCandidates = filterSubmissionsByStatus(
    candidates,
    [SUBMISSION_STATUS.SUBMITTED],
    false
  );

  // RECRUITING
  // Position wasn't cancelled AND is before due date OR no candidates were submitted to position after due date
  if (
    currentStatus.name !== POSITION_STATUS.CANCELLED &&
    (isBeforeDueDate || (!isBeforeDueDate && !submittedCandidates.length))
  ) {
    // Recruiting for PM starts when position is received by MM -> find position start date from 1st status for MM
    const unpublishedStatus = findStatus(statuses, POSITION_STATUS.UNPUBLISHED);

    return formatStatus({
      ...currentStatus,
      name: MAIN_RECRUITING,
      start: unpublishedStatus ? unpublishedStatus.start : currentStatus.start,
    });
  }

  // SELECTION
  // Current date > Due date AND no candidate selected by PM OR
  // Current date > Due date AND some candidate to submitted to PM or interview
  if (
    !isBeforeDueDate &&
    [POSITION_STATUS.PENDING_SELECTION, POSITION_STATUS.NO_SELECTION].includes(currentStatus.name)
  ) {
    // Selection for PM starts when due date is reached
    const pendingSelectionStatus = findStatus(statuses, POSITION_STATUS.PENDING_SELECTION);

    return formatStatus({
      ...currentStatus,
      name: MAIN_SELECTION,
      start: pendingSelectionStatus ? pendingSelectionStatus.start : currentStatus.start,
    });
  }

  // ONBOARDING
  if (
    [
      POSITION_STATUS.OFFER,
      POSITION_STATUS.ONBOARDING,
      POSITION_STATUS.ONBOARDING_CANCELLED,
    ].includes(currentStatus.name)
  ) {
    // Onboarding for PM starts when offer was sent candidate
    const offerStatus = findStatus(statuses, POSITION_STATUS.OFFER);

    return formatStatus({
      ...currentStatus,
      name: MAIN_ONBOARDING,
      start: offerStatus ? offerStatus.start : currentStatus.start,
    });
  }

  // DELIVERY
  // Current date >= candidate start date on position
  if ([POSITION_STATUS.DELIVERY, POSITION_STATUS.OFFBOARDING].includes(currentStatus.name)) {
    // Delivery for PM starts when candidate starts on position
    const deliveryStatus = findStatus(statuses, POSITION_STATUS.DELIVERY);

    return formatStatus({
      ...currentStatus,
      name: MAIN_DELIVERY,
      start: deliveryStatus ? deliveryStatus.start : currentStatus.start,
    });
  }

  // CLOSED
  // Current date >= position end date
  if (currentStatus.name === POSITION_STATUS.CLOSED) {
    return formatStatus({
      ...currentStatus,
      name: MAIN_CLOSED,
    });
  }

  // CANCELLED
  // Position is cancelled by PM
  if (currentStatus.name === POSITION_STATUS.CANCELLED) {
    return formatStatus({
      ...currentStatus,
      name: MAIN_CANCELLED,
    });
  }

  return {};
};

const getMainStatusForMM = (statuses, candidates, dueDate) => {
  const currentStatus = getCurrentStatus(statuses);
  const isBeforeDueDate = moment().isBefore(moment(dueDate));

  // RECRUITING
  // Position created by PM AND Current date < Due date OR
  if ([POSITION_STATUS.UNPUBLISHED, POSITION_STATUS.PUBLISHED].includes(currentStatus.name)) {
    // Recruiting for MM starts when position is received by MM -> find position start date from 1st status for MM
    const unpublishedStatus = findStatus(statuses, POSITION_STATUS.UNPUBLISHED);

    return formatStatus({
      ...currentStatus,
      name: MAIN_RECRUITING,
      start: unpublishedStatus ? unpublishedStatus.start : currentStatus.start,
    });
  }

  // SELECTION
  // Current date > Due date AND some candidates were submitted by MM OR some candidate has already an interview
  if (!isBeforeDueDate && currentStatus.name === POSITION_STATUS.PENDING_SELECTION) {
    // Selection for PM starts when due date is reached
    const pendingSelectionStatus = findStatus(statuses, POSITION_STATUS.PENDING_SELECTION);

    return formatStatus({
      ...currentStatus,
      name: MAIN_SELECTION,
      start: pendingSelectionStatus ? pendingSelectionStatus.start : currentStatus.start,
    });
  }

  // ONBOARDING
  // Some candidates with status Onboarding or Offer or Onboarding Cancelled
  if (
    filterSubmissionsByStatus(candidates, [SUBMISSION_STATUS.OFFER, SUBMISSION_STATUS.ONBOARDING])
      .length > 0
  ) {
    // Onboarding for PM starts when offer was sent candidate
    const offerStatus = findStatus(statuses, POSITION_STATUS.OFFER);

    return formatStatus({
      ...currentStatus,
      name: MAIN_ONBOARDING,
      start: offerStatus ? offerStatus.start : currentStatus.start,
    });
  }

  // DELIVERY
  // Current date >= candidate start date on position
  if ([POSITION_STATUS.DELIVERY, POSITION_STATUS.OFFBOARDING].includes(currentStatus.name)) {
    // Delivery for PM starts when candidate starts on position
    const deliveryStatus = findStatus(statuses, POSITION_STATUS.DELIVERY);

    return formatStatus({
      ...currentStatus,
      name: MAIN_DELIVERY,
      start: deliveryStatus ? deliveryStatus.start : currentStatus.start,
    });
  }

  // CANCELLED
  // Position is cancelled by PM
  if (currentStatus.name === POSITION_STATUS.CANCELLED) {
    return formatStatus({
      ...currentStatus,
      name: MAIN_CANCELLED,
    });
  }

  // CLOSED
  // Current position status is closed, onboarding-cancelled, no submissions, no selection
  const closedStatuses = [
    POSITION_STATUS.CLOSED,
    POSITION_STATUS.ONBOARDING_CANCELLED,
    POSITION_STATUS.NO_SUBMISSIONS,
    POSITION_STATUS.NO_SELECTION,
  ];

  if (closedStatuses.includes(currentStatus.name)) {
    return formatStatus({
      ...currentStatus,
      name: MAIN_CLOSED,
    });
  }

  return {};
};

const getMainStatusForSupplier = ({ statuses, candidates, dueDate, isPublishedToSupplier }) => {
  const currentStatus = getCurrentStatus(statuses);
  const isBeforeDueDate = moment().isBefore(moment(dueDate));
  const newCandidates = filterSubmissionsByStatus(candidates, SUBMISSION_STATUS.NEW);
  const submittedCandidates = filterSubmissionsByStatus(candidates, [SUBMISSION_STATUS.SUBMITTED]);
  const interviewedCandidates = filterSubmissionsByStatus(candidates, SUBMISSION_STATUS.INTERVIEW);
  const onboardingCandidates = filterSubmissionsByStatus(candidates, [
    SUBMISSION_STATUS.OFFER,
    SUBMISSION_STATUS.ONBOARDING,
  ]);
  const deliveredCandidates = filterSubmissionsByStatus(candidates, [
    SUBMISSION_STATUS.DELIVERY,
    SUBMISSION_STATUS.OFF_BOARDING,
  ]);

  // RECRUITING
  // Position is published by MM to supplier AND Current date < Supplier due date
  if (
    // Position is Published
    (isBeforeDueDate && isPublishedToSupplier) ||
    // Position unpublished but some candidates were submitted
    ((currentStatus.name === POSITION_STATUS.UNPUBLISHED || !isPublishedToSupplier) &&
      (newCandidates.length || submittedCandidates.length) &&
      isBeforeDueDate)
  ) {
    // Recruiting for PM starts when position is received by MM -> find position start date from 1st status for MM
    const publishedStatus = findStatus(statuses, POSITION_STATUS.PUBLISHED);

    return formatStatus({
      ...currentStatus,
      name: MAIN_RECRUITING,
      start: publishedStatus ? publishedStatus.start : currentStatus.start,
    });
  }

  // SELECTION
  // Current date > Due date AND supplier is not part of audience anymore but submitted candidates for position
  if (
    (!isBeforeDueDate || currentStatus.name === POSITION_STATUS.UNPUBLISHED) &&
    (submittedCandidates.length > 0 || interviewedCandidates.length > 0)
  ) {
    // Selection for PM starts when due date is reached
    const pendingSelectionStatus = findStatus(statuses, POSITION_STATUS.PENDING_SELECTION);

    return formatStatus({
      ...currentStatus,
      name: MAIN_SELECTION,
      start: pendingSelectionStatus ? pendingSelectionStatus.start : currentStatus.start,
    });
  }

  // ONBOARDING
  // Some supplier's candidates with status Onboarding or Offer or Onboarding Cancelled
  if (onboardingCandidates.length > 0) {
    // Onboarding for PM starts when offer was sent candidate
    const offerStatus = findStatus(statuses, POSITION_STATUS.ONBOARDING);

    return formatStatus({
      ...currentStatus,
      name: MAIN_ONBOARDING,
      start: offerStatus ? offerStatus.start : currentStatus.start,
    });
  }

  // DELIVERY
  // Current date >= candidate start date on position and some supplier candidate with status offer or onboarding
  if (
    [POSITION_STATUS.DELIVERY, POSITION_STATUS.OFFBOARDING].includes(currentStatus.name) &&
    deliveredCandidates.length > 0
  ) {
    // Delivery for supplier starts when candidate starts on position
    const deliveryStatus = findStatus(statuses, POSITION_STATUS.DELIVERY);

    return formatStatus({
      ...currentStatus,
      name: MAIN_DELIVERY,
      start: deliveryStatus ? deliveryStatus.start : currentStatus.start,
    });
  }

  // CANCELLED
  // Position is cancelled by PM
  if (currentStatus.name === POSITION_STATUS.CANCELLED) {
    return formatStatus({
      ...currentStatus,
      name: MAIN_CANCELLED,
    });
  }

  // CLOSED
  if (isPositionClosedForSupplier(statuses, candidates, dueDate, isPublishedToSupplier)) {
    return getClosedPositionStatusForSupplier(statuses, candidates, dueDate);
  }

  return {};
};

/**
 * https://ibm.ent.box.com/file/727326644508
 * https://ibm.ent.box.com/file/679546492394
 * @param {object} positionData
 * @param {object[]} positionData.statuses
 * @param {object[]} positionData.candidates
 * @param {string} positionData.dueDate
 * @param {string} positionData.supplierDueDate
 * @param {string} role
 * @param {string} supplierId
 * @returns {?Status}
 */
export const getMainStatusByRole = (
  { statuses, candidates, dueDate, supplierDueDate, isPublishedToSupplier },
  role
) => {
  if (role === SUPPLIER_ROLE) {
    return getMainStatusForSupplier({
      statuses,
      candidates,
      dueDate: supplierDueDate,
      isPublishedToSupplier,
    });
  }

  if (role === MANAGER_ROLE) {
    return getMainStatusForPM(statuses, candidates, dueDate);
  }

  return getMainStatusForMM(statuses, candidates, dueDate);
};

const getMainStatusHistoryForPM = statuses => {
  const history = [];
  const currentStatus = getCurrentStatus(statuses);

  // Recruiting
  const firstUnpublishStatus = findStatus(statuses, POSITION_STATUS.UNPUBLISHED, false);
  const lastUnpublishStatus = findStatus(statuses, POSITION_STATUS.UNPUBLISHED);
  const publishStatus = findStatus(statuses, POSITION_STATUS.PUBLISHED);
  const recruitingEndDate = (() => {
    switch (currentStatus.name) {
      case POSITION_STATUS.NO_SUBMISSIONS:
        return null;
      case POSITION_STATUS.UNPUBLISHED:
        return lastUnpublishStatus.end;
      case POSITION_STATUS.PUBLISHED:
        return publishStatus.end;
      default:
        return getProperty(publishStatus, 'end', lastUnpublishStatus.end);
    }
  })();

  if (firstUnpublishStatus) {
    history.push(
      formatStatus({
        ...firstUnpublishStatus,
        name: MAIN_RECRUITING,
        end: recruitingEndDate,
      })
    );
  }

  // Selection
  const pendingSelectionStatus = findStatus(statuses, POSITION_STATUS.PENDING_SELECTION);
  const noSelectionStatus = findStatus(statuses, POSITION_STATUS.NO_SELECTION);
  if (pendingSelectionStatus || noSelectionStatus) {
    history.push(
      formatStatus({
        ...pendingSelectionStatus,
        end: noSelectionStatus ? null : pendingSelectionStatus.end,
        name: MAIN_SELECTION,
      })
    );
  }

  // Onboarding
  const offerStatus = findStatus(statuses, POSITION_STATUS.OFFER);
  if (offerStatus) {
    const onboardingStatus = findStatus(statuses, POSITION_STATUS.ONBOARDING);
    const onboardingEndDate = (() => {
      switch (currentStatus.name) {
        case POSITION_STATUS.ONBOARDING_CANCELLED:
          return null;
        case POSITION_STATUS.CANCELLED:
          return onboardingStatus ? onboardingStatus.end : offerStatus.end;
        default:
          return onboardingStatus ? onboardingStatus.end : null;
      }
    })();

    history.push(
      formatStatus({
        ...offerStatus,
        end: onboardingEndDate,
        name: MAIN_ONBOARDING,
      })
    );
  }

  // Delivery
  const deliveryStatus = findStatus(statuses, POSITION_STATUS.DELIVERY);
  if (deliveryStatus) {
    history.push(formatStatus({ ...deliveryStatus, name: MAIN_DELIVERY }));
  }

  // Cancelled
  if (currentStatus.name === POSITION_STATUS.CANCELLED) {
    history.push(formatStatus({ ...currentStatus, name: MAIN_CANCELLED }));

    return history;
  }

  // Closed
  if (currentStatus.name === POSITION_STATUS.CLOSED) {
    history.push(formatStatus({ ...currentStatus, name: MAIN_CLOSED }));
  }

  return history;
};

const getMainStatusHistoryForMM = statuses => {
  const history = [];
  const currentStatus = getCurrentStatus(statuses);

  // Recruiting
  const firstUnpublishStatus = findStatus(statuses, POSITION_STATUS.UNPUBLISHED, false);
  const lastUnpublishStatus = findStatus(statuses, POSITION_STATUS.UNPUBLISHED);
  const publishStatus = findStatus(statuses, POSITION_STATUS.PUBLISHED);
  if (firstUnpublishStatus) {
    history.push(
      formatStatus({
        ...firstUnpublishStatus,
        end:
          publishStatus && currentStatus.name !== POSITION_STATUS.UNPUBLISHED
            ? publishStatus.end
            : lastUnpublishStatus.end,
        name: MAIN_RECRUITING,
      })
    );
  }

  // Selection
  const pendingSelectionStatus = findStatus(statuses, POSITION_STATUS.PENDING_SELECTION);
  if (pendingSelectionStatus) {
    history.push(formatStatus({ ...pendingSelectionStatus, name: MAIN_SELECTION }));
  }

  // Onboarding
  const offerStatus = findStatus(statuses, POSITION_STATUS.OFFER);
  const onboardingStatus = findStatus(statuses, POSITION_STATUS.ONBOARDING);
  if (offerStatus) {
    history.push(
      formatStatus({
        ...offerStatus,
        end: onboardingStatus ? onboardingStatus.end : offerStatus.end,
        name: MAIN_ONBOARDING,
      })
    );
  }

  // Delivery
  const deliveryStatus = findStatus(statuses, POSITION_STATUS.DELIVERY);
  if (deliveryStatus) {
    history.push(formatStatus({ ...deliveryStatus, name: MAIN_DELIVERY }));
  }

  // Cancelled
  if (currentStatus.name === POSITION_STATUS.CANCELLED) {
    history.push(formatStatus({ ...currentStatus, name: MAIN_CANCELLED }));

    return history;
  }

  // Closed
  if (
    [
      POSITION_STATUS.CLOSED,
      POSITION_STATUS.NO_SUBMISSIONS,
      POSITION_STATUS.NO_SELECTION,
      POSITION_STATUS.ONBOARDING_CANCELLED,
    ].includes(currentStatus.name)
  ) {
    history.push(formatStatus({ ...currentStatus, name: MAIN_CLOSED }));
  }

  return history;
};

const getMainStatusHistoryForSupplier = (statuses, candidates, dueDate, isPublishedToSupplier) => {
  const history = [];
  const currentStatus = getCurrentStatus(statuses);
  const isAfterDueDate = moment().isAfter(moment(dueDate));

  // Recruiting
  const publishStatus = findStatus(statuses, POSITION_STATUS.PUBLISHED);
  if (publishStatus) {
    history.push(
      formatStatus({
        ...publishStatus,
        end: isAfterDueDate && !candidates.length ? dueDate : publishStatus.end,
        name: MAIN_RECRUITING,
      })
    );
  }

  // Selection
  const pendingSelectionStatus = findStatus(statuses, POSITION_STATUS.PENDING_SELECTION);
  const submittedCandidates = filterSubmissionsByStatus(candidates, [
    SUBMISSION_STATUS.SUBMITTED,
    SUBMISSION_STATUS.NOT_SELECTED,
    SUBMISSION_STATUS.OFFER,
    SUBMISSION_STATUS.INTERVIEW,
    SUBMISSION_STATUS.ONBOARDING,
    SUBMISSION_STATUS.ONBOARDING_CANCELLED,
    SUBMISSION_STATUS.DELIVERY,
  ]);
  if (
    (isAfterDueDate || currentStatus.name === POSITION_STATUS.UNPUBLISHED) &&
    submittedCandidates.length > 0
  ) {
    history.push(formatStatus({ ...pendingSelectionStatus, name: MAIN_SELECTION }));
  }

  // Onboarding
  const offerStatus = findStatus(statuses, POSITION_STATUS.OFFER);
  const onboardingStatus = findStatus(statuses, POSITION_STATUS.ONBOARDING);
  const onboardingCandidates = filterSubmissionsByStatus(candidates, [
    SUBMISSION_STATUS.OFFER,
    SUBMISSION_STATUS.INTERVIEW,
    SUBMISSION_STATUS.ONBOARDING,
    SUBMISSION_STATUS.ONBOARDING_CANCELLED,
    SUBMISSION_STATUS.DELIVERY,
  ]);
  if (onboardingCandidates.length > 0) {
    history.push(
      formatStatus({
        ...offerStatus,
        end: onboardingStatus ? onboardingStatus.end : offerStatus.end,
        name: MAIN_ONBOARDING,
      })
    );
  }

  // Delivery
  const deliveryStatus = findStatus(statuses, POSITION_STATUS.DELIVERY);
  const deliveredCandidates = filterSubmissionsByStatus(candidates, SUBMISSION_STATUS.DELIVERY);
  if (deliveredCandidates.length > 0) {
    history.push(formatStatus({ ...deliveryStatus, name: MAIN_DELIVERY }));
  }

  // Cancelled
  if (currentStatus.name === POSITION_STATUS.CANCELLED) {
    history.push(formatStatus({ ...currentStatus, name: MAIN_CANCELLED }));

    return history;
  }

  // Closed
  if (isPositionClosedForSupplier(statuses, candidates, dueDate, isPublishedToSupplier)) {
    history.push(getClosedPositionStatusForSupplier(statuses, candidates, dueDate));
  }

  return history;
};

/**
 * @param {object[]} statuses
 * @param {object[]} candidates
 * @param {string} role
 * @param {string} supplierDueDate
 * @param {string} supplierId
 */
export const getMainStatusHistoryByRole = (
  statuses,
  candidates,
  role,
  supplierDueDate,
  isPublishedToSupplier
) => {
  if (role === SUPPLIER_ROLE) {
    return getMainStatusHistoryForSupplier(
      statuses,
      candidates,
      supplierDueDate,
      isPublishedToSupplier
    );
  }

  if (role === MANAGER_ROLE) {
    return getMainStatusHistoryForPM(statuses);
  }

  return getMainStatusHistoryForMM(statuses);
};
