import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// components
import Documents from '../Documents';
import Templates from './Templates';

const propTypes = {
  files: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool.isRequired,
  templates: PropTypes.arrayOf(
    PropTypes.shape({
      boxId: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ),
  note: PropTypes.object,
  collapsedTemplates: PropTypes.bool,
};

const defaultProps = {
  files: [],
  templates: [],
  note: null,
  collapsedTemplates: false,
};

const Forms = ({ loading, templates, files, note, collapsedTemplates }) => {
  const { t } = useTranslation();
  const notes = [{ ...note, label: t('notes') }];

  return (
    <div className="m-t-20 w100">
      <Templates loading={loading} templates={templates} collapsed={collapsedTemplates} />
      <div className="m-t-20">
        <Documents loading={loading} title={t('onboardingForms')} files={files} notes={notes} />
      </div>
    </div>
  );
};

Forms.propTypes = propTypes;
Forms.defaultProps = defaultProps;

export default Forms;
