/* eslint-disable import/prefer-default-export */
import { basicRoles } from 'dependencies/staticData/Roles';
import { getRoleText } from 'helpers/NameMapping';

export const getRoleList = filteredList => {
  return basicRoles
    .filter(role => !filteredList.includes(role))
    .map(filteredRole => {
      return {
        label: getRoleText(filteredRole),
        value: filteredRole,
      };
    });
};
