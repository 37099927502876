import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import getProperty from 'lodash/get';
// hooks
import useAuth from 'hooks/useAuth';
// components
import ClickableElement from 'components/atoms/ClickableElement';
import Icon from 'components/atoms/Icon';
import TruncatedText from 'components/atoms/TruncatedText';
import Tooltip from 'components/atoms/Tooltip';
import Chip, { COLOR } from 'components/atoms/NewChip';
import { ProposalSettings } from 'components/organisms/SubmissionSettings';
import SubmissionStatusFlow from 'components/molecules/SubmissionStatusFlow';
// helpers
import { prettyNumber } from 'helpers/Number';
import { getCurrencyText } from 'helpers/NameMapping';
import { preventDefault } from 'helpers/HtmlDOM';
import { getRelativeTime, getTimeYear } from 'helpers/RelativeTimeFormatter';
// constants
import { POSITION_TAB } from 'constants/pageTabs';
import {
  STAFF_ROLE,
  SUPPLIER_ROLE,
  MANAGER_ROLE,
  RATES_NTE_RATE,
  RATES_SUPPLIER_RATE,
  RATES_SELL_PRICE,
} from 'constants';
// styles
import colors from 'dependencies/materialStyles/Colors';
import styles from './proposalTable.module.scss';

const propTypes = {
  proposal: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const ProposalRow = ({ className, proposal, position }) => {
  const { t } = useTranslation();
  const { role } = useAuth();
  const history = useHistory();

  const { project, client } = position;
  const currency = getCurrencyText(project.currency);
  const nteRate = getProperty(position.getRate(RATES_NTE_RATE), 'value');
  const partnerRate = getProperty(proposal.getRate(RATES_SUPPLIER_RATE), 'value');
  const sellRate = getProperty(proposal.getRate(RATES_SELL_PRICE), 'value');
  const startDate = getProperty(proposal, 'hiringProcess.purchaseOrder.start');
  const endDate = getProperty(proposal, 'hiringProcess.purchaseOrder.end');

  const handlePositionClick = () => {
    const tab = role === STAFF_ROLE ? POSITION_TAB.RATES : POSITION_TAB.DETAIL;

    history.push(`/position/${position.id}?tab=${tab}`);
  };

  return (
    <Link to={`/proposal/${proposal.id}`}>
      <div
        className={classNames(styles.grid, styles.row, className, {
          [styles.partner]: role === SUPPLIER_ROLE,
          [styles.matchManager]: role === STAFF_ROLE,
          [styles.projectManager]: role === MANAGER_ROLE,
        })}
      >
        {/* Name */}
        <div className={styles.columnCell}>
          <div className="bolder">
            <TruncatedText>{proposal.name}</TruncatedText>
          </div>
          <div className={styles.status}>
            <SubmissionStatusFlow submission={proposal} />
          </div>
        </div>

        {/* MM role - Partner */}
        {role === STAFF_ROLE && (
          <div className={styles.cell}>
            <TruncatedText>{proposal.partner.name}</TruncatedText>
          </div>
        )}
        {/* PM role - MM who submitted the proposal */}
        {role === MANAGER_ROLE && (
          <div className={styles.cell}>
            <Tooltip title={proposal.reviewedBy.name} className="overflowHidden">
              <div>
                <TruncatedText disableTooltip>{proposal.reviewedBy.company}</TruncatedText>
              </div>
            </Tooltip>
          </div>
        )}

        {/* Position + RFQ + project + client */}
        <div className={styles.columnCell}>
          <ClickableElement onClick={preventDefault(handlePositionClick)}>
            <div className={styles.cell}>
              <div className={styles.rfq}>{position.rfqLabel}</div>
              <Tooltip title={`${project.name} - ${client.name}`}>
                <div className="overflowHidden">
                  <Chip size="sm" color={COLOR.GRAY} className={styles.projectChip}>
                    <TruncatedText>{project.shortName}</TruncatedText>
                  </Chip>
                </div>
              </Tooltip>
            </div>
            <div>
              <TruncatedText>{position.title}</TruncatedText>
            </div>
          </ClickableElement>
        </div>

        {/* Location */}
        <div className={styles.cell}>
          <div className={styles.locationIcon}>
            <Icon size={15} name="location" color={COLOR.GRAY} />
          </div>
          <TruncatedText>
            {position.isOffsite() ? t('offsite') : position.location.city}
          </TruncatedText>
        </div>

        {/* Submission Rate */}
        <div className={classNames(styles.cell, styles.right)}>
          {role === MANAGER_ROLE && sellRate && (
            <React.Fragment>
              {prettyNumber(sellRate)}
              <span className={styles.currency}>{currency}</span>
            </React.Fragment>
          )}
          {role !== MANAGER_ROLE && partnerRate && (
            <React.Fragment>
              {prettyNumber(partnerRate)}
              <span className={styles.currency}>{currency}</span>
            </React.Fragment>
          )}
        </div>

        {/* MM role - NTE rate */}
        {role === STAFF_ROLE && (
          <div className={classNames(styles.cell, styles.right)}>
            {nteRate && (
              <React.Fragment>
                {prettyNumber(nteRate)}
                <span className={styles.currency}>{currency}</span>
              </React.Fragment>
            )}
          </div>
        )}

        {/* Submission date (CV submission) */}
        <div className={classNames(styles.columnCell, styles.right)}>
          <div className="bolder">{getTimeYear(proposal.submissionDate)}</div>
          <div className="m-t-2">{getRelativeTime(proposal.submissionDate)}</div>
        </div>

        {/* Start & end date */}
        {startDate && endDate ? (
          <div className={classNames(styles.columnCell, styles.right)}>
            <div className="bolder">{getTimeYear(startDate)}</div>
            <div className="bolder m-t-2">{getTimeYear(endDate)}</div>
          </div>
        ) : (
          <div className={classNames(styles.cell, styles.right, 'f-s-14')}>
            {t('notApplicable')}
          </div>
        )}

        <div role="presentation" className={styles.settings} onClick={preventDefault}>
          <ProposalSettings proposal={proposal} position={position} color={colors.gray} />
        </div>
      </div>
    </Link>
  );
};

ProposalRow.propTypes = propTypes;
ProposalRow.defaultProps = defaultProps;

export default ProposalRow;
