import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// components
import { SUPPORTED_COLORS, COLOR_WHITE } from 'constants/colors';
import Popover from 'components/molecules/Popover';
import ChipButton from './ChipButton';

const propTypes = {
  note: PropTypes.any.isRequired,
  title: PropTypes.string,
  label: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  noteContentClassName: PropTypes.string,
  color: PropTypes.oneOf(SUPPORTED_COLORS),
  iconSize: PropTypes.number,
  iconColor: PropTypes.string,
};

const defaultProps = {
  title: '',
  noteContentClassName: '',
  color: COLOR_WHITE,
  iconSize: 18,
  iconColor: '',
};

const ChipNote = ({
  color,
  note,
  title,
  label,
  className,
  noteContentClassName,
  iconSize,
  iconColor,
}) => {
  const { t } = useTranslation();

  return (
    <Popover
      placement="bottom-start"
      button={onClick => (
        <ChipButton
          label={label}
          color={color}
          className={className}
          onClick={onClick}
          iconSize={iconSize}
          iconColor={iconColor}
        />
      )}
    >
      <div className={`${noteContentClassName} chip_noteContent`}>
        <div className="f-s-15 m-b-5">
          <b>{title || t('statusNote')}</b>
        </div>
        <div>{note}</div>
      </div>
    </Popover>
  );
};

ChipNote.propTypes = propTypes;
ChipNote.defaultProps = defaultProps;

export default ChipNote;
