import cloneDeep from 'lodash/cloneDeep';
import setProperty from 'lodash/set';
import getProperty from 'lodash/get';
import hasProperty from 'lodash/has';
import mapObject from 'lodash/map';
// constants
import { REVISION_TYPES } from 'constants';
import { ACTIONS } from '../constants';
// helpers
import { getProposedRates } from './common';

const validateCVData = typeData => {
  if (typeData.action === ACTIONS.ACCEPT) {
    const formComplete =
      hasProperty(typeData, 'revisedFile.value.file.0') &&
      hasProperty(typeData, 'revisedFile.value.reportId') &&
      hasProperty(typeData, 'revisedFile.value.score');
    return !formComplete;
  }
  return !getProperty(typeData, 'rejectionNote.value');
};

const validateRates = (typeData, revision) => {
  if (typeData.action === ACTIONS.ACCEPT) {
    const rates = getProperty(typeData, 'value', []);
    const proposedRates = getProposedRates(revision);
    const requiredLength = proposedRates.length;

    return Object.values(rates).length !== requiredLength;
  }

  return !getProperty(typeData, 'rejectionNote.value');
};

const validateAttestation = typeData => {
  if (typeData.action === ACTIONS.ACCEPT) {
    return !hasProperty(typeData, 'revisedFile.value.file.0');
  }

  return !getProperty(typeData, 'rejectionNote.value');
};

const validate = (formData, tabs, currentTab, revision) => {
  const newFormData = cloneDeep(formData);
  const types = mapObject(formData, (value, key) => {
    return { ...value, type: key };
  });

  if (currentTab) {
    const selectActionError = getProperty(formData, [currentTab, 'action']);
    const revisionType = getProperty(formData, currentTab, {});
    if (selectActionError) {
      switch (currentTab) {
        case REVISION_TYPES.CV:
          setProperty(newFormData, [currentTab, 'error'], validateCVData(revisionType));
          break;
        case REVISION_TYPES.ATTESTATION:
          setProperty(newFormData, [currentTab, 'error'], validateAttestation(revisionType));
          break;
        case REVISION_TYPES.RATES:
          setProperty(newFormData, [currentTab, 'error'], validateRates(revisionType, revision));
          break;

        default:
      }
    }
    setProperty(newFormData, [currentTab, 'requiredSelection'], !selectActionError);
  } else {
    tabs.forEach(({ id }) => {
      if (!hasProperty(formData, [id, 'action'])) {
        setProperty(newFormData, [id, 'requiredSelection'], true);
      } else {
        setProperty(newFormData, [id, 'requiredSelection'], false);
      }
    });
    types.forEach(type => {
      const revisionType = type.type;

      switch (revisionType) {
        case REVISION_TYPES.CV:
          setProperty(newFormData, [revisionType, 'error'], validateCVData(type));
          break;
        case REVISION_TYPES.ATTESTATION:
          setProperty(newFormData, [revisionType, 'error'], validateAttestation(type));
          break;
        case REVISION_TYPES.RATES:
          setProperty(newFormData, [revisionType, 'error'], validateRates(type, revision));
          break;

        default:
      }
    });
  }

  return newFormData;
};

export default validate;
