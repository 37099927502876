import React, { useState, useContext } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// hooks
import useAuth from 'hooks/useAuth';
// context
import ToastContext from 'context/ToastContext';
// services
import logger from 'services/Client';
// components
import Modal from 'components/molecules/Modal';
import Loader from 'components/atoms/Loader';
import Button from 'components/atoms/Button';
import DropdownCheckbox from 'components/molecules/DropdownCheckbox';
// helpers
import { parseData, handleSelectedRole, formatRole } from './Helpers';

const propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
};

const defaultProps = {
  isOpen: false,
  onClose: () => {},
  onSuccess: () => {},
};

const getInitialRole = (projectRoles, role) => {
  const currentPR = projectRoles.find(pr => pr.role === role);

  return currentPR ? formatRole(currentPR.role, currentPR.id) : null;
};

const RoleChanger = ({ isOpen, onClose, onSuccess }) => {
  const { t } = useTranslation();
  const { id, role, projectRoles } = useAuth();
  const { addToast } = useContext(ToastContext);
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState(getInitialRole(projectRoles, role));

  const roles = parseData(projectRoles);

  const handleSubmit = async () => {
    try {
      if (selectedValue.value !== role) {
        setLoading(true);
        await handleSelectedRole(id, selectedValue);
        onSuccess();
      }
    } catch (error) {
      addToast.error(t('errorPlaceholderText'));
      logger.exception(error);
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="sm">
      <Modal.Header>{t('switchRole')}</Modal.Header>
      <Modal.Body>
        {loading ? (
          <div className="space-30">
            <Loader />
          </div>
        ) : (
          <div className="space-10">
            <div className="p-b-10">{t('selectRole')}</div>
            <DropdownCheckbox
              checkedLabel={selectedValue ? selectedValue.label : null}
              selected={selectedValue}
              label={t('role')}
              onChange={val => setSelectedValue(val)}
              list={roles}
              name="roleSelection"
            />
          </div>
        )}
      </Modal.Body>
      {!loading && (
        <Modal.Footer>
          <Button label={t('cancel')} secondary onClick={onClose} />
          <span className="p-l-12">
            <Button label={t('switch')} disabled={selectedValue === null} onClick={handleSubmit} />
          </span>
        </Modal.Footer>
      )}
    </Modal>
  );
};

RoleChanger.propTypes = propTypes;
RoleChanger.defaultProps = defaultProps;

export default RoleChanger;
