// libraries
import i18n from 'i18next';
// constants
import { COLOR_RED, COLOR_YELLOW, COLOR_BLUE, COLOR_GREEN } from 'constants/colors';

export const MATCH_TYPE = {
  WEAK: 'WEAK',
  MODERATE: 'MODERATE',
  GOOD: 'GOOD',
  EXCELLENT: 'EXCELLENT',
  NONE: 'NONE',
};

export const MATCH_TYPE_LIST = [
  MATCH_TYPE.WEAK,
  MATCH_TYPE.MODERATE,
  MATCH_TYPE.GOOD,
  MATCH_TYPE.EXCELLENT,
];

export const TYPES = [
  {
    value: MATCH_TYPE.WEAK,
    label: i18n.t('weak'),
    color: COLOR_RED,
  },
  {
    value: MATCH_TYPE.MODERATE,
    label: i18n.t('moderate'),
    color: COLOR_YELLOW,
  },
  {
    value: MATCH_TYPE.GOOD,
    label: i18n.t('good'),
    color: COLOR_BLUE,
  },
  {
    value: MATCH_TYPE.EXCELLENT,
    label: i18n.t('excellent'),
    color: COLOR_GREEN,
  },
];
