import React, { useState } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// components
import Icon from 'components/atoms/Icon';
import Popper from 'components/atoms/Popper';
import ClickableElement from 'components/atoms/ClickableElement';
// constants
import { ORDER } from '../constants';
import { SORT_TYPE } from './constants';
// styles
import styles from './sortPopover.module.scss';

const propTypes = {
  onSort: PropTypes.func.isRequired,
  type: PropTypes.oneOf(Object.values(SORT_TYPE)).isRequired,
  children: PropTypes.any.isRequired,
};

const SortPopover = ({ onSort, type, children }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const getAscLabel = () => {
    if (type === SORT_TYPE.STRING) {
      return <React.Fragment>A &rarr; Z</React.Fragment>;
    }

    return (
      <React.Fragment>
        {t('lowest')} &rarr; {t('highest')}
      </React.Fragment>
    );
  };

  const getDescLabel = () => {
    if (type === SORT_TYPE.STRING) {
      return <React.Fragment>Z &rarr; A</React.Fragment>;
    }

    return (
      <React.Fragment>
        {t('highest')} &rarr; {t('lowest')}
      </React.Fragment>
    );
  };

  const handleSort = order => event => {
    event.stopPropagation();
    setAnchorEl(null);
    onSort(order);
  };

  return (
    <ClickableElement onClick={e => setAnchorEl(e.currentTarget)} className={styles.container}>
      <span className="m-r-2">{children}</span>
      <Icon name="expandMore" size={18} />
      <Popper
        isOpen={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        className={styles.menu}
      >
        <ClickableElement onClick={handleSort(ORDER.ASC)} className={styles.item}>
          <div className={styles.icon}>
            <Icon name="sort" size={20} />
          </div>
          {t('sort')}: {getAscLabel()}
        </ClickableElement>
        <ClickableElement onClick={handleSort(ORDER.DESC)} className={styles.item}>
          <div className={styles.icon}>
            <Icon name="sort" size={20} />
          </div>
          {t('sort')}: {getDescLabel()}
        </ClickableElement>
      </Popper>
    </ClickableElement>
  );
};

SortPopover.propTypes = propTypes;

export default SortPopover;
