import React, { useState } from 'react';
import PropTypes from 'prop-types';
// Libraries
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
// Components
import Button from 'components/atoms/Button';
import LinkButton from 'components/atoms/LinkButton';
import { PublishPosition } from 'components/organisms/PositionSettings';
// Style
import styles from '../publishBanner.module.scss';

const propTypes = {
  position: PropTypes.object.isRequired,
  targetRates: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      year: PropTypes.number.isRequired,
    })
  ),
};

const defaultProps = {
  targetRates: [],
};

const Actions = ({ position, targetRates }) => {
  const { t } = useTranslation();

  const [isPublishOpen, setIsPublishOpen] = useState(false);
  const [isViewPublishOpen, setViewPublishOpen] = useState(false);

  const closePublishModal = () => {
    setIsPublishOpen(false);
    setViewPublishOpen(false);
  };

  return (
    <div className={classNames(styles.actionButtons, 'fs-unmask')}>
      <LinkButton onClick={() => setViewPublishOpen(true)} className={styles.linkButton}>
        {t('viewPublishDetails')}
      </LinkButton>
      <Button label={t('editPublishInfo')} secondary onClick={() => setIsPublishOpen(true)} />
      <PublishPosition
        isOpen={isPublishOpen || isViewPublishOpen}
        viewSummary={isViewPublishOpen}
        isEdit
        targetRates={targetRates}
        position={position}
        onClose={closePublishModal}
      />
    </div>
  );
};

Actions.propTypes = propTypes;
Actions.defaultProps = defaultProps;

export default Actions;
