/* eslint-disable import/order */
import React from 'react';
// libraries
import { useQuery } from '@apollo/client';
import getProperty from 'lodash/get';
import { useHistory, useLocation, useParams } from 'react-router-dom';
// hooks
import useAuth from 'hooks/useAuth';
// query
import { GET_POSITION_BY_ID } from 'queries/PositionQueries';
// components
import ContentLoader from 'components/atoms/ContentLoader';
import PositionMainInfo from 'components/organisms/PositionMainInfo';
import PositionSettings from 'components/organisms/PositionSettings';
import ErrorPage from 'components/pages/ErrorPage';
import DetailPage from 'components/templates/DetailPage';
import PositionContent from './PositionContent';
// constants
import { POSITION_TAB } from 'constants/pageTabs';
import { SUBMISSION_TYPE } from 'constants';
// helpers
import { parseParams } from 'helpers/QueryParams';
import parsePosition from 'helpers/PositionParser';
import { getTabs, isUnauthorizedAccess } from './helpers';

const PositionPage = () => {
  const location = useLocation();
  const history = useHistory();
  const { positionId } = useParams();
  const user = useAuth();

  const navigateToTab = newTab => {
    history.push(`${location.pathname}?tab=${newTab}`);
  };

  const { loading, error, data } = useQuery(GET_POSITION_BY_ID, {
    variables: {
      id: positionId,
    },
  });

  if (loading) {
    return <ContentLoader type={SUBMISSION_TYPE} />;
  }

  if (!data.position || isUnauthorizedAccess(data.position, user)) {
    return <ErrorPage code={404} />;
  }

  if (error) {
    return <ErrorPage code={500} />;
  }

  const position = parsePosition(data.position);
  const tabs = getTabs(user.role);
  const whiteListParams = { tab: tabs.map(tab => tab.id) };
  const queryParams = parseParams(location, whiteListParams);
  const currentTab = getProperty(queryParams, 'tab', POSITION_TAB.DETAIL);

  return (
    <DetailPage>
      <DetailPage.Aside>
        <PositionMainInfo position={position} />
      </DetailPage.Aside>
      <DetailPage.Body>
        <DetailPage.Navigation tabs={tabs} activeTab={currentTab} onSwitch={navigateToTab}>
          <PositionSettings singlePositionPage position={position} actionButton />
        </DetailPage.Navigation>
        <DetailPage.Content>
          <PositionContent tab={currentTab} position={position} />
        </DetailPage.Content>
      </DetailPage.Body>
    </DetailPage>
  );
};

export default PositionPage;
