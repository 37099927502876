import React, { useState, useEffect, useRef } from 'react';
// libraries
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
// constants
import { SUPPLIER_DIRECT, SUPPLIER_IBM, SUPPLIER_NOT_DIRECT } from 'constants';
// helpers
import {
  calculateManYearDiscount,
  calculateCandidateGPsFromSellPrice,
} from 'helpers/RatesCalculation';
import { isDirectSupplier as isDirectSupplierHelper } from 'helpers/Submission';
// components
import Allowance from '../Allowance';
import Rates from './Rates';

const propTypes = {
  hours: PropTypes.number,
  partnerCategory: PropTypes.oneOf([SUPPLIER_DIRECT, SUPPLIER_IBM, SUPPLIER_NOT_DIRECT]).isRequired,
  includeCSAFee: PropTypes.bool.isRequired,
  currency: PropTypes.string.isRequired,
  years: PropTypes.arrayOf(PropTypes.number).isRequired,
  nteRates: PropTypes.arrayOf(PropTypes.number).isRequired,
  partnerRates: PropTypes.arrayOf(PropTypes.number).isRequired,
  sellPrices: PropTypes.arrayOf(PropTypes.number).isRequired,
  onApplyRates: PropTypes.func.isRequired,
  isProposal: PropTypes.bool.isRequired,
  enableManYearDiscount: PropTypes.bool,
};

const defaultProps = {
  hours: 0,
  enableManYearDiscount: false,
};

const RatesSimulation = ({
  hours,
  partnerCategory,
  includeCSAFee,
  currency,
  years,
  nteRates,
  partnerRates,
  sellPrices,
  onApplyRates,
  isProposal,
  enableManYearDiscount,
}) => {
  const manYearDiscount = enableManYearDiscount ? calculateManYearDiscount(hours) : 0;
  const isDirectSupplier = isDirectSupplierHelper(partnerCategory);

  const initialSellPrices = useRef(sellPrices);
  const [ibmGPs, setIbmGPs] = useState(
    calculateCandidateGPsFromSellPrice(
      partnerRates,
      initialSellPrices.current,
      manYearDiscount,
      isDirectSupplier,
      includeCSAFee
    )
  );
  const [localSellPrices, setSellPrices] = useState(sellPrices);

  useEffect(() => {
    setSellPrices(sellPrices);
    setIbmGPs(
      calculateCandidateGPsFromSellPrice(
        partnerRates,
        sellPrices,
        manYearDiscount,
        isDirectSupplier,
        includeCSAFee
      )
    );
  }, [sellPrices]);

  const ratesHasChanged = () => {
    if (!isEqual(initialSellPrices.current, localSellPrices)) return true;

    return false;
  };

  const resetChanges = () => {
    setSellPrices(initialSellPrices.current);
    setIbmGPs(
      calculateCandidateGPsFromSellPrice(
        partnerRates,
        initialSellPrices.current,
        manYearDiscount,
        isDirectSupplier,
        includeCSAFee
      )
    );
  };

  return (
    <div>
      <Allowance
        manYearDiscount={manYearDiscount}
        hours={hours || 0}
        partnerCategory={partnerCategory}
        includeCSAFee={includeCSAFee}
        onApplyRates={() => onApplyRates(localSellPrices)}
        resetChanges={resetChanges}
        isDiscardActive={ratesHasChanged()}
        className="m-b-8"
      />
      <Rates
        nteRates={nteRates}
        partnerRates={partnerRates}
        sellPrices={localSellPrices}
        setSellPrices={setSellPrices}
        ibmGPs={ibmGPs}
        setIbmGPs={setIbmGPs}
        initialSellPrices={initialSellPrices.current}
        partnerCategory={partnerCategory}
        includeCSAFee={includeCSAFee}
        currency={currency}
        manYearDiscount={manYearDiscount}
        years={years}
        isProposal={isProposal}
        className="m-b-16"
      />
    </div>
  );
};

RatesSimulation.propTypes = propTypes;
RatesSimulation.defaultProps = defaultProps;

export default RatesSimulation;
