import { useState, useReducer, useCallback } from 'react';
// libraries
import getProperty from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

const ACTIONS = {
  SET: 'SET',
  UPDATE: 'UPDATE',
  REMOVE_UNSELECTED: 'REMOVE_UNSELECTED',
};

const reducer = (submissions, action) => {
  switch (action.type) {
    case ACTIONS.SET:
      return action.submissions;
    case ACTIONS.UPDATE:
      return submissions.map(submission => ({
        ...submission,
        [action.key]: submission.id === action.id ? action.value : submission[action.key],
      }));
    case ACTIONS.REMOVE_UNSELECTED:
      return submissions.filter(submission => !submission.unselected);
    default:
      return submissions;
  }
};

const useSubmissions = initialState => {
  const [savedProgress, setSavedProgress] = useState();
  const [submissions, dispatch] = useReducer(reducer, initialState);

  const setSubmissions = useCallback(newSubmissions => {
    dispatch({ type: ACTIONS.SET, submissions: newSubmissions });
  });

  const updateSubmission = useCallback((id, key, value) => {
    dispatch({ type: ACTIONS.UPDATE, id, key, value });
  });

  const removeUnselected = useCallback(() => {
    dispatch({ type: ACTIONS.REMOVE_UNSELECTED });
  });

  const getProgressData = useCallback(() => {
    if (isEmpty(submissions)) {
      return null;
    }

    // only pre-selected candidate (without any additional change) is not assumed as progress
    const preselectedSubmissionId = getProperty(initialState, '0.id');
    const preselectedSubmission = submissions.find(
      submission => submission.id === preselectedSubmissionId
    );

    if (submissions.length === 1 && preselectedSubmission) {
      // sell rates and releasable documents not changed -> no progress
      const sellRates = getProperty(preselectedSubmission, 'sellRates', []);
      const sellRatesChanged = sellRates.some(rate => rate.highlighted);

      if (
        !sellRatesChanged &&
        !preselectedSubmission.releasableSubmissionFile &&
        !preselectedSubmission.releasableAttestation
      ) {
        return null;
      }
    }

    return submissions.map(submission => {
      const submissionData = {
        id: submission.id,
      };

      // sell rates
      if (submission.sellRates) {
        submissionData.sellRates = submission.sellRates.map(rate => ({
          year: rate.year,
          value: rate.value,
        }));
      }

      // documents
      if (submission.releasableSubmissionFile) {
        submissionData.releasableSubmissionFile = submission.releasableSubmissionFile;
      }
      if (submission.releasableAttestation) {
        submissionData.releasableAttestation = submission.releasableAttestation;
      }

      return submissionData;
    });
  });

  const commitProgress = useCallback(progress => {
    const progressToSave = progress || getProgressData();

    setSavedProgress(progressToSave);
  });

  const hasProgress = useCallback(() => {
    const currentProgress = getProgressData();

    if (!currentProgress) {
      return false;
    }
    if (!savedProgress && currentProgress) {
      return true;
    }

    return !isEqual(savedProgress, currentProgress);
  });

  return {
    submissions,
    setSubmissions,
    updateSubmission,
    removeUnselected,
    getProgressData,
    commitProgress,
    hasProgress,
  };
};

export default useSubmissions;
