import React, { useState } from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Collapse from '@material-ui/core/Collapse';
// components
import Button from 'components/atoms/Button';
// styles
import './cardSection.scss';

const propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.any,
  collapsible: PropTypes.bool,
  collapsed: PropTypes.bool,
  noPadding: PropTypes.bool,
  bordered: PropTypes.bool,
  children: PropTypes.any,
};

const defaultProps = {
  className: '',
  title: '',
  content: null,
  collapsible: false,
  collapsed: false,
  noPadding: false,
  bordered: false,
  children: null,
};

const CardSection = ({
  className,
  title,
  content,
  collapsible,
  collapsed,
  noPadding,
  bordered,
  children,
}) => {
  const { t } = useTranslation();
  const [isCollapsed, setIsCollapsed] = useState(collapsed);

  const classes = classNames('cardSection', className, {
    'cardSection--noPadding': noPadding,
    'cardSection--bordered': bordered,
  });

  const toggleSection = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className={classes}>
      <div className={classNames('cardSection_header', { 'm-b-15': !collapsible })}>
        <div>
          <div className="cardSection_title m-r-10">{title}</div>
        </div>
        {collapsible && (
          <Button
            type={isCollapsed ? 'expandMore' : 'less'}
            tooltip={isCollapsed ? t('expand') : t('collapse')}
            onClick={toggleSection}
          />
        )}
      </div>
      <Collapse in={!isCollapsed} timeout="auto">
        <div className="cardSection_content">{content || children}</div>
      </Collapse>
    </div>
  );
};

CardSection.propTypes = propTypes;
CardSection.defaultProps = defaultProps;

export default CardSection;
