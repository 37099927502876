/* eslint-disable import/order */
import React from 'react';
// libraries
import PropTypes from 'prop-types';
import getProperty from 'lodash/get';
// constants
import { TYPES } from './constants';
import { SH_EXPERIENCE_LEVEL } from 'constants';
// components
import LeftSection from './LeftSection';
import RightSection from './RightSection';
// helpers
import { roundNumber } from 'helpers/Number';
import { getMatchType } from './helpers';
// types
import { MetricType } from 'types';
// styles
import styles from './matchMetrics.module.scss';

const propTypes = {
  metrics: MetricType.isRequired,
  score: PropTypes.number.isRequired,
  candidateName: PropTypes.string.isRequired,
  positionTitle: PropTypes.string.isRequired,
  project: PropTypes.string.isRequired,
  occupations: PropTypes.arrayOf(PropTypes.string).isRequired,
  experienceLevel: PropTypes.oneOf(Object.values(SH_EXPERIENCE_LEVEL)).isRequired,
  onClose: PropTypes.func,
};

const defaultProps = {
  onClose: null,
};

const MatchMetrics = ({
  onClose,
  metrics,
  score,
  candidateName,
  positionTitle,
  project,
  occupations,
  experienceLevel,
}) => {
  const matchType = getMatchType(metrics.percentile);
  const matchColor = getProperty(
    TYPES.find(type => type.value === matchType),
    'color'
  );
  const roundedScore = roundNumber(score, 1);
  const roundedMetrics = {
    ...metrics,
    min: roundNumber(metrics.min, 1),
    max: roundNumber(metrics.max, 1),
    mean: roundNumber(metrics.mean, 1),
    median: roundNumber(metrics.median, 1),
    dataPoints: metrics.dataPoints.map(value => roundNumber(value, 1)),
  };

  return (
    <div className={styles.container}>
      <LeftSection
        metrics={roundedMetrics}
        score={roundedScore}
        matchType={matchType}
        matchColor={matchColor}
        candidateName={candidateName}
        positionTitle={positionTitle}
      />
      <RightSection
        metrics={roundedMetrics}
        score={roundedScore}
        matchColor={matchColor}
        candidateName={candidateName}
        project={project}
        experienceLevel={experienceLevel}
        occupations={occupations}
        onClose={onClose}
      />
    </div>
  );
};

MatchMetrics.propTypes = propTypes;
MatchMetrics.defaultProps = defaultProps;

export default MatchMetrics;
