import React, { useState } from 'react';
// libraries
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';
// components
import OutsideDetector from 'components/atoms/OutsideDetector';

const propTypes = {
  children: PropTypes.any.isRequired,
  onEnter: PropTypes.func.isRequired,
  onLeave: PropTypes.func.isRequired,
  disableClickAway: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
  disableClickAway: false,
  disabled: false,
};

const HoverDetector = ({ children, onEnter, onLeave, disableClickAway, disabled, className }) => {
  const [active, setActive] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleEnter = event => {
    if (!active) {
      setAnchorEl(event.currentTarget);
      setActive(true);
      onEnter();
    }
  };

  const handleLeave = () => {
    if (active) {
      setAnchorEl(null);
      setActive(false);
      onLeave();
    }
  };

  const open = Boolean(anchorEl);
  const transitionId = open ? 'mouse-over-popover' : undefined;

  return (
    <OutsideDetector onClick={() => (!disableClickAway ? handleLeave : null)}>
      <div
        className={className}
        onMouseEnter={!disabled ? handleEnter : null}
        onMouseLeave={!disabled ? handleLeave : null}
        onMouseOver={!disabled ? throttle(handleEnter, 250) : null}
        onFocus={() => {}}
        onBlur={() => {}}
        aria-owns={transitionId}
        aria-haspopup="true"
      >
        {children}
      </div>
    </OutsideDetector>
  );
};

HoverDetector.propTypes = propTypes;
HoverDetector.defaultProps = defaultProps;

export default HoverDetector;
