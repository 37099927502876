/* eslint-disable import/order */
import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import getProperty from 'lodash/get';
// constants
import { RATES_SUPPLIER_RATE } from 'constants';
import { SORT_BY } from '../constants';
// components
import Section from 'components/molecules/FieldSection';
import Document from '../Document';
import Table from '../Table';
import Errors from '../Errors';
import UnselectAction from '../UnselectAction';
import SortPopover, { SORT_TYPE } from '../SortPopover';
import PreviouslySavedLegend from '../PreviouslySavedLegend';
import Submission from './Submission';
// styles
import styles from './review.module.scss';
import moduleStyles from '../submitCandidates.module.scss';

const propTypes = {
  isProposal: PropTypes.bool.isRequired,
  submissions: PropTypes.arrayOf(PropTypes.object).isRequired,
  position: PropTypes.shape({
    title: PropTypes.string.isRequired,
    project: PropTypes.string.isRequired,
    experienceLevel: PropTypes.string.isRequired,
    occupations: PropTypes.arrayOf(PropTypes.string).isRequired,
    currency: PropTypes.string.isRequired,
    manYearDiscount: PropTypes.number.isRequired,
    nteRates: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number.isRequired,
        year: PropTypes.number.isRequired,
      })
    ).isRequired,
  }).isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  onSubmissionUnselect: PropTypes.func.isRequired,
  onSubmissionRestore: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  hasSavedSubmissions: PropTypes.bool.isRequired,
  rateCard: PropTypes.shape({
    boxId: PropTypes.string.isRequired,
    filename: PropTypes.string.isRequired,
  }),
};

const defaultProps = {
  errors: [],
  rateCard: null,
};

const Review = ({
  isProposal,
  rateCard,
  submissions,
  position,
  errors,
  onSubmissionUnselect,
  onSubmissionRestore,
  onSort,
  hasSavedSubmissions,
}) => {
  const { t } = useTranslation();
  const years = getProperty(submissions, '0.sellRates', []).map(sellRate => sellRate.year);

  const handleSort = sortBy => order => {
    onSort(sortBy, order);
  };

  return (
    <Section
      title={t('submitCandidatesReviewTitle')}
      description={t('submitCandidatesReviewDescription')}
    >
      <Table>
        <Table.Head
          className={classNames(styles.row, styles.head, { [styles.proposal]: isProposal })}
        >
          {!isProposal && (
            <Table.Title>
              <SortPopover type={SORT_TYPE.NUMBER} onSort={handleSort(SORT_BY.SCORE)}>
                {t('score')}
              </SortPopover>
            </Table.Title>
          )}
          <Table.Title>
            <SortPopover type={SORT_TYPE.STRING} onSort={handleSort(SORT_BY.NAME)}>
              {t('name')}
            </SortPopover>
          </Table.Title>
          <Table.Title className="text-center">
            {t('sellRates')}
            {(!isProposal || years.length > 1) && (
              <div
                className={classNames(moduleStyles.headerYears, styles.years)}
                style={{ '--numberOfYears': years.length }}
              >
                {years.map(year => (
                  <div key={year}>{year}</div>
                ))}
              </div>
            )}
          </Table.Title>
          <Table.Title>{t('attachments')}</Table.Title>
        </Table.Head>
        <Table.Body>
          {submissions.map(submission => {
            return (
              <Table.Row
                className={classNames(styles.row, { [styles.proposal]: isProposal })}
                key={submission.id}
                disabled
              >
                <Submission
                  isProposal={isProposal}
                  score={submission.score}
                  name={submission.getName()}
                  company={submission.partner.name}
                  partnerType={submission.partner.category}
                  sellRates={submission.sellRates}
                  partnerRates={submission.getRates(RATES_SUPPLIER_RATE)}
                  includeCSAFee={submission.partner.includeCSAFee}
                  releasableSubmissionFile={submission.releasableSubmissionFile}
                  releasableAttestation={submission.releasableAttestation}
                  disabled={submission.unselected}
                  position={position}
                  metrics={!isProposal ? submission.getMetrics() : null}
                  previouslySaved={submission.previouslySaved}
                />
                <UnselectAction
                  onUnselect={() => onSubmissionUnselect(submission.id)}
                  onRestore={() => onSubmissionRestore(submission.id)}
                  isRemoved={!!submission.unselected}
                />
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      {isProposal && rateCard && (
        <div className="m-t-20">
          {rateCard && <Document boxId={rateCard.boxId} filename={rateCard.filename} bordered />}
        </div>
      )}
      {(errors.length > 0 || hasSavedSubmissions) && (
        <div className={classNames(styles.footer, { [styles.alignRight]: !errors.length })}>
          {errors.length > 0 && (
            <div className="m-r-15">
              <Errors messages={errors} />
            </div>
          )}
          {hasSavedSubmissions && <PreviouslySavedLegend />}
        </div>
      )}
    </Section>
  );
};

Review.propTypes = propTypes;
Review.defaultProps = defaultProps;

export default Review;
