import React from 'react';

export interface DialogContextProps {
  labelId: string; // The id of the element that label the dialog.
  loading: boolean;
  loadingText: string;
  onClose: () => void;
}

export default React.createContext<DialogContextProps>({
  labelId: '',
  loading: false,
  loadingText: '',
  onClose: () => {},
});
