/* eslint-disable import/order */
import React, { useState, useContext } from 'react';
// libraries
import { useTranslation, Trans } from 'react-i18next';
import PropTypes from 'prop-types';
// hooks
import useAuth from 'hooks/useAuth';
// context
import ToastContext from 'context/ToastContext';
// helpers
import { getTimeYear } from 'helpers/RelativeTimeFormatter';
// constants
import { ON_BOARDING_START_DATE_CONFIRMED } from 'constants/templates';
import { ONBOARDING_TAB } from 'constants/onboarding';
import { SUBMISSION_STATUS_TYPE } from 'constants/submissionStatus';
// components
import Button from 'components/atoms/Button';
import EmailModal from '../EmailModal';
import JobOffer from './JobOffer';
import TextContent from '../TextContent';
import EditForm from '../EditForm';
// forms
import { rejectStartDateForm } from '../forms';
import { submitStartDateToPM, parseEmailTemplateData } from '../helpers';

const propTypes = {
  confirmedStartDate: PropTypes.object,
  submission: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
  requiredStartDate: PropTypes.string,
  supplier: PropTypes.string,
};

const defaultProps = {
  confirmedStartDate: {},
  requiredStartDate: null,
  supplier: '',
};

const CONTENT_REJECT_START_DATE = 'CONTENT_REJECT_START_DATE';

const SubmitStartDate = ({
  confirmedStartDate,
  submission,
  position,
  requiredStartDate,
  supplier,
}) => {
  const { addToast } = useContext(ToastContext);
  const { t } = useTranslation();
  const user = useAuth();

  const [isOpen, setOpen] = useState(false);
  const [contentType, setContentType] = useState(null);

  const { rfq, project } = position;
  const formattedConfirmedStartDate = getTimeYear(confirmedStartDate.date);
  const formattedRequiredStartDate = getTimeYear(requiredStartDate);
  const { managerEmail, ...templateData } = parseEmailTemplateData(
    submission,
    position,
    user,
    ONBOARDING_TAB.START_DATE
  );

  const handleSubmit = async email => {
    try {
      const onboardingStatus = submission.getStatus(
        user.role,
        SUBMISSION_STATUS_TYPE.ONBOARDING_START_DATE
      );

      await submitStartDateToPM({
        submission,
        currentOnboardingStatusId: onboardingStatus.id,
        email,
        userId: user.id,
      });
      setOpen(false);
    } catch (error) {
      addToast.error(t('errorWhileSubmittingStartDateToProjectManager'));
    }
  };

  const onStartDateReject = () => {
    //   TODO: REJECT START DATE BY MATCH MANAGER to finish in future
  };

  const getContent = () => {
    if (contentType === CONTENT_REJECT_START_DATE) {
      return (
        <EditForm
          onCancel={() => setContentType(null)}
          onSubmit={onStartDateReject}
          defaultStartDate={confirmedStartDate.date}
          onSubmitLabel={t('reject')}
          form={rejectStartDateForm}
        />
      );
    }
    return (
      <React.Fragment>
        <div className="display-flex-row flexEnd p-t-15">
          <React.Fragment>
            {/* TODO: will be implemented in near future */}
            {/* <span className="p-r-12">
          <Button
            label={t('reject')}
            onClick={() => setContentType(CONTENT_REJECT_START_DATE)}
            secondary
          />
        </span> */}
            <Button label={t('submit')} onClick={() => setOpen(true)} />
          </React.Fragment>
        </div>
        <JobOffer submission={submission} className="w100" date={confirmedStartDate} />
      </React.Fragment>
    );
  };

  const getLabel = () => {
    const expectedStartDate = (
      <Trans i18nKey="expectedStartDateForPosition">
        Expected start date for {{ rfq }} is <b>{{ formattedRequiredStartDate }}</b>.
      </Trans>
    );
    if (contentType === CONTENT_REJECT_START_DATE) {
      return <span>{expectedStartDate}</span>;
    }
    return (
      <span>
        {expectedStartDate}
        <br />
        {t('submitStartDateForApprovalToPM')}
      </span>
    );
  };

  return (
    <React.Fragment>
      <TextContent
        title={
          <Trans i18nKey="supplierConfirmedAvailabilityForCandidate">
            {{ supplier }} has confirmed availability for
            {{ formattedConfirmedStartDate }}.
          </Trans>
        }
        label={getLabel()}
        content={getContent()}
      />
      <EmailModal
        isOpen={isOpen}
        projectId={project.id}
        title={t('submitStartDateForApprovalToPM')}
        recipient={managerEmail}
        onClose={() => setOpen(false)}
        onSubmit={handleSubmit}
        templateType={ON_BOARDING_START_DATE_CONFIRMED}
        templateData={{ ...templateData, candidateStartDate: formattedConfirmedStartDate }}
      />
    </React.Fragment>
  );
};

SubmitStartDate.propTypes = propTypes;
SubmitStartDate.defaultProps = defaultProps;

export default SubmitStartDate;
