import React from 'react';
import type { IconType } from 'react-icons';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

interface IconProps {
  component: IconType;
  size?: SvgIconProps['fontSize'];
  color?: string;
}

const Icon = ({ component, size, color = 'inherit', ...rest }: IconProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SvgIcon component={component} fontSize={size} htmlColor={color} inheritViewBox {...rest} />
);
export default Icon;
