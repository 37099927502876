import React, { useState } from 'react';
import { MdAdd } from 'react-icons/md';
import { Stack } from '@mui/material';
import useToast from '@hooks/useToast';
import useDataFetch from '@hooks/useDataFetch';
import Page from '@components/Page';
import Heading from '@components/Heading';
import ComboBox from '@components/ComboBox';
import SearchInput from '@components/SearchInput';
import Button from '@components/Button';
import Chip from '@components/Chip';
import Alert from '@components/Alert';
import InfiniteScroll from '@components/InfiniteScroll';
import SupplierFormModal from './SupplierFormModal';
import SupplierDeleteModal from './SupplierDeleteModal';
import RecordsTable from './RecordsTable';
import App from '../../../App';
import {
  CSAFeeOption,
  SupplierCategory,
  SupplierFormData,
  SupplierRecord,
  SupplierStatus,
} from './types';
import fetchRecords from './helpers/fetchRecords';
import editRecord from './helpers/editRecord';
import createRecord from './helpers/createRecord';
import deleteRecord from './helpers/deleteRecord';
import { statusOptions, typeOptions, csaFeeOptions } from './data/filterOptions';
import styles from './suppliersPage.module.scss';

const SuppliersPage = () => {
  const [isOpen, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [itemToEdit, setItemToEdit] = useState<Nullable<SupplierRecord>>(null);
  const [itemToDelete, setItemToDelete] = useState<Nullable<SupplierRecord>>(null);

  const toast = useToast();
  const {
    count,
    data: items,
    loading,
    loadingMore,
    error,
    hasMore,
    filteredBy,
    filterBy,
    fetchMore,
    searchByText,
    refetch,
  } = useDataFetch(fetchRecords, { preFetch: true });

  const handleEdit = async (data: SupplierFormData) => {
    try {
      setSubmitting(true);
      if (itemToEdit) {
        await editRecord(itemToEdit.id, data);
        setItemToEdit(null);
        refetch();
      }

      toast.success('Supplier was successfully updated');
    } catch (err) {
      toast.error('Something went wrong');
    } finally {
      setSubmitting(false);
    }
  };

  const handleCreate = async (data: SupplierFormData) => {
    try {
      setSubmitting(true);
      await createRecord(data);
      setOpen(false);
      refetch();

      toast.success('Supplier was successfully created');
    } catch (err) {
      toast.error('Something went wrong');
    } finally {
      setSubmitting(false);
    }
  };

  const handleDelete = async () => {
    try {
      setSubmitting(true);
      if (itemToDelete) {
        await deleteRecord(itemToDelete.id);
        setItemToDelete(null);
        refetch();
      }

      toast.success('Supplier was successfully marked as deleted');
    } catch (err) {
      toast.error('Something went wrong');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <App>
      <Page>
        <div className={styles.header}>
          <Heading>Suppliers</Heading>
          {!loading && count > 0 && (
            <span className={styles.count}>
              <Chip size="small">{count}</Chip>
            </span>
          )}
        </div>
        <div className={styles.toolbar}>
          <Stack gap={2} direction="row">
            <SearchInput placeholder="Search by name" onChange={searchByText} />
            <ComboBox
              ariaLabel="Filter by status"
              placeholder="Filter by status"
              value={filteredBy?.status || ''}
              options={statusOptions}
              onChange={(option) => filterBy({ status: option?.id as SupplierStatus })}
            />
            <ComboBox
              ariaLabel="Filter by type"
              placeholder="Filter by type"
              value={filteredBy?.type || ''}
              options={typeOptions}
              onChange={(option) => filterBy({ type: option?.id as SupplierCategory })}
            />
            <ComboBox
              ariaLabel="Filter by CSA fee"
              placeholder="Filter by CSA fee"
              value={filteredBy?.csaFee || ''}
              options={csaFeeOptions}
              onChange={(option) => filterBy({ csaFee: option?.id as CSAFeeOption })}
            />
          </Stack>
          <Button icon={MdAdd} onClick={() => setOpen(true)}>
            Add Supplier
          </Button>
        </div>
        {!loading && !count && (
          <Alert type="info" classes={{ root: styles.alert }}>
            No records were found
          </Alert>
        )}
        {error && (
          <Alert type="error" classes={{ root: styles.alert }}>
            Something went wrong during data fetching
          </Alert>
        )}
        {!error && (loading || count > 0) && (
          <InfiniteScroll
            hasMore={hasMore}
            itemsLength={items.length}
            onFetchMore={fetchMore}
            hasItems={items.length > 0}
          >
            <RecordsTable
              items={items}
              loading={loading && !loadingMore}
              onEdit={setItemToEdit}
              onDelete={setItemToDelete}
            />
          </InfiniteScroll>
        )}
        <SupplierFormModal
          isOpen={isOpen}
          submitting={submitting}
          title="Create Supplier"
          onClose={() => setOpen(false)}
          onSubmit={handleCreate}
        />
        <SupplierFormModal
          key={itemToEdit?.id}
          isOpen={!!itemToEdit}
          submitting={submitting}
          initialData={{
            name: itemToEdit?.name || '',
            includeCSAFee: itemToEdit?.includeCSAFee,
            isDirectSupplier: itemToEdit?.category === 'direct',
          }}
          title={`Edit "${itemToEdit?.name || ''}" Supplier`}
          onClose={() => setItemToEdit(null)}
          onSubmit={handleEdit}
        />
        <SupplierDeleteModal
          key={itemToDelete?.id}
          name={itemToDelete?.name || ''}
          isOpen={!!itemToDelete}
          submitting={submitting}
          onClose={() => setItemToDelete(null)}
          onSubmit={handleDelete}
        />
      </Page>
    </App>
  );
};

export default SuppliersPage;
