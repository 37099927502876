import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// hooks
import useAuth from 'hooks/useAuth';
// components
import LinkButton from 'components/atoms/LinkButton';
import Loader from 'components/atoms/Loader';
// helpers
import { openLink } from 'helpers/Link';
import { boxFileUrl } from 'helpers/Box';
import { getTimeYear } from 'helpers/RelativeTimeFormatter';
// constants
import { MANAGER_ROLE } from 'constants';
// styles
import styles from './documents.module.scss';

const propTypes = {
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      boxId: PropTypes.string,
      filename: PropTypes.string,
      createdAt: PropTypes.string,
    })
  ),
  notes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      text: PropTypes.string,
      createdAt: PropTypes.string,
      author: PropTypes.string,
    })
  ),
  darker: PropTypes.bool,
};

const defaultProps = {
  files: [],
  notes: [],
  darker: false,
};

const Documents = ({ loading, title, files, notes, darker }) => {
  const { role } = useAuth();
  const { t } = useTranslation();
  // PM role shouldn't see notes provided by partner
  const filteredNotes = role === MANAGER_ROLE ? [] : notes;

  if (!files.length && !filteredNotes.length) {
    return null;
  }

  const renderFile = file => {
    return (
      <React.Fragment>
        <LinkButton className={styles.link} onClick={() => openLink(boxFileUrl(file.boxId))}>
          {file.filename}
        </LinkButton>
        <div className={styles.info}>
          <b>{file.author}</b> {getTimeYear(file.createdAt)}
        </div>
      </React.Fragment>
    );
  };

  return (
    <div className={classNames(styles.container, { [styles.darker]: darker })}>
      <div className={styles.header}>{title}</div>
      {loading ? (
        <div className={styles.loader}>
          <Loader fixedSize small />
        </div>
      ) : (
        <div className={styles.items}>
          {files.map(fileObject => {
            return (
              <div key={fileObject.label}>
                <div className={styles.label}>{fileObject.label}</div>
                {fileObject.files.length > 0 ? (
                  fileObject.files.map((file, index) => (
                    <div className={classNames({ 'm-t-10': index })} key={file.boxId}>
                      {renderFile(file)}
                    </div>
                  ))
                ) : (
                  <i>{t('notProvided')}</i>
                )}
              </div>
            );
          })}
          {filteredNotes.map(note => {
            return (
              <div key={note.label}>
                <div className={styles.label}>{note.label}</div>
                {note.text ? (
                  <React.Fragment>
                    {note.text}
                    <div className={styles.info}>
                      <b>{note.author}</b> {getTimeYear(note.createdAt)}
                    </div>
                  </React.Fragment>
                ) : (
                  <i>{t('notProvided')}</i>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

Documents.propTypes = propTypes;
Documents.defaultProps = defaultProps;

export default Documents;
