import React, { useState, useContext } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/useAuth';
import { CircularProgress } from '@material-ui/core';
// context
import ToastContext from 'context/ToastContext';
// services
import logger from 'services/Logger';
// components
import Avatar from 'components/atoms/Avatar';
import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
// styles
import colors from 'dependencies/materialStyles/Colors';
// helpers
import { getReadableYearDateTime } from 'helpers/RelativeTimeFormatter';
import { markAsRead, markAsUnread, removeNotification } from './Helpers';

const propTypes = {
  data: PropTypes.object,
  className: PropTypes.string,
  close: PropTypes.func,
};

const defaultProps = {
  data: {},
  className: '',
  close: () => {},
};

const Notification = ({ className, data, close }) => {
  const [isShown, setIsShown] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { lastViewedNotification } = useAuth();
  const { t } = useTranslation();
  const { addToast } = useContext(ToastContext);
  const { createdAt, message, link, read, name, payload, id } = data;

  if (!message) {
    return null;
  }

  const onClick = async () => {
    if (!loading) {
      close();
      // not all notification will have link
      if (link) {
        history.push(link);
      }

      if (!read) {
        try {
          await markAsRead({ id, payload, createdAt, message });
        } catch (error) {
          addToast.error(t('errorWhileMarkingNotificationRead'));
          logger.exception(error);
        }
      }
    }
  };

  const deleteNotification = async e => {
    e.stopPropagation();
    try {
      setLoading(true);
      await removeNotification(id, lastViewedNotification.id);
    } catch (error) {
      addToast.error(t('errorWhileDeletingNotification'));
      logger.exception(error);
    }
  };

  const markNotificationRead = async e => {
    e.stopPropagation();
    try {
      if (read) {
        await markAsUnread({ id, payload, createdAt, message });
      } else {
        await markAsRead({ id, payload, createdAt, message });
      }
    } catch (error) {
      addToast.error(
        read ? t('errorWhileMarkingNotificationUnread') : t('errorWhileMarkingNotificationRead')
      );
      logger.exception(error);
    }
  };

  return (
    <div
      className={`notificationItem_wrapper ${!read ? 'unread' : ''}`}
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
    >
      <div className={`${className} notificationItem`} onClick={onClick} role="presentation">
        <div className="m-r-15">
          <Avatar name={name} size={17}>
            <Icon name="settings" color={colors.green8} />
          </Avatar>
        </div>
        <div className="notificationItem_content">
          <div className="notificationItem-message">{message}</div>
          <div className="notificationItem-date">
            {createdAt ? getReadableYearDateTime(createdAt) : ''}
          </div>
        </div>
      </div>
      <div
        className="notificationItem_controllers"
        style={{ visibility: isShown || loading ? 'visible' : 'hidden' }}
      >
        {loading ? (
          <div className="notificationItem_loader">
            <CircularProgress size={14} />
          </div>
        ) : (
          <React.Fragment>
            <Button type="delete" onClick={deleteNotification} tooltip={t('remove')} size={18} />
            <Button
              type={read ? 'read' : 'unread'}
              onClick={markNotificationRead}
              tooltip={read ? t('markUnread') : t('markRead')}
              size={18}
            />
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

Notification.propTypes = propTypes;
Notification.defaultProps = defaultProps;

export default Notification;
