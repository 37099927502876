import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// styles
import styles from './table.module.scss';

const propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const Title = ({ children, className }) => {
  return <div className={classNames(styles.title, { [className]: className })}>{children}</div>;
};

Title.propTypes = propTypes;
Title.defaultProps = defaultProps;

export default Title;
