export const USER_UNAUTHENTICATED = 'unauthenticated';
export const USER_UNAUTHORIZED = 'unauthorized';
export const SIGN_UP_WEAK_PASSWORD = 'weekPassword';

// sorting hat response error statuses
export const SH_ERRORS = {
  NOT_AUTHORIZED: 'NOT_AUTHORIZED',
  INVALID_CREDENTIALS: 'INVALID_CREDENTIALS',
  TOKEN_REVOKED: 'TOKEN_REVOKED',
  BAD_USER_INPUT: 'BAD_USER_INPUT',
  OBJECT_NOT_FOUND: 'OBJECT_NOT_FOUND',
  UNPROCESSABLE_ENTITY: 'UNPROCESSABLE_ENTITY',
  SERVICE_UNAVAILABLE: 'SERVICE_UNAVAILABLE',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
};
