import React, { useContext } from 'react';
import classNames from 'classnames';
import CircularProgress from '@mui/material/CircularProgress';
import DialogContext from './DialogContext';
import styles from './dialog.module.scss';

interface DialogBodyProps {
  children: React.ReactNode;
}

const DialogBody = ({ children }: DialogBodyProps) => {
  const { loading, loadingText } = useContext(DialogContext);

  return (
    <div className={styles.body}>
      <div className={classNames({ [styles.hiddenContent]: loading })}>{children}</div>
      {loading && (
        <div className={styles.loader}>
          <CircularProgress size={36} color="inherit" />
          {loadingText && <div className={styles.loadingText}>{loadingText}</div>}
        </div>
      )}
    </div>
  );
};

export default DialogBody;
