import i18n from 'i18next';
// constants
import { SUBMISSION_STATUS } from 'constants/submissionStatus';

/**
 * Parse user who submitted the candidate + submission date
 * @param {object} submission
 * @returns {object}
 */
export const getSubmissions = submission => {
  const newStatus = submission.findStatus(SUBMISSION_STATUS.NEW);
  const submittedStatus = submission.findStatus(SUBMISSION_STATUS.SUBMITTED);

  const submitter = newStatus
    ? {
        date: newStatus.start,
        name: submission.submittedBy.name || i18n.t('unknownUser'),
      }
    : null;

  const reviewer = submittedStatus
    ? {
        date: submittedStatus.start,
        name: submission.reviewedBy.name || i18n.t('unknownUser'),
      }
    : null;

  return {
    submitter,
    reviewer,
  };
};
