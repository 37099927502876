import { gql } from '@apollo/client';
import getProperty from 'lodash/get';
// queries
import { SubmissionStatus } from 'queries/Fragments';
// services
import client from 'services/Client';
import logger from 'services/Logger';
// helpers
import parseSubmission from 'helpers/SubmissionParser';
import parseProposal from 'helpers/ProposalParser';

const FETCH_SUBMISSIONS = gql`
  query fetchSubmissions($positionId: ID!, $submissionStatus: [String!]) {
    submissionsList(
      filter: {
        position: { id: { equals: $positionId } }
        statuses: { some: { name: { in: $submissionStatus }, end: { is_empty: true } } }
      }
    ) {
      items {
        id
        profile {
          id
          fullName
        }
        statuses(filter: { name: { in: $submissionStatus }, end: { is_empty: true } }) {
          items {
            ...SubmissionStatus
          }
        }
      }
    }
  }
  ${SubmissionStatus}
`;

const FETCH_PROPOSALS = gql`
  query fetchProposals($positionId: ID!, $submissionStatus: [String!]) {
    proposalsList(
      filter: {
        position: { id: { equals: $positionId } }
        statuses: { some: { name: { in: $submissionStatus }, end: { is_empty: true } } }
      }
    ) {
      items {
        id
        name
        statuses(filter: { name: { in: $submissionStatus }, end: { is_empty: true } }) {
          items {
            ...SubmissionStatus
          }
        }
      }
    }
  }
  ${SubmissionStatus}
`;

/**
 * @param {string} positionId
 * @param {string} submissionStatus
 * @param {boolean} isProposal
 * @returns {Promise<object>}
 */
const fetchSubmissions = async (positionId, submissionStatus, isProposal) => {
  try {
    const response = await client.query({
      query: isProposal ? FETCH_PROPOSALS : FETCH_SUBMISSIONS,
      variables: {
        positionId,
        submissionStatus,
      },
    });

    return isProposal
      ? getProperty(response, 'data.proposalsList.items', []).map(parseProposal)
      : getProperty(response, 'data.submissionsList.items', []).map(parseSubmission);
  } catch (error) {
    logger.exception(error, { positionId });
    throw error;
  }
};

export default fetchSubmissions;
