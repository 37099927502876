import { gql } from '@apollo/client';

export const GET_ACTIVE_MAINTENANCES = gql`
  query getActiveMaintenances($currentTime: DateTime!) {
    maintenancesList(
      filter: { AND: [{ date: { gte: $currentTime } }, { enabled: { equals: true } }] }
      sort: { date: ASC }
      first: 1
    ) {
      items {
        id
        date
        text
      }
    }
  }
`;
