import i18n from 'i18next';

// Revision
export const REVISION_MODAL_REVISIONS_TAB = 'revisions';
export const REVISION_MODAL_EMAIL_TAB = 'email';

export const TABS = [
  {
    id: REVISION_MODAL_REVISIONS_TAB,
    label: i18n.t('revision'),
  },
  {
    id: REVISION_MODAL_EMAIL_TAB,
    label: i18n.t('email'),
  },
];

export const REVISION_TYPE_COMPONENT = {
  values: [
    {
      label: i18n.t('proposal'),
      value: 'CV',
    },
    {
      label: i18n.t('attestation'),
      value: 'ATTESTATION',
    },
    {
      label: i18n.t('rates'),
      value: 'RATES',
    },
  ],
  collapsed: false,
  placeholder: i18n.t('selectType'),
  type: 'requestRevision',
  className: 'm-b-10',
  key: 'revision',
};
