import { gql } from '@apollo/client';
import getProperty from 'lodash/get';
// service
import client from 'services/Client';
// helpers
import { getLevelByScore } from 'helpers/MRI';

export const GET_PENDING_REPORT = gql`
  query getPendingReport($id: ID!) {
    pendingReport(id: $id) {
      id
      score
      shPersonId
      shCandidateId
      cv {
        id
        createdAt
        boxId
        name
        type
      }
      positionReport {
        id
        shPositionId
        position {
          id
        }
      }
    }
  }
`;

/**
 * @param {string} reportId
 * @returns {Promise<object>}
 */
export const getPendingReport = async reportId => {
  const result = await client.query({
    query: GET_PENDING_REPORT,
    variables: {
      id: reportId,
    },
  });

  return getProperty(result, 'data.pendingReport');
};

const DELETE_PENDING_REPORT = gql`
  mutation deletePendingReport($id: ID!) {
    pendingReportDelete(filter: { id: $id }) {
      success
    }
  }
`;

/**
 * @param {string} reportId
 * @returns {Promise<object>}
 */
export const deletePendingReport = async reportId => {
  await client.mutate({
    mutation: DELETE_PENDING_REPORT,
    variables: { id: reportId },
    update: cache => {
      const normalizeId = cache.identify({ id: reportId, __typename: 'PendingReport' });
      cache.evict({ id: normalizeId });
      cache.gc();
    },
  });
};

const FETCH_READABILITY_LEVELS = gql`
  query fetchReadabilityLevels {
    cvReadabilitiesList {
      items {
        id
        level
      }
    }
  }
`;

/**
 * Fetch CV readability level id
 * @param {number} score
 * @returns {Promise<string>}
 */
export const fetchCvReadabilityLevel = async score => {
  const level = getLevelByScore(score);
  const response = await client.query({ query: FETCH_READABILITY_LEVELS });
  const mriLevels = getProperty(response, 'data.cvReadabilitiesList.items', []);
  const mriLevelMap = mriLevels.reduce((map, mriLevel) => {
    return { ...map, [mriLevel.level]: mriLevel.id };
  }, {});

  return mriLevelMap[level];
};
