import React from 'react';
// Libraries
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

const propTypes = {
  className: PropTypes.string,
  translation: PropTypes.string,
  link: PropTypes.string,
};

const defaultProps = {
  className: '',
  translation: null,
  link: null,
};

const LinkElement = props => {
  const { className, translation, link } = props;

  return (
    <div className={className}>
      <Trans i18nKey={translation}>
        X
        {link && (
          <a href={link} target="_blank" rel="noopener noreferrer">
            {link}
          </a>
        )}
      </Trans>
    </div>
  );
};

LinkElement.propTypes = propTypes;
LinkElement.defaultProps = defaultProps;

export default LinkElement;
