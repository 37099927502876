import React, { useState } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// helpers
import { formatNumber, prettyNumber } from 'helpers/Number';
// components
import NumberInput from 'components/atoms/NumberInput';
import HoverDetector from 'components/atoms/HoverDetector';
import Tooltip from 'components/atoms/Tooltip';
import LinkButton from 'components/atoms/LinkButton';
import Button from './Button';
// styles
import styles from './rateInput.module.scss';

const propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onUseForAll: PropTypes.func,
  className: PropTypes.string,
  currency: PropTypes.string.isRequired,
  decimalLimit: PropTypes.number,
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  empty: PropTypes.bool,
  highlighted: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  hideUseAll: PropTypes.bool,
  useForAllPopoverStyle: PropTypes.bool,
  allowNegative: PropTypes.bool,
};

const defaultProps = {
  value: null,
  onUseForAll: () => {},
  className: '',
  highlighted: false,
  decimalLimit: 2,
  step: 0.5,
  min: 0,
  max: null,
  disabled: false,
  readOnly: false,
  empty: false,
  error: false,
  errorMessage: '',
  hideUseAll: false,
  useForAllPopoverStyle: false,
  allowNegative: false,
};

const Rate = ({
  value,
  onChange,
  onUseForAll,
  className,
  currency,
  decimalLimit,
  step,
  min,
  max,
  disabled,
  readOnly,
  empty,
  highlighted,
  error,
  errorMessage,
  hideUseAll,
  useForAllPopoverStyle,
  allowNegative,
}) => {
  const { t } = useTranslation();
  const [focused, setFocused] = useState(false);
  const [hovered, setHovered] = useState(false);
  const interactive = !disabled && !readOnly;
  const active = focused || hovered;

  const addValue = () => {
    const currentValue = Number(value) || 0;
    const newValue = formatNumber(currentValue + step, decimalLimit);

    if (max === null) {
      onChange(newValue);
    } else {
      onChange(Math.min(newValue, max));
    }
  };

  const subtractValue = () => {
    const currentValue = Number(value) || 0;
    const newValue = formatNumber(currentValue - step, decimalLimit);

    if (min === null) {
      onChange(newValue);
    } else {
      onChange(Math.max(newValue, min));
    }
  };

  return (
    <HoverDetector
      disabled={!interactive}
      onEnter={() => setHovered(true)}
      onLeave={() => setHovered(false)}
      className={classNames(styles.wrapper, {
        [styles.disabled]: disabled,
        [styles.empty]: disabled && empty,
        [styles.hoverable]: interactive,
        [styles.focused]: focused && interactive,
        [styles.error]: error,
        [className]: className,
      })}
    >
      <Tooltip
        title={<span>{errorMessage}</span>}
        open={!!errorMessage && error && active}
        noTransition
      >
        <NumberInput
          value={!empty ? value : ''}
          onChange={onChange}
          disabled={disabled}
          readOnly={readOnly}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          decimalLimit={decimalLimit}
          step={step}
          min={min}
          max={max}
          border={false}
          allowNegative={allowNegative}
          formatter={prettyNumber}
          className={classNames(styles.input, {
            [styles.highlighted]: highlighted && interactive,
          })}
        />
      </Tooltip>
      {active && interactive ? (
        <div className={styles.control}>
          <Button icon="less" onClick={addValue} />
          <Button icon="expandMore" onClick={subtractValue} />
        </div>
      ) : (
        <div className={classNames(styles.control, styles.currency)}>{currency}</div>
      )}
      {active && interactive && !hideUseAll && (
        <LinkButton
          onClick={() => onUseForAll(value)}
          className={classNames(styles.useForAll, {
            [styles.popover]: useForAllPopoverStyle,
          })}
        >
          {t('useForAll')}
        </LinkButton>
      )}
    </HoverDetector>
  );
};

Rate.propTypes = propTypes;
Rate.defaultProps = defaultProps;

export default Rate;
