import reduceObject from 'lodash/reduce';

export const areRatesFilled = (years, filledRatesCustom, rates) => {
  const areFilled =
    (years && filledRatesCustom >= years) ||
    (rates && filledRatesCustom === Object.keys(rates).length);
  return areFilled;
};

export const isRequiredConditionMet = (comparedRatesArray, values) => {
  const requiredCondition = comparedRatesArray.find(({ required }) => required);

  if (!requiredCondition) return true;

  return values.filter(Boolean).every(({ errorMessageKey }) => {
    return errorMessageKey !== requiredCondition.errorMessageKey;
  });
};

export const getComparedRatesArray = rates => {
  return reduceObject(
    rates,
    (prev, value, objectKey) => {
      return [
        ...prev,
        {
          rateKey: objectKey,
          ...value,
        },
      ];
    },
    []
  );
};
