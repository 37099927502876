import React, { useState } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Collapse from '@material-ui/core/Collapse';
// components
import Button from 'components/atoms/Button';
// styles
import styles from './collapsibleContent.module.scss';

const propTypes = {
  collapsed: PropTypes.bool,
  header: PropTypes.any,
  headerClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  children: PropTypes.any.isRequired,
  fixedSize: PropTypes.bool,
};

const defaultProps = {
  collapsed: false,
  header: '',
  headerClassName: '',
  contentClassName: '',
  fixedSize: true,
};

const CollapsibleContent = ({
  collapsed,
  header,
  headerClassName,
  contentClassName,
  fixedSize,
  children,
}) => {
  const { t } = useTranslation();
  const [isCollapsed, setIsCollapsed] = useState(collapsed);

  const toggleSection = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <React.Fragment>
      <div
        className={classNames(styles.header, {
          [headerClassName]: headerClassName,
        })}
      >
        {header && <div className="m-r-10">{header}</div>}
        <div className={classNames(styles.button, { [styles.relative]: fixedSize })}>
          <Button
            type={isCollapsed ? 'expandMore' : 'less'}
            tooltip={isCollapsed ? t('expand') : t('collapse')}
            onClick={toggleSection}
          />
        </div>
      </div>
      {children && (
        <Collapse in={!isCollapsed} timeout="auto">
          <div className={classNames('m-t-15', { [contentClassName]: contentClassName })}>
            {children}
          </div>
        </Collapse>
      )}
    </React.Fragment>
  );
};

CollapsibleContent.propTypes = propTypes;
CollapsibleContent.defaultProps = defaultProps;

export default CollapsibleContent;
