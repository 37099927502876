import React, { useMemo } from 'react';
import LoadingWrapper from '@components/LoadingWrapper';
import styles from './loader.module.scss';

interface LoaderProps {
  size?: 'small' | 'medium' | 'large';
  ariaLabel?: string;
}

const Loader = ({ size = 'medium', ariaLabel = 'Loading' }: LoaderProps) => {
  const containerStyles = useMemo(() => {
    switch (size) {
      case 'small':
        return { '--size': 12 };
      case 'medium':
        return { '--size': 16 };
      case 'large':
        return { '--size': 20 };
      default:
        return { '--size': 16 };
    }
  }, [size]);

  return (
    <LoadingWrapper loading ariaLabel={ariaLabel}>
      <div className={styles.container} style={containerStyles}>
        <span className={styles.dot} />
        <div className={styles.dots}>
          <span />
          <span />
          <span />
        </div>
      </div>
    </LoadingWrapper>
  );
};

export default Loader;
