import axios from 'services/Axios';

/**
 * @typedef {Object} EmailAddress
 * @property {string} name
 * @property {string} email
 */

/**
 * @typedef {Object} EmailAttachment
 * @property {string} id box id
 * @property {string} [filename] filename without extension. Extension will be appended automatically
 *  base on file mime type.
 */

/**
 * Send email
 * subject, from, to, and html are required fields
 * @param {object} options
 * @param {string} options.subject
 * @param {string|EmailAddress} options.from Sender
 * @param {string|string[]|EmailAddress|EmailAddress[]} options.to Recipient(s)
 * @param {string|string[]|EmailAddress|EmailAddress[]} [options.cc]
 * @param {string|string[]|EmailAddress|EmailAddress[]} [options.bcc]
 * @param {string|EmailAddress} [options.replyTo]
 * @param {EmailAttachment|EmailAttachment[]} [options.attachments]
 * @param {string} options.html
 * @returns {Promise}
 */
export const sendEmail = options => {
  return axios({
    url: '/api/email/send',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: options,
  });
};

/**
 * Get email template
 * @param {string} id email template id from SendGrid
 * @returns {Promise<object>}
 */
export const getTemplate = async id => {
  const response = await axios(`/api/email/template/${id}`);
  const { subject, html } = response.data;

  return {
    subject,
    html,
  };
};
