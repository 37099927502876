/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
// context
import ModalContext from 'context/ModalContext';
// constants
import { ACTION_DELETE, ACTION_EDIT } from 'constants';
// helpers
import colors from 'dependencies/materialStyles/Colors';
// components
import IconDropdown from 'components/molecules/IconDropdown';
import DeleteDraft from './DeleteDraft';
import EditDraftName from './EditDraftName';

const propTypes = {
  draftId: PropTypes.string.isRequired,
};

class DraftSettings extends React.Component {
  static contextType = ModalContext;

  onClick = (e, type) => {
    const { openModal } = this.context;
    e.preventDefault();
    openModal(type);
  };

  renderMenuItems = () => {
    return [
      { icon: 'edit', id: 'editDraftName', onClick: e => this.onClick(e, ACTION_EDIT) },
      { icon: 'delete', id: 'deleteDraft', onClick: e => this.onClick(e, ACTION_DELETE) },
    ];
  };

  render() {
    const { isModalOpen, closeModal } = this.context;
    const { draftId } = this.props;
    return (
      <div className="fs-unmask">
        <IconDropdown menuItems={this.renderMenuItems()} iconColor={colors.gray} />
        <DeleteDraft isOpen={isModalOpen(ACTION_DELETE)} onClose={closeModal} draftId={draftId} />
        <EditDraftName isOpen={isModalOpen(ACTION_EDIT)} onClose={closeModal} draftId={draftId} />
      </div>
    );
  }
}

DraftSettings.propTypes = propTypes;

export default DraftSettings;
