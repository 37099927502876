import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
import shortid from 'shortid';
// components
import Upload from './Upload';
import Preview from './Preview';
// styles
import styles from './fileUpload.module.scss';

const propTypes = {
  className: PropTypes.string,
  selected: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      size: PropTypes.number,
      link: PropTypes.string,
    })
  ),
  accept: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  onChange: PropTypes.func,
  onEdit: PropTypes.func,
  editable: PropTypes.bool,
  onDrop: PropTypes.func,
  loading: PropTypes.bool,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
};

const defaultProps = {
  className: '',
  selected: [],
  accept: null,
  onDrop: null,
  loading: false,
  multiple: false,
  disabled: false,
  editable: false,
  error: false,
  onChange: () => {},
  onEdit: () => {},
};

const FileUpload = ({
  className,
  selected,
  onChange,
  onDrop,
  loading,
  multiple,
  accept,
  disabled,
  editable,
  onEdit,
  error,
}) => {
  const selectedFiles = selected || [];

  const removeFile = id => {
    const files = selected.filter(file => file.id !== id);
    onChange(files);
  };

  const onFilesChange = files => {
    const newFiles = files.map(file => ({
      id: shortid.generate(),
      file,
      name: file.name,
      size: file.size,
    }));

    if (onDrop) {
      onDrop(files);
    }

    onChange([...selectedFiles, ...newFiles]);
  };

  return (
    <div className={className}>
      {selectedFiles.length > 0 && (
        <div className={styles.files}>
          {selectedFiles.map(file => (
            <div key={file.id} className={classNames({ 'm-b-8': multiple })}>
              <Preview
                filename={file.name}
                size={file.size}
                link={file.link}
                onRemove={() => removeFile(file.id)}
                editable={editable}
                onEdit={onEdit}
                loading={loading}
                disabled={disabled}
              />
            </div>
          ))}
        </div>
      )}
      {(multiple || !selectedFiles.length) && (
        <Upload
          onChange={onFilesChange}
          multiple={multiple}
          disabled={disabled}
          accept={accept}
          error={error}
        />
      )}
    </div>
  );
};

FileUpload.propTypes = propTypes;
FileUpload.defaultProps = defaultProps;

export default FileUpload;
