import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
// components
import Banner, { BANNER_TYPE } from 'components/molecules/Banner';
// helpers
import { filterSubmissionsByStatus } from 'helpers/Submission';
// constants
import { SUBMISSION_STATUS } from 'constants/submissionStatus';
import { ONSITE } from 'constants';
// styles
import styles from './submitCandidates.module.scss';

const propTypes = {
  position: PropTypes.object.isRequired,
};

const Header = ({ position }) => {
  const renderSubmittedCandidatesCount = () => {
    const maximumSubmissionAllowed = position.maximumCandidatesAllowed;
    const numberOfSubmittedCandidates = filterSubmissionsByStatus(
      position.getSubmissions(),
      SUBMISSION_STATUS.SUBMITTED
    ).length;

    return (
      <Trans i18nKey="permittedCandidatesSubmitted">
        {{ numberOfSubmittedCandidates }} of {{ maximumSubmissionAllowed }} permitted submissions
        have been made
      </Trans>
    );
  };

  const renderPositionTitle = () => {
    const isOnsite = position.delivery.value === ONSITE;
    const { city, country } = position.location;

    if (isOnsite && city) {
      return `${position.title} in ${city}, ${country}`;
    }

    return `${position.title}, ${position.delivery.label}`;
  };

  return (
    <Link to={`/position/${position.id}`}>
      <Banner type={BANNER_TYPE.ANNOUNCEMENT}>
        <div className={styles.header}>
          <div className="m-r-12">
            <div className={styles.rfq}>{position.rfqLabel}</div>
            <div className={styles.title}>{renderPositionTitle()}</div>
            <div className={styles.submittedCandidatesCount}>
              {renderSubmittedCandidatesCount()}
            </div>
          </div>
          <div className={styles.evaluationMethod}>{position.evaluationMethod.label}</div>
        </div>
      </Banner>
    </Link>
  );
};

Header.propTypes = propTypes;

export default Header;
