import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// hooks
import useAuth from 'hooks/useAuth';
// components
import Tooltip from 'components/atoms/Tooltip';
import PositionTargetRateInfo from 'components/molecules/PositionTargetRateInfo';
// helpers
import { getCurrencyText } from 'helpers/NameMapping';
import { prettyNumber } from 'helpers/Number';
import { getMainRate, getSecondaryRate } from './helpers';
// styles
import styles from './positionCard.module.scss';

const propTypes = {
  position: PropTypes.object.isRequired,
};

const Rates = ({ position }) => {
  const { t } = useTranslation();
  const { role, supplierId } = useAuth();
  const mainRate = getMainRate(position, role);
  const secondaryRate = getSecondaryRate(position, role);
  const currency = getCurrencyText(position.project.currency);
  const tooltipText = position.isFixedPrice() ? t('fixedPrice') : t('hourlyRate');

  return (
    <div className={styles.rates}>
      <div className={classNames(styles.rate, { [styles.smallerRate]: position.isFixedPrice() })}>
        {mainRate ? (
          <Tooltip title={tooltipText} placement="bottom">
            <span>
              {prettyNumber(mainRate)} {currency}
            </span>
          </Tooltip>
        ) : (
          <PositionTargetRateInfo
            positionId={position.id}
            hasBeenPublishedBefore={position.hasBeenPublishedBefore()}
            isPublished={position.isPublished(role, supplierId)}
            className={styles.emptyRate}
            currency={currency}
            size={24}
          />
        )}
      </div>
      {secondaryRate && (
        <Tooltip title={tooltipText} placement="bottom">
          <div className={styles.nte}>
            NTE: {prettyNumber(secondaryRate)} {currency}
          </div>
        </Tooltip>
      )}
    </div>
  );
};

Rates.propTypes = propTypes;

export default Rates;
