import { onError } from '@apollo/client/link/error';
import logger from '@services/logger';

const errorLink = () =>
  // eslint-disable-next-line consistent-return
  onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        logger.log(`[Graphql error]: ${error.message}`, {
          code: error?.extensions?.code || '',
          exception: error?.extensions?.exception || '',
          operationName: operation.operationName,
          variables: operation.variables,
        });
      });
    }

    if (networkError) {
      logger.error(`[Network error]: ${networkError}`, {
        operationName: operation?.operationName,
        variables: operation?.variables,
      });
    }
  });

export default errorLink;
