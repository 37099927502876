export const FILE_TYPE = {
  PDF: 'filePdf',
  IMAGE: 'fileImage',
  CSV: 'fileCsv',
  EXCEL: 'fileExcel',
  TEXT: 'fileText',
  WORD: 'fileWord',
  ARCHIVE: 'fileArchive',
  FILE: 'file',
};
