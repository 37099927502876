import { useState } from 'react';
import useIsMounted from './useIsMounted';

/**
 * @param {string} [modal] Modal id which will be opened in the start
 */
const useModalProvider = (modal = '') => {
  const isMounted = useIsMounted();
  const [modalOpen, setModalOpen] = useState(!!modal);
  const [modalId, setModalId] = useState(modal);

  const openModal = id => {
    if (isMounted()) {
      setModalOpen(true);
      setModalId(id);
    }
  };

  const closeModal = () => {
    if (isMounted()) {
      setModalOpen(false);
      setModalId('');
    }
  };

  const isModalOpen = id => modalOpen && modalId === id;

  return {
    openModal,
    closeModal,
    isModalOpen,
  };
};

export default useModalProvider;
