import { useState, useEffect, useRef } from 'react';
// libraries
import i18n from 'i18next';

const PROCESS_TEXTS = [
  i18n.t('uploadingCV'),
  i18n.t('parsingCV'),
  i18n.t('extractingSkills'),
  i18n.t('identifyingSkills'),
  i18n.t('evaluationPerformed'),
  i18n.t('preparingReport'),
];

const useAnalyseProcessText = () => {
  const intervalId = useRef('');
  const [loading, setLoading] = useState(false);
  const [textId, setTextId] = useState(0);

  useEffect(() => {
    return () => {
      if (intervalId.current) {
        intervalId.current = '';
        clearInterval(intervalId.current);
      }
    };
  }, []);

  const start = () => {
    setLoading(true);

    intervalId.current = setInterval(() => {
      setTextId(({ id }) => ({
        textId: id + 1,
        loading: true,
      }));
    }, 2500);
  };

  const clean = () => {
    clearInterval(intervalId.current);
    setLoading(false);
    setTextId(0);
  };

  const getText = () => {
    return textId < PROCESS_TEXTS.length
      ? PROCESS_TEXTS[textId]
      : PROCESS_TEXTS[PROCESS_TEXTS.length - 1];
  };

  return { loading, text: getText(), start, clean };
};

export default useAnalyseProcessText;
