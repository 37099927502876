// services
import client from 'services/Client';
// queries
import { GET_USER, GET_ME } from 'queries/AuthenticationQueries';
// helpers
import parseUser from 'helpers/UserParser';

const useAuth = () => {
  const { email } = client.readQuery({ query: GET_ME });

  if (!email) return null;

  const { user, role } = client.readQuery({
    query: GET_USER,
    variables: {
      email,
    },
  });

  return parseUser({ ...user, email }, role);
};

export default useAuth;
