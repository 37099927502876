import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// components
import LinkButton from 'components/atoms/LinkButton';
import Button from 'components/atoms/Button';
// styles
import styles from './unselectAction.module.scss';

const propTypes = {
  onUnselect: PropTypes.func.isRequired,
  onRestore: PropTypes.func.isRequired,
  isRemoved: PropTypes.bool.isRequired,
  children: PropTypes.any,
};

const defaultProps = {
  children: null,
};

const UnselectAction = ({ onUnselect, onRestore, isRemoved, children }) => {
  const { t } = useTranslation();

  if (isRemoved) {
    return (
      <div className="text-right">
        <LinkButton className={styles.undo} onClick={onRestore}>
          {t('undo')}
        </LinkButton>
      </div>
    );
  }

  return (
    <div className="text-right">
      <Button type="close" size={18} onClick={onUnselect} iconStyle="icon20" />
      {children && <div className="m-t-2">{children}</div>}
    </div>
  );
};

UnselectAction.propTypes = propTypes;
UnselectAction.defaultProps = defaultProps;

export default UnselectAction;
