import React from 'react';
import ReactDOM from 'react-dom';
import Media from 'react-media';
// Libraries
import 'translations/i18n';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router } from 'react-router-dom';
// services
import logger from 'services/Logger';
// components
import App from 'App';
import Toast from 'components/molecules/Toast/Toast';
import UpdateManager from 'components/organisms/UpdateManager';
import AuthProvider from 'components/templates/AuthenticationProvider';
// styles
import globalTheme from 'dependencies/materialStyles/GlobalTheme';
import 'dependencies/scss/reset.scss';
import 'dependencies/scss/base.scss';
import 'dependencies/scss/common.scss';
import 'dependencies/scss/ckeditor.scss';

// Listen to every uncaught exception and log them
window.onerror = (msg, src, line, _col, error) => {
  if (error) {
    logger.exception(error, { msg, src, line });
  }

  return true;
};

ReactDOM.render(
  <AuthProvider>
    <MuiThemeProvider theme={globalTheme}>
      <Toast>
        <Router>
          <Media query={{ maxWidth: 785 }}>{matches => <App isSmallScreen={matches} />}</Media>
          <UpdateManager />
        </Router>
      </Toast>
    </MuiThemeProvider>
  </AuthProvider>,
  document.getElementById('root')
);
