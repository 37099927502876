import { gql } from '@apollo/client';
import getProperty from 'lodash/get';
import moment from 'moment';
import uniq from 'lodash/uniq';
// services
import client from 'services/Client';
import logger from 'services/Logger';
import notifications from 'services/Notification';
// queries
import { GET_POSITION_BY_ID } from 'queries/PositionQueries';
import { PositionStatus, SubmissionStatus } from 'queries/Fragments';
// helpers
import { formatDate } from 'helpers/RelativeTimeFormatter';
import { getSubmissionLink } from 'helpers/Link';
// constants
import { NOTE_OFFER, NOTE_SUBMISSION_STATUS, NOTE_POSITION_STATUS } from 'constants';
import { SUBMISSION_STATUS, SUBMISSION_STATUS_TYPE } from 'constants/submissionStatus';
import { POSITION_STATUS } from 'constants/positionStatus';
import {
  START_DATE_TYPE,
  ONBOARDING_STATUS_DATE,
  ONBOARDING_STATUS_FORMS,
  ONBOARDING_STATUS_PO,
} from 'constants/onboarding';
import { FIELD } from '../constants';

const ONBOARDING_TYPE_MAP = {
  HIRING_START_DATE: SUBMISSION_STATUS_TYPE.ONBOARDING_START_DATE,
  HIRING_ONBOARDING: SUBMISSION_STATUS_TYPE.ONBOARDING_FORMS,
  HIRING_PO: SUBMISSION_STATUS_TYPE.ONBOARDING_PO,
};

const ONBOARDING_INITIAL_STATUS = {
  [SUBMISSION_STATUS_TYPE.ONBOARDING_START_DATE]: ONBOARDING_STATUS_DATE.PROPOSE,
  [SUBMISSION_STATUS_TYPE.ONBOARDING_FORMS]: ONBOARDING_STATUS_FORMS.SUBMIT,
  [SUBMISSION_STATUS_TYPE.ONBOARDING_PO]: ONBOARDING_STATUS_PO.RELEASE,
};

/**
 * Get onboarding status mutation data
 * @param {object[]} onboardingConfig
 * @returns object
 */
const getOnboardingStatuses = onboardingConfig => {
  const statuses = [];

  onboardingConfig.forEach(option => {
    const type = getProperty(option, 'type');
    const statusType = ONBOARDING_TYPE_MAP[type];

    statuses.push({
      start: moment().toISOString(),
      type: ONBOARDING_TYPE_MAP[type],
      name: ONBOARDING_INITIAL_STATUS[statusType],
    });
  });

  return statuses;
};

const GET_ALREADY_AWARDED_PARTNERS = gql`
  query getAlreadyAwardedPartners($positionId: ID!) {
    position(id: $positionId) {
      awardedTo {
        items {
          id
        }
      }
    }
  }
`;

/**
 * Get awarded suppliers id
 * @param {string} positionId
 * @param {object[]} submissions
 * @param {object[]} selectedSubmissions
 * @returns {string[]} List of suppliers id
 */
const getAwardedSuppliersMutationData = async (positionId, submissions, selectedSubmissions) => {
  const awardedSuppliers = selectedSubmissions.map(selectedSubmission => {
    const matchedSubmission = submissions.find(
      submission => submission.id === selectedSubmission.id
    );

    return matchedSubmission ? matchedSubmission.partner.id : '';
  });

  if (awardedSuppliers.length > 0) {
    return { reconnect: uniq(awardedSuppliers.filter(Boolean)).map(id => ({ id })) };
  }

  const response = await client.query({
    query: GET_ALREADY_AWARDED_PARTNERS,
    variables: { positionId },
  });
  const alreadyAwardedSuppliers = getProperty(response, 'data.position.awardedTo.items', []).map(
    ({ id }) => id
  );

  return { disconnect: alreadyAwardedSuppliers.map(id => ({ id })) };
};

const parseSubmission = ({
  submission,
  formData,
  onboardingConfig,
  isWinner,
  rejectionReason,
  userId,
}) => {
  const currentStatus = submission.getStatus();

  if (isWinner) {
    const startDate = formData[FIELD.START_DATE].value;
    const mutationData = {
      statuses: {
        update: {
          filter: { id: currentStatus.id },
          data: { end: moment().toISOString() },
        },
        create: [
          ...getOnboardingStatuses(onboardingConfig),
          {
            start: moment().toISOString(),
            name: SUBMISSION_STATUS.OFFER,
          },
        ],
      },
      hiringProcess: {
        create: {
          dates: {
            create: { date: formatDate(startDate), type: START_DATE_TYPE.EXPECTED },
          },
        },
      },
    };

    const note = getProperty(formData, [FIELD.NOTES, 'value']);
    if (note) {
      mutationData.hiringProcess.create.notes = {
        create: {
          note,
          noteType: NOTE_OFFER,
          author: {
            connect: {
              id: userId,
            },
          },
        },
      };
    }

    return mutationData;
  }

  if (
    ![SUBMISSION_STATUS.SUBMITTED, SUBMISSION_STATUS.OFFER, SUBMISSION_STATUS.ONBOARDING].includes(
      currentStatus.value
    )
  ) {
    return null;
  }

  const data = {
    statuses: {
      update: {
        filter: { id: currentStatus.id },
        data: { end: moment().toISOString() },
      },
      create: {
        start: moment().toISOString(),
        name:
          currentStatus.value === SUBMISSION_STATUS.SUBMITTED
            ? SUBMISSION_STATUS.NOT_SELECTED
            : SUBMISSION_STATUS.ONBOARDING_CANCELLED,
      },
    },
  };

  if (rejectionReason) {
    data.statuses.create.note = {
      create: { note: rejectionReason, noteType: NOTE_SUBMISSION_STATUS },
    };
  }

  return data;
};

const SELECT_WINNER = gql`
  mutation selectWinner($positionId: ID!, $data: PositionUpdateInput!) {
    positionUpdate(filter: { id: $positionId }, data: $data) {
      id
      totalEvaluatedPrice
      statuses(sort: { createdAt: DESC }) {
        items {
          ...PositionStatus
        }
      }
      awardedTo {
        items {
          id
        }
      }
      submissions {
        count
        items {
          id
          statuses(sort: { createdAt: DESC }) {
            items {
              ...SubmissionStatus
            }
          }
        }
      }
      proposals {
        count
        items {
          id
          statuses(sort: { createdAt: DESC }) {
            items {
              ...SubmissionStatus
            }
          }
        }
      }
    }
  }
  ${PositionStatus}
  ${SubmissionStatus}
`;

/**
 * Ger rejection reason for submission
 * @param {string} submissionId
 * @param {object} formData
 * @returns {string} Rejection reason
 */
const getRejectionReason = (submissionId, formData) => {
  const rejectedSubmissions = getProperty(formData, [FIELD.NOT_SELECTED_SUBMISSIONS, 'value'], []);
  const hasRejectionReason = getProperty(formData, [FIELD.HAS_REJECTION_REASON, 'value'], false);
  const sameRejectionReason = getProperty(formData, [FIELD.SAME_REJECTION_REASON, 'value'], false);
  const reason = getProperty(formData, [FIELD.REASON, 'value'], '');
  const reasons = getProperty(formData, [FIELD.REASONS, 'value']);

  if (!hasRejectionReason) {
    return '';
  }

  if (sameRejectionReason || rejectedSubmissions.length === 1) {
    return reason;
  }

  return getProperty(reasons, submissionId, '');
};

/**
 * @param {object} formData
 * @param {object} position
 * @param {string} userId Logged in user
 * @returns {Promise}
 */
const selectWinner = async (formData, position, userId) => {
  try {
    const onboardingConfig = position.getConfig('onboarding', []);
    const positionStatus = position.getStatus();
    const submittedSubmissions = position
      .getParsedSubmissions()
      .filter(submission => submission.hasStatus(SUBMISSION_STATUS.SUBMITTED, false));
    const onboardingSubmissions = position
      .getParsedSubmissions()
      .filter(submission => submission.hasStatus(SUBMISSION_STATUS.OFFER, false));

    const totalEvalPrice = getProperty(formData, [FIELD.TOTAL_EVALUATED_PRICE, 'value'], 0);
    const selectedSubmissions = []
      .concat(getProperty(formData, [FIELD.SELECTED_SUBMISSION, 'value'], []))
      .filter(Boolean);
    const selectedSubmissionsId = selectedSubmissions.map(({ id }) => id);
    const rejectedSubmissions =
      getProperty(formData, [FIELD.NOT_SELECTED_SUBMISSIONS, 'value']) ||
      onboardingSubmissions.filter(({ id }) => !selectedSubmissionsId.includes(id));

    const submissionMutationData = {
      update: submittedSubmissions
        .map(submission => {
          const isWinner = selectedSubmissions.some(
            selectedSubmission => selectedSubmission.id === submission.id
          );

          const submissionData = parseSubmission({
            submission,
            formData,
            onboardingConfig,
            isWinner,
            rejectionReason: getRejectionReason(submission.id, formData),
            userId,
          });

          if (!submissionData) {
            return null;
          }

          return {
            filter: { id: submission.id },
            data: submissionData,
          };
        })
        .filter(Boolean),
    };

    const mutationData = {
      awardedTo: await getAwardedSuppliersMutationData(
        position.id,
        submittedSubmissions,
        selectedSubmissions
      ),
    };

    if (position.isFixedPrice()) {
      mutationData.proposals = submissionMutationData;
    } else {
      mutationData.submissions = submissionMutationData;
    }

    // Bid has been won
    if (selectedSubmissions.length > 0) {
      if (positionStatus.value !== POSITION_STATUS.OFFER) {
        mutationData.statuses = {
          update: {
            filter: { id: positionStatus.id },
            data: { end: moment().toISOString() },
          },
          create: {
            start: moment().toISOString(),
            name: POSITION_STATUS.OFFER,
          },
        };
      }
    } else {
      // Bid has been lost
      mutationData.totalEvaluatedPrice = totalEvalPrice;
      mutationData.statuses = {
        update: {
          filter: { id: positionStatus.id },
          data: { end: moment().toISOString() },
        },
        create: {
          start: moment().toISOString(),
          name:
            onboardingSubmissions.length > 0
              ? POSITION_STATUS.ONBOARDING_CANCELLED
              : POSITION_STATUS.NO_SELECTION,
        },
      };

      const justification = getProperty(formData, [FIELD.JUSTIFICATION, 'value']);
      if (justification) {
        mutationData.statuses.create.note = {
          create: {
            note: justification,
            noteType: NOTE_POSITION_STATUS,
          },
        };
      }
    }

    await client.mutate({
      mutation: SELECT_WINNER,
      variables: { positionId: position.id, data: mutationData },
      refetchQueries: [{ query: GET_POSITION_BY_ID, variables: { id: position.id } }],
      awaitRefetchQueries: true,
      update: cache => {
        cache.evict({ fieldName: ['submissionsList', 'proposalsList', 'positionsList'] });
        cache.gc();
      },
    });

    // notifications
    rejectedSubmissions.forEach(submission => {
      const currentStatus = submission.getStatus();

      if (
        [
          SUBMISSION_STATUS.SUBMITTED,
          SUBMISSION_STATUS.OFFER,
          SUBMISSION_STATUS.ONBOARDING,
        ].includes(currentStatus.value)
      ) {
        notifications.submissionRejectedByClient({
          submission: submission.id,
          link: getSubmissionLink(submission.id, position.isFixedPrice()),
          createdBy: userId,
          isProposal: position.isFixedPrice(),
        });
      }
    });

    selectedSubmissions.forEach(submission => {
      notifications.submissionSelectedByClient({
        submission: submission.id,
        link: getSubmissionLink(submission.id, position.isFixedPrice()),
        createdBy: userId,
        isProposal: position.isFixedPrice(),
      });
    });
  } catch (error) {
    logger.exception(error);
    throw error;
  }
};

export default selectWinner;
