// constants
import { SUBMISSION_FILTERS } from 'constants/filters';
import {
  SORT_CV_DATE,
  SORT_DUE_DATE,
  SORT_AVAILABILITY,
  STAFF_ROLE,
  MANAGER_ROLE,
  SUPPLIER_ROLE,
} from 'constants';
// helpers
import {
  getFilterByProjects,
  getFilterForSupplierProposals,
  getFilterForManagerSubmissions,
} from 'helpers/Filter';
import { getParsedSearchText } from 'helpers/Position';
import {
  getFilterForProject,
  getFilterForLocation,
  getFilterForPartner,
  getFilterForSearchText,
  getFilterForDueDate,
  getFilterForStatuses,
} from './filters';

/**
 * Format filters
 * @param {string} searchText
 * @param {object} filteredBy
 * @param {object} user
 * @param {string} user.role
 * @param {string} user.supplierId
 * @param {string[]} user.projects
 * @param {object[]} user.clients
 */
export const formatFilters = (searchText, filteredBy, user) => {
  const filter = [];

  // filter positions that are related to projects of current role
  filter.push({
    position: {
      AND: [
        { id: { is_not_empty: true } },
        getFilterByProjects(user.projects.map(project => project.id)),
      ],
    },
  });

  // managers can't see candidates with statuses "New" or "Not Submitted to Client"
  if (user.role === MANAGER_ROLE) {
    filter.push(getFilterForManagerSubmissions());
  }

  // partner can't see candidates of other suppliers
  if (user.role === SUPPLIER_ROLE) {
    filter.push(getFilterForSupplierProposals(user.supplierId));
  }

  if (filteredBy) {
    const project = filteredBy[SUBMISSION_FILTERS.PROJECT];
    const location = filteredBy[SUBMISSION_FILTERS.LOCATION];
    const partner = filteredBy[SUBMISSION_FILTERS.PARTNER];
    const dueDates = [].concat(filteredBy[SUBMISSION_FILTERS.DUE_DATE] || []);
    const statuses = [].concat(filteredBy[SUBMISSION_FILTERS.STATUS] || []);

    // filter by due date
    if (dueDates.length > 0) {
      filter.push(getFilterForDueDate(dueDates, user.role));
    }

    // filter by status
    if (statuses.length > 0) {
      filter.push(getFilterForStatuses(statuses, user.role));
    }

    // filter by project
    if (project) {
      filter.push(getFilterForProject(project));
    }

    // filter by location (city)
    if (location) {
      filter.push(getFilterForLocation(location));
    }

    // filter by partner
    if (user.role === STAFF_ROLE && partner) {
      filter.push(getFilterForPartner(partner));
    }
  }

  // if there's search text typed that has at least 3 characters
  if (searchText && searchText.length > 2) {
    const newSearchText = getParsedSearchText(searchText, user.clients);
    if (newSearchText.length > 2) {
      // search by position number and title
      filter.push(getFilterForSearchText(newSearchText));
    }
  }

  return { AND: filter };
};

export const formatSorts = (sortedBy, role) => {
  switch (sortedBy.id) {
    case SORT_DUE_DATE:
      return role === SUPPLIER_ROLE
        ? { position: { supplierDueDate: 'DESC' } }
        : { position: { dueOn: 'DESC' } };
    case SORT_AVAILABILITY:
      return { availability: { start: 'ASC' } };
    case SORT_CV_DATE:
      return { submissionDate: 'DESC' };
    default:
      return { score: 'DESC' };
  }
};
