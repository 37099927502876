import orderBy from 'lodash/orderBy';
import times from 'lodash/times';

/**
 * @param {object[]} rates
 * @param {number} rates.value
 * @param {number} rates.year
 * @param {number} [startYear]
 * @returns {object[]}
 */
const updateYears = (rates, startYear) => {
  return rates.map((rate, index) => ({
    ...rate,
    value: rate.value,
    year: startYear + index,
  }));
};

/**
 * Init rates.
 * @param {object[]} rates
 * @param {number} rates.value
 * @param {number} rates.year
 * @param {number} [startYear]
 * @param {number} [numberOfYears]
 * @returns {object[]}
 */
export const parseRates = (rates, startYear, numberOfYears) => {
  const orderedRates = orderBy(rates, 'year');

  if (!startYear && !numberOfYears) {
    return orderedRates;
  }

  // if more rates provided than numberOfYears -> remove last rates
  if (orderedRates.length > numberOfYears) {
    return updateYears(orderedRates.slice(0, numberOfYears), startYear);
  }

  // if no rates or missing rates for some years -> create missing rates
  if (orderedRates.length < numberOfYears) {
    const newRates = [...orderedRates];

    times(numberOfYears, index => {
      if (!newRates[index]) {
        newRates.push({
          value: null,
          year: startYear + index,
          error: false,
          highlighted: false,
        });
      }
    });

    return updateYears(newRates, startYear);
  }

  return updateYears(rates, startYear);
};
