import React from 'react';
// libraries
import PropTypes from 'prop-types';
// components
import Chip from 'components/atoms/NewChip';
// constants
import { MRI_LEVEL } from 'constants/report';
// helpers
import { getLevelDetails } from './helpers';

const propTypes = {
  level: PropTypes.oneOf(Object.values(MRI_LEVEL)).isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const MRILevelChip = ({ level, className }) => {
  const { color, label } = getLevelDetails(level);

  return (
    <Chip color={color} className={className}>
      {label}
    </Chip>
  );
};

MRILevelChip.propTypes = propTypes;
MRILevelChip.defaultProps = defaultProps;

export default MRILevelChip;
