import React from 'react';
import PropTypes from 'prop-types';
// constants
import { ROUTE_LEGAL_TERMS, ROUTE_LEGAL_PRIVACY, ROUTE_LEGAL_COOKIES } from 'constants';
// components
import Cookies from './Cookies';
import LegalTerms from './LegalTerms';
import LegalPrivacy from './LegalPrivacy';

const propTypes = {
  tab: PropTypes.string,
};

const defaultProps = {
  tab: null,
};

const LegalPageContent = ({ tab, ...restProps }) => {
  if (tab === ROUTE_LEGAL_TERMS) {
    return <LegalTerms {...restProps} />;
  }
  if (tab === ROUTE_LEGAL_PRIVACY) {
    return <LegalPrivacy {...restProps} />;
  }
  if (tab === ROUTE_LEGAL_COOKIES) {
    return <Cookies {...restProps} />;
  }
  return null;
};

LegalPageContent.propTypes = propTypes;
LegalPageContent.defaultProps = defaultProps;

export default LegalPageContent;
