import i18n from 'i18next';
// constants
import { MRI_LEVEL } from 'constants/report';
import { COLOR } from 'constants/colors';

export const getLevelDetails = mriLevel => {
  if (mriLevel === MRI_LEVEL.LOW) {
    return {
      label: i18n.t('low'),
      color: COLOR.RED,
    };
  }
  if (mriLevel === MRI_LEVEL.MODERATE) {
    return {
      label: i18n.t('moderate'),
      color: COLOR.YELLOW,
    };
  }

  return {
    label: i18n.t('high'),
    color: COLOR.GREEN,
  };
};
