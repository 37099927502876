import React from 'react';
import shortid from 'shortid';
// libraries
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { Trans, withTranslation } from 'react-i18next';
// context
import ToastContext from 'context/ToastContext';
// services
import { signUp, getPasswordRules } from 'services/Authentication';
// components
import Field from 'components/molecules/Field';
import Loader from 'components/atoms/Loader';
import Input from 'components/atoms/Input';
import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
// styles
import colors from 'dependencies/materialStyles/Colors';
// constants
import { SIGN_UP_WEAK_PASSWORD } from 'constants/error';
import { SIGN_UP_FORM } from './Constants';

const propTypes = {
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

class SignUpForm extends React.Component {
  static contextType = ToastContext;

  isMounted = false;

  state = {
    loading: false,
    data: {
      firstName: {
        value: '',
        error: false,
        errorMessage: '',
      },
      lastName: {
        value: '',
        error: false,
        errorMessage: '',
      },
      email: {
        value: '',
        error: false,
        errorMessage: '',
      },
      phoneNumber: {
        value: '',
        error: false,
        errorMessage: '',
      },
      password: {
        value: '',
        error: false,
        errorMessage: '',
        hint: null,
        anchor: null,
      },
      confirmedPassword: {
        value: '',
        error: false,
        errorMessage: '',
      },
    },
  };

  async componentDidMount() {
    this.isMounted = true;

    try {
      const { regex, rules } = await getPasswordRules();
      if (this.isMounted) {
        const { t } = this.props;
        this.setState(prevState => ({
          passwordRules: rules,
          regex,
          data: {
            ...prevState.data,
            password: {
              ...prevState.data.password,
              hint: (
                <div>
                  <div className="b p-5">
                    <b>{t('passwordMustContain')}</b>
                  </div>
                  <ul>
                    {rules.map(rule => (
                      <li key={shortid()} className="p-5">
                        - {rule}
                      </li>
                    ))}
                  </ul>
                </div>
              ),
            },
          },
        }));
      }
    } catch (error) {
      if (this.isMounted) {
        this.setState({ passwordRules: null });
      }
    }
  }

  componentWillUnmount() {
    this.isMounted = false;
  }

  handleChange = (value, id) => {
    const {
      data: { password, confirmedPassword },
    } = this.state;

    this.setState(prevState => ({
      data: {
        ...prevState.data,
        [id]: {
          ...prevState.data[id],
          value,
          error: false,
          errorMessage: '',
        },
      },
    }));

    if (
      ['password', 'confirmedPassword'].includes(id) &&
      (password.error || confirmedPassword.error)
    ) {
      this.setState(prevState => ({
        data: {
          ...prevState.data,
          password: {
            ...prevState.data.password,
            error: false,
            errorMessage: '',
          },
          confirmedPassword: {
            ...prevState.data.confirmedPassword,
            error: false,
            errorMessage: '',
          },
        },
      }));
    }
  };

  validate = showToast => {
    const { addToast } = this.context;
    const { t } = this.props;
    const { data, regex, passwordRules } = this.state;

    if (regex && regex.test(data.password.value)) {
      return true;
    }
    if (showToast) addToast.error(t('passwordIssue'));

    this.setState(prevState => ({
      data: {
        ...prevState.data,
        password: {
          ...prevState.data.password,
          error: true,
          errorMessage: t('passwordRequirementsNotMet'),
          popoverErrors: passwordRules,
        },
      },
    }));
    return false;
  };

  compare = showToast => {
    const { addToast } = this.context;
    const { t } = this.props;
    const { data } = this.state;

    if (
      data.password.value &&
      data.confirmedPassword.value &&
      data.password.value !== data.confirmedPassword.value
    ) {
      if (showToast) addToast.error(t('passwordNotMatch'));
      this.setState(prevState => ({
        data: {
          ...prevState.data,
          password: {
            ...data.password,
            error: true,
            errorMessage: t('passwordNotMatch'),
          },
          confirmedPassword: {
            ...data.confirmedPassword,
            error: true,
            errorMessage: t('passwordNotMatch'),
          },
        },
      }));
      return false;
    }

    return true;
  };

  validatePasswords = (id, showToast = false) => {
    switch (id) {
      case 'password':
        return this.validate(showToast);
      case 'confirmedPassword':
        return this.compare(showToast);
      default:
        return this.validate(showToast) && this.compare(showToast);
    }
  };

  validateForm = () => {
    const { addToast } = this.context;
    const { t } = this.props;
    const { data } = this.state;
    let someFieldIsMissing = false;

    Object.keys(data).forEach(field => {
      const formField = SIGN_UP_FORM.find(({ id }) => id === field);
      const isRequired = formField ? formField.required : true;

      if (!data[field].value && isRequired) {
        someFieldIsMissing = true;
        this.setState(prevState => ({
          data: {
            ...prevState.data,
            [field]: {
              ...prevState.data[field],
              error: true,
            },
          },
        }));
      }
    });

    if (someFieldIsMissing) {
      addToast.error(t('missingField'));
      return false;
    }
    if (!this.validatePasswords(null, true)) return false;

    return true;
  };

  handleSubmit = async () => {
    const { addToast } = this.context;
    const { t } = this.props;
    if (!this.validateForm()) return;

    try {
      this.setState({ loading: true });

      const { history } = this.props;
      const {
        data: { firstName, lastName, password, phoneNumber, email, confirmedPassword },
      } = this.state;

      const response = await signUp({
        firstName: firstName.value,
        lastName: lastName.value,
        email: email.value,
        phoneNumber: phoneNumber.value,
        password: password.value,
        confirmedPassword: confirmedPassword.value,
      });
      history.push(`/email-verification?uuid=${response.data.uuid}&email=${email.value}`);
    } catch (error) {
      if (error.type === SIGN_UP_WEAK_PASSWORD) {
        addToast.error(error.message);
        this.setState(prevState => ({
          loading: false,
          data: {
            ...prevState.data,
            password: {
              ...prevState.data.password,
              error: true,
              errorMessage: error.message,
            },
          },
        }));
      } else if (error.type) {
        addToast.error(t(error.type));
        this.setState({ loading: false });
      } else {
        addToast.error(t('errorPlaceholderText'));
        this.setState({ loading: false });
      }
    }
  };

  onClear = id => {
    this.handleChange('', id);
  };

  onBlur = (id, value) => {
    const { data } = this.state;

    if (value && ['confirmedPassword', 'password'].includes(id)) {
      this.validatePasswords(id);
    }

    switch (id) {
      case 'confirmedPassword':
        if (data.password.value) {
          this.validatePasswords('password');
        }
        break;
      case 'password':
        if (data.confirmedPassword.value) {
          this.validatePasswords('confirmedPassword');
        }
        break;
      default:
    }
  };

  render() {
    const { t } = this.props;
    const { loading, data } = this.state;

    const getLink = param => {
      return `${window.location.origin}${param}`;
    };

    return (
      <React.Fragment>
        <div className="authForm">
          <div className="authForm_header">{t('register')}</div>
          {SIGN_UP_FORM.map(({ id, icon, title, type, required }) => {
            const onClear = ['password', 'confirmedPassword'].includes(id)
              ? null
              : () => {
                  this.onClear(id);
                };

            return (
              <React.Fragment key={id}>
                <Field
                  key={id}
                  label={t(title)}
                  required={required}
                  error={data[id].error}
                  popoverErrors={data[id].popoverErrors}
                  errorMessage={data[id].errorMessage}
                  fieldNotFilled={data[id].error && !data[id].value}
                  className={`authForm_input ${data[id].error ? 'redForm' : ''}`}
                  content={
                    <Input
                      error={data[id].error}
                      id={id}
                      focusedHint={data[id].hint}
                      hintPlacement="top-end"
                      viewPassword={type === 'password'}
                      displayClearButton
                      icon={<Icon color={colors.gray} size={18} name={icon} />}
                      value={data[id].value}
                      onBlur={() => {
                        this.onBlur(id, data[id].value);
                      }}
                      onClear={onClear}
                      inputType={type}
                      onChange={this.handleChange}
                    />
                  }
                />
              </React.Fragment>
            );
          })}
          <div className="authForm_statement">
            <Trans i18nKey="signUpLegalStatement">
              By clicking Sign Up, you agree to our{' '}
              <a href={getLink('/legal/terms')} target="_blank" rel="noopener noreferrer">
                Terms of Use
              </a>{' '}
              and{' '}
              <a href={getLink('/legal/privacy')} target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </a>
              .
            </Trans>
          </div>
          {loading ? (
            <div className="space-12">
              <Loader />
            </div>
          ) : (
            <div className="authForm_button">
              <Button label={t('signUp')} onClick={this.handleSubmit} />
            </div>
          )}
          <div className="authForm_statement">
            <Trans i18nKey="signUpRedirectLogin">
              Already an existing user?{' '}
              <Link to="/login" className="authLink">
                Sign in
              </Link>
            </Trans>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

SignUpForm.propTypes = propTypes;

export default withTranslation()(withRouter(SignUpForm));
