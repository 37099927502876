import React from 'react';
// libraries
import PropTypes from 'prop-types';
import i18n from 'i18next';
import classNames from 'classnames';
// components
import Chip from 'components/atoms/Chip';
// helpers
import { getPassedRelativeTime } from 'helpers/RelativeTimeFormatter';
// styles
import './statusNote.scss';

const propTypes = {
  status: PropTypes.string.isRequired,
  color: PropTypes.string,
  noteTitle: PropTypes.string,
  noteText: PropTypes.string,
  noteAuthor: PropTypes.string,
  noteDate: PropTypes.string,
  small: PropTypes.bool,
};

const defaultProps = {
  color: 'blue',
  noteTitle: i18n.t('rejectionReason'),
  noteText: '',
  noteAuthor: '',
  noteDate: '',
  small: false,
};

const StatusNote = ({ status, color, noteTitle, noteText, noteAuthor, noteDate, small }) => {
  const renderNote = () => {
    if (!noteText) return null;

    return (
      <div className="statusNote">
        <div className="statusNote_text">{noteText}</div>
        <div className="statusNote_author">
          <b>{noteAuthor}</b> {getPassedRelativeTime(noteDate)}
        </div>
      </div>
    );
  };

  return (
    <Chip
      note={renderNote()}
      noteTitle={noteTitle}
      label={status}
      color={color}
      className={classNames({ smallChip: small })}
      iconSize={12}
      iconColor="white"
    />
  );
};

StatusNote.propTypes = propTypes;
StatusNote.defaultProps = defaultProps;

export default StatusNote;
