import React, { useMemo } from 'react';
import capitalize from 'lodash/capitalize';
import { MdDownload, MdOpenInNew } from 'react-icons/md';
import Link from '@mui/material/Link';
import LoadingWrapper from '@components/LoadingWrapper';
import Chip, { ChipProps } from '@components/Chip';
import IconButton from '@components/IconButton';
import TruncatedText from '@components/TruncatedText';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableBodySkeleton,
} from '@components/Table';
import { formatDateTime } from '@utils/formatDate';
import { ImportRecord, ImportRecordStatus } from './types';
import styles from './importsPage.module.scss';

interface RecordsTableProps {
  items: ImportRecord[];
  loading: boolean;
}

const STATUS_COLOR: Record<ImportRecordStatus, ChipProps['color']> = {
  pending: 'blue',
  parsed: 'green',
  partiallyParsed: 'yellow',
  failed: 'red',
  created: 'gray',
};

const RecordsTable = ({ items, loading }: RecordsTableProps) => {
  const classes = useMemo(
    () => ({
      root: styles.table,
      row: styles.row,
    }),
    []
  );

  return (
    <LoadingWrapper loading={loading} ariaLabel="Loading imports..">
      <Table classes={classes}>
        <TableHead>
          <TableRow>
            <TableCell>Document</TableCell>
            <TableCell>Request Title</TableCell>
            <TableCell>Clearance</TableCell>
            <TableCell>Uploaded At</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>
              <span>&nbsp;</span>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableBodySkeleton columns={6} />
          ) : (
            items.map((item) => (
              <TableRow key={item.id}>
                <TableCell>
                  {item.status === 'created' ||
                  item.status === 'parsed' ||
                  item.status === 'partiallyParsed' ? (
                    <Link href={`/position/import/${item.id}`} target="_blank" rel="noreferrer">
                      {item.filename}
                    </Link>
                  ) : (
                    item.filename
                  )}
                </TableCell>
                <TableCell>
                  <TruncatedText>{item.parsedData?.title || '-'}</TruncatedText>
                </TableCell>
                <TableCell>{item.parsedData?.clearance || '-'}</TableCell>
                <TableCell>{formatDateTime(item.createdAt)}</TableCell>
                <TableCell>
                  <Chip color={STATUS_COLOR[item.status]} size="medium">
                    {item.status === 'partiallyParsed'
                      ? 'Partially parsed'
                      : capitalize(item.status)}
                  </Chip>
                </TableCell>
                <TableCell align="right">
                  {(item.status === 'parsed' || item.status === 'created') && (
                    <IconButton
                      size="small"
                      label="Open"
                      icon={MdOpenInNew}
                      href={`/position/import/${item.id}`}
                      className={styles.openImportLink}
                    />
                  )}
                  <IconButton
                    size="small"
                    label="Download"
                    icon={MdDownload}
                    href={item.downloadLink}
                    download
                  />
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </LoadingWrapper>
  );
};

export default RecordsTable;
