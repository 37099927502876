import React from 'react';
// libraries
import PropTypes from 'prop-types';
import getProperty from 'lodash/get';
// helpers
import { isExcellentMatch, hasEnoughDataPoints } from 'helpers/MatchMetrics';
// constants
import { SH_EXPERIENCE_LEVEL } from 'constants';
// component
import ScoreComponent from 'components/atoms/Score';
import ExcellentMatchIcon from 'components/molecules/ExcellentMatchIcon';
import { MatchMetricsPopper } from 'components/organisms/MatchMetrics';
// types
import { MetricType } from 'types';

const propTypes = {
  score: PropTypes.number.isRequired,
  candidateName: PropTypes.string.isRequired,
  positionTitle: PropTypes.string.isRequired,
  project: PropTypes.string.isRequired,
  occupations: PropTypes.arrayOf(PropTypes.string).isRequired,
  experienceLevel: PropTypes.oneOf(Object.values(SH_EXPERIENCE_LEVEL)).isRequired,
  metrics: MetricType,
};

const defaultProps = {
  metrics: null,
};

const Score = ({
  score,
  metrics,
  candidateName,
  positionTitle,
  project,
  occupations,
  experienceLevel,
}) => {
  const percentile = getProperty(metrics, 'percentile', 0);
  const count = getProperty(metrics, 'count', 0);
  const displayExcellentMatch = isExcellentMatch(percentile) && hasEnoughDataPoints(count);

  return (
    <MatchMetricsPopper
      placement="right-end"
      metrics={metrics}
      score={score}
      candidateName={candidateName}
      positionTitle={positionTitle}
      project={project}
      occupations={occupations}
      experienceLevel={experienceLevel}
    >
      <ScoreComponent
        score={score}
        icon={displayExcellentMatch ? <ExcellentMatchIcon bordered /> : null}
      />
    </MatchMetricsPopper>
  );
};

Score.propTypes = propTypes;
Score.defaultProps = defaultProps;

export default Score;
