import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// components
import Chip from 'components/atoms/NewChip';
// constants
import { COLOR } from 'constants/colors';

const propTypes = {
  type: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
};

const DraftChip = ({ type, count, label }) => {
  const { t } = useTranslation();

  if (count > 0) {
    return (
      <Link to={`/drafts?filter=${type}`}>
        <Chip color={COLOR.BLUE}>
          {count} {label} {count > 1 ? t('drafts') : t('draft')}
        </Chip>
      </Link>
    );
  }
  return null;
};

DraftChip.propTypes = propTypes;

export default DraftChip;
