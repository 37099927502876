import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
// components
import Link from 'components/atoms/Link';
// constants
import { SUPPORT_EMAIL } from 'constants';
// style
import styles from './errorPage.module.scss';

const propTypes = {
  code: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  description: PropTypes.string,
};

const defaultProps = {
  code: 500,
  title: '',
  description: '',
};

const ErrorPage = ({ code, title, description }) => {
  const { t } = useTranslation();

  const getTitle = () => {
    if (title) {
      return title;
    }

    switch (code) {
      case 401:
        return t('unauthorizedTitle');
      case 404:
        return t('pageNotFound');
      case 500:
        return t('serverError');
      default:
        return '';
    }
  };

  const getDescription = () => {
    if (description) {
      return description;
    }

    switch (code) {
      case 401:
        return t('unauthorizedDescription');
      case 404:
        return t('pageNotFoundDescription');
      case 500:
        return (
          <Trans i18nKey="serverErrorDescription">
            Rest assured we have been notified and are looking into the issue. In the meantime you
            can try to refresh your browser and if the error continues, you may contact our support
            team by sending an email to{' '}
            <Link href={`mailto:${SUPPORT_EMAIL}?subject=TMH server error`}>
              {{ email: SUPPORT_EMAIL }}
            </Link>{' '}
            or by using the help button at the bottom right corner
          </Trans>
        );
      default:
        return '';
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.code}>{code}</div>
        <div className={styles.line} />
        <h3 className={styles.title}>{getTitle()}</h3>
        <p className={styles.description}>{getDescription()}</p>
      </div>
    </div>
  );
};

ErrorPage.propTypes = propTypes;
ErrorPage.defaultProps = defaultProps;

export default ErrorPage;
