import isObject from 'lodash/isObject';
import logger from '@services/logger';

interface UploadDocumentResponse {
  id: string;
}

const isUploadDocumentResponse = (data: unknown): data is UploadDocumentResponse =>
  isObject(data) && 'id' in data;

const uploadDocument = async (file: File) => {
  try {
    const formData = new FormData();
    formData.set('file', file);

    const response = await fetch('/import/api/upload', {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const responseData = await response.json();

    if (!isUploadDocumentResponse(responseData)) {
      throw new Error('Unknown response for upload document');
    }

    return responseData.id;
  } catch (error: unknown) {
    if (error instanceof Error) {
      logger.error(error.message, { error, filename: file.name });
    } else {
      logger.error('Unknown error in upload document', { error, filename: file.name });
    }

    throw error;
  }
};

export default uploadDocument;
