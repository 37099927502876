import React, { useRef, useState, useContext } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import filter from 'lodash/filter';
// constants
import { SUBMISSION_TAB } from 'constants/pageTabs';
import { STAFF_ROLE, SUPPLIER_ROLE, MANAGER_ROLE } from 'constants';
import { POSITION_STATUS } from 'constants/positionStatus';
import { SUBMISSION_STATUS, SUBMISSION_STATUS_TYPE } from 'constants/submissionStatus';
import { ONBOARDING_TAB } from 'constants/onboarding';
// services
import logger from 'services/Logger';
import notification from 'services/Notification';
// contexts
import ToastContext from 'context/ToastContext';
// hooks
import useModalProvider from 'hooks/useModalProvider';
import useIsMounted from 'hooks/useIsMounted';
import useAuth from 'hooks/useAuth';
// helpers
import { getAbsoluteUrl } from 'helpers/Link';
import { isRevisableAsync } from 'helpers/Revision';
import { fetchPositionStatus } from 'helpers/Position';
import { hasPendingOnboardingAction } from 'helpers/Onboarding';
// components
import IconDropdown from 'components/molecules/IconDropdown';
import RequestRevision from 'components/organisms/RequestRevisionModal';
import {
  hasSubmissions,
  showSelectWinner,
  SubmitCandidates,
  SelectWinner,
} from 'components/organisms/PositionSettings';
import RejectSubmission from './RejectSubmission';
import WithdrawSubmission from './WithdrawSubmission';
import ReplaceCV, { commitNewCv } from './ReplaceCV';

const propTypes = {
  submission: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
  actionButton: PropTypes.bool,
  color: PropTypes.string,
  hideSecondaryMenuItems: PropTypes.bool,
};

const defaultProps = {
  actionButton: false,
  color: 'white',
  hideSecondaryMenuItems: false,
};

const MODAL = {
  REJECT: 'reject',
  WITHDRAW: 'withdraw',
  REVISION: 'requestRevision',
  SUBMIT: 'submit',
  REPLACE_CV: 'replaceCV',
  SELECT_WINNER: 'selectWinner',
};

const ITEM_KEYS = {
  REVISION: 'requestRevision',
  REPLACE_CV: 'replaceCV',
  SELECT_WINNER: 'selectWinner',
};

const SubmissionSettings = ({
  submission,
  position,
  actionButton,
  color,
  hideSecondaryMenuItems,
}) => {
  const { openModal, isModalOpen, closeModal } = useModalProvider();
  const { addToast } = useContext(ToastContext);
  const user = useAuth();
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const history = useHistory();
  const menuRef = useRef();

  const [loader, setLoader] = useState({ loading: false, loadingKey: '' });
  const [hasDueDatePassed, setDueDatePassed] = useState(position.isAfterDueDate());

  const { role, id: userId } = user;
  const positionStatus = position.getStatus();
  const submissionStatus = submission.getStatus(role);
  const submissionStatusValue = submissionStatus ? submissionStatus.value : '';

  const redirectTo = url => {
    history.push(url);
  };

  const executeAsync = async (fn, successMsg = '', loadingKey) => {
    setLoader({ loading: true, loadingKey });

    try {
      const response = await fn();

      if (successMsg) {
        addToast.success(successMsg);
      }
      return response;
    } catch (error) {
      addToast.error(t('errorPlaceholderText'));
      logger.exception(error);

      return false;
    } finally {
      if (isMounted()) {
        setLoader({ loading: false, loadingKey: '' });
      }
    }
  };

  const isItemLoading = key => {
    const { loading, loadingKey } = loader;
    return loading && loadingKey === key;
  };

  const handleRevisionClick = async () => {
    const isRevisableResult = await executeAsync(
      () => isRevisableAsync(submission.id),
      '',
      ITEM_KEYS.REVISION
    );

    if (isRevisableResult) {
      openModal(MODAL.REVISION);
      menuRef.current.closeMenu();
      return;
    }

    addToast.warning(t('maximumNumberOfRevisionTypes'));
  };

  const handleReplaceCv = async newReportId => {
    try {
      setLoader({ loading: true, loadingKey: ITEM_KEYS.REPLACE_CV });

      await commitNewCv(submission.id, newReportId, user.supplierId);
      addToast.success(t('replaceCandidateCVSuccess'));

      notification.submissionEdited({
        submission: submission.id,
        link: getAbsoluteUrl(`/submission/${submission.id}`),
        createdBy: userId,
        isProposal: submission.isProposal(),
      });

      // if candidate detail page
      if (actionButton) {
        setLoader({ loading: false, loadingKey: ITEM_KEYS.REPLACE_CV });
        closeModal();
      } else {
        redirectTo(`/submission/${submission.id}`);
      }
    } catch (error) {
      addToast.error(t('errorPlaceholderText'));
    } finally {
      setLoader({ loading: false, loadingKey: ITEM_KEYS.REPLACE_CV });
    }
  };

  const checkPositionDueDate = () => {
    if (position.isAfterDueDate() && !hasDueDatePassed) {
      setDueDatePassed(true);
    }
  };

  const handleViewReportClick = () => {
    if (!submission.report) {
      addToast.error(t('reportNotfound'));
      return;
    }

    history.push(`/report/match/${submission.report.id}`);
  };

  const goToOnboarding = step => {
    const stepQuery = step ? `&step=${step}` : '';

    redirectTo(`/submission/${submission.id}?tab=${SUBMISSION_TAB.ONBOARDING}${stepQuery}`);
  };

  const handleSelectWinnerClick = async () => {
    const actualPositionStatus = await executeAsync(
      () => fetchPositionStatus(position.id),
      null,
      ITEM_KEYS.SELECT_WINNER
    );

    if (actualPositionStatus !== POSITION_STATUS.PENDING_SELECTION) {
      addToast.error(t('positionNotInSelectionState'));
      return;
    }

    menuRef.current.closeMenu();
    openModal(MODAL.SELECT_WINNER);
  };

  const submitToClientItem = {
    icon: 'addPerson',
    id: 'submitToClient',
    onClick: () => openModal(MODAL.SUBMIT),
  };
  const editCandidateItem = {
    icon: 'edit',
    id: 'editSubmission',
    onClick: () => redirectTo(`/candidate/edit/${submission.id}/${position.id}`),
  };
  const replaceCvItem = {
    id: 'replaceCV',
    icon: 'fileReplace',
    label: t('replaceSubmittedCv'),
    onClick: () => openModal(MODAL.REPLACE_CV),
  };

  const getViewMatchReportItem = () => {
    if (hideSecondaryMenuItems) {
      return null;
    }
    return {
      icon: 'analytics',
      id: 'viewMatchReport',
      onClick: handleViewReportClick,
    };
  };

  const getRejectCandidateItem = () => {
    if (submission.isRejectable(role)) {
      return {
        icon: 'cancel',
        id: 'rejectCandidate',
        onClick: () => openModal(MODAL.REJECT),
      };
    }
    return null;
  };

  const getSelectWinnerItem = () => {
    if (showSelectWinner(positionStatus, hasDueDatePassed)) {
      return {
        icon: 'trophy',
        id: 'selectWinner',
        onClick: handleSelectWinnerClick,
        stayOpenAfterClick: true,
        loading: isItemLoading(ITEM_KEYS.WINNER),
      };
    }
    return null;
  };

  const getReviseCandidateItem = () => {
    if (submission.isRevisable(positionStatus.value, role)) {
      return {
        icon: 'revision',
        id: MODAL.REVISION,
        onClick: handleRevisionClick,
        stayOpenAfterClick: true,
        loading: isItemLoading(ITEM_KEYS.REVISION),
      };
    }
    return null;
  };

  const getWithDrawCandidateItem = () => {
    if (submission.isRejectable(role)) {
      return {
        icon: 'cancel',
        id: 'withdrawSubmission',
        onClick: () => openModal(MODAL.WITHDRAW),
      };
    }
    return null;
  };

  const getConfirmStartDateItem = () => {
    if (
      hasPendingOnboardingAction(SUBMISSION_STATUS_TYPE.ONBOARDING_START_DATE, submission, role)
    ) {
      return {
        icon: 'calendar',
        id: 'confirmStartDate',
        onClick: () => goToOnboarding(ONBOARDING_TAB.START_DATE),
      };
    }
    return null;
  };

  const getOnboardingFormsItem = () => {
    if (hasPendingOnboardingAction(SUBMISSION_STATUS_TYPE.ONBOARDING_FORMS, submission, role)) {
      return {
        icon: 'fileUpload',
        id: 'onboardingFormsItem',
        onClick: () => goToOnboarding(ONBOARDING_TAB.FORMS),
      };
    }
    return null;
  };

  const getCreatePoItem = () => {
    if (hasPendingOnboardingAction(SUBMISSION_STATUS_TYPE.ONBOARDING_PO, submission, role)) {
      return {
        icon: 'purchaseOrder',
        id: 'createPO',
        onClick: () => goToOnboarding(ONBOARDING_TAB.PO),
      };
    }
    return null;
  };

  const getConfirmPoItem = () => {
    if (hasPendingOnboardingAction(SUBMISSION_STATUS_TYPE.ONBOARDING_PO, submission, role)) {
      return {
        icon: 'purchaseOrder',
        id: 'confirmPO',
        onClick: () => goToOnboarding(ONBOARDING_TAB.PO),
      };
    }
    return null;
  };

  const renderMenuItems = () => {
    if (submissionStatusValue === SUBMISSION_STATUS.NEW) {
      if (role === MANAGER_ROLE) {
        return filter([getViewMatchReportItem()]);
      }
      if (role === STAFF_ROLE) {
        return filter([
          submitToClientItem,
          getRejectCandidateItem(),
          getReviseCandidateItem(),
          getViewMatchReportItem(),
        ]);
      }
      if (role === SUPPLIER_ROLE) {
        return filter([
          getWithDrawCandidateItem(),
          editCandidateItem,
          replaceCvItem,
          getViewMatchReportItem(),
        ]);
      }
    }

    if (submissionStatusValue === SUBMISSION_STATUS.SUBMITTED) {
      if (role === MANAGER_ROLE) {
        return filter([getViewMatchReportItem()]);
      }
      if (role === STAFF_ROLE) {
        return filter([getSelectWinnerItem(), getRejectCandidateItem(), getViewMatchReportItem()]);
      }
      if (role === SUPPLIER_ROLE) {
        return filter([getWithDrawCandidateItem(), editCandidateItem, getViewMatchReportItem()]);
      }
    }

    if (submissionStatusValue === SUBMISSION_STATUS.OFFER) {
      if (role === MANAGER_ROLE) {
        return filter([getCreatePoItem()]);
      }
      if (role === STAFF_ROLE) {
        return filter([getRejectCandidateItem(), getViewMatchReportItem()]);
      }
      if (role === SUPPLIER_ROLE) {
        return filter([
          getConfirmStartDateItem(),
          getOnboardingFormsItem(),
          editCandidateItem,
          getWithDrawCandidateItem(),
          getViewMatchReportItem(),
        ]);
      }
    }

    if (submissionStatusValue === SUBMISSION_STATUS.ONBOARDING) {
      if (role === MANAGER_ROLE) {
        return filter([getConfirmStartDateItem(), getCreatePoItem()]);
      }
      if (role === STAFF_ROLE) {
        return filter([getConfirmStartDateItem(), getOnboardingFormsItem(), getConfirmPoItem()]);
      }
      if (role === SUPPLIER_ROLE) {
        return filter([getOnboardingFormsItem(), getWithDrawCandidateItem(), editCandidateItem]);
      }
    }

    if (submissionStatusValue === SUBMISSION_STATUS.DELIVERY) {
      if (role === MANAGER_ROLE) {
        return filter([getViewMatchReportItem()]);
      }
      if (role === SUPPLIER_ROLE) {
        return filter([editCandidateItem]);
      }
    }
    return [];
  };

  return (
    <div className="fs-unmask">
      <IconDropdown
        ref={menuRef}
        menuItems={renderMenuItems()}
        actionButton={actionButton}
        iconColor={color}
        onOpen={checkPositionDueDate}
      />
      {role === STAFF_ROLE && (
        <React.Fragment>
          <RequestRevision
            isOpen={isModalOpen(MODAL.REVISION)}
            onClose={closeModal}
            submissionId={submission.id}
            positionId={position.id}
            projectId={position.project.id}
            name={submission.candidate.name}
          />
          <RejectSubmission
            id={submission.id}
            name={submission.candidate.name}
            statusId={submissionStatus.id}
            statusValue={submissionStatus.value}
            isOpen={isModalOpen(MODAL.REJECT)}
            isProposal={false}
            onClose={closeModal}
            onFinish={closeModal}
            position={position}
          />
          <SubmitCandidates
            isOpen={isModalOpen(MODAL.SUBMIT)}
            onClose={closeModal}
            onFinish={() => redirectTo(`/position/${position.id}?tab=TAB_RATES`)}
            positionId={position.id}
            submissionId={submission.id}
          />
          <SelectWinner
            isOpen={isModalOpen(MODAL.SELECT_WINNER)}
            hasSubmissions={hasSubmissions(positionStatus)}
            position={position}
            onClose={closeModal}
          />
        </React.Fragment>
      )}
      {role === SUPPLIER_ROLE && (
        <WithdrawSubmission
          isOpen={isModalOpen(MODAL.WITHDRAW)}
          id={submission.id}
          name={submission.candidate.name}
          status={submissionStatus}
          onClose={closeModal}
          isProposal={false}
        />
      )}
      <ReplaceCV
        isOpen={isModalOpen(MODAL.REPLACE_CV)}
        submitting={isItemLoading(ITEM_KEYS.REPLACE_CV)}
        submissionId={submission.id}
        matchReportId={submission.report.id}
        candidateName={submission.candidate.name}
        candidateId={submission.candidate.id}
        onClose={closeModal}
        onReplace={handleReplaceCv}
      />
    </div>
  );
};

SubmissionSettings.propTypes = propTypes;
SubmissionSettings.defaultProps = defaultProps;

export default SubmissionSettings;
