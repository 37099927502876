// libraries
import unionBy from 'lodash/unionBy';
import hasProperty from 'lodash/has';
import getProperty from 'lodash/get';
// constants
import { POSITION_STATUS } from 'constants/positionStatus';
import { STATUS_STATE } from 'components/molecules/StatusFlow';
// helpers
import { statuses } from './statuses';

const getState = status => {
  if (!hasProperty(status, 'start') && !hasProperty(status, 'end')) {
    return STATUS_STATE.TO_DO;
  }
  if (getProperty(status, 'start') && getProperty(status, 'end')) {
    return STATUS_STATE.FINISHED;
  }
  return STATUS_STATE.ACTIVE;
};

const isStatusCompleted = status => {
  const isFinalStatus = [POSITION_STATUS.CLOSED, POSITION_STATUS.CANCELLED].includes(status.value);
  const state = getState(status);
  // status is finished
  if (state === STATUS_STATE.FINISHED) return true;

  // final and active status are considered finished
  return isFinalStatus && state === STATUS_STATE.ACTIVE;
};

/**
 * Format position statuses for Status flow component
 * @param {object[]} history - status history of position
 * @param {string} role
 * @returns {object[]}
 */
export const formatHistory = history => {
  // hide planned statuses if position is closed or cancelled
  if (
    history.some(status =>
      [POSITION_STATUS.CANCELLED, POSITION_STATUS.CLOSED].includes(status.value)
    )
  ) {
    return history.map(status => {
      return {
        ...status,
        state: getState(status),
        isCompleted: isStatusCompleted(status),
      };
    });
  }

  // add planned statuses
  const historyStatuses = unionBy(history, statuses, 'value');

  return historyStatuses.map(status => {
    return {
      ...status,
      state: getState(status),
      isCompleted: isStatusCompleted(status),
    };
  });
};
