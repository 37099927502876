// select
// selectNoOptions
// selectSchemaOptions
// radio
// levels
import React from 'react';
// Libraries
import i18n from 'i18next';
import PropTypes from 'prop-types';
import getProperty from 'lodash/get';
// Components
import DropdownCheckbox from 'components/molecules/DropdownCheckbox';
import Levels from 'components/atoms/Levels';
import RadioCustom from 'components/molecules/RadioCustom';
import RadioTableOptions from 'components/organisms/RadioTableOptions';
import SelectSchemaOptions from 'components/organisms/SelectSchemaOptions';
import Checkbox from 'components/atoms/Checkbox';
// helpers
import { translateData } from './Helpers';
import { getComponent } from '../FormCustom/Helper';

const propTypes = {
  component: PropTypes.object,
  formData: PropTypes.any,
  form: PropTypes.any,
  updateFormData: PropTypes.func.isRequired,
  updateData: PropTypes.func,
  moreInfo: PropTypes.any,
  disabled: PropTypes.bool,
};

const defaultProps = {
  component: {},
  form: {},
  formData: {},
  moreInfo: {},
  updateData: () => {},
  disabled: false,
};

function Selects(props) {
  const { component, updateFormData, updateData, formData, moreInfo, form, disabled } = props;
  const { type, properties, key, defaultValue } = component;
  const { placeholderKey } = properties || {};
  const placeholder = placeholderKey ? i18n.t(placeholderKey) : null;

  const getSelectedValues = formDataValue => {
    if (formDataValue) {
      return formDataValue;
    }
    if (component.input && defaultValue && defaultValue !== '') {
      return updateFormData(key, defaultValue);
    }
    if (
      type === 'radio' &&
      defaultValue &&
      defaultValue !== '' &&
      component.values.length > 0 &&
      component.values[0].label
    ) {
      updateFormData(key, component.values[parseInt(defaultValue)]);
    }
    return null;
  };

  const getDropdownList = () => {
    if (component.data && component.data.values && component.data.values.length !== 0) {
      return translateData(component.data.values, properties);
    }
    return null;
  };

  const onPreFillChange = value => {
    updateFormData(key, value);
    let newData = { [key]: value };
    if (component.componentsToChange) {
      component.componentsToChange.forEach(compKey => {
        if (value && value.value) {
          const formComponent = getComponent(form, compKey);
          const newValue = getProperty(moreInfo, `${compKey}.${value.value}`, []);
          const requiredError =
            getProperty(formComponent, 'validate.required') && newValue.length === 0;
          newData = {
            ...newData,
            [compKey]: {
              value: newValue,
              requiredError,
            },
          };
        }
      });
    }
    updateData({ ...formData, ...newData }, key);
  };

  switch (type) {
    case 'radio':
      return (
        <RadioCustom
          list={
            component.values && component.values.length !== 0
              ? translateData(component.values, properties)
              : null
          }
          selected={getSelectedValues(formData[key])}
          onChange={value => updateFormData(key, value)}
          name={key}
          disabled={disabled}
        />
      );
    case 'checkbox':
      return (
        <Checkbox
          label={i18n.t(getProperty(properties, 'valueKey'))}
          checked={getProperty(formData, [key, 'value'])}
          onChange={value => updateFormData(key, value)}
        />
      );
    case 'radioTableOptions':
      return (
        <RadioTableOptions
          tableName={component.tableName}
          fieldName={component.fieldName}
          orderBy={component.orderBy}
          selected={getSelectedValues(formData[key])}
          onChange={value => updateFormData(key, value)}
          name={key}
          disabled={disabled}
        />
      );
    case 'select':
      return (
        <DropdownCheckbox
          list={getDropdownList()}
          selected={getSelectedValues(formData[key])}
          onChange={value => updateFormData(key, value)}
          label={placeholder}
          checkedLabel={formData && formData[key] ? formData[key].label : ''}
          name={key}
          disabled={disabled}
        />
      );
    case 'selectPreFill':
      return (
        <DropdownCheckbox
          list={moreInfo[key]}
          selected={getSelectedValues(formData[key])}
          onChange={value => onPreFillChange(value)}
          label={placeholder}
          checkedLabel={formData && formData[key] ? formData[key].label : ''}
          name={key}
          disabled={disabled}
        />
      );
    case 'selectSchemaOptions':
      return (
        <SelectSchemaOptions
          tableName={component.tableName}
          fieldName={component.fieldName}
          selected={getSelectedValues(formData[key])}
          onChange={value => updateFormData(key, value)}
          label={placeholder}
          checkedLabel={formData && formData[key] ? formData[key].label : ''}
          name={key}
          disabled={disabled}
        />
      );
    case 'levels':
      return (
        <Levels
          className="form cp p-t-10"
          list={moreInfo.levels}
          name={key}
          value={getProperty(formData, [key, 'value'])}
          onChange={value => updateData({ ...formData, [key]: { value } }, key)}
          showYears
          disabled={disabled}
        />
      );
    default:
      return null;
  }
}

Selects.propTypes = propTypes;
Selects.defaultProps = defaultProps;

export default Selects;
