// Role names in DB
export const SUPPLIER_ROLE_NAME = 'Supplier';
export const MATCH_MANAGER_ROLE_NAME = 'Match Manager';
export const PROJECT_MANAGER_ROLE_NAME = 'Project Manager';
export const ADMINISTRATOR_ROLE_NAME = 'Administrator';

export const BASIC_ROLE = {
  SUPPLIER: 'SUPPLIER',
  MATCH_MANAGER: 'MATCH_MANAGER',
  PROJECT_MANAGER: 'MANAGER',
};

// System roles
export const SYSTEM_ROLE = {
  BASIC: 'BASIC',
  VISITOR: 'VISITOR',
  ADMIN: 'ADMIN',
  SUPER_ADMIN: 'SUPER_ADMIN',
};

export const BASIC_ROLES = Object.values(BASIC_ROLE);
export const SYSTEM_ROLES = Object.values(SYSTEM_ROLE);
