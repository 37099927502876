/* eslint-disable import/order */
import React, { useContext, useEffect, useState } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// context
import ToastContext from 'context/ToastContext';
// components
import HistoryModal from './HistoryModal';
// constants
import { SUPPORTED_HISTORY_TYPES } from './constants';
// helpers
import { getHistoryFilterByType, getHistoryQueryByType } from './Queries';
// styles
import './historyModal.scss';

const propTypes = {
  type: PropTypes.string,
  itemId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const defaultProps = {
  type: null,
};

const HistoryModalContainer = props => {
  const { type, isOpen, itemId, onClose } = props;
  const { addToast } = useContext(ToastContext);
  const [content, setContent] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!type) {
      setContent(false);
    } else if (type && !SUPPORTED_HISTORY_TYPES.includes(type)) {
      addToast.error(t('notSupportedHistoryType'));
      onClose();
      setContent(false);
    } else {
      setContent(true);
    }
  }, [type]);

  if (!isOpen || !content) {
    return null;
  }

  const query = getHistoryQueryByType(type);
  const variables = getHistoryFilterByType(type, itemId);

  return <HistoryModal query={query} variables={variables} {...props} />;
};

HistoryModalContainer.propTypes = propTypes;
HistoryModalContainer.defaultProps = defaultProps;

export default HistoryModalContainer;
