/* eslint-disable no-unused-expressions */
// libraries
import moment from 'moment';
import getProperty from 'lodash/get';
import hasProperty from 'lodash/has';
import isArray from 'lodash/isArray';
import cloneDeep from 'lodash/cloneDeep';
import flatten from 'lodash/flatten';
// components
import client from 'services/Client';
// queries
import { GET_DRAFT_BY_ID } from 'queries/DraftQueries';
import { UPDATE_IMPORT_GENERIC } from 'queries/ImportQueries';
// constants
import {
  SCROLL_TO_ELEMENT_PREFIX,
  COMPONENT_SKILLS,
  COMPONENT_ACTIVITIES,
  COMPONENT_FILE,
} from 'constants';
import { isComponentRequired, isComponentEmpty, isComponentHidden } from 'helpers/Form';
import fetchImportRecord from './fetchImport';

export const COMPONENTS_WITH_ALLOWED_POINTER = [COMPONENT_FILE];

/**
 *
 * @param {Object} formData - fetched formData
 * @param {Array} surveyComponents - components to be reformatted from array to object {value: data }
 */
const formatSurveyItems = (formData, surveyComponents = []) => {
  if (!formData) return formData;

  const formDataCopy = cloneDeep(formData);

  surveyComponents.forEach(componentKey => {
    const componentValue = getProperty(formData, componentKey);
    if (isArray(componentValue)) {
      formDataCopy[componentKey] = {
        value: componentValue,
        requiredError: componentValue.length === 0,
      };
    }
  });
  return formDataCopy;
};

const formatDates = (formData, dateComponents = []) => {
  return dateComponents.reduce((prev, key) => {
    const value = hasProperty(formData, [key, 'value']) ? formData[key].value : formData[key];

    if (value) {
      return {
        ...prev,
        [key]: {
          value: moment(value),
        },
      };
    }

    return prev;
  }, {});
};

const formatRates = (formData, rateComponents = []) => {
  return rateComponents.reduce((prev, key) => {
    const rates = hasProperty(formData, [key, 'value']) ? formData[key].value : formData[key];

    if (rates) {
      return {
        ...prev,
        [key]: {
          value: isArray(rates) ? rates : Object.values(rates),
        },
      };
    }

    return prev;
  }, {});
};

/**
 * Pre-fill form with saved draft
 * @param {string} id
 * @param {string[]} params.dateComponents Form date components key
 * @param {string[]} params.rateComponents Form rate components key
 */
export const loadDraft = async (id, { dateComponents, rateComponents }) => {
  const results = await client.query({
    query: GET_DRAFT_BY_ID,
    variables: { id },
  });

  const draft = getProperty(results, 'data.draft.draft');
  const formattedDraft = formatSurveyItems(draft, [COMPONENT_SKILLS, COMPONENT_ACTIVITIES]);
  const formattedDates = formatDates(draft, dateComponents);
  const formattedRates = formatRates(draft, rateComponents);

  return { ...formattedDraft, ...formattedDates, ...formattedRates };
};

export const updateImport = (importId, json) => {
  return client.mutate({
    mutation: UPDATE_IMPORT_GENERIC,
    variables: {
      importId,
      data: {
        data: json,
      },
    },
  });
};

/**
 * Pre-fill form with saved import
 * @param {string} id
 * @param {object} params
 * @param {string[]} params.dateComponents Form date components key
 * @param {string[]} params.rateComponents Form rate components key
 */
export const loadImport = async (id, { dateComponents, rateComponents }) => {
  const importData = await fetchImportRecord(id);
  const formattedImport = formatSurveyItems(importData, [COMPONENT_SKILLS, COMPONENT_ACTIVITIES]);
  const formattedDates = formatDates(importData, dateComponents);
  const formattedRates = formatRates(importData, rateComponents);

  return {
    ...formattedImport,
    ...formattedDates,
    ...formattedRates,
  };
};

export const getFormVariables = (type, projectId) => {
  return {
    filter: {
      OR: [
        { AND: [{ type: { equals: type } }, { project: { id: { equals: projectId } } }] },
        { AND: [{ type: { equals: type } }, { isFormDefault: { equals: true } }] },
      ],
    },
  };
};

const getFormInfo = fullForm => {
  const { form, id, enabledFields } = fullForm;
  return { formId: id, form, enabledFields: enabledFields || [] };
};

export const getForm = data => {
  if (data.formsList && data.formsList.count > 0) {
    const { items, count } = data.formsList;
    if (count === 1) {
      return getFormInfo(items[0]);
    }
    const form = items.find(formIO => !formIO.isFormDefault);
    return form ? getFormInfo(form) : null;
  }
  return null;
};

const isPanelConditional = (component, formData) => {
  return (
    formData &&
    formData[component.conditional.when] &&
    formData[component.conditional.when].value === component.conditional.eq
  );
};

const isPanelVisible = (panel, formData) => {
  const referencedComponent = getProperty(panel, 'conditional.when');
  const conditionValue = getProperty(panel, 'conditional.eq');
  const showPanel = getProperty(panel, 'conditional.show');
  const componentValue = getProperty(formData, [referencedComponent, 'value']);

  // if referenced component is not defined panel is always visible
  if (!referencedComponent) return true;

  // panel is visible if value defined in form is equal to one in form data
  if (showPanel) {
    return componentValue === conditionValue;
  }

  // panel is visible if value defined in form is NOT equal to one in form data
  return componentValue !== conditionValue;
};

export const getPanels = (form, data) => {
  return form && form.components
    ? form.components.filter(
        component =>
          component.type === 'panel' &&
          (!component.conditional.when || !!isPanelConditional(component, data))
      )
    : [];
};

export const formatFormStructure = form => {
  let structure = {};
  form &&
    form.components &&
    form.components.forEach(panel => {
      if (panel.type === 'panel') {
        panel.components.forEach(component => {
          if (component.input) {
            structure = {
              ...structure,
              [component.key]:
                component.defaultValue &&
                component.defaultValue !== '' &&
                component.type === 'number'
                  ? { value: component.defaultValue, error: false }
                  : undefined,
            };
          }
        });
      }
    });
  return structure;
};

export const getRequiredFields = (form, formData) => {
  let requiredFields = {};
  const panels = getProperty(form, 'components', []);

  panels.forEach(panel => {
    if (panel.type === 'panel' && isPanelVisible(panel, formData)) {
      const fields = panel.components.filter(component => isComponentRequired(component, formData));
      const fieldKeys = fields.map(component => component.key);

      requiredFields = {
        ...requiredFields,
        [panel.key]: [...fieldKeys],
      };
    }
  });

  return requiredFields;
};

export const getComponent = (form, componentKey) => {
  let component = null;
  getProperty(form, 'components', []).forEach(comp => {
    getProperty(comp, 'components', []).forEach(formComponent => {
      if (formComponent.key === componentKey) {
        component = formComponent;
      }
    });
  });
  return component;
};

export const getMissingRequiredFields = (
  form,
  data,
  requiredFields,
  customComponents = {},
  disabledFields = []
) => {
  let missingRequiredFields = {};

  Object.keys(requiredFields).forEach(panelKey => {
    missingRequiredFields = {
      ...missingRequiredFields,
      [panelKey]: requiredFields[panelKey].filter(reqField => {
        const component = getComponent(form, reqField);

        if (disabledFields.includes(component.key)) {
          return false;
        }

        if (hasProperty(customComponents, [reqField, 'hasError'])) {
          const { hasError } = customComponents[reqField];
          const value = getProperty(data, [reqField, 'value']);

          return hasError(value, data, true);
        }

        return (
          isComponentRequired(component, data) &&
          isComponentEmpty(data, component.key) &&
          !isComponentHidden(component, data)
        );
      }),
    };
  });

  const panels = getProperty(form, 'components', []);
  panels.forEach(panel => {
    if (panel.type === 'panel' && isPanelVisible(panel, data)) {
      const components = getProperty(panel, 'components', []);
      components.forEach(component => {
        if (disabledFields.includes(component.key)) {
          return;
        }

        if (hasProperty(customComponents, [component.key, 'hasError'])) {
          const { hasError } = customComponents[component.key];
          const value = getProperty(data, [component.key, 'value']);

          const isFieldRequired = flatten(Object.values(requiredFields)).includes(component.key);
          if (hasError(value, data, isFieldRequired)) {
            missingRequiredFields[panel.key] = missingRequiredFields[panel.key] || [];

            if (!missingRequiredFields[panel.key].includes(component.key)) {
              missingRequiredFields[panel.key].push(component.key);
            }
          }
        }
      });
    }
  });

  return missingRequiredFields;
};

/**
 * @param {string} key - form element key
 */
export const scrollToElement = key => {
  const elements = document.getElementsByClassName(`${SCROLL_TO_ELEMENT_PREFIX}${key}`);
  const requiredElement = getProperty(elements, '0');

  requiredElement &&
    requiredElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
};
