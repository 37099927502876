import { gql } from '@apollo/client';
import getProperty from 'lodash/get';
import moment from 'moment';
// services
import client from 'services/Client';
import logger from 'services/Logger';
// queries
import { Rate, RevisionStatus, SubmissionRole } from 'queries/Fragments';
// helpers
import { getOpenRevisionTypes } from 'helpers/Revision';
import parseSubmission from 'helpers/SubmissionParser';
// constants
import { RATES_SUPPLIER_RATE } from 'constants';

const FETCH_SUBMISSION = gql`
  query fetchSubmission($id: ID!) {
    submission(id: $id) {
      id
      profile {
        id
        fullName
      }
      candidate {
        id
        supplier {
          id
          category
          includeCSAFee
        }
      }
      rates {
        items {
          ...Rate
        }
      }
      submissionRoles {
        items {
          ...SubmissionRole
        }
      }
      revisions {
        items {
          id
          statuses {
            items {
              ...RevisionStatus
            }
          }
          types {
            items {
              id
              type
            }
          }
        }
      }
    }
  }
  ${Rate}
  ${SubmissionRole}
  ${RevisionStatus}
`;

const fetchSubmission = async id => {
  try {
    const response = await client.query({ query: FETCH_SUBMISSION, variables: { id } });
    const submission = parseSubmission(response.data.submission);

    const { candidate, partner, revisions } = submission;
    const partnerRates = submission.getRates(RATES_SUPPLIER_RATE);
    const startYear = moment(getProperty(partnerRates, '0.startDate')).year();
    const currency = getProperty(partnerRates, '0.currency');
    const revisionTypes = getOpenRevisionTypes(revisions);

    return {
      name: candidate.name,
      revisionTypes,
      partnerCategory: partner.category,
      includeCSAFee: partner.includeCSAFee,
      partnerRates: partnerRates.map(rate => rate.value),
      rates: {
        startYear,
        currency,
        numberOfYears: partnerRates.length,
      },
      submittedBy: {
        name: submission.submittedBy.name,
        email: submission.submittedBy.email,
      },
    };
  } catch (error) {
    logger.exception(error, { id });
    throw error;
  }
};

export default fetchSubmission;
