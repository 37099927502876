import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import shortid from 'shortid';
// components
import Button from 'components/atoms/Button';
import LinkButton from 'components/atoms/LinkButton';
import TextField from 'components/atoms/TextField';
// styles
import './textFieldList.scss';

const propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  showErrors: PropTypes.bool,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      error: PropTypes.string,
    })
  ).isRequired,
};

const defaultProps = {
  label: '',
  placeholder: '',
  required: false,
  showErrors: true,
};

const TextFieldList = React.memo(
  ({ label, placeholder, required, fields, onChange, showErrors }) => {
    const { t } = useTranslation();

    // Assign id to each field
    const fieldsWithId = fields.map(field => {
      if (!field.id) {
        return { ...field, id: shortid.generate() };
      }

      return field;
    });

    const handleChange = (id, value) => {
      const newFields = fieldsWithId.map(field => {
        if (field.id === id) {
          return {
            ...field,
            value,
          };
        }

        return field;
      });

      onChange(newFields);
    };

    const handleAddClick = () => {
      onChange([...fieldsWithId, { value: '', error: '', id: shortid.generate() }]);
    };

    const handleRemoveClick = id => {
      const newFields = fieldsWithId.filter(field => field.id !== id);

      onChange(newFields);
    };

    return (
      <React.Fragment>
        <div className="textFieldList">
          {fieldsWithId.map(({ value, error, id }, index) => (
            <div className="textFieldList_row" key={id}>
              <TextField
                label={index === 0 ? label : ''}
                required={required}
                placeholder={placeholder}
                value={value}
                error={showErrors ? error : ''}
                onChange={input => handleChange(id, input)}
              />
              {fieldsWithId.length > 1 && (
                <div className="m-l-5">
                  <Button
                    tooltip={t('remove')}
                    type="close"
                    onClick={() => handleRemoveClick(id)}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
        <LinkButton onClick={handleAddClick}>{t('addEmail')}</LinkButton>
      </React.Fragment>
    );
  }
);

TextFieldList.propTypes = propTypes;
TextFieldList.defaultProps = defaultProps;

export default TextFieldList;
