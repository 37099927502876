import React, { useContext } from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// components
import Icon from 'components/atoms/Icon';
import ClickableElement from 'components/atoms/ClickableElement';
// context
import CloseContext from './CloseContext';
// styles
import styles from './modal.module.scss';

const propTypes = {
  children: PropTypes.any.isRequired,
  hideCloseIcon: PropTypes.bool,
  error: PropTypes.bool,
  className: PropTypes.string,
};

const defaultProps = {
  hideCloseIcon: false,
  error: false,
  className: '',
};

const ModalHeader = React.memo(({ children, error, hideCloseIcon, className }) => {
  const onClose = useContext(CloseContext);

  return (
    <div className={classNames(styles.header, className, { [styles.error]: error })}>
      <div className={styles.title}>{children}</div>
      {!hideCloseIcon && (
        <ClickableElement className={styles.closeIcon} onClick={onClose}>
          <Icon name="close" color="#fff" size={18} />
        </ClickableElement>
      )}
    </div>
  );
});

ModalHeader.defaultProps = defaultProps;
ModalHeader.propTypes = propTypes;

export default ModalHeader;
