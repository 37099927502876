import moment from 'moment';
import i18next from 'i18next';
// helpers
import { getDayLabel, getTimeYear } from 'helpers/RelativeTimeFormatter';

export const formatDateText = date => {
  const today = moment();
  const tomorrow = moment().add(1, 'day');
  const formattedDate = getTimeYear(date);
  const formattedTime = moment(date).format('HH:mm [(UTC]Z[)]');

  if (moment(date).isSame(today, 'day')) {
    return `${i18next.t('today')} (${formattedDate}) at ${formattedTime}`;
  }
  if (moment(date).isSame(tomorrow, 'day')) {
    return `${i18next.t('tomorrow')} (${formattedDate}) at ${formattedTime}`;
  }

  return `on ${getDayLabel(date)}, ${formattedDate} at ${formattedTime}`;
};
