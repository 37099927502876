/* eslint-disable import/order */
import React, { useRef } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// constants
import { MIN_BUSINESS_GP_REQUIREMENT, DEFAULT_IBM_FEE } from 'constants/rates';
import { SUPPLIER_DIRECT, SUPPLIER_IBM, SUPPLIER_NOT_DIRECT } from 'constants';
// helpers
import { ceilNumber } from 'helpers/Number';
import { getCurrencyText } from 'helpers/NameMapping';
import {
  calculateCandidateGPsFromSellPrice,
  calculateCandidateGPFromSellPrice,
  calculateCandidateSellPriceFromGP,
} from 'helpers/RatesCalculation';
import { isDirectSupplier as isDirectSupplierHelper } from 'helpers/Submission';
import { getErrors, initCandidateSellPrices } from '../helpers';
// components
import RateInput from 'components/molecules/RateInput';
import RateText from 'components/molecules/RateText';
import Errors from '../Errors';
// styles
import styles from '../ratesSimulation.module.scss';

const propTypes = {
  nteRates: PropTypes.arrayOf(PropTypes.number).isRequired,
  originPartnerRates: PropTypes.arrayOf(PropTypes.number).isRequired,
  partnerRates: PropTypes.arrayOf(PropTypes.number).isRequired,
  sellPrices: PropTypes.arrayOf(PropTypes.number).isRequired,
  initialSellPrices: PropTypes.arrayOf(PropTypes.number).isRequired,
  ibmGPs: PropTypes.arrayOf(PropTypes.number).isRequired,
  onPartnerRatesChange: PropTypes.func.isRequired,
  setSellPrices: PropTypes.func.isRequired,
  setIbmGPs: PropTypes.func.isRequired,
  partnerCategory: PropTypes.oneOf([SUPPLIER_DIRECT, SUPPLIER_IBM, SUPPLIER_NOT_DIRECT]).isRequired,
  includeCSAFee: PropTypes.bool.isRequired,
  currency: PropTypes.string.isRequired,
  manYearDiscount: PropTypes.number.isRequired,
  years: PropTypes.arrayOf(PropTypes.number).isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const Rates = ({
  partnerCategory,
  includeCSAFee,
  currency,
  years,
  manYearDiscount,
  nteRates,
  originPartnerRates,
  partnerRates,
  sellPrices,
  setSellPrices,
  initialSellPrices,
  onPartnerRatesChange,
  ibmGPs,
  setIbmGPs,
  className,
}) => {
  const { t } = useTranslation();
  const isDirectSupplier = isDirectSupplierHelper(partnerCategory);
  const initialPartnerRates = useRef(partnerRates);

  const handlePartnerRateChange = (index, value) => {
    const newPartnerRates = partnerRates.map((rate, rateIndex) =>
      rateIndex !== index ? rate : value
    );

    onPartnerRatesChange(newPartnerRates);
    const newSellPrices = sellPrices.map((sellPrice, priceIndex) =>
      priceIndex === index
        ? ceilNumber(
            calculateCandidateSellPriceFromGP(
              newPartnerRates[index],
              DEFAULT_IBM_FEE,
              manYearDiscount,
              isDirectSupplier,
              includeCSAFee
            )
          )
        : sellPrice
    );
    setSellPrices(newSellPrices);
    const newIbmGPs = ibmGPs.map((ibmGP, ibmGPIndex) =>
      ibmGPIndex === index
        ? calculateCandidateGPFromSellPrice(
            newPartnerRates[index],
            newSellPrices[index],
            manYearDiscount,
            isDirectSupplier,
            includeCSAFee
          )
        : ibmGP
    );
    setIbmGPs(newIbmGPs);
  };

  const handleSellPriceChange = (index, value) => {
    const newSellPrices = sellPrices.map((price, priceIndex) =>
      priceIndex === index ? value : price
    );

    setSellPrices(newSellPrices);
    const newIbmGPs = ibmGPs.map((ibmGP, ibmGPIndex) =>
      ibmGPIndex === index
        ? calculateCandidateGPFromSellPrice(
            partnerRates[index],
            newSellPrices[index],
            manYearDiscount,
            isDirectSupplier,
            includeCSAFee
          )
        : ibmGP
    );
    setIbmGPs(newIbmGPs);
  };

  const onUseForAllPartnerRate = value => {
    const newPartnerRates = Array(partnerRates.length).fill(value);
    onPartnerRatesChange(newPartnerRates);
    const newSellPrices = initCandidateSellPrices(
      newPartnerRates,
      manYearDiscount,
      isDirectSupplier,
      includeCSAFee
    );
    setSellPrices(newSellPrices);
    setIbmGPs(
      calculateCandidateGPsFromSellPrice(
        newPartnerRates,
        newSellPrices,
        manYearDiscount,
        isDirectSupplier,
        includeCSAFee
      )
    );
  };

  const onUseForAllSellPrice = value => {
    const newSellPrices = Array(sellPrices.length).fill(value);
    setSellPrices(newSellPrices);
    setIbmGPs(
      calculateCandidateGPsFromSellPrice(
        partnerRates,
        newSellPrices,
        manYearDiscount,
        isDirectSupplier,
        includeCSAFee
      )
    );
  };

  const errors = getErrors({
    nteRates,
    targetRates: partnerRates,
    sellPrices,
    ibmGPs,
    isProposal: false,
  });

  return (
    <div className={className}>
      <div className={classNames(styles.candidateTable)}>
        <div className={styles.title} />
        <div className={classNames(styles.title, styles.endAlignment)}>{t('clientNte')}</div>
        <div className={classNames(styles.title, styles.endAlignment)}>{t('partnerRate')}</div>
        <div className={classNames(styles.title, styles.endAlignment)}>{t('proposeNewRates')}</div>
        <div className={classNames(styles.title, styles.endAlignment)}>{t('sellPriceLabel')}</div>
        <div className={classNames(styles.title, styles.endAlignment)}>{t('dgp')}</div>

        {years.map((year, index) => {
          const nteRate = nteRates[index];
          const originPartnerRate = originPartnerRates[index];
          const partnerRate = partnerRates[index];
          const sellPrice = sellPrices[index];
          const dGP = ibmGPs[index];

          return (
            <React.Fragment key={year}>
              <div className={classNames(styles.title, 'p-l-0 verticalCenter flexStart')}>
                {year}
              </div>
              <RateText currency={getCurrencyText(currency)} value={nteRate} />
              <RateText currency={getCurrencyText(currency)} value={originPartnerRate} />
              <RateInput
                currency={getCurrencyText(currency)}
                value={partnerRate}
                onChange={val => handlePartnerRateChange(index, val)}
                error={nteRate && partnerRate > nteRate}
                errorMessage={t('rateExceedClientNTE')}
                highlighted={partnerRate !== initialPartnerRates.current[index]}
                useForAllPopoverStyle
                onUseForAll={onUseForAllPartnerRate}
              />
              <RateInput
                currency={getCurrencyText(currency)}
                value={sellPrice}
                onChange={val => handleSellPriceChange(index, val)}
                error={nteRate && sellPrice > nteRate}
                errorMessage={t('sellPriceCantBeHigherThanNTE')}
                highlighted={sellPrice !== initialSellPrices[index]}
                useForAllPopoverStyle
                onUseForAll={onUseForAllSellPrice}
              />
              <RateText
                currency="%"
                className="m-l-4"
                value={dGP}
                error={dGP < MIN_BUSINESS_GP_REQUIREMENT}
                errorMessage={t('dgpIsBelowMinimumRequirement', {
                  minimumGP: MIN_BUSINESS_GP_REQUIREMENT,
                })}
              />
            </React.Fragment>
          );
        })}
      </div>
      <Errors errors={errors} className={styles.errorContainer} />
    </div>
  );
};

Rates.propTypes = propTypes;
Rates.defaultProps = defaultProps;

export default Rates;
