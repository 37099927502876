import React from 'react';
// libraries
import { useTranslation } from 'react-i18next';
// components
import Resolution from './Resolution';

const DeliveryActions = ({ ...resolutionProps }) => {
  const { t } = useTranslation();

  return (
    <Resolution
      {...resolutionProps}
      label={t('resolutionDelivery')}
      tooltip={t('tooltipResolutionDelivery')}
    />
  );
};

export default DeliveryActions;
