import React from 'react';
import { Accept } from 'react-dropzone';
import FilesList from './FilesList';
import Dropzone from './Dropzone';
import { BaseFile, UploadedFile, DroppedFile } from './types';

interface FileUploadProps {
  accept?: Accept;
  multiple?: boolean;
  disabled?: boolean;
  files?: (DroppedFile | UploadedFile)[];
  onUpload: (files: DroppedFile[]) => void;
  onRemove?: (file: BaseFile) => void;
}

const FileUpload = ({
  accept,
  multiple = false,
  disabled = false,
  files = [],
  onUpload,
  onRemove = () => {},
}: FileUploadProps) => (
  <div>
    <Dropzone accept={accept} multiple={multiple} disabled={disabled} onDrop={onUpload} />
    <FilesList files={files} onRemove={onRemove} disabled={disabled} />
  </div>
);

export default FileUpload;
