import React from 'react';
import PropTypes from 'prop-types';
import { getTimeYear } from 'helpers/RelativeTimeFormatter';

const propTypes = {
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.any,
  note: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
  subHeader: null,
};

const Header = ({ header, subHeader, note, author, createdAt, className }) => {
  return (
    <div className="revision-header">
      <div className={`revision-header_header p-10 ${className}`}>{header}</div>
      {subHeader}
      <div className="revision-header_container">
        <div className="revision-header_quote">”</div>
        <div className="revision-header_note-container">
          <div className="revision-header_note p-b-10">{note}</div>
          <div className="revision-header_author">
            <b>{author}</b>&nbsp;
            {createdAt && getTimeYear(createdAt)}
          </div>
        </div>
      </div>
    </div>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
