import { STAFF_ROLE, SUPPLIER_ROLE, MANAGER_ROLE } from 'constants';
import { SUBMISSION_FILTERS, SUBMISSION_STATUS_FILTERS, DUE_DATE_FILTERS } from 'constants/filters';

const DUE_DATE_FAMILY = Object.values(DUE_DATE_FILTERS);
const STATUS_FAMILY = Object.values(SUBMISSION_STATUS_FILTERS);

const FILTER_COMBINATIONS = {
  [DUE_DATE_FILTERS.DUE_TODAY]: [
    ...DUE_DATE_FAMILY,
    SUBMISSION_STATUS_FILTERS.NEW,
    SUBMISSION_STATUS_FILTERS.SUBMITTED_TO_CLIENT,
    SUBMISSION_STATUS_FILTERS.NOT_SUBMITTED_TO_CLIENT,
    SUBMISSION_STATUS_FILTERS.WITHDRAWN,
  ],
  [DUE_DATE_FILTERS.DUE_TOMORROW]: [
    ...DUE_DATE_FAMILY,
    SUBMISSION_STATUS_FILTERS.NEW,
    SUBMISSION_STATUS_FILTERS.SUBMITTED_TO_CLIENT,
    SUBMISSION_STATUS_FILTERS.NOT_SUBMITTED_TO_CLIENT,
    SUBMISSION_STATUS_FILTERS.WITHDRAWN,
  ],
  [DUE_DATE_FILTERS.DUE_NEXT_7_DAYS]: [
    ...DUE_DATE_FAMILY,
    SUBMISSION_STATUS_FILTERS.NEW,
    SUBMISSION_STATUS_FILTERS.SUBMITTED_TO_CLIENT,
    SUBMISSION_STATUS_FILTERS.NOT_SUBMITTED_TO_CLIENT,
    SUBMISSION_STATUS_FILTERS.WITHDRAWN,
  ],
  [DUE_DATE_FILTERS.EXPIRED]: [
    ...DUE_DATE_FAMILY,
    ...STATUS_FAMILY.filter(status => status !== SUBMISSION_STATUS_FILTERS.NEW),
  ],
  [DUE_DATE_FILTERS.NOT_EXPIRED]: [
    ...DUE_DATE_FAMILY,
    SUBMISSION_STATUS_FILTERS.NEW,
    SUBMISSION_STATUS_FILTERS.SUBMITTED_TO_CLIENT,
    SUBMISSION_STATUS_FILTERS.NOT_SUBMITTED_TO_CLIENT,
    SUBMISSION_STATUS_FILTERS.WITHDRAWN,
  ],
  [SUBMISSION_STATUS_FILTERS.NEW]: [
    ...STATUS_FAMILY,
    ...DUE_DATE_FAMILY.filter(dueDate => dueDate !== DUE_DATE_FILTERS.EXPIRED),
  ],
  [SUBMISSION_STATUS_FILTERS.SUBMITTED_TO_CLIENT]: [...STATUS_FAMILY, ...DUE_DATE_FAMILY],
  [SUBMISSION_STATUS_FILTERS.NOT_SUBMITTED_TO_CLIENT]: [...STATUS_FAMILY, ...DUE_DATE_FAMILY],
  [SUBMISSION_STATUS_FILTERS.NOT_SELECTED_BY_CLIENT]: [...STATUS_FAMILY, DUE_DATE_FILTERS.EXPIRED],
  [SUBMISSION_STATUS_FILTERS.OFFER]: [...STATUS_FAMILY, DUE_DATE_FILTERS.EXPIRED],
  [SUBMISSION_STATUS_FILTERS.ONBOARDING]: [...STATUS_FAMILY, DUE_DATE_FILTERS.EXPIRED],
  [SUBMISSION_STATUS_FILTERS.PENDING_START_DATE]: [...STATUS_FAMILY, DUE_DATE_FILTERS.EXPIRED],
  [SUBMISSION_STATUS_FILTERS.CONFIRMED_START_DATE]: [...STATUS_FAMILY, DUE_DATE_FILTERS.EXPIRED],
  [SUBMISSION_STATUS_FILTERS.PENDING_FORMS]: [...STATUS_FAMILY, DUE_DATE_FILTERS.EXPIRED],
  [SUBMISSION_STATUS_FILTERS.PENDING_PO]: [...STATUS_FAMILY, DUE_DATE_FILTERS.EXPIRED],
  [SUBMISSION_STATUS_FILTERS.ONBOARDING_COMPLETED]: [...STATUS_FAMILY, DUE_DATE_FILTERS.EXPIRED],
  [SUBMISSION_STATUS_FILTERS.ONBOARDING_CANCELLED]: [...STATUS_FAMILY, DUE_DATE_FILTERS.EXPIRED],
  [SUBMISSION_STATUS_FILTERS.DELIVERY]: [...STATUS_FAMILY, DUE_DATE_FILTERS.EXPIRED],
  [SUBMISSION_STATUS_FILTERS.WITHDRAWN]: [...STATUS_FAMILY, ...DUE_DATE_FAMILY],
};

const FILTER_COMBINATIONS_PM = {
  [DUE_DATE_FILTERS.DUE_TODAY]: [
    ...DUE_DATE_FAMILY,
    SUBMISSION_STATUS_FILTERS.NEW,
    SUBMISSION_STATUS_FILTERS.NOT_SELECTED_BY_CLIENT,
  ],
  [DUE_DATE_FILTERS.DUE_TOMORROW]: [
    ...DUE_DATE_FAMILY,
    SUBMISSION_STATUS_FILTERS.NEW,
    SUBMISSION_STATUS_FILTERS.NOT_SELECTED_BY_CLIENT,
  ],
  [DUE_DATE_FILTERS.DUE_NEXT_7_DAYS]: [
    ...DUE_DATE_FAMILY,
    SUBMISSION_STATUS_FILTERS.NEW,
    SUBMISSION_STATUS_FILTERS.NOT_SELECTED_BY_CLIENT,
  ],
  [DUE_DATE_FILTERS.EXPIRED]: [...DUE_DATE_FAMILY, ...STATUS_FAMILY],
  [DUE_DATE_FILTERS.NOT_EXPIRED]: [
    ...DUE_DATE_FAMILY,
    SUBMISSION_STATUS_FILTERS.NEW,
    SUBMISSION_STATUS_FILTERS.NOT_SELECTED_BY_CLIENT,
  ],
  [SUBMISSION_STATUS_FILTERS.NEW]: [
    ...STATUS_FAMILY,
    ...DUE_DATE_FAMILY.filter(dueDate => dueDate !== DUE_DATE_FILTERS.EXPIRED),
  ],
  [SUBMISSION_STATUS_FILTERS.NOT_SELECTED_BY_CLIENT]: [...STATUS_FAMILY, ...DUE_DATE_FAMILY],
  [SUBMISSION_STATUS_FILTERS.OFFER]: [...STATUS_FAMILY, DUE_DATE_FILTERS.EXPIRED],
  [SUBMISSION_STATUS_FILTERS.ONBOARDING]: [...STATUS_FAMILY, DUE_DATE_FILTERS.EXPIRED],
  [SUBMISSION_STATUS_FILTERS.PENDING_START_DATE]: [...STATUS_FAMILY, DUE_DATE_FILTERS.EXPIRED],
  [SUBMISSION_STATUS_FILTERS.CONFIRMED_START_DATE]: [...STATUS_FAMILY, DUE_DATE_FILTERS.EXPIRED],
  [SUBMISSION_STATUS_FILTERS.PENDING_FORMS]: [...STATUS_FAMILY, DUE_DATE_FILTERS.EXPIRED],
  [SUBMISSION_STATUS_FILTERS.PENDING_PO]: [...STATUS_FAMILY, DUE_DATE_FILTERS.EXPIRED],
  [SUBMISSION_STATUS_FILTERS.ONBOARDING_COMPLETED]: [...STATUS_FAMILY, DUE_DATE_FILTERS.EXPIRED],
  [SUBMISSION_STATUS_FILTERS.ONBOARDING_CANCELLED]: [...STATUS_FAMILY, DUE_DATE_FILTERS.EXPIRED],
  [SUBMISSION_STATUS_FILTERS.DELIVERY]: [...STATUS_FAMILY, DUE_DATE_FILTERS.EXPIRED],
};

export const FILTER_COMBINATION_MAP = {
  [STAFF_ROLE]: FILTER_COMBINATIONS,
  [SUPPLIER_ROLE]: FILTER_COMBINATIONS,
  [MANAGER_ROLE]: FILTER_COMBINATIONS_PM,
};

export const FILTERS_WITHOUT_CONDITIONS = [
  SUBMISSION_FILTERS.PROJECT,
  SUBMISSION_FILTERS.LOCATION,
  SUBMISSION_FILTERS.PARTNER,
];
