import React, { useState, useMemo } from 'react';
// libraries
import PropTypes from 'prop-types';
// hooks
import useDebounce from 'hooks/useDebounce';
// components
import PageHeader from 'components/molecules/PageHeader';
// context
import { PageFiltersProvider } from 'context/PageFiltersContext';

const propTypes = {
  className: PropTypes.string,
  content: PropTypes.any,
  noClearFilters: PropTypes.bool,
  noSearch: PropTypes.bool,
  defaultSort: PropTypes.object,
};

const defaultProps = {
  className: '',
  content: null,
  noClearFilters: false,
  noSearch: false,
  defaultSort: null,
};

const Page = ({ className, noSearch, noClearFilters, defaultSort, content, ...props }) => {
  const [searchText, setSearchText] = useState('');
  const [sortedBy, setSortBy] = useState(defaultSort);

  const debouncedSearchText = useDebounce(searchText, 400);
  const memoizedFilters = useMemo(() => ({ searchText: debouncedSearchText, sortedBy }), [
    debouncedSearchText,
    sortedBy,
  ]);

  const clearFilters = () => {
    setSearchText('');
    setSortBy(null);
  };

  return (
    <div className={className}>
      <PageFiltersProvider value={memoizedFilters}>
        <PageHeader
          clearFilters={noClearFilters ? null : clearFilters}
          clearSearch={noSearch ? null : () => setSearchText('')}
          handleSearch={noSearch ? null : value => setSearchText(value)}
          updateSortedBy={value => setSortBy(value)}
          searchText={searchText}
          {...props}
        />
        {content}
      </PageFiltersProvider>
    </div>
  );
};

Page.propTypes = propTypes;
Page.defaultProps = defaultProps;

export default Page;
