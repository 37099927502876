import { gql } from '@apollo/client';
import { LIMIT_SIZE_TABLE } from 'constants';
import { projectFragment } from './ProjectQueries';

export const GET_CLIENTS = gql`
  query getClients(
    $filter: ClientFilter
    $first: Int!
    $skip: Int!
    $projectFilter: ProjectFilter
  ) {
    clientsList(first: $first, skip: $skip, filter: $filter, orderBy: [name_ASC]) {
      items {
        id
        name
        description
        projects(filter: $projectFilter) {
          count
          items {
            ...Project
          }
        }
      }
      count
    }
  }
  ${projectFragment}
`;

export const CREATE_CLIENT = gql`
  mutation createClient($name: String!, $description: String) {
    clientCreate(data: { name: $name, description: $description }) {
      id
    }
  }
`;

export const DELETE_CLIENT = gql`
  mutation deleteClient($id: ID!) {
    clientDelete(filter: { id: $id }) {
      success
    }
  }
`;

export const GET_CLIENT_BY_PROJECT = gql`
  query getClientByProject($id: ID!) {
    project(id: $id) {
      id
      name
      client {
        id
        name
        config
      }
    }
  }
`;

export const defaultClientVariables = {
  first: LIMIT_SIZE_TABLE,
  skip: 0,
  projectFilter: {},
  filter: {},
  orderBy: null,
};
