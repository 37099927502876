import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// components
import Tooltip from 'components/atoms/Tooltip';
import TextSection from 'components/atoms/TextSection';
// helpers
import { parsePositionDetails } from './helpers';

const propTypes = {
  position: PropTypes.object.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const PositionInfo = ({ position, className }) => {
  const details = parsePositionDetails(position);
  const halfLength = Math.ceil(details.length / 2);
  const leftSide = details.splice(0, halfLength);

  return (
    <div className={`${className} display-flex-row`}>
      <div className="flex1">
        {leftSide.map(({ label, value, tooltip }, index) => {
          return (
            <div key={label} className={classNames('m-r-10', { 'm-t-15': index })}>
              <TextSection title={label}>
                <Tooltip title={tooltip}>
                  <span>{value}</span>
                </Tooltip>
              </TextSection>
            </div>
          );
        })}
      </div>
      <div className="flex1">
        {details.map(({ label, value, tooltip }, index) => {
          return (
            <div key={label} className={classNames({ 'm-t-15': index })}>
              <TextSection title={label}>
                <Tooltip title={tooltip}>
                  <span>{value}</span>
                </Tooltip>
              </TextSection>
            </div>
          );
        })}
      </div>
    </div>
  );
};

PositionInfo.propTypes = propTypes;
PositionInfo.defaultProps = defaultProps;

export default PositionInfo;
