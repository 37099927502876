import getProperty from 'lodash/get';
import i18n from 'i18next';

export const getAvailabilityLabel = availability => {
  const map = {
    IMMEDIATELY: i18n.t('immediately'),
    '1-2_WEEKS': i18n.t('12weeks'),
    '2-3_WEEKS': i18n.t('23weeks'),
    '3-4_WEEKS': i18n.t('34weeks'),
    LONGER_THAN_4_WEEKS: i18n.t('longerThan4weeks'),
  };

  return getProperty(map, availability, availability);
};
