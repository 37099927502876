import React from 'react';
import ContentLoader from 'react-content-loader';

const SubmissionLoader = () => (
  <ContentLoader
    height={800}
    width={1600}
    speed={2}
    primaryColor="#f1f1f1"
    secondaryColor="#ecebeb"
  >
    <rect x="132" y="461" rx="0" ry="0" width="67" height="1" />
    <rect x="139" y="611" rx="0" ry="0" width="1" height="0" />
    <rect x="0" y="0" rx="0" ry="0" width="280" height="840" />
    <rect x="330" y="40" rx="0" ry="0" width="150" height="40" />
    <rect x="520" y="40" rx="0" ry="0" width="150" height="40" />
    <rect x="710" y="40" rx="0" ry="0" width="150" height="40" />
    <rect x="331" y="110" rx="0" ry="0" width="581" height="235" />
    <rect x="330" y="377" rx="0" ry="0" width="1218" height="197" />
    <rect x="944" y="110" rx="0" ry="0" width="602" height="235" />
    <rect x="329" y="608" rx="0" ry="0" width="1218" height="197" />
  </ContentLoader>
);

export default SubmissionLoader;
