import jwtDecode from 'jwt-decode';
import getProperty from 'lodash/get';
import axiosRetry from 'axios-retry';
// services
import axios from 'services/Axios';
// helpers
import { redirectToLogin as redirectToLoginHelper } from 'helpers/Link';
// constants
import { IS_DEVELOPMENT, PUBLIC_ROUTES } from 'constants';
import { USER_UNAUTHENTICATED, USER_UNAUTHORIZED } from 'constants/error';

const TOKEN_KEY = 'auth_token';
const SIGN_UP = '/auth/sign-up';
const LOG_IN = '/auth/login';
const RESET_PASSWORD = '/auth/reset-password';
const VERIFY_CONTEXT = '/auth/context-reset-password';
const SET_PASSWORD = '/auth/set-password';
const RESEND_EMAIL_VERIFICATION_EMAIL = '/auth/resend-email-verification';
const ME = '/auth/me';
const PASSWORD_RULES = '/auth/appId/password-rules';
const REFRESH_TOKEN = '/auth/refresh-token';

const handleError = e => {
  if (e.response) {
    const error = new Error();

    switch (e.response.status) {
      case 401:
        error.message = 'User is not authenticated';
        error.type = USER_UNAUTHENTICATED;
        error.code = 401;
        break;
      case 403:
        error.message = 'Unauthorized to use the app. The user must added in app';
        error.type = USER_UNAUTHORIZED;
        error.code = 403;
        break;
      case 500:
        error.message = getProperty(e.response.data, 'message', '');
        error.type = getProperty(e.response.data, 'type', '');
        error.code = 500;
        break;
      default:
        error.message = e.message;
        if (IS_DEVELOPMENT) {
          console.error(error);
        }
    }

    throw error;
  } else {
    if (IS_DEVELOPMENT) {
      console.error(e);
    }

    throw e;
  }
};

export const redirectToLogin = redirectToLoginHelper;

export const isPublicRoute = () => PUBLIC_ROUTES.includes(window.location.pathname);

export const getUserDetails = async () => {
  try {
    const response = await axios.get(ME, {
      timeout: 1000 * 60, // 1 min
      'axios-retry': {
        retries: 5,
        retryDelay: retryCount => retryCount * 2000,
        retryCondition: error =>
          axiosRetry.isRetryableError(error) || axiosRetry.isNetworkError(error),
      },
    });

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const tokenHasExpired = token => {
  const decoded = jwtDecode(token);
  const currentTime = new Date().getTime() / 1000;

  return decoded.exp < currentTime;
};

export const signUp = async signUpForm => {
  try {
    const response = await axios.post(SIGN_UP, signUpForm);

    return response;
  } catch (error) {
    return handleError(error);
  }
};

export const login = async loginData => {
  try {
    await axios.post(LOG_IN, loginData);
  } catch (error) {
    handleError(error);
  }
};

/**
 * @returns Rules object
 * @example const result  = {
      regex: { RegExp()},
      rules: ['String'],
    };
 */
export const getPasswordRules = async () => {
  try {
    const { data } = await axios.get(PASSWORD_RULES);
    // Temporary fix
    const rules = [
      'Must be at least 8 characters in length',
      'Must contain at least 1 lowercase alphabetical character',
      'Must contain at least 1 uppercase alphabetical character',
      'Must contain at least 1 number',
      'Must contain at least 1 special character',
    ];

    return {
      regex: new RegExp(atob(data.regex)),
      rules,
      // rules: JSON.parse(data.rules),
    };
  } catch (error) {
    return {};
  }
};

export const setPassword = async ({ uuid, password, confirmedPassword }) => {
  try {
    const data = await axios.post(SET_PASSWORD, { uuid, password, confirmedPassword });
    return data;
  } catch (error) {
    handleError(error);
    return {};
  }
};

export const verifyContext = async context => {
  try {
    const { data } = await axios.get(VERIFY_CONTEXT, {
      params: {
        context,
      },
    });
    return data.success ? data : false;
  } catch (error) {
    return false;
  }
};

export const refreshToken = async () => {
  try {
    const response = await axios.post(REFRESH_TOKEN);

    return response.data.token;
  } catch (error) {
    handleError(error);

    return '';
  }
};

export const resetPassword = async email => {
  try {
    await axios.post(RESET_PASSWORD, { email });
  } catch (error) {
    handleError(error);
  }
};

export const resendEmailVerificationEmail = async id => {
  try {
    await axios.post(RESEND_EMAIL_VERIFICATION_EMAIL, { id });
  } catch (error) {
    handleError(error);
  }
};

export const storeToken = token => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const getToken = () => localStorage.getItem(TOKEN_KEY);
