import getProperty from 'lodash/get';
import sortBy from 'lodash/sortBy';
// constants
import {
  SUPPLIER_ROLE,
  MANAGER_ROLE,
  SUPPLIER_DIRECT,
  SUPPLIER_IBM,
  METRICS_TYPE,
  SUBMITTER,
  REVIEWER,
  FILE_CV,
  FILE_RELEASABLE_CV,
  FILE_ATTESTATION,
  FILE_RELEASABLE_ATTESTATION,
} from 'constants';
import { COLOR } from 'constants/colors';
import { SUBMISSION_STATUS_TYPE, SUBMISSION_STATUS } from 'constants/submissionStatus';
// helpers
import { isExcellentMatch, hasEnoughDataPoints } from 'helpers/MatchMetrics';
import { isRevisable } from 'helpers/Revision';
import { getCurrencyText } from 'helpers/NameMapping';
import { formatStatus, getBasicStatusHistoryByRole } from './status';
import { getAvailabilityLabel } from './label';

/**
 * @typedef {Object} Status
 * @property {string} id
 * @property {string} value
 * @property {string} label
 * @property {string} start
 * @property {string} end
 * @property {string} note
 * @property {string} author Author name
 */

/**
 * @typedef {Object} File
 * @property {string} id
 * @property {string} boxId
 * @property {string} filename
 * @property {string} type
 * @property {string} createdAt
 * @property {string} author
 */

/**
 * @typedef {Object} Note
 * @property {string} id
 * @property {string} type
 * @property {string} text
 * @property {string} author
 */

const formatFile = file => ({
  id: file.id,
  boxId: file.boxId,
  filename: file.name,
  type: file.type,
  createdAt: file.createdAt,
  author: getProperty(file, 'author.person.fullName', ''),
});

const formatNote = note => ({
  id: note.id,
  type: note.noteType,
  text: note.note,
  date: note.createdAt,
  author: getProperty(note, 'author.person.fullName', ''),
});

class Submission {
  constructor(data) {
    // TODO: list and format all values manually like in position parser
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(data)) {
      this[key] = value;
    }

    const submissionRoles = getProperty(data, 'submissionRoles.items', []);
    const submitter = submissionRoles.find(submissionRole => submissionRole.role === SUBMITTER);
    const reviewer = submissionRoles.find(submissionRole => submissionRole.role === REVIEWER);
    const availability = getProperty(data, 'availability.leadTimeFromSelection', '');

    this.data = data;
    this.candidate = {
      id: getProperty(data, 'candidate.id', ''),
      name: getProperty(data, 'profile.fullName', ''),
      email: getProperty(data, 'profile.email', ''),
      phone: getProperty(data, 'profile.phone', ''),
      skype: getProperty(data, 'profile.skype', ''),
      linkedIn: getProperty(data, 'profile.linkedIn', ''),
      exIBM: getProperty(data, 'profile.exIBM', false),
      citizenship: getProperty(data, 'profile.citizenship.name', ''),
      clearance: {
        type: getProperty(data, 'profile.clearance.type.name', ''),
        validFrom: getProperty(data, 'profile.clearance.validFrom', ''),
        validUntil: getProperty(data, 'profile.clearance.validUntil', ''),
        transferRequired: getProperty(data, 'profile.clearance.transferRequired', false),
        note: getProperty(data, 'profile.clearance.notes.items.0.note', ''),
        country: getProperty(data, 'profile.clearance.country.name', ''),
      },
    };
    this.score = getProperty(data, 'score', 0);
    this.name = getProperty(data, 'profile.fullName', '');
    this.reports = getProperty(data, 'reports.items', []);
    this.report = {
      id: getProperty(this.reports, '0.id'),
      shCandidateId: getProperty(this.reports, '0.candidateReport.shCandidateId', ''),
    };
    this.revisions = getProperty(data, 'revisions.items', []);
    this.statuses = getProperty(data, 'statuses.items', []);
    this.documents = getProperty(data, 'documents.items', []);
    this.metrics = getProperty(data, 'metrics.items', []);
    this.partner = {
      id: getProperty(data, 'candidate.supplier.id', ''),
      name: getProperty(data, 'candidate.supplier.company.name', ''),
      category: getProperty(data, 'candidate.supplier.category', ''),
      includeCSAFee: getProperty(data, 'candidate.supplier.includeCSAFee', false),
    };
    this.availability = {
      value: availability,
      label: getAvailabilityLabel(availability),
      start: getProperty(data, 'availability.start', ''),
    };
    this.submittedBy = {
      id: getProperty(submitter, 'user.id', ''),
      name: getProperty(submitter, 'user.person.fullName', ''),
      email: getProperty(submitter, 'user.user.email', ''),
    };
    this.reviewedBy = {
      id: getProperty(reviewer, 'user.id', ''),
      name: getProperty(reviewer, 'user.person.fullName', ''),
      email: getProperty(reviewer, 'user.user.email', ''),
      company: getProperty(reviewer, 'user.organization.company.name', ''),
    };
  }

  /**
   * Get candidate name
   * @returns {string}
   */
  getName = () => {
    return this.candidate.name;
  };

  /**
   * Get candidate email
   * @returns {string}
   */
  getEmail = () => {
    return this.candidate.email;
  };

  /**
   * Get candidate phone
   * @returns {string}
   */
  getPhone = () => {
    return this.candidate.phone;
  };

  /**
   * Get candidate clearance
   * @returns {string}
   */
  getClearance = () => {
    return this.candidate.clearance;
  };

  /**
   * Find status (even if status is already finished)
   * @param {string} status
   * @param {string} [role]
   * @return {?Status}
   */
  findStatus = (status, role) => {
    const candidateStatus = this.statuses.find(({ name }) => name === status);

    return candidateStatus ? formatStatus(candidateStatus, role) : null;
  };

  /**
   * Get current status
   * @param {string} role
   * @param {string} [type] Status type
   * @returns {?Status} Current status
   */
  getStatus = (role, type = SUBMISSION_STATUS_TYPE.BASIC) => {
    const currentStatus = this.statuses.find(status => status.type === type && !status.end);

    return currentStatus ? formatStatus(currentStatus, role) : null;
  };

  /**
   * Submission has status?
   * @param {string|string[]} status Submission status
   * @param {boolean} [filterByCurrentStatus=true] Check only submissions' current status
   * @returns {boolean}
   */
  hasStatus = (status, filterByCurrentStatus = true) => {
    const searchingStatuses = [].concat(status);

    if (filterByCurrentStatus) {
      const currentStatus = this.getStatus();

      return searchingStatuses.includes(currentStatus.value);
    }

    return this.statuses.some(({ name: submissionStatus }) =>
      searchingStatuses.includes(submissionStatus)
    );
  };

  /**
   * Get history of basic statuses
   * @param {string} role
   * @returns {Status[]}
   */
  getBasicStatusHistory = role => {
    return getBasicStatusHistoryByRole(this.statuses, role);
  };

  /**
   * Get color for status by role
   * @param {string} role
   * @returns {string} - Color
   */
  getColor = role => {
    const currentStatus = getProperty(this.getStatus(role), 'value', '');

    const statusMap = {
      [SUBMISSION_STATUS.NEW]: COLOR.RED,
      [SUBMISSION_STATUS.SUBMITTED]: {
        [MANAGER_ROLE]: COLOR.RED,
        default: COLOR.YELLOW,
      },
      [SUBMISSION_STATUS.OFFER]: COLOR.GREEN,
      [SUBMISSION_STATUS.ONBOARDING]: COLOR.GREEN,
      [SUBMISSION_STATUS.ONBOARDING_CANCELLED]: COLOR.GRAY,
      [SUBMISSION_STATUS.NOT_SUBMITTED]: COLOR.GRAY,
      [SUBMISSION_STATUS.NOT_SELECTED]: COLOR.GRAY,
      [SUBMISSION_STATUS.WITHDRAWN]: COLOR.GRAY,
      [SUBMISSION_STATUS.INTERVIEW]: COLOR.BLUE,
      [SUBMISSION_STATUS.DELIVERY]: COLOR.BLUE,
      [SUBMISSION_STATUS.OFF_BOARDING]: COLOR.GRAY,
      [SUBMISSION_STATUS.OFF_BOARDED]: COLOR.GRAY,
    };

    return (
      getProperty(statusMap, [currentStatus, role]) ||
      getProperty(statusMap, [currentStatus, 'default']) ||
      getProperty(statusMap, currentStatus, '')
    );
  };

  /**
   * Find document by type
   * @param {string} type
   * @returns {?File}
   */
  getDocument = type => {
    const file = this.documents.find(document => document.type === type);

    if (file) {
      return formatFile(file);
    }

    return null;
  };

  /**
   * Find documents by type
   * @param {string} [type] If not provided, all candidate documents are returned
   * @returns {File[]}
   */
  getDocuments = type => {
    if (!type) {
      return this.documents.map(formatFile);
    }

    return this.documents.filter(document => document.type === type).map(formatFile);
  };

  /**
   * Get CV file based on role
   * @param {string} role
   * @returns {?File}
   */
  getSubmissionFile = role => {
    const cv = this.getDocument(FILE_CV);
    const releasableCv = this.getDocument(FILE_RELEASABLE_CV);

    if (role === SUPPLIER_ROLE) {
      return cv;
    }

    // releasable cv has higher priority
    return releasableCv || cv;
  };

  /**
   * Get attestation file based on role
   * @param {string} role
   * @returns {?File}
   */
  getAttestation = role => {
    const attestation = this.getDocument(FILE_ATTESTATION);
    const releasableAttestation = this.getDocument(FILE_RELEASABLE_ATTESTATION);

    if (role === SUPPLIER_ROLE) {
      return attestation;
    }

    // releasable attestation has higher priority
    return releasableAttestation || attestation;
  };

  /**
   * Get rates
   * @param {string} type Rate type
   * @returns {object[]}
   */
  getRates = type => {
    const rates = getProperty(this, 'rates.items', []);

    return sortBy(
      rates
        .filter(({ rateType }) => rateType === type)
        .map(rate => ({ ...rate, year: new Date(rate.startDate).getFullYear() })),
      'startDate'
    );
  };

  /**
   * Get rate by type for given year
   * @param {string} type Rate type
   * @param {number} [year] Rate for given year. By default it uses current year or 1st rate if rate for current year is not found
   * @returns {?object}
   */
  getRate = (type, year) => {
    const rates = this.getRates(type);
    const rateYear = year || new Date().getFullYear();
    const foundRate = rates.find(rate => new Date(rate.startDate).getFullYear() === rateYear);
    const rate = foundRate || getProperty(rates, '0');

    return rate ? { ...rate, currencyLabel: getCurrencyText(rate.currency) } : null;
  };

  /**
   * Find note by type
   * @param {string} type
   * @returns {?Note}
   */
  getNote = type => {
    const note = getProperty(this.data, 'notes.items', []).find(
      ({ noteType }) => noteType === type
    );

    if (note) {
      return formatNote(note);
    }

    return null;
  };

  /**
   * Find onboarding document by type
   * @param {string} type
   * @returns {File}
   */
  getOnboardingDocument = type => {
    const documents = getProperty(this.data, 'hiringProcess.files.items', []);
    const file = documents.find(document => document.type === type);

    if (file) {
      return formatFile(file);
    }

    return null;
  };

  /**
   * Find onboarding documents
   * @param {string} [type]
   * @returns {File}
   */
  getOnboardingDocuments = type => {
    const documents = getProperty(this.data, 'hiringProcess.files.items', []);

    if (type) {
      return documents.filter(document => document.type === type).map(formatFile);
    }

    return documents.map(formatFile);
  };

  /**
   * Candidate submitted by direct partner?
   * @returns {boolean}
   */
  isDirectPartner = () => {
    return [SUPPLIER_DIRECT, SUPPLIER_IBM].includes(this.partner.category);
  };

  /**
   * Was candidate in onboarding state ever
   * @returns {boolean}
   */
  hasOnboarding = () => {
    const offerStatus = this.findStatus(SUBMISSION_STATUS.OFFER);

    return !!offerStatus;
  };

  /**
   * Get metrics by type
   * @param {string} [type]
   * @returns {?object}
   */
  getMetrics = (type = METRICS_TYPE.BASIC) => {
    return this.metrics.find(metric => metric.type === type);
  };

  /**
   * @param {string} [type]
   * @returns {boolean}
   */
  isExcellentMatch = (type = METRICS_TYPE.BASIC) => {
    const metric = this.getMetrics(type);

    return metric ? isExcellentMatch(metric.percentile) : false;
  };

  /**
   * @param {string} [type]
   * @returns {boolean}
   */
  hasEnoughDataPoints = (type = METRICS_TYPE.BASIC) => {
    const metric = this.getMetrics(type);

    return metric ? hasEnoughDataPoints(metric.count) : false;
  };

  /**
   * Is candidate revisable?
   * @param {string} positionStatus
   * @param {string} role
   * @returns {boolean}
   */
  isRevisable = (positionStatus, role) => {
    const currentStatus = this.getStatus(role);

    return isRevisable(positionStatus, currentStatus.value, this.revisions);
  };

  isRejectable = role => {
    const currentStatus = this.getStatus(role);

    return ![
      SUBMISSION_STATUS.DELIVERY,
      SUBMISSION_STATUS.WITHDRAWN,
      SUBMISSION_STATUS.NOT_SUBMITTED,
      SUBMISSION_STATUS.NOT_SELECTED,
      SUBMISSION_STATUS.ONBOARDING_CANCELLED,
    ].includes(currentStatus.value);
  };

  isProposal = () => {
    return false;
  };
}

/**
 * Parse and normalize submission data from 8base
 * @param {object} data Candidate data from 8base
 * @returns {Candidate}
 */
const parseSubmission = data => new Submission(data);

export default parseSubmission;
