import React from 'react';
// libraries
import PropTypes from 'prop-types';
// hooks
import useAuth from 'hooks/useAuth';
// constants
import { STAFF_ROLE } from 'constants';
import { MAIN_RECRUITING } from 'constants/positionStatus';
// components
import PositionStatusFlow from 'components/molecules/PositionStatusFlow';
import PositionCallToActions from 'components/organisms/PositionCallToActions';
import StatusDate from 'components/molecules/StatusDate';
import Title from './Title';
import Rates from './Rates';
import MainInfo from './MainInfo';
import Audience from './Audience';
import PublishStatus from './PublishStatus';
// styles
import styles from './positionCard.module.scss';

const propTypes = {
  isNew: PropTypes.bool.isRequired,
  position: PropTypes.object.isRequired,
};

const Content = ({ isNew, position }) => {
  const { role, supplierId } = useAuth();
  const positionStatus = position.getMainStatus(role, supplierId);
  const status = position.getStatus(role, supplierId);
  const unPublishDate = position.hasBeenPublishedBefore() ? status.start : null;

  return (
    <React.Fragment>
      {role === STAFF_ROLE && positionStatus.value === MAIN_RECRUITING && (
        <PublishStatus
          isPublished={position.isPublished(role, supplierId)}
          partnerDueDate={position.supplierDueDate}
          unPublishedOn={unPublishDate}
        />
      )}
      {!isNew && (
        <div className={styles.statusFlow}>
          <PositionStatusFlow position={position} isPopover={false} />
        </div>
      )}
      <div className={styles.header}>
        <div className={styles.positionInfo}>
          <Title position={position} />
          <StatusDate position={position} role={role} />
        </div>
        <Rates position={position} />
      </div>

      <div className={styles.main}>
        <MainInfo position={position} />
      </div>

      <div className={styles.footer}>
        <div className={styles.audience}>
          <Audience position={position} />
        </div>
        <PositionCallToActions position={position} />
      </div>
    </React.Fragment>
  );
};

Content.propTypes = propTypes;

export default React.memo(Content);
