import React from 'react';
import getProperty from 'lodash/get';
// Libraries
import PropTypes from 'prop-types';
// Components
import ProjectRoles from 'components/molecules/ProjectRoles';
import RevisionTypeRequest from 'components/molecules/RevisionTypeRequest';
import SurveyCustom from 'components/molecules/SurveyCustom';
// constants
import { COMPONENT_ACTIVITIES } from 'constants';
// helpers
import { reCalculateAngle, addItemToData } from './Helpers';

const propTypes = {
  component: PropTypes.object,
  formData: PropTypes.any,
  updateFormData: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

const defaultProps = {
  component: {},
  formData: {},
  disabled: false,
};

function ComplexComponents(props) {
  const { component, updateFormData, formData, disabled } = props;
  const { type, key } = component;
  const onSurveyChange = (questionId, value) => {
    updateFormData(key, {
      value: formData[key].value.map(q => (q.id === questionId ? value : q)),
    });
  };

  const addItem = (value, fieldId) => {
    const previousItems = getProperty(formData, [fieldId, 'value'], []);
    const data = addItemToData(value, fieldId, previousItems);

    updateFormData(fieldId, { value: data, requiredError: false });
  };

  const removeItem = (questionId, fieldId) => {
    let data = formData[fieldId].value.filter(item => item.id !== questionId);
    if (fieldId === COMPONENT_ACTIVITIES) {
      data = reCalculateAngle(data);
    }

    const requiredError = getProperty(component, 'validate.required') && data.length === 0;
    updateFormData(fieldId, { value: data, requiredError });
  };

  switch (type) {
    case 'projectRoles':
      return (
        <ProjectRoles
          id={key}
          onChange={value => updateFormData(key, value)}
          formData={formData}
          name={key}
          disabled={disabled}
        />
      );
    case 'survey':
      return (
        <SurveyCustom
          questions={getProperty(formData, [key, 'value'], [])}
          values={component.values}
          onChange={onSurveyChange}
          id={key}
          required={component.validate.required}
          removeItem={removeItem}
          addItem={addItem}
          disabled={disabled}
        />
      );
    case 'requestRevision':
      return (
        <RevisionTypeRequest onChange={updateFormData} component={component} formData={formData} />
      );
    default:
      return null;
  }
}

ComplexComponents.propTypes = propTypes;
ComplexComponents.defaultProps = defaultProps;

export default ComplexComponents;
