import React from 'react';
// helpers
import useAuth from 'hooks/useAuth';
import Avatar from 'components/atoms/Avatar';
// styles
import './user.scss';

const User = () => {
  const { name, company, email } = useAuth();

  return (
    <div className="user">
      <div className="user_logoContainer">
        <div className="m-r-12">
          <Avatar name={name} />
        </div>
      </div>
      <div className="user_text fs-mask">
        <div className="user_name">{name}</div>
        <div className="f-s-15">{email}</div>
        {company && <div className="user_company">{company}</div>}
      </div>
    </div>
  );
};

export default User;
