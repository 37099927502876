import React from 'react';
// libraries
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// helpers
import { ACTION_FINISHED } from 'constants';
import { getFutureRelativeTime, getTimeYear } from 'helpers/RelativeTimeFormatter';
import { getArrowColor } from '../Helpers';
// components
import ArrowRole from './ArrowRole';
import ArrowType from './ArrowType';

const propTypes = {
  type: PropTypes.string,
  dueDate: PropTypes.string,
  isOverdue: PropTypes.bool,
};

const defaultProps = {
  type: null,
  dueDate: null,
  isOverdue: false,
};

const ArrowContainer = props => {
  const { dueDate, type, isOverdue } = props;
  const { t } = useTranslation();
  const color = getArrowColor(type);

  const getDate = () => {
    if (!dueDate) {
      return '';
    }
    if (type === ACTION_FINISHED) return getTimeYear(dueDate);
    return (
      <React.Fragment>
        {t('due')} {getFutureRelativeTime(dueDate)}
        {isOverdue ? ' !' : ''}
      </React.Fragment>
    );
  };

  return (
    <div className="arrowContainer">
      <ArrowRole {...props} color={color} />
      <ArrowType {...props} color={color} />
      <div
        className={`${
          type !== ACTION_FINISHED && isOverdue ? 'text-red' : ''
        } element_addLabel text-center p-t-3`}
      >
        {getDate()}
      </div>
    </div>
  );
};

ArrowContainer.propTypes = propTypes;
ArrowContainer.defaultProps = defaultProps;

export default ArrowContainer;
