import { SUPPLIER_ROLE, MANAGER_ROLE } from 'constants';
// helpers
import { filterSubmissionsBySupplier, filterSubmissionsByManagerRole } from 'helpers/Submission';

/**
 * @param {object[]} submissions
 * @param {string} role
 * @param {string} [supplierId]
 */
export const filterSubmissions = (submissions, role, supplierId) => {
  if (role === SUPPLIER_ROLE) {
    return filterSubmissionsBySupplier(submissions, supplierId);
  }

  if (role === MANAGER_ROLE) {
    return filterSubmissionsByManagerRole(submissions);
  }

  return submissions;
};
