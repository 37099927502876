/* eslint-disable import/order */
import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// helpers
import { getCurrencyText } from 'helpers/NameMapping';
import { getPassedRelativeTime } from 'helpers/RelativeTimeFormatter';
// components
import TruncatedTextBlock from 'components/atoms/TruncatedTextBlock';
import TimezoneDate from 'components/atoms/TimezoneDate';
import Section from 'components/molecules/FieldSection';
import ReviewSection from './ReviewSection';
// helpers
import { prettyNumber } from 'helpers/Number';
import { formatList } from 'helpers/StringParser';
import { isPartnerDueDateValid, getPartnerDueDateErrorMessage } from '../helpers';
// constants
import { STEPS } from '../constants';
// styles
import styles from './review.module.scss';

const propTypes = {
  position: PropTypes.object.isRequired,
  targetRates: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      year: PropTypes.number.isRequired,
    })
  ).isRequired,
  showTargetRatesToPartners: PropTypes.bool.isRequired,
  showNteRatesToPartners: PropTypes.bool.isRequired,
  allowSubmissionAboveTargetRates: PropTypes.bool.isRequired,
  partnerDueDate: PropTypes.string.isRequired,
  audience: PropTypes.array.isRequired,
  publishToAll: PropTypes.bool.isRequired,
  note: PropTypes.string,
  onSectionClick: PropTypes.func.isRequired,
  displayErrors: PropTypes.bool.isRequired,
};

const defaultProps = {
  note: '',
};

const Review = ({
  position,
  targetRates,
  showTargetRatesToPartners,
  showNteRatesToPartners,
  allowSubmissionAboveTargetRates,
  partnerDueDate,
  publishToAll,
  audience,
  note,
  onSectionClick,
  displayErrors,
}) => {
  const { t } = useTranslation();
  const currency = getCurrencyText(position.project.currency);
  const missingTargetRates = targetRates.every(rate => !rate.value);
  const targetRatesHaveError = targetRates.some(rate => rate.error);
  const dueDateError = !partnerDueDate || !isPartnerDueDateValid(partnerDueDate, position.dueDate);
  const dueDateErrorMessage = getPartnerDueDateErrorMessage(partnerDueDate, position.dueDate);
  const audienceError = !publishToAll && !audience.length;

  const getTargetRatesErrorMessage = () => {
    // if some target rate is not filled
    if (targetRates.some(rate => !rate.value)) {
      return t('targetRatesMissingErrorMessage');
    }

    // another error is when some provided target rate is above NTE rate
    if (targetRates.some(rate => rate.error)) {
      return t('errorPositionPublishReviewTargetRatesAboveNteRates');
    }

    return '';
  };

  const renderAudienceSummary = () => {
    if (publishToAll) {
      return t('publishedToAll');
    }

    const companies = audience.map(({ label }) => label);

    return formatList(companies);
  };

  return (
    <Section title={t('reviewTitle')} description={t('reviewDescription')}>
      <ReviewSection
        className="m-b-15"
        icon="settings"
        label={t('rateSettings')}
        onClick={() => onSectionClick(STEPS.TARGET_RATES)}
      >
        <div className={classNames(styles.rateSetting, 'm-b-5')}>
          {showTargetRatesToPartners
            ? t('showTargetRatesToPartners')
            : t('notShowTargetRatesToPartners')}
        </div>
        <div className={classNames(styles.rateSetting, 'm-b-5')}>
          {allowSubmissionAboveTargetRates
            ? t('allowSubmissionAboveTargetRates')
            : t('notAllowSubmissionAboveTargetRates')}
        </div>
        <div className={styles.rateSetting}>
          {showNteRatesToPartners ? t('showNteRatesToPartners') : t('notShowNteRatesToPartners')}
        </div>
      </ReviewSection>
      {showTargetRatesToPartners && (
        <ReviewSection
          className="m-b-15"
          icon="money"
          label={t('targetRates')}
          emptyLabel={t('targetRatesEmptyLabel')}
          isEmpty={missingTargetRates}
          error={displayErrors && targetRatesHaveError}
          errorMessage={displayErrors ? getTargetRatesErrorMessage() : ''}
          onClick={() => onSectionClick(STEPS.TARGET_RATES)}
        >
          <div className={styles.rates}>
            {targetRates.map(rate => (
              <div
                key={rate.year}
                className={classNames(styles.rate, { [styles.error]: rate.error })}
              >
                {(position.isTimeAndMaterials() || targetRates.length > 1) && (
                  <div className={styles.rateYear}>{rate.year}</div>
                )}
                {rate.value && (
                  <div className={styles.rateValue}>
                    {prettyNumber(rate.value)} {currency}
                  </div>
                )}
              </div>
            ))}
          </div>
        </ReviewSection>
      )}
      <ReviewSection
        className="m-b-15"
        icon="calendar"
        label={t('dueDate')}
        emptyLabel={t('partnerDueDateEmptyLabel')}
        isEmpty={!partnerDueDate}
        error={displayErrors && dueDateError}
        errorMessage={displayErrors ? dueDateErrorMessage : ''}
        onClick={() => onSectionClick(STEPS.PARTNER_DUE_DATE)}
      >
        {partnerDueDate && (
          <React.Fragment>
            <TimezoneDate
              date={partnerDueDate}
              timezone={position.project.timezone}
              tooltipPlacement="right"
            />
            <div className={styles.relativeDueDate}>{getPassedRelativeTime(partnerDueDate)}</div>
          </React.Fragment>
        )}
      </ReviewSection>
      <ReviewSection
        className="m-b-15"
        icon="user"
        label={t('audience')}
        emptyLabel={t('audienceEmptyLabel')}
        isEmpty={displayErrors && audienceError}
        error={displayErrors && audienceError}
        errorMessage={t('audienceMissingErrorMessage')}
        onClick={() => onSectionClick(STEPS.AUDIENCE)}
      >
        {t('publishedTo')}&nbsp;
        <span className={styles.audience}>{renderAudienceSummary()}</span>
      </ReviewSection>
      <ReviewSection
        icon="note"
        label={t('notes')}
        emptyLabel={t('notesEmptyLabel')}
        isEmpty={!note}
        onClick={() => onSectionClick(STEPS.NOTES)}
      >
        <div className={styles.note}>
          <TruncatedTextBlock maxLines={2}>{note}</TruncatedTextBlock>
        </div>
      </ReviewSection>
    </Section>
  );
};

Review.propTypes = propTypes;
Review.defaultProps = defaultProps;

export default Review;
