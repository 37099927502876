import { gql } from '@apollo/client';
import { File, Note, User, Rate } from 'queries/Fragments';

export const RevisionType = gql`
  fragment RevisionType on RevisionType {
    id
    createdAt
    reason {
      ...Note
    }
    revision {
      id
      createdBy {
        ...User
      }
    }
  }
  ${Note}
  ${User}
`;

export const GET_ATTACHMENTS = gql`
  query getHistoryAttachments($filter: AttachmentFilter) {
    attachmentsList(filter: $filter, sort: { createdAt: DESC }) {
      items {
        ...File
        createdBy {
          ...User
        }
        revisionType {
          ...RevisionType
        }
      }
    }
  }
  ${File}
  ${User}
  ${RevisionType}
`;

export const GET_RATES = gql`
  query getHistoryRates($filter: RateGroupFilter) {
    rateGroupsList(filter: $filter, sort: { createdAt: DESC }) {
      items {
        id
        createdAt
        revision {
          ...RevisionType
        }
        createdBy {
          ...User
        }
        rates {
          items {
            ...Rate
          }
        }
      }
    }
  }
  ${Rate}
  ${User}
  ${RevisionType}
`;
