import {
  // Notification groups
  NOTIFICATION_GROUP_POSITION,
  NOTIFICATION_GROUP_CANDIDATE,
  NOTIFICATION_GROUP_SUBMISSION,
  NOTIFICATION_GROUP_ONBOARDING,
  NOTIFICATION_GROUP_REVISION,
  NOTIFICATION_GROUP_IMPORT,
  // Notification events
  NOTIFICATION_EVENT,
} from './Constants';

export const eventGroups = {
  [NOTIFICATION_GROUP_POSITION]: [
    NOTIFICATION_EVENT.POSITION_CREATED,
    NOTIFICATION_EVENT.POSITION_PUBLISHED,
    NOTIFICATION_EVENT.POSITION_UNPUBLISHED,
    NOTIFICATION_EVENT.POSITION_REPUBLISHED,
    NOTIFICATION_EVENT.POSITION_PUBLISH_INFORMATION_CHANGED,
    // NOTIFICATION_EVENT.POSITION_OWNERSHIP_CLAIMED,
    // NOTIFICATION_EVENT.POSITION_OWNERSHIP_REMOVED,
    NOTIFICATION_EVENT.POSITION_CANCELLED,
  ],
  [NOTIFICATION_GROUP_CANDIDATE]: [
    NOTIFICATION_EVENT.CANDIDATE_BULK_MATCH,
    NOTIFICATION_EVENT.CANDIDATE_DELETED,
    NOTIFICATION_EVENT.CANDIDATE_ADDED,
    NOTIFICATION_EVENT.POSITION_BULK_MATCH,
  ],
  [NOTIFICATION_GROUP_SUBMISSION]: [
    NOTIFICATION_EVENT.SUBMISSION_SUBMITTED_ALAKA_MATCH_MANAGER,
    NOTIFICATION_EVENT.SUBMISSION_SUBMITTED_ALAKA_SUPPLIER,
    NOTIFICATION_EVENT.SUBMISSION_EDITED,
    NOTIFICATION_EVENT.SUBMISSION_WITHDRAWN,
    NOTIFICATION_EVENT.SUBMISSION_SUBMITTED_CLIENT,
    NOTIFICATION_EVENT.SUBMISSION_SUBMITTED_PROJECT_MANAGER,
    NOTIFICATION_EVENT.SUBMISSION_SELECTED_CLIENT,
    NOTIFICATION_EVENT.SUBMISSION_REJECTED_CLIENT,
    NOTIFICATION_EVENT.SUBMISSION_REJECTED_MATCH_MANAGER,
    NOTIFICATION_EVENT.ONBOARDING_START_DATE_ACCEPTED_PM,
    NOTIFICATION_EVENT.ONBOARDING_START_DATE_ACCEPTED_PARTNER,
    NOTIFICATION_EVENT.ONBOARDING_START_DATE_ACCEPTED_MM,
    NOTIFICATION_EVENT.ONBOARDING_START_DATE_REJECTED_PM,
    NOTIFICATION_EVENT.ONBOARDING_START_DATE_REJECTED_PARTNER,
  ],
  [NOTIFICATION_GROUP_ONBOARDING]: [
    NOTIFICATION_EVENT.ONBOARDING_FORMS_UPLOADED_PARTNER,
    NOTIFICATION_EVENT.ONBOARDING_FORMS_UPLOADED_MM,
    NOTIFICATION_EVENT.ONBOARDING_FORMS_UPLOADED_TO_AUSY,
    NOTIFICATION_EVENT.ONBOARDING_FORMS_REQUEST,
    NOTIFICATION_EVENT.ONBOARDING_PO_CREATED,
    NOTIFICATION_EVENT.ONBOARDING_PO_RECEIVED,
    NOTIFICATION_EVENT.ONBOARDING_CANCELLED,
  ],
  [NOTIFICATION_GROUP_REVISION]: [
    NOTIFICATION_EVENT.SUBMISSION_REVISION_REQUEST,
    NOTIFICATION_EVENT.SUBMISSION_REVISION_SUBMITTED,
    NOTIFICATION_EVENT.SUBMISSION_REVISION_SUBMITTED_MM,
    NOTIFICATION_EVENT.SUBMISSION_REVISION_SUBMITTED_PARTNER,
  ],
  [NOTIFICATION_GROUP_IMPORT]: [
    NOTIFICATION_EVENT.POSITION_PARSING_SUCCESS,
    NOTIFICATION_EVENT.POSITION_PARSING_ERROR,
    NOTIFICATION_EVENT.PO_PARSING_ERROR,
  ],
};
