import React from 'react';
// hooks
import useAuth from 'hooks/useAuth';
// libraries
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// components
import TextSection from 'components/atoms/TextSection';
import TimezoneDate from 'components/atoms/TimezoneDate';
// helpers
import { getReadableYearDateTime, getFormattedDuration } from 'helpers/RelativeTimeFormatter';

const propTypes = {
  position: PropTypes.object.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const StatusDates = ({ position, className }) => {
  const { role } = useAuth();
  const { t } = useTranslation();

  const { created, duration, project } = position;
  const dueDates = position.getDueDates(role, true);

  return (
    <div className={className}>
      {dueDates.map((date, index) => (
        <div key={date.label} className={index > 0 ? 'm-t-15' : ''}>
          <TextSection title={date.label}>
            <TimezoneDate date={date.value} timezone={project.timezone} />
          </TextSection>
        </div>
      ))}
      <div className="m-t-15">
        <TextSection title={t('receivedDate')}>{getReadableYearDateTime(created)}</TextSection>
      </div>
      <div className="m-t-15">
        <TextSection title={t('periodOfPerformance')}>
          {getFormattedDuration(duration.start, duration.end)}
        </TextSection>
      </div>
    </div>
  );
};

StatusDates.propTypes = propTypes;
StatusDates.defaultProps = defaultProps;

export default StatusDates;
