// helpers
import client from 'services/Client';
import { getRoleText } from 'helpers/NameMapping';
// constants
import { UPDATE_LAST_USED_ROLE } from 'queries/UserQueries';

export const formatRole = (roleKey, id) => {
  return {
    label: getRoleText(roleKey),
    value: roleKey,
    id,
  };
};

export const parseData = projectRoles => {
  if (projectRoles) {
    return projectRoles.map(item => {
      return formatRole(item.role, item.id);
    });
  }
  return [];
};

export const handleSelectedRole = (userId, value) => {
  const { id, value: role } = value;

  return client.mutate({
    mutation: UPDATE_LAST_USED_ROLE,
    variables: { userId, projectRoleId: id, role },
  });
};
