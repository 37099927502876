import { gql } from '@apollo/client';

export const NOTIFICATION_FRAGMENT = gql`
  fragment Notification on NotificationItem {
    id
    createdAt
    message
    read
    payload
  }
`;

const NotificationEvent = gql`
  fragment NotificationEvent on SubscriberEvent {
    event
    web {
      exists
      enabled
    }
    email {
      exists
      enabled
    }
  }
`;

export const GET_NOTIFICATIONS = gql`
  query notificationsList($after: ID, $lastViewed: ID) {
    notificationsList(after: $after, lastViewed: $lastViewed) {
      count
      new
      cursor
      items {
        ...Notification
      }
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;

export const GET_NUMBER_OF_NEW_NOTIFICATIONS = gql`
  query notificationsList($lastViewed: ID) {
    notificationsList(lastViewed: $lastViewed) {
      new
    }
  }
`;

export const GET_NOTIFICATION_EVENTS = gql`
  query notificationEvents {
    subscribedEventsList {
      count
      items {
        ...NotificationEvent
      }
    }
  }
  ${NotificationEvent}
`;

export const MARK_AS_READ = gql`
  mutation notificationMarkAsRead($id: ID!) {
    notificationMarkAsRead(id: $id) {
      ...Notification
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;

export const MARK_AS_UNREAD = gql`
  mutation notificationMarkAsUnread($id: ID!) {
    notificationMarkAsUnread(id: $id) {
      ...Notification
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;

export const REMOVE_NOTIFICATION = gql`
  mutation notificationRemove($id: ID!) {
    notificationRemove(id: $id) {
      success
    }
  }
`;

export const NOTIFICATION_ADDED = gql`
  subscription {
    notificationAdded {
      id
      createdAt
      message
      read
      payload
    }
  }
`;

export const TOGGLE_NOTIFICATION_EVENT = gql`
  mutation notificationEnable($event: String!, $type: SubscriberType!, $enabled: Boolean!) {
    notificationEnable(event: $event, type: $type, enabled: $enabled) {
      ...NotificationEvent
    }
  }
  ${NotificationEvent}
`;

export const TOGGLE_NOTIFICATION_EVENT_LIST = gql`
  mutation notificationsListEnable(
    $events: [String!]!
    $type: SubscriberType!
    $enabled: Boolean!
  ) {
    notificationsListEnable(events: $events, type: $type, enabled: $enabled) {
      ...NotificationEvent
    }
  }
  ${NotificationEvent}
`;

export const SUBSCRIBE_USER = gql`
  mutation subscribeUser($email: String!) {
    notificationsSubscribe(email: $email) {
      success
    }
  }
`;

export const NOTIFICATIONS_SUBSCRIBERS_UPDATE = gql`
  mutation updateNotifications($users: [ID]!) {
    notificationsSubscribersUpdate(users: $users) {
      success
    }
  }
`;
