import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
// components
import Icon from 'components/atoms/Icon';
// constants
import { TOOLTIP_POSITION } from 'components/atoms/Tooltip';

const propTypes = {
  icon: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  placement: PropTypes.oneOf(Object.values(TOOLTIP_POSITION)),
  color: PropTypes.any,
  className: PropTypes.string,
  size: PropTypes.number,
};

const defaultProps = {
  tooltip: '',
  placement: TOOLTIP_POSITION.TOP,
  className: '',
  color: 'grey',
  size: 10,
};

const TooltipIcon = ({ icon, tooltip, placement, color, size, className }) => {
  return (
    <Tooltip title={tooltip} placement={placement} disableFocusListener>
      <span className={className}>
        <Icon name={icon} color={color} size={size} />
      </span>
    </Tooltip>
  );
};

TooltipIcon.propTypes = propTypes;
TooltipIcon.defaultProps = defaultProps;

export default TooltipIcon;
