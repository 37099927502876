import React from 'react';
// libraries
import ContentLoader from 'react-content-loader';

const PositionLoader = () => (
  <ContentLoader
    height={100}
    width={1250}
    speed={1}
    primaryColor="#f1f1f1"
    secondaryColor="#e6e6e6"
  >
    <circle cx="40" cy="40" r="40" />
    <rect x="120" y="0" rx="3" ry="3" width="200" height="15" />
    <rect x="1200" y="0" rx="3" ry="3" width="130" height="25" />
    <rect x="120" y="40" rx="3" ry="3" width="500" height="15" />
    <rect x="120" y="90" rx="3" ry="3" width="250" height="10" />
    <rect x="400" y="90" rx="3" ry="3" width="250" height="10" />
    <rect x="800" y="90" rx="3" ry="3" width="250" height="10" />
    <rect x="1100" y="90" rx="3" ry="3" width="150" height="10" />
  </ContentLoader>
);

export default PositionLoader;
