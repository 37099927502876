import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

const getUrl = () => {
  const url = new URL('/import/graphql', window.location.origin);

  if (url.protocol === 'https:') {
    url.protocol = 'wss';
  } else {
    url.protocol = 'ws';
  }

  return url.href;
};

const wsLink = () =>
  new GraphQLWsLink(
    createClient({
      url: getUrl(),
    })
  );

export default wsLink;
