import React, { useState, useRef, useContext } from 'react';
// libraries
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
// hooks
import useAuth from 'hooks/useAuth';
// context
import ToastContext from 'context/ToastContext';
// components
import Icon from 'components/atoms/Icon';
import Menu from 'components/molecules/NewMenu';
import ClickableElement from 'components/atoms/ClickableElement';
// helpers
import { getRoleText } from 'helpers/NameMapping';
import { switchRole } from './helpers';
// styles
import styles from './header.module.scss';

const RoleSwitcher = () => {
  const { t } = useTranslation();
  const { addToast } = useContext(ToastContext);
  const history = useHistory();
  const user = useAuth();
  const roles = user.getRoles();
  const hasMoreRoles = roles.length > 1;
  const element = useRef();

  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedRole, setRole] = useState(null);

  const changeRole = async role => {
    setRole(role);
    setLoading(true);

    try {
      const roleId = user.projectRoles.find(projectRole => projectRole.role === role).id;
      await switchRole(user.id, roleId, role);
      setOpen(false);
      history.push('/');
    } catch (error) {
      addToast.error(t('errorPlaceholderText'));
    } finally {
      setLoading(false);
      setRole(null);
    }
  };

  const handleClose = () => {
    if (loading) {
      return;
    }

    setOpen(false);
  };

  const items = roles
    .filter(role => role !== user.role)
    .map(role => ({
      label: getRoleText(role),
      onClick: () => changeRole(role),
      loading: selectedRole === role,
      disabled: !!selectedRole,
    }));

  return (
    <React.Fragment>
      <ClickableElement
        disabled={!hasMoreRoles}
        onClick={() => setOpen(true)}
        className={styles.role}
        ref={element}
      >
        {getRoleText(user.role)}
        {hasMoreRoles && <Icon size={18} name={isOpen ? 'less' : 'expandMore'} />}
      </ClickableElement>
      <Menu
        isOpen={isOpen}
        onClose={handleClose}
        anchorEl={element.current}
        transformOrigin={{ vertical: -10, horizontal: 'right' }}
        items={items}
      />
    </React.Fragment>
  );
};

export default RoleSwitcher;
