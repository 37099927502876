/* eslint-disable import/order */
import React, { useState, useContext, useEffect } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import getProperty from 'lodash/get';
import moment from 'moment';
// hooks
import useAuth from 'hooks/useAuth';
// context
import ToastContext from 'context/ToastContext';
// constants
import { SUBMISSION_STATUS } from 'constants/submissionStatus';
import { POSITION_STATUS } from 'constants/positionStatus';
import { FIELD } from './constants';
// components
import NumberInput from 'components/atoms/NumberInput';
import Textarea from 'components/atoms/Textarea';
import DatePicker from 'components/atoms/DatePickerCustom';
import RadioList from 'components/molecules/RadioList';
import Field from 'components/molecules/FieldSection';
import Modal from 'components/molecules/ModalWindow';
import RejectionFields from './RejectionFields';
// helpers
import {
  fetchSubmissions,
  selectWinner,
  validateReselectWonBid,
  validateReselectLostBid,
} from './helpers';
// styles
import styles from './selectWinner.module.scss';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  position: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

const ReselectWinner = ({ isOpen, position, onClose }) => {
  const { id: userId } = useAuth();
  const { t } = useTranslation();
  const { addToast } = useContext(ToastContext);

  const initialData = {
    [FIELD.SUBMISSION_WON]: {
      value: null,
    },
    [FIELD.SELECTED_SUBMISSION]: {
      value: '',
    },
    [FIELD.NOT_SELECTED_SUBMISSIONS]: {
      value: [],
    },
    [FIELD.HAS_REJECTION_REASON]: {
      value: null,
    },
    [FIELD.SAME_REJECTION_REASON]: {
      value: null,
    },
    [FIELD.REASON]: {
      value: '',
    },
    [FIELD.REASONS]: {
      value: {},
    },
    [FIELD.START_DATE]: {
      value: position.duration.start,
    },
    [FIELD.JUSTIFICATION]: {
      value: '',
    },
    [FIELD.TOTAL_EVALUATED_PRICE]: {
      value: 0,
    },
  };

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [formTouched, setFormTouched] = useState(false);
  const [submissions, setSubmissions] = useState([]);
  const [formData, setFormData] = useState(initialData);

  const positionStatus = position.getStatus();
  const previouslyWon = [
    POSITION_STATUS.OFFER,
    POSITION_STATUS.ONBOARDING,
    POSITION_STATUS.DELIVERY,
  ].includes(positionStatus.value);
  const currentlyWon = formData[FIELD.SUBMISSION_WON].value === true;
  const currentlyLost = formData[FIELD.SUBMISSION_WON].value === false;
  const previouslyLost = [
    POSITION_STATUS.NO_SELECTION,
    POSITION_STATUS.ONBOARDING_CANCELLED,
  ].includes(positionStatus.value);
  const displayStartDateField = currentlyWon || (previouslyLost && submissions.length > 0);
  const boolOptions = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ];
  const selectedSubmissions = submissions.filter(submission =>
    [SUBMISSION_STATUS.ONBOARDING, SUBMISSION_STATUS.OFFER].includes(submission.getStatus().value)
  );
  const rejectedSubmissions = submissions.filter(submission =>
    [SUBMISSION_STATUS.NOT_SELECTED, SUBMISSION_STATUS.ONBOARDING_CANCELLED].includes(
      submission.getStatus().value
    )
  );
  const submissionsListOptions = rejectedSubmissions.map(submission => ({
    value: submission.id,
    label: submission.getName(),
  }));

  useEffect(() => {
    const fetchSubmissionsFn = async () => {
      setLoading(true);

      try {
        const fetchedSubmissions = await fetchSubmissions(
          position.id,
          [
            SUBMISSION_STATUS.NOT_SELECTED,
            SUBMISSION_STATUS.ONBOARDING_CANCELLED,
            SUBMISSION_STATUS.ONBOARDING,
            SUBMISSION_STATUS.OFFER,
          ],
          position.isFixedPrice()
        );

        setSubmissions(fetchedSubmissions);
      } catch (error) {
        addToast.error(t('errorPlaceholderText'));
      } finally {
        setLoading(false);
      }
    };

    if (isOpen) {
      fetchSubmissionsFn();
    }
  }, [isOpen]);

  const resetChanges = () => {
    setFormTouched(false);
    setFormData(initialData);
    setSubmissions([]);
  };

  const validateFormData = data => {
    const validateSubmissionWonField = previouslyWon && submissions.length > 0;

    return previouslyWon && (submissions.length === 0 || currentlyLost)
      ? validateReselectLostBid(data)
      : validateReselectWonBid(data, validateSubmissionWonField);
  };

  const handleFormDataChange = data => {
    if (formTouched) {
      const validatedData = validateFormData(data);
      setFormData(validatedData);
    } else {
      setFormData(data);
    }
  };

  const handleChange = field => value => {
    handleFormDataChange({ ...formData, [field]: { ...formData[field], value } });
  };

  const handleSubmissionSelection = submissionId => {
    const selectedSubmission = submissions.find(submission => submissionId === submission.id);

    setFormData({
      ...formData,
      [FIELD.SELECTED_SUBMISSION]: {
        ...formData[FIELD.SELECTED_SUBMISSION],
        value: selectedSubmission,
      },
      [FIELD.NOT_SELECTED_SUBMISSIONS]: {
        ...formData[FIELD.NOT_SELECTED_SUBMISSIONS],
        value: selectedSubmission
          ? selectedSubmissions.filter(
              submission =>
                submissionId !== submission.id &&
                submission.getStatus().value !== SUBMISSION_STATUS.NOT_SELECTED
            )
          : [],
      },
    });
  };

  const handleOurCandidateHasWonChange = submissionWon => {
    setFormData({
      ...formData,
      [FIELD.SUBMISSION_WON]: {
        ...formData[FIELD.SUBMISSION_WON],
        value: submissionWon,
      },
      [FIELD.SELECTED_SUBMISSION]: {
        ...formData[FIELD.SELECTED_SUBMISSION],
        value: null,
      },
      [FIELD.NOT_SELECTED_SUBMISSIONS]: {
        ...formData[FIELD.NOT_SELECTED_SUBMISSIONS],
        value: submissionWon ? [] : selectedSubmissions,
      },
      [FIELD.HAS_REJECTION_REASON]: {
        value: null,
      },
      [FIELD.SAME_REJECTION_REASON]: {
        value: null,
      },
      [FIELD.REASON]: {
        value: '',
      },
      [FIELD.REASONS]: {
        value: {},
      },
    });
  };

  const validateData = () => {
    const validatedFormData = validateFormData(formData);
    const hasError = Object.values(validatedFormData).some(({ error }) => error);
    handleFormDataChange(validatedFormData);

    if (!formTouched) {
      setFormTouched(true);
    }

    return hasError;
  };

  const handleSubmit = async () => {
    const hasError = validateData();
    if (hasError) {
      return;
    }

    try {
      setSubmitting(true);
      await selectWinner(formData, position, userId);
      resetChanges();
      onClose();
      addToast.success(t('winnerReSelectedSuccess'));
    } catch (error) {
      addToast.error(t('errorPlaceholderText'));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      loading={loading || submitting}
      loadingText={loading ? t('fetchingDataLoader') : t('submittingLoader')}
    >
      <Modal.Header>
        {t('reselectWinner')} - {position.rfqLabel}
      </Modal.Header>
      <Modal.Body>
        <Field
          title={t('provideJustificationForReSelection')}
          classes={{ content: 'm-t-12' }}
          error={formData[FIELD.JUSTIFICATION].error}
          errorMessages={formData[FIELD.JUSTIFICATION].errorMessages}
        >
          <Textarea
            value={formData[FIELD.JUSTIFICATION].value}
            onChange={handleChange(FIELD.JUSTIFICATION)}
            rows={5}
          />
        </Field>
        {previouslyWon && submissions.length > 0 && (
          <div className="m-t-30">
            <Field
              title={t('didOurCandidateWin')}
              classes={{ content: styles.sectionContent }}
              error={formData[FIELD.SUBMISSION_WON].error}
              errorMessages={formData[FIELD.SUBMISSION_WON].errorMessages}
            >
              <RadioList
                options={boolOptions}
                selected={formData[FIELD.SUBMISSION_WON].value}
                onChange={handleOurCandidateHasWonChange}
              />
            </Field>
          </div>
        )}
        {previouslyWon && (submissions.length === 0 || currentlyLost) && (
          <div className="m-t-30">
            <Field
              title={t('totalEvaluatedPrice')}
              classes={{ content: styles.sectionContent }}
              error={formData[FIELD.TOTAL_EVALUATED_PRICE].error}
              errorMessages={formData[FIELD.TOTAL_EVALUATED_PRICE].errorMessages}
            >
              <NumberInput
                min={0}
                step={1}
                value={formData[FIELD.TOTAL_EVALUATED_PRICE.value]}
                onChange={handleChange(FIELD.TOTAL_EVALUATED_PRICE)}
                className={styles.totalPrice}
                floatLeft
              />
            </Field>
          </div>
        )}
        {submissions.length > 0 && (currentlyWon || previouslyLost) && (
          <div className="m-t-30">
            <Field
              title={t('submissions')}
              description={t('pleaseSelectWinningProposal')}
              classes={{ content: 'm-t-12' }}
              error={formData[FIELD.SELECTED_SUBMISSION].error}
              errorMessages={formData[FIELD.SELECTED_SUBMISSION].errorMessages}
            >
              <div className={styles.checkListWrapper}>
                <RadioList
                  options={submissionsListOptions}
                  selected={getProperty(formData, [FIELD.SELECTED_SUBMISSION, 'value', 'id'])}
                  onChange={handleSubmissionSelection}
                />
              </div>
            </Field>
          </div>
        )}
        <RejectionFields formData={formData} onChange={setFormData} />
        {displayStartDateField && (
          <div className="m-t-30">
            <Field
              title={t('expectedStartDateLabel')}
              description={t('expectedStartDateDescription')}
              classes={{ content: 'm-t-12' }}
            >
              <DatePicker
                value={formData[FIELD.START_DATE].value}
                onChange={handleChange(FIELD.START_DATE)}
                minDate={moment()}
              />
            </Field>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Modal.Buttons
          primaryLabel={t('submit')}
          primaryAction={handleSubmit}
          secondaryAction={onClose}
        />
      </Modal.Footer>
    </Modal>
  );
};

ReselectWinner.propTypes = propTypes;

export default ReselectWinner;
