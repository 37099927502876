// services
import logger from 'services/Logger';
import notification from 'services/Notification';
import { sendEmail } from 'services/Mailer';
// helpers
import { closeSubmissionStatus } from 'helpers/Submission';
import { getSubmissionLink } from 'helpers/Link';
// constants
import { SUBMISSION_TAB } from 'constants/pageTabs';
import { ONBOARDING_TAB } from 'constants/onboarding';

/**
 * Confirm Purchase Order to Supplier by Mach Manager
 * Step: Purchase Order - Send PO Confirmation
 * Role: Match Manager
 *
 * Actions:
 * 1. Put end date to latest hiring status
 * 2. Update basic candidate status to Contractor
 *
 * @param {object} data
 * @param {object} data.submission
 * @param {string} data.currentOnboardingStatusId Latest hiring status id
 * @param {object} data.email Html + email options (to, from, subject..)
 * @param {string} data.userId Logged in user id
 */
const confirmPurchaseOrder = async ({ submission, currentOnboardingStatusId, email, userId }) => {
  try {
    await closeSubmissionStatus({
      submissionId: submission.id,
      oldStatusId: currentOnboardingStatusId,
      isProposal: submission.isProposal(),
    });

    // Notify supplier about PO
    const link = getSubmissionLink(submission.id, submission.isProposal(), {
      queryParams: {
        tab: SUBMISSION_TAB.ONBOARDING,
        step: ONBOARDING_TAB.PO,
      },
    });

    notification.onboardingPurchaseOrderReceived({
      submission: submission.id,
      link,
      createdBy: userId,
      isProposal: submission.isProposal(),
    });

    // send email confirmation to PM
    sendEmail(email);
  } catch (error) {
    logger.exception(error);
    throw error;
  }
};

export default confirmPurchaseOrder;
