import React, { useState, useEffect } from 'react';
// libraries
import PropTypes from 'prop-types';
// component
import NodeComponent from 'components/atoms/NodeComponent';
// chart
import getScoreCircle from './outerScoreCircle';

const propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  median: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

const OuterScore = ({ min, median, max, color }) => {
  const [node, setNode] = useState();

  useEffect(() => {
    setNode(getScoreCircle({ min, median, max, color }));
  }, [min, median, max, color]);

  return <NodeComponent node={node} />;
};

OuterScore.propTypes = propTypes;

export default OuterScore;
