import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// components
import ClickableElement from 'components/atoms/ClickableElement';
// styles
import styles from './tabs.module.scss';

const propTypes = {
  selected: PropTypes.string.isRequired,
  onSwitch: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

const Tabs = ({ selected, onSwitch, items }) => {
  return (
    <div className={styles.tabs}>
      {items.map((tab, index) => {
        return (
          <ClickableElement
            key={tab.id}
            onClick={() => onSwitch(tab.id)}
            className={classNames(styles.tab, {
              [styles.active]: selected === tab.id,
              'm-0': !index,
            })}
          >
            {tab.label}
          </ClickableElement>
        );
      })}
    </div>
  );
};

Tabs.propTypes = propTypes;

export default Tabs;
