import getProperty from 'lodash/get';
import moment from 'moment';
// helpers
import { getMetrics, isExcellentMatch, hasEnoughDataPoints } from 'helpers/MatchMetrics';
import positionParser from 'helpers/PositionParser';
// constants
import { POSITION_STATUS } from 'constants/positionStatus';
import { FILTER_BY, SORT_BY, ORDER } from './constants';

export const formatFilter = (candidateId, supplierId, filterBy = {}) => {
  const selectedClearances = filterBy[FILTER_BY.CLEARANCE] || [];
  const location = filterBy[FILTER_BY.LOCATION];

  const filter = {
    candidate: {
      id: { equals: candidateId },
    },
    position: {
      // don't fetch matches which are already submitted to position
      submissions: { none: { candidate: { id: { equals: candidateId } } } },
      supplierDueDate: {
        gte: moment().toISOString(),
      },
      statuses: {
        some: {
          name: { equals: POSITION_STATUS.PUBLISHED },
          end: { is_empty: true },
        },
      },
    },
    AND: [
      {
        position: {
          OR: [
            {
              publishedToAll: { equals: true },
            },
            {
              publishedTo: { some: { id: { equals: supplierId } } },
            },
          ],
        },
      },
    ],
  };

  // filter by location
  if (location) {
    filter.AND.push({
      position: {
        location: {
          some: {
            city: {
              equals: location,
            },
          },
        },
      },
    });
  }

  // filter by clearance
  if (selectedClearances.length > 0) {
    filter.AND.push({
      position: {
        clearance: {
          id: {
            in: selectedClearances,
          },
        },
      },
    });
  }

  // search by position id/title
  if (filterBy[FILTER_BY.FULL_TEXT]) {
    filter.AND.push({
      position: {
        OR: [
          {
            rfq: {
              name: {
                contains: filterBy[FILTER_BY.FULL_TEXT],
              },
            },
          },
          {
            title: {
              contains: filterBy[FILTER_BY.FULL_TEXT],
            },
          },
        ],
      },
    });
  }

  return filter;
};

export const formatSort = sortObj => {
  let sort;

  switch (sortObj.value) {
    case SORT_BY.SCORE:
      sort = { report: { score: ORDER.DESC } };
      break;
    case SORT_BY.POSITION_TITLE:
      sort = { position: { title: ORDER.ASC } };
      break;
    case SORT_BY.DUE_DATE:
      sort = { position: { supplierDueDate: ORDER.ASC } };
      break;
    case SORT_BY.START_DATE:
      sort = { position: { duration: { start: ORDER.ASC } } };
      break;
    case SORT_BY.PROJECT:
      sort = { position: { project: { name: ORDER.ASC } } };
      break;
    default:
      sort = { report: { score: ORDER.DESC } };
  }

  return sort;
};

export const parseCandidateMatches = data => {
  const metrics = getMetrics(getProperty(data, 'metrics.items', []));
  const percentile = getProperty(metrics, 'percentile', 0);
  const metricsCount = getProperty(metrics, 'count', 0);
  const displayExcellentMatch = isExcellentMatch(percentile) && hasEnoughDataPoints(metricsCount);

  const report = getProperty(data, 'report', {});
  const position = positionParser(getProperty(data, 'position', {}));

  return {
    match: {
      id: getProperty(data, 'id', ''),
      displayExcellentMatch,
      report,
      metrics,
    },
    position,
  };
};
