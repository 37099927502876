import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
// components
import TextSection from 'components/atoms/TextSection';
// helper
import { getTimeYear } from 'helpers/RelativeTimeFormatter';
// constants
import { SUBMITTED_BY, SUBMITTED_TO_CLIENT_BY } from './constants';

const propTypes = {
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  type: PropTypes.oneOf([SUBMITTED_BY, SUBMITTED_TO_CLIENT_BY]).isRequired,
};

const SubmittedByInfo = ({ name, date, type }) => {
  const { t } = useTranslation();

  const submissionDate = getTimeYear(date);
  const title = type === SUBMITTED_BY ? t('submittedBy') : t('submittedToClientBy');

  return (
    <TextSection title={title}>
      <Trans i18nKey="submittedByInfo">
        {{ name }} on <b>{{ submissionDate }}</b>
      </Trans>
    </TextSection>
  );
};

SubmittedByInfo.propTypes = propTypes;

export default SubmittedByInfo;
