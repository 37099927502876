import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// components
import Icon from 'components/atoms/Icon';
// helpers
import { getCookieTypeLabel } from '../helpers';
// constants
import { COOKIE_TYPE } from '../constants';
// styles
import styles from './trackingInfo.module.scss';

const propTypes = {
  label: PropTypes.string.isRequired,
  tracked: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(Object.values(COOKIE_TYPE)).isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const TrackingInfo = ({ label, tracked, className, type }) => {
  return (
    <div className={classNames(className, styles.wrapper)}>
      <div className={classNames(styles.icon, { [styles.tracked]: tracked })}>
        <Icon name={tracked ? 'checkOutline' : 'closeOutline'} size={16} />
      </div>
      <div className="m-l-10">
        <div className={styles.label}>{label}</div>
        <div className={styles.type}>{getCookieTypeLabel(type)}</div>
      </div>
    </div>
  );
};

TrackingInfo.propTypes = propTypes;
TrackingInfo.defaultProps = defaultProps;

export default TrackingInfo;
