/* eslint-disable import/order */
import React, { useContext, useState, useReducer, useMemo } from 'react';
// libraries
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// hooks
import useAuth from 'hooks/useAuth';
// constant
import { MAIN_RECRUITING } from 'constants/positionStatus';
// helpers
import {
  getNewSubmissionsCount,
  getReceivedSubmissionsCount,
  getSubmittedSubmissionsCount,
} from 'helpers/Submission';
import { getYear } from 'helpers/RelativeTimeFormatter';
import { filterOutHiddenSubmissions, filterSubmissions, sortSubmissions } from './Filter';
// components
import Tooltip from 'components/atoms/Tooltip';
import Button from 'components/atoms/Button';
import SubmissionsCount from 'components/molecules/SubmissionsCount';
import { SubmitCandidates } from 'components/organisms/PositionSettings';
import CandidateFilter from './CandidateFilter';
import CandidateList from './List';
import CandidateListHeader from './ListHeader';
import NoCandidatesPlaceholder from './NoCandidatesPlaceholder';
// context
import MultipliersContext from '../MultipliersContext';
import { FilterProvider } from './FilterContext';
// data
import getSortingOptions from './SortingOptions';
import filteringOptions from './FilteringOptions';
// styles
import './candidates.scss';

const propTypes = {
  position: PropTypes.object.isRequired,
  maximumCandidatesAllowed: PropTypes.number.isRequired,
  submissions: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};

const SET_SELL_RATES = 'setSellRates';
const SET_CANDIDATE_GPS = 'setCandidateGPs';
const HIDE_CANDIDATE = 'hideCandidate';
const SHOW_ALL_CANDIDATES = 'showAllCandidates';

const initialState = {
  sellRates: {},
  candidatesGP: {},
  hiddenCandidates: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case SET_SELL_RATES:
      return {
        ...state,
        sellRates: {
          ...state.sellRates,
          [action.id]: action.value.map(rate => ({
            value: rate.value,
            year: parseInt(getYear(rate.startDate)),
          })),
        },
      };
    case SET_CANDIDATE_GPS:
      return {
        ...state,
        candidatesGP: {
          ...state.candidatesGP,
          [action.id]: action.value,
        },
      };
    case HIDE_CANDIDATE:
      return {
        ...state,
        hiddenCandidates: [...state.hiddenCandidates, action.id],
      };
    case SHOW_ALL_CANDIDATES:
      return {
        ...state,
        hiddenCandidates: [],
      };
    default:
      return state;
  }
};

const Submissions = ({ maximumCandidatesAllowed, submissions, position }) => {
  const sortingOptions = getSortingOptions(position.isFixedPrice());

  const { t } = useTranslation();
  const { nteRates } = useContext(MultipliersContext);
  const { role, supplierId } = useAuth();
  const [isSubmitCandidatesModalOpen, setSubmitCandidatesModalOpen] = useState(false);
  const [sortBy, setSortBy] = useState(sortingOptions[0].value);
  const [filterBy, setFilterBy] = useState(filteringOptions[0].value);
  const [state, dispatch] = useReducer(reducer, initialState);

  const { hiddenCandidates, candidatesGP } = state;
  const years = nteRates.map(rate => getYear(rate.startDate));
  const visibleCandidates = filterOutHiddenSubmissions(submissions, hiddenCandidates);
  const filteredCandidates = filterSubmissions(visibleCandidates, filterBy);
  const sortedCandidates = sortSubmissions(filteredCandidates, sortBy, candidatesGP);
  const numberOfNewCandidates = getNewSubmissionsCount(submissions);
  const sellRates = Object.keys(state.sellRates).map(id => ({
    id,
    rates: state.sellRates[id],
  }));

  const filters = useMemo(() => {
    return {
      sellRates,
      setSellRates: (id, rates) => dispatch({ type: SET_SELL_RATES, id, value: rates }),
      setCandidateGPs: (id, GPs) => dispatch({ type: SET_CANDIDATE_GPS, id, value: GPs }),
      hideCandidate: id => dispatch({ type: HIDE_CANDIDATE, id }),
      showAllCandidates: () => dispatch({ type: SHOW_ALL_CANDIDATES }),
    };
  }, [sellRates]);

  const submittedCandidatesTitle = (
    <SubmissionsCount
      role={role}
      textBefore={`${t('submissions')} (`}
      textAfter=")"
      received={getReceivedSubmissionsCount(submissions, role, supplierId)}
      submitted={getSubmittedSubmissionsCount(submissions, role, supplierId)}
      allowed={maximumCandidatesAllowed}
    />
  );

  if (submissions.length === 0) {
    return null;
  }

  return (
    <React.Fragment>
      <div className="space-12 pageHeading">{t('submissions')}</div>
      <div className="oneRow">
        <CandidateFilter
          sortingOptions={sortingOptions}
          selectedFilterBy={filterBy}
          onSelectedFilterBy={setFilterBy}
          selectedSortBy={sortBy}
          onSortByChange={setSortBy}
          numberOfHiddenCandidates={state.hiddenCandidates.length}
          onShowHiddenCandidatesClick={filters.showAllCandidates}
        />
        {position.getMainStatus(role, supplierId).value === MAIN_RECRUITING && (
          <React.Fragment>
            <Tooltip title={!numberOfNewCandidates ? t('noCandidatesToSubmitTooltip') : ''}>
              <div className="positionCandidates_button">
                <Button
                  label={t('submitToClient')}
                  onClick={() => setSubmitCandidatesModalOpen(true)}
                  className="fs-unmask"
                  disabled={!numberOfNewCandidates}
                />
              </div>
            </Tooltip>
            <SubmitCandidates
              isOpen={isSubmitCandidatesModalOpen}
              onClose={() => setSubmitCandidatesModalOpen(false)}
              positionId={position.id}
              className="fs-unmask"
              sellRates={sellRates}
            />
          </React.Fragment>
        )}
      </div>
      <FilterProvider value={filters}>
        {sortedCandidates.length > 0 ? (
          <div className="positionCandidates m-t-20">
            <div className="positionCandidates_list">
              <CandidateListHeader title={submittedCandidatesTitle} years={years} />
              <CandidateList submissions={sortedCandidates} />
            </div>
          </div>
        ) : (
          <div className="m-t-20">
            <NoCandidatesPlaceholder filterBy={filterBy} />
          </div>
        )}
      </FilterProvider>
    </React.Fragment>
  );
};

Submissions.propTypes = propTypes;

export default Submissions;
