import * as d3 from 'd3';
import i18n from 'i18next';
import classNames from 'classnames';
import maxBy from 'lodash/maxBy';
import inRange from 'lodash/inRange';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
// constants
import { COLOR_RED, COLOR_YELLOW, COLOR_BLUE, COLOR_GREEN } from 'constants/colors';
// styles
import colors from 'dependencies/materialStyles/Colors';
import styles from './distributionChart.module.scss';

const HEIGHT = 200;
const MARGIN = {
  TOP: 35,
  BOTTOM: 35,
  LEFT: 30,
  RIGHT: 10,
};

const getCandidateScoreColor = color => {
  switch (color) {
    case COLOR_RED:
      return colors.red6;
    case COLOR_YELLOW:
      return colors.yellow6;
    case COLOR_BLUE:
      return colors.blue6;
    case COLOR_GREEN:
      return colors.green6;
    default:
      return colors.gray6;
  }
};

/**
 * Group data points by occurrence and score
 * @param {number[]} dataPoints
 * @returns {object[]}
 */
export const groupScores = dataPoints => {
  return map(groupBy(dataPoints), (scoreList, score) => {
    return { count: scoreList.length, score: parseInt(score) };
  });
};

/**
 * If candidate and median scores are close to each other -> display only candidate score
 * @param {number} median
 * @param {number} score
 * @returns {boolean}
 */
const includeMedianTick = (median, score) => {
  return !inRange(median, score - 3, score + 3);
};

/**
 * Get scores which will be displayed on X axis
 * @param {number} median
 * @param {number} score
 * @returns {number[]}
 */
const getScoreTicks = (median, score) => {
  const SCORE_TICKS = [0, 20, 40, 60, 80, 100];

  // don't show default score tick if median score or candidate score is near to default tick
  const ticks = SCORE_TICKS.filter(tick => {
    return !inRange(median, tick - 3, tick + 3) && !inRange(score, tick - 3, tick + 3);
  });

  if (includeMedianTick(median, score)) {
    ticks.push(median);
  }

  return [...ticks, score].sort((a, b) => a - b);
};

/**
 * Calculate rendered candidate's name width
 * @param {string} name
 * @returns {number}
 */
const getCandidateNameWidth = name => {
  // create a temporary node and append it to body
  let size = 0;
  const node = document.createElement('div');
  document.body.appendChild(node);

  const textNode = d3
    .select(node)
    .append('svg')
    .append('g')
    .append('text')
    .attr('class', classNames(styles.label, styles.labelCenter))
    .text(name);

  // get text width
  size = Math.ceil(textNode.node().getComputedTextLength());

  // remove temporary node
  node.remove();

  return size;
};

/**
 * Get position offset for candidate name where to display on X axis to not overflow chart or median label
 * @param {number} svgWidth
 * @param {number} medianPosition
 * @param {number} scorePosition
 * @param {number} nameWidth
 * @returns
 */
const getCandidateNameOffset = (svgWidth, medianPosition, scorePosition, nameWidth) => {
  const fitLeft = scorePosition - nameWidth / 2 - MARGIN.LEFT > 0;
  const fitRight = scorePosition + nameWidth / 2 < svgWidth;
  const fitCenter = fitLeft && fitRight;

  if (fitCenter) {
    const mnamePositionStart = scorePosition - nameWidth / 2;
    const mnamePositionEnd = scorePosition + nameWidth / 2;

    // centered name overflow median from left side
    if (medianPosition < scorePosition && medianPosition > mnamePositionStart) {
      // still fit right
      if (medianPosition + 8 + nameWidth < svgWidth) {
        return medianPosition + 8;
      }
    }

    // centered name overflow median from right side
    if (medianPosition > scorePosition && medianPosition < mnamePositionEnd) {
      // still fit left
      if (medianPosition - 8 - nameWidth - MARGIN.LEFT > 0) {
        return medianPosition - 8 - nameWidth;
      }
    }

    return scorePosition - nameWidth / 2;
  }
  if (!fitLeft) {
    return MARGIN.LEFT;
  }

  return svgWidth - nameWidth;
};

/**
 * @param {object} params
 * @param {number} params.width Container width
 * @param {number} params.median
 * @param {number} params.mean
 * @param {number} params.score
 * @param {string} params.candidateName
 * @param {string} params.matchColor
 * @param {number[]} params.dataPoints
 * @param {func} params.onMouseEnter
 * @param {func} params.onMouseLeave
 * @returns node
 */
const getDistributionBarChart = ({
  width,
  median,
  mean,
  score,
  candidateName,
  matchColor,
  dataPoints,
  onMouseEnter,
  onMouseLeave,
}) => {
  const data = groupScores(dataPoints);
  const scoreColor = getCandidateScoreColor(matchColor);
  const maxCount = maxBy(data, 'count').count;

  const node = document.createElement('div');
  const svg = d3
    .select(node)
    .append('svg')
    .attr('width', width)
    .attr('height', HEIGHT);

  // gradient def
  const defs = svg.append('defs');
  const gradient = defs
    .append('linearGradient')
    .attr('id', 'grayGradient')
    .attr('x1', 0)
    .attr('x2', 0)
    .attr('y1', 0)
    .attr('y2', 1);
  gradient
    .append('stop')
    .attr('id', 'end')
    .attr('offset', '0%')
    .attr('stop-color', colors.gray6);
  gradient
    .append('stop')
    .attr('id', 'start')
    .attr('offset', '100%')
    .attr('stop-color', colors.gray4);

  // needed for bar hover effect
  const transitionGradient = defs
    .append('linearGradient')
    .attr('id', 'grayGradientForTransition')
    .attr('x1', 0)
    .attr('x2', 0)
    .attr('y1', 0)
    .attr('y2', 1);
  transitionGradient
    .append('stop')
    .attr('id', 'end')
    .attr('offset', '0%')
    .attr('stop-color', colors.gray6);
  transitionGradient
    .append('stop')
    .attr('id', 'start')
    .attr('offset', '100%')
    .attr('stop-color', colors.gray5);

  const scaleX = d3
    .scaleBand()
    .domain(d3.range(101))
    .range([MARGIN.LEFT, width - MARGIN.RIGHT])
    .paddingInner(0.3)
    .paddingOuter(1);

  // generate ticks for X axis
  const tickValues = getScoreTicks(median, score);
  const xAxis = d3
    .axisBottom(scaleX)
    .tickValues(tickValues)
    .tickPadding(8)
    .tickSize(0);

  // add X axis
  const xAxisNode = svg
    .append('g')
    .attr('transform', `translate(0, ${HEIGHT - MARGIN.BOTTOM})`)
    .attr('class', styles.axisX)
    .call(xAxis);

  // add class for median score and candidate score tick
  xAxisNode
    .selectAll('g')
    .filter(value => value === median)
    .select('text')
    .attr('class', styles.medianScore);
  xAxisNode
    .selectAll('g')
    .filter(value => value === score)
    .select('text')
    .attr('class', styles.score)
    .style('fill', scoreColor);

  // add label for X axis
  svg
    .append('g')
    .attr('transform', `translate(${width - 8}, ${HEIGHT})`)
    .append('text')
    .attr('class', classNames(styles.label, styles.labelEnd))
    .text(i18n.t('score'));

  // add mean score label
  svg
    .append('g')
    .attr('transform', `translate(23 ${HEIGHT - 2})`)
    .append('text')
    .attr('class', styles.label)
    .text(`* ${i18n.t('average')} = ${mean}`);

  const scaleY = d3
    .scaleLinear()
    .domain([maxCount, 0])
    .range([MARGIN.BOTTOM, HEIGHT - MARGIN.TOP]);

  // display only integer ticks
  const yAxisTicks = scaleY.ticks(4).filter(tick => Number.isInteger(tick));

  // generate ticks for Y axis
  const yAxis = d3
    .axisLeft(scaleY)
    .tickValues(yAxisTicks)
    .tickFormat(d3.format('d'))
    .tickPadding(8)
    .tickSize((width - MARGIN.LEFT - MARGIN.RIGHT) * -1);

  // add Y axis
  svg
    .append('g')
    .attr('transform', `translate(${MARGIN.LEFT}, 0)`)
    .attr('class', styles.axisY)
    .call(yAxis);

  // add label for Y axis
  svg
    .append('g')
    .attr('transform', `translate(0, 10)`)
    .append('text')
    .attr('class', styles.label)
    .text(i18n.t('candidates'));

  // don't add line line for median if it's same as score
  if (median !== score) {
    // add line for median score
    svg
      .append('line')
      .attr('x1', scaleX(median) + scaleX.bandwidth() / 2)
      .attr('y1', HEIGHT - MARGIN.BOTTOM)
      .attr('x2', scaleX(median) + scaleX.bandwidth() / 2)
      .attr('y2', 18)
      .attr('class', styles.medianLine);

    // add label for median score
    svg
      .append('g')
      .attr('transform', `translate(${scaleX(median)}, 10)`)
      .append('text')
      .attr('class', classNames(styles.medianLabel, styles.labelCenter))
      .text(i18n.t('median'));
  }

  // add line for candidate score
  svg
    .append('line')
    .attr('x1', scaleX(score) + scaleX.bandwidth() / 2)
    .attr('y1', HEIGHT - MARGIN.BOTTOM)
    .attr('x2', scaleX(score) + scaleX.bandwidth() / 2)
    .attr('y2', MARGIN.TOP)
    .attr('class', styles.scoreLine)
    .style('stroke', scoreColor);

  const candidateNameWidth = getCandidateNameWidth(candidateName);
  const candidateNameOffset = getCandidateNameOffset(
    width,
    scaleX(median),
    scaleX(score),
    candidateNameWidth
  );

  // add candidate name for score
  const candidateLabelNode = svg
    .append('g')
    .attr('transform', `translate(${candidateNameOffset}, ${MARGIN.TOP - 8})`);

  // white background for candidate name to overlap median line
  candidateLabelNode
    .append('text')
    .attr('class', styles.label)
    .style('stroke', 'white')
    .style('stroke-width', '2')
    .text(candidateName);

  // candidate name
  candidateLabelNode
    .append('text')
    .attr('class', styles.label)
    .style('fill', scoreColor)
    .text(candidateName);

  const getBarColor = barScore => {
    if (barScore === score) {
      return scoreColor;
    }
    if (barScore === median) {
      return colors.gray8;
    }

    return 'url(#grayGradient)';
  };

  const getBarHeight = count => {
    return HEIGHT - MARGIN.BOTTOM - scaleY(count);
  };

  // append data
  const bars = svg
    .selectAll('rect')
    .data(data)
    .enter()
    .append('rect')
    .attr('x', d => scaleX(d.score))
    .attr('y', scaleY(0))
    .attr('width', scaleX.bandwidth())
    .attr('height', 0)
    .attr('fill', d => getBarColor(d.score))
    // hover effect
    .on('mouseenter', (event, d) => {
      onMouseEnter(event, d);

      if (d.score === score || d.score === median) {
        d3.select(event.target)
          .transition()
          .duration(300)
          .ease(d3.easeLinear)
          .attr('y', () => scaleY(d.count) - 5)
          .attr('height', () => getBarHeight(d.count) + 5);
      } else {
        d3.select(event.target)
          .transition()
          .duration(0)
          .style('fill', 'url(#grayGradientForTransition)')
          .on('end', () => {
            transitionGradient
              .selectAll('stop')
              .transition()
              .duration(300)
              .attr('stop-color', colors.gray7);
          })
          .transition()
          .duration(300)
          .ease(d3.easeLinear)
          .attr('y', () => scaleY(d.count) - 5)
          .attr('height', () => getBarHeight(d.count) + 5);
      }
    })
    .on('mouseleave', (event, d) => {
      onMouseLeave();

      if (d.score === score || d.score === median) {
        d3.select(event.target)
          .transition()
          .duration(300)
          .ease(d3.easeLinear)
          .attr('y', () => scaleY(d.count))
          .attr('height', () => getBarHeight(d.count));
      } else {
        d3.select(event.target)
          .transition()
          .duration(300)
          .ease(d3.easeLinear)
          .style('fill', () => {
            transitionGradient.select('#start').attr('stop-color', colors.gray5);

            return 'url(#grayGradient)';
          })
          .attr('y', () => scaleY(d.count))
          .attr('height', () => getBarHeight(d.count));
      }
    });

  // animation for bars
  bars
    .transition()
    .duration(400)
    .delay((_d, index) => index * 10)
    .ease(d3.easeCubic)
    .attr('y', d => scaleY(d.count))
    .attr('height', d => getBarHeight(d.count));

  return node;
};

export default getDistributionBarChart;
