import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
// components
import Nbsp from 'components/atoms/Nbsp';
import Placeholder from 'components/atoms/Placeholder';
import CandidatesMatchRow from './CandidateMatchRow';
// styles
import styles from './candidatesMatchTable.module.scss';

const propTypes = {
  matches: PropTypes.arrayOf(PropTypes.object).isRequired,
  candidate: PropTypes.object.isRequired,
};

const CandidatesMatchTable = ({ matches, candidate }) => {
  const { t } = useTranslation();

  if (!matches.length) {
    return <Placeholder className={styles.noResults}>{t('noCandidatesPlaceholder')}</Placeholder>;
  }

  return (
    <div className={styles.table}>
      <div className={styles.grid}>
        <div className={classNames(styles.head, styles.center)}>{t('score')}</div>
        <div className={styles.head}>{t('cognitiveReport')}</div>
        <div className={styles.head}>{t('positionName')}</div>
        <div className={classNames(styles.head, styles.right)}>{t('dueDate')}</div>
        <div className={classNames(styles.head, styles.right)}>{t('targetRate')}</div>
        <div className={styles.head}>{t('location')}</div>
        <div className={styles.head}>{t('clearance')}</div>
        <div className={classNames(styles.head, styles.right)}>{t('startingDate')}</div>
        <div>
          <Nbsp />
        </div>
      </div>
      {matches.map((data, index) => (
        <div className={classNames({ 'm-t-2': index })} key={data.match.id}>
          <CandidatesMatchRow match={data.match} position={data.position} candidate={candidate} />
        </div>
      ))}
    </div>
  );
};

CandidatesMatchTable.propTypes = propTypes;

export default CandidatesMatchTable;
