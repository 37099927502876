// libraries
import i18n from 'i18next';
import getProperty from 'lodash/get';
// constants
import { FILE_JOB_OFFER, FILE_SIGNED_JOB_OFFER, MANAGER_ROLE } from 'constants';
import { OFFER_NOTE } from '../constants';

/**
 * @param {string} type
 * @returns {string}
 */
const getFileLabel = type => {
  const LABEL_MAP = {
    [FILE_JOB_OFFER]: i18n.t('jobOffer'),
    [FILE_SIGNED_JOB_OFFER]: i18n.t('signedJobOffer'),
  };

  return getProperty(LABEL_MAP, type, type);
};

export const getFiles = (submission, role) => {
  if (role !== MANAGER_ROLE) {
    return submission
      .getOnboardingDocuments()
      .filter(file => [FILE_JOB_OFFER, FILE_SIGNED_JOB_OFFER].includes(file.type))
      .map(file => ({
        label: getFileLabel(file.type),
        files: [file],
      }));
  }

  return [];
};

export const getNotes = (submission, date, role) => {
  const notes = [];
  const offerNote = getProperty(submission, 'hiringProcess.notes.items', []).find(
    note => note.noteType === OFFER_NOTE
  );

  if (date && date.note) {
    notes.push({
      label: i18n.t('note'),
      text: getProperty(date, 'note.note', ''),
      createdAt: getProperty(date, 'note.createdAt', ''),
      author: getProperty(date, 'note.author.person.fullName', ''),
    });
  }
  if (role !== MANAGER_ROLE && offerNote) {
    notes.push({
      label: i18n.t('offerNote'),
      text: getProperty(offerNote, 'note', ''),
      createdAt: getProperty(offerNote, 'createdAt', ''),
      author: getProperty(offerNote, 'author.person.fullName', ''),
    });
  }

  return notes;
};
