import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
// constants
import { CV_TEMPLATE_LINK } from 'constants/box';
import {
  MRI_ERROR_CODE_NO_SECTIONS_FOUND,
  MRI_IMPACT,
  MRI_LEVEL,
  MRI_SECTION,
} from 'constants/report';
// helpers
import { parseImprovement, getSectionTitle } from './helpers';
// components
import BadSection from './BadSection';
import GoodSection from './GoodSection';
// styles
import styles from './mriImprovements.module.scss';

const propTypes = {
  hideSectionsDescription: PropTypes.bool,
  goodSections: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOf(Object.values(MRI_SECTION)).isRequired,
      impact: PropTypes.oneOf(Object.values(MRI_IMPACT)).isRequired,
    })
  ),
  badSections: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOf(Object.values(MRI_SECTION)).isRequired,
      impact: PropTypes.oneOf(Object.values(MRI_IMPACT)).isRequired,
      penalty: PropTypes.number.isRequired,
      level: PropTypes.oneOf(Object.values(MRI_LEVEL)).isRequired,
      issues: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string.isRequired,
          code: PropTypes.string.isRequired,
        })
      ),
    })
  ),
};

const defaultProps = {
  goodSections: [],
  badSections: [],
  hideSectionsDescription: false,
};

const MRIImprovements = ({ hideSectionsDescription, goodSections, badSections }) => {
  const { t } = useTranslation();

  const allSectionsError = [...goodSections, ...badSections].find(
    ({ id }) => id === MRI_ERROR_CODE_NO_SECTIONS_FOUND
  );

  if (allSectionsError) {
    const improvement = parseImprovement(allSectionsError);

    return (
      <div>
        <div className={styles.factorDescription}>{t('mriNoSectionsFoundErrorDescription')}</div>
        <BadSection
          title={getSectionTitle(improvement.name)}
          level={improvement.level}
          impact={improvement.impact}
          penalty={improvement.penalty}
        >
          <div className={styles.allSectionsError}>
            <Trans i18nKey="mriAllSectionsErrorContent">
              Please revise your CV based on provided template{' '}
              <Link href={CV_TEMPLATE_LINK}>here</Link>. Make sure it includes following sections:
              <br />- Work history
              <br />- Certifications
              <br /> - Clearance
              <br /> - Education
              <br /> - Languages
            </Trans>
          </div>
        </BadSection>
      </div>
    );
  }

  return (
    <React.Fragment>
      {goodSections.length > 0 && (
        <div className={classNames({ 'm-b-20': badSections.length > 0 })}>
          {!hideSectionsDescription && (
            <div className={styles.factorDescription}>{t('mriGoodSectionsDescription')}</div>
          )}
          <div className={goodSections.length > 1 ? styles.goodSections : 'display-flex-row'}>
            {goodSections.map(({ name, impact }) => (
              <GoodSection key={name} title={getSectionTitle(name)} impact={impact} />
            ))}
          </div>
        </div>
      )}
      {badSections.length > 0 && (
        <div>
          {!hideSectionsDescription && (
            <div className={styles.factorDescription}>{t('mriBadSectionsDescription')}</div>
          )}
          {badSections.map(({ penalty, id, issues, name, level, impact }, index) => {
            return (
              <div key={id} className={classNames({ 'm-t-20': index })}>
                <BadSection
                  issues={issues}
                  title={getSectionTitle(name)}
                  level={level}
                  impact={impact}
                  penalty={penalty}
                />
              </div>
            );
          })}
        </div>
      )}
    </React.Fragment>
  );
};

MRIImprovements.propTypes = propTypes;
MRIImprovements.defaultProps = defaultProps;

export default MRIImprovements;
