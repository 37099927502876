import React from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
// components
import Button from 'components/atoms/Button';
// styles
import colors from 'dependencies/materialStyles/Colors';
import {
  activeCheckboxStyle,
  disabledCheckboxStyle,
} from 'dependencies/materialStyles/CheckboxStyle';

const propTypes = {
  item: PropTypes.object,
  selected: PropTypes.any,
  name: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

const defaultProps = {
  item: {},
  selected: {},
  name: '',
  onChange: () => {},
  disabled: false,
};

const Radio = props => {
  const { item, selected, name, onChange, disabled } = props;

  const Checked = (
    <Button
      type="radioChecked"
      disabled={disabled}
      color={disabled ? colors.disabledGray : colors.blue}
      size={18}
      iconStyle="icon18"
    />
  );
  const Unchecked = (
    <Button
      type="radioUnchecked"
      color={disabled ? colors.disabledGray : colors.gray}
      disabled={disabled}
      size={18}
      iconStyle="icon18"
    />
  );

  const isSelected =
    (!isNil(selected) && selected === item.value) ||
    (selected &&
      !isNil(selected.value) &&
      !isNil(item.value) &&
      item.label === selected.label &&
      item.value === selected.value) ||
    (selected &&
      !isNil(selected.id) &&
      !isNil(item.id) &&
      item.label === selected.label &&
      item.id === selected.id) ||
    (selected && !isNil(selected.value) && !isNil(item.value) && item.value === selected.value);

  const onRadioClick = () => {
    if (!disabled) {
      onChange(item, name);
    }
  };

  const checkboxStyle = disabled ? disabledCheckboxStyle : activeCheckboxStyle;

  return (
    <div
      role="presentation"
      style={{
        ...checkboxStyle.style,
        cursor: disabled ? 'not-allowed' : 'pointer',
      }}
      onClick={onRadioClick}
    >
      <div style={checkboxStyle.iconStyle}>{isSelected ? Checked : Unchecked}</div>
      <div style={checkboxStyle.labelStyle}>{item.label}</div>
    </div>
  );
};

Radio.propTypes = propTypes;
Radio.defaultProps = defaultProps;

export default Radio;
