/* eslint-disable import/order */
import React, { useState } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// helpers
import { boxFileUrl } from 'helpers/Box';
import { uploadDocument } from '../helpers';
// constants
import { SORT_BY } from '../constants';
// components
import Section from 'components/molecules/FieldSection';
import FileUpload from 'components/molecules/FileUpload';
import Table from '../Table';
import UnselectAction from '../UnselectAction';
import SortPopover, { SORT_TYPE } from '../SortPopover';
import PreviouslySavedLegend from '../PreviouslySavedLegend';
import Submission from './Submission';
// styles
import styles from './documents.module.scss';

const propTypes = {
  isProposal: PropTypes.bool.isRequired,
  submissions: PropTypes.arrayOf(PropTypes.object).isRequired,
  onReleasableSubmissionFileChange: PropTypes.func.isRequired,
  onReleasableAttestationChange: PropTypes.func.isRequired,
  onRateCardChange: PropTypes.func.isRequired,
  onSubmissionUnselect: PropTypes.func.isRequired,
  onSubmissionRestore: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  position: PropTypes.shape({
    title: PropTypes.string.isRequired,
    rfq: PropTypes.string.isRequired,
    project: PropTypes.string.isRequired,
    experienceLevel: PropTypes.string.isRequired,
    occupations: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  folder: PropTypes.string.isRequired,
  hasSavedSubmissions: PropTypes.bool.isRequired,
  rateCard: PropTypes.shape({
    boxId: PropTypes.string.isRequired,
    filename: PropTypes.string.isRequired,
  }),
};

const defaultProps = {
  rateCard: null,
};

const Documents = ({
  isProposal,
  rateCard,
  submissions,
  onReleasableSubmissionFileChange,
  onReleasableAttestationChange,
  onRateCardChange,
  onSubmissionUnselect,
  onSubmissionRestore,
  onSort,
  position,
  folder,
  hasSavedSubmissions,
}) => {
  const { t } = useTranslation();
  const [rateFile, setRateFile] = useState([]);
  const [loading, setLoading] = useState(false);

  const formatFile = file => ({
    boxId: file.boxId,
    filename: file.filename,
  });

  const getRateFile = () => {
    if (!rateCard && !rateFile) {
      return null;
    }
    if (!rateCard) {
      return rateFile;
    }

    return [
      {
        id: rateCard.boxId,
        name: rateCard.filename,
        link: boxFileUrl(rateCard.boxId),
      },
    ];
  };

  const handleReleasableSubmissionFileChange = candidateId => cv => {
    onReleasableSubmissionFileChange(candidateId, cv);
  };

  const handleReleasableAttestationChange = candidateId => attestation => {
    onReleasableAttestationChange(candidateId, attestation);
  };

  const handleSort = sortBy => order => {
    onSort(sortBy, order);
  };

  const handleRateCardChange = async file => {
    setRateFile(file);

    if (file.length > 0) {
      try {
        setLoading(true);
        const { filename, id } = await uploadDocument({
          file: file[0].file,
          folder,
          rfq: position.rfq,
          type: 'Rate Card',
        });

        onRateCardChange({ boxId: id, filename });
      } catch (error) {
        onRateCardChange(null);
      } finally {
        setLoading(false);
        setRateFile([]);
      }
    } else {
      onRateCardChange(null);
    }
  };

  return (
    <Section
      title={t('submitCandidatesDocumentsTitle')}
      description={t('submitCandidatesDocumentsDescription')}
    >
      <Table>
        <Table.Head
          className={classNames(styles.row, styles.head, { [styles.proposal]: isProposal })}
        >
          {!isProposal && (
            <Table.Title>
              <SortPopover type={SORT_TYPE.NUMBER} onSort={handleSort(SORT_BY.SCORE)}>
                {t('score')}
              </SortPopover>
            </Table.Title>
          )}
          <Table.Title>
            <SortPopover type={SORT_TYPE.STRING} onSort={handleSort(SORT_BY.NAME)}>
              {t('name')}
            </SortPopover>
          </Table.Title>
          <Table.Title>{t('currentProposal')}</Table.Title>
          <Table.Title>{t('releasableProposal')}</Table.Title>
          <Table.Title>{t('currentAttestation')}</Table.Title>
          <Table.Title>{t('releasableAttestation')}</Table.Title>
        </Table.Head>
        <Table.Body>
          {submissions.map(submission => {
            const currentSubmissionFile = submission.getSubmissionFile();
            const currentAttestation = submission.getAttestation();

            return (
              <Table.Row
                className={classNames(styles.row, { [styles.proposal]: isProposal })}
                key={submission.id}
                disabled
              >
                <Submission
                  isProposal={isProposal}
                  score={submission.score}
                  name={submission.getName()}
                  currentSubmissionFile={formatFile(currentSubmissionFile)}
                  releasableSubmissionFile={submission.releasableSubmissionFile}
                  currentAttestation={formatFile(currentAttestation)}
                  releasableAttestation={submission.releasableAttestation}
                  onReleasableSubmissionFileChange={handleReleasableSubmissionFileChange(
                    submission.id
                  )}
                  onReleasableAttestationChange={handleReleasableAttestationChange(submission.id)}
                  folder={folder}
                  disabled={submission.unselected}
                  position={position}
                  metrics={!isProposal ? submission.getMetrics() : null}
                  previouslySaved={submission.previouslySaved}
                />
                <UnselectAction
                  onUnselect={() => onSubmissionUnselect(submission.id)}
                  onRestore={() => onSubmissionRestore(submission.id)}
                  isRemoved={!!submission.unselected}
                />
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      {isProposal && (
        <Section
          title={t('submitProposalRateCardTitle')}
          description={t('submitProposalRateCardDescription')}
          classes={{
            root: 'm-t-30',
            content: 'm-t-10',
          }}
        >
          <FileUpload selected={getRateFile()} onChange={handleRateCardChange} loading={loading} />
        </Section>
      )}
      {hasSavedSubmissions && (
        <div className="m-t-8">
          <PreviouslySavedLegend />
        </div>
      )}
    </Section>
  );
};

Documents.propTypes = propTypes;
Documents.defaultProps = defaultProps;

export default Documents;
