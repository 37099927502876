/* eslint-disable import/order */
import React, { useState, useContext } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import getProperty from 'lodash/get';
// context
import ToastContext from 'context/ToastContext';
// hooks
import useAuth from 'hooks/useAuth';
import useModalProvider from 'hooks/useModalProvider';
// components
import IconDropdown from 'components/molecules/IconDropdown';
import DeleteCandidate from './DeleteCandidate';
import ReplaceCV, { commitNewCv } from './ReplaceCV';
// styles
import colors from 'dependencies/materialStyles/Colors';

const propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onlySecondaryActions: PropTypes.bool,
  onDeleted: PropTypes.func,
};

const defaultProps = {
  onlySecondaryActions: false,
  onDeleted: null,
};

const MODAL = {
  REPLACE_CV: 'replaceCV',
  DELETE: 'delete',
};

const CandidateSettings = ({ id, name, onDeleted, onlySecondaryActions }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { addToast } = useContext(ToastContext);
  const { id: userId } = useAuth();
  const { openModal, isModalOpen, closeModal } = useModalProvider();
  const [replacingCv, setReplacingCv] = useState(false);

  const handleReplaceCv = async newCvData => {
    try {
      setReplacingCv(true);

      await commitNewCv({
        candidateId: id,
        shCandidateId: getProperty(newCvData, 'shCandidateId', ''),
        shPersonId: getProperty(newCvData, 'shPersonId', ''),
        boxId: getProperty(newCvData, 'boxId', ''),
        filename: getProperty(newCvData, 'file.name', ''),
        mriScore: getProperty(newCvData, 'mri.score', 0),
        userId,
      });

      addToast.success(t('replaceCandidateCVSuccess'));
    } catch (error) {
      addToast.error(t('errorPlaceholderText'));
    } finally {
      closeModal();
      setReplacingCv(false);
    }
  };

  const replaceCvMenuItem = {
    id: 'replaceCV',
    label: t('replaceCV'),
    icon: 'fileReplace',
    onClick: () => openModal(MODAL.REPLACE_CV),
  };
  const editCandidateMenuItem = {
    id: 'editCandidate',
    label: t('editCandidate'),
    icon: 'edit',
    onClick: () => history.push(`/candidate/edit/${id}`),
  };
  const deleteCandidateMenuItem = {
    id: 'deleteCandidate',
    label: t('deleteCandidateRecord'),
    icon: 'delete',
    onClick: () => openModal(MODAL.DELETE),
  };

  const getMenuItems = () => {
    if (onlySecondaryActions) {
      return [replaceCvMenuItem, deleteCandidateMenuItem];
    }

    return [replaceCvMenuItem, editCandidateMenuItem, deleteCandidateMenuItem];
  };

  return (
    <React.Fragment>
      <IconDropdown menuItems={getMenuItems()} iconColor={colors.gray} />
      <ReplaceCV
        isOpen={isModalOpen(MODAL.REPLACE_CV)}
        candidateId={id}
        candidateName={name}
        submitting={replacingCv}
        onClose={closeModal}
        onReplace={handleReplaceCv}
      />
      <DeleteCandidate
        isOpen={isModalOpen(MODAL.DELETE)}
        id={id}
        name={name}
        onClose={closeModal}
        onDeleted={onDeleted}
      />
    </React.Fragment>
  );
};

CandidateSettings.propTypes = propTypes;
CandidateSettings.defaultProps = defaultProps;

export default CandidateSettings;
