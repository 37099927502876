/* eslint-disable react/no-array-index-key */
import React, { useContext } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// helpers
import { prettyNumber } from 'helpers/Number';
import { getCurrencyText } from 'helpers/NameMapping';
// constants
import { MIN_BUSINESS_GP_REQUIREMENT, MIN_BUSINESS_GP_FP_REQUIREMENT } from 'constants/rates';
// components
import RateTable from 'components/molecules/RateTable';
// context
import MultipliersContext from '../../MultipliersContext';

const propTypes = {
  currency: PropTypes.string.isRequired,
  supplierRates: PropTypes.arrayOf(PropTypes.number).isRequired,
  ibmGPs: PropTypes.arrayOf(PropTypes.number).isRequired,
  sellPrices: PropTypes.arrayOf(PropTypes.number).isRequired,
  isProposal: PropTypes.bool.isRequired,
};

const Rates = ({ currency, supplierRates, ibmGPs, sellPrices, isProposal }) => {
  const { t } = useTranslation();
  const { nteRates } = useContext(MultipliersContext);

  const MIN_GP_REQUIREMENT = isProposal
    ? MIN_BUSINESS_GP_FP_REQUIREMENT
    : MIN_BUSINESS_GP_REQUIREMENT;

  return (
    <div className="positionCandidate_rates">
      <RateTable bordered={false}>
        <RateTable.Row>
          <RateTable.Title className="positionCandidate_rates_type" active={false}>
            {t('supplierRate')}
          </RateTable.Title>
          {supplierRates.map((rate, index) => (
            <RateTable.Text key={index} symbol={getCurrencyText(currency)} active={false}>
              {prettyNumber(rate)}
            </RateTable.Text>
          ))}
        </RateTable.Row>
        <RateTable.Row>
          <RateTable.Title className="positionCandidate_rates_type" active={false}>
            {t('sellPriceLabel')}
          </RateTable.Title>
          {supplierRates.map((_rate, index) => (
            <RateTable.Text
              key={index}
              symbol={sellPrices[index] ? getCurrencyText(currency) : ''}
              active={false}
              error={nteRates[index] && sellPrices[index] > nteRates[index].value}
              errorMessage={t('priceExceedClientNTE')}
            >
              {sellPrices[index] ? prettyNumber(sellPrices[index]) : '-'}
            </RateTable.Text>
          ))}
        </RateTable.Row>
        <RateTable.Row>
          <RateTable.Title className="positionCandidate_rates_type noBorder_bottom" active={false}>
            {t('dgp')}
          </RateTable.Title>
          {ibmGPs.map((dGP, index) => (
            <RateTable.Text
              className="noBorder_bottom"
              key={index}
              symbol="%"
              active={false}
              error={dGP < MIN_GP_REQUIREMENT}
              errorMessage={t('lowGPErrorText', { minimumGP: MIN_GP_REQUIREMENT })}
            >
              {dGP}
            </RateTable.Text>
          ))}
        </RateTable.Row>
      </RateTable>
    </div>
  );
};

Rates.propTypes = propTypes;

export default Rates;
