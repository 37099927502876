import React, { useContext } from 'react';
// libraries
import PropTypes from 'prop-types';
import FlipMove from 'react-flip-move';
// constants
import { SUBMISSION_STATUS } from 'constants/submissionStatus';
// helpers
import parseSubmission from 'helpers/SubmissionParser';
import parseProposal from 'helpers/ProposalParser';
// context
import PositionContext from '../PositionContext';
// components
import Submission from './Submission';

const propTypes = {
  submissions: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};

const List = ({ submissions }) => {
  const position = useContext(PositionContext);

  return (
    <FlipMove maintainContainerHeight enterAnimation="fade" leaveAnimation={false}>
      {submissions.map((data, index) => {
        const submission = position.isFixedPrice() ? parseProposal(data) : parseSubmission(data);

        return (
          <div key={submission.id}>
            <Submission
              activeRates={submission.getStatus().value === SUBMISSION_STATUS.NEW}
              submission={submission}
              evenRow={index % 2 === 0}
            />
          </div>
        );
      })}
    </FlipMove>
  );
};

List.propTypes = propTypes;

export default List;
