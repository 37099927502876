const menuItemStyle = {
  menuItem: {},
  loading: {
    cursor: 'default',
    pointerEvents: 'none',
    '&:hover, &:focus': {
      backgroundColor: 'transparent',
    },
  },
  icon: {},
  primary: {
    fontFamily: 'Lato-Light',
    fontSize: '17px',
  },
};

export default menuItemStyle;
