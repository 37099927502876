import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// components
import Icon from 'components/atoms/Icon';
// styles
import colors from 'dependencies/materialStyles/Colors';
import styles from './excellentMatchIcon.module.scss';

const propTypes = {
  size: PropTypes.number,
  bordered: PropTypes.bool,
};

const defaultProps = {
  size: 10,
  bordered: false,
};

const ExcellentMatchIcon = ({ size, bordered }) => {
  return (
    <div className={classNames(styles.icon, { [styles.bordered]: bordered })}>
      <Icon name="starFilled" size={size} color={colors.yellow5} />
    </div>
  );
};

ExcellentMatchIcon.propTypes = propTypes;
ExcellentMatchIcon.defaultProps = defaultProps;

export default ExcellentMatchIcon;
