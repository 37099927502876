import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
};

const defaultProps = {
  className: '',
};

const Row = ({ className, children }) => {
  return (
    <div className={classNames('responsiveTable_row', { [className]: className })}>{children}</div>
  );
};

Row.propTypes = propTypes;
Row.defaultProps = defaultProps;

export default Row;
