/* eslint-disable react/prop-types */
import React from 'react';
// libraries
import i18n from 'i18next';
import MenuItem from '@material-ui/core/MenuItem';
// styles
import colors from 'dependencies/materialStyles/Colors';
// constants
import { ACTION_CREATE_NEW } from 'constants';
// components
import CloseChip from 'components/atoms/CloseChip';
import Icon from 'components/atoms/Icon';
import { activeAutoCompleteStyles as autoCompleteStyles } from './AutoCompleteStyles';

function MultiValue(props) {
  const { children, removeProps, isDisabled } = props;

  return (
    <CloseChip
      label={children}
      onDelete={removeProps.onClick}
      id={children}
      disabled={isDisabled}
    />
  );
}

function Option(props) {
  const { innerRef, isFocused, innerProps, children } = props;
  return (
    <MenuItem
      buttonRef={innerRef}
      selected={isFocused}
      component="div"
      style={autoCompleteStyles.menuItem}
      {...innerProps}
    >
      {children === ACTION_CREATE_NEW ? <i>{i18n.t(ACTION_CREATE_NEW)}</i> : children}
    </MenuItem>
  );
}

function Menu(props) {
  const { innerProps, children } = props;
  return (
    <div style={autoCompleteStyles.menu} {...innerProps}>
      {children}
    </div>
  );
}

function SingleValue(props) {
  const { innerProps, children } = props;
  return (
    <div className={autoCompleteStyles.singleValue} {...innerProps}>
      {children}
    </div>
  );
}

function ClearIndicator(props) {
  const { innerProps } = props;
  return (
    <div style={autoCompleteStyles.flex} {...innerProps}>
      <Icon name="clear" size={16} color={colors.gray} style={autoCompleteStyles.clearIcon} />
    </div>
  );
}

function DropdownIndicator(props) {
  const { innerProps } = props;
  return (
    <Icon
      name="expandMore"
      {...innerProps}
      size={24}
      color={colors.gray}
      style={autoCompleteStyles.dropdownIcon}
    />
  );
}

const components = {
  ClearIndicator,
  DropdownIndicator,
  MultiValue,
  Option,
  Menu,
  SingleValue,
};

export default components;
