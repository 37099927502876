import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// constants
import { SUPPLIER_DIRECT, SUPPLIER_IBM, SUPPLIER_NOT_DIRECT } from 'constants';
// components
import Button from 'components/atoms/Button';
import RateText from 'components/molecules/RateText';
import Tooltip from 'components/atoms/Tooltip';
import LevelOfEffort from './LevelOfEffort';
// helpers
import { getCSALabel, getAusyLabel } from './helpers';
// styles
import styles from './ratesSimulation.module.scss';

const propTypes = {
  manYearDiscount: PropTypes.number.isRequired,
  hours: PropTypes.number.isRequired,
  partnerCategory: PropTypes.oneOf([SUPPLIER_DIRECT, SUPPLIER_IBM, SUPPLIER_NOT_DIRECT]).isRequired,
  includeCSAFee: PropTypes.bool.isRequired,
  onApplyRates: PropTypes.func.isRequired,
  resetChanges: PropTypes.func.isRequired,
  isDiscardActive: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const Allowance = ({
  manYearDiscount,
  onApplyRates,
  resetChanges,
  isDiscardActive,
  hours,
  className,
  includeCSAFee,
  partnerCategory,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.candidateControls, className)}>
      <div className={styles.allowanceTitle}>{t('csaFee')} (1.05%)</div>
      <div className={classNames(styles.regularText, 'm-r-15')}>{getCSALabel(includeCSAFee)}</div>

      <Tooltip title={<LevelOfEffort hours={hours} />}>
        <div className={styles.allowanceTitle}>{t('manYearDiscountLabel')}</div>
      </Tooltip>
      <Tooltip title={<LevelOfEffort hours={hours} />}>
        <div className="m-r-15">
          <RateText currency="%" value={manYearDiscount} />
        </div>
      </Tooltip>

      <Button label={t('useSimulatedRates')} onClick={onApplyRates} />

      <div className={styles.allowanceTitle}>{t('ausyFee')} (1.5%)</div>
      <div className={classNames(styles.regularText, 'm-r-15')}>
        {getAusyLabel(partnerCategory)}
      </div>

      <div className={styles.resetButton}>
        <Button
          label={t('resetChanges')}
          onClick={resetChanges}
          disabled={!isDiscardActive}
          secondary
        />
      </div>
    </div>
  );
};

Allowance.propTypes = propTypes;
Allowance.defaultProps = defaultProps;

export default Allowance;
