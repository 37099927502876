import React from 'react';
import type { IconType } from 'react-icons';
import IconButton from '@components/IconButton';

interface FileRecordActionProps {
  icon: IconType;
  label: string;
  onClick: () => void;
}

const FileRecordAction = ({ icon, label, onClick }: FileRecordActionProps) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    onClick();
  };

  return <IconButton label={label} onClick={handleClick} icon={icon} size="small" />;
};

export default FileRecordAction;
