import { gql } from '@apollo/client';
// queries
import { Note } from 'queries/Fragments';
// services
import client from 'services/Client';
import logger from 'services/Logger';

const UPDATE_REJECTION_NOTE = gql`
  mutation updateRejectionNote($id: ID!, $reason: String!) {
    noteUpdate(filter: { id: $id }, data: { note: $reason }) {
      ...Note
    }
  }
  ${Note}
`;

/**
 * Update rejection reason
 * @param {string} id Note id
 * @param {string} reason New reason
 * @returns {Promise}
 */
const updateRejectionReason = async (id, reason) => {
  try {
    await client.mutate({
      mutation: UPDATE_REJECTION_NOTE,
      variables: {
        id,
        reason,
      },
    });
  } catch (error) {
    logger.exception(error);
    throw error;
  }
};

export default updateRejectionReason;
