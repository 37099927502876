import React from 'react';
// libraries
import PropTypes from 'prop-types';
// components
import CandidateHeader from './Header';
import CandidateSettings from './Settings';
import CandidateDates from './Dates';

const propTypes = {
  submission: PropTypes.object.isRequired,
  sellPrices: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      startDate: PropTypes.string.isRequired,
    })
  ).isRequired,
};

const Summary = ({ submission, sellPrices }) => {
  const currentStatus = submission.getStatus();

  return (
    <div className="positionCandidate_summary">
      <div className="positionCandidate_header">
        <CandidateHeader submission={submission} />
        <CandidateSettings
          submission={submission}
          revisions={submission.revisions}
          sellPrices={sellPrices}
        />
      </div>
      <CandidateDates
        availableIn={submission.availability.label}
        status={currentStatus.value}
        statusDate={currentStatus.start}
      />
    </div>
  );
};

Summary.propTypes = propTypes;

export default Summary;
