import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// styles
import styles from './submitCandidates.module.scss';

const propTypes = {
  messages: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
};

const Errors = ({ messages }) => {
  return (
    <React.Fragment>
      {[].concat(messages).map((message, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index} className={classNames(styles.error, { 'm-t-5': index })}>
          {`* ${message}`}
        </div>
      ))}
    </React.Fragment>
  );
};

Errors.propTypes = propTypes;

export default Errors;
