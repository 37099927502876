import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
// components
import Button from 'components/atoms/Button';
import PrivacyBanner from 'components/molecules/PrivacyBanner';
import CandidateSettings from 'components/organisms/CandidateSettings';
// helpers
import { formPossessive } from 'helpers/StringParser';
import { getTimeYear } from 'helpers/RelativeTimeFormatter';
// constants
import { POSITION_TAB } from 'constants/pageTabs';
// styles
import styles from './candidatePage.module.scss';

const propTypes = {
  candidate: PropTypes.object.isRequired,
  positionId: PropTypes.string,
};

const defaultProps = {
  positionId: '',
};

const CandidateHeader = ({ candidate, positionId }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleCandidateDeleted = () => {
    if (!positionId) {
      // redirect to candidates page
      history.push('/candidates');
    } else {
      history.push(`/position/${positionId}?tab=${POSITION_TAB.RANKING}`);
    }
  };

  return (
    <div className={styles.header}>
      <div className={styles.titleWrapper}>
        <h1 className={styles.title}>
          {t('candidateRecordTitle', {
            name: formPossessive(candidate.name),
            interpolation: {
              escapeValue: false,
            },
          })}
        </h1>
        <Button
          secondary
          label={t('editCandidateRecord')}
          onClick={() => history.push(`/candidate/edit/${candidate.id}`)}
        />
      </div>
      <div className={styles.dates}>
        <span>
          {t('lastUpdated', {
            date: getTimeYear(candidate.updatedAt),
          })}
        </span>
        <span className="m-l-30">
          {t('dateCreated', {
            dateCreated: getTimeYear(candidate.createdAt),
          })}
        </span>
      </div>
      <div className={styles.subtitleWrapper}>
        <div className={styles.subtitle}>{t('candidatePoolInformation')}</div>
        <CandidateSettings
          id={candidate.id}
          name={candidate.name}
          onlySecondaryActions
          onDeleted={handleCandidateDeleted}
        />
      </div>
      <PrivacyBanner />
    </div>
  );
};

CandidateHeader.propTypes = propTypes;
CandidateHeader.defaultProps = defaultProps;

export default CandidateHeader;
