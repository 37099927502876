import React from 'react';
// libraries
import PropTypes from 'prop-types';
// constants
import { SH_EXPERIENCE_LEVEL } from 'constants';
// types
import { MetricType } from 'types';
// components
import Modal from 'components/molecules/Modal';
import MatchMetrics from './MatchMetrics';

const propTypes = {
  metrics: MetricType.isRequired,
  score: PropTypes.number.isRequired,
  candidateName: PropTypes.string.isRequired,
  positionTitle: PropTypes.string.isRequired,
  project: PropTypes.string.isRequired,
  occupations: PropTypes.arrayOf(PropTypes.string).isRequired,
  experienceLevel: PropTypes.oneOf(Object.values(SH_EXPERIENCE_LEVEL)).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const MatchMetricsModal = ({
  isOpen,
  onClose,
  metrics,
  score,
  candidateName,
  positionTitle,
  project,
  occupations,
  experienceLevel,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <MatchMetrics
        onClose={onClose}
        metrics={metrics}
        score={score}
        candidateName={candidateName}
        positionTitle={positionTitle}
        project={project}
        occupations={occupations}
        experienceLevel={experienceLevel}
      />
    </Modal>
  );
};

MatchMetricsModal.propTypes = propTypes;

export default MatchMetricsModal;
