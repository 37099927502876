import React, { useContext } from 'react';
// libraries
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// components
import Icon from 'components/atoms/Icon';
import Score from 'components/atoms/Score';
import Tooltip from 'components/atoms/Tooltip';
import TooltipIcon from 'components/molecules/TooltipIcon';
import ExcellentMatchIcon from 'components/molecules/ExcellentMatchIcon';
import SubmissionStatusFlow from 'components/molecules/SubmissionStatusFlow';
import { MatchMetricsPopper } from 'components/organisms/MatchMetrics';
// helpers
import { getRevisionsStatusInfoText } from 'helpers/Revision';
import { getPartnerCategoryText } from 'helpers/NameMapping';
import { isSubmitted } from 'helpers/Submission';
// constants
import { SUBMISSION_TAB } from 'constants/pageTabs';
import { SUBMISSION_STATUS } from 'constants/submissionStatus';
// styles
import colors from 'dependencies/materialStyles/Colors';
// context
import PositionContext from '../../PositionContext';

const propTypes = {
  submission: PropTypes.object.isRequired,
};

const Header = ({ submission }) => {
  const position = useContext(PositionContext);

  const submissionName = position.isFixedPrice() ? submission.name : submission.candidate.name;
  const currentStatus = submission.getStatus();
  const revisionStatus = getRevisionsStatusInfoText(submission.revisions);

  const displayExcellentMatch = () => {
    return submission.isExcellentMatch() && submission.hasEnoughDataPoints();
  };

  const getSubmissionDetailLink = () => {
    const link = position.isFixedPrice()
      ? `/proposal/${submission.id}`
      : `/submission/${submission.id}`;

    if ([SUBMISSION_STATUS.OFFER, SUBMISSION_STATUS.ONBOARDING].includes(currentStatus.value)) {
      return `${link}?tab=${SUBMISSION_TAB.ONBOARDING}`;
    }

    return link;
  };

  return (
    <div className="positionCandidate_header_title">
      {position.isTimeAndMaterials() && (
        <MatchMetricsPopper
          metrics={submission.getMetrics()}
          score={submission.score}
          candidateName={submissionName}
          positionTitle={position.title}
          project={position.project.name}
          occupations={position.getOccupationsName()}
          experienceLevel={position.experienceLevel.value}
          placement="right-end"
          offsetX={5}
        >
          <Score
            score={submission.score}
            icon={displayExcellentMatch() ? <ExcellentMatchIcon bordered /> : null}
          />
        </MatchMetricsPopper>
      )}
      <div className="positionCandidate_header_name">
        <div className="oneRow">
          <Link to={getSubmissionDetailLink()}>{submissionName}</Link>
          {isSubmitted(currentStatus.name) && (
            <span className="m-l-5">
              <Icon name="lock" color={colors.gray} size={13} />
            </span>
          )}
        </div>
        <Tooltip title={getPartnerCategoryText(submission.partner.category)}>
          <div className="positionCandidate_header_company">{submission.partner.name}</div>
        </Tooltip>
        <div className="positionCandidate_header_statuses">
          <SubmissionStatusFlow submission={submission} size="sm" />
          {position.isTimeAndMaterials() && revisionStatus && (
            <TooltipIcon
              icon="revision"
              tooltip={revisionStatus}
              size={17}
              placement="bottom"
              className="m-l-5  display-inline-flex"
            />
          )}
        </div>
      </div>
    </div>
  );
};

Header.propTypes = propTypes;

export default Header;
