import React from 'react';
import type { IconType } from 'react-icons';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import MuiIconButton from '@mui/material/IconButton';
import Icon from '@components/Icon';

interface IconButtonBaseProps {
  label: string;
  icon: IconType;
  size?: 'small' | 'medium';
  disabled?: boolean;
  className?: string;
  tooltipPlacement?: TooltipProps['placement'];
}

interface IconButtonLinkProps extends IconButtonBaseProps {
  href: string;
  targetSelf?: boolean;
  download?: boolean;
}

interface IconButtonProps extends IconButtonBaseProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const IconButton = ({
  icon,
  className,
  disabled = false,
  size = 'medium',
  tooltipPlacement = 'top',
  ...props
}: IconButtonLinkProps | IconButtonProps) => (
  <Tooltip title={props.label} placement={tooltipPlacement}>
    {'onClick' in props ? (
      <MuiIconButton onClick={props.onClick} size={size} disabled={disabled} className={className}>
        <Icon component={icon} size={size} />
      </MuiIconButton>
    ) : (
      <MuiIconButton
        href={props.href}
        target={props.targetSelf ? '_self' : '_blank'}
        download={!!props.download}
        size={size}
        disabled={disabled}
        className={className}
      >
        <Icon component={icon} size={size} />
      </MuiIconButton>
    )}
  </Tooltip>
);

export default IconButton;
