import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// styles
import styles from './identityMatch.module.scss';

const IdentityProps = PropTypes.shape({
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
});

const propTypes = {
  currentIdentity: IdentityProps.isRequired,
  newIdentity: IdentityProps.isRequired,
};

const IdentityMatch = ({ currentIdentity, newIdentity }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.header}>{t('version')}</div>
        <div className={styles.header}>{t('name')}</div>
        <div className={styles.header}>{t('email')}</div>
        <div className={styles.header}>{t('phone')}</div>
      </div>
      <div className={styles.row}>
        <div className={styles.version}>{t('old')}</div>
        <div>{currentIdentity.name}</div>
        <div>{currentIdentity.email}</div>
        <div>{currentIdentity.phone}</div>
      </div>
      <div className={styles.row}>
        <div className={styles.version}>{t('new')}</div>
        <div
          className={classNames({
            [styles.error]: currentIdentity.name !== newIdentity.name,
          })}
        >
          {newIdentity.name || t('notFound')}
        </div>
        <div
          className={classNames({
            [styles.error]: currentIdentity.email !== newIdentity.email,
          })}
        >
          {newIdentity.email || t('notFound')}
        </div>
        <div
          className={classNames({
            [styles.error]: currentIdentity.phone !== newIdentity.phone,
          })}
        >
          {newIdentity.phone || t('notFound')}
        </div>
      </div>
    </div>
  );
};

IdentityMatch.propTypes = propTypes;

export default IdentityMatch;
