/* eslint-disable import/order */
import React, { useState } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import Collapse from '@material-ui/core/Collapse';
import getProperty from 'lodash/get';
// hooks
import useAuth from 'hooks/useAuth';
// constants
import { REVISION_STATUS, SUPPLIER_ROLE, RATES_SUPPLIER_RATE, REVISION_TYPES } from 'constants';
// helpers
import { translateKey } from 'helpers/StringParser';
import { getRevisionStatusText } from 'helpers/NameMapping';
import { getPassedRelativeTime } from 'helpers/RelativeTimeFormatter';
import { isReviseActionBlocked } from 'helpers/Revision';
// components
import Button from 'components/atoms/Button';
import RespondRevisionModal from 'components/organisms/RespondRevisionModal';
import StatusNote from 'components/molecules/StatusNote';
import HistoryModal from 'components/organisms/HistoryModal';
import RevisionsType from './RevisionType';

const propTypes = {
  index: PropTypes.number.isRequired,
  status: PropTypes.shape({
    status: PropTypes.oneOf(Object.values(REVISION_STATUS)).isRequired,
    createdById: PropTypes.string.isRequired,
    createdByName: PropTypes.string.isRequired,
  }).isRequired,
  revisionTypes: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  requestedBy: PropTypes.string.isRequired,
  requestedAt: PropTypes.string.isRequired,
  revisedBy: PropTypes.string,
  revisedAt: PropTypes.string,
  candidateId: PropTypes.string.isRequired,
  revisionId: PropTypes.string.isRequired,
  candidateName: PropTypes.string.isRequired,
};

const defaultProps = {
  revisedBy: '',
  revisedAt: '',
};

const Revision = ({
  revisionId,
  index,
  status,
  revisionTypes,
  requestedBy,
  requestedAt,
  revisedBy,
  revisedAt,
  candidateId,
  candidateName,
}) => {
  const { t } = useTranslation();
  const { role, account } = useAuth();
  const { status: statusValue, createdById, createdByName } = status;
  const [isOpen, setIsOpen] = useState(statusValue !== REVISION_STATUS.COMPLETED);
  const [isRevisionOpen, setIsRevisionOpen] = useState(false);
  const [modal, setModal] = useState(null);
  const isReviseBlocked = isReviseActionBlocked(statusValue, createdById, account.id);

  const openModal = type => {
    const historyType = type === REVISION_TYPES.RATES ? RATES_SUPPLIER_RATE : type;
    setModal(historyType);
  };

  const handleOnReviseClick = async () => {
    setIsRevisionOpen(true);
  };

  return (
    <React.Fragment>
      <div className="revision">
        <div className="revision_header">
          <div>
            <div className="revision_title">
              <span className="revision_title_text">
                {t('revision')} {index}
              </span>
              <StatusNote
                status={getRevisionStatusText(statusValue, role)}
                color={statusValue === REVISION_STATUS.COMPLETED ? 'gray' : 'blue'}
                small
              />
            </div>
            <div className="revision_author">
              {t('requestedBy')} <b>{requestedBy}</b> {getPassedRelativeTime(requestedAt)}
            </div>
            {revisedBy && (
              <div className="revision_author m-t-2">
                {t('revisedBy')} <b>{revisedBy}</b> {getPassedRelativeTime(revisedAt)}
              </div>
            )}
          </div>
          {(statusValue === REVISION_STATUS.COMPLETED || role !== SUPPLIER_ROLE) && (
            <Button
              type={!isOpen ? 'expandMore' : 'less'}
              tooltip={isOpen ? t('collapse') : t('expand')}
              onClick={() => setIsOpen(!isOpen)}
            />
          )}
          {statusValue !== REVISION_STATUS.COMPLETED &&
            role === SUPPLIER_ROLE &&
            !isReviseBlocked && <Button label={t('revise')} onClick={handleOnReviseClick} />}
          {statusValue !== REVISION_STATUS.COMPLETED && role === SUPPLIER_ROLE && isReviseBlocked && (
            <div className="revision_author m-t-2">
              <Trans i18nKey="underRevision">
                <b>{{ createdByName }}</b> is currently revising this submission
              </Trans>
            </div>
          )}
        </div>
      </div>
      <Collapse in={isOpen} timeout="auto">
        {revisionTypes.map(
          ({ id, type, status: typeStatus, reason, rejectionNote, additionalNote }, typeIndex) => {
            const typeStatusValue = getProperty(typeStatus, 'status', '');
            const note =
              typeStatusValue === REVISION_STATUS.REJECTED ? rejectionNote : additionalNote;
            return (
              <RevisionsType
                key={id}
                index={typeIndex + 1}
                type={type}
                isActive={statusValue !== REVISION_STATUS.COMPLETED}
                status={typeStatusValue}
                requestReason={getProperty(reason, 'note', '')}
                note={getProperty(note, 'note', '')}
                revisedBy={getProperty(note, 'author.person.fullName', '')}
                revisedAt={getProperty(note, 'createdAt', '')}
                onHistoryClick={() => openModal(type)}
              />
            );
          }
        )}
      </Collapse>
      <HistoryModal
        title={modal ? translateKey(modal) : null}
        isOpen={!!modal}
        itemId={candidateId}
        type={modal}
        onClose={() => setModal(null)}
      />
      <RespondRevisionModal
        name={candidateName}
        isOpen={isRevisionOpen}
        revisionId={revisionId}
        onClose={() => {
          setIsRevisionOpen(false);
        }}
      />
    </React.Fragment>
  );
};

Revision.propTypes = propTypes;
Revision.defaultProps = defaultProps;

export default Revision;
