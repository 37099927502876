import { gql } from '@apollo/client';
// const
import { SUPPLIER_ROLE } from 'constants';
import { File } from './Fragments';

const userInfoFragment = gql`
  fragment UserInfo on AlakaUser {
    id
    user {
      id
      email
    }
    person {
      id
      fullName
      mobilePhone
      phone
    }
    lastLogin
    status
    organization {
      id
      company {
        id
        name
      }
    }
  }
`;

const fragments = {
  basicInfo: gql`
    fragment BasicUserInfo on AlakaUser {
      ...UserInfo
    }
    ${userInfoFragment}
  `,
  adminInfo: gql`
    fragment AdminUserInfo on AlakaUser {
      ...UserInfo
    }
    ${userInfoFragment}
  `,
  visitorInfo: gql`
    fragment VisitorUserInfo on AlakaUser {
      ...UserInfo
    }
    ${userInfoFragment}
  `,
};

export const GET_BASIC_USERS = gql`
  query getBasicUsers(
    $filter: AlakaUserFilter
    $first: Int!
    $skip: Int!
    $sort: [AlakaUserSort!]
  ) {
    alakaUsersList(first: $first, skip: $skip, filter: $filter, sort: $sort) {
      items {
        ...BasicUserInfo
      }
      count
    }
  }
  ${fragments.basicInfo}
`;

export const GET_ADMIN_USERS = gql`
  query getAdminUsers(
    $filter: AlakaUserFilter
    $first: Int!
    $skip: Int!
    $sort: [AlakaUserSort!]
  ) {
    alakaUsersList(first: $first, skip: $skip, filter: $filter, sort: $sort) {
      items {
        ...AdminUserInfo
      }
      count
    }
  }
  ${fragments.adminInfo}
`;

export const GET_VISITOR_USERS = gql`
  query getVisitorUsers(
    $filter: AlakaUserFilter
    $first: Int!
    $skip: Int!
    $sort: [AlakaUserSort!]
  ) {
    alakaUsersList(first: $first, skip: $skip, filter: $filter, sort: $sort) {
      items {
        ...VisitorUserInfo
      }
      count
    }
  }
  ${fragments.visitorInfo}
`;

export const GET_USER_ROLES = gql`
  query getUserRoles($id: ID!) {
    projectRolesList(filter: { user: { id: { equals: $id } } }) {
      count
      items {
        id
        projects {
          items {
            id
            name
          }
        }
        role
      }
    }
  }
`;

export const LAST_ROLE_USED = gql`
  fragment usedRole on ProjectRole {
    id
    role
  }
`;

export const alakaUserFragment = gql`
  fragment AlakaUser on AlakaUser {
    id
    systemRole
    supplier {
      id
    }
    organization {
      id
      name
      supplier {
        id
      }
    }
    language
    person {
      id
      preferredName
      fullName
    }
    lastRoleUsed {
      ...usedRole
    }
    lastViewed
    projectRoles {
      items {
        id
        role
        projects {
          items {
            id
            name
            config
            client {
              id
              config
            }
          }
        }
      }
    }
  }
  ${LAST_ROLE_USED}
`;

export const UPDATE_LAST_USED_ROLE = gql`
  mutation updateLastRoleUsed($userId: ID!, $projectRoleId: ID!, $role: String!) {
    lastRoleUsedUpdate: alakaUserUpdate(
      data: { lastRoleUsed: { connect: { id: $projectRoleId } } }
      filter: { id: $userId }
    ) {
      ...AlakaUser
    }
    roleUpdate: toggleRole(role: $role) @client
  }
  ${alakaUserFragment}
`;

export const FIND_SUPPLIER = gql`
  query findSupplier($inputValue: String!, $first: Int!, $skip: Int!, $project: ID!) {
    peopleList(
      first: $first
      skip: $skip
      orderBy: fullName_ASC
      filter: {
        AND: [
          {
            personUser: {
              projectRoles: {
                some: {
                  AND: [
                    { role: { equals: "${SUPPLIER_ROLE}" } }
                    { projects: { some: { id: { equals: $project } } } }
                  ]
                }
              }
            }
          }
          {
            OR: [
              { fullName: { contains: $inputValue } }
              { personUser: { supplier: { company: { name: { contains: $inputValue } } } } }
            ]
          }
        ]
      }
    ) {
      items {
        id
        fullName
        personUser {
          id
          supplier {
            id
            company {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_LAST_VIEWED_NOTIFICATION = gql`
  mutation updateLastViewedNotification($userId: ID!, $lastViewed: JSON!) {
    alakaUserUpdate(filter: { id: $userId }, data: { lastViewed: $lastViewed }) {
      ...AlakaUser
    }
  }
  ${alakaUserFragment}
`;

/**
 * @param userId {ID} - id of the user
 */
export const GET_USER = gql`
  query getAlakaUser($userId: ID!) {
    alakaUser(id: $userId) {
      ...AlakaUser
    }
  }
  ${alakaUserFragment}
`;

export const UPDATE_AUTHOR_FOR_DOCUMENT = gql`
  mutation updateAuthorForDocument($document: ID!, $author: ID!) {
    attachmentUpdate(filter: { id: $document }, data: { author: { reconnect: { id: $author } } }) {
      ...File
    }
  }
  ${File}
`;

/**
 * @param {string} userId
 * @returns {string} alakaUser update mutation in string format
 */
export const addSupplierToUserMutation = (userId, supplierId) => {
  if (!userId) return '';
  if (!supplierId) return '';

  return `
    update_${userId}: alakaUserUpdate(
      data: {
        supplier: {
          connect: {
            id: "${supplierId}"
          }
        }
      },
      filter: {
        id: "${userId}"
      }
    ) {
      id
    }
  `;
};
