import { gql } from '@apollo/client';
import getProperty from 'lodash/get';
// services
import client from 'services/Client';
import logger from 'services/Logger';
// queries
import { Revision, Rate } from 'queries/Fragments';

const FETCH_REVISION = gql`
  query fetchRevision($id: ID!) {
    revision(id: $id) {
      ...Revision
      submission {
        id
        candidate {
          id
        }
        position {
          id
          rfq {
            id
            name
          }
          report {
            id
            shPositionId
          }
          project {
            id
            name
            shProjectId
            client {
              id
              name
            }
          }
          rates {
            items {
              ...Rate
            }
          }
        }
        rates {
          items {
            ...Rate
          }
        }
      }
    }
  }
  ${Revision}
  ${Rate}
`;

export const fetchRevision = async id => {
  try {
    const response = await client.query({
      query: FETCH_REVISION,
      variables: { id },
    });

    return getProperty(response, 'data.revision');
  } catch (error) {
    logger.exception(error, { id });
    throw error;
  }
};

export default fetchRevision;
