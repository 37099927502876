import i18n from 'i18next';
// constants
import {
  MIN_BUSINESS_GP_REQUIREMENT,
  MIN_BUSINESS_GP_FP_REQUIREMENT,
  DEFAULT_IBM_FEE,
} from 'constants/rates';
import { SUPPLIER_DIRECT, SUPPLIER_IBM, SUPPLIER_NOT_DIRECT } from 'constants';
// helpers
import { ceilNumber } from 'helpers/Number';
import { calculateCandidateSellPriceFromGP } from 'helpers/RatesCalculation';

export const getCSALabel = csa => {
  return csa ? i18n.t('paidByIBM') : i18n.t('notPaidByIBM');
};

export const getAusyLabel = ausy => {
  switch (ausy) {
    case SUPPLIER_DIRECT:
      return i18n.t('notApplicable');
    case SUPPLIER_IBM:
      return i18n.t('paidByIBM');
    case SUPPLIER_NOT_DIRECT:
      return i18n.t('paidByPartner');
    default:
      return '-';
  }
};

export const initCandidateSellPrices = (
  partnerRates,
  manYearDiscount,
  isDirectSupplier,
  includeCSAFee
) => {
  return partnerRates.map(partnerRate => {
    return ceilNumber(
      calculateCandidateSellPriceFromGP(
        partnerRate,
        DEFAULT_IBM_FEE,
        manYearDiscount,
        isDirectSupplier,
        includeCSAFee
      )
    );
  });
};

export const getErrors = ({
  nteRates = [],
  targetRates = [],
  sellPrices = [],
  ibmGPs = [],
  isProposal,
}) => {
  const errors = [];
  const targetRatesError = targetRates.find((tr, index) => nteRates[index] && tr > nteRates[index]);
  const sellPriceError = sellPrices.find((sp, index) => nteRates[index] && sp > nteRates[index]);
  const MIN_GP = isProposal ? MIN_BUSINESS_GP_FP_REQUIREMENT : MIN_BUSINESS_GP_REQUIREMENT;
  const ibmGPError = ibmGPs.find(dgp => dgp < MIN_GP);

  if (targetRatesError) {
    errors.push(i18n.t('targetRatesHigherThanNTEError'));
  }
  if (sellPriceError) {
    errors.push(i18n.t('sellPriceCantBeHigherThanNTE'));
  }
  if (ibmGPError === 0 || ibmGPError) {
    errors.push(i18n.t('dgpIsBelowMinimumRequirement', { minimumGP: MIN_GP }));
  }

  return errors;
};
