import { gql } from '@apollo/client';
import moment from 'moment';
import orderBy from 'lodash/orderBy';
import getProperty from 'lodash/get';
// services
import client from 'services/Client';
// queries
import { SubmissionStatus } from 'queries/Fragments';
// constants
import { SUBMISSION_STATUS, SUBMISSION_STATUS_TYPE } from 'constants/submissionStatus';
import { SUPPLIER_ROLE, NOTE_SUBMISSION_STATUS, SUPPLIER_DIRECT, SUPPLIER_IBM } from 'constants';

const UPDATE_SUBMISSION_STATUS = gql`
  mutation updateSubmissionStatus($submissionId: ID!, $data: SubmissionUpdateInput!) {
    submissionUpdate(filter: { id: $submissionId }, data: $data) {
      id
      statuses(sort: { createdAt: DESC }) {
        items {
          ...SubmissionStatus
        }
      }
    }
  }
  ${SubmissionStatus}
`;

const UPDATE_PROPOSAL_STATUS = gql`
  mutation updateProposalStatus($submissionId: ID!, $data: ProposalUpdateInput!) {
    proposalUpdate(filter: { id: $submissionId }, data: $data) {
      id
      statuses(sort: { createdAt: DESC }) {
        items {
          ...SubmissionStatus
        }
      }
    }
  }
  ${SubmissionStatus}
`;

/**
 * Change submission status
 * @param {object} params
 * @param {string} params.submissionId
 * @param {string} params.status
 * @param {string} params.oldStatusId
 * @param {boolean} params.isProposal
 * @param {string} [params.type]
 * @param {string} [params.note]
 * @param {object} [options] Options for graphql mutation
 */
export const changeSubmissionStatus = (params, options = {}) => {
  const data = {
    statuses: {
      update: {
        filter: { id: params.oldStatusId },
        data: { end: moment().toISOString() },
      },
      create: {
        name: params.status,
        type: params.type || SUBMISSION_STATUS_TYPE.BASIC,
        start: moment().toISOString(),
      },
    },
  };

  if (params.note) {
    data.statuses.create.note = {
      create: {
        noteType: NOTE_SUBMISSION_STATUS,
        note: params.note,
      },
    };
  }

  return client.mutate({
    mutation: params.isProposal ? UPDATE_PROPOSAL_STATUS : UPDATE_SUBMISSION_STATUS,
    variables: {
      submissionId: params.submissionId,
      data,
    },
    update: cache => {
      // remove cached submission and position lists
      cache.evict({ fieldName: ['submissionsList', 'positionsList', 'proposalsList'] });
      cache.gc();
    },
    ...options,
  });
};

/**
 * Change submission status
 * @param {object} params
 * @param {string} params.submissionId
 * @param {string} params.oldStatusId
 * @param {boolean} params.isProposal
 * @param {string} [params.note]
 * @param {object} [options] Options for graphql mutation
 */
export const closeSubmissionStatus = async (params, options = {}) => {
  const data = {
    statuses: {
      update: {
        filter: { id: params.oldStatusId },
        data: { end: moment().toISOString() },
      },
    },
  };

  if (params.note) {
    data.statuses.create.note = {
      create: {
        noteType: NOTE_SUBMISSION_STATUS,
        note: params.note,
      },
    };
  }

  return client.mutate({
    mutation: params.isProposal ? UPDATE_PROPOSAL_STATUS : UPDATE_SUBMISSION_STATUS,
    variables: {
      submissionId: params.submissionId,
      data,
    },
    update: cache => {
      // remove cached submission and position lists
      cache.evict({ fieldName: ['submissionsList', 'positionsList', 'proposalsList'] });
      cache.gc();
    },
    ...options,
  });
};

const RESET_SUBMISSION_STATUS = gql`
  mutation resetSubmissionStatus($submissionId: ID!, $data: SubmissionUpdateInput!) {
    submissionUpdate(filter: { id: $submissionId }, data: $data) {
      id
      statuses(sort: { createdAt: DESC }) {
        items {
          ...SubmissionStatus
        }
      }
    }
  }
  ${SubmissionStatus}
`;

const RESET_PROPOSAL_STATUS = gql`
  mutation resetProposalStatus($submissionId: ID!, $data: ProposalUpdateInput!) {
    proposalUpdate(filter: { id: $submissionId }, data: $data) {
      id
      statuses(sort: { createdAt: DESC }) {
        items {
          ...SubmissionStatus
        }
      }
    }
  }
  ${SubmissionStatus}
`;

const REMOVE_SUBMISSION_SELL_PRICE = gql`
  mutation removeSubmissionSellPrice($submissionId: ID!) {
    rateDeleteByFilter(
      filter: {
        AND: [{ rateType: { equals: "SELL_PRICE" }, submission: { id: { equals: $submissionId } } }]
      }
    ) {
      success
    }
  }
`;

const REMOVE_PROPOSAL_SELL_PRICE = gql`
  mutation removeSubmissionSellPrice($submissionId: ID!) {
    rateDeleteByFilter(
      filter: {
        AND: [{ rateType: { equals: "SELL_PRICE" }, proposal: { id: { equals: $submissionId } } }]
      }
    ) {
      success
    }
  }
`;

/**
 * Change submission status
 * @param {string} submissionId
 * @param {object[]} statuses
 * @param {boolean} isProposal
 */
export const resetSubmissionStatus = async (submissionId, statuses, isProposal) => {
  const orderedStatuses = orderBy(statuses, 'start', 'desc');
  const currentStatus = orderedStatuses.shift();
  const previousStatus = orderedStatuses.shift();
  const promises = [];
  let data = {};

  if (currentStatus.name === SUBMISSION_STATUS.SUBMITTED) {
    promises.push(
      client.mutate({
        mutation: isProposal ? REMOVE_PROPOSAL_SELL_PRICE : REMOVE_SUBMISSION_SELL_PRICE,
        variables: {
          submissionId,
        },
        update: cache => {
          cache.evict({ fieldName: ['ratesList'] });
          cache.gc();
        },
      })
    );
  }

  if (previousStatus.name === SUBMISSION_STATUS.NEW) {
    data = {
      statuses: {
        disconnect: { id: currentStatus.id },
        update: { filter: { id: previousStatus.id }, data: { end: null } },
      },
    };

    promises.push(
      client.mutate({
        mutation: isProposal ? RESET_PROPOSAL_STATUS : RESET_SUBMISSION_STATUS,
        variables: {
          submissionId,
          data,
        },
        update: cache => {
          // remove cached position lists
          cache.evict({ fieldName: ['submissionsList', 'proposalsList'] });
          cache.gc();
        },
      })
    );
  }

  await Promise.all(promises);

  return null;
};

/**
 * Is candidate submission submitted by status
 * @param {string} status
 * @returns {boolean}
 */
export const isSubmitted = status => {
  return [
    SUBMISSION_STATUS.SUBMITTED,
    SUBMISSION_STATUS.OFFER,
    SUBMISSION_STATUS.INTERVIEW,
    SUBMISSION_STATUS.ONBOARDING,
    SUBMISSION_STATUS.ONBOARDING_CANCELLED,
    SUBMISSION_STATUS.OFF_BOARDING,
    SUBMISSION_STATUS.OFF_BOARDED,
    SUBMISSION_STATUS.DELIVERY,
    SUBMISSION_STATUS.NOT_SELECTED,
  ].includes(status);
};

/**
 * Is candidate submission rejected by status
 * @param {string} status
 * @returns {boolean}
 */
export const isRejected = status =>
  [
    SUBMISSION_STATUS.WITHDRAWN,
    SUBMISSION_STATUS.NOT_SUBMITTED,
    SUBMISSION_STATUS.NOT_SELECTED,
    SUBMISSION_STATUS.ONBOARDING_CANCELLED,
  ].includes(status);

/**
 * Find submission current basic status object
 * @param {object[]} statuses
 * @param {string} [type]
 * @returns {?object}
 */
export const getSubmissionCurrentStatus = (statuses, type = SUBMISSION_STATUS_TYPE.BASIC) => {
  return statuses.find(status => status.type === type && !status.end);
};

/**
 * Filter submissions by status
 * @param {object[]} submissions
 * @param {string|string[]} status Submission status
 * @param {boolean} [filterByCurrentStatus=true] Check only submissions' current status
 * @return {object[]} Filtered submissions
 */
export const filterSubmissionsByStatus = (submissions, status, filterByCurrentStatus = true) => {
  const searchingStatuses = [].concat(status);

  return submissions.filter(submission => {
    const statuses = getProperty(submission, 'statuses.items', []);

    if (filterByCurrentStatus) {
      const currentStatus = getSubmissionCurrentStatus(statuses);

      return searchingStatuses.includes(currentStatus.name);
    }

    return statuses.some(({ name: submissionStatus }) =>
      searchingStatuses.includes(submissionStatus)
    );
  });
};

/**
 * Filter submissions by supplier id
 * @param {object[]} submissions
 * @param {string} supplierId
 * @returns {object[]}
 */
export const filterSubmissionsBySupplier = (submissions, supplierId) => {
  return submissions.filter(submission => {
    const submissionSupplierId =
      getProperty(submission, 'candidate.supplier.id') || getProperty(submission, 'supplier.id');

    return submissionSupplierId === supplierId;
  });
};

/**
 * Filter submissions for PM
 * @param {object[]} submissions
 * @returns {object[]}
 */
export const filterSubmissionsByManagerRole = submissions => {
  return submissions.filter(submission => {
    const statuses = getProperty(submission, 'statuses.items', []);
    const currentStatus = getSubmissionCurrentStatus(statuses);

    return ![
      SUBMISSION_STATUS.NEW,
      SUBMISSION_STATUS.NOT_SUBMITTED,
      SUBMISSION_STATUS.WITHDRAWN,
    ].includes(currentStatus.name);
  });
};

/**
 * Get number of received submissions for given role
 * @param {object[]} submissions
 * @param {string} role
 * @param {string} supplierId
 * @returns {number}
 */
export const getReceivedSubmissionsCount = (submissions, role, supplierId) => {
  if (role === SUPPLIER_ROLE) {
    const partnerSubmissions = filterSubmissionsBySupplier(submissions, supplierId);

    return partnerSubmissions.length;
  }

  return submissions.length;
};

/**
 * @param {object[]} submissions
 * @returns {number}
 */
const getSubmittedSubmissionsListCount = submissions => {
  const submittedSubmissions = submissions.filter(submission => {
    const statuses = getProperty(submission, 'statuses.items', []);
    const currentStatus = getSubmissionCurrentStatus(statuses);

    return isSubmitted(currentStatus.name);
  });

  return submittedSubmissions.length;
};

/**
 * Get number of submitted submissions for given role
 * @param {object[]} submissions
 * @param {string} role
 * @param {string} supplierId
 * @returns {number}
 */
export const getSubmittedSubmissionsCount = (submissions, role, supplierId) => {
  if (role === SUPPLIER_ROLE) {
    const partnerSubmissions = filterSubmissionsBySupplier(submissions, supplierId);

    return getSubmittedSubmissionsListCount(partnerSubmissions);
  }

  return getSubmittedSubmissionsListCount(submissions);
};

/**
 * Get number of new submissions
 * @param {object[]} submissions
 * @param {string} role
 * @param {string} supplierId
 * @returns {number}
 */
export const getNewSubmissionsCount = (submissions, role, supplierId) => {
  const filteredSubmissions =
    role === SUPPLIER_ROLE ? filterSubmissionsBySupplier(submissions, supplierId) : submissions;

  return filteredSubmissions.filter(submission => {
    const statuses = getProperty(submission, 'statuses.items', []);
    const currentStatus = getSubmissionCurrentStatus(statuses);

    return getProperty(currentStatus, 'name') === SUBMISSION_STATUS.NEW;
  }).length;
};

export const isDirectSupplier = category => [SUPPLIER_DIRECT, SUPPLIER_IBM].includes(category);
