/* eslint-disable react/no-array-index-key */
import React, { useEffect, useContext } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// hooks
import useIsFirstRun from 'hooks/useIsFirstRun';
// helpers
import { getCurrencyText } from 'helpers/NameMapping';
import { prettyNumber } from 'helpers/Number';
import {
  calculateCandidateGPsFromSellPrice,
  calculateCandidateGPFromSellPrice,
} from 'helpers/RatesCalculation';
// constants
import { MIN_BUSINESS_GP_REQUIREMENT, MIN_BUSINESS_GP_FP_REQUIREMENT } from 'constants/rates';
// components
import RateTable from 'components/molecules/RateTable';
// context
import MultipliersContext from '../../MultipliersContext';

const propTypes = {
  currency: PropTypes.string.isRequired,
  isDirectSupplier: PropTypes.bool.isRequired,
  includeCSAFee: PropTypes.bool.isRequired,
  supplierRates: PropTypes.arrayOf(PropTypes.number).isRequired,
  ibmGPs: PropTypes.arrayOf(PropTypes.number).isRequired,
  sellPrices: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
    })
  ).isRequired,
  onSellPricesChange: PropTypes.func.isRequired,
  onIbmGPsChange: PropTypes.func.isRequired,
  isProposal: PropTypes.bool.isRequired,
};

const ActiveRates = ({
  currency,
  isDirectSupplier,
  includeCSAFee,
  supplierRates,
  ibmGPs,
  sellPrices,
  onSellPricesChange,
  onIbmGPsChange,
  isProposal,
}) => {
  const { t } = useTranslation();
  const isFirstRun = useIsFirstRun();
  const { manYearDiscount, nteRates } = useContext(MultipliersContext);

  const MIN_GP_REQUIREMENT = isProposal
    ? MIN_BUSINESS_GP_FP_REQUIREMENT
    : MIN_BUSINESS_GP_REQUIREMENT;

  // calculate dGPs when sellPrice is changed
  useEffect(() => {
    if (!isFirstRun) {
      const GPs = calculateCandidateGPsFromSellPrice(
        supplierRates,
        sellPrices.map(({ value }) => value),
        manYearDiscount,
        isDirectSupplier,
        includeCSAFee
      );
      onIbmGPsChange(GPs);
    }
  }, [JSON.stringify(sellPrices), manYearDiscount, isDirectSupplier]);

  const onSellPriceChange = (index, value) => {
    const updatedSellPrices = sellPrices.map((price, priceIndex) =>
      priceIndex === index ? { ...price, value } : price
    );
    const updatedGPs = ibmGPs.map((dGP, dGPIndex) =>
      dGPIndex === index
        ? calculateCandidateGPFromSellPrice(
            supplierRates[index],
            value,
            manYearDiscount,
            isDirectSupplier,
            includeCSAFee
          )
        : dGP
    );

    onSellPricesChange(updatedSellPrices);
    onIbmGPsChange(updatedGPs);
  };

  return (
    <div className="positionCandidate_rates">
      <RateTable bordered={false}>
        <RateTable.Row>
          <RateTable.Title className="positionCandidate_rates_type" active={false}>
            {t('supplierRate')}
          </RateTable.Title>
          {supplierRates.map((rate, index) => (
            <RateTable.Text key={index} symbol={getCurrencyText(currency)} active={false}>
              {prettyNumber(rate)}
            </RateTable.Text>
          ))}
        </RateTable.Row>
        <RateTable.Row>
          <RateTable.Title className="positionCandidate_rates_type">
            {t('sellPriceLabel')}
          </RateTable.Title>
          {sellPrices.map((price, index) => (
            <RateTable.Input
              id={`test_sellPrice_${index}`}
              key={index}
              symbol={getCurrencyText(currency)}
              value={price.value}
              onChange={val => onSellPriceChange(index, val)}
              error={nteRates[index] && price.value > nteRates[index].value}
              errorMessage={t('priceExceedClientNTE')}
            />
          ))}
        </RateTable.Row>
        <RateTable.Row>
          <RateTable.Title className="positionCandidate_rates_type noBorder_bottom" active={false}>
            {t('dgp')}
          </RateTable.Title>
          {ibmGPs.map((dGP, index) => (
            <RateTable.Text
              id={`test_dgp_${index}`}
              className="noBorder_bottom"
              key={index}
              symbol="%"
              active={false}
              allowNegative
              error={dGP < MIN_GP_REQUIREMENT}
              errorMessage={t('lowGPErrorText', { minimumGP: MIN_GP_REQUIREMENT })}
            >
              {dGP}
            </RateTable.Text>
          ))}
        </RateTable.Row>
      </RateTable>
    </div>
  );
};

ActiveRates.propTypes = propTypes;

export default ActiveRates;
