import i18n from 'i18next';

export const COOKIE_TYPE = {
  ESSENTIALS: 'essentials',
  FUNCTIONALITY: 'functionality',
  ANALYTICS: 'analytics',
};

export const TRACKING_INFO_LIST = [
  {
    label: i18n.t('trackingInfoCookiePermission'),
    type: COOKIE_TYPE.ESSENTIALS,
  },
  {
    label: i18n.t('trackingInfoSession'),
    type: COOKIE_TYPE.ESSENTIALS,
  },
  {
    label: i18n.t('trackingInfoAuth'),
    type: COOKIE_TYPE.ESSENTIALS,
  },
  {
    label: i18n.t('trackingInfoZendesk'),
    type: COOKIE_TYPE.ESSENTIALS,
  },
  {
    label: i18n.t('trackingInfoSelectedPreferences'),
    type: COOKIE_TYPE.FUNCTIONALITY,
  },
];
