import React from 'react';
import InputBase, { InputBaseProps } from '@mui/material/InputBase';
import classNames from 'classnames';
import styles from './textInput.module.scss';

const TextInput = (props: InputBaseProps) => {
  const { classes = {}, fullWidth = true, ...restProps } = props;
  const { root, error, focused, disabled, adornedEnd, input, ...restClasses } = classes;

  return (
    <InputBase
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
      fullWidth={fullWidth}
      classes={{
        root: classNames(styles.container, root),
        error: classNames(styles.error, error),
        focused: classNames(styles.focused, focused),
        disabled: classNames(styles.disabled, disabled),
        adornedEnd: classNames(styles.clearButtonVisible, adornedEnd),
        input: classNames(styles.input, input),
        ...restClasses,
      }}
    />
  );
};

export default TextInput;
