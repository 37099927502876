import React from 'react';
import PropTypes from 'prop-types';
// helpers
import { prettyNumber } from 'helpers/Number';
import { getCurrencyText } from 'helpers/NameMapping';

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  currency: PropTypes.string.isRequired,
};

const Rate = ({ value, currency }) => {
  return (
    <React.Fragment>
      {value ? (
        <span>
          {prettyNumber(value)}
          <span className="table_cell_currency">{getCurrencyText(currency)}</span>
        </span>
      ) : (
        ''
      )}
    </React.Fragment>
  );
};

Rate.propTypes = propTypes;

export default Rate;
