import getProperty from 'lodash/get';
// services
import client from 'services/Client';
import logger from 'services/Logger';
// queries
import { UPDATE_LAST_VIEWED_NOTIFICATION } from 'queries/UserQueries';
import {
  NOTIFICATION_FRAGMENT,
  MARK_AS_READ,
  MARK_AS_UNREAD,
  GET_NUMBER_OF_NEW_NOTIFICATIONS,
  REMOVE_NOTIFICATION,
} from 'queries/NotificationQueries';
// helpers
import { isToday, isYesterday, isLast7Days } from 'helpers/RelativeTimeFormatter';
// constants
import {
  NOTIFICATION_FLAG_NEW,
  NOTIFICATION_FLAG_TODAY,
  NOTIFICATION_FLAG_YESTERDAY,
  NOTIFICATION_FLAG_LAST_7_DAYS,
  NOTIFICATION_FLAG_OLDER,
} from './Constants';

export const updateLastViewed = async (userId, lastViewed) => {
  if (lastViewed && lastViewed.id && lastViewed.createdAt) {
    try {
      await client.mutate({
        mutation: UPDATE_LAST_VIEWED_NOTIFICATION,
        variables: {
          userId,
          lastViewed: {
            id: lastViewed.id,
            date: lastViewed.createdAt,
          },
        },
      });
    } catch (error) {
      logger.exception(error);
    }
  }
};

const handleFlagValue = (flags, value) => {
  return !flags[value] ? value : null;
};

const getFlag = (flags, notification, lastViewedDate) => {
  const viewedTime = lastViewedDate ? new Date(lastViewedDate).getTime() : null;
  const createdAtTime = new Date(notification.createdAt).getTime();

  const isNew = !viewedTime || createdAtTime > viewedTime;
  if (isNew) {
    return handleFlagValue(flags, NOTIFICATION_FLAG_NEW);
  }
  if (isToday(notification.createdAt)) {
    return handleFlagValue(flags, NOTIFICATION_FLAG_TODAY);
  }
  if (isYesterday(notification.createdAt)) {
    return handleFlagValue(flags, NOTIFICATION_FLAG_YESTERDAY);
  }
  if (isLast7Days(notification.createdAt)) {
    return handleFlagValue(flags, NOTIFICATION_FLAG_LAST_7_DAYS);
  }
  return handleFlagValue(flags, NOTIFICATION_FLAG_OLDER);
};

export const parseNotifications = (notifications, lastViewedDate) => {
  let flags = {};

  return notifications.map(notification => {
    const flag = getFlag(flags, notification, lastViewedDate);
    if (flag) {
      flags = { ...flags, [flag]: true };
    }

    const link = getProperty(notification, 'payload.relativeLink');
    const name = getProperty(notification, 'payload.createdBy.name');
    return { ...notification, flag, link, name };
  });
};

export const getNewNotifications = async lastViewedId => {
  const variables = lastViewedId ? { lastViewed: lastViewedId } : {};
  const response = await client.query({
    query: GET_NUMBER_OF_NEW_NOTIFICATIONS,
    variables,
  });
  return getProperty(response, 'data.notificationsList.new');
};

export const markAsUnread = notification => {
  const { id, payload, createdAt, message } = notification;
  return client.mutate({
    mutation: MARK_AS_UNREAD,
    variables: { id },
    optimisticResponse: {
      __typename: 'Mutation',
      notificationMarkAsUnread: {
        id,
        __typename: 'NotificationItem',
        read: false,
        payload,
        createdAt,
        message,
      },
    },
  });
};

export const markAsRead = notification => {
  const { id, payload, createdAt, message } = notification;
  return client.mutate({
    mutation: MARK_AS_READ,
    variables: { id },
    optimisticResponse: {
      __typename: 'Mutation',
      notificationMarkAsRead: {
        id,
        __typename: 'NotificationItem',
        read: true,
        payload,
        createdAt,
        message,
      },
    },
  });
};

export const removeNotification = id => {
  return client.mutate({
    mutation: REMOVE_NOTIFICATION,
    variables: { id },
    update: cache => {
      cache.modify({
        fields: {
          notificationsList: (existing, { readField }) => {
            return {
              ...existing,
              items: existing.items.filter(itemRef => readField('id', itemRef) !== id),
            };
          },
        },
      });
    },
  });
};

export const updateNotificationCache = newNotification => {
  client.cache.modify({
    fields: {
      notificationsList: existing => {
        const newNotificationRef = client.cache.writeFragment({
          data: newNotification,
          fragment: NOTIFICATION_FRAGMENT,
        });

        return {
          ...existing,
          items: [newNotificationRef, ...existing.items],
        };
      },
    },
  });
};
