// libraries
import { gql } from '@apollo/client';
// helpers
import { translateKey } from 'helpers/StringParser';

export const formatRadioData = (data, tableName, fieldName) => {
  if (data[tableName] && data[tableName].items) {
    const list = data[tableName].items;
    return list.map(item => ({
      value: item.id,
      label: translateKey(item[fieldName]),
      key: item[fieldName],
    }));
  }
  return [];
};

// eslint-disable-next-line no-unused-vars
export const getQuery = (tableName, fieldName, orderBy) => {
  return gql`
    query radioList {
      ${tableName}{
        items {
          id
          ${fieldName}
        }
      }
    }
  `;
};
