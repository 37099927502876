import React from 'react';
// libraries
import PropTypes from 'prop-types';
import remove from 'lodash/remove';
import getProperty from 'lodash/get';
import { withTranslation } from 'react-i18next';
// constants
import { basicRoles } from 'dependencies/staticData/Roles';
// components
import Button from 'components/atoms/Button';
import ProjectRole from './ProjectRole';
// helpers
import { getRoleList } from './Helpers';
// styles
import './projectRoles.scss';

const propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  formData: PropTypes.object,
  name: PropTypes.string.isRequired,
};

const defaultProps = {
  formData: {},
};

const projectRole = {
  role: undefined,
  projects: undefined,
};

class ProjectRoles extends React.Component {
  state = {
    components: [0],
    selectedRoles: [],
  };

  /*
    updating data that was changed by project and role components
  */
  updateProjectRole = (value, projectRoleName, index) => {
    const { onChange, name, formData } = this.props;
    let projectRoles = formData && formData[name] ? formData[name] : null;
    if (projectRoles && projectRoles.value.length > index) {
      projectRoles.value[index] = { ...projectRoles.value[index], [projectRoleName]: value };
    } else if (projectRoles && projectRoles.value.length === index) {
      projectRoles = [...projectRoles.value, { ...projectRole, [projectRoleName]: value }];
    } else {
      projectRoles = [{ ...projectRole, [projectRoleName]: value }];
    }
    if (projectRoleName === 'role') {
      this.updateRoles(projectRoles);
    }
    onChange(projectRoles);
  };

  /*
    updating list of used roles to be able to filter role dropdown
    and provide to users just options of unused roles
  */
  updateRoles = projectRoles => {
    if (projectRoles) {
      const selectedRoles = projectRoles
        .map(pr => (pr.role && pr.role.value ? pr.role.value : null))
        .filter(role => role != null);
      this.setState({ selectedRoles });
    }
  };

  /*
    deleting project role component alongside with its stored data
  */
  deleteProjectRole = (component, index) => {
    const { components } = this.state;
    const { formData, onChange, name } = this.props;
    // Removing UI component from the list
    remove(components, comp => {
      return comp === component;
    });
    this.setState({ components });
    const currentData = formData && formData[name] ? formData[name] : null;
    if (currentData && index > -1 && currentData[index]) {
      // Removing data that were already stored to formData
      if (index > -1) {
        currentData.splice(index, 1);
      }
      onChange(currentData);
      // Removing disability to select role from dropdown when already used in other component
      this.updateRoles(currentData);
    }
  };

  /*
    adding another UI project role component
  */
  addProjectRole = () => {
    this.setState(prevState => ({
      components: [...prevState.components, prevState.components.length],
    }));
  };

  render() {
    const { components, selectedRoles } = this.state;
    const { formData, name, id, t } = this.props;
    const roles = getRoleList(selectedRoles);

    const getContent = () => {
      if (components && components.length > 0) {
        return (
          <React.Fragment>
            {components.map((component, index) => {
              return (
                <ProjectRole
                  roles={roles}
                  onDelete={() => this.deleteProjectRole(component, index)}
                  key={`projectRoles_${index}`}
                  onChange={(value, projectRoleName) =>
                    this.updateProjectRole(value, projectRoleName, index)
                  }
                  data={getProperty(formData, [name, 'value', index])}
                />
              );
            })}
          </React.Fragment>
        );
      }
      return <div className="projectRole_alert">{t('atLeastOneProjectAndRoleAreRequired')}</div>;
    };

    return (
      <div className={`test_${id}`}>
        {getContent()}
        {components.length < basicRoles.length && (
          <Button label={t('addNewRole')} iconButton="add" onClick={this.addProjectRole} />
        )}
      </div>
    );
  }
}

ProjectRoles.propTypes = propTypes;
ProjectRoles.defaultProps = defaultProps;

export default withTranslation()(ProjectRoles);
