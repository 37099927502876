import React from 'react';
// libraries
import { useHistory } from 'react-router-dom';
import getProperty from 'lodash/get';
// constants
import { ROUTE_LEGAL_TERMS } from 'constants';
// components
import Tabs from 'components/atoms/Tabs';
import LegalPageContent from './LegalPageContent';
// helpers
import { legalPageTabs } from './LegalPageTabs';
// styles
import './legalPage.scss';

const LegalPage = () => {
  const history = useHistory();

  const tab = getProperty(
    legalPageTabs.find(pageTab => pageTab.id === window.location.pathname),
    'id',
    ROUTE_LEGAL_TERMS
  );

  const setTab = newTab => {
    history.push(newTab);
  };

  return (
    <div>
      <div className="legalPage_tabsContainer">
        <div className="legalPage_tabs">
          <Tabs selected={tab} onSwitch={setTab} items={legalPageTabs} />
        </div>
      </div>
      <LegalPageContent tab={tab} className="legalPage_content" />
    </div>
  );
};

export default LegalPage;
