import React from 'react';
// libraries
import ContentLoader from 'react-content-loader';

const CandidateLoader = () => (
  <ContentLoader
    height={580}
    width={1400}
    speed={1}
    primaryColor="#f1f1f1"
    secondaryColor="#e6e6e6"
  >
    <rect x="0" y="0" rx="3" ry="3" width="300" height="15" />
    <rect x="1200" y="0" rx="3" ry="3" width="200" height="15" />
    <rect x="0" y="35" rx="3" ry="3" width="400" height="15" />
    <rect x="0" y="65" rx="3" ry="3" width="1400" height="30" />
    <rect x="0" y="150" rx="0" ry="0" width="1400" height="150" />
    <rect x="0" y="330" rx="0" ry="0" width="1400" height="250" />
  </ContentLoader>
);

export default CandidateLoader;
