import React from 'react';
import PropTypes from 'prop-types';
import './loader.scss';

const propTypes = {
  fixedWidth: PropTypes.bool,
  fixedSize: PropTypes.bool,
  fullSize: PropTypes.bool,
  small: PropTypes.bool,
  className: PropTypes.string,
};

const defaultProps = {
  fixedWidth: false,
  fixedSize: false,
  fullSize: false,
  small: false,
  className: '',
};

const Loader = ({ fixedWidth, fixedSize, fullSize, small, className }) => {
  const classes = ['loader loader-moved'];

  if (fullSize) {
    classes.push('full-screen');
  }
  if (fixedWidth) {
    classes.push('loader-fixedWidth');
  }
  if (fixedSize) {
    classes.push('loader-fixedSize');
  }
  if (small) {
    classes.push('loader-small');
  }
  if (className) {
    classes.push(className);
  }

  return (
    <div className={classes.join(' ')}>
      <div className="gooey gooey-moved">
        <span className="dot" />
        <div className="dots">
          <span />
          <span />
          <span />
        </div>
      </div>
    </div>
  );
};

Loader.propTypes = propTypes;
Loader.defaultProps = defaultProps;

export default Loader;
