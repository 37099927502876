import React from 'react';
import Skeleton from '@mui/material/Skeleton';

interface TextSkeletonProps {
  heading?: boolean;
  width?: string | number;
}

const TextSkeleton = ({ heading = false, width = 200 }: TextSkeletonProps) => (
  <Skeleton animation="wave" variant="rectangular" width={width} height={heading ? 25 : 16} />
);

export default TextSkeleton;
