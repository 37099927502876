import React, { useState } from 'react';
// libraries
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
// constants
import { COMPONENT_ACTIVITIES } from 'constants';
// components
import NewField from 'components/molecules/NewField';
import SurveyLine from './SurveyLine';
// styles
import './surveyCustom.scss';

const propTypes = {
  id: PropTypes.string.isRequired,
  addItem: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  questions: PropTypes.array,
  values: PropTypes.array,
};

const defaultProps = {
  questions: [],
  placeholder: null,
  values: [],
};

const SurveyCustom = props => {
  const { addItem, questions, values, id, onChange, removeItem, placeholder, t } = props;

  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState(false);

  const addInput = () => {
    if (inputValue !== '') {
      addItem(inputValue, id);
      setInputValue('');
    } else {
      setError(true);
    }
  };

  const handleOnChange = input => {
    if (error) setError(true);

    setInputValue(input);
  };

  const fieldPlaceholder = id === COMPONENT_ACTIVITIES ? t('addNewActivity') : t('addNewSkill');

  return (
    <NewField
      multiline
      content={
        <div>
          {questions &&
            questions.map(question => {
              return (
                <SurveyLine
                  key={question.id}
                  value={question.description}
                  id={question.id}
                  color={question.color}
                  chips={values}
                  selected={question.value}
                  label={question.label}
                  onChange={value => onChange(question.id, value)}
                  removeItem={questionId => removeItem(questionId, id)}
                />
              );
            })}
        </div>
      }
      id={`${id}_field`}
      className={`${error ? 'redForm' : ''} test_${id}`}
      addInput={addInput}
      placeholder={placeholder || fieldPlaceholder}
      selected={inputValue}
      onChange={handleOnChange}
    />
  );
};

SurveyCustom.propTypes = propTypes;
SurveyCustom.defaultProps = defaultProps;

export default withTranslation()(SurveyCustom);
