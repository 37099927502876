/* eslint-disable import/order */
import React from 'react';
// libraries
import getProperty from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
// hooks
import useAuth from 'hooks/useAuth';
// constants
import { ONBOARDING_STATUS_DATE, ONBOARDING_TAB } from 'constants/onboarding';
import { SUBMISSION_STATUS_TYPE } from 'constants/submissionStatus';
import { STAFF_ROLE, SUPPLIER_ROLE, MANAGER_ROLE } from 'constants';
import { DATE_APPROVED, DATE_PENDING } from '../constants';
// components
import ApproveStartDate from './ApproveStartDate';
import ProposeStartDate from './ProposeStartDate';
import JobOffer from './JobOffer';
import SubmitStartDate from './SubmitStartDate';
import StartDateConfirmed from './StartDateConfirmed';
import TextContent from '../TextContent';
// helpers
import { formPossessive } from 'helpers/StringParser';
import { getTimeYear } from 'helpers/RelativeTimeFormatter';
import { isTabFinished, getStartDate, parseExpectedPositionStartDate } from '../helpers';

const propTypes = {
  submission: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
  tabs: PropTypes.array,
};

const defaultProps = {
  tabs: [],
};

const StartDate = ({ submission, position, tabs }) => {
  const { role } = useAuth();
  const { t } = useTranslation();

  const { rfq, duration } = position;
  const requiredStartDate = parseExpectedPositionStartDate(submission, duration.start);
  const acceptedStartDate = getStartDate(submission, DATE_APPROVED) || {};
  const pendingStartDate = getStartDate(submission, DATE_PENDING) || {};
  const supplier = getProperty(submission, 'partner.name');
  const formattedRequiredStartDate = getTimeYear(requiredStartDate);
  const formattedPendingStartDate = getTimeYear(pendingStartDate.date);
  const formattedAcceptedStartDate = getTimeYear(acceptedStartDate.date);
  const formattedCandidateName = formPossessive(submission.getName());
  const activeStatus = submission.getStatus(role, SUBMISSION_STATUS_TYPE.ONBOARDING_START_DATE);

  const tabFinished = isTabFinished(tabs, ONBOARDING_TAB.START_DATE);
  if (tabFinished && formattedAcceptedStartDate) {
    // 0. Accepted - all steps are finished
    return (
      <TextContent
        title={
          <Trans i18nKey="startDateIsConfirmedTo">
            Start date is confirmed for {{ formattedAcceptedStartDate }}
          </Trans>
        }
        content={<JobOffer submission={submission} className="w100" date={acceptedStartDate} />}
      />
    );
  }

  if (!activeStatus) {
    //   1. INACTIVE STATUS - the same for all roles
    return (
      <TextContent
        title={t('noActionRequiredFromYourSide')}
        label={t('stepsNeededToBeCompletedBeforeMovingHere')}
      />
    );
  }

  const selectedStartDateForCandidate = (
    <Trans i18nKey="expectedStartDateForCandidate">
      You have selected start date for <b>{{ formattedPendingStartDate }}</b>.
    </Trans>
  );

  const expectedStartDateForPosition = (
    <Trans i18nKey="expectedStartDateForPosition">
      Expected start date for {{ rfq }} is <b>{{ formattedRequiredStartDate }}</b>.
    </Trans>
  );

  const proposeStartDate = (
    <TextContent
      title={
        <Trans i18nKey="weAreWaitingForSupplierToConfirmStartDate">
          We&apos;re waiting for {{ supplier }} to confirm the start date.
        </Trans>
      }
      label={expectedStartDateForPosition}
      content={role !== MANAGER_ROLE && <JobOffer submission={submission} className="w100" />}
    />
  );

  if (activeStatus.value === ONBOARDING_STATUS_DATE.PROPOSE) {
    if (role === SUPPLIER_ROLE) {
      // 2. PROPOSE START DATE - Supplier
      return <ProposeStartDate submission={submission} position={position} />;
    }

    if (role === STAFF_ROLE) {
      // 3.1 PROPOSE START DATE - Project Manager - same for Match Manager
      return proposeStartDate;
    }
    if (role === MANAGER_ROLE) {
      // 3.2 PROPOSE START DATE - Match Manager - same for Project Manager
      return proposeStartDate;
    }
  }

  if (activeStatus.value === ONBOARDING_STATUS_DATE.SUBMIT) {
    if (role === SUPPLIER_ROLE) {
      // 4. SUBMIT START DATE FOR APPROVAL - Supplier
      return (
        <StartDateConfirmed
          title={
            <Trans
              i18nKey="youConfirmedAvailabilityOfCandidate"
              tOptions={{ interpolation: { escapeValue: false } }}
              values={{ formattedCandidateName }}
            >
              You have confirmed {{ formattedCandidateName }} availability for this role.
            </Trans>
          }
          label={
            <span>
              {selectedStartDateForCandidate}
              <br />
              {t('weAreWaitingForApprovals')}
            </span>
          }
          confirmedStartDate={pendingStartDate}
          submission={submission}
        />
      );
    }
    if (role === MANAGER_ROLE) {
      // 5. SUBMIT START DATE FOR APPROVAL - Project Manager
      return proposeStartDate;
    }
    if (role === STAFF_ROLE) {
      // 6. SUBMIT START DATE FOR APPROVAL - Match Manager
      return (
        <SubmitStartDate
          supplier={supplier}
          confirmedStartDate={pendingStartDate}
          requiredStartDate={requiredStartDate}
          submission={submission}
          position={position}
        />
      );
    }
  }

  if (activeStatus.value === ONBOARDING_STATUS_DATE.APPROVE) {
    if (role === SUPPLIER_ROLE) {
      // 7. APPROVE START DATE - Supplier
      return (
        <StartDateConfirmed
          title={
            <Trans i18nKey="pendingPMConfirmationAsStartDate">
              Pending Project Manager confirmation for {{ formattedPendingStartDate }} as start
              date.
            </Trans>
          }
          label={expectedStartDateForPosition}
          confirmedStartDate={pendingStartDate}
          submission={submission}
        />
      );
    }
    if (role === MANAGER_ROLE) {
      // 8. APPROVE START DATE - Project Manager
      return (
        <ApproveStartDate
          submission={submission}
          position={position}
          confirmedStartDate={pendingStartDate}
          requiredStartDate={requiredStartDate}
        />
      );
    }
    if (role === STAFF_ROLE) {
      // 9. APPROVE START DATE - Match Manager
      return (
        <TextContent
          title={
            <Trans i18nKey="pendingPMConfirmationAsStartDate">
              Pending Project Manager confirmation for {{ formattedPendingStartDate }} as start
              date.
            </Trans>
          }
          label={expectedStartDateForPosition}
          content={<JobOffer date={pendingStartDate} submission={submission} className="w100" />}
        />
      );
    }
  }

  return null;
};

StartDate.propTypes = propTypes;
StartDate.defaultProps = defaultProps;

export default StartDate;
