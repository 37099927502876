import React from 'react';
// libraries
import PropTypes from 'prop-types';
// styles
import styles from './ratesSimulation.module.scss';

const propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
};

const defaultProps = {
  errors: [],
  className: '',
};

const Errors = ({ className, errors }) => {
  if (errors.length > 0) {
    return (
      <div className={className}>
        {errors.map((error, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} className={styles.error}>
              * {error}
            </div>
          );
        })}
      </div>
    );
  }

  return null;
};

Errors.propTypes = propTypes;
Errors.defaultProps = defaultProps;

export default Errors;
