import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

const defaultProps = {
  className: '',
  children: '',
};

const Cell = ({ className, children }) => {
  return (
    <div
      className={classNames('responsiveTable_item responsiveTable_cell', {
        [className]: className,
      })}
    >
      {children}
    </div>
  );
};

Cell.propTypes = propTypes;
Cell.defaultProps = defaultProps;

export default Cell;
