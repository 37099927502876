import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
// helpers
import { getPassedRelativeTime, getTimezoneTime } from 'helpers/RelativeTimeFormatter';
import { formatNumber, prettyNumber } from 'helpers/Number';
// components
import TimezoneDate from 'components/atoms/TimezoneDate/TimezoneDate';
import Tooltip from 'components/atoms/Tooltip';
import Audience from '../Audience';
// styles
import styles from '../publishBanner.module.scss';

const propTypes = {
  supplierDueDate: PropTypes.string.isRequired,
  publishedOn: PropTypes.string.isRequired,
  firstYearRate: PropTypes.number,
  currency: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
  publishAudience: PropTypes.array.isRequired,
  publishedToAll: PropTypes.bool.isRequired,
};

const defaultProps = {
  firstYearRate: null,
};

const Content = ({
  supplierDueDate,
  publishedOn,
  firstYearRate,
  currency,
  timezone,
  publishAudience,
  publishedToAll,
}) => {
  const { t } = useTranslation();
  const rate = firstYearRate ? prettyNumber(formatNumber(firstYearRate)) : null;

  const PublishInfo = () => (
    <div className={styles.publishInfo}>
      <Tooltip title={getTimezoneTime(publishedOn)}>
        <span>
          {t('published')}&nbsp;{getPassedRelativeTime(publishedOn)}
        </span>
      </Tooltip>
    </div>
  );

  const DueDate = () => (
    <b>
      <TimezoneDate date={supplierDueDate} timezone={timezone} />
    </b>
  );

  if (rate) {
    return (
      <div className={styles.content}>
        <div>
          <Trans
            i18nKey="publishBannerTargetRatesSet"
            values={{
              rate,
              currency,
            }}
            components={[
              <b />,
              <Audience publishedToAll={publishedToAll} publishAudience={publishAudience} />,
              <DueDate />,
            ]}
          />
        </div>
        <PublishInfo />
      </div>
    );
  }

  return (
    <div className={styles.content}>
      <div>
        <Trans i18nKey="publishBannerNoTargetRatesSet" components={[<b />, <DueDate />]} />
        <PublishInfo />
      </div>
    </div>
  );
};

Content.propTypes = propTypes;
Content.defaultProps = defaultProps;

export default Content;
