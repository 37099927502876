import { gql } from '@apollo/client';
import getProperty from 'lodash/get';
// queries
import { Rate, Profile } from 'queries/Fragments';
// services
import client from 'services/Client';
import logger from 'services/Logger';

const UPDATE_CANDIDATE = gql`
  mutation markCandidateAsDeleted($id: ID!, $data: CandidateUpdateInput!) {
    candidateUpdate(filter: { id: $id }, data: $data) {
      id
      deleted
      isInPool
      expectedRate {
        ...Rate
      }
      status
      availableFrom
      profile {
        ...Profile
      }
    }
    candidateMatchDeleteByFilter(filter: { candidate: { id: { equals: $id } } }, force: true) {
      success
    }
  }
  ${Rate}
  ${Profile}
`;

const REMOVE_CANDIDATE = gql`
  mutation removeCandidate($id: ID!) {
    candidateDelete(id: $id) {
      success
    }
  }
`;

const GET_CANDIDATE = gql`
  query getCandidateDeleteData($id: ID!) {
    candidate(id: $id) {
      id
      expectedRate {
        id
      }
      profile {
        id
        clearance {
          id
        }
        citizenship {
          id
        }
        permIssuingCountry {
          id
        }
        location {
          id
        }
        experienceLevel {
          id
        }
      }
    }
  }
`;

/**
 * @param {string} id
 * @param {boolean} hasSubmission Candidate has any submission?
 * @returns {Promise}
 */
const deleteCandidate = async (id, hasSubmission) => {
  try {
    const removeCandidateFromCache = cache => {
      cache.modify({
        fields: {
          candidatesList: (existing, { readField }) => {
            const count = getProperty(existing, 'count', 0);
            const items = getProperty(existing, 'items', []);

            return {
              count: count - 1,
              items: items.filter(candidateRef => {
                return readField('id', candidateRef) !== id;
              }),
            };
          },
        },
      });

      // delete cached candidate matches
      cache.evict({ fieldName: ['candidateMatchesList', 'candidatesList'] });
      cache.gc();
    };

    if (hasSubmission) {
      // mark candidate record as deleted + remove personal information from candidate record + position matches
      const response = await client.query({ query: GET_CANDIDATE, variables: { id } });
      const candidate = getProperty(response, 'data.candidate');
      const expectedRateId = getProperty(candidate, 'expectedRate.id');
      const clearanceId = getProperty(candidate, 'profile.clearance.id');
      const citizenshipId = getProperty(candidate, 'profile.citizenship.id');
      const permIssuingCountryId = getProperty(candidate, 'profile.permIssuingCountry.id');
      const locationId = getProperty(candidate, 'profile.location.id');
      const experienceLevelId = getProperty(candidate, 'profile.experienceLevel.id');

      const data = {
        deleted: true,
        isInPool: false,
        status: null,
        availableFrom: null,
        profile: {
          update: {
            fullName: '',
            preferredName: '',
            email: '',
            phone: '',
            skype: '',
            linkedIn: '',
            willingToRelocate: null,
            profession: '',
            description: '',
          },
        },
      };

      if (expectedRateId) {
        data.expectedRate = { disconnect: { id: expectedRateId } };
      }
      if (clearanceId) {
        data.profile.update.clearance = { disconnect: { id: clearanceId } };
      }
      if (citizenshipId) {
        data.profile.update.citizenship = { disconnect: { id: citizenshipId } };
      }
      if (permIssuingCountryId) {
        data.profile.update.permIssuingCountry = { disconnect: { id: permIssuingCountryId } };
      }
      if (locationId) {
        data.profile.update.location = { disconnect: { id: locationId } };
      }
      if (experienceLevelId) {
        data.profile.update.experienceLevel = { disconnect: { id: experienceLevelId } };
      }

      await client.mutate({
        mutation: UPDATE_CANDIDATE,
        variables: { id, data },
        update: removeCandidateFromCache,
      });
    } else {
      // delete candidate permanently. Delete all data from DB + files from box
      await client.mutate({
        mutation: REMOVE_CANDIDATE,
        variables: { id },
        context: { clientName: 'server' },
        update: removeCandidateFromCache,
      });
    }
  } catch (error) {
    logger.exception(error);
    throw error;
  }
};

export default deleteCandidate;
