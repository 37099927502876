import { gql } from '@apollo/client';

export const GET_FIELDS_OPTIONS = gql`
  query fieldsOptions($tableName: String!) {
    table(name: $tableName) {
      fields {
        name
        fieldTypeAttributes {
          ... on SwitchFieldTypeAttributes {
            listOptions
          }
        }
      }
    }
  }
`;
