import { gql } from '@apollo/client';
import moment from 'moment';
import pick from 'lodash/pick';
import uniq from 'lodash/uniq';
// queries
import { SubmissionStatus, SubmissionRole, Rate, File } from 'queries/Fragments';
import { UPDATE_POSITION_FOR_SUBMISSIONS } from 'queries/PositionSettingQueries';
// services
import client from 'services/Client';
import notification from 'services/Notification';
import axios from 'services/Axios';
import logger from 'services/Logger';
// helpers
import { getYearDate } from 'helpers/RelativeTimeFormatter';
import { formatHtmlTable } from 'helpers/Template';
import { getAbsoluteUrl } from 'helpers/Link';
// constants
import { SUBMISSION_STATUS } from 'constants/submissionStatus';
import {
  FILE_RELEASABLE_PROPOSAL,
  FILE_RELEASABLE_CV,
  FILE_RELEASABLE_ATTESTATION,
  FILE_RATE_CARD,
  RATES_SELL_PRICE,
  REVIEWER,
} from 'constants';

export const SUBMIT_CANDIDATES = gql`
  mutation submitCandidates($data: SubmissionUpdateInput!, $id: ID!) {
    submissionUpdate(data: $data, filter: { id: $id }) {
      id
      statuses(sort: { createdAt: DESC }) {
        items {
          ...SubmissionStatus
        }
      }
      submissionRoles {
        items {
          ...SubmissionRole
        }
      }
      documents(sort: { createdAt: DESC }) {
        items {
          ...File
        }
      }
      rates(filter: { endDate: { is_empty: true } }) {
        items {
          ...Rate
        }
      }
    }
  }
  ${SubmissionStatus}
  ${SubmissionRole}
  ${File}
  ${Rate}
`;

export const SUBMIT_PROPOSALS = gql`
  mutation submitProposals($data: ProposalUpdateInput!, $id: ID!) {
    proposalUpdate(data: $data, filter: { id: $id }) {
      id
      statuses(sort: { createdAt: DESC }) {
        items {
          ...SubmissionStatus
        }
      }
      roles {
        items {
          ...SubmissionRole
        }
      }
      documents(sort: { createdAt: DESC }) {
        items {
          ...File
        }
      }
      rates(filter: { endDate: { is_empty: true } }) {
        items {
          ...Rate
        }
      }
      position {
        documents(sort: { createdAt: DESC }) {
          items {
            ...File
          }
        }
      }
    }
  }
  ${SubmissionStatus}
  ${SubmissionRole}
  ${File}
  ${Rate}
`;

/**
 * @param {object} params
 * @param {object[]} params.submissions
 * @param {string} params.positionId
 * @param {string} params.manYearDiscount
 * @param {string} params.currency
 * @param {string} params.email
 * @param {object} params.emailOptions
 * @param {string} params.userId Logged in user id
 * @param {object} params.rateCard
 */
const commitSubmissions = async ({
  isProposal,
  submissions,
  positionId,
  manYearDiscount,
  currency,
  email,
  emailOptions,
  userId,
  rateCard,
}) => {
  const submissionPromises = submissions.map(submission => {
    const data = {};
    const now = moment().toISOString();
    const currentStatus = submission.getStatus();
    const newReleasableDocuments = [];

    // submission file
    if (submission.releasableSubmissionFile.isNew) {
      newReleasableDocuments.push({
        name: submission.releasableSubmissionFile.filename,
        boxId: submission.releasableSubmissionFile.boxId,
        type: isProposal ? FILE_RELEASABLE_PROPOSAL : FILE_RELEASABLE_CV,
      });
    }

    // attestation
    if (submission.releasableAttestation.isNew) {
      newReleasableDocuments.push({
        name: submission.releasableAttestation.filename,
        boxId: submission.releasableAttestation.boxId,
        type: FILE_RELEASABLE_ATTESTATION,
      });
    }

    // sell rates
    data.rates = {
      create: submission.sellRates.map(sellRate => ({
        value: sellRate.value,
        rateType: RATES_SELL_PRICE,
        startDate: getYearDate(sellRate.year),
        currency,
      })),
    };

    // add submission role to track, who made the submission to client
    const roleMutation = {
      create: {
        role: REVIEWER,
        user: {
          connect: {
            id: userId,
          },
        },
      },
    };

    if (isProposal) {
      data.roles = roleMutation;
      data.position = {
        update: {
          documents: {
            create: {
              name: rateCard.filename,
              boxId: rateCard.boxId,
              type: FILE_RATE_CARD,
            },
          },
        },
      };
    } else {
      data.submissionRoles = roleMutation;
    }

    // change submission status to "Submitted to client"
    data.statuses = {
      create: {
        name: SUBMISSION_STATUS.SUBMITTED,
        start: now,
      },
      update: {
        filter: {
          id: currentStatus.id,
        },
        data: {
          end: now,
        },
      },
    };

    // save new releasable attachments
    if (newReleasableDocuments.length > 0) {
      data.documents = {
        create: newReleasableDocuments,
      };
    }

    return client.mutate({
      mutation: isProposal ? SUBMIT_PROPOSALS : SUBMIT_CANDIDATES,
      variables: {
        id: submission.id,
        data,
      },
    });
  });

  try {
    await Promise.all(submissionPromises);

    // send email with attachments to client
    const emailPromise = axios.post('/api/email/candidate/submit', {
      isProposal,
      rateCard,
      position: positionId,
      manYearDiscount,
      submissions: submissions.map(submission => ({
        id: submission.id,
        sellRates: submission.sellRates.map(sellRate => sellRate.value),
        attachments: [
          pick(submission.releasableSubmissionFile, ['boxId', 'filename']),
          pick(submission.releasableAttestation, ['boxId', 'filename']),
        ],
      })),
      email: {
        options: emailOptions,
        content: formatHtmlTable(email),
      },
    });

    // add partners to position bids (needed for position filtering) and
    // reset "saved progress" for submission
    const partnersId = uniq(submissions.map(submission => submission.partner.id));
    const positionBidsPromise = client.mutate({
      mutation: UPDATE_POSITION_FOR_SUBMISSIONS,
      variables: { position: positionId, partners: partnersId.map(id => ({ id })) },
      update: cache => {
        cache.evict({ fieldName: ['positionsList', 'submissionsList', 'proposalsList'] });
        cache.gc();
      },
    });

    // these requests are not in parallel with submissions update, since we have to
    // refetch positions and submissions list after submissions were updated + send email to client
    // only if all the changes in DB were successfully committed
    await Promise.all([emailPromise, positionBidsPromise]);

    // send notifications to partners and PM
    // TODO: Including all submissions into 1 notification per partner will be part of an another
    // US. Until then, it will be separate notification for each submission made
    submissions.forEach(submission => {
      notification.submissionSubmittedToClient({
        submission: submission.id,
        link: getAbsoluteUrl(`/${isProposal ? 'proposal' : 'submission'}/${submission.id}`),
        createdBy: userId,
        isProposal,
      });
      // notification for PM
      notification.newSubmissionSubmitted({
        submission: submission.id,
        link: getAbsoluteUrl(`/${isProposal ? 'proposal' : 'submission'}/${submission.id}`),
        createdBy: userId,
        isProposal,
      });
    });
  } catch (error) {
    logger.exception(error);
    throw error;
  }
};

export default commitSubmissions;
