import React, { useEffect } from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
import clone from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
// components
import RateInput from 'components/molecules/RateInput';
// helpers
import { prettyNumber } from 'helpers/Number';
import { getYear } from 'helpers/RelativeTimeFormatter';
import { parseRates } from './helpers';
// style
import styles from './ratesInput.module.scss';

const propTypes = {
  rates: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      year: PropTypes.number,
      error: PropTypes.bool,
      errorMessage: PropTypes.string,
      highlighted: PropTypes.bool,
    })
  ),
  staticRates: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      rates: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
    })
  ),
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  startYear: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  numberOfYears: PropTypes.number.isRequired,
  hideYears: PropTypes.bool,
  currency: PropTypes.string.isRequired,
  decimalLimit: PropTypes.number,
  step: PropTypes.number,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  empty: PropTypes.bool,
  useForAllPopoverStyle: PropTypes.bool,
  classes: PropTypes.shape({
    row: PropTypes.string,
  }),
};

const defaultProps = {
  rates: [],
  staticRates: [],
  label: '',
  startYear: getYear(),
  hideYears: false,
  decimalLimit: 2,
  step: 0.5,
  disabled: false,
  readOnly: false,
  empty: false,
  useForAllPopoverStyle: false,
  classes: {
    row: '',
  },
};

const RatesInput = ({
  rates,
  staticRates,
  onChange,
  label,
  startYear,
  numberOfYears,
  hideYears,
  currency,
  decimalLimit,
  step,
  disabled,
  readOnly,
  empty,
  useForAllPopoverStyle,
  classes,
}) => {
  const parsedRates = parseRates(rates, parseInt(startYear), numberOfYears);
  const years = parsedRates.map(rate => rate.year);
  const hasLabel = label || staticRates.some(staticRate => staticRate.label);

  useEffect(() => {
    // if passed rates are incomplete -> trigger "onChange" with parsed rates
    const incomingRatesValue = rates.map(rate => rate.value);
    const parsedRatesValue = parsedRates.map(rate => rate.value);

    if (!isEqual(incomingRatesValue, parsedRatesValue)) {
      onChange(parsedRates);
    }
  }, [rates, startYear, numberOfYears]);

  const handleChange = index => value => {
    const newRates = clone(parsedRates);
    newRates[index].value = value;

    onChange(newRates, index);
  };

  const handleUseForAll = value => {
    const newRates = parsedRates.map(rate => ({
      ...rate,
      value,
    }));

    onChange(newRates);
  };

  return (
    <div className={classNames(styles.container, { [styles.noLabels]: !hasLabel })}>
      {!hideYears && (
        <div className={classNames(styles.row, styles.years, classes.row)}>
          {years.map(year => (
            <div key={year} className={styles.year}>
              {year}
            </div>
          ))}
        </div>
      )}
      {staticRates.map(staticRate => (
        <React.Fragment key={staticRate.label}>
          <div className={styles.label}>{staticRate.label}</div>
          <div className={classNames(styles.row, classes.row)}>
            {staticRate.rates.map((rate, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index} className={styles.staticRate}>
                {prettyNumber(rate)} {currency}
              </div>
            ))}
          </div>
        </React.Fragment>
      ))}
      {label && <div className={styles.label}>{label}</div>}
      <div className={classNames(styles.row, classes.row)}>
        {parsedRates.map((rate, index) => (
          <div className={classNames({ disabledPointer: disabled })} key={rate.year}>
            <RateInput
              value={rate.value}
              onChange={handleChange(index)}
              onUseForAll={handleUseForAll}
              currency={currency}
              decimalLimit={decimalLimit}
              step={step}
              error={rate.error}
              errorMessage={rate.errorMessage}
              highlighted={rate.highlighted}
              disabled={disabled}
              readOnly={readOnly}
              empty={empty}
              useForAllPopoverStyle={useForAllPopoverStyle}
              hideUseAll={parsedRates.length === 1}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

RatesInput.propTypes = propTypes;
RatesInput.defaultProps = defaultProps;

export default RatesInput;
