import React, { useContext, useState, useEffect, useCallback, useRef } from 'react';
// libraries
import PropTypes from 'prop-types';
import getProperty from 'lodash/get';
import isEqual from 'lodash/isEqual';
// constants
import { RATES_SUPPLIER_RATE, RATES_SELL_PRICE } from 'constants';
// helpers
import { initCandidateSellPrices, initCandidateGPs, getRatesValue } from '../../helpers';
// context
import MultipliersContext from '../../MultipliersContext';
import FilterContext from '../FilterContext';
// components
import Summary from './Summary';
import ActiveRates from './ActiveRates';
import Rates from './Rates';
// styles
import './submission.scss';

const propTypes = {
  evenRow: PropTypes.bool,
  activeRates: PropTypes.bool,
  submission: PropTypes.object.isRequired,
};

const defaultProps = {
  evenRow: false,
  activeRates: true,
};

const Submission = React.memo(({ evenRow, activeRates, submission }) => {
  const { setCandidateGPs, setSellRates } = useContext(FilterContext);
  const { manYearDiscount } = useContext(MultipliersContext);
  const sellRates = submission.getRates(RATES_SELL_PRICE);
  const partnerRates = submission.getRates(RATES_SUPPLIER_RATE);
  const isDirectSupplier = submission.isDirectPartner();
  const { includeCSAFee } = submission.partner;
  const supplierRatesValue = getRatesValue(partnerRates);
  const currency = getProperty(partnerRates, '0.currency', '');
  const initialPrices = initCandidateSellPrices({
    supplierRates: partnerRates,
    manYearDiscount,
    isDirectSupplier,
    includeCSAFee,
    isFixedPrice: submission.isProposal(),
    sellRates,
  });

  const [sellPrices, setSellPrices] = useState(initialPrices);
  const [ibmGPs, setIbmGPs] = useState(
    initCandidateGPs(
      partnerRates,
      sellRates.length > 0 ? sellRates : initialPrices,
      manYearDiscount,
      isDirectSupplier,
      includeCSAFee
    )
  );
  const lastGPsForSorting = useRef(ibmGPs); // persist last GPs for sorting

  const classes = ['positionCandidate'];
  if (evenRow) {
    classes.push('positionCandidate-dark');
  }

  const onIbmGPsChange = useCallback(GPs => {
    setIbmGPs(GPs);
  }, []);

  // set initial GPs for sorting
  useEffect(() => {
    setCandidateGPs(submission.id, ibmGPs);
  }, []);

  // set candidate's GP for sorting
  const handleBlur = e => {
    // set changes if candidate rates are not further focused. It's prevents candidates re-ordering while
    // typing values
    if (!e.currentTarget.contains(e.relatedTarget)) {
      // check if GPs have changed
      if (!isEqual(ibmGPs, lastGPsForSorting.current)) {
        setCandidateGPs(submission.id, ibmGPs);
        lastGPsForSorting.current = ibmGPs;
      }
    }
  };

  const handleSellPricesChange = rates => {
    setSellPrices(rates);
    setSellRates(submission.id, rates);
  };

  return (
    <div className={classes.join(' ')} onBlur={handleBlur}>
      <Summary submission={submission} sellPrices={sellPrices} />
      {!activeRates ? (
        <Rates
          currency={currency}
          supplierRates={supplierRatesValue}
          ibmGPs={ibmGPs}
          sellPrices={sellRates.map(rate => rate.value)}
          isProposal={submission.isProposal()}
        />
      ) : (
        <ActiveRates
          currency={currency}
          isDirectSupplier={isDirectSupplier}
          includeCSAFee={includeCSAFee}
          supplierRates={supplierRatesValue}
          ibmGPs={ibmGPs}
          sellPrices={sellPrices}
          onSellPricesChange={handleSellPricesChange}
          onIbmGPsChange={onIbmGPsChange}
          isProposal={submission.isProposal()}
        />
      )}
    </div>
  );
});

Submission.propTypes = propTypes;
Submission.defaultProps = defaultProps;

export default Submission;
