import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// components
import Icon from 'components/atoms/Icon';
// constants
import { COLOR } from 'constants/colors';
// styles
import styles from './textIcon.module.scss';

const propTypes = {
  icon: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  size: PropTypes.number,
  color: PropTypes.oneOf(Object.values(COLOR)),
  classes: PropTypes.shape({
    root: PropTypes.string,
    icon: PropTypes.string,
  }),
};

const defaultProps = {
  color: COLOR.BLUE,
  classes: {},
  size: 13,
};

const TextIcon = ({ icon, children, color, classes, size }) => {
  return (
    <div className={classNames(styles.container, classes.root)}>
      <span className={classNames(styles.icon, styles[color], classes.icon)}>
        <Icon name={icon} size={size} />
      </span>
      <span className="m-l-10">{children}</span>
    </div>
  );
};

TextIcon.propTypes = propTypes;
TextIcon.defaultProps = defaultProps;

export default TextIcon;
