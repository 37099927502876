import getProperty from 'lodash/get';
// constants
import { DEFAULT_TARGET_MARGIN, DEFAULT_DGP, DEFAULT_DGP_FP } from 'constants/rates';
// helpers
import { ceilNumber } from 'helpers/Number';
import {
  calculateTargetRate,
  calculateCandidateSellPriceFromGP,
  calculateCandidateGPFromSellPrice,
} from 'helpers/RatesCalculation';

/**
 * @typedef {Object} Rate
 * @property {string} id
 * @property {value} number
 * @property {startDate} string
 * @property {currency} string
 */

/**
 * Extract rate values from list of rate objects
 * @param {Rate[]} rates
 * @returns {number[]}
 */
export const getRatesValue = rates => rates.map(rate => rate.value);

/**
 * @param {Rate[]} nteRates
 * @param {Rate[]} [targetRatesFromDb]
 * @returns {Rate[]}
 */
export const initTargetRates = (nteRates, targetRatesFromDb = []) => {
  if (!nteRates.length) return [];
  if (nteRates.length === targetRatesFromDb.length) {
    return targetRatesFromDb;
  }

  const calculatedTargetRates = nteRates.map(({ value, currency, startDate }) => {
    return {
      value: calculateTargetRate(value, DEFAULT_TARGET_MARGIN),
      currency,
      startDate,
    };
  });

  // if target rates are already saved, but number of position rates changed ->
  // number of target rates and client NTE rates are not the same -> fill missing target rates
  // or remove additional target rates. Target rates from DB have higher priority than calculated rates
  const targetRates = targetRatesFromDb.slice(0, nteRates.length); // remove additional rates if needed
  // if some rates are missing -> fill them from calculated rates
  if (targetRates.length < nteRates.length) {
    return [...targetRates, ...calculatedTargetRates.slice(targetRates.length, nteRates.length)];
  }

  return targetRates;
};

/**
 * @param {Rate[]} supplierRates
 * @param {number} ibmFee
 * @param {bool} isDirectSupplier
 * @param {bool} includeCSAFee
 * @param {Rate[]} [sellRates=[]] Sell prices from DB
 * @param {boolean} isFixedPrice
 * @returns {Rate}
 */
export const initCandidateSellPrices = ({
  supplierRates,
  manYearDiscount,
  isDirectSupplier,
  includeCSAFee,
  isFixedPrice,
  sellRates: sellPricesFromDb = [],
}) => {
  if (!supplierRates.length) return [];
  if (supplierRates.length === sellPricesFromDb.length) {
    return sellPricesFromDb;
  }

  const calculatedSellPrices = supplierRates.map(({ value, currency, startDate }) => {
    return {
      value: ceilNumber(
        calculateCandidateSellPriceFromGP(
          value,
          isFixedPrice ? DEFAULT_DGP_FP : DEFAULT_DGP,
          manYearDiscount,
          isDirectSupplier,
          includeCSAFee
        )
      ),
      currency,
      startDate,
    };
  });

  // if sell prices are already saved, but number of supplier rates changed ->
  // number of sell prices and supplier rates are not the same -> fill missing sell prices
  // or remove additional sell prices. Sell prices from DB have higher priority than calculated rates
  const sellPrices = sellPricesFromDb.slice(0, supplierRates.length); // remove additional rates if needed
  // if some prices are missing -> fill them from calculated prices
  if (sellPrices.length < supplierRates.length) {
    return [...sellPrices, ...calculatedSellPrices.slice(sellPrices.length, supplierRates.length)];
  }

  return sellPrices;
};

/**
 * @param {Rate[]} supplierRates
 * @param {Rate[]} sellPricesFromDb
 * @param {number} manYearDiscount
 * @param {bool} isDirectSupplier
 * @param {bool} includeCSAFee
 * @returns {number[]}
 */
export const initCandidateGPs = (
  supplierRates,
  sellPrices,
  manYearDiscount,
  isDirectSupplier,
  includeCSAFee
) => {
  return supplierRates.map((supplierRate, index) => {
    const sellPrice = getProperty(sellPrices, `${index}.value`, 0);

    return calculateCandidateGPFromSellPrice(
      supplierRate.value,
      sellPrice,
      manYearDiscount,
      isDirectSupplier,
      includeCSAFee
    );
  });
};
