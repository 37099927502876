// libraries
import getProperty from 'lodash/get';
// services
import { upload } from 'services/Box';
// constants
import { SUPPLIER_ROLE, STAFF_ROLE, MANAGER_ROLE } from 'constants';
import { SUBMISSION_STATUS_TYPE } from 'constants/submissionStatus';
import {
  ONBOARDING_STATUS_DATE,
  ONBOARDING_STATUS_FORMS,
  ONBOARDING_STATUS_PO,
} from 'constants/onboarding';

export const uploadOnboardingFile = async (formData, folder, key = 'file', type = 'JOB_OFFER') => {
  const file = getProperty(formData, `${key}.0.file`);
  if (!file) {
    return formData;
  }

  const extension = file.name.split('.').pop();
  const filename = `${type}.${extension}`;
  const uploadedFile = {
    boxId: await upload(file, filename, folder),
    name: filename,
    file: {
      name: filename,
      path: filename,
    },
  };

  return { ...formData, [key]: [uploadedFile] };
};

/**
 * @param {object} submission
 * @returns {boolean}
 */
export const isOnboardingFormsStepFinished = submission => {
  const onboardingFormsStatusPM = submission.findStatus(ONBOARDING_STATUS_FORMS.SEND_TO_PM);
  const onboardingFormsStatusAUSY = submission.findStatus(ONBOARDING_STATUS_FORMS.SEND_TO_AUSY);

  if (submission.isDirectPartner()) {
    return onboardingFormsStatusPM && onboardingFormsStatusPM.end;
  }

  return (
    onboardingFormsStatusPM &&
    onboardingFormsStatusPM.end &&
    onboardingFormsStatusAUSY &&
    onboardingFormsStatusAUSY.end
  );
};

/**
 * Ger roles which need to take next action in onboarding
 * @param {object} submission Onboarding submission
 * @param {string} role
 * @returns {string[]} List of pending roles
 */
export const getOnboardingPendingRoles = (submission, role) => {
  const startDateStatus = submission.getStatus(role, SUBMISSION_STATUS_TYPE.ONBOARDING_START_DATE);
  const formsStatus = submission.getStatus(role, SUBMISSION_STATUS_TYPE.ONBOARDING_FORMS);
  const poStatus = submission.getStatus(role, SUBMISSION_STATUS_TYPE.ONBOARDING_PO);
  const startDateStatusValue = startDateStatus ? startDateStatus.value : '';
  const formsStatusValue = formsStatus ? formsStatus.value : '';
  const poStatusValue = poStatus ? poStatus.value : '';
  const pendingRoles = [];

  // Partner role
  if (
    startDateStatusValue === ONBOARDING_STATUS_DATE.PROPOSE ||
    formsStatusValue === ONBOARDING_STATUS_FORMS.SUBMIT
  ) {
    pendingRoles.push(SUPPLIER_ROLE);
  }

  // PM role
  if (
    startDateStatusValue === ONBOARDING_STATUS_DATE.APPROVE ||
    poStatusValue === ONBOARDING_STATUS_PO.RELEASE
  ) {
    pendingRoles.push(MANAGER_ROLE);
  }

  // MM role
  if (
    startDateStatusValue === ONBOARDING_STATUS_DATE.SUBMIT ||
    (formsStatusValue !== ONBOARDING_STATUS_FORMS.SUBMIT &&
      !isOnboardingFormsStepFinished(submission)) ||
    poStatusValue === ONBOARDING_STATUS_PO.CONFIRM
  ) {
    pendingRoles.push(STAFF_ROLE);
  }

  return pendingRoles;
};

/**
 * Find out if step is finished for set role.
 * @param {string} status Onboarding status
 * @param {object} submission
 * @param {string} role
 * @returns {boolean}
 */
export const hasPendingOnboardingAction = (status, submission, role) => {
  const startDateStatus = getProperty(
    submission.getStatus(role, SUBMISSION_STATUS_TYPE.ONBOARDING_START_DATE),
    'value'
  );
  const formsStatus = getProperty(
    submission.getStatus(role, SUBMISSION_STATUS_TYPE.ONBOARDING_FORMS),
    'value'
  );
  const poStatus = getProperty(
    submission.getStatus(role, SUBMISSION_STATUS_TYPE.ONBOARDING_PO),
    'value'
  );

  if (status === SUBMISSION_STATUS_TYPE.ONBOARDING_START_DATE) {
    if (role === SUPPLIER_ROLE) return startDateStatus === ONBOARDING_STATUS_DATE.PROPOSE;
    if (role === STAFF_ROLE) return startDateStatus === ONBOARDING_STATUS_DATE.SUBMIT;
  }

  if (status === SUBMISSION_STATUS_TYPE.ONBOARDING_FORMS) {
    if (role === SUPPLIER_ROLE) return formsStatus === ONBOARDING_STATUS_FORMS.SUBMIT;
    if (role === STAFF_ROLE)
      return (
        formsStatus !== ONBOARDING_STATUS_FORMS.SUBMIT && !isOnboardingFormsStepFinished(submission)
      );
  }

  if (status === SUBMISSION_STATUS_TYPE.ONBOARDING_PO) {
    if (role === MANAGER_ROLE) return poStatus === ONBOARDING_STATUS_PO.RELEASE;
    if (role === STAFF_ROLE) return poStatus === ONBOARDING_STATUS_PO.CONFIRM;
  }

  return false;
};

/**
 * Is submission ready for delivery? All onboarding actions have been already finished?
 * @param {object} submission
 * @param {string} role
 * @returns {bool}
 */
export const isReadyForDelivery = (submission, role) => {
  const startDateStatus = submission.getStatus(role, SUBMISSION_STATUS_TYPE.ONBOARDING_START_DATE);
  const formsStatus = submission.getStatus(role, SUBMISSION_STATUS_TYPE.ONBOARDING_FORMS);
  const poStatus = submission.getStatus(role, SUBMISSION_STATUS_TYPE.ONBOARDING_PO);

  return !startDateStatus && !formsStatus && !poStatus;
};
