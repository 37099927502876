import { gql } from '@apollo/client';
// services
import client from 'services/Client';

/**
 * Trigger bulk matching of candidate
 * @param {string} candidateId
 */
export const triggerCandidateBulkMatching = candidateId => {
  const CREATE_CANDIDATE_BULK_MATCH = gql`
    mutation createCandidateBulkMatch($candidateId: ID!) {
      createCandidateBulkMatch(candidate: $candidateId) {
        success
      }
    }
  `;

  return client.mutate({
    mutation: CREATE_CANDIDATE_BULK_MATCH,
    variables: {
      candidateId,
    },
  });
};

/**
 * Trigger bulk matching for suppliers
 * @param {string} positionId
 * @param {string[]} supplierIds Suppliers added to audience. If not set suppliers will be fetched from position's current audience
 */
export const triggerPositionBulkMatching = (positionId, supplierIds) => {
  const CREATE_BULK_MATCH = gql`
    mutation createPositionBulkMatch($position: ID!, $suppliers: [ID!] = []) {
      createPositionBulkMatch(position: $position, suppliers: $suppliers) {
        success
      }
    }
  `;

  client.mutate({
    mutation: CREATE_BULK_MATCH,
    variables: {
      position: positionId,
      suppliers: supplierIds,
    },
  });
};
