import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// components
import Button from 'components/atoms/Button';
import LinkButton from 'components/atoms/LinkButton';
// styles
import styles from './sideFilter.module.scss';

const propTypes = {
  onClose: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const SideFilter = ({ onClose, onClear, children, className }) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(className, styles.container)}>
      <div className={styles.closeButton}>
        <Button type="clear" onClick={onClose} tooltip={t('close')} />
      </div>
      <div className={styles.header}>
        <span className={styles.title}>{t('filter')}</span>
        <LinkButton onClick={onClear} className={styles.clearButton}>
          {t('clear')}
        </LinkButton>
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

SideFilter.propTypes = propTypes;
SideFilter.defaultProps = defaultProps;

export default SideFilter;
