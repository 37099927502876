/* eslint-disable import/order */
import React, { useState, useContext } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// hooks
import useAuth from 'hooks/useAuth';
// context
import ToastContext from 'context/ToastContext';
// components
import Button from 'components/atoms/Button';
import EditForm from '../EditForm';
import PurchaseOrderTicket from './PurchaseOrderTicket';
import TextContent from '../TextContent';
// constants
import { SUBMISSION_STATUS_TYPE } from 'constants/submissionStatus';
import { PO_PENDING } from './constants';
// helpers
import { purchaseOrderForm, disabledPurchaseOrderComponents } from '../forms';
import { getDefaultData } from './helpers';
import { createPurchaseOrder } from '../helpers';

const propTypes = {
  purchaseOrder: PropTypes.object,
  submission: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
};

const defaultProps = {
  purchaseOrder: {},
  readOnly: false,
};

const ReleasePurchaseOrder = ({ purchaseOrder, submission, position, readOnly }) => {
  const { addToast } = useContext(ToastContext);
  const { t } = useTranslation();
  const { id: userId, role } = useAuth();

  const [editable, setEditable] = useState(false);
  const [loading, setLoading] = useState(false);

  const { rfq, project } = position;
  const onboardingStatus = submission.getStatus(role, SUBMISSION_STATUS_TYPE.ONBOARDING_PO);

  const handleSubmit = async formData => {
    try {
      setLoading(true);
      await createPurchaseOrder({
        formData,
        submission,
        currentOnboardingStatusId: onboardingStatus.id,
        position,
        userId,
      });
      setEditable(false);
    } catch (error) {
      addToast.error(t('errorWhileCreatingPO'));
      setLoading(false);
    }
  };

  if (editable) {
    const defaultData = getDefaultData(submission, position);

    return (
      <EditForm
        form={purchaseOrderForm}
        disabledComponents={disabledPurchaseOrderComponents}
        onCancel={() => setEditable(false)}
        onSubmit={handleSubmit}
        defaultData={defaultData}
        onSubmitLabel={t('createPO')}
        loading={loading}
        className="p-t-40 purchaseOrderForm"
        moreInfo={{ currency: project.currency, numberOfYears: 1, hideNames: true }}
      />
    );
  }

  return (
    <TextContent
      title={readOnly ? t('waitingForMMToConfirmPO') : t('addYourPurchaseOrder')}
      label={!readOnly ? <Button label={t('createPO')} onClick={() => setEditable(true)} /> : null}
      content={
        <PurchaseOrderTicket
          type={PO_PENDING}
          className="w100 m-t-30"
          purchaseOrder={purchaseOrder}
          candidateName={submission.getName()}
          rfq={rfq}
        />
      }
    />
  );
};

ReleasePurchaseOrder.propTypes = propTypes;
ReleasePurchaseOrder.defaultProps = defaultProps;

export default ReleasePurchaseOrder;
