import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
// context
import ToastContext from 'context/ToastContext';
// services
import logger from 'services/Logger';
// components
import Modal from 'components/molecules/Modal';
import Loader from 'components/atoms/Loader';
import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
// constants
import { MAX_ALLOWED_NAME_LENGTH } from 'constants';
// helper
import { updateDraftName } from '../Helpers';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  draftId: PropTypes.string.isRequired,
};

class EditDraftName extends React.PureComponent {
  static contextType = ToastContext;

  isMounted = false;

  state = {
    inputValue: '',
    loading: false,
    errorMessage: null,
  };

  componentDidMount() {
    this.isMounted = true;
  }

  componentWillUnmount() {
    this.isMounted = false;
  }

  onChange = inputValue => {
    const { errorMessage } = this.state;
    this.setState({ inputValue });
    if (errorMessage) {
      this.setState({ errorMessage: null });
    }
  };

  updateDraftName = async () => {
    const { addToast } = this.context;
    const { onClose, draftId, t } = this.props;
    const { inputValue } = this.state;

    if (inputValue !== '') {
      if (inputValue.length < MAX_ALLOWED_NAME_LENGTH) {
        this.setState({ loading: true });

        try {
          await updateDraftName(draftId, inputValue);
          addToast.success(t('updateDraftNameSuccessToast'));
        } catch (error) {
          addToast.error(t('errorWhileUpdatingDraftName'));
          logger.exception(error);
        } finally {
          if (this.isMounted) {
            this.setState({ loading: false });
            onClose();
          }
        }
      } else {
        this.setState({ errorMessage: t('max100CharsAllowed') });
      }
    } else {
      this.setState({ errorMessage: t('fillName') });
    }
  };

  render() {
    const { inputValue, loading, errorMessage } = this.state;
    const { isOpen, onClose, t } = this.props;

    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <Modal.Header>{t('updateDraftName')}</Modal.Header>
        <Modal.Body>
          {loading ? (
            <div className="space-30">
              <Loader />
            </div>
          ) : (
            <div className="space-10">
              <div className="p-b-15">{t('updateDraftNameWithName')}</div>
              <Input
                value={inputValue}
                placeholder={t('updateDraftNameName')}
                onChange={this.onChange}
                id="updateDraftNameName"
                onClear={() => this.onChange('')}
              />
              {errorMessage && <div className="field_requiredWarning">{errorMessage}</div>}
            </div>
          )}
        </Modal.Body>
        {!loading && (
          <Modal.Footer>
            <Button label={t('cancel')} secondary onClick={onClose} />
            <span className="p-l-12">
              <Button
                label={t('update')}
                disabled={inputValue === '' || loading}
                onClick={this.updateDraftName}
              />
            </span>
          </Modal.Footer>
        )}
      </Modal>
    );
  }
}

EditDraftName.propTypes = propTypes;

export default withTranslation()(EditDraftName);
