import React, { useState, useEffect, useRef } from 'react';
// libraries
import PropTypes from 'prop-types';
import useSize from '@react-hook/size';
import { useTranslation } from 'react-i18next';
// constants
import { SUPPORTED_COLORS } from 'constants/colors';
// component
import Popper from 'components/atoms/Popper';
import NodeComponent from 'components/atoms/NodeComponent';
// chart
import getDistributionBarChart from './distributionChart';
// styles
import styles from './distributionChart.module.scss';

const propTypes = {
  median: PropTypes.number.isRequired,
  mean: PropTypes.number.isRequired,
  score: PropTypes.number.isRequired,
  dataPoints: PropTypes.arrayOf(PropTypes.number).isRequired,
  matchColor: PropTypes.oneOf(SUPPORTED_COLORS).isRequired,
  candidateName: PropTypes.string.isRequired,
};

const DistributionGraph = ({ median, mean, score, dataPoints, matchColor, candidateName }) => {
  const { t } = useTranslation();
  const containerElement = useRef();
  const [width] = useSize(containerElement);
  const [node, setNode] = useState();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [hoveredBar, setHoveredBar] = useState({});

  const handleBarHover = (event, data) => {
    setHoveredBar({
      count: data.count,
      score: data.score,
      offset: event.offsetX,
    });
    setTooltipOpen(true);
  };

  useEffect(() => {
    if (width) {
      setNode(
        getDistributionBarChart({
          width,
          median,
          mean,
          score,
          matchColor,
          candidateName,
          dataPoints,
          onMouseEnter: handleBarHover,
          onMouseLeave: () => setTooltipOpen(false),
        })
      );
    }
  }, [width, median, mean, score, matchColor, candidateName]);

  return (
    <div ref={containerElement}>
      <Popper
        isOpen={tooltipOpen}
        anchorEl={containerElement.current}
        placement="top-start"
        className={styles.tooltip}
        disableBackdrop
        disablePortal
        modifiers={{
          offset: {
            enabled: true,
            offset: `${hoveredBar.offset - 50}, -5px`,
          },
        }}
      >
        <React.Fragment>
          <div className="m-b-5">
            <b>{hoveredBar.count}</b> {hoveredBar.count > 1 ? t('candidates') : t('candidate')}
          </div>
          <b>{hoveredBar.score}</b> {t('score')}
        </React.Fragment>
      </Popper>
      <NodeComponent className={styles.barChart} node={node} />
    </div>
  );
};

DistributionGraph.propTypes = propTypes;

export default DistributionGraph;
