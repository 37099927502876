// Libraries
import i18n from 'i18next';
import shortid from 'shortid';
// helpers
import { parsePersonData, parseLocationData } from 'helpers/FormDataParser';
// constants
import { GET_PEOPLE } from 'queries/ProjectQueries';
import { GET_LOCATION } from 'queries/SearchQueries';
import { COMPONENT_ACTIVITIES, COMPONENT_SKILLS } from 'constants';
import { PEOPLE_AUTOCOMPLETE, LOCATION_AUTOCOMPLETE } from './Constants';

export const getCustomQuery = type => {
  switch (type) {
    case PEOPLE_AUTOCOMPLETE:
      return GET_PEOPLE;
    case LOCATION_AUTOCOMPLETE:
      return GET_LOCATION;
    default:
      return null;
  }
};

export const getCustomDataParser = type => {
  switch (type) {
    case PEOPLE_AUTOCOMPLETE:
      return parsePersonData;
    case LOCATION_AUTOCOMPLETE:
      return parseLocationData;
    default:
      return null;
  }
};

export const translateData = (values, properties) => {
  if (properties && properties.valuesNeedsTranslation) {
    return values.map(value => {
      return { ...value, label: i18n.t(value.label) };
    });
  }
  return values;
};

export const reCalculateAngle = data => {
  const angle = Math.floor(100 / data.length);
  return data.map(item => ({ ...item, angle }));
};

const VALUE_REQUIRED = 'REQUIRED';
const requiredValue = {
  value: VALUE_REQUIRED,
  label: 'Required',
};

const getNewField = (value, fieldId) => {
  const field = {
    description: value,
  };
  if (fieldId === COMPONENT_ACTIVITIES) {
    return { ...field, id: shortid.generate() };
  }
  if (fieldId === COMPONENT_SKILLS) {
    return { ...field, ...requiredValue, id: shortid.generate() };
  }
  return field;
};

export const addItemToData = (value, fieldId, data) => {
  const newField = getNewField(value, fieldId, data);
  return data ? [...data, newField] : [newField];
};

export const validateNumber = (value, validate) => {
  if (value) {
    const number = value.value;
    if (validate.min && number < validate.min) {
      return false;
    }
    if (validate.max && number > validate.max) {
      return false;
    }
  }
  return true;
};
