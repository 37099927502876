import React from 'react';
// libraries
import { useTranslation, Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import isNumber from 'lodash/isNumber';
import classNames from 'classnames';
// helpers
import { getLevelByScore } from 'helpers/MRI';
// components
import Banner from 'components/molecules/Banner';
import LinkButton from 'components/atoms/LinkButton';
// constants
import { MRI_LEVEL } from 'constants/report';
// styles
import styles from './mriBanner.module.scss';

const propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  mriScore: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  noDescription: PropTypes.bool,
};

const defaultProps = {
  className: '',
  noDescription: false,
};

const MRIBanner = ({ mriScore, onClick, className, noDescription }) => {
  const { t } = useTranslation();
  const level = isNumber(mriScore) ? getLevelByScore(mriScore) : mriScore;

  if (!mriScore) {
    return (
      <Banner type="missing" className={classNames('align-start', className)}>
        <div className={styles.mriBanner}>
          <div>
            <b>{t('mRINotAvailableTitle')}</b>
          </div>
          {!noDescription && (
            <div className="m-t-8 line-3">{t('mriCurrentlyNotAvailableDescription')}</div>
          )}
        </div>
      </Banner>
    );
  }

  if (level === MRI_LEVEL.HIGH) {
    return (
      <Banner type="success" className={classNames('align-start', className)}>
        <div className={styles.mriBanner}>
          <div>
            <b>{t('cvWasFullyReadAndUnderstood')}</b>
          </div>
          {!noDescription && (
            <div className="m-t-8 line-3">
              <Trans i18nKey="mriMainMessageHigh">
                The Match Score <b>should be accurate</b>.
              </Trans>
            </div>
          )}
          <LinkButton onClick={onClick} className="m-t-8">
            {t('viewDetails')}
          </LinkButton>
        </div>
      </Banner>
    );
  }

  if (level === MRI_LEVEL.MODERATE) {
    return (
      <Banner type="warning" className={classNames('align-start', className)}>
        <div className={styles.mriBanner}>
          <div>
            <b>{t('cvWasNotSufficientlyUnderstood')}</b>
          </div>
          {!noDescription && (
            <div className="m-t-8 line-3">
              <Trans i18nKey="mriMainMessageAverage">
                The CV Readability could be improved.
                <br /> The Match Score <b>may be mildly affected</b>.
              </Trans>
            </div>
          )}
          <LinkButton onClick={onClick} className="m-t-8">
            {t('seeWhatWentWrong')}
          </LinkButton>
        </div>
      </Banner>
    );
  }

  if (level === MRI_LEVEL.LOW) {
    return (
      <Banner type="alert" className={classNames('align-start', className)}>
        <div className={styles.mriBanner}>
          <div>
            <b>{t('cvWasNotSufficientlyUnderstood')}</b>
          </div>
          {!noDescription && (
            <div className="m-t-8 line-3">
              <Trans i18nKey="mriMainMessageBad">
                The CV Readability could be significantly improved.
                <br /> The Match Score <b>may be heavily affected</b>.
              </Trans>
            </div>
          )}
          <LinkButton onClick={onClick} className="m-t-8">
            {t('seeWhatWentWrong')}
          </LinkButton>
        </div>
      </Banner>
    );
  }

  return null;
};

MRIBanner.propTypes = propTypes;
MRIBanner.defaultProps = defaultProps;

export default MRIBanner;
