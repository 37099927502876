import React from 'react';
// libraries
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// components
import LinkButton from 'components/atoms/LinkButton';
import SubmissionStatusFlow from 'components/molecules/SubmissionStatusFlow';
import Rates from 'components/molecules/Rates';
// constants
import { SUBMISSION_STATUS } from 'constants/submissionStatus';
import { SUBMISSION_TAB } from 'constants/pageTabs';
import {
  SUPPLIER_ROLE,
  FILE_PROPOSAL,
  FILE_ATTESTATION,
  RATES_SELL_PRICE,
  RATES_SUPPLIER_RATE,
} from 'constants';
// helpers
import { getPassedRelativeTime } from 'helpers/RelativeTimeFormatter';
import { openLink } from 'helpers/Link';
import { boxFileUrl } from 'helpers/Box';
// styles
import styles from './positionSubmissions.module.scss';

const propTypes = {
  role: PropTypes.string.isRequired,
  proposal: PropTypes.object.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const Proposal = ({ role, proposal, className }) => {
  const { t } = useTranslation();

  const status = proposal.getStatus(role);
  const proposalFile = proposal.getDocument(FILE_PROPOSAL);
  const attestation = proposal.getDocument(FILE_ATTESTATION);
  const partnerRates = proposal.getRates(RATES_SUPPLIER_RATE);
  const sellRates = proposal.getRates(RATES_SELL_PRICE);

  const getDetailLink = () => {
    const link = `/proposal/${proposal.id}`;

    if ([SUBMISSION_STATUS.OFFER, SUBMISSION_STATUS.ONBOARDING].includes(status.value)) {
      return `${link}?tab=${SUBMISSION_TAB.ONBOARDING}`;
    }

    return link;
  };

  const handleLinkClick = link => event => {
    event.preventDefault();
    openLink(link);
  };

  return (
    <Link to={getDetailLink()}>
      <div className={classNames(styles.proposalRow, { [className]: className })}>
        <div>
          <div className={styles.info}>
            <div className={styles.name}>{proposal.name}</div>
            <SubmissionStatusFlow submission={proposal} />
          </div>
          {role === SUPPLIER_ROLE && (
            <div className={styles.supplier}>{proposal.submittedBy.name}</div>
          )}
          <div className={styles.date}>{getPassedRelativeTime(status.start)}</div>
        </div>

        <Rates
          rates={role === SUPPLIER_ROLE ? partnerRates : sellRates}
          hideYears={partnerRates.length === 1}
        />

        <div className="fs-unmask">
          <LinkButton onClick={handleLinkClick(boxFileUrl(proposalFile.boxId))}>
            {t('viewProposal')}
          </LinkButton>
          <LinkButton onClick={handleLinkClick(boxFileUrl(attestation.boxId))} className="m-t-2">
            {t('attestation')}
          </LinkButton>
        </div>
      </div>
    </Link>
  );
};

Proposal.propTypes = propTypes;
Proposal.defaultProps = defaultProps;

export default Proposal;
