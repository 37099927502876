import React from 'react';
// libraries
import PropTypes from 'prop-types';
// components
import CircleTabs from './CircleTabs';
import ProgressTabs from './ProgressTabs';
// helpers
import { getProgress } from './Helpers';
// styles
import './navigation.scss';

const propTypes = {
  tab: PropTypes.string,
  setTab: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      steps: PropTypes.arrayOf(
        PropTypes.shape({
          finished: PropTypes.bool.isRequired,
        })
      ),
    })
  ),
  tabDetailsOpen: PropTypes.bool,
  toggleTabDetails: PropTypes.func,
};

const defaultProps = {
  tab: null,
  tabs: [],
  tabDetailsOpen: true,
  toggleTabDetails: () => {},
};

const Navigation = props => {
  const { tab, setTab, tabs, tabDetailsOpen, toggleTabDetails } = props;
  const { progress, percentage } = getProgress(tabs);

  return (
    <div className="navigation fs-unmask">
      <CircleTabs
        tabs={tabs}
        activeTab={tab}
        setTab={setTab}
        percentage={percentage}
        progress={progress}
        open={tabDetailsOpen}
        toggleBar={toggleTabDetails}
      />
      {tabDetailsOpen && <ProgressTabs className="cp" currentTab={tab} tabs={tabs} />}
    </div>
  );
};

Navigation.propTypes = propTypes;
Navigation.defaultProps = defaultProps;

export default Navigation;
