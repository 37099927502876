import React, { useContext } from 'react';
// libraries
import { Trans, useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// context
import ToastContext from 'context/ToastContext';
// components
import Icon from 'components/atoms/Icon';
// helpers
import { formatAcceptTypes } from './helpers';
// styles
import styles from './fileUpload.module.scss';

const propTypes = {
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  accept: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  multiple: false,
  disabled: false,
  error: false,
  accept: null,
};

const ERRORS = {
  INVALID_FILE_TYPE: 'file-invalid-type',
  TOO_MANY_FILES: 'too-many-files',
};

const FileUpload = ({ accept, multiple, disabled, error, onChange }) => {
  const { t } = useTranslation();
  const { addToast } = useContext(ToastContext);

  const onDrop = (acceptedFiles, rejectedFiles) => {
    // error when trying to upload multiple files but only one is allowed
    const tooManyFiles = rejectedFiles.some(rejectedFile =>
      rejectedFile.errors.some(({ code }) => code === ERRORS.TOO_MANY_FILES)
    );
    if (!multiple && rejectedFiles.length && tooManyFiles) {
      addToast.error(t('multipleFilesUploadNotAllowed'));
    }

    // error when all the added files have not supported type
    const allFilesHaveInvalidFileType = rejectedFiles.every(rejectedFile =>
      rejectedFile.errors.some(({ code }) => code === ERRORS.INVALID_FILE_TYPE)
    );
    if (rejectedFiles.length && !acceptedFiles.length && allFilesHaveInvalidFileType) {
      addToast.error(t('fileFormatNotSupported', { supportedTypes: formatAcceptTypes(accept) }));
    }

    // error when some of added files has not supported type
    const someFileHasInvalidFileType = rejectedFiles.some(rejectedFile =>
      rejectedFile.errors.some(({ code }) => code === ERRORS.INVALID_FILE_TYPE)
    );
    if (rejectedFiles.length && acceptedFiles.length && someFileHasInvalidFileType) {
      addToast.warning(
        t('fileFormatNotSupportedForSomeFiles', { supportedTypes: formatAcceptTypes(accept) })
      );
    }

    if (acceptedFiles.length > 0) {
      onChange(acceptedFiles);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    noKeyboard: true,
    multiple,
    disabled,
    accept,
    onDrop,
  });

  return (
    <div
      {...getRootProps({
        className: classNames(styles.upload, {
          [styles.active]: isDragActive,
          [styles.error]: error,
          [styles.disabled]: disabled,
        }),
      })}
    >
      <input {...getInputProps()} />
      <Icon name="cloudUpload" size={24} />
      <span className="m-l-12">
        <Trans i18nKey="dropFilesHere">
          Drop file here to upload or <span className={styles.browse}>choose file</span>
        </Trans>
      </span>
    </div>
  );
};

FileUpload.propTypes = propTypes;
FileUpload.defaultProps = defaultProps;

export default FileUpload;
