import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// styles
import styles from './link.module.scss';

const propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  external: PropTypes.bool,
  disableStyle: PropTypes.bool,
};

const defaultProps = {
  className: '',
  external: true,
  disableStyle: false,
};

const Link = ({ href, children, className, external, disableStyle }) => {
  return (
    <a
      href={href}
      onClick={event => event.stopPropagation()}
      rel="noopener noreferrer"
      className={classNames({ [styles.link]: !disableStyle, [className]: className })}
      target={classNames({ _blank: external, _self: !external })}
    >
      {children}
    </a>
  );
};

Link.propTypes = propTypes;
Link.defaultProps = defaultProps;

export default Link;
