/* eslint-disable import/order */
import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// hooks
import useAuth from 'hooks/useAuth';
// helpers
import { prettyNumber } from 'helpers/Number';
// components
import TextSection from 'components/atoms/TextSection';
import LinkButton from 'components/atoms/LinkButton';
import SubmissionStatusFlow from 'components/molecules/SubmissionStatusFlow';
import Section from './Section';
// constants
import { POSITION_TAB } from 'constants/pageTabs';
import {
  STAFF_ROLE,
  SUPPLIER_ROLE,
  MANAGER_ROLE,
  RATES_SUPPLIER_RATE,
  RATES_SELL_PRICE,
} from 'constants';
// styles
import styles from './submissionMainInfo.module.scss';

const propTypes = {
  proposal: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
};

const ProposalMainInfo = ({ proposal, position }) => {
  const { role } = useAuth();
  const { t } = useTranslation();
  const history = useHistory();

  const sellRate = proposal.getRate(RATES_SELL_PRICE);
  const partnerRate = proposal.getRate(RATES_SUPPLIER_RATE);

  const navigateToPosition = () => {
    const positionLinkParams = role === STAFF_ROLE ? `?tab=${POSITION_TAB.RATES}` : '';
    history.push(`/position/${position.id}${positionLinkParams}`);
  };

  const formatRate = rate => {
    return `${rate.currencyLabel}${prettyNumber(rate.value)}`;
  };

  return (
    <React.Fragment>
      <div className={styles.banner} />
      <Section className={styles.scoreSection}>
        <div className={styles.name}>{proposal.name}</div>
        <div className="m-t-15">
          <LinkButton className={styles.positionLink} onClick={navigateToPosition}>
            <div className={styles.rfq}>{position.rfqLabel}</div>
            {position.title}
          </LinkButton>
        </div>
        <div className="m-t-20">
          <SubmissionStatusFlow submission={proposal} />
        </div>
      </Section>

      <Section>
        <TextSection title={t('project')} icon="project">
          {position.client.name}, {position.project.name}
        </TextSection>
        <div className="m-t-20">
          <TextSection title={t('location')} icon="location">
            {position.getLocation()}
          </TextSection>
        </div>
        {role === STAFF_ROLE && (
          <div className="m-t-20">
            <TextSection title={t('company')} icon="company">
              {proposal.partner.name}
            </TextSection>
          </div>
        )}
        {role === STAFF_ROLE && (
          <React.Fragment>
            <div className="m-t-20">
              <TextSection title={t('partnerRate')} icon="money">
                {formatRate(partnerRate)}
              </TextSection>
            </div>
            <div className="m-t-20">
              <TextSection title={t('mySellPrice')} icon="money">
                {sellRate ? formatRate(sellRate) : <i>{t('notProvided')}</i>}
              </TextSection>
            </div>
          </React.Fragment>
        )}
        {role === SUPPLIER_ROLE && (
          <div className="m-t-20">
            <TextSection title={t('mySellRate')} icon="money">
              {formatRate(partnerRate)}
            </TextSection>
          </div>
        )}
        {role === MANAGER_ROLE && (
          <div className="m-t-20">
            <TextSection title={t('sellRate')} icon="money">
              {formatRate(sellRate)}
            </TextSection>
          </div>
        )}
      </Section>
    </React.Fragment>
  );
};

ProposalMainInfo.propTypes = propTypes;

export default ProposalMainInfo;
