import React, { useState } from 'react';
// libraries
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
// hooks
import useAuth from 'hooks/useAuth';
// components
import HoverDetector from 'components/atoms/HoverDetector';
import Controls from './Controls';
import Content from './Content';
// styles
import styles from './positionCard.module.scss';

const propTypes = {
  position: PropTypes.object.isRequired,
};

const PositionCard = ({ position }) => {
  const [visible, setVisible] = useState(false);
  const { role, supplierId } = useAuth();

  if (!position) {
    return null;
  }

  const color = position.getColor(role, supplierId);
  // TODO: fetch real value in next US
  const isNew = false;

  return (
    <HoverDetector
      onEnter={() => setVisible(true)}
      onLeave={() => setVisible(false)}
      className={styles.container}
    >
      <Link to={`/position/${position.id}`} className={classNames(styles.card, 'cp')}>
        <div className={classNames(styles.content, styles[color])}>
          <Controls position={position} visible={visible} />
          <Content position={position} isNew={isNew} />
        </div>
      </Link>
    </HoverDetector>
  );
};

PositionCard.propTypes = propTypes;

export default PositionCard;
