/* eslint-disable import/order */
import React from 'react';
// libraries
import { useTranslation } from 'react-i18next';
import getProperty from 'lodash/get';
import PropTypes from 'prop-types';
// hooks
import useAuth from 'hooks/useAuth';
// constants
import { SUPPLIER_ROLE, NOTE_ONBOARDING_CANCELLED } from 'constants';
import { ONBOARDING_CANCELLED_TYPES } from './constants';
import { STEP, DATE_REJECTED } from '../constants';
// components
import OfferDeclined from './OfferDeclined';
// helpers
import { findCancelledStep } from '../helpers';

const propTypes = {
  submission: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
};

const CancelledProcess = ({ submission, position }) => {
  const { role } = useAuth();
  const { t } = useTranslation();

  const activeStatus = findCancelledStep(submission);
  const notes = getProperty(submission, 'hiringProcess.notes.items', []);
  const reason = notes.find(note => note.noteType === NOTE_ONBOARDING_CANCELLED) || {};

  // Start date rejected by Supplier
  if (activeStatus === STEP.START_DATE_PROPOSE) {
    const subject =
      role === SUPPLIER_ROLE ? t('offerWasDeclined') : t('offerWasDeclinedBySupplier');

    return (
      <OfferDeclined
        type={ONBOARDING_CANCELLED_TYPES.START_DATE_REJECTED_BY_SUPPLIER}
        submission={submission}
        position={position}
        subject={subject}
        reason={reason.note}
      />
    );
  }

  if ([STEP.START_DATE_SUBMIT, STEP.START_DATE_APPROVE].includes(activeStatus)) {
    const basicStatus = submission.getStatus();
    const startDateRejected = getProperty(submission, 'hiringProcess.dates.items', []).find(
      status => status.status === DATE_REJECTED
    );

    // Start date rejected by PM
    if (activeStatus === STEP.START_DATE_APPROVE && startDateRejected) {
      return (
        <OfferDeclined
          type={ONBOARDING_CANCELLED_TYPES.START_DATE_REJECTED_BY_PM}
          submission={submission}
          position={position}
          subject={t('startDateDeclined')}
          reason={reason.note}
        />
      );
    }

    // Candidate withdrawn by supplier
    return (
      <OfferDeclined
        type={ONBOARDING_CANCELLED_TYPES.CANDIDATE_WITHDRAWN}
        submission={submission}
        position={position}
        subject={t('candidateWasWithdrawn')}
        reason={getProperty(basicStatus, 'note.note')}
      />
    );
  }

  return <OfferDeclined submission={submission} position={position} />;
};

CancelledProcess.propTypes = propTypes;

export default CancelledProcess;
