import React from 'react';
// libraries
import PropTypes from 'prop-types';
// components
import SubmissionDetails from 'components/organisms/SubmissionDetails';
import PositionDetails from 'components/organisms/PositionDetails';
import Onboarding from 'components/organisms/Onboarding';
import ErrorPage from 'components/pages/ErrorPage';
// constants
import { SUBMISSION_TAB } from 'constants/pageTabs';

const propTypes = {
  tab: PropTypes.string.isRequired,
  submission: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
};

const SubmissionContent = ({ tab, submission, position }) => {
  if (tab === SUBMISSION_TAB.POSITION) {
    return <PositionDetails position={position} hidePublishBanner />;
  }
  if (tab === SUBMISSION_TAB.DETAIL) {
    return <SubmissionDetails submission={submission} position={position} />;
  }
  if (tab === SUBMISSION_TAB.ONBOARDING) {
    return <Onboarding submission={submission} position={position} />;
  }

  return <ErrorPage code={404} />;
};

SubmissionContent.propTypes = propTypes;

export default SubmissionContent;
