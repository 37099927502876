import { useSubscription } from '@apollo/client';
import { useReducer, useCallback, useEffect } from 'react';
import { gql } from '@services/gql';
import client from '@services/client';
import fetchRecord from './helpers/fetchRecord';
import fetchRecords from './helpers/fetchRecords';
import { ImportRecord, ImportRecordStatus } from './types';
import IMPORT_STATUS_MAP from './importStatusMap';

interface State {
  count: number;
  items: ImportRecord[];
  loading: boolean;
  loadingMore: boolean;
  error: boolean;
  endCursor: Nullable<string>;
}

interface ActionFetch {
  type: 'FETCH';
}

interface ActionFetchMore {
  type: 'FETCH_MORE';
}

interface ActionError {
  type: 'ERROR';
}

interface ActionAdd {
  type: 'ADD';
  payload: {
    item: ImportRecord;
  };
}

interface ActionSet {
  type: 'SET';
  payload: {
    count: number;
    items: ImportRecord[];
    endCursor: Nullable<string>;
  };
}

interface ActionAddMore {
  type: 'ADD_MORE';
  payload: {
    items: ImportRecord[];
    endCursor: Nullable<string>;
  };
}

interface ActionUpdate {
  type: 'UPDATE';
  payload: {
    id: string;
    status: ImportRecordStatus;
  };
}

type ImportsAction =
  | ActionFetch
  | ActionFetchMore
  | ActionError
  | ActionAdd
  | ActionAddMore
  | ActionSet
  | ActionUpdate;

const reducer = (state: State, action: ImportsAction): State => {
  switch (action.type) {
    case 'FETCH':
      return {
        ...state,
        loading: true,
        error: false,
      };
    case 'FETCH_MORE':
      return {
        ...state,
        loadingMore: true,
        error: false,
      };
    case 'ERROR':
      return {
        ...state,
        loading: false,
        loadingMore: false,
        error: true,
      };
    case 'ADD':
      return {
        ...state,
        loading: false,
        error: false,
        items: [action.payload.item, ...state.items],
      };
    case 'ADD_MORE':
      return {
        ...state,
        loading: false,
        loadingMore: false,
        error: false,
        items: [...state.items, ...action.payload.items],
        endCursor: action.payload.endCursor,
      };
    case 'SET':
      return {
        ...state,
        loading: false,
        error: false,
        count: action.payload.count,
        items: action.payload.items,
        endCursor: action.payload.endCursor,
      };
    case 'UPDATE':
      return {
        ...state,
        items: state.items.map((item) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              status: action.payload.status,
            };
          }

          return item;
        }),
      };
    default:
      return state;
  }
};

const DOCUMENT_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription onStatusUpdate {
    statusUpdated {
      id
      status
      parsedData
    }
  }
`);

const IMPORT_RECORD_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription onNewRecord {
    newRecord {
      id
      createdAt
      filename
      downloadLink
      status
      parsedData
    }
  }
`);

const useData = () => {
  const [state, dispatch] = useReducer(reducer, {
    count: 0,
    items: [],
    loading: true,
    loadingMore: false,
    error: false,
    endCursor: null,
  });

  // subscribe to import status update
  useSubscription(DOCUMENT_SUBSCRIPTION, {
    client,
    onData: ({ data: result }) => {
      if (result?.data?.statusUpdated.id) {
        const { id, status } = result.data.statusUpdated;

        dispatch({
          type: 'UPDATE',
          payload: { id, status: status === 'PARSED' ? 'parsed' : 'failed' },
        });
      }
    },
  });

  // subscribe to new position import record
  useSubscription(IMPORT_RECORD_SUBSCRIPTION, {
    client,
    onData: ({ data: result }) => {
      if (result?.data?.newRecord.id) {
        dispatch({
          type: 'ADD',
          payload: {
            item: {
              ...result.data.newRecord,
              status: IMPORT_STATUS_MAP[result.data.newRecord.status],
            },
          },
        });
      }
    },
  });

  const fetchImportRecords = async (fullText?: string) => {
    try {
      dispatch({ type: 'FETCH' });
      const response = await fetchRecords({ fullText });
      dispatch({
        type: 'SET',
        payload: {
          count: response.count,
          items: response.items,
          endCursor: response.pageInfo.endCursor,
        },
      });
    } catch (error) {
      dispatch({ type: 'ERROR' });
    }
  };

  // fetch list of records on load
  useEffect(() => {
    fetchImportRecords();
  }, []);

  const searchByText = useCallback((text: string) => {
    fetchImportRecords(text);
  }, []);

  const fetchImportRecord = useCallback((id: string) => {
    const fetchImportFn = async () => {
      try {
        dispatch({ type: 'FETCH' });
        const importRecord = await fetchRecord(id);

        if (importRecord) {
          dispatch({ type: 'ADD', payload: { item: importRecord } });
        }
      } catch (error) {
        dispatch({ type: 'ERROR' });
      }
    };

    fetchImportFn();
  }, []);

  const fetchMore = useCallback(() => {
    if (state.loading) {
      return;
    }

    const fetchImportsFn = async () => {
      try {
        dispatch({ type: 'FETCH_MORE' });
        const response = await fetchRecords({ after: state.endCursor });
        dispatch({
          type: 'ADD_MORE',
          payload: { items: response.items, endCursor: response.pageInfo.endCursor },
        });
      } catch (error) {
        dispatch({ type: 'ERROR' });
      }
    };

    fetchImportsFn();
  }, [state.endCursor]);

  return {
    count: state.count,
    items: state.items,
    loading: state.loading,
    loadingMore: state.loadingMore,
    error: state.error,
    hasMore: state.count > state.items.length,
    searchByText,
    fetchRecord: fetchImportRecord,
    fetchMore,
  };
};

export default useData;
