import { GET_ATTACHMENTS, GET_RATES } from 'queries/HistoryQueries';
import { FILES_HISTORY, RATES_HISTORY } from './constants';

const getFileHistoryFilters = (type, itemId) => {
  return {
    filter: {
      AND: [
        {
          submission: {
            id: {
              equals: itemId,
            },
          },
        },
        {
          type: {
            equals: type,
          },
        },
      ],
    },
  };
};

const getRateHistoryFilters = (type, itemId) => {
  return {
    filter: {
      AND: [
        {
          revision: {
            id: {
              is_empty: false,
            },
          },
        },
        {
          rates: {
            every: {
              AND: [{ rateType: { equals: type } }, { submission: { id: { equals: itemId } } }],
            },
          },
        },
      ],
    },
  };
};

export const getHistoryFilterByType = (type, itemId) => {
  if (FILES_HISTORY.includes(type)) {
    return getFileHistoryFilters(type, itemId);
  }
  if (RATES_HISTORY.includes(type)) {
    return getRateHistoryFilters(type, itemId);
  }
  return null;
};

export const getHistoryQueryByType = type => {
  if (FILES_HISTORY.includes(type)) {
    return GET_ATTACHMENTS;
  }
  if (RATES_HISTORY.includes(type)) {
    return GET_RATES;
  }
  return null;
};
