import React, { useContext, useMemo } from 'react';
import MuiTableHead from '@mui/material/TableHead';
import TableContext, { TableContextProps } from './TableContext';

interface TableHeadProps {
  children: React.ReactNode;
  className?: string;
}

const TableHead = ({ children, className }: TableHeadProps) => {
  const parentContext = useContext(TableContext);
  const context: TableContextProps = useMemo(
    () => ({
      ...parentContext,
      variant: 'head',
    }),
    [parentContext]
  );

  return (
    <TableContext.Provider value={context}>
      <MuiTableHead
        component="div"
        classes={{
          root: className,
        }}
      >
        {children}
      </MuiTableHead>
    </TableContext.Provider>
  );
};

export default TableHead;
