import { gql } from '@apollo/client';
import moment from 'moment';
// services
import client from 'services/Client';
import logger from 'services/Logger';
// helpers
import { triggerCandidateBulkMatching } from 'helpers/BulkMatching';
import { fetchCvReadabilityLevel } from 'helpers/Report';
// constants
import { FILE_CV } from 'constants';

const COMMIT_NEW_CV = gql`
  mutation commitNewCV(
    $shCandidateId: String!
    $shPersonId: String
    $filename: String!
    $boxId: String!
    $mriLevelId: ID!
    $candidateId: ID!
    $userId: ID!
    $currentDate: DateTime!
    $fileType: String!
  ) {
    candidateUpdate(
      filter: { id: $candidateId }
      data: {
        lastUpdate: $currentDate
        reports: {
          create: {
            cvReadabilityLevel: { connect: { id: $mriLevelId } }
            shCandidateId: $shCandidateId
            shPersonId: $shPersonId
            isCandidateCv: true
            cv: {
              create: {
                boxId: $boxId
                name: $filename
                type: $fileType
                author: { connect: { id: $userId } }
              }
            }
          }
        }
      }
    ) {
      id
      lastUpdate
      reports(sort: { createdAt: DESC }, first: 1, filter: { isCandidateCv: { equals: true } }) {
        items {
          id
          cvReadabilityLevel {
            id
            level
          }
          shCandidateId
          cv {
            id
            name
            boxId
            createdAt
          }
        }
      }
    }
  }
`;

/**
 * @param {string} candidateId
 * @param {string} shCandidateId
 * @param {string} [shPersonId]
 * @param {number} mriScore
 * @param {string} boxId
 * @param {string} filename
 * @param {string} userId
 * @returns {Promise}
 */
const commitNewCv = async ({
  candidateId,
  shCandidateId,
  shPersonId,
  mriScore,
  boxId,
  filename,
  userId,
}) => {
  try {
    const mriLevelId = await fetchCvReadabilityLevel(mriScore);

    await client.mutate({
      mutation: COMMIT_NEW_CV,
      variables: {
        shCandidateId,
        shPersonId,
        mriLevelId,
        candidateId,
        boxId,
        filename,
        fileType: FILE_CV,
        userId,
        currentDate: moment().toISOString(),
      },
    });

    triggerCandidateBulkMatching(candidateId);
  } catch (error) {
    logger.exception(error, { candidateId, shPersonId, shCandidateId, userId });
    throw error;
  }
};

export default commitNewCv;
