import { fromPromise } from '@apollo/client';
// services
import { storeToken, refreshToken, redirectToLogin } from 'services/Authentication';

export const refreshTokenAndRetryRequest = (operation, forward) => {
  return fromPromise(
    refreshToken()
      .then(newToken => {
        storeToken(newToken);

        return newToken;
      })
      .catch(() => {
        redirectToLogin();
      })
  ).flatMap(newToken => {
    operation.setContext({
      headers: {
        authorization: `Bearer ${newToken}`,
      },
    });

    return forward(operation);
  });
};
