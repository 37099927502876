import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// constants
import { NOT_PROVIDED_CLEARANCE } from 'constants/filters';
// components
import Button from 'components/atoms/Button';
import LinkButton from 'components/atoms/LinkButton';
import Collapse from 'components/atoms/Collapse';
import CheckboxList from 'components/molecules/CheckboxList';
import Popover from 'components/molecules/Popover';
import SideFilter from 'components/molecules/SideFilter';
import FilterOptionsLoader from './FilterOptionsLoader';
// styles
import styles from './clearancesFilter.module.scss';

const propTypes = {
  loading: PropTypes.bool.isRequired,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  clearances: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      title: PropTypes.string,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

const ClearancesFilter = ({ loading, selected, clearances, onChange }) => {
  const { t } = useTranslation();

  const renderClearanceHeader = () => {
    return (
      <div className={styles.clearanceFilterHeader}>
        <span className="m-r-5">{t('clearance')}</span>
        {!loading && (
          <Popover
            button={onClick => <Button type="info" iconStyle="icon0" size={14} onClick={onClick} />}
          >
            <div className="p-15">
              {clearances
                .filter(clearance => {
                  return (
                    clearance.label !== clearance.title &&
                    clearance.value !== NOT_PROVIDED_CLEARANCE
                  );
                })
                .map((clearance, index) => {
                  return (
                    <div className={classNames({ 'm-t-5': index })} key={clearance.value}>
                      <span className="bolder">{clearance.label}</span> - {clearance.title}
                    </div>
                  );
                })}
            </div>
          </Popover>
        )}
      </div>
    );
  };

  const renderCollapseButton = (collapsed, onToggle) => {
    return <LinkButton onClick={onToggle}>{collapsed ? t('seeMore') : t('seeLess')}</LinkButton>;
  };

  return (
    <SideFilter.Group title={renderClearanceHeader()}>
      {loading ? (
        <FilterOptionsLoader />
      ) : (
        <Collapse collapsed collapsedSize={145} button={renderCollapseButton}>
          <CheckboxList options={clearances} selected={selected} onChange={onChange} />
        </Collapse>
      )}
    </SideFilter.Group>
  );
};

ClearancesFilter.propTypes = propTypes;

export default ClearancesFilter;
