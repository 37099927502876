import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import getProperty from 'lodash/get';
// helpers
import { removeDots } from 'helpers/StringParser';
// components
import Switch from 'components/atoms/Switch';

const EventProp = PropTypes.shape({
  exists: PropTypes.bool.isRequired,
  enabled: PropTypes.bool.isRequired,
});

const propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  eventItem: PropTypes.shape({
    web: EventProp.isRequired,
    email: EventProp.isRequired,
    event: PropTypes.string.isRequired,
  }),
  isHeader: PropTypes.bool,
  types: PropTypes.array,
};

const defaultProps = {
  className: '',
  onChange: () => {},
  eventItem: {},
  isHeader: false,
  types: [],
};

const Event = props => {
  const { t } = useTranslation();
  const { className, onChange, eventItem, isHeader, types } = props;
  const { event } = eventItem;

  const eventParsed = removeDots(event);
  const title = t(`notificationEvent${eventParsed}Title`);
  const description = t(`notificationEvent${eventParsed}Description`);

  return (
    <div className={`notificationSettings_event ${className}`}>
      <div className="notificationSettings_eventInfo">
        <div className="notificationSettings_eventInfo-title">{title}</div>
        <div className="notificationSettings_eventInfo-description">{description}</div>
      </div>
      <div className="notificationSettings_event-switch">
        {types.map(({ id, label }) => {
          const switchValue = getProperty(eventItem[id], 'enabled');
          const disabled = !getProperty(eventItem[id], 'exists');

          return (
            <div key={id}>
              {isHeader && <div className="notificationSettings_eventInfo-header">{label}</div>}
              {disabled ? (
                <div className="notificationSettings_event-switchElement">
                  <Switch id={`${event}_${id}`} disabled={disabled} checked={switchValue} />
                </div>
              ) : (
                <div className="notificationSettings_event-switchElement">
                  <Switch
                    id={`${event}_${id}`}
                    disabled={disabled}
                    checked={switchValue}
                    onChange={value => onChange(value, id, eventItem, isHeader)}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

Event.propTypes = propTypes;
Event.defaultProps = defaultProps;

export default Event;
