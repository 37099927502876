import React from 'react';
// libraries
import PropTypes from 'prop-types';
// hooks
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/useAuth';
import getProperty from 'lodash/get';
// components
import Chip from 'components/atoms/NewChip';
import StatusFlow from 'components/molecules/StatusFlow';
import Popover from 'components/molecules/Popover';
import ClickableElement from 'components/atoms/ClickableElement';
import Icon from 'components/atoms/Icon';
// helpers
import { preventDefault } from 'helpers/HtmlDOM';
import { formatHistory } from './helpers';
// styles
import styles from './positionStatusFlow.module.scss';

const propTypes = {
  position: PropTypes.object.isRequired,
  isPopover: PropTypes.bool,
};

const defaultProps = {
  isPopover: true,
};

const PositionStatusFlow = ({ position, isPopover }) => {
  const { role, supplierId } = useAuth();
  const { t } = useTranslation();

  const statusHistory = position.getMainStatusHistory(role, supplierId);
  const statuses = formatHistory(statusHistory);
  const status = position.getMainStatus(role, supplierId);
  const color = position.getColor(role, supplierId);

  if (isPopover) {
    return (
      <Popover
        placement="bottom-start"
        button={onClick => (
          <ClickableElement onClick={preventDefault(onClick)}>
            <Chip color={color} size="sm">
              <div className={styles.chipContent}>
                <span className="m-r-5">{status.label}</span>
                <div className={styles.icon}>
                  <Icon name="infoOutline" size="16" />
                </div>
              </div>
            </Chip>
          </ClickableElement>
        )}
      >
        <div className={styles.statusFlowContent}>
          <div className={styles.statusFlowTitle}>
            <b>{t('statusProgress')}</b>
          </div>
          <StatusFlow color={color} statuses={statuses} note={getProperty(status, 'note', '')} />
        </div>
      </Popover>
    );
  }

  return <StatusFlow color={color} statuses={statuses} note={getProperty(status, 'note', '')} />;
};

PositionStatusFlow.propTypes = propTypes;
PositionStatusFlow.defaultProps = defaultProps;

export default PositionStatusFlow;
