import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// styles
import styles from './ratesSimulation.module.scss';

const propTypes = {
  hours: PropTypes.number.isRequired,
};

const LevelOfEffort = ({ hours }) => {
  const { t } = useTranslation();

  return (
    <span className={styles.tooltip}>
      <b>
        {t('levelOfEffortLabel')}
        <br />
      </b>
      <span>{hours} hrs</span>
    </span>
  );
};

LevelOfEffort.propTypes = propTypes;

export default LevelOfEffort;
