import React from 'react';
// libraries
import PropTypes from 'prop-types';
import getProperty from 'lodash/get';
// components
import Revision from './Revision';
// styles
import './revisions.scss';

const propTypes = {
  submission: PropTypes.object.isRequired,
};

const RevisionsList = ({ submission }) => {
  return (
    <div className="revisionsList">
      {submission.revisions.map(
        ({ id, createdAt, createdBy, revisedBy, revisedAt, statuses, types }, index) => {
          const status = getProperty(statuses, 'items', []).find(item => !item.end);

          return (
            <Revision
              key={id}
              revisionId={id}
              index={submission.revisions.length - index}
              status={{
                status: getProperty(status, 'status'),
                createdByName: getProperty(status, 'createdBy.alakaUser.person.fullName'),
                createdById: getProperty(status, 'createdBy.id'),
              }}
              revisionTypes={getProperty(types, 'items', [])}
              requestedBy={getProperty(createdBy, 'alakaUser.person.fullName', '')}
              requestedAt={createdAt}
              revisedBy={getProperty(revisedBy, 'person.fullName', '')}
              revisedAt={revisedAt}
              candidateId={submission.id}
              candidateName={submission.candidate.name}
            />
          );
        }
      )}
    </div>
  );
};

RevisionsList.propTypes = propTypes;

export default RevisionsList;
