import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// styles
import styles from './fieldSection.module.scss';

const propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.any,
  optional: PropTypes.bool,
  error: PropTypes.bool,
  errorMessages: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  children: PropTypes.any,
  classes: PropTypes.shape({
    root: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.string,
    errorMessages: PropTypes.string,
  }),
};

const defaultProps = {
  description: null,
  optional: false,
  error: false,
  errorMessages: [],
  children: null,
  classes: {
    root: '',
    title: '',
    content: '',
    errorMessages: '',
  },
};

const FieldSection = ({
  title,
  description,
  optional,
  error,
  errorMessages,
  children,
  classes,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className={classNames(styles.title, classes.root, { [styles.error]: error })}>
        <span className={classes.title}>{title}</span>
        {optional && <span className={styles.optional}>{t('optional')}</span>}
      </div>
      {description && <div className={styles.description}>{description}</div>}
      {children && <div className={classNames(styles.content, classes.content)}>{children}</div>}
      {errorMessages.length > 0 && (
        <div className={classNames('m-t-10', classes.errorMessages)}>
          {[].concat(errorMessages).map((message, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} className={classNames(styles.errorMessage, { 'm-t-5': index })}>
              {`* ${message}`}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

FieldSection.propTypes = propTypes;
FieldSection.defaultProps = defaultProps;

export default FieldSection;
