import React from 'react';
import PropTypes from 'prop-types';
import getProperty from 'lodash/get';
// Hooks
import useAuth from 'hooks/useAuth';
// Helpers
import { getCurrencyText } from 'helpers/NameMapping';
import { getYear } from 'helpers/RelativeTimeFormatter';
// Constants
import { POSITION_STATUS } from 'constants/positionStatus';
import { RATES_TARGET_RATE } from 'constants';
// Components
import Banner, { BANNER_TYPE } from 'components/molecules/Banner';
import PublishedActions from './Published/Actions';
import PublishedContent from './Published/Content';
import UnpublishedContent from './Unpublished/Content';
import UnpublishedActions from './Unpublished/Actions';
// Style
import styles from './publishBanner.module.scss';

const propTypes = {
  position: PropTypes.object.isRequired,
};

const PublishBanner = ({ position }) => {
  const { role, supplierId } = useAuth();
  const { timezone } = position.project;
  const { audience, isPublishedToAll } = position;
  const isPublished = position.isPublished(role, supplierId);
  const status = position.getStatus(role, supplierId);
  const unPublishStatus = position.findStatus(POSITION_STATUS.UNPUBLISHED);
  const currency = getCurrencyText(position.project.currency);
  const targetRates = position.getRates(RATES_TARGET_RATE).map(rate => ({
    value: rate.value,
    year: parseInt(getYear(rate.startDate)),
  }));
  const firstYearRate = getProperty(targetRates, '0.value');

  return (
    <Banner type={BANNER_TYPE.ANNOUNCEMENT} className={styles.banner}>
      {isPublished && (
        <div className={styles.publishBanner}>
          <PublishedContent
            supplierDueDate={position.supplierDueDate}
            publishAudience={audience}
            publishedToAll={isPublishedToAll}
            firstYearRate={firstYearRate}
            currency={currency}
            publishedOn={status.start}
            timezone={timezone}
          />
          <PublishedActions position={position} targetRates={targetRates} />
        </div>
      )}
      {!isPublished && (
        <div className={styles.publishBanner}>
          <UnpublishedContent
            supplierDueDate={position.supplierDueDate}
            firstYearRate={firstYearRate}
            currency={currency}
            timezone={timezone}
            unPublishedOn={unPublishStatus.start}
            publishAudience={audience}
            publishedToAll={isPublishedToAll}
          />
          <UnpublishedActions
            position={position}
            role={role}
            supplierId={supplierId}
            targetRates={targetRates}
          />
        </div>
      )}
    </Banner>
  );
};

PublishBanner.propTypes = propTypes;

export default PublishBanner;
