import React from 'react';
import PropTypes from 'prop-types';
// components
import Row from './Row';
import Head from './Head';
import Title from './Title';
import Text from './Text';
import Input from './Input';
// styles
import './rateTable.scss';

const propTypes = {
  bordered: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
};

const defaultProps = {
  bordered: true,
  className: '',
};

const Table = ({ bordered, className, children }) => {
  const classes = ['rateTable'];

  if (bordered) {
    classes.push('rateTable-bordered');
  }
  if (className) {
    classes.push(...className.split(' '));
  }

  return <div className={classes.join(' ')}>{children}</div>;
};

Table.propTypes = propTypes;
Table.defaultProps = defaultProps;
Table.Row = Row;
Table.Head = Head;
Table.Title = Title;
Table.Text = Text;
Table.Input = Input;

export default Table;
