import React from 'react';
// Libraries
import PropTypes from 'prop-types';
import times from 'lodash/times';
// components
import ContentLoader from 'components/atoms/ContentLoader';
// constants
import { CANDIDATE_TYPE } from 'constants';

const propTypes = {
  type: PropTypes.string,
  amount: PropTypes.number,
};

const defaultProps = {
  type: CANDIDATE_TYPE,
  amount: 4,
};

const ContentLoaderPage = props => {
  const { type, amount } = props;
  return (
    <div className="list infinite-scroll-component">
      {times(amount, index => {
        return (
          <div key={`${type}_${index}`}>
            <ContentLoader type={type} />
          </div>
        );
      })}
    </div>
  );
};

ContentLoaderPage.propTypes = propTypes;
ContentLoaderPage.defaultProps = defaultProps;

export default ContentLoaderPage;
