// services
import logger from 'services/Logger';
import notification from 'services/Notification';
import { sendEmail } from 'services/Mailer';
// helpers
import { changeSubmissionStatus } from 'helpers/Submission';
import { getSubmissionLink } from 'helpers/Link';
// constants
import { SUBMISSION_STATUS_TYPE } from 'constants/submissionStatus';
import { ONBOARDING_TAB, ONBOARDING_STATUS_DATE } from 'constants/onboarding';
import { STEP } from '../constants';

/**
 * Submit Start Date to Project Manager by Match Manager
 * Step: Start Date - Submit for Confirmation
 * Role: Match Manager
 *
 * Actions:
 * 1. Create next hiring status
 * 2. Put end date to previous hiring status
 *
 * @param {object} data
 * @param {object} data.submission
 * @param {string} data.currentOnboardingStatusId
 * @param {object} data.email Html + email options (to, from, subject..)
 * @param {string} data.userId Logged in user id
 */
const submitStartDateToPM = async ({ submission, currentOnboardingStatusId, email, userId }) => {
  try {
    await changeSubmissionStatus({
      submissionId: submission.id,
      status: ONBOARDING_STATUS_DATE.APPROVE,
      type: SUBMISSION_STATUS_TYPE.ONBOARDING_START_DATE,
      oldStatusId: currentOnboardingStatusId,
      isProposal: submission.isProposal(),
    });
    sendEmail(email);

    const link = getSubmissionLink(submission.id, submission.isProposal(), {
      queryParams: {
        step: STEP.START_DATE_APPROVE,
        tab: ONBOARDING_TAB.START_DATE,
      },
    });

    notification.onboardingStartDateAcceptedByMM({
      submission: submission.id,
      link,
      createdBy: userId,
      isProposal: submission.isProposal(),
    });
  } catch (error) {
    logger.exception(error, { submissionId: submission.id });
    throw error;
  }
};

export default submitStartDateToPM;
