import React from 'react';
import PropTypes from 'prop-types';
// types
import {
  CANDIDATE_TYPE,
  POSITION,
  SINGLE_POSITION_TYPE,
  LAZY_PAGE_TYPE,
  REPORT_TYPE,
  SUBMISSION_TYPE,
} from 'constants';
// Components
import CandidateLoader from './CandidateLoader';
import ListLoader from './ListLoader';
import PositionLoader from './PositionLoader';
import SinglePositionLoader from './SinglePositionLoader';
import LazyPageLoader from './LazyPageLoader';
import ReportLoader from './ReportLoader';
import SubmissionLoader from './SubmissionLoader';

const ContentLoader = ({ type }) => {
  if (type === CANDIDATE_TYPE) {
    return <CandidateLoader />;
  }
  if (type === POSITION) {
    return <PositionLoader />;
  }
  if (type === SINGLE_POSITION_TYPE) {
    return <SinglePositionLoader />;
  }
  if (type === LAZY_PAGE_TYPE) {
    return <LazyPageLoader />;
  }
  if (type === REPORT_TYPE) {
    return <ReportLoader />;
  }
  if (type === SUBMISSION_TYPE) {
    return <SubmissionLoader />;
  }
  return <ListLoader />;
};

ContentLoader.propTypes = {
  type: PropTypes.string,
};

ContentLoader.defaultProps = {
  type: null,
};

export default ContentLoader;
