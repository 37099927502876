import React from 'react';
// libraries
import PropTypes from 'prop-types';
// styles
import colors from 'dependencies/materialStyles/Colors';
// components
import Icon from 'components/atoms/Icon';
import Circle from './Circle';

const propTypes = {
  tab: PropTypes.object,
  activeTab: PropTypes.string,
  tabClassName: PropTypes.string,
  setTab: PropTypes.func,
  open: PropTypes.bool,
  done: PropTypes.bool,
  index: PropTypes.number,
};

const defaultProps = {
  tab: {},
  activeTab: null,
  tabClassName: '',
  setTab: () => {},
  open: true,
  done: false,
  index: 0,
};

const CircleTab = props => {
  const { tab, setTab, index, activeTab, done, open, tabClassName } = props;
  const color = activeTab === tab.id ? 'white' : colors.gray;
  return (
    <div
      role="presentation"
      onClick={() => setTab(tab.id)}
      // className={classNames.join(' ')}
      className={`circleTab ${
        activeTab === tab.id ? `${tabClassName ? '' : 'highlight'}` : 'notHighlight'
      } ${tabClassName}`}
    >
      <div className="circleTab_icon">
        {done || tab.icon ? (
          <Icon name={done ? 'done' : tab.icon} color={color} />
        ) : (
          <span className={`text-${activeTab === tab.id ? 'white' : 'gray'} f-s-21`}>
            {index + 1}
          </span>
        )}
      </div>
      {!open && (
        <div className="circleTab_label">
          <div>{tab.label}</div>
          {tab.notification && <Circle tooltip={tab.notification} className="circleTab_priority" />}
        </div>
      )}
    </div>
  );
};

CircleTab.propTypes = propTypes;
CircleTab.defaultProps = defaultProps;

export default CircleTab;
