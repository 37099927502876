import React from 'react';
// libraries
import PropTypes from 'prop-types';
// styles
import styles from './modal.module.scss';

const propTypes = {
  children: PropTypes.any.isRequired,
};

const ModalBody = ({ children }) => <div className={styles.body}>{children}</div>;

ModalBody.propTypes = propTypes;

export default ModalBody;
