import { split, from } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
// links
import authLink from './auth';
import errorLink from './error';
import httpLink from './http';
import wsLink from './ws';
import retryLink from './retry';

const getLink = () => {
  const links = [authLink(), retryLink(), errorLink()];
  const httpLinkInstance = httpLink();

  // split link based on operation type
  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink(),
    httpLinkInstance
  );

  links.push(link);

  return from(links);
};

export default getLink;
