import React from 'react';
// libraries
import PropTypes from 'prop-types';
// styles
import styles from './sideFilter.module.scss';

const propTypes = {
  title: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired,
};

const Group = ({ title, children }) => {
  return (
    <div className={styles.group}>
      <div className={styles.groupTitle}>{title}</div>
      {children}
    </div>
  );
};

Group.propTypes = propTypes;

export default Group;
