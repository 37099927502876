/* eslint-disable no-unused-expressions */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
// styles
import './input.scss';
import colors from 'dependencies/materialStyles/Colors';
// components
import Icon from 'components/atoms/Icon';
import Popper from 'components/atoms/Popper';

const propTypes = {
  icon: PropTypes.any,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  onBlur: PropTypes.func,
  hintPlacement: PropTypes.string,
  focusedHint: PropTypes.any,
  validateInput: PropTypes.func,
  inputType: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  highlight: PropTypes.bool,
  keepHintVisible: PropTypes.bool,
  id: PropTypes.string,
  width: PropTypes.any,
  viewPassword: PropTypes.bool,
  disabled: PropTypes.bool,
};

const defaultProps = {
  id: '',
  viewPassword: false,
  hintPlacement: 'top-end',
  className: '',
  icon: null,
  keepHintVisible: false,
  inputType: 'text',
  highlight: false,
  focusedHint: null,
  onChange: () => {},
  onClear: null,
  onBlur: null,
  placeholder: null,
  value: '',
  validateInput: null,
  width: null,
  disabled: false,
};

const Input = props => {
  const {
    className,
    onChange,
    onClear,
    icon,
    inputType,
    placeholder,
    viewPassword,
    value,
    width,
    id,
    highlight,
    validateInput,
    focusedHint,
    hintPlacement,
    keepHintVisible,
    disabled,
    onBlur,
  } = props;
  const displayClearButton = onClear && value && value.length > 0 && !disabled;

  const highlightClass = highlight && !value ? 'dashboard-input-highlight' : '';
  const [showPassword, setShowPassword] = useState(false);
  const [hintOpen, setHintOpen] = useState(false);
  const inputElement = useRef(null);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleBlur = () => {
    if (focusedHint && !keepHintVisible) {
      setHintOpen(false);
    }
    !!validateInput && validateInput();
    !!onBlur && onBlur();
  };

  const handleFocus = () => {
    if (focusedHint) {
      setHintOpen(true);
    }
  };

  return (
    <React.Fragment>
      <div
        className={`${className} ${highlightClass} test_${id} ${
          disabled ? 'dashboard-input-disabled' : ''
        } dashboard-input-div dashboard-input-group`}
      >
        <span
          className={`${
            icon ? '' : 'p-l-0'
          } blackBorder dashboard-icon-div dashboard-input-style dashboard-input-group-addon`}
        >
          {icon}
        </span>
        <input
          id={id}
          className={`${
            displayClearButton ? 'dashboard-input-border-white' : ''
          } dashboard-input-style dashboard-input`}
          type={showPassword ? 'text' : inputType}
          onFocus={handleFocus}
          placeholder={placeholder}
          value={value}
          name={id}
          onChange={e => onChange(e.target.value, id)}
          style={width ? { width: `${width}px` } : null}
          onBlur={() => handleBlur()}
          disabled={disabled}
          ref={inputElement}
        />

        {displayClearButton && (
          <span
            role="presentation"
            className="pointer blackBorder dashboard-icon-div dashboard-input-style dashboard-input-group-addon"
            onClick={onClear}
          >
            <Icon color={colors.gray} name="clear" size={20} />
          </span>
        )}
        {!displayClearButton && <div className="dashboard-input-width" />}
        {inputType === 'password' && viewPassword && (
          <span
            role="presentation"
            className="pointer blackBorder dashboard-icon-div dashboard-input-style dashboard-input-group-addon"
            onClick={togglePassword}
          >
            <Icon color={colors.gray} name={showPassword ? 'watch' : 'stopWatch'} size={15} />
          </span>
        )}
      </div>
      {focusedHint && (
        <Popper
          isOpen={hintOpen}
          anchorEl={inputElement.current}
          placement={hintPlacement}
          className="m-b-5"
        >
          <div className="p-10 f-s-13">
            <pre>{focusedHint}</pre>
          </div>
        </Popper>
      )}
    </React.Fragment>
  );
};

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export default Input;
