import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// components
import Icon from 'components/atoms/Icon';
// styles
import colors from 'dependencies/materialStyles/Colors';
import styles from './banner.module.scss';
// constants
import { BANNER_TYPE } from './constants';
// helper
import { getBannerColor, getBannerIcon } from './helpers';

const propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(Object.values(BANNER_TYPE)).isRequired,
  children: PropTypes.any.isRequired,
  customIcon: PropTypes.any,
};

const defaultProps = {
  className: '',
  customIcon: null,
};

const Banner = ({ className, type, children, customIcon }) => {
  const { color, iconColor } = getBannerColor(type);
  const icon = getBannerIcon(type);

  return (
    <div
      className={classNames(styles.banner, styles[color], {
        [className]: className,
      })}
    >
      {customIcon || (
        <div className={styles.icon}>
          <Icon name={icon} size={20} color={colors[iconColor]} />
        </div>
      )}
      <div className={styles.content}>{children}</div>
    </div>
  );
};

Banner.propTypes = propTypes;
Banner.defaultProps = defaultProps;

export default Banner;
