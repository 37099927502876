import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// components
import Switch from 'components/atoms/Switch';
// styles
import styles from './cookieSettings.module.scss';

const propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

const defaultProps = {
  className: '',
  disabled: false,
  onChange: null,
};

const CookieType = ({ className, title, description, checked, disabled, onChange }) => {
  return (
    <div className={className}>
      <div className={styles.groupHeader}>
        <div className={styles.subtitle}>{title}</div>
        <Switch checked={checked} onChange={onChange} disabled={disabled} />
      </div>
      <div className={classNames(styles.description, 'm-t-10')}>{description}</div>
    </div>
  );
};

CookieType.propTypes = propTypes;
CookieType.defaultProps = defaultProps;

export default CookieType;
