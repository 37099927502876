import React from 'react';
// Libraries
import moment from 'moment-business-days';
import PropTypes from 'prop-types';
import { MuiThemeProvider } from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  InlineDateTimePicker,
  InlineDatePicker,
} from 'material-ui-pickers';
import { useTranslation } from 'react-i18next';
// helpers
import { getReadableYearDateTime, getTimeYear } from 'helpers/RelativeTimeFormatter';
// styles
import Icon from 'components/atoms/Icon';
import colors from 'dependencies/materialStyles/Colors';
import datePickerTheme from './DatePickerStyles';
import './datePickerCustom.scss';

const propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]), // moment object or ISO date
  id: PropTypes.string,
  enableTime: PropTypes.bool,
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
  minDateMessage: PropTypes.any,
  maxDateMessage: PropTypes.any,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

const defaultProps = {
  id: '',
  value: null,
  enableTime: false,
  minDate: undefined,
  maxDate: undefined,
  minDateMessage: undefined,
  maxDateMessage: undefined,
  placeholder: '',
  disabled: false,
};

const DatePickerCustom = props => {
  const {
    value,
    id,
    onChange,
    minDate,
    maxDate,
    minDateMessage,
    maxDateMessage,
    enableTime,
    placeholder,
    disabled,
  } = props;
  const { t } = useTranslation();

  const formatDate = date => {
    return enableTime ? getReadableYearDateTime(date) : getTimeYear(date);
  };

  const getPickers = () => {
    const inputProps = {
      disableUnderline: true,
    };

    const commonProps = {
      InputProps: inputProps,
      onChange,
      minDate,
      maxDate,
      minDateMessage: minDateMessage || `* ${t('dateCannotBeBefore')} ${formatDate(minDate)}`,
      maxDateMessage: maxDateMessage || `* ${t('dateCannotBeAfter')} ${formatDate(maxDate)}`,
      invalidDateMessage: `* ${t('invalidDateFormat')}`,
      disabled,
      keyboardIcon: <Icon name="calendar" color={colors.gray} size={20} />,
      adornmentPosition: 'start',
      value,
      keyboard: true,
      id,
    };

    if (enableTime) {
      return (
        <InlineDateTimePicker
          ampm={false}
          mask={[
            /\d/,
            /\d/,
            '/',
            /\d/,
            /\d/,
            '/',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            ' ',
            /\d/,
            /\d/,
            ':',
            /\d/,
            /\d/,
            ` (UTC${moment(value).format('Z')})`,
          ]}
          format="DD/MM/YYYY HH:mm [(UTC]Z[)]"
          placeholder={placeholder || 'DD/MM/YYYY HH:mm'}
          {...commonProps}
        />
      );
    }

    return (
      <InlineDatePicker
        format="DD/MM/YYYY"
        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
        placeholder={placeholder || 'DD/MM/YYYY'}
        {...commonProps}
      />
    );
  };

  return (
    <div className={`datepicker-container test_${id} ${disabled ? 'disabled' : ''}`}>
      <MuiThemeProvider theme={datePickerTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>{getPickers()}</MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </div>
  );
};

DatePickerCustom.propTypes = propTypes;
DatePickerCustom.defaultProps = defaultProps;

export default DatePickerCustom;
