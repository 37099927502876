// libraries
import i18n from 'i18next';
// constants
import {
  ACTION_INACTIVE,
  ACTION_PENDING,
  ACTION_OWN_PENDING,
  ACTION_FINISHED,
  ACTION_OVERDUE,
  ACTION_CANCELLED,
} from 'constants';

export const getContentFromType = type => {
  const arrowTypes = {
    [ACTION_INACTIVE]: {
      title: i18n.t('inactive'),
      icon: null,
    },
    [ACTION_OWN_PENDING]: {
      title: i18n.t('pendingForYourAction'),
      icon: 'error',
    },
    [ACTION_PENDING]: {
      title: i18n.t('pendingForOthersToFinish'),
      icon: 'pending',
    },
    [ACTION_FINISHED]: {
      title: i18n.t('finished'),
      icon: 'check',
    },
    [ACTION_OVERDUE]: {
      title: i18n.t('overdue'),
      icon: 'warning',
    },
    [ACTION_CANCELLED]: {
      title: i18n.t('cancelled'),
      icon: 'warning',
    },
  };
  return arrowTypes[type] || {};
};
