import React, { useContext } from 'react';
import classNames from 'classnames';
import MuiTableCell from '@mui/material/TableCell';
import TableContext from './TableContext';
import styles from './table.module.scss';

interface TableCellProps {
  children: React.ReactNode;
  align?: 'center' | 'left' | 'right';
  className?: string;
}

const TableCell = ({ children, className, align = 'left' }: TableCellProps) => {
  const { variant } = useContext(TableContext);

  return (
    <MuiTableCell
      component="div"
      role={variant === 'head' ? 'columnheader' : 'cell'}
      align={align}
      classes={{
        root: classNames(styles.cell, className),
        head: styles.header,
      }}
    >
      {children}
    </MuiTableCell>
  );
};

export default TableCell;
