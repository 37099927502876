import getProperty from 'lodash/get';
import moment from 'moment';
// services
import axios from 'services/Axios';
import client from 'services/Client';
import notification from 'services/Notification';
import logger from 'services/Logger';
// queries
import { GET_ACTORS_OF_TYPE } from 'queries/PositionQueries';
import {
  CLAIM_OWNERSHIP,
  REMOVE_OWNERSHIP,
  WATCH_POSITION,
  STOP_WATCH_POSITION,
  GET_SUPPLIER_DUE_DATE,
  UPDATE_SUPPLIER_DUE_DATE,
} from 'queries/PositionSettingQueries';
// constants
import { POSITION_STATUS } from 'constants/positionStatus';
import { ACTOR_PRIMARY_OWNER } from 'constants';
// helpers
import { getAbsoluteUrl } from 'helpers/Link';
import { changePositionStatus } from 'helpers/Position';

export const unpublish = async (positionId, statusId, userId) => {
  await changePositionStatus({
    status: POSITION_STATUS.UNPUBLISHED,
    positionId,
    oldStatusId: statusId,
  });

  notification.positionUnpublished({
    position: positionId,
    link: getAbsoluteUrl(`/position/${positionId}`),
    createdBy: userId,
  });
};

const invalidatePreviousOwners = previousOwners => {
  return previousOwners.map(({ id }) => {
    return client.mutate({
      mutation: REMOVE_OWNERSHIP,
      variables: {
        positionRoleId: id,
        end: moment().toISOString(),
      },
    });
  });
};

export const claimOwnership = async (positionId, userId) => {
  const previousOwners = getProperty(
    await client.query({
      query: GET_ACTORS_OF_TYPE,
      variables: {
        positionId,
        role: ACTOR_PRIMARY_OWNER,
      },
    }),
    'data.position.actors.items',
    []
  );

  await Promise.all([
    invalidatePreviousOwners(previousOwners),
    client.mutate({
      mutation: CLAIM_OWNERSHIP,
      variables: {
        positionId,
        userId,
        start: moment().toISOString(),
      },
    }),
  ]);

  notification.positionOwnershipClaimed({
    position: positionId,
    link: getAbsoluteUrl(`/position/${positionId}`),
    createdBy: userId,
  });
};

export const removeOwnership = async (positionRoleId, positionId, userId) => {
  await client.mutate({
    mutation: REMOVE_OWNERSHIP,
    variables: {
      positionRoleId,
      end: moment().toISOString(),
    },
  });

  notification.positionOwnershipRemoved({
    position: positionId,
    link: getAbsoluteUrl(`/position/${positionId}`),
    createdBy: userId,
  });
};

export const watchPosition = async (positionId, userId) => {
  await client.mutate({
    mutation: WATCH_POSITION,
    variables: {
      positionId,
      userId,
      start: moment().toISOString(),
    },
  });
};

export const stopWatchPosition = async positionRoleId => {
  await client.mutate({
    mutation: STOP_WATCH_POSITION,
    variables: {
      positionRoleId,
      end: moment().toISOString(),
    },
  });
};

/**
 * @typedef {Object} DueDates
 * @property {string} position
 * @property {string} supplier
 */

/**
 * Fetch position and supplier due date
 * @param {string} id Position id
 * @returns {Promise<DueDates>} Position and supplier due date
 */
export const fetchDueDates = async id => {
  try {
    const response = await client.query({
      query: GET_SUPPLIER_DUE_DATE,
      variables: { id },
    });

    const positionDueDate = getProperty(response, 'data.position.dueOn');
    const supplierDueDate = getProperty(response, 'data.position.supplierDueDate');

    return {
      position: positionDueDate,
      supplier: supplierDueDate || positionDueDate,
    };
  } catch (error) {
    logger.exception(error);
    throw error;
  }
};

/**
 * Update supplier due date
 * @param {string} id Position id
 * @param {string} dueDate Supplier due date
 */
export const updateSupplierDueDate = async (id, dueDate) => {
  try {
    await client.mutate({
      mutation: UPDATE_SUPPLIER_DUE_DATE,
      variables: {
        id,
        dueDate,
      },
    });
  } catch (error) {
    logger.exception(error);
    throw error;
  }
};

export const hasSubmissions = positionStatus => {
  return positionStatus.value === POSITION_STATUS.PENDING_SELECTION;
};

export const showSelectWinner = (positionStatus, hasDueDatePassed) => {
  if (positionStatus.value === POSITION_STATUS.PENDING_SELECTION) {
    return true;
  }

  const statusesForRecruitingState = [POSITION_STATUS.PUBLISHED, POSITION_STATUS.UNPUBLISHED];
  if (hasDueDatePassed && statusesForRecruitingState.includes(positionStatus.value)) {
    return true;
  }

  return false;
};

export const downloadSubmissionsDocuments = async positionId => {
  try {
    const response = await axios.get(`/api/files/candidates-docs/${positionId}`, {
      responseType: 'blob',
    });

    if (response.data.error) {
      logger.error(response.data.error, { positionId });
    }

    return new Blob([response.data]);
  } catch (error) {
    logger.exception(error, { positionId });
    throw error;
  }
};
