import React, { useRef } from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useSize from '@react-hook/size';
// hooks
import useToggle from 'hooks/useToggle';
// components
import Chip, { COLOR } from 'components/atoms/NewChip';
import Icon from 'components/atoms/Icon';
import Tooltip from 'components/atoms/Tooltip';
import TruncatedText from 'components/atoms/TruncatedText';
import ClickableElement from 'components/atoms/ClickableElement';
import OutsideDetector from 'components/atoms/OutsideDetector';
import CheckboxList from 'components/molecules/CheckboxList';
// styles
import styles from './checkboxListInput.module.scss';

const propTypes = {
  icon: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  selected: PropTypes.arrayOf(PropTypes.string.isRequired),
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  icon: '',
  placeholder: '',
  selected: [],
};

const CHIP_MIN_WIDTH = 95;

const CheckboxListInput = ({ placeholder, icon, options, selected, onChange }) => {
  const [collapsed, toggle] = useToggle(true);
  const input = useRef();
  const [width] = useSize(input);

  const selectedOptions = options.filter(option => selected.includes(option.value));
  const numberOfVisibleSelectedOptions = Math.floor(width / CHIP_MIN_WIDTH);
  const visibleSelectedOptions = selectedOptions.slice(0, numberOfVisibleSelectedOptions);
  const hiddenSelectedOptions = selectedOptions.slice(numberOfVisibleSelectedOptions);

  const handleRemove = value => () => {
    onChange(selected.filter(selectedValue => selectedValue !== value));
  };

  const renderTooltip = () => {
    return (
      <div>
        {hiddenSelectedOptions.map((option, index) => (
          <div key={option.label} className={classNames({ 'm-t-2': index })}>
            - {option.label}
          </div>
        ))}
      </div>
    );
  };

  return (
    <OutsideDetector onClick={() => (!collapsed ? toggle() : null)}>
      <ClickableElement
        onClick={toggle}
        className={styles.container}
        disabled={!collapsed || selectedOptions.length > 0}
      >
        <div className={styles.input} ref={input}>
          {icon && (
            <div className={classNames(styles.icon, 'm-r-10')}>
              <Icon name={icon} size={16} />
            </div>
          )}
          {selectedOptions.length > 0 ? (
            <React.Fragment>
              {visibleSelectedOptions.map((option, index) => (
                <Chip
                  key={option.value}
                  color={COLOR.BLUE}
                  className={classNames(styles.chip, { 'm-l-5': index })}
                >
                  <TruncatedText>{option.label}</TruncatedText>
                  <ClickableElement
                    onClick={handleRemove(option.value)}
                    className={styles.deleteIcon}
                  >
                    <Icon name="close" size={14} />
                  </ClickableElement>
                </Chip>
              ))}
              {hiddenSelectedOptions.length > 0 && (
                <Tooltip title={renderTooltip()}>
                  <div className={styles.moreItems}>
                    <i>...({hiddenSelectedOptions.length})</i>
                  </div>
                </Tooltip>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {placeholder && <div className={styles.placeholder}>{placeholder}</div>}
            </React.Fragment>
          )}
        </div>
        <ClickableElement
          onClick={toggle}
          className={styles.icon}
          disabled={collapsed && !selectedOptions.length}
        >
          <Icon name={collapsed ? 'expandMore' : 'less'} size={23} />
        </ClickableElement>
        {!collapsed && (
          <div className={styles.checkboxList}>
            <CheckboxList selected={selected} options={options} onChange={onChange} />
          </div>
        )}
      </ClickableElement>
    </OutsideDetector>
  );
};

CheckboxListInput.propTypes = propTypes;
CheckboxListInput.defaultProps = defaultProps;

export default CheckboxListInput;
