import React from 'react';
// libraries
import getProperty from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// hooks
import useAuth from 'hooks/useAuth';
// constants
import { ONBOARDING_STATUS_PO, ONBOARDING_TAB } from 'constants/onboarding';
import { SUBMISSION_STATUS_TYPE } from 'constants/submissionStatus';
import { STAFF_ROLE, SUPPLIER_ROLE, MANAGER_ROLE } from 'constants';
// constants
import { PO_FINISHED } from './constants';
// components
import ConfirmPurchaseOrder from './ConfirmPurchaseOrder';
import TextContent from '../TextContent';
import PurchaseOrderTicket from './PurchaseOrderTicket';
import ReleasePurchaseOrder from './ReleasePurchaseOrder';
// helpers
import { isTabFinished } from '../helpers';

const propTypes = {
  submission: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
  tabs: PropTypes.array,
};

const defaultProps = {
  tabs: [],
};

const PurchaseOrder = ({ submission, tabs, position }) => {
  const { role } = useAuth();
  const { t } = useTranslation();

  const { rfqLabel } = position;
  const purchaseOrder = getProperty(submission, 'hiringProcess.purchaseOrder', {});
  const activeStatus = submission.getStatus(role, SUBMISSION_STATUS_TYPE.ONBOARDING_PO);

  if (isTabFinished(tabs, ONBOARDING_TAB.PO)) {
    // 0. Accepted - all steps are finished
    return (
      <TextContent
        title={t('purchaseOrderIsActive')}
        content={
          <PurchaseOrderTicket
            type={PO_FINISHED}
            className="w100 m-t-30"
            purchaseOrder={purchaseOrder}
            candidateName={submission.getName()}
            rfq={rfqLabel}
          />
        }
      />
    );
  }

  if (!activeStatus) {
    // 1. INACTIVE STATUS - the same for all roles
    return (
      <TextContent
        title={t('purchaseOrderIsInactive')}
        label={t('purchaseOrderIsInactiveDescription')}
        content={
          <PurchaseOrderTicket
            className="w100 m-t-30"
            candidateName={submission.getName()}
            rfq={rfqLabel}
          />
        }
      />
    );
  }

  if (activeStatus.value === ONBOARDING_STATUS_PO.RELEASE) {
    if (role === SUPPLIER_ROLE) {
      // 2. CREATE PURCHASE ORDER - Supplier
      return (
        <TextContent
          title={t('purchaseOrderIsInactive')}
          label={t('waitingForPOConfirmation')}
          content={
            <PurchaseOrderTicket
              className="w100 m-t-30"
              candidateName={submission.getName()}
              rfq={rfqLabel}
            />
          }
        />
      );
    }
    if (role === MANAGER_ROLE) {
      // 3. CREATE PURCHASE ORDER - Project Manager
      return (
        <ReleasePurchaseOrder
          submission={submission}
          position={position}
          purchaseOrder={purchaseOrder}
        />
      );
    }
    if (role === STAFF_ROLE) {
      // 4. CREATE PURCHASE ORDER - Match Manager
      return (
        <TextContent
          title={t('purchaseOrderIsInactive')}
          label={t('waitingForPMToReleasePO')}
          content={
            <PurchaseOrderTicket
              className="w100 m-t-30"
              candidateName={submission.getName()}
              rfq={rfqLabel}
            />
          }
        />
      );
    }
  }

  if (activeStatus.value === ONBOARDING_STATUS_PO.CONFIRM) {
    if (role === SUPPLIER_ROLE) {
      // 5. SEND PO CONFIRMATION - Supplier
      return (
        <TextContent
          title={t('purchaseOrderIsInactive')}
          label={t('waitingForMMToConfirmPO')}
          content={
            <PurchaseOrderTicket
              className="w100 m-t-30"
              candidateName={submission.getName()}
              rfq={rfqLabel}
            />
          }
        />
      );
    }
    if (role === MANAGER_ROLE) {
      // 6. SEND PO CONFIRMATION - Project Manager - show not filled form files
      return (
        <ReleasePurchaseOrder
          submission={submission}
          position={position}
          purchaseOrder={purchaseOrder}
          readOnly
        />
      );
    }
    if (role === STAFF_ROLE) {
      // 7. SEND PO CONFIRMATION - Match Manager
      return (
        <ConfirmPurchaseOrder
          purchaseOrder={purchaseOrder}
          submission={submission}
          position={position}
        />
      );
    }
  }

  return null;
};

PurchaseOrder.propTypes = propTypes;
PurchaseOrder.defaultProps = defaultProps;

export default PurchaseOrder;
