import i18n from 'i18next';
import getProperty from 'lodash/get';
// helpers
import { findRates } from 'helpers/Common';
// constants
import { REVISION_TYPES, RATES_TARGET_RATE, RATES_SUPPLIER_RATE } from 'constants';
import { ACTIONS } from '../constants';

export const getRevisionType = (revision, revisionType) => {
  return getProperty(revision, 'types.items', []).find(({ type }) => type === revisionType);
};

export const getProposedRates = revision => {
  const types = getProperty(revision, 'types.items', []);
  const rateType = types.find(({ type }) => type === REVISION_TYPES.RATES);

  return getProperty(rateType, 'rates.items', []);
};

export const getPartnerRates = revision => {
  const rates = getProperty(revision, 'submission.rates.items', []);

  return findRates(rates, RATES_SUPPLIER_RATE);
};

export const getTargetRates = revision => {
  const positionRates = getProperty(revision, 'submission.position.rates.items', []);

  return findRates(positionRates, RATES_TARGET_RATE);
};

export const hasError = formData => {
  return Object.values(formData).some(({ error, requiredSelection }) => error || requiredSelection);
};

export const getActionButtonTitle = (type, action) => {
  if ([REVISION_TYPES.CV, REVISION_TYPES.ATTESTATION].includes(type)) {
    return ACTIONS.ACCEPT === action ? i18n.t('revisionAccept') : i18n.t('revisionReject');
  }
  return ACTIONS.ACCEPT === action ? i18n.t('revisionAcceptRate') : i18n.t('revisionRejectRate');
};
