import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
// components
import AutoCompleteSearch from 'components/molecules/AutoCompleteSearch';
import Button from 'components/atoms/Button';
import DropdownCheckbox from 'components/molecules/DropdownCheckbox';
import Field from 'components/molecules/Field';

const propTypes = {
  onDelete: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  data: PropTypes.object,
  roles: PropTypes.array,
};

const defaultProps = {
  data: {},
  roles: [],
};

class ProjectRole extends React.Component {
  constructor(props) {
    super(props);
    const { roles } = props;
    this.state = {
      roles,
    };
  }

  render() {
    const { roles } = this.state;
    const { data, onChange, onDelete, t } = this.props;

    return (
      <div className="projectRole">
        <div className="projectRole_delete">
          <Button type="delete" tooltip={t('delete')} onClick={onDelete} />
        </div>
        <Field
          required
          className="projectRole_role"
          label={t('role')}
          content={
            <DropdownCheckbox
              list={roles}
              selected={data && data.role ? data.role : null}
              onChange={onChange}
              label={t('selectARole')}
              checkedLabel={data && data.role ? data.role.label : ''}
              name="role"
              width={250}
            />
          }
        />
        <Field
          required
          className="projectRole_projects"
          label={t('projects')}
          content={
            <AutoCompleteSearch
              type="projectsList"
              onChange={onChange}
              id="projects"
              defaultValue={data && data.projects ? data.projects.label : null}
              limitSize={5}
              isMulti
            />
          }
        />
      </div>
    );
  }
}

ProjectRole.propTypes = propTypes;
ProjectRole.defaultProps = defaultProps;

export default withTranslation()(ProjectRole);
