import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// libraries
import CheckboxMaterial from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/styles';
// components
import Icon from 'components/atoms/Icon';
// styles
import colors from 'dependencies/materialStyles/Colors';
import { activeCheckboxStyle as checkboxStyle } from 'dependencies/materialStyles/CheckboxStyle';

const propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  smallFontSize: PropTypes.bool,
};

const defaultProps = {
  value: '',
  label: '',
  fullWidth: true,
  disabled: false,
  smallFontSize: false,
};

const useStyles = makeStyles({
  checkbox: {
    padding: '0 8px',
  },
  labelText: {
    fontSize: 15,
    fontFamily: 'Lato-Light',
    lineHeight: 1.3,
  },
  labelTextSmall: {
    fontSize: 14,
    fontFamily: 'Lato-Light',
    lineHeight: 1.3,
  },
  labelInline: {
    marginLeft: -8,
  },
  labelFullWidth: {
    width: '100%',
    padding: 0,
    marginRight: 0,
    marginLeft: -8,
  },
});

const Checkbox = ({ label, value, checked, onChange, fullWidth, disabled, smallFontSize }) => {
  const classes = useStyles();

  const getIconStyle = style => {
    if (disabled) {
      return {
        ...style,
        fill: colors.gray4,
      };
    }

    return style;
  };

  const handleChange = e => {
    if (!disabled) {
      onChange(e.target.checked);
    }
  };

  return (
    <div className={classNames({ disabledPointer: disabled })}>
      <FormControlLabel
        label={label}
        classes={{
          root: fullWidth ? classes.labelFullWidth : classes.labelInline,
          label: smallFontSize ? classes.labelTextSmall : classes.labelText,
        }}
        control={
          <CheckboxMaterial
            checked={checked}
            color="primary"
            value={value}
            classes={{ root: classes.checkbox }}
            checkedIcon={
              <Icon name="checkboxChecked" style={getIconStyle(checkboxStyle.iconChecked)} />
            }
            icon={
              <Icon name="checkboxUnchecked" style={getIconStyle(checkboxStyle.iconUnChecked)} />
            }
            onChange={handleChange}
            disabled={disabled}
          />
        }
      />
    </div>
  );
};

Checkbox.propTypes = propTypes;
Checkbox.defaultProps = defaultProps;

export default Checkbox;
