/* eslint-disable import/order */
import React, { useState, useContext, useEffect, useMemo } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import getProperty from 'lodash/get';
import moment from 'moment';
// hooks
import useAuth from 'hooks/useAuth';
// context
import ToastContext from 'context/ToastContext';
// components
import Textarea from 'components/atoms/Textarea';
import DatePicker from 'components/atoms/DatePickerCustom';
import Field from 'components/molecules/FieldSection';
import ModalStepper from 'components/molecules/ModalStepper';
import SelectWinnerTab from './SelectWinnerTab';
// constants
import { SUBMISSION_STATUS } from 'constants/submissionStatus';
import { STEP, FIELD, STEP_FIELDS } from './constants';
// helpers
import { fetchSubmissions, selectWinner, validateSelectWinner } from './helpers';
import { getTabs } from './tabOptions';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  position: PropTypes.object.isRequired,
  hasSubmissions: PropTypes.bool.isRequired,
};

const SelectWinner = ({ isOpen, onClose, position, hasSubmissions }) => {
  const { id: userId } = useAuth();
  const { t } = useTranslation();
  const { addToast } = useContext(ToastContext);

  const [submissions, setSubmissions] = useState([]);
  const [activeTab, setActiveTab] = useState('');
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [formTouched, setFormTouched] = useState(false);
  const [formData, setFormData] = useState({
    [FIELD.SUBMISSION_WON]: {
      value: null,
    },
    [FIELD.HAS_REJECTION_REASON]: {
      value: null,
    },
    [FIELD.SAME_REJECTION_REASON]: {
      value: null,
    },
    [FIELD.SELECTED_SUBMISSION]: {
      value: null,
    },
    [FIELD.NOT_SELECTED_SUBMISSIONS]: {
      value: [],
    },
    [FIELD.START_DATE]: {
      value: position.duration.start,
    },
    [FIELD.REASON]: {
      value: '',
    },
    [FIELD.REASONS]: {
      value: {},
    },
    [FIELD.NOTES]: {
      value: '',
    },
    [FIELD.TOTAL_EVALUATED_PRICE]: {
      value: 0,
    },
  });

  useEffect(() => {
    const fetchSubmissionsFn = async () => {
      try {
        setLoading(true);
        const fetchedSubmissions = await fetchSubmissions(
          position.id,
          SUBMISSION_STATUS.SUBMITTED,
          position.isFixedPrice()
        );
        setSubmissions(fetchedSubmissions);
      } catch (error) {
        addToast.error(t('errorPlaceholderText'));
        onClose();
      } finally {
        setLoading(false);
      }
    };

    if (isOpen) {
      fetchSubmissionsFn();
    }
  }, [isOpen]);

  const hasWon = getProperty(formData, [FIELD.SUBMISSION_WON, 'value'], false);
  const tabs = useMemo(() => getTabs(hasSubmissions, hasWon), [hasSubmissions, hasWon]);

  const handleFormDataChange = data => {
    if (formTouched) {
      const validatedData = validateSelectWinner(data);
      setFormData(validatedData);
    } else {
      setFormData(data);
    }
  };

  const handleChange = field => value => {
    setFormData({ ...formData, [field]: { ...formData[field], value } });
  };

  const validateData = () => {
    const validatedFormData = validateSelectWinner(formData);
    const hasError = Object.values(validatedFormData).some(({ error }) => error);
    setFormData(validatedFormData);

    if (!formTouched) {
      setFormTouched(true);
    }

    return { hasError, validatedFormData };
  };

  const handleSwitch = (to, from) => {
    const { hasError, validatedFormData } = validateData();

    const tabHasError = STEP_FIELDS[from].some(field => {
      return getProperty(validatedFormData, [field, 'error'], false);
    });

    if (hasError && tabHasError) {
      return;
    }

    setActiveTab(to);
  };

  const handleSubmit = async () => {
    const { hasError } = validateData();
    if (hasError) {
      return;
    }

    try {
      setSubmitting(true);
      await selectWinner(formData, position, userId);
      onClose();
      addToast.success(t('winnerSelectedSuccess'));
    } catch (error) {
      addToast.error(t('errorPlaceholderText'));
      setSubmitting(false);
    }
  };

  const content = {
    [STEP.SELECT_WINNER]: (
      <SelectWinnerTab
        hasSubmissions={hasSubmissions}
        submissions={submissions}
        formData={formData}
        onChange={handleFormDataChange}
      />
    ),
    [STEP.START_DATE]: (
      <Field title={t('expectedStartDateLabel')} description={t('expectedStartDateDescription')}>
        <DatePicker
          value={formData[FIELD.START_DATE].value}
          onChange={handleChange(FIELD.START_DATE)}
          minDate={moment()
            .hours(23)
            .minutes(59)}
        />
      </Field>
    ),
    [STEP.NOTES]: (
      <Field title={t('additionalNotes')} description={t('additionalNotesDescription')} optional>
        <Textarea
          value={formData[FIELD.NOTES].value}
          onChange={handleChange(FIELD.NOTES)}
          rows={8}
        />
      </Field>
    ),
  };

  return (
    <ModalStepper
      isOpen={isOpen}
      onClose={onClose}
      onSwitch={handleSwitch}
      onSubmit={handleSubmit}
      title={`${t('selectWinner')} - ${position.rfqLabel}`}
      tabs={tabs}
      activeTab={activeTab}
      loading={loading || submitting}
      loadingText={loading ? t('fetchingDataLoader') : t('submittingLoader')}
      content={content}
      newDesign
    />
  );
};

SelectWinner.propTypes = propTypes;

export default SelectWinner;
