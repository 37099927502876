import React, { useState, useEffect } from 'react';
// libraries
import PropTypes from 'prop-types';
import { ApolloProvider } from '@apollo/client';
// services
import { getUserDetails, redirectToLogin, storeToken } from 'services/Authentication';
import client from 'services/Client';
import logger from 'services/Logger';
// local states
import { setUser } from 'localStates/loggedInUser';
// components
import Loader from 'components/atoms/Loader';
import ErrorPage from 'components/pages/ErrorPage';
// queries
import { GET_USER } from 'queries/AuthenticationQueries';
// constants
import { PUBLIC_ROUTES, PUBLIC_ROUTES_WITH_HEADER } from 'constants';
// style
import styles from './authenticationProvider.module.scss';

const propTypes = {
  children: PropTypes.element.isRequired,
};

const isPublicRoute = pathname => {
  const isPublicWithHeader = PUBLIC_ROUTES_WITH_HEADER.includes(pathname);

  return isPublicWithHeader ? false : PUBLIC_ROUTES.includes(pathname);
};

const AuthenticationProvider = ({ children }) => {
  const [loading, setLoading] = useState(!isPublicRoute(window.location.pathname));
  const [error, setError] = useState(false);

  useEffect(() => {
    const loadUser = async () => {
      // preload current user data
      // TODO: Load current user data with user details in one query
      try {
        const { token, email, role } = await getUserDetails();
        storeToken(token);

        await client.query({ query: GET_USER, variables: { email } });
        setUser(email, role);

        setLoading(false);
      } catch (err) {
        if (err.code === 401) {
          if (PUBLIC_ROUTES_WITH_HEADER.includes(window.location.pathname)) {
            // TODO: prevent logging error to console in this case
            setLoading(false);
          } else {
            redirectToLogin();
          }
        } else {
          setError(true);
          setLoading(false);
          logger.exception(err);
        }
      }
    };

    if (!isPublicRoute(window.location.pathname)) loadUser();
  }, []);

  if (loading) {
    return <Loader fullSize />;
  }

  if (error) {
    return (
      <div className={styles.errorPage}>
        <ErrorPage code={500} />
      </div>
    );
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

AuthenticationProvider.propTypes = propTypes;

export default AuthenticationProvider;
