import React from 'react';
import { useTranslation } from 'react-i18next';
// libraries
import PropTypes from 'prop-types';
// hooks
import useAuth from 'hooks/useAuth';
// helpers
import { getRoleColor } from 'helpers/Colors';
import colors from 'dependencies/materialStyles/Colors';
// components
import Button from 'components/atoms/Button';
import CircleTab from './CircleTab';
// styles
import './circleTabs.scss';

const propTypes = {
  tabs: PropTypes.array,
  className: PropTypes.string,
  activeTab: PropTypes.string,
  setTab: PropTypes.func,
  percentage: PropTypes.number,
  open: PropTypes.bool,
  toggleBar: PropTypes.func,
  hideProgress: PropTypes.bool,
  progress: PropTypes.string,
};

const defaultProps = {
  tabs: [],
  className: '',
  activeTab: null,
  setTab: () => {},
  percentage: 0,
  open: true,
  toggleBar: () => {},
  hideProgress: false,
  progress: '0/0',
};

const CircleTabs = props => {
  const { role } = useAuth();
  const { t } = useTranslation();
  const {
    tabs,
    className,
    activeTab,
    setTab,
    percentage,
    open,
    toggleBar,
    hideProgress,
    progress,
  } = props;

  return (
    <div style={{ position: 'relative' }} className={className}>
      <div className="circleTabs" style={open ? { padding: 0 } : null}>
        {tabs.map((tab, index) => {
          return (
            <CircleTab
              tab={tab}
              key={tab.id}
              tabClassName={tab.tabClassName}
              activeTab={activeTab}
              index={index}
              setTab={setTab}
              open={open}
              done={tab.steps.every(step => step.finished)}
            />
          );
        })}
      </div>
      {!hideProgress && (
        <div
          className={`circleTabs_progress cp ${percentage === 100 ? 'completed' : ''}`}
          onClick={() => toggleBar(!open)}
          role="presentation"
        >
          <div>
            {progress} {t('completed')}
          </div>
          <Button
            type={!open ? 'expandMore' : 'less'}
            color={percentage === 100 ? colors[getRoleColor[role]] : colors.gray}
            tooltip={`${open ? t('collapse') : t('expand')}`}
          />
        </div>
      )}
    </div>
  );
};

CircleTabs.propTypes = propTypes;
CircleTabs.defaultProps = defaultProps;

export default CircleTabs;
