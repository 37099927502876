/* eslint-disable import/order */
import React, { useState, useEffect, useContext } from 'react';
// libraries
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import getProperty from 'lodash/get';
// context
import ToastContext from 'context/ToastContext';
// hooks
import useAuth from 'hooks/useAuth';
import useItemsCount from 'hooks/useItemsCount';
import useViewSwitcher from 'hooks/useViewSwitcher';
// constants
import { MANAGER_ROLE, POSITION, COUNT_POSITION } from 'constants';
// helpers
import { addParam, removeParam, clearParams, parseParams, setParams } from 'helpers/QueryParams';
import { fetchClearances } from 'helpers/Clearance';
import { parseDataForCollapsedFilter, getPositionFiltersWhiteList } from './helper';
// queries
import { GET_DRAFT_COUNT } from 'queries/DraftQueries';
// components
import Button from 'components/atoms/Button';
import DraftChip from 'components/molecules/DraftChip';
import PositionList from 'components/organisms/PositionList';
import Page from 'components/templates/Page';
import ViewSwitch from 'components/molecules/ViewSwitch';
import CollapsedFilters from 'components/molecules/CollapsedFilters';
import PositionFilters from './PositionFilters';
// data
import PositionSort from './data/PositionSort';
// styles
import './positionsPage.scss';

const propTypes = {
  mine: PropTypes.bool,
};

const defaultProps = {
  mine: false,
};

const PositionsPage = ({ mine }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const itemsCount = useItemsCount(COUNT_POSITION);
  const { addToast } = useContext(ToastContext);
  const { id, role, projects } = useAuth();
  const [tableView, setView] = useViewSwitcher(POSITION);

  const [filtersOpen, setFiltersOpen] = useState(true);
  const [loading, setLoading] = useState(true);
  const [clearances, setClearances] = useState([]);

  const userProjects = projects.map(project => project.name);
  const filter = parseParams(location, getPositionFiltersWhiteList(role, userProjects));
  const sortList = PositionSort[role] || PositionSort.SUPPLIER;
  const draftCountResponse = useQuery(GET_DRAFT_COUNT, {
    variables: { userId: id, type: POSITION },
  });

  useEffect(() => {
    const fetchClearanceOptions = async () => {
      try {
        setLoading(true);
        const fetchedClearances = await fetchClearances(null, true);

        setClearances(fetchedClearances);
      } catch {
        addToast.warn(t('errorClearancesNotLoaded'));
      } finally {
        setLoading(false);
      }
    };

    fetchClearanceOptions();
  }, []);

  const toggleTableView = type => {
    setView(type);
  };

  const toggleFilters = () => {
    setFiltersOpen(!filtersOpen);
  };

  const removeFilter = (type, value) => {
    removeParam(type, value, location, history);
  };

  const addFilter = (type, value) => {
    if (!value) {
      removeFilter(type);
    } else {
      addParam(type, value, location, history);
    }
  };

  const removeFilters = () => {
    clearParams(history, location);
  };

  const getFilters = () => {
    return (
      <PositionFilters
        loading={loading}
        clearances={clearances}
        filter={filter}
        onClose={toggleFilters}
        onChangeParams={params => setParams(params, location, history)}
        onChange={addFilter}
        onClear={removeFilters}
      />
    );
  };

  const getCollapsedFilters = () => {
    return (
      <div className="flex_space page_filter fs-unmask">
        <CollapsedFilters
          items={parseDataForCollapsedFilter(filter, role, clearances)}
          onFilterClick={toggleFilters}
          onClear={removeFilter}
        />
        <div className="oneRow">
          <ViewSwitch tableView={tableView} toggleView={toggleTableView} />
        </div>
      </div>
    );
  };

  const createButton = role === MANAGER_ROLE && (
    <Link to="/position/create">
      <Button iconButton="add" label={t('addPosition')} />
    </Link>
  );

  return (
    <div className="display-flex-row h100">
      {filtersOpen && getFilters()}
      <div className={`scrollableContent ${filtersOpen ? 'panelOpen' : ''}`} id="scrollableContent">
        <Page
          headerName={mine ? t('myPositions') : t('positions')}
          count={itemsCount}
          sortList={sortList}
          noClearFilters
          defaultSort={sortList[0]}
          createButton={createButton}
          className="h100 display-flex"
          searchPlaceholder={t('searchPositions')}
          customHeaderContent={
            role === MANAGER_ROLE && (
              <DraftChip
                type={POSITION}
                count={getProperty(draftCountResponse, 'data.draftsList.count', 0)}
                label={t('position')}
              />
            )
          }
          pageHeaderClassName="page_header fs-unmask"
          content={
            <React.Fragment>
              {getCollapsedFilters()}
              <div className="page_content">
                <PositionList filteredBy={filter} tableView={tableView} mine={mine} />
              </div>
            </React.Fragment>
          }
        />
      </div>
    </div>
  );
};

PositionsPage.propTypes = propTypes;
PositionsPage.defaultProps = defaultProps;

export default PositionsPage;
