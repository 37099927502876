// constants
import { COLOR } from 'constants/colors';
import { FILE_TYPE } from './constants';

export const getIconType = fileExtension => {
  if (['pdf'].includes(fileExtension)) {
    return FILE_TYPE.PDF;
  }
  if (['png', 'jpeg', 'jpg'].includes(fileExtension)) {
    return FILE_TYPE.IMAGE;
  }
  if (['csv'].includes(fileExtension)) {
    return FILE_TYPE.CSV;
  }
  if (['xls', 'xlsx'].includes(fileExtension)) {
    return FILE_TYPE.EXCEL;
  }
  if (['txt'].includes(fileExtension)) {
    return FILE_TYPE.TEXT;
  }
  if (['dot', 'dotx', 'doc', 'docx'].includes(fileExtension)) {
    return FILE_TYPE.WORD;
  }
  if (['zip', 'rar'].includes(fileExtension)) {
    return FILE_TYPE.ARCHIVE;
  }

  return FILE_TYPE.FILE;
};

export const getColorByFileType = type => {
  if ([FILE_TYPE.PDF, FILE_TYPE.IMAGE].includes(type)) {
    return COLOR.RED;
  }
  if ([FILE_TYPE.WORD, FILE_TYPE.TEXT].includes(type)) {
    return COLOR.BLUE;
  }
  if ([FILE_TYPE.EXCEL, FILE_TYPE.CSV].includes(type)) {
    return COLOR.GREEN;
  }

  return COLOR.GRAY;
};
