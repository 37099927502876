import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
// translations
import en from './en/translation.json';
import de from './de/translation.json';

i18next.use(initReactI18next).init(
  {
    lng: 'en', // language to use
    fallbackLng: 'en',
    ns: 'translation',
    keySeparator: '.', // key separator
    defaultNS: 'translation',
    resources: {
      en: {
        translation: en,
      },
      de: {
        translation: de,
      },
    },
  },
  () => {
    // i18next.t('welcome.title'); // key in moduleA namespace (defined default)
    i18next.t('welcomeTitle'); // key in common namespace
  }
);

export default i18next;
