import i18n from 'i18next';
import {
  ADMIN_ROLE,
  STAFF_ROLE,
  SUPPLIER_ROLE,
  MANAGER_ROLE,
  SORT_SCORE,
  SORT_CV_DATE,
  SORT_DUE_DATE,
  SORT_AVAILABILITY,
} from 'constants';

const staff = [
  {
    label: i18n.t('matchScore'),
    id: SORT_SCORE,
  },
  {
    label: i18n.t('cvReceived'),
    id: SORT_CV_DATE,
  },
  {
    label: i18n.t('dueDate'),
    id: SORT_DUE_DATE,
  },
];

const supplier = [
  {
    label: i18n.t('matchScore'),
    id: SORT_SCORE,
  },
  {
    label: i18n.t('cvSubmitted'),
    id: SORT_CV_DATE,
  },
  {
    label: i18n.t('dueDate'),
    id: SORT_DUE_DATE,
  },
];

const manager = [
  {
    label: i18n.t('matchScore'),
    id: SORT_SCORE,
  },
  {
    label: i18n.t('cvReceived'),
    id: SORT_CV_DATE,
  },
  // !!! DUE DATE not visible in table! TO REMOVE? !!!
  // {
  //   label: i18n.t('dueDate'),
  //   id: SORT_DUE_DATE,
  // },
  {
    label: i18n.t('availability'),
    id: SORT_AVAILABILITY,
  },
];

export default {
  [ADMIN_ROLE]: staff,
  [STAFF_ROLE]: staff,
  [SUPPLIER_ROLE]: supplier,
  [MANAGER_ROLE]: manager,
};
