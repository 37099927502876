import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// hooks
import useModalProvider from 'hooks/useModalProvider';
import useAuth from 'hooks/useAuth';
// constants
import { STAFF_ROLE } from 'constants';
// components
import IconDropdown from 'components/molecules/IconDropdown';
import {
  UploadReleasableCV,
  UploadReleasableAttestation,
} from 'components/organisms/SubmissionSettings';
// styles
import colors from 'dependencies/materialStyles/Colors';

const propTypes = {
  submission: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
};

const MODAL = {
  RELEASABLE_CV: 'RELEASABLE_CV',
  ATTESTATION: 'ATTESTATION',
};

const DocumentSettings = ({ submission, position }) => {
  const { t } = useTranslation();
  const { role } = useAuth();
  const { openModal, closeModal, isModalOpen } = useModalProvider();
  const { rfqLabel, client, project } = position;

  if (role === STAFF_ROLE) {
    const menuItems = [
      {
        icon: 'fileUpload',
        id: 'uploadReleasableCv',
        label: t('uploadReleasableCv'),
        onClick: () => openModal(MODAL.RELEASABLE_CV),
      },
      {
        icon: 'cloudUpload',
        id: 'uploadAttestation',
        label: t('uploadAttestation'),
        onClick: () => openModal(MODAL.ATTESTATION),
      },
    ];

    return (
      <React.Fragment>
        <IconDropdown menuItems={menuItems} iconColor={colors.gray} />
        <UploadReleasableCV
          isOpen={isModalOpen(MODAL.RELEASABLE_CV)}
          onClose={closeModal}
          client={client.name}
          project={project.name}
          rfq={rfqLabel}
          submissionId={submission.id}
          name={submission.candidate.name}
        />
        <UploadReleasableAttestation
          isOpen={isModalOpen(MODAL.ATTESTATION)}
          onClose={closeModal}
          client={client.name}
          project={project.name}
          rfq={rfqLabel}
          submissionId={submission.id}
          name={submission.candidate.name}
        />
      </React.Fragment>
    );
  }

  return null;
};

DocumentSettings.propTypes = propTypes;

export default DocumentSettings;
