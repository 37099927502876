import React from 'react';
// Libraries
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
// components
import Icon from 'components/atoms/Icon';

const propTypes = {
  tooltip: PropTypes.string,
  className: PropTypes.string,
};

const defaultProps = {
  tooltip: null,
  className: '',
};

const Circle = ({ tooltip, className }) => {
  const circleIcon = (
    <div className={`${className} circleTab_notificationIcon cp`}>
      <Icon name="priority" size={13} color="white" />
    </div>
  );
  if (!tooltip) {
    return circleIcon;
  }
  return (
    <Tooltip title={tooltip} disableFocusListener TransitionProps={{ timeout: 600 }}>
      {circleIcon}
    </Tooltip>
  );
};

Circle.propTypes = propTypes;
Circle.defaultProps = defaultProps;

export default Circle;
