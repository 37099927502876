import React from 'react';
import { Redirect } from 'react-router-dom';
// components
// import Page from 'components/templates/Page';

const DashboardPage = () => {
  return <Redirect to="/positions/all" />;
  // return (
  //   <Page
  //     headerName="Dashboard"
  //     noFilters
  //     className="dashboard-container"
  //     content={<div>dashboard</div>}
  //   />
  // );
};

export default DashboardPage;
