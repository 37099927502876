import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// components
import TextSection from 'components/atoms/TextSection';
import Link from 'components/atoms/Link';
// constants
import { FILE_POSITION_DOCUMENT, FILE_RATE_CARD, STAFF_ROLE } from 'constants';
// helpers
import { boxFileUrl } from 'helpers/Box';
// styles
import styles from './attachments.module.scss';

const propTypes = {
  position: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  reportId: PropTypes.string,
};

const defaultProps = {
  reportId: '',
};

const Attachments = ({ reportId, position, role }) => {
  const { t } = useTranslation();
  const documents = position.getDocuments(FILE_POSITION_DOCUMENT);
  const rateCard = position.getDocument(FILE_RATE_CARD);

  if (role === STAFF_ROLE && rateCard) {
    documents.push(rateCard);
  }

  if (!reportId && !documents.length) {
    return <i>{t('notProvided')}</i>;
  }

  return (
    <div className={styles.attachments}>
      {documents.length > 0 && (
        <div className={classNames(styles.attachment, { 'm-r-12': reportId })}>
          <TextSection title={t('documents')}>
            {documents.map((document, index) => (
              <div className={index ? 'm-t-8' : ''} key={document.id}>
                <Link href={boxFileUrl(document.boxId)} className={styles.link}>
                  {document.filename}
                </Link>
              </div>
            ))}
          </TextSection>
        </div>
      )}
      {reportId && (
        <div className={styles.attachment}>
          <TextSection title={t('scorecardTemplate')}>
            <Link href={`/report/position/${reportId}`} external={false} className="fs-unmask">
              {t('viewScorecard')}
            </Link>
          </TextSection>
        </div>
      )}
    </div>
  );
};

Attachments.propTypes = propTypes;
Attachments.defaultProps = defaultProps;

export default Attachments;
