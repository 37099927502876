/* eslint-disable import/order */
import React from 'react';
// libraries
import PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import classNames from 'classnames';
// constants
import { RATES_SUPPLIER_RATE } from 'constants';
// helpers
import { getPartnerCategoryText } from 'helpers/NameMapping';
import { validateSellRate } from '../helpers';
// components
import RatesSimulation from 'components/organisms/RatesSimulation/Submission';
import Table from '../Table';
import SubmissionRow from './SubmissionRow';
// types
import { MetricType } from 'types';
// styles
import styles from './sellRates.module.scss';

const propTypes = {
  isProposal: PropTypes.bool.isRequired,
  submission: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  rateSimulationId: PropTypes.string,
  onSimulationClick: PropTypes.func.isRequired,
  onSubmissionUnselect: PropTypes.func.isRequired,
  onSubmissionRestore: PropTypes.func.isRequired,
  onRatesSimulationOpened: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  position: PropTypes.shape({
    title: PropTypes.string.isRequired,
    project: PropTypes.string.isRequired,
    experienceLevel: PropTypes.string.isRequired,
    occupations: PropTypes.arrayOf(PropTypes.string).isRequired,
    currency: PropTypes.string.isRequired,
    hours: PropTypes.number,
    manYearDiscount: PropTypes.number.isRequired,
    nteRates: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number.isRequired,
        year: PropTypes.number.isRequired,
      })
    ).isRequired,
  }).isRequired,
  metrics: MetricType,
};

const defaultProps = {
  rateSimulationId: '',
  disabled: false,
  metrics: null,
};

const Submission = ({
  isProposal,
  submission,
  rateSimulationId,
  onChange,
  onSimulationClick,
  onSubmissionUnselect,
  onSubmissionRestore,
  onRatesSimulationOpened,
  disabled,
  position,
  metrics,
}) => {
  const partnerRates = submission.getRates(RATES_SUPPLIER_RATE);
  const isSimulationOpen = rateSimulationId === submission.id;
  const { nteRates, manYearDiscount, currency, hours } = position;
  const years = nteRates.map(rate => rate.year);

  const validateRate = (newSellRate, rate) => {
    const defaultRate = submission.defaultSellRates.find(({ year }) => year === rate.year);
    const nteRate = nteRates.find(({ year }) => year === rate.year);
    const partnerRate = partnerRates.find(({ year }) => year === rate.year);

    const errorMessage = validateSellRate({
      sellRate: newSellRate,
      partnerRate: partnerRate.value,
      nteRate: nteRate.value,
      manYearDiscount,
      isDirectSupplier: submission.isDirectPartner(),
      includeCSAFee: submission.partner.includeCSAFee,
    });

    return {
      ...rate,
      value: newSellRate,
      highlighted: newSellRate !== defaultRate.value,
      error: !newSellRate || !!errorMessage,
      errorMessage,
    };
  };

  const handleChange = sellRates => {
    // highlight rates which were changed and handle error for rates
    const newSellRates = sellRates.map(rate => {
      return validateRate(rate.value, rate);
    });

    onChange(submission.id, newSellRates);
  };

  const handleRemoveCandidateClick = () => {
    // close simulation if it's open
    if (isSimulationOpen) {
      onSimulationClick(submission.id);
    }

    onSubmissionUnselect();
  };

  const applyRates = simulatedRates => {
    const newSellRates = submission.sellRates.map((rate, index) => {
      return validateRate(simulatedRates[index], rate);
    });

    onChange(submission.id, newSellRates);
  };

  return (
    <React.Fragment>
      <Table.Row
        className={classNames(styles.row, { [styles.narrow]: !disabled })}
        highlighted={isSimulationOpen}
        disabled
      >
        <SubmissionRow
          isProposal={isProposal}
          score={submission.score}
          name={submission.getName()}
          company={submission.partner.name}
          partnerType={getPartnerCategoryText(submission.partner.category)}
          partnerRates={partnerRates}
          nteRates={nteRates}
          sellRates={submission.sellRates}
          onSellRatesChange={handleChange}
          isSimulationOpen={isSimulationOpen}
          onSimulationClick={() => onSimulationClick(submission.id)}
          onSubmissionUnselect={handleRemoveCandidateClick}
          onSubmissionRestore={onSubmissionRestore}
          disabled={disabled}
          position={position}
          metrics={metrics}
          previouslySaved={submission.previouslySaved}
        />
      </Table.Row>
      <Collapse
        in={isSimulationOpen}
        timeout="auto"
        classes={{ wrapperInner: styles.ratesSimulation }}
        onEntered={onRatesSimulationOpened}
      >
        <RatesSimulation
          hours={hours}
          partnerCategory={submission.partner.category}
          includeCSAFee={submission.partner.includeCSAFee}
          currency={currency}
          years={years}
          nteRates={nteRates.map(rate => rate.value)}
          partnerRates={partnerRates.map(rate => rate.value)}
          onApplyRates={applyRates}
          sellPrices={submission.sellRates.map(rate => rate.value)}
          isProposal={isProposal}
          enableManYearDiscount={!!manYearDiscount}
        />
      </Collapse>
    </React.Fragment>
  );
};

Submission.propTypes = propTypes;
Submission.defaultProps = defaultProps;

export default Submission;
