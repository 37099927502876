// textfield, NumberFormInput, email, phoneNumber, address, textarea
/* eslint-disable no-restricted-globals */
import React, { useEffect } from 'react';
// Libraries
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import getProperty from 'lodash/get';
import hasProperty from 'lodash/has';
import PropTypes from 'prop-types';
// Components
import CKEditor from 'components/molecules/CKEditor';
import Icon from 'components/atoms/Icon';
import Input from 'components/atoms/Input';
import NumberFormInput from 'components/atoms/NumberFormInput';
import Textarea from 'components/atoms/Textarea';
// helpers
import colors from 'dependencies/materialStyles/Colors';
// helpers
import { validateNumber } from './Helpers';

const propTypes = {
  component: PropTypes.object,
  formData: PropTypes.any,
  updateFormData: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

const defaultProps = {
  component: {},
  formData: {},
  disabled: false,
};

function Texts(props) {
  const { t } = useTranslation();

  const { component, updateFormData, formData, disabled } = props;
  const { type, properties, defaultValue, key } = component;
  const { placeholderKey } = properties || {};
  const placeholder = placeholderKey ? i18n.t(placeholderKey) : null;

  useEffect(() => {
    if (!getProperty(formData, [key, 'value']) && defaultValue) {
      setTimeout(() => {
        updateFormData(key, defaultValue);
      }, 0);
    }
  }, []);

  const onInputChange = (value, id) => {
    const newValue = value !== '' ? { value, error: false } : null;
    updateFormData(id, newValue);
  };

  const getHtmlValue = () => {
    if (formData[key]) {
      return formData[key].value || defaultValue || '';
    }

    return defaultValue || '';
  };

  const validateInput = (id, pattern, errorMessage) => {
    if (formData[id]) {
      if (
        formData[id].value &&
        isNaN(formData[id].value) &&
        formData[id].value.match(new RegExp(pattern)) === null
      ) {
        updateFormData(id, {
          ...formData[id],
          error: true,
          errorMessage,
        });
      }
    }
  };

  const validateNumberInput = () => {
    if (component.validate) {
      const isValid = validateNumber(formData[key], component.validate);
      if (!isValid) {
        updateFormData(key, {
          ...formData[key],
          error: true,
          errorMessage: t(getProperty(component, 'properties.errorMessageKey', 'invalidInput')),
        });
      }
    }
  };

  const getInputValue = () => {
    if (formData && formData[key]) {
      if (component.index && formData[key][component.index]) {
        return formData[key][component.index].value;
      }
      return formData[key].value;
    }
    if (defaultValue) {
      return Number(defaultValue);
    }
    return '';
  };

  switch (type) {
    case 'html':
      return (
        <CKEditor
          type="basic"
          data={getHtmlValue()}
          onChange={value => onInputChange(value, key)}
        />
      );
    case 'textfield':
      return (
        <Input
          placeholder={placeholder}
          onChange={onInputChange}
          id={key}
          disabled={disabled}
          onClear={() => updateFormData(key, undefined)}
          value={formData[key] ? formData[key].value : ''}
          validateInput={
            component.validate && component.validate.pattern
              ? () =>
                  validateInput(key, component.validate.pattern, component.validate.customMessage)
              : null
          }
        />
      );
    case 'number':
      return (
        <NumberFormInput
          value={getInputValue()}
          allowNegative={!!component.validate && !!component.validate.negativeNumbers}
          decimalLimit={component.decimalLimit ? component.decimalLimit : 0}
          min={component.validate && component.validate.min ? component.validate.min : 0}
          onChange={value => onInputChange(value, key)}
          onBlur={validateNumberInput}
          disabled={disabled}
          placeholder={placeholder || i18n.t('typeNumber')}
          highlight={component.highlight}
          id={key}
        />
      );
    case 'email':
      return (
        <Input
          placeholder="example@domain.com"
          onChange={onInputChange}
          id={key}
          disabled={disabled}
          onClear={() => updateFormData(key, undefined)}
          value={formData[key] ? formData[key].value : ''}
          inputType="email"
          icon={<Icon color={colors.gray} size={18} name="email" />}
          validateInput={
            component.validate && component.validate.pattern
              ? () =>
                  validateInput(key, component.validate.pattern, component.validate.customMessage)
              : null
          }
        />
      );
    case 'phoneNumber':
      return (
        <Input
          placeholder="+999 (999) 999-9999"
          onChange={onInputChange}
          id={key}
          disabled={disabled}
          value={formData[key] ? formData[key].value : ''}
          inputType="tel"
          onClear={() => updateFormData(key, undefined)}
          icon={<Icon color={colors.gray} size={18} name="phone" />}
          validateInput={
            component.validate && component.inputMask
              ? () => validateInput(key, component.inputMask, component.validate.customMessage)
              : null
          }
        />
      );
    case 'textarea':
      return (
        <Textarea
          id={key}
          placeholder={placeholder}
          disabled={disabled}
          onChange={value => onInputChange(value, key)}
          value={getProperty(formData, [key, 'value']) || ''}
          onBlur={
            hasProperty(component, 'validate.pattern')
              ? () =>
                  validateInput(
                    key,
                    component.validate.pattern,
                    getProperty(component, 'validate.customMessage', '')
                  )
              : null
          }
        />
      );
    default:
      return null;
  }
}

Texts.propTypes = propTypes;
Texts.defaultProps = defaultProps;

export default Texts;
