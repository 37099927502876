import { gql } from '@apollo/client';
import { Rate, File, Note, MatchReport } from 'queries/Fragments';

const GET_CANDIDATE_BY_ID = gql`
  query getCandidateById($id: ID!) {
    candidate(id: $id) {
      id
      createdAt
      updatedAt
      lastUpdate
      availableFrom
      status
      deleted
      isInPool
      expectedRate {
        ...Rate
      }
      reports(filter: { isCandidateCv: { equals: true } }, sort: { createdAt: DESC }, first: 1) {
        items {
          id
          createdAt
          updatedAt
          shCandidateId
          cvReadabilityLevel {
            id
            level
          }
          shCandidateId
          cv {
            ...File
          }
          matchReports(sort: { createdAt: DESC }, first: 1) {
            items {
              ...MatchReport
            }
          }
        }
      }
      profile {
        id
        profession
        description
        fullName
        email
        phone
        experienceLevel {
          id
          shLevel
          yearsOfExperience
        }
        location {
          id
          city
          country {
            id
            name
          }
        }
        clearance {
          id
          validFrom
          validUntil
          type {
            id
            name
            shClearance
          }
          notes {
            items {
              ...Note
            }
          }
        }
        willingToRelocate
      }
    }
  }
  ${Note}
  ${Rate}
  ${File}
  ${MatchReport}
`;

export default GET_CANDIDATE_BY_ID;
