import React, { useState, useContext } from 'react';
// libraries
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import getProperty from 'lodash/get';
import hasProperty from 'lodash/has';
// hooks
import useAuth from 'hooks/useAuth';
// context
import ToastContext from 'context/ToastContext';
// components
import Textarea from 'components/atoms/Textarea';
import Field from 'components/molecules/Field';
import Modal from 'components/molecules/ModalWindow';
// helpers
import { updateRejectionReason } from '../helpers';
import { withdrawSubmission } from './helpers';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  status: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  isProposal: PropTypes.bool.isRequired,
  disqualificationNotes: PropTypes.object,
};

const defaultProps = {
  disqualificationNotes: null,
};

const WithdrawSubmission = ({
  isOpen,
  id,
  name,
  status,
  onClose,
  isProposal,
  disqualificationNotes,
}) => {
  const { id: userId } = useAuth();
  const { addToast } = useContext(ToastContext);
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState(getProperty(disqualificationNotes, 'text', ''));
  const [hasError, setError] = useState(false);

  const handleChange = value => {
    setReason(value);
  };

  const handleSubmit = async () => {
    if (!reason) {
      setError(true);
      return;
    }

    try {
      setLoading(true);

      if (hasProperty(disqualificationNotes, 'note')) {
        await updateRejectionReason(disqualificationNotes.id, reason);
        addToast.success(t('candidateRejectionNoteUpdateSuccessToast'));
      } else {
        await withdrawSubmission({ submissionId: id, status, reason, userId, isProposal });
        addToast.success(t('candidateWithdrawalSuccessToast'));
      }
      onClose();
    } catch (error) {
      addToast.error(t('errorPlaceholderText'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} loading={loading}>
      <Modal.Header>
        <Trans i18nKey="candidateWithdrawal">Submission {{ name }} withdrawal</Trans>
      </Modal.Header>
      <Modal.Body>
        <Field
          required
          label={t('withdrawReasonLabelKey')}
          error={hasError}
          errorMessage={t('thisFieldIsRequired')}
        >
          <Textarea value={reason} onChange={handleChange} rows={8} />
        </Field>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Buttons
          primaryLabel={t('withdraw')}
          primaryAction={handleSubmit}
          secondaryAction={onClose}
        />
      </Modal.Footer>
    </Modal>
  );
};

WithdrawSubmission.propTypes = propTypes;
WithdrawSubmission.defaultProps = defaultProps;

export default WithdrawSubmission;
