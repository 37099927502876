import React from 'react';
// libraries
import PropTypes from 'prop-types';
// styles
import styles from './detailPage.module.scss';

const propTypes = {
  children: PropTypes.any.isRequired,
};

const DetailPage = ({ children }) => {
  return <div className={styles.container}>{children}</div>;
};

DetailPage.propTypes = propTypes;

export default DetailPage;
