/* eslint-disable import/order */
import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// helpers
import { getSubmittedSubmissionsCount, getReceivedSubmissionsCount } from 'helpers/Submission';
import { getTimeYear, getPassedRelativeTime } from 'helpers/RelativeTimeFormatter';
import { getCurrencyText } from 'helpers/NameMapping';
// constants
import { COLOR } from 'constants/colors';
import {
  STAFF_ROLE,
  MANAGER_ROLE,
  SUPPLIER_ROLE,
  RATES_NTE_RATE,
  RATES_TARGET_RATE,
} from 'constants';
// hooks
import useAuth from 'hooks/useAuth';
// components
import Chip from 'components/atoms/NewChip';
import Icon from 'components/atoms/Icon';
import Tooltip from 'components/atoms/Tooltip';
import TruncatedText from 'components/atoms/TruncatedText';
import SubmissionsCount from 'components/molecules/SubmissionsCount';
import PositionCallToActions from 'components/organisms/PositionCallToActions';
import PositionSettings from 'components/organisms/PositionSettings';
import PositionStatusFlow from 'components/molecules/PositionStatusFlow';
import PositionTargetRateInfo from 'components/molecules/PositionTargetRateInfo';
import PublishStatus from 'components/organisms/PublishStatus';
import Rate from './Rate';
// helpers
import { preventDefault } from 'helpers/HtmlDOM';
import { getLatestStartDate, dueToday } from './helpers';
// styles
import colors from 'dependencies/materialStyles/Colors';
import styles from './positionTable.module.scss';

const propTypes = {
  position: PropTypes.object.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const PositionRow = ({ position, className }) => {
  const { role, supplierId } = useAuth();
  const { t } = useTranslation();

  const submissions = position.getSubmissions();
  const color = position.getColor(role, supplierId);
  const receivedSubmissions = getReceivedSubmissionsCount(submissions, role, supplierId);
  const submittedSubmissions = getSubmittedSubmissionsCount(submissions, role, supplierId);
  const status = position.getMainStatus(role, supplierId);
  const targetRate = position.getRate(RATES_TARGET_RATE);
  const nteRate = position.getRate(RATES_NTE_RATE);
  const dueDate = role === SUPPLIER_ROLE ? position.supplierDueDate : position.dueDate;
  const { start, hours } = getLatestStartDate(role, supplierId, position);
  const levelOfEffort = `${hours} ${t('hrsAbb')}`;
  const currency = getCurrencyText(position.project.currency);

  return (
    <Link to={`/position/${position.id}`}>
      <div
        className={classNames(styles.grid, styles.row, className, {
          [styles.partner]: role === SUPPLIER_ROLE,
          [styles.matchManager]: role === STAFF_ROLE,
          [styles.projectManager]: role === MANAGER_ROLE,
        })}
      >
        {/* Position + RFQ + project + client */}
        <div className={styles.columnCell}>
          <div className={styles.cell}>
            <div className={styles.rfq}>{position.rfqLabel}</div>
            <Tooltip title={`${position.project.name} - ${position.client.name}`}>
              <div className="overflowHidden">
                <Chip size="sm" color={COLOR.GRAY} className={styles.projectChip}>
                  <TruncatedText>{position.project.shortName}</TruncatedText>
                </Chip>
              </div>
            </Tooltip>
          </div>
          <div>
            <TruncatedText>{position.title}</TruncatedText>
          </div>
          <PublishStatus position={position} tooltipPlacement="right" />
        </div>

        {/* Status */}
        <div className={styles.columnCell}>
          <PositionStatusFlow position={position} />
          <div className={styles.statusDate}>{getPassedRelativeTime(status.start)}</div>
        </div>

        {/* Due date */}
        <div className={classNames(styles.columnCell, styles.right)}>
          <div className="bolder">{getTimeYear(dueDate)}</div>
          <div
            className={classNames('m-t-2', {
              bolder: dueToday(dueDate),
              'text-red': dueToday(dueDate),
            })}
          >
            {getPassedRelativeTime(dueDate)}
          </div>
        </div>

        {/* Target rate */}
        {[STAFF_ROLE, SUPPLIER_ROLE].includes(role) && (
          <div className={classNames(styles.cell, styles.right)}>
            {position.showTargetRatesToPartners && targetRate ? (
              <Rate value={targetRate.value} currency={targetRate.currency} />
            ) : (
              <PositionTargetRateInfo
                positionId={position.id}
                hasBeenPublishedBefore={position.hasBeenPublishedBefore()}
                isPublished={position.isPublished(role, supplierId)}
                currency={currency}
                color={colors.gray6}
              />
            )}
          </div>
        )}

        {/* NTE rate */}
        {[STAFF_ROLE, MANAGER_ROLE].includes(role) && (
          <div className={classNames(styles.cell, styles.right)}>
            {nteRate && <Rate value={nteRate.value} currency={nteRate.currency} />}
          </div>
        )}

        {/* Location */}
        <div className={styles.cell}>
          {position.isOnsite() && position.location.city && (
            <div className={styles.locationIcon}>
              <Icon size={15} name="location" color={COLOR.GRAY} />
            </div>
          )}
          <TruncatedText>
            {position.isOffsite() ? (
              <Tooltip title={position.delivery.description}>
                <i>{position.delivery.label}</i>
              </Tooltip>
            ) : (
              position.location.city
            )}
          </TruncatedText>
        </div>

        {/* Submissions count */}
        <div className={classNames(styles.cell, styles.right)}>
          {role === STAFF_ROLE ? (
            <SubmissionsCount
              role={role}
              received={receivedSubmissions}
              submitted={submittedSubmissions}
              allowed={position.maximumCandidatesAllowed}
            >
              <div className="verticalCenter">
                <div className="m-r-5">
                  <Chip size="sm" color={color}>
                    {`${receivedSubmissions} CV${receivedSubmissions > 1 ? 's' : ''}`}
                  </Chip>
                </div>
                <div className="f-s-14 p-t-3">{`${submittedSubmissions}/${position.maximumCandidatesAllowed}`}</div>
              </div>
            </SubmissionsCount>
          ) : (
            <SubmissionsCount
              role={role}
              received={receivedSubmissions}
              submitted={submittedSubmissions}
              allowed={position.maximumCandidatesAllowed}
            />
          )}
        </div>

        {/* Start data + hours */}
        {role !== STAFF_ROLE && (
          <div className={classNames(styles.columnCell, styles.right)}>
            <div className="bolder">{getTimeYear(start)}</div>
            {hours && <div className="m-t-2">{levelOfEffort}</div>}
          </div>
        )}

        {/* Resolution */}
        <div className={classNames(styles.cell, styles.resolution)}>
          <TruncatedText>
            <PositionCallToActions position={position} iconAndLabels />
          </TruncatedText>
        </div>

        {/* Settings */}
        <div role="presentation" className={styles.settings} onClick={preventDefault}>
          <PositionSettings position={position} onlySecondaryActions />
        </div>
      </div>
    </Link>
  );
};

PositionRow.propTypes = propTypes;
PositionRow.defaultProps = defaultProps;

export default PositionRow;
