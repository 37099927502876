import React from 'react';
// libraries
import ContentLoader from 'react-content-loader';
import times from 'lodash/times';

const TableLoader = () => {
  const ROW_HEIGHT = 70;

  return (
    <ContentLoader
      height={700}
      width={1400}
      speed={1}
      primaryColor="#f1f1f1"
      secondaryColor="#e6e6e6"
    >
      <rect x="0" y="0" width="1400" height="1" />
      <rect x="0" y="699" width="1400" height="1" />
      <rect x="0" y="0" width="1" height="700" />
      <rect x="1399" y="0" width="1" height="700" />
      <rect x="25" y="30" width="40" height="8" />
      <rect x="75" y="30" width="90" height="8" />
      <rect x="280" y="30" width="80" height="8" />
      <rect x="450" y="30" width="80" height="8" />
      <rect x="650" y="30" width="100" height="8" />
      <rect x="870" y="30" width="65" height="8" />
      <rect x="1000" y="30" width="65" height="8" />
      <rect x="1130" y="30" width="80" height="8" />
      <rect x="1280" y="30" width="80" height="8" />
      {times(10, index => (
        <React.Fragment key={index}>
          <circle cx="40" cy={(index + 1) * ROW_HEIGHT + 30} r="20" />
          <rect x="75" y={(index + 1) * ROW_HEIGHT + 20} rx="3" ry="3" width="120" height="8" />
          <rect x="75" y={(index + 1) * ROW_HEIGHT + 35} rx="3" ry="3" width="100" height="8" />
          <rect x="280" y={(index + 1) * ROW_HEIGHT + 28} rx="3" ry="3" width="90" height="8" />
          <rect x="450" y={(index + 1) * ROW_HEIGHT + 20} rx="3" ry="3" width="90" height="8" />
          <rect x="450" y={(index + 1) * ROW_HEIGHT + 35} rx="3" ry="3" width="110" height="8" />
          <rect x="650" y={(index + 1) * ROW_HEIGHT + 28} rx="3" ry="3" width="100" height="8" />
          <rect x="870" y={(index + 1) * ROW_HEIGHT + 28} rx="3" ry="3" width="50" height="8" />
          <rect x="1000" y={(index + 1) * ROW_HEIGHT + 28} rx="3" ry="3" width="50" height="8" />
          <rect x="1130" y={(index + 1) * ROW_HEIGHT + 20} rx="3" ry="3" width="100" height="8" />
          <rect x="1130" y={(index + 1) * ROW_HEIGHT + 35} rx="3" ry="3" width="110" height="8" />
          <rect x="1270" y={(index + 1) * ROW_HEIGHT + 20} rx="3" ry="3" width="110" height="8" />
          <rect x="1280" y={(index + 1) * ROW_HEIGHT + 35} rx="3" ry="3" width="100" height="8" />
        </React.Fragment>
      ))}
    </ContentLoader>
  );
};

export default TableLoader;
