export const POSITION_FILTERS = {
  DUE_DATE: 'dueOn',
  PROJECT: 'project',
  LOCATION: 'location',
  STATUS: 'status',
  SUBMISSION: 'submission',
  PUBLISH_STATUS: 'publishStatus',
  CONTRACT_TYPE: 'contractType',
  DELIVERY_OPTION: 'deliveryOption',
  CLEARANCE: 'clearance',
};

export const CONTRACT_TYPE_FILTERS = {
  TIME_AND_MATERIAL: 'TIME_AND_MATERIALS',
  FIXED_PRICE: 'FIXED_PRICE',
};

export const DELIVERY_OPTION_FILTERS = {
  ONSITE: 'ONSITE',
  OFFSITE: 'OFFSITE',
};

export const PUBLISH_STATUS_FILTERS = {
  PUBLISHED: 'PUBLISHED',
  UNPUBLISHED: 'UNPUBLISHED',
};

export const DUE_DATE_FILTERS = {
  DUE_TODAY: 'DUE_TODAY',
  DUE_TOMORROW: 'DUE_TOMORROW',
  DUE_NEXT_7_DAYS: 'DUE_NEXT_7_DAYS',
  EXPIRED: 'EXPIRED',
  NOT_EXPIRED: 'NOT_EXPIRED',
};

export const POSITION_SUBMISSION_FILTERS = {
  NO_CANDIDATE_RECEIVED: 'NO_CANDIDATE_RECEIVED',
  CANDIDATE_RECEIVED: 'CANDIDATE_RECEIVED',
  NO_CANDIDATE_SUBMITTED: 'NO_CANDIDATE_SUBMITTED',
  CANDIDATE_SUBMITTED: 'CANDIDATE_SUBMITTED',
};

export const POSITION_STATUS_FILTERS = {
  RECRUITING: 'RECRUITING',
  SELECTION: 'SELECTION',
  PENDING_SELECTION: 'PENDING_SELECTION',
  OFFER: 'OFFER',
  ONBOARDING: 'ONBOARDING',
  ONBOARDING_IN_PROGRESS: 'ONBOARDING_IN_PROGRESS',
  ONBOARDING_COMPLETED: 'ONBOARDING_COMPLETED',
  ONBOARDING_CANCELLED: 'ONBOARDING_CANCELLED',
  DELIVERY: 'DELIVERY',
  CLOSED: 'CLOSED',
  NO_SUBMISSIONS: 'NO_SUBMISSIONS',
  NO_SELECTION: 'NO_SELECTION',
  CANCELLED: 'CANCELLED',
};

export const SUBMISSION_FILTERS = {
  DUE_DATE: 'dueOn',
  STATUS: 'status',
  PARTNER: 'partner',
  PROJECT: 'project',
  LOCATION: 'location',
};

export const SUBMISSION_STATUS_FILTERS = {
  NEW: 'NEW',
  SUBMITTED_TO_CLIENT: 'SUBMITTED_TO_CLIENT',
  NOT_SUBMITTED_TO_CLIENT: 'NOT_SUBMITTED_TO_CLIENT',
  NOT_SELECTED_BY_CLIENT: 'NOT_SELECTED_BY_CLIENT',
  WITHDRAWN: 'WITHDRAWN',
  OFFER: 'OFFER',
  ONBOARDING: 'ONBOARDING',
  PENDING_START_DATE: 'PENDING_START_DATE',
  CONFIRMED_START_DATE: 'CONFIRMED_START_DATE',
  PENDING_FORMS: 'PENDING_FORMS',
  PENDING_PO: 'PENDING_PO',
  ONBOARDING_COMPLETED: 'ONBOARDING_COMPLETED',
  ONBOARDING_CANCELLED: 'ONBOARDING_CANCELLED',
  DELIVERY: 'DELIVERY',
};

export const IMPORT_FILTERS = {
  DUE_DATE: 'dueOn',
};

export const NOT_PROVIDED_CLEARANCE = 'notProvided';

export const CANDIDATE_STATUS_FILTERS = {
  AVAILABLE: 'AVAILABLE',
  UNAVAILABLE: 'UNAVAILABLE',
  AVAILABLE_SOON: 'AVAILABLE_SOON',
  AVAILABLE_LATER: 'AVAILABLE_LATER',
};

export const CANDIDATE_LAST_UPDATE_FILTERS = {
  LAST_30DAYS: 'LAST_30DAYS',
  LAST_3MONTHS: 'LAST_3MONTHS',
  LAST_6MONTHS: 'LAST_6MONTHS',
  LAST_YEAR: 'LAST_YEAR',
  MORE_THAN_YEAR: 'MORE_THAN_YEAR',
};

export const CANDIDATE_RELOCATE_FILTERS = {
  YES: 'YES',
  NO: 'NO',
  REMOTE_ONLY: 'REMOTE_ONLY',
  NO_PREFERENCE: 'NO_PREFERENCE',
};

export const CANDIDATE_FILTERS = {
  LOCATION: 'location',
  STATUS: 'status',
  LAST_UPDATE: 'lastUpdate',
  CLEARANCE: 'clearance',
  RELOCATE: 'relocate',
};
