import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import getProperty from 'lodash/get';
// helpers
import { getYear } from 'helpers/RelativeTimeFormatter';
import { getCurrencyText } from 'helpers/NameMapping';
// constant
import { RATES_NTE_RATE } from 'constants';
// components
import CollapsibleContent from 'components/molecules/CollapsibleContent';
import RatesInput from 'components/molecules/RatesInput';
import Section from 'components/molecules/FieldSection';
import RatesSimulation from 'components/organisms/RatesSimulation/Position';
// styles import
import styles from './publishPosition.module.scss';

const propTypes = {
  targetRates: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      year: PropTypes.number.isRequired,
      error: PropTypes.bool,
      highlighted: PropTypes.bool,
    })
  ),
  initialTargetRates: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      year: PropTypes.number.isRequired,
    })
  ),
  onChange: PropTypes.func.isRequired,
  position: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  readOnly: PropTypes.bool,
};

const defaultProps = {
  targetRates: [],
  initialTargetRates: [],
  disabled: false,
  error: false,
  readOnly: false,
};

const TargetRates = ({
  targetRates,
  initialTargetRates,
  onChange,
  position,
  disabled,
  error,
  readOnly,
}) => {
  const { t } = useTranslation();
  const { project } = position;
  const nteRates = position.getRates(RATES_NTE_RATE);
  const enableManYearDiscount = position.getConfig('enableManYearDiscount', false);
  const startYear = getYear(getProperty(nteRates, '0.startDate'));
  const staticRates = [
    {
      label: t('nteRates'),
      rates: nteRates.map(rate => rate.value),
    },
  ];

  const getTargetRates = () => {
    return targetRates.map(rate => {
      const initialTargetRate = initialTargetRates.find(({ year }) => year === rate.year);
      const hasChanged = initialTargetRate ? initialTargetRate.value !== rate.value : false;

      return {
        ...rate,
        error: error ? rate.error : false,
        highlighted: hasChanged,
      };
    });
  };

  const handleChange = newTargeRates => {
    onChange(
      newTargeRates.map((rate, index) => {
        const rateHasError = !rate.value || rate.value > getProperty(nteRates, [index, 'value'], 0);

        return {
          ...rate,
          error: rateHasError,
        };
      })
    );
  };

  const onApplyRates = newTargeRates => {
    onChange(
      targetRates.map((rate, index) => {
        const rateHasError =
          !newTargeRates[index] ||
          newTargeRates[index] > getProperty(nteRates, [index, 'value'], 0);

        return {
          ...rate,
          value: newTargeRates[index],
          error: rateHasError,
        };
      })
    );
  };

  const getErrorMessage = () => {
    // if some rate is not filled
    if (targetRates.some(rate => !rate.value)) {
      return t('missingFields');
    }

    // another error is when some provided target rate is above NTE rate
    if (targetRates.some(rate => rate.error)) {
      return t('errorPositionPublishTargetRatesAboveNteRates');
    }

    return '';
  };

  const targetInitRates = targetRates || initialTargetRates;

  return (
    <Section
      title={t('targetRates')}
      description={t('publishPositionTargetRatesDescription')}
      error={error}
    >
      <RatesInput
        label={t('targetRates')}
        rates={getTargetRates()}
        onChange={handleChange}
        staticRates={staticRates}
        currency={getCurrencyText(project.currency)}
        startYear={parseInt(startYear)}
        numberOfYears={nteRates.length}
        disabled={disabled}
        empty={disabled}
        readOnly={readOnly}
        useForAllPopoverStyle={error}
        hideYears={position.isFixedPrice() && nteRates.length === 1}
      />
      {error && !disabled && <div className={styles.targetRatesError}>* {getErrorMessage()}</div>}
      {disabled ? (
        <div className={styles.disabledSimulation}>
          * {t('inOrderToSeeRSTableEnableShowingTRToPartners')}
        </div>
      ) : (
        <div className="border m-t-20">
          <CollapsibleContent
            collapsed
            contentClassName="m-t-0"
            headerClassName={styles.rateSimulationHeader}
            header={
              <React.Fragment>
                <div className="f-s-15 bold">{t('ratesSimulation')}</div>
                <div className="m-t-5">{t('rateSimulationDescription')}</div>
              </React.Fragment>
            }
          >
            <RatesSimulation
              hours={position.duration.hours}
              currency={project.currency}
              years={nteRates.map(rate => getYear(rate.startDate))}
              nteRates={nteRates.map(rate => rate.value)}
              targetRates={targetInitRates.map(rate => rate.value)}
              onApplyRates={onApplyRates}
              isFixedPrice={position.isFixedPrice()}
              enableManYearDiscount={enableManYearDiscount}
            />
          </CollapsibleContent>
        </div>
      )}
    </Section>
  );
};

TargetRates.propTypes = propTypes;
TargetRates.defaultProps = defaultProps;

export default TargetRates;
