import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// hooks
import useAuth from 'hooks/useAuth';
// helpers
import parsePosition from 'helpers/PositionParser';
import parseSubmission from 'helpers/SubmissionParser';
// constants
import { STAFF_ROLE, SUPPLIER_ROLE, MANAGER_ROLE } from 'constants';
// components
import SubmissionRow from './SubmissionRow';
// styles
import styles from './submissionTable.module.scss';

const propTypes = {
  submissions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const SubmissionTable = ({ submissions }) => {
  const { t } = useTranslation();
  const { role } = useAuth();

  if (!submissions.length) {
    return null;
  }

  const getRateLabel = () => {
    if (role === SUPPLIER_ROLE) {
      return t('submissionRate');
    }

    if (role === STAFF_ROLE) {
      return t('partnerRate');
    }

    return t('rate');
  };

  return (
    <div className={styles.table}>
      <div
        className={classNames(styles.grid, {
          [styles.partner]: role === SUPPLIER_ROLE,
          [styles.matchManager]: role === STAFF_ROLE,
          [styles.projectManager]: role === MANAGER_ROLE,
        })}
      >
        <div className={classNames(styles.head, styles.alignCenter)}>{t('score')}</div>
        <div className={styles.head}>{t('candidate')}</div>
        {role === STAFF_ROLE && <div className={styles.head}>{t('partner')}</div>}
        {role === MANAGER_ROLE && <div className={styles.head}>{t('company')}</div>}
        <div className={styles.head}>{t('position')}</div>
        <div className={styles.head}>{t('positionLocation')}</div>
        <div className={classNames(styles.head, styles.alignRight)}>{getRateLabel()}</div>
        {role === STAFF_ROLE && (
          <div className={classNames(styles.head, styles.alignRight)}>{t('nteRate')}</div>
        )}
        <div className={classNames(styles.head, styles.alignRight)}>
          {role === SUPPLIER_ROLE ? t('cvSubmitted') : t('cvReceived')}
        </div>
        <div className={classNames(styles.head, styles.alignRight)}>{t('start&endDate')}</div>
      </div>

      {submissions.map((submission, index) => {
        const position = parsePosition(submission.position);

        return (
          <SubmissionRow
            key={submission.id}
            submission={parseSubmission(submission)}
            position={position}
            className={submissions.length - 1 === index ? 'border-bottom' : ''}
          />
        );
      })}
    </div>
  );
};

SubmissionTable.propTypes = propTypes;

export default SubmissionTable;
