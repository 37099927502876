import React from 'react';
import ContentLoader from 'react-content-loader';

const NotificationEventsLoader = () => (
  <ContentLoader
    speed={2}
    width={1000}
    height={300}
    viewBox="0 0 1000 300"
    primaryColor="#f1f1f1"
    secondaryColor="#e6e6e6"
  >
    <rect x="0" y="5" width="100" height="14" />
    <rect x="0" y="46" width="100" height="14" />
    <rect x="0" y="89" width="100" height="14" />
    <rect x="0" y="132" width="100" height="14" />
    <rect x="130" y="3" width="600" height="400" />
  </ContentLoader>
);

export default NotificationEventsLoader;
