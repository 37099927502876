import { ApolloClient, InMemoryCache } from '@apollo/client';
import links from './links';

const client = new ApolloClient({
  link: links,
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      errorPolicy: 'none',
      fetchPolicy: 'network-only',
    },
    mutate: {
      errorPolicy: 'none',
    },
    watchQuery: {
      fetchPolicy: 'network-only',
    },
  },
});

export default client;
