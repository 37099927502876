import React from 'react';
// libraries
import LinearProgress from '@material-ui/core/LinearProgress';
import PropTypes from 'prop-types';
import bytes from 'bytes';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
// components
import FileIcon from 'components/atoms/FileIcon';
import Icon from 'components/atoms/Icon';
// styles
import colors from 'dependencies/materialStyles/Colors';
import styles from './fileUpload.module.scss';

const propTypes = {
  filename: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  size: PropTypes.number,
  link: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  editable: PropTypes.bool,
};

const defaultProps = {
  link: '',
  size: 0,
  loading: false,
  disabled: false,
  editable: false,
};

const Preview = ({ filename, size, link, onRemove, loading, disabled, editable, onEdit }) => {
  const { t } = useTranslation();

  const renderFileInfo = () => {
    const fileInfo = (
      <React.Fragment>
        <FileIcon size={24} fileExtension={filename.split('.').pop()} disabled={disabled} />
        <div className={styles.fileInfo}>
          <div className={styles.filename}>{filename}</div>
          {(loading || !!size) && (
            <div className={styles.size}>
              {loading && t('uploadingFile')}
              {!loading && !!size && bytes(size, { unitSeparator: ' ', decimalPlaces: 1 })}
            </div>
          )}
        </div>
      </React.Fragment>
    );

    if (link) {
      if (disabled || loading) {
        return <span className={classNames(styles.link, styles.notClickable)}>{fileInfo}</span>;
      }

      return (
        <a
          href={link}
          className={classNames(styles.link, styles.clickable)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {fileInfo}
        </a>
      );
    }

    return fileInfo;
  };

  return (
    <React.Fragment>
      <div className={classNames(styles.file, { [styles.inactive]: disabled })}>
        <div className={styles.preview}>{renderFileInfo()}</div>
        {!disabled && editable && !loading && (
          <span className="cp" onClick={onEdit} role="presentation">
            <Icon color={colors.gray} size={20} name="edit" />
          </span>
        )}
        {!disabled && !editable && !loading && (
          <span className="cp" onClick={onRemove} role="presentation">
            <Icon color={colors.gray} size={20} name="close" />
          </span>
        )}
      </div>
      {!disabled && loading && (
        <LinearProgress
          classes={{
            colorPrimary: styles.progressBarColor,
            barColorPrimary: styles.progressBarSecondaryColor,
          }}
        />
      )}
    </React.Fragment>
  );
};

Preview.propTypes = propTypes;
Preview.defaultProps = defaultProps;

export default Preview;
