/* eslint-disable import/order */
import React, { useState, useEffect, useRef } from 'react';
// libraries
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
// constants
import { SUPPLIER_DIRECT, SUPPLIER_IBM, SUPPLIER_NOT_DIRECT } from 'constants';
import { RATES_FORMULA_LINK } from 'constants/box';
// helpers
import {
  calculateManYearDiscount,
  calculateCandidateGPsFromSellPrice,
} from 'helpers/RatesCalculation';
import { isDirectSupplier as isDirectSupplierHelper } from 'helpers/Submission';
import { initCandidateSellPrices } from '../helpers';
// components
import SimulationLegend from 'components/molecules/SimulationLegend';
import Allowance from '../Allowance';
import Rates from './Rates';
// styles
import styles from '../ratesSimulation.module.scss';

const propTypes = {
  hours: PropTypes.number.isRequired,
  partnerCategory: PropTypes.oneOf([SUPPLIER_DIRECT, SUPPLIER_IBM, SUPPLIER_NOT_DIRECT]).isRequired,
  includeCSAFee: PropTypes.bool.isRequired,
  currency: PropTypes.string.isRequired,
  years: PropTypes.arrayOf(PropTypes.number).isRequired,
  nteRates: PropTypes.arrayOf(PropTypes.number).isRequired,
  targetRates: PropTypes.arrayOf(PropTypes.number).isRequired,
  partnerRates: PropTypes.arrayOf(PropTypes.number).isRequired,
  onApplyRates: PropTypes.func.isRequired,
  newRates: PropTypes.arrayOf(PropTypes.number).isRequired,
  enableManYearDiscount: PropTypes.bool,
};

const defaultProps = {
  enableManYearDiscount: false,
};

const RatesSimulation = ({
  hours,
  partnerCategory,
  includeCSAFee,
  currency,
  years,
  nteRates,
  targetRates,
  partnerRates,
  onApplyRates,
  newRates,
  enableManYearDiscount,
}) => {
  const manYearDiscount = enableManYearDiscount ? calculateManYearDiscount(hours) : 0;
  const isDirectSupplier = isDirectSupplierHelper(partnerCategory);

  const [localPartnerRates, setPartnerRates] = useState(targetRates);
  const initialSellPrices = useRef(
    initCandidateSellPrices(localPartnerRates, manYearDiscount, isDirectSupplier, includeCSAFee)
  );
  const [ibmGPs, setIbmGPs] = useState(
    calculateCandidateGPsFromSellPrice(
      localPartnerRates,
      initialSellPrices.current,
      manYearDiscount,
      isDirectSupplier,
      includeCSAFee
    )
  );
  const [sellPrices, setSellPrices] = useState(initialSellPrices.current);

  useEffect(() => {
    if (newRates && newRates.length > 0) {
      setPartnerRates(newRates);
      const newSellPrices = initCandidateSellPrices(
        newRates,
        manYearDiscount,
        isDirectSupplier,
        includeCSAFee
      );
      setSellPrices(newSellPrices);
      setIbmGPs(
        calculateCandidateGPsFromSellPrice(
          newRates,
          newSellPrices,
          manYearDiscount,
          isDirectSupplier,
          includeCSAFee
        )
      );
    }
  }, [newRates]);

  const ratesHasChanged = () => {
    if (!isEqual(targetRates, localPartnerRates)) return true;
    if (!isEqual(initialSellPrices.current, sellPrices)) return true;

    return false;
  };

  const resetChanges = () => {
    setPartnerRates(targetRates);
    setSellPrices(initialSellPrices.current);
    setIbmGPs(
      calculateCandidateGPsFromSellPrice(
        targetRates,
        initialSellPrices.current,
        manYearDiscount,
        isDirectSupplier,
        includeCSAFee
      )
    );
  };

  return (
    <div>
      <Allowance
        manYearDiscount={manYearDiscount}
        hours={hours}
        partnerCategory={partnerCategory}
        includeCSAFee={includeCSAFee}
        onApplyRates={() => onApplyRates(localPartnerRates)}
        resetChanges={resetChanges}
        isDiscardActive={ratesHasChanged()}
        className={styles.revisionAllowance}
      />
      <Rates
        nteRates={nteRates}
        originPartnerRates={partnerRates}
        partnerRates={localPartnerRates}
        sellPrices={sellPrices}
        setSellPrices={setSellPrices}
        ibmGPs={ibmGPs}
        setIbmGPs={setIbmGPs}
        initialSellPrices={initialSellPrices.current}
        onPartnerRatesChange={setPartnerRates}
        partnerCategory={partnerCategory}
        includeCSAFee={includeCSAFee}
        currency={currency}
        manYearDiscount={manYearDiscount}
        years={years}
      />
      <SimulationLegend link={RATES_FORMULA_LINK} className="m-b-24 space-v-16" />
    </div>
  );
};

RatesSimulation.propTypes = propTypes;
RatesSimulation.defaultProps = defaultProps;

export default RatesSimulation;
