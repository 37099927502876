import colors from 'dependencies/materialStyles/Colors';
import { createMuiTheme } from '@material-ui/core';

export const switchStyle = createMuiTheme({
  overrides: {
    MuiSwitch: {
      switchBase: {
        height: 'auto',
        '&$disabled + $bar': {
          cursor: 'not-allowed',
        },
      },
      disabled: {},
      colorSecondary: {
        '&$checked': {
          color: colors.blue,
        },
        '&$checked + $bar': {
          backgroundColor: `${colors.blue} !important`,
        },
      },
    },
  },
});
