import { RATES_SUPPLIER_RATE } from 'constants';

export const ATTESTATION_HISTORY_MODAL = 'ATTESTATION';
export const CV_HISTORY_MODAL = 'CV';
export const PARTNER_RATE_HISTORY_MODAL = RATES_SUPPLIER_RATE;

export const FILES_HISTORY = [ATTESTATION_HISTORY_MODAL, CV_HISTORY_MODAL];
export const RATES_HISTORY = [PARTNER_RATE_HISTORY_MODAL];
export const SUPPORTED_HISTORY_TYPES = [
  ATTESTATION_HISTORY_MODAL,
  CV_HISTORY_MODAL,
  PARTNER_RATE_HISTORY_MODAL,
];
