import React from 'react';
// Libraries
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
// helpers
import { getItemStyle } from './Helpers';

const propTypes = {
  items: PropTypes.array,
  onDragEnd: PropTypes.func,
  className: PropTypes.string,
};

const defaultProps = {
  items: [],
  onDragEnd: () => {},
  className: '',
};

const DragDrop = props => {
  const { items, onDragEnd, className } = props;

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {provided => (
          <div ref={provided.innerRef} className={className}>
            {items.map((item, index) => (
              <Draggable key={shortid.generate()} draggableId={item.id} index={index}>
                {(providedDrag, snapshotDrag) => (
                  <div
                    ref={providedDrag.innerRef}
                    {...providedDrag.draggableProps}
                    {...providedDrag.dragHandleProps}
                    style={getItemStyle(snapshotDrag.isDragging, providedDrag.draggableProps.style)}
                  >
                    {item.content}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

DragDrop.propTypes = propTypes;
DragDrop.defaultProps = defaultProps;

export default DragDrop;
