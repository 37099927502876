import React, { useContext, useMemo } from 'react';
import times from 'lodash/times';
import { TextSkeleton } from '@components/ContentLoader';
import TableContext, { TableContextProps } from './TableContext';
import TableBody from './TableBody';
import TableRow from './TableRow';
import TableCell from './TableCell';

interface TableSkeletonBodyProps {
  columns: number;
  rows?: number;
}

const TableSkeletonBody = ({ columns, rows = 5 }: TableSkeletonBodyProps) => {
  const parentContext = useContext(TableContext);
  const context: TableContextProps = useMemo(
    () => ({
      ...parentContext,
      hoverable: false,
    }),
    [parentContext]
  );

  return (
    <TableContext.Provider value={context}>
      <TableBody>
        {times(rows, (rowIndex) => (
          <TableRow key={rowIndex}>
            {times(columns, (columnIndex) => (
              <TableCell key={columnIndex}>
                <TextSkeleton width="65%" />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </TableContext.Provider>
  );
};

export default TableSkeletonBody;
