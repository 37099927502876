import i18n from 'i18next';
import isEmpty from 'lodash/isEmpty';
// helpers
import { getRevisionTypeText, getCurrencyText } from 'helpers/NameMapping';

/**
 * @param {object[]} types
 * @param {object} [rates]
 * @returns {string}
 */
export const buildRevisionHtmlPartForEmailBody = (types, rateParams = {}) => {
  let html = `<ul>
    ${types
      .map(
        ({ type, reason }) => `<li><strong>${getRevisionTypeText(type)}</strong> - ${reason}</li>`
      )
      .join('')}
  </ul>`;

  if (!isEmpty(rateParams)) {
    const { candidateName, currency, years, targetRates, currentRates, proposedRates } = rateParams;
    const currencyText = getCurrencyText(currency);

    html += `<table>
      <thead>
        <tr>
          <th rowspan="2">${candidateName}</th>
          <th colspan="${years.length}">${i18n.t('rates')} (${currencyText})</th>
        </tr>
        <tr>
          ${years.map(year => `<th>${year}</th>`).join('')}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>${i18n.t('proposedRates')}</td>
          ${proposedRates.map(rate => `<td>${rate} ${currencyText}</td>`).join('')}
        </tr>
        <tr>
          <td>${i18n.t('currentRates')}</td>
          ${currentRates.map(rate => `<td>${rate} ${currencyText}</td>`).join('')}
        </tr>
        <tr>
          <td>${i18n.t('targetRates')}</td>
          ${targetRates.map(rate => `<td>${rate} ${currencyText}</td>`).join('')}
        </tr>
      </tbody>
    </table>`;
  }

  return html;
};

/**
 * @param {string[]} types
 * @returns {string}
 */
export const buildTypesMessagePartForEmailSubject = types => {
  if (!types.length) return '';
  if (types.length === 1) return getRevisionTypeText(types[0]);
  if (types.length === 2) {
    return `${getRevisionTypeText(types[0])} and ${getRevisionTypeText(types[1])}`;
  }

  const clonedTypes = [...types];
  const lastType = clonedTypes.pop();

  return `${clonedTypes.map(getRevisionTypeText).join(', ')} ${i18n.t('and')} ${getRevisionTypeText(
    lastType
  )}`;
};
