import React from 'react';
import ContentLoader from 'react-content-loader';

const LazyPageLoader = () => (
  <ContentLoader
    height={450}
    width={1600}
    speed={1}
    primaryColor="#f1f1f1"
    secondaryColor="#e6e6e6"
  >
    <rect x="50" y="45" rx="3" ry="3" width="150" height="10" />
    <rect x="50" y="90" rx="3" ry="3" width="1500" height="10" />
    <rect x="50" y="120" rx="3" ry="3" width="1500" height="10" />
    <rect x="50" y="150" rx="3" ry="3" width="1500" height="10" />
    <rect x="50" y="180" rx="3" ry="3" width="1500" height="10" />
    <rect x="50" y="210" rx="3" ry="3" width="1500" height="10" />
  </ContentLoader>
);

export default LazyPageLoader;
