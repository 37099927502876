import React from 'react';
import classNames from 'classnames';
import MuiChip from '@mui/material/Chip';
import styles from './chip.module.scss';

export interface ChipProps {
  size?: 'small' | 'medium' | 'large';
  color?: 'gray' | 'blue' | 'green' | 'yellow' | 'red';
  className?: string;
  children: React.ReactNode;
}

const Chip = ({ children, className, size = 'medium', color = 'gray' }: ChipProps) => (
  <MuiChip
    label={children}
    size={size === 'small' ? 'small' : 'medium'}
    classes={{
      root: classNames(styles.container, styles[color], className),
      label: classNames(styles.label, styles[size]),
    }}
  />
);

export default Chip;
