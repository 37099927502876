import React from 'react';
// libraries
import PropTypes from 'prop-types';
// components
import Item from './Item';
// styles
import styles from './deliverables.module.scss';

const propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      start: PropTypes.string,
      end: PropTypes.string,
      description: PropTypes.string,
    })
  ).isRequired,
};

const Deliverables = ({ items }) => {
  return (
    <React.Fragment>
      {items.map(deliverable => (
        <div className={styles.item} key={deliverable.id}>
          <Item
            name={deliverable.name}
            start={deliverable.start}
            end={deliverable.end}
            description={deliverable.description}
          />
        </div>
      ))}
    </React.Fragment>
  );
};

Deliverables.propTypes = propTypes;

export default Deliverables;
