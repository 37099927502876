/* eslint-disable import/order */
import React, { useState, useContext } from 'react';
// libraries
import { useTranslation, Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import getProperty from 'lodash/get';
// hooks
import useAuth from 'hooks/useAuth';
// context
import ToastContext from 'context/ToastContext';
// constants
import { SUBMISSION_STATUS_TYPE } from 'constants/submissionStatus';
// helpers
import { getTimeYear } from 'helpers/RelativeTimeFormatter';
import { declineStartDateByPM, confirmStartDateByPM } from '../helpers';
// components
import Loader from 'components/atoms/Loader';
import Button from 'components/atoms/Button';
import EditForm from '../EditForm';
import JobOffer from './JobOffer';
import TextContent from '../TextContent';
// forms
import { declineDateForm } from '../forms';

const propTypes = {
  confirmedStartDate: PropTypes.object,
  requiredStartDate: PropTypes.string,
  submission: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
};

const defaultProps = {
  confirmedStartDate: {},
  requiredStartDate: null,
};

const CONTENT_DECLINE_START_DATE = 'CONTENT_DECLINE_START_DATE';

const ApproveStartDate = ({ submission, position, confirmedStartDate, requiredStartDate }) => {
  const { addToast } = useContext(ToastContext);
  const { t } = useTranslation();
  const { id: userId, role } = useAuth();

  const [loading, setLoading] = useState(false);
  const [contentType, setContentType] = useState(null);

  const { rfq } = position;
  const status = submission.getStatus(role);
  const onboardingStatus = submission.getStatus(role, SUBMISSION_STATUS_TYPE.ONBOARDING_START_DATE);
  const formattedRequiredStartDate = getTimeYear(requiredStartDate);
  const formattedConfirmedDate = getTimeYear(confirmedStartDate.date);

  const onStartDateAccept = async () => {
    try {
      setLoading(true);
      await confirmStartDateByPM({
        submission,
        currentOnboardingStatusId: onboardingStatus.id,
        startDateId: confirmedStartDate.id,
        userId,
      });
    } catch (error) {
      addToast.error(t('errorWhileConfirmingCandidate'));
      setLoading(false);
      setContentType(null);
    }
  };

  const onStartDateReject = async data => {
    const rejectionReason = getProperty(data, 'rejectionReason.value');

    try {
      setLoading(true);
      await declineStartDateByPM({
        submission,
        position,
        currentStatusId: status.id,
        currentOnboardingStatusId: onboardingStatus.id,
        startDateId: confirmedStartDate.id,
        rejectionReason,
        userId,
      });
    } catch (error) {
      addToast.error(t('errorWhileDecliningCandidate'));
      setLoading(false);
      setContentType(null);
    }
  };

  const getContent = () => {
    if (contentType === CONTENT_DECLINE_START_DATE) {
      return (
        <EditForm
          form={declineDateForm}
          onCancel={() => setContentType(null)}
          onSubmit={onStartDateReject}
          onSubmitLabel={t('decline')}
          loading={loading}
        />
      );
    }

    return (
      <React.Fragment>
        <div className={`display-flex-row flexEnd ${contentType ? 'p-t-30' : 'p-t-15'}`}>
          {loading ? (
            <div>
              <Loader small />
            </div>
          ) : (
            <React.Fragment>
              <span className="p-r-12">
                <Button
                  label={t('declineStartDate')}
                  onClick={() => setContentType(CONTENT_DECLINE_START_DATE)}
                  secondary
                />
              </span>
              <Button label={t('confirmStartDate')} onClick={onStartDateAccept} />
            </React.Fragment>
          )}
        </div>
        {!loading && (
          <JobOffer submission={submission} className="w100" date={confirmedStartDate} />
        )}
      </React.Fragment>
    );
  };

  return (
    <TextContent
      title={
        <Trans i18nKey="candidateIsAvailableToStart">
          {{ candidateName: submission.getName() }} is available to start on
          {{ formattedConfirmedDate }}.
        </Trans>
      }
      label={
        <Trans i18nKey="expectedStartDateForPosition">
          Expected start date for {{ rfq }} is <b>{{ formattedRequiredStartDate }}</b>.
        </Trans>
      }
      content={getContent()}
    />
  );
};

ApproveStartDate.propTypes = propTypes;
ApproveStartDate.defaultProps = defaultProps;

export default ApproveStartDate;
