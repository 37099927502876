import React from 'react';
// libraries
import PropTypes from 'prop-types';
// hooks
import useAuth from 'hooks/useAuth';
// constants
import {
  MAIN_RECRUITING,
  MAIN_SELECTION,
  MAIN_ONBOARDING,
  MAIN_DELIVERY,
  MAIN_CLOSED,
  MAIN_CANCELLED,
} from 'constants/positionStatus';
// components
import RecruitingActions from './RecruitingActions';
import SelectionActions from './SelectionActions';
import OnboardingActions from './OnboardingActions';
import DeliveryActions from './DeliveryActions';
import ClosedActions from './ClosedActions';
import CancelledActions from './CancelledActions';

const propTypes = {
  position: PropTypes.object.isRequired,
};

const PositionCallToActions = ({ position, ...resolutionProps }) => {
  const { role, supplierId } = useAuth();
  const mainStatus = position.getMainStatus(role, supplierId);
  const { value: positionStatus, id } = position.getStatus(role, supplierId);

  if (mainStatus.value === MAIN_RECRUITING) {
    return <RecruitingActions position={position} positionStatusId={id} {...resolutionProps} />;
  }
  if (mainStatus.value === MAIN_SELECTION) {
    return (
      <SelectionActions
        position={position}
        role={role}
        positionStatus={positionStatus}
        {...resolutionProps}
      />
    );
  }
  if (mainStatus.value === MAIN_ONBOARDING) {
    return (
      <OnboardingActions
        position={position}
        role={role}
        supplierId={supplierId}
        {...resolutionProps}
      />
    );
  }
  if (mainStatus.value === MAIN_DELIVERY) {
    return <DeliveryActions {...resolutionProps} />;
  }
  if (mainStatus.value === MAIN_CLOSED) {
    return <ClosedActions role={role} positionStatus={positionStatus} {...resolutionProps} />;
  }
  if (mainStatus.value === MAIN_CANCELLED) {
    return <CancelledActions role={role} {...resolutionProps} />;
  }

  return null;
};

PositionCallToActions.propTypes = propTypes;

export default PositionCallToActions;
