import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// components
import Nbsp from 'components/atoms/Nbsp/Nbsp';
// helpers
import { prettyNumber } from 'helpers/Number';
// styles
import styles from './selection.module.scss';
import moduleStyles from '../submitCandidates.module.scss';

const ratesPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.number.isRequired,
    year: PropTypes.number.isRequired,
  })
);

const propTypes = {
  years: PropTypes.arrayOf(PropTypes.number).isRequired,
  currency: PropTypes.string.isRequired,
  partnerRates: ratesPropTypes.isRequired,
  nteRates: ratesPropTypes.isRequired,
};

const RatesTooltip = ({ currency, years, nteRates, partnerRates }) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(moduleStyles.tooltip, styles.rates)}>
      <div className={classNames(styles.ratesRow)}>
        <div className={styles.rateCell}>
          <Nbsp />
        </div>
        {years.map(year => (
          <div className={classNames(styles.rateCell, 'bold')} key={year}>
            {year}
          </div>
        ))}
      </div>
      <div className={styles.ratesRow}>
        <div className={classNames(styles.rateCell, 'bold')}>{t('clientNte')}</div>
        {nteRates.map(rate => (
          <div className={classNames('text-right', styles.rateCell)} key={rate.year}>
            {prettyNumber(rate.value)}
            <span className="m-l-2">{currency}</span>
          </div>
        ))}
      </div>
      <div className={styles.ratesRow}>
        <div className={classNames(styles.rateCell, 'bold')}>{t('partnerRate')}</div>
        {partnerRates.map(rate => (
          <div className={classNames('text-right', styles.rateCell)} key={rate.year}>
            {prettyNumber(rate.value)}
            <span className="m-l-2">{currency}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

RatesTooltip.propTypes = propTypes;

export default RatesTooltip;
