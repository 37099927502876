// helpers
import { getStatusText } from 'helpers/NameMapping';
// constants
import { STAFF_ROLE, SUPPLIER_ROLE } from 'constants';
import { SUBMISSION_STATUS } from 'constants/submissionStatus';

export const getStatuses = role => {
  const statusJourney = [];

  if ([STAFF_ROLE, SUPPLIER_ROLE].includes(role)) {
    statusJourney.push({
      id: SUBMISSION_STATUS.NEW,
      value: SUBMISSION_STATUS.NEW,
      label: getStatusText(SUBMISSION_STATUS.NEW, role),
    });
  }

  statusJourney.push(
    {
      id: SUBMISSION_STATUS.SUBMITTED,
      value: SUBMISSION_STATUS.SUBMITTED,
      label: getStatusText(SUBMISSION_STATUS.SUBMITTED, role),
    },
    {
      id: SUBMISSION_STATUS.OFFER,
      value: SUBMISSION_STATUS.OFFER,
      label: getStatusText(SUBMISSION_STATUS.OFFER, role),
    },
    {
      id: SUBMISSION_STATUS.ONBOARDING,
      value: SUBMISSION_STATUS.ONBOARDING,
      label: getStatusText(SUBMISSION_STATUS.ONBOARDING, role),
    },
    {
      id: SUBMISSION_STATUS.DELIVERY,
      value: SUBMISSION_STATUS.DELIVERY,
      label: getStatusText(SUBMISSION_STATUS.DELIVERY, role),
    }
  );

  return statusJourney;
};
