import i18n from 'i18next';
import { ADMIN_ROLE, STAFF_ROLE, SUPPLIER_ROLE, MANAGER_ROLE } from 'constants';

const basicSortList = [
  {
    label: i18n.t('dueDate'),
    id: 'dueDate',
  },
  {
    label: i18n.t('positionTitle'),
    id: 'title',
  },
  {
    label: i18n.t('project'),
    id: 'project',
  },
  // {
  //   label: i18n.t('location'),
  //   id: 'location',
  // },
  // {
  //   label: i18n.t('publishedDate'),
  //   id: 'lastPublishedDate',
  // },
];

const PositionSort = {
  [ADMIN_ROLE]: basicSortList,
  [STAFF_ROLE]: basicSortList,
  [SUPPLIER_ROLE]: basicSortList,
  [MANAGER_ROLE]: basicSortList,
};

export default PositionSort;
