import { gql } from '@apollo/client';
import getProperty from 'lodash/get';
// services
import client from 'services/Client';
import logger from 'services/Logger';
import { getCandidate } from 'services/Report';

const GET_PENDING_REPORT = gql`
  query fetchPendingReport($id: ID!) {
    report: pendingReport(id: $id) {
      id
      score
      shCandidateId
      shPersonId
      positionReport {
        id
        shPositionId
        position {
          id
          rfq {
            id
            name
          }
          title
          project {
            id
            name
            shProjectId
            client {
              id
              name
              config
            }
          }
          report {
            id
          }
        }
      }
    }
  }
`;

/**
 * @param {string} id Pending report id
 * @returns {Promise<object>}
 */
const fetchPendingReport = async id => {
  try {
    const response = await client.query({ query: GET_PENDING_REPORT, variables: { id } });
    const report = getProperty(response, 'data.report');
    const position = getProperty(report, 'positionReport.position');
    const shCandidateId = getProperty(report, 'shCandidateId');
    const candidateResponse = await getCandidate(shCandidateId);

    return {
      score: getProperty(report, 'score'),
      shPositionId: getProperty(report, 'positionReport.shPositionId'),
      shCandidateId,
      shPersonId: getProperty(report, 'shPersonId'),
      candidate: {
        name: getProperty(candidateResponse, 'person.name', ''),
        email: getProperty(candidateResponse, 'person.email', ''),
        phone: getProperty(candidateResponse, 'person.phone', ''),
      },
      position: {
        id: getProperty(position, 'id', ''),
        rfq: getProperty(position, 'rfq.name', ''),
        title: getProperty(position, 'title', ''),
        client: getProperty(position, 'project.client.name', ''),
        config: getProperty(position, 'project.client.config'),
        reportId: getProperty(position, 'report.id', ''),
        project: {
          id: getProperty(position, 'project.id', ''),
          name: getProperty(position, 'project.name', ''),
          shProjectId: getProperty(position, 'project.shProjectId'),
        },
      },
    };
  } catch (error) {
    logger.exception(error, { id });
    throw error;
  }
};

export default fetchPendingReport;
