/* eslint-disable import/order */
import React, { useContext } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import getProperty from 'lodash/get';
import flatten from 'lodash/flatten';
// hooks
import useAuth from 'hooks/useAuth';
import useModalProvider from 'hooks/useModalProvider';
// context
import ToastContext from 'context/ToastContext';
// components
import Button from 'components/atoms/Button';
import TextContent from '../TextContent';
import EmailModal from '../EmailModal';
import Forms from './Forms';
// helpers
import { submitOnboardingDocumentsForPM, submitOnboardingDocumentsForAusy } from './helpers';
// constants
import { ONBOARDING_STATUS_FORMS } from 'constants/onboarding';
import { ON_BOARDING_FORMS_CONFIRMED, ON_BOARDING_FORM_AUSY } from 'constants/templates';
import {
  ONBOARDING_FILE_NHF,
  ONBOARDING_FILE_HR72,
  ONBOARDING_FILE_PASSPORT,
  ONBOARDING_FILE_AAS_PLUS_NDA,
  ONBOARDING_FILE_ATTESTATION,
  ONBOARDING_FILE_NR_AIS_SECOPS_FORM,
} from '../constants';

const propTypes = {
  loading: PropTypes.bool.isRequired,
  templates: PropTypes.arrayOf(PropTypes.object).isRequired,
  files: PropTypes.arrayOf(PropTypes.object),
  submission: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
  note: PropTypes.object,
};

const defaultProps = {
  files: [],
  note: null,
};

const MODAL = {
  PM_EMAIL: 'PM_EMAIL',
  AUSY_EMAIL: 'AUSY_EMAIL',
};

const ATTACHMENT_TYPES_FOR_PM = [
  ONBOARDING_FILE_PASSPORT,
  ONBOARDING_FILE_HR72,
  ONBOARDING_FILE_AAS_PLUS_NDA,
  ONBOARDING_FILE_ATTESTATION,
  ONBOARDING_FILE_NR_AIS_SECOPS_FORM,
];

const ConfirmOnboardingForms = ({ loading, templates, files, note, submission, position }) => {
  const user = useAuth();
  const { t } = useTranslation();
  const { openModal, closeModal, isModalOpen } = useModalProvider();
  const { addToast } = useContext(ToastContext);

  const { rfq, title, project, client } = position;
  const ausyEmail = position.getConfig('ausy', '');
  const projectManager = position.getProjectManager();

  const sendToAusyStatus = submission.findStatus(ONBOARDING_STATUS_FORMS.SEND_TO_AUSY);
  const documentsSentToAusy = sendToAusyStatus && sendToAusyStatus.end;
  const sendToPMStatus = submission.findStatus(ONBOARDING_STATUS_FORMS.SEND_TO_PM);
  const documentsSentToPM = sendToPMStatus && sendToPMStatus.end;

  const attachmentsForAusy = flatten(
    files.filter(group => group.type === ONBOARDING_FILE_NHF).map(group => group.files)
  );
  const attachmentsForPM = flatten(
    files.filter(group => ATTACHMENT_TYPES_FOR_PM.includes(group.type)).map(group => group.files)
  );

  const getContentDescription = () => {
    if (!submission.isDirectPartner()) {
      if (documentsSentToAusy) {
        return t('onboardingFormsSubmittedToAusyDescription');
      }

      if (documentsSentToPM) {
        return t('onboardingFormsSubmittedToPMDescription');
      }
    }

    return '';
  };

  const handleSubmit = async email => {
    try {
      if (isModalOpen(MODAL.PM_EMAIL)) {
        await submitOnboardingDocumentsForPM(email, submission, user.id);
      } else {
        await submitOnboardingDocumentsForAusy(email, submission, user.id);
      }

      closeModal();
      addToast.success(t('onboardingFormsSubmittedSuccess'));
    } catch (error) {
      addToast.error(t('errorPlaceholderText'));
    }
  };

  return (
    <React.Fragment>
      <TextContent title={t('readyForYourConfirmation')} subTitle={getContentDescription()}>
        <div className="horizontalCenter p-t-15">
          {!submission.isDirectPartner() && !documentsSentToAusy && (
            <div className="m-r-12">
              <Button label={t('submitFormsToAusy')} onClick={() => openModal(MODAL.AUSY_EMAIL)} />
            </div>
          )}
          {(submission.isDirectPartner() || !documentsSentToPM) && (
            <Button label={t('submitFormsToPM')} onClick={() => openModal(MODAL.PM_EMAIL)} />
          )}
        </div>
        <Forms
          loading={loading}
          templates={templates}
          files={files}
          note={note}
          category={submission.partner.category}
          projectId={project.id}
          collapsedTemplates
        />
      </TextContent>
      <EmailModal
        isOpen={isModalOpen(MODAL.AUSY_EMAIL)}
        projectId={project.id}
        title={t('submitFormsToAusy')}
        recipient={ausyEmail}
        onClose={closeModal}
        onSubmit={handleSubmit}
        attachments={attachmentsForAusy}
        templateType={ON_BOARDING_FORM_AUSY}
        templateData={{
          rfq,
          candidate: submission.getName(),
          matchManager: user.name,
        }}
      />
      <EmailModal
        isOpen={isModalOpen(MODAL.PM_EMAIL)}
        projectId={project.id}
        title={t('submitFormsToPM')}
        recipient={getProperty(projectManager, 'email', '')}
        onClose={closeModal}
        onSubmit={handleSubmit}
        attachments={attachmentsForPM}
        templateType={ON_BOARDING_FORMS_CONFIRMED}
        templateData={{
          project: project.name,
          client: client.name,
          rfq,
          position: title,
          manager: getProperty(projectManager, 'name', ''),
          candidate: submission.getName(),
        }}
      />
    </React.Fragment>
  );
};

ConfirmOnboardingForms.propTypes = propTypes;
ConfirmOnboardingForms.defaultProps = defaultProps;

export default ConfirmOnboardingForms;
