import { from, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import errorLink from './error';
import httpLink from './http';
import retryLink from './retry';
import wsLink from './ws';

const splitLink = () =>
  split(
    ({ query }) => {
      const definition = getMainDefinition(query);

      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink(),
    httpLink()
  );

const links = from([retryLink(), errorLink(), splitLink()]);

export default links;
