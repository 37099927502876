import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
// helpers
import { getRoleText } from 'helpers/NameMapping';
import { isReadyForDelivery, getOnboardingPendingRoles } from 'helpers/Onboarding';
import { getSubmissionLink } from 'helpers/Link';
// constants
import { SUBMISSION_TAB } from 'constants/pageTabs';
import { SUBMISSION_STATUS } from 'constants/submissionStatus';
import { POSITION_STATUS } from 'constants/positionStatus';
// components
import Action from './Action';
import Resolution from './Resolution';

const propTypes = {
  position: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  supplierId: PropTypes.string,
};

const defaultProps = {
  supplierId: '',
};

const OnboardingActions = ({ position, role, supplierId, ...resolutionProps }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const submissions = position.getParsedSubmissions(role, supplierId);
  const onboardingSubmissions = submissions.filter(submission =>
    submission.hasStatus([SUBMISSION_STATUS.ONBOARDING, SUBMISSION_STATUS.OFFER])
  );
  const onboardingSubmission = onboardingSubmissions.length > 0 ? onboardingSubmissions[0] : null;

  const goToOnboardingPage = () => {
    const link = getSubmissionLink(onboardingSubmission.id, position.isFixedPrice(), {
      absoluteUrl: false,
      queryParams: {
        tab: SUBMISSION_TAB.ONBOARDING,
      },
    });

    history.push(link);
  };

  if (onboardingSubmission && position.getStatus().value !== POSITION_STATUS.ONBOARDING_CANCELLED) {
    if (isReadyForDelivery(onboardingSubmission, role)) {
      return (
        <Resolution
          {...resolutionProps}
          label={t('resolutionReadyForDelivery')}
          tooltip={t('tooltipReadyForDelivery')}
        />
      );
    }

    const pendingRoles = getOnboardingPendingRoles(onboardingSubmission, role);
    const translatedPendingRoles = (() => {
      if (pendingRoles.length) {
        const translatedRoles = pendingRoles.map(pendingRole => getRoleText(pendingRole));

        return translatedRoles.join(' and ');
      }

      return null;
    })();

    if (pendingRoles.includes(role)) {
      return (
        <Action
          onClick={goToOnboardingPage}
          label={t('actionOnboardingTakeAction')}
          tooltip={t('tooltipOnboardingTakeAction')}
        />
      );
    }

    if (pendingRoles.length > 1) {
      return (
        <Resolution
          {...resolutionProps}
          label={t('resolutionOnboarding')}
          tooltip={t('tooltipOnboarding', { role: translatedPendingRoles })}
        />
      );
    }

    return (
      <Resolution
        {...resolutionProps}
        label={t('resolutionOnboardingSingleRoleAction', { role: translatedPendingRoles })}
        tooltip={t('tooltipOnboardingTakeAction')}
      />
    );
  }

  return null;
};

OnboardingActions.propTypes = propTypes;
OnboardingActions.defaultProps = defaultProps;

export default OnboardingActions;
