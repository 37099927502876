import React from 'react';
// libraries
import PropTypes from 'prop-types';
// styles
import styles from './modal.module.scss';

const propTypes = {
  children: PropTypes.any.isRequired,
};

const ModalFooter = ({ children }) => <div className={styles.footer}>{children}</div>;

ModalFooter.propTypes = propTypes;

export default ModalFooter;
