import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// components
import Link from 'components/atoms/Link';
import LinkButton from 'components/atoms/LinkButton';
import TextSection from 'components/atoms/TextSection';
// helpers
import { openLink } from 'helpers/Link';
import { boxFileUrl } from 'helpers/Box';
// constants
import {
  SUPPLIER_ROLE,
  MANAGER_ROLE,
  FILE_CV,
  FILE_RELEASABLE_CV,
  FILE_ATTESTATION,
  FILE_RELEASABLE_ATTESTATION,
} from 'constants';
// styles
import styles from './submissionDetails.module.scss';

const propTypes = {
  submission: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
};

const Documents = ({ submission, role }) => {
  const { t } = useTranslation();
  const cv = submission.getDocument(FILE_CV);
  const releasableCv = submission.getDocument(FILE_RELEASABLE_CV);
  const attestation = submission.getDocument(FILE_ATTESTATION);
  const releasableAttestation = submission.getDocument(FILE_RELEASABLE_ATTESTATION);

  return (
    <div className={styles.documents}>
      {(role !== MANAGER_ROLE || (role === MANAGER_ROLE && !releasableCv)) && (
        <TextSection title={t('candidateCv')}>
          <LinkButton onClick={() => openLink(boxFileUrl(cv.boxId))}>{cv.filename}</LinkButton>
        </TextSection>
      )}
      {role !== SUPPLIER_ROLE && releasableCv && (
        <TextSection title={role === MANAGER_ROLE ? t('candidateCv') : t('releasableCv')}>
          <LinkButton onClick={() => openLink(boxFileUrl(releasableCv.boxId))}>
            {releasableCv.filename}
          </LinkButton>
        </TextSection>
      )}
      {(role !== MANAGER_ROLE || (role === MANAGER_ROLE && !releasableAttestation)) && (
        <TextSection title={t('attestation')}>
          <LinkButton onClick={() => openLink(boxFileUrl(attestation.boxId))}>
            {attestation.filename}
          </LinkButton>
        </TextSection>
      )}
      {role !== SUPPLIER_ROLE && releasableAttestation && (
        <TextSection title={role === MANAGER_ROLE ? t('attestation') : t('releasableAttestation')}>
          <LinkButton onClick={() => openLink(boxFileUrl(releasableAttestation.boxId))}>
            {releasableAttestation.filename}
          </LinkButton>
        </TextSection>
      )}
      <TextSection title={t('report')}>
        <Link href={`/report/match/${submission.report.id}`} className="fs-unmask">
          {t('viewMatchReport')}
        </Link>
      </TextSection>
    </div>
  );
};

Documents.propTypes = propTypes;

export default Documents;
