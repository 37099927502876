import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// helpers
import { getTimeYear } from 'helpers/RelativeTimeFormatter';
// styles
import styles from './note.module.scss';

const propTypes = {
  label: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  date: PropTypes.string,
  author: PropTypes.string,
};

const defaultProps = {
  date: '',
  author: '',
};

const Note = ({ label, text, date, author }) => {
  return (
    <div>
      <div className={styles.label}>{label}</div>
      <div className={styles.text}>{text}</div>
      {(date || author) && (
        <div className={styles.detail}>
          {author && <b>{author}</b>}
          {date && (
            <span className={classNames(styles.date, { 'm-l-5': author })}>
              {getTimeYear(date)}
            </span>
          )}
        </div>
      )}
    </div>
  );
};

Note.propTypes = propTypes;
Note.defaultProps = defaultProps;

export default Note;
