import client from '@services/client';
import { gql } from '@services/gql';
import { ImportRecord } from '../types';
import IMPORT_STATUS_MAP from '../importStatusMap';

const FETCH_RECORD = gql(/* GraphQL */ `
  query fetchRecord($id: ID!) {
    record(id: $id) {
      id
      createdAt
      filename
      downloadLink
      status
      parsedData
    }
  }
`);

const fetchRecords = async (id: string): Promise<Nullable<ImportRecord>> => {
  const response = await client.query({ query: FETCH_RECORD, variables: { id } });

  if (!response.data.record) {
    return null;
  }

  const item = response.data.record;

  return {
    id: item.id,
    createdAt: item.createdAt,
    filename: item.filename,
    downloadLink: item.downloadLink,
    status: IMPORT_STATUS_MAP[item.status],
    parsedData: item.parsedData,
  };
};

export default fetchRecords;
