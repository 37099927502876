/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useRef } from 'react';
// libraries
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// hooks
import usePrevious from 'hooks/usePrevious';
import useModalManager from './useModalManager';
// context
import { CloseProvider } from './CloseContext';
// components
import Header from './Header';
import Body from './Body';
import Footer from './Footer';
// styles
import styles from './modal.module.scss';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  closeOnEsc: PropTypes.bool,
  closeOnBackdrop: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', 'full']),
  children: PropTypes.any,
};

const defaultProps = {
  closeOnEsc: true,
  closeOnBackdrop: true,
  size: 'md',
  children: null,
};

const Modal = React.memo(({ isOpen, onClose, closeOnBackdrop, closeOnEsc, size, children }) => {
  const rootElement = useRef(document.createElement('div'));
  const modalElement = useRef();
  const prevIsOpen = usePrevious(isOpen);
  // for stacking modals we need to manage z-indexes of stacked modals
  const { registerModal, unregisterModal, getZIndex } = useModalManager();

  const attachModalRoot = () => {
    document.body.appendChild(rootElement.current);
    registerModal(modalElement.current);
  };

  const detachModalRoot = () => {
    if (rootElement.current.isConnected) {
      document.body.removeChild(rootElement.current);
    }

    unregisterModal();
  };

  useEffect(() => {
    if (!prevIsOpen && isOpen) {
      attachModalRoot(rootElement.current);
    } else if (prevIsOpen && !isOpen) {
      detachModalRoot(rootElement.current);
    }

    // remove modal from DOM when component unmount
    return () => {
      if (isOpen) {
        detachModalRoot(rootElement.current);
      }
    };
  }, [isOpen]);

  const handleKeyDown = event => {
    // close modal esc
    if (closeOnEsc && event.keyCode === 27) {
      event.stopPropagation();
      onClose();
    }
  };

  const handleBackdropClick = event => {
    // close modal by clicking outside of the modal
    if (event.target === event.currentTarget && closeOnBackdrop) {
      onClose();
    }
  };

  const stopPropagation = event => {
    event.stopPropagation();
  };

  if (!isOpen) {
    return null;
  }

  const modal = (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <div
      ref={modalElement}
      role="presentation"
      tabIndex="-1"
      onClick={stopPropagation}
      onMouseEnter={stopPropagation}
      onMouseLeave={stopPropagation}
      onMouseOver={stopPropagation}
      onKeyDown={handleKeyDown}
      style={{ '--zIndex': getZIndex() }}
    >
      <div className={styles.backdrop} />
      <div className={styles.container} tabIndex="-1" role="dialog" onClick={handleBackdropClick}>
        <div className={classNames('fs-mask', styles.contentWrapper, styles[size])} role="document">
          <div className={styles.content}>
            <CloseProvider value={onClose}>{children}</CloseProvider>
          </div>
        </div>
      </div>
    </div>
  );

  return ReactDOM.createPortal(modal, rootElement.current);
});

Modal.Header = Header;
Modal.Body = Body;
Modal.Footer = Footer;
Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;

export default Modal;
