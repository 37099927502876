import React from 'react';
import ContentLoader from 'react-content-loader';

const CandidateLoader = () => (
  <ContentLoader height={160} width={350} speed={1} primaryColor="#f1f1f1" secondaryColor="#e6e6e6">
    <rect x="70" y="20" rx="4" ry="4" width="117" height="6" />
    <rect x="70" y="35" rx="4" ry="4" width="90" height="4" />
    <rect x="10" y="65" rx="8" ry="8" width="110" height="15" />
    <rect x="10" y="95" rx="3" ry="3" width="330" height="6" />
    <rect x="10" y="115" rx="3" ry="3" width="330" height="6" />
    <rect x="10" y="135" rx="3" ry="3" width="201" height="6" />
    <circle cx="30" cy="30" r="20" />
    <rect x="0" y="0" rx="0" ry="0" width="2" height="160" />
    <rect x="348" y="0" rx="0" ry="0" width="2" height="160" />
    <rect x="0" y="0" rx="0" ry="0" width="350" height="2" />
    <rect x="0" y="158" rx="0" ry="0" width="350" height="2" />
  </ContentLoader>
);

export default CandidateLoader;
