import i18n from 'i18next';
// constants
import { STEP } from './constants';

const tabs = [
  {
    id: STEP.SELECT_WINNER,
    label: i18n.t('winner'),
  },
  {
    id: STEP.START_DATE,
    label: i18n.t('startDate'),
  },
  {
    id: STEP.NOTES,
    label: i18n.t('notes'),
  },
];

export const getTabs = (hasBid, hasWon) => {
  if (!hasBid || !hasWon) {
    return [tabs[0]];
  }

  return tabs;
};

export default tabs;
