import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Switch as MaterialSwitch } from '@material-ui/core';
// styles
import { switchStyle } from './SwitchStyle';

const propTypes = {
  id: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

const defaultProps = {
  id: '',
  onChange: () => {},
  disabled: false,
  required: false,
};

const Switch = props => {
  const { id, checked, onChange, disabled, required } = props;

  return (
    <MuiThemeProvider theme={switchStyle}>
      <MaterialSwitch
        name={id}
        checked={checked}
        onChange={event => onChange(event.target.checked)}
        disabled={disabled}
        required={required}
      />
    </MuiThemeProvider>
  );
};

Switch.propTypes = propTypes;
Switch.defaultProps = defaultProps;

export default Switch;
