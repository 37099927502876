import React, { useState, useEffect, useCallback, useContext } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import debounce from 'lodash/debounce';
// queries
import { GET_LOCATION } from 'queries/SearchQueries';
// components
import Input from 'components/atoms/NewInput';
import CheckboxListInput from 'components/molecules/CheckboxListInput';
import RadioListInput from 'components/molecules/RadioListInput';
import AutoCompleteSearch from 'components/molecules/AutoCompleteSearch';
// helpers
import { parseLocationData } from 'helpers/FormDataParser';
import { fetchClearances } from 'helpers/Clearance';
// context
import ToastContext from 'context/ToastContext';
// constants
import { FILTER_BY, SORT_OPTIONS } from '../constants';
// styles
import styles from './candidatesMatchFilter.module.scss';

const propTypes = {
  filteredBy: PropTypes.object,
  sortedBy: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired,
};

const defaultProps = {
  filteredBy: {},
};

const CandidatesMatchFilter = ({ filteredBy, sortedBy, onFilterChange, onSortChange }) => {
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState(filteredBy[FILTER_BY.FULL_TEXT] || '');
  const [clearanceOptions, setClearanceOptions] = useState([]);

  const { addToast } = useContext(ToastContext);

  const selectedClearances = filteredBy[FILTER_BY.CLEARANCE] || [];

  // update local state for search text when new search text is passed to component via props
  useEffect(() => {
    const incomingSearchText = filteredBy[FILTER_BY.FULL_TEXT] || '';

    if (searchText !== incomingSearchText && searchText.length > 2) {
      setSearchText(incomingSearchText);
    }
  }, [filteredBy[FILTER_BY.FULL_TEXT]]);

  useEffect(() => {
    const fetchClearancesData = async () => {
      try {
        const clearanceLevels = await fetchClearances();
        if (clearanceLevels.length > 0) {
          setClearanceOptions(clearanceLevels);
        }
      } catch (error) {
        addToast.warning(t('errorClearancesNotLoaded'));
      }
    };
    fetchClearancesData();
  }, []);

  // don't filter list immediately when the text is changed, but rather use debounce
  const debouncedSearchTextChange = useCallback(
    debounce(text => onFilterChange({ [FILTER_BY.FULL_TEXT]: text }), 400),
    [filteredBy]
  );

  const handleSearchTextChange = value => {
    setSearchText(value);

    // apply search if at least 3 chars were filled
    if (value.length > 2) {
      debouncedSearchTextChange(value);
    } else if (searchText.length > 2 && value.length <= 2) {
      debouncedSearchTextChange('');
    }
  };

  const handleFilterChange = field => value => {
    onFilterChange({ [field]: value });
  };

  return (
    <div className={styles.container}>
      <Grid container spacing={16}>
        {/* Full text search */}
        <Grid item sm={12} md={6} lg={3}>
          <Input
            placeholder={t('candidatePoolMatchSearchPlaceholder')}
            value={searchText}
            onChange={handleSearchTextChange}
            icon="search"
          />
        </Grid>

        {/* Sorting */}
        <Grid item sm={12} md={6} lg={3}>
          <RadioListInput
            label={`${t('sortedBy')}:`}
            selected={sortedBy.value}
            options={SORT_OPTIONS}
            onChange={onSortChange}
            noRemoveButton
          />
        </Grid>

        {/* Clearances */}
        <Grid item sm={12} md={6} lg={3}>
          <CheckboxListInput
            icon="verified"
            placeholder={t('clearance')}
            options={clearanceOptions}
            selected={selectedClearances}
            onChange={handleFilterChange(FILTER_BY.CLEARANCE)}
          />
        </Grid>

        {/* Location */}
        <Grid item sm={12} md={6} lg={3}>
          <AutoCompleteSearch
            type="locationsList"
            placeholder={t('searchByLocation')}
            customQuery={GET_LOCATION}
            customDataParser={data => parseLocationData(data, true)}
            onChange={option => handleFilterChange(FILTER_BY.LOCATION)(option ? option.value : '')}
          />
        </Grid>
      </Grid>
    </div>
  );
};

CandidatesMatchFilter.propTypes = propTypes;
CandidatesMatchFilter.defaultProps = defaultProps;

export default CandidatesMatchFilter;
