/* eslint-disable import/order */
import React, { useState, useEffect, useContext } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// context
import ToastContext from 'context/ToastContext';
// components
import Link from 'components/atoms/Link';
import Modal from 'components/molecules/ModalWindow';
import MRIImprovements from 'components/molecules/MRIImprovements';
import ChipsList from 'components/molecules/ChipsList';
import GaugeChart from './GaugeChart';
// helpers
import { getLevelByScore, getDescriptionByLevel, LEVELS } from 'helpers/MRI';
import { getTimeYear } from 'helpers/RelativeTimeFormatter';
import { boxFileUrl } from 'helpers/Box';
import { fetchData } from './helpers';
// styles
import styles from './MRIModal.module.scss';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  shCandidateId: PropTypes.string.isRequired,
  candidateName: PropTypes.string.isRequired,
  cv: PropTypes.shape({
    name: PropTypes.string.isRequired,
    boxId: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.any,
};

const defaultProps = {
  children: null,
};

const MRIModal = ({ isOpen, shCandidateId, candidateName, cv, onClose, children }) => {
  const { t } = useTranslation();
  const { addToast } = useContext(ToastContext);

  const [loading, setLoading] = useState(false);
  const [mri, setMri] = useState({});

  const mriLevel = getLevelByScore(mri.score);
  const mriDescription = getDescriptionByLevel(mriLevel);

  useEffect(() => {
    const fetchDataAsync = async () => {
      try {
        setLoading(true);
        const mriData = await fetchData(shCandidateId);
        setMri(mriData);
      } catch (error) {
        addToast.error(t('errorPlaceholderText'));
        onClose();
      } finally {
        setLoading(false);
      }
    };

    if (isOpen && !loading) {
      fetchDataAsync();
    }
  }, [isOpen, shCandidateId]);

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      onClose={onClose}
      loading={loading}
      loadingText={loading ? t('fetchingDataLoader') : ''}
      className={styles.container}
      closeOnBackdrop
      closeOnEsc
    >
      <Modal.Header className={styles.header} centered>
        <div className={styles.title}>{t('cvReadability')}</div>
        <div className={styles.subtitle}>
          <b>{candidateName}</b>
          <span className="m-l-5">|</span>
          <Link href={boxFileUrl(cv.boxId)} className={styles.cvLink}>
            {cv.name}
          </Link>
          {getTimeYear(cv.createdAt)}
        </div>
      </Modal.Header>
      <Modal.Body className={styles.body}>
        {mri.score && (
          <div className={styles.topSection}>
            <GaugeChart score={mri.score} className={styles.chart} />
            <ChipsList
              selected={mriLevel}
              items={LEVELS}
              className={{ root: styles.levels, chip: styles.levelChip }}
            />
            <div className={styles.description}>{mriDescription}</div>
          </div>
        )}
        <div className={styles.bottomSection}>
          <div className={styles.factorsTitle}>{t('matchFactors')}</div>
          <MRIImprovements goodSections={mri.goodSections} badSections={mri.badSections} />
        </div>
        {children}
      </Modal.Body>
    </Modal>
  );
};

MRIModal.propTypes = propTypes;
MRIModal.defaultProps = defaultProps;

export default MRIModal;
