import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// components
import Tooltip from 'components/atoms/Tooltip';
// helpers
import { getReadableDateTime, getPassedRelativeTime } from 'helpers/RelativeTimeFormatter';
// components
import Icon from 'components/atoms/Icon';
// constants
import { SUBMISSION_STATUS } from 'constants/submissionStatus';
// styles
import colors from 'dependencies/materialStyles/Colors';

const propTypes = {
  availableIn: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  statusDate: PropTypes.string.isRequired,
};

const Dates = ({ availableIn, status, statusDate }) => {
  const { t } = useTranslation();

  const getTitle = () => {
    switch (status) {
      case SUBMISSION_STATUS.NEW:
        return t('received');
      case SUBMISSION_STATUS.NOT_SUBMITTED:
        return t('rejected');
      case SUBMISSION_STATUS.WITHDRAWN:
        return t('withdrawn');
      case SUBMISSION_STATUS.SUBMITTED:
        return t('submittedToClient');
      case SUBMISSION_STATUS.ONBOARDING:
        return t('selectedByClient');
      case SUBMISSION_STATUS.NOT_SELECTED:
        return t('notSelectedByClient');
      default:
        return '';
    }
  };

  return (
    <React.Fragment>
      <div className="positionCandidate_date">
        <Icon name="calendarAvailable" color={colors.gray} size={12} />
        <strong>{t('availableIn')}</strong> {availableIn}
      </div>
      <div className="positionCandidate_date">
        <Icon name="time" color={colors.gray} size={12} />
        <strong>{getTitle(status)}</strong>{' '}
        <Tooltip title={getReadableDateTime(statusDate)} placement="right" disableFocusListener>
          <span>{getPassedRelativeTime(statusDate)}</span>
        </Tooltip>
      </div>
    </React.Fragment>
  );
};

Dates.propTypes = propTypes;

export default Dates;
