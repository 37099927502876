import React, { useMemo } from 'react';
import classNames from 'classnames';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import Autocomplete from '@mui/material/Autocomplete';
import { MdArrowDropDown } from 'react-icons/md';
import { BaseTextInput } from '@components/TextInput';
import Icon from '@components/Icon';
import Checkbox from '@components/Checkbox';
import ListContainer from './ListContainer';
import styles from './comboBox.module.scss';

export interface Option {
  id: string;
  value: string;
}

interface BaseComboBoxProps {
  id?: string;
  placeholder?: string;
  ariaLabel?: string;
  name?: string;
  error?: boolean;
  loading?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  options: Option[];
  disableClearable?: boolean;
  multiple?: boolean;
}

interface MultipleComboBoxProps extends BaseComboBoxProps {
  multiple: true;
  value: string[];
  onChange(option: Option[]): void;
}

interface SingleComboBoxProps extends BaseComboBoxProps {
  multiple?: false;
  value: string;
  onChange(option: Nullable<Option>): void;
}

type ComboBoxProps = SingleComboBoxProps | MultipleComboBoxProps;

const ComboBox = ({
  id,
  value,
  placeholder,
  name,
  ariaLabel,
  multiple = false,
  loading = false,
  error = false,
  readOnly = false,
  disabled = false,
  disableClearable = false,
  options,
  onChange,
}: ComboBoxProps) => {
  const selectedValue = useMemo(() => {
    if (multiple) {
      return options.filter((option) => value.includes(option.id));
    }

    return options.find((option) => option.id === value) || null;
  }, [value, multiple, options]);

  return (
    <Autocomplete
      value={selectedValue}
      fullWidth
      multiple={multiple}
      loading={loading}
      disabled={disabled}
      readOnly={readOnly}
      placeholder={placeholder}
      disableCloseOnSelect={multiple}
      disableClearable={disableClearable}
      getOptionKey={(option: Option) => option.id}
      getOptionLabel={(option: Option) => option.value}
      isOptionEqualToValue={(option: Option, val: Option) => option.id === val.id}
      onChange={(_event, option) => onChange(option as Nullable<Option> & Option[])}
      options={options}
      PaperComponent={ListContainer}
      popupIcon={<Icon component={MdArrowDropDown} size="small" />}
      classes={{ endAdornment: styles.icons }}
      ChipProps={{ size: 'small', classes: { root: styles.chip } }}
      renderInput={(params) => {
        const { ref, ...restInputProps } = params.InputProps;

        return (
          <div ref={ref}>
            <BaseTextInput
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...restInputProps}
              name={name}
              placeholder={placeholder}
              error={error}
              disabled={disabled}
              readOnly={readOnly}
              inputRef={params.inputProps.ref}
              inputProps={{
                ...params.inputProps,
                id,
                'aria-label': ariaLabel,
                autoComplete: 'disabled',
              }}
              className={styles.input}
            />
          </div>
        );
      }}
      renderOption={(props, option, { inputValue, selected }) => {
        const { key, className, ...optionProps } = props;
        const matches = match(option.value, inputValue, { insideWords: true });
        const parts = parse(option.value, matches);

        return (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <li key={key} {...optionProps} className={classNames(className, styles.listItem)}>
            {multiple && <Checkbox checked={selected} onChange={() => {}} />}
            <div className={styles.listItemLabel}>
              {!Array.isArray(selectedValue) && selectedValue?.value === inputValue ? (
                option.value
              ) : (
                <React.Fragment>
                  {parts.map((part, index) => (
                    <span
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      style={{ fontFamily: part.highlight ? 'Lato' : 'Lato-Light' }}
                    >
                      {part.text}
                    </span>
                  ))}
                </React.Fragment>
              )}
            </div>
          </li>
        );
      }}
    />
  );
};

export default ComboBox;
