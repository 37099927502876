import React from 'react';
// libraries
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// components
import Button from 'components/atoms/Button';

const propTypes = {
  step: PropTypes.number.isRequired,
  onContinue: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  stepAmount: PropTypes.number.isRequired,
  submitLabel: PropTypes.string,
  submitDisabled: PropTypes.bool,
};

const defaultProps = {
  submitLabel: null,
  submitDisabled: false,
};

const StepperButtons = ({
  step,
  stepAmount,
  onContinue,
  onBack,
  onSubmit,
  submitLabel,
  submitDisabled,
}) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {step > 1 && (
        <span className="m-r-12">
          <Button label={t('back')} secondary onClick={onBack} />
        </span>
      )}
      {step < stepAmount && <Button label={t('continue')} onClick={onContinue} />}
      {step === stepAmount && (
        <Button label={submitLabel || t('submit')} disabled={submitDisabled} onClick={onSubmit} />
      )}
    </React.Fragment>
  );
};

StepperButtons.propTypes = propTypes;
StepperButtons.defaultProps = defaultProps;

export default StepperButtons;
