import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
// components
import Nbsp from 'components/atoms/Nbsp';
// helpers
import { getFormattedDuration, getTimeYear } from 'helpers/RelativeTimeFormatter';
// styles
import styles from './item.module.scss';

const propTypes = {
  name: PropTypes.string.isRequired,
  start: PropTypes.string,
  end: PropTypes.string,
  description: PropTypes.string,
};

const defaultProps = {
  start: '',
  end: '',
  description: '',
};

const Item = ({ name, start, end, description }) => {
  const { t } = useTranslation();

  const renderDuration = () => {
    if (!start && !end) {
      return <i>{t('notProvided')}</i>;
    }

    if (!start) {
      return (
        <Trans
          i18nKey="deliverables.durationEnd"
          components={[<span className={styles.dateText} />, <Nbsp />]}
          values={{ date: getTimeYear(end) }}
        >
          until DD.MM.YYYY
        </Trans>
      );
    }

    if (!end) {
      return (
        <Trans
          i18nKey="deliverables.durationStart"
          components={[<span className={styles.dateText} />, <Nbsp />]}
          values={{ date: getTimeYear(start) }}
        >
          from DD.MM.YYYY
        </Trans>
      );
    }

    return getFormattedDuration(start, end);
  };

  return (
    <React.Fragment>
      <div>
        <span className={styles.title}>{name}</span>
        <div className={styles.description}>{description}</div>
      </div>
      <div className={styles.duration}>
        <p>{renderDuration()}</p>
      </div>
    </React.Fragment>
  );
};

Item.propTypes = propTypes;
Item.defaultProps = defaultProps;

export default Item;
