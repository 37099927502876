import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
// hooks
import useAuth from 'hooks/useAuth';
// helpers
import { getFormattedDuration } from 'helpers/RelativeTimeFormatter';
import { getReceivedSubmissionsCount, getSubmittedSubmissionsCount } from 'helpers/Submission';
// components
import TextSection from 'components/atoms/TextSection';
import PositionStatusFlow from 'components/molecules/PositionStatusFlow';
import SubmissionsCount from 'components/molecules/SubmissionsCount';
import PublishStatus from 'components/organisms/PublishStatus';
import Section from './Section';
// styles
import styles from './positionMainInfo.module.scss';

const propTypes = {
  position: PropTypes.object.isRequired,
};

const PositionMainInfo = ({ position }) => {
  const { t } = useTranslation();
  const { role, supplierId } = useAuth();

  const color = position.getColor(role, supplierId);
  const submissions = position.isFixedPrice() ? position.proposals : position.submissions;

  return (
    <React.Fragment>
      <div className={classNames(styles.banner, styles[color])} />
      <Section>
        <div className={styles.mainSection}>
          <div className={styles.rfq}>{position.rfqLabel}</div>
          <div className={styles.title}>{position.title}</div>
          <div className="m-t-20 text-center">
            <PositionStatusFlow position={position} />
            <PublishStatus position={position} className="m-t-12" />
          </div>
        </div>
      </Section>
      <Section>
        <TextSection title={t('project')} icon="project">
          {position.client.name}, {position.project.name}
        </TextSection>
        {position.location.id && (
          <div className="m-t-20">
            <TextSection title={t('location')} icon="location">
              {position.getLocation()}
            </TextSection>
          </div>
        )}
      </Section>
      <Section>
        <TextSection title={t('periodOfPerformance')} icon="calendar">
          {getFormattedDuration(position.duration.start, position.duration.end)}
        </TextSection>
        {position.duration.hours && (
          <div className="m-t-20">
            <TextSection title={t('levelOfEffort')} icon="level">
              {position.duration.hours} {t('hours').toLocaleLowerCase()}
            </TextSection>
          </div>
        )}
      </Section>
      <Section className={styles.submissionsSection}>
        <SubmissionsCount
          role={role}
          allowed={position.maximumCandidatesAllowed}
          received={getReceivedSubmissionsCount(submissions, role, supplierId)}
          submitted={getSubmittedSubmissionsCount(submissions, role, supplierId)}
          inlineView={false}
        />
      </Section>
    </React.Fragment>
  );
};

PositionMainInfo.propTypes = propTypes;

export default PositionMainInfo;
