import React, { useState } from 'react';
// Libraries
import PropTypes from 'prop-types';
// components
import DropdownContent from './DropdownContent';
import DropdownHeader from './DropdownHeader';
// styles
import './dropdownCheckbox.scss';

const propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

const defaultProps = {
  name: '',
  onChange: () => {},
  width: null,
  disabled: false,
  className: '',
};

const DropdownCheckbox = props => {
  const { width, name, onChange, disabled, className, ...restProps } = props;
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState('');

  const toggleCheckbox = () => {
    if (!disabled) {
      setOpen(!open);
    }
  };

  const handleChange = value => {
    if (!disabled) {
      onChange(value, name);
      toggleCheckbox();
      setSearchText('');
    }
  };

  const onRemove = e => {
    if (!disabled) {
      e.stopPropagation();
      onChange(null, name);
    }
  };

  return (
    <div
      className={`checkboxContainer test_${name} ${className}`}
      style={width ? { width: `${width}px` } : null}
    >
      <DropdownHeader
        open={open}
        onRemove={onRemove}
        toggleCheckbox={toggleCheckbox}
        disabled={disabled}
        {...restProps}
      />
      {open && (
        <DropdownContent
          searchText={searchText}
          onChange={handleChange}
          toggleCheckbox={toggleCheckbox}
          name={name}
          {...restProps}
        />
      )}
    </div>
  );
};

DropdownCheckbox.propTypes = propTypes;
DropdownCheckbox.defaultProps = defaultProps;

export default DropdownCheckbox;
