import i18n from 'i18next';
// constants
import { CANDIDATE_STATUS_FILTERS, CANDIDATE_LAST_UPDATE_FILTERS } from 'constants/filters';

export const ORDER = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const SORT_BY = {
  SCORE: 'score',
  NAME: 'name',
  EXPECTED_RATE: 'expectedRate',
  CV_READABILITY: 'cvReadability',
};

export const SORT_OPTIONS = [
  {
    value: SORT_BY.SCORE,
    label: i18n.t('score'),
  },
  {
    value: SORT_BY.NAME,
    label: i18n.t('name'),
  },
  {
    value: SORT_BY.EXPECTED_RATE,
    label: i18n.t('expectedMinimumRate'),
  },
  {
    value: SORT_BY.CV_READABILITY,
    label: i18n.t('cvReadability'),
  },
];

export const FILTER_BY = {
  FULL_TEXT: 'fullText',
  CLEARANCE: 'clearance',
  STATUS: 'status',
  LAST_CV_UPDATE: 'lastCvUpdate',
  LOCATION: 'location',
};

export const LAST_CV_UPDATE_OPTIONS = [
  { value: CANDIDATE_LAST_UPDATE_FILTERS.LAST_30DAYS, label: i18n.t('last30Days') },
  { value: CANDIDATE_LAST_UPDATE_FILTERS.LAST_3MONTHS, label: i18n.t('last3Months') },
  { value: CANDIDATE_LAST_UPDATE_FILTERS.LAST_6MONTHS, label: i18n.t('last6Months') },
  { value: CANDIDATE_LAST_UPDATE_FILTERS.LAST_YEAR, label: i18n.t('lastYear') },
  { value: CANDIDATE_LAST_UPDATE_FILTERS.MORE_THAN_YEAR, label: i18n.t('moreThanOneYear') },
];

export const STATUS_FILTER_OPTIONS = [
  { value: CANDIDATE_STATUS_FILTERS.AVAILABLE, label: i18n.t('available') },
  { value: CANDIDATE_STATUS_FILTERS.UNAVAILABLE, label: i18n.t('unavailable') },
  { value: CANDIDATE_STATUS_FILTERS.AVAILABLE_SOON, label: i18n.t('availableSoon') },
  { value: CANDIDATE_STATUS_FILTERS.AVAILABLE_LATER, label: i18n.t('availableLater') },
];
