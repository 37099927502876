import React from 'react';
// libraries
import { useTranslation, Trans } from 'react-i18next';
import PropTypes from 'prop-types';
// components
import Link from 'components/atoms/Link';
// styles
import styles from './simulationLegend.module.scss';

const propTypes = {
  link: PropTypes.string.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const SimulationLegend = ({ link, className }) => {
  const { t } = useTranslation();

  return (
    <div className={`${className} ${styles.info}`}>
      <Trans i18nKey="submitCandidatesRatesFormula">
        Refer to the{' '}
        <Link href={link} className={styles.infoLink}>
          {{ formulaTable: t('formulaTable') }}
        </Link>{' '}
        to learn how the default rates are calculated
      </Trans>
      <br />
      {t('submitCandidatesManualSetRatesInfo')}
    </div>
  );
};

SimulationLegend.propTypes = propTypes;
SimulationLegend.defaultProps = defaultProps;

export default SimulationLegend;
