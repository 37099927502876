import React, { useState, useRef } from 'react';
// libraries
import PropTypes from 'prop-types';
// components
import Popper from 'components/atoms/Popper';

const propTypes = {
  button: PropTypes.func.isRequired,
  disableOffset: PropTypes.bool,
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  placement: PropTypes.oneOf([
    'bottom-end',
    'bottom-start',
    'bottom',
    'left-end',
    'left-start',
    'left',
    'right-end',
    'right-start',
    'right',
    'top-end',
    'top-start',
    'top',
  ]),
};

const defaultProps = {
  placement: 'top-start',
  disableOffset: false,
  className: '',
};

const Popover = ({ button, children, className, placement, disableOffset }) => {
  const [isOpen, setOpen] = useState(false);
  const contentEl = useRef();

  const handleClose = event => {
    // if content is not open -> do nothing
    if (!isOpen) return;

    // if click on content -> don't close
    if (event) {
      const path = event.path || (event.composedPath && event.composedPath());
      if (path.includes(contentEl.current)) {
        return;
      }
    }

    setOpen(false);
  };

  const handleClick = event => {
    if (isOpen || !event) {
      handleClose();
      return;
    }

    setOpen(true);
  };

  return (
    <React.Fragment>
      <div className={className} ref={contentEl}>
        {button(handleClick)}
      </div>
      <Popper
        isOpen={isOpen}
        onClose={handleClose}
        anchorEl={contentEl.current}
        placement={placement}
        modifiers={{
          flip: {
            enabled: false,
          },
          offset: {
            enabled: !disableOffset,
            offset: '5px, 5px',
          },
          preventOverflow: {
            enabled: false,
          },
          hide: {
            enabled: false,
          },
        }}
      >
        {children}
      </Popper>
    </React.Fragment>
  );
};

Popover.propTypes = propTypes;
Popover.defaultProps = defaultProps;

export default Popover;
