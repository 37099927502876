import i18n from 'i18next';

export const ORDER = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const SORT_BY = {
  SCORE: 'score',
  DUE_DATE: 'dueDate',
  START_DATE: 'startDate',
  POSITION_TITLE: 'positionTitle',
  PROJECT: 'project',
};

export const SORT_OPTIONS = [
  {
    value: SORT_BY.SCORE,
    label: i18n.t('score'),
  },
  {
    value: SORT_BY.DUE_DATE,
    label: i18n.t('dueDate'),
  },
  {
    value: SORT_BY.START_DATE,
    label: i18n.t('startDate'),
  },
  {
    value: SORT_BY.POSITION_TITLE,
    label: i18n.t('position'),
  },
  {
    value: SORT_BY.PROJECT,
    label: i18n.t('project'),
  },
];

export const FILTER_BY = {
  FULL_TEXT: 'fullText',
  CLEARANCE: 'clearance',
  LOCATION: 'location',
};
