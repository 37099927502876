import { gql } from '@apollo/client';
// services
import client from 'services/Client';
import logger from 'services/Logger';
import { upload } from 'services/Box';
// queries
import { File } from 'queries/Fragments';

const UPLOAD_DOCUMENT = gql`
  mutation uploadReleasableAttestation(
    $submissionId: ID!
    $boxId: String!
    $filename: String!
    $type: String!
  ) {
    submissionUpdate(
      filter: { id: $submissionId }
      data: { documents: { create: { name: $filename, boxId: $boxId, type: $type } } }
    ) {
      id
      documents(sort: { createdAt: DESC }) {
        items {
          ...File
        }
      }
    }
  }
  ${File}
`;

/**
 * Upload releasable Attestation to box and create new document record in DB
 * @param {File} file
 * @param {object} params
 * @param {string} params.submissionId
 * @param {string} params.candidateName
 * @param {string} params.project
 * @param {string} params.client
 * @param {string} params.rfq e.g. 3111-0
 * @returns {Promise<string>} Uploaded file id from box
 */
const uploadDocument = async (file, { submissionId, filename, folder, type }) => {
  try {
    const boxId = await upload(file, filename, folder);

    await client.mutate({
      mutation: UPLOAD_DOCUMENT,
      variables: {
        submissionId,
        boxId,
        filename,
        type,
      },
    });
  } catch (error) {
    logger.exception(error, { submissionId, filename, folder, type });
    throw error;
  }
};

export default uploadDocument;
