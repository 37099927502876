import React from 'react';
// libraries
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
// hooks
import useAuth from 'hooks/useAuth';
// helpers
import { getRoleColor } from 'helpers/Colors';
// constants
import { APP_VERSION } from 'constants';
// components
import Button from 'components/atoms/Button';
import Notifications from 'components/molecules/Notifications';
import HeaderSettings from './HeaderSettings';
import RoleSwitcher from './RoleSwitcher';
import Logo from './Logo';
// styles
import './header.scss';

const propTypes = {
  openMenu: PropTypes.func.isRequired,
};

const Header = ({ openMenu }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const auth = useAuth();
  const role = auth ? auth.role : null;

  const renderSignedUserComponents = () => {
    return (
      <React.Fragment>
        <RoleSwitcher />
        <Notifications />
        <HeaderSettings className="p-l-15 p-r-5 fs-unmask" />
      </React.Fragment>
    );
  };

  const renderPublicComponents = () => {
    return (
      <React.Fragment>
        <div
          role="presentation"
          className="publicHeaderButton"
          onClick={() => history.push('/login')}
        >
          {t('signIn')}
        </div>
        <div
          role="presentation"
          className="publicHeaderButton-highlighted"
          onClick={() => history.push('/signup')}
        >
          {t('signUp')}
        </div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div className={`header header--${getRoleColor[role] || getRoleColor.default}`}>
        <div className="display-flex-row flexStart flex1Basic">
          <div className="display-header-menu">
            <Button type="menu" color="white" size={30} onClick={openMenu} />
          </div>
          <Logo role={role} className="display-header-logo" />
          {role && <div className="header_version">{APP_VERSION}</div>}
        </div>
        <div className="header_icons flex1Basic flexEnd">
          {role ? renderSignedUserComponents() : renderPublicComponents()}
        </div>
      </div>
    </React.Fragment>
  );
};

Header.propTypes = propTypes;

export default Header;
