import React, { useState } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// components
import Button from 'components/atoms/Button';
import LinkButton from 'components/atoms/LinkButton';
import Loader from 'components/atoms/Loader';
// helpers
import { openLink } from 'helpers/Link';
import { boxFileUrl } from 'helpers/Box';
// constants
import { ONBOARDING_FILE_IVCP, ONBOARDING_FILE_NR_AIS_SECOPS } from '../constants';
// styles
import styles from './templates.module.scss';

const propTypes = {
  loading: PropTypes.bool.isRequired,
  templates: PropTypes.arrayOf(
    PropTypes.shape({
      boxId: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ),
  collapsed: PropTypes.bool,
};

const defaultProps = {
  collapsed: false,
  templates: [],
};

const Templates = ({ loading, templates, collapsed }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(!collapsed);

  const renderContent = () => {
    if (loading) {
      return (
        <div className={styles.loader}>
          <Loader fixedSize small />
        </div>
      );
    }

    if (templates.length > 0) {
      return (
        <div className={styles.files}>
          {templates.map(({ boxId, label, type }) => {
            return (
              <div key={boxId}>
                <div className={styles.label}>{label}</div>
                <LinkButton className={styles.link} onClick={() => openLink(boxFileUrl(boxId))}>
                  {t('viewDocument')}
                </LinkButton>
                {type === ONBOARDING_FILE_IVCP && (
                  <div className={styles.info}>{t('forInformationOnly')}</div>
                )}
                {type === ONBOARDING_FILE_NR_AIS_SECOPS && (
                  <div className={styles.info}>Only to be read and acknowledged</div>
                )}
              </div>
            );
          })}
        </div>
      );
    }

    return null;
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {t('templates')}
        <Button
          type={!expanded ? 'expandMore' : 'less'}
          tooltip={expanded ? t('collapse') : t('expand')}
          onClick={() => setExpanded(!expanded)}
        />
      </div>
      {expanded && renderContent()}
    </div>
  );
};

Templates.propTypes = propTypes;
Templates.defaultProps = defaultProps;

export default Templates;
