import { gql } from '@apollo/client';
// services
import client from 'services/Client';
import logger from 'services/Logger';
// helpers
import { resetPositionStatus } from 'helpers/Position';
import { getSubmissionCurrentStatus, resetSubmissionStatus } from 'helpers/Submission';
// constants
import { SUBMISSION_STATUS } from 'constants/submissionStatus';
// queries
import { PositionStatus } from 'queries/Fragments';

const UPDATE_POSITION_DUE_DATE = gql`
  mutation updatePositionDueDate($position: ID!, $dueDate: DateTime!) {
    positionUpdate(filter: { id: $position }, data: { dueOn: $dueDate }) {
      id
      statuses(sort: { createdAt: DESC }) {
        items {
          ...PositionStatus
        }
      }
    }
  }
  ${PositionStatus}
`;

export const reopenPosition = async (position, submissionsToReset, dueDate) => {
  try {
    const promises = [
      client.mutate({
        mutation: UPDATE_POSITION_DUE_DATE,
        variables: {
          position: position.id,
          dueDate,
        },
      }),
      resetPositionStatus(position),
    ];

    if (submissionsToReset.length > 0) {
      submissionsToReset.forEach(submission => {
        const isProposal = position.isFixedPrice();
        const submissionStatus = getSubmissionCurrentStatus(submission.statuses.items);

        if (
          [SUBMISSION_STATUS.NOT_SUBMITTED, SUBMISSION_STATUS.SUBMITTED].includes(
            submissionStatus.name
          )
        ) {
          resetSubmissionStatus(submission.id, submission.statuses.items, isProposal);
        }
      });
    }

    await Promise.all(promises);
  } catch (error) {
    logger.exception(error);
    throw error;
  }
};
