import { gql } from '@apollo/client';
import {
  Rate,
  MatchReport,
  SubmissionStatus,
  MatchMetric,
  ExperienceLevel,
} from 'queries/Fragments';

const GET_CANDIDATE_MATCHES_LIST = gql`
  query getCandidateMatchesList(
    $filter: CandidateMatchFilter!
    $sort: [CandidateMatchSort!]
    $skip: Int!
  ) {
    candidateMatchesList(filter: $filter, sort: $sort, first: 20, skip: $skip) {
      count
      items {
        id
        report {
          ...MatchReport
        }
        metrics(sort: { updatedAt: DESC }, first: 1) {
          items {
            ...MatchMetric
          }
        }
        position {
          id
          title
          dueOn
          supplierDueDate
          contractType
          location {
            items {
              id
              city
              country {
                name
              }
            }
          }
          rfq {
            id
            name
          }
          submissions {
            items {
              id
              statuses {
                items {
                  ...SubmissionStatus
                }
              }
            }
          }
          duration {
            id
            start
          }
          rates(sort: { startDate: ASC }) {
            items {
              ...Rate
            }
          }
          clearance {
            id
            name
            shortName
            shClearance
          }
          shOccupations
          level {
            ...ExperienceLevel
          }
          project {
            id
            name
            shortName
            config
            client {
              id
              name
              config
            }
          }
        }
      }
    }
  }
  ${Rate}
  ${MatchReport}
  ${SubmissionStatus}
  ${MatchMetric}
  ${ExperienceLevel}
`;

export default GET_CANDIDATE_MATCHES_LIST;
