/* eslint-disable import/order */
import React, { useState } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// components
import Icon from 'components/atoms/Icon';
import Chip from 'components/atoms/NewChip';
import Tooltip from 'components/atoms/Tooltip';
import LinkButton from 'components/atoms/LinkButton';
import ImpactChip from './ImpactChip';
// helpers
import { formatNumber } from 'helpers/Number';
import { getLevelDetails } from 'helpers/MRI';
// constants
import { MRI_LEVEL, MRI_IMPACT } from 'constants/report';
// styles
import styles from './mriImprovements.module.scss';

const propTypes = {
  title: PropTypes.string.isRequired,
  level: PropTypes.oneOf([MRI_LEVEL.LOW, MRI_LEVEL.MODERATE, MRI_LEVEL.HIGH]).isRequired,
  impact: PropTypes.oneOf([MRI_IMPACT.LOW, MRI_IMPACT.MEDIUM, MRI_IMPACT.HIGH]).isRequired,
  issues: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ),
  penalty: PropTypes.number.isRequired,
  children: PropTypes.any,
};

const defaultProps = {
  issues: [],
  children: null,
};

const BadSection = ({ penalty, issues, impact, level, title, children }) => {
  const { t } = useTranslation();
  const [showAll, setShowAll] = useState(false);

  const showButton = issues.length > 1;
  const listOfIssues = showButton && !showAll ? issues.slice(0, 1) : issues;
  const levelDetails = getLevelDetails(level);
  const { color } = levelDetails;

  return (
    <div className={classNames(styles.section, styles[color])}>
      <div className="flex_space m-b-12">
        <div className={styles.sectionHeader}>
          <div className={styles.sectionTitle}>{title}</div>
          {issues && (
            <div className={styles.issues}>
              <span className={styles.issueDot}>•</span>
              {t('issuesCount', { count: issues.length })}
            </div>
          )}
        </div>
        <div className="display-flex-row">
          <div className="m-r-5">
            <ImpactChip impact={impact} />
          </div>
          <Tooltip title={t('penaltyPoints', { count: formatNumber(penalty) })}>
            <div className={styles.penalty}>
              <Chip color={color} className={styles.chip}>
                {levelDetails.label}
              </Chip>
            </div>
          </Tooltip>
        </div>
      </div>

      <div className="display-flex-row align-start">
        <div className={classNames(styles.warningIcon, styles[color])}>
          <Icon name="alert" size={18} />
        </div>
        <div className="m-l-12">
          {issues &&
            listOfIssues.map(({ code, text }) => {
              return (
                <div key={code} className={styles.issue}>
                  {text}
                </div>
              );
            })}
          {children}
          {showButton && (
            <LinkButton onClick={() => setShowAll(!showAll)} className="m-t-4">
              {showAll ? t('seeLess') : t('seeMore')}
            </LinkButton>
          )}
        </div>
      </div>
    </div>
  );
};

BadSection.propTypes = propTypes;
BadSection.defaultProps = defaultProps;

export default BadSection;
