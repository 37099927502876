import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// components
import Tabs from 'components/atoms/Tabs';
// styles
import styles from './detailPage.module.scss';

const propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  activeTab: PropTypes.string.isRequired,
  onSwitch: PropTypes.func.isRequired,
  children: PropTypes.any,
};

const defaultProps = {
  children: null,
};

const Navigation = ({ tabs, activeTab, onSwitch, children }) => {
  return (
    <div className={classNames(styles.navigation, 'fs-unmask')}>
      <Tabs items={tabs} selected={activeTab} onSwitch={onSwitch} />
      {children && <div className={styles.navigationContent}>{children}</div>}
    </div>
  );
};

Navigation.propTypes = propTypes;
Navigation.defaultProps = defaultProps;

export default Navigation;
