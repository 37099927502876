import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import Tooltip from '@mui/material/Tooltip';
import styles from './truncatedText.module.scss';

interface TruncatedTextProps {
  children: React.ReactNode;
  className?: string;
  disableTooltip?: boolean;
}

const TruncatedText = ({ children, className, disableTooltip = false }: TruncatedTextProps) => {
  const element = useRef(null);
  const [isOverflown, setIsOverflown] = useState(false);

  const isElementOverflown = () => {
    if (!element.current) {
      return false;
    }

    const { scrollWidth, clientWidth } = element.current;

    return scrollWidth > clientWidth;
  };

  useEffect(() => {
    const handleElementResize = () => {
      if (!disableTooltip) {
        setIsOverflown(isElementOverflown());
      }
    };

    // create debounce fn
    const debouncedElementResize = debounce(handleElementResize, 300);

    // check text overflown after render
    handleElementResize();

    // re-run overflown check after window was resized
    window.addEventListener('resize', debouncedElementResize);

    return () => {
      window.removeEventListener('resize', debouncedElementResize);
    };
  }, []);

  const renderContent = () => (
    <div className={classNames(styles.container, className)} ref={element}>
      {children}
    </div>
  );

  if (disableTooltip || !isOverflown) {
    return renderContent();
  }

  return (
    <Tooltip title={children} placement="top" disableFocusListener>
      {renderContent()}
    </Tooltip>
  );
};

export default TruncatedText;
