import logger from '@logdna/browser';
import appConfig from '@config/app';
import loggerConfig from '@config/logger';

logger.init(loggerConfig.token, {
  url: loggerConfig.endpoint,
  app: 'TMH client',
  tags: ['tmh', 'client'],
  disabled: appConfig.isDev,
  console: {
    enable: !appConfig.isDev,
    integrations: ['warn', 'error'],
  },
});

export default logger;
