import sortBy from 'lodash/sortBy';
import queryString from 'query-string';
// constants
import { STATUS_ACTIVE } from 'constants';

export const findRates = (rates, type) => {
  return sortBy(rates.filter(rate => rate.rateType === type && !rate.endDate), 'startDate');
};

export const findActualProjectStatus = status => {
  if (status && status.items.length > 0) {
    const actualStatus = status.items.find(stat => stat.status === STATUS_ACTIVE);
    if (actualStatus) {
      const { startDate, endDate, id } = actualStatus;
      return {
        startDate,
        endDate,
        id,
      };
    }
    return null;
  }
  return null;
};

/**
 * Get label for selected option
 * @param {object[]} options list of options for select box
 * @param {object[]} options[].label Option label
 * @param {object[]} options[].value Option value
 * @param {string} selectedValue Selected option value
 * @return {string} Selected option label
 */
export const getOptionLabel = (options, selectedValue) => {
  const selectedOption = options.find(option => option.value === selectedValue);

  return selectedOption ? selectedOption.label : '';
};

export const isLocalhost = () => {
  return window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
};

/**
 * Generate Rate card link
 * @param {number[]} sellPrices
 * @param {number} manYearDiscount
 * @param {string} positionId
 * @param {string} candidateId
 */
export const getRateCardLink = (sellPrices, manYearDiscount, positionId, candidateId) => {
  const encodedSellPrices = encodeURIComponent(JSON.stringify(sellPrices));
  const params = queryString.stringify({
    sellPrices: encodedSellPrices,
    manYearDiscount,
  });

  return `/api/files/spreadsheet/${positionId}/${candidateId}?${params}`;
};
