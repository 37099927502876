import React from 'react';
import { IconType } from 'react-icons';
import {
  MdInfoOutline,
  MdErrorOutline,
  MdCheckCircleOutline,
  MdOutlineWarningAmber,
} from 'react-icons/md';
import classNames from 'classnames';
import Icon from '@components/Icon';
import colors from '@utils/colors';
import styles from './alert.module.scss';

interface AlertProps {
  type: 'info' | 'success' | 'warning' | 'error';
  children: React.ReactNode;
  classes?: {
    root?: string;
  };
}

const COLOR_MAP: Record<AlertProps['type'], string> = {
  info: 'blue',
  success: 'green',
  warning: 'yellow',
  error: 'red',
};

const ICON_COLOR_MAP: Record<AlertProps['type'], string> = {
  info: colors.blueDarkest,
  success: colors.greenDarkest,
  warning: colors.yellowDarkest,
  error: colors.redDarkest,
};

const ICON_MAP: Record<AlertProps['type'], IconType> = {
  info: MdInfoOutline,
  success: MdCheckCircleOutline,
  warning: MdOutlineWarningAmber,
  error: MdErrorOutline,
};

const Alert = ({ type, children, classes = {} }: AlertProps) => (
  <div role="alert" className={classNames(styles.container, styles[COLOR_MAP[type]], classes.root)}>
    <div className={styles.icon}>
      <Icon
        component={ICON_MAP[type]}
        data-testid={type}
        color={ICON_COLOR_MAP[type]}
        size="small"
      />
    </div>
    <div className={styles.content}>{children}</div>
  </div>
);

export default Alert;
