import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// styles
import styles from './stepper.module.scss';

const propTypes = {
  step: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  error: PropTypes.bool,
};

const defaultProps = {
  isActive: false,
  error: false,
};

const Tab = ({ step, label, onClick, isActive, error }) => {
  return (
    <div
      role="presentation"
      onClick={onClick}
      className={classNames(styles.tab, {
        [styles.active]: isActive,
        [styles.error]: error && !isActive,
      })}
    >
      <div className={styles.step}>{step}</div>
      <div className={styles.label}>{label}</div>
    </div>
  );
};

Tab.propTypes = propTypes;
Tab.defaultProps = defaultProps;

export default Tab;
