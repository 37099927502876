import React, { useState, useEffect, useContext } from 'react';
// libraries
import { useSubscription } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import getProperty from 'lodash/get';
import isEqual from 'lodash/isEqual';
// hooks
import useAuth from 'hooks/useAuth';
// services
import logger from 'services/Logger';
// queries
import { GET_NOTIFICATIONS, NOTIFICATION_ADDED } from 'queries/NotificationQueries';
// context
import ToastContext from 'context/ToastContext';
// components
import Button from 'components/atoms/Button';
import Popper from 'components/atoms/Popper';
import Placeholder from 'components/atoms/Placeholder';
import CursorBasedList from 'components/molecules/CursorBasedList';
import { ListItemLoader } from 'components/molecules/ContentLoaders';
import NotificationList from './NotificationList';
// helpers
import {
  parseNotifications,
  updateLastViewed,
  getNewNotifications,
  updateNotificationCache,
} from './Helpers';
// styles
import './notifications.scss';

const Notifications = () => {
  const { addToast } = useContext(ToastContext);
  const { id: userId, lastViewedNotification } = useAuth();
  const { t } = useTranslation();

  const [newNotifications, setNewNotifications] = useState(0);
  const [firstNotification, setFirstNotification] = useState();
  const [anchorEl, setAnchorEl] = useState(null);

  useSubscription(NOTIFICATION_ADDED, {
    onSubscriptionData: ({ subscriptionData }) => {
      setNewNotifications(prevCount => prevCount + 1);
      // update cache only when some notifications were already stored in cache
      if (firstNotification) {
        const newNotification = getProperty(subscriptionData, 'data.notificationAdded');
        setFirstNotification(newNotification);
        updateNotificationCache(newNotification);
      }
    },
  });

  useEffect(() => {
    const fetchNewNotifications = async () => {
      try {
        const numberOfNewNotifications = await getNewNotifications(lastViewedNotification.id);
        setNewNotifications(numberOfNewNotifications);
      } catch (error) {
        addToast.error(t('errorWhileFetchingNotifications'));
        logger.exception(error);
      }
    };

    fetchNewNotifications();
  }, []);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (newNotifications > 0) {
      setNewNotifications(0);
      updateLastViewed(userId, firstNotification);
    }
  };

  const markAllAsRead = () => {
    // TODO: mark all as read
  };

  const updateFirstNotification = notifications => {
    const notification = getProperty(notifications, `0`);
    if (!isEqual(notification, firstNotification)) {
      setFirstNotification(notification);
    }
  };

  return (
    <div className="notification p-l-15">
      <div className="notification_icon fs-unmask" onClick={handleClick} role="presentation">
        <Button type="notification" size={23} color="white" tooltip={t('notifications')} />
        {newNotifications > 0 && <div className="notification_icon_number">{newNotifications}</div>}
      </div>
      <Popper
        isOpen={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        placement="bottom-end"
        className="m-t-5"
      >
        <div className="scrollableNotifications">
          <CursorBasedList
            query={GET_NOTIFICATIONS}
            variables={{ lastViewed: lastViewedNotification.id }}
          >
            {({ data, loading, error }) => {
              if (error) {
                return t('errorPlaceholderText');
              }

              if (loading) {
                return (
                  <div className="notification_loader">
                    <ListItemLoader />
                  </div>
                );
              }

              if (!data.length) {
                return (
                  <Placeholder className="notification_placeholder" icon="notification" size="lg">
                    {t('noNotificationsToShow')}
                  </Placeholder>
                );
              }

              const notifications = parseNotifications(data, lastViewedNotification.date);
              updateFirstNotification(notifications);

              return (
                <NotificationList
                  notifications={notifications}
                  markAllAsRead={markAllAsRead}
                  handleClose={handleClose}
                />
              );
            }}
          </CursorBasedList>
        </div>
      </Popper>
    </div>
  );
};

export default Notifications;
