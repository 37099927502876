import { makeVar } from '@apollo/client';

export const reactiveVar = makeVar({
  email: '',
  role: '',
});

/**
 * @param {string} role
 */
export const updateRole = role => {
  const user = reactiveVar();
  reactiveVar({ ...user, role });
};

/**
 * @param {string} email
 * @param {string} role
 */
export const setUser = (email, role) => {
  reactiveVar({ email, role });
};
