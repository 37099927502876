import React from 'react';
// libraries
import PropTypes from 'prop-types';
// components
import TableOption from 'components/molecules/TableOption';
import Inputs from './Inputs';

const propTypes = {
  tableName: PropTypes.string,
  fieldName: PropTypes.string,
};

const defaultProps = {
  tableName: null,
  fieldName: null,
};

const InputGroup = props => {
  const { tableName, fieldName } = props;

  if (tableName && fieldName) {
    return (
      <TableOption tableName={tableName} fieldName={fieldName}>
        {({ loading, values }) => {
          return <Inputs {...props} values={values} loading={loading} />;
        }}
      </TableOption>
    );
  }

  return <Inputs {...props} />;
};

InputGroup.propTypes = propTypes;
InputGroup.defaultProps = defaultProps;

export default InputGroup;
