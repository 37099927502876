import type { Option } from '@components/ComboBox';

export const statusOptions: Option[] = [
  {
    id: 'deleted',
    value: 'Deleted',
  },
  {
    id: 'active',
    value: 'Active',
  },
];

export const typeOptions: Option[] = [
  {
    id: 'direct',
    value: 'Direct',
  },
  {
    id: 'notDirect',
    value: 'Not Direct',
  },
];

export const csaFeeOptions: Option[] = [
  {
    id: 'ibm',
    value: 'IBM',
  },
  {
    id: 'supplier',
    value: 'Supplier',
  },
];
