import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// components
import Tab from './Tab';
// styles
import styles from './stepper.module.scss';

const propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      error: PropTypes.bool,
    })
  ),
  /** Active tab id. By default first is tab is active */
  activeTab: PropTypes.string,
  /** On tab switch. Id of switched tab is provided */
  onSwitch: PropTypes.func.isRequired,
  error: PropTypes.bool,
  children: PropTypes.any.isRequired,
};

const defaultProps = {
  tabs: [],
  activeTab: '',
  error: false,
};

const Stepper = ({ tabs, activeTab, onSwitch, error, children }) => {
  return (
    <React.Fragment>
      {tabs.length > 1 && (
        <div className={styles.tabs}>
          {tabs.map((tab, index) => {
            return (
              <Tab
                key={tab.id}
                step={index + 1}
                label={tab.label}
                isActive={tab.id === activeTab}
                error={tab.error}
                onClick={() => onSwitch(tab.id)}
              />
            );
          })}
        </div>
      )}
      <div
        className={classNames(styles.content, {
          [styles.full]: tabs.length <= 1,
          [styles.error]: error,
        })}
      >
        {children}
      </div>
    </React.Fragment>
  );
};

Stepper.propTypes = propTypes;
Stepper.defaultProps = defaultProps;

export default Stepper;
