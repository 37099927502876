import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// constants
import { STAFF_ROLE, SUPPLIER_ROLE } from 'constants';
// components
import Resolution from './Resolution';

const propTypes = {
  role: PropTypes.string.isRequired,
};

const CancelledActions = ({ role, ...resolutionProps }) => {
  const { t } = useTranslation();

  if ([STAFF_ROLE, SUPPLIER_ROLE].includes(role)) {
    return <Resolution label={t('resolutionCancelled')} {...resolutionProps} />;
  }
  return null;
};

CancelledActions.propTypes = propTypes;

export default CancelledActions;
