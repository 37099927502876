import React from 'react';
// Libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// components
import OutsideDetector from 'components/atoms/OutsideDetector';
import Input from 'components/atoms/Input';
import RadioCustom from 'components/molecules/RadioCustom';
import ScoreList from 'components/molecules/ScoreList';
import Placeholder from 'components/atoms/Placeholder';
import Loader from 'components/atoms/Loader';

const propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  selected: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
  onChange: PropTypes.func,
  toggleCheckbox: PropTypes.func,
  setSearchText: PropTypes.func,
  list: PropTypes.array,
  loading: PropTypes.bool,
  searchText: PropTypes.string,
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  search: PropTypes.bool,
  scoreList: PropTypes.bool,
};

const defaultProps = {
  selected: null,
  onChange: () => {},
  toggleCheckbox: () => {},
  setSearchText: () => {},
  placeholder: null,
  list: null,
  loading: false,
  searchText: '',
  maxHeight: null,
  search: false,
  scoreList: false,
};

const DropdownContent = props => {
  const {
    onChange,
    toggleCheckbox,
    setSearchText,
    list,
    selected,
    loading,
    search,
    placeholder,
    name,
    maxHeight,
    searchText,
    scoreList,
  } = props;
  const { t } = useTranslation();

  const regex = new RegExp(searchText, 'gi');
  const filteredList =
    search && searchText !== '' ? list.filter(item => item.label.match(regex)) : list;

  const getList = () => {
    if (scoreList) {
      return <ScoreList onChange={onChange} list={filteredList} selected={selected} name={name} />;
    }
    return <RadioCustom onChange={onChange} list={filteredList} selected={selected} name={name} />;
  };

  return (
    <OutsideDetector onClick={toggleCheckbox}>
      <div
        className="checkboxContainer_content"
        style={maxHeight ? { maxHeight: `${maxHeight}px` } : null}
      >
        {search && (
          <Input
            onChange={setSearchText}
            inputType="text"
            placeholder={t('typeToSearch')}
            value={searchText}
            onClear={() => setSearchText('')}
            className="border_bottom"
            id={`${name}_input`}
          />
        )}
        <div className="checkboxContainer_space">
          {loading ? (
            <div className="space-20-30">
              <Loader />
            </div>
          ) : (
            <div>
              {filteredList && filteredList.length > 0 ? (
                getList()
              ) : (
                <Placeholder size="sm">{placeholder || t('noMoreItems')}</Placeholder>
              )}
            </div>
          )}
        </div>
      </div>
    </OutsideDetector>
  );
};

DropdownContent.propTypes = propTypes;
DropdownContent.defaultProps = defaultProps;

export default DropdownContent;
