import React from 'react';
// Libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// components
import Button from 'components/atoms/Button';
import Popover from 'components/molecules/Popover';
// styles
import colors from 'dependencies/materialStyles/Colors';
import './field.scss';

const propTypes = {
  className: PropTypes.string,
  content: PropTypes.any,
  children: PropTypes.any,
  description: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  fieldNotFilled: PropTypes.bool,
  hideLabel: PropTypes.bool,
  label: PropTypes.string,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
  fieldClassName: PropTypes.string,
  warning: PropTypes.string,
};

const defaultProps = {
  className: '',
  content: null,
  children: null,
  description: null,
  error: false,
  errorMessage: null,
  fieldNotFilled: false,
  hideLabel: false,
  label: '',
  required: false,
  tooltip: null,
  fieldClassName: '',
  warning: '',
};

const Field = props => {
  const { t } = useTranslation();
  const {
    className,
    content,
    children,
    description,
    error,
    errorMessage,
    fieldNotFilled,
    hideLabel,
    label,
    required,
    tooltip,
    fieldClassName,
    warning,
  } = props;

  return (
    <div className={`${className} field`}>
      {!hideLabel && (
        <div className="field_label">
          <div className="field_question">
            {label}
            {required ? (
              <span className="text-red p-l-2">*</span>
            ) : (
              <span className="field_optional">{t('optional')}</span>
            )}
          </div>
          {tooltip && (
            <Popover
              disableOffset
              button={onClick => (
                <Button type="help" size="16" color={colors.gray} onClick={onClick} />
              )}
            >
              <div className="p-15">
                <pre>{tooltip}</pre>
              </div>
            </Popover>
          )}
        </div>
      )}
      {description && <div className="field_description">{description}</div>}
      <div className={`${fieldClassName} field_content`}>{content || children}</div>
      {warning && <div className="field_warning">{t(warning)}</div>}
      {(fieldNotFilled || (error && errorMessage)) && (
        <div className="field_requiredWarning">
          {fieldNotFilled ? `* ${t('thisFieldIsRequired')}` : errorMessage || ''}
        </div>
      )}
    </div>
  );
};

Field.propTypes = propTypes;
Field.defaultProps = defaultProps;

export default Field;
