import { BANNER_TYPE } from './constants';

export const getBannerColor = type => {
  switch (type) {
    case BANNER_TYPE.ANNOUNCEMENT:
      return { color: 'blue', iconColor: 'blue8' };
    case BANNER_TYPE.WARNING:
      return { color: 'yellow', iconColor: 'yellow9' };
    case BANNER_TYPE.ERROR:
      return { color: 'red', iconColor: 'red7' };
    case BANNER_TYPE.SUCCESS:
      return { color: 'green', iconColor: 'green7' };
    case BANNER_TYPE.MISSING_INFO:
      return { color: 'gray', iconColor: 'gray7' };
    default:
      return { color: 'blue', iconColor: 'blue8' };
  }
};

export const getBannerIcon = type => {
  switch (type) {
    case BANNER_TYPE.ANNOUNCEMENT:
      return 'infoOutline';
    case BANNER_TYPE.WARNING:
      return 'warningOutline';
    case BANNER_TYPE.ERROR:
      return 'alert';
    case BANNER_TYPE.SUCCESS:
      return 'checkOutline';
    case BANNER_TYPE.MISSING_INFO:
      return 'infoOutline';
    default:
      return 'infoOutline';
  }
};
