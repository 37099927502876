import React from 'react';
// libraries
import PropTypes from 'prop-types';
// constants
import { COLOR_GRAY, COLOR_BLUE, COLOR_GREEN, COLOR_RED, COLOR_YELLOW } from 'constants/colors';
// components
import Avatar from 'components/atoms/Avatar';
import Tooltip from 'components/atoms/Tooltip';

const propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['', COLOR_BLUE, COLOR_GREEN, COLOR_RED, COLOR_YELLOW, COLOR_GRAY]),
  avatarShape: PropTypes.string.isRequired,
  size: PropTypes.number,
};

const defaultProps = {
  color: '',
  size: 13,
};

const TooltipAvatar = ({ name, color, size, avatarShape }) => {
  return (
    <Tooltip title={name}>
      <div>
        <Avatar size={size} name={name} color={color} shape={avatarShape} />
      </div>
    </Tooltip>
  );
};

TooltipAvatar.propTypes = propTypes;
TooltipAvatar.defaultProps = defaultProps;

export default TooltipAvatar;
