import React from 'react';
// libraries
import PropTypes from 'prop-types';
// components
import Level from './Level';
// styles
import './levels.scss';

const propTypes = {
  className: PropTypes.string,
  list: PropTypes.array,
  name: PropTypes.string.isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func,
  showYears: PropTypes.bool,
};

const defaultProps = {
  className: '',
  list: null,
  value: {},
  onChange: null,
  showYears: false,
};

const Levels = props => {
  const { list, value, onChange, name, className, showYears } = props;

  if (list) {
    return (
      <div className={`${className} levels test_${name}`}>
        {list.map(level => {
          const { id, yearsOfExperience, order, title } = level;
          return (
            <div
              key={id}
              onClick={onChange ? () => onChange(level) : undefined}
              role="presentation"
              className="p-b-2"
            >
              <Level
                id={id}
                yearsOfExperience={yearsOfExperience}
                order={order}
                title={title}
                highlighted={value && id === value.id}
                showYears={showYears}
              />
            </div>
          );
        })}
      </div>
    );
  }

  const { id, yearsOfExperience, order, title } = value;
  return (
    <Level
      id={id}
      yearsOfExperience={yearsOfExperience}
      order={order}
      title={title}
      showYears={showYears}
      className={className}
      highlighted
    />
  );
};

Levels.propTypes = propTypes;
Levels.defaultProps = defaultProps;

export default Levels;
