import React from 'react';
import classNames from 'classnames';
import Button from '@components/Button';
import { Dialog, DialogHeader, DialogBody, DialogFooter } from '@components/Dialog';
import spacing from '@styles/spacing.module.scss';
import styles from './suppliersPage.module.scss';

interface SupplierDeleteModalProps {
  name: string;
  isOpen: boolean;
  submitting: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const SupplierDeleteModal = ({
  isOpen,
  name,
  submitting,
  onClose,
  onSubmit,
}: SupplierDeleteModalProps) => (
  <Dialog
    isOpen={isOpen}
    onClose={onClose}
    size="sm"
    disableCloseOnBackdropClick
    disableCloseOnEscape={submitting}
  >
    <DialogHeader title="Delete supplier" disableCloseButton={submitting} />
    <DialogBody>
      <div className={styles.fontLato}>
        Are you sure you want to delete {name}&#39;s supplier record?
      </div>
      <div className={classNames(styles.textBlock, spacing.mt4)}>
        This action will mark the supplier record as deleted. Supplier users&#39; will no longer
        receive any notifications and won&#39;t participate in any further submissions. This action
        will also delete all the supplier user&#39;s access to the application which{' '}
        <b>cannot be undone.</b> Existing supplier submissions will not be deleted.
      </div>
    </DialogBody>
    <DialogFooter>
      <Button onClick={onClose} secondary disabled={submitting}>
        Close
      </Button>
      <div className={spacing.ml3}>
        <Button onClick={onSubmit} color="error" loading={submitting} loadingText="Deleting...">
          Delete
        </Button>
      </div>
    </DialogFooter>
  </Dialog>
);

export default SupplierDeleteModal;
