/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { nanoid } from 'nanoid';
import classNames from 'classnames';
import { MdCloudUpload } from 'react-icons/md';
import { useDropzone, Accept, FileRejection } from 'react-dropzone';
import useToast from '@hooks/useToast';
import Icon from '@components/Icon';
import { DroppedFile } from './types';
import styles from './fileUpload.module.scss';

interface DropzoneProps {
  accept?: Accept;
  multiple: boolean;
  disabled: boolean;
  onDrop: (files: DroppedFile[]) => void;
}

const ERRORS = {
  INVALID_FILE_TYPE: 'file-invalid-type',
  TOO_MANY_FILES: 'too-many-files',
} as const;

const Dropzone = ({ accept, multiple, disabled, onDrop }: DropzoneProps) => {
  const toast = useToast();

  const formatAcceptTypesMessage = () => {
    if (accept) {
      return `The following formats are supported ${Object.keys(accept).join(', ')}`;
    }

    return '';
  };

  const handleUpload = (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    // error when trying to upload multiple files but only one is allowed
    const tooManyFiles = rejectedFiles.some((rejectedFile) =>
      rejectedFile.errors.some(({ code }) => code === ERRORS.TOO_MANY_FILES)
    );
    if (!multiple && rejectedFiles.length > 0 && tooManyFiles) {
      toast.error('Only single file is allowed for upload');

      return;
    }

    // error when all the added files have not supported type
    const allFilesHaveInvalidFileType = rejectedFiles.every((rejectedFile) =>
      rejectedFile.errors.some(({ code }) => code === ERRORS.INVALID_FILE_TYPE)
    );
    if (rejectedFiles.length && !acceptedFiles.length && allFilesHaveInvalidFileType) {
      toast.error(`Uploaded file format is not supported. ${formatAcceptTypesMessage()}`);

      return;
    }

    // error when some of added files has not supported type
    const someFileHasInvalidFileType = rejectedFiles.some((rejectedFile) =>
      rejectedFile.errors.some(({ code }) => code === ERRORS.INVALID_FILE_TYPE)
    );
    if (rejectedFiles.length && acceptedFiles.length && someFileHasInvalidFileType) {
      toast.warning(
        `Some files were not accepted due to unsupported format. ${formatAcceptTypesMessage()}`
      );
    }

    if (acceptedFiles.length > 0) {
      const files: DroppedFile[] = acceptedFiles.map((file) => ({
        id: nanoid(10),
        file,
        filename: file.name,
        size: file.size,
      }));

      onDrop(files);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleUpload,
    accept,
    disabled,
    multiple,
  });

  return (
    <div
      {...getRootProps({
        'aria-label': 'dropzone',
        role: 'button',
        tabIndex: 0,
        className: classNames(styles.dropzone, {
          [styles.active]: isDragActive,
          [styles.disabled]: disabled,
        }),
      })}
    >
      <Icon component={MdCloudUpload} />
      <input data-testid="dropzone" {...getInputProps()} />
      <div className="m-l-12">
        Drop file here to upload or <span className={styles.underline}>choose file</span> to upload
      </div>
    </div>
  );
};

export default Dropzone;
