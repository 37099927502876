import React from 'react';
// libraries
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// components
import Tooltip from 'components/atoms/Tooltip';
// helpers
import { getTimezoneOffsetTime, getTimezoneTime } from 'helpers/RelativeTimeFormatter';

const propTypes = {
  date: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
  label: PropTypes.string,
  tooltipPlacement: PropTypes.string,
};

const defaultProps = {
  label: '',
  tooltipPlacement: 'bottom',
};

const TimezoneDate = ({ date, timezone, label, tooltipPlacement }) => {
  const { t } = useTranslation();
  if (!date) {
    return null;
  }

  const timezoneDate = `${t('projectTimezone')}: ${getTimezoneOffsetTime(date, timezone)}`;

  return (
    <Tooltip placement={tooltipPlacement} title={timezoneDate}>
      <span>
        {label ? `${label} ` : ''}
        {getTimezoneTime(date)}
      </span>
    </Tooltip>
  );
};

TimezoneDate.propTypes = propTypes;
TimezoneDate.defaultProps = defaultProps;

export default TimezoneDate;
