import { useState, useEffect, useCallback } from 'react';
// libraries
import { gql } from '@apollo/client';
import getProperty from 'lodash/get';
import moment from 'moment';
// services
import client from 'services/Client';
import logger from 'services/Logger';
// queries
import { MatchMetric } from 'queries/Fragments';
// helpers
import { fetchClearances } from 'helpers/Clearance';
import parseCandidate from 'helpers/CandidateParser';
// constants
import { CANDIDATE_STATUS } from 'constants/candidateStatus';
import { CANDIDATE_STATUS_FILTERS, CANDIDATE_LAST_UPDATE_FILTERS } from 'constants/filters';
import { ORDER, SORT_BY, FILTER_BY } from './constants';

const GET_ALREADY_SUBMITTED_CANDIDATES = gql`
  query getAlreadySubmitted($positionId: ID!, $supplierId: ID!) {
    position(id: $positionId) {
      id
      submissions(filter: { candidate: { supplier: { id: { equals: $supplierId } } } }) {
        items {
          id
          candidate {
            id
          }
        }
      }
    }
  }
`;

const fetchAlreadySubmittedCandidates = async (positionId, supplierId) => {
  try {
    const response = await client.query({
      query: GET_ALREADY_SUBMITTED_CANDIDATES,
      variables: {
        positionId,
        supplierId,
      },
      fetchPolicy: 'cache-first',
    });

    const submissions = getProperty(response, 'data.position.submissions.items', []);

    return submissions.map(submission => submission.candidate.id);
  } catch (error) {
    logger.exception(error, { positionId, supplierId });

    return [];
  }
};

const GET_CANDIDATES_POOL_COUNT = gql`
  query getCandidatesPoolCount($supplierId: ID!) {
    candidatesList(filter: { supplier: { id: { equals: $supplierId } } }) {
      count
    }
  }
`;

const GET_CANDIDATES_MATCH = gql`
  query getCandidatesMatch(
    $filter: CandidateMatchFilter!
    $sort: [CandidateMatchSort!]
    $skip: Int!
  ) {
    candidateMatchesList(filter: $filter, sort: $sort, first: 20, skip: $skip) {
      count
      items {
        id
        candidate {
          id
          status
          availableFrom
          createdAt
          lastUpdate
          expectedRate {
            id
            value
            currency
          }
          profile {
            id
            fullName
            profession
            willingToRelocate
            location {
              id
              city
              country {
                id
                name
              }
            }
          }
        }
        report {
          id
          score
          candidateReport {
            id
            createdAt
            cvReadabilityLevel {
              id
              level
            }
            shCandidateId
            cv {
              id
              name
              boxId
            }
          }
        }
        metrics {
          items {
            ...MatchMetric
          }
        }
      }
    }
  }
  ${MatchMetric}
`;

const formatFilter = (positionId, supplierId, alreadySubmittedCandidates, filterBy = {}) => {
  const selectedClearances = filterBy[FILTER_BY.CLEARANCE] || [];
  const selectedStatuses = filterBy[FILTER_BY.STATUS] || [];
  const lastCvUpdate = filterBy[FILTER_BY.LAST_CV_UPDATE];
  const location = filterBy[FILTER_BY.LOCATION];

  const filter = {
    position: { id: { equals: positionId } },
    candidate: {
      supplier: { id: { equals: supplierId } },
      deleted: { equals: false },
      isInPool: { equals: true },
    },
    AND: [],
  };

  // don't fetch already submitted candidates
  if (alreadySubmittedCandidates.length > 0) {
    filter.candidate.id = { not_in: alreadySubmittedCandidates };
  }

  // search by candidate name
  if (filterBy[FILTER_BY.FULL_TEXT]) {
    filter.candidate.profile = {
      OR: [
        {
          fullName: {
            contains: filterBy[FILTER_BY.FULL_TEXT],
          },
        },
        {
          email: {
            contains: filterBy[FILTER_BY.FULL_TEXT],
          },
        },
        {
          profession: {
            contains: filterBy[FILTER_BY.FULL_TEXT],
          },
        },
        {
          description: {
            contains: filterBy[FILTER_BY.FULL_TEXT],
          },
        },
      ],
    };
  }

  // filter by clearance
  if (selectedClearances.length > 0) {
    filter.AND.push({
      candidate: {
        profile: {
          clearance: {
            type: {
              id: {
                in: selectedClearances,
              },
            },
          },
        },
      },
    });
  }

  // filter by status
  if (selectedStatuses.length > 0) {
    const statusFilters = [];

    selectedStatuses.forEach(status => {
      if (status === CANDIDATE_STATUS_FILTERS.AVAILABLE) {
        statusFilters.push({
          OR: [
            { candidate: { status: { equals: CANDIDATE_STATUS.AVAILABLE } } },
            {
              AND: [
                { candidate: { status: { equals: CANDIDATE_STATUS.AVAILABLE_FROM } } },
                { candidate: { availableFrom: { lte: moment().format('YYYY-MM-DD') } } },
              ],
            },
          ],
        });
      }

      if (status === CANDIDATE_STATUS_FILTERS.UNAVAILABLE) {
        statusFilters.push({
          OR: [
            { candidate: { status: { equals: CANDIDATE_STATUS.UNAVAILABLE } } },
            {
              AND: [
                { candidate: { status: { equals: CANDIDATE_STATUS.AVAILABLE_FROM } } },
                { candidate: { availableFrom: { gte: moment().format('YYYY-MM-DD') } } },
              ],
            },
          ],
        });
      }

      if (status === CANDIDATE_STATUS_FILTERS.AVAILABLE_SOON) {
        // 1 - 6 weeks
        statusFilters.push({
          AND: [
            { candidate: { status: { equals: CANDIDATE_STATUS.AVAILABLE_FROM } } },
            { candidate: { availableFrom: { gte: moment().format('YYYY-MM-DD') } } },
            {
              candidate: {
                availableFrom: {
                  lt: moment()
                    .add(6, 'weeks')
                    .format('YYYY-MM-DD'),
                },
              },
            },
          ],
        });
      }

      if (status === CANDIDATE_STATUS_FILTERS.AVAILABLE_LATER) {
        // > 6 weeks
        statusFilters.push({
          AND: [
            { candidate: { status: { equals: CANDIDATE_STATUS.AVAILABLE_FROM } } },
            {
              candidate: {
                availableFrom: {
                  gte: moment()
                    .add(6, 'weeks')
                    .format('YYYY-MM-DD'),
                },
              },
            },
          ],
        });
      }

      return null;
    });

    filter.AND.push({ OR: statusFilters });
  }

  // filter by last CV update
  if (lastCvUpdate) {
    // use current day instead of current time to allow cache filtered results
    const currentDay = moment().startOf('day');
    let interval;

    switch (lastCvUpdate) {
      case CANDIDATE_LAST_UPDATE_FILTERS.LAST_30DAYS:
        interval = {
          gte: currentDay.subtract(30, 'days').toISOString(),
        };
        break;
      case CANDIDATE_LAST_UPDATE_FILTERS.LAST_3MONTHS:
        interval = {
          gte: currentDay.subtract(3, 'months').toISOString(),
        };
        break;
      case CANDIDATE_LAST_UPDATE_FILTERS.LAST_6MONTHS:
        interval = {
          gte: currentDay.subtract(6, 'months').toISOString(),
        };
        break;
      case CANDIDATE_LAST_UPDATE_FILTERS.LAST_YEAR:
        interval = {
          gte: currentDay.subtract(1, 'years').toISOString(),
        };
        break;
      case CANDIDATE_LAST_UPDATE_FILTERS.MORE_THAN_YEAR:
        interval = {
          lte: currentDay.subtract(1, 'years').toISOString(),
        };
        break;
      default:
    }

    filter.AND.push({
      report: {
        candidateReport: {
          createdAt: interval,
        },
      },
    });
  }

  // filter by location
  if (location) {
    filter.AND.push({
      candidate: {
        profile: {
          location: {
            id: {
              equals: location,
            },
          },
        },
      },
    });
  }

  return filter;
};

const formatSort = value => {
  let sort;

  switch (value) {
    case SORT_BY.SCORE:
      sort = { report: { score: ORDER.DESC } };
      break;
    case SORT_BY.NAME:
      sort = { candidate: { profile: { fullName: ORDER.ASC } } };
      break;
    case SORT_BY.EXPECTED_RATE:
      sort = { candidate: { expectedRate: { value: ORDER.ASC } } };
      break;
    case SORT_BY.CV_READABILITY:
      sort = { report: { candidateReport: { cvReadabilityLevel: { priority: ORDER.DESC } } } };
      break;
    default:
      sort = { report: { score: ORDER.DESC } };
  }

  return [sort, { candidate: { status: ORDER.ASC } }];
};

const parseData = data => {
  const candidate = parseCandidate(getProperty(data, 'candidate'), {});

  return {
    id: data.id,
    candidateId: candidate.id,
    name: candidate.name,
    availableFrom: candidate.availableFrom,
    profession: candidate.profession,
    status: candidate.getStatus(),
    expectedRate: candidate.expectedRate,
    location: candidate.location,
    willingToRelocate: candidate.getWillingToRelocate(),
    score: getProperty(data, 'report.score', 0),
    shCandidateId: getProperty(data, 'report.candidateReport.shCandidateId'),
    cvReadability: getProperty(data, 'report.candidateReport.cvReadabilityLevel.level', ''),
    lastUpdate:
      getProperty(data, 'candidate.lastUpdate', '') || getProperty(data, 'candidate.createdAt', ''),
    metrics: getProperty(data, 'metrics.items', []),
    cv: {
      name: getProperty(data, 'report.candidateReport.cv.name', ''),
      boxId: getProperty(data, 'report.candidateReport.cv.boxId', ''),
      createdAt: getProperty(data, 'report.candidateReport.createdAt', ''),
    },
  };
};

/**
 * @param {string} positionId
 * @param {string} projectId
 * @param {string} supplierId
 * @returns {object}
 */
const useCandidatesMatch = (positionId, projectId, supplierId) => {
  const [hasError, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [count, setCount] = useState(0);
  const [candidatesCount, setCandidatesCount] = useState(0);
  const [data, setData] = useState([]);
  const [clearances, setClearances] = useState([]);
  const [sortedBy, setSortedBy] = useState({ value: SORT_BY.SCORE, order: ORDER.DESC });
  const [filteredBy, setFilteredBy] = useState({});

  const fetchData = async (filter, sort, skip = 0) => {
    try {
      // don't show loader if fetching more items for same filter
      if (!skip) setLoading(true);

      // fetch candidates which are already submitted
      const alreadySubmittedCandidates = await fetchAlreadySubmittedCandidates(
        positionId,
        supplierId
      );

      const response = await client.query({
        query: GET_CANDIDATES_MATCH,
        variables: {
          filter: formatFilter(positionId, supplierId, alreadySubmittedCandidates, filter),
          sort: formatSort(sort.value, sort.order),
          skip,
        },
      });

      const itemsCount = getProperty(response, 'data.candidateMatchesList.count', 0);
      const list = getProperty(response, 'data.candidateMatchesList.items', []);
      const parsedData = list.map(parseData);

      // if fetching more items for the same filter -> merge existing data with the new ones
      const newData = skip ? [...data, ...parsedData] : parsedData;
      setData(newData);
      setCount(itemsCount);

      return parsedData;
    } catch (error) {
      setError(true);
      logger.exception(error, { positionId, supplierId, filter, sort });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchCandidatesPoolCount = async () => {
      try {
        const [fetchedClearances, fetchedData, response] = await Promise.all([
          fetchClearances(projectId),
          fetchData(filteredBy, sortedBy),
          client.query({
            query: GET_CANDIDATES_POOL_COUNT,
            variables: { supplierId },
          }),
        ]);

        const supplierCandidatesCount = getProperty(response, 'data.candidatesList.count', 0);
        setCandidatesCount(supplierCandidatesCount);
        setClearances(fetchedClearances);

        // if no candidate matches -> either they are still processed or
        // partner doesn't have any candidates in pool
        if (!fetchedData.length > 0 && supplierCandidatesCount > 0) {
          setProcessing(true);
        }
      } catch (error) {
        setError(true);
        logger.exception(error, { positionId, supplierId });
        throw error;
      } finally {
        setLoading(false);
      }
    };

    fetchCandidatesPoolCount();
  }, []);

  const fetchMore = useCallback(() => {
    fetchData(filteredBy, sortedBy, data.length);
  }, [filteredBy, sortedBy, data.length]);

  const filter = useCallback(
    selectedFilters => {
      setFilteredBy(selectedFilters);
      fetchData(selectedFilters, sortedBy);
    },
    [sortedBy]
  );

  const sort = useCallback(
    (value, order) => {
      const sortBy = { value, order };

      setSortedBy(sortBy);
      fetchData(filteredBy, sortBy);
    },
    [filteredBy]
  );

  return {
    sort,
    filter,
    fetchMore,
    filteredBy,
    sortedBy,
    loading,
    error: hasError,
    processing,
    data,
    count,
    candidatesCount,
    clearances,
  };
};

export default useCandidatesMatch;
