import getProperty from 'lodash/get';
import { FILES_HISTORY, RATES_HISTORY } from './constants';

const parseRevision = revision => {
  if (!revision) {
    return null;
  }
  const note = getProperty(revision, 'reason.note');
  const requestor = getProperty(revision, 'revision.createdBy.alakaUser.person.fullName');
  const date = getProperty(revision, 'createdAt');
  return {
    note,
    requestor,
    date,
  };
};

const parseAttachments = data => {
  const files = getProperty(data, 'attachmentsList.items', []);
  return files.map(({ id, boxId, createdAt, ...file }, index) => {
    const revision = parseRevision(getProperty(file, 'revisionType'));
    const fileName = getProperty(file, 'name');
    const name =
      getProperty(file, 'author.person.fullName') ||
      getProperty(file, 'createdBy.alakaUser.person.fullName');
    return {
      id,
      date: createdAt,
      fileName,
      name,
      boxId,
      version: files.length - index,
      revision,
    };
  });
};

const parseRates = data => {
  const rateGroups = getProperty(data, 'rateGroupsList.items', []);
  return rateGroups.map(({ id, createdAt, ...group }, index) => {
    const revision = parseRevision(getProperty(group, 'revision'));
    const rates = getProperty(group, 'rates.items', []);
    const name = getProperty(group, 'createdBy.alakaUser.person.fullName');
    return {
      id,
      date: createdAt,
      name,
      rates,
      version: rateGroups.length - index,
      revision,
    };
  });
};

export const parseData = (data, type) => {
  if (FILES_HISTORY.includes(type)) {
    return parseAttachments(data);
  }
  if (RATES_HISTORY.includes(type)) {
    return parseRates(data);
  }
  return null;
};
