/* eslint-disable import/order */
import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
// hooks
import useAuth from 'hooks/useAuth';
// components
import TextIcon from 'components/atoms/TextIcon';
import CardSection from 'components/molecules/CardSection';
import Notes from './Notes';
import Rates from './Rates';
import Documents from './Documents';
import DocumentSettings from './DocumentSettings';
import Revisions from './Revisions';
// helpers
import { getTime, getTimeYear } from 'helpers/RelativeTimeFormatter';
// constants
import {
  MANAGER_ROLE,
  NOTE_IBM_EXPLANATION,
  RATES_SUPPLIER_RATE,
  RATES_SELL_PRICE,
} from 'constants';
// styles
import styles from './submissionDetails.module.scss';

const propTypes = {
  submission: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
};

const SubmissionDetails = ({ submission, position }) => {
  const { t } = useTranslation();
  const { role } = useAuth();

  const { candidate, availability } = submission;
  const partnerRates = submission.getRates(RATES_SUPPLIER_RATE);
  const sellRates = submission.getRates(RATES_SELL_PRICE);
  const hasRevision = submission.revisions.length > 0 && role !== MANAGER_ROLE;
  const ibmExplanationNote = submission.getNote(NOTE_IBM_EXPLANATION);

  return (
    <Grid container spacing={16}>
      <Grid item xs={12} md={12} lg={4}>
        <div className={styles.section}>
          <CardSection bordered title={t('contactInfo')}>
            <TextIcon icon="email">{candidate.email}</TextIcon>
            {candidate.phone && (
              <div className="m-t-5">
                <TextIcon icon="phone">{candidate.phone}</TextIcon>
              </div>
            )}
            {candidate.skype && (
              <div className="m-t-5">
                <TextIcon icon="skype">{candidate.skype}</TextIcon>
              </div>
            )}
            {candidate.linkedIn && (
              <div className="m-t-5">
                <TextIcon icon="linkedIn">{candidate.linkedIn}</TextIcon>
              </div>
            )}
          </CardSection>
        </div>
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <div className={styles.section}>
          <CardSection bordered title={t('availability')}>
            <TextIcon icon="calendar">
              {availability.value && (
                <Trans i18nKey="submissionAvailability">
                  Submission available in <b>{{ availableInDate: availability.label }}</b>
                </Trans>
              )}
              {availability.start && (
                <React.Fragment>
                  {availability.value ? ', ' : ''}
                  {t('earliestAvailableDate')} <b>{getTime(availability.start)}</b>
                </React.Fragment>
              )}
            </TextIcon>
            {candidate.citizenship && (
              <div className="m-t-5">
                <TextIcon icon="location">
                  <Trans i18nKey="countryOfCitizenship">
                    Country of citizenship <b>{{ country: candidate.citizenship }}</b>
                  </Trans>
                </TextIcon>
              </div>
            )}
            {candidate.exIBM && (
              <div className="m-t-5">
                <TextIcon icon="company">
                  <Trans i18nKey="exIBM">
                    Is ex IBMer:
                    <b>Yes</b>
                  </Trans>
                  {ibmExplanationNote && <span> - {ibmExplanationNote.text}</span>}
                </TextIcon>
              </div>
            )}
          </CardSection>
        </div>
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <div className={styles.section}>
          <CardSection bordered title={t('clearanceInformation')}>
            <TextIcon icon="info">
              {t('clearanceType')}: <b>{candidate.clearance.type}</b>
            </TextIcon>
            {candidate.clearance.validFrom && (
              <div className="m-t-5">
                <TextIcon icon="info">
                  {t('clearanceIssueDate')}: <b>{getTimeYear(candidate.clearance.validFrom)}</b>
                </TextIcon>
              </div>
            )}
            {candidate.clearance.validUntil && (
              <div className="m-t-5">
                <TextIcon icon="info">
                  {t('clearanceValidUntil')}: <b>{getTimeYear(candidate.clearance.validUntil)}</b>
                </TextIcon>
              </div>
            )}
            {candidate.clearance.transferRequired && (
              <div className="m-t-5">
                <TextIcon icon="info">
                  {t('clearanceTransferRequired')}: <b>{t('yes')}</b>
                </TextIcon>
              </div>
            )}
            {candidate.clearance.country && (
              <div className="m-t-5">
                <TextIcon icon="info">
                  {t('clearanceCountry')}: <b>{candidate.clearance.country}</b>
                </TextIcon>
              </div>
            )}
            {candidate.clearance.note && (
              <div className="m-t-5">
                <TextIcon icon="info">
                  {t('clearanceNotes')}: {candidate.clearance.note}
                </TextIcon>
              </div>
            )}
          </CardSection>
        </div>
      </Grid>
      <Grid container item xs md={12} lg>
        <Grid container spacing={16}>
          <Grid item xs={12} md={12} lg={hasRevision ? 12 : 6}>
            <div className={styles.section}>
              <CardSection bordered title={t('rates')}>
                <Rates partnerRates={partnerRates} sellRates={sellRates} role={role} />
              </CardSection>
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={hasRevision ? 12 : 6}>
            <div className={styles.section}>
              <CardSection bordered title={t('attachments')}>
                <div className={styles.documentsContainer}>
                  <Documents submission={submission} role={role} />
                  <div className="m-l-20">
                    <DocumentSettings submission={submission} position={position} />
                  </div>
                </div>
              </CardSection>
            </div>
          </Grid>
        </Grid>
      </Grid>
      {hasRevision && (
        <Grid item xs={12} md={12} lg={6}>
          <div className={styles.section}>
            <CardSection bordered noPadding title={t('revisions')}>
              <div className={styles.revisions}>
                <Revisions submission={submission} />
              </div>
            </CardSection>
          </div>
        </Grid>
      )}
      <Grid item xs={12} md={12}>
        <div className={styles.section}>
          <CardSection bordered title={t('notes')}>
            <Notes submission={submission} role={role} />
          </CardSection>
        </div>
      </Grid>
    </Grid>
  );
};

SubmissionDetails.propTypes = propTypes;

export default SubmissionDetails;
