import React from 'react';
import PropTypes from 'prop-types';
import getProperty from 'lodash/get';
// components
import Score from 'components/atoms/Score';
// styles
import './scoreList.scss';

const propTypes = {
  className: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.object),
  name: PropTypes.string.isRequired,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
};

const defaultProps = {
  className: '',
  list: [],
  selected: {},
  onChange: () => {},
};

const ScoreList = props => {
  const { className, list, name, onChange, selected } = props;

  return (
    <div className={`${className} scoreList test_${name}`}>
      {list.map(item => {
        const { score, label, value } = item;
        const isItemSelected = value === getProperty(selected, 'value');
        return (
          <div
            role="presentation"
            className={`scoreList_item ${isItemSelected ? 'selected' : ''}`}
            onClick={() => onChange(item, name)}
            key={value}
          >
            <Score score={score} white={isItemSelected} />
            <div className="p-l-12">{label}</div>
          </div>
        );
      })}
    </div>
  );
};

ScoreList.propTypes = propTypes;
ScoreList.defaultProps = defaultProps;

export default ScoreList;
