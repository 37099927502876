import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
import getProperty from 'lodash/get';
// components
import Checkbox from 'components/atoms/Checkbox';
import CollapsibleContent from 'components/molecules/CollapsibleContent';
// helpers
import { getNewSelectedOptionsByCheck, getNewSelectedOptionsByUncheck } from './helpers';
// styles
import styles from './checkboxList.module.scss';

const propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
          disabled: PropTypes.bool,
        })
      ),
    })
  ).isRequired,
  selected: PropTypes.arrayOf(PropTypes.string.isRequired),
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  selected: [],
};

const CheckboxList = ({ options, selected, onChange }) => {
  const handleChange = (option, parentOption) => checked => {
    const newOptions = checked
      ? getNewSelectedOptionsByCheck(option, selected, parentOption)
      : getNewSelectedOptionsByUncheck(option, selected, parentOption);

    onChange(newOptions);
  };

  const isOptionChecked = option => {
    // at least one sub-option is selected
    const isSomeSubOptionChecked = option.items.some(subOption =>
      selected.includes(subOption.value)
    );

    // all sub-options are selected or disabled
    const isAllSubOptionsCheckedOrDisabled = option.items.every(
      subOption => subOption.disabled || selected.includes(subOption.value)
    );

    return (
      selected.includes(option.value) ||
      (!option.disabled && isSomeSubOptionChecked && isAllSubOptionsCheckedOrDisabled)
    );
  };

  return (
    <div>
      {options.map((option, index) => {
        // if options has sub-options
        if (getProperty(option, 'items', []).length > 0) {
          // expand sub-options on render
          const showSubOptions =
            option.items.some(subOption => selected.includes(subOption.value)) &&
            !selected.includes(option.value);

          return (
            <div className={classNames({ 'm-t-12': index })} key={option.value}>
              <CollapsibleContent
                collapsed={!showSubOptions}
                contentClassName={styles.subOptions}
                fixedSize={false}
                header={
                  <Checkbox
                    label={option.label}
                    value={option.value}
                    disabled={option.disabled}
                    checked={isOptionChecked(option)}
                    onChange={handleChange(option)}
                  />
                }
              >
                {option.items.map(subOption => (
                  <div className="m-t-12" key={subOption.value}>
                    <Checkbox
                      label={subOption.label}
                      value={subOption.value}
                      disabled={subOption.disabled}
                      checked={
                        selected.includes(subOption.value) ||
                        (selected.includes(option.value) && !subOption.disabled)
                      }
                      onChange={handleChange(subOption, option)}
                    />
                  </div>
                ))}
              </CollapsibleContent>
            </div>
          );
        }

        return (
          <div className={classNames({ 'm-t-12': index })} key={option.value}>
            <Checkbox
              label={option.label}
              value={option.value}
              disabled={option.disabled}
              checked={selected.includes(option.value)}
              onChange={handleChange(option)}
            />
          </div>
        );
      })}
    </div>
  );
};

CheckboxList.propTypes = propTypes;
CheckboxList.defaultProps = defaultProps;

export default CheckboxList;
