import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// hooks
import useAuth from 'hooks/useAuth';
import useItemsCount from 'hooks/useItemsCount';
// components
import Page from 'components/templates/Page';
import DraftList from 'components/organisms/DraftList';
// constants
import { COUNT_DRAFT } from 'constants';
// helpers
import { getFilterByRole } from './helpers';
// data
import DraftSort from './data/DraftSort';

const propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const DraftPage = ({ location, history }) => {
  const { t } = useTranslation();
  const { id, role } = useAuth();
  const itemsCount = useItemsCount(COUNT_DRAFT);

  const filterBy = getFilterByRole(role);

  return (
    <div className="scrollableContent" id="scrollableContent">
      <Page
        headerName={t('drafts')}
        noClearFilters
        sortList={DraftSort}
        defaultSort={DraftSort[0]}
        count={itemsCount}
        searchPlaceholder={t('searchDrafts')}
        className="dashboard-container fs-unmask"
        location={location}
        history={history}
        content={
          <div className="p-t-20">
            <DraftList userId={id} filteredBy={filterBy} />
          </div>
        }
      />
    </div>
  );
};

DraftPage.propTypes = propTypes;

export default DraftPage;
