export const ONBOARDING_TAB = {
  JOB_OFFER: 'HIRING_JOB_OFFER',
  START_DATE: 'HIRING_START_DATE',
  FORMS: 'HIRING_ONBOARDING',
  PO: 'HIRING_PO',
};

export const HIRING_PROCESS_STATUS = {
  PENDING: 'PENDING',
  FINISHED: 'FINISHED',
  CANCELLED: 'CANCELLED',
};

export const ONBOARDING_STATUS_DATE = {
  PROPOSE: 'START_DATE_PROPOSE',
  SUBMIT: 'START_DATE_SUBMIT',
  APPROVE: 'START_DATE_APPROVE',
};

export const ONBOARDING_STATUS_FORMS = {
  SUBMIT: 'ONBOARDING_SUBMIT',
  SEND_TO_PM: 'ONBOARDING_CONFIRM',
  SEND_TO_AUSY: 'ONBOARDING_AUSY',
};

export const ONBOARDING_STATUS_PO = {
  RELEASE: 'PO_RELEASE',
  CONFIRM: 'PO_CONFIRM',
};

export const START_DATE_STATUS = {
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  PENDING: 'PENDING',
};

export const START_DATE_TYPE = {
  EXPECTED: 'EXPECTED_START_DATE',
  SUPPLIER: 'SUPPLIER_START_DATE',
};
