import {
  STAFF_ROLE,
  SUPPLIER_ROLE,
  MANAGER_ROLE,
  CANDIDATE_TYPE,
  PROPOSAL_TYPE,
  PROJECT_TYPE,
} from 'constants';
import { POSITION_FORM_TYPE } from 'constants/form';

export const getFilterByRole = role => {
  if (role === SUPPLIER_ROLE) {
    return [CANDIDATE_TYPE, PROPOSAL_TYPE];
  }
  if (role === STAFF_ROLE) {
    return [CANDIDATE_TYPE, PROPOSAL_TYPE, PROJECT_TYPE];
  }
  if (role === MANAGER_ROLE) {
    return [POSITION_FORM_TYPE.TIME_AND_MATERIALS, POSITION_FORM_TYPE.FIXED_PRICE];
  }
  return null;
};
