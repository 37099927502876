import React from 'react';
// libraries
import useAuth from 'hooks/useAuth';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// components
import Documents from '../Documents';
// helpers
import { getFiles, getNotes } from './helpers';

const propTypes = {
  submission: PropTypes.object.isRequired,
  date: PropTypes.object,
};

const defaultProps = {
  date: null,
};

const JobOffer = ({ submission, date }) => {
  const { t } = useTranslation();
  const { role } = useAuth();
  const files = getFiles(submission, role);
  const notes = getNotes(submission, date, role);

  if (files.length > 0 || notes.length > 0) {
    return (
      <div className="m-t-20 w100">
        <Documents title={t('offer&notes')} files={files} notes={notes} darker />
      </div>
    );
  }

  return null;
};

JobOffer.propTypes = propTypes;
JobOffer.defaultProps = defaultProps;

export default JobOffer;
