import colors from './Colors';

export const activeCheckboxStyle = {
  iconStyle: {
    marginRight: '8px',
    height: '18px',
  },
  iconChecked: {
    paddingTop: '2px',
    height: '17px',
    width: '17px',
    fill: colors.blue,
  },
  iconUnChecked: {
    paddingTop: '2px',
    height: '17px',
    width: '17px',
    fill: colors.gray,
  },
  labelStyle: {
    lineHeight: '14px',
    fontSize: '14px',
    fontFamily: 'Lato-Light',
    // color: colors['gray'],
    display: 'flex',
    alignItems: 'center',
  },
  style: {
    display: 'flex',
  },
  root: {
    color: colors.blue,
  },
};

export const disabledCheckboxStyle = {
  ...activeCheckboxStyle,
  labelStyle: {
    ...activeCheckboxStyle.labelStyle,
    color: colors.gray7,
  },
};
