import React from 'react';
// libraries
import PropTypes from 'prop-types';
// components
import TableOption from 'components/molecules/TableOption';
import DropdownCheckbox from 'components/molecules/DropdownCheckbox';

const propTypes = {
  tableName: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  selected: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  checkedLabel: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

const defaultProps = {
  selected: null,
  label: '',
};

const SelectSchemaOptions = props => {
  const { tableName, fieldName, selected, onChange, label, checkedLabel, name } = props;
  return (
    <TableOption tableName={tableName} fieldName={fieldName}>
      {({ loading, values }) => {
        return (
          <DropdownCheckbox
            list={values}
            loading={loading}
            selected={selected}
            onChange={onChange}
            label={label}
            checkedLabel={checkedLabel}
            name={name}
          />
        );
      }}
    </TableOption>
  );
};

SelectSchemaOptions.propTypes = propTypes;
SelectSchemaOptions.defaultProps = defaultProps;

export default SelectSchemaOptions;
