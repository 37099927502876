import React, { useContext } from 'react';
// libraries
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
// context
import ToastContext from 'context/ToastContext';
// services
import logger from 'services/Logger';
// queries
import { GET_NOTIFICATION_EVENTS } from 'queries/NotificationQueries';
// components
import ErrorPage from 'components/pages/ErrorPage';
import NotificationEventsLoader from 'components/atoms/ContentLoader/NotificationEventsLoader';
import NotificationContent from './NotificationContent';
// helpers
import {
  parseEvents,
  toggleNotificationEvent,
  toggleNotificationEventList,
  getGroupEvents,
} from './Helpers';
// styles
import './notificationSettings.scss';

const NotificationSettings = () => {
  const { loading, error, data } = useQuery(GET_NOTIFICATION_EVENTS, {
    fetchPolicy: 'cache-first',
  });
  const { addToast } = useContext(ToastContext);
  const { t } = useTranslation();

  if (loading) {
    return <NotificationEventsLoader />;
  }

  if (error) return <ErrorPage code={500} />;

  const { tabs, eventList } = parseEvents(data);

  if (tabs.length === 0) {
    return t('noNotificationEventsToShow');
  }

  const onChange = async (enabled, type, eventItem, isHeader) => {
    const eventVariables = { ...eventItem, [type]: { ...eventItem[type], enabled } };
    const enumType = type.toUpperCase();

    try {
      if (isHeader) {
        const { events, optimisticEvents } = getGroupEvents({
          enabled,
          type,
          eventList,
          eventItem,
        });
        await toggleNotificationEventList({
          events,
          enabled,
          type: enumType,
          optimisticEvents,
        });
      } else {
        await toggleNotificationEvent({ enabled, type: enumType, eventVariables });
      }
    } catch (err) {
      addToast.error(t('errorWhileEnablingNotificationPreference'));
      logger.exception(err);
    }
  };

  return <NotificationContent tabs={tabs} eventList={eventList} onChange={onChange} />;
};

export default NotificationSettings;
