import React from 'react';
import { processingTableList } from './Texts';

const ProcessingTable = () => {
  return (
    <div className="processingTable">
      {processingTableList.map((item, index) => {
        return (
          <div key={`${index}_item`} className="processingTable_item">
            {item}
          </div>
        );
      })}
    </div>
  );
};

export default ProcessingTable;
