import { gql } from '@apollo/client';
import getProperty from 'lodash/get';
// services
import client from 'services/Client';
import logger from 'services/Logger';

const FIND_CANDIDATE_IDENTITY = gql`
  query finCandidateIdentity($supplierId: ID!, $shPersonId: String, $shCandidateId: String!) {
    candidatesList(
      first: 1
      filter: {
        supplier: { id: { equals: $supplierId } }
        reports: {
          some: {
            OR: [
              {
                AND: [{ shPersonId: { equals: $shPersonId } }, { shPersonId: { is_empty: false } }]
              }
              { shPersonId: { is_empty: true }, shCandidateId: { equals: $shCandidateId } }
            ]
          }
        }
      }
    ) {
      items {
        id
      }
    }
  }
`;

/**
 * Resolve candidate based on SH person and candidate id
 * @param {string} shPersonId
 * @param {string} shCandidateId
 * @returns {Promise<?string>} Candidate Id or null
 */
export const resolveCandidateIdentity = async (supplierId, shPersonId, shCandidateId) => {
  try {
    const response = await client.query({
      query: FIND_CANDIDATE_IDENTITY,
      variables: { supplierId, shPersonId, shCandidateId },
    });

    return getProperty(response, 'data.candidatesList.items.0.id');
  } catch (error) {
    logger.exception(error, { supplierId, shPersonId, shCandidateId });
    throw error;
  }
};
