import React, { useContext, useState } from 'react';
// libraries
import PropTypes from 'prop-types';
// hooks
import { useTranslation } from 'react-i18next';
// context
import ToastContext from 'context/ToastContext';
// services
import logger from 'services/Logger';
// constants
import { SUPPLIER_ROLE, STAFF_ROLE } from 'constants';
import { POSITION_STATUS } from 'constants/positionStatus';
// helpers
import { fetchPositionStatus } from 'helpers/Position';
// components
import { SelectWinner } from 'components/organisms/PositionSettings';
import Loader from 'components/atoms/Loader';
import Action from './Action';
import Resolution from './Resolution';

const propTypes = {
  position: PropTypes.object.isRequired,
  positionStatus: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
};

const SelectionActions = ({ position, role, positionStatus, ...resolutionProps }) => {
  const { addToast } = useContext(ToastContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isSelectWinnerOpen, setIsSelectWinnerOpen] = useState(false);
  const { t } = useTranslation();
  const { id } = position;

  const hasSubmissions = () => {
    return positionStatus === POSITION_STATUS.PENDING_SELECTION;
  };

  const handleSelectWinnerClick = async () => {
    try {
      setIsLoading(true);
      const actualPositionStatus = await fetchPositionStatus(id);

      if (
        ![POSITION_STATUS.PENDING_SELECTION, POSITION_STATUS.NO_SUBMISSIONS].includes(
          actualPositionStatus
        )
      ) {
        addToast.warning(t('positionNotInSelectionState'));

        return;
      }

      setIsSelectWinnerOpen(true);
    } catch (error) {
      addToast.error(t('errorPlaceholderText'));
      logger.exception(error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loader small fixedSize />;
  }

  if (role === STAFF_ROLE) {
    return (
      <div>
        <Action
          label={t('selectWinner')}
          tooltip={t('tooltipSelectWinner')}
          onClick={handleSelectWinnerClick}
        />
        <SelectWinner
          isOpen={isSelectWinnerOpen}
          hasSubmissions={hasSubmissions()}
          position={position}
          onClose={() => setIsSelectWinnerOpen(false)}
        />
      </div>
    );
  }
  if (role === SUPPLIER_ROLE) {
    return (
      <Resolution
        label={t('resolutionWaitingForFeedback')}
        tooltip={t('tooltipWaitingForFeedback')}
        {...resolutionProps}
      />
    );
  }

  return null;
};

SelectionActions.propTypes = propTypes;

export default SelectionActions;
