import i18n from 'i18next';
// constants
import { STAFF_ROLE, SUPPLIER_ROLE, MANAGER_ROLE } from 'constants';
import {
  DUE_DATE_FILTERS as DUE_DATE_FILTERS_OPTIONS,
  SUBMISSION_STATUS_FILTERS,
} from 'constants/filters';

export const DUE_DATE_FILTERS = [
  {
    label: i18n.t('dueToday'),
    value: DUE_DATE_FILTERS_OPTIONS.DUE_TODAY,
  },
  {
    label: i18n.t('dueTomorrow'),
    value: DUE_DATE_FILTERS_OPTIONS.DUE_TOMORROW,
  },
  {
    label: i18n.t('dueInNext7Days'),
    value: DUE_DATE_FILTERS_OPTIONS.DUE_NEXT_7_DAYS,
  },
  {
    label: i18n.t('expired'),
    value: DUE_DATE_FILTERS_OPTIONS.EXPIRED,
  },
  {
    label: i18n.t('notExpired'),
    value: DUE_DATE_FILTERS_OPTIONS.NOT_EXPIRED,
  },
];

const PARTNER_SUBMISSION_STATUS_FILTERS = [
  {
    label: i18n.t('new'),
    value: SUBMISSION_STATUS_FILTERS.NEW,
    items: [],
  },
  {
    label: i18n.t('submittedToClient'),
    value: SUBMISSION_STATUS_FILTERS.SUBMITTED_TO_CLIENT,
    items: [],
  },
  {
    label: i18n.t('notSubmittedToClient'),
    value: SUBMISSION_STATUS_FILTERS.NOT_SUBMITTED_TO_CLIENT,
    items: [],
  },
  {
    label: i18n.t('notSelectedByClient'),
    value: SUBMISSION_STATUS_FILTERS.NOT_SELECTED_BY_CLIENT,
    items: [],
  },
  {
    label: i18n.t('withdrawn'),
    value: SUBMISSION_STATUS_FILTERS.WITHDRAWN,
    items: [],
  },
  {
    label: i18n.t('offer'),
    value: SUBMISSION_STATUS_FILTERS.OFFER,
    items: [],
  },
  {
    label: i18n.t('onboarding'),
    value: SUBMISSION_STATUS_FILTERS.ONBOARDING,
    items: [
      {
        label: i18n.t('pendingStartDate'),
        value: SUBMISSION_STATUS_FILTERS.PENDING_START_DATE,
        items: [],
      },
      {
        label: i18n.t('confirmedStartDate'),
        value: SUBMISSION_STATUS_FILTERS.CONFIRMED_START_DATE,
        items: [],
      },
      {
        label: i18n.t('pendingForms'),
        value: SUBMISSION_STATUS_FILTERS.PENDING_FORMS,
        items: [],
      },
      {
        label: i18n.t('pendingClientPO'),
        value: SUBMISSION_STATUS_FILTERS.PENDING_PO,
        items: [],
      },
      {
        label: i18n.t('onboardingCompleted'),
        value: SUBMISSION_STATUS_FILTERS.ONBOARDING_COMPLETED,
        items: [],
      },
    ],
  },
  {
    label: i18n.t('onboardingCancelled'),
    value: SUBMISSION_STATUS_FILTERS.ONBOARDING_CANCELLED,
    items: [],
  },
  {
    label: i18n.t('delivery'),
    value: SUBMISSION_STATUS_FILTERS.DELIVERY,
    items: [],
  },
];

const MM_SUBMISSION_STATUS_FILTERS = [
  {
    label: i18n.t('new'),
    value: SUBMISSION_STATUS_FILTERS.NEW,
    items: [],
  },
  {
    label: i18n.t('submittedToClient'),
    value: SUBMISSION_STATUS_FILTERS.SUBMITTED_TO_CLIENT,
    items: [],
  },
  {
    label: i18n.t('notSubmittedToClient'),
    value: SUBMISSION_STATUS_FILTERS.NOT_SUBMITTED_TO_CLIENT,
    items: [],
  },
  {
    label: i18n.t('notSelectedByClient'),
    value: SUBMISSION_STATUS_FILTERS.NOT_SELECTED_BY_CLIENT,
    items: [],
  },
  {
    label: i18n.t('withdrawn'),
    value: SUBMISSION_STATUS_FILTERS.WITHDRAWN,
    items: [],
  },
  {
    label: i18n.t('offer'),
    value: SUBMISSION_STATUS_FILTERS.OFFER,
    items: [],
  },
  {
    label: i18n.t('onboarding'),
    value: SUBMISSION_STATUS_FILTERS.ONBOARDING,
    items: [
      {
        label: i18n.t('startDateInProgress'),
        value: SUBMISSION_STATUS_FILTERS.PENDING_START_DATE,
        items: [],
      },
      {
        label: i18n.t('confirmedStartDate'),
        value: SUBMISSION_STATUS_FILTERS.CONFIRMED_START_DATE,
        items: [],
      },
      {
        label: i18n.t('formsInProgress'),
        value: SUBMISSION_STATUS_FILTERS.PENDING_FORMS,
        items: [],
      },
      {
        label: i18n.t('pendingClientPO'),
        value: SUBMISSION_STATUS_FILTERS.PENDING_PO,
        items: [],
      },
      {
        label: i18n.t('onboardingCompleted'),
        value: SUBMISSION_STATUS_FILTERS.ONBOARDING_COMPLETED,
        items: [],
      },
    ],
  },
  {
    label: i18n.t('onboardingCancelled'),
    value: SUBMISSION_STATUS_FILTERS.ONBOARDING_CANCELLED,
    items: [],
  },
  {
    label: i18n.t('delivery'),
    value: SUBMISSION_STATUS_FILTERS.DELIVERY,
    items: [],
  },
];

const PM_SUBMISSION_STATUS_FILTERS = [
  {
    label: i18n.t('new'),
    value: SUBMISSION_STATUS_FILTERS.NEW,
    items: [],
  },
  {
    label: i18n.t('rejected'),
    value: SUBMISSION_STATUS_FILTERS.NOT_SELECTED_BY_CLIENT,
    items: [],
  },
  {
    label: i18n.t('offer'),
    value: SUBMISSION_STATUS_FILTERS.OFFER,
    items: [],
  },
  {
    label: i18n.t('onboarding'),
    value: SUBMISSION_STATUS_FILTERS.ONBOARDING,
    items: [
      {
        label: i18n.t('pendingStartDate'),
        value: SUBMISSION_STATUS_FILTERS.PENDING_START_DATE,
        items: [],
      },
      {
        label: i18n.t('confirmedStartDate'),
        value: SUBMISSION_STATUS_FILTERS.CONFIRMED_START_DATE,
        items: [],
      },
      {
        label: i18n.t('pendingForms'),
        value: SUBMISSION_STATUS_FILTERS.PENDING_FORMS,
        items: [],
      },
      {
        label: i18n.t('pendingPO'),
        value: SUBMISSION_STATUS_FILTERS.PENDING_PO,
        items: [],
      },
      {
        label: i18n.t('onboardingCompleted'),
        value: SUBMISSION_STATUS_FILTERS.ONBOARDING_COMPLETED,
        items: [],
      },
    ],
  },
  {
    label: i18n.t('onboardingCancelled'),
    value: SUBMISSION_STATUS_FILTERS.ONBOARDING_CANCELLED,
    items: [],
  },
  {
    label: i18n.t('delivery'),
    value: SUBMISSION_STATUS_FILTERS.DELIVERY,
    items: [],
  },
];

export const SUBMISSION_STATUS_FILTERS_MAP = {
  [STAFF_ROLE]: MM_SUBMISSION_STATUS_FILTERS,
  [SUPPLIER_ROLE]: PARTNER_SUBMISSION_STATUS_FILTERS,
  [MANAGER_ROLE]: PM_SUBMISSION_STATUS_FILTERS,
};
