import client from '@services/client';
import { gql } from '@services/gql';

const DELETE_SUPPLIER = gql(/* GraphQL */ `
  mutation deleteSupplier($id: ID!) {
    supplierDelete(id: $id) {
      success
    }
  }
`);

const deleteRecord = async (id: string): Promise<void> => {
  await client.mutate({
    mutation: DELETE_SUPPLIER,
    variables: { id },
  });
};

export default deleteRecord;
