import React from 'react';
// libraries
import PropTypes from 'prop-types';
// components
import Tooltip from 'components/atoms/Tooltip';
// styles
import styles from './positionCard.module.scss';

const propTypes = {
  position: PropTypes.object.isRequired,
};

const Title = ({ position }) => {
  return (
    <React.Fragment>
      <div className="display-flex-row">
        <div className={styles.rfq}>{position.rfqLabel}</div>
        <Tooltip title={`${position.client.name} - ${position.project.name}`} placement="bottom">
          <div className={styles.project}>{position.project.shortName}</div>
        </Tooltip>
      </div>
      <div className={styles.title}>{position.title}</div>
    </React.Fragment>
  );
};

Title.propTypes = propTypes;

export default Title;
