import React, { useEffect, useRef } from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// components
import Icon from 'components/atoms/Icon';
import ClickableElement from 'components/atoms/ClickableElement';
// styles
import styles from './input.module.scss';

const propTypes = {
  value: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'password', 'email', 'phone']),
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  classes: PropTypes.shape({
    root: PropTypes.string,
    icon: PropTypes.string,
    input: PropTypes.string,
  }),
  icon: PropTypes.string,
  error: PropTypes.bool,
  autofocus: PropTypes.bool,
};

const defaultProps = {
  type: 'text',
  disabled: false,
  classes: {
    root: '',
    icon: '',
    input: '',
  },
  icon: '',
  error: false,
  autofocus: false,
  onFocus: () => {},
  onBlur: () => {},
};

const Input = ({
  value,
  type,
  disabled,
  classes,
  icon,
  error,
  onChange,
  onFocus,
  onBlur,
  autofocus,
  ...props
}) => {
  const element = useRef(null);

  useEffect(() => {
    if (autofocus) {
      element.current.focus();
    }
  }, [autofocus]);

  const handleClear = () => {
    onChange('');
    element.current.focus();
  };

  return (
    <div
      className={classNames(styles.container, classes.root, {
        [styles.disabled]: disabled,
        [styles.error]: error,
      })}
    >
      {icon && (
        <Icon name={icon} size={18} className={classNames('m-r-5', styles.icon, classes.icon)} />
      )}
      <input
        type={type}
        value={value}
        onChange={event => onChange(event.target.value, event)}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
        ref={element}
        className={classNames(styles.input, classes.input)}
        {...props}
      />
      {value && !disabled && (
        <ClickableElement onClick={handleClear}>
          <Icon name="clear" size={18} className={styles.icon} />
        </ClickableElement>
      )}
    </div>
  );
};

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export default Input;
