import React, { useState, useContext } from 'react';
// libraries
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
// context
import ToastContext from 'context/ToastContext';
// components
import Button from 'components/atoms/Button';
import Loader from 'components/atoms/Loader';
import Modal from 'components/molecules/Modal';
import Field from 'components/molecules/Field';
import FileUpload from 'components/molecules/FileUpload';
// constants
import { FILE_RELEASABLE_CV } from 'constants';
// helpers
import { boxFileDir } from 'helpers/Box';
import { uploadDocument } from './helpers';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  submissionId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  rfq: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
  project: PropTypes.string.isRequired,
};

const UploadReleasableCV = ({ isOpen, submissionId, name, onClose, client, project, rfq }) => {
  const { t } = useTranslation();
  const { addToast } = useContext(ToastContext);

  const [loading, setLoading] = useState(false);
  const [hasError, setError] = useState(false);
  const [files, setFiles] = useState([]);

  const handleChange = fileList => {
    setError(false);
    setFiles(fileList);
  };

  const handleClose = () => {
    setFiles(null);
    setError(false);
    onClose();
  };

  const handleSubmit = async () => {
    if (!files.length) {
      setError(true);
      return;
    }

    try {
      setLoading(true);
      const { file } = files[0];
      const folder = boxFileDir({ client, project, rfq });
      const filename = `${name} Releasable CV.${file.name.split('.').pop()}`;

      await uploadDocument(file, {
        submissionId,
        filename,
        folder,
        type: FILE_RELEASABLE_CV,
      });
      addToast.success(t('uploadReleasableCvSuccess'));
      handleClose();
    } catch (error) {
      addToast.error(t('errorPlaceholderText'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} closeOnEsc={false} closeOnBackdrop={false}>
      <Modal.Header>
        <Trans i18nKey="releasableCvName">Releasable CV for {{ name }}</Trans>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div className="space-30">
            <Loader />
          </div>
        ) : (
          <Field label={t('selectCV')} className="m-0" required fieldNotFilled={hasError}>
            <FileUpload
              selected={files}
              onChange={handleChange}
              accept=".pdf,.dot,.dotx,.doc,.docx,.png,.jpeg,.jpg,.txt"
              error={hasError}
            />
          </Field>
        )}
      </Modal.Body>
      {!loading && (
        <Modal.Footer>
          <Button label={t('cancel')} secondary onClick={handleClose} />
          <span className="p-l-12">
            <Button label={t('upload')} onClick={handleSubmit} />
          </span>
        </Modal.Footer>
      )}
    </Modal>
  );
};

UploadReleasableCV.propTypes = propTypes;

export default UploadReleasableCV;
