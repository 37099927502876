import { useReducer, useEffect, useCallback } from 'react';
import omit from 'lodash/omit';
import debounce from 'lodash/debounce';
import logger from '@services/logger';
import { SortOrder, SortOption, DataFetchResolver } from '@localTypes/fetch';

interface DataFetchOptions<TFilter, TSort> {
  initialFilter?: TFilter;
  initialSort?: SortOption<TSort>;
  preFetch?: boolean; // fetch on initial render
  pageSize?: number;
}

interface State<TData, TFilter, TSort> {
  count: number;
  data: TData[];
  loading: boolean;
  loadingMore: boolean;
  error: boolean;
  fullText: string;
  sortBy: Nullable<TSort>;
  filterBy: Nullable<TFilter>;
  order: SortOrder;
}

type InitialFetchOptions<TFilter, TSort> = Pick<
  State<any, TFilter, TSort>,
  'filterBy' | 'sortBy' | 'order'
>;

interface FetchOptions<TFilter extends Record<string, any> = {}, TSort = null> {
  filter?: Nullable<TFilter>;
  sortBy?: Nullable<TSort>;
  order?: SortOrder;
  fullText?: string;
  skip?: number;
  keepPreviousData?: boolean;
}

interface ActionFetch<TFilter extends Record<string, any> = {}, TSort = null> {
  type: 'FETCH';
  payload: FetchOptions<TFilter, TSort>;
}

interface ActionReset<TFilter, TSort> {
  type: 'RESET';
  payload: InitialFetchOptions<TFilter, TSort>;
}

interface ActionSet<TData> {
  type: 'SET';
  payload: {
    count: number;
    data: TData[];
  };
}

interface ActionAdd<TData> {
  type: 'ADD';
  payload: {
    data: TData[];
  };
}

interface ActionError {
  type: 'ERROR';
}

interface ActionFullText {
  type: 'FULL_TEXT';
  payload: {
    text: string;
  };
}

type Action<TData, TFilter extends Record<string, any> = {}, TSort = null> =
  | ActionFetch<TFilter, TSort>
  | ActionReset<TFilter, TSort>
  | ActionSet<TData>
  | ActionAdd<TData>
  | ActionError
  | ActionFullText;

const reducer = <TData, TFilter extends Record<string, any> = {}, TSort = null>(
  state: State<TData, TFilter, TSort>,
  action: Action<TData, TFilter, TSort>
): State<TData, TFilter, TSort> => {
  switch (action.type) {
    case 'FETCH': {
      const newState: Partial<State<TData, TFilter, TSort>> = {
        loading: !action.payload.keepPreviousData,
        loadingMore: action.payload.keepPreviousData,
        error: false,
        fullText: action.payload.fullText ?? state.fullText,
      };

      if (action.payload.filter) {
        newState.filterBy = action.payload.filter || state.filterBy;
      }
      if (action.payload.sortBy) {
        newState.sortBy = action.payload.sortBy || state.sortBy;
      }
      if (action.payload.order) {
        newState.order = action.payload.order || state.order;
      }

      return { ...state, ...newState };
    }
    case 'RESET':
      return {
        ...state,
        ...action.payload,
        fullText: '',
      };
    case 'SET':
      return {
        ...state,
        loading: false,
        loadingMore: false,
        count: action.payload.count,
        data: action.payload.data,
      };
    case 'ADD':
      return {
        ...state,
        loading: false,
        loadingMore: false,
        data: [...state.data, ...action.payload.data],
      };
    case 'ERROR':
      return {
        ...state,
        loading: false,
        error: true,
        data: [],
      };
    case 'FULL_TEXT':
      return {
        ...state,
        fullText: action.payload.text,
      };
    default:
      return state;
  }
};

const useDataFetch = <TData, TFilter extends Record<string, any> = {}, TSort = null>(
  resolver: DataFetchResolver<TData, TFilter, TSort>,
  options: DataFetchOptions<TFilter, TSort> = {}
) => {
  const initialFetchOptions: InitialFetchOptions<TFilter, TSort> = {
    filterBy: options.initialFilter || null,
    sortBy: options.initialSort?.field || null,
    order: options.initialSort?.order || 'ASC',
  };

  const [state, dispatch] = useReducer<
    React.Reducer<State<TData, TFilter, TSort>, Action<TData, TFilter, TSort>>
  >(reducer, {
    count: 0,
    data: [],
    loading: false,
    loadingMore: false,
    error: false,
    fullText: '',
    ...initialFetchOptions,
  });

  const fetchData = async (fetchOptions: FetchOptions<TFilter, TSort> = {}) => {
    try {
      dispatch({ type: 'FETCH', payload: fetchOptions });
      const response = await resolver({
        skip: fetchOptions.skip || 0,
        limit: options.pageSize || 30,
        fullText: fetchOptions.fullText || '',
        filterBy: fetchOptions.filter || initialFetchOptions.filterBy,
        sortBy: fetchOptions.sortBy ? fetchOptions.sortBy : initialFetchOptions.sortBy,
        order: fetchOptions.order ? fetchOptions.order : initialFetchOptions.order,
      });

      if (fetchOptions.keepPreviousData) {
        dispatch({ type: 'ADD', payload: { data: response.data } });
      } else {
        dispatch({ type: 'SET', payload: { count: response.count, data: response.data } });
      }
    } catch (error) {
      dispatch({ type: 'ERROR' });
      if (error instanceof Error) {
        logger.error(error.message, { fetchOptions, state: omit(state, 'data') });
      }
    }
  };

  // 1st fetch
  useEffect(() => {
    if (options.preFetch) {
      fetchData();
    }
  }, []);

  const debounceFullTextSearch = useCallback(
    debounce((text) => {
      fetchData({
        filter: state.filterBy,
        sortBy: state.sortBy,
        order: state.order,
        fullText: text,
      });
    }, 300),
    [state.sortBy, state.filterBy, state.order]
  );

  const searchByText = useCallback(
    (text: string) => {
      dispatch({ type: 'FULL_TEXT', payload: { text } });
      debounceFullTextSearch(text);
    },
    [state.sortBy, state.filterBy, state.order]
  );

  const filterBy = useCallback(
    (filter: TFilter, overwrite: boolean = false) => {
      if (overwrite) {
        fetchData({ fullText: state.fullText, sortBy: state.sortBy, order: state.order, filter });
      } else {
        fetchData({
          fullText: state.fullText,
          sortBy: state.sortBy,
          order: state.order,
          filter: { ...state.filterBy, ...filter },
        });
      }
    },
    [state.fullText, state.filterBy, state.sortBy, state.order]
  );

  const sortBy = useCallback(
    (sort: SortOption<TSort>) => {
      fetchData({
        filter: state.filterBy,
        fullText: state.fullText,
        sortBy: sort.field,
        order: sort.order,
      });
    },
    [state.fullText, state.filterBy]
  );

  const refetch = useCallback(() => {
    fetchData({
      filter: state.filterBy,
      fullText: state.fullText,
      sortBy: state.sortBy,
      order: state.order,
    });
  }, [state.fullText, state.sortBy, state.filterBy, state.order]);

  const fetch = useCallback(() => {
    fetchData();
  }, []);

  const fetchMore = useCallback(() => {
    fetchData({
      filter: state.filterBy,
      fullText: state.fullText,
      sortBy: state.sortBy,
      order: state.order,
      skip: state.data.length,
      keepPreviousData: true,
    });
  }, [state.fullText, state.sortBy, state.filterBy, state.order, state.data]);

  const reset = useCallback(() => {
    dispatch({ type: 'RESET', payload: initialFetchOptions });
    fetchData(initialFetchOptions);
  }, []);

  return {
    count: state.count,
    data: state.data,
    loading: state.loading,
    loadingMore: state.loadingMore,
    error: state.error,
    sortedBy: state.sortBy,
    filteredBy: state.filterBy,
    order: state.order,
    fullText: state.fullText,
    hasMore: state.count > state.data.length,
    searchByText,
    filterBy,
    sortBy,
    refetch,
    fetch,
    fetchMore,
    reset,
  };
};

export default useDataFetch;
