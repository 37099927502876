const Colors = {
  // primary, secondary
  blue: '#109fc6',
  black: '#0f1e25',
  white: '#ffffff',
  // secondary colors
  blueDark: '#0a72a3',
  green: '#00bea1',
  greenLight: '#79da80',
  yellow: '#efcb4f',
  orange: '#ef9a4f',
  red: '#d0314b',
  purple: '#8f3458',
  // role colors
  atramentDark: '#152630',
  atrament: '#1e3b4d',
  brownishDark: '#2c2d2f',
  brownish: '#414043',
  managerDark: '#151521',
  manager: '#1d1d27',
  // gray colors
  gray1: '#fbfbfb',
  gray2: '#f6f6f6',
  gray3: '#f1f1f1',
  gray4: '#e6e6e6',
  gray5: '#bfbfbf',
  gray6: '#a0a0a0',
  gray7: '#8f8e8e',
  gray8: '#676767',
  // blue colors
  blue1: '#d3ecf3',
  blue2: '#A5DAE9',
  blue3: '#5DC6E2',
  blue4: '#29B2D8',
  blue5: '#109FC6',
  blue6: '#188dad',
  blue7: '#0E6F8D',
  blue8: '#0E5574',
  blue9: '#00415E',
  // green colors
  green1: '#cbf0e7',
  green2: '#93e0d4',
  green3: '#49d1bd',
  green4: '#00bea1',
  green5: '#04a78e',
  green6: '#158876',
  green7: '#0a7262',
  green8: '#025f50',
  green9: '#045145',
  // red colors
  red1: '#f5cfd5',
  red2: '#edb2bc',
  red3: '#DC8896',
  red4: '#D7566B',
  red5: '#D0314B',
  red6: '#A51B32',
  red7: '#8a1629',
  red8: '#6A0616',
  red9: '#5e111d',
  // yellow colors
  yellow1: '#f8efd1',
  yellow2: '#f9e6a6',
  yellow3: '#f9dc7a',
  yellow4: '#efcb4f',
  yellow5: '#e0bc3f',
  yellow6: '#cba72d',
  yellow7: '#b19329',
  yellow8: '#927220',
  yellow9: '#7b5d10',
  gray: '#a0a0a0',
  darkGray: '#8e8e8e',
  disabledGray: '#bfbfbf',
  iconGray: '#a0a0a0',
};

export default Colors;
