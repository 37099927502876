import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// components
import ClickableElement from 'components/atoms/ClickableElement';
import Icon from 'components/atoms/Icon';
// styles
import colors from 'dependencies/materialStyles/Colors';
import styles from './closeChip.module.scss';

const propTypes = {
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

const defaultProps = {
  className: '',
  disabled: false,
};

const CloseChip = ({ className, id, label, onDelete, disabled }) => {
  return (
    <div
      className={classNames(className, styles.closeChip, {
        [styles.disabled]: disabled,
      })}
    >
      <div className={styles.label}>{label}</div>
      {!disabled && (
        <ClickableElement className={styles.close} onClick={() => onDelete(id)}>
          <Icon name="close" size={15} color={colors.blue8} />
        </ClickableElement>
      )}
    </div>
  );
};

CloseChip.propTypes = propTypes;
CloseChip.defaultProps = defaultProps;

export default CloseChip;
