import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// components
import LinkButton from 'components/atoms/LinkButton';
import StatusNote from 'components/molecules/StatusNote';
// constants
import { REVISION_STATUS, REVISION_TYPES } from 'constants';
// helpers
import { getRevisionStatusText, getRevisionTypeText } from 'helpers/NameMapping';

const propTypes = {
  index: PropTypes.number.isRequired,
  type: PropTypes.oneOf(Object.values(REVISION_TYPES)).isRequired,
  isActive: PropTypes.bool.isRequired,
  status: PropTypes.string,
  requestReason: PropTypes.string.isRequired,
  note: PropTypes.string,
  revisedBy: PropTypes.string,
  revisedAt: PropTypes.string,
  onHistoryClick: PropTypes.func.isRequired,
};

const defaultProps = {
  status: '',
  note: '',
  revisedBy: '',
  revisedAt: '',
};

const RevisionType = ({
  index,
  type,
  isActive,
  status,
  requestReason,
  note,
  revisedBy,
  revisedAt,
  onHistoryClick,
}) => {
  const { t } = useTranslation();

  const getStatusColor = () => {
    switch (status) {
      case REVISION_STATUS.ACCEPTED:
        return 'green';
      case REVISION_STATUS.REJECTED:
        return 'red';
      default:
        return 'blue';
    }
  };

  const getStatusNoteTitle = () => {
    switch (status) {
      case REVISION_STATUS.ACCEPTED:
        return t('additionalNotes');
      case REVISION_STATUS.REJECTED:
        return t('rejectionReason');
      default:
        return '';
    }
  };

  return (
    <div className="revision_type">
      <div className="verticalCenter m-r-15">
        <div className={classNames('revision_type_index', { 'bg-blue': isActive })}>{index}</div>
        <div>
          <div className="revision_type_name">{getRevisionTypeText(type)}</div>
          {status && [REVISION_STATUS.ACCEPTED, REVISION_STATUS.REJECTED].includes(status) && (
            <div className="revision_type_status">
              <StatusNote
                status={getRevisionStatusText(status)}
                color={getStatusColor()}
                noteTitle={getStatusNoteTitle()}
                noteText={note}
                noteAuthor={revisedBy}
                noteDate={revisedAt}
                small
              />
            </div>
          )}
          <div className="revision_type_reason">&quot;{requestReason}&quot;</div>
        </div>
      </div>
      <div className="revision_type_button">
        <LinkButton onClick={onHistoryClick}>{t('viewHistory')}</LinkButton>
      </div>
    </div>
  );
};

RevisionType.propTypes = propTypes;
RevisionType.defaultProps = defaultProps;

export default RevisionType;
