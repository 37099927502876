import React, { useState } from 'react';
// libraries
import PropTypes from 'prop-types';
import getProperty from 'lodash/get';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
// hooks
import useAuth from 'hooks/useAuth';
// components
import ErrorPage from 'components/pages/ErrorPage';
import Navigation from './Navigation';
import OnboardingContent from './OnboardingContent';
// constants
import { generateTabs, getLatestActiveTab } from './helpers';
// styles
import './onboarding.scss';

const propTypes = {
  submission: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
};

const Onboarding = ({ submission, position }) => {
  const { role } = useAuth();
  const history = useHistory();
  const location = useLocation();
  const [tabDetailsOpen, toggleTabDetails] = useState(true);

  if (!submission.hasOnboarding()) {
    return <ErrorPage code={401} />;
  }

  const tabs = generateTabs(submission, position, role);
  const query = queryString.parse(location.search);
  const tab = getProperty(query, 'step', getLatestActiveTab(submission, tabs, role));

  const setTab = step => {
    history.push(`${location.pathname}?${queryString.stringify({ ...query, step })}`);
  };

  return (
    <div className="onboarding">
      <Navigation
        tab={tab}
        setTab={setTab}
        tabs={tabs}
        tabDetailsOpen={tabDetailsOpen}
        toggleTabDetails={toggleTabDetails}
      />
      <OnboardingContent
        tab={tab}
        tabs={tabs}
        tabDetailsOpen={tabDetailsOpen}
        submission={submission}
        position={position}
      />
    </div>
  );
};

Onboarding.propTypes = propTypes;

export default Onboarding;
