import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// components
import Icon from 'components/atoms/Icon';
import Button from 'components/atoms/Button';
import Modal from 'components/molecules/Modal';
// styles
import styles from './dialogs.module.scss';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};

const SuccessDialog = ({ isOpen, onClose, title, children }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnEsc closeOnBackdrop>
      <div className={classNames(styles.container, styles.success)}>
        <div className={styles.close}>
          <Button type="close" size={24} onClick={onClose} />
        </div>
        <div className={styles.icon}>
          <Icon name="checkOutline" size={120} />
        </div>
        <div className={styles.title}>{title}</div>
        <div className={styles.content}>{children}</div>
      </div>
    </Modal>
  );
};

SuccessDialog.propTypes = propTypes;

export default SuccessDialog;
