import * as d3 from 'd3';
// helpers
import { formatNumber } from 'helpers/Number';
import { getLevelByScore } from 'helpers/MRI';
// constants
import { MRI_LEVEL } from 'constants/report';
// styles
import colors from 'dependencies/materialStyles/Colors';

const COLORS = {
  [MRI_LEVEL.LOW]: [colors.red2, colors.red3, colors.red4, colors.red5, colors.red6],
  // yellow3 is out of current color pallette because it better supports gradient of gauge chart
  [MRI_LEVEL.MODERATE]: [colors.yellow2, '#EDD276', colors.yellow5, colors.yellow6, colors.yellow7],
  [MRI_LEVEL.HIGH]: [colors.green2, colors.green3, colors.green4, colors.green5, colors.green6],
};

// Final calculation of X and Y ax coordinates
const calculateRadialCoordinates = (angle, offset = 10) => {
  return { x: Math.cos(angle - Math.PI / 2) * offset, y: Math.sin(angle - Math.PI / 2) * offset };
};

// Get coordinates of pointer in gauge chart
const getTriangleCoordinates = (percentage, offset = 10) => {
  const percentScale = d3
    .scaleLinear()
    .domain([0, 100])
    .range([0, 1]);
  const newPercentage = percentScale(percentage);
  const angleScale = d3
    .scaleLinear()
    .domain([0, 1])
    .range([-Math.PI / 2, Math.PI / 2])
    .clamp(true);
  const angle = angleScale(newPercentage);

  return calculateRadialCoordinates(angle, offset);
};

// Get rotation degree for pointer in gauge chart
const getRotationDegByPercentage = percentage => {
  // Degree range is half of the circle
  const degreeRange = 360 / 2;
  // Degree of necessary rotation when percentage is 0
  const startDegree = 270;

  return -(startDegree - (degreeRange / 100) * percentage);
};

/**
 * Generates gauge chart based on score
 * @param {number} score
 */
const getGaugeChart = score => {
  const gaugeNode = document.createElement('div');
  // init color and format percentage
  const percentage = formatNumber(score, 0);
  const level = getLevelByScore(score);
  const colorRange = COLORS[level];

  // init basic gauge values
  const width = 220;
  const height = 220;
  const borderWidth = 78;
  const padding = 0.02;
  const radius = Math.min(width - 30, height - 30) / 2;
  const fontSize = '32px';

  // array of colors for the pie (in the same order as the dataset)
  const color = d3.scaleOrdinal().range(colorRange);
  const data = [1, 1, 1, 1, 1];

  // Generation of main svg for chart
  const vis = d3
    .select(gaugeNode)
    .append('svg') // create the SVG element inside the <body>
    .data([data]) // associate our data with the document
    .attr('width', width) // set the width and height of our visualization (these will be attributes of the <svg> tag
    .attr('height', 130)
    .append('svg:g') // make a group to hold our pie chart
    .attr('transform', `translate(${width / 2},${height / 2})`); // move the center of the pie chart from 0, 0 to radius, radius

  // Generation of Main percentage in the middle of the gauge chart
  vis
    .append('text')
    .attr('x', 0)
    .attr('y', -25)
    .style('alignment-baseline', 'central')
    .style('dominant-baseline', 'central')
    .style('text-anchor', 'middle')
    .style('font-size', fontSize)
    .style('font-family', 'Lato-Bold')
    .style('fill', colors.black)
    .text(percentage);

  // Generation 0 and 100 indicators at the bottom of gauge chart
  vis
    .append('text')
    .attr('x', -(width / 2 - 24))
    .attr('y', 7)
    .style('alignment-baseline', 'central')
    .style('dominant-baseline', 'central')
    .style('text-anchor', 'middle')
    .style('font-size', '14px')
    .style('font-family', 'Lato-Bold')
    .style('fill', colors.gray7)
    .text(0);

  vis
    .append('text')
    .attr('x', width / 2 - 25)
    .attr('y', 7)
    .style('alignment-baseline', 'central')
    .style('dominant-baseline', 'central')
    .style('text-anchor', 'middle')
    .style('font-size', '14px')
    .style('font-family', 'Lato-Bold')
    .style('fill', colors.gray7)
    .text(100);

  // Generation of half doughnut with sections
  const arc = d3
    .arc() // this will create <path> elements for us using arc data
    .innerRadius(borderWidth)
    .outerRadius(radius);

  const pie = d3
    .pie() // this will create arc data for us given a list of values
    .startAngle(-90 * (Math.PI / 180))
    .endAngle(90 * (Math.PI / 180))
    .padAngle(padding) // some space between slices
    .sort(null); // No! we don't want to order it by size

  const arcs = vis
    .selectAll('g.slice') // this selects all <g> elements with class slice (there aren't any yet)
    .data(pie) // associate the generated pie data (an array of arcs, each having startAngle, endAngle and value properties)
    .enter() // this will create <g> elements for every "extra" data element that should be associated with a selection. The result is creating a <g> for every object in the data array
    .append('svg:g') // create a group to hold each slice (we will have a <path> and a <text> element associated with each slice)
    .attr('class', 'slice'); // allow us to style things in the slices (like text)

  arcs
    .append('svg:path')
    .attr('fill', (_d, i) => color(i)) // set the color for each slice to be chosen from the color function defined above
    .attr('d', arc); // this creates the actual SVG path using the associated data (pie) with the arc drawing function

  // Generation of gauge triangle pointer
  const triangleRadius = radius + 5;
  const coordinates = getTriangleCoordinates(percentage, triangleRadius);
  const rotationDeg = getRotationDegByPercentage(percentage);

  const triangle = d3
    .symbol()
    .type(d3.symbolTriangle)
    .size(110);
  vis
    .append('svg:path')
    .attr('d', triangle)
    .attr('fill', colors.gray5)
    .attr(
      'transform',
      `rotate(${rotationDeg} ${coordinates.x} ${coordinates.y}) translate(${coordinates.x}, ${coordinates.y}) `
    );

  return gaugeNode;
};

export default getGaugeChart;
