import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// helpers
import { getPartnerCategoryText } from 'helpers/NameMapping';
import { isDirectSupplier } from 'helpers/Submission';
// types
import { MetricType } from 'types';
// components
import Tooltip from 'components/atoms/Tooltip';
import TruncatedText from 'components/atoms/TruncatedText';
import Score from '../Score';
import SaveIcon from '../SaveIcon';
import Documents from './Documents';
import Rate from './Rate';
// styles
import styles from './review.module.scss';
import moduleStyles from '../submitCandidates.module.scss';

const documentPropTypes = PropTypes.shape({
  boxId: PropTypes.string,
  filename: PropTypes.string.isRequired,
});

const ratesPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.number.isRequired,
    year: PropTypes.number.isRequired,
  })
);

const propTypes = {
  isProposal: PropTypes.bool.isRequired,
  score: PropTypes.number,
  name: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  partnerType: PropTypes.string.isRequired,
  sellRates: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      year: PropTypes.number.isRequired,
      error: PropTypes.bool,
    })
  ).isRequired,
  partnerRates: ratesPropTypes.isRequired,
  includeCSAFee: PropTypes.bool.isRequired,
  releasableSubmissionFile: documentPropTypes,
  releasableAttestation: documentPropTypes,
  disabled: PropTypes.bool,
  previouslySaved: PropTypes.bool,
  position: PropTypes.shape({
    title: PropTypes.string.isRequired,
    project: PropTypes.string.isRequired,
    experienceLevel: PropTypes.string.isRequired,
    occupations: PropTypes.arrayOf(PropTypes.string).isRequired,
    manYearDiscount: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    nteRates: ratesPropTypes.isRequired,
  }).isRequired,
  metrics: MetricType,
};

const defaultProps = {
  score: null,
  releasableSubmissionFile: null,
  releasableAttestation: null,
  disabled: false,
  previouslySaved: false,
  metrics: null,
};

const Submission = ({
  isProposal,
  score,
  name,
  company,
  partnerType,
  sellRates,
  partnerRates,
  includeCSAFee,
  releasableSubmissionFile,
  releasableAttestation,
  disabled,
  position,
  metrics,
  previouslySaved,
}) => {
  const { t } = useTranslation();

  const renderNameTooltip = () => {
    return (
      <div className={moduleStyles.tooltip}>
        <div className="bold">{t('submittedBy')}</div>
        {company} ({getPartnerCategoryText(partnerType)})
      </div>
    );
  };

  const getRateByYear = (rates, year) => {
    const foundRate = rates.find(rate => rate.year === year);

    return foundRate ? foundRate.value : 0;
  };

  return (
    <React.Fragment>
      {!isProposal && (
        <div className={classNames('text-center', { [moduleStyles.disabledElement]: disabled })}>
          <Score
            score={score}
            metrics={metrics}
            candidateName={name}
            positionTitle={position.title}
            project={position.project}
            experienceLevel={position.experienceLevel}
            occupations={position.occupations}
          />
        </div>
      )}
      <div className={styles.nameBox}>
        <Tooltip title={renderNameTooltip()} noMaxWidth>
          <div
            className={classNames(moduleStyles.nameLabel, {
              [moduleStyles.disabledText]: disabled,
            })}
          >
            <TruncatedText disableTooltip>{name}</TruncatedText>
          </div>
        </Tooltip>
        {previouslySaved && (
          <div className="m-l-4">
            <SaveIcon disabled={disabled} />
          </div>
        )}
      </div>
      <div className={styles.rates} style={{ '--numberOfYears': sellRates.length }}>
        {sellRates.map(sellRate => (
          <div key={sellRate.year} className="text-right">
            <Rate
              sellRate={sellRate.value}
              partnerRate={getRateByYear(partnerRates, sellRate.year)}
              nteRate={getRateByYear(position.nteRates, sellRate.year)}
              currency={position.currency}
              isDirectSupplier={isDirectSupplier(partnerType)}
              manYearDiscount={position.manYearDiscount}
              includeCSAFee={includeCSAFee}
              disabled={disabled}
            />
          </div>
        ))}
      </div>
      <div className={styles.documents}>
        <Documents
          releasableSubmissionFile={releasableSubmissionFile}
          releasableAttestation={releasableAttestation}
          disabled={disabled}
        />
      </div>
    </React.Fragment>
  );
};

Submission.propTypes = propTypes;
Submission.defaultProps = defaultProps;

export default Submission;
