import React, { useCallback, useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Stack } from '@mui/material';
import usePrevious from '@hooks/usePrevious';
import { Dialog, DialogHeader, DialogBody, DialogFooter } from '@components/Dialog';
import FormField from '@components/FormField';
import TextInput from '@components/TextInput';
import ComboBox, { Option } from '@components/ComboBox';
import Button from '@components/Button';
import spacing from '@styles/spacing.module.scss';
import { SupplierFormData } from './types';

interface SupplierFormModalProps {
  title: string;
  isOpen: boolean;
  submitting: boolean;
  initialData?: Partial<SupplierFormData>;
  onClose: () => void;
  onSubmit: (data: SupplierFormData) => void;
}

const ProjectSchema = Yup.object().shape({
  name: Yup.string().required('This field is required').trim(),
  isDirectSupplier: Yup.boolean().required(),
  includeCSAFee: Yup.boolean().required(),
});

const supplierTypeOptions: Option[] = [
  { value: 'Direct', id: 'direct' },
  { value: 'Not Direct', id: 'notDirect' },
];

const supplierCSAFeeOptions: Option[] = [
  { value: 'IBM', id: 'ibm' },
  { value: 'Supplier', id: 'supplier' },
];

const SupplierFormModal = ({
  isOpen,
  submitting,
  title,
  initialData,
  onClose,
  onSubmit,
}: SupplierFormModalProps) => {
  const previouslyOpen = usePrevious(isOpen);
  const initialValues: SupplierFormData = {
    name: '',
    includeCSAFee: true,
    isDirectSupplier: false,
    ...initialData,
  };

  const { values, errors, touched, setFieldValue, handleSubmit, resetForm } = useFormik({
    initialValues,
    validationSchema: ProjectSchema,
    onSubmit: (data) => onSubmit(data),
  });

  useEffect(() => {
    if (previouslyOpen && !isOpen) {
      resetForm();
    }
  }, [isOpen]);

  const handleChange = useCallback(
    (field: keyof SupplierFormData) => (value: string) => {
      setFieldValue(field, value);
    },
    [setFieldValue]
  );

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      size="sm"
      disableCloseOnBackdropClick
      disableCloseOnEscape={submitting}
    >
      <DialogHeader title={title} disableCloseButton={submitting} />
      <DialogBody>
        <form onSubmit={handleSubmit}>
          <Stack gap={3}>
            <FormField
              label="Name"
              htmlFor="name"
              error={!!errors.name && touched.name}
              errorText={errors.name}
              required
            >
              <TextInput
                id="name"
                name="name"
                value={values.name}
                onChange={handleChange('name')}
                error={!!errors.name && touched.name}
                disabled={submitting}
                autoFocus
              />
            </FormField>
            <FormField
              label="Type"
              htmlFor="type"
              helperText="For indirect suppliers the Randstad fee (1.5%) is included in the candidate sell price."
              required
            >
              <ComboBox
                id="type"
                value={values.isDirectSupplier ? 'direct' : 'notDirect'}
                ariaLabel="type"
                options={supplierTypeOptions}
                onChange={(option) => setFieldValue('isDirectSupplier', option!.id === 'direct')}
                disabled={submitting}
                disableClearable
              />
            </FormField>
            <FormField
              label="CSA Fee paid by"
              htmlFor="csaFee"
              helperText="If paid by IBM, CSA Fee (1.05%) is included in the candidate sell price."
              required
            >
              <ComboBox
                id="csaFee"
                value={values.includeCSAFee ? 'ibm' : 'supplier'}
                ariaLabel="csaFee"
                options={supplierCSAFeeOptions}
                onChange={(option) => setFieldValue('includeCSAFee', option!.id === 'ibm')}
                disabled={submitting}
                disableClearable
              />
            </FormField>
          </Stack>
        </form>
      </DialogBody>
      <DialogFooter>
        <Button onClick={onClose} secondary disabled={submitting}>
          Close
        </Button>
        <div className={spacing.ml3}>
          <Button onClick={handleSubmit} loading={submitting} loadingText="Submitting...">
            Submit
          </Button>
        </div>
      </DialogFooter>
    </Dialog>
  );
};

export default SupplierFormModal;
