import React, { useContext, useMemo } from 'react';
import classNames from 'classnames';
import MuiTableBody from '@mui/material/TableBody';
import TableContext, { TableContextProps } from './TableContext';
import styles from './table.module.scss';

interface TableBodyProps {
  children: React.ReactNode;
  className?: string;
}

const TableBody = ({ children, className }: TableBodyProps) => {
  const parentContext = useContext(TableContext);
  const context: TableContextProps = useMemo(
    () => ({
      ...parentContext,
      variant: 'body',
    }),
    [parentContext]
  );

  return (
    <TableContext.Provider value={context}>
      <MuiTableBody
        component="div"
        classes={{
          root: classNames(styles.body, className),
        }}
      >
        {children}
      </MuiTableBody>
    </TableContext.Provider>
  );
};

export default TableBody;
