import React, { useState, useContext } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// context
import ToastContext from 'context/ToastContext';
// components
import Modal from 'components/molecules/Modal';
import Loader from 'components/atoms/Loader';
import Button from 'components/atoms/Button';
// helper
import { deleteDraft } from 'helpers/Draft';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  draftId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

const DeleteDraft = ({ isOpen, draftId, onClose }) => {
  const { t } = useTranslation();
  const { addToast } = useContext(ToastContext);
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    try {
      setLoading(true);
      await deleteDraft(draftId);
      addToast.success(t('draftDeleted'));
    } catch (error) {
      addToast.error(t('errorWhileDeletingDraft'));
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header>{t('deleteDraft')}</Modal.Header>
      <Modal.Body>
        {loading ? (
          <div className="space-30">
            <Loader />
          </div>
        ) : (
          <div className="space-10">
            <div className="p-b-15">{t('deleteDraftQuestion')}</div>
          </div>
        )}
      </Modal.Body>
      {!loading && (
        <Modal.Footer>
          <Button label={t('cancel')} secondary onClick={onClose} />
          <span className="p-l-12">
            <Button label={t('delete')} onClick={handleDelete} />
          </span>
        </Modal.Footer>
      )}
    </Modal>
  );
};

DeleteDraft.propTypes = propTypes;

export default DeleteDraft;
