import React from 'react';
// libraries
import PropTypes from 'prop-types';
// components
import Icon from 'components/atoms/Icon';
import colors from 'dependencies/materialStyles/Colors';

const propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  currentTab: PropTypes.object,
  tabs: PropTypes.array,
};

const defaultProps = {
  className: '',
  onChange: () => {},
  currentTab: {},
  tabs: [],
};

const Menu = ({ className, currentTab, tabs, onChange }) => {
  return (
    <div>
      <div className={`${className}`}>
        {tabs.map(tab => {
          return (
            <div
              key={tab.id}
              role="presentation"
              onClick={() => onChange(tab)}
              className={`menuOption-item ${currentTab.id === tab.id ? 'active' : ''}`}
            >
              <Icon name={tab.icon} color={colors.atrament} /> {tab.label}
            </div>
          );
        })}
      </div>
    </div>
  );
};

Menu.propTypes = propTypes;
Menu.defaultProps = defaultProps;

export default Menu;
