import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { Fade } from 'react-awesome-reveal';
// components
import Icon from 'components/atoms/Icon';
// constants
import { TOAST_TIMEOUT } from 'constants';
// data
import { colors, icons } from './data';

const propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['success', 'error', 'warning']).isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

class ToastItem extends React.PureComponent {
  componentDidMount = () => {
    const { onClick } = this.props;

    const intervalId = setTimeout(onClick, TOAST_TIMEOUT);
    this.cancelTimeout = () => clearTimeout(intervalId);
  };

  close = () => {
    const { onClick } = this.props;

    this.cancelTimeout();
    onClick();
  };

  render() {
    const { id, type, text } = this.props;

    return (
      <Fade bottom key={id}>
        <div className={`bg-${colors[type]} toast`}>
          <Icon size={18} color="white" name={icons[type]} />
          <div className="toast_text">{text}</div>
          <div role="presentation" onClick={this.close}>
            <Icon size={20} color="white" name="close" />
          </div>
        </div>
      </Fade>
    );
  }
}

ToastItem.propTypes = propTypes;

export default ToastItem;
