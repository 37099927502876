import React from 'react';
import Heading from '@components/Heading';
import styles from './page.module.scss';

interface PageProps {
  children: React.ReactNode;
  title?: string;
}

const Page = ({ children, title }: PageProps) => (
  <main className={styles.container}>
    {title && <Heading className={styles.title}>{title}</Heading>}
    {children}
  </main>
);

export default Page;
