import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
// Libraries
import { Link } from 'react-router-dom';
// component
import Chip from 'components/atoms/Chip';

const propTypes = {
  icon: PropTypes.any.isRequired,
  chip: PropTypes.string,
  link: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  bigMenu: PropTypes.bool,
  className: PropTypes.string,
};

const defaultProps = {
  chip: null,
  bigMenu: true,
  className: '',
};

const MenuItem = props => {
  const { active, chip, icon, label, link, onClick, bigMenu, className } = props;

  const getItem = () => {
    return (
      <div className={`menuItem ${bigMenu ? '' : 'small'}`}>
        <div className={className}>{icon}</div>
        <span className={`${bigMenu ? '' : 'small'}`}>
          <div className="menuItem_label">{label}</div>
          <div>{chip && <Chip label={chip} className="menuItem_chip" />}</div>
        </span>
      </div>
    );
  };

  return (
    <Link to={link}>
      <div
        className={`flex-row ${active ? 'menuItem--active' : ''} fs-unmask`}
        role="presentation"
        onClick={onClick}
      >
        <div className="menuItem_highlight" />
        {bigMenu ? (
          getItem()
        ) : (
          <Tooltip
            title={label}
            placement="right"
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
          >
            {getItem()}
          </Tooltip>
        )}
      </div>
    </Link>
  );
};

MenuItem.propTypes = propTypes;
MenuItem.defaultProps = defaultProps;

export default MenuItem;
