/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
// styles
import './htmlElement.scss';

const propTypes = {
  className: PropTypes.string,
  content: PropTypes.any,
};

const defaultProps = {
  className: '',
  content: null,
};

const HTMLElement = props => {
  const { className, content } = props;
  const createMarkup = () => {
    return { __html: content };
  };
  return <div className={`${className}`} dangerouslySetInnerHTML={createMarkup()} />;
};

HTMLElement.propTypes = propTypes;
HTMLElement.defaultProps = defaultProps;

export default HTMLElement;
