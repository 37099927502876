import { FileType } from '../types';

const COLOR = {
  GRAY: '#a0a0a0',
  RED: '#db4437',
  BLUE: '#4183f0',
  GREEN: '#0f9d58',
} as const;

const getIconColor = (type: FileType, disabled: boolean): string => {
  if (disabled) {
    return COLOR.GRAY;
  }

  if (['pdf', 'image'].includes(type)) {
    return COLOR.RED;
  }
  if (['msWord', 'text'].includes(type)) {
    return COLOR.BLUE;
  }
  if (['spreadsheet', 'csv'].includes(type)) {
    return COLOR.GREEN;
  }

  return COLOR.GRAY;
};

export default getIconColor;
