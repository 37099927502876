import React, { useEffect, useState, useContext } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import isEqual from 'lodash/isEqual';
// context
import ToastContext from 'context/ToastContext';
// hooks
import useIsFirstRun from 'hooks/useIsFirstRun';
// components
import Placeholder from 'components/atoms/Placeholder';
import Button from 'components/atoms/Button';
import Loader from 'components/atoms/Loader';
import Modal from 'components/molecules/Modal';
import Email from '../Email';
// styles
import './emailModal.scss';

const propTypes = {
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      boxId: PropTypes.string,
      filename: PropTypes.string.isRequired,
      onClick: PropTypes.func,
    })
  ),
  isOpen: PropTypes.bool.isRequired,
  options: PropTypes.shape({
    subject: PropTypes.string,
    from: PropTypes.string,
    to: PropTypes.string,
    cc: PropTypes.arrayOf(PropTypes.string),
    bcc: PropTypes.arrayOf(PropTypes.string),
    replyTo: PropTypes.string,
  }),
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  html: PropTypes.string,
  title: PropTypes.string,
  emailOptionsOpen: PropTypes.bool,
};

const defaultProps = {
  attachments: [],
  options: {
    subject: '',
    from: '',
    to: '',
    cc: [''],
    bcc: [''],
    replyTo: '',
  },
  html: '',
  loading: false,
  loadingText: '',
  title: i18n.t('editor'),
  emailOptionsOpen: false,
};

const EditorModal = React.memo(
  ({
    isOpen,
    title,
    options,
    html,
    loading,
    loadingText,
    onClose,
    onSubmit,
    attachments,
    emailOptionsOpen,
  }) => {
    const { t } = useTranslation();
    const isFirstRun = useIsFirstRun();
    const { addToast } = useContext(ToastContext);
    const [email, setEmail] = useState({ html, options, isValid: false });
    const [showErrors, setShowErrors] = useState(false);

    useEffect(() => {
      if (!isFirstRun) {
        if (html !== email.html) {
          setEmail(prevEmail => ({ ...prevEmail, html }));
        }
        if (!isEqual(options, email.options)) {
          setEmail(prevEmail => ({ ...prevEmail, options }));
        }
      }
    }, [html, options]);

    const handleChange = newHtml => {
      setEmail({ ...email, html: newHtml });
    };

    const handleOptionsChange = (newOptions, areValid) => {
      setEmail({ ...email, options: newOptions, isValid: areValid });
    };

    const handleSubmit = () => {
      // check if email options are valid
      if (!email.isValid) {
        setShowErrors(true);
        addToast.error(t('invalidEmailOptions'));
        return;
      }

      onSubmit({
        html: email.html,
        ...email.options,
        attachments: attachments.map(attachment => ({
          id: attachment.boxId,
          filename: attachment.filename,
        })),
      });
    };

    return (
      <Modal isOpen={isOpen} size="lg" onClose={onClose} closeOnEsc={false} closeOnBackdrop={false}>
        <Modal.Header>{title}</Modal.Header>
        <Modal.Body>
          {loading && (
            <div className="space-30">
              <Loader />
              {loadingText && <Placeholder className="m-t-20">{loadingText}</Placeholder>}
            </div>
          )}
          <div className={loading ? 'display-none' : ''}>
            <Email
              html={email.html}
              attachments={attachments}
              options={email.options}
              onChange={handleChange}
              onOptionsChange={handleOptionsChange}
              showErrors={showErrors}
              emailOptionsOpen={emailOptionsOpen}
            />
          </div>
        </Modal.Body>
        {!loading && (
          <Modal.Footer>
            <Button label={t('cancel')} secondary onClick={onClose} />
            <span className="p-l-12">
              <Button label={t('send')} onClick={handleSubmit} />
            </span>
          </Modal.Footer>
        )}
      </Modal>
    );
  }
);

EditorModal.propTypes = propTypes;
EditorModal.defaultProps = defaultProps;

export default EditorModal;
