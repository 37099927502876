import getProperty from 'lodash/get';
import i18n from 'i18next';
// helpers
import { capitalizeWord } from 'helpers/StringParser';
// constants
import { POSITION_STATUS } from 'constants/positionStatus';
import {
  // position roles
  ACTOR_PRIMARY_OWNER,
  ACTOR_REQUESTER,
  ACTOR_PROJECT_MANAGER,
  // work arrangement
  WORK_ARRANGEMENT_FULL_TIME,
  WORK_ARRANGEMENT_PART_TIME,
  // evaluation methods
  METHOD_BEST_VALUE,
  METHOD_LOWEST_PRICE,
  // travel requirements
  TRAVEL_FULL_TIME,
  TRAVEL_VERY_FREQUENTLY,
  TRAVEL_SOMETIMES,
  TRAVEL_INFREQUENTLY,
  TRAVEL_NOT_REQUIRED,
  POSITION_TYPE,
} from 'constants';

export const getContractTypeLabel = type => {
  const map = {
    [POSITION_TYPE.FIXED_PRICE]: i18n.t('fixedPrice'),
    [POSITION_TYPE.TIME_AND_MATERIALS]: i18n.t('timeAndMaterials'),
  };

  return getProperty(map, type, type);
};

export const getWorkArrangementLabel = arrangement => {
  const map = {
    [WORK_ARRANGEMENT_FULL_TIME]: i18n.t('fullTime'),
    [WORK_ARRANGEMENT_PART_TIME]: i18n.t('partTime'),
  };

  return getProperty(map, arrangement, arrangement);
};

export const getEvaluationMethodLabel = method => {
  const map = {
    [METHOD_BEST_VALUE]: i18n.t('bestValue'),
    [METHOD_LOWEST_PRICE]: i18n.t('lowestPrice'),
  };

  return getProperty(map, method, method);
};

export const getPositionRoleLabel = role => {
  const map = {
    [ACTOR_PRIMARY_OWNER]: i18n.t('owner'),
    [ACTOR_REQUESTER]: i18n.t('requester'),
    [ACTOR_PROJECT_MANAGER]: i18n.t('projectManager'),
  };

  return getProperty(map, role, role);
};

export const getLanguageLabel = language => {
  const map = {
    ENGLISH: i18n.t('english'),
    FRENCH: i18n.t('french'),
    GERMAN: i18n.t('german'),
  };

  const label = getProperty(map, language);

  return label || capitalizeWord(language.toLowerCase());
};

export const getTravelLabel = travel => {
  const map = {
    [TRAVEL_FULL_TIME]: i18n.t('fullTime'),
    [TRAVEL_VERY_FREQUENTLY]: i18n.t('veryFrequently'),
    [TRAVEL_SOMETIMES]: i18n.t('sometimes'),
    [TRAVEL_INFREQUENTLY]: i18n.t('infrequently'),
    [TRAVEL_NOT_REQUIRED]: i18n.t('noTravelRequired'),
  };

  return getProperty(map, travel, travel);
};

/**
 * Get translated position status by role
 * @param {string} status
 * @param {string} [role]
 */
export const getStatusLabel = status => {
  const statusMap = {
    [POSITION_STATUS.UNPUBLISHED]: i18n.t('unpublished'),
    [POSITION_STATUS.PUBLISHED]: i18n.t('published'),
    [POSITION_STATUS.PENDING_SELECTION]: i18n.t('pendingSelection'),
    [POSITION_STATUS.OFFER]: i18n.t('offer'),
    [POSITION_STATUS.INTERVIEW]: i18n.t('interview'),
    [POSITION_STATUS.ONBOARDING]: i18n.t('onboarding'),
    [POSITION_STATUS.DELIVERY]: i18n.t('delivery'),
    [POSITION_STATUS.OFFBOARDING]: i18n.t('offBoarding'),
    [POSITION_STATUS.CLOSED]: i18n.t('closed'),
    [POSITION_STATUS.DELIVERY_CANCELLED]: i18n.t('deliveryCancelled'),
    [POSITION_STATUS.ONBOARDING_CANCELLED]: i18n.t('onboardingCancelled'),
    [POSITION_STATUS.NO_SUBMISSIONS]: i18n.t('noSubmissions'),
    [POSITION_STATUS.NO_SELECTION]: i18n.t('noSelection'),
    [POSITION_STATUS.CANCELLED]: i18n.t('cancelled'),
  };

  return getProperty(statusMap, status, '');
};
