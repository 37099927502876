import React from 'react';
// libraries
import PropTypes from 'prop-types';
// components
import EditForm from '../EditForm';
// helpers
import { startDateForm, startDateFormRequiredOffer } from '../forms';

const propTypes = {
  defaultStartDate: PropTypes.string,
  isOfferRequired: PropTypes.bool,
};

const defaultProps = {
  defaultStartDate: null,
  isOfferRequired: false,
};

const ConfirmStartDate = props => {
  const { defaultStartDate, isOfferRequired } = props;
  const form = isOfferRequired ? startDateFormRequiredOffer : startDateForm;

  return (
    <EditForm form={form} defaultData={{ startDate: { value: defaultStartDate } }} {...props} />
  );
};

ConfirmStartDate.propTypes = propTypes;
ConfirmStartDate.defaultProps = defaultProps;

export default ConfirmStartDate;
