import { WebSocketLink } from '@apollo/client/link/ws';
// services
import logger from 'services/Logger';
import { getToken, storeToken, tokenHasExpired, refreshToken } from 'services/Authentication';

/**
 * Get ws link for graphql subscriptions
 * @returns {string}
 */
const getWebsocketUrl = () => {
  const url = new URL('/graphql', window.location.href);

  if (url.protocol === 'https:') {
    url.protocol = url.protocol.replace('https', 'wss');
  } else {
    url.protocol = url.protocol.replace('http', 'ws');
  }

  return url.href;
};

// link for subscriptions
const wsLink = () => {
  const link = new WebSocketLink({
    uri: getWebsocketUrl(),
    options: {
      lazy: true,
      reconnect: true,
      reconnectionAttempts: 5,
      connectionParams: async () => {
        let token = getToken();

        if (!token || tokenHasExpired(token)) {
          try {
            token = await refreshToken();
            storeToken(token);

            // Reset the WS connection for it to carry the new JWT.
            link.subscriptionClient.close(false, false);
          } catch (error) {
            if (error.code !== 401) {
              logger.exception(error);
            }

            // close connection
            link.subscriptionClient.close();
          }
        }

        return {
          authToken: token,
        };
      },
    },
  });

  return link;
};

export default wsLink;
