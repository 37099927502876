import React, { useState, useRef } from 'react';
// libraries
import PropTypes from 'prop-types';
// constants
import { SH_EXPERIENCE_LEVEL } from 'constants';
// types
import { MetricType } from 'types';
// helpers
import { hasEnoughDataPoints } from 'helpers/MatchMetrics';
// components
import HoverDetector from 'components/atoms/HoverDetector';
import Popper from 'components/atoms/Popper';
import MatchMetrics from '../MatchMetrics';
import NotEnoughCandidatesMatch from './NotEnoughCandidatesMatch';
// styles
import styles from './matchMetricsPopper.module.scss';

const propTypes = {
  metrics: MetricType,
  score: PropTypes.number.isRequired,
  candidateName: PropTypes.string.isRequired,
  positionTitle: PropTypes.string.isRequired,
  project: PropTypes.string.isRequired,
  occupations: PropTypes.arrayOf(PropTypes.string).isRequired,
  experienceLevel: PropTypes.oneOf(Object.values(SH_EXPERIENCE_LEVEL)).isRequired,
  children: PropTypes.any.isRequired,
  placement: PropTypes.string,
  offsetX: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  offsetY: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const defaultProps = {
  metrics: null,
  offsetX: 0,
  offsetY: 0,
  placement: 'right-start',
};

const MatchMetricsPopper = ({
  metrics,
  score,
  candidateName,
  positionTitle,
  project,
  occupations,
  experienceLevel,
  children,
  placement,
  offsetX,
  offsetY,
}) => {
  const anchorEl = useRef();
  const [isOpen, setOpen] = useState(false);

  if (!metrics) {
    return children;
  }

  return (
    <React.Fragment>
      <div ref={anchorEl}>
        <HoverDetector
          onEnter={() => setOpen(true)}
          onLeave={() => setOpen(false)}
          disableClickAway
        >
          {children}
          <Popper
            isOpen={isOpen}
            placement={placement}
            anchorEl={anchorEl.current}
            containerClassName={styles.popper}
            offsetX={offsetX}
            offsetY={offsetY}
            disableBackdrop
          >
            {hasEnoughDataPoints(metrics.count) ? (
              <MatchMetrics
                metrics={metrics}
                score={score}
                candidateName={candidateName}
                positionTitle={positionTitle}
                project={project}
                occupations={occupations}
                experienceLevel={experienceLevel}
              />
            ) : (
              <NotEnoughCandidatesMatch score={score} />
            )}
          </Popper>
        </HoverDetector>
      </div>
    </React.Fragment>
  );
};

MatchMetricsPopper.propTypes = propTypes;
MatchMetricsPopper.defaultProps = defaultProps;

export default MatchMetricsPopper;
