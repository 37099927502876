import { gql } from '@apollo/client';
import getProperty from 'lodash/get';
// services
import client from 'services/Client';
import logger from 'services/Logger';

const HAS_SUBMISSION = gql`
  query hasSubmission($id: ID!) {
    candidate(id: $id) {
      id
      submissions {
        count
      }
    }
  }
`;

/**
 * @param {string} id
 * @returns {Promise<boolean>}
 */
const hasSubmission = async id => {
  try {
    const response = await client.query({ query: HAS_SUBMISSION, variables: { id } });

    return getProperty(response, 'data.candidate.submissions.count', 0) > 0;
  } catch (error) {
    logger.exception(error, { id });
    throw error;
  }
};

export default hasSubmission;
