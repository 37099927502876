import i18n from 'i18next';
import { ROUTE_LEGAL_TERMS, ROUTE_LEGAL_PRIVACY, ROUTE_LEGAL_COOKIES } from 'constants';

export const legalPageTabs = [
  {
    id: ROUTE_LEGAL_TERMS,
    label: i18n.t('termsOfUse'),
  },
  {
    id: ROUTE_LEGAL_PRIVACY,
    label: i18n.t('privacyPolicy'),
  },
  {
    id: ROUTE_LEGAL_COOKIES,
    label: i18n.t('cookiePolicy'),
  },
];
