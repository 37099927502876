// services
import client from 'services/Client';
import logger from 'services/Logger';
// libraries
import getProperty from 'lodash/get';
// constants
import { ACTION_CREATE_NEW } from 'constants';

const parseOptions = (response, customDataParser, type) => {
  const parsingData = customDataParser ? customDataParser(response.data) : response.data;
  const data = getProperty(parsingData, `${type}.items`, []);
  return data.map(({ id, name }) => ({ value: id, label: name }));
};

export const loadOptions = async (
  search,
  prevOptions,
  query,
  variables,
  type,
  customDataParser
) => {
  const searchLower = search ? search.toLowerCase() : '';

  try {
    const response = await client.query({
      query,
      variables: { inputValue: searchLower, skip: prevOptions.length, first: 10, ...variables },
    });
    const hasMore = getProperty(response, `data.${type}.items`, []).length !== 0;

    return {
      options: parseOptions(response, customDataParser, type),
      hasMore,
    };
  } catch (error) {
    logger.exception(error);
    throw error;
  }
};

export const addNewOption = async inputValue => {
  const newOption = {
    label: inputValue,
    value: ACTION_CREATE_NEW,
  };

  return newOption;
};
