import React from 'react';
// libraries
import { useTranslation } from 'react-i18next';
// components
import MenuGroup from './MenuGroup';

const Manager = ({ onClick, currentLink, isOpen }) => {
  const { t } = useTranslation();
  const routes = [
    {
      label: t('positions'),
      items: [
        {
          label: t('positions'),
          link: '/positions/all',
          icon: 'assignment',
          className: 'fs-unmask',
        },
      ],
    },
    {
      label: t('submissions'),
      items: [
        {
          label: t('submissions'),
          link: '/candidates/all',
          icon: 'candidates',
          className: 'fs-unmask',
        },
        {
          label: t('proposals'),
          link: '/proposals/all',
          icon: 'proposal',
          className: 'fs-unmask',
        },
      ],
    },
    {
      label: t('drafts'),
      items: [
        {
          label: t('myDrafts'),
          link: '/drafts',
          icon: 'edit',
          className: 'fs-unmask',
        },
      ],
    },
    {
      label: t('imports'),
      items: [
        {
          label: t('imports'),
          link: '/position/imports',
          icon: 'import',
          className: 'fs-unmask',
        },
      ],
    },
  ];

  return routes.map(route => {
    return (
      <div className="group" key={`manager_${route.label}`}>
        {isOpen && <div className="group_label">{route.label}</div>}
        <MenuGroup
          className={route.className}
          routes={route.items}
          onClick={onClick}
          currentLink={currentLink}
          isOpen={isOpen}
        />
      </div>
    );
  });
};

export default Manager;
