// Notification groups
export const NOTIFICATION_GROUP_POSITION = 'position';
export const NOTIFICATION_GROUP_CANDIDATE = 'candidate';
export const NOTIFICATION_GROUP_SUBMISSION = 'submission';
export const NOTIFICATION_GROUP_ONBOARDING = 'onboarding';
export const NOTIFICATION_GROUP_REVISION = 'revision';
export const NOTIFICATION_GROUP_PURCHASE_ORDER = 'po';
export const NOTIFICATION_GROUP_PURCHASE_PROJECT = 'projects';
export const NOTIFICATION_GROUP_IMPORT = 'imports';

// Notification types
export const NOTIFICATION_TYPE_IN_APP = 'web';
export const NOTIFICATION_TYPE_EMAIL = 'email';
export const NOTIFICATION_TYPE_SMS = 'sms';

// Notification events
export const NOTIFICATION_EVENT = {
  // position events
  POSITION_CREATED: 'position.created',
  POSITION_REPUBLISHED: 'position.republished',
  POSITION_PUBLISH_INFORMATION_CHANGED: 'position.publish.updated',
  POSITION_PUBLISHED: 'position.published',
  POSITION_UNPUBLISHED: 'position.unpublished',
  POSITION_OWNERSHIP_CLAIMED: 'position.ownership.claimed',
  POSITION_OWNERSHIP_REMOVED: 'position.ownership.removed',
  POSITION_CANCELLED: 'position.cancelled',
  POSITION_PARSING_SUCCESS: 'position.parsing.success',
  POSITION_PARSING_ERROR: 'position.parsing.error',
  // candidate events
  POSITION_BULK_MATCH: 'position.bulkMatch',
  CANDIDATE_DELETED: 'candidate.deleted',
  CANDIDATE_ADDED: 'candidate.added',
  CANDIDATE_BULK_MATCH: 'candidate.bulkMatch',
  // submission events
  SUBMISSION_EDITED: 'submission.edited',
  SUBMISSION_SUBMITTED_CLIENT: 'submission.submitted.client',
  SUBMISSION_SUBMITTED_ALAKA_SUPPLIER: 'submission.submitted.alaka.supplier',
  SUBMISSION_SUBMITTED_ALAKA_MATCH_MANAGER: 'submission.submitted.alaka.matchManager',
  SUBMISSION_SUBMITTED_PROJECT_MANAGER: 'submission.submitted.projectManager',
  SUBMISSION_SELECTED_CLIENT: 'submission.selected.client',
  SUBMISSION_REJECTED_CLIENT: 'submission.rejected.client',
  SUBMISSION_REJECTED_MATCH_MANAGER: 'submission.rejected.matchManager',
  SUBMISSION_REVISION_REQUEST: 'submission.revision.request',
  SUBMISSION_REVISION_SUBMITTED: 'submission.revision.submitted',
  SUBMISSION_REVISION_SUBMITTED_MM: 'submission.revision.submitted.matchManager',
  SUBMISSION_REVISION_SUBMITTED_PARTNER: 'submission.revision.submitted.supplier',
  SUBMISSION_WITHDRAWN: 'submission.withdrawn',
  // onboarding events
  ONBOARDING_START_DATE_ACCEPTED_PM: 'onboarding.startDate.accepted.projectManager',
  ONBOARDING_START_DATE_ACCEPTED_PARTNER: 'onboarding.startDate.accepted.supplier',
  ONBOARDING_START_DATE_ACCEPTED_MM: 'onboarding.startDate.accepted.matchManager',
  ONBOARDING_START_DATE_REJECTED_PM: 'onboarding.startDate.rejected.projectManager',
  ONBOARDING_START_DATE_REJECTED_PARTNER: 'onboarding.startDate.rejected.supplier',
  ONBOARDING_FORMS_UPLOADED_PARTNER: 'onboarding.forms.uploaded.supplier',
  ONBOARDING_FORMS_UPLOADED_MM: 'onboarding.forms.uploaded.matchManager',
  ONBOARDING_FORMS_UPLOADED_TO_AUSY: 'onboarding.forms.uploaded.ausy',
  ONBOARDING_FORMS_REQUEST: 'onboarding.forms.request',
  ONBOARDING_PO_CREATED: 'onboarding.po.created',
  ONBOARDING_PO_RECEIVED: 'onboarding.po.received',
  ONBOARDING_CANCELLED: 'onboarding.cancelled.matchManager',
  PO_PARSING_ERROR: 'po.parsing.error',
};
