import React from 'react';
// libraries
import PropTypes from 'prop-types';
import useAuth from 'hooks/useAuth';
// components
import ArrowContainer from './ArrowContainer';
// styles
import './progressTabs.scss';

const propTypes = {
  currentTab: PropTypes.string,
  tabs: PropTypes.array,
  isLinear: PropTypes.bool,
};

const defaultProps = {
  currentTab: null,
  tabs: [],
  isLinear: false,
};

const ProgressTabs = ({ currentTab, isLinear, tabs }) => {
  const { role } = useAuth();
  return (
    <div className="otherOptions">
      {tabs.map((tab, tabIndex) => {
        return (
          <div key={`${tab.id}_legend`} className={currentTab === tab.id ? 'highlight' : ''}>
            <div className="circleTab_label p-b-10 flex_space">
              <div>{tab.label}</div>
              <div>
                {tab.finishedStepsCount}/{tab.steps.length}
              </div>
            </div>
            <div className="display-flex-row flex1">
              {tab.steps.map((step, index) => {
                const { type, label, dueDate, isOverdue } = step;
                const isLast = isLinear
                  ? tabIndex === tabs.length - 1 && index === tab.steps.length - 1
                  : index === tab.steps.length - 1;
                const isFirst = isLinear ? tabIndex === 0 && index === 0 : index === 0;

                return (
                  <ArrowContainer
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${tab.id}_${index}`}
                    type={type}
                    label={label}
                    role={step.role}
                    currentRole={role}
                    dueDate={dueDate}
                    isOverdue={isOverdue}
                    isLast={isLast}
                    isFirst={isFirst}
                  />
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

ProgressTabs.propTypes = propTypes;
ProgressTabs.defaultProps = defaultProps;

export default ProgressTabs;
