/* eslint-disable import/order */
import React from 'react';
// libraries
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
// components
import ErrorPage from 'components/pages/ErrorPage';
import SimplePage from 'components/templates/SimplePage';
import CandidateDetails from './CandidateDetails';
import SelectedPosition from './SelectedPosition';
import CandidateHeader from './CandidateHeader';
import CandidateDetailsLoader from './CandidateDetailsLoader';
import CandidateMatchListTable from './CandidatesMatchTable';
// helpers
import parseCandidate from 'helpers/CandidateParser';
// query
import GET_CANDIDATE_BY_ID from './query';
// styles
import styles from './candidatePage.module.scss';

const CandidatePage = () => {
  const { candidateId, positionId } = useParams();

  const { loading, error, data } = useQuery(GET_CANDIDATE_BY_ID, {
    variables: {
      id: candidateId,
    },
  });

  if (loading) {
    return (
      <div className={styles.loader}>
        <CandidateDetailsLoader />
      </div>
    );
  }

  if (!loading && (!data.candidate || data.candidate.deleted || !data.candidate.isInPool)) {
    return <ErrorPage code={404} />;
  }

  if (error) {
    return <ErrorPage code={500} />;
  }

  const candidateData = parseCandidate(data.candidate);

  return (
    <div className={styles.content} id="scrollableContent">
      <React.Fragment>
        <CandidateHeader positionId={positionId} candidate={candidateData} />
        <SimplePage>
          {positionId && (
            <div className="m-b-20">
              <SelectedPosition positionId={positionId} candidateId={candidateId} />
            </div>
          )}
          <CandidateDetails candidate={candidateData} />
          {!positionId && (
            <div className="m-t-30">
              <CandidateMatchListTable candidateId={candidateId} candidate={candidateData} />
            </div>
          )}
        </SimplePage>
      </React.Fragment>
    </div>
  );
};

export default CandidatePage;
