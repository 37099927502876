import { useState, useEffect, useCallback } from 'react';
// libraries
import getProperty from 'lodash/get';
// services
import client from 'services/Client';
import logger from 'services/Logger';
// queries
import GET_CANDIDATE_MATCHES_LIST from './query';
// helpers
import { formatSort, formatFilter, parseCandidateMatches } from './helpers';
// constants
import { ORDER, SORT_BY } from './constants';

/**
 * @param {string} candidateId
 * @param {string} supplierId
 * @returns {object}
 */
const useCandidatesMatch = (candidateId, supplierId) => {
  const [hasError, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);
  const [sortedBy, setSortedBy] = useState({ value: SORT_BY.SCORE, order: ORDER.DESC });
  const [filteredBy, setFilteredBy] = useState({});

  const fetchData = async (filter, sort, skip = 0) => {
    try {
      // don't show loader if fetching more items for same filter
      if (!skip) setLoading(true);

      const response = await client.query({
        query: GET_CANDIDATE_MATCHES_LIST,
        variables: {
          filter: formatFilter(candidateId, supplierId, filter),
          sort: formatSort(sort),
          skip,
        },
      });
      const itemsCount = getProperty(response, 'data.candidateMatchesList.count', 0);
      const list = getProperty(response, 'data.candidateMatchesList.items', []);
      const parsedData = list.map(parseCandidateMatches);

      // if fetching more items for the same filter -> merge existing data with the new ones
      const newData = skip ? [...data, ...parsedData] : parsedData;
      setData(newData);
      setCount(itemsCount);

      return parsedData;
    } catch (error) {
      setError(true);
      logger.exception(error, { candidateId, supplierId, filter, sort });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        await fetchData(filteredBy, sortedBy);
      } catch (error) {
        setError(true);
        throw error;
      } finally {
        setLoading(false);
      }
    };
    fetchInitialData();
  }, []);

  const fetchMore = useCallback(() => {
    fetchData(filteredBy, sortedBy, data.length);
  }, [filteredBy, sortedBy, data.length]);

  const filter = useCallback(
    selectedFilters => {
      setFilteredBy(selectedFilters);
      fetchData(selectedFilters, sortedBy);
    },
    [sortedBy]
  );

  const sort = useCallback(
    (value, order) => {
      const sortBy = { value, order };

      setSortedBy(sortBy);
      fetchData(filteredBy, sortBy);
    },
    [filteredBy]
  );

  return {
    sort,
    filter,
    fetchMore,
    filteredBy,
    sortedBy,
    loading,
    error: hasError,
    data,
    count,
  };
};

export default useCandidatesMatch;
