import React from 'react';
import PropTypes from 'prop-types';
import querystring from 'query-string';
import { withRouter, Link } from 'react-router-dom';
import { withTranslation, Trans } from 'react-i18next';
// services
import { login } from 'services/Authentication';
// context
import ToastContext from 'context/ToastContext';
// components
import Icon from 'components/atoms/Icon';
import Loader from 'components/atoms/Loader';
import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
// styles
import colors from 'dependencies/materialStyles/Colors';

const propTypes = {
  location: PropTypes.any.isRequired,
  t: PropTypes.any.isRequired,
};

class LoginForm extends React.Component {
  static contextType = ToastContext;

  state = {
    email: '',
    password: '',
    loading: false,
  };

  handleChange = (value, id) => {
    this.setState({
      [id]: value,
    });
  };

  handleSubmit = async () => {
    const { addToast } = this.context;
    const { t, location } = this.props;
    const { email, password } = this.state;

    if (email !== '' && password !== '') {
      this.setState({
        loading: true,
      });

      try {
        await login({ password, username: email.trim() });

        const queryParams = location.search ? querystring.parse(location.search) : {};
        const redirect = queryParams.redirect || '/';
        window.location.replace(redirect);
      } catch (error) {
        addToast.error(t(error.type || 'errorPlaceholderText'));
        this.setState({ loading: false });
      }
    } else {
      addToast.error(t('fillEmailAndPassword'));
      this.setState({ error: true });
    }
  };

  render() {
    const { t } = this.props;
    const { loading, email, password, error } = this.state;

    const getLink = param => {
      return `${window.location.origin}${param}`;
    };

    return (
      <div className="authForm">
        <div className="authForm_header p-b-10">{t('signIn')}</div>
        <div className={`formWidth ${error && email === '' ? 'redForm' : ''}`}>
          <Input
            placeholder={t('email')}
            icon={<Icon color={colors.gray} size={18} name="person" />}
            value={email}
            id="email"
            className="authForm_input"
            onChange={this.handleChange}
          />
        </div>
        <div className={`formWidth ${error && password === '' ? 'redForm' : ''}`}>
          <Input
            id="password"
            className="authForm_input m-b-0"
            placeholder={t('password')}
            icon={<Icon color={colors.gray} size={18} name="lock" />}
            inputType="password"
            value={password}
            onChange={this.handleChange}
          />
        </div>
        {loading ? (
          <div className="m-t-20">
            &nbsp;
            <Loader />
          </div>
        ) : (
          <React.Fragment>
            <div className="authForm_link">
              <Link to="/forgot-password">{t('forgotPassword')}</Link>
            </div>
            <div className="authForm_buttons">
              <div className="authForm_button">
                <Button label={t('signIn')} disabled={loading} onClick={this.handleSubmit} />
              </div>
              <div className="authForm_signUp">
                {t('noAccount')}{' '}
                <Link className="authLink" to="/signup">
                  {t('registerHere')}
                </Link>
              </div>
            </div>
            <div className="authForm_statement m-t-20">
              <Trans i18nKey="loginLegalStatement">
                By signing in you agree to our{' '}
                <a href={getLink('/legal/terms')} target="_blank" rel="noopener noreferrer">
                  Terms of Use
                </a>{' '}
                and{' '}
                <a href={getLink('/legal/privacy')} target="_blank" rel="noopener noreferrer">
                  Privacy Policy
                </a>
                .
              </Trans>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

LoginForm.propTypes = propTypes;

export default withTranslation()(withRouter(LoginForm));
