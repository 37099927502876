/* eslint-disable import/order */
import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// hooks
import useAuth from 'hooks/useAuth';
// component
import Placeholder from 'components/atoms/Placeholder';
import Proposal from './Proposal';
// helpers
import parseProposal from 'helpers/ProposalParser';
import { filterSubmissions } from './helpers';
// styles
import styles from './positionSubmissions.module.scss';

const propTypes = {
  position: PropTypes.object.isRequired,
};

const PositionProposals = ({ position }) => {
  const { role, supplierId } = useAuth();
  const { t } = useTranslation();

  const proposals = filterSubmissions(position.proposals, role, supplierId);

  if (proposals.length > 0) {
    return (
      <div className={styles.container}>
        {proposals.map((proposal, index) => {
          return (
            <Proposal
              key={proposal.id}
              role={role}
              proposal={parseProposal(proposal)}
              className={index > 0 ? '' : 'border-top-none'}
            />
          );
        })}
      </div>
    );
  }

  return (
    <Placeholder size="lg" icon="proposal">
      {t('noProposals')}
    </Placeholder>
  );
};

PositionProposals.propTypes = propTypes;

export default PositionProposals;
