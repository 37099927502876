import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// components
import TextSection from 'components/atoms/TextSection';
import RatesList from 'components/molecules/Rates';
// constants
import { STAFF_ROLE, SUPPLIER_ROLE, RATES_NTE_RATE, RATES_TARGET_RATE } from 'constants';

const propTypes = {
  position: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
};

const Rates = ({ position, role }) => {
  const { t } = useTranslation();

  const nteRates = position.getRates(RATES_NTE_RATE);
  const targetRates = position.getRates(RATES_TARGET_RATE);

  const showTargetRates = () => {
    if (!targetRates.length) {
      return false;
    }
    if (role === STAFF_ROLE) {
      return true;
    }
    if (role === SUPPLIER_ROLE) {
      return position.showTargetRatesToPartners;
    }

    return false;
  };

  return (
    <React.Fragment>
      {(role !== SUPPLIER_ROLE || position.showNteRatesToPartners) && (
        <>
          <TextSection title={t('maximumRate')} classes={{ title: 'm-b-5' }}>
            <RatesList
              rates={nteRates}
              hideYears={position.isFixedPrice() && nteRates.length === 1}
            />
          </TextSection>
        </>
      )}
      {showTargetRates() && (
        <div className="m-t-20">
          <TextSection title={t('targetRates')}>
            <RatesList
              rates={targetRates}
              className="m-t-5"
              hideYears={position.isFixedPrice() && nteRates.length === 1}
            />
          </TextSection>
        </div>
      )}
    </React.Fragment>
  );
};

Rates.propTypes = propTypes;

export default Rates;
