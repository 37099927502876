/* eslint-disable no-unused-expressions */
import React from 'react';
// libraries
import PropTypes from 'prop-types';
// helpers
import { getRoleColor } from 'helpers/Colors';
import { ADMIN_ROLE, STAFF_ROLE, SUPPLIER_ROLE, MANAGER_ROLE } from 'constants';
// hooks
import useAuth from 'hooks/useAuth';
// components
import StaffMenu from './Staff';
import SupplierMenu from './Supplier';
import ManagerMenu from './Manager';
import ToggleButton from './ToggleButton';
// styles
import './menu.scss';

const propTypes = {
  location: PropTypes.string,
  close: PropTypes.func,
  open: PropTypes.func,
  clearFilters: PropTypes.func,
  menuOpen: PropTypes.bool,
  className: PropTypes.string,
};

const defaultProps = {
  location: '',
  close: () => {},
  open: () => {},
  clearFilters: () => {},
  menuOpen: false,
  className: '',
};

const Menu = ({ location, close, open, clearFilters, menuOpen, className }) => {
  const { role } = useAuth();

  const onClick = (event, link) => {
    if (link === location) {
      event.preventDefault();
    } else {
      !!clearFilters && clearFilters();
      !!close && close();
    }
  };

  const onArrowClick = () => {
    if (menuOpen) {
      close();
    } else {
      open();
    }
  };

  const renderMenuItems = () => {
    if (role === STAFF_ROLE || role === ADMIN_ROLE) {
      return <StaffMenu onClick={onClick} currentLink={location} isOpen={menuOpen} />;
    }
    if (role === SUPPLIER_ROLE) {
      return <SupplierMenu onClick={onClick} currentLink={location} isOpen={menuOpen} />;
    }
    if (role === MANAGER_ROLE) {
      return <ManagerMenu onClick={onClick} currentLink={location} isOpen={menuOpen} />;
    }

    return null;
  };

  return (
    <div
      className={`menu--${
        role ? getRoleColor[role] : getRoleColor.default
      } ${className} overflowYAuto display-flex p-t-15`}
    >
      <div className="flex1">{renderMenuItems()}</div>
      <ToggleButton isOpen={menuOpen} onClick={onArrowClick} />
    </div>
  );
};

Menu.propTypes = propTypes;
Menu.defaultProps = defaultProps;

export default Menu;
