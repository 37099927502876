import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// components
import Row from './Row';
import Head from './Head';
import Cell from './Cell';
// styles
import './responsiveTable.scss';

const propTypes = {
  bordered: PropTypes.bool,
  striped: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
};

const defaultProps = {
  bordered: false,
  striped: false,
  className: '',
};

const ResponsiveTable = ({ bordered, striped, className, children }) => {
  return (
    <div
      className={classNames('responsiveTable', {
        'responsiveTable--bordered': bordered,
        'responsiveTable--striped': striped,
        [className]: className,
      })}
    >
      {children}
    </div>
  );
};

ResponsiveTable.propTypes = propTypes;
ResponsiveTable.defaultProps = defaultProps;

ResponsiveTable.Row = Row;
ResponsiveTable.Head = Head;
ResponsiveTable.Cell = Cell;

export default ResponsiveTable;
