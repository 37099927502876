import React from 'react';
import ContentLoader from 'react-content-loader';

const SinglePositionLoader = () => (
  <ContentLoader
    height={450}
    width={1600}
    speed={1}
    primaryColor="#f1f1f1"
    secondaryColor="#e6e6e6"
  >
    <rect x="50" y="45" rx="3" ry="3" width="150" height="10" />
    <rect x="1450" y="45" rx="3" ry="3" width="100" height="10" />
    <rect x="50" y="90" rx="3" ry="3" width="1500" height="10" />
    <rect x="50" y="120" rx="3" ry="3" width="1500" height="10" />
    <rect x="50" y="150" rx="3" ry="3" width="1500" height="10" />
    <rect x="50" y="200" rx="3" ry="3" width="120" height="10" />
    <rect x="50" y="230" rx="3" ry="3" width="1500" height="10" />
    <rect x="50" y="260" rx="3" ry="3" width="1500" height="10" />
    <rect x="50" y="290" rx="3" ry="3" width="1500" height="10" />
    <rect x="50" y="340" rx="3" ry="3" width="220" height="10" />
    <rect x="50" y="370" rx="3" ry="3" width="1500" height="10" />
    <rect x="50" y="400" rx="3" ry="3" width="1500" height="10" />
    <rect x="50" y="430" rx="3" ry="3" width="1500" height="10" />
  </ContentLoader>
);

export default SinglePositionLoader;
