/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
// hooks
import { useCookieSettings } from 'components/organisms/CookieSettings';
// components
import LevelList from 'components/molecules/LevelList';
import Section from 'components/atoms/Section';
import Table from 'components/molecules/ResponsiveTable';
import LinkButton from 'components/atoms/LinkButton';
// constants
import { LIST_TYPE_BULLET } from 'constants/list';
import { COOKIE_POLICY_UPDATE_DATE } from 'constants';
// helpers
import { getTimeYear } from 'helpers/RelativeTimeFormatter';
import { INSTALLED_COOKIES } from './Texts';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const Cookies = ({ className }) => {
  const { t } = useTranslation();
  const { showCookieSettings } = useCookieSettings();
  const lastUpdatedDate = getTimeYear(COOKIE_POLICY_UPDATE_DATE);

  return (
    <div className={className}>
      <Section title={t('cookiePolicy')} titleClassName="mainHeader" />
      <br />
      <div className="lastUpdateDate">{t('lastUpdated', { date: lastUpdatedDate })}</div>
      <br />
      <br />
      <div>
        {t('cookies1')}
        <br />
        <br />
        {t('cookies2')}
        <br />
        <br />
        {t('cookies3')}
      </div>
      <br />
      <Section title={t('whatIsCookie')} content={t('whatIsCookieDescription')} />
      <Section
        title={t('typesOfCookies')}
        content={
          <div>
            {t('typesOfCookiesDescription1')} <br />
            <br />
            <Trans i18nKey="typesOfCookiesDescription2">
              <b>Essential Cookies</b> are cookies needed for the website to run smoothly and
              facilitate navigation. With these cookies user can move around and use all the
              features without interruption. Without these essential cookies it is not possible for
              the website to function properly. Essential cookies are generally kept until the
              browser closes.
            </Trans>
            <br />
            <br />
            {t('typesOfCookiesDescription3')}
            <br />
            <br />
            <Trans i18nKey="typesOfCookiesDescription4" components={[<b />]} />
            <br />
            <br />
            {t('typesOfCookiesDescription5')}
            <br />
            <br />
            <Trans i18nKey="typesOfCookiesDescription6" components={[<b />]} />
            <br />
            <br />
            {t('typesOfCookiesDescription7')}
            <br />
            <br />
          </div>
        }
      />
      <Section
        title={t('websiteCookies')}
        content={
          <div>
            {t('websiteCookiesDescription')}
            <br />
            <br />
            <Table bordered>
              <Table.Row>
                <Table.Head> {t('type')}</Table.Head>
                <Table.Head> {t('name')}</Table.Head>
                <Table.Head> {t('provider')}</Table.Head>
                <Table.Head> {t('purpose')}</Table.Head>
              </Table.Row>
              {INSTALLED_COOKIES.map(({ type, name, provider, purpose }) => (
                <Table.Row key={name}>
                  <Table.Cell>
                    <b>{type}</b>
                  </Table.Cell>
                  <Table.Cell>{name}</Table.Cell>
                  <Table.Cell>{provider}</Table.Cell>
                  <Table.Cell>{purpose}</Table.Cell>
                </Table.Row>
              ))}
            </Table>
          </div>
        }
      />
      <Section
        title={t('howDoIChangeMyCookieSettings')}
        content={
          <Trans
            i18nKey="howDoIChangeMyCookieSettingsDescription"
            components={[
              <LinkButton inline onClick={showCookieSettings}>
                {t('here')}
              </LinkButton>,
            ]}
          />
        }
      />
      <Section
        title={t('deletingOrDisablingCookies')}
        content={
          <div>
            <Trans i18nKey="deletingOrDisablingCookiesDescription" components={[<br />]} />
            <LevelList
              content={{
                type: LIST_TYPE_BULLET,
                list: [
                  {
                    content: (
                      <a href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform=Desktop&hl=en">
                        Chrome
                      </a>
                    ),
                  },
                  {
                    content: (
                      <a href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop">
                        Firefox
                      </a>
                    ),
                  },
                  {
                    content: (
                      <a href="https://support.apple.com/en-in/guide/safari/sfri11471/mac">
                        Safari (iOS)
                      </a>
                    ),
                  },
                  {
                    content: (
                      <a href="https://blogs.opera.com/news/2015/08/how-to-manage-cookies-in-opera/">
                        Opera
                      </a>
                    ),
                  },
                  {
                    content: (
                      <a href="https://www.cookiesandyou.com/disable-cookies/windows/edge/">Edge</a>
                    ),
                  },
                ],
              }}
            />
          </div>
        }
      />
      <Section
        title={t('changesToPrivacyPolicy')}
        content={
          <Trans
            i18nKey="changesToPrivacyPolicyDescription"
            components={[<br />]}
            values={{
              lastUpdatedDate,
            }}
          />
        }
      />
      <Section
        title={t('howToContactUs')}
        content={
          <Trans i18nKey="howToContactUsDescription">
            If you have any questions or would like further information, don&apos;t hesitate to
            contact us on{' '}
            <a href="mailto:talent.matching.hub@be.ibm.com">talent.matching.hub@be.ibm.com</a> or
            via the Feedback button available on the website.
          </Trans>
        }
      />
      <Section
        title={t('otherHelpfulResource')}
        content={
          <div>
            {t('otherHelpfulResourceDescription1')}
            <br />
            <LevelList
              content={{
                type: LIST_TYPE_BULLET,
                list: [
                  {
                    content: (
                      <a href="http://www.allaboutcookies.org">http://www.allaboutcookies.org</a>
                    ),
                  },
                ],
              }}
            />
          </div>
        }
      />
    </div>
  );
};

Cookies.propTypes = propTypes;
Cookies.defaultProps = defaultProps;

export default Cookies;
