import React from 'react';
// libraries
import PropTypes from 'prop-types';
// helpers
import { openLink } from 'helpers/Link';
import { boxFileUrl } from 'helpers/Box';
// components
import Attachment from 'components/molecules/Attachment';
// styles
import styles from './attachments.module.scss';

const propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      boxId: PropTypes.string,
      filename: PropTypes.string.isRequired,
      onClick: PropTypes.func,
    })
  ),
};

const defaultProps = {
  items: [],
};

const Attachments = ({ items }) => {
  return (
    <div className={styles.attachments}>
      {items.map(({ boxId, filename, onClick }) => (
        <div className={styles.attachment} key={filename}>
          <Attachment
            filename={filename}
            onClick={() => (onClick ? onClick() : openLink(boxFileUrl(boxId)))}
          />
        </div>
      ))}
    </div>
  );
};

Attachments.propTypes = propTypes;
Attachments.defaultProps = defaultProps;

export default Attachments;
