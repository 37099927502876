/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
// components
import ListItem from './ListItem';
// styles
import './levelList.scss';

const propTypes = {
  content: PropTypes.any,
  firstLevelClassName: PropTypes.string,
};

const defaultProps = {
  content: null,
  firstLevelClassName: '',
};

const LevelList = ({ content, firstLevelClassName }) => {
  const renderList = (list, type = '', level = 0, prevIndex = '') =>
    list.map((item, index) => {
      return (
        <React.Fragment key={`${level}-${index}`}>
          <ListItem
            title={item.title}
            index={index}
            preIndex={prevIndex}
            type={type}
            className={level === 0 ? firstLevelClassName : ''}
            content={
              <React.Fragment>
                {item.content}
                {item.list
                  ? renderList(
                      item.list,
                      item.type,
                      level + 1,
                      prevIndex ? `${prevIndex}.${index + 1}.` : `${index + 1}.`
                    )
                  : null}
              </React.Fragment>
            }
          />
        </React.Fragment>
      );
    });

  return (
    <div className="levelList">{renderList(content.list, content.type, 0, content.prevIndex)}</div>
  );
};

LevelList.propTypes = propTypes;
LevelList.defaultProps = defaultProps;

export default LevelList;
