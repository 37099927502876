import React from 'react';
// Libraries
import isEqual from 'lodash/isEqual';
import shortid from 'shortid';
import PropTypes from 'prop-types';
// components
import DragDrop from 'components/atoms/DragDrop';
import Icon from 'components/atoms/Icon';
import NewField from 'components/molecules/NewField';
// constants
import { SELECT_TYPE } from 'constants';
// styles
import colors from 'dependencies/materialStyles/Colors';
import './inputGroup.scss';
// helpers
import { reorder } from './Helpers';

const propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  selected: PropTypes.array,
  onChange: PropTypes.func,
  list: PropTypes.array,
  values: PropTypes.array,
  loading: PropTypes.bool,
  type: PropTypes.string,
};

const defaultProps = {
  selected: [],
  onChange: () => {},
  placeholder: null,
  list: null,
  loading: false,
  values: null,
  type: '',
};

class Groups extends React.Component {
  constructor(props) {
    super(props);
    const { selected } = this.props;
    this.state = {
      items: selected ? selected.map(item => this.getComponent(item)) : [],
      inputValue: '',
    };
  }

  componentDidUpdate(oldProps) {
    const { selected } = this.props;
    if (!isEqual(oldProps.selected, selected)) {
      this.setState({
        items: selected.map(item => {
          return this.getComponent(item);
        }),
      });
    }
  }

  filterItems = id => {
    const { items } = this.state;
    return items.filter(item => item.id !== id);
  };

  removeChip = itemId => {
    const { onChange, id } = this.props;
    const items = this.filterItems(itemId);
    this.setState({ items });
    onChange(id, items);
  };

  onChange = value => {
    this.setState({ inputValue: value.target.value });
  };

  addInput = () => {
    const { items, inputValue } = this.state;
    this.setState({
      items: [...items, this.getComponent(inputValue)],
    });
    this.setState({ inputValue: '' });
  };

  updateCheck = value => {
    const { onChange, id } = this.props;
    const { items } = this.state;
    this.setState(prevState => ({
      items: [...prevState.items, this.getComponent(value)],
    }));
    onChange(id, [...items, value]);
  };

  onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    this.setState(prevState => ({
      items: reorder(prevState.items, result.source.index, result.destination.index),
    }));
  };

  getComponent = value => {
    const id = shortid.generate();
    return {
      id,
      label: value.label,
      value: value.value,
      content: (
        <div className="inputGroup_item">
          <div className="inputGroup_chip">{value.label}</div>
          <div className="inputGroup_close" onClick={() => this.removeChip(id)} role="presentation">
            <Icon name="close" size="18" color={colors.gray} />
          </div>
        </div>
      ),
    };
  };

  render() {
    const { placeholder, id, list, selected, type, values, loading } = this.props;
    const { items, inputValue } = this.state;
    return (
      <div className={`test_${id}`}>
        <NewField
          content={
            <div className={items && items.length > 0 ? 'p-b-10' : ''}>
              <DragDrop
                onDragEnd={this.onDragEnd}
                items={items}
                className="display-flex-row wrap"
              />
            </div>
          }
          id={id}
          addInput={this.addInput}
          placeholder={placeholder}
          selected={type === SELECT_TYPE ? selected : inputValue}
          onChange={type === SELECT_TYPE ? this.updateCheck : this.onChange}
          type={type}
          list={values || list}
          loading={loading}
        />
      </div>
    );
  }
}

Groups.propTypes = propTypes;
Groups.defaultProps = defaultProps;

export default Groups;
