import React from 'react';
// libraries
import PropTypes from 'prop-types';
// components
import Event from './Event';

const propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  events: PropTypes.object,
  types: PropTypes.array,
};

const defaultProps = {
  className: '',
  onChange: () => {},
  events: {},
  types: [],
};

const Events = props => {
  const { className, events, onChange, types } = props;

  return (
    <div className={className}>
      <Event
        className="notificationSettings_content-header"
        onChange={onChange}
        types={types}
        eventItem={events.header}
        isHeader
      />
      {events.list.map(event => (
        <Event onChange={onChange} eventItem={event} types={types} key={event.event} />
      ))}
    </div>
  );
};

Events.propTypes = propTypes;
Events.defaultProps = defaultProps;

export default Events;
