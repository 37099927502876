import React from 'react';
// libraries
import ContentLoader from 'react-content-loader';
import times from 'lodash/times';
// styles
import styles from './contentLoaders.module.scss';

const CardsLoader = () => {
  return (
    <div className={styles.cardsLoader}>
      {times(4, index => (
        <div key={index}>
          <ContentLoader
            height={160}
            width={350}
            speed={1}
            primaryColor="#f1f1f1"
            secondaryColor="#e6e6e6"
          >
            <rect x="5" y="15" rx="5" ry="5" width="220" height="5" />
            <rect x="5" y="45" rx="5" ry="5" width="260" height="5" />
            <rect x="5" y="75" rx="5" ry="5" width="320" height="5" />
            <rect x="5" y="105" rx="5" ry="5" width="290" height="5" />
          </ContentLoader>
        </div>
      ))}
    </div>
  );
};

export default CardsLoader;
