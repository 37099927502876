// libraries
import unionBy from 'lodash/unionBy';
import hasProperty from 'lodash/has';
import getProperty from 'lodash/get';
// constants
import { SUBMISSION_STATUS } from 'constants/submissionStatus';
import { STATUS_STATE } from 'components/molecules/StatusFlow';
// helpers
import { getStatuses } from './statuses';

const getState = status => {
  if (!hasProperty(status, 'start') && !hasProperty(status, 'end')) {
    return STATUS_STATE.TO_DO;
  }
  if (getProperty(status, 'start') && getProperty(status, 'end')) {
    return STATUS_STATE.FINISHED;
  }
  return STATUS_STATE.ACTIVE;
};

const FINAL_STATUSES = [
  SUBMISSION_STATUS.NOT_SELECTED,
  SUBMISSION_STATUS.NOT_SUBMITTED,
  SUBMISSION_STATUS.WITHDRAWN,
  SUBMISSION_STATUS.ONBOARDING_CANCELLED,
];

const isStatusCompleted = status => {
  const isFinalStatus = FINAL_STATUSES.includes(status.value);
  const state = getState(status);
  // status is finished
  if (state === STATUS_STATE.FINISHED) return true;

  // final and active status are considered finished
  return isFinalStatus && state === STATUS_STATE.ACTIVE;
};

/**
 * Format candidate statuses for Status flow component
 * @param {object[]} history - status history of candidate
 * @param {string} role
 * @returns {object[]}
 */
export const formatHistory = (history, role) => {
  // hide planned statuses if position is closed or cancelled
  if (history.some(status => FINAL_STATUSES.includes(status.value))) {
    return history.map(status => {
      return {
        ...status,
        state: getState(status),
        isCompleted: isStatusCompleted(status),
      };
    });
  }

  // add planned statuses
  const historyStatuses = unionBy(history, getStatuses(role), 'value');

  return historyStatuses.map(status => {
    return {
      ...status,
      state: getState(status),
      isCompleted: isStatusCompleted(status),
    };
  });
};
