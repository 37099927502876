import React from 'react';
import classNames from 'classnames';
import styles from './heading.module.scss';

export interface HeadingProps extends React.HTMLAttributes<HTMLElement> {
  children: React.ReactNode;
  className?: string;
  level?: 1 | 2 | 3;
}

const Heading = ({ children, className, level = 1, ...props }: HeadingProps) => {
  const Tag: keyof JSX.IntrinsicElements = `h${level}`;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Tag className={classNames(styles.container, className)} {...props}>
      {children}
    </Tag>
  );
};

export default Heading;
