// libraries
import getProperty from 'lodash/get';
import findKey from 'lodash/findKey';
// service
import axios from 'services/Axios';
import logger from 'services/Logger';
// helpers
import { boxFileDir } from 'helpers/Box';
import { formatNumber } from 'helpers/Number';
import { parseMRI } from 'helpers/MRI';
// constants
import { SH_ERRORS } from 'constants/error';

/**
 * Parse SH error
 * @param {Error} error
 * @returns {Error}
 */
const parseShError = error => {
  const errors = getProperty(error, 'response.data.params.errors');
  const SH_ERROR_CODES = Object.values(SH_ERRORS);

  if (errors) {
    const shErrorCode = Object.values(errors).find(err => SH_ERROR_CODES.includes(err.code));

    if (shErrorCode) {
      return {
        ...error,
        type: findKey(SH_ERRORS, errorCode => errorCode === shErrorCode.code),
      };
    }
  }

  return error;
};

/**
 * @param {string} shPositionId
 * @returns {Promise<object>}
 */
export const getPositionReport = async shPositionId => {
  try {
    const response = await axios.get(`/api/report/position/${shPositionId}`);

    return response.data.report;
  } catch (error) {
    logger.exception(error);
    throw parseShError(error);
  }
};

/**
 * @param {string} shCandidateId
 * @returns {Promise<object>}
 */
export const getCandidate = async shCandidateId => {
  const response = await axios.get(`/api/report/candidate/data/${shCandidateId}`);

  return getProperty(response, 'data');
};

/**
 * @typedef {Object} Metrics
 * @property {number} min
 * @property {number} max
 * @property {number} median
 * @property {number} count
 * @property {?number} percentile
 * @property {number[]} dataPoints
 */

/**
 * Get match metrics
 * @param {object} filter filter options
 * @param {string} filter.occupation position occupations
 * @param {string} filter.projectId position project id
 * @param {number} score candidate score
 * @param {string} [excludeId] candidate SH id
 * @returns {Promise<Metrics>}
 */
export const getMatchMetrics = async (filter, score, excludeId) => {
  try {
    const response = await axios.post(`/api/report/match/metrics`, { filter, score, excludeId });
    const metrics = getProperty(response, 'data.metrics');
    const min = getProperty(metrics, 'score.skills.overall.min') || 0;
    const max = getProperty(metrics, 'score.skills.overall.max') || 0;
    const mean = getProperty(metrics, 'score.skills.overall.mean') || 0;
    const median = getProperty(metrics, 'score.skills.overall.median') || 0;
    const percentile = getProperty(metrics, 'score.skills.overall.percentile');

    return {
      min: formatNumber(min * 100, 2),
      max: formatNumber(max * 100, 2),
      mean: formatNumber(mean * 100, 2),
      median: formatNumber(median * 100, 2),
      count: getProperty(metrics, 'score.skills.overall.count') || 0,
      percentile: percentile ? formatNumber(percentile * 100, 2) : percentile,
      dataPoints: getProperty(metrics, 'datapoints.score.skills.overall', []).map(value =>
        formatNumber(value * 100, 2)
      ),
    };
  } catch (error) {
    logger.exception(error);
    throw error;
  }
};

/**
 * @param {object} params
 * @param {string} params.shCandidateId
 * @param {string} params.shPositionId
 * @returns {Promise<object>}
 */
export const getMatchReport = async ({ shCandidateId, shPositionId }) => {
  try {
    const response = await axios.get(`/api/report/match/${shCandidateId}/${shPositionId}`);

    return response.data.report;
  } catch (error) {
    logger.exception(error);
    throw parseShError(error);
  }
};

/**
 * @param {File} file
 * @param {object} data
 * @param {string} data.project
 * @param {string} data.client
 * @param {string} data.rfq
 * @param {string} data.shProjectId
 * @param {string} data.positionId
 * @param {string} data.supplierId
 * @param {string} data.userId
 */
export const uploadCv = async (file, data) => {
  const { project, rfq } = data;
  const formData = new FormData();
  formData.append('file', file);
  formData.append('filename', file.name);
  formData.append('dir', boxFileDir({ client: data.client, project, rfq }));
  formData.append('shProjectId', data.shProjectId);
  formData.append('supplierId', data.supplierId);
  formData.append('positionId', data.positionId);
  formData.append('userId', data.userId);

  try {
    const reportResponse = await axios.post('/api/report/match/create', formData);

    return reportResponse.data;
  } catch (error) {
    logger.exception(error, { data });
    throw parseShError(error);
  }
};

/**
 * @param {File} file
 * @param {object} data
 * @param {string} data.project
 * @param {string} data.client
 * @param {string} data.rfq
 * @param {string} data.positionId
 * @param {string} data.supplierId
 * @param {string} data.shProjectId
 * @param {string} data.shPositionId
 * @param {string} data.positionReportId
 * @param {string} data.userId
 */
export const replaceSubmissionCV = async (file, data) => {
  try {
    const { project, rfq } = data;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('filename', file.name);
    formData.append('dir', boxFileDir({ client: data.client, project, rfq }));
    formData.append('positionId', data.positionId);
    formData.append('supplierId', data.supplierId);
    formData.append('shProjectId', data.shProjectId);
    formData.append('shPositionId', data.shPositionId);
    formData.append('positionReportId', data.positionReportId);
    formData.append('userId', data.userId);

    const reportResponse = await axios.post('/api/report/replace/submission', formData);
    const mri = getProperty(reportResponse, 'data.mri');

    return {
      ...reportResponse.data,
      mri: parseMRI(mri),
    };
  } catch (error) {
    logger.exception(error, { data });
    throw parseShError(error);
  }
};

/**
 * @param {File} file
 * @param {string} supplierName
 * @param {string} supplierId
 * @param {string} [candidateId]
 */
export const replaceCandidateCV = async (file, supplierName, supplierId, candidateId) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('filename', file.name);
    formData.append('dir', supplierName);
    formData.append('supplierId', supplierId);
    formData.append('candidateId', candidateId);

    const reportResponse = await axios.post('/api/report/replace/candidate', formData);
    const mri = getProperty(reportResponse, 'data.mri');

    return {
      ...reportResponse.data,
      mri: parseMRI(mri),
    };
  } catch (error) {
    logger.exception(error, { supplierName });
    throw parseShError(error);
  }
};

/**
 * Uploads and parses cv in SH
 * @param {File} file
 * @param {string} supplierName
 * @param {object} params
 * @param {string} params.supplierName
 * @param {string} params.userId
 * @param {string} params.supplierId
 */
export const parseCv = async (file, { supplierName, userId, supplierId }) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('filename', file.name);
    formData.append('dir', supplierName);
    formData.append('userId', userId);
    formData.append('supplierId', supplierId);

    const reportResponse = await axios.post('/api/report/cv/parse', formData);
    const mri = getProperty(reportResponse, 'data.mri');

    return {
      ...reportResponse.data,
      mri: parseMRI(mri),
    };
  } catch (error) {
    logger.exception(error, { supplierName });
    throw parseShError(error);
  }
};

/**
 * @param {string} shCandidateId SH candidate id
 * @returns {Promise<object>}
 */
export const getCandidateMRI = async shCandidateId => {
  try {
    const response = await axios.get(`/api/report/mri/${shCandidateId}`);

    return parseMRI(getProperty(response, 'data.mri'));
  } catch (error) {
    logger.exception(error, { shCandidateId });
    throw error;
  }
};
