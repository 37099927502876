// libraries
import i18n from 'i18next';
// constants
import { SORT_DRAFT_NAME, SORT_UPDATED_DATE } from 'constants';

const DraftSort = [
  {
    label: i18n.t('draftName'),
    id: SORT_DRAFT_NAME,
  },
  {
    label: i18n.t('updatedAtDate'),
    id: SORT_UPDATED_DATE,
  },
];

export default DraftSort;
