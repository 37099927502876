// libraries
import i18n from 'i18next';
import getProperty from 'lodash/get';
import difference from 'lodash/difference';
import isBoolean from 'lodash/isBoolean';
import sumBy from 'lodash/sumBy';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
// constants
import { COLOR } from 'constants/colors';
import {
  MRI_SECTION,
  MRI_IMPACT,
  SECTION_IMPACTS,
  IMPACT_ORDER,
  MRI_LEVEL,
} from 'constants/report';

export const LEVELS = [
  {
    value: MRI_LEVEL.LOW,
    label: i18n.t('low'),
    color: COLOR.RED,
  },
  {
    value: MRI_LEVEL.MODERATE,
    label: i18n.t('moderate'),
    color: COLOR.YELLOW,
  },
  {
    value: MRI_LEVEL.HIGH,
    label: i18n.t('high'),
    color: COLOR.GREEN,
  },
];

export const getLevelDetails = levelKey => {
  return LEVELS.find(({ value }) => value === levelKey);
};

const IMPACTS = {
  [MRI_IMPACT.LOW]: {
    color: COLOR.GREEN,
    label: i18n.t('lowMatchImpact'),
    dots: 1,
  },
  [MRI_IMPACT.MEDIUM]: {
    color: COLOR.YELLOW,
    label: i18n.t('mediumMatchImpact'),
    dots: 2,
  },
  [MRI_IMPACT.HIGH]: {
    color: COLOR.RED,
    label: i18n.t('highMatchImpact'),
    dots: 3,
  },
};

export const getImpactDetails = impactKey => {
  return getProperty(IMPACTS, impactKey, {});
};

const getLevel = issues => {
  return sumBy(issues, 'penalty');
};

export const FRACTIONAL_DEDUCTION_MAP = {
  411: false,
  412: false,
  331: false,
  413: false,
  902: false,
  221: true,
  222: true,
  224: true,
  225: true,
  414: false,
  416: false,
  901: false,
  903: false,
  231: true,
  232: true,
  904: true,
  905: true,
};

const getFractionalDeduction = issueIds => {
  return !issueIds.find(id => !getProperty(FRACTIONAL_DEDUCTION_MAP, id, true));
};

/**
 * @param {number} penalty
 * @param {number|boolean} fractionalDeduction boolean or issue code
 * @returns {string}
 */
export const getPenaltyLevel = (penalty, fractionalDeduction) => {
  const fractionalDeductionValue = isBoolean(fractionalDeduction)
    ? fractionalDeduction
    : FRACTIONAL_DEDUCTION_MAP[fractionalDeduction];

  if (penalty === 0) {
    return MRI_LEVEL.HIGH;
  }
  if (penalty > 0 && penalty <= 15 && fractionalDeductionValue) {
    return MRI_LEVEL.MODERATE;
  }

  return MRI_LEVEL.LOW;
};

const formatGoodSections = (sections = []) => {
  return sections.map(section => ({
    name: section,
    impact: MRI_IMPACT.LOW,
  }));
};

const getGoodSections = (improvements = [], consideredSections = []) => {
  const improvementPoints = improvements.map(improvement => improvement.section);
  return formatGoodSections(
    difference(consideredSections, [...improvementPoints, MRI_SECTION.ALL_SECTIONS])
  );
};

const getBadSections = improvements => {
  const groupedImprovements = groupBy(
    improvements.filter(({ impact }) =>
      [MRI_IMPACT.LOW, MRI_IMPACT.MEDIUM, MRI_IMPACT.HIGH].includes(impact)
    ),
    'section'
  );
  const formattedImprovements = Object.values(groupedImprovements).map(section => {
    const sectionKey = getProperty(section, '0.section');
    const penalty = getLevel(section);

    return {
      id: sectionKey,
      impact: getProperty(SECTION_IMPACTS, sectionKey),
      name: getProperty(section, '0.section'),
      level: getPenaltyLevel(penalty, getFractionalDeduction(section.map(({ id }) => id))),
      penalty,
      issues: section.map(({ description, id }) => ({
        code: id,
        text: description,
      })),
    };
  });

  return orderBy(
    formattedImprovements,
    [
      val => {
        return getProperty(IMPACT_ORDER, val.impact);
      },
      'penalty',
    ],
    ['desc', 'desc']
  );
};

/**
 *
 * @typedef {Object} GoodSection
 * @property {string} name One of MRI_SECTION
 * @property {string} impact  One of MRI_IMPACT
 */

/**
 *
 * @typedef {Object} BadSection
 * @property {string} name One of MRI_SECTION
 * @property {string} impact  One of MRI_IMPACT
 * @property {number} penalty
 * @property {string} level One of MRI_LEVEL
 * @property {string[]} issues Array of section issues
 */

/**
 * @typedef {Object} MRI
 * @property {number} score Float number with two decimals
 * @property {string[]} sections Array of sections.
 * @property {GoodSection[]} goodSections Array of good sections.
 * @property {BadSection[]} badSections Array of bad sections.
 */

/**
 * Crates MRI object from sorting hat MRI response
 * @param {object} mriData
 * @returns {MRI}
 */
export const parseMRI = mriData => {
  const sections = getProperty(mriData, 'consideredSections', []);
  const improvements = getProperty(mriData, 'improvementPoints');
  const goodSections = improvements ? getGoodSections(improvements, sections) : null;
  const badSections = improvements ? getBadSections(improvements) : null;

  return {
    score: getProperty(mriData, 'value'),
    sections,
    goodSections,
    badSections,
  };
};

export const getDescriptionByLevel = level => {
  switch (level) {
    case MRI_LEVEL.LOW:
      return i18n.t('mriMainModalDescriptionLow');
    case MRI_LEVEL.MODERATE:
      return i18n.t('mriMainModalDescriptionModerate');
    case MRI_LEVEL.HIGH:
      return i18n.t('mriMainModalDescriptionHigh');
    default:
      return null;
  }
};

export const getLevelByScore = mriScore => {
  if (mriScore >= 0 && mriScore < 50) {
    return MRI_LEVEL.LOW;
  }
  if (mriScore >= 50 && mriScore < 80) {
    return MRI_LEVEL.MODERATE;
  }
  if (mriScore >= 80 && mriScore <= 100) {
    return MRI_LEVEL.HIGH;
  }

  return null;
};
