import { gql } from '@apollo/client';
import moment from 'moment';
// services
import client from 'services/Client';
import logger from 'services/Logger';
import notification from 'services/Notification';
// queries
import { Note, PositionStatus, SubmissionStatus } from 'queries/Fragments';
// helpers
import { getSubmissionLink } from 'helpers/Link';
// constants
import { NOTE_SUBMISSION_STATUS, NOTE_ONBOARDING_CANCELLED } from 'constants';
import { SUBMISSION_STATUS } from 'constants/submissionStatus';
import { POSITION_STATUS } from 'constants/positionStatus';
import { SUBMISSION_TAB } from 'constants/pageTabs';
import { HIRING_PROCESS_STATUS } from 'constants/onboarding';
import { STEP } from '../constants';

const REJECT_START_DATE_BY_SUPPLIER_SUBMISSION = gql`
  mutation rejectStartDateByPartnerSubmission($submissionId: ID!, $data: SubmissionUpdateInput!) {
    submissionUpdate(filter: { id: $submissionId }, data: $data) {
      id
      position {
        id
        statuses(sort: { createdAt: DESC }) {
          items {
            ...PositionStatus
          }
        }
      }
      statuses(sort: { createdAt: DESC }) {
        items {
          ...SubmissionStatus
        }
      }
      hiringProcess {
        id
        status
        cancelledStep {
          ...SubmissionStatus
        }
        notes {
          items {
            ...Note
          }
        }
      }
    }
  }
  ${SubmissionStatus}
  ${PositionStatus}
  ${Note}
`;

const REJECT_START_DATE_BY_SUPPLIER_PROPOSAL = gql`
  mutation rejectStartDateByPartnerProposal($submissionId: ID!, $data: ProposalUpdateInput!) {
    proposalUpdate(filter: { id: $submissionId }, data: $data) {
      id
      position {
        id
        statuses(sort: { createdAt: DESC }) {
          items {
            ...PositionStatus
          }
        }
      }
      statuses(sort: { createdAt: DESC }) {
        items {
          ...SubmissionStatus
        }
      }
      hiringProcess {
        id
        status
        cancelledStep {
          ...SubmissionStatus
        }
        notes {
          items {
            ...Note
          }
        }
      }
    }
  }
  ${SubmissionStatus}
  ${PositionStatus}
  ${Note}
`;

/**
 * Reject Start Date / Job Offer by Supplier to Match Manager
 * Step: Start Date - Propose Start Date
 * Role: Supplier
 *
 * Actions:
 * 1. Create HiringProcess Object
 * 2. Set hiring process status as CANCELLED
 * 3. Add connection to cancelledStep field in hiringProcess to current hiring status
 * 4. Add note with type REJECTION_REASON
 * 5. Change status of candidate from onboarding to ONBOARDING_CANCELLED
 * 6. Change status of position to ONBOARDING_CANCELLED
 *
 * @param {object} data
 * @param {object} data.position
 * @param {object} data.submission
 * @param {string} data.currentOnboardingStatusId Latest hiring status id
 * @param {string} data.currentStatusId Latest submission status id
 * @param {string} data.rejectionReason
 * @param {string} data.userId Logged in supplier user id
 */
const rejectStartDateBySupplier = async ({
  position,
  submission,
  currentOnboardingStatusId,
  currentStatusId,
  rejectionReason,
  userId,
}) => {
  try {
    const positionStatus = position.getStatus();
    const hiringProcessMutationData = {
      status: HIRING_PROCESS_STATUS.CANCELLED,
      cancelledStep: { connect: { id: currentOnboardingStatusId } },
      notes: {
        create: {
          note: rejectionReason,
          noteType: NOTE_ONBOARDING_CANCELLED,
          author: { connect: { id: userId } },
        },
      },
    };

    const mutationData = {
      statuses: {
        update: {
          filter: { id: currentStatusId },
          data: { end: moment().toISOString() },
        },
        create: {
          start: moment().toISOString(),
          name: SUBMISSION_STATUS.ONBOARDING_CANCELLED,
        },
      },
      hiringProcess: {
        update: hiringProcessMutationData,
      },
    };

    // change position status to ONBOARDING_CANCELLED
    if ([POSITION_STATUS.OFFER, POSITION_STATUS.ONBOARDING].includes(positionStatus.value)) {
      mutationData.position = {
        update: {
          statuses: {
            update: {
              filter: { id: positionStatus.id },
              data: { end: moment().toISOString() },
            },
            create: {
              start: moment().toISOString(),
              name: POSITION_STATUS.ONBOARDING_CANCELLED,
              note: {
                create: {
                  note: rejectionReason,
                  noteType: NOTE_SUBMISSION_STATUS,
                  author: { connect: { id: userId } },
                },
              },
            },
          },
        },
      };
    }

    await client.mutate({
      mutation: submission.isProposal()
        ? REJECT_START_DATE_BY_SUPPLIER_PROPOSAL
        : REJECT_START_DATE_BY_SUPPLIER_SUBMISSION,
      variables: {
        submissionId: submission.id,
        data: mutationData,
      },
      update: cache => {
        cache.evict({ fieldName: ['submissionsList', 'positionsList', 'proposalsList'] });
        cache.gc();
      },
    });

    const link = getSubmissionLink(submission.id, submission.isProposal(), {
      queryParams: {
        step: STEP.START_DATE_SUBMIT,
        tab: SUBMISSION_TAB.ONBOARDING,
      },
    });

    notification.onboardingStartDateRejectedBySupplier({
      submission: submission.id,
      link,
      createdBy: userId,
      isProposal: submission.isProposal(),
    });
  } catch (error) {
    logger.exception(error);
    throw error;
  }
};

export default rejectStartDateBySupplier;
