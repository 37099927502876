import React, { useState, useEffect, useContext } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import getProperty from 'lodash/get';
// components
import ReplaceCvModal from 'components/organisms/ReplaceCandidateCvModal';
// context
import ToastContext from 'context/ToastContext';
import { fetchData } from './helpers';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  submitting: PropTypes.bool,
  candidateId: PropTypes.string.isRequired,
  candidateName: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onReplace: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
};

const defaultProps = {
  submitting: false,
  onSubmit: null,
};

const ReplaceCV = ({
  candidateId,
  isOpen,
  submitting,
  candidateName,
  onClose,
  onReplace,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const { addToast } = useContext(ToastContext);
  const [candidate, setCandidate] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const initData = async () => {
      try {
        setLoading(true);
        const data = await fetchData(candidateId);
        if (data) {
          setCandidate(data);
        }
      } catch (error) {
        addToast.error(t('errorPlaceholderText'));
        onClose();
      } finally {
        setLoading(false);
      }
    };

    if (isOpen && !loading && !candidate) {
      initData();
    }
  }, [isOpen]);

  return (
    <ReplaceCvModal
      isOpen={isOpen}
      submitting={submitting}
      loading={loading}
      onClose={onClose}
      onReplace={onReplace}
      onSubmit={onSubmit}
      candidate={{
        id: candidateId,
        name: candidateName,
        shCandidateId: getProperty(candidate, 'reports.items.0.shCandidateId', ''),
        shPersonId: getProperty(candidate, 'reports.items.0.shPersonId', ''),
        supplierName: getProperty(candidate, 'supplier.company.name', ''),
        supplierId: getProperty(candidate, 'supplier.id'),
      }}
    />
  );
};

ReplaceCV.propTypes = propTypes;
ReplaceCV.defaultProps = defaultProps;

export default ReplaceCV;
