/* eslint-disable import/order */
import React from 'react';
// libraries
import { useQuery } from '@apollo/client';
import getProperty from 'lodash/get';
import queryString from 'query-string';
import { useHistory, useLocation, useParams } from 'react-router-dom';
// hooks
import useAuth from 'hooks/useAuth';
// components
import ContentLoader from 'components/atoms/ContentLoader';
import SubmissionSettings from 'components/organisms/SubmissionSettings';
import SubmissionMainInfo from 'components/organisms/SubmissionMainInfo';
import ErrorPage from 'components/pages/ErrorPage';
import DetailPage from 'components/templates/DetailPage';
import SubmissionContent from './SubmissionContent';
// constants
import { SUBMISSION_TAB } from 'constants/pageTabs';
import { SUBMISSION_TYPE } from 'constants';
// helpers
import parseSubmission from 'helpers/SubmissionParser';
import parsePosition from 'helpers/PositionParser';
import { getTabs, isUnauthorizedAccess } from './helpers';
// query
import { GET_SUBMISSION } from './query';

const SubmissionPage = () => {
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams();
  const user = useAuth();

  const query = queryString.parse(location.search);
  const currentTab = getProperty(query, 'tab', SUBMISSION_TAB.DETAIL);

  const navigateToTab = newTab => {
    history.push(`${location.pathname}?tab=${newTab}`);
  };

  const { loading, error, data } = useQuery(GET_SUBMISSION, {
    variables: {
      id,
    },
  });

  if (loading) {
    return <ContentLoader type={SUBMISSION_TYPE} />;
  }

  if (!data.submission || isUnauthorizedAccess(data.submission, user)) {
    return <ErrorPage code={404} />;
  }

  if (error) {
    return <ErrorPage code={500} />;
  }

  const submission = parseSubmission(data.submission);
  const position = parsePosition(submission.position);
  const tabs = getTabs(submission.hasOnboarding(), user.role);

  return (
    <DetailPage>
      <DetailPage.Aside>
        <SubmissionMainInfo submission={submission} position={position} />
      </DetailPage.Aside>
      <DetailPage.Body>
        <DetailPage.Navigation tabs={tabs} activeTab={currentTab} onSwitch={navigateToTab}>
          <SubmissionSettings
            hideSecondaryMenuItems
            position={position}
            submission={submission}
            actionButton
          />
        </DetailPage.Navigation>
        <DetailPage.Content>
          <SubmissionContent tab={currentTab} submission={submission} position={position} />
        </DetailPage.Content>
      </DetailPage.Body>
    </DetailPage>
  );
};

export default SubmissionPage;
