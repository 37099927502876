import React from 'react';
import PropTypes from 'prop-types';
// Components
import { withStyles } from '@material-ui/core/styles';
import {
  MenuItem as MaterialMenuItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
} from '@material-ui/core';
import Icon from 'components/atoms/Icon';
// styles
import colors from 'dependencies/materialStyles/Colors';
import menuItemStyle from './MenuItemStyle';

const propTypes = {
  iconName: PropTypes.string,
  primaryText: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  includeLoader: PropTypes.bool,
  classes: PropTypes.any.isRequired,
};

const defaultProps = {
  iconName: null,
  primaryText: '',
  onClick: () => {},
  loading: false,
  includeLoader: false,
};

const MenuItem = props => {
  const { iconName, primaryText, onClick, loading, includeLoader, classes } = props;

  return (
    <MaterialMenuItem
      className={`fs-unmask ${loading ? classes.loading : classes.menuItem}`}
      onClick={onClick}
    >
      {iconName && (
        <ListItemIcon className={classes.icon}>
          <Icon name={iconName} color={colors.iconGray} />
        </ListItemIcon>
      )}
      <ListItemText classes={{ primary: classes.primary }} inset primary={primaryText} />
      {includeLoader && (
        <CircularProgress
          size={14}
          color="inherit"
          style={{ visibility: loading ? 'visible' : 'hidden' }}
        />
      )}
    </MaterialMenuItem>
  );
};

MenuItem.propTypes = propTypes;
MenuItem.defaultProps = defaultProps;

export default withStyles(menuItemStyle)(MenuItem);
