import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { MenuItem, ListItemIcon, ListItemText, CircularProgress } from '@material-ui/core';
import classNames from 'classnames';
// components
import Icon from 'components/atoms/Icon';
// styles
import styles from './menu.module.scss';

const propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
};

const defaultProps = {
  icon: '',
  label: '',
  onClick: null,
  loading: false,
  disabled: false,
  selected: false,
};

const Item = ({ icon, label, onClick, loading, disabled, selected }) => {
  return (
    <MenuItem
      onClick={onClick}
      disabled={disabled}
      selected={selected}
      classes={{ root: classNames(styles.item, { [styles.loading]: loading }) }}
    >
      {icon ? (
        <React.Fragment>
          <ListItemIcon>
            <Icon name={icon} />
          </ListItemIcon>
          <ListItemText classes={{ primary: styles.item }} inset primary={label} />
        </React.Fragment>
      ) : (
        <span>{label} </span>
      )}
      <div className="m-l-10">
        <CircularProgress
          size={14}
          color="inherit"
          style={{ visibility: loading ? 'visible' : 'hidden' }}
        />
      </div>
    </MenuItem>
  );
};

Item.propTypes = propTypes;
Item.defaultProps = defaultProps;

export default Item;
