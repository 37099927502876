import React, { useContext } from 'react';
import classNames from 'classnames';
import MuiTableRow from '@mui/material/TableRow';
import TableContext from './TableContext';
import styles from './table.module.scss';

interface TableRowProps {
  children: React.ReactNode;
  className?: string;
}

const TableRow = ({ children, className }: TableRowProps) => {
  const { hoverable, variant, classes } = useContext(TableContext);

  return (
    <MuiTableRow
      component="div"
      hover={variant === 'body' ? hoverable : false}
      classes={{
        root: classNames(variant === 'head' ? styles.baseRow : styles.row, classes?.row, className),
      }}
    >
      {children}
    </MuiTableRow>
  );
};

export default TableRow;
