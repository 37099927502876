/* eslint-disable import/order */
import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
// constants
import { COLOR as ICON_COLOR } from 'constants/colors';
import { CANDIDATE_STATUS } from 'constants/candidateStatus';
// helpers
import { getTimeYear } from 'helpers/RelativeTimeFormatter';
// components
import Link from 'components/atoms/Link';
import Icon from 'components/atoms/Icon';
import Chip, { COLOR } from 'components/atoms/NewChip';
import Tooltip, { TOOLTIP_POSITION } from 'components/atoms/Tooltip';
import TextIcon from 'components/atoms/TextIcon';
import ClickableElement from 'components/atoms/ClickableElement';
import Popover from 'components/molecules/Popover';
import CVReadability from './CVReadability';
import LatestCVBanner from './LatestCVBanner';
import RateDisplay from './RateDisplay';
import Card from './Card';
// styles
import styles from './candidatePage.module.scss';
import colors from 'dependencies/materialStyles/Colors';

const propTypes = {
  candidate: PropTypes.object.isRequired,
};

const CandidateDetails = ({ candidate }) => {
  const { t } = useTranslation();

  const renderStatus = () => {
    const status = candidate.getStatus();

    if (status.value === CANDIDATE_STATUS.AVAILABLE_FROM) {
      return (
        <Chip color={candidate.getStatus().color} className="m-l-5">
          {`${candidate.getStatus().label} ${getTimeYear(candidate.availableFrom)}`}
        </Chip>
      );
    }
    return (
      <Chip color={candidate.getStatus().color} className="m-l-5">
        {candidate.getStatus().label}
      </Chip>
    );
  };

  return (
    <Card>
      <Grid container spacing={16}>
        <Grid item xs={12} md={9}>
          <div className={styles.candidateGroup}>
            {candidate.name && (
              <TextIcon icon="candidate" size={20} classes={{ icon: styles.candidateIcon }}>
                <p className={styles.candidateName}>{candidate.name}</p>
              </TextIcon>
            )}
            {candidate.getStatus().value && renderStatus()}
          </div>
          <div className={styles.professionGroup}>
            {candidate.profession && <p className={styles.profession}>{candidate.profession}</p>}
            {candidate.clearance.type && (
              <React.Fragment>
                <TextIcon
                  icon="shield"
                  size={12}
                  classes={{ root: styles.clearance, icon: styles.clearanceIcon }}
                >
                  {candidate.clearance.type}
                </TextIcon>
                <Popover
                  className={styles.infoIcon}
                  button={onClick => (
                    <ClickableElement onClick={onClick}>
                      <Icon name="info" icon="info" size={15} color={colors.gray} />
                    </ClickableElement>
                  )}
                >
                  <div className={styles.popoverBody}>
                    <p>{t('clearanceValidUntil')}</p>
                    <span>{getTimeYear(candidate.clearance.validUntil)}</span>
                  </div>
                </Popover>
              </React.Fragment>
            )}
          </div>
          {candidate.clearance.note && (
            <p className={styles.clearanceNote}>
              {t('clearanceNotes')}:
              <span className={classNames(styles.infoText, 'm-l-5')}>
                {candidate.clearance.note}
              </span>
            </p>
          )}
          {candidate.description && (
            <>
              <div className={styles.candidateDetails}>{candidate.description}</div>
              <div className={classNames('m-t-5', styles.infoText)}>{t('systemGenerated')}</div>
            </>
          )}
        </Grid>

        <Grid item xs={12} md={3} className="text-right">
          {candidate.experience.level && (
            <Chip color={COLOR.BLUE} className="m-l-5">
              <Tooltip
                title={t('yearsOfExperience', {
                  yearsOfExperience: candidate.experience.yearsOfExperience,
                })}
                placement={TOOLTIP_POSITION.BOTTOM}
              >
                <div>{t('experienceLevel', { experienceLevel: candidate.experience.level })}</div>
              </Tooltip>
            </Chip>
          )}
          {candidate.expectedRate.value && (
            <div className={styles.candidateRate}>
              <RateDisplay
                rate={candidate.expectedRate.value}
                currency={candidate.expectedRate.currency}
                rateDescription={
                  <Trans i18nKey="expectedMinimumRatePerHour">
                    Expected Min
                    <br />
                    sell Rate/hour
                  </Trans>
                }
              />
            </div>
          )}
        </Grid>
      </Grid>

      <div className="d-flex m-t-30">
        <div className={styles.banners}>
          <div className={classNames('m-r-20', styles.bannerContainer)}>
            <LatestCVBanner candidate={candidate} />
          </div>
          <div className={styles.bannerContainer}>
            <CVReadability report={candidate.getReport()} candidate={candidate.name} />
          </div>
        </div>
        <div className={styles.contact}>
          {/* Location */}
          <TextIcon icon="location" size={15} color={ICON_COLOR.DARK_GRAY}>
            {candidate.location || <i>{t('unknown')}</i>}
          </TextIcon>
          {/* Relocation */}
          <div className="m-t-5">
            <TextIcon icon="route" size={15} color={ICON_COLOR.DARK_GRAY}>
              {candidate.getWillingToRelocate() || <i>{t('unknown')}</i>}
            </TextIcon>
          </div>
          {/* Email */}
          <div className="m-t-5">
            <TextIcon icon="email" size={15} color={ICON_COLOR.DARK_GRAY}>
              {candidate.email ? (
                <Link href={`mailto:${candidate.email}`}>{candidate.email}</Link>
              ) : (
                <i>{t('unknown')}</i>
              )}
            </TextIcon>
          </div>
          {/* Phone */}
          <div className="m-t-5">
            <TextIcon icon="phone" size={15} color={ICON_COLOR.DARK_GRAY}>
              {candidate.phone ? (
                <Link href={`tel:${candidate.phone}`}>{candidate.phone}</Link>
              ) : (
                <i>{t('unknown')}</i>
              )}
            </TextIcon>
          </div>
        </div>
      </div>
    </Card>
  );
};

CandidateDetails.propTypes = propTypes;

export default CandidateDetails;
