// constants
import { SORT_FORM_TYPE, SORT_DRAFT_NAME, SORT_UPDATED_DATE } from 'constants';
// queries
import { UPDATE_DRAFT } from 'queries/DraftQueries';
// services
import client from 'services/Client';

export const formatFilters = (userId, searchText, type) => {
  const filters = [{ user: { id: { equals: userId } } }];
  if (searchText && searchText.length > 0) {
    filters.push({
      name: { contains: searchText },
    });
  }
  if (type && type.length > 0) {
    filters.push({
      form: { type: { in: type } },
    });
  }
  return { AND: filters };
};

export const formatSorts = ({ id }) => {
  switch (id) {
    case SORT_FORM_TYPE:
      return [{ form: { type: 'ASC' } }, { name: 'ASC' }];
    case SORT_DRAFT_NAME:
      return [{ name: 'ASC' }];
    case SORT_UPDATED_DATE:
      return [{ updatedAt: 'DESC' }];
    default:
      return null;
  }
};

export const updateDraftName = (id, name) => {
  return client.mutate({
    mutation: UPDATE_DRAFT,
    variables: {
      id,
      name,
    },
  });
};
