import getProperty from 'lodash/get';
import moment from 'moment';
import i18n from 'i18next';
// helpers
import { getCandidateStatusColor } from 'helpers/Colors';
import {
  getCandidateStatusText,
  getCurrencyText,
  getExperienceLevelText,
} from 'helpers/NameMapping';

const RELOCATION = {
  YES: 'YES',
  NO: 'NO',
};

const sortReports = (a, b) => {
  const dateA = getProperty(a, 'cv.updatedAt', '');
  const dateB = getProperty(b, 'cv.updatedAt', '');

  return moment(dateB).diff(dateA);
};

class Candidate {
  constructor(data) {
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(data)) {
      this[key] = value;
    }

    const experience = getProperty(data, 'profile.experienceLevel', {});
    const location = getProperty(data, 'profile.location');
    const country = getProperty(location, 'country.name', '');
    const city = getProperty(location, 'city');

    this.data = data;
    this.id = getProperty(data, 'id', '');
    this.availableFrom = getProperty(data, 'availableFrom');
    this.name = getProperty(data, 'profile.fullName', '');
    this.profession = getProperty(data, 'profile.profession', '');
    this.updatedAt = getProperty(data, 'lastUpdate', '') || getProperty(data, 'updatedAt', '');
    this.createdAt = getProperty(data, 'createdAt', '');
    this.description = getProperty(data, 'profile.description', '');
    this.email = getProperty(data, 'profile.email', '');
    this.phone = getProperty(data, 'profile.phone', '');
    this.skype = getProperty(data, 'profile.skype', '');
    this.linkedIn = getProperty(data, 'profile.linkedIn', '');
    this.exIBM = getProperty(data, 'profile.exIBM', false);
    this.citizenship = getProperty(data, 'profile.citizenship.name', '');
    this.expectedRate = {
      value: getProperty(data, 'expectedRate.value', ''),
      currency: getCurrencyText(getProperty(data, 'expectedRate.currency', '')),
    };
    this.experience = {
      level: getExperienceLevelText(getProperty(experience, 'shLevel', '')),
      yearsOfExperience: getProperty(experience, 'yearsOfExperience', ''),
    };
    this.location = location ? `${city}, ${country}` : '';
    this.clearance = {
      type: getProperty(data, 'profile.clearance.type.name', ''),
      validFrom: getProperty(data, 'profile.clearance.validFrom', ''),
      validUntil: getProperty(data, 'profile.clearance.validUntil', ''),
      transferRequired: getProperty(data, 'profile.clearance.transferRequired', false),
      note: getProperty(data, 'profile.clearance.notes.items.0.note', ''),
      country: getProperty(data, 'profile.clearance.country.name', ''),
    };
    this.reports = getProperty(data, 'reports.items', []);
    this.matches = getProperty(data, 'positionMatches.items', []);
    this.partner = {
      id: getProperty(data, 'supplier.id', ''),
      name: getProperty(data, 'supplier.company.name', ''),
    };
  }

  /**
   * Get current status
   * @returns {Status} Current status
   */
  getStatus = () => {
    const status = getProperty(this.data, 'status', '');

    return {
      value: status,
      color: getCandidateStatusColor(status),
      label: getCandidateStatusText(status),
    };
  };

  /**
   * Get most recent report
   * @returns {object}
   */
  getReport = () => {
    const sortedReports = this.reports.sort(sortReports);
    const report = getProperty(sortedReports, '0');

    return {
      ...report,
      cvReadabilityLevel: getProperty(report, 'cvReadabilityLevel.level', ''),
    };
  };

  /**
   * Get most recent cv
   * @returns {object}
   */
  getCv = () => {
    const cv = getProperty(this.getReport(), 'cv');

    return {
      id: getProperty(cv, 'id', ''),
      boxId: getProperty(cv, 'boxId', ''),
      name: getProperty(cv, 'name', ''),
      type: getProperty(cv, 'type', ''),
      createdAt: getProperty(cv, 'createdAt', ''),
      updatedAt: getProperty(cv, 'updatedAt', ''),
      author: getProperty(cv, 'author.person.fullName', ''),
    };
  };

  /**
   * Get Candidate's willing to relocate
   * @returns {string}
   */
  getWillingToRelocate = () => {
    const value = getProperty(this.data, 'profile.willingToRelocate', '');

    if (value === RELOCATION.YES) {
      return i18n.t('willingToRelocate');
    }
    if (value === RELOCATION.NO) {
      return i18n.t('notWillingToRelocate');
    }

    return i18n.t('remoteOnly');
  };
}

/**
 * Parse and normalize candidate data from 8base
 * @param {object} data Candidate data from 8base
 * @returns {Candidate}
 */
const parseCandidate = data => new Candidate(data);

export default parseCandidate;
