import getProperty from 'lodash/get';
import { gql } from '@apollo/client';
// services
import client from 'services/Client';
import logger from 'services/Logger';
// helpers
import parsePosition from 'helpers/PositionParser';
// queries
import { PositionStatus, Rate, PositionRatesSettings } from 'queries/Fragments';

const parseData = position => {
  const matches = getProperty(position, 'data.candidateMatches.items', []);

  return {
    ...position,
    candidateMatchId: getProperty(matches, '0.id', {}),
    match: getProperty(matches, '0.report', {}),
  };
};

export const GET_POSITION_MATCH_BY_ID = gql`
  query position($candidateId: ID!, $positionId: ID!) {
    position(id: $positionId) {
      id
      contractType
      title
      ...PositionRatesSettings
      rfq {
        id
        name
      }
      createdAt
      dueOn
      supplierDueDate
      candidateMatches(filter: { candidate: { id: { equals: $candidateId } } }) {
        items {
          id
          report {
            id
            score
          }
        }
      }
      publishedToAll
      publishedTo {
        items {
          id
        }
      }
      clearance {
        id
        name
      }
      deliveryOption {
        id
        mode
        description
      }
      project {
        id
        timezone
        config
        currency
        client {
          id
          config
        }
      }
      location {
        items {
          id
          country {
            id
            name
          }
          city
        }
      }
      rates {
        items {
          ...Rate
        }
      }
      report {
        id
        shPositionId
      }
      statuses(sort: { createdAt: DESC }) {
        items {
          ...PositionStatus
        }
      }
    }
  }
  ${PositionStatus}
  ${Rate}
  ${PositionRatesSettings}
`;

/**
 * Fetch and parser position
 * @param {string} id Position id
 * @returns {Promise<?object>}
 */
export const fetchPositionMatch = async (candidateId, positionId) => {
  try {
    const response = await client.query({
      query: GET_POSITION_MATCH_BY_ID,
      variables: { candidateId, positionId },
    });

    if (!response.data.position) {
      return null;
    }

    return parseData(parsePosition(response.data.position));
  } catch (error) {
    logger.exception(error, { candidateId });
    throw error;
  }
};
