/* eslint-disable react/prop-types */
import React from 'react';
import ContentLoader from 'react-content-loader';

const RadioLoader = ({ height = 65, width = 200 }) => (
  <div style={{ height: `${height}px`, width: `${width}px` }}>
    <ContentLoader
      height={height}
      width={width}
      speed={1}
      primaryColor="#f1f1f1"
      secondaryColor="#e6e6e6"
    >
      <rect x="0" y="5" rx="5" ry="5" width="120" height="8" />
      <rect x="0" y="35" rx="5" ry="5" width="120" height="8" />
    </ContentLoader>
  </div>
);

export default RadioLoader;
