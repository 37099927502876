import React, { useEffect, useState } from 'react';
// libraries
import getProperty from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// hooks
import useAuth from 'hooks/useAuth';
// constants
import { ONBOARDING_STATUS_FORMS } from 'constants/onboarding';
import { SUBMISSION_STATUS_TYPE } from 'constants/submissionStatus';
import { STAFF_ROLE, SUPPLIER_ROLE, MANAGER_ROLE } from 'constants';
// helpers
import { isOnboardingFormsStepFinished } from 'helpers/Onboarding';
import { getOnboardingFiles, getOnboardingFormsNote, fetchTemplates } from './helpers';
// components
import ConfirmOnboardingForms from './ConfirmOnboardingForms';
import Forms from './Forms';
import SubmitOnboardingForms from './SubmitOnboardingForms';
import TextContent from '../TextContent';

const propTypes = {
  submission: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
};

const OnboardingForms = ({ submission, position }) => {
  const { role } = useAuth();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState([]);

  const { project } = position;
  const category = getProperty(submission, 'partner.category');
  const files = getOnboardingFiles(submission, templates, role);
  const note = getOnboardingFormsNote(submission);
  const notSubmittedFilesCount = files.filter(group => group.files.length === 0).length;
  const onboardingFormsStatus = submission.getStatus(role, SUBMISSION_STATUS_TYPE.ONBOARDING_FORMS);

  useEffect(() => {
    const initTemplates = async () => {
      try {
        setLoading(true);

        const templateData = await fetchTemplates(project.id, category);
        setTemplates(templateData);
      } finally {
        setLoading(false);
      }
    };

    initTemplates();
  }, []);

  if (isOnboardingFormsStepFinished(submission)) {
    // 0. Accepted - all steps are finished, show filled files
    return (
      <TextContent
        title={t('allIsWell')}
        label={t('allNecessaryFormsWereProvided')}
        content={
          <Forms
            loading={loading}
            templates={templates}
            files={files}
            note={note}
            collapsedTemplates
          />
        }
      />
    );
  }

  if (!onboardingFormsStatus) {
    // 1. INACTIVE STATUS - the same for all roles
    return (
      <TextContent
        title={t('noActionRequiredHere')}
        label={t('noActionRequiredHereDescription')}
        content={<Forms loading={loading} templates={templates} files={files} note={note} />}
      />
    );
  }

  if (onboardingFormsStatus.value === ONBOARDING_STATUS_FORMS.SUBMIT) {
    if (role === SUPPLIER_ROLE) {
      // 2. SUBMIT ONBOARDING FORMS - Supplier
      return (
        <SubmitOnboardingForms
          files={files}
          templates={templates}
          note={note}
          category={category}
          submission={submission}
          position={position}
          missing={notSubmittedFilesCount}
        />
      );
    }

    if (role === MANAGER_ROLE) {
      // 3. SUBMIT ONBOARDING FORMS - Project Manager
      return (
        <TextContent
          title={t('waitingForSupplierToUploadOnboardingForms')}
          label={t('noActionRequiredFromYourSide')}
          content={<Forms loading={loading} templates={templates} files={files} />}
        />
      );
    }

    if (role === STAFF_ROLE) {
      // 4. SUBMIT ONBOARDING FORMS - Match Manager
      return (
        <TextContent
          title={t('waitingForSupplierToUploadOnboardingForms')}
          label={t('noActionRequiredFromYourSideNow')}
          content={<Forms loading={loading} templates={templates} files={files} />}
        />
      );
    }
  }

  if (role === SUPPLIER_ROLE) {
    // 5. CONFIRM ONBOARDING FORMS - Supplier
    return (
      <SubmitOnboardingForms
        files={files}
        templates={templates}
        note={note}
        category={category}
        submission={submission}
        position={position}
        missing={notSubmittedFilesCount}
        edit
      />
    );
  }
  if (role === MANAGER_ROLE) {
    // 6. CONFIRM ONBOARDING FORMS - Project Manager - Don't show submitted form files
    return (
      <TextContent
        title={t('waitingForSupplierToUploadOnboardingForms')}
        label={t('noActionRequiredFromYourSide')}
        content={
          <Forms
            loading={loading}
            templates={templates}
            files={files.map(file => ({ ...file, boxId: null }))}
            collapsedTemplates
          />
        }
      />
    );
  }

  if (role === STAFF_ROLE) {
    // 7. CONFIRM ONBOARDING FORMS - Match Manager - show filled files
    return (
      <ConfirmOnboardingForms
        loading={loading}
        templates={templates}
        files={files}
        note={note}
        submission={submission}
        position={position}
      />
    );
  }

  return null;
};

OnboardingForms.propTypes = propTypes;

export default OnboardingForms;
