// reactive vars
import { reactiveVar as loggedInUserVar } from 'localStates/loggedInUser';

const resolvers = {
  Mutation: {
    toggleRole: (_parent, { role }) => {
      const loggedInUser = loggedInUserVar();
      loggedInUserVar({ ...loggedInUser, role });

      return null;
    },
  },
};

export default resolvers;
