import React from 'react';
// libraries
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import hasProperty from 'lodash/has';
// components
import Field from 'components/molecules/Field';
// constants
import { SCROLL_TO_ELEMENT_PREFIX } from 'constants';
// helpers
import { isComponentRequired, isComponentEmpty, isComponentHidden } from 'helpers/Form';
import getComponent from './Components';

const propTypes = {
  className: PropTypes.string,
  component: PropTypes.object,
  fieldClassName: PropTypes.string,
  formData: PropTypes.any,
  formWasSubmitted: PropTypes.bool,
  hideFieldErrorMessage: PropTypes.bool,
  error: PropTypes.bool,
  warning: PropTypes.object,
};

const defaultProps = {
  className: '',
  component: {},
  fieldClassName: '',
  formData: {},
  formWasSubmitted: false,
  hideFieldErrorMessage: false,
  error: false,
  warning: { show: false, message: '' },
};

const ComponentParser = props => {
  const { t } = useTranslation();
  const {
    className,
    component,
    fieldClassName,
    formData,
    formWasSubmitted,
    hideFieldErrorMessage,
    error,
    warning,
  } = props;
  const { properties, hideLabel } = component;
  const { labelKey, descriptionKey, tooltipKey } = properties || {};

  const fieldNotFilled =
    isComponentRequired(component, formData) &&
    isComponentEmpty(formData, component.key) &&
    !isComponentHidden(component, formData) &&
    formWasSubmitted;

  const hasError = () => {
    if (fieldNotFilled) {
      return true;
    }
    if (formData[component.key] && !formData.error) {
      return formData[component.key].error;
    }
    if (formData.error) {
      return formData.error;
    }

    return false;
  };

  const getErrorMessage = () => {
    if (hasProperty(formData, [component.key, 'errorMessage']) && !formData.errorMessage) {
      return formData[component.key].errorMessage;
    }
    if (formData.errorMessage) {
      return formData.errorMessage;
    }

    return '';
  };

  if (isComponentHidden(component, formData)) {
    return null;
  }

  return (
    <React.Fragment>
      {!component.hidden && (
        <Field
          className={classNames(SCROLL_TO_ELEMENT_PREFIX + component.key, {
            [className]: className,
            redForm: hasError(),
          })}
          warning={warning.show ? warning.message : ''}
          hideLabel={hideLabel}
          fieldClassName={fieldClassName}
          label={t(labelKey)}
          required={isComponentRequired(component, formData)}
          tooltip={tooltipKey ? t(tooltipKey) : null}
          description={descriptionKey ? t(descriptionKey) : null}
          content={
            getComponent({ ...props, component: { ...component, error: error || hasError() } }) ||
            t('notSupportedComponent')
          }
          fieldNotFilled={fieldNotFilled}
          error={hasError()}
          errorMessage={!hideFieldErrorMessage ? getErrorMessage() : null}
        />
      )}
    </React.Fragment>
  );
};

ComponentParser.propTypes = propTypes;
ComponentParser.defaultProps = defaultProps;

export default ComponentParser;
