/* eslint-disable import/order */
import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import classNames from 'classnames';
// components
import Button from 'components/atoms/Button';
import Chip from 'components/atoms/NewChip';
import Tooltip from 'components/atoms/Tooltip';
import DistributionGraph from './DistributionGraph';
// constants
import { SUPPORTED_COLORS } from 'constants/colors';
import { SH_EXPERIENCE_LEVEL } from 'constants';
import { MATCH_TYPE } from './constants';
// helpers
import { getExperienceLevelText } from 'helpers/NameMapping';
import { roundNumber } from 'helpers/Number';
import { capitalizeWord } from 'helpers/StringParser';
import { getMatchType } from './helpers';
// styles
import styles from './rightSection.module.scss';

const propTypes = {
  metrics: PropTypes.shape({
    percentile: PropTypes.number,
    mean: PropTypes.number.isRequired,
    median: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    dataPoints: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
  score: PropTypes.number.isRequired,
  matchColor: PropTypes.oneOf(SUPPORTED_COLORS).isRequired,
  candidateName: PropTypes.string.isRequired,
  project: PropTypes.string.isRequired,
  experienceLevel: PropTypes.oneOf(Object.values(SH_EXPERIENCE_LEVEL)).isRequired,
  occupations: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func,
};

const defaultProps = {
  onClose: null,
};

const RightSection = ({
  metrics,
  score,
  matchColor,
  candidateName,
  project,
  experienceLevel,
  occupations,
  onClose,
}) => {
  const { t } = useTranslation();

  const renderDescription = () => {
    const formattedPercentile = roundNumber(metrics.percentile || 0, 1);
    const lowPercentile = roundNumber(100 - 100 / metrics.count - formattedPercentile, 1);

    if ([MATCH_TYPE.WEAK, MATCH_TYPE.NONE].includes(getMatchType(metrics.percentile))) {
      return (
        <Trans i18nKey="matchMetricsRightSectionWeakDescription">
          Candidate&apos;s score is lower than {{ percentile: lowPercentile }}% of the{' '}
          <b>{{ candidatesCount: metrics.count }}</b> candidates submitted for{' '}
          <b>similar requests</b> with similar experience. Other candidates score is{' '}
          {{ mean: metrics.mean }} on average.
        </Trans>
      );
    }

    return (
      <Trans i18nKey="matchMetricsRightSectionStrongDescription">
        Candidate&apos;s score is higher than {{ percentile: formattedPercentile }}% of the{' '}
        <b>{{ candidatesCount: metrics.count }}</b> candidates submitted for <b>similar requests</b>{' '}
        with similar experience. Other candidates score is {{ mean: metrics.mean }} on average.
      </Trans>
    );
  };

  const renderOccupationsTooltip = () => {
    if (occupations.length === 1) {
      return '';
    }

    return (
      <div>
        {occupations.map(occupation => (
          <div key={occupation}>- {capitalizeWord(occupation)}</div>
        ))}
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>{t('matchMetricsRightSectionTitle')}</div>
        {onClose && <Button type="close" size={24} onClick={onClose} />}
      </div>
      <div className={styles.description}>{renderDescription()}</div>
      <Chip className={styles.comparableDescription} size="lg">
        <span className={styles.comparableCount}>{metrics.count}</span>
        <span className={styles.comparableText}>{t('comparableCandidatesCountDescription')}</span>
      </Chip>
      <div className={styles.comparableCriteria}>
        <div className={styles.comparableCriteriaText}>{t('comparableCandidatesCriteria')}:</div>
        <div className={styles.criteriaChips}>
          <Chip className={classNames(styles.criteriaChip, 'm-r-5')}>
            {t('project')} {project}
          </Chip>
          {occupations.length > 0 && (
            <Chip className={classNames(styles.criteriaChip, 'm-r-5')}>
              <Tooltip title={renderOccupationsTooltip()}>
                <span>
                  {capitalizeWord(occupations[0])}{' '}
                  {occupations.length > 1 ? `(${occupations.length})` : null}
                </span>
              </Tooltip>
            </Chip>
          )}
          <Chip className={styles.criteriaChip}>{getExperienceLevelText(experienceLevel)}</Chip>
        </div>
      </div>
      <div className="m-t-20">
        <DistributionGraph
          mean={metrics.mean}
          median={metrics.median}
          score={score}
          matchColor={matchColor}
          dataPoints={metrics.dataPoints}
          candidateName={candidateName}
        />
      </div>
    </div>
  );
};

RightSection.propTypes = propTypes;
RightSection.defaultProps = defaultProps;

export default RightSection;
