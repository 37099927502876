import React, { useRef, useState, useContext } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import filter from 'lodash/filter';
// constants
import { SUBMISSION_TAB } from 'constants/pageTabs';
import { STAFF_ROLE, SUPPLIER_ROLE, MANAGER_ROLE } from 'constants';
import { POSITION_STATUS } from 'constants/positionStatus';
import { SUBMISSION_STATUS, SUBMISSION_STATUS_TYPE } from 'constants/submissionStatus';
import { ONBOARDING_TAB } from 'constants/onboarding';
// services
import logger from 'services/Logger';
// contexts
import ToastContext from 'context/ToastContext';
// hooks
import useModalProvider from 'hooks/useModalProvider';
import useIsMounted from 'hooks/useIsMounted';
import useAuth from 'hooks/useAuth';
// helpers
import { fetchPositionStatus } from 'helpers/Position';
import { hasPendingOnboardingAction } from 'helpers/Onboarding';
// components
import IconDropdown from 'components/molecules/IconDropdown';
import {
  hasSubmissions,
  showSelectWinner,
  SubmitCandidates,
  SelectWinner,
} from 'components/organisms/PositionSettings';
import RejectSubmission from './RejectSubmission';
import WithdrawSubmission from './WithdrawSubmission';

const propTypes = {
  proposal: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
  actionButton: PropTypes.bool,
  color: PropTypes.string,
};

const defaultProps = {
  actionButton: false,
  color: 'white',
};

const MODAL = {
  REJECT: 'reject',
  WITHDRAW: 'withdraw',
  SUBMIT: 'submit',
  SELECT_WINNER: 'selectWinner',
};

const ITEM_KEYS = {
  SELECT_WINNER: 'selectWinner',
};

const SubmissionSettings = ({ proposal, position, actionButton, color }) => {
  const { openModal, isModalOpen, closeModal } = useModalProvider();
  const { addToast } = useContext(ToastContext);
  const user = useAuth();
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const history = useHistory();
  const menuRef = useRef();

  const [loader, setLoader] = useState({ loading: false, loadingKey: '' });
  const [hasDueDatePassed, setDueDatePassed] = useState(position.isAfterDueDate());

  const { role } = user;
  const positionStatus = position.getStatus();
  const submissionStatus = proposal.getStatus(role);
  const submissionStatusValue = submissionStatus ? submissionStatus.value : '';

  const redirectTo = url => {
    history.push(url);
  };

  const executeAsync = async (fn, successMsg = '', loadingKey) => {
    setLoader({ loading: true, loadingKey });

    try {
      const response = await fn();

      if (successMsg) {
        addToast.success(successMsg);
      }
      return response;
    } catch (error) {
      addToast.error(t('errorPlaceholderText'));
      logger.exception(error);

      return false;
    } finally {
      if (isMounted()) {
        setLoader({ loading: false, loadingKey: '' });
      }
    }
  };

  const isItemLoading = key => {
    const { loading, loadingKey } = loader;
    return loading && loadingKey === key;
  };

  const checkPositionDueDate = () => {
    if (position.isAfterDueDate() && !hasDueDatePassed) {
      setDueDatePassed(true);
    }
  };

  const goToOnboarding = step => {
    const stepQuery = step ? `&step=${step}` : '';

    redirectTo(`/proposal/${proposal.id}?tab=${SUBMISSION_TAB.ONBOARDING}${stepQuery}`);
  };

  const handleSelectWinnerClick = async () => {
    const actualPositionStatus = await executeAsync(
      () => fetchPositionStatus(position.id),
      null,
      ITEM_KEYS.SELECT_WINNER
    );

    if (actualPositionStatus !== POSITION_STATUS.PENDING_SELECTION) {
      addToast.error(t('positionNotInSelectionState'));
      return;
    }

    menuRef.current.closeMenu();
    openModal(MODAL.SELECT_WINNER);
  };

  const submitToClientItem = {
    icon: 'addPerson',
    id: 'submitToClient',
    onClick: () => openModal(MODAL.SUBMIT),
  };
  const editItem = {
    icon: 'edit',
    id: 'editProposal',
    onClick: () => redirectTo(`/proposal/edit/${proposal.id}/${position.id}`),
  };

  const getRejectCandidateItem = () => {
    if (proposal.isRejectable(role)) {
      return {
        icon: 'cancel',
        id: 'rejectCandidate',
        onClick: () => openModal(MODAL.REJECT),
      };
    }
    return null;
  };

  const getSelectWinnerItem = () => {
    if (showSelectWinner(positionStatus, hasDueDatePassed)) {
      return {
        icon: 'trophy',
        id: 'selectWinner',
        onClick: handleSelectWinnerClick,
        stayOpenAfterClick: true,
        loading: isItemLoading(ITEM_KEYS.WINNER),
      };
    }
    return null;
  };

  const getWithDrawCandidateItem = () => {
    if (proposal.isRejectable(role)) {
      return {
        icon: 'cancel',
        id: 'withdrawSubmission',
        onClick: () => openModal(MODAL.WITHDRAW),
      };
    }
    return null;
  };

  const getConfirmStartDateItem = () => {
    if (hasPendingOnboardingAction(SUBMISSION_STATUS_TYPE.ONBOARDING_START_DATE, proposal, role)) {
      return {
        icon: 'calendar',
        id: 'confirmStartDate',
        onClick: () => goToOnboarding(ONBOARDING_TAB.START_DATE),
      };
    }
    return null;
  };

  const getOnboardingFormsItem = () => {
    if (hasPendingOnboardingAction(SUBMISSION_STATUS_TYPE.ONBOARDING_FORMS, proposal, role)) {
      return {
        icon: 'fileUpload',
        id: 'onboardingFormsItem',
        onClick: () => goToOnboarding(ONBOARDING_TAB.FORMS),
      };
    }
    return null;
  };

  const getCreatePoItem = () => {
    if (hasPendingOnboardingAction(SUBMISSION_STATUS_TYPE.ONBOARDING_PO, proposal, role)) {
      return {
        icon: 'purchaseOrder',
        id: 'createPO',
        onClick: () => goToOnboarding(ONBOARDING_TAB.PO),
      };
    }
    return null;
  };

  const getConfirmPoItem = () => {
    if (hasPendingOnboardingAction(SUBMISSION_STATUS_TYPE.ONBOARDING_PO, proposal, role)) {
      return {
        icon: 'purchaseOrder',
        id: 'confirmPO',
        onClick: () => goToOnboarding(ONBOARDING_TAB.PO),
      };
    }
    return null;
  };

  const renderMenuItems = () => {
    if (submissionStatusValue === SUBMISSION_STATUS.NEW) {
      if (role === MANAGER_ROLE) {
        return filter([]);
      }
      if (role === STAFF_ROLE) {
        return filter([submitToClientItem, getRejectCandidateItem()]);
      }
      if (role === SUPPLIER_ROLE) {
        return filter([getWithDrawCandidateItem(), editItem]);
      }
    }

    if (submissionStatusValue === SUBMISSION_STATUS.SUBMITTED) {
      if (role === MANAGER_ROLE) {
        return filter([]);
      }
      if (role === STAFF_ROLE) {
        return filter([getSelectWinnerItem(), getRejectCandidateItem()]);
      }
      if (role === SUPPLIER_ROLE) {
        return filter([getWithDrawCandidateItem(), editItem]);
      }
    }

    if (submissionStatusValue === SUBMISSION_STATUS.OFFER) {
      if (role === MANAGER_ROLE) {
        return filter([getCreatePoItem()]);
      }
      if (role === STAFF_ROLE) {
        return filter([getRejectCandidateItem()]);
      }
      if (role === SUPPLIER_ROLE) {
        return filter([
          getConfirmStartDateItem(),
          getOnboardingFormsItem(),
          editItem,
          getWithDrawCandidateItem(),
        ]);
      }
    }

    if (submissionStatusValue === SUBMISSION_STATUS.ONBOARDING) {
      if (role === MANAGER_ROLE) {
        return filter([getConfirmStartDateItem(), getCreatePoItem()]);
      }
      if (role === STAFF_ROLE) {
        return filter([getConfirmStartDateItem(), getOnboardingFormsItem(), getConfirmPoItem()]);
      }
      if (role === SUPPLIER_ROLE) {
        return filter([getOnboardingFormsItem(), getWithDrawCandidateItem(), editItem]);
      }
    }

    if (submissionStatusValue === SUBMISSION_STATUS.DELIVERY) {
      if (role === MANAGER_ROLE) {
        return filter([]);
      }
      if (role === SUPPLIER_ROLE) {
        return filter([editItem]);
      }
    }
    return [];
  };

  return (
    <div className="fs-unmask">
      <IconDropdown
        ref={menuRef}
        menuItems={renderMenuItems()}
        actionButton={actionButton}
        iconColor={color}
        onOpen={checkPositionDueDate}
      />
      {role === STAFF_ROLE && (
        <React.Fragment>
          <RejectSubmission
            id={proposal.id}
            name={proposal.name}
            statusId={submissionStatus.id}
            statusValue={submissionStatus.value}
            isOpen={isModalOpen(MODAL.REJECT)}
            onClose={closeModal}
            onFinish={closeModal}
            position={position}
            isProposal
          />
          <SubmitCandidates
            isOpen={isModalOpen(MODAL.SUBMIT)}
            onClose={closeModal}
            onFinish={() => redirectTo(`/position/${position.id}?tab=TAB_RATES`)}
            positionId={position.id}
            submissionId={proposal.id}
          />
          <SelectWinner
            isOpen={isModalOpen(MODAL.SELECT_WINNER)}
            hasSubmissions={hasSubmissions(positionStatus)}
            position={position}
            onClose={closeModal}
          />
        </React.Fragment>
      )}
      {role === SUPPLIER_ROLE && (
        <WithdrawSubmission
          isOpen={isModalOpen(MODAL.WITHDRAW)}
          id={proposal.id}
          name={proposal.name}
          status={submissionStatus}
          onClose={closeModal}
          isProposal
        />
      )}
    </div>
  );
};

SubmissionSettings.propTypes = propTypes;
SubmissionSettings.defaultProps = defaultProps;

export default SubmissionSettings;
