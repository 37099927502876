import { gql } from '@apollo/client';

export const GET_FORM = gql`
  query formsList($filter: FormFilter) {
    formsList(filter: $filter) {
      items {
        id
        form
        type
        isFormDefault
        enabledFields
      }
      count
    }
  }
`;

export const GET_FORM_ID = gql`
  query getFormByProject($project: ID!, $type: String!) {
    formsList(
      filter: {
        OR: [
          { AND: [{ type: { equals: $type } }, { project: { id: { equals: $project } } }] }
          { AND: [{ type: { equals: $type } }, { isFormDefault: { equals: true } }] }
        ]
      }
    ) {
      items {
        id
        isFormDefault
      }
    }
  }
`;
