const colors = {
  blue: '#109fc6',
  blueLight: '#29b2d8',
  blueLightest: '#d3ecf3',
  blueDark: '#0e8fb4',
  blueDarkest: '#0e5574',
  gray: '#e6e6e6',
  grayLight: '#f6f6f6',
  grayLightest: '#fbfbfb',
  grayDark: '#a0a0a0',
  grayDarkest: '#676767',
  red: '#d0314b',
  redLightest: '#f5cfd5',
  redDark: '#a51b32',
  redDarkest: '#8a1629',
  green: '#00bea1',
  greenLightest: '#cbf0e7',
  greenDark: '#04a78e',
  greenDarkest: '#025f50',
  yellow: '#efcb4f',
  yellowLightest: '#f9e6a6',
  yellowDark: '#e0bc3f',
  yellowDarkest: '#7b5d10',
} as const;

export default colors;
