/* eslint-disable import/order */
import React, { useContext } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import getProperty from 'lodash/get';
import setProperty from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
// services
import logger from 'services/Logger';
// contexts
import ToastContext from 'context/ToastContext';
// hooks
import useAuth from 'hooks/useAuth';
// services
import { uploadCv, getMatchReport } from 'services/Report';
// components
import Score from 'components/atoms/Score';
import Textarea from 'components/atoms/Textarea';
import Icon from 'components/atoms/Icon';
import LinkButton from 'components/atoms/LinkButton';
import Field from 'components/molecules/Field';
import FileUpload from 'components/molecules/FileUpload';
// helpers
import { getActionButtonTitle } from './helpers';
// constants
import { REVISION_TYPES } from 'constants';
import { ACTIONS } from './constants';
import { SUPPORTED_CV_FORMATS } from 'constants/file';
// styles
import colors from 'dependencies/materialStyles/Colors';

const { CV, ATTESTATION, RATES } = REVISION_TYPES;

const propTypes = {
  formData: PropTypes.object.isRequired,
  type: PropTypes.oneOf([CV, ATTESTATION, RATES]).isRequired,
  onChange: PropTypes.func,
  revision: PropTypes.object.isRequired,
  setLoading: PropTypes.func.isRequired,
  onActionChange: PropTypes.func.isRequired,
  onCvUploadStarted: PropTypes.func,
  onCvUploadFinished: PropTypes.func,
};

const defaultProps = {
  onChange: () => {},
  onCvUploadStarted: () => {},
  onCvUploadFinished: () => {},
};

const RevisionForm = ({
  type,
  formData,
  onChange,
  revision,
  setLoading,
  onCvUploadStarted,
  onCvUploadFinished,
  onActionChange,
}) => {
  const { t } = useTranslation();
  const { id: userId, supplierId } = useAuth();
  const { addToast } = useContext(ToastContext);

  const action = getProperty(formData, [type, 'action'], null);
  const error = getProperty(formData, [type, 'error'], false);
  const rejectionNote = getProperty(formData, [type, 'rejectionNote', 'value'], '');
  const rejectionNoteError = getProperty(formData, [type, 'error'], false);
  const acceptNote = getProperty(formData, [type, 'acceptNote', 'value'], '');
  const selectedFiles = getProperty(formData, [type, 'revisedFile', 'value', 'file']);
  const score = getProperty(formData, [type, 'revisedFile', 'value', 'score']);
  const reportId = getProperty(formData, [type, 'revisedFile', 'value', 'reportId']);
  const submissionId = getProperty(revision, 'submission.id', '');

  const handleUploadCV = async file => {
    const position = getProperty(revision, 'submission.position');
    const rfq = getProperty(position, 'rfq.name', '');
    const client = getProperty(position, 'project.client.name', '');
    const project = getProperty(position, 'project.name', '');
    const shProjectId = getProperty(position, 'project.shProjectId', '');
    const positionId = getProperty(position, 'id', '');
    const fileData = getProperty(file, '0.file');

    const reportResponse = await uploadCv(fileData, {
      rfq,
      project,
      client,
      shProjectId,
      positionId,
      supplierId,
      userId,
    });
    const newReportId = getProperty(reportResponse, 'reportId', '');
    const existingCv = getProperty(reportResponse, 'existingCv', false);

    if (!newReportId) {
      addToast.error(t('cvNotAnalysed'));
      return false;
    }
    if (existingCv) {
      addToast.warning(t('existingCv'));
      return false;
    }

    const report = await getMatchReport({
      shPositionId: reportResponse.shPositionId,
      shCandidateId: reportResponse.shCandidateId,
    });

    return {
      reportId: newReportId,
      score: parseFloat((getProperty(report, 'score.overall', 0) * 100).toFixed(2)),
    };
  };

  const handleChange = async (value, id) => {
    const newFormData = cloneDeep(formData);
    const fileValue = getProperty(value, 'file');

    if (type === CV && id === 'revisedFile' && fileValue) {
      try {
        onCvUploadStarted();
        setLoading(true);
        const newCv = await handleUploadCV(fileValue);

        if (!newCv) {
          setProperty(newFormData, type, null);
        } else {
          setProperty(newFormData, [type, id, 'value'], {
            ...value,
            reportId: newCv.reportId,
            score: newCv.score,
          });
        }

        onChange(newFormData, type);
      } catch (parseError) {
        logger.exception(parseError);
        addToast.error(t('errorPlaceholderText'));
        setProperty(newFormData, type, null);
        onChange(newFormData, type);
      } finally {
        setLoading(false);
        onCvUploadFinished();
      }
    } else {
      if (!fileValue && id === 'revisedFile') {
        setProperty(newFormData, [type, 'boxFileId'], null);
      }

      setProperty(newFormData, [type, id, 'value'], value);
      onChange(newFormData, type);
    }
  };

  const toggleAction = selectedAction => {
    onActionChange(selectedAction);
  };

  const goToReportPreview = () => {
    window.open(`/report/preview/${reportId}/${submissionId}`);
  };

  if (action === ACTIONS.REJECT) {
    return (
      <div className="reject-form">
        <Field
          label={t('rejectRevisionReason')}
          required
          error={rejectionNoteError}
          className={`revision-form_reject-revision-field ${error ? 'redForm' : ''}`}
          content={
            <Textarea
              id="rejectionNote"
              className="revision-form_note"
              onChange={handleChange}
              value={rejectionNote}
            />
          }
        />
        <LinkButton
          onClick={() => {
            toggleAction(ACTIONS.ACCEPT);
          }}
        >
          <span className="link-container">{getActionButtonTitle(type, ACTIONS.ACCEPT)}</span>
        </LinkButton>
      </div>
    );
  }

  if (action === ACTIONS.ACCEPT) {
    return (
      <div className="accept-form">
        {[CV, ATTESTATION].includes(type) && (
          <div>
            {type === CV && reportId && (
              <div className="accept-form_score">
                <Score score={score} size={100} />
                <LinkButton onClick={goToReportPreview}>
                  <div className="p-t-5 accept-form_match-report-button">
                    <Icon name="openInNew" color={colors.blue} size={15} />
                    <span className="p-l-2">{t('viewMatchReport')}</span>
                  </div>
                </LinkButton>
              </div>
            )}
            <Field
              hideLabel
              className="m-t-20"
              fieldNotFilled={error}
              content={
                <FileUpload
                  selected={selectedFiles}
                  onChange={files => handleChange({ file: files }, 'revisedFile')}
                  accept={SUPPORTED_CV_FORMATS}
                  error={error}
                />
              }
            />
            <Field
              label={t('additionalNote')}
              className="m-t-20 revision-form_reject-revision-field"
              content={
                <Textarea
                  id="acceptNote"
                  className="revision-form_note"
                  onChange={handleChange}
                  value={acceptNote}
                />
              }
            />
            <LinkButton
              onClick={() => {
                toggleAction(ACTIONS.REJECT);
              }}
            >
              <span className="link-container">{getActionButtonTitle(type, ACTIONS.REJECT)}</span>
            </LinkButton>
          </div>
        )}
        {type === RATES && (
          <LinkButton
            onClick={() => {
              toggleAction(ACTIONS.REJECT);
            }}
          >
            <span className="link-container">{getActionButtonTitle(type, ACTIONS.REJECT)}</span>
          </LinkButton>
        )}
      </div>
    );
  }
  return null;
};

RevisionForm.propTypes = propTypes;
RevisionForm.defaultProps = defaultProps;

export default RevisionForm;
