import { gql } from '@apollo/client';
// services
import client from 'services/Client';
import logger from 'services/Logger';
// queries
import { Rate } from 'queries/Fragments';
// helpers
import parsePosition from 'helpers/PositionParser';
// constants
import { RATES_NTE_RATE, RATES_TARGET_RATE } from 'constants';

const FETCH_POSITION = gql`
  query fetchPosition($id: ID!) {
    position(id: $id) {
      id
      title
      rfq {
        id
        name
      }
      project {
        id
        name
        config
        client {
          id
          name
        }
      }
      duration {
        id
        hours
      }
      rates {
        items {
          ...Rate
        }
      }
    }
  }
  ${Rate}
`;

export const fetchPosition = async id => {
  try {
    const response = await client.query({
      query: FETCH_POSITION,
      variables: { id },
    });

    const position = parsePosition(response.data.position);
    const nteRates = position.getRates(RATES_NTE_RATE);
    const targetRates = position.getRates(RATES_TARGET_RATE);

    return {
      client: position.client.name,
      project: position.project.name,
      rfq: position.rfqLabel,
      title: position.title,
      years: nteRates.map(rate => rate.year),
      hours: position.duration.hours,
      nteRates: nteRates.map(rate => rate.value),
      targetRates: targetRates.map(rate => rate.value),
      enableManYearDiscount: position.getConfig('enableManYearDiscount', false),
    };
  } catch (error) {
    logger.exception(error, { id });
    throw error;
  }
};

export default fetchPosition;
