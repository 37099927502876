import React, { useState, useContext } from 'react';
// Libraries
import { useLocation, Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import queryString from 'query-string';
// components
import Button from 'components/atoms/Button';
import Loader from 'components/atoms/Loader';
// context
import ToastContext from 'context/ToastContext';
// services
import { resetPassword } from 'services/Authentication';

const ResetPasswordSuccess = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { addToast } = useContext(ToastContext);
  const [loading, setLoading] = useState(false);

  const { email } = queryString.parse(location.search);

  const resendPasswordReset = async () => {
    try {
      setLoading(true);
      await resetPassword(email);
      addToast.success(t('resetPasswordEmailSentSuccess'));
    } catch (error) {
      addToast.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="authForm">
      <div className="authForm_header">{t('passwordResetSent')}</div>
      <div className="authForm_label formWidth m-b-30">
        <div>
          <Trans i18nKey="resetPasswordDescription">
            We just emailed you link for setting your new password. Please check your inbox{' '}
            <b>{email}</b>.
          </Trans>
        </div>
        <div>
          <Trans i18nKey="resetPasswordDescription2">
            If you can&apos;t find the email, please make sure you&apos;ve entered the address you
            registered with, and check your spam folder.
          </Trans>
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div className="authForm_button">
          <Button label={t('resendResetPasswordEmail')} onClick={resendPasswordReset} />
        </div>
      )}
      <div className="authForm_statement">
        <Trans i18nKey="genericRedirectLogin">
          Return to{' '}
          <Link to="/login" className="authLink">
            Sign in
          </Link>
        </Trans>
      </div>
    </div>
  );
};

export default ResetPasswordSuccess;
