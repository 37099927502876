import React from 'react';
import PropTypes from 'prop-types';
// Components
import Chip from 'components/atoms/Chip';
import Filters from './Filters';
// styles
import './pageHeader.scss';

const propTypes = {
  headerName: PropTypes.string,
  noFilters: PropTypes.bool,
  customHeaderContent: PropTypes.any,
  pageHeaderClassName: PropTypes.string,
  count: PropTypes.number,
  createButton: PropTypes.any,
};

const defaultProps = {
  headerName: null,
  noFilters: false,
  customHeaderContent: null,
  pageHeaderClassName: '',
  count: null,
  createButton: null,
};

const PageHeader = props => {
  const {
    headerName,
    noFilters,
    customHeaderContent,
    pageHeaderClassName,
    count,
    createButton,
  } = props;
  return (
    <div className={`${pageHeaderClassName} pageHeader`}>
      <div className="pageHeader_firstRow">
        <div className="space-12 pageHeading display-flex-row">
          {headerName}
          {count > 0 && (
            <div style={{ marginTop: '-3px' }}>
              <Chip label={count} type="header" color="gray" className="m-l-5" />
            </div>
          )}
        </div>
        {customHeaderContent}
      </div>
      {!noFilters && (
        <div className="pageHeader_firstRow">
          {!noFilters && (
            <div className="oneRow space-between">
              <Filters {...props} />
            </div>
          )}
          {createButton}
        </div>
      )}
    </div>
  );
};

PageHeader.propTypes = propTypes;
PageHeader.defaultProps = defaultProps;

export default PageHeader;
