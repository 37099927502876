/* eslint-disable import/order */
import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
// hooks
import useAuth from 'hooks/useAuth';
// helpers
import { getPositionActors } from 'helpers/Position';
import { getPositionNotes } from './helpers';
// components
import LinkButton from 'components/atoms/LinkButton';
import Collapse from 'components/atoms/Collapse';
import StyledHtml from 'components/atoms/StyledHtml';
import CardSection from 'components/molecules/CardSection';
import Rates from './Rates';
import Attachments from './Attachments';
import PositionNotes from './PositionNotes';
import Requirements from './Requirements';
import PositionRoles from './PositionRoles';
import StatusDates from './StatusDates';
import PositionInfo from './PositionInfo';
import Deliverables from './Deliverables';
import PublishBanner, { showPublishBanner } from '../PublishBanner';
// styles
import './positionDetails.scss';

const propTypes = {
  position: PropTypes.object.isRequired,
  hidePublishBanner: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
};

const defaultProps = {
  className: '',
  color: 'blue',
  hidePublishBanner: false,
};

const PositionDetails = ({ position, color, className, hidePublishBanner }) => {
  const { t } = useTranslation();
  const user = useAuth();
  const { role } = user;

  const notes = getPositionNotes(position, role);
  const positionRoles = getPositionActors(position).map(positionRole => ({
    role: positionRole.label,
    name: positionRole.user.name,
  }));

  const renderCollapseButton = (collapsed, onToggle) => {
    return <LinkButton onClick={onToggle}>{collapsed ? t('seeMore') : t('seeLess')}</LinkButton>;
  };

  return (
    <div className={classNames('positionDetails', className)}>
      <Grid container spacing={16}>
        {showPublishBanner(position, user) && !hidePublishBanner && (
          <Grid item xs={12}>
            <PublishBanner position={position} />
          </Grid>
        )}
        {position.description && (
          <Grid item xs={12} md={12} lg={12}>
            <CardSection
              bordered
              title={t('positionOrProjectOverview')}
              content={
                <Collapse collapsed collapsedSize={150} button={renderCollapseButton}>
                  <StyledHtml html={position.description} />
                </Collapse>
              }
            />
          </Grid>
        )}
        <Grid item xs={12} md={12} lg={3}>
          <div className="positionDetails_section">
            <CardSection
              bordered
              title={t('dates')}
              content={<StatusDates position={position} />}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <div className="positionDetails_section">
            <CardSection
              bordered
              title={t('positionLogistics')}
              content={<PositionInfo position={position} color={color} />}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={12} lg={3}>
          <div className="positionDetails_section">
            <CardSection
              bordered
              title={t('people')}
              content={<PositionRoles roles={positionRoles} />}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <div className="positionDetails_section">
            <CardSection bordered title={t('rates')}>
              <Rates position={position} role={role} />
            </CardSection>
          </div>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <div className="positionDetails_section">
            <CardSection
              bordered
              title={t('attachments')}
              content={
                <Attachments reportId={position.report.id} position={position} role={role} />
              }
            />
          </div>
        </Grid>
        {position.deliverables.length > 0 && (
          <Grid item xs={12} md={12} lg={12}>
            <div className="positionDetails_section">
              <CardSection bordered title={t('deliverables.title')}>
                <Deliverables items={position.deliverables} />
              </CardSection>
            </div>
          </Grid>
        )}
        <Grid item xs={12} md={12}>
          <div className="positionDetails_section positionDetails_requirements">
            <CardSection
              bordered
              title={t('requirements')}
              content={<Requirements position={position} />}
            />
          </div>
        </Grid>
        {notes.length > 0 && (
          <Grid item xs={12} md={12} lg>
            <div className="positionDetails_section">
              <CardSection bordered title={t('notes')} content={<PositionNotes notes={notes} />} />
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

PositionDetails.propTypes = propTypes;
PositionDetails.defaultProps = defaultProps;

export default PositionDetails;
