/* eslint-disable import/order */
import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
// components
import Placeholder from 'components/atoms/Placeholder';
import { TableLoader } from 'components/molecules/ContentLoaders';
import InfiniteScroll from 'components/atoms/InfiniteScroll';
import ErrorPage from 'components/pages/ErrorPage';
import Link from 'components/atoms/Link';
import Nbsp from 'components/atoms/Nbsp';
import CandidateMatchesTable from './CandidatesMatchTable';
import CandidatesMatchFilter from './CandidatesMatchFilter';
// hooks
import useAuth from 'hooks/useAuth';
import useCandidatesMatch from './useCandidatesMatch';
// styles
import styles from './candidatesMatchTable.module.scss';

const propTypes = {
  candidateId: PropTypes.string.isRequired,
  candidate: PropTypes.object.isRequired,
};

const CandidateMatchListTable = ({ candidateId, candidate }) => {
  const { t } = useTranslation();
  const { supplierId } = useAuth();

  const {
    data,
    filter,
    sort,
    fetchMore,
    filteredBy,
    sortedBy,
    loading,
    error,
    count,
  } = useCandidatesMatch(candidateId, supplierId);
  const filtersSelected = Object.values(filteredBy).filter(value => !isEmpty(value)).length > 0;

  const renderContent = () => {
    if (loading) {
      return <TableLoader />;
    }

    if (error) {
      return <ErrorPage code={500} />;
    }

    if (!data.length) {
      return (
        <Placeholder size="lg" icon="team">
          {filtersSelected ? t('noCandidatesMatchesForSelectedFilters') : t('noOpenPositions')}
        </Placeholder>
      );
    }

    return (
      <InfiniteScroll
        dataLength={data.length}
        hasMore={count > data.length}
        next={fetchMore}
        scrollableTarget="scrollableContent"
      >
        <CandidateMatchesTable candidate={candidate} matches={data} />
      </InfiniteScroll>
    );
  };

  return (
    <React.Fragment>
      <div className={styles.wrapper}>
        <div className={styles.title}>{t('matchedPositions')}</div>
        <div className={styles.infoText}>
          {t('candidatesMatchedToTimeAndMaterials')}
          <Nbsp />
          <Link
            href="/positions/all?contractType=FIXED_PRICE&status=RECRUITING"
            className={styles.infoLink}
          >
            {t('clickHereToViewFixedPrice')}
          </Link>
        </div>
        <CandidatesMatchFilter
          filteredBy={filteredBy}
          sortedBy={sortedBy}
          onFilterChange={filter}
          onSortChange={sort}
        />
        <div className="m-t-30">{renderContent()}</div>
      </div>
    </React.Fragment>
  );
};

CandidateMatchListTable.propTypes = propTypes;

export default React.memo(CandidateMatchListTable);
