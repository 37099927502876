import getProperty from 'lodash/get';
// local states
import { updateItem, getItemCount } from 'localStates/itemsCount';
// constants
import { LIST_COUNT_OPERATIONS } from 'constants';

export const updateListCount = data => {
  if (data) {
    const type = getProperty(Object.keys(data), '0');

    if (LIST_COUNT_OPERATIONS.includes(type)) {
      const count = getProperty(data, [type, 'count'], 0);
      updateItem(type, count);
    }
  }
};

/**
 * Get list count
 * @param {string} type Count list type
 * @returns {number} count
 */
export const getListCount = type => {
  if (!LIST_COUNT_OPERATIONS.includes(type)) {
    throw new Error(`Type "${type}" not found in list "${LIST_COUNT_OPERATIONS.join(', ')}"`);
  }

  return getItemCount(type);
};

/**
 * Set list count
 * @param {string} type Count list type
 * @param {number} count
 */
export const setListCount = (type, count) => {
  if (!LIST_COUNT_OPERATIONS.includes(type)) {
    throw new Error(`Type "${type}" not found in list "${LIST_COUNT_OPERATIONS.join(', ')}"`);
  }

  updateItem(type, count);
};
