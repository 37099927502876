import getProperty from 'lodash/get';

/**
 * Get new selected options when an option was checked
 * @param {object} option Option or sub-option which was checked
 * @param {string[]} selected Already selected options
 * @param {object} [parentOption] Parent option if sub-option was checked
 * @returns {string[]}
 */
export const getNewSelectedOptionsByCheck = (option, selected, parentOption) => {
  // sub-option was checked
  if (parentOption) {
    const allSubOptionsChecked = parentOption.items.every(subOption =>
      [...selected, option.value].includes(subOption.value)
    );

    // if all sub-options are checked and parent option is not -> remove sub-options and add main option
    if (allSubOptionsChecked && !selected.includes(parentOption.value)) {
      const subOptions = parentOption.items.map(subOption => subOption.value);
      const newSelectedOptions = selected.filter(
        selectedOption => !subOptions.includes(selectedOption)
      );

      return [...newSelectedOptions, parentOption.value];
    }
  }

  // option was checked which has some sub-options
  if (!parentOption && getProperty(option, 'items', []).length > 0) {
    // option was checked which has some sub-options
    const subOptions = option.items.map(subOption => subOption.value);
    const allSubOptionsAreAvailable = option.items.every(subOption => !subOption.disabled);

    // if option has sub-options available -> remove sub-options from selected and add main option
    if (allSubOptionsAreAvailable) {
      return [...selected, option.value].filter(
        selectedOption => !subOptions.includes(selectedOption)
      );
    }

    // add all available sub-options
    const availableSubOptions = option.items.filter(subOption => !subOption.disabled);
    const newSelectedOptions = [...selected];
    availableSubOptions.forEach(subOption => {
      if (!selected.includes(subOption.value)) {
        newSelectedOptions.push(subOption.value);
      }
    });

    return newSelectedOptions;
  }

  // option was checked which doesn't have any sub-options
  return [...selected, option.value];
};

/**
 * Get new selected options when an option was unchecked
 * @param {object} option Option which was unchecked
 * @param {string[]} selected Already selected options
 * @param {object} [parentOption] Parent option if sub-option was unchecked
 * @returns {string[]}
 */
export const getNewSelectedOptionsByUncheck = (option, selected, parentOption) => {
  // sub-option was unchecked
  if (parentOption) {
    // sub-option was unchecked but parent option is checked
    if (selected.includes(parentOption.value)) {
      // uncheck parent option + checked sub-option
      const newSelectedOptions = selected.filter(
        selectedOption => ![option.value, parentOption.value].includes(selectedOption)
      );
      // add other sub-options to already selected options
      parentOption.items.forEach(subOption => {
        if (subOption.value !== option.value) {
          newSelectedOptions.push(subOption.value);
        }
      });

      return newSelectedOptions;
    }
  }

  // option was unchecked which has some sub-options
  if (!parentOption && getProperty(option, 'items', []).length > 0) {
    const subOptions = option.items.map(subOption => subOption.value);

    return selected.filter(
      selectedOption => selectedOption !== option.value && !subOptions.includes(selectedOption)
    );
  }

  // option which doesn't have any sub-options was unchecked
  return selected.filter(selectedOption => selectedOption !== option.value);
};
