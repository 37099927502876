/* eslint-disable import/order */
import React, { useState, useContext } from 'react';
// libraries
import { useTranslation, Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import getProperty from 'lodash/get';
// hooks
import useAuth from 'hooks/useAuth';
// context
import ToastContext from 'context/ToastContext';
// helpers
import { boxFileDir } from 'helpers/Box';
import { uploadOnboardingFile } from 'helpers/Onboarding';
import { getTimeYear } from 'helpers/RelativeTimeFormatter';
import {
  confirmStartDateBySupplier,
  rejectStartDateBySupplier,
  parseExpectedPositionStartDate,
} from '../helpers';
// constants
import { SUBMISSION_STATUS_TYPE } from 'constants/submissionStatus';
import { FILE_SIGNED_JOB_OFFER } from 'constants';
// components
import Button from 'components/atoms/Button';
import ConfirmStartDate from './ConfirmStartDate';
import EditForm from '../EditForm';
import JobOffer from './JobOffer';
import TextContent from '../TextContent';
// forms
import { rejectDateForm } from '../forms';

const propTypes = {
  submission: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
};

const CONTENT_REJECT_DATE = 'CONTENT_REJECT_DATE';
const CONTENT_CONFIRM_DATE = 'CONTENT_CONFIRM_DATE';

const ProposeStartDate = ({ submission, position }) => {
  const { t } = useTranslation();
  const { addToast } = useContext(ToastContext);
  const { id: userId, role, preferredName } = useAuth();

  const [loading, setLoading] = useState(false);
  const [contentType, setContentType] = useState(null);

  const { rfq, duration, client, project } = position;
  const status = submission.getStatus(role);
  const onboardingStatus = submission.getStatus(role, SUBMISSION_STATUS_TYPE.ONBOARDING_START_DATE);
  const requiredStartDate = parseExpectedPositionStartDate(submission, duration.start);
  const formattedRequiredStartDate = getTimeYear(requiredStartDate);

  // Confirm Start Date / Job Offer by Supplier
  const onStartDateConfirm = async data => {
    setLoading(true);
    try {
      const folder = boxFileDir({ client: client.name, project: project.name, rfq }, 'onboarding');
      const formData = await uploadOnboardingFile(
        data,
        folder,
        FILE_SIGNED_JOB_OFFER,
        FILE_SIGNED_JOB_OFFER
      );

      await confirmStartDateBySupplier({
        submission,
        position,
        currentStatusId: status.id,
        currentOnboardingStatusId: onboardingStatus.id,
        formData,
        userId,
      });
    } catch (error) {
      addToast.error(t('errorWhileConfirmingStartDateBySupplier'));
      setLoading(false);
      setContentType(null);
    }
  };

  // Reject Start Date / Job Offer by Supplier
  const onStartDateReject = async data => {
    const rejectionReason = getProperty(data, 'rejectionReason.value');

    try {
      setLoading(true);
      await rejectStartDateBySupplier({
        submission,
        position,
        currentStatusId: status.id,
        currentOnboardingStatusId: onboardingStatus.id,
        rejectionReason,
        userId,
      });
    } catch (error) {
      addToast.error(t('errorWhileRejectingStartDateBySupplier'));
      setLoading(false);
      setContentType(null);
    }
  };

  const getContent = () => {
    if (contentType === CONTENT_REJECT_DATE) {
      return (
        <EditForm
          form={rejectDateForm}
          onCancel={() => setContentType(null)}
          onSubmit={onStartDateReject}
          onSubmitLabel={t('decline')}
          loading={loading}
        />
      );
    }

    if (contentType === CONTENT_CONFIRM_DATE) {
      return (
        <ConfirmStartDate
          onCancel={() => setContentType(null)}
          onSubmit={onStartDateConfirm}
          defaultStartDate={requiredStartDate}
          onSubmitLabel={t('accept')}
          loading={loading}
          isOfferRequired={position.getConfig('signedOfferRequired', false)}
        />
      );
    }

    return (
      <React.Fragment>
        <div className={`display-flex-row flexEnd ${!contentType ? '' : 'p-t-30'} `}>
          <span className="p-r-12">
            <Button
              label={t('decline')}
              onClick={() => setContentType(CONTENT_REJECT_DATE)}
              secondary
            />
          </span>
          <Button label={t('accept')} onClick={() => setContentType(CONTENT_CONFIRM_DATE)} />
        </div>
        <JobOffer submission={submission} className="w100" />
      </React.Fragment>
    );
  };

  return (
    <TextContent
      title={
        <Trans i18nKey="proposeStartDateConfirmOffer">
          Hi {{ preferredName }}, please confirm that your candidate,
          {{ candidateName: submission.getName() }}, accepts our offer.
        </Trans>
      }
      label={
        contentType === CONTENT_CONFIRM_DATE ? (
          <Trans i18nKey="expectedStartDateForPosition">
            Expected start date for {{ rfq }} is <b>{{ formattedRequiredStartDate }}</b>.
          </Trans>
        ) : null
      }
      content={getContent()}
    />
  );
};

ProposeStartDate.propTypes = propTypes;

export default ProposeStartDate;
