import React from 'react';
// Libraries
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './auth.scss';

const propTypes = {
  content: PropTypes.any.isRequired,
};

const Authentication = ({ content }) => {
  const { t } = useTranslation();
  return (
    <div className="loginTemplate">
      <div className="loginTemplate_container">
        <div className="loginTemplate_billboard">
          <div className="loginTemplate_billboard--img" />
          <div className="loginTemplate_billboard--text">
            <div className="loginTemplate_billboard--services">IBM Services</div>
            <div className="loginTemplate_billboard--alaka">{t('talentMatchingHub')}</div>
            <div className="loginTemplate_billboard--description">
              <Trans i18nKey="welcomeDescription">
                HIRE FASTER. WORK BETTER. PLAN FURTHER.
                <br />
                The smart platform that matches the best jobs to the best people.
              </Trans>
            </div>
            <div className="loginTemplate_billboard--team">{t('alakaTeam')}</div>
          </div>
        </div>
        <div className="loginTemplate_content">{content}</div>
      </div>
    </div>
  );
};

Authentication.propTypes = propTypes;

export default Authentication;
