import React from 'react';
// libraries
import PropTypes from 'prop-types';
// constants
import { COLOR_GRAY, COLOR_BLUE, COLOR_GREEN, COLOR_RED, COLOR_YELLOW } from 'constants/colors';
// components
import AvatarList from 'components/molecules/AvatarList';
// styles
import styles from './audience.module.scss';

const propTypes = {
  color: PropTypes.oneOf(['', COLOR_BLUE, COLOR_GREEN, COLOR_RED, COLOR_YELLOW, COLOR_GRAY]),
  label: PropTypes.string.isRequired,
  candidates: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClick: PropTypes.func.isRequired,
};

const defaultProps = {
  color: '',
};

const AudienceBox = ({ color, label, candidates, onClick }) => {
  if (!candidates.length) {
    return null;
  }

  return (
    <div role="presentation" className="verticalCenter" onClick={onClick}>
      <span className={styles.label}>{label}</span>
      <AvatarList color={color} items={candidates} />
    </div>
  );
};

AudienceBox.propTypes = propTypes;
AudienceBox.defaultProps = defaultProps;

export default AudienceBox;
