// libraries
import getProperty from 'lodash/get';
import i18n from 'i18next';
// services
import client from 'services/Client';
import logger from 'services/Logger';
import notification from 'services/Notification';
import { sendEmail } from 'services/Mailer';
// queries
import { GET_TEMPLATES_BY_PROJECT } from 'queries/TemplateQueries';
// helpers
import { closeSubmissionStatus } from 'helpers/Submission';
import { getSubmissionLink } from 'helpers/Link';
// constants
import { SUBMISSION_TAB } from 'constants/pageTabs';
import { SUPPLIER_IBM, SUPPLIER_DIRECT, SUPPLIER_NOT_DIRECT, MANAGER_ROLE } from 'constants';
import { ONBOARDING_STATUS_FORMS, ONBOARDING_TAB } from 'constants/onboarding';
import {
  ONBOARDING_FILE_HR72,
  ONBOARDING_FILE_PASSPORT,
  ONBOARDING_FILE_IVCP,
  ONBOARDING_FILE_NDA,
  ONBOARDING_FILE_NHF,
  ONBOARDING_FILE_ATTESTATION,
  ONBOARDING_FILE_AAS_PLUS_NDA,
  ONBOARDING_FILE_NR_AIS_SECOPS_FORM,
  ONBOARDING_FILE_NR_AIS_SECOPS,
  ONBOARDING_NR_SECOPS_CHECKBOX,
  ONBOARDING_FORMS_NOTE,
} from '../constants';
// forms
import { onboardingForm } from '../forms';

/**
 * @param {string} type
 * @returns {string}
 */
const getFormLabel = type => {
  const LABEL_MAP = {
    [ONBOARDING_FILE_HR72]: i18n.t('hr72'),
    [ONBOARDING_FILE_PASSPORT]: i18n.t('passportCopy/Id'),
    [ONBOARDING_FILE_IVCP]: i18n.t('ivcp'),
    [ONBOARDING_FILE_NDA]: i18n.t('nda'),
    [ONBOARDING_FILE_NHF]: i18n.t('nhf'),
    [ONBOARDING_FILE_ATTESTATION]: i18n.t('attestation'),
    [ONBOARDING_FILE_AAS_PLUS_NDA]: 'AAS+ NCIA NDA',
    [ONBOARDING_FILE_NR_AIS_SECOPS_FORM]: 'NR Secops D2 form',
    [ONBOARDING_FILE_NR_AIS_SECOPS]: 'NR AIS SecOps Policy',
    [ONBOARDING_FORMS_NOTE]: i18n.t('notes'),
  };

  return getProperty(LABEL_MAP, type, type);
};

/**
 * @param {object[]} templates
 * @param {string} category partner category
 * @returns {string[]}
 */
const getOnboardingFileTypes = (templates, category) => {
  const files = templates.filter(template => template.required).map(template => template.type);
  files.splice(1, 0, ONBOARDING_FILE_PASSPORT);

  if (category === SUPPLIER_DIRECT) {
    return files.filter(file => file !== ONBOARDING_FILE_NHF);
  }
  if (category === SUPPLIER_NOT_DIRECT) {
    return files.filter(file => file !== ONBOARDING_FILE_NDA);
  }

  return files;
};

/**
 * Fetch onboarding forms template
 * @param {string} projectId
 * @param {string} category supplier category (direct, not-direct)
 * @returns {object[]}
 */
export const fetchTemplates = async (projectId, category) => {
  try {
    const response = await client.query({
      query: GET_TEMPLATES_BY_PROJECT,
      variables: { projectId, category: category !== SUPPLIER_IBM ? category : null },
      fetchPolicy: 'cache-first',
    });

    const projectTemplates = getProperty(response, 'data.project.templateGroup.templates.items');
    const clientTemplates = getProperty(
      response,
      'data.project.client.templateGroup.templates.items'
    );

    // project templates have higher priority than client templates
    const templates = projectTemplates || clientTemplates || [];

    return templates.map(({ id, boxId, type, required }) => ({
      id,
      boxId,
      type,
      label: getFormLabel(type),
      required,
    }));
  } catch (error) {
    logger.exception(error, { projectId, category });
    throw error;
  }
};

export const getOnboardingForms = (templates, category, disabled, isProposal = false) => {
  const inputs = [...getOnboardingFileTypes(templates, category)];

  if (inputs.includes(ONBOARDING_FILE_NR_AIS_SECOPS_FORM)) {
    inputs.push(ONBOARDING_NR_SECOPS_CHECKBOX);
  }

  inputs.push(ONBOARDING_FORMS_NOTE);

  return inputs.map(type => ({
    ...onboardingForm[type],
    disabled,
    allowMultiple: isProposal,
  }));
};

/**
 * @param {object} submission
 * @param {object[]} templates
 * @param {string} role
 * @returns {object[]}
 */
export const getOnboardingFiles = (submission, templates, role) => {
  const fileTypes = getOnboardingFileTypes(templates, submission.partner.category);
  const filteredFileTypes =
    role === MANAGER_ROLE
      ? fileTypes.filter(type => [ONBOARDING_FILE_HR72, ONBOARDING_FILE_PASSPORT].includes(type))
      : fileTypes;

  return filteredFileTypes.map(type => ({
    type,
    label: getFormLabel(type),
    files: submission.getOnboardingDocuments(type) || [],
  }));
};

/**
 * @param {object} submission
 * @returns {?object}
 */
export const getOnboardingFormsNote = submission => {
  const notes = getProperty(submission, 'hiringProcess.notes.items', []);
  const onboardingFormsNote = notes.find(({ noteType }) => noteType === ONBOARDING_FORMS_NOTE);

  if (onboardingFormsNote) {
    return {
      text: onboardingFormsNote.note,
      author: getProperty(onboardingFormsNote, 'author.person.fullName', ''),
      createdAt: onboardingFormsNote.createdAt,
    };
  }

  return null;
};

/**
 * @param {object} email Email options and html from email component
 * @param {object} submission
 * @param {string} userId Logged in user id
 * @returns {Promise}
 */
export const submitOnboardingDocumentsForAusy = async (email, submission, userId) => {
  try {
    const onboardingFormsStatusAusy = submission.findStatus(ONBOARDING_STATUS_FORMS.SEND_TO_AUSY);

    await Promise.all([
      sendEmail(email),
      closeSubmissionStatus({
        submissionId: submission.id,
        oldStatusId: onboardingFormsStatusAusy.id,
        isProposal: submission.isProposal(),
      }),
    ]);

    // Notification for PM
    const link = getSubmissionLink(submission.id, submission.isProposal(), {
      queryParams: {
        step: ONBOARDING_TAB.FORMS,
        tab: SUBMISSION_TAB.ONBOARDING,
      },
    });

    notification.onboardingFormsUploadedToAusy({
      submission: submission.id,
      link,
      createdBy: userId,
      isProposal: submission.isProposal(),
    });
  } catch (error) {
    logger.exception(error, { submission: submission.id, userId });
    throw error;
  }
};

/**
 * @param {object} email Email options and html from email component
 * @param {object} submission
 * @param {string} userId Logged in user id
 * @returns {Promise}
 */
export const submitOnboardingDocumentsForPM = async (email, submission, userId) => {
  try {
    const onboardingFormsStatusPM = submission.findStatus(ONBOARDING_STATUS_FORMS.SEND_TO_PM);

    await Promise.all([
      sendEmail(email),
      closeSubmissionStatus({
        submissionId: submission.id,
        oldStatusId: onboardingFormsStatusPM.id,
        isProposal: submission.isProposal(),
      }),
    ]);

    // Notification for PM
    const link = getSubmissionLink(submission.id, submission.isProposal(), {
      queryParams: {
        step: ONBOARDING_TAB.FORMS,
        tab: SUBMISSION_TAB.ONBOARDING,
      },
    });

    notification.onboardingFormsUploadedByMM({
      submission: submission.id,
      link,
      createdBy: userId,
      isProposal: submission.isProposal(),
    });
  } catch (error) {
    logger.exception(error, { candidate: submission.id, userId });
    throw error;
  }
};

export const parseDefaultData = files => {
  let defaultData = {};

  files.forEach(file => {
    if (file.value) {
      defaultData = { ...defaultData, [file.id]: { value: file.value } };
    } else if (file.name) {
      defaultData = {
        ...defaultData,
        [file.key]: [{ boxId: file.name, file: { name: file.label } }],
      };
    }
  });

  return defaultData;
};
