import i18n from 'i18next';
import cloneDeep from 'lodash/cloneDeep';
import pick from 'lodash/pick';
import moment from 'moment';
// helpers
import { validate } from 'components/molecules/SubmissionRejection';
// constants
import { FIELD } from '../constants';

const wonFields = [
  FIELD.HAS_REJECTION_REASON,
  FIELD.SAME_REJECTION_REASON,
  FIELD.SELECTED_SUBMISSION,
  FIELD.REASONS,
  FIELD.REASON,
];

/**
 * Reset all errors for data
 * @param {object} formData
 * @returns {object}
 */
const resetErrors = formData => {
  const data = cloneDeep(formData);

  Object.keys(data).forEach(field => {
    data[field].error = false;
    data[field].errorMessages = '';
  });

  return data;
};

const validateRejectionFields = formData => {
  const hasRejectionReason = formData[FIELD.HAS_REJECTION_REASON].value;
  const hasSameRejectionReason = formData[FIELD.SAME_REJECTION_REASON].value;
  const rejectionReasons = formData[FIELD.REASONS].value;
  const rejectionReason = formData[FIELD.REASON].value;
  const rejectedSubmissions = formData[FIELD.NOT_SELECTED_SUBMISSIONS].value;
  const rejectionFieldsData = pick(formData, [
    FIELD.HAS_REJECTION_REASON,
    FIELD.SAME_REJECTION_REASON,
    FIELD.REASON,
    FIELD.REASONS,
  ]);
  const validatedData = resetErrors(rejectionFieldsData);

  const hasRejectionReasonError = rejectedSubmissions.length > 0 && hasRejectionReason === null;
  validatedData[FIELD.HAS_REJECTION_REASON].error = hasRejectionReasonError;
  validatedData[FIELD.HAS_REJECTION_REASON].errorMessages = hasRejectionReasonError
    ? i18n.t('selectAnOptionError')
    : '';

  const sameRejectionReasonError =
    hasRejectionReason && rejectedSubmissions.length > 1 && hasSameRejectionReason === null;
  validatedData[FIELD.SAME_REJECTION_REASON].error = sameRejectionReasonError;
  validatedData[FIELD.SAME_REJECTION_REASON].errorMessages = sameRejectionReasonError
    ? i18n.t('selectAnOptionError')
    : '';

  const reasonErrorMessages = validate(rejectionReason);
  const reasonError =
    hasRejectionReason &&
    (rejectedSubmissions.length === 1 || hasSameRejectionReason) &&
    reasonErrorMessages.length > 0;

  validatedData[FIELD.REASON].error = reasonError;
  validatedData[FIELD.REASON].errorMessages = reasonError ? reasonErrorMessages : [];

  const reasonsError =
    hasRejectionReason &&
    rejectedSubmissions.length > 1 &&
    !hasSameRejectionReason &&
    Object.values(rejectionReasons).filter(reason => reason).length !== rejectedSubmissions.length;

  validatedData[FIELD.REASONS].error = reasonsError;
  validatedData[FIELD.REASONS].errorMessages = reasonsError
    ? i18n.t('provideValueForAllError')
    : '';

  return validatedData;
};

/**
 * Validate data for select winner
 * @param {object} formData
 * @returns {object} validated data with already set errors
 */
export const validateSelectWinner = formData => {
  const hasWon = formData[FIELD.SUBMISSION_WON].value;
  const totalEvalPrice = formData[FIELD.TOTAL_EVALUATED_PRICE].value;
  const selectedSubmission = formData[FIELD.SELECTED_SUBMISSION].value;
  const startDate = formData[FIELD.START_DATE].value;
  const validatedData = resetErrors(formData);

  // no option was selected
  validatedData[FIELD.SUBMISSION_WON].error = hasWon === null;
  validatedData[FIELD.SUBMISSION_WON].errorMessages =
    hasWon === null ? i18n.t('selectAnOptionError') : '';

  // not won
  if (hasWon === false) {
    validatedData[FIELD.TOTAL_EVALUATED_PRICE].error = !totalEvalPrice;
    validatedData[FIELD.TOTAL_EVALUATED_PRICE].errorMessages = !totalEvalPrice
      ? i18n.t('missingValueError')
      : '';

    // reset errors for "won" fields
    wonFields.forEach(field => {
      validatedData[field].error = false;
      validatedData[field].errorMessages = '';
    });
  }

  // has won
  if (hasWon === true) {
    // reset errors for "lost" fields
    validatedData[FIELD.TOTAL_EVALUATED_PRICE].error = false;
    validatedData[FIELD.TOTAL_EVALUATED_PRICE].errorMessages = '';

    validatedData[FIELD.SELECTED_SUBMISSION].error = !selectedSubmission;
    validatedData[FIELD.SELECTED_SUBMISSION].errorMessages = !selectedSubmission
      ? i18n.t('selectAtLeastOneSubmissionError')
      : '';

    validatedData[FIELD.START_DATE].error = moment(startDate).isBefore(moment());
  }

  return { ...validatedData, ...validateRejectionFields(formData) };
};

/**
 * Validate data for re-selecting won bid
 * @param {object} formData
 * @param {boolean} validateSubmissionWonField
 * @returns {object} validated data with already set errors
 */
export const validateReselectWonBid = (formData, validateSubmissionWonField) => {
  const submissionWon = formData[FIELD.SUBMISSION_WON].value;
  const reason = formData[FIELD.JUSTIFICATION].value;
  const selectedSubmission = formData[FIELD.SELECTED_SUBMISSION].value;
  const startDate = formData[FIELD.START_DATE].value;
  const validatedData = resetErrors(formData);

  const submissionWonError = validateSubmissionWonField && submissionWon === null;
  validatedData[FIELD.SUBMISSION_WON].error = submissionWonError;
  validatedData[FIELD.SUBMISSION_WON].errorMessages = submissionWonError
    ? i18n.t('selectAnOptionError')
    : '';

  validatedData[FIELD.JUSTIFICATION].error = !reason;
  validatedData[FIELD.JUSTIFICATION].errorMessages = !reason ? i18n.t('missingValueError') : '';

  validatedData[FIELD.SELECTED_SUBMISSION].error = !selectedSubmission;
  validatedData[FIELD.SELECTED_SUBMISSION].errorMessages = !selectedSubmission
    ? i18n.t('selectAtLeastOneSubmissionError')
    : '';

  validatedData[FIELD.START_DATE].error = moment(startDate).isBefore(moment());

  return validatedData;
};

/**
 * Validate data for re-selecting lost bid
 * @param {object} formData
 * @returns {object} validated data with already set errors
 */
export const validateReselectLostBid = formData => {
  const reason = formData[FIELD.JUSTIFICATION].value;
  const totalEvalPrice = formData[FIELD.TOTAL_EVALUATED_PRICE].value;
  const validatedData = resetErrors(formData);

  validatedData[FIELD.JUSTIFICATION].error = !reason;
  validatedData[FIELD.JUSTIFICATION].errorMessages = !reason ? i18n.t('missingValueError') : '';

  validatedData[FIELD.TOTAL_EVALUATED_PRICE].error = !totalEvalPrice;
  validatedData[FIELD.TOTAL_EVALUATED_PRICE].errorMessages = !totalEvalPrice
    ? i18n.t('missingValueError')
    : '';

  return validatedData;
};
