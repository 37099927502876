export const DEFAULT_TARGET_MARGIN = 30;
export const DEFAULT_IBM_FEE = 10.0;
export const DEFAULT_IBM_FEE_FP = 10.0;
export const MIN_BUSINESS_GP_REQUIREMENT = 10.0;
export const MIN_BUSINESS_GP_FP_REQUIREMENT = 10.0;
export const DEFAULT_DGP = 10.0;
export const DEFAULT_DGP_FP = 10.0;
export const APPROXIMATE_WORKING_HOURS_PER_YEAR = 2080;
export const INTEGRATOR_FEE = 1.5;
export const CSA_FEE = 1.05;
