import { useRef, useEffect } from 'react';

const useIsFirstRun = () => {
  const isFirstRunRef = useRef(true);

  useEffect(() => {
    isFirstRunRef.current = false;
  }, []);

  return isFirstRunRef.current;
};

export default useIsFirstRun;
