/* eslint-disable import/order */
import React, { useState, useEffect, useContext } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// hooks
import useAuth from 'hooks/useAuth';
// components
import Score from 'components/atoms/Score';
import Button from 'components/atoms/Button';
import TextIcon from 'components/atoms/TextIcon';
import TimezoneDate from 'components/atoms/TimezoneDate';
import LinkButton from 'components/atoms/LinkButton';
import PositionStatusFlow from 'components/molecules/PositionStatusFlow';
import StatusDate from 'components/molecules/StatusDate';
import Banner, { BANNER_TYPE } from 'components/molecules/Banner';
import RateDisplay from '../RateDisplay';
import Card from '../Card';
import PositionLoader from './PositionLoader';
// context
import ToastContext from 'context/ToastContext';
// helpers
import { openLink } from 'helpers/Link';
import { fetchPositionMatch } from './helpers';
// constants
import { RATES_NTE_RATE, RATES_TARGET_RATE, SUPPLIER_ROLE } from 'constants';
import { COLOR as ICON_COLOR } from 'constants/colors';
import { MAIN_RECRUITING } from 'constants/positionStatus';
// styles
import styles from './selectedPosition.module.scss';

const propTypes = {
  candidateId: PropTypes.string.isRequired,
  positionId: PropTypes.string.isRequired,
};

const SelectedPosition = ({ candidateId, positionId }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { role, supplierId } = useAuth();
  const { addToast } = useContext(ToastContext);
  const [loading, setLoading] = useState(false);
  const [position, setPosition] = useState(null);

  useEffect(() => {
    const fetchPositionFn = async () => {
      try {
        setLoading(true);

        const fetchedPosition = await fetchPositionMatch(candidateId, positionId);
        setPosition(fetchedPosition);
      } catch (error) {
        addToast.error('errorPlaceholderText');
      } finally {
        setLoading(false);
      }
    };

    fetchPositionFn();
  }, []);

  const handleMatchReportRedirect = () => {
    openLink(`/report/match/${position.match.id}`);
  };

  const redirectToSubmissionForm = () => {
    history.push(`/submission/create/${positionId}/${position.candidateMatchId}`);
  };

  if (loading) {
    return (
      <Card>
        <PositionLoader />
      </Card>
    );
  }

  if (!position) {
    return (
      <Banner className={styles.banner} type={BANNER_TYPE.ERROR}>
        {t('positionNotFound')}
      </Banner>
    );
  }

  const nteRate = position.getRate(RATES_NTE_RATE);
  const targetRate = position.getRate(RATES_TARGET_RATE);
  const positionStatus = position.getMainStatus(SUPPLIER_ROLE);
  const isPublished = position.isPublished(SUPPLIER_ROLE, supplierId);
  const location = position.getLocation();

  return (
    <Card>
      <div className={styles.container}>
        <div className={styles.leftSection}>
          <Score score={position.match.score} size={70} strokeWidth={9} />
          <div className="m-l-20">
            <p className={styles.title}>{t('selectedPositionTitle')}:</p>
            <div className="d-flex m-t-5">
              <Link to={`/position/${position.id}`} className={styles.positionName}>
                {position.rfqLabel} - {position.title}
              </Link>
              <div className={styles.status}>
                <PositionStatusFlow position={position} />
              </div>
              <StatusDate position={position} role={role} />
            </div>
            <LinkButton onClick={handleMatchReportRedirect} className="m-t-10">
              <TextIcon icon="fileText" size={16}>
                {t('viewMatchReport')}
              </TextIcon>
            </LinkButton>
            <div className="d-flex m-t-30">
              <div className="m-r-20">
                <TextIcon
                  icon="calendar"
                  size={18}
                  color={ICON_COLOR.DARK_GRAY}
                  classes={{ icon: styles.icon }}
                >
                  <TimezoneDate date={position.dueDate} timezone={position.project.timezone} />
                </TextIcon>
              </div>
              {location && (
                <div className="m-r-20">
                  <TextIcon
                    icon="location"
                    size={18}
                    color={ICON_COLOR.DARK_GRAY}
                    classes={{ icon: styles.icon }}
                  >
                    {location}
                  </TextIcon>
                </div>
              )}
              <TextIcon
                icon="verified"
                size={16}
                color={ICON_COLOR.DARK_GRAY}
                classes={{ icon: styles.icon }}
              >
                {position.clearance}
              </TextIcon>
            </div>
          </div>
        </div>

        <div className={styles.rightSection}>
          <RateDisplay
            rate={targetRate.value}
            currency={position.project.currencyLabel}
            tooltip={position.isFixedPrice() ? t('fixedPrice') : t('hourlyRate')}
            rateDescription={
              position.showNteRatesToPartners && (
                <React.Fragment>
                  /{t('nte')} {nteRate.value}
                  {position.project.currencyLabel}
                </React.Fragment>
              )
            }
          />
          <div className="m-t-10">
            <Button
              label={t('submitCandidate')}
              disabled={positionStatus.value !== MAIN_RECRUITING || !isPublished}
              onClick={redirectToSubmissionForm}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

SelectedPosition.propTypes = propTypes;

export default SelectedPosition;
