import React, { useState, useEffect } from 'react';
// libraries
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
// helpers
import { prettyNumber } from 'helpers/Number';
// hooks
import useIsFirstRun from 'hooks/useIsFirstRun';
// components
import NumberInput from 'components/atoms/NumberInput';

const propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  symbol: PropTypes.string,
  highlight: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  allowNegative: PropTypes.bool,
};

const defaultProps = {
  value: null,
  className: '',
  symbol: '',
  highlight: false,
  error: false,
  errorMessage: '',
  allowNegative: false,
};

const Input = ({
  id,
  className,
  symbol,
  highlight,
  error,
  errorMessage,
  value,
  onChange,
  allowNegative,
}) => {
  const [focused, setFocused] = useState(false);
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const isFirstRun = useIsFirstRun();
  const classes = ['rateTable_input'];

  useEffect(() => {
    if (!isFirstRun) {
      setTooltipOpen(error && focused);
    }
  }, [error]);

  if (focused) {
    classes.push('rateTable_input-highlighted');
  } else if (highlight) {
    classes.push('rateTable_input-highlighted');
  }

  if (error) {
    classes.push('rateTable-error');
  }
  if (className) {
    classes.push(...className.split(' '));
  }

  return (
    <div className={classes.join(' ')}>
      <Tooltip
        title={errorMessage}
        onOpen={() => (!focused && error ? setTooltipOpen(true) : null)}
        onClose={() => (!focused ? setTooltipOpen(false) : null)}
        open={isTooltipOpen}
        placement="top-end"
      >
        <div className="rateTable_input_box">
          <NumberInput
            name={id}
            className="rateTable_input_element"
            value={value}
            onChange={onChange}
            border={false}
            allowNegative={allowNegative}
            decimalLimit={2}
            step={0.5}
            onFocus={() => setFocused(true)}
            formatter={prettyNumber}
            onBlur={() => {
              setFocused(false);
              setTooltipOpen(false);
            }}
          />
          {symbol && <span className="rateTable_symbol">{symbol}</span>}
        </div>
      </Tooltip>
    </div>
  );
};

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export default Input;
