import PropTypes from 'prop-types';
// constants
import { MRI_SECTION, MRI_IMPACT, MRI_LEVEL } from 'constants/report';

export const MetricType = PropTypes.shape({
  percentile: PropTypes.number,
  min: PropTypes.number.isRequired,
  mean: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  median: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  dataPoints: PropTypes.arrayOf(PropTypes.number).isRequired,
  updatedAt: PropTypes.string,
});

export const MriType = PropTypes.shape({
  score: PropTypes.number.isRequired,
  sections: PropTypes.arrayOf(PropTypes.oneOf(Object.values(MRI_SECTION))).isRequired,
  goodSections: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOf(Object.values(MRI_SECTION)).isRequired,
      impact: PropTypes.oneOf(Object.values(MRI_IMPACT)).isRequired,
    })
  ).isRequired,
  badSections: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOf(Object.values(MRI_SECTION)).isRequired,
      impact: PropTypes.oneOf(Object.values(MRI_IMPACT)).isRequired,
      penalty: PropTypes.number.isRequired,
      level: PropTypes.oneOf(Object.values(MRI_LEVEL)).isRequired,
      issues: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string.isRequired,
          code: PropTypes.string.isRequired,
        })
      ),
    })
  ),
});
