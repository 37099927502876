import { TAB_NOTIFICATIONS } from 'constants';
import i18n from 'i18next';

const tabs = [
  {
    id: TAB_NOTIFICATIONS,
    label: i18n.t('notifications'),
  },
];

export default tabs;
