import React, { useState, useEffect } from 'react';
// libraries
import offlinePlugin from 'offline-plugin/runtime';
import { Prompt } from 'react-router';
// helpers
import { isLocalhost } from 'helpers/Common';

/**
 * Manage app static assets and force refresh on app updates
 */
const UpdateManager = () => {
  const [assetsUpdated, setAssetsUpdated] = useState(false);

  useEffect(() => {
    // init service worker for caching assets
    offlinePlugin.install({
      onUpdateReady: () => offlinePlugin.applyUpdate(),
      // assets have been updated
      onUpdated: () => {
        setAssetsUpdated(true);
      },
    });
  }, []);

  const onNavigation = (location, action) => {
    // For development on localhost we use "hot-refresh" on changes provided
    // via webpack-server so don't force refresh here
    if (isLocalhost()) {
      return true;
    }

    // force hard refresh on user navigation if assets were updated
    if (action === 'PUSH' && assetsUpdated) {
      // force hard refresh
      window.location.assign(location.pathname + location.search);

      return false;
    }

    return true;
  };

  return <Prompt message={onNavigation} />;
};

export default UpdateManager;
