import React, { useState } from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import getProperty from 'lodash/get';
// components
import ClickableElement from 'components/atoms/ClickableElement';
import Score from 'components/atoms/Score';
import Icon from 'components/atoms/Icon';
import TruncatedText from 'components/atoms/TruncatedText';
import Button from 'components/atoms/Button';
import ExcellentMatchIcon from 'components/molecules/ExcellentMatchIcon';
import MRILevelChip from 'components/molecules/MRILevelChip';
import MRIModal from 'components/organisms/MRIModal';
import { MatchMetricsPopper } from 'components/organisms/MatchMetrics';
// helpers
import { getRelativeTime, getTimeYear } from 'helpers/RelativeTimeFormatter';
import { preventDefault } from 'helpers/HtmlDOM';
import { getMetrics, isExcellentMatch, hasEnoughDataPoints } from 'helpers/MatchMetrics';
// constants
import { CANDIDATE_STATUS } from 'constants/candidateStatus';
// styles
import styles from './candidatesMatchTable.module.scss';

const propTypes = {
  data: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
  allowSubmission: PropTypes.bool.isRequired,
};

const CandidateMatchRow = ({ data, position, allowSubmission }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [opened, setOpen] = useState(false);

  const metrics = getMetrics(getProperty(data, 'metrics', []));
  const percentile = getProperty(metrics, 'percentile', 0);
  const metricsCount = getProperty(metrics, 'count', 0);
  const displayExcellentMatch = isExcellentMatch(percentile) && hasEnoughDataPoints(metricsCount);

  const redirectToSubmissionForm = () => {
    history.push(`/submission/create/${position.id}/${data.id}`);
  };

  const renderStatus = () => {
    if (!data.status.value) {
      return (
        <span className={styles.smaller}>
          <i>{t('notProvided')}</i>
        </span>
      );
    }

    if (data.status.value === CANDIDATE_STATUS.AVAILABLE_FROM) {
      return (
        <div className={styles.availableFrom}>
          {data.status.label}
          <div className="bolder m-t-2">{getTimeYear(data.availableFrom)}</div>
        </div>
      );
    }

    return data.status.label;
  };

  return (
    <React.Fragment>
      <Link to={`/candidate/${data.candidateId}/${position.id}`}>
        <div className={styles.row}>
          {/* Score */}
          <div className={styles.score}>
            <MatchMetricsPopper
              metrics={metrics}
              score={data.score}
              candidateName={data.name}
              positionTitle={position.title}
              project={position.project.name}
              occupations={position.getOccupationsName()}
              experienceLevel={position.experienceLevel.value}
              placement="right-start"
              offsetX={10}
            >
              <Score
                score={data.score}
                icon={displayExcellentMatch ? <ExcellentMatchIcon bordered /> : null}
              />
            </MatchMetricsPopper>
          </div>
          {/* Candidate name */}
          <div className={styles.columnCell}>
            <TruncatedText className="bolder">{data.name}</TruncatedText>
            <TruncatedText>{data.profession}</TruncatedText>
          </div>
          {/* Status */}
          <div className={styles.cell}>{renderStatus()}</div>
          {/* Expected Rate */}
          <div className={classNames(styles.cell, styles.right)}>
            {data.expectedRate.value ? (
              <React.Fragment>
                <div className="bolder">
                  {data.expectedRate.value}
                  {data.expectedRate.currency}
                </div>
                <span className={classNames('m-l-2', styles.smaller)}>/{t('hour')}</span>
              </React.Fragment>
            ) : (
              <span className={styles.smaller}>
                <i>{t('notProvided')}</i>
              </span>
            )}
          </div>
          {/* Location */}
          <div className={styles.cell}>
            {data.location ? (
              <React.Fragment>
                <div className={styles.locationIcon}>
                  <Icon size={15} name="location" />
                </div>
                <TruncatedText>{data.location}</TruncatedText>
              </React.Fragment>
            ) : (
              <span className={styles.smaller}>
                <i>{t('unknown')}</i>
              </span>
            )}
          </div>
          {/* CV Readability */}
          <div className={classNames(styles.cell, styles.center)}>
            <ClickableElement onClick={preventDefault(() => setOpen(true))}>
              <MRILevelChip level={data.cvReadability} />
            </ClickableElement>
          </div>
          {/* Last update */}
          <div className={classNames(styles.columnCell, styles.right)}>
            <div className="bolder">{getTimeYear(data.lastUpdate)}</div>
            <div className="m-t-2">{getRelativeTime(data.lastUpdate)}</div>
          </div>
          {/* Submit button */}
          <div className={classNames(styles.cell, styles.right)}>
            <Button
              onClick={preventDefault(redirectToSubmissionForm)}
              label={t('submit')}
              disabled={!allowSubmission}
            />
          </div>
        </div>
      </Link>

      <MRIModal
        isOpen={opened}
        onClose={() => setOpen(false)}
        candidateName={data.name}
        shCandidateId={data.shCandidateId}
        cv={data.cv}
      />
    </React.Fragment>
  );
};

CandidateMatchRow.propTypes = propTypes;

export default CandidateMatchRow;
