import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// types
import { MetricType } from 'types';
// components
import TruncatedText from 'components/atoms/TruncatedText';
import DocumentUploadBox from './DocumentUploadBox';
import Score from '../Score';
import Document from '../Document';
import SaveIcon from '../SaveIcon';
// helpers
import { formatDocumentName } from '../helpers';
// constants
import { DOCUMENT_TYPE } from '../constants';
// styles
import moduleStyles from '../submitCandidates.module.scss';
import styles from './documents.module.scss';

const documentPropTypes = PropTypes.shape({
  boxId: PropTypes.string,
  filename: PropTypes.string.isRequired,
});

const propTypes = {
  isProposal: PropTypes.bool.isRequired,
  score: PropTypes.number,
  name: PropTypes.string.isRequired,
  currentSubmissionFile: documentPropTypes.isRequired,
  releasableSubmissionFile: documentPropTypes,
  currentAttestation: documentPropTypes.isRequired,
  releasableAttestation: documentPropTypes,
  onReleasableSubmissionFileChange: PropTypes.func.isRequired,
  onReleasableAttestationChange: PropTypes.func.isRequired,
  folder: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  previouslySaved: PropTypes.bool,
  position: PropTypes.shape({
    title: PropTypes.string.isRequired,
    rfq: PropTypes.string.isRequired,
    project: PropTypes.string.isRequired,
    experienceLevel: PropTypes.string.isRequired,
    occupations: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  metrics: MetricType,
};

const defaultProps = {
  score: null,
  releasableSubmissionFile: null,
  releasableAttestation: null,
  disabled: false,
  previouslySaved: false,
  metrics: null,
};

const Submission = ({
  isProposal,
  score,
  name,
  currentSubmissionFile,
  releasableSubmissionFile,
  currentAttestation,
  releasableAttestation,
  onReleasableSubmissionFileChange,
  onReleasableAttestationChange,
  folder,
  disabled,
  position,
  metrics,
  previouslySaved,
}) => {
  const { t } = useTranslation();
  const { rfq } = position;

  const handleCurrentSubmissionFileSelection = () => {
    onReleasableSubmissionFileChange({
      ...currentSubmissionFile,
      filename: formatDocumentName(currentSubmissionFile.filename, name, rfq, DOCUMENT_TYPE.CV),
    });
  };

  const handleCurrentAttestationSelection = () => {
    onReleasableAttestationChange({
      ...currentAttestation,
      filename: formatDocumentName(
        currentAttestation.filename,
        name,
        rfq,
        DOCUMENT_TYPE.ATTESTATION
      ),
    });
  };

  return (
    <React.Fragment>
      {!isProposal && (
        <div className={classNames('text-center', { [moduleStyles.disabledElement]: disabled })}>
          <Score
            score={score}
            metrics={metrics}
            candidateName={name}
            positionTitle={position.title}
            project={position.project}
            experienceLevel={position.experienceLevel}
            occupations={position.occupations}
          />
        </div>
      )}
      <div className={styles.nameBox}>
        <div
          className={classNames(moduleStyles.nameLabel, { [moduleStyles.disabledText]: disabled })}
        >
          <TruncatedText>{name}</TruncatedText>
        </div>
        {previouslySaved && (
          <div className="m-l-4">
            <SaveIcon disabled={disabled} />
          </div>
        )}
      </div>
      <Document
        boxId={currentSubmissionFile.boxId}
        filename={currentSubmissionFile.filename}
        disabled={disabled}
      />
      <DocumentUploadBox
        label={t('upload')}
        itemLabel={t('useCurrentProposal')}
        onUpload={cv => onReleasableSubmissionFileChange({ ...cv, isNew: true })}
        onUseCurrent={handleCurrentSubmissionFileSelection}
        onRemoveSelected={() => onReleasableSubmissionFileChange(null)}
        document={releasableSubmissionFile}
        folder={folder}
        rfq={rfq}
        submission={name}
        type={DOCUMENT_TYPE.CV}
        disabled={disabled}
      />
      <Document
        boxId={currentAttestation.boxId}
        filename={currentAttestation.filename}
        disabled={disabled}
      />
      <DocumentUploadBox
        label={t('upload')}
        itemLabel={t('useCurrentAttestation')}
        onUpload={attestation => onReleasableAttestationChange({ ...attestation, isNew: true })}
        onUseCurrent={handleCurrentAttestationSelection}
        onRemoveSelected={() => onReleasableAttestationChange(null)}
        document={releasableAttestation}
        folder={folder}
        rfq={rfq}
        submission={name}
        type={DOCUMENT_TYPE.ATTESTATION}
        disabled={disabled}
      />
    </React.Fragment>
  );
};

Submission.propTypes = propTypes;
Submission.defaultProps = defaultProps;

export default Submission;
