import React, { useEffect } from 'react';
// libraries
import PropTypes from 'prop-types';
import getProperty from 'lodash/get';
// components
import AutoCompleteSearch from 'components/molecules/AutoCompleteSearch';
import InputGroup from 'components/molecules/InputGroup';
// helpers
import { getCustomQuery, getCustomDataParser } from './Helpers';

const propTypes = {
  component: PropTypes.object,
  formData: PropTypes.any,
  updateFormData: PropTypes.func.isRequired,
  defaultValue: PropTypes.array,
  customQueryVariables: PropTypes.object,
  disabled: PropTypes.bool,
};

const defaultProps = {
  component: {},
  formData: {},
  defaultValue: null,
  customQueryVariables: {},
  disabled: false,
};

function Autocompletes(props) {
  const { component, updateFormData, formData, customQueryVariables, disabled } = props;
  const { type, key, defaultValue, limitSize } = component;

  useEffect(() => {
    if (defaultValue && !formData[key]) {
      updateFormData(key, defaultValue, true);
    }
  }, []);

  switch (type) {
    case 'autocomplete':
      return (
        <AutoCompleteSearch
          type={component.tableType}
          onChange={(value, id) => updateFormData(id, value)}
          id={key}
          defaultValue={
            formData[key] && ((!component.isMulti && formData[key].label) || component.isMulti)
              ? formData[key]
              : null
          }
          limitSize={limitSize || 5}
          isMulti={!!component.isMulti}
          createNew={component.createNew}
          disabled={disabled}
        />
      );
    case 'autocompleteCustom':
      return (
        <AutoCompleteSearch
          customQuery={getCustomQuery(component.tableType)}
          customDataParser={getCustomDataParser(component.tableType)}
          customQueryVariables={customQueryVariables}
          type={component.tableType}
          onChange={(value, id) => {
            updateFormData(id, value);
          }}
          id={key}
          defaultValue={
            formData[key] && ((!component.isMulti && formData[key].label) || component.isMulti)
              ? formData[key]
              : null
          }
          limitSize={limitSize || 5}
          isMulti={!!component.isMulti}
          createNew={component.createNew}
          disabled={disabled}
        />
      );
    case 'inputGroups':
      return (
        <InputGroup
          id={key}
          tableName={component.tableName}
          fieldName={component.fieldName}
          placeholder={component.placeholder}
          onChange={updateFormData}
          selected={getProperty(formData, [key, 'value'])}
          type={component.inputType}
          list={component.values}
          disabled={disabled}
        />
      );
    default:
      return null;
  }
}

Autocompletes.propTypes = propTypes;
Autocompletes.defaultProps = defaultProps;

export default Autocompletes;
