import React, { useContext } from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// context
import ModalContext from './ModalContext';
// styles
import styles from './modalWindow.module.scss';

const propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const Footer = ({ children, className }) => {
  const { loading } = useContext(ModalContext);

  if (loading) {
    return null;
  }

  return <div className={classNames(styles.footer, className)}>{children}</div>;
};

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
