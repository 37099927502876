import React from 'react';
// libraries
import PropTypes from 'prop-types';
import getProperty from 'lodash/get';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// helpers
import { getCurrencyText } from 'helpers/NameMapping';
import { prettyNumber } from 'helpers/Number';
// types
import { MetricType } from 'types';
// components
import Button from 'components/atoms/Button';
import TruncatedText from 'components/atoms/TruncatedText';
import Tooltip from 'components/atoms/Tooltip';
import RatesInput from 'components/molecules/RatesInput';
import Score from '../Score';
import UnselectAction from '../UnselectAction';
import SaveIcon from '../SaveIcon';
// styles
import styles from './sellRates.module.scss';
import moduleStyles from '../submitCandidates.module.scss';

const ratesPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.number.isRequired,
    year: PropTypes.number.isRequired,
  })
);

const propTypes = {
  isProposal: PropTypes.bool.isRequired,
  score: PropTypes.number,
  name: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  partnerType: PropTypes.string.isRequired,
  partnerRates: ratesPropTypes.isRequired,
  onSellRatesChange: PropTypes.func.isRequired,
  sellRates: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      year: PropTypes.number.isRequired,
      highlighted: PropTypes.bool,
      error: PropTypes.bool,
    })
  ).isRequired,
  isSimulationOpen: PropTypes.bool.isRequired,
  onSimulationClick: PropTypes.func.isRequired,
  onSubmissionUnselect: PropTypes.func.isRequired,
  onSubmissionRestore: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  previouslySaved: PropTypes.bool,
  position: PropTypes.shape({
    title: PropTypes.string.isRequired,
    project: PropTypes.string.isRequired,
    experienceLevel: PropTypes.string.isRequired,
    occupations: PropTypes.arrayOf(PropTypes.string).isRequired,
    currency: PropTypes.string.isRequired,
    nteRates: ratesPropTypes.isRequired,
  }).isRequired,
  metrics: MetricType,
};

const defaultProps = {
  score: null,
  previouslySaved: false,
  metrics: null,
};

const SubmissionRow = ({
  isProposal,
  score,
  name,
  company,
  partnerType,
  partnerRates,
  onSellRatesChange,
  sellRates,
  isSimulationOpen,
  onSimulationClick,
  onSubmissionUnselect,
  onSubmissionRestore,
  disabled,
  position,
  metrics,
  previouslySaved,
}) => {
  const { t } = useTranslation();
  const currency = getCurrencyText(position.currency);
  const firstPartnerRate = getProperty(partnerRates, '0.value');
  const firstNteRate = getProperty(position.nteRates, '0.value');
  const startYear = getProperty(position.nteRates, '0.year');

  return (
    <React.Fragment>
      <div
        className={classNames(styles.fixedCandidateColumn, {
          [styles.highlighted]: isSimulationOpen,
          [styles.proposal]: isProposal,
        })}
      >
        {!isProposal && (
          <div className={classNames('text-center', { [moduleStyles.disabledElement]: disabled })}>
            <Score
              score={score}
              metrics={metrics}
              candidateName={name}
              positionTitle={position.title}
              project={position.project}
              experienceLevel={position.experienceLevel}
              occupations={position.occupations}
            />
          </div>
        )}
        <div className={styles.nameBox}>
          <div className={styles.nameWrapper}>
            <div className={moduleStyles.nameLabel}>
              <TruncatedText className={disabled ? moduleStyles.disabledText : ''}>
                {name}
              </TruncatedText>
            </div>
            {previouslySaved && (
              <div className="m-l-4">
                <SaveIcon disabled={disabled} />
              </div>
            )}
          </div>
          <Tooltip title={partnerType} placement="top-start">
            <div className={disabled ? moduleStyles.disabledText : ''}>{company}</div>
          </Tooltip>
        </div>
        <div className={classNames('text-right', { [moduleStyles.disabledText]: disabled })}>
          {prettyNumber(firstPartnerRate)} {currency}
        </div>
        <div className={classNames('text-right', { [moduleStyles.disabledText]: disabled })}>
          {prettyNumber(firstNteRate)} {currency}
        </div>
      </div>
      <div className={styles.scrollableCandidateColumn}>
        <div className={classNames(styles.rates, { [moduleStyles.disabledElement]: disabled })}>
          <RatesInput
            rates={sellRates}
            onChange={onSellRatesChange}
            currency={currency}
            startYear={startYear}
            numberOfYears={sellRates.length}
            hideYears
            readOnly={disabled}
          />
        </div>
        <UnselectAction
          isRemoved={disabled}
          onUnselect={onSubmissionUnselect}
          onRestore={onSubmissionRestore}
        >
          <Button
            type={isSimulationOpen ? 'less' : 'expandMore'}
            tooltip={isSimulationOpen ? t('closeRatesSimulation') : t('simulateRates')}
            onClick={onSimulationClick}
            iconStyle="icon20"
          />
        </UnselectAction>
      </div>
    </React.Fragment>
  );
};

SubmissionRow.propTypes = propTypes;
SubmissionRow.defaultProps = defaultProps;

export default SubmissionRow;
