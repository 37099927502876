import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
// components
import DropdownCheckbox from 'components/molecules/DropdownCheckbox';
import Input from 'components/atoms/Input';
import Icon from 'components/atoms/Icon';
// context
import { PageFiltersConsumer } from 'context/PageFiltersContext';
// styles
import colors from 'dependencies/materialStyles/Colors';
import styles from './filters.module.scss';

const propTypes = {
  searchPlaceholder: PropTypes.string,
  filterLabel: PropTypes.string,
  filterLabelChecked: PropTypes.string,
  t: PropTypes.func.isRequired,
  clearFilters: PropTypes.func,
  clearSearch: PropTypes.func,
  handleSearch: PropTypes.func,
  filterList: PropTypes.array,
  sortList: PropTypes.array,
  updateFilterby: PropTypes.func,
  updateSortedBy: PropTypes.func,
  searchText: PropTypes.string,
};

const defaultProps = {
  searchPlaceholder: null,
  filterLabel: null,
  filterLabelChecked: null,
  clearFilters: null,
  clearSearch: null,
  handleSearch: null,
  filterList: null,
  sortList: null,
  updateFilterby: null,
  updateSortedBy: null,
  searchText: '',
};

const Filters = props => {
  const {
    clearFilters,
    clearSearch,
    handleSearch,
    filterList,
    sortList,
    updateFilterby,
    updateSortedBy,
    filterLabel,
    filterLabelChecked,
    searchPlaceholder,
    searchText,
    t,
  } = props;

  const handles = [!!handleSearch, !!updateFilterby, !!updateSortedBy].filter(func => !!func);
  if (handles.length === 0) {
    return null;
  }

  return (
    <PageFiltersConsumer>
      {({ filteredBy, sortedBy }) => {
        return (
          <div className="filters">
            {!!handleSearch && (
              <div className="m-r-15 fs-mask">
                <Input
                  onChange={handleSearch}
                  icon={
                    <Icon
                      name="search"
                      color={colors.gray}
                      style={{ height: '18px', width: '18px' }}
                    />
                  }
                  id="search"
                  inputType="text"
                  placeholder={searchPlaceholder || t('searchDefaultPlaceholder')}
                  value={searchText}
                  onClear={clearSearch}
                  width={240}
                />
              </div>
            )}
            {!!updateFilterby && filterList && (
              <div className="m-r-15">
                <DropdownCheckbox
                  list={filterList}
                  selected={filteredBy}
                  onChange={updateFilterby}
                  name="filter"
                  label={filterLabel || t('filter')}
                  checkedLabel={
                    filteredBy.label && (
                      <span>
                        <span className="checkboxContainer_header-italic">
                          {filterLabelChecked || t('filteredBy')}:{' '}
                        </span>
                        {filteredBy.label}
                      </span>
                    )
                  }
                  width={200}
                />
              </div>
            )}
            {!!updateSortedBy && sortList && (
              <div className="m-r-15">
                <DropdownCheckbox
                  list={sortList}
                  selected={sortedBy}
                  onChange={updateSortedBy}
                  label={t('sort')}
                  name="sort"
                  className={styles.sortElement}
                  checkedLabel={
                    sortedBy.label && (
                      <span>
                        <span className="checkboxContainer_header-italic">{t('sortedBy')}: </span>
                        {sortedBy.label}
                      </span>
                    )
                  }
                />
              </div>
            )}
            {!!clearFilters && (
              <div className="filters_clear" role="presentation" onClick={clearFilters}>
                {t('clearFilters')}
                {/* Clear {handles.length > 1 ? 'all filters' : 'filter'} */}
              </div>
            )}
          </div>
        );
      }}
    </PageFiltersConsumer>
  );
};

Filters.propTypes = propTypes;
Filters.defaultProps = defaultProps;

export default withTranslation()(Filters);
