import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// components
import Button from 'components/atoms/Button';
import RateInput from 'components/molecules/RateInput';
import RateText from 'components/molecules/RateText';
import Tooltip from 'components/atoms/Tooltip';
import LevelOfEffort from '../LevelOfEffort';
// styles
import styles from '../ratesSimulation.module.scss';

const propTypes = {
  targetMargin: PropTypes.number.isRequired,
  onTargetMarginChange: PropTypes.func.isRequired,
  onMyMarginChange: PropTypes.func.isRequired,
  yearPurchased: PropTypes.number.isRequired,
  manYearDiscount: PropTypes.number.isRequired,
  myMargin: PropTypes.number.isRequired,
  hours: PropTypes.number.isRequired,
  onApplyRates: PropTypes.func.isRequired,
  resetChanges: PropTypes.func.isRequired,
  isDiscardActive: PropTypes.bool.isRequired,
  className: PropTypes.string,
  targetMarginChanged: PropTypes.bool,
  myMarginChanged: PropTypes.bool,
};

const defaultProps = {
  className: '',
  targetMarginChanged: false,
  myMarginChanged: false,
};

const Allowance = ({
  yearPurchased,
  manYearDiscount,
  targetMargin,
  myMargin,
  onTargetMarginChange,
  onMyMarginChange,
  onApplyRates,
  resetChanges,
  isDiscardActive,
  hours,
  className,
  targetMarginChanged,
  myMarginChanged,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.controls, className)}>
      <div className={styles.allowanceTitle}>{t('targetMargin')}</div>
      <RateInput
        currency="%"
        min={0}
        max={100}
        value={targetMargin}
        onChange={onTargetMarginChange}
        highlighted={targetMarginChanged}
        hideUseAll
        className={styles.controlElement}
      />
      <Tooltip title={<LevelOfEffort hours={hours} />}>
        <div className={styles.allowanceTitle}>{t('manYearDiscountLabel')}</div>
      </Tooltip>
      <Tooltip title={<LevelOfEffort hours={hours} />}>
        <div className={styles.controlElement}>
          <RateText currency="%" value={manYearDiscount} />
        </div>
      </Tooltip>
      <Button label={t('useSimulatedRates')} onClick={onApplyRates} />
      <div className={styles.allowanceTitle}>{t('ibmFeeLabel')}</div>
      <RateInput
        currency="%"
        min={0}
        max={100}
        value={myMargin}
        onChange={onMyMarginChange}
        highlighted={myMarginChanged}
        hideUseAll
        className={styles.controlElement}
      />
      <div className={styles.allowanceTitle}>{`% ${t('yearPurchasedLabel')}`}</div>
      <RateText currency="%" value={yearPurchased} className={styles.controlElement} />
      <Button
        label={t('resetChanges')}
        onClick={resetChanges}
        disabled={!isDiscardActive}
        secondary
      />
    </div>
  );
};

Allowance.propTypes = propTypes;
Allowance.defaultProps = defaultProps;

export default Allowance;
