import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  color: PropTypes.any,
  size: PropTypes.any,
};

const defaultProps = {
  color: '',
  size: 24,
};

const WarningOutlineIcon = ({ color, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      height={`${size}px`}
      viewBox="0 0 24 24"
      width={`${size}px`}
      fill={color || 'currentColor'}
    >
      <g>
        <rect fill="none" height="24" width="24" />
      </g>
      <g>
        <g>
          <g>
            <path d="M12,5.99L19.53,19H4.47L12,5.99 M12,2L1,21h22L12,2L12,2z" />
            <polygon points="13,16 11,16 11,18 13,18" />
            <polygon points="13,10 11,10 11,15 13,15" />
          </g>
        </g>
      </g>
    </svg>
  );
};

WarningOutlineIcon.propTypes = propTypes;
WarningOutlineIcon.defaultProps = defaultProps;

export default WarningOutlineIcon;
