/* eslint-disable react/no-array-index-key */
import React from 'react';
// Libraries
import PropTypes from 'prop-types';
// helpers
import { getCurrencyText } from 'helpers/NameMapping';
// components
import NumberFormInput from 'components/atoms/NumberFormInput';

const propTypes = {
  value: PropTypes.object,
  id: PropTypes.string.isRequired,
  currency: PropTypes.string,
  year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  rate: PropTypes.object,
  className: PropTypes.string,
  staticValues: PropTypes.array,
  highlight: PropTypes.bool,
  hideNames: PropTypes.bool,
  compareToRate: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
};

const defaultProps = {
  value: null,
  currency: null,
  year: null,
  onBlur: () => {},
  rate: null,
  className: '',
  staticValues: null,
  highlight: false,
  compareToRate: null,
  hideNames: false,
  disabled: false,
};

const Rate = props => {
  const {
    className,
    value,
    id,
    currency,
    year,
    onChange,
    onBlur,
    rate,
    staticValues,
    highlight,
    compareToRate,
    hideNames,
    disabled,
  } = props;

  const onRateChange = val => {
    const rateValue = val ? { value: val, year, currency } : null;
    onChange(rateValue, id);
  };

  const onRateBlur = () => {
    if (compareToRate) {
      const validationRates = compareToRate.reduce((previous, current) => {
        return {
          ...previous,
          [current]: rate[current],
        };
      }, {});
      onBlur(id, validationRates);

      return;
    }

    onBlur(id, null);
  };

  return (
    <div className={`${className} ${value && value.error ? 'redForm' : ''} rateYear`}>
      {!hideNames && (
        <div className="rateYear_year rateInputs_cell-header element_name">{year}</div>
      )}
      {staticValues &&
        staticValues.map((rateKey, index) => {
          if (rate[rateKey.key]) {
            const currentRate = rate[rateKey.key];
            return (
              <div key={currentRate.id} className="rateYear_rate rateInputs_cell">
                <span className="p-r-5">{currentRate.value}</span>
                {getCurrencyText(currentRate.currency)}
              </div>
            );
          }
          return <div className="rateInputs_cell" key={index} />;
        })}
      <div className="rateYear_input">
        <NumberFormInput
          value={value && value.value ? value.value : ''}
          decimalLimit={2}
          min={0}
          onChange={onRateChange}
          onBlur={onRateBlur}
          highlight={highlight}
          id={id}
          disabled={disabled}
        />
        {currency ? <span className="p-l-2">{getCurrencyText(currency)}</span> : null}
      </div>
    </div>
  );
};

Rate.propTypes = propTypes;
Rate.defaultProps = defaultProps;

export default Rate;
