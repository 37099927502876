import React from 'react';
// libraries
import ContentLoader from 'react-content-loader';

const ListItemLoader = () => (
  <ContentLoader
    speed={2}
    width={400}
    height={90}
    viewBox="0 0 400 90"
    primaryColor="#f1f1f1"
    secondaryColor="#e6e6e6"
  >
    <rect x="65" y="31" rx="0" ry="0" width="300" height="8" />
    <circle cx="33" cy="45" r="18" />
    <rect x="65" y="52" rx="0" ry="0" width="250" height="8" />
  </ContentLoader>
);

export default ListItemLoader;
