import { gql } from '@apollo/client';
// constants
import { ACTOR_PRIMARY_OWNER, ACTOR_WATCHER } from 'constants';
// fragments
import { PositionActor, PositionStatus } from 'queries/Fragments';

export const GET_SUPPLIER_DUE_DATE = gql`
  query getSupplierDueDate($id: ID!) {
    position(id: $id) {
      id
      dueOn
      supplierDueDate
    }
  }
`;

export const UPDATE_SUPPLIER_DUE_DATE = gql`
  mutation updateSupplierDueDate($id: ID!, $dueDate: DateTime!) {
    positionUpdate(data: { supplierDueDate: $dueDate }, filter: { id: $id }) {
      id
      supplierDueDate
    }
  }
`;

export const CLAIM_OWNERSHIP = gql`
  mutation claimOwnership($positionId: ID!, $userId: ID!, $start: DateTime! ) {
    positionUpdate(data: {
      actors: {
        create: {
          role: "${ACTOR_PRIMARY_OWNER}",
          startDate: $start,
          user: {
            connect: {
              id: $userId
            }
          }
        }
      }
    }, filter: {id: $positionId}) {
      id
      actors {
        items {
          ...PositionActor
        }
      }
    }
  }
  ${PositionActor}
`;

export const REMOVE_OWNERSHIP = gql`
  mutation removeOwnership($positionRoleId: ID!, $end: DateTime!) {
    positionRoleUpdate(filter: { id: $positionRoleId }, data: { endDate: $end }) {
      ...PositionActor
    }
  }
  ${PositionActor}
`;

export const WATCH_POSITION = gql`
  mutation watchPosition($positionId: ID!, $userId: ID!,$start: DateTime!) {
    positionUpdate(data: {
      actors: {
        create: {
          role: "${ACTOR_WATCHER}",
          startDate: $start,
          user: {
            connect: {
              id: $userId
            }
          }
        }
      }
    }, filter: {id: $positionId}) {
      id
      actors {
        items {
          ...PositionActor
        }
      }
    }
  }
  ${PositionActor}
`;

export const STOP_WATCH_POSITION = gql`
  mutation watchPosition($positionRoleId: ID!, $end: DateTime!) {
    positionRoleUpdate(filter: { id: $positionRoleId }, data: { endDate: $end }) {
      ...PositionActor
    }
  }
  ${PositionActor}
`;

export const GET_POSITION_STATUS = gql`
  query getPositionStatuses($id: ID!) {
    position(id: $id) {
      id
      statuses(sort: { createdAt: DESC }) {
        items {
          ...PositionStatus
        }
      }
    }
  }
  ${PositionStatus}
`;

export const UPDATE_POSITION_FOR_SUBMISSIONS = gql`
  mutation updatePositionForSubmissions($position: ID!, $partners: [SupplierKeyFilter!]) {
    positionUpdate(
      filter: { id: $position }
      data: { bids: { connect: $partners }, candidatesSubmissionProgress: null }
    ) {
      id
      candidatesSubmissionProgress
    }
  }
`;

export const SAVE_SUBMIT_SUBMISSIONS_PROGRESS = gql`
  mutation saveSubmitCandidatesProgress($position: ID!, $data: JSON!) {
    positionUpdate(filter: { id: $position }, data: { candidatesSubmissionProgress: $data }) {
      id
      candidatesSubmissionProgress
    }
  }
`;

export const SAVE_PUBLISH_PROGRESS = gql`
  mutation savePublishPositionProgress($position: ID!, $data: JSON!) {
    positionUpdate(filter: { id: $position }, data: { publishProgress: $data }) {
      id
      publishProgress
    }
  }
`;
