import React from 'react';
// libraries
import shortid from 'shortid';
import getProperty from 'lodash/get';
import PropTypes from 'prop-types';
// hooks
import { useTranslation } from 'react-i18next';
// components
import LinkButton from 'components/atoms/LinkButton';
import RateTable from 'components/molecules/RateTable';
// constants
import { getCurrencyText } from 'helpers/NameMapping';
import { getYear } from 'helpers/RelativeTimeFormatter';
import { getPartnerRates, getProposedRates, getTargetRates } from './helpers';

const propTypes = {
  revision: PropTypes.object.isRequired,
  showRatesInput: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  ratesValue: PropTypes.object.isRequired,
  error: PropTypes.bool,
};

const defaultProps = {
  error: false,
};

const Rates = ({ revision, showRatesInput, onChange, ratesValue, error }) => {
  const { t } = useTranslation();
  const proposedRates = getProposedRates(revision);
  const supplierRate = getPartnerRates(revision);
  const targetRates = getTargetRates(revision);
  const years = proposedRates.map(({ startDate }) => getYear(startDate));

  const useProposedRates = () => {
    const ratesObject = proposedRates.reduce(
      (list, current, index) => ({ ...list, [index]: current }),
      {}
    );
    onChange(ratesObject);
  };

  return (
    <div className="rate-table_container">
      <RateTable className="rate-table" bordered={false}>
        <RateTable.Row>
          <RateTable.Head>&nbsp;</RateTable.Head>
          {years.map(year => (
            <RateTable.Head key={shortid()}>{year}</RateTable.Head>
          ))}
        </RateTable.Row>

        <RateTable.Row className="rate-table_border-bottom">
          <RateTable.Title className="rate-table_title" active={false}>
            {t('proposedRates')}
          </RateTable.Title>
          {proposedRates.map(({ value, id, currency }) => (
            <RateTable.Text className="rate-table_rate" key={id} symbol={getCurrencyText(currency)}>
              {value}
            </RateTable.Text>
          ))}
        </RateTable.Row>
        <RateTable.Row>
          <RateTable.Title className="rate-table_title" active={false}>
            {t('currentRates')}
          </RateTable.Title>
          {supplierRate.map(({ value, id, currency }) => (
            <RateTable.Text className="rate-table_rate" key={id} symbol={getCurrencyText(currency)}>
              {value}
            </RateTable.Text>
          ))}
        </RateTable.Row>
        {targetRates.length !== 0 && (
          <RateTable.Row>
            <RateTable.Title className="rate-table_title" active={false}>
              {t('targetRate')}
            </RateTable.Title>
            {targetRates.map(({ value, id, currency }) => (
              <RateTable.Text
                className="rate-table_rate"
                key={id}
                symbol={getCurrencyText(currency)}
              >
                {value}
              </RateTable.Text>
            ))}
          </RateTable.Row>
        )}
        {showRatesInput && (
          <React.Fragment>
            <RateTable.Row>
              <RateTable.Title className="rate-table_title" active={false}>
                {t('newSupplierRate')}
              </RateTable.Title>
              {proposedRates.map((rate, index) => {
                const rateObject = ratesValue[index];
                const value = getProperty(rateObject, 'value', 0);
                const id = getProperty(rateObject, 'id', index);
                return (
                  <RateTable.Input
                    id={id.toString()}
                    className="rate-table_rate-input"
                    key={id}
                    symbol={getCurrencyText(rate.currency)}
                    value={value}
                    onChange={newValue => {
                      onChange({
                        ...ratesValue,
                        [index]: { value: newValue, id: index, startDate: rate.startDate },
                      });
                    }}
                    highlight
                    error={error}
                  >
                    {value}
                  </RateTable.Input>
                );
              })}
            </RateTable.Row>
          </React.Fragment>
        )}
      </RateTable>
      {showRatesInput && (
        <LinkButton onClick={useProposedRates} className="rate-table_link">
          {t('useProposedRates')}
        </LinkButton>
      )}
    </div>
  );
};

Rates.propTypes = propTypes;
Rates.defaultProps = defaultProps;

export default Rates;
