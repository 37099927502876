// helpers
import { getFiltersByDueDate } from 'helpers/Filter';
// constants
import { SUBMISSION_STATUS_FILTERS } from 'constants/filters';
import {
  SUPPLIER_ROLE,
  STAFF_ROLE,
  MANAGER_ROLE,
  SUPPLIER_IBM,
  SUPPLIER_DIRECT,
  SUPPLIER_NOT_DIRECT,
} from 'constants';
import { SUBMISSION_STATUS } from 'constants/submissionStatus';
import {
  ONBOARDING_STATUS_DATE,
  ONBOARDING_STATUS_FORMS,
  ONBOARDING_STATUS_PO,
} from 'constants/onboarding';

export const getFilterForDueDate = (options, role) => {
  if (role === SUPPLIER_ROLE) {
    return { position: getFiltersByDueDate(options, 'supplierDueDate') };
  }

  return { position: getFiltersByDueDate(options) };
};

/**
 * @param {string} project
 */
export const getFilterForProject = project => {
  return { position: { project: { name: { equals: project } } } };
};

/**
 * @param {string} city
 */
export const getFilterForLocation = city => {
  return { position: { location: { some: { city: { equals: city } } } } };
};

/**
 * @param {string} partner Partner id
 */
export const getFilterForPartner = partner => {
  return {
    supplier: {
      company: {
        name: {
          equals: partner,
        },
      },
    },
  };
};

const submissionStatusFilter = (status, finished) => {
  const statuses = Array.isArray(status) ? status : [status];

  return {
    AND: [
      {
        name: {
          in: statuses,
        },
      },
      {
        end: {
          is_empty: !finished,
        },
      },
    ],
  };
};

const submissionStatus = (status, finished = false) => {
  return {
    statuses: {
      some: submissionStatusFilter(status, finished),
    },
  };
};

/**
 * Filter by submissions by position RFQ or title, or candidate name
 * @param {string} searchText
 */
export const getFilterForSearchText = searchText => {
  return {
    OR: [
      { name: { contains: searchText } },
      { email: { contains: searchText } },
      { phone: { contains: searchText } },
      {
        position: {
          OR: [{ title: { contains: searchText } }, { rfq: { name: { contains: searchText } } }],
        },
      },
    ],
  };
};

/**
 * Filter rules https://ibm.ent.box.com/file/729721655801
 * @param {string[]} statuses
 * @param {string} role
 */
export const getFilterForStatuses = (statuses, role) => {
  const filters = [];

  statuses.forEach(status => {
    // New
    if (status === SUBMISSION_STATUS_FILTERS.NEW) {
      if (role === MANAGER_ROLE) {
        filters.push(submissionStatus(SUBMISSION_STATUS.SUBMITTED));
      } else {
        filters.push(submissionStatus(SUBMISSION_STATUS.NEW));
      }
    }

    // Submitted to client
    if (status === SUBMISSION_STATUS_FILTERS.SUBMITTED_TO_CLIENT) {
      filters.push(submissionStatus(SUBMISSION_STATUS.SUBMITTED));
    }

    // Not submitted to client
    if (status === SUBMISSION_STATUS_FILTERS.NOT_SUBMITTED_TO_CLIENT) {
      filters.push(submissionStatus(SUBMISSION_STATUS.NOT_SUBMITTED));
    }

    // Not selected by client
    if (status === SUBMISSION_STATUS_FILTERS.NOT_SELECTED_BY_CLIENT) {
      filters.push(submissionStatus(SUBMISSION_STATUS.NOT_SELECTED));
    }

    // Withdrawn
    if (status === SUBMISSION_STATUS_FILTERS.WITHDRAWN) {
      filters.push(submissionStatus(SUBMISSION_STATUS.WITHDRAWN));
    }

    // Offer
    if (status === SUBMISSION_STATUS_FILTERS.OFFER) {
      filters.push(submissionStatus(SUBMISSION_STATUS.OFFER));
    }

    // Onboarding
    if (status === SUBMISSION_STATUS_FILTERS.ONBOARDING) {
      filters.push(submissionStatus(SUBMISSION_STATUS.ONBOARDING));
    }

    // Onboarding - Start date Pending
    if (status === SUBMISSION_STATUS_FILTERS.PENDING_START_DATE) {
      const startDateFilter = [submissionStatus(SUBMISSION_STATUS.ONBOARDING)];

      if (role === SUPPLIER_ROLE) {
        startDateFilter.push(submissionStatus(ONBOARDING_STATUS_DATE.PROPOSE));
      }
      if (role === STAFF_ROLE) {
        startDateFilter.push(submissionStatus(ONBOARDING_STATUS_DATE.SUBMIT));
      }
      if (role === MANAGER_ROLE) {
        startDateFilter.push(submissionStatus(ONBOARDING_STATUS_DATE.APPROVE));
      }

      filters.push({ AND: startDateFilter });
    }

    // Onboarding - Start date confirmed by PM
    if (status === SUBMISSION_STATUS_FILTERS.CONFIRMED_START_DATE) {
      filters.push({
        AND: [
          submissionStatus(SUBMISSION_STATUS.ONBOARDING),
          submissionStatus(ONBOARDING_STATUS_DATE.APPROVE, true),
        ],
      });
    }

    // Onboarding - Forms pending
    if (status === SUBMISSION_STATUS_FILTERS.PENDING_FORMS) {
      if (role === SUPPLIER_ROLE) {
        filters.push({
          AND: [
            submissionStatus(SUBMISSION_STATUS.ONBOARDING),
            submissionStatus(ONBOARDING_STATUS_FORMS.SUBMIT),
          ],
        });
      } else {
        // For NOT Direct partner we require to submit forms to both PM and Ausy
        const pendingFormsFilter = {
          OR: [
            {
              AND: [
                {
                  supplier: {
                    category: {
                      equals: SUPPLIER_NOT_DIRECT,
                    },
                  },
                },
                submissionStatus(SUBMISSION_STATUS.ONBOARDING),
                {
                  OR: [
                    submissionStatus(ONBOARDING_STATUS_FORMS.SEND_TO_AUSY),
                    submissionStatus(ONBOARDING_STATUS_FORMS.SEND_TO_PM),
                  ],
                },
              ],
            },
            // For Direct partner require to submit form only to PM
            {
              AND: [
                {
                  supplier: {
                    category: {
                      in: [SUPPLIER_DIRECT, SUPPLIER_IBM],
                    },
                  },
                },
                submissionStatus(SUBMISSION_STATUS.ONBOARDING),
                submissionStatus(ONBOARDING_STATUS_FORMS.SEND_TO_PM),
              ],
            },
          ],
        };

        // MM role includes condition when partner has not submitted the forms yet
        if (role === STAFF_ROLE) {
          pendingFormsFilter.OR.push({
            AND: [
              submissionStatus(SUBMISSION_STATUS.ONBOARDING),
              submissionStatus(ONBOARDING_STATUS_FORMS.SUBMIT),
            ],
          });
        }

        filters.push(pendingFormsFilter);
      }
    }

    // Onboarding - Pending PO
    if (status === SUBMISSION_STATUS_FILTERS.PENDING_PO) {
      const poFilter = [submissionStatus(SUBMISSION_STATUS.ONBOARDING)];

      if (role === SUPPLIER_ROLE) {
        poFilter.push(submissionStatus(ONBOARDING_STATUS_PO.CONFIRM));
      }
      if (role === STAFF_ROLE) {
        poFilter.push({
          OR: [
            submissionStatus(ONBOARDING_STATUS_PO.RELEASE),
            submissionStatus(ONBOARDING_STATUS_PO.CONFIRM),
          ],
        });
      }
      if (role === MANAGER_ROLE) {
        poFilter.push(submissionStatus(ONBOARDING_STATUS_PO.RELEASE));
      }

      filters.push({ AND: poFilter });
    }

    // Onboarding Completed
    if (status === SUBMISSION_STATUS_FILTERS.ONBOARDING_COMPLETED) {
      filters.push({
        AND: [
          submissionStatus(SUBMISSION_STATUS.ONBOARDING),
          submissionStatus(ONBOARDING_STATUS_DATE.APPROVE, true),
          submissionStatus(ONBOARDING_STATUS_PO.CONFIRM, true),
          {
            OR: [
              {
                AND: [
                  {
                    supplier: {
                      category: {
                        equals: SUPPLIER_NOT_DIRECT,
                      },
                    },
                  },
                  submissionStatus(ONBOARDING_STATUS_FORMS.SEND_TO_AUSY, true),
                  submissionStatus(ONBOARDING_STATUS_FORMS.SEND_TO_PM, true),
                ],
              },
              {
                AND: [
                  {
                    supplier: {
                      category: {
                        in: [SUPPLIER_IBM, SUPPLIER_DIRECT],
                      },
                    },
                  },
                  submissionStatus(ONBOARDING_STATUS_FORMS.SEND_TO_PM, true),
                ],
              },
            ],
          },
        ],
      });
    }

    // Onboarding Cancelled
    if (status === SUBMISSION_STATUS_FILTERS.ONBOARDING_CANCELLED) {
      filters.push(submissionStatus(SUBMISSION_STATUS.ONBOARDING_CANCELLED));
    }

    // Delivery
    if (status === SUBMISSION_STATUS_FILTERS.DELIVERY) {
      filters.push(submissionStatus(SUBMISSION_STATUS.DELIVERY));
    }
  });

  return { OR: filters };
};
