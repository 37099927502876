import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// helpers
import { getInitials } from 'helpers/StringParser';
import { getColorFromLetters } from 'helpers/Colors';
// constants
import { COLOR_BLUE, COLOR_GREEN, COLOR_RED, COLOR_YELLOW, COLOR_GRAY } from 'constants/colors';
// styles
import styles from './avatar.module.scss';

const propTypes = {
  children: PropTypes.any,
  name: PropTypes.string,
  color: PropTypes.oneOf(['', COLOR_BLUE, COLOR_GREEN, COLOR_RED, COLOR_YELLOW, COLOR_GRAY]),
  size: PropTypes.number,
};

const defaultProps = {
  children: null,
  name: '',
  color: '',
  size: 25,
};

const Avatar = ({ children, name, color, size }) => {
  const style = {
    fontSize: size,
    width: size + 20,
    height: size + 20,
  };

  if (!name && !children) {
    return null;
  }

  if (!name && children) {
    return (
      <div className={classNames(styles.avatar, styles[color || COLOR_GREEN])} style={style}>
        {children}
      </div>
    );
  }

  const initials = getInitials(name);
  const avatarColor = color || getColorFromLetters(initials);

  return (
    <div className={classNames(styles.avatar, styles[avatarColor])} style={style}>
      {initials}
    </div>
  );
};

Avatar.propTypes = propTypes;
Avatar.defaultProps = defaultProps;

export default Avatar;
