/* eslint-disable react/no-array-index-key */
import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// components
import RateTable from 'components/molecules/RateTable';

const propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  years: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
};

const ListHeader = ({ title, years }) => {
  const { t } = useTranslation();

  return (
    <RateTable bordered={false} className="positionCandidates_list_header">
      <RateTable.Row>
        <RateTable.Head align="left" className="positionCandidates_list_title">
          {title}
        </RateTable.Head>
        <RateTable.Head align="left" className="positionCandidates_list_type">
          {t('rateTypes')}
        </RateTable.Head>
        {years.map((year, index) => (
          <RateTable.Head key={index}>{years.length > 1 && year}</RateTable.Head>
        ))}
      </RateTable.Row>
    </RateTable>
  );
};

ListHeader.propTypes = propTypes;

export default ListHeader;
