import { InMemoryCache } from '@apollo/client';
// local states
import { reactiveVar as loggedInUserVar } from 'localStates/loggedInUser';
// constants
import { OFFSET_PAGINATION_LIST_TYPES, LIST_TYPE } from 'constants/dataTypes';
// downloaded in 'pre-build' task which is executed before 'npm start' or 'npm run build'
// eslint-disable-next-line import/no-unresolved
import possibleTypes from '../../../assets/fragmentTypes.json';

const infiniteScrollPagination = () => {
  return {
    keyArgs: ['filter', 'sort', 'orderBy'],
    merge: (existing = {}, incoming = {}, { args: { skip = 0 } }) => {
      const incomingItems = incoming.items || [];
      const existingItems = existing.items || [];

      if (!incomingItems.length) {
        return existing;
      }

      return {
        ...existing,
        count: incoming.count || existing.count,
        items: [...existingItems.slice(0, skip), ...incomingItems],
      };
    },
  };
};

/**
 * @param {string[]} keyFields
 */
const cursorBasedPagination = keyFields => {
  return {
    keyArgs: keyFields || false,
    merge: (existing = {}, incoming = {}, { readField, args }) => {
      const incomingItems = incoming.items || [];
      const existingItems = existing.items || [];
      const cursorIndex = existingItems.findIndex(item => readField('id', item) === args.after);
      const slicedItems =
        cursorIndex > -1 ? existingItems.slice(0, cursorIndex + 1) : existingItems;

      if (!existingItems.length) {
        return incoming;
      }

      return {
        ...existing,
        cursor: incoming.cursor,
        items: [...slicedItems, ...incomingItems],
      };
    },
  };
};

const cache = new InMemoryCache({
  possibleTypes,
  typePolicies: {
    Query: {
      fields: {
        email: {
          read: () => loggedInUserVar().email,
        },
        role: {
          read: () => loggedInUserVar().role,
        },
        [LIST_TYPE.NOTIFICATION]: cursorBasedPagination(),
        // build type policies for infinite scrolling pagination
        ...OFFSET_PAGINATION_LIST_TYPES.reduce((list, type) => {
          return {
            ...list,
            [type]: infiniteScrollPagination(),
          };
        }, {}),
      },
    },
    SubscriberEvent: {
      keyFields: ['event'],
    },
  },
});

export default cache;
