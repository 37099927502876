import i18n from 'i18next';

export const STEPS = {
  SELECTION: 'selection',
  SELL_RATES: 'sellRates',
  DOCUMENTS: 'documents',
  REVIEW: 'review',
  EMAIL: 'email',
};

export const TABS = [
  {
    id: STEPS.SELECTION,
    label: i18n.t('selection'),
  },
  {
    id: STEPS.SELL_RATES,
    label: i18n.t('sellRates'),
  },
  {
    id: STEPS.DOCUMENTS,
    label: i18n.t('attachments'),
  },
  {
    id: STEPS.REVIEW,
    label: i18n.t('review'),
  },
  {
    id: STEPS.EMAIL,
    label: i18n.t('email'),
  },
];

export const DOCUMENT_TYPE = {
  CV: 'CV',
  ATTESTATION: 'Attestation',
};

export const SORT_BY = {
  SCORE: 'score',
  NAME: 'name',
  RATE: 'rate',
};

export const ORDER = {
  ASC: 'asc',
  DESC: 'desc',
};
