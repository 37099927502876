// constants
import { SUBMISSION_FILTERS } from 'constants/filters';
import {
  SORT_SCORE,
  SORT_CV_DATE,
  SORT_DUE_DATE,
  SORT_AVAILABILITY,
  SUPPLIER_ROLE,
} from 'constants';
// helpers
import { getParsedSearchText } from 'helpers/Position';

/**
 * Format filters
 * @param {string} searchText
 * @param {object} filteredBy
 * @param {object} user
 * @param {string} user.role
 * @param {string} user.supplierId
 * @param {string[]} user.projects
 * @param {object[]} user.clients
 */
export const formatFilters = (searchText, filteredBy, user) => {
  const filter = {
    location: filteredBy[SUBMISSION_FILTERS.LOCATION],
    project: filteredBy[SUBMISSION_FILTERS.PROJECT],
    partner: filteredBy[SUBMISSION_FILTERS.PARTNER],
    dueDates: filteredBy[SUBMISSION_FILTERS.DUE_DATE],
    statuses: filteredBy[SUBMISSION_FILTERS.STATUS],
  };

  // if there's search text typed that has at least 3 characters
  if (searchText && searchText.length > 2) {
    const newSearchText = getParsedSearchText(searchText, user.clients);

    if (newSearchText.length > 2) {
      filter.fullText = newSearchText;
    }
  }

  return filter;
};

export const formatSorts = (sortedBy, role) => {
  switch (sortedBy.id) {
    case SORT_DUE_DATE:
      return role === SUPPLIER_ROLE
        ? { position: { supplierDueDate: 'DESC' } }
        : { position: { dueOn: 'DESC' } };
    case SORT_AVAILABILITY:
      return { availability: { start: 'ASC' } };
    case SORT_CV_DATE:
      return { submissionDate: 'DESC' };
    case SORT_SCORE:
      return { score: 'DESC' };
    default:
      return { score: 'DESC' };
  }
};
