import { gql } from '@apollo/client';
import i18n from 'i18next';
import getProperty from 'lodash/get';
import union from 'lodash/union';
import moment from 'moment';
// services
import client from 'services/Client';
import logger from 'services/Logger';
// queries
import { PositionStatus, RevisionStatus, SubmissionStatus } from 'queries/Fragments';
// constants
import { SUBMISSION_STATUS } from 'constants/submissionStatus';
import { POSITION_STATUS } from 'constants/positionStatus';
import { REVISION_STATUS, REVISION_TYPES, STAFF_ROLE } from 'constants';
// helpers
import { getSubmissionCurrentStatus } from './Submission';

export const getRevisionStatus = statuses => statuses.find(item => !item.end);

export const getOpenRevisions = revisions => {
  return revisions.filter(revision => {
    const statuses = getProperty(revision, 'statuses.items', []);
    const revisionStatus = getRevisionStatus(statuses);

    if (!revisionStatus) return false;

    return revisionStatus.status !== REVISION_STATUS.COMPLETED;
  });
};

export const getOpenRevisionTypes = revisions => {
  const openRevisions = getOpenRevisions(revisions);

  if (!openRevisions) return [];

  return openRevisions.reduce((openRevisionTypes, revision) => {
    const revisionTypes = getProperty(revision, 'types.items', []);

    return union(openRevisionTypes, revisionTypes);
  }, []);
};

/**
 * @param {string} positionStatus
 * @param {string} candidateStatus
 * @param {object[]} revisions
 * @returns {boolean}
 */
export const isRevisable = (positionStatus, candidateStatus, revisions) => {
  const openRevisionTypes = getOpenRevisionTypes(revisions);
  const allowedNumberOfRevisions = Object.keys(REVISION_TYPES).length;

  if (!positionStatus || !candidateStatus || !openRevisionTypes) return false;

  if (
    [POSITION_STATUS.PUBLISHED, POSITION_STATUS.REPLACEMENT].includes(positionStatus) &&
    candidateStatus === SUBMISSION_STATUS.NEW &&
    openRevisionTypes.length < allowedNumberOfRevisions
  ) {
    return true;
  }

  return false;
};

const IS_REVISABLE = gql`
  query isRevisable($id: ID!) {
    submission(id: $id) {
      id
      statuses {
        items {
          ...SubmissionStatus
        }
      }
      position {
        id
        statuses {
          items {
            ...PositionStatus
          }
        }
      }
      revisions {
        items {
          id
          statuses {
            items {
              ...RevisionStatus
            }
          }
        }
      }
    }
  }
  ${SubmissionStatus}
  ${PositionStatus}
  ${RevisionStatus}
`;

export const isRevisableAsync = async candidateId => {
  try {
    const response = await client.query({
      query: IS_REVISABLE,
      variables: { id: candidateId },
      fetchPolicy: 'network-only',
    });

    const submission = getProperty(response, 'data.submission');
    const positionStatuses = getProperty(submission, 'position.statuses.items', []);
    const positionStatus = positionStatuses.find(status => !status.end);
    const statuses = getProperty(submission, 'statuses.items', []);
    const revisions = getProperty(submission, 'revisions.items', []);
    const candidateStatus = getSubmissionCurrentStatus(statuses);

    return isRevisable(positionStatus.name, candidateStatus.name, revisions);
  } catch (error) {
    logger.exception(error, { candidateId });
    throw error;
  }
};

const getStatuses = revisions => {
  const statuses = [];

  revisions.forEach(revision => {
    const statusesData = getProperty(revision, 'statuses.items', []);
    const revisionStatus = getRevisionStatus(statusesData);

    statuses.push(revisionStatus);
  });

  return statuses;
};

const getCurrentStatus = (revisions, role) => {
  const statuses = getStatuses(revisions);
  const completed = statuses.every(({ status }) => status === REVISION_STATUS.COMPLETED);
  const inProgress = statuses.some(({ status }) => status === REVISION_STATUS.IN_PROGRESS);
  const pending = statuses.some(({ status }) => status === REVISION_STATUS.PENDING);

  if (completed) return REVISION_STATUS.COMPLETED;
  if (inProgress && role === STAFF_ROLE) return REVISION_STATUS.PENDING;
  if (inProgress) return REVISION_STATUS.IN_PROGRESS;
  if (pending) return REVISION_STATUS.PENDING;

  return '';
};

const getRevisionsStatus = (revisions, role) => {
  if (revisions.length === 0) return '';

  const openRevisions = getOpenRevisions(revisions);
  if (openRevisions.length > 0) return getCurrentStatus(openRevisions, role);

  return REVISION_STATUS.COMPLETED;
};

/**
 * @param {object[]} revisions - all revisions of the candidate open or closed
 * @param {string} role - current role of the user
 * @returns {string} revision status
 */
export const getRevisionsStatusInfoText = (revisions = [], role) => {
  const status = getRevisionsStatus(revisions, role);

  if (status === REVISION_STATUS.PENDING) {
    return i18n.t('revisionPending');
  }
  if (status === REVISION_STATUS.IN_PROGRESS) {
    return i18n.t('revisionInProgress');
  }

  return '';
};

export const getUpdateStatusObject = (oldStatusId, newStatus) => {
  const now = moment().toISOString();
  return {
    update: {
      filter: {
        id: oldStatusId,
      },
      data: {
        end: now,
      },
    },
    create: {
      start: now,
      status: newStatus,
    },
  };
};

export const isReviseActionBlocked = (status, createdById, userId) => {
  return status === REVISION_STATUS.IN_PROGRESS && createdById !== userId;
};
