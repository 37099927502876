// TODO: Replace momentJS with luxon
import moment from 'moment-business-days';
import momentTimezone from 'moment-timezone';
import { t } from 'i18next';

export const getRelativeTime = (date, zeroHours) => {
  if (zeroHours) {
    return moment(date)
      .hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0)
      .fromNow();
  }
  return moment(date).fromNow();
};

export const getPassedRelativeTime = (date, zeroHours) => {
  return getRelativeTime(date, zeroHours);
};

export const getFutureRelativeTime = (date, zeroHours) => {
  // const laterDate = new Date(date);
  // const earlierDate = new Date();
  // earlierDate.setHours(0, 0, 0, 0);
  // if (laterDate.getTime() < earlierDate.getTime()) {
  //   return 'as soon as possible';
  // }
  return getRelativeTime(date, zeroHours);
};

const timezoneFormat = 'DD MMM YYYY, HH:mm [(UTC]Z[)]';

export const getTimezoneOffsetTime = (time, timezone) => {
  return momentTimezone(time)
    .tz(timezone)
    .format(timezoneFormat);
};

export const getTimezoneTime = time => {
  return moment(time).format(timezoneFormat);
};

export const getReadableDateTime = time => {
  return moment(time).format('DD MMM, HH:mm');
};

export const getReadableYearDateTime = time => {
  return moment(time).format('DD MMM YYYY, HH:mm');
};

export const getTime = time => {
  return moment(time).format('DD MMM');
};

export const getTimeYear = time => {
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(new Date(time))) {
    return time;
  }
  return moment(time).format('DD MMM, YYYY');
};

/**
 * @param {string|moment} time
 * @returns {number}
 */
export const getYear = time => {
  if (moment.isMoment(time)) {
    return time.year();
  }

  const momentTime = time ? moment(time) : moment();

  return momentTime.year();
};

export const getCurrentYear = () => {
  return moment().year();
};

export const getFormattedTimeFromDuration = duration => {
  if (!duration) return duration;

  const durationFormatted = moment.duration(duration, 'months');
  const durationYears = durationFormatted.years();
  const durationMonths = durationFormatted.months();

  if (!durationYears && durationMonths) return `${Math.floor(durationMonths)} ${t('months')}`;
  if (!durationMonths && durationYears) return `${Math.floor(durationYears)} ${t('years')}`;
  if (!durationYears && !durationMonths) return 'N/A';

  return `${Math.floor(durationYears)}${t('yearAbbreviation')} ${Math.floor(durationMonths)}${t(
    'monthAbbreviation'
  )}`;
};

export const isToday = date => {
  const formattedDate = moment(date).format('YYYY-MM-DD');
  const formattedToday = moment().format('YYYY-MM-DD');
  return moment(formattedDate).isSame(formattedToday);
};

export const isYesterday = date => {
  const formattedDate = moment(date).format('YYYY-MM-DD');
  const formattedYesterday = moment()
    .subtract(1, 'days')
    .format('YYYY-MM-DD');
  return moment(formattedDate).isSame(formattedYesterday);
};

export const isLast7Days = date => {
  const formattedDate = moment(date).format('YYYY-MM-DD');
  const formattedLast7Days = moment()
    .subtract(7, 'days')
    .format('YYYY-MM-DD');
  return moment(formattedDate).isAfter(formattedLast7Days);
};

const DAYS = [
  t('Monday'),
  t('Tuesday'),
  t('Wednesday'),
  t('Thursday'),
  t('Friday'),
  t('Saturday'),
  t('Sunday'),
];

export const getDayLabel = date => {
  return DAYS[moment(date).isoWeekday() - 1];
};

export const getYearDate = year => moment([year, 1, 1]).format('YYYY-MM-DD');

export const formatDate = date => moment(date).format('YYYY-MM-DD');

/**
 * Format position period of performance
 * @param {string} start Position start date
 * @param {string} end Position end date
 */
export const getFormattedDuration = (start, end) => {
  return `${getTimeYear(start)}${start ? ` - ${getTimeYear(end)}` : ''}`;
};
