/* eslint-disable import/order */
import React, { useState } from 'react';
// libraries
import { useQuery } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import getProperty from 'lodash/get';
// hooks
import useAuth from 'hooks/useAuth';
import useItemsCount from 'hooks/useItemsCount';
// constants
import { SUPPLIER_ROLE, CANDIDATE_TYPE, VIEW_TABLE, COUNT_SUBMISSIONS } from 'constants';
// helpers
import { addParam, removeParam, clearParams, parseParams } from 'helpers/QueryParams';
import { parseDataForCollapsedFilter, getSubmissionFiltersWhiteList } from './helpers';
// queries
import { GET_DRAFT_COUNT } from 'queries/DraftQueries';
// components
import DraftChip from 'components/molecules/DraftChip';
import CollapsedFilters from 'components/molecules/CollapsedFilters';
import SubmissionList from 'components/organisms/SubmissionList';
import Page from 'components/templates/Page';
// data
import SubmissionsFilter from './SubmissionsFilter';
import SORT_OPTIONS from './data/sort';

const SubmissionsPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { id, role, projects } = useAuth();
  const itemsCount = useItemsCount(COUNT_SUBMISSIONS);
  const [filtersOpen, setFiltersOpen] = useState(true);

  const userProjects = projects.map(project => project.name);
  const filter = parseParams(location, getSubmissionFiltersWhiteList(role, userProjects));
  const sortList = SORT_OPTIONS[role] || SORT_OPTIONS[SUPPLIER_ROLE];
  const draftCountResponse = useQuery(GET_DRAFT_COUNT, {
    variables: { userId: id, type: CANDIDATE_TYPE },
  });

  const toggleFilters = () => {
    setFiltersOpen(!filtersOpen);
  };

  const removeFilter = (type, value) => {
    removeParam(type, value, location, history);
  };

  const addFilter = (type, value) => {
    if (!value) {
      removeFilter(type);
    } else {
      addParam(type, value, location, history);
    }
  };

  const removeFilters = () => {
    clearParams(history, location);
  };

  return (
    <div className="display-flex-row h100">
      {filtersOpen && (
        <SubmissionsFilter
          filter={filter}
          onClose={toggleFilters}
          onChange={addFilter}
          onClear={removeFilters}
        />
      )}
      <div
        id="scrollableContent"
        className={classNames('scrollableContent', { panelOpen: filtersOpen })}
      >
        <Page
          headerName={t('submissions')}
          count={itemsCount}
          sortList={sortList}
          defaultSort={sortList[0]}
          noClearFilters
          tableView
          searchPlaceholder={t('searchCandidates')}
          className="h100 display-flex"
          pageHeaderClassName="page_header fs-unmask"
          customHeaderContent={
            role === SUPPLIER_ROLE && (
              <DraftChip
                type={CANDIDATE_TYPE}
                count={getProperty(draftCountResponse, 'data.draftsList.count', 0)}
                label={t('submission')}
              />
            )
          }
          content={
            <React.Fragment>
              <div className="flex_space page_filter fs-unmask">
                <CollapsedFilters
                  items={parseDataForCollapsedFilter(filter, role)}
                  onFilterClick={toggleFilters}
                  onClear={removeFilter}
                />
              </div>
              <div className="page_content">
                <SubmissionList filteredBy={filter} tableView={VIEW_TABLE} />
              </div>
            </React.Fragment>
          }
        />
      </div>
    </div>
  );
};

export default SubmissionsPage;
