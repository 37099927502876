import React, { useCallback } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// services
import logger from 'services/Logger';
// components
import Button from 'components/atoms/Button';
import ErrorPage from 'components/pages/ErrorPage';
import ContentLoaderPage from 'components/pages/ContentLoaderPage';
// constants
import { LAZY_PAGE_TYPE } from 'constants';

const propTypes = {
  error: PropTypes.object,
  pastDelay: PropTypes.bool.isRequired,
  timedOut: PropTypes.bool.isRequired,
  retry: PropTypes.func.isRequired,
};

const defaultProps = {
  error: null,
};

const Loading = ({ error, pastDelay, timedOut, retry }) => {
  const { t } = useTranslation();
  const errorContent = useCallback(
    title => {
      const handleClick = () => {
        if (!error) {
          retry();
        }

        // Error is mostly caused due to missing chunk which was replaced with new one
        // Hard refresh helps load the current (newest) chunks
        if (error.name === 'ChunkLoadError' || error.message.match('Loading CSS chunk')) {
          window.location.reload();
        } else {
          retry();
        }
      };

      return (
        <React.Fragment>
          <ErrorPage code={500} title={title} description={t('pageLoadingErrorDescription')} />

          <div className="center">
            <Button label={t('retry')} onClick={handleClick} />
          </div>
        </React.Fragment>
      );
    },
    [error]
  );

  if (error) {
    logger.exception(error);

    return errorContent(t('pageLoadingError'));
  }

  if (timedOut) {
    return errorContent(t('pageLoadingTimeOut'));
  }

  if (pastDelay) {
    return <ContentLoaderPage type={LAZY_PAGE_TYPE} amount={1} />;
  }

  return null;
};

Loading.propTypes = propTypes;
Loading.defaultProps = defaultProps;

export default Loading;
