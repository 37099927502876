// libraries
import i18n from 'i18next';
// helpers
import { roundNumber } from 'helpers/Number';
// constants
import { MATCH_TYPE } from './constants';

/**
 * @param {number} percentile
 * @returns {string}
 */
export const getMatchType = percentile => {
  const formattedPercentile = percentile ? roundNumber(percentile, 1) : percentile;

  if (formattedPercentile >= 75) {
    return MATCH_TYPE.EXCELLENT;
  }
  if (formattedPercentile >= 50 && formattedPercentile < 75) {
    return MATCH_TYPE.GOOD;
  }
  if (formattedPercentile >= 25 && formattedPercentile < 50) {
    return MATCH_TYPE.MODERATE;
  }
  if (formattedPercentile >= 0 && formattedPercentile < 25) {
    return MATCH_TYPE.WEAK;
  }

  return MATCH_TYPE.NONE;
};

/**
 * @param {string} type
 * @returns {string}
 */
export const getQualityByType = type => {
  switch (type) {
    case MATCH_TYPE.EXCELLENT:
      return i18n.t('matchMetricsExcellentRanking');
    case MATCH_TYPE.GOOD:
      return i18n.t('matchMetricsGoodRanking');
    case MATCH_TYPE.MODERATE:
      return i18n.t('matchMetricsModerateRanking');
    case MATCH_TYPE.WEAK:
      return i18n.t('matchMetricsWeakRanking');
    default:
      return i18n.t('matchMetricsNoRanking');
  }
};
