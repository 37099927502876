import React from 'react';
// libraries
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
// styles
import styles from './styledHtml.module.scss';

const propTypes = {
  html: PropTypes.string.isRequired,
};

const StyledHtml = ({ html }) => {
  // html "\n" with <br/> tag
  const enhancedHtml = html.replace(/\\n/g, '<br/>');
  const cleanedHtml = DOMPurify.sanitize(enhancedHtml, {
    USE_PROFILES: { html: true },
    FORBID_TAGS: ['style'],
    FORBID_ATTR: ['style', 'class'],
  });

  return <div className={styles.container}>{parse(cleanedHtml)}</div>;
};

StyledHtml.propTypes = propTypes;

export default StyledHtml;
