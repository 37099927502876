import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// helpers
import { prettyNumber } from 'helpers/Number';
// components
import Tooltip from 'components/atoms/Tooltip';
// styles
import styles from './rateText.module.scss';

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  currency: PropTypes.string.isRequired,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
};

const defaultProps = {
  value: '',
  className: '',
  error: false,
  errorMessage: '',
};

const RateText = ({ value, className, error, errorMessage, currency }) => {
  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.error]: error,
        [className]: className,
      })}
    >
      <Tooltip title={error ? errorMessage : ''}>
        <div className={styles.value}>{prettyNumber(value)}</div>
      </Tooltip>
      <div className={styles.currency}>{currency}</div>
    </div>
  );
};

RateText.propTypes = propTypes;
RateText.defaultProps = defaultProps;

export default RateText;
