import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontFamily: 'Lato-Bold',
        fontSize: 11,
      },
    },
  },
});

export default theme;
