/* eslint-disable import/order */
import React, { useState, useEffect, useContext, useRef } from 'react';
// libraries
import PropTypes from 'prop-types';
import shortId from 'shortid';
import { useTranslation } from 'react-i18next';
import getProperty from 'lodash/get';
import setProperty from 'lodash/set';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
// hooks
import useAuth from 'hooks/useAuth';
import useIsMounted from 'hooks/useIsMounted';
// context
import ToastContext from 'context/ToastContext';
// components
import ModalStepper from 'components/molecules/ModalStepper';
import Email from 'components/organisms/Email';
import RequestRevisionForm from './RequestRevisionForm';
// helpers
import {
  fetchSubmission,
  fetchPosition,
  validate,
  getErrorFromFormData,
  createRevision,
  buildTypesMessagePartForEmailSubject,
  buildRevisionHtmlPartForEmailBody,
} from './helpers';
import { getAbsoluteUrl } from 'helpers/Link';
import { buildEmailTemplate, hydrateTemplate } from 'helpers/Template';
// constants
import { REVISION_REQUEST } from 'constants/templates';
import { TABS, REVISION_MODAL_REVISIONS_TAB, REVISION_MODAL_EMAIL_TAB } from './constants';
// style
import './requestRevisionModal.scss';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  submissionId: PropTypes.string.isRequired,
  positionId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

const RequestRevisionModal = ({ isOpen, onClose, submissionId, positionId, projectId, name }) => {
  const { t } = useTranslation();
  const { id: userId, email: userEmail } = useAuth();
  const { addToast } = useContext(ToastContext);
  const isMounted = useIsMounted();
  const lastDynamicEmailParams = useRef();

  const [activeTab, setActiveTab] = useState(TABS[0].id);
  const [loading, setLoading] = useState(false);
  const [emailTemplate, setEmailTemplate] = useState({ subject: '', html: '' });
  const [rates, setRates] = useState({});
  const [openRevisionTypes, setOpenRevisionTypes] = useState([]);
  const [rateSimulationProps, setRateSimulationProps] = useState({});
  const [email, setEmail] = useState({ html: '', options: {}, error: false });
  const [showEmailError, setShowEmailError] = useState(false);

  const initId = shortId();
  const [formData, setFormData] = useState({
    [initId]: {
      id: initId,
      value: {
        note: { value: '', error: false },
        type: { value: '', error: false },
      },
      error: false,
      collapsed: false,
    },
  });

  useEffect(() => {
    const fetchEmailTemplate = () => {
      return buildEmailTemplate(REVISION_REQUEST, projectId).catch(() =>
        addToast.warning(t('emailTemplateNotFound'))
      );
    };

    const fetchData = async () => {
      try {
        setLoading(true);
        const [submissionData, positionData, templateData] = await Promise.all([
          fetchSubmission(submissionId),
          fetchPosition(positionId),
          fetchEmailTemplate(),
        ]);

        const {
          rates: candidateRates,
          revisionTypes,
          partnerCategory,
          includeCSAFee,
          partnerRates,
          submittedBy,
        } = submissionData;
        const {
          rfq,
          title,
          project,
          client,
          hours,
          nteRates,
          targetRates,
          years,
          enableManYearDiscount,
        } = positionData;
        const { html, subject } = templateData;
        const { currency } = candidateRates;
        // fixed params for email template
        const templateDataParams = {
          subscriber: submittedBy.name,
          rfq,
          position: title,
          project,
          client,
          candidate: name,
          link: getAbsoluteUrl(`/submission/${submissionId}`),
        };

        setEmailTemplate({
          html: hydrateTemplate(html, templateDataParams),
          subject: hydrateTemplate(subject, templateDataParams),
        });
        setEmail({
          ...email,
          options: {
            ...templateData.options,
            to: submittedBy.email,
            replyTo: userEmail,
          },
        });
        setRateSimulationProps({
          hours,
          enableManYearDiscount,
          nteRates,
          targetRates,
          partnerCategory,
          includeCSAFee,
          currency,
          years,
          partnerRates,
        });
        setRates(candidateRates);
        setOpenRevisionTypes(revisionTypes);
      } catch (error) {
        addToast.error(t('errorPlaceholderText'));
      } finally {
        setLoading(false);
      }
    };

    if (isOpen) {
      fetchData();
    }
  }, [isOpen]);

  const handleEmailChange = value => {
    const emailData = cloneDeep(email);

    setProperty(emailData, 'html', value);
    setEmail(emailData);
  };

  const handleEmailOptionsChange = (options, isValid) => {
    const emailData = cloneDeep(email);

    setProperty(emailData, 'options', options);
    if (email.isValid !== isValid) {
      setProperty(emailData, 'error', !isValid);
    }

    setEmail(emailData);
  };

  const handleClose = () => {
    if (isMounted()) {
      setFormData({
        [initId]: {
          id: initId,
          value: {
            note: { value: '', error: false },
            type: { value: '', error: false },
          },
          error: false,
          collapsed: false,
        },
      });
      onClose();
    }
  };

  const handleSubmit = async () => {
    try {
      // validate form data
      const validatedFormData = validate(formData);
      const formDataError = getErrorFromFormData(validatedFormData);

      if (formDataError) {
        setFormData(validatedFormData);
        addToast.error(formDataError);
        return;
      }

      // validate email options
      if (email.error) {
        setShowEmailError(true);
        addToast.error(t('invalidEmailOptions'));
        return;
      }

      setLoading(true);
      await createRevision(formData, submissionId, userId, email);
      handleClose();
      addToast.success(t('revisionRequested'));
    } catch (error) {
      addToast.error(t('errorPlaceholderText'));
      setLoading(false);
    }
  };

  const handleSwitch = tab => {
    // if form is not valid -> prevent transition to other steps
    if (activeTab === REVISION_MODAL_REVISIONS_TAB) {
      const validated = validate(formData);
      const error = getErrorFromFormData(validated);

      if (error) {
        setFormData(validated);
        addToast.error(t(error));

        return;
      }
    }

    // re-build email template when some template params have changed
    if (tab === REVISION_MODAL_EMAIL_TAB) {
      const dynamicEmailParams = Object.values(formData).map(data => ({
        type: getProperty(data, 'value.type.value', ''),
        reason: getProperty(data, 'value.note.value', ''),
        rates: getProperty(data, 'value.rates.value'),
      }));

      let ratesParams;
      const ratesType = dynamicEmailParams.find(item => item.rates);

      if (ratesType) {
        const { requiredError, ...proposedRatesObject } = getProperty(ratesType, 'rates', {});
        const proposedRates = Object.values(proposedRatesObject).map(({ value }) => value);
        ratesParams = {
          candidateName: name,
          currency: getProperty(rateSimulationProps, 'currency', ''),
          years: getProperty(rateSimulationProps, 'years', []),
          targetRates: getProperty(rateSimulationProps, 'targetRates', []),
          currentRates: getProperty(rateSimulationProps, 'partnerRates', []),
          proposedRates,
        };
      }

      if (!isEqual(dynamicEmailParams, lastDynamicEmailParams.current)) {
        lastDynamicEmailParams.current = dynamicEmailParams;

        const subjectParams = {
          types: buildTypesMessagePartForEmailSubject(dynamicEmailParams.map(({ type }) => type)),
        };
        const htmlParams = {
          revision: buildRevisionHtmlPartForEmailBody(dynamicEmailParams, ratesParams),
        };

        setEmail({
          ...email,
          options: {
            ...email.options,
            subject: hydrateTemplate(emailTemplate.subject, subjectParams),
          },
          html: hydrateTemplate(emailTemplate.html, htmlParams),
        });
      }
    }

    setActiveTab(tab);
  };

  const getContent = () => {
    return {
      [REVISION_MODAL_REVISIONS_TAB]: (
        <RequestRevisionForm
          rates={rates}
          loading={loading}
          openRevisionTypes={openRevisionTypes}
          rateSimulationProps={rateSimulationProps}
          formData={formData}
          setFormData={setFormData}
        />
      ),
      [REVISION_MODAL_EMAIL_TAB]: (
        <Email
          key={REVISION_MODAL_EMAIL_TAB}
          html={email.html}
          options={email.options}
          onChange={handleEmailChange}
          onOptionsChange={handleEmailOptionsChange}
          showErrors={showEmailError}
        />
      ),
    };
  };

  return (
    <ModalStepper
      isOpen={isOpen}
      title={t('requestRevisionHeader', { name })}
      tabs={TABS}
      loading={loading}
      content={getContent()}
      onClose={handleClose}
      onSubmit={handleSubmit}
      onSwitch={handleSwitch}
      activeTab={activeTab}
      submitDisabled={false}
      closeOnBackdrop={false}
    />
  );
};

RequestRevisionModal.propTypes = propTypes;

export default RequestRevisionModal;
