import Table from './Table';
import Row from './Row';
import Head from './Head';
import Body from './Body';
import Title from './Title';

Table.Row = Row;
Table.Head = Head;
Table.Body = Body;
Table.Title = Title;

export default Table;
