import React from 'react';
import PropTypes from 'prop-types';
import { personalData } from './Texts';

const propTypes = {
  tableDescription: PropTypes.string,
};

const defaultProps = {
  tableDescription: null,
};

const PersonalDataTable = ({ tableDescription }) => {
  return (
    <div className="m-t-10 technicalMeasuresTable">
      {tableDescription && (
        <div className="technicalMeasuresTable_description">{tableDescription}</div>
      )}
      {personalData.map(({ category, description, source }, index) => {
        return (
          <div
            className={`${
              index === 0
                ? 'technicalMeasuresTable_header'
                : 'personalDataTable_row_content technicalMeasuresTable_row_content m-t-2'
            } ${index > 0 && index % 2 === 0 ? 'technicalMeasuresTable_row_gray' : ''}`}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          >
            <div className="technicalMeasuresTable_column_first">{category}</div>
            <div className="personalDataTable_column_second technicalMeasuresTable_column_second">
              {description}
            </div>
            <div className="technicalMeasuresTable_column_third">{source}</div>
          </div>
        );
      })}
    </div>
  );
};

PersonalDataTable.propTypes = propTypes;
PersonalDataTable.defaultProps = defaultProps;

export default PersonalDataTable;
