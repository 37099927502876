import differenceBy from 'lodash/differenceBy';

export const filterList = (list, selected) => {
  if (selected && selected.length > 0) {
    if (list && list.length > 0) {
      return differenceBy(list, selected, 'value');
    }
  }
  return list;
};
