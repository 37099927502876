import React from 'react';
// libraries
import PropTypes from 'prop-types';
// styles
import styles from './detailPage.module.scss';

const propTypes = {
  children: PropTypes.any.isRequired,
};

const Aside = ({ children }) => {
  return <div className={styles.aside}>{children}</div>;
};

Aside.propTypes = propTypes;

export default Aside;
