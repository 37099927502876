/* eslint-disable import/order */
import React, { useContext, useState, useRef } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
// context
import ToastContext from 'context/ToastContext';
import PositionContext from '../../PositionContext';
import MultipliersContext from '../../MultipliersContext';
import FilterContext from '../FilterContext';
// hooks
import useModalProvider from 'hooks/useModalProvider';
import useIsMounted from 'hooks/useIsMounted';
import useAuth from 'hooks/useAuth';
// styles
import colors from 'dependencies/materialStyles/Colors';
// components
import IconDropdown from 'components/molecules/IconDropdown';
import { SubmitCandidates } from 'components/organisms/PositionSettings';
import { RejectSubmission, ReplaceCV, commitNewCv } from 'components/organisms/SubmissionSettings';
import RequestRevision from 'components/organisms/RequestRevisionModal';
// helpers
import { boxFileUrl } from 'helpers/Box';
import { openLink } from 'helpers/Link';
import { isRevisable, isRevisableAsync } from 'helpers/Revision';
import { getRateCardLink } from 'helpers/Common';
import { resetSubmissionStatus } from 'helpers/Submission';
// constants
import { SUBMISSION_STATUS } from 'constants/submissionStatus';

const ratePropType = PropTypes.shape({
  value: PropTypes.number,
  startDate: PropTypes.string.isRequired,
});

const propTypes = {
  submission: PropTypes.object.isRequired,
  sellPrices: PropTypes.arrayOf(ratePropType).isRequired,
};

const MODAL = {
  REJECT: 'reject',
  SUBMIT: 'submit',
  REVISION: 'requestRevision',
  REPLACE_CV: 'replaceCV',
};

const Settings = React.memo(({ submission, sellPrices }) => {
  const { t } = useTranslation();
  const { supplierId } = useAuth();
  const menuRef = useRef();
  const history = useHistory();
  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(false);
  const [replacingCV, setReplacingCV] = useState(false);

  const position = useContext(PositionContext);
  const { manYearDiscount } = useContext(MultipliersContext);
  const { addToast } = useContext(ToastContext);
  const { hideCandidate, sellRates } = useContext(FilterContext);
  const { isModalOpen, openModal, closeModal } = useModalProvider();

  const currentStatus = submission.getStatus();
  const positionStatus = position.getStatus();
  const sellPricesValue = sellPrices.map(sellPrice => sellPrice.value);
  const rateCardLink = getRateCardLink(
    sellPricesValue,
    manYearDiscount,
    position.id,
    submission.id
  );

  const onModalClose = () => {
    if (isMounted()) {
      closeModal();
    }
  };

  const handleRevisionClick = async () => {
    try {
      setLoading(true);
      const isCandidateRevisable = await isRevisableAsync(submission.id);

      if (!isCandidateRevisable) {
        addToast.warning(t('candidateRequestRevisionNotAllowed'));
        return;
      }

      openModal(MODAL.REVISION);
      menuRef.current.closeMenu();
    } catch (error) {
      addToast.error('errorPlaceholderText');
    } finally {
      setLoading(false);
    }
  };

  const handleViewCvClick = () => {
    const file = submission.getSubmissionFile();
    openLink(boxFileUrl(file.boxId));
  };

  const handleViewAttestationClick = () => {
    const attestation = submission.getAttestation();
    openLink(boxFileUrl(attestation.boxId));
  };

  const handleViewReportClick = () => {
    const reportId = submission.report.id;
    history.push(`/report/match/${reportId}`);
  };

  const handleReplaceCv = async newReportId => {
    try {
      setReplacingCV(true);

      await commitNewCv(submission.id, newReportId, supplierId);
      addToast.success(t('replaceCandidateCVSuccess'));
      closeModal();
    } catch (error) {
      addToast.error('errorPlaceholderText');
    } finally {
      setReplacingCV(false);
    }
  };

  const handleResetStatusClick = async () => {
    try {
      setLoading(true);
      await resetSubmissionStatus(submission.id, submission.statuses, submission.isProposal());
      addToast.success('Submission status was successfully reset');
    } catch (error) {
      addToast.error('errorPlaceholderText');
    } finally {
      setLoading(false);
    }
  };

  const menuItems = [
    {
      icon: 'hide',
      id: 'hideCandidate',
      label: t('hideSubmission'),
      onClick: () => hideCandidate(submission.id),
    },
    {
      icon: 'portrait',
      id: 'viewCV',
      label: position.isFixedPrice() ? t('viewProposal') : t('viewCv'),
      onClick: handleViewCvClick,
    },
    {
      icon: 'description',
      id: 'viewAttestation',
      onClick: handleViewAttestationClick,
    },
  ];

  if (!position.isFixedPrice()) {
    menuItems.push(
      {
        icon: 'analytics',
        id: 'viewMatchReport',
        onClick: handleViewReportClick,
      },
      {
        icon: 'attach',
        id: 'viewRateCard',
        onClick: () => openLink(rateCardLink),
      }
    );
  }

  if (currentStatus.value === SUBMISSION_STATUS.NEW) {
    menuItems.unshift({
      icon: 'cancel',
      id: 'rejectCandidate',
      label: t('rejectSubmission'),
      onClick: () => openModal(MODAL.REJECT),
    });
  }

  if (
    position.isTimeAndMaterials() &&
    isRevisable(positionStatus.value, currentStatus.value, submission.revisions)
  ) {
    menuItems.unshift({
      icon: 'revision',
      id: MODAL.REVISION,
      onClick: handleRevisionClick,
      stayOpenAfterClick: true,
      loading,
    });
  }

  if (currentStatus.value === SUBMISSION_STATUS.NEW) {
    menuItems.unshift({
      icon: 'addPerson',
      id: 'submitToClient',
      onClick: () => openModal(MODAL.SUBMIT),
    });

    if (position.isTimeAndMaterials()) {
      menuItems.unshift({
        id: 'replaceCV',
        icon: 'fileReplace',
        label: t('replaceSubmittedCv'),
        onClick: () => openModal(MODAL.REPLACE_CV),
      });
    }
  }

  if (
    [SUBMISSION_STATUS.SUBMITTED, SUBMISSION_STATUS.NOT_SUBMITTED].includes(currentStatus.value)
  ) {
    menuItems.push({
      icon: 'reset',
      id: 'resetStatus',
      label: t('resetStatus'),
      stayOpenAfterClick: true,
      onClick: handleResetStatusClick,
      loading,
    });
  }

  return (
    <div className="fs-unmask">
      <IconDropdown
        ref={menuRef}
        menuItems={menuItems}
        iconColor={colors.gray}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      />
      <RejectSubmission
        id={submission.id}
        name={submission.getName()}
        isOpen={isModalOpen(MODAL.REJECT)}
        isProposal={submission.isProposal()}
        statusId={currentStatus.id}
        statusValue={currentStatus.value}
        onClose={closeModal}
        onFinish={onModalClose}
        position={position}
      />
      {position.isTimeAndMaterials() && (
        <RequestRevision
          isOpen={isModalOpen(MODAL.REVISION)}
          onClose={closeModal}
          submissionId={submission.id}
          positionId={position.id}
          projectId={position.project.id}
          name={submission.getName()}
        />
      )}
      <SubmitCandidates
        isOpen={isModalOpen(MODAL.SUBMIT)}
        onClose={closeModal}
        positionId={position.id}
        submissionId={submission.id}
        sellRates={sellRates}
      />
      {position.isTimeAndMaterials() && (
        <ReplaceCV
          isOpen={isModalOpen(MODAL.REPLACE_CV)}
          submitting={replacingCV}
          submissionId={submission.id}
          matchReportId={submission.report.id}
          candidateName={submission.getName()}
          onClose={closeModal}
          onReplace={handleReplaceCv}
        />
      )}
    </div>
  );
});

Settings.propTypes = propTypes;

export default Settings;
