import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
};

const defaultProps = {
  className: '',
};

const Row = ({ className, children }) => {
  const classes = ['rateTable_row'];

  if (className) {
    classes.push(...className.split(' '));
  }

  return <div className={classes.join(' ')}>{children}</div>;
};

Row.propTypes = propTypes;
Row.defaultProps = defaultProps;

export default Row;
