import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
};

const defaultProps = {
  active: true,
  className: '',
};

const Title = ({ active, className, children }) => {
  const classes = ['rateTable_cell', 'rateTable-left', 'rateTable-title'];

  if (!active) {
    classes.push('rateTable-disabled');
  }
  if (className) {
    classes.push(...className.split(' '));
  }

  return <div className={classes.join(' ')}>{children}</div>;
};

Title.propTypes = propTypes;
Title.defaultProps = defaultProps;

export default Title;
