import React from 'react';
// libraries
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// helpers
import { getRoleText } from 'helpers/NameMapping';

const propTypes = {
  role: PropTypes.string,
  currentRole: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  isLast: PropTypes.bool,
};

const defaultProps = {
  role: null,
  currentRole: null,
  className: '',
  color: 'grayLight',
  isLast: true,
};

const ArrowRole = ({ role, currentRole, className, color, isLast }) => {
  const { t } = useTranslation();
  return (
    <div className={`${className} arrow_role ${color}`} style={isLast ? { marginRight: 0 } : null}>
      {role === currentRole ? t('yourAction') : getRoleText(role)}
    </div>
  );
};

ArrowRole.propTypes = propTypes;
ArrowRole.defaultProps = defaultProps;

export default ArrowRole;
