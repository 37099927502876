import { gql } from '@apollo/client';
import getProperty from 'lodash/get';
// services
import client from 'services/Client';
import { getCandidateMRI, getCandidate } from 'services/Report';
import logger from 'services/Logger';

const FETCH_DATA = gql`
  query fetchCandidateReplaceCvData($id: ID!) {
    candidate(id: $id) {
      id
      profile {
        id
        fullName
        email
        phone
      }
    }
  }
`;

const fetchCandidateData = async candidateId => {
  const response = await client.query({ query: FETCH_DATA, variables: { id: candidateId } });

  return {
    name: getProperty(response, 'data.candidate.profile.fullName', ''),
    email: getProperty(response, 'data.candidate.profile.email', ''),
    phone: getProperty(response, 'data.candidate.profile.phone', ''),
  };
};

const fetchCandidateShData = async shCandidateId => {
  const response = await getCandidate(shCandidateId);

  return {
    name: getProperty(response, 'person.name', ''),
    email: getProperty(response, 'person.email', ''),
    phone: getProperty(response, 'person.phone', ''),
  };
};

/**
 * @param {string} shCandidateId
 * @param {string} [candidateId]
 * @returns {Promise<object>}
 */
export const fetchData = async (shCandidateId, candidateId) => {
  try {
    const [mri, candidate] = await Promise.all([
      getCandidateMRI(shCandidateId),
      candidateId ? fetchCandidateData(candidateId) : fetchCandidateShData(shCandidateId),
    ]);

    return {
      mri,
      ...candidate,
    };
  } catch (error) {
    logger.exception(error, { candidateId });
    throw error;
  }
};
