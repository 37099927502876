import { gql } from '@apollo/client';
// fragments
import {
  File,
  Rate,
  PositionActor,
  SubmissionStatus,
  SubmissionRole,
  Note,
  PositionStatus,
  RevisionStatus,
  User,
  PositionRatesSettings,
  MatchMetric,
  ExperienceLevel,
  Profile,
} from 'queries/Fragments';

const PositionSubmission = gql`
  fragment PositionSubmission on Submission {
    id
    score
    submissionDate
    availability {
      id
      start
      leadTimeFromSelection
    }
    statuses(sort: { createdAt: DESC }) {
      items {
        ...SubmissionStatus
      }
    }
    rates(filter: { endDate: { is_empty: true } }) {
      items {
        ...Rate
      }
    }
    documents(sort: { createdAt: DESC }) {
      items {
        ...File
      }
    }
    submissionRoles {
      items {
        ...SubmissionRole
      }
    }
    reports(sort: { createdAt: DESC }) {
      items {
        id
      }
    }
    metrics {
      items {
        ...MatchMetric
      }
    }
    hiringProcess {
      id
      purchaseOrder {
        id
        end
      }
      dates {
        items {
          id
          date
          status
          type
        }
      }
    }
    revisions {
      items {
        id
        createdAt
        createdBy {
          ...User
        }
        types {
          items {
            id
            type
            reason {
              ...Note
            }
            rejectionNote {
              ...Note
            }
            status {
              ...RevisionStatus
            }
          }
        }
        statuses {
          items {
            ...RevisionStatus
          }
        }
        workInProgress
      }
    }
    candidate {
      id
      supplier {
        id
        category
        includeCSAFee
        company {
          id
          name
        }
      }
    }
    profile {
      ...Profile
    }
  }
  ${SubmissionStatus}
  ${Rate}
  ${File}
  ${Note}
  ${User}
  ${SubmissionRole}
  ${MatchMetric}
  ${RevisionStatus}
  ${Profile}
`;

const PositionProposal = gql`
  fragment PositionProposal on Proposal {
    id
    name
    email
    phone
    submissionDate
    clearance {
      id
      type {
        id
        name
      }
      validUntil
    }
    availability {
      id
      start
      leadTimeFromSelection
    }
    statuses(sort: { createdAt: DESC }) {
      items {
        ...SubmissionStatus
      }
    }
    rates(filter: { endDate: { is_empty: true } }) {
      items {
        ...Rate
      }
    }
    documents(sort: { createdAt: DESC }) {
      items {
        ...File
      }
    }
    roles {
      items {
        ...SubmissionRole
      }
    }
    supplier {
      id
      includeCSAFee
      category
      company {
        id
        name
      }
    }
  }
  ${SubmissionStatus}
  ${Rate}
  ${File}
  ${SubmissionRole}
`;

export const fragments = {
  fullInfo: gql`
    fragment FullPositionInfo on Position {
      id
      title
      description
      contractType
      deliverables {
        items {
          id
          start
          end
          description
          name
        }
      }
      rfq {
        id
        name
      }
      dueOn
      supplierDueDate
      project {
        id
        name
        currency
        config
        timezone
        levels(sort: { order: ASC }) {
          items {
            id
            order
            yearsOfExperience
            title
          }
        }
        client {
          id
          name
          config
        }
      }
      workArrangement {
        id
        arrangement
      }
      deliveryOption {
        id
        mode
        description
      }
      level {
        ...ExperienceLevel
      }
      location {
        items {
          id
          country {
            id
            name
          }
          city
        }
      }
      duration {
        id
        start
        end
        hours
      }
      report {
        id
        shPositionId
      }
      statuses(sort: { createdAt: DESC }) {
        items {
          ...PositionStatus
        }
      }
      actors {
        items {
          ...PositionActor
        }
      }
      documents(sort: { createdAt: DESC }) {
        items {
          ...File
        }
      }
      notes(sort: { createdAt: DESC }) {
        items {
          ...Note
        }
      }
      requirements {
        items {
          id
          requirementType
          label
          description
          order
          importance
          percentage
          color
        }
      }
      evaluationMethod
      languages
      travel {
        id
        option
      }
      clearance {
        id
        name
      }
      rates(filter: { endDate: { is_empty: true } }) {
        items {
          ...Rate
        }
      }
      maximumCandidatesAllowed
      department {
        id
        name
      }
      submissions {
        count
        items {
          ...PositionSubmission
        }
      }
      proposals {
        count
        items {
          ...PositionProposal
        }
      }
      publishedToAll
      hasBeenPublishedTo {
        items {
          id
          company {
            id
            name
          }
        }
      }
      publishedTo {
        items {
          id
          company {
            id
            name
          }
        }
      }
      candidatesSubmissionProgress
      publishProgress
      priority
      shOccupations
      occupationTemplate {
        id
        name
      }
      ...PositionRatesSettings
    }
    ${PositionSubmission}
    ${PositionProposal}
    ${PositionActor}
    ${Rate}
    ${File}
    ${PositionStatus}
    ${Note}
    ${PositionRatesSettings}
    ${ExperienceLevel}
  `,
};

export const GET_POSITION_BY_ID = gql`
  query position($id: ID!) {
    position(id: $id) {
      ...FullPositionInfo
    }
  }
  ${fragments.fullInfo}
`;

export const GET_ACTORS_OF_TYPE = gql`
  query fetchActorsByRole($role: String, $positionId: ID!) {
    position(id: $positionId) {
      id
      actors(filter: { role: { equals: $role }, endDate: { is_empty: true } }) {
        items {
          ...PositionActor
        }
      }
    }
  }
  ${PositionActor}
`;
