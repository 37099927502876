/* eslint-disable import/order */
import React, { useState, useEffect, useContext, useRef } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import getProperty from 'lodash/get';
// context
import ToastContext from 'context/ToastContext';
// hooks
import useAuth from 'hooks/useAuth';
import useSubmissions from './useSubmissions';
// helpers
import parseSubmission from 'helpers/SubmissionParser';
import parseProposal from 'helpers/ProposalParser';
import { fetchPosition } from 'helpers/Position';
import { getCurrencyText } from 'helpers/NameMapping';
import { calculateManYearDiscount } from 'helpers/RatesCalculation';
import {
  initSellRates,
  getDefaultSellRates,
  getBoxFolder,
  getErrorMessages,
  areSellRatesValid,
  areAllReleasableDocumentsFilled,
  isNumberOfSelectedSubmissionsValid,
  initEmailTemplate,
  buildEmail,
  getEmailAttachments,
  commitSubmissions,
  sortSubmissions,
  saveProgress,
} from './helpers';
// components
import LinkButton from 'components/atoms/LinkButton';
import ModalStepper from 'components/molecules/ModalStepper';
import { Prompt } from 'components/molecules/Dialogs';
import Email from 'components/organisms/Email';
import Header from './Header';
import Selection from './Selection';
import SellRates from './SellRates';
import Documents from './Documents';
import Review from './Review';
// constants
import { SUBMISSION_STATUS } from 'constants/submissionStatus';
import { RATES_NTE_RATE } from 'constants';
import { TABS, STEPS, SORT_BY, ORDER } from './constants';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onFinish: PropTypes.func,
  positionId: PropTypes.string.isRequired,
  submissionId: PropTypes.string,
  sellRates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      rates: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.number,
          year: PropTypes.number.isRequired,
        })
      ),
    })
  ),
};

const defaultProps = {
  submissionId: '',
  sellRates: [],
  onFinish: null,
};

const SubmitCandidates = ({ isOpen, onClose, onFinish, positionId, submissionId, sellRates }) => {
  const { t } = useTranslation();
  const { addToast } = useContext(ToastContext);
  const user = useAuth();
  const emailTemplate = useRef();

  const [tabs, setTabs] = useState(TABS);
  const [activeTab, setActiveTab] = useState('');
  const [position, setPosition] = useState(null);
  const [fetchingDataError, setFetchingDataError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [email, setEmail] = useState('');
  const [emailOptions, setEmailOptions] = useState(null);
  const [isEmailOptionsValid, setEmailOptionsValid] = useState(false);
  const [sortBy, setSortBy] = useState(SORT_BY.SCORE);
  const [order, setOrder] = useState(ORDER.DESC);
  const [isPromptOpen, setPromptOpen] = useState(false);
  const [rateCard, setRateCard] = useState(null);

  // Handling list of selected submissions in a useReducer is easier and more predictable than in useState.
  // Updating selected submissions asynchronously with useState during multiple releasable documents upload
  // led to unpredictable and incorrect state update.
  const {
    submissions: selectedSubmissionsData,
    setSubmissions,
    updateSubmission,
    removeUnselected,
    getProgressData,
    commitProgress,
    hasProgress,
  } = useSubmissions(submissionId ? [{ id: submissionId }] : []);

  const isProposal = position ? position.isFixedPrice() : false;
  const allSubmissionsData = position ? position.getSubmissions() : [];
  const allSubmissions = allSubmissionsData.map(isProposal ? parseProposal : parseSubmission);
  const boxFolder = position ? getBoxFolder(position) : '';
  const rfqNumber = position ? position.rfq : '';
  const currency = getProperty(position, 'project.currency', '');
  const manYearDiscount = (() => {
    if (!position) {
      return 0;
    }

    const enableManYearDiscount = position.getConfig('enableManYearDiscount', false);

    return enableManYearDiscount ? calculateManYearDiscount(position.duration.hours) : 0;
  })();

  const positionData = {
    title: position ? position.title : '',
    rfq: position ? position.rfqLabel : '',
    project: position ? position.project.name : '',
    experienceLevel: position ? position.experienceLevel.value : '',
    occupations: position ? position.getOccupationsName() : [],
    nteRates: position ? position.getRates(RATES_NTE_RATE) : [],
    manYearDiscount,
    hours: getProperty(position, 'duration.hours', 0),
    currency: {
      value: currency,
      label: getCurrencyText(currency),
    },
  };

  const getSelectedSubmissions = () =>
    selectedSubmissionsData.reduce((list, selectedSubmissionData) => {
      const submission = allSubmissions.find(({ id }) => id === selectedSubmissionData.id);

      if (submission) {
        const defaultSellRates = getDefaultSellRates(submission, positionData.manYearDiscount);

        // merge submission data from DB with filled data like sell rates, documents etc.
        return [
          ...list,
          {
            ...submission,
            ...selectedSubmissionData,
            sellRates: selectedSubmissionData.sellRates || [],
            defaultSellRates,
          },
        ];
      }

      return list;
    }, []);

  const selectedSubmissions = getSelectedSubmissions(selectedSubmissionsData);
  const sortedSubmissions = sortSubmissions(selectedSubmissions, sortBy, order);
  const preparedSubmissions = selectedSubmissions.filter(submission => !submission.unselected);
  const maximumSubmissionsAllowed = position ? position.maximumCandidatesAllowed : 0;
  const submissionsMade = allSubmissions.filter(submission =>
    submission.hasStatus(SUBMISSION_STATUS.SUBMITTED)
  );
  const hasSavedSubmissions = selectedSubmissions.some(({ previouslySaved }) => previouslySaved);
  const errorMessages = getErrorMessages({
    isProposal,
    selectedSubmissions: preparedSubmissions,
    maximumAllowedSubmissions: maximumSubmissionsAllowed,
    numberOfSubmissionsMade: submissionsMade.length,
    rateCard,
  });

  useEffect(() => {
    const buildEmailTemplate = async (rfqNum, project, projectManagerEmail) => {
      try {
        const initedEmail = await initEmailTemplate(rfqNum, project, projectManagerEmail, user);

        emailTemplate.current = initedEmail.content;
        setEmail(initedEmail.content);
        setEmailOptions(initedEmail.options);
      } catch {
        addToast.error(t('errorPlaceholderText'));
        setFetchingDataError(true);
      }
    };

    const fetchPositionFn = async () => {
      try {
        setLoading(true);
        setLoadingText(t('fetchingDataLoader'));

        const fetchedPosition = await fetchPosition(positionId);
        if (fetchedPosition) {
          setPosition(fetchedPosition);
          const projectManager = fetchedPosition.getProjectManager();
          const projectManagerEmail = projectManager ? projectManager.email : '';
          const submissions = fetchedPosition
            .getSubmissions()
            .map(fetchedPosition.isFixedPrice() ? parseProposal : parseSubmission);
          const positionNteRates = fetchedPosition.getRates(RATES_NTE_RATE);
          const positionManYearDiscount = calculateManYearDiscount(fetchedPosition.duration.hours);

          // load and build email template asynchronously in background
          buildEmailTemplate(fetchedPosition.rfq, fetchedPosition.project.id, projectManagerEmail);

          // if position has saved progress for submissions and no pre-selected submission -> apply progress
          const savedProgress = fetchedPosition.submissionProgress;
          if (savedProgress && !submissionId) {
            // validate submissions in saved progress still have status "New". If not skip them
            const newSubmissions = submissions.filter(submission =>
              submission.hasStatus(SUBMISSION_STATUS.NEW)
            );
            const newSubmissionsId = newSubmissions.map(submission => submission.id);
            const submissionsFromProgress = getProperty(
              savedProgress,
              'submissions',
              []
            ).filter(submission => newSubmissionsId.includes(submission.id));

            if (submissionsFromProgress.length > 0) {
              const savedSubmissions = submissionsFromProgress.map(savedSubmission => {
                const submission = newSubmissions.find(({ id }) => savedSubmission.id === id);

                return {
                  ...savedSubmission,
                  ...submission,
                  // add flag that progress for submission was previously saved
                  previouslySaved: true,
                  // init sell rates (validate, and highligh error or changed rates)
                  sellRates: initSellRates(
                    submission,
                    savedSubmission.sellRates,
                    positionNteRates,
                    positionManYearDiscount
                  ),
                };
              });

              setSubmissions(savedSubmissions);
              commitProgress(submissionsFromProgress);
              setActiveTab(savedProgress.lastStep);
              setRateCard(savedProgress.rateCard);
            }
          } else {
            // if pre-selected submission -> init data for pre-selected submission
            selectedSubmissionsData.forEach(selectedSubmission => {
              if (!selectedSubmission.sellRates) {
                const submission = submissions.find(({ id }) => id === selectedSubmission.id);
                const submissionSellRates = sellRates.find(
                  ratesList => ratesList.id === submission.id
                );

                const providedSellRates = getProperty(submissionSellRates, 'rates');
                const initedSellRates = initSellRates(
                  submission,
                  providedSellRates,
                  positionNteRates,
                  positionManYearDiscount
                );

                updateSubmission(submission.id, 'sellRates', initedSellRates);
              }
            });
          }
        } else {
          addToast.error(t('errorPlaceholderText'));
          setFetchingDataError(true);
        }
      } catch (error) {
        addToast.error(t('errorPlaceholderText'));
        setFetchingDataError(true);
      } finally {
        setLoading(false);
        setLoadingText('');
      }
    };

    if (isOpen && !position && !loading) {
      fetchPositionFn();
    }
  }, [isOpen]);

  const hasTabError = id => tabs.find(tab => tab.id === id).error;

  const hasTabsError = tabsId => tabsId.some(tabId => hasTabError(tabId));

  const validateSteps = () => {
    const tabsWithError = [];

    // set/unset errors for steps
    if (!areSellRatesValid(preparedSubmissions)) {
      tabsWithError.push(STEPS.SELL_RATES);
    }
    if (!areAllReleasableDocumentsFilled(preparedSubmissions)) {
      tabsWithError.push(STEPS.DOCUMENTS);
    }
    if (
      !isNumberOfSelectedSubmissionsValid(
        preparedSubmissions.length,
        maximumSubmissionsAllowed,
        submissionsMade.length
      )
    ) {
      tabsWithError.push(STEPS.REVIEW);
    }
    if (isProposal && !rateCard) {
      tabsWithError.push(STEPS.DOCUMENTS);
    }

    setTabs(
      tabs.map(tab => ({
        ...tab,
        // don't revalidate email tab. It's handled in "handleSubmit"
        error: tab.id !== STEPS.EMAIL ? tabsWithError.includes(tab.id) : tab.error,
      }))
    );

    return !tabsWithError.length;
  };

  // validate data when selected submissions change and there is already some error tab
  useEffect(() => {
    if (selectedSubmissionsData.length > 0) {
      if (hasTabsError([STEPS.SELL_RATES, STEPS.DOCUMENTS, STEPS.REVIEW])) {
        validateSteps();
      }
    }
  }, [selectedSubmissionsData]);

  const handleSelection = selectedSubmissionsValue => {
    setSubmissions(selectedSubmissionsValue);

    // re-validate selection step -> at least 1 submission must be selected
    if (hasTabError(STEPS.SELECTION) && selectedSubmissionsValue.length > 0) {
      setTabs(tabs.map(tab => ({ ...tab, error: false })));
    }
  };

  const handleEmailOptionsChange = (newEmailOptions, isNewEmailOptionsValid) => {
    setEmailOptions(newEmailOptions);
    setEmailOptionsValid(isNewEmailOptionsValid);

    // re-validate email step after submission on each change
    if (submitted) {
      setTabs(
        tabs.map(tab => (tab.id === STEPS.EMAIL ? { ...tab, error: !isNewEmailOptionsValid } : tab))
      );
    }
  };

  const setSubmissionData = key => (id, value) => {
    updateSubmission(id, key, value);
  };

  const unselectSubmission = id => {
    updateSubmission(id, 'unselected', true);
  };

  const selectSubmission = id => {
    updateSubmission(id, 'unselected', false);
  };

  const handleSort = (sortByValue, orderValue) => {
    setSortBy(sortByValue);
    setOrder(orderValue);
  };

  const getContent = () => ({
    [STEPS.SELECTION]: (
      <Selection
        isProposal={isProposal}
        submissions={sortSubmissions(allSubmissions, sortBy, order)}
        onSelect={handleSelection}
        onSort={handleSort}
        position={{ ...positionData, currency: positionData.currency.label }}
        selectedSubmissions={selectedSubmissionsData}
        sellRates={sellRates}
        error={hasTabError(STEPS.SELECTION)}
        hasSavedSubmissions={hasSavedSubmissions}
      />
    ),
    [STEPS.SELL_RATES]: (
      <SellRates
        isProposal={isProposal}
        submissions={sortedSubmissions}
        onChange={setSubmissionData('sellRates')}
        onSort={handleSort}
        position={{ ...positionData, currency: positionData.currency.value }}
        onSubmissionUnselect={unselectSubmission}
        onSubmissionRestore={selectSubmission}
        hasSavedSubmissions={hasSavedSubmissions}
      />
    ),
    [STEPS.DOCUMENTS]: (
      <Documents
        isProposal={isProposal}
        rateCard={rateCard}
        submissions={sortedSubmissions}
        onReleasableSubmissionFileChange={setSubmissionData('releasableSubmissionFile')}
        onReleasableAttestationChange={setSubmissionData('releasableAttestation')}
        onRateCardChange={setRateCard}
        onSubmissionUnselect={unselectSubmission}
        onSubmissionRestore={selectSubmission}
        onSort={handleSort}
        folder={boxFolder}
        position={positionData}
        hasSavedSubmissions={hasSavedSubmissions}
      />
    ),
    [STEPS.REVIEW]: (
      <Review
        isProposal={isProposal}
        rateCard={rateCard}
        submissions={sortedSubmissions}
        position={{ ...positionData, currency: positionData.currency.label }}
        errors={errorMessages}
        onSubmissionUnselect={unselectSubmission}
        onSubmissionRestore={selectSubmission}
        onSort={handleSort}
        hasSavedSubmissions={hasSavedSubmissions}
      />
    ),
    [STEPS.EMAIL]: (
      <Email
        html={email}
        options={emailOptions}
        attachments={getEmailAttachments({
          submissions: preparedSubmissions,
          positionId,
          rfq: positionData.rfq,
          manYearDiscount: positionData.manYearDiscount,
          isProposal,
          rateCard,
        })}
        onChange={setEmail}
        onOptionsChange={handleEmailOptionsChange}
        showErrors={submitted}
        emailOptionsOpen
      />
    ),
  });

  const resetState = () => {
    setSubmissions([]);
    setFetchingDataError(false);
    setActiveTab(STEPS.SELECTION);
    setPosition(null);
  };

  const handleClose = () => {
    // if has no saved progress -> show prompt
    if (hasProgress()) {
      setPromptOpen(true);
      return;
    }

    setPromptOpen(false);
    resetState();
    onClose();
  };

  const handleSwitch = (to, from) => {
    // validate selection step -> at least 1 submission must be selected
    if (from === STEPS.SELECTION && !selectedSubmissions.length) {
      setTabs(tabs.map(tab => (tab.id === STEPS.SELECTION ? { ...tab, error: true } : tab)));
      return;
    }

    // highligh error in review step
    if (to === STEPS.REVIEW) {
      setTabs(
        tabs.map(tab =>
          tab.id === STEPS.REVIEW && errorMessages.length > 0 ? { ...tab, error: true } : tab
        )
      );
    }

    // prevent email step if any error + highligh steps with error
    if (to === STEPS.EMAIL) {
      const isValid = validateSteps();

      if (!isValid) {
        addToast.warning(t('invalidStepsWarning'));
        return;
      }

      // rebuild email template
      const newEmail = buildEmail(emailTemplate.current, rfqNumber, preparedSubmissions);
      setEmail(newEmail);
    }

    // don't allow email step from review step if all submissions were unselected in review step
    if (from === STEPS.REVIEW && to === STEPS.EMAIL && !preparedSubmissions.length) {
      addToast.warning(t('noSelectedSubmissionsWarning'));
      setTabs(tabs.map(tab => (tab.id === STEPS.REVIEW ? { ...tab, error: true } : tab)));
      return;
    }

    // remove selected submissions marked as "Unselected" when step is changed
    if ([STEPS.SELL_RATES, STEPS.DOCUMENTS, STEPS.REVIEW].includes(from)) {
      removeUnselected();

      // if not submissions left redirect user to the 1st step
      if (!preparedSubmissions.length && to !== STEPS.SELECTION) {
        addToast.warning(t('noSelectedSubmissionsWarning'));
        setTabs(tabs.map(tab => ({ ...tab, error: false })));
        setActiveTab(STEPS.SELECTION);
        return;
      }
    }

    setActiveTab(to);
  };

  const handleSubmit = async () => {
    // validate email options
    if (!isEmailOptionsValid) {
      setSubmitted(true);
      setTabs(tabs.map(tab => (tab.id === STEPS.EMAIL ? { ...tab, error: true } : tab)));
      addToast.error(t('invalidEmailOptions'));
      return;
    }

    try {
      setLoading(true);
      await commitSubmissions({
        isProposal,
        submissions: selectedSubmissions,
        positionId,
        manYearDiscount: positionData.manYearDiscount,
        currency,
        email,
        emailOptions,
        userId: user.id,
        rateCard,
      });

      addToast.success(t('submitCandidateToClientSuccess'));
      resetState();
      setLoading(false);

      if (onFinish) {
        onFinish();
      } else {
        onClose();
      }
    } catch (error) {
      setLoading(false);
      addToast.error(t('errorPlaceholderText'));
    }
  };

  const handleSaveProgress = async () => {
    try {
      setLoading(true);
      setLoadingText(t('savingProgressLoader'));
      await saveProgress(positionId, getProgressData(), activeTab, rateCard);
      // commit current progress. It's used to track for new changes, new progress
      commitProgress();
      resetState();
      onClose();
      addToast.success(t('saveSubmissionProgressSuccess'));
    } catch (error) {
      addToast.error(t('errorPlaceholderText'));
    } finally {
      setLoading(false);
      setLoadingText('');
    }
  };

  const handleDiscard = () => {
    setPromptOpen(false);
    resetState();
    onClose();
  };

  const handleSave = () => {
    setPromptOpen(false);
    handleSaveProgress();
  };

  const renderSaveProgressButton = () => {
    if (hasProgress()) {
      return (
        <LinkButton onClick={handleSaveProgress} className="f-s-15">
          {t('saveProgressAndExit')}
        </LinkButton>
      );
    }

    return null;
  };

  return (
    <React.Fragment>
      <ModalStepper
        isOpen={isOpen}
        title={t('submitToClient')}
        header={position && <Header position={position} />}
        footer={renderSaveProgressButton()}
        tabs={tabs}
        activeTab={activeTab}
        error={tabs.some(tab => tab.error)}
        errorContent={t('errorDuringDataFetching')}
        displayErrorContent={fetchingDataError}
        loading={loading}
        loadingText={loadingText}
        content={getContent()}
        onSwitch={handleSwitch}
        onSubmit={handleSubmit}
        onClose={handleClose}
        closeOnBackdrop={false}
        submitButtonLabel={t('submit')}
        modalSize="xl"
        newDesign
      />
      <Prompt
        isOpen={isPromptOpen}
        title={t('saveProgress')}
        label={t('save')}
        onClose={() => setPromptOpen(false)}
        onClick={handleSave}
        secondaryLabel={t('discard')}
        onSecondaryClick={handleDiscard}
      >
        {t('pendingProgressPrompt')}
      </Prompt>
    </React.Fragment>
  );
};

SubmitCandidates.propTypes = propTypes;
SubmitCandidates.defaultProps = defaultProps;

export default SubmitCandidates;
