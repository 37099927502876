import React, { useEffect, useState } from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Fade } from 'react-awesome-reveal';
// components
import Textarea from 'components/atoms/Textarea';
// styles
import style from './SurveyLine.module.scss';

const propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isHover: PropTypes.bool,
};

const defaultProps = {
  isHover: false,
  label: '',
};

const EditableLabel = ({ label, onChange, isHover, id }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [textareaValue, setTextareaValue] = useState(label);

  useEffect(() => {
    if (textareaValue !== label) setTextareaValue(label);
  }, [label]);

  const handleEdit = () => {
    setIsEdit(true);
  };

  return (
    <div className={style.labelContainer}>
      {isEdit && (
        <Fade>
          <div className={style.edit}>
            <Textarea
              id={id}
              value={textareaValue}
              className={style.input}
              autoFocus
              onChange={input => setTextareaValue(input)}
              onBlur={() => {
                setIsEdit(false);
                onChange(textareaValue);
              }}
            />
          </div>
        </Fade>
      )}
      {!isEdit && (
        <Fade>
          <div
            role="presentation"
            onClick={handleEdit}
            className={classNames(style.label, {
              [style.hover]: isHover,
            })}
          >
            {textareaValue}
          </div>
        </Fade>
      )}
    </div>
  );
};

EditableLabel.propTypes = propTypes;
EditableLabel.defaultProps = defaultProps;

export default EditableLabel;
