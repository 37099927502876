// constants
import { FILES_ENDPOINT } from 'constants/box';

/**
 * Returns link to file
 * @param {string} fileId box file id
 * @returns {string}
 */
export const boxFileUrl = fileId => `${FILES_ENDPOINT}/open/${fileId}`;

/**
 * Escape invalid chars in filename or folder name for Box
 * @param {string} name Filename or folder name
 * @returns {string}
 */
export const escapeFilename = name => {
  return name.replace(/[\\/:<>]/g, '-');
};

/**
 * Return dir path for files
 * @param {object} params
 * @param {string} params.client Client name
 * @param {string} params.project Project name
 * @param {string} params.rfq RFQ number
 * @param {string} [additionalPath] Additional path
 * @returns {string}
 */
export const boxFileDir = (params, additionalPath = '') => {
  const client = escapeFilename(params.client);
  const project = escapeFilename(params.project);
  const rfq = escapeFilename(params.rfq);

  if (!client || !project || !rfq) {
    return '';
  }

  const dir = `${client}/${project}/${rfq}`;

  return additionalPath ? `${dir}/${escapeFilename(additionalPath)}` : dir;
};
