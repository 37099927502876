import React from 'react';

export interface TableContextProps {
  hoverable?: boolean;
  loading?: boolean;
  variant?: 'head' | 'body';
  classes?: {
    root?: string;
    row?: string;
  };
}

export default React.createContext<TableContextProps>({
  hoverable: true,
  loading: false,
  variant: 'body',
  classes: {},
});
