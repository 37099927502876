import React, { useState } from 'react';
// libraries
import PropTypes from 'prop-types';
import getProperty from 'lodash/get';
// helpers
import { getTabEvents, types } from './Helpers';
// components
import Events from './Events';
import Menu from './Menu';

const propTypes = {
  tabs: PropTypes.array,
  onChange: PropTypes.func,
  eventList: PropTypes.object,
};

const defaultProps = {
  tabs: [],
  onChange: () => {},
  eventList: {},
};

const NotificationContent = props => {
  const { tabs, eventList, onChange } = props;
  const initTab = getProperty(tabs, '0', null);
  const [tab, setTab] = useState(initTab);

  const events = getTabEvents(eventList, tab);
  return (
    <div className="notificationSettings fs-unmask">
      <Menu currentTab={tab} tabs={tabs} onChange={setTab} className="notificationSettings_menu" />
      <Events
        events={events}
        className="notificationSettings_content"
        onChange={onChange}
        types={types}
      />
    </div>
  );
};

NotificationContent.propTypes = propTypes;
NotificationContent.defaultProps = defaultProps;

export default NotificationContent;
