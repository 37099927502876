export const STEP = {
  JOB_OFFER_CONFIRM: 'JOB_OFFER_CONFIRM',
  JOB_OFFER_APPROVE: 'JOB_OFFER_APPROVE',
  START_DATE_PROPOSE: 'START_DATE_PROPOSE',
  START_DATE_SUBMIT: 'START_DATE_SUBMIT',
  START_DATE_APPROVE: 'START_DATE_APPROVE',
  ONBOARDING_SUBMIT: 'ONBOARDING_SUBMIT',
  ONBOARDING_CONFIRM: 'ONBOARDING_CONFIRM',
  PO_RELEASE: 'PO_RELEASE',
  PO_CONFIRM: 'PO_CONFIRM',
};

// date statuses
export const DATE_APPROVED = 'APPROVED';
export const DATE_REJECTED = 'REJECTED';
export const DATE_PENDING = 'PENDING';

// file types
export const ONBOARDING_FILE_HR72 = 'HR72';
export const ONBOARDING_FILE_PASSPORT = 'PASSPORT_COPY';
export const ONBOARDING_FILE_IVCP = 'IVCP';
export const ONBOARDING_FILE_NDA = 'NDA';
export const ONBOARDING_FILE_NHF = 'NHF';
export const ONBOARDING_FILE_ATTESTATION = 'ATTESTATION';
export const ONBOARDING_FILE_AAS_PLUS_NDA = 'AAS_PLUS_NDA';
export const ONBOARDING_FILE_NR_AIS_SECOPS_FORM = 'NR_AIS_SECOPS_FORM';
export const ONBOARDING_FILE_NR_AIS_SECOPS = 'NR_AIS_SECOPS';
export const ONBOARDING_NR_SECOPS_CHECKBOX = 'ONBOARDING_NR_SECOPS_CHECKBOX';

// note types
export const ONBOARDING_FORMS_NOTE = 'ONBOARDING_FORMS_NOTE';
export const START_DATE_NOTE = 'START_DATE';
export const OFFER_NOTE = 'OFFER_NOTE';

// rate types
export const PURCHASE_ORDER_RATE = 'PURCHASE_ORDER_RATE';
export const PURCHASE_ORDER_TOTAL_BUDGET = 'TOTAL_BUDGET';
