import queryString from 'query-string';
import getProperty from 'lodash/get';

/**
 * Programmatically open link
 * Simulates clicking on <a> tag
 * @param {string} url
 * @param {boolean} [targetBlank=true] open in new window?
 */
export const openLink = (url, targetBlank = true) => {
  const a = document.createElement('a');

  a.target = targetBlank ? '_blank' : '_self';
  a.href = url;
  a.dispatchEvent(new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window }));
};

/**
 * Return absolute url for provided relative URL
 * @param {string} relativeUrl
 * @returns {string}
 */
export const getAbsoluteUrl = relativeUrl => {
  return new URL(relativeUrl, document.baseURI).href;
};

/**
 * Redirect to login page
 */
export const redirectToLogin = () => {
  // don't redirect if we are already on login page
  if (window.location.pathname === '/login') return;

  const redirect =
    window.location.pathname === '/'
      ? '/login'
      : `/login?redirect=${window.location.pathname + encodeURIComponent(window.location.search)}`;

  window.location.replace(redirect);
};

/**
 * Get link to submission detail page
 * @param {string} id Submission id
 * @param {boolean} isProposal
 * @param {object} options
 * @param {object} [options.queryParams]
 * @param {boolean} [options.absoluteUrl=false]
 * @returns {string} Absolute link to submission detail page
 */
export const getSubmissionLink = (id, isProposal, options) => {
  const useAbsoluteUrl = getProperty(options, 'absoluteUrl', true);
  const queryParams = getProperty(options, 'queryParams');
  const route = isProposal ? 'proposal' : 'submission';
  const link = useAbsoluteUrl ? getAbsoluteUrl(`/${route}/${id}`) : `/${route}/${id}`;

  return queryParams ? `${link}?${queryString.stringify(queryParams)}` : link;
};
