import React from 'react';
// components
import ModalProvider from 'components/molecules/ModalProvider';
import DraftSettings from './DraftSettings';

const DraftSettingsContainer = props => (
  <ModalProvider>
    <DraftSettings {...props} />
  </ModalProvider>
);

export default DraftSettingsContainer;
