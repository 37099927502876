import i18n from 'i18next';
import moment from 'moment';
import hasProperty from 'lodash/has';
import getProperty from 'lodash/get';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';
import isNil from 'lodash/isNil';
// helpers
import parseProposal from 'helpers/ProposalParser';
import parseSubmission from 'helpers/SubmissionParser';
import { filterSubmissionsBySupplier } from 'helpers/Submission';
import { getCurrencyText, getDeliveryText } from 'helpers/NameMapping';
// constants
import {
  POSITION_STATUS,
  MAIN_RECRUITING,
  MAIN_SELECTION,
  MAIN_ONBOARDING,
  MAIN_DELIVERY,
  MAIN_CLOSED,
  MAIN_CANCELLED,
} from 'constants/positionStatus';
import {
  STAFF_ROLE,
  SUPPLIER_ROLE,
  MANAGER_ROLE,
  ONSITE,
  OFFSITE,
  REMOTE,
  HYBRID,
  ACTOR_PRIMARY_OWNER,
  ACTOR_PROJECT_MANAGER,
  REQUIREMENT_ACTIVITY,
  REQUIREMENT_SKILL,
  POSITION_TYPE,
} from 'constants';
// helpers
import {
  formatStatus,
  getCurrentStatus,
  getMainStatusByRole,
  getMainStatusHistoryByRole,
} from './status';
import {
  getPositionRoleLabel,
  getWorkArrangementLabel,
  getEvaluationMethodLabel,
  getLanguageLabel,
  getTravelLabel,
  getContractTypeLabel,
} from './label';

/**
 * @typedef {Object} PositionRole
 * @property {string} id Position role id
 * @property {string} role Position role constant
 * @property {string} label Label for position role
 * @property {object} user Position role user
 * @property {string} user.id
 * @property {string} user.name
 * @property {string} user.email
 * @property {string} user.phone
 */

/**
 * @typedef {Object} File
 * @property {string} id
 * @property {string} boxId Box id
 * @property {string} filename
 * @property {string} type
 */

/**
 * @typedef {Object} Note
 * @property {string} id
 * @property {string} type
 * @property {string} text
 * @property {string} author
 */

/**
 * @typedef {Object} Rate
 * @property {string} id
 * @property {number} value
 * @property {string} startDate
 * @property {string} currency
 * @property {number} year
 */

/**
 * @typedef {Object} Status
 * @property {string} id
 * @property {string} value
 * @property {string} label
 * @property {string} start
 * @property {string} end
 * @property {string} note
 * @property {string} author Author name
 */

const getRfqLabel = (rfq, positionLabel) => {
  return positionLabel ? `${positionLabel} ${rfq}` : rfq;
};

const getReceiveDate = statuses => {
  const filteredStatuses = statuses.filter(item => item.name === POSITION_STATUS.UNPUBLISHED);
  const orderedStatuses = orderBy(filteredStatuses, 'start', 'asc');

  if (orderedStatuses.length > 0) {
    return orderedStatuses[0].start;
  }

  return '';
};

const getPositionRoles = roles => {
  const filtered = roles.filter(role => !role.endDate);

  return filtered.map(({ id, role, user }) => {
    const phone = getProperty(user, 'person.phone', '');
    const mobilePhone = getProperty(user, 'person.mobilePhone', '');

    return {
      id,
      role,
      label: getPositionRoleLabel(role),
      user: {
        id: getProperty(user, 'id', ''),
        name: getProperty(user, 'person.fullName', ''),
        email: getProperty(user, 'user.email', ''),
        phone: phone || mobilePhone,
      },
    };
  });
};

const formatFile = file => ({
  id: file.id,
  boxId: file.boxId,
  filename: file.name,
  type: file.type,
});

const formatNote = note => ({
  id: note.id,
  type: note.noteType,
  text: note.note,
  date: note.createdAt,
  author: getProperty(note, 'author.person.fullName', ''),
});

const formatRate = rate => ({
  ...rate,
  year: moment(rate.startDate).year(),
});

class Position {
  constructor(data) {
    const rfq = getProperty(data, 'rfq.name', '');
    const statuses = getProperty(data, 'statuses.items', []);
    const projectConfig = getProperty(data, 'project.config', {});
    const clientConfig = getProperty(data, 'project.client.config', {});
    const positionRoles = getProperty(data, 'actors.items', []);
    const requirements = getProperty(data, 'requirements.items', []);

    const getRateSetting = (setting, defaultValue) => {
      return isNil(data[setting])
        ? getProperty(data, ['project', setting], defaultValue)
        : data[setting];
    };

    this.data = data;
    this.id = getProperty(data, 'id', '');
    this.title = getProperty(data, 'title', '');
    this.rfq = rfq;
    this.rfqLabel = getRfqLabel(rfq, getProperty(clientConfig, 'positionLabel', ''));
    this.description = getProperty(data, 'description', '');
    this.created = getReceiveDate(statuses);
    this.dueDate = getProperty(data, 'dueOn', '');
    this.supplierDueDate = getProperty(data, 'supplierDueDate', '');
    this.roles = getPositionRoles(positionRoles);
    this.contractType = (() => {
      const type = getProperty(data, 'contractType', '');

      return {
        value: type,
        label: getContractTypeLabel(type),
      };
    })();
    this.duration = {
      start: getProperty(data, 'duration.start', ''),
      end: getProperty(data, 'duration.end', ''),
      hours: getProperty(data, 'duration.hours', 0),
    };
    this.experience = {
      label: getProperty(data, 'level.title', ''),
      years: getProperty(data, 'level.years', ''),
    };
    this.workArrangement = (() => {
      const arrangement = getProperty(data, 'workArrangement.arrangement', '');

      return {
        value: arrangement,
        label: getWorkArrangementLabel(arrangement),
      };
    })();
    this.delivery = (() => {
      const mode = getProperty(data, 'deliveryOption.mode', '');
      const description = getProperty(data, 'deliveryOption.description', '');

      return {
        value: mode,
        label: getDeliveryText(mode),
        description,
      };
    })();
    this.evaluationMethod = (() => {
      const evaluationMethod = getProperty(data, 'evaluationMethod', '');

      return {
        value: evaluationMethod,
        label: getEvaluationMethodLabel(evaluationMethod),
      };
    })();
    this.location = {
      id: getProperty(data, 'location.items.0.id', ''),
      name: getProperty(data, 'location.items.0.name', ''),
      city: getProperty(data, 'location.items.0.city', ''),
      country: getProperty(data, 'location.items.0.country.name', ''),
    };
    this.travel = (() => {
      const travel = getProperty(data, 'travel.option', '');

      return {
        id: getProperty(data, 'travel.id', ''),
        value: travel,
        label: getTravelLabel(travel),
      };
    })();
    this.clearance = getProperty(data, 'clearance.name', '');
    this.clearanceShortName = getProperty(data, 'clearance.shortName', '');
    this.requirements = requirements;
    this.skills = requirements
      .filter(({ requirementType }) => requirementType === REQUIREMENT_SKILL)
      .map(skill => ({
        id: getProperty(skill, 'id', ''),
        value: getProperty(skill, 'description', ''),
        required: getProperty(skill, 'importance', '') !== 'OPTIONAL',
      }));
    this.activities = requirements
      .filter(({ requirementType }) => requirementType === REQUIREMENT_ACTIVITY)
      .map(activity => ({
        id: getProperty(activity, 'id', ''),
        value: getProperty(activity, 'description', ''),
        percentage: getProperty(activity, 'percentage', 0),
        color: getProperty(activity, 'color', ''),
      }));
    this.rates = getProperty(data, 'rates.items', []);
    this.notes = getProperty(data, 'notes.items', []);
    this.documents = getProperty(data, 'documents.items', []);
    this.languages = getProperty(data, 'languages', []).map(getLanguageLabel);
    this.statuses = statuses;
    this.occupations = getProperty(data, 'shOccupations', []) || [];
    this.occupation = getProperty(data, 'shOccupation') || '';
    this.experienceLevel = {
      id: getProperty(data, 'level.id', ''),
      value: getProperty(data, 'level.shLevel', ''),
      title: getProperty(data, 'level.title', ''),
    };
    this.project = {
      id: getProperty(data, 'project.id', ''),
      shProjectId: getProperty(data, 'project.shProjectId', ''),
      name: getProperty(data, 'project.name', ''),
      shortName: getProperty(data, 'project.shortName', ''),
      config: projectConfig,
      timezone: getProperty(data, 'project.timezone', ''),
      currency: getProperty(data, 'project.currency', ''),
      currencyLabel: getCurrencyText(getProperty(data, 'project.currency', '')),
      experienceLevels: getProperty(data, 'project.levels.items', []).map(level => ({
        label: getProperty(level, 'title', ''),
        years: getProperty(level, 'years', ''),
      })),
    };
    this.client = {
      id: getProperty(data, 'project.client.id', ''),
      name: getProperty(data, 'project.client.name', ''),
      config: clientConfig,
    };
    this.submissions = getProperty(data, 'submissions.items', []);
    this.proposals = getProperty(data, 'proposals.items', []);
    this.maximumCandidatesAllowed = getProperty(data, 'maximumCandidatesAllowed', 1);
    this.report = {
      id: getProperty(data, 'report.id', ''),
      shPositionId: getProperty(data, 'report.shPositionId', ''),
    };
    this.showTargetRatesToPartners = getRateSetting('showTargetRatesToPartners', true);
    this.showNteRatesToPartners = getRateSetting('showNteRatesToPartners', true);
    this.allowSubmissionAboveTargetRates = getRateSetting('allowSubmissionAboveTargetRates', true);
    this.isPublishedToAll = data.publishedToAll;
    this.audience = (() => {
      const audience = getProperty(data, 'publishedTo.items', []);

      return audience.map(supplier => ({
        id: supplier.id,
        company: getProperty(supplier, 'company.name', ''),
      }));
    })();
    this.hasBeenPublishedTo = (() => {
      const allAudience = getProperty(data, 'hasBeenPublishedTo.items', []);

      return allAudience.map(supplier => ({
        id: supplier.id,
        company: getProperty(supplier, 'company.name', ''),
      }));
    })();
    this.submissionProgress = getProperty(data, 'candidatesSubmissionProgress');
    this.publishProgress = getProperty(data, 'publishProgress');
    this.deliverables = getProperty(data, 'deliverables.items', []);
  }

  /**
   * Get position due dates
   * @param {string} role
   * @param {boolean} [includeSupplierDueDateForMM=false]
   */
  getDueDates = (role, includeSupplierDueDateForMM = false) => {
    const positionDueDateLabel = i18n.t('dueDate');
    const supplierDueDateLabel = i18n.t('supplierDueDate');

    if (role === MANAGER_ROLE) {
      return [
        {
          label: positionDueDateLabel,
          value: this.dueDate,
        },
      ];
    }

    if (role === SUPPLIER_ROLE) {
      return [
        {
          label: positionDueDateLabel,
          value: this.supplierDueDate,
        },
      ];
    }

    if (role === STAFF_ROLE) {
      const dueDates = [
        {
          label: positionDueDateLabel,
          value: this.dueDate,
        },
      ];

      if (includeSupplierDueDateForMM) {
        dueDates.push({
          label: supplierDueDateLabel,
          value: this.supplierDueDate,
        });
      }

      return dueDates;
    }

    return [];
  };

  /**
   * Get submissions from position
   * @param {string} [role]
   * @param {string} [supplierId]
   * @returns {object[]}
   */
  getSubmissions = (role, supplierId) => {
    const submissions = this.isFixedPrice() ? this.proposals : this.submissions;

    if (role === SUPPLIER_ROLE) {
      return filterSubmissionsBySupplier(submissions, supplierId);
    }

    return submissions;
  };

  /**
   * Get submissions from position
   * @param {string} [role]
   * @param {string} [supplierId]
   * @returns {object[]}
   */
  getParsedSubmissions = (role, supplierId) => {
    const submissions = this.isFixedPrice() ? this.proposals : this.submissions;

    if (role === SUPPLIER_ROLE) {
      return filterSubmissionsBySupplier(submissions, supplierId).map(
        this.isFixedPrice() ? parseProposal : parseSubmission
      );
    }

    return submissions.map(this.isFixedPrice() ? parseProposal : parseSubmission);
  };

  /**
   * Find status (even if status is already finished)
   * @param {string} status
   * @return {?Status}
   */
  findStatus = status => {
    const positionStatus = this.statuses.find(statusItem => statusItem.name === status);

    return positionStatus ? formatStatus(positionStatus) : null;
  };

  /**
   * Get position current status for given role
   * @param {string} [role]
   * @param {string} [supplierId] Applicable only for supplier role
   * @returns {Status}
   */
  getStatus = (role, supplierId) => {
    if (role !== SUPPLIER_ROLE) {
      const status = getCurrentStatus(this.statuses);

      return formatStatus(status);
    }

    const supplierStatus = getCurrentStatus(
      this.statuses,
      this.supplierDueDate,
      this.getSubmissions(role, supplierId)
    );

    return formatStatus(supplierStatus);
  };

  /**
   * Get position main status by role
   * @param {string} role
   * @param {string} [supplierId] Applicable only for supplier role
   * @returns {Status}
   */
  getMainStatus = (role, supplierId) => {
    return getMainStatusByRole(
      {
        statuses: this.statuses,
        candidates: this.getSubmissions(role, supplierId),
        dueDate: this.dueDate,
        supplierDueDate: this.supplierDueDate,
        isPublishedToSupplier: this.isPublished(role, supplierId),
      },
      role
    );
  };

  /**
   * Get history of main statuses
   * @param {string} role
   * @param {string} supplierId
   * @returns {Status[]}
   */
  getMainStatusHistory = (role, supplierId) => {
    return getMainStatusHistoryByRole(
      this.statuses,
      this.getSubmissions(role, supplierId),
      role,
      this.supplierDueDate,
      this.isPublished(role, supplierId)
    );
  };

  /**
   * Get color of position
   * @param {string} role
   * @param {string} [supplierId] Applicable only for supplier role
   * @returns {string} - color
   */
  getColor = (role, supplierId) => {
    const status = this.getMainStatus(role, supplierId);

    switch (status.value) {
      case MAIN_RECRUITING:
        return 'red';
      case MAIN_SELECTION:
        return 'yellow';
      case MAIN_ONBOARDING:
        return 'green';
      case MAIN_DELIVERY:
        return 'blue';
      case MAIN_CLOSED:
        return 'gray';
      case MAIN_CANCELLED:
        return 'gray';
      default:
        return 'blue';
    }
  };

  /**
   * Find document by type
   * @param {string} type
   * @returns {?File}
   */
  getDocument = type => {
    const file = this.documents.find(document => document.type === type);

    if (file) {
      return formatFile(file);
    }

    return null;
  };

  /**
   * Find documents by type
   * @param {string} [type] If not provided, all position documents are returned
   * @returns {File[]}
   */
  getDocuments = type => {
    if (!type) {
      return this.documents.map(formatFile);
    }

    return this.documents.filter(document => document.type === type).map(formatFile);
  };

  /**
   * Find note by type
   * @param {string} type
   * @returns {?Note}
   */
  getNote = type => {
    const note = this.notes.find(({ noteType }) => noteType === type);

    if (note) {
      return formatNote(note);
    }

    return null;
  };

  /**
   * Get position role
   * @param {string} role position role
   * @returns {?PositionRole}
   */
  getPositionRole = role => {
    return this.roles.find(positionRole => role === positionRole.role);
  };

  /**
   * Get position role by user
   * @param {string} role position role
   * @param {string} user User id
   * @returns {?PositionRole}
   */
  getPositionRoleByUser = (role, user) => {
    return this.roles.find(
      positionRole => role === positionRole.role && positionRole.user.id === user
    );
  };

  /**
   * Get position roles
   * @param {string} role position role
   * @returns {PositionRole[]}
   */
  getPositionRoles = role => {
    return this.roles.filter(positionRole => positionRole.role === role);
  };

  /**
   * Get position owner
   * @returns {?PositionRole}
   */
  getOwner = () => {
    return this.getPositionRole(ACTOR_PRIMARY_OWNER);
  };

  /**
   * Get position owner
   * @returns {?User}
   */
  getProjectManager = () => {
    const projectManagerRole = this.getPositionRole(ACTOR_PROJECT_MANAGER);
    const projectManager = getProperty(projectManagerRole, 'user');

    if (!projectManager) return null;

    // Override project manager data from project/client config
    return { ...projectManager, ...this.getConfig('projectManager', {}) };
  };

  /**
   * Get list of SH occupations
   * @returns {string[]}
   */
  getOccupationsName = () => {
    return this.occupations.map(occupation => occupation.name);
  };

  /**
   * Get rates
   * @param {string} type Rate type
   * @returns {Rate[]}
   */
  getRates = type => {
    return sortBy(
      this.rates.filter(({ rateType }) => rateType === type).map(formatRate),
      'startDate'
    );
  };

  /**
   * Get rate by type for given year
   * @param {string} type Rate type
   * @param {number} [year] Rate for given year. By default it uses current year or 1st rate if rate for current year is not found
   * @returns {?Rate}
   */
  getRate = (type, year) => {
    const rates = this.getRates(type);
    const rateYear = year || new Date().getFullYear();
    const foundRate = rates.find(rate => new Date(rate.startDate).getFullYear() === rateYear);

    if (foundRate) {
      return formatRate(foundRate);
    }

    return rates[0] ? formatRate(rates[0]) : null;
  };

  /**
   * Get project or client config. Project config override client config
   * @param {string} key config key
   * @param {any} [defaultValue] default value if given config is not found
   */
  getConfig = (key, defaultValue = null) => {
    if (hasProperty(this.project.config, key)) {
      return this.project.config[key];
    }
    if (hasProperty(this.client.config, key)) {
      return this.client.config[key];
    }

    return defaultValue;
  };

  /**
   * Is position delivery mode onsite?
   * @returns {boolean}
   */
  isOnsite = () => {
    return this.delivery.value === ONSITE;
  };

  /**
   * Is position delivery mode offsite?
   * @returns {boolean}
   */
  isOffsite = () => {
    return [OFFSITE, REMOTE, HYBRID].includes(this.delivery.value);
  };

  /**
   * Get formatted location
   * @returns {string}
   */
  getLocation = () => {
    if (this.isOffsite()) {
      return this.delivery.label;
    }

    if (!this.location.country || !this.location.city) {
      return '';
    }

    return `${this.location.city}, ${this.location.country}`;
  };

  /**
   * Is position after due date?
   * @param {string} [role]
   * @returns {boolean}
   */
  isAfterDueDate = role => {
    if (role === SUPPLIER_ROLE) {
      return moment().isAfter(moment(this.supplierDueDate));
    }

    return moment().isAfter(moment(this.dueDate));
  };

  /**
   * Is position before due date?
   * @param {string} [role]
   * @returns {boolean}
   */
  isBeforeDueDate = role => {
    if (role === SUPPLIER_ROLE) {
      return moment().isBefore(moment(this.supplierDueDate));
    }

    return moment().isBefore(moment(this.dueDate));
  };

  /**
   * Is position published to allow candidate submissions?
   * @param {string} [role=STAFF_ROLE]
   * @param {string} [supplierId='']
   * @returns {bool}
   */
  isPublished = (role = STAFF_ROLE, supplierId = '') => {
    const currentStatus = this.getStatus();

    // Check if position is published to supplier
    if (role === SUPPLIER_ROLE && supplierId) {
      return (
        currentStatus.value === POSITION_STATUS.PUBLISHED &&
        this.isBeforeDueDate(role) &&
        (this.audience.some(({ id }) => id === supplierId) || this.isPublishedToAll)
      );
    }

    return currentStatus.value === POSITION_STATUS.PUBLISHED && this.isBeforeDueDate(role);
  };

  /**
   * Was position previously published?
   * @param {string} [supplierId]
   * @returns {bool}
   */
  hasBeenPublishedBefore = supplierId => {
    if (supplierId) {
      return this.hasBeenPublishedTo.some(({ id }) => id === supplierId);
    }
    return this.statuses.some(status => status.name === POSITION_STATUS.PUBLISHED);
  };

  /**
   * Is fixed price contract type
   * @returns {bool}
   */
  isFixedPrice = () => {
    return this.contractType.value === POSITION_TYPE.FIXED_PRICE;
  };

  /**
   * Is Time and Material contract type
   * @returns {bool}
   */
  isTimeAndMaterials = () => {
    return this.contractType.value === POSITION_TYPE.TIME_AND_MATERIALS;
  };

  /**
   * Position requires security clearance?
   * @returns {bool}
   */
  requiresClearance = () => {
    return !this.clearance || this.clearance !== 'No Clearance';
  };
}

/**
 * Parse and normalize position data from 8base
 * @param {object} data Position data from 8base
 * @returns {Position}
 */
const parsePosition = data => new Position(data);

export default parsePosition;
