import React from 'react';
import { Trans } from 'react-i18next';
import i18n from 'i18next';
import { LIST_TYPE_BULLET, LIST_TYPE_SQUARE_BULLET_EMPTY } from 'constants/list';

export const definitions = {
  type: LIST_TYPE_BULLET,
  list: [
    {
      content: (
        <Trans i18nKey="termsOfUseDefinitions1">
          <b>&quot;Controller&quot;</b> means the natural or legal person, public authority, agency
          or other body which, alone or jointly with others, determines the purposes and means of
          the processing of personal data.
        </Trans>
      ),
    },
    {
      content: (
        <Trans i18nKey="termsOfUseDefinitions2">
          <b>&quot;Processor&quot;</b> means a natural or legal person, public authority, agency or
          other body which processes personal data on behalf of the controller.
        </Trans>
      ),
    },
    {
      content: (
        <Trans i18nKey="termsOfUseDefinitions3">
          <b>&quot;Personal Data&quot;</b> means any information relating to an identified or
          identifiable natural person.
        </Trans>
      ),
    },
    {
      content: (
        <Trans i18nKey="termsOfUseDefinitions4">
          <b>&quot;GDPR&quot;</b> means the General Data Protection Regulation (EU) 2016/679 of the
          European Parliament and of the Council of 27 April 2016 on the protection of natural
          persons with regard to the processing of personal data and on the free movement of such
          data.
        </Trans>
      ),
    },
    {
      content: (
        <Trans i18nKey="termsOfUseDefinitions5">
          <b>&quot;Sub-processor&quot;</b> means a third party used by IBM to provide our service.
        </Trans>
      ),
    },
    {
      content: (
        <Trans i18nKey="termsOfUseDefinitions55">
          <b>&quot;Product&quot;</b> means the web site, service, software or computer program known
          as ‘IBM Services Talent Matching Hub’, developed by IBM and for which access and/or use is
          provided to Licensee. Product includes any manuals and user’s guide and any other material
          relating to the product provided to Licensee pursuant to this Agreement.
        </Trans>
      ),
    },
    {
      content: (
        <Trans i18nKey="termsOfUseDefinitions6">
          <b>&quot;You&quot; or &quot;Licensee&quot;</b> means the <b>‘user’</b> (an individual on
          behalf of a nominated company or organization that will be bound by these terms) that
          signs up to use the Product.
        </Trans>
      ),
    },
    {
      content: (
        <Trans i18nKey="termsOfUseDefinitions7">
          <b>&quot;Data Subject&quot;</b> is the identified or identifiable natural person the
          Personal Data is relating to.
        </Trans>
      ),
    },
    {
      content: (
        <Trans i18nKey="termsOfUseDefinitions75">
          <b>&quot;Licensor&quot;</b> means IBM.
        </Trans>
      ),
    },
    {
      content: (
        <Trans i18nKey="termsOfUseDefinitions8">
          <b>&quot;Candidate&quot;</b> is a person seeking employment whose profile and application
          is submitted through the product and managed by the Licensee in the recruitment process.
        </Trans>
      ),
    },
    {
      content: (
        <Trans i18nKey="termsOfUseDefinitions9">
          <b>&quot;Project&quot;</b> refers to your existing relationship and contractual
          engagements under which you require the use of the Product to work collaboratively with
          other parties to achieve the business objectives of that contract.
        </Trans>
      ),
    },
    {
      content: (
        <Trans i18nKey="termsOfUseDefinitions10">
          <b>&quot;Content&quot;</b> meaning all data and information that Licensee provides,
          authorizes access to, or inputs to the Product.
        </Trans>
      ),
    },
  ],
};

export const licenseeObligationsTerms = {
  type: null,
  list: [
    {
      content: i18n.t('licenseeObligationsDescriptionTermsListTitle'),
      type: LIST_TYPE_BULLET,
      list: [
        {
          content: i18n.t('licenseeObligationsDescriptionTermsList1'),
        },
        {
          content: i18n.t('licenseeObligationsDescriptionTermsList2'),
        },
      ],
    },
  ],
};

export const licenseeObligations = {
  type: null,
  list: [
    {
      title: null,
      content: i18n.t('licenseeObligationsDescription1'),
      list: [
        {
          content: i18n.t('licenseeObligationsDescription1_1'),
        },
        {
          content: i18n.t('licenseeObligationsDescription1_2'),
        },
        {
          content: i18n.t('licenseeObligationsDescription1_3'),
          type: LIST_TYPE_SQUARE_BULLET_EMPTY,
          list: [
            {
              content: i18n.t('licenseeObligationsDescription1_3_1'),
            },
            {
              content: i18n.t('licenseeObligationsDescription1_3_1_1'),
            },
            {
              content: i18n.t('licenseeObligationsDescription1_3_1_2'),
            },
            {
              content: i18n.t('licenseeObligationsDescription1_3_1_3'),
            },
            {
              content: i18n.t('licenseeObligationsDescription1_3_1_4'),
            },
            {
              content: i18n.t('licenseeObligationsDescription1_3_1_5'),
            },
            {
              content: i18n.t('licenseeObligationsDescription1_3_1_6'),
            },
            {
              content: i18n.t('licenseeObligationsDescription1_3_1_7'),
            },
            {
              content: i18n.t('licenseeObligationsDescription1_3_1_8'),
            },
            {
              content: i18n.t('licenseeObligationsDescription1_3_1_9'),
            },
            {
              content: i18n.t('licenseeObligationsDescription1_3_1_10'),
            },
            {
              content: i18n.t('licenseeObligationsDescription1_3_1_11'),
            },
            {
              content: i18n.t('licenseeObligationsDescription1_3_2'),
            },
            {
              content: i18n.t('licenseeObligationsDescription1_3_3'),
            },
          ],
        },
      ],
      type: LIST_TYPE_BULLET,
    },
    {
      content: i18n.t('licenseeObligationsDescription2'),
    },
    {
      content: i18n.t('licenseeObligationsDescription3'),
    },
    {
      content: i18n.t('licenseeObligationsDescription4'),
      list: [
        {
          content: i18n.t('licenseeObligationsDescription4_1'),
        },
        {
          content: i18n.t('licenseeObligationsDescription4_2'),
        },
      ],
      type: LIST_TYPE_BULLET,
    },
    {
      content: i18n.t('licenseeObligationsDescription5'),
      list: [
        { content: i18n.t('licenseeObligationsDescription5_1') },
        {
          content: i18n.t('licenseeObligationsDescription5_2'),
        },
        {
          content: i18n.t('licenseeObligationsDescription5_3'),
        },
      ],
      type: LIST_TYPE_BULLET,
    },
    {
      content: i18n.t('licenseeObligationsDescription6'),
    },
    {
      content: i18n.t('licenseeObligationsDescription7'),
    },
    {
      content: i18n.t('licenseeObligationsDescription8'),
    },
  ],
};

export const intellectualPropertyRights = {
  type: null,
  list: [
    {
      content: i18n.t('intellectualPropertyRightsDescription1'),
    },
    {
      content: i18n.t('intellectualPropertyRightsDescription2'),
    },
    {
      content: i18n.t('intellectualPropertyRightsDescription3'),
    },
    {
      content: i18n.t('intellectualPropertyRightsDescription4'),
    },
    {
      content: i18n.t('intellectualPropertyRightsDescription5'),
    },
  ],
};

export const privacyNumberedList = {
  type: null,
  list: [
    {
      content: (
        <Trans i18nKey="privacyNumberedListDescription1">
          IBM, its affiliates, and contractors of either, may, wherever they do business, store and
          otherwise process business contact information (BCI) of Licensee, its personnel and
          authorized users, for example, name, business telephone, address, email, and user ID for
          business dealings with them. Where notice to or consent by the individuals is required for
          such processing, Licensee will notify and obtain such consent. The IBM Privacy Statement
          at{' '}
          <a href="https://www.ibm.com/privacy/us/en/" target="_blank" rel="noopener noreferrer">
            https://www.ibm.com/privacy/us/en/
          </a>{' '}
          provides additional details with respect to BCI and Account Data described below.
        </Trans>
      ),
    },
    {
      content: i18n.t('privacyNumberedListDescription2'),
    },
  ],
};

export const privacyBulletList = {
  type: LIST_TYPE_BULLET,
  list: [
    {
      content: i18n.t('privacyBulletListDescription1'),
    },
    {
      content: i18n.t('privacyBulletListDescription2'),
    },
  ],
};

export const dpa = {
  type: null,
  list: [
    {
      content: (
        <Trans i18nKey="dpaDescription1">
          IBM&apos;s Data Processing Addendum at{' '}
          <a href="http://ibm.com/dpa" target="_blank" rel="noopener noreferrer">
            http://ibm.com/dpa
          </a>{' '}
          (DPA) and applicable DPA Exhibit(s) apply to personal data contained in Content, if and to
          the extent: i) the European General Data Protection Regulation (EU/2016/679); or ii) other
          data protection laws identified at{' '}
          <a href="http://ibm.com/dpa/dpl" target="_blank" rel="noopener noreferrer">
            http://ibm.com/dpa/dpl
          </a>{' '}
          apply.
        </Trans>
      ),
    },
    {
      content: i18n.t('dpaDescription2'),
    },
    {
      content: i18n.t('dpaDescription3'),
    },
  ],
};

export const processingTableList = [
  i18n.t('combines'),
  i18n.t('copies'),
  i18n.t('deletes'),
  i18n.t('hides'),
  i18n.t('links'),
  i18n.t('obscures'),
  i18n.t('parses'),
  i18n.t('reads'),
  i18n.t('receives'),
  i18n.t('sends'),
  i18n.t('shares'),
  i18n.t('stores'),
  i18n.t('transforms'),
  i18n.t('transitions'),
  i18n.t('updates'),
];

export const technicalMeasuresList = [
  {
    title: i18n.t('technicalMeasures1_1'),
    increment: 1.1,
    content: [
      i18n.t('technicalMeasures1_1_1'),
      i18n.t('technicalMeasures1_1_2'),
      i18n.t('technicalMeasures1_1_3'),
      i18n.t('technicalMeasures1_1_4'),
    ],
  },
  {
    title: i18n.t('technicalMeasures1_2'),
    increment: 1.2,
    content: [
      i18n.t('technicalMeasures1_2_1'),
      i18n.t('technicalMeasures1_2_2'),
      i18n.t('technicalMeasures1_2_3'),
      i18n.t('technicalMeasures1_2_4'),
      i18n.t('technicalMeasures1_2_5'),
      i18n.t('technicalMeasures1_2_6'),
    ],
  },
  {
    title: i18n.t('technicalMeasures1_3'),
    increment: 1.3,
    content: [
      i18n.t('technicalMeasures1_3_1'),
      i18n.t('technicalMeasures1_3_3'),
      i18n.t('technicalMeasures1_3_4'),
    ],
  },
  {
    title: i18n.t('technicalMeasures1_4'),
    increment: 1.4,
    content: [
      i18n.t('technicalMeasures1_4_1'),
      i18n.t('technicalMeasures1_4_2'),
      i18n.t('technicalMeasures1_4_3'),
      i18n.t('technicalMeasures1_4_4'),
      i18n.t('technicalMeasures1_4_5'),
    ],
  },
  {
    title: i18n.t('technicalMeasures1_5'),
    increment: 1.5,
    content: [
      i18n.t('technicalMeasures1_5_1'),
      i18n.t('technicalMeasures1_5_2'),
      i18n.t('technicalMeasures1_5_3'),
      i18n.t('technicalMeasures1_5_4'),
      i18n.t('technicalMeasures1_5_5'),
    ],
  },
  {
    title: i18n.t('technicalMeasures1_6'),
    increment: 1.6,
    content: [i18n.t('technicalMeasures1_6_1'), i18n.t('technicalMeasures1_6_2')],
  },
  {
    title: i18n.t('technicalMeasures1_7'),
    increment: 1.7,
    content: [
      i18n.t('technicalMeasures1_7_1'),
      i18n.t('technicalMeasures1_7_2'),
      i18n.t('technicalMeasures1_7_3'),
      i18n.t('technicalMeasures1_7_4'),
      i18n.t('technicalMeasures1_7_5'),
    ],
  },
  {
    title: i18n.t('technicalMeasures2_1'),
    increment: 2.1,
    content: [
      i18n.t('technicalMeasures2_1_1'),
      i18n.t('technicalMeasures2_1_2'),
      i18n.t('technicalMeasures2_1_3'),
    ],
  },
  {
    title: i18n.t('technicalMeasures2_2'),
    increment: 2.2,
    content: [
      i18n.t('technicalMeasures2_2_1'),
      i18n.t('technicalMeasures2_2_2'),
      i18n.t('technicalMeasures2_2_3'),
      i18n.t('technicalMeasures2_2_4'),
      i18n.t('technicalMeasures2_2_5'),
      i18n.t('technicalMeasures2_2_6'),
    ],
  },
  {
    title: i18n.t('technicalMeasures2_3'),
    increment: 2.3,
    content: [
      i18n.t('technicalMeasures2_3_1'),
      i18n.t('technicalMeasures2_3_2'),
      i18n.t('technicalMeasures2_3_3'),
    ],
  },
  {
    title: i18n.t('technicalMeasures3_1'),
    increment: 3.1,
    content: [i18n.t('technicalMeasures3_1_1'), i18n.t('technicalMeasures3_1_2')],
  },
  {
    title: i18n.t('technicalMeasures3_2'),
    increment: 3.2,
    content: [
      i18n.t('technicalMeasures3_2_1'),
      i18n.t('technicalMeasures3_2_2'),
      i18n.t('technicalMeasures3_2_3'),
    ],
  },
  {
    title: i18n.t('technicalMeasures3_3'),
    increment: 3.3,
    content: [
      i18n.t('technicalMeasures3_3_1'),
      i18n.t('technicalMeasures3_3_2'),
      i18n.t('technicalMeasures3_3_3'),
    ],
  },
  {
    title: i18n.t('technicalMeasures3_4'),
    increment: 3.4,
    content: [i18n.t('technicalMeasures3_4_1')],
  },
  {
    title: i18n.t('technicalMeasures3_5'),
    increment: 3.5,
    content: [
      i18n.t('technicalMeasures3_5_1'),
      i18n.t('technicalMeasures3_5_2'),
      i18n.t('technicalMeasures3_5_3'),
    ],
  },
  {
    title: i18n.t('technicalMeasures3_6'),
    increment: 3.6,
    content: [i18n.t('technicalMeasures3_6_1'), i18n.t('technicalMeasures3_6_2')],
  },
];

export const personalData = [
  {
    category: i18n.t('category'),
    description: i18n.t('description'),
    source: i18n.t('source'),
  },
  {
    category: i18n.t('categoriesOfPersonalDataCategory'),
    description: (
      <Trans i18nKey="categoriesOfPersonalDataDescription1">
        - Education and Professional Certifications
        <br />
        - Profession and Employment Information
        <br />- Professional Affiliations
      </Trans>
    ),
    source: (
      <Trans i18nKey="categoriesOfPersonalDataSource">
        - Licensee
        <br />
        - Affiliates
        <br />- Third Parties
      </Trans>
    ),
  },
  {
    category: 'Characteristics of the Individual',
    description: (
      <Trans i18nKey="categoriesOfPersonalDataDescription2">
        - Demographic
        <br />
        - Nationality and Citizenship
        <br />- Personal Preference and Interest
      </Trans>
    ),
    source: (
      <Trans i18nKey="categoriesOfPersonalDataSource">
        - Licensee
        <br />
        - Affiliates
        <br />- Third Parties
      </Trans>
    ),
  },
  {
    category: 'Habits and Activities of the Individual',
    description: (
      <Trans i18nKey="categoriesOfPersonalDataDescription3">
        - Behavior
        <br />- Consumed Resources
      </Trans>
    ),
    source: (
      <Trans i18nKey="categoriesOfPersonalDataSource">
        - Licensee
        <br />
        - Affiliates
        <br />- Third Parties
      </Trans>
    ),
  },
  {
    category: 'Identity of the Individual',
    description: (
      <Trans i18nKey="categoriesOfPersonalDataDescription4">
        - Individual
        <br />
        - Online Access and Authentication Credentials
        <br />
        - Online Connection and Network Connectivity Data
        <br />
        - Online Identifier <br />
        - Person Name
        <br />
        - Technology Identifiers
        <br />- Telephony
      </Trans>
    ),
    source: (
      <Trans i18nKey="categoriesOfPersonalDataSource">
        - Licensee
        <br />
        - Affiliates
        <br />- Third Parties
      </Trans>
    ),
  },
  {
    category: 'Location of the Individual',
    description: (
      <Trans i18nKey="categoriesOfPersonalDataDescription5">
        - Appointments, Schedules, Calendar Entries
        <br />- Physical Location of the Individual
      </Trans>
    ),
    source: (
      <Trans i18nKey="categoriesOfPersonalDataSource">
        - Licensee
        <br />
        - Affiliates
        <br />- Third Parties
      </Trans>
    ),
  },
];

export const subProcessorsList = [
  {
    left: i18n.t('nameOfSubProcessor'),
    right: i18n.t('purpose'),
  },
  {
    left: ['IBM Belgium', 'IBM Romania', 'IBM Slovensko'],
    right: (
      <Trans i18nKey="subProcessorsPurpose1">
        - Service Support
        <br />
        - Development
        <br />- Infrastructure Maintenance
      </Trans>
    ),
  },
  {
    left: 'Parseur B. V.',
    right: i18n.t('emailParsing'),
  },
  {
    left: 'Sovren',
    right: i18n.t('documentParsing'),
  },
  {
    left: 'Zendesk, Inc',
    right: i18n.t('licenseeServiceSupport'),
  },
  {
    left: '8Base, Inc.',
    right: i18n.t('dataStorage'),
  },
];

const COOKIE_TYPES = {
  ESSENTIAL: i18n.t('essential'),
  FUNCTIONALITY: i18n.t('functionality'),
  ANALYTICS: i18n.t('analytics'),
};

export const INSTALLED_COOKIES = [
  {
    type: COOKIE_TYPES.ESSENTIAL,
    name: 'auth_token',
    provider: 'IBM Services Talent Matching Hub',
    purpose: i18n.t('authTokenPurpose'),
  },
  {
    type: COOKIE_TYPES.ESSENTIAL,
    name: 'cookiesAccepted',
    provider: 'IBM Services Talent Matching Hub',
    purpose: i18n.t('cookiesAcceptedPurpose'),
  },
  {
    type: COOKIE_TYPES.ESSENTIAL,
    name: 'connect.sid',
    provider: 'IBM Services Talent Matching Hub',
    purpose: i18n.t('connectSidPurpose'),
  },
  {
    type: COOKIE_TYPES.ESSENTIAL,
    name: 'refreshToken',
    provider: 'IBM Services Talent Matching Hub',
    purpose: i18n.t('refreshTokenPurpose'),
  },
  {
    type: COOKIE_TYPES.ESSENTIAL,
    name: '__cfduid',
    provider: 'IBM Services Talent Matching Hub',
    purpose: i18n.t('cfUidPurpose'),
  },
  {
    type: COOKIE_TYPES.ESSENTIAL,
    name: 'ZD-store',
    provider: 'Zendesk',
    purpose: i18n.t('zdStorePurpose'),
  },
  {
    type: COOKIE_TYPES.ESSENTIAL,
    name: 'ZD-arturos',
    provider: 'Zendesk',
    purpose: i18n.t('zdArturosPurpose'),
  },
  {
    type: COOKIE_TYPES.ESSENTIAL,
    name: 'ZD-suid',
    provider: 'Zendesk',
    purpose: i18n.t('zdSuidPurpose'),
  },
  {
    type: COOKIE_TYPES.ESSENTIAL,
    name: 'ZD-buid',
    provider: 'Zendesk',
    purpose: i18n.t('zdBuidPurpose'),
  },
  {
    type: COOKIE_TYPES.FUNCTIONALITY,
    name: 'VIEW_POSITION',
    provider: 'IBM Services Talent Matching Hub',
    purpose: i18n.t('viewPositionPurpose'),
  },
  {
    type: COOKIE_TYPES.FUNCTIONALITY,
    name: 'VIEW_CANDIDATE',
    provider: 'IBM Services Talent Matching Hub',
    purpose: i18n.t('viewCandidatePurpose'),
  },
];

export const informationUse = {
  type: LIST_TYPE_BULLET,
  list: [
    {
      content: i18n.t('informationUse_1'),
    },
    {
      content: [i18n.t('informationUse_2')],
    },
    {
      content: [i18n.t('informationUse_3')],
    },
    {
      content: [i18n.t('informationUse_4')],
    },
    {
      content: [i18n.t('informationUse_5')],
    },
    {
      content: [i18n.t('informationUse_6')],
    },
    {
      content: [i18n.t('informationUse_7')],
    },
    {
      content: [i18n.t('informationUse_8')],
    },
  ],
};
