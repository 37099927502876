import { gql } from '@apollo/client';
import { alakaUserFragment } from './UserQueries';

export const GET_ME = gql`
  query getMe {
    email @client
    role @client
  }
`;

export const GET_USER = gql`
  query getUserByEmail($email: String!) {
    email @client
    role @client
    user(email: $email) {
      id
      alakaUser {
        ...AlakaUser
      }
    }
  }
  ${alakaUserFragment}
`;

export const GET_ROLES = gql`
  query getRoles {
    rolesList {
      items {
        id
        name
      }
    }
  }
`;

export const GET_USERS_WITH_ROLES = gql`
  query getUserWithRoles($users: [ID!]!) {
    usersList(filter: { alakaUser: { id: { in: $users } } }) {
      items {
        id
        roles {
          items {
            id
          }
        }
        alakaUser {
          id
          systemRole
          projectRoles {
            items {
              id
              role
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_USER_ROLE = gql`
  mutation updateUserRole($id: ID!, $roles: [RoleKeyFilter!]) {
    userUpdate(filter: { id: $id }, data: { roles: { reconnect: $roles } }) {
      id
      alakaUser {
        ...AlakaUser
      }
    }
  }
  ${alakaUserFragment}
`;
