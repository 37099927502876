import React from 'react';
// Libraries
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
// components
import Name from './Name';

const Names = props => {
  const { className, staticNames, name, t, required } = props;
  if (!staticNames && !name) {
    return null;
  }
  return (
    <div className={`${className} names`}>
      <Name name="" className="rateInputs_cell-header" />
      {staticNames &&
        staticNames.map(staticName => {
          return <Name name={t(staticName.labelKey)} key={`rates_${staticName.key}`} />;
        })}
      <Name name={t(name)} required={required} />
    </div>
  );
};

Names.propTypes = {
  t: PropTypes.func.isRequired,
  name: PropTypes.string,
  staticNames: PropTypes.array,
  className: PropTypes.string,
  required: PropTypes.bool,
};
Names.defaultProps = {
  staticNames: null,
  name: null,
  className: '',
  required: false,
};

export default withTranslation()(Names);
