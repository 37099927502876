import React from 'react';
// Libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { gql } from '@apollo/client';
import { getAutocompleteQuery } from 'queries/SearchQueries';
// components
import AutoComplete from './AutoComplete';
import { loadOptions } from './loadOptions';
// styles
import { activeAutoCompleteStyles, disabledAutoCompleteStyles } from './AutoCompleteStyles';
import components from './AutoCompleteComponents';
import './autoCompleteCustom.scss';

const propTypes = {
  placeholder: PropTypes.string,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
  isLoading: PropTypes.bool,
  dataSource: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  id: PropTypes.string,
  createNew: PropTypes.bool,
  type: PropTypes.string.isRequired,
  customQuery: PropTypes.object,
  customQueryVariables: PropTypes.object,
  customDataParser: PropTypes.func,
  disabled: PropTypes.bool,
};

const defaultProps = {
  placeholder: 'Start typing ...',
  isMulti: false,
  closeMenuOnSelect: true,
  isClearable: true,
  isLoading: false,
  dataSource: undefined,
  value: null,
  onChange: () => {},
  onInputChange: () => {},
  createNew: false,
  customQuery: null,
  customQueryVariables: {},
  customDataParser: null,
  id: '',
  disabled: false,
};

const AutoCompleteCustom = props => {
  const { t } = useTranslation();
  const {
    value,
    onInputChange,
    dataSource,
    placeholder,
    closeMenuOnSelect,
    isMulti,
    isLoading,
    onChange,
    createNew,
    isClearable,
    id,
    type,
    customQuery,
    customQueryVariables,
    customDataParser,
    disabled,
  } = props;

  const finalQuery =
    customQuery ||
    gql`
      ${getAutocompleteQuery(type)}
    `;

  const autoCompleteStyles = disabled ? disabledAutoCompleteStyles : activeAutoCompleteStyles;

  return (
    <AutoComplete
      options={dataSource}
      isMulti={isMulti}
      components={components}
      closeMenuOnSelect={closeMenuOnSelect}
      styles={autoCompleteStyles}
      isLoading={isLoading}
      placeholder={placeholder}
      onChange={onChange}
      loadOptions={(search, prevOptions) =>
        loadOptions(search, prevOptions, finalQuery, customQueryVariables, type, customDataParser)
      }
      value={value}
      createNew={createNew}
      isClearable={isClearable}
      onInputChange={onInputChange}
      className={`autocomplete-container test_${id}`}
      noOptionsMessage={() => t('noResultsFound')}
      debounceTimeout={300}
      disabled={disabled}
    />
  );
};

AutoCompleteCustom.propTypes = propTypes;
AutoCompleteCustom.defaultProps = defaultProps;

export default AutoCompleteCustom;
