import getProperty from 'lodash/get';
// constants
import { COLOR } from 'constants/colors';
import { CANDIDATE_STATUS } from 'constants/candidateStatus';
import { SUBMISSION_STATUS } from 'constants/submissionStatus';
import {
  ADMIN_ROLE,
  STAFF_ROLE,
  SUPPLIER_ROLE,
  MANAGER_ROLE,
  USER_PENDING,
  USER_ACTIVE,
} from 'constants';

/**
 * Get color based on score
 * @param {number} score
 * @returns {string}
 */
export const getScoreColor = score => {
  if (score >= 75) {
    return COLOR.GREEN;
  }
  if (score >= 50) {
    return COLOR.BLUE;
  }
  if (score >= 25) {
    return COLOR.YELLOW;
  }
  if (score > 0) {
    return COLOR.RED;
  }

  return COLOR.GRAY;
};

export const getMatchHighlightColor = score => {
  if (score > 70) {
    return COLOR.GREEN;
  }
  if (score > 40) {
    return COLOR.BLUE;
  }
  if (score > 10) {
    return COLOR.ORANGE;
  }

  return COLOR.GRAY;
};

export const getColorFromLetters = name => {
  const number = name.charCodeAt(0);
  if (number > 80) {
    return COLOR.GREEN;
  }
  if (number > 70) {
    return COLOR.BLUE;
  }
  if (number >= 66) {
    return COLOR.RED;
  }

  return COLOR.YELLOW;
};

/**
 * @param {string} status
 * @param {string} [role]
 * @returns
 */
export const getStatusColor = (status, role) => {
  const statusMap = {
    [SUBMISSION_STATUS.NEW]: COLOR.RED,
    [SUBMISSION_STATUS.SUBMITTED]: {
      [MANAGER_ROLE]: COLOR.RED,
      default: COLOR.YELLOW,
    },
    [SUBMISSION_STATUS.OFFER]: COLOR.GREEN,
    [SUBMISSION_STATUS.ONBOARDING]: COLOR.GREEN,
    [SUBMISSION_STATUS.ONBOARDING_CANCELLED]: COLOR.GRAY,
    [SUBMISSION_STATUS.NOT_SUBMITTED]: COLOR.GRAY,
    [SUBMISSION_STATUS.NOT_SELECTED]: COLOR.GRAY,
    [SUBMISSION_STATUS.WITHDRAWN]: COLOR.GRAY,
    [SUBMISSION_STATUS.INTERVIEW]: COLOR.BLUE,
    [SUBMISSION_STATUS.DELIVERY]: COLOR.BLUE,
    [SUBMISSION_STATUS.OFF_BOARDING]: COLOR.GRAY,
    [SUBMISSION_STATUS.OFF_BOARDED]: COLOR.GRAY,
  };

  return (
    getProperty(statusMap, [status, role]) ||
    getProperty(statusMap, [status, 'default']) ||
    getProperty(statusMap, status, '')
  );
};

export const getRoleColor = {
  [ADMIN_ROLE]: 'atrament',
  [STAFF_ROLE]: 'atrament',
  [SUPPLIER_ROLE]: 'brownish',
  [MANAGER_ROLE]: 'manager',
  default: 'atrament',
};

export const getUserStatusColor = status => {
  const colors = {
    [USER_ACTIVE]: COLOR.GREEN,
    [USER_PENDING]: COLOR.BLUE,
  };

  return getProperty(colors, status, COLOR.GRAY);
};

export const getCandidateStatusColor = status => {
  const colors = {
    [CANDIDATE_STATUS.AVAILABLE]: COLOR.BLUE,
    [CANDIDATE_STATUS.UNAVAILABLE]: COLOR.RED,
    [CANDIDATE_STATUS.AVAILABLE_FROM]: COLOR.YELLOW,
  };

  return getProperty(colors, status, COLOR.GRAY);
};
