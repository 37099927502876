import getProperty from 'lodash/get';
// constants
import {
  APPROXIMATE_WORKING_HOURS_PER_YEAR,
  INTEGRATOR_FEE,
  CSA_FEE,
  DEFAULT_TARGET_MARGIN,
} from 'constants/rates';
// helpers
import { formatNumber, roundNumber } from 'helpers/Number';

/**
 * @typedef {Object} Rate
 * @property {string} [id]
 * @property {value} number
 * @property {startDate} string
 * @property {currency} string
 */

/**
 * @param {number} hours
 * @returns {number}
 */
export const calculateYearPurchased = hours => {
  const yearPurchased = hours > 0 ? hours / (APPROXIMATE_WORKING_HOURS_PER_YEAR / 100) : 0;

  return yearPurchased > 100 ? 100 : formatNumber(yearPurchased);
};

/**
 * @param {number} hours
 * @returns {number}
 */
export const calculateManYearDiscount = hours => {
  const percentage = calculateYearPurchased(hours);

  if (percentage < 75) {
    return 0;
  }
  if (percentage >= 75 && percentage < 100) {
    return 1;
  }

  return 2;
};

/**
 * @param {number} nteRate
 * @param {number} [targetMargin]
 * @returns {number}
 */
export const calculateTargetRate = (nteRate, targetMargin = DEFAULT_TARGET_MARGIN) => {
  return roundNumber(formatNumber(nteRate - nteRate * (targetMargin / 100)));
};

/**
 *
 * @param {number} nteRate
 * @param {number} targetRate
 * @returns {number}
 */
export const calculateTargetMargin = (nteRate, targetRate) => {
  return formatNumber(((nteRate - targetRate) / nteRate) * 100);
};

/**
 * @param {number[]} nteRates
 * @param {number} targetMargin
 * @returns {number[]}
 */
export const calculateTargetRates = (nteRates, targetMargin) => {
  return nteRates.map(nteRate => calculateTargetRate(nteRate, targetMargin));
};

/**
 * @param {number} targetRate
 * @param {number} ibmFee
 * @returns {number}
 */
export const calculateSellPriceFromTargetRate = (targetRate, ibmFee) => {
  const costPlusIntegratorFee = targetRate * (1 + CSA_FEE / 100) * (1 + INTEGRATOR_FEE / 100);

  return roundNumber(formatNumber(costPlusIntegratorFee * (1 + ibmFee / 100)));
};

/**
 * @param {number[]} targetRates
 * @param {number} ibmFee
 * @returns {number[]}
 */
export const calculateSellPricesFromTargetRates = (targetRates, ibmFee) => {
  return targetRates.map(rate => {
    return calculateSellPriceFromTargetRate(rate, ibmFee);
  });
};

/**
 * @param {number[]} targetRates
 * @param {number} ibmFee
 * @param {number} manYearDiscount
 * @returns {number[]}
 */
export const calculatePositionGPsFromIbmFee = (targetRates, ibmFee, manYearDiscount) => {
  return targetRates.map(rate => {
    const costPlusIntegratorFee = rate * (1 + CSA_FEE / 100) * (1 + INTEGRATOR_FEE / 100);
    const grossSellPrice = costPlusIntegratorFee * (1 + ibmFee / 100);
    const totalDiscounts = grossSellPrice * (manYearDiscount / 100);
    const price = grossSellPrice - totalDiscounts;

    return formatNumber(((price - costPlusIntegratorFee) / price) * 100);
  });
};

/**
 * @param {number} targetRate
 * @param {number} sellPrice
 * @param {number} manYearDiscount
 * @returns {number}
 */
export const calculatePositionGPFromSellPrice = (targetRate, sellPrice, manYearDiscount) => {
  const totalDiscounts = sellPrice * (manYearDiscount / 100);
  const price = sellPrice - totalDiscounts;

  return formatNumber(((price - targetRate) / price) * 100);
};

/**
 * @param {number[]} targetRates
 * @param {number[]} sellPrices
 * @param {number} manYearDiscount
 * @returns {number[]}
 */
export const calculatePositionGPsFromSellPrice = (targetRates, sellPrices, manYearDiscount) => {
  return targetRates.map((rate, index) => {
    const sellPrice = sellPrices[index];
    return calculatePositionGPFromSellPrice(rate, sellPrice, manYearDiscount);
  });
};

/**
 * @param {number} supplierRate
 * @param {number} ibmFee
 * @param {bool} isDirectSupplier
 * @param {bool} includeCSAFee
 * @returns {number}
 */
export const calculateCandidateSellPriceFromIbmFee = (
  supplierRate,
  ibmFee,
  isDirectSupplier,
  includeCSAFee
) => {
  const csaFee = includeCSAFee ? CSA_FEE : 0;
  const integratorFee = isDirectSupplier ? 0 : INTEGRATOR_FEE;
  const costPlusIntegratorFee = supplierRate * (1 + csaFee / 100) * (1 + integratorFee / 100);

  return formatNumber(costPlusIntegratorFee * (1 + ibmFee / 100));
};

/**
 * @param {number[]} supplierRates
 * @param {number} ibmFee
 * @param {bool} isDirectSupplier
 * @param {bool} includeCSAFee
 * @returns {number[]}
 */
export const calculateCandidateSellPricesFromIbmFee = (
  supplierRates,
  ibmFee,
  isDirectSupplier,
  includeCSAFee
) => {
  return supplierRates.map(supplierRate =>
    calculateCandidateSellPriceFromIbmFee(supplierRate, ibmFee, isDirectSupplier, includeCSAFee)
  );
};

/**
 * @param {number} supplierRate
 * @param {number} dGP
 * @param {number} manYearDiscount
 * @param {bool} isDirectSupplier
 * @param {bool} includeCSAFee
 * @returns {number}
 */
export const calculateCandidateSellPriceFromGP = (
  supplierRate,
  dGP,
  manYearDiscount,
  isDirectSupplier,
  includeCSAFee
) => {
  const csaFee = includeCSAFee ? CSA_FEE : 0;
  const integratorFee = isDirectSupplier ? 0 : INTEGRATOR_FEE;
  const costPlusIntegratorFee = supplierRate * (1 + csaFee / 100) * (1 + integratorFee / 100);
  const sellPrice = costPlusIntegratorFee / ((1 - dGP / 100) * (1 - manYearDiscount / 100));

  return formatNumber(sellPrice);
};

/**
 * @param {number} supplierRate
 * @param {number} sellPrice
 * @param {number} manYearDiscount
 * @param {boolean} isDirectSupplier
 * @param {boolean} includeCSAFee
 * @returns {number}
 */
export const calculateCandidateGPFromSellPrice = (
  supplierRate,
  sellPrice,
  manYearDiscount,
  isDirectSupplier,
  includeCSAFee
) => {
  const csaFee = includeCSAFee ? CSA_FEE : 0;
  const integratorFee = isDirectSupplier ? 0 : INTEGRATOR_FEE;
  const costPlusIntegratorFee = supplierRate * (1 + csaFee / 100) * (1 + integratorFee / 100);
  const discount = sellPrice * (manYearDiscount / 100);
  const price = sellPrice - discount;

  return formatNumber(((price - costPlusIntegratorFee) / price) * 100);
};

/**
 * @param {number[]} supplierRates
 * @param {number[]} sellPrices
 * @param {number} manYearDiscount
 * @param {bool} isDirectSupplier
 * @param {bool} includeCSAFee
 * @returns {number[]}
 */
export const calculateCandidateGPsFromSellPrice = (
  supplierRates,
  sellPrices,
  manYearDiscount,
  isDirectSupplier,
  includeCSAFee
) => {
  return supplierRates.map((supplierRate, index) => {
    const sellPrice = getProperty(sellPrices, index, 0);

    return calculateCandidateGPFromSellPrice(
      supplierRate,
      sellPrice,
      manYearDiscount,
      isDirectSupplier,
      includeCSAFee
    );
  });
};

/**
 *
 * @param {Rate[]} nteRates
 * @param {Rate[]} targetRates
 * @returns {number} - target margin
 */
export const getTargetMargin = (nteRates = [], targetRates = []) => {
  // return default value when there're no rates
  // or they're not saved just calculated with default target margin
  if (!nteRates.length || !targetRates.length || targetRates.some(rate => !rate || !rate.id)) {
    return DEFAULT_TARGET_MARGIN;
  }

  // calculate target margin based on first year rate of saved target rates
  return calculateTargetMargin(
    getProperty(nteRates, '0.value', 0),
    getProperty(targetRates, '0.value', 0)
  );
};
