import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// components
import Field from 'components/molecules/FieldSection';
import RadioList from 'components/molecules/RadioList';
import CollapsibleContent from 'components/molecules/CollapsibleContent';
import SubmissionRejection from 'components/molecules/SubmissionRejection';
// constants
import { FIELD } from './constants';
// styles
import styles from './selectWinner.module.scss';

const propTypes = {
  formData: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

const RejectionFields = ({ formData, onChange }) => {
  const { t } = useTranslation();
  const hasRejectionReason = formData[FIELD.HAS_REJECTION_REASON].value;
  const hasSameRejectionReason = formData[FIELD.SAME_REJECTION_REASON].value;
  const rejectedSubmissions = formData[FIELD.NOT_SELECTED_SUBMISSIONS].value;
  const rejectionReasons = formData[FIELD.REASONS].value;
  const boolOptions = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ];

  const handleChange = field => value => {
    onChange({ ...formData, [field]: { ...formData[field], value } });
  };

  const handleRejectionReasonChange = id => value => {
    handleChange(FIELD.REASONS)({
      ...rejectionReasons,
      [id]: value,
    });
  };

  return (
    <React.Fragment>
      {rejectedSubmissions.length > 0 && (
        <div className="m-t-30">
          <Field
            title={t('clientProvidedReasonOfRejection')}
            classes={{ content: styles.sectionContent }}
            error={formData[FIELD.HAS_REJECTION_REASON].error}
            errorMessages={formData[FIELD.HAS_REJECTION_REASON].errorMessages}
          >
            <RadioList
              options={boolOptions}
              selected={hasRejectionReason}
              onChange={handleChange(FIELD.HAS_REJECTION_REASON)}
            />
          </Field>
        </div>
      )}
      {hasRejectionReason && rejectedSubmissions.length > 1 && (
        <div className="m-t-30">
          <Field
            title={t('reasonIsSame')}
            classes={{ content: styles.sectionContent }}
            error={formData[FIELD.SAME_REJECTION_REASON].error}
            errorMessages={formData[FIELD.SAME_REJECTION_REASON].errorMessages}
          >
            <RadioList
              options={boolOptions}
              selected={hasSameRejectionReason}
              onChange={handleChange(FIELD.SAME_REJECTION_REASON)}
            />
          </Field>
        </div>
      )}
      {hasRejectionReason && (hasSameRejectionReason || rejectedSubmissions.length === 1) && (
        <div className="m-t-30">
          <SubmissionRejection
            reason={formData[FIELD.REASON].value}
            onChange={handleChange(FIELD.REASON)}
            errors={formData[FIELD.REASON].errorMessages}
          />
        </div>
      )}
      {hasRejectionReason && hasSameRejectionReason === false && rejectedSubmissions.length > 1 && (
        <div className="m-t-30">
          <Field
            title={t('rejectionReasons')}
            classes={{ content: styles.sectionContent }}
            error={formData[FIELD.REASONS].error}
            errorMessages={formData[FIELD.REASONS].errorMessages}
          >
            {rejectedSubmissions.map((submission, index) => (
              <div
                key={submission.id}
                className={classNames(styles.submissionReason, { 'm-t-10': index })}
              >
                <CollapsibleContent
                  collapsed
                  header={<span className={styles.submissionName}>{submission.getName()}</span>}
                >
                  <SubmissionRejection
                    reason={rejectionReasons[submission.id] || ''}
                    onChange={handleRejectionReasonChange(submission.id)}
                  />
                </CollapsibleContent>
              </div>
            ))}
          </Field>
        </div>
      )}
    </React.Fragment>
  );
};

RejectionFields.propTypes = propTypes;

export default RejectionFields;
