import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
// components
import Icon from 'components/atoms/Icon';
import Arrow from './Arrow';
// helpers
import { getContentFromType } from './Helpers';

const propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  color: PropTypes.string,
};

const defaultProps = {
  type: null,
  label: null,
  color: 'grayLight',
};

const ArrowType = props => {
  const { type, label, color } = props;

  const { title, icon } = getContentFromType(type);

  if (title) {
    return (
      <Tooltip title={title} TransitionProps={{ timeout: 600 }}>
        <span>
          <Arrow
            color={color}
            {...props}
            content={
              <React.Fragment>
                {icon ? (
                  <div className="display-flex-row verticalCenter">
                    <span style={{ width: '18px', paddingRight: '5px' }}>
                      <Icon color="white" name={icon} size={18} />
                    </span>{' '}
                    {label}
                  </div>
                ) : (
                  label
                )}
              </React.Fragment>
            }
          />
        </span>
      </Tooltip>
    );
  }

  return null;
};

ArrowType.propTypes = propTypes;
ArrowType.defaultProps = defaultProps;

export default ArrowType;
