import { FileType } from '../types';

const getFileType = (extension: string): FileType => {
  if (['pdf'].includes(extension)) {
    return 'pdf';
  }
  if (['png', 'jpeg', 'jpg'].includes(extension)) {
    return 'image';
  }
  if (['csv'].includes(extension)) {
    return 'csv';
  }
  if (['xls', 'xlsx'].includes(extension)) {
    return 'spreadsheet';
  }
  if (['txt'].includes(extension)) {
    return 'text';
  }
  if (['dot', 'dotx', 'doc', 'docx'].includes(extension)) {
    return 'msWord';
  }
  if (['zip', 'rar'].includes(extension)) {
    return 'compress';
  }

  return 'other';
};

export default getFileType;
