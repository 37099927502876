import React from 'react';
// Libraries
import { useTranslation } from 'react-i18next';
import hasProperty from 'lodash/has';
import PropTypes from 'prop-types';
// components
import Names from './Names';
import Rates from './Rates';
// styles
import './rateInputs.scss';

const propTypes = {
  values: PropTypes.object,
  numberOfYears: PropTypes.number,
  rates: PropTypes.object,
  name: PropTypes.string.isRequired,
  staticValues: PropTypes.array,
  inputNameKey: PropTypes.string,
  required: PropTypes.bool,
  hideNames: PropTypes.bool,
};

const defaultProps = {
  values: null,
  numberOfYears: null,
  rates: null,
  staticValues: null,
  inputNameKey: null,
  required: false,
  hideNames: false,
};

const RateInputs = props => {
  const {
    hideNames,
    numberOfYears,
    rates,
    staticValues,
    inputNameKey,
    name,
    required,
    values,
  } = props;
  const { t } = useTranslation();
  if (!numberOfYears && !rates) {
    return null;
  }

  const valuesNames = staticValues
    ? staticValues.filter(({ key }) => {
        if (rates) {
          return Object.values(rates).some(rate => hasProperty(rate, key));
        }
        return false;
      })
    : null;

  const getErrors = () => {
    if (values) {
      const error = Object.values(values).find(val => val && val.error && val.errorMessageKey);
      if (error) {
        return <div className="field_requiredWarning">{t(error.errorMessageKey)}</div>;
      }
    }
    return null;
  };

  return (
    <div className={`test_${name}`}>
      <div className="rateInputs">
        {!hideNames && (
          <Names
            className="rateInputs_names"
            name={inputNameKey}
            staticNames={valuesNames}
            required={required}
          />
        )}
        <Rates className="rateInputs_rates" {...props} staticValues={valuesNames} />
      </div>
      {getErrors()}
    </div>
  );
};

RateInputs.propTypes = propTypes;
RateInputs.defaultProps = defaultProps;

export default RateInputs;
