import React, { useState, useContext } from 'react';
// libraries
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import hasProperty from 'lodash/has';
import getProperty from 'lodash/get';
// context
import ToastContext from 'context/ToastContext';
// components
import Button from 'components/atoms/Button';
import ComponentParser from 'components/organisms/ComponentParser';
import Loader from 'components/atoms/Loader';
// helpers
import { roundNumber } from 'helpers/Number';
import { checkRequired } from './helpers';

const propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  onSubmitLabel: PropTypes.string,
  defaultData: PropTypes.object,
  form: PropTypes.array,
  className: PropTypes.string,
  loading: PropTypes.bool,
  moreInfo: PropTypes.any,
  disabledComponents: PropTypes.array,
};

const defaultProps = {
  onCancel: () => {},
  onSubmit: () => {},
  onSubmitLabel: null,
  defaultData: {},
  form: [],
  className: '',
  loading: false,
  moreInfo: {},
  disabledComponents: [],
};

const EditForm = ({
  moreInfo,
  onCancel,
  onSubmit,
  onSubmitLabel,
  defaultData,
  form,
  className,
  loading,
  disabledComponents,
}) => {
  const [formData, setFormData] = useState(defaultData);
  const [error, setError] = useState(false);
  const { addToast } = useContext(ToastContext);
  const { t } = useTranslation();

  const updateFormData = (key, value) => {
    const id = formData[key] && formData[key].id ? formData[key].id : null;
    let changedData = { [key]: id ? { ...value, id } : value };

    // Auto-calculation of total budget based on formula effort * rate
    if (key === 'effort' && formData.rate) {
      const rate = getProperty(formData, 'rate.value', 0);
      const effort = getProperty(value, 'value', 0);
      const totalBudget = parseFloat(roundNumber(effort * rate));

      changedData = {
        ...changedData,
        totalBudget: {
          ...formData.totalBudget,
          value: totalBudget,
        },
      };
    }

    const updatedForm = { ...formData, ...changedData };
    setFormData(updatedForm);
    if (error) {
      setError(false);
    }
  };

  const submit = () => {
    const areRequiredFields = checkRequired(formData, form);
    if (areRequiredFields) {
      onSubmit(formData);
    } else if (!error) {
      setError(true);
      addToast.error(t('fillAllRequiredFields'));
    }
  };

  return (
    <div className={`${className} display-flex flex1 w100 p-t-20`}>
      {form &&
        form.map((component, index) => {
          const required = getProperty(component, 'validate.required');
          const requiredError =
            !getProperty(formData, `${component.key}`) ||
            (hasProperty(formData, `${component.key}.value`) &&
              !getProperty(formData, `${component.key}.value`)) ||
            getProperty(formData, `${component.key}.requiredError`);
          const isError = error && required && requiredError;
          const disabled = disabledComponents.includes(component.key);

          return (
            <ComponentParser
              name={component.key}
              key={component.key}
              disabled={disabled}
              moreInfo={moreInfo}
              className={`component_container ${isError ? 'redForm' : ''} ${
                index === form.length - 1 ? 'm-0' : ''
              }`}
              component={component}
              updateFormData={updateFormData}
              formData={formData}
              error={isError}
            />
          );
        })}
      <div className="display-flex-row flexEnd p-t-30">
        {loading ? (
          <div style={{ marginRight: '60px', marginTop: '10px' }}>
            <Loader small />
          </div>
        ) : (
          <React.Fragment>
            <span className="p-r-12">
              <Button label={t('cancel')} onClick={onCancel} secondary />
            </span>
            <Button label={onSubmitLabel || t('submit')} onClick={submit} />
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

EditForm.propTypes = propTypes;
EditForm.defaultProps = defaultProps;

export default EditForm;
