import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// components
import Radio from './Radio';

const propTypes = {
  className: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.object),
  name: PropTypes.string.isRequired,
};

const defaultProps = {
  className: '',
  list: [],
};

const RadioCustom = props => {
  const { className, list, name } = props;

  return (
    <div className={`${className} radioCustom test_${name}`}>
      {list &&
        list.length > 0 &&
        list.map((item, index) => {
          return (
            <div key={item.label} className={classNames({ 'm-t-12': index })}>
              <Radio item={item} {...props} />
            </div>
          );
        })}
    </div>
  );
};

RadioCustom.propTypes = propTypes;
RadioCustom.defaultProps = defaultProps;

export default RadioCustom;
