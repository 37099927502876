import axios from 'services/Axios';
import getProperty from 'lodash/get';
import { FILES_ENDPOINT } from 'constants/box';

/**
 * @typedef {Object} DownloadFileResponse
 * @property {string} file File binary encoded in base64
 * @property {string} filename
 * @property {string} mime
 */

/**
 * Download file from box
 * @param {string} id
 * @returns {Promise<DownloadFileResponse>}
 */
export const download = async id => {
  if (!id) throw new Error('"id" is required parameter');

  const response = await axios.get(`${FILES_ENDPOINT}/${id}`);

  return response.data;
};

/**
 * Upload file to box
 * @param {File} file
 * @param {string} [filename] If filename is not explicitly provided, it will be read from file
 * @param {string} [folder] Folder path where file will be stored e.g RFQs/D-12345. By default it saves in root folder
 * @returns {Promise<string>} id of newly created file
 */
export const upload = async (file, filename, folder = '') => {
  if (!file) throw new Error('"file" is required parameter');

  const formData = new FormData();

  formData.append('file', file);
  formData.append('filename', filename || file.name);
  formData.append('folder', folder);

  const response = await axios.post(`${FILES_ENDPOINT}/file`, formData);

  return response.data.id;
};

/**
 * Copy file to different folder
 * @param {string} fileId Box file id
 * @param {string} folder Folder path where file will be copied
 * @returns {Promise<string>} id of moved file
 */
export const copy = async (fileId, folder) => {
  const file = await axios.post(`${FILES_ENDPOINT}/copy`, { id: fileId, folder });

  return getProperty(file, 'data.id');
};

/**
 * Move file to different folder
 * @param {string} fileId Box file id
 * @param {string} folder Folder path where file will be moved
 * @returns {Promise<string>} id of moved file
 */
export const move = async (fileId, folder) => {
  const response = await axios.post(`${FILES_ENDPOINT}/move`, { id: fileId, folder });

  return response.data.id;
};

/**
 * @typedef {Object} FileInput
 * @property {File} file
 * @property {string} [filename] If filename is not explicitly provided, it will be read from file
 */

/**
 * Upload multiple files to box
 * @param {FileInput[]} files
 * @param {string} [folder] Folder path where file will be stored e.g RFQs/D-12345. By default it saves in root folder
 * @returns {Promise<string[]>} ids of newly created files
 */
export const bulkUpload = async (files, folder = '') => {
  if (!files.length) {
    throw new Error('No files were provided');
  }

  const formData = new FormData();
  formData.append('folder', folder);
  files.forEach(({ file, filename }) => {
    formData.append(filename || file.name, file);
  });

  const response = await axios.post(`${FILES_ENDPOINT}/files`, formData);

  return response.data.ids;
};

/**
 * Delete file from box
 * @param {string} id
 * @returns {Promise}
 */
export const remove = async id => {
  if (!id) throw new Error('"id" is required parameter');

  return axios.delete(`${FILES_ENDPOINT}/${id}`);
};

/**
 * Create folder in box
 * @param {string} folder Folder path for folder to create
 * @returns {Promise<string>} Folder id
 */
export const createFolder = async folder => {
  const response = await axios.post(`${FILES_ENDPOINT}/folder`, { folder });

  return response.data.id;
};
