// helpers
import { getPositionMainStatusText } from 'helpers/NameMapping';
// constants
import {
  MAIN_RECRUITING,
  MAIN_SELECTION,
  MAIN_ONBOARDING,
  MAIN_DELIVERY,
  MAIN_CLOSED,
} from 'constants/positionStatus';

export const statuses = [
  {
    id: MAIN_RECRUITING,
    value: MAIN_RECRUITING,
    label: getPositionMainStatusText(MAIN_RECRUITING),
  },
  {
    id: MAIN_SELECTION,
    value: MAIN_SELECTION,
    label: getPositionMainStatusText(MAIN_SELECTION),
  },
  {
    id: MAIN_ONBOARDING,
    value: MAIN_ONBOARDING,
    label: getPositionMainStatusText(MAIN_ONBOARDING),
  },
  {
    id: MAIN_DELIVERY,
    value: MAIN_DELIVERY,
    label: getPositionMainStatusText(MAIN_DELIVERY),
  },
  {
    id: MAIN_CLOSED,
    value: MAIN_CLOSED,
    label: getPositionMainStatusText(MAIN_CLOSED),
  },
];
