import i18n from 'i18next';

export const STEPS = {
  RATES_SETTING: 'ratesSetting',
  TARGET_RATES: 'rates',
  PARTNER_DUE_DATE: 'partnerDueDate',
  AUDIENCE: 'audience',
  NOTES: 'notes',
  REVIEW: 'preview',
};

export const RATE_SETTING = {
  SHOW_TARGET_RATES_TO_PARTNERS: 'showTargetRatesToPartners',
  SHOW_NTE_RATES_TO_PARTNERS: 'showNteRatesToPartners',
  ALLOW_SUBMISSION_ABOVE_TARGET_RATES: 'allowSubmissionAboveTargetRates',
};

export const TABS = [
  {
    id: STEPS.TARGET_RATES,
    label: i18n.t('rates'),
  },
  {
    id: STEPS.PARTNER_DUE_DATE,
    label: i18n.t('dueDate'),
  },
  {
    id: STEPS.AUDIENCE,
    label: i18n.t('audience'),
  },
  {
    id: STEPS.NOTES,
    label: i18n.t('notes'),
  },
  {
    id: STEPS.REVIEW,
    label: i18n.t('review'),
  },
];

export const PUBLISH_IDENTIFIERS = {
  ALL_PARTNERS: 'allPartners',
  AUDIENCE: 'audience',
};

export const PUBLISH_OPTIONS = [
  {
    name: PUBLISH_IDENTIFIERS.ALL_PARTNERS,
    label: i18n.t('publishToAllPartners'),
    value: PUBLISH_IDENTIFIERS.ALL_PARTNERS,
  },
  {
    name: PUBLISH_IDENTIFIERS.AUDIENCE,
    label: i18n.t('publishSelectPartners'),
    value: PUBLISH_IDENTIFIERS.AUDIENCE,
  },
];
