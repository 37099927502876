import React from 'react';
// Libraries
import PropTypes from 'prop-types';
// components
import Autocompletes from './Autocompletes';
import ComplexComponents from './ComplexComponents';
import Pickers from './Pickers';
import Selects from './Selects';
import Texts from './Texts';
import NoInputElements from './NoInputElements';

const autocompleteTypes = ['autocomplete', 'autocompleteCustom', 'inputGroups'];
const complexTypes = ['survey', 'rates', 'projectRoles'];
const pickerTypes = ['datetime', 'file', 'pieChart'];
const textTypes = ['textfield', 'number', 'email', 'phoneNumber', 'address', 'textarea', 'html'];
const noInputElements = ['htmlelement', 'linkElement'];
const selectTypes = [
  'select',
  'selectPreFill',
  'selectNoOptions',
  'selectSchemaOptions',
  'radio',
  'radioTableOptions',
  'levels',
  'checkbox',
];

const getComponent = props => {
  const { component } = props;
  const { type } = component || {};
  if (textTypes.includes(type)) {
    return <Texts {...props} />;
  }
  if (selectTypes.includes(type)) {
    return <Selects {...props} />;
  }
  if (autocompleteTypes.includes(type)) {
    return <Autocompletes {...props} />;
  }
  if (pickerTypes.includes(type)) {
    return <Pickers {...props} />;
  }
  if (noInputElements.includes(type)) {
    return <NoInputElements {...props} />;
  }
  if (complexTypes.includes(type)) {
    return <ComplexComponents {...props} />;
  }
  return null;
};

getComponent.propTypes = {
  component: PropTypes.object.isRequired,
};

export default getComponent;
