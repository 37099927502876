import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { LIST_TYPE_NUMBER, LIST_TYPE_BULLET } from 'constants/list';
// components
import Section from 'components/atoms/Section';
import LevelList from 'components/molecules/LevelList';
import ProcessingTable from './ProcessingTable';
import PersonalDataTable from './PersonalDataTable';
import SubProcessorsTable from './SubProcessorsTable';
import TechnicalMeasuresTable from './TechnicalMeasuresTable';
// helpers
import { informationUse } from './Texts';

const DPAExhibit = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Section
        title={`4. ${t('processing')}`}
        content={
          <React.Fragment>
            {t('processingDescription')}
            <br />
            <br />
            <b>4.1 {t('natureOfProcessing')}</b>
            <div className="p-b-5 p-t-5">{t('natureOfProcessingDescription')}</div>
            <ProcessingTable />
          </React.Fragment>
        }
      />
      <Section
        title={`5. ${t('licenseePersonalData')}`}
        content={
          <LevelList
            firstLevelClassName="p-t-20"
            content={{
              title: t('licenseePersonalData'),
              type: LIST_TYPE_NUMBER,
              prevIndex: '5.',
              list: [
                {
                  title: t('categoriesOfDataSubjects'),
                  content: t('categoriesOfDataSubjectsDescription'),
                  type: LIST_TYPE_BULLET,
                  list: [
                    {
                      content: t('categoriesOfDataSubjectsDescription1'),
                    },
                    {
                      content: t('categoriesOfDataSubjectsDescription2'),
                    },
                    {
                      content: t('categoriesOfDataSubjectsDescription3'),
                    },
                    {
                      content: t('categoriesOfDataSubjectsDescription4'),
                    },
                    {
                      content: t('categoriesOfDataSubjectsDescription5'),
                    },
                    {
                      content: t('categoriesOfDataSubjectsDescription6'),
                    },
                  ],
                },
                {
                  title: t('categoriesOfPersonalData'),
                  content: (
                    <Trans i18nKey="categoriesOfPersonalDataDescription">
                      The types of personal data that generally can be processed through the Service
                      are listed in the following exhibit.
                      <PersonalDataTable
                        tableDescription={t('categoriesOfPersonalDataTableDescription')}
                      />
                      IBM may collect information about Licensee’s computer, including where
                      available IP address, operating system and browser type, for system
                      administration, support, and to collect aggregate information for internal
                      reporting purposes.
                    </Trans>
                  ),
                },
                {
                  title: t('typesOfPersonalDataSpecialCategoriesPD'),
                  content: (
                    <Trans i18nKey="typesOfPersonalDataSpecialCategoriesPDescription">
                      Given the nature of the Product, Licensee acknowledges that IBM is not able to
                      review data provided by Licensee to determine if it contains any Licensee
                      Personal Data including Special Categories of Personal Data outside the list
                      set out in Exhibit 1 or as may be provided by the Licensee.
                      <br />
                      <br />
                      Therefore, Licensee is responsible to provide IBM with, and keep updated,
                      lists of Personal Data that IBM can have access to during the Service by
                      contacting{' '}
                      <a href="mailto:talent.matching.hub@be.ibm.com">
                        talent.matching.hub@be.ibm.com
                      </a>
                      .
                      <br />
                      <br />
                      In the absence of other instructions from Licensee, it will be assumed that
                      during the Services IBM can have access, even incidentally, to all types of
                      data provided by Licensee. The technical and organization measures below will
                      be used by IBM to safeguard all type of Licensee Personal Data. If changes to
                      the above lists require changes of the agreed Processing, Licensee shall
                      provide Additional Instructions to IBM as set out in the DPA.
                    </Trans>
                  ),
                },
                {
                  title: t('technicalOrganizationalMeasures'),
                  content: (
                    <TechnicalMeasuresTable
                      tableDescription={t('technicalOrganizationalMeasuresTable')}
                    />
                  ),
                },
                {
                  title: t('subProcessorsList'),
                  content: (
                    <Trans i18nKey="subProcessorsListDescription">
                      IBM may use the following Subprocessor(s) in the Processing of Licensee
                      Personal Data:
                      <br />
                      <SubProcessorsTable />
                    </Trans>
                  ),
                },
                {
                  title: t('howDoWeUseInformation'),
                  content: (
                    <div>
                      <Trans
                        i18nKey="howDoWeUseInformationDescription"
                        components={[<b />, <br />]}
                      />
                      <LevelList content={informationUse} />
                      <br />
                    </div>
                  ),
                },
              ],
            }}
          />
        }
      />
    </React.Fragment>
  );
};

export default DPAExhibit;
