import React, { useContext } from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// components
import Placeholder from 'components/atoms/Placeholder';
import Loader from 'components/atoms/Loader';
// context
import ModalContext from './ModalContext';
// styles
import styles from './modalWindow.module.scss';

const propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const Body = ({ children, className }) => {
  const { loading, loadingText, size } = useContext(ModalContext);

  return (
    <div className={classNames(styles.body, className)}>
      <div className={classNames({ [styles.hiddenContent]: loading })}>{children}</div>
      {loading && (
        <div className={styles.loader}>
          <Loader fixedSize small={['sm', 'md'].includes(size)} />
          {loadingText && <Placeholder className="m-t-12">{loadingText}</Placeholder>}
        </div>
      )}
    </div>
  );
};

Body.propTypes = propTypes;
Body.defaultProps = defaultProps;

export default Body;
