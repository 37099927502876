import { gql } from '@apollo/client';
import moment from 'moment';
import getProperty from 'lodash/get';
import setProperty from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
// services
import client from 'services/Client';
import logger from 'services/Logger';
import notification from 'services/Notification';
import { sendEmail } from 'services/Mailer';
// queries
import { Revision } from 'queries/Fragments';
// helpers
import { getAbsoluteUrl } from 'helpers/Link';
import { formatHtmlTable } from 'helpers/Template';
// constants
import {
  RATES_PROPOSED_REVISION_RATE,
  REVISION_TYPES,
  REVISION_STATUS,
  NOTE_REVISION_TYPE,
} from 'constants';

const getRatesMutationData = revisionType => {
  const rateValue = getProperty(revisionType, 'value.rates.value', {});
  delete rateValue.requiredError;

  const CREATE_RATE_OBJECT = {
    currency: null,
    rateType: null,
    startDate: null,
    value: null,
  };

  return Object.values(rateValue).map(rate => {
    const createObject = cloneDeep(CREATE_RATE_OBJECT);
    const currency = getProperty(rate, 'currency');
    const value = getProperty(rate, 'value');
    const year = getProperty(rate, 'year');
    const startDate = moment()
      .year(year)
      .format('YYYY-MM-DD');

    setProperty(createObject, 'currency', currency);
    setProperty(createObject, 'value', value);
    setProperty(createObject, 'startDate', startDate);
    setProperty(createObject, 'rateType', RATES_PROPOSED_REVISION_RATE);

    return createObject;
  });
};

const parseData = (formData, userId) => {
  const REVISION_TYPE_CREATE = {
    status: {
      create: {
        start: '',
        status: '',
      },
    },
    reason: {
      create: {
        note: '',
        noteType: '',
      },
    },
    type: '',
  };

  const typesMutationData = Object.values(formData).map(revisionType => {
    const typeMutationData = cloneDeep(REVISION_TYPE_CREATE);
    const reason = getProperty(revisionType, 'value.note.value');
    const type = getProperty(revisionType, 'value.type.value');

    setProperty(typeMutationData, 'reason.create.note', reason);
    setProperty(typeMutationData, 'reason.create.author.connect.id', userId);
    setProperty(typeMutationData, 'reason.create.noteType', NOTE_REVISION_TYPE);
    setProperty(typeMutationData, 'type', type);
    setProperty(typeMutationData, 'status.create.status', REVISION_STATUS.PENDING);
    setProperty(typeMutationData, 'status.create.start', moment().toISOString());

    if (type === REVISION_TYPES.RATES) {
      const rates = getRatesMutationData(revisionType);
      setProperty(typeMutationData, 'rates.create', rates);
    }

    return typeMutationData;
  });

  return {
    revisions: {
      create: {
        statuses: {
          create: {
            status: REVISION_STATUS.PENDING,
            start: moment().toISOString(),
          },
        },
        types: { create: typesMutationData },
      },
    },
  };
};

export const CREATE_REVISION = gql`
  mutation createRevision($submissionId: ID!, $data: SubmissionUpdateInput!) {
    submissionUpdate(filter: { id: $submissionId }, data: $data) {
      id
      revisions(sort: { createdAt: DESC }) {
        items {
          ...Revision
        }
      }
    }
  }
  ${Revision}
`;

/**
 * @param {object} formData
 * @param {string} submissionId
 * @param {string} userId
 * @param {object} email
 * @param {string} email.html
 * @param {object} email.options
 */
const createRevision = async (formData, submissionId, userId, email) => {
  try {
    await client.mutate({
      mutation: CREATE_REVISION,
      variables: {
        submissionId,
        data: parseData(formData, userId),
      },
    });

    // email notification to supplier who submitted the candidate
    sendEmail({ ...email.options, html: formatHtmlTable(email.html) }).catch(error => {
      logger.exception(error, { email });
    });

    notification.submissionRevisionRequested({
      submission: submissionId,
      link: getAbsoluteUrl(`/submission/${submissionId}`),
      createdBy: userId,
    });
  } catch (error) {
    logger.exception(error, { formData, submissionId });
    throw error;
  }
};

export default createRevision;
