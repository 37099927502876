import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// components
import Placeholder from 'components/atoms/Placeholder';
import { CardsLoader } from 'components/molecules/ContentLoaders';
import OffsetBasedList from 'components/molecules/OffsetBasedList';
import PositionCard from 'components/organisms/PositionCard';
import ErrorPage from 'components/pages/ErrorPage';
// helpers
import parsePosition from 'helpers/PositionParser';
// queries
import { GET_POSITIONS_CARD_VIEW } from './queries';
// styles
import styles from './positionList.module.scss';

const propTypes = {
  filter: PropTypes.object.isRequired,
  sort: PropTypes.object,
  customVariables: PropTypes.object,
};

const defaultProps = {
  sort: { dueOn: 'ASC' },
  customVariables: {},
};

const PositionListCard = ({ filter, sort, customVariables }) => {
  const { t } = useTranslation();

  return (
    <OffsetBasedList
      query={GET_POSITIONS_CARD_VIEW}
      variables={{ filter, sort, ...customVariables }}
      clientName="newServer"
    >
      {({ data, error, loading }) => {
        if (loading) {
          return <CardsLoader />;
        }
        if (error) {
          return <ErrorPage code={500} />;
        }
        if (!data.length) {
          return (
            <Placeholder size="lg" icon="assignment">
              {t('noPositions')}
            </Placeholder>
          );
        }

        return (
          <div className={styles.grid}>
            {data.map(position => (
              <PositionCard position={parsePosition(position)} key={position.id} />
            ))}
          </div>
        );
      }}
    </OffsetBasedList>
  );
};

PositionListCard.propTypes = propTypes;
PositionListCard.defaultProps = defaultProps;

export default PositionListCard;
