import React, { useCallback } from 'react';
import classNames from 'classnames';
import FormFieldError from './FormFieldError';
import styles from './formField.module.scss';

interface FormFieldProps {
  label: string;
  error?: boolean;
  errorText?: string;
  htmlFor?: string;
  required?: boolean;
  looseGap?: boolean;
  helperText?: string;
  children: React.ReactNode;
}

const FormField = ({
  label,
  error = false,
  errorText = '',
  htmlFor,
  required,
  looseGap = false,
  helperText,
  children,
}: FormFieldProps) => {
  const renderRequiredLabel = useCallback(() => {
    if (required === undefined) {
      return null;
    }

    return (
      <p>
        {required ? (
          <span className={styles.required}>*</span>
        ) : (
          <span className={styles.optional}>Optional</span>
        )}
      </p>
    );
  }, [required]);

  return (
    <div>
      <div className={classNames(styles.header, { [styles.looseGap]: looseGap })}>
        <div className={styles.labelWrapper}>
          {htmlFor ? (
            <label
              htmlFor={htmlFor}
              className={classNames(styles.labelText, { [styles.error]: error })}
            >
              {label}
            </label>
          ) : (
            <div className={classNames(styles.labelText, { [styles.error]: error })}>{label}</div>
          )}
          {renderRequiredLabel()}
        </div>
        {helperText && <p className={styles.helperText}>{helperText}</p>}
      </div>
      {children}
      {error && errorText && <FormFieldError message={errorText} />}
    </div>
  );
};

export default FormField;
