import { ApolloClient } from '@apollo/client';
// local store data
import typeDefs from './typeDefs';
import resolvers from './resolvers';
import cache from './cache';
import links from './links';

const client = new ApolloClient({
  link: links(),
  cache,
  typeDefs,
  resolvers,
  defaultOptions: {
    query: {
      errorPolicy: 'none',
      fetchPolicy: 'network-only',
    },
    mutation: {
      errorPolicy: 'none',
    },
    watchQuery: {
      fetchPolicy: 'network-only',
    },
  },
});

export default client;
