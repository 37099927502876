import { ApolloLink } from '@apollo/client';
// services
import { getToken, tokenHasExpired } from 'services/Authentication';
// helpers
import { refreshTokenAndRetryRequest } from '../helpers';

// Add auth token in header before each request middleware
const authLink = () =>
  new ApolloLink((operation, forward) => {
    // Retrieve the authorization token from local storage.
    const token = getToken();

    if (token && tokenHasExpired(token)) {
      return refreshTokenAndRetryRequest(operation, forward);
    }

    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    });

    return forward(operation);
  });

export default authLink;
