import * as d3 from 'd3';
import colorPalette from 'dependencies/materialStyles/Colors';

// Final calculation of X and Y ax coordinates
const calculateRadialCoordinates = (angle, offset = 10) => {
  return { x: Math.cos(angle - Math.PI / 2) * offset, y: Math.sin(angle - Math.PI / 2) * offset };
};

// Get coordinates of pointer in circular chart
const getTriangleCoordinates = (percentage, offset = 10) => {
  const percentScale = d3
    .scaleLinear()
    .domain([0, 100])
    .range([0, 2 * Math.PI]);

  const newPercentage = percentScale(percentage);

  return calculateRadialCoordinates(newPercentage, offset);
};

const LABEL_SIZES = {
  MIN: {
    X: 68,
    X_SMALL: 58,
    X_BIG: 74,
    Y: 17,
  },
  MEDIAN: {
    X: 74,
    X_SMALL: 64,
    X_BIG: 80,
    Y: 17,
  },
  MAX: { X: 72, X_SMALL: 62, X_BIG: 78, Y: 17 },
};

// Get rotation degree for pointer in gauge chart
const getRotationDegByPercentage = percentage => {
  // Degree range is full of the circle
  const degreeRange = 360;
  // Degree of necessary rotation when percentage is 0
  const startDegree = 180;
  return -(startDegree - (degreeRange / 100) * percentage);
};

// Get position offset of labels
const getHorizontalLabelOffset = (percentage, size) => {
  if (percentage >= 0 && percentage < 10) {
    return size.X_SMALL / 2;
  }
  if (percentage < 50) {
    return size.X / 2;
  }
  if (percentage === 100) {
    return -size.X_BIG / 2;
  }
  return -size.X / 2;
};

const absDifference = (a, b) => {
  return Math.abs(a - b);
};

// Build Min - Med - Max circle
const getOuterCircle = ({ min, median, max, color }) => {
  const width = 310;
  const height = 200;
  const radius = 194;

  const data = [min, max - min, 100 - max];
  const colors = ['transparent', color, 'transparent'];

  const outerCircle = document.createElement('div');

  // construct default pie layout
  const pie = d3.pie().sort(null);

  // construct arc generator
  const arc = d3
    .arc()
    .cornerRadius(4)
    .outerRadius(radius * 0.44)
    .innerRadius(radius * 0.4);

  // creates the chart container
  const svg = d3
    .select(outerCircle)
    .append('svg')
    .attr('width', width)
    .attr('height', height)
    .append('g')
    .attr('transform', `translate(${width / 2},${height / 2})`);

  // enter data and draw pie chart
  svg
    .append('g')
    .datum(data)
    .selectAll('path')
    .data(pie)
    .enter()
    .append('path')
    .attr('fill', (_d, i) => {
      return colors[i];
    })
    .attr('d', arc);

  // min, max, median triangles
  const triangle = d3
    .symbol()
    .type(d3.symbolTriangle)
    .size(110);

  const triangleRadius = radius / 2 - 7;
  const minTriangleCoordinates = getTriangleCoordinates(min, triangleRadius);
  const minTriangleRotationDeg = getRotationDegByPercentage(min);
  const medianTriangleCoordinates = getTriangleCoordinates(median, triangleRadius);
  const medianTriangleRotationDeg = getRotationDegByPercentage(median);
  const maxTriangleCoordinates = getTriangleCoordinates(max, triangleRadius);
  const maxTriangleRotationDeg = getRotationDegByPercentage(max);

  const showMedian = absDifference(min, median) > 3 && absDifference(median, max) > 3;

  svg
    .append('svg:path')
    .attr('d', triangle)
    .attr('fill', colorPalette.gray5)
    .attr(
      'transform',
      `rotate(${minTriangleRotationDeg} ${minTriangleCoordinates.x} ${minTriangleCoordinates.y}) translate(${minTriangleCoordinates.x}, ${minTriangleCoordinates.y}) `
    );

  if (showMedian) {
    svg
      .append('svg:path')
      .attr('d', triangle)
      .attr('fill', colorPalette.gray5)
      .attr(
        'transform',
        `rotate(${medianTriangleRotationDeg} ${medianTriangleCoordinates.x} ${medianTriangleCoordinates.y}) translate(${medianTriangleCoordinates.x}, ${medianTriangleCoordinates.y}) `
      );
  }

  svg
    .append('svg:path')
    .attr('d', triangle)
    .attr('fill', colorPalette.gray5)
    .attr(
      'transform',
      `rotate(${maxTriangleRotationDeg} ${maxTriangleCoordinates.x} ${maxTriangleCoordinates.y}) translate(${maxTriangleCoordinates.x}, ${maxTriangleCoordinates.y}) `
    );

  // min, max, median labels
  const minXCoordinates = minTriangleCoordinates.x + getHorizontalLabelOffset(min, LABEL_SIZES.MIN);
  const minYCoordinates = minTriangleCoordinates.y;
  const medianXCoordinates =
    medianTriangleCoordinates.x + getHorizontalLabelOffset(median, LABEL_SIZES.MEDIAN);
  const medianYCoordinates = medianTriangleCoordinates.y;
  const maxXCoordinates = maxTriangleCoordinates.x + getHorizontalLabelOffset(max, LABEL_SIZES.MAX);
  const maxYCoordinates = maxTriangleCoordinates.y;

  svg
    .append('text')
    .attr('x', minXCoordinates)
    .attr('y', minYCoordinates)
    .style('alignment-baseline', 'central')
    .style('dominant-baseline', 'central')
    .style('text-anchor', 'middle')
    .style('font-size', '14px')
    .style('font-family', 'Lato-Bold')
    .style('fill', colorPalette.gray7)
    .text(`MIN ${min}`);

  if (showMedian) {
    svg
      .append('text')
      .attr('x', medianXCoordinates)
      .attr('y', medianYCoordinates)
      .style('alignment-baseline', 'central')
      .style('dominant-baseline', 'central')
      .style('text-anchor', 'middle')
      .style('font-size', '14px')
      .style('font-family', 'Lato-Bold')
      .style('fill', colorPalette.gray7)
      .text(`MED ${median}`);
  }

  svg
    .append('text')
    .attr('x', maxXCoordinates)
    .attr('y', maxYCoordinates)
    .style('alignment-baseline', 'central')
    .style('dominant-baseline', 'central')
    .style('text-anchor', 'middle')
    .style('font-size', '14px')
    .style('font-family', 'Lato-Bold')
    .style('fill', colorPalette.gray7)
    .text(`MAX ${max}`);

  return outerCircle;
};

export default getOuterCircle;
