import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// components
import Document from '../Document';
// styles
import styles from './review.module.scss';

const documentPropTypes = PropTypes.shape({
  boxId: PropTypes.string,
  filename: PropTypes.string.isRequired,
});

const propTypes = {
  releasableSubmissionFile: documentPropTypes,
  releasableAttestation: documentPropTypes,
  disabled: PropTypes.bool,
};

const defaultProps = {
  releasableSubmissionFile: null,
  releasableAttestation: null,
  disabled: false,
};

const Documents = ({ releasableSubmissionFile, releasableAttestation, disabled }) => {
  const { t } = useTranslation();

  if (!releasableSubmissionFile && !releasableAttestation) {
    return (
      <div className={classNames(styles.errorText, { [styles.disabledText]: disabled })}>
        {t('missingProposal&AttestationError')}
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="m-b-5">
        {releasableSubmissionFile ? (
          <Document
            boxId={releasableSubmissionFile.boxId}
            filename={releasableSubmissionFile.filename}
            disabled={disabled}
          />
        ) : (
          <div className={classNames(styles.errorText, { [styles.disabledText]: disabled })}>
            {t('missingProposalError')}
          </div>
        )}
      </div>
      {releasableAttestation ? (
        <Document
          boxId={releasableAttestation.boxId}
          filename={releasableAttestation.filename}
          disabled={disabled}
        />
      ) : (
        <div className={classNames(styles.errorText, { [styles.disabledText]: disabled })}>
          {t('missingAttestationError')}
        </div>
      )}
    </React.Fragment>
  );
};

Documents.propTypes = propTypes;
Documents.defaultProps = defaultProps;

export default Documents;
