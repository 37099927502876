import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
// components
import Icon from 'components/atoms/Icon';

const propTypes = {
  t: PropTypes.func.isRequired,
};

const ErrorPlaceholder = ({ t }) => (
  <div className="oneRow">
    <Icon name="error" size={20} />
    <span className="m-l-5">{t('errorPlaceholderText')}</span>
  </div>
);

ErrorPlaceholder.propTypes = propTypes;

export default withTranslation()(ErrorPlaceholder);
