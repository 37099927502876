import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// components
import ClickableElement from 'components/atoms/ClickableElement';
import PositionSettings from 'components/organisms/PositionSettings';
// helpers
import { preventDefault } from 'helpers/HtmlDOM';
// styles
import styles from './positionCard.module.scss';

const propTypes = {
  position: PropTypes.object.isRequired,
  visible: PropTypes.bool,
};

const defaultProps = {
  visible: false,
};

const Controls = ({ position, visible }) => {
  return (
    <ClickableElement
      className={classNames(styles.controls, { [styles.active]: visible })}
      onClick={preventDefault}
    >
      <div className={styles.control}>
        <PositionSettings position={position} onlySecondaryActions />
      </div>
    </ClickableElement>
  );
};

Controls.propTypes = propTypes;
Controls.defaultProps = defaultProps;

export default Controls;
