import React from 'react';
// libraries
import PropTypes from 'prop-types';
import moment from 'moment';
import getProperty from 'lodash/get';
import hasProperty from 'lodash/has';
// components
import DatePickerCustom from 'components/atoms/DatePickerCustom';
import FileUpload from 'components/molecules/FileUpload';
// helpers
import { boxFileUrl } from 'helpers/Box';

const propTypes = {
  component: PropTypes.object,
  formData: PropTypes.any,
  updateFormData: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

const defaultProps = {
  component: {},
  formData: {},
  disabled: false,
  readOnly: false,
};

const TODAY = 'today';

function Pickers(props) {
  const { component, updateFormData, formData, disabled, readOnly } = props;
  const { type, key, allowMultiple } = component;

  const getDatePickerValue = () => {
    const formDataValue = getProperty(formData, [key, 'value']);
    const datePickerValue = formDataValue || formData[key];
    const dateDefaultValue = component.defaultValue === '' ? null : component.defaultValue;

    return datePickerValue || dateDefaultValue;
  };

  const getMinDate = () => {
    const minDate = getProperty(component, 'properties.minDate');
    if (minDate) {
      if (minDate === TODAY) {
        return moment();
      }
      const date = moment(getProperty(formData, [minDate, 'value'], '1900-01-01'));
      const addDays = getProperty(component, 'properties.addDays');
      if (addDays) {
        return date.add(addDays, 'days');
      }
      return date;
    }
    return moment('1900-01-01');
  };

  const getMaxDate = () => {
    const maxDate = getProperty(component, 'properties.maxDate');
    if (maxDate) {
      if (maxDate === TODAY) {
        return moment();
      }
      const date = moment(getProperty(formData, [maxDate, 'value'], '1900-01-01'));

      return date;
    }
    return undefined;
  };

  const getFileUploadValue = () => {
    let files = [];

    if (hasProperty(formData, [key, 'value'])) {
      files = formData[key].value || [];
    } else {
      files = getProperty(formData, key) || [];
    }

    return files.map(file => ({
      ...file,
      id: file.id || file.boxId,
      link: file.boxId ? boxFileUrl(file.boxId) : '',
      name: file.name || getProperty(file, 'file.name', ''),
      size: file.size || getProperty(file, 'file.size', 0),
    }));
  };

  switch (type) {
    case 'datetime':
      return (
        <DatePickerCustom
          value={getDatePickerValue()}
          enableTime={component.enableTime}
          id={key}
          onChange={value => updateFormData(key, { value })}
          minDate={getMinDate()}
          maxDate={getMaxDate()}
          disabled={disabled}
        />
      );
    case 'file':
      return (
        <FileUpload
          className={`test_${key}`}
          selected={getFileUploadValue()}
          onChange={files => updateFormData(key, files.length ? files : null)}
          accept=".pdf,.dot,.dotx,.doc,.docx,.png,.jpeg,.jpg,.txt,.xlsx,.xls"
          multiple={allowMultiple}
          disabled={component.disabled || disabled}
          readOnly={component.readOnly || readOnly}
          error={component.error}
        />
      );
    default:
      return null;
  }
}

Pickers.propTypes = propTypes;
Pickers.defaultProps = defaultProps;

export default Pickers;
