export const MAIN_RECRUITING = 'RECRUITING';
export const MAIN_SELECTION = 'SELECTION';
export const MAIN_ONBOARDING = 'ONBOARDING';
export const MAIN_DELIVERY = 'DELIVERY';
export const MAIN_CLOSED = 'CLOSED';
export const MAIN_CANCELLED = 'CANCELLED';

export const POSITION_STATUS = {
  UNPUBLISHED: 'UNPUBLISHED',
  PUBLISHED: 'PUBLISHED',
  PENDING_SELECTION: 'PENDING_SELECTION',
  OFFER: 'OFFER',
  INTERVIEW: 'INTERVIEW',
  ONBOARDING: 'ONBOARDING',
  DELIVERY: 'DELIVERY',
  OFFBOARDING: 'OFFBOARDING',
  CLOSED: 'CLOSED',
  ONBOARDING_CANCELLED: 'ONBOARDING_CANCELLED',
  DELIVERY_CANCELLED: 'DELIVERY_CANCELLED',
  NO_SELECTION: 'NO_SELECTION',
  NO_SUBMISSIONS: 'NO_SUBMISSIONS',
  CANCELLED: 'CANCELLED',
  REPLACEMENT: 'REPLACEMENT',
};
