import React from 'react';
// libraries
import PropTypes from 'prop-types';
// components
import Score from 'components/atoms/Score';
// constants
import { SUPPORTED_COLORS } from 'constants/colors';
// helpers
import { getSecondaryColor } from './helpers';
import OuterScore from './OuterScore';
// styles
import styles from './advancedScore.module.scss';

const propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  median: PropTypes.number.isRequired,
  score: PropTypes.number.isRequired,
  color: PropTypes.oneOf(SUPPORTED_COLORS),
};

const defaultProps = {
  color: null,
};

const AdvancedScore = ({ score, min, median, max, color }) => {
  const secondary = getSecondaryColor(color);

  return (
    <div className={styles.container}>
      <Score score={score} size={140} strokeWidth={6} />
      <div className={styles.outerCircle}>
        <OuterScore min={min} median={median} max={max} color={secondary} />
      </div>
    </div>
  );
};

AdvancedScore.propTypes = propTypes;
AdvancedScore.defaultProps = defaultProps;

export default AdvancedScore;
