import { gql } from '@apollo/client';
import moment from 'moment';
// services
import client from 'services/Client';
import logger from 'services/Logger';
import notification from 'services/Notification';
// queries
import { Note, PositionStatus, SubmissionStatus, OnboardingStartDate } from 'queries/Fragments';
// helpers
import { getSubmissionLink } from 'helpers/Link';
// constants
import { NOTE_ONBOARDING_CANCELLED } from 'constants';
import { SUBMISSION_STATUS } from 'constants/submissionStatus';
import { POSITION_STATUS } from 'constants/positionStatus';
import { SUBMISSION_TAB } from 'constants/pageTabs';
import { HIRING_PROCESS_STATUS, ONBOARDING_TAB, START_DATE_STATUS } from 'constants/onboarding';

const DECLINE_START_DATE_BY_PM_SUBMISSION = gql`
  mutation declineStartDateByPMSubmission($submissionId: ID!, $data: SubmissionUpdateInput!) {
    submissionUpdate(filter: { id: $submissionId }, data: $data) {
      id
      position {
        id
        statuses(sort: { createdAt: DESC }) {
          items {
            ...PositionStatus
          }
        }
      }
      statuses(sort: { createdAt: DESC }) {
        items {
          ...SubmissionStatus
        }
      }
      hiringProcess {
        id
        status
        cancelledStep {
          ...SubmissionStatus
        }
        notes {
          items {
            ...Note
          }
        }
        dates {
          items {
            ...OnboardingStartDate
          }
        }
      }
    }
  }
  ${PositionStatus}
  ${SubmissionStatus}
  ${Note}
  ${OnboardingStartDate}
`;

const DECLINE_START_DATE_BY_PM_PROPOSAL = gql`
  mutation declineStartDateByPMProposal($submissionId: ID!, $data: ProposalUpdateInput!) {
    proposalUpdate(filter: { id: $submissionId }, data: $data) {
      id
      position {
        id
        statuses(sort: { createdAt: DESC }) {
          items {
            ...PositionStatus
          }
        }
      }
      statuses(sort: { createdAt: DESC }) {
        items {
          ...SubmissionStatus
        }
      }
      hiringProcess {
        id
        status
        cancelledStep {
          ...SubmissionStatus
        }
        notes {
          items {
            ...Note
          }
        }
        dates {
          items {
            ...OnboardingStartDate
          }
        }
      }
    }
  }
  ${PositionStatus}
  ${SubmissionStatus}
  ${Note}
  ${OnboardingStartDate}
`;

/**
 * Decline start date by Project Manager
 * Step: Start Date - Confirm Start Date
 * Role: Project Manager
 *
 * Actions:
 * 1. Set hiring process status as CANCELLED
 * 2. Add connection to cancelledStep field in hiringProcess to current hiring status
 * 3. Add note with type REJECTION_REASON
 * 4. Change status of candidate from OFFER to ONBOARDING_CANCELLED
 * 5. Update Start Date status to REJECTED
 * 6. Change status of position to ONBOARDING_CANCELLED
 *
 * @param {object} data
 * @param {object} data.position
 * @param {object} data.submission
 * @param {string} data.currentOnboardingStatusId Latest hiring status id
 * @param {string} data.currentStatusId Latest submission status id
 * @param {string} data.startDateId
 * @param {string} data.rejectionReason
 * @param {string} data.userId Logged in user id
 */
const declineStartDateByProjectManager = async ({
  position,
  submission,
  currentOnboardingStatusId,
  currentStatusId,
  startDateId,
  rejectionReason,
  userId,
}) => {
  const positionStatus = position.getStatus();
  const hiringProcessMutationData = {
    status: HIRING_PROCESS_STATUS.CANCELLED,
    cancelledStep: { connect: { id: currentOnboardingStatusId } },
    notes: {
      create: {
        note: rejectionReason,
        noteType: NOTE_ONBOARDING_CANCELLED,
        author: { connect: { id: userId } },
      },
    },
    dates: {
      update: {
        filter: { id: startDateId },
        data: {
          status: START_DATE_STATUS.REJECTED,
        },
      },
    },
  };

  const mutationData = {
    statuses: {
      update: {
        filter: { id: currentStatusId },
        data: { end: moment().toISOString() },
      },
      create: {
        start: moment().toISOString(),
        name: SUBMISSION_STATUS.ONBOARDING_CANCELLED,
      },
    },
    hiringProcess: {
      update: hiringProcessMutationData,
    },
  };

  // change position status to ONBOARDING_CANCELLED
  if ([POSITION_STATUS.OFFER, POSITION_STATUS.ONBOARDING].includes(positionStatus.value)) {
    mutationData.position = {
      update: {
        statuses: {
          update: {
            filter: { id: positionStatus.id },
            data: { end: moment().toISOString() },
          },
          create: {
            start: moment().toISOString(),
            name: POSITION_STATUS.ONBOARDING_CANCELLED,
          },
        },
      },
    };
  }

  try {
    await client.mutate({
      mutation: submission.isProposal()
        ? DECLINE_START_DATE_BY_PM_PROPOSAL
        : DECLINE_START_DATE_BY_PM_SUBMISSION,
      variables: {
        submissionId: submission.id,
        data: mutationData,
      },
      update: cache => {
        cache.evict({ fieldName: ['submissionsList', 'positionsList', 'proposalsList'] });
        cache.gc();
      },
    });

    const link = getSubmissionLink(submission.id, submission.isProposal(), {
      queryParams: {
        step: ONBOARDING_TAB.START_DATE,
        tab: SUBMISSION_TAB.ONBOARDING,
      },
    });

    notification.onboardingStartDateRejectedByPM({
      submission: submission.id,
      link,
      createdBy: userId,
      isProposal: submission.isProposal(),
    });
  } catch (error) {
    logger.exception(error);
    throw error;
  }
};

export default declineStartDateByProjectManager;
