import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// constants
import { LIMIT_SIZE_TABLE } from 'constants';
// helpers
import parsePosition from 'helpers/PositionParser';
// components
import Placeholder from 'components/atoms/Placeholder';
import { TableLoader } from 'components/molecules/ContentLoaders';
import OffsetBasedList from 'components/molecules/OffsetBasedList';
import PositionTable from 'components/organisms/PositionTable';
import ErrorPage from 'components/pages/ErrorPage';
// queries
import { GET_POSITIONS_TABLE_VIEW } from './queries';

const propTypes = {
  filter: PropTypes.object.isRequired,
  sort: PropTypes.object,
  customVariables: PropTypes.object,
};

const defaultProps = {
  sort: { dueOn: 'ASC' },
  customVariables: {},
};

const PositionListTable = ({ filter, sort, customVariables }) => {
  const { t } = useTranslation();

  return (
    <OffsetBasedList
      query={GET_POSITIONS_TABLE_VIEW}
      variables={{ filter, sort, ...customVariables }}
      size={LIMIT_SIZE_TABLE}
      clientName="newServer"
    >
      {({ data, error, loading }) => {
        if (loading) {
          return <TableLoader />;
        }
        if (error) {
          return <ErrorPage code={500} />;
        }
        if (!data.length) {
          return (
            <Placeholder size="lg" icon="assignment">
              {t('noPositions')}
            </Placeholder>
          );
        }

        return <PositionTable positions={data.map(parsePosition)} />;
      }}
    </OffsetBasedList>
  );
};

PositionListTable.propTypes = propTypes;
PositionListTable.defaultProps = defaultProps;

export default PositionListTable;
