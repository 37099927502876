import React, { useState } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// components
import MRIBanner from 'components/molecules/MRIBanner';
import MRIModal from 'components/organisms/MRIModal';
// styles
import styles from './candidatePage.module.scss';

const propTypes = {
  report: PropTypes.object.isRequired,
  candidate: PropTypes.string.isRequired,
};

const CVReadability = ({ report, candidate }) => {
  const { t } = useTranslation();
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <React.Fragment>
      <p className={styles.bannerTitle}>{t('cvReadability')}</p>
      <MRIBanner
        mriScore={report.cvReadabilityLevel}
        onClick={() => setModalOpen(true)}
        className={styles.banner}
        noDescription
      />
      <MRIModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        candidateName={candidate}
        shCandidateId={report.shCandidateId}
        cv={report.cv}
      />
    </React.Fragment>
  );
};

CVReadability.propTypes = propTypes;

export default CVReadability;
