import i18n from 'i18next';

export const ACTIONS = {
  ACCEPT: 'accept',
  REJECT: 'reject',
};

export const RATE_LABELS = {
  proposedRates: i18n.t('proposedRates'),
  sellPrice: i18n.t('supplierRate'),
};
