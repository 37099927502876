import React from 'react';
// Libraries
import PropTypes from 'prop-types';
// components
import ErrorPage from 'components/pages/ErrorPage';
import NotificationSettings from 'components/organisms/NotificationSettings';
// constants
import { TAB_NOTIFICATIONS } from 'constants';

const propTypes = {
  currentTab: PropTypes.string.isRequired,
};

const SettingsContent = ({ currentTab }) => {
  if (currentTab === TAB_NOTIFICATIONS) {
    return <NotificationSettings />;
  }
  return <ErrorPage code={404} />;
};

SettingsContent.propTypes = propTypes;

export default SettingsContent;
