import React from 'react';
// libraries
import PropTypes from 'prop-types';
// components
import Tooltip from 'components/atoms/Tooltip';
import Icon from 'components/atoms/Icon';
import TruncatedText from 'components/atoms/TruncatedText';
import colors from 'dependencies/materialStyles/Colors';
// style
import style from './PositionCallToActions.module.scss';

const propTypes = {
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  labelsOnly: PropTypes.bool,
  iconAndLabels: PropTypes.bool,
};

const defaultProps = {
  tooltip: '',
  labelsOnly: false,
  iconAndLabels: false,
};

const Resolution = ({ label, tooltip, labelsOnly, iconAndLabels }) => {
  if (labelsOnly) {
    return `${label}. ${tooltip}`;
  }

  if (iconAndLabels) {
    const newLabel = `${label}. ${tooltip}`;
    return (
      <div className="d-flex align-items-center">
        <Tooltip title={newLabel}>
          <div className="d-inline-flex m-r-5">
            <Icon name="infoOutline" size={20} color={colors.gray6} />
          </div>
        </Tooltip>
        <TruncatedText>{newLabel}</TruncatedText>
      </div>
    );
  }

  return (
    <Tooltip title={tooltip}>
      <div className={style.resolution} role="presentation" onClick={e => e.preventDefault()}>
        <div className={style.icon}>
          <Icon name="infoOutline" size={20} color={colors.gray8} />
        </div>
        <div className={style.resolutionLabel}>{label}</div>
      </div>
    </Tooltip>
  );
};

Resolution.propTypes = propTypes;
Resolution.defaultProps = defaultProps;

export default Resolution;
