import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// components
import Icon from 'components/atoms/Icon';
// styles
import styles from './saveIcon.module.scss';

const propTypes = {
  highlighted: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.number,
};

const defaultProps = {
  highlighted: false,
  disabled: false,
  size: 18,
};

const SaveIcon = ({ highlighted, disabled, size }) => {
  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.disabled]: disabled,
        [styles.highlighted]: highlighted,
      })}
    >
      <Icon name="save" size={size} />
      <div className={styles.doneIcon}>
        <Icon name="done" size={size - 2} />
      </div>
    </div>
  );
};

SaveIcon.propTypes = propTypes;
SaveIcon.defaultProps = defaultProps;

export default SaveIcon;
