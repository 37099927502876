import DetailPage from './DetailPage';
import Aside from './Aside';
import Body from './Body';
import Navigation from './Navigation';
import Content from './Content';

DetailPage.Aside = Aside;
DetailPage.Body = Body;
DetailPage.Navigation = Navigation;
DetailPage.Content = Content;

export default DetailPage;
