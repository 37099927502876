import client from '@services/client';
import { gql } from '@services/gql';
import { SupplierFormData } from '../types';

const EDIT_SUPPLIER = gql(/* GraphQL */ `
  mutation editSupplier($id: ID!, $data: NewSupplierEditInput!) {
    supplierEdit(id: $id, data: $data) {
      success
    }
  }
`);

const editRecord = async (id: string, data: Partial<SupplierFormData>): Promise<void> => {
  await client.mutate({
    mutation: EDIT_SUPPLIER,
    variables: { id, data },
  });
};

export default editRecord;
