import React from 'react';
// libraries
import PropTypes from 'prop-types';
// components
import Tooltip from 'components/atoms/Tooltip';
import Icon from 'components/atoms/Icon';
// styles
import colors from 'dependencies/materialStyles/Colors';
import styles from '../positionCard.module.scss';

const propTypes = {
  icon: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  tooltip: PropTypes.string,
};

const defaultProps = {
  tooltip: '',
};

const MainInfoRecord = ({ icon, children, tooltip }) => {
  return (
    <div className={styles.info}>
      <Icon name={icon} color={colors.gray7} size={18} />
      <Tooltip title={tooltip}>
        <div>{children}</div>
      </Tooltip>
    </div>
  );
};

MainInfoRecord.propTypes = propTypes;
MainInfoRecord.defaultProps = defaultProps;

export default MainInfoRecord;
