// libraries
import { gql } from '@apollo/client';
import i18n from 'i18next';
import getProperty from 'lodash/get';
import setProperty from 'lodash/set';
// constants
import {
  CANDIDATE_TYPE,
  PROPOSAL_TYPE,
  POSITION,
  PROJECT_TYPE,
  CLIENT_TYPE,
  USER_TYPE,
  COUNT_DRAFT,
} from 'constants';
import { POSITION_FORM_TYPE } from 'constants/form';
// queries
import { CREATE_DRAFT, UPDATE_DRAFT } from 'queries/DraftQueries';
// services
import client from 'services/Client';
import logger from 'services/Logger';
// helpers
import { getListCount, setListCount } from 'helpers/ListCount';
import { uploadFormDocuments } from 'helpers/Form';

/**
 * @param {string} type Draft type (position, candidate..)
 * @returns {object}
 */
export const getDraftStructure = type => {
  switch (type) {
    case CANDIDATE_TYPE:
      return {
        name: i18n.t('candidate'),
        type: 'candidate',
        color: 'green',
        icon: 'candidates',
      };
    case PROPOSAL_TYPE:
      return {
        name: i18n.t('proposal'),
        type: 'proposal',
        color: 'orange',
        icon: 'proposal',
      };
    case POSITION_FORM_TYPE.FIXED_PRICE:
    case POSITION:
      return {
        name: i18n.t('position'),
        type: 'position',
        color: 'blue',
        icon: 'assignment',
      };
    case PROJECT_TYPE:
      return {
        name: i18n.t('project'),
        type: 'project',
        color: 'red',
        icon: 'project',
      };
    case CLIENT_TYPE:
      return {
        name: i18n.t('client'),
        type: 'client',
        color: 'yellow',
        icon: 'person',
      };
    case USER_TYPE:
      return {
        name: i18n.t('user'),
        type: 'user',
        color: 'orange',
        icon: 'users',
      };
    default:
      return {};
  }
};

export const DELETE_DRAFT = gql`
  mutation deleteDraft($id: ID!) {
    draftDelete(filter: { id: $id }) {
      success
    }
  }
`;

/**
 * @param {string} id Draft id
 */
export const deleteDraft = async id => {
  try {
    await client.mutate({
      mutation: DELETE_DRAFT,
      variables: { id },
      update: cache => {
        cache.modify({
          fields: {
            draftsList: (existing, { readField }) => {
              const count = getProperty(existing, 'count', 0);
              const items = getProperty(existing, 'items', []);

              return {
                count: count ? count - 1 : 0,
                items: items.filter(draftRef => {
                  return readField('id', draftRef) !== id;
                }),
              };
            },
          },
        });

        const currentCount = getListCount(COUNT_DRAFT);
        setListCount(COUNT_DRAFT, currentCount - 1);
      },
    });
  } catch (error) {
    logger.exception(error, { id });
    throw error;
  }
};

/**
 * @param {object} formData
 * @param {array[]} [fileComponents] File components key
 * @returns {Promise<object>}
 */
const uploadFilesForDraft = async (formData, fileComponents = []) => {
  const uploadedFilesDraft = {};

  // eslint-disable-next-line no-restricted-syntax
  for await (const fileComponent of fileComponents) {
    const files = getProperty(formData, [fileComponent, 'value']) || [];
    const filesToUpload = files.filter(file => !file.boxId);
    const existingFiles = files
      .filter(file => file.boxId)
      .map(({ name, boxId }) => ({ name, boxId }));

    if (filesToUpload.length) {
      const uploadedFiles = await uploadFormDocuments(filesToUpload, 'temp');
      const uploadedFilesValue = uploadedFiles.map(file => ({
        boxId: file.id,
        name: file.name,
      }));

      setProperty(
        uploadedFilesDraft,
        [fileComponent, 'value'],
        [...uploadedFilesValue, ...existingFiles]
      );
    }
  }

  return uploadedFilesDraft;
};

/**
 * @param {string} id Draft id
 * @param {object} formData
 * @param {array[]} [fileComponents] File components key
 */
export const updateDraft = async (id, formData, fileComponents = []) => {
  let draftData = formData;

  try {
    if (fileComponents && fileComponents.length > 0) {
      draftData = {
        ...draftData,
        ...(await uploadFilesForDraft(formData, fileComponents)),
      };
    }

    await client.mutate({
      mutation: UPDATE_DRAFT,
      variables: {
        id,
        draft: draftData,
      },
    });
  } catch (error) {
    logger.exception(error, { id, formData });
    throw error;
  }
};

/**
 * @param {string} name Draft name
 * @param {object} data.formData
 * @param {string} data.formId
 * @param {string} data.userId Logged in user id
 * @param {string} [data.urlParams] Optional url to append after /draft/:id
 * @param {string[]} [data.fileComponents] Id of components which are file component
 */
export const createDraft = async (name, data) => {
  const { formData, formId, userId, fileComponents, urlParams } = data;
  let draftData = formData;

  try {
    if (fileComponents && fileComponents.length > 0) {
      draftData = {
        ...draftData,
        ...(await uploadFilesForDraft(formData, fileComponents)),
      };
    }

    await client.mutate({
      mutation: CREATE_DRAFT,
      variables: {
        name,
        formId,
        userId,
        params: urlParams,
        draft: draftData,
      },
      update: cache => {
        cache.evict({ fieldName: 'draftsList' });
        cache.gc();
      },
    });
  } catch (error) {
    logger.exception(error, { name, data });
    throw error;
  }
};
