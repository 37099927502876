import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// styles
import styles from './linkButton.module.scss';

const propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  inline: PropTypes.bool,
};

const defaultProps = {
  className: '',
  inline: false,
};

const LinkButton = ({ className, onClick, children, inline }) => {
  return (
    <div
      role="button"
      tabIndex="0"
      className={classNames(
        styles.link,
        {
          [styles.inline]: inline,
          [className]: className,
        },
        'fs-unmask'
      )}
      onKeyPress={e => (e.keyCode === 13 ? onClick() : null)}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

LinkButton.propTypes = propTypes;
LinkButton.defaultProps = defaultProps;

export default LinkButton;
