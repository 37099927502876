import React from 'react';
// libraries
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// components
import Icon from 'components/atoms/Icon';
// services
import logger from 'services/Logger';

const propTypes = {
  children: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError(error) {
    logger.exception(error);

    return { hasError: true };
  }

  componentDidCatch(error) {
    this.setState({
      hasError: true,
    });

    logger.exception(error);
  }

  render() {
    const { hasError } = this.state;
    const { children, t } = this.props;

    if (hasError) {
      return (
        <div className="oneRow p-5">
          <Icon name="error" size={20} />
          <span className="m-l-5">{t('errorPlaceholderText')}</span>
        </div>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = propTypes;

export default withTranslation()(ErrorBoundary);
