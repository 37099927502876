/* eslint-disable import/order */
import React, { useState, useRef } from 'react';
// libraries
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// hooks
import useForceUpdate from 'hooks/useForceUpdate';
// components
import Section from 'components/molecules/FieldSection';
import SimulationLegend from 'components/molecules/SimulationLegend';
import Table from '../Table';
import SortPopover, { SORT_TYPE } from '../SortPopover';
import PreviouslySavedLegend from '../PreviouslySavedLegend';
import Submission from './Submission';
// constants
import { DEFAULT_DGP, DEFAULT_DGP_FP } from 'constants/rates';
import { RATES_FORMULA_LINK } from 'constants/box';
import { SORT_BY } from '../constants';
// helpers
import { scrollToElement } from '../helpers';
import { getElementWidth, hasVerticalScrollbar, getScrollbarWidth } from 'helpers/HtmlDOM';
// styles
import styles from './sellRates.module.scss';
import moduleStyles from '../submitCandidates.module.scss';

const propTypes = {
  isProposal: PropTypes.bool.isRequired,
  submissions: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  position: PropTypes.shape({
    title: PropTypes.string.isRequired,
    project: PropTypes.string.isRequired,
    experienceLevel: PropTypes.string.isRequired,
    occupations: PropTypes.arrayOf(PropTypes.string).isRequired,
    currency: PropTypes.string.isRequired,
    hours: PropTypes.number,
    manYearDiscount: PropTypes.number.isRequired,
    nteRates: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number.isRequired,
        year: PropTypes.number.isRequired,
      })
    ).isRequired,
  }).isRequired,
  onSubmissionUnselect: PropTypes.func.isRequired,
  onSubmissionRestore: PropTypes.func.isRequired,
  hasSavedSubmissions: PropTypes.bool.isRequired,
};

const SellRates = ({
  isProposal,
  submissions,
  onChange,
  onSort,
  position,
  onSubmissionUnselect,
  onSubmissionRestore,
  hasSavedSubmissions,
}) => {
  const forceUpdate = useForceUpdate();
  const tableElement = useRef();
  const bodyElement = useRef();
  const yearsElement = useRef();
  const { t } = useTranslation();
  const [rateSimulationId, setRateSimulationId] = useState('');

  const years = position.nteRates.map(rate => rate.year);
  const isScrollable = years.length > 4;

  const handleSimulationClick = id => {
    if (rateSimulationId === id) {
      setRateSimulationId('');
    } else {
      setRateSimulationId(id);
      scrollToElement(`SUBMISSION_MODAL_CANDIDATE_${id}`);
    }
  };

  const handleSort = sortBy => order => {
    onSort(sortBy, order);
  };

  const handleScroll = event => {
    // move sell rates years in header on scrolling
    yearsElement.current.style.transform = `translate3d(-${event.currentTarget.scrollLeft}px, 0, 0)`;
  };

  const getTableWidth = () => {
    // table width is required to fix RS position
    if (tableElement.current) {
      const tableWidth = getElementWidth(tableElement.current);

      if (hasVerticalScrollbar(bodyElement.current)) {
        return tableWidth - getScrollbarWidth();
      }

      return tableWidth;
    }

    return 0;
  };

  return (
    <Section
      title={t('submitCandidatesSellRatesTitle')}
      description={t('submitCandidatesSellRatesDescription', {
        defaultDGP: isProposal ? DEFAULT_DGP_FP : DEFAULT_DGP,
      })}
    >
      <Table
        ref={tableElement}
        style={{ '--numberOfYears': years.length, '--tableWidth': getTableWidth() }}
        className={classNames(styles.table, { [styles.scrollable]: isScrollable })}
      >
        <Table.Head className={classNames(styles.row, styles.head)}>
          <div className={classNames(styles.fixedColumn, { [styles.proposal]: isProposal })}>
            {!isProposal && (
              <Table.Title>
                <SortPopover type={SORT_TYPE.NUMBER} onSort={handleSort(SORT_BY.SCORE)}>
                  {t('score')}
                </SortPopover>
              </Table.Title>
            )}
            <Table.Title>
              <SortPopover type={SORT_TYPE.STRING} onSort={handleSort(SORT_BY.NAME)}>
                {t('name')}
              </SortPopover>
            </Table.Title>
            <Table.Title className="text-right">{t('partnerRate')}</Table.Title>
            <Table.Title className="text-right">{t('clientNte')}</Table.Title>
          </div>
          <div className={styles.scrollableColumn}>
            <Table.Title className="text-center">
              {t('sellRates')}
              {(!isProposal || years.length > 1) && (
                <div
                  ref={yearsElement}
                  className={classNames(styles.rates, moduleStyles.headerYears)}
                >
                  {years.map(year => (
                    <div key={year}>{year}</div>
                  ))}
                </div>
              )}
            </Table.Title>
          </div>
        </Table.Head>
        <Table.Body
          ref={bodyElement}
          className={classNames(styles.body, { [styles.bodyHeight]: rateSimulationId })}
          onScroll={isScrollable ? handleScroll : null}
        >
          {submissions.map(submission => {
            return (
              <div id={`SUBMISSION_MODAL_CANDIDATE_${submission.id}`} key={submission.id}>
                <Submission
                  key={submission.id}
                  isProposal={isProposal}
                  submission={submission}
                  onChange={onChange}
                  rateSimulationId={rateSimulationId}
                  onSimulationClick={handleSimulationClick}
                  onSubmissionUnselect={() => onSubmissionUnselect(submission.id)}
                  onSubmissionRestore={() => onSubmissionRestore(submission.id)}
                  // when RS is opened (transition finished) -> force re-render to recalculate table body width
                  onRatesSimulationOpened={forceUpdate}
                  disabled={submission.unselected}
                  position={position}
                  metrics={!isProposal ? submission.getMetrics() : null}
                />
              </div>
            );
          })}
        </Table.Body>
      </Table>
      <SimulationLegend link={RATES_FORMULA_LINK} className="m-t-12" />
      {hasSavedSubmissions && <PreviouslySavedLegend />}
    </Section>
  );
};

SellRates.propTypes = propTypes;

export default SellRates;
