import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// components
import Tooltip from 'components/atoms/Tooltip';
// helpers
import { calculateCandidateGPFromSellPrice } from 'helpers/RatesCalculation';
import { validateSellRate } from '../helpers';
// styles
import styles from './review.module.scss';
import moduleStyles from '../submitCandidates.module.scss';

const propTypes = {
  sellRate: PropTypes.number,
  partnerRate: PropTypes.number.isRequired,
  nteRate: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  manYearDiscount: PropTypes.number.isRequired,
  isDirectSupplier: PropTypes.bool.isRequired,
  includeCSAFee: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

const defaultProps = {
  sellRate: null,
  disabled: false,
};

const Rate = ({
  sellRate,
  partnerRate,
  nteRate,
  currency,
  manYearDiscount,
  isDirectSupplier,
  includeCSAFee,
  disabled,
}) => {
  const { t } = useTranslation();

  const dGP = calculateCandidateGPFromSellPrice(
    partnerRate,
    sellRate,
    manYearDiscount,
    isDirectSupplier,
    includeCSAFee
  );
  const rateErrorMessage = validateSellRate({
    partnerRate,
    sellRate,
    nteRate,
    manYearDiscount,
    isDirectSupplier,
    includeCSAFee,
  });

  const renderRateTooltip = () => {
    return (
      <div className={moduleStyles.tooltip}>
        {rateErrorMessage && (
          <div className="m-b-8">
            <b>{rateErrorMessage}</b>
          </div>
        )}
        <div className={styles.rateTable}>
          <b>{t('clientNte')}</b>
          <b>{t('partnerRate')}</b>
          <b>dGP</b>
          <span>
            {nteRate} {currency}
          </span>
          <span>
            {partnerRate} {currency}
          </span>
          <span>{sellRate ? dGP : '-'} %</span>
        </div>
      </div>
    );
  };

  return (
    <Tooltip title={renderRateTooltip()} noMaxWidth>
      <div
        className={classNames({
          [styles.error]: !!rateErrorMessage || !sellRate,
          [moduleStyles.disabledText]: disabled,
        })}
      >
        {sellRate || '-'} {currency}
      </div>
    </Tooltip>
  );
};

Rate.propTypes = propTypes;
Rate.defaultProps = defaultProps;

export default Rate;
