import colors from 'dependencies/materialStyles/Colors';

const radius = '3px';
const height = '28px';
const button = {
  borderRadius: radius,
  height,
  lineHeight: '14px',
  border: `1px solid`,
};
const buttonLabel = {
  textTransform: 'initial',
  fontSize: '14px',
  fontFamily: 'Lato',
};

const overlayStyle = {
  minWidth: '115px',
  borderRadius: radius,
  boxShadow: 'none',
};

const getButton = (primaryColor, secondaryColor) => {
  return {
    ...button,
    ...buttonLabel,
    ...overlayStyle,
    backgroundColor: colors[primaryColor],
    borderColor: colors[secondaryColor],
    color: colors[secondaryColor],
    padding: '3px 8px',
  };
};

const buttonStyle = {
  icon: {
    width: '30px',
    height: '30px',
    padding: 0,
  },
  iconW18: {
    width: '18px',
    height: '30px',
    padding: 0,
  },
  icon20: {
    height: '24px',
    width: '24px',
    padding: 0,
  },
  icon18: {
    padding: '0',
    width: '18px',
    height: '18px',
    left: 0,
  },
  icon18m: {
    padding: '0',
    width: '18px',
    height: '18px',
    marginTop: '-3px',
    left: 0,
  },
  smallIcon: {
    width: '20px',
    height: '20px',
  },
  icon0: {
    width: 'auto',
    height: 'auto',
    padding: 0,
  },
  xs: {
    width: 'auto',
    height: 'auto',
    padding: 2,
  },
  buttonblue: {
    ...getButton('blue', 'blue'),
    '&:hover': {
      backgroundColor: '#0E8FB4',
    },
  },
  buttondarkblue: {
    ...getButton('blue8', 'blue8'),
  },
  buttonred: {
    ...getButton('red', 'red'),
  },
  buttongreen: {
    ...getButton('green', 'green'),
  },
  buttonyellow: {
    ...getButton('yellow', 'yellow'),
  },
  buttongray: {
    ...getButton('disabledGray', 'disabledGray'),
    cursor: 'not-allowed',
  },
  buttonwhiteblue: {
    ...getButton('white', 'blue'),
    '&:hover': {
      backgroundColor: '#E7F6FB',
    },
  },
  buttonwhitetransparentblue: {
    ...getButton('transparent', 'blue8'),
  },
  buttonwhitedarkblue: {
    ...getButton('white', 'blue8'),
  },
  buttonwhitered: {
    ...getButton('white', 'red'),
  },
  buttonwhitegreen: {
    ...getButton('white', 'green'),
  },
  buttonwhiteyellow: {
    ...getButton('white', 'yellow'),
  },
  buttonwhitegray: {
    ...getButton('gray', 'gray'),
  },
  buttonLabel: {
    ...buttonLabel,
    color: 'white',
  },
  buttonLabelwhiteblue: {
    ...buttonLabel,
    color: colors.blue,
  },
  buttonLabelwhitered: {
    ...buttonLabel,
    color: colors.red,
  },
  buttonLabelwhitegreen: {
    ...buttonLabel,
    color: colors.green,
  },
  buttonLabelwhiteyellow: {
    ...buttonLabel,
    color: colors.yellow,
  },
};
export default buttonStyle;
