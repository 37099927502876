/* eslint-disable import/order */
import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import meanBy from 'lodash/meanBy';
// components
import Icon from 'components/atoms/Icon';
import Link from 'components/atoms/Link';
import Checkbox from 'components/atoms/Checkbox';
import Chip, { COLOR } from 'components/atoms/NewChip';
import TruncatedText from 'components/atoms/TruncatedText';
import Tooltip from 'components/atoms/Tooltip';
import Score from '../Score';
import Document from '../Document';
import SaveIcon from '../SaveIcon';
import RatesTooltip from './RatesTooltip';
// helpers
import { formatNumber, prettyNumber } from 'helpers/Number';
// constants
import { SUBMISSION_STATUS } from 'constants/submissionStatus';
// types
import { MetricType } from 'types';
// styles
import styles from './selection.module.scss';
import moduleStyles from '../submitCandidates.module.scss';

const ratesPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.number.isRequired,
    year: PropTypes.number.isRequired,
  })
);

const propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onCheck: PropTypes.func.isRequired,
  score: PropTypes.number,
  name: PropTypes.string.isRequired,
  status: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  company: PropTypes.string.isRequired,
  partnerType: PropTypes.string.isRequired,
  clearance: PropTypes.string,
  partnerRates: ratesPropTypes.isRequired,
  proposal: PropTypes.shape({
    filename: PropTypes.string.isRequired,
    boxId: PropTypes.string.isRequired,
  }).isRequired,
  reportId: PropTypes.string,
  previouslySaved: PropTypes.bool,
  position: PropTypes.shape({
    title: PropTypes.string.isRequired,
    project: PropTypes.string.isRequired,
    experienceLevel: PropTypes.string.isRequired,
    occupations: PropTypes.arrayOf(PropTypes.string).isRequired,
    currency: PropTypes.string.isRequired,
    nteRates: ratesPropTypes.isRequired,
  }).isRequired,
  metrics: MetricType,
};

const defaultProps = {
  score: null,
  clearance: '',
  reportId: '',
  metrics: null,
  previouslySaved: false,
};

const Submission = ({
  checked,
  disabled,
  onCheck,
  score,
  name,
  status,
  company,
  partnerType,
  clearance,
  partnerRates,
  proposal,
  reportId,
  position,
  metrics,
  previouslySaved,
}) => {
  const { t } = useTranslation();
  const averagePartnerRate = formatNumber(meanBy(partnerRates, 'value'));
  const rateYears = partnerRates.map(rate => rate.year);
  const { nteRates, currency } = position;

  const renderNameTooltip = () => {
    return (
      <div className={moduleStyles.tooltip}>
        <div className="bold">{t('submittedBy')}</div>
        {company} ({partnerType})
        {clearance && (
          <React.Fragment>
            <div className="bold m-t-5">{t('clearance')}</div>
            {clearance}
          </React.Fragment>
        )}
      </div>
    );
  };

  const renderRatesTooltip = () => {
    return (
      <RatesTooltip
        currency={currency}
        years={rateYears}
        nteRates={nteRates}
        partnerRates={partnerRates}
      />
    );
  };

  return (
    <React.Fragment>
      <div className="text-center">
        <Checkbox checked={checked} onChange={onCheck} disabled={disabled} />
      </div>
      {score !== null && (
        <div className={classNames('text-center', { [moduleStyles.disabledElement]: disabled })}>
          <Score
            score={score}
            metrics={metrics}
            candidateName={name}
            positionTitle={position.title}
            project={position.project}
            experienceLevel={position.experienceLevel}
            occupations={position.occupations}
          />
        </div>
      )}
      <div className={styles.nameBox}>
        <div className={styles.nameWrapper}>
          <Tooltip title={renderNameTooltip()} noMaxWidth>
            <div
              className={classNames(moduleStyles.nameLabel, {
                [moduleStyles.disabledText]: disabled,
              })}
            >
              <TruncatedText disableTooltip>{name}</TruncatedText>
            </div>
          </Tooltip>
          {previouslySaved && (
            <div className="m-l-4">
              <SaveIcon disabled={disabled} highlighted={checked} />
            </div>
          )}
        </div>
        <div className={classNames({ [moduleStyles.disabledElement]: disabled })}>
          <Chip size="sm" color={status.value === SUBMISSION_STATUS.NEW ? COLOR.BLUE : COLOR.GRAY}>
            {status.label}
          </Chip>
        </div>
      </div>
      <div className="text-right">
        <Tooltip title={renderRatesTooltip()} noMaxWidth>
          <div className={classNames({ [moduleStyles.disabledText]: disabled })}>
            {prettyNumber(averagePartnerRate)}
            <span className="p-l-2">{currency}</span>
          </div>
        </Tooltip>
      </div>
      <Document boxId={proposal.boxId} filename={proposal.filename} />
      {reportId && (
        <Link href={`/report/match/${reportId}`} className={styles.report}>
          <Icon size={15} name="openInNew" /> {t('viewReport')}
        </Link>
      )}
    </React.Fragment>
  );
};

Submission.propTypes = propTypes;
Submission.defaultProps = defaultProps;

export default Submission;
