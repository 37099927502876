import { gql } from '@apollo/client';
import moment from 'moment';
// services
import client from 'services/Client';
import logger from 'services/Logger';
import notification from 'services/Notification';
// queries
import { SubmissionStatus, OnboardingStartDate } from 'queries/Fragments';
// helpers
import { getSubmissionLink } from 'helpers/Link';
// constants
import { SUBMISSION_TAB } from 'constants/pageTabs';
import { ONBOARDING_TAB, START_DATE_STATUS } from 'constants/onboarding';

const ACCEPT_START_DATE_BY_PM_SUBMISSION = gql`
  mutation acceptStartDateByPMSubmission($submissionId: ID!, $data: SubmissionUpdateInput!) {
    submissionUpdate(filter: { id: $submissionId }, data: $data) {
      id
      statuses(sort: { createdAt: DESC }) {
        items {
          ...SubmissionStatus
        }
      }
      hiringProcess {
        id
        dates {
          items {
            ...OnboardingStartDate
          }
        }
      }
    }
  }
  ${SubmissionStatus}
  ${OnboardingStartDate}
`;

const ACCEPT_START_DATE_BY_PM_PROPOSAL = gql`
  mutation acceptStartDateByPMProposal($submissionId: ID!, $data: ProposalUpdateInput!) {
    proposalUpdate(filter: { id: $submissionId }, data: $data) {
      id
      statuses(sort: { createdAt: DESC }) {
        items {
          ...SubmissionStatus
        }
      }
      hiringProcess {
        id
        dates {
          items {
            ...OnboardingStartDate
          }
        }
      }
    }
  }
  ${SubmissionStatus}
  ${OnboardingStartDate}
`;

/**
 * Confirm Start Date by Project Manager
 * Step: Start Date - Confirm Start Date
 * Role: Project Manager
 *
 * Actions:
 * 1. Update Start Date status to APPROVED
 * 2. Put end date to previous hiring status
 * 3. If process is sequential, create new hiring status
 *
 * @param {object} data
 * @param {object} data.submission
 * @param {string} data.currentOnboardingStatusId Latest hiring status id
 * @param {string} data.startDateId
 * @param {string} data.userId Logged in user id
 */
const confirmStartDateByPM = async ({
  submission,
  currentOnboardingStatusId,
  startDateId,
  userId,
}) => {
  const mutationData = {
    statuses: {
      update: {
        filter: { id: currentOnboardingStatusId },
        data: { end: moment().toISOString() },
      },
    },
    hiringProcess: {
      update: {
        dates: {
          update: {
            filter: { id: startDateId },
            data: { status: START_DATE_STATUS.APPROVED },
          },
        },
      },
    },
  };

  try {
    await client.mutate({
      mutation: submission.isProposal()
        ? ACCEPT_START_DATE_BY_PM_PROPOSAL
        : ACCEPT_START_DATE_BY_PM_SUBMISSION,
      variables: {
        submissionId: submission.id,
        data: mutationData,
      },
    });

    const link = getSubmissionLink(submission.id, submission.isProposal(), {
      queryParams: {
        step: ONBOARDING_TAB.START_DATE,
        tab: SUBMISSION_TAB.ONBOARDING,
      },
    });

    notification.onboardingStartDateAcceptedByPM({
      submission: submission.id,
      link,
      createdBy: userId,
      isProposal: submission.isProposal(),
    });
  } catch (error) {
    logger.exception(error);
    throw error;
  }
};

export default confirmStartDateByPM;
