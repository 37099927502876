import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// components
import Icon from 'components/atoms/Icon';
// constants
import { STATUS_STATE } from './constants';
// styles
import styles from './statusFlow.module.scss';

const propTypes = {
  color: PropTypes.string.isRequired,
  state: PropTypes.oneOf(Object.values(STATUS_STATE)).isRequired,
  label: PropTypes.string.isRequired,
  isCompleted: PropTypes.bool.isRequired,
};

const StatusStep = ({ color, state, label, isCompleted }) => {
  return (
    <React.Fragment>
      <div className={styles.circleContainer}>
        <div
          className={classNames(styles.circle, styles[state], {
            [styles[color]]: state !== STATUS_STATE.TO_DO,
          })}
        >
          {isCompleted && <Icon name="done" size={16} />}
        </div>
      </div>
      <div
        className={classNames(styles.label, {
          [styles.activeText]: state === STATUS_STATE.ACTIVE,
        })}
      >
        {label}
      </div>
    </React.Fragment>
  );
};

StatusStep.propTypes = propTypes;

export default StatusStep;
