// constants
import { COLOR_BLUE, COLOR_GREEN, COLOR_RED, COLOR_YELLOW } from 'constants/colors';
import colors from 'dependencies/materialStyles/Colors';

export const getSecondaryColor = color => {
  switch (color) {
    case COLOR_BLUE:
      return colors.blue1;
    case COLOR_GREEN:
      return colors.green1;
    case COLOR_RED:
      return colors.red1;
    case COLOR_YELLOW:
      return colors.yellow2;
    default:
      return colors.gray3;
  }
};
