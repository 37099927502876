import React from 'react';
// libraries
import { useTranslation } from 'react-i18next';
// component
import SaveIcon from './SaveIcon';
// styles
import styles from './submitCandidates.module.scss';

const PreviouslySavedLegend = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.previouslySaved}>
      <SaveIcon /> <span className="m-l-5">{t('previouslySavedSubmissions')}</span>
    </div>
  );
};

export default PreviouslySavedLegend;
