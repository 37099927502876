import React, { useState, useContext } from 'react';
// Libraries
import queryString from 'query-string';
import { useTranslation, Trans } from 'react-i18next';
import { useLocation } from 'react-router-dom';
// context
import ToastContext from 'context/ToastContext';
// Components
import Button from 'components/atoms/Button';
import Loader from 'components/atoms/Loader';
// services
import { resendEmailVerificationEmail } from 'services/Authentication';

const EmailVerification = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { addToast } = useContext(ToastContext);
  const [loading, setLoading] = useState(false);

  const { uuid, email } = queryString.parse(location.search);

  const resendEmailVerification = async () => {
    try {
      setLoading(true);
      await resendEmailVerificationEmail(uuid);
      addToast.success(t('verificationEmailResendSuccess'));
    } catch (error) {
      addToast.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="authForm">
      <div className="authForm_header">{t('checkYourEmail')}</div>
      <div className="authForm_subHeader">{email}</div>
      <div className="authForm_label formWidth m-b-30">
        <div>
          <Trans i18nKey="emailVerificationDescription">
            Thanks for signing up! Please check your email inbox and confirm your email address to
            get started.
          </Trans>
        </div>
        <div>
          <Trans i18nKey="emailVerificationDescription2">
            Check your inbox and click the link in your email to confirm your account. If you
            can&apos;t find the email check your spam folder or click link below to re-send email.
          </Trans>
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <Button label={t('resendConfirmationEmail')} onClick={resendEmailVerification} />
      )}
    </div>
  );
};

export default EmailVerification;
