import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
// constants
import { PRIVACY_POLICY_UPDATE_DATE } from 'constants';
// components
import Section from 'components/atoms/Section';
import LevelList from 'components/molecules/LevelList';
import DPAExhibit from './DPAExhibit';
// helpers
// eslint-disable-next-line import/order
import { getTimeYear } from 'helpers/RelativeTimeFormatter';
import { privacyNumberedList, privacyBulletList, definitions, dpa } from './Texts';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const LegalPrivacy = ({ className }) => {
  const { t } = useTranslation();
  const lastUpdatedDate = getTimeYear(PRIVACY_POLICY_UPDATE_DATE);
  return (
    <div className={className}>
      <Section title={t('privacyPolicy')} titleClassName="mainHeader p-b-0" />
      <br />
      <div className="lastUpdateDate">{t('lastUpdated', { date: lastUpdatedDate })}</div>
      <br />
      <br />
      <Section
        title={`1. ${t('definitions')}`}
        content={
          <div>
            <LevelList content={definitions} />
            <br />
            <Trans i18nKey="definitionsDescription" components={[<br />]} />
          </div>
        }
      />
      <Section
        title={`2. ${t('privacyPolicy')}`}
        content={
          <div>
            <LevelList content={privacyNumberedList} firstLevelClassName="p-t-20" />
          </div>
        }
      />
      <Section
        content={
          <Trans i18nKey="privacyPolicyGDPRDescription1">
            For the purpose of the GDPR:
            <br />
            <LevelList content={privacyBulletList} />
          </Trans>
        }
      />
      <Section content={t('privacyPolicyGDPRDescription2')} />
      <Section title={`3. ${t('miscellaneous')}`} content={t('miscellaneousDescription')} />
      <Section
        title={t('dataProcessingAddendum')}
        titleClassName="mainHeader p-b-0"
        content={<LevelList content={dpa} firstLevelClassName="p-t-20" />}
      />
      <Section
        title={t('DPAExhibit')}
        titleClassName="mainHeader"
        content={t('DPAExhibitDescription')}
      />
      <DPAExhibit />
      <Section
        title={`6. ${t('linksToOtherWebsites')}`}
        content={<Trans i18nKey="linksToOtherWebsitesDescription" components={[<b />]} />}
      />
      <Section
        title={`7. ${t('contact')}`}
        content={
          <Trans
            i18nKey="contactDescription"
            components={[
              <br />,
              <a href="mailto:talent.matching.hub@be.ibm.com">talent.matching.hub@be.ibm.com</a>,
            ]}
          />
        }
      />
      <Section
        title={`8. ${t('changesToPrivacyPolicy')}`}
        content={
          <Trans
            i18nKey="changesToPrivacyPolicyDescription"
            components={[<br />]}
            values={{ lastUpdatedDate }}
          />
        }
      />
    </div>
  );
};

LegalPrivacy.propTypes = propTypes;
LegalPrivacy.defaultProps = defaultProps;

export default LegalPrivacy;
