import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// components
import Button from 'components/atoms/Button';
import CloseChip from 'components/atoms/CloseChip';
// styles
import './collapsedFilters.scss';

const propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ).isRequired,
  onClear: PropTypes.func.isRequired,
  onFilterClick: PropTypes.func.isRequired,
};

const CollapsedFilters = ({ items, onClear, onFilterClick }) => {
  const { t } = useTranslation();

  return (
    <div className="oneRow filtersButton">
      <span onClick={onFilterClick} role="presentation">
        <Button type="filter" tooltip={t('openFilters')} className="p-t-40" />
        <span className="filtersButton_heading">
          {t('filters')}
          {items.length > 0 ? ':' : ''}
        </span>
      </span>
      <div className="oneRow">
        {items.map(filter => {
          return (
            <CloseChip
              key={filter.value}
              id={filter.value}
              label={filter.label}
              onDelete={() => onClear(filter.type, filter.value)}
            />
          );
        })}
      </div>
    </div>
  );
};

CollapsedFilters.propTypes = propTypes;

export default CollapsedFilters;
