import React, { useState, useContext, useEffect } from 'react';
// hooks
import useAuth from 'hooks/useAuth';
import useToggle from 'hooks/useToggle';
import usePrevious from 'hooks/usePrevious';
// libraries
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';
import getProperty from 'lodash/get';
import Collapse from '@material-ui/core/Collapse';
// context
import ToastContext from 'context/ToastContext';
// services
import { replaceSubmissionCV, getCandidateMRI, getCandidate } from 'services/Report';
// components
import Icon from 'components/atoms/Icon';
import Button from 'components/atoms/Button';
import Score from 'components/atoms/Score';
import Link from 'components/atoms/Link';
import LinkButton from 'components/atoms/LinkButton';
import Chip, { COLOR } from 'components/atoms/NewChip';
import Banner, { BANNER_TYPE } from 'components/molecules/Banner';
import Modal from 'components/molecules/ModalWindow';
import FileUpload from 'components/molecules/FileUpload';
import MRILevelChip from 'components/molecules/MRILevelChip';
import MRIImprovements from 'components/molecules/MRIImprovements';
import { ErrorDialog } from 'components/molecules/Dialogs';
import IdentityMatch from 'components/molecules/IdentityMatch';
// constants
import { CV_FORMAT_MANUAL_LINK } from 'constants';
import { CV_TEMPLATE_LINK } from 'constants/box';
import { SUPPORTED_CV_FORMATS } from 'constants/file';
import { SH_ERRORS } from 'constants/error';
// helpers
import { getRfqLabel } from 'helpers/Position';
import { roundNumber } from 'helpers/Number';
import { getLevelByScore } from 'helpers/MRI';
import { boxFileUrl } from 'helpers/Box';
import { resolveCandidateIdentity } from 'helpers/IdentityResolver';
import { fetchMatchReport, fetchPendingReport } from './helpers';
// styles
import styles from './replaceSubmissionCvModal.module.scss';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  submitting: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onReplace: PropTypes.func.isRequired,
  candidateName: PropTypes.string.isRequired,
  submissionId: PropTypes.string,
  pendingReportId: PropTypes.string,
  matchReportId: PropTypes.string,
  candidateId: PropTypes.string,
};

const defaultProps = {
  submitting: false,
  submissionId: '',
  pendingReportId: '',
  matchReportId: '',
  candidateId: '',
};

const ReplaceSubmissionCvModal = ({
  isOpen,
  submitting,
  onReplace,
  onClose,
  candidateName,
  submissionId,
  pendingReportId,
  matchReportId,
  candidateId,
}) => {
  const { t } = useTranslation();
  const { addToast } = useContext(ToastContext);
  const { id: userId, supplierId } = useAuth();
  const prevIsOpen = usePrevious(isOpen);

  const [reportData, setReportData] = useState(null);
  const [newReport, setReport] = useState(null);
  const [loading, setLoading] = useState(false);
  const [missingCv, setMissingCv] = useState(false);
  const [analysing, setAnalysing] = useState(false);
  const [hasInvalidFormat, setInvalidFormatError] = useState(false);
  const [cv, setCv] = useState(null);
  const [identityMismatch, setIdentityMismatch] = useState(false);
  const [reportCollapsed, toggleReport] = useToggle(true);
  const [newReportCollapsed, toggleNewReport] = useToggle(true);

  const badSections = getProperty(reportData, 'mri.badSections', []);
  const mriScore = getProperty(reportData, 'mri.score', 0);
  const score = roundNumber(getProperty(reportData, 'score', 0), 1);
  // new report data
  const newBadSections = getProperty(newReport, 'mri.badSections', []);
  const newMriScore = getProperty(newReport, 'mri.score', 0);
  const newScore = roundNumber(getProperty(newReport, 'score', 0), 1);

  const rfq = getProperty(reportData, 'position.rfq', '');
  const rfqLabel = getRfqLabel(rfq, getProperty(reportData, 'position.config'));
  const title = getProperty(reportData, 'position.title', '');
  const project = getProperty(reportData, 'position.project.name', '');
  const client = getProperty(reportData, 'position.client', '');

  useEffect(() => {
    const fetchReportData = async () => {
      try {
        setLoading(true);

        const data = pendingReportId
          ? await fetchPendingReport(pendingReportId)
          : await fetchMatchReport(matchReportId);

        if (data.shPositionId && data.shCandidateId) {
          const mri = await getCandidateMRI(data.shCandidateId);
          setReportData({ ...data, mri });
        }
      } catch (error) {
        addToast.error(t('errorPlaceholderText'));
        onClose();
      } finally {
        setLoading(false);
      }
    };

    if (isOpen) {
      fetchReportData();
    }
  }, [isOpen]);

  useEffect(() => {
    // reset state after close
    if (prevIsOpen && !isOpen) {
      setCv(null);
      setReport(null);
      setMissingCv(false);
      setInvalidFormatError(false);
      setAnalysing(false);

      if (!newReportCollapsed) {
        toggleNewReport();
      }
    }
  }, [isOpen]);

  const handleReplace = () => {
    if (identityMismatch) {
      addToast.error(t('candidateMismatchIdentityError'));
      return;
    }

    if (!cv) {
      setMissingCv(true);
      return;
    }

    onReplace(newReport.id);
  };

  const handleFileChange = async files => {
    if (!files.length) {
      setIdentityMismatch(false);
      setReport(null);
      setCv(null);
      return;
    }

    try {
      const newCv = files[0];
      setCv(newCv);
      setMissingCv(false);
      setAnalysing(true);

      const report = await replaceSubmissionCV(newCv.file, {
        project,
        client,
        rfq,
        positionId: getProperty(reportData, 'position.id', ''),
        shProjectId: getProperty(reportData, 'position.project.shProjectId', ''),
        shPositionId: getProperty(reportData, 'shPositionId', ''),
        positionReportId: getProperty(reportData, 'position.reportId', ''),
        supplierId,
        userId,
      });

      const pendingReport = await fetchPendingReport(report.id);
      const [resolvedCandidateId, candidateResponse] = await Promise.all([
        resolveCandidateIdentity(supplierId, pendingReport.shPersonId, pendingReport.shCandidateId),
        getCandidate(pendingReport.shCandidateId),
      ]);

      // Prevent replacing candidate with a different identity for submission from a candidate match
      if (candidateId && candidateId !== resolvedCandidateId) {
        setIdentityMismatch(true);
      }

      setCv({ ...newCv, link: boxFileUrl(report.boxId) });
      setReport({
        ...report,
        candidate: {
          name: getProperty(candidateResponse, 'person.name', ''),
          email: getProperty(candidateResponse, 'person.email', ''),
          phone: getProperty(candidateResponse, 'person.phone', ''),
        },
      });
    } catch (error) {
      setCv(null);

      if (error.type === SH_ERRORS.UNPROCESSABLE_ENTITY) {
        setInvalidFormatError(true);
      } else {
        addToast.error(t('errorPlaceholderText'));
      }
    } finally {
      setAnalysing(false);
    }
  };

  const getScoreDiffColor = () => {
    if (!newReport) {
      return '';
    }

    if (score > newScore) {
      return COLOR.RED;
    }
    if (score < newScore) {
      return COLOR.GREEN;
    }

    return COLOR.GRAY;
  };

  const renderScoreDiff = () => {
    if (!newReport) {
      return '';
    }

    if (score > newScore) {
      return `-${score - newScore}`;
    }
    if (score < newScore) {
      return `+${newScore - score}`;
    }

    return `+0`;
  };

  const getReportLink = () => {
    return pendingReportId
      ? `/report/preview/${pendingReportId}`
      : `/report/match/${matchReportId}`;
  };

  const getNewReportLink = () => {
    const link = `/report/preview/${newReport.id}`;

    return submissionId ? `${link}/${submissionId}` : link;
  };

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      onClose={onClose}
      loading={loading || submitting}
      loadingText={submitting ? t('replacingLoader') : t('fetchingDataLoader')}
    >
      <Modal.Header disableClose={analysing}>
        <Trans i18nKey="replaceCVTitle">Replace CV for {{ candidate: candidateName }}</Trans>
        {!loading && !submitting && (
          <div className={styles.subtitle}>
            {rfqLabel} - {title} | {client} - {project}
          </div>
        )}
      </Modal.Header>
      <Modal.Body>
        <div className={classNames(styles.banner, styles.bordered)}>
          <div className={styles.bannerLeft}>
            <Score size={50} score={score} />
            <div className={styles.reportLinkWrapper}>
              <Link href={getReportLink()} className={styles.reportLink}>
                <Icon name="openInNew" size={16} />
                <span className="m-l-2">{t('viewReport')}</span>
              </Link>
              <MRILevelChip level={getLevelByScore(mriScore)} className="m-t-4" />
            </div>
          </div>
          <LinkButton onClick={toggleReport} className={styles.reportLink}>
            <span className="m-r-2">{t('viewCVReadability')}</span>
            <Icon name={reportCollapsed ? 'expandMore' : 'less'} size={16} />
          </LinkButton>
        </div>
        <Collapse in={!reportCollapsed} timeout="auto">
          <div className={styles.mri}>
            <div className={styles.mriTitle}>{t('improveCvReadability')}</div>
            <MRIImprovements badSections={badSections} hideSectionsDescription />
          </div>
        </Collapse>

        <div className={styles.upload}>
          <Trans
            i18nKey="replaceCvDescription"
            components={[
              <Link href={CV_TEMPLATE_LINK} className={styles.link}>
                {t('cvTemplate')}
              </Link>,
              <Link href={CV_FORMAT_MANUAL_LINK} className={styles.link}>
                {t('howToFormatCv')}
              </Link>,
            ]}
          />
          <div className="m-t-12">
            <FileUpload
              selected={cv ? [cv] : []}
              onChange={handleFileChange}
              loading={analysing}
              error={missingCv}
              accept={SUPPORTED_CV_FORMATS}
            />
            {missingCv && <div className={styles.error}>* {t('cvIsRequired')}</div>}
            <div className={styles.uploadLegalNote}>
              <Trans
                i18nKey="analyseCvLegalNote"
                components={[
                  <Link href="/legal/terms" className={styles.link}>
                    {t('termsOfUse')}
                  </Link>,
                  <Link href="/legal/privacy" className={styles.link}>
                    {t('privacyPolicy')}
                  </Link>,
                ]}
              />
            </div>
          </div>

          {newReport && cv && (
            <React.Fragment>
              <div className={styles.banner}>
                <div className={styles.bannerLeft}>
                  <div className={styles.scoreWrapper}>
                    <div className={styles.scoreWrapper}>
                      <Score size={50} score={newScore} />
                      <Chip size="sm" color={getScoreDiffColor()} className={styles.scoreDiff}>
                        {renderScoreDiff()}
                      </Chip>
                    </div>
                  </div>
                  <div className={styles.reportLinkWrapper}>
                    <Link href={getNewReportLink()} className={styles.reportLink}>
                      <Icon name="openInNew" size={16} />
                      <span className="m-l-2">{t('viewNewReport')}</span>
                    </Link>
                    <MRILevelChip level={getLevelByScore(newMriScore)} className="m-t-4" />
                  </div>
                </div>
                <LinkButton onClick={toggleNewReport} className={styles.reportLink}>
                  <span className="m-r-2">{t('viewCVReadability')}</span>
                  <Icon name={newReportCollapsed ? 'expandMore' : 'less'} size={16} />
                </LinkButton>
              </div>
              <Collapse in={!newReportCollapsed} timeout="auto">
                <div className={styles.mri}>
                  <div className={styles.mriTitle}>{t('improveCvReadability')}</div>
                  <MRIImprovements badSections={newBadSections} hideSectionsDescription />
                </div>
              </Collapse>
            </React.Fragment>
          )}

          {identityMismatch && newReport && (
            <React.Fragment>
              <div className="m-t-15">
                <IdentityMatch
                  currentIdentity={reportData.candidate}
                  newIdentity={newReport.candidate}
                />
              </div>
              <div className="m-t-10">
                <Banner type={BANNER_TYPE.WARNING}>{t('candidateMismatchIdentityError')}</Banner>
              </div>
            </React.Fragment>
          )}

          <ErrorDialog
            isOpen={hasInvalidFormat}
            title={t('wrongCvFormat')}
            label={t('tryAgain')}
            secondaryLabel={t('cancel')}
            onClose={() => setInvalidFormatError(false)}
            onClick={() => setInvalidFormatError(false)}
            onSecondaryClick={() => {
              setInvalidFormatError(false);
              onClose();
            }}
          >
            <div className="text-center">{t('cvFormatNotSupportedCaption')}</div>
          </ErrorDialog>
        </div>
        <Modal.Footer className={styles.footer}>
          <div className="m-r-12">
            <Button label={t('cancel')} onClick={onClose} disabled={analysing} secondary />
          </div>
          <Button label={t('replaceCV')} disabled={analysing} onClick={handleReplace} />
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
};

ReplaceSubmissionCvModal.propTypes = propTypes;
ReplaceSubmissionCvModal.defaultProps = defaultProps;

export default ReplaceSubmissionCvModal;
