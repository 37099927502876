// libraries
import getProperty from 'lodash/get';

/**
 * Custom data parser of fetched list of people right after querying people List
 * ready for Autocomplete Search component
 * filtered just person that is AlakaUser (via query filter)
 * using user id and person fullName as values for autocomplete people list
 */
export const parsePersonData = data => {
  return data && data.peopleList && data.peopleList.items
    ? {
        ...data,
        peopleList: {
          ...data.peopleList,
          items: data.peopleList.items.map(person => {
            return {
              id: person.personUser ? person.personUser.id : null,
              name: `${person.fullName} (${getProperty(
                person,
                'personUser.supplier.company.name'
              )})`,
            };
          }),
        },
      }
    : data;
};

export const parseLocationData = (data, noCountry) => {
  const locations = getProperty(data, 'locationsList.items', []);

  if (locations.length > 0) {
    const newLocations = locations.map(loc => ({
      id: loc.id,
      name: !noCountry ? `${loc.city}, ${getProperty(loc, 'country.name', '')}` : loc.city,
    }));
    return {
      ...data,
      locationsList: {
        ...data.locationsList,
        items: newLocations,
      },
    };
  }

  return data;
};

/**
 * Helper parser to prepare data for autocomplete search component
 * @param {object} data - list response from graphql
 * @returns [{id: string, name: string}]
 */
export const parseSuppliers = data => {
  const suppliersList = getProperty(data, 'suppliersList.items');

  if (!suppliersList) return data;

  return {
    ...data,
    suppliersList: {
      items: suppliersList.map(supplier => {
        return {
          id: supplier.id,
          name: supplier.company.name,
        };
      }),
    },
  };
};
