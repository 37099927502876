import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// components
import Icon from 'components/atoms/Icon';
// styles
import styles from './placeholder.module.scss';

const propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  icon: PropTypes.string,
  inline: PropTypes.bool,
};

const defaultProps = {
  className: '',
  size: 'md',
  icon: '',
  inline: false,
};

const ICON_SIZE = {
  sm: 16,
  md: 20,
  lg: 26,
};

const Placeholder = ({ className, size, icon, inline, children }) => {
  return (
    <div
      className={classNames(styles.container, styles[size], className, { [styles.inline]: inline })}
    >
      {icon && (
        <div className={styles.icon}>
          <Icon name={icon} size={ICON_SIZE[size]} />
        </div>
      )}
      {children}
    </div>
  );
};

Placeholder.propTypes = propTypes;
Placeholder.defaultProps = defaultProps;

export default Placeholder;
