import {
  ACTION_OVERDUE,
  ACTION_INACTIVE,
  ACTION_PENDING,
  ACTION_OWN_PENDING,
  ACTION_FINISHED,
  ACTION_CANCELLED,
} from 'constants';

export const getArrowColor = type => {
  switch (type) {
    case ACTION_INACTIVE:
      return 'grayLight';
    case ACTION_PENDING:
      return 'gray';
    case ACTION_OWN_PENDING:
      return 'blue';
    case ACTION_FINISHED:
      return 'green';
    case ACTION_OVERDUE:
      return 'red';
    case ACTION_CANCELLED:
      return 'red';
    default:
      return 'gray';
  }
};

export const getProgress = tabs => {
  let finished = 0;
  let total = 0;

  tabs.forEach(tab => {
    finished += tab.finishedStepsCount;
    total += tab.steps.length;
  });

  const percentage = Math.floor((finished / total) * 100);

  return { progress: `${finished}/${total}`, percentage };
};
