import React from 'react';
import PropTypes from 'prop-types';
// icons
import {
  MdAdd as AddIcon,
  MdPersonAdd as AddPersonIcon,
  MdErrorOutline as AlertIcon,
  MdInsertChart as AnalyticsIcon,
  MdArchive as Archive,
  MdArrowDropDown as ArrowDownIcon,
  MdAttachFile as AttachIcon,
  MdArrowBack as BackIcon,
  MdBookmark as BookmarkIcon,
  MdBuild as BuildIcon,
  MdCancel as CancelIcon,
  MdCheckBox as CheckboxCheckedIcon,
  MdCheckBoxOutlineBlank as CheckboxUncheckedIcon,
  MdClear as ClearIcon,
  MdDateRange as CalendarIcon,
  MdEventAvailable as CalendarAvailableIcon,
  MdPeople as CandidatesIcon,
  MdCheckCircle as CheckIcon,
  MdLocationCity as CityIcon,
  MdClose as CloseIcon,
  MdCloudQueue as CloudIcon,
  MdCloudUpload as CloudUploadIcon,
  MdPermContactCalendar as ContractorIcon,
  MdFilterNone as CopyIcon,
  MdDelete as DeleteIcon,
  MdDescription as Description,
  MdDone as DoneIcon,
  MdEdit as EditIcon,
  MdMailOutline as EmailIcon,
  MdError as ErrorIcon,
  MdExplore as ExploreIcon,
  MdAssignmentInd as EvaluationIcon,
  MdArrowForward as ForwardIcon,
  MdVisibilityOff as HideIcon,
  MdHelp as HelpIcon,
  MdAccountBalance as CompanyIcon,
  MdInfo as InfoIcon,
  MdChevronRight as RightIcon,
  MdChevronLeft as LeftIcon,
  MdExpandMore as ExpandMoreIcon,
  MdExpandLess as LessIcon,
  MdLocationOn as LocationIcon,
  MdLock as LockIcon,
  MdMenu as MenuIcon,
  MdMoreVert as MoreIcon,
  MdNotifications as NotificationIcon,
  MdQuestionAnswer as QuestionIcon,
  MdPerson as PersonIcon,
  MdPhone as PhoneIcon,
  MdPictureAsPdf as PDFIcon,
  MdPublish as PublishIcon,
  MdPortrait as PortraitIcon,
  MdRadioButtonChecked as RadioCheckedIcon,
  MdRadioButtonUnchecked as RadioUncheckedIcon,
  MdSave as SaveIcon,
  MdSearch as SearchIcon,
  MdSend as SendIcon,
  MdSettings as SettingsIcon,
  MdExitToApp as SignOutIcon,
  MdStarBorder as StarIcon,
  MdSync as SyncIcon,
  MdAccessTime as TimeIcon,
  MdVerifiedUser as VerifiedIcon,
  MdWarning as WarningIcon,
  MdWork as PositionIcon,
  MdTurnedIn as MyItemsIcon,
  MdTurnedInNot as EmptyFlagIcon,
  MdSupervisorAccount,
  MdArchive,
  MdFileUpload,
  MdOpenInNew as OpenInNewIcon,
  MdAttachMoney as MoneyIcon,
  MdTimeline,
  MdLocalAtm as PurchaseOrderIcon,
  MdWatchLater as PendingIcon,
  MdPersonPin,
  MdRotateRight as RevisionIcon,
  MdRecentActors,
  MdDrafts,
  MdMail,
  MdInfoOutline,
  MdContacts,
  MdMoreHoriz,
  MdFileDownload,
  MdPerson,
  MdPersonOutline,
  MdContentPaste,
  MdReceipt,
  MdImportExport,
  MdPeople,
} from 'react-icons/md';
// styles
import {
  FaSkype,
  FaLinkedin,
  FaUsers,
  FaThLarge,
  FaAlignJustify,
  FaEye,
  FaEyeSlash,
  FaTimesCircle,
  FaUserTimes,
  FaTrophy,
  FaSearchengin,
  FaFolderOpen,
  FaFile,
  FaFilePdf,
  FaFileImage,
  FaFileCsv,
  FaFileExcel,
  FaFileAlt,
  FaFileWord,
  FaFileArchive,
  FaRoute,
  FaShieldAlt,
  FaPeopleCarry,
} from 'react-icons/fa';
import {
  AiOutlineExclamationCircle,
  AiOutlineCloseCircle,
  AiOutlineFileDone,
} from 'react-icons/ai';
import { BiDuplicate, BiReset } from 'react-icons/bi';
import { TiFilter } from 'react-icons/ti';
import { IoIosStar } from 'react-icons/io';
import { GoPrimitiveDot } from 'react-icons/go';
// components
import CheckOutline from './icons/CheckOutlineIcon';
import WarningOutline from './icons/WarningOutline';
import FileUploadOutline from './icons/FileUploadOutline';

const propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  style: PropTypes.object,
};

const defaultProps = {
  className: '',
  color: '',
  size: 25,
  style: {},
};

const Icon = props => {
  const { className, color, name, size, style } = props;

  const icons = {
    add: <AddIcon style={style} color={color} size={size} />,
    addPerson: <AddPersonIcon style={style} color={color} size={size} />,
    alert: <AlertIcon style={style} color={color} size={size} />,
    analytics: <AnalyticsIcon style={style} color={color} size={size} />,
    archive: <Archive style={style} color={color} size={size} />,
    arrowDown: <ArrowDownIcon style={style} color={color} size={size} />,
    assignment: <PositionIcon style={style} color={color} size={size} />,
    attach: <AttachIcon style={style} color={color} size={size} />,
    back: <BackIcon style={style} color={color} size={size} />,
    bookmark: <BookmarkIcon style={style} color={color} size={size} />,
    build: <BuildIcon style={style} color={color} size={size} />,
    bullet: <GoPrimitiveDot style={style} color={color} size={size} />,
    cancel: <CancelIcon style={style} color={color} size={size} />,
    calendar: <CalendarIcon style={style} color={color} size={size} />,
    calendarAvailable: <CalendarAvailableIcon style={style} color={color} size={size} />,
    candidates: <PersonIcon style={style} color={color} size={size} />,
    candidatesAllowed: <MdRecentActors style={style} color={color} size={size} />,
    candidate: <MdPerson style={style} color={color} size={size} />,
    candidatesSubmitted: <MdPersonPin style={style} color={color} size={size} />,
    candidatesReceived: <AddPersonIcon style={style} color={color} size={size} />,
    cardView: <FaThLarge style={style} color={color} size={size} />,
    clear: <ClearIcon style={style} color={color} size={size} />,
    check: <CheckIcon style={style} color={color} size={size} />,
    checkboxChecked: <CheckboxCheckedIcon style={style} color={color} size={size} />,
    checkboxUnchecked: <CheckboxUncheckedIcon style={style} color={color} size={size} />,
    city: <CityIcon style={style} color={color} size={size} />,
    close: <CloseIcon style={style} color={color} size={size} />,
    closeOutline: <AiOutlineCloseCircle style={style} color={color} size={size} />,
    cloud: <CloudIcon style={style} color={color} size={size} />,
    cloudUpload: <CloudUploadIcon style={style} color={color} size={size} />,
    contractor: <ContractorIcon style={style} color={color} size={size} />,
    copy: <CopyIcon style={style} color={color} size={size} />,
    delete: <DeleteIcon style={style} color={color} size={size} />,
    description: <Description style={style} color={color} size={size} />,
    done: <DoneIcon style={style} color={color} size={size} />,
    download: <MdFileDownload style={style} color={color} size={size} />,
    duplicate: <BiDuplicate style={style} color={color} size={size} />,
    edit: <EditIcon style={style} color={color} size={size} />,
    email: <EmailIcon style={style} color={color} size={size} />,
    empty: <MdReceipt style={style} color={color} size={size} />,
    error: <ErrorIcon style={style} color={color} size={size} />,
    explore: <ExploreIcon style={style} color={color} size={size} />,
    evaluation: <EvaluationIcon style={style} color={color} size={size} />,
    file: <FaFile style={style} color={color} size={size} />,
    filePdf: <FaFilePdf style={style} color={color} size={size} />,
    fileImage: <FaFileImage style={style} color={color} size={size} />,
    fileCsv: <FaFileCsv style={style} color={color} size={size} />,
    fileExcel: <FaFileExcel style={style} color={color} size={size} />,
    fileText: <FaFileAlt style={style} color={color} size={size} />,
    fileWord: <FaFileWord style={style} color={color} size={size} />,
    fileArchive: <FaFileArchive style={style} color={color} size={size} />,
    fileAnalysis: <FaSearchengin style={style} color={color} size={size} />,
    fileUpload: <MdFileUpload style={style} color={color} size={size} />,
    fileReplace: <FileUploadOutline style={style} color={color} size={size} />,
    filter: <TiFilter style={style} color={color} size={size} />,
    flag: <MyItemsIcon style={style} color={color} size={size} />,
    flagEmpty: <EmptyFlagIcon style={style} color={color} size={size} />,
    forward: <ForwardIcon style={style} color={color} size={size} />,
    help: <HelpIcon style={style} color={color} size={size} />,
    hide: <HideIcon style={style} color={color} size={size} />,
    company: <CompanyIcon style={style} color={color} size={size} />,
    import: <MdArchive style={style} color={color} size={size} />,
    info: <InfoIcon style={style} color={color} size={size} />,
    infoOutline: <MdInfoOutline style={style} color={color} size={size} />,
    left: <LeftIcon style={style} color={color} size={size} />,
    less: <LessIcon style={style} color={color} size={size} />,
    level: <MdTimeline style={style} color={color} size={size} />,
    expandMore: <ExpandMoreIcon style={style} color={color} size={size} />,
    location: <LocationIcon style={style} color={color} size={size} />,
    lock: <LockIcon style={style} color={color} size={size} />,
    linkedIn: <FaLinkedin style={style} color={color} size={size} />,
    menu: <MenuIcon style={style} color={color} size={size} />,
    money: <MoneyIcon style={style} color={color} size={size} />,
    more: <MoreIcon style={style} color={color} size={size} />,
    moreVert: <MdMoreHoriz style={style} color={color} size={size} />,
    note: <MdContentPaste style={style} color={color} size={size} />,
    notification: <NotificationIcon style={style} color={color} size={size} />,
    onboarding: <MdContacts style={style} color={color} size={size} />,
    openInNew: <OpenInNewIcon style={style} color={color} size={size} />,
    question: <QuestionIcon style={style} color={color} size={size} />,
    pdf: <PDFIcon style={style} color={color} size={size} />,
    pending: <PendingIcon style={style} color={color} size={size} />,
    person: <PersonIcon style={style} color={color} size={size} />,
    phone: <PhoneIcon style={style} color={color} size={size} />,
    project: <FaFolderOpen style={style} color={color} size={size} />,
    people: <CandidatesIcon style={style} color={color} size={size} />,
    publish: <PublishIcon style={style} color={color} size={size} />,
    portrait: <PortraitIcon style={style} color={color} size={size} />,
    purchaseOrder: <PurchaseOrderIcon style={style} color={color} size={size} />,
    radioChecked: <RadioCheckedIcon style={style} color={color} size={size} />,
    radioUnchecked: <RadioUncheckedIcon style={style} color={color} size={size} />,
    rejected: <FaTimesCircle style={style} color={color} size={size} />,
    reset: <BiReset style={style} color={color} size={size} />,
    revision: <RevisionIcon style={style} color={color} size={size} />,
    right: <RightIcon style={style} color={color} size={size} />,
    save: <SaveIcon style={style} color={color} size={size} />,
    search: <SearchIcon style={style} color={color} size={size} />,
    send: <SendIcon style={style} color={color} size={size} />,
    settings: <SettingsIcon style={style} color={color} size={size} />,
    signout: <SignOutIcon style={style} color={color} size={size} />,
    skype: <FaSkype style={style} color={color} size={size} />,
    sort: <MdImportExport style={style} color={color} size={size} />,
    star: <StarIcon style={style} color={color} size={size} />,
    starFilled: <IoIosStar style={style} color={color} size={size} />,
    sync: <SyncIcon style={style} color={color} size={size} />,
    switch: <SyncIcon style={style} color={color} size={size} />,
    tableView: <FaAlignJustify style={style} color={color} size={size} />,
    team: <FaUsers style={style} color={color} size={size} />,
    time: <TimeIcon style={style} color={color} size={size} />,
    trophy: <FaTrophy style={style} color={color} size={size} />,
    read: <MdDrafts style={style} color={color} size={size} />,
    unread: <MdMail style={style} color={color} size={size} />,
    user: <MdPersonOutline style={style} color={color} size={size} />,
    users: <MdSupervisorAccount style={style} color={color} size={size} />,
    verified: <VerifiedIcon style={style} color={color} size={size} />,
    warning: <WarningIcon style={style} color={color} size={size} />,
    exclamationCircle: <AiOutlineExclamationCircle style={style} color={color} size={size} />,
    watch: <FaEye style={style} color={color} size={size} />,
    withdrawn: <FaUserTimes style={style} color={color} size={size} />,
    stopWatch: <FaEyeSlash style={style} color={color} size={size} />,
    warningOutline: <WarningOutline style={style} color={color} size={size} />,
    checkOutline: <CheckOutline style={style} color={color} size={size} />,
    group: <MdPeople style={style} color={color} size={size} />,
    route: <FaRoute style={style} color={color} size={size} />,
    shield: <FaShieldAlt style={style} color={color} size={size} />,
    proposal: <AiOutlineFileDone style={style} color={color} size={size} />,
    suppliers: <FaPeopleCarry style={style} color={color} size={size} />,
  };

  if (!name || !icons[name]) {
    return null;
  }

  if (className) {
    return <span className={className}>{icons[name || 'assignment']}</span>;
  }

  return <React.Fragment>{icons[name]}</React.Fragment>;
};

Icon.propTypes = propTypes;
Icon.defaultProps = defaultProps;

export default Icon;
