/* eslint-disable import/order */
import React, { useState, useContext } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
// hooks
import useAuth from 'hooks/useAuth';
// context
import ToastContext from 'context/ToastContext';
// components
import Button from 'components/atoms/Button';
import Forms from './Forms';
import TextContent from '../TextContent';
import EditForm from '../EditForm';
// helpers
import { boxFileDir } from 'helpers/Box';
import { getOnboardingForms, parseDefaultData } from './helpers';
import { submitFormsBySupplier } from '../helpers';
// constants
import { SUBMISSION_STATUS_TYPE } from 'constants/submissionStatus';

const propTypes = {
  files: PropTypes.array,
  templates: PropTypes.arrayOf(
    PropTypes.shape({
      boxId: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ),
  note: PropTypes.object,
  submission: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
  edit: PropTypes.bool,
  missing: PropTypes.number,
};

const defaultProps = {
  files: [],
  templates: [],
  note: null,
  edit: false,
  missing: 0,
};

const SubmitOnboardingForms = ({ files, templates, note, submission, position, edit, missing }) => {
  const { t } = useTranslation();
  const { addToast } = useContext(ToastContext);
  const { id: userId, role } = useAuth();

  const [editable, setEditable] = useState(false);
  const [loading, setLoading] = useState(false);

  const { rfq, project, client } = position;
  const formDisabled = missing > 0 && edit;
  const onboardingForm = getOnboardingForms(
    templates,
    submission.partner.category,
    formDisabled,
    submission.isProposal()
  );
  const onboardingStatus = submission.getStatus(role, SUBMISSION_STATUS_TYPE.ONBOARDING_FORMS);

  const getTitle = () => {
    if (missing) {
      return (
        <Trans i18nKey="submitOnboardingFormsForCandidate">
          Please submit onboarding forms for {{ candidateName: submission.getName() }}.
        </Trans>
      );
    }

    return t('youSubmittedAllNecessaryForms');
  };

  const handleSubmit = async formData => {
    const folder = boxFileDir({ rfq, project: project.name, client: client.name }, 'onboarding');

    try {
      setLoading(true);
      await submitFormsBySupplier({
        submission,
        currentOnboardingStatusId: onboardingStatus.id,
        formData,
        folder,
        userId,
        rfq,
      });
      setEditable(false);
    } catch (error) {
      addToast.error(t('errorWhileSubmittingForms'));
      setLoading(false);
    }
  };

  if (editable) {
    const defaultData = parseDefaultData(files);

    return (
      <EditForm
        form={onboardingForm}
        onCancel={() => setEditable(false)}
        onSubmit={handleSubmit}
        onSubmitLabel={t('submitForms')}
        loading={loading}
        defaultData={defaultData}
        className="p-t-40"
      />
    );
  }

  return (
    <TextContent
      title={getTitle()}
      label={!missing ? t('weAreWaitingForApprovals') : ''}
      content={
        <React.Fragment>
          {missing > 0 && <Button label={t('submitForms')} onClick={() => setEditable(true)} />}
          <Forms
            files={files}
            templates={templates}
            note={note}
            category={submission.partner.category}
            projectId={project.id}
            collapsedTemplates={missing === 0}
          />
        </React.Fragment>
      }
    />
  );
};

SubmitOnboardingForms.propTypes = propTypes;
SubmitOnboardingForms.defaultProps = defaultProps;

export default SubmitOnboardingForms;
