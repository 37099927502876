/* eslint-disable import/order */
import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// hooks
import useAuth from 'hooks/useAuth';
// component
import Placeholder from 'components/atoms/Placeholder';
import Submission from './Submission';
// helpers
import parseSubmission from 'helpers/SubmissionParser';
import { filterSubmissions } from './helpers';
// styles
import styles from './positionSubmissions.module.scss';

const propTypes = {
  position: PropTypes.object.isRequired,
};

const PositionSubmissions = ({ position }) => {
  const { role, supplierId } = useAuth();
  const { t } = useTranslation();

  const submissions = filterSubmissions(position.submissions, role, supplierId);

  if (submissions.length > 0) {
    return (
      <div className={styles.container}>
        {submissions.map((submission, index) => {
          return (
            <Submission
              key={submission.id}
              role={role}
              position={position}
              submission={parseSubmission(submission)}
              className={index > 0 ? '' : 'border-top-none'}
            />
          );
        })}
      </div>
    );
  }

  return (
    <Placeholder size="lg" icon="candidates">
      {t('noSubmissions')}
    </Placeholder>
  );
};

PositionSubmissions.propTypes = propTypes;

export default PositionSubmissions;
