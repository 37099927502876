import { gql } from '@apollo/client';
import getProperty from 'lodash/get';
import hasProperty from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
// services
import client from 'services/Client';
import logger from 'services/Logger';
import { copy, upload, createFolder } from 'services/Box';
// helpers
import { boxFileDir } from 'helpers/Box';
// queries
import { GET_CLIENT_BY_PROJECT } from 'queries/ClientQueries';
import { GET_FORM_ID } from 'queries/FormQueries';

export const isComponentReferencedInRequiredCondidtion = (key, form) => {
  const panels = getProperty(form, 'components', []);

  return panels.some(panel => {
    const components = getProperty(panel, 'components', []);

    return components.some(component => {
      const requiredCondition = getProperty(component, 'properties.required');

      if (requiredCondition) {
        const referencedComponent = Object.keys(requiredCondition)[0];

        return referencedComponent === key;
      }

      return false;
    });
  });
};

export const isComponentReferencedByPanelConditional = (componentKey, form) => {
  const componentsChangingPanelVisibility = getProperty(form, 'components', []).map(panel =>
    getProperty(panel, 'conditional.when')
  );

  return componentsChangingPanelVisibility.includes(componentKey);
};

// Pre-defined filters for components.
const FILTERS = {
  not_empty: (value, filterValue) => {
    return filterValue ? !!value : !value;
  },
  is_empty: (value, filterValue) => {
    return filterValue ? !value : !!value;
  },
};

const evaluateCondition = (value, condition) => {
  const operation = Object.keys(condition)[0];
  const operationValue = condition[operation];
  const availableFilters = Object.keys(FILTERS);

  if (availableFilters.includes(operation)) {
    return FILTERS[operation](value, operationValue);
  }

  return true;
};

const parseCondition = (requiredCondition, formData) => {
  const referencedComponent = Object.keys(requiredCondition)[0];

  const conditionExpression = getProperty(requiredCondition, [referencedComponent], {});

  const referencedComponentValue = getProperty(formData, [referencedComponent, 'value']);

  return { referencedComponent, conditionExpression, referencedComponentValue };
};

export const isComponentRequired = (component, formData) => {
  const { validate } = component;
  const requiredCondition = getProperty(component, 'properties.required');

  // If required condition exists execute it
  if (requiredCondition) {
    const { conditionExpression, referencedComponentValue } = parseCondition(
      requiredCondition,
      formData
    );

    return evaluateCondition(referencedComponentValue, conditionExpression);
  }

  // Return default setting of component
  return validate && validate.required;
};

export const isComponentEmpty = (data, key) => {
  const value = getProperty(data, [key, 'value']);
  const isValueBoolean = typeof value === 'boolean';

  return (
    !data[key] ||
    isEmpty(data[key]) ||
    data[key].requiredError ||
    (hasProperty(data, `${key}.value`) && !isValueBoolean && !value)
  );
};

/**
 * @param {string} projectId
 * @param {string} formType Type of form (position, candidate,..)
 * @returns {Promise<string>}
 */
export const getFormIdByProject = async (projectId, formType) => {
  try {
    const response = await client.query({
      query: GET_FORM_ID,
      variables: {
        project: projectId,
        type: formType,
      },
    });

    const forms = getProperty(response, 'data.formsList.items', []);
    const projectForm = forms.find(form => !form.isFormDefault);
    const defaultForm = forms.find(form => form.isFormDefault);

    if (!projectForm && !defaultForm) {
      logger.warn('Missing form', { projectId, formType });
      return '';
    }

    return projectForm ? projectForm.id : defaultForm.id;
  } catch (error) {
    logger.exception(error, { projectId, formType });
    throw error;
  }
};

/**
 * @param {string} projectId
 * @param {string} rfq
 * @returns {Promise<string>}
 */
export const getDocumentFolder = async (projectId, rfq) => {
  const response = await client.query({
    query: GET_CLIENT_BY_PROJECT,
    variables: { id: projectId },
  });

  return boxFileDir({
    client: getProperty(response, 'data.project.client.name', ''),
    project: getProperty(response, 'data.project.name', ''),
    rfq,
  });
};

/**
 * @typedef {Object} File
 * @property {string} id Box file id
 * @property {string} name filename
 */

/**
 * @param {object[]} files Files from file component
 * @param {string} folder
 * @param {boolean} [isDraftOrImport=false]
 * @returns {Promise<File[]>}
 */
export const uploadFormDocuments = async (files, folder, isDraftOrImport = false) => {
  // box copy and upload file functions creates a folder if it doesn't exist. Multiple requests
  // for creating a folder at the same time ends with 409 error (name_temporarily_reserved).
  // Therefore we have to ensure that the folder already exits or create it beforehand
  if (files && files.length > 1) {
    await createFolder(folder);
  }

  // Files which have 'size' field are new files
  const newFiles = files.filter(file => file.size);
  const promises = newFiles.map(async ({ file, name }) => ({
    id: await upload(file, name, folder),
    name,
  }));

  // if draft or import -> copy files into position folder
  if (isDraftOrImport) {
    const existingFiles = files.filter(file => file.boxId);
    const existingFilesPromises = existingFiles.map(async file => ({
      id: await copy(file.boxId, folder),
      name: file.name,
    }));
    promises.push(...existingFilesPromises);
  }

  return Promise.all(promises);
};

export const isComponentHidden = (component, formData) => {
  if (component.conditional) {
    const value = getProperty(formData, component.conditional.when);

    if (component.conditional.eq) {
      if (component.conditional.show) {
        if (!value) {
          return true;
        }
        if (
          typeof value.value === 'string' &&
          component.conditional.eq.toLowerCase() !== value.value.toLowerCase()
        ) {
          return true;
        }
        if (typeof value.value === 'boolean' && component.conditional.eq !== value.value) {
          return true;
        }

        // to be removed when all form components are refactored to value.value
        if (
          typeof value === 'string' &&
          value.toLowerCase() !== component.conditional.eq.toLowerCase()
        ) {
          return true;
        }
      }
      // hide component if value matches conditional value set in form
      if (!component.conditional.show && value) {
        if (typeof value.value === 'boolean' && component.conditional.eq === value.value) {
          return true;
        }
        if (
          typeof value.value === 'string' &&
          component.conditional.eq.toLowerCase() === value.value.toLowerCase()
        ) {
          return true;
        }
      }
    }
    if (!!component.conditional.show && !value) {
      return true;
    }
  }
  return false;
};

/**
 * @typedef {Object} Form
 * @property {string} id
 * @property {string} data
 * @property {string[]} enabledFields
 */

const FETCH_FORM = gql`
  query fetchForm($type: String!, $project: ID) {
    formsList(
      filter: { OR: [{ type: { equals: $type } }, { project: { id: { equals: $project } } }] }
    ) {
      items {
        id
        form
        isFormDefault
        enabledFields
        project {
          id
        }
      }
    }
  }
`;

/**
 * Fetch form
 * @param {string} type Form type
 * @param {string} project Project id
 * @returns {Promise<?Form>}
 */
export const fetchForm = async (type, project) => {
  try {
    const response = await client.query({
      query: FETCH_FORM,
      variables: {
        type,
        project,
      },
    });

    const forms = getProperty(response, 'data.formsList.items', []);

    if (!forms.length) {
      return null;
    }

    if (project) {
      const projectForm = forms.find(form => getProperty(form, 'project.id') === project);

      if (projectForm) {
        return {
          id: projectForm.id,
          data: projectForm.form,
          enabledFields: projectForm.enabledFields || [],
        };
      }
    }

    const matchedForm = forms.find(form => form.isFormDefault);

    return matchedForm
      ? {
          id: matchedForm.id,
          data: matchedForm.form,
          enabledFields: matchedForm.enabledFields || [],
        }
      : null;
  } catch (error) {
    logger.exception(error, { type, project });
    throw error;
  }
};
