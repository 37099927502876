import React from 'react';
// libraries
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const Logo = ({ className }) => {
  const { t } = useTranslation();

  return (
    <Link to="/">
      <div className={`${className}`}>
        <div className="logo_secondaryName logo_role">IBM Services</div>
        <div className="logo_name">{t('talentMatchingHub')}</div>
      </div>
    </Link>
  );
};

Logo.propTypes = propTypes;
Logo.defaultProps = defaultProps;

export default Logo;
