import React from 'react';
import { StyledEngineProvider } from '@mui/material/styles';

interface AppProps {
  children: React.ReactNode;
}

const App = ({ children }: AppProps) => (
  <StyledEngineProvider injectFirst>{children}</StyledEngineProvider>
);

export default App;
