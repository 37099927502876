import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// hooks
import useAuth from 'hooks/useAuth';
// constants
import { SUPPLIER_ROLE, STAFF_ROLE } from 'constants';
// components
import Icon from 'components/atoms/Icon';
import LinkButton from 'components/atoms/LinkButton';
import Button from 'components/atoms/Button';
import Tooltip from 'components/atoms/Tooltip';
import Popover from 'components/molecules/Popover';
// styles
import colors from 'dependencies/materialStyles/Colors';
import styles from './positionTargetRateInfo.module.scss';

const propTypes = {
  positionId: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  hasBeenPublishedBefore: PropTypes.bool.isRequired,
  isPublished: PropTypes.bool.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
};

const defaultProps = {
  className: '',
  color: colors.gray5,
  size: 20,
};

const PositionTargetRateInfo = ({
  positionId,
  currency,
  hasBeenPublishedBefore,
  isPublished,
  className,
  color,
  size,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { role } = useAuth();

  const renderButton = onClick => {
    return (
      <div className={classNames(styles.button, { [className]: className })}>
        <Button
          type="infoOutline"
          size={size}
          tooltip={t('moreInfo')}
          color={color}
          onClick={event => {
            event.preventDefault();
            onClick(event);
          }}
        />
        <div className={styles.currency}>{currency}</div>
      </div>
    );
  };

  if (role === STAFF_ROLE && !hasBeenPublishedBefore) {
    return (
      <Popover placement="bottom-end" button={renderButton}>
        <div className="p-20">
          <div className="m-b-15">{t('targetRatesAreMissing')}</div>
          <LinkButton
            className="text-decoration-none"
            onClick={event => {
              event.preventDefault();
              history.push(`/position/${positionId}?tab=TAB_RATES`);
            }}
          >
            {t('setTargetRates')}
          </LinkButton>
        </div>
      </Popover>
    );
  }

  if ([SUPPLIER_ROLE, STAFF_ROLE].includes(role)) {
    const tooltip =
      !isPublished && hasBeenPublishedBefore
        ? t('publishedBeforeWithoutTargetRates')
        : t('notTargetRatesForPosition');

    return (
      <Tooltip title={tooltip}>
        <div className={classNames(styles.container, { [className]: className })}>
          <Icon name="infoOutline" size={size} color={color} />
          <div className={styles.currency}>{currency}</div>
        </div>
      </Tooltip>
    );
  }

  return null;
};

PositionTargetRateInfo.propTypes = propTypes;
PositionTargetRateInfo.defaultProps = defaultProps;

export default PositionTargetRateInfo;
