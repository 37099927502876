import React from 'react';
import PropTypes from 'prop-types';
// Hooks
import { useTranslation } from 'react-i18next';
// Components
import Modal from 'components/molecules/Modal';
import Button from 'components/atoms/Button';
import Loader from 'components/atoms/Loader';
import Placeholder from 'components/atoms/Placeholder';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

const defaultProps = { loading: false };

const SaveProgressModal = ({ isOpen, onClose, onConfirm, loading }) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnBackdrop={false}>
      <Modal.Header hideCloseIcon>{t('saveProgress')}</Modal.Header>
      <Modal.Body>
        {loading ? (
          <React.Fragment>
            <div style={{ marginBottom: '25px', marginTop: '45px' }}>
              <Loader />
            </div>
            <Placeholder>{t('saveInProgress')}</Placeholder>
          </React.Fragment>
        ) : (
          <div className="space-20">{t('saveProgressPrompt')}</div>
        )}
      </Modal.Body>
      {!loading && (
        <Modal.Footer>
          <Button label={t('discard')} secondary onClick={onClose} />
          <span className="p-l-12">
            <Button label={t('save')} onClick={onConfirm} />
          </span>
        </Modal.Footer>
      )}
    </Modal>
  );
};

SaveProgressModal.propTypes = propTypes;
SaveProgressModal.defaultProps = defaultProps;

export default SaveProgressModal;
