import { gql } from '@apollo/client';

export const importFragment = gql`
  fragment Import on Import {
    id
    updatedAt
    createdAt
    data
    form {
      id
      importProject {
        id
        name
        shortName
        config
        timezone
      }
      type
    }
    attachments(sort: { createdAt: DESC }) {
      items {
        id
        boxId
        type
        name
      }
    }
    validated
    name
    mail
    missingFields
    dueOn
  }
`;

export const GET_IMPORTS = gql`
  query importsList($filter: ImportFilter, $skip: Int, $first: Int, $sort: [ImportSort!]) {
    importsList(filter: $filter, sort: $sort, skip: $skip, first: $first) {
      count
      items {
        ...Import
      }
    }
  }
  ${importFragment}
`;

export const GET_IMPORT_BY_ID = gql`
  query getImportById($importId: ID!) {
    import(id: $importId) {
      ...Import
    }
  }
  ${importFragment}
`;

export const UPDATE_IMPORT = gql`
  mutation updateImport($importId: ID!, $validated: Boolean!) {
    importUpdate(filter: { id: $importId }, data: { validated: $validated }) {
      ...Import
    }
  }
  ${importFragment}
`;

export const UPDATE_IMPORT_GENERIC = gql`
  mutation updateImport($importId: ID!, $data: ImportUpdateInput!) {
    importUpdate(filter: { id: $importId }, data: $data) {
      id
    }
  }
`;
