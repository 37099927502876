import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// components
import NumberInput from 'components/atoms/NumberInput';
// context
import MultipliersContext from '../MultipliersContext';

const propTypes = {
  targetMargin: PropTypes.number,
  onTargetMarginChange: PropTypes.func.isRequired,
  onFeeChange: PropTypes.func.isRequired,
  yearPurchased: PropTypes.number,
  showTargetMargin: PropTypes.bool,
};

const defaultProps = {
  targetMargin: null,
  yearPurchased: 0,
  showTargetMargin: true,
};

const Allowance = React.memo(
  ({ targetMargin, onTargetMarginChange, onFeeChange, yearPurchased, showTargetMargin }) => {
    const { t } = useTranslation();
    const { ibmFee, manYearDiscount } = useContext(MultipliersContext);

    return (
      <div className="rfqRates_allowance">
        <div className="rfqRates_allowance_row">
          <div className="rfqRates_allowance_label">{`% ${t('yearPurchasedLabel')}`}</div>
          <div className="rfqRates_allowance_value">{yearPurchased} %</div>
        </div>
        <div className="rfqRates_allowance_row">
          <div className="rfqRates_allowance_label">{t('targetMargin')}</div>
          <div className="oneRow">
            {showTargetMargin ? (
              <NumberInput
                name="targetMargin"
                className="rfqRates_allowance_input"
                min={0}
                value={targetMargin}
                decimalLimit={2}
                onChange={onTargetMarginChange}
              />
            ) : (
              <span className="rfqRates_allowance_emptyInput">-</span>
            )}
            <span className="m-l-5">%</span>
          </div>
        </div>
        <div className="rfqRates_allowance_row">
          <div className="rfqRates_allowance_label">{t('ibmFeeLabel')}</div>
          <div className="oneRow">
            <NumberInput
              name="ibmFee"
              className="rfqRates_allowance_input"
              min={0}
              value={ibmFee}
              decimalLimit={2}
              onChange={onFeeChange}
            />
            <span className="m-l-5">%</span>
          </div>
        </div>
        {manYearDiscount > 0 && (
          <div className="rfqRates_allowance_row">
            <div className="rfqRates_allowance_label">{`% ${t('manYearDiscountLabel')}`}</div>
            <div className="rfqRates_allowance_value">{manYearDiscount} %</div>
          </div>
        )}
      </div>
    );
  }
);

Allowance.propTypes = propTypes;
Allowance.defaultProps = defaultProps;

export default Allowance;
