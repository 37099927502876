/* eslint-disable dot-notation */
import React, { useState, useEffect } from 'react';
// libraries
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import moment from 'moment';
import getProperty from 'lodash/get';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Zendesk, { ZendeskAPI } from 'react-zendesk';
// services
import client from 'services/Client';
// hooks
import useAuth from 'hooks/useAuth';
// queries
import { GET_ACTIVE_MAINTENANCES } from 'queries/MaintenanceQueries';
// constants
import { BASIC_ROLE, BASIC_ROLES, SYSTEM_ROLE } from 'constants/roles';
import {
  POSITION_REPORT_TYPE,
  MATCH_TYPE,
  ANALYSIS_TYPE,
  PUBLIC_ROUTES,
  PREVIEW_TYPE,
  ROUTES_WITH_HEADER,
  ZENDESK_KEY,
  IS_DEVELOPMENT,
} from 'constants';
// components
import OutsideDetector from 'components/atoms/OutsideDetector';
import MaintenanceBanner from 'components/molecules/MaintenanceBanner';
import Menu from 'components/molecules/Menu';
import Header from 'components/molecules/Header';
import ProtectedRoute from 'components/molecules/ProtectedRoute';
import CookieSettings from 'components/organisms/CookieSettings';
import LazyPageLoader from 'components/templates/LazyPageLoader';
// pages
import DashboardPage from 'components/pages/DashboardPage';
import ErrorPage from 'components/pages/ErrorPage';
import SubmissionsPage from 'components/pages/SubmissionsPage';
import ProposalsPage from 'components/pages/ProposalsPage';
import DraftPage from 'components/pages/DraftPage';
import PositionPage from 'components/pages/PositionPage';
import SettingsPage from 'components/pages/SettingsPage';
import CandidatePage from 'components/pages/CandidatePage';
import PositionsPage from 'components/pages/PositionsPage';
import SubmissionPage from 'components/pages/SubmissionPage';
import ProposalPage from 'components/pages/ProposalPage';
import LoginPage from 'components/pages/LoginPage';
import SignUpPage from 'components/pages/SignUpPage';
import ForgotPasswordPage from 'components/pages/ForgotPasswordPage';
import ResetPassword from 'components/pages/ResetPasswordPage';
import ResetPasswordSuccessPage from 'components/pages/ResetPasswordSuccess';
import EmailVerificationPage from 'components/pages/EmailVerificationPage';
import LegalPage from 'components/pages/LegalPage';
import ImportsPage from '@client/pages/Import';
import SuppliersPage from '@client/pages/Supplier';
// styles
import 'dependencies/scss/grid.scss';

// lazy pages
const ProjectsPage = LazyPageLoader(() => import('components/pages/ProjectsPage'));
const CandidatesPage = LazyPageLoader(() => import('components/pages/CandidatesPage'));
const UsersPage = LazyPageLoader(() => import('components/pages/UsersPage'));
const ProjectUsersPage = LazyPageLoader(() => import('components/pages/ProjectUsersPage'));
const CreateNewUser = LazyPageLoader(() => import('components/pages/CreateNewUser'));
const CreateSubmissionPage = LazyPageLoader(() => import('components/pages/CreateSubmissionPage'));
const CreateProposalPage = LazyPageLoader(() => import('components/pages/CreateProposalPage'));
const CreateNewClient = LazyPageLoader(() => import('components/pages/CreateNewClient'));
const CreateNewPosition = LazyPageLoader(() => import('components/pages/CreateNewPosition'));
const CreateNewProject = LazyPageLoader(() => import('components/pages/CreateNewProject'));
const CognitiveReportPage = LazyPageLoader(() => import('components/pages/CognitiveReportPage'));
const CreateCandidatePage = LazyPageLoader(() => import('components/pages/CreateCandidatePage'));
const EditCandidatePage = LazyPageLoader(() => import('components/pages/EditCandidatePage'));

const propTypes = {
  isSmallScreen: PropTypes.bool.isRequired,
};

const MAINTENANCE_KEY = 'maintenanceId';
const isPublicRoute = location => PUBLIC_ROUTES.includes(location.pathname);
const isRouteWithHeader = location => ROUTES_WITH_HEADER.includes(location.pathname);

// TODO: App component is getting too big, with focusing and handling many different behaviors.
// Move into a separate folder and break it down into multiple smaller components
const App = ({ isSmallScreen }) => {
  const location = useLocation();
  const user = useAuth();
  const [maintenance, setMaintenance] = useState();
  const [menuOpen, setMenuOpen] = useState(false);
  const [controlsVisible, setControlsVisible] = useState(!isPublicRoute(location));
  const [headerVisible, setHeaderVisible] = useState(
    isRouteWithHeader(location) || !isPublicRoute(location)
  );

  // don't set value for headerVisible if it already has the same value
  const checkAndSetHeaderVisible = value => {
    if (value !== headerVisible) {
      setHeaderVisible(value);
    }
  };

  // don't set value for controlsVisible if it already has the same value
  const checkAndSetControlVisible = value => {
    if (value !== controlsVisible) {
      setControlsVisible(value);
    }
  };

  useEffect(() => {
    const fetchMaintenance = async () => {
      if (isPublicRoute(location)) {
        return;
      }

      const response = await client.query({
        query: GET_ACTIVE_MAINTENANCES,
        variables: { currentTime: moment().toISOString() },
        fetchPolicy: 'network-only',
      });

      const seenMaintenanceId = localStorage.getItem(MAINTENANCE_KEY);
      const currentMaintenanceId = getProperty(maintenance, 'id');
      const data = getProperty(response, 'data.maintenancesList.items.0', null);

      if (data && currentMaintenanceId !== data.id && seenMaintenanceId !== data.id) {
        setMaintenance(data);
      }
    };

    fetchMaintenance();

    // refetch every 10mins
    const intervalId = setInterval(
      () =>
        fetchMaintenance().catch(() => {
          clearInterval(intervalId);
        }),
      60 * 10 * 1000
    );

    return () => clearInterval(intervalId);
  }, []);

  const closeMaintenanceInfo = () => {
    localStorage.setItem(MAINTENANCE_KEY, maintenance.id);
    setMaintenance(null);
  };

  useEffect(() => {
    // when accessing pages, where just header component should be visible
    // header is visible, but menu is hidden
    if (isRouteWithHeader(location)) {
      checkAndSetHeaderVisible(true);
      checkAndSetControlVisible(false);
      // when accessing public pages except pages where header component should be visible
      // all controllers should be hidden
    } else if (isPublicRoute(location)) {
      checkAndSetHeaderVisible(false);
      checkAndSetControlVisible(false);
      // rest of pages are private pages where all controllers should be visible
    } else {
      checkAndSetHeaderVisible(true);
      checkAndSetControlVisible(true);
    }

    // remove maintenance info after expiration
    if (maintenance && moment(moment()).isAfter(maintenance.date)) {
      closeMaintenanceInfo();
    }
  }, [location.pathname]);

  const openMenu = () => {
    setMenuOpen(true);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const renderHeader = () => {
    return (
      <Route
        render={() => {
          if (!headerVisible) {
            return null;
          }

          return (
            <div className="header-layout">
              <Header openMenu={openMenu} />
            </div>
          );
        }}
      />
    );
  };

  const renderMenu = () => {
    return (
      <Route
        render={() => {
          if (!controlsVisible) {
            return null;
          }

          return (
            <div className="menu-layout fs-unmask">
              <div className={`menu ${menuOpen ? 'wider' : ''}`}>
                <Menu
                  close={closeMenu}
                  open={openMenu}
                  location={location.pathname}
                  menuOpen={menuOpen}
                />
              </div>
            </div>
          );
        }}
      />
    );
  };

  const getLayoutClass = () => {
    if (headerVisible && !controlsVisible) {
      return 'header-full';
    }
    return controlsVisible ? '' : 'full';
  };

  const renderMaintenanceBanner = () => {
    if (maintenance) {
      return (
        <MaintenanceBanner
          date={maintenance.date}
          text={maintenance.text}
          onClose={closeMaintenanceInfo}
        />
      );
    }
    return null;
  };

  const setEmailForZendesk = () => {
    if (user && user.email) {
      ZendeskAPI('webWidget', 'prefill', {
        email: {
          value: user.email,
          readOnly: true,
        },
      });
    }
  };

  return (
    <React.Fragment>
      <Zendesk zendeskKey={ZENDESK_KEY} onLoaded={setEmailForZendesk} />
      <CookieSettings />
      {renderMaintenanceBanner()}
      <div
        className={classNames({
          'grid-container': !maintenance,
          'grid-container-banner': maintenance,
          'fs-mask': !IS_DEVELOPMENT,
          wider: menuOpen,
        })}
      >
        {renderHeader()}
        {renderMenu()}
        <div
          className={`main-layout ${getLayoutClass()}`}
          style={menuOpen ? { overflow: 'hidden' } : null}
        >
          {isSmallScreen && menuOpen && (
            <div className="display-header-menu menu">
              <OutsideDetector className="menu-width h100" onClick={closeMenu}>
                <Menu close={closeMenu} open={openMenu} menuOpen={menuOpen} />
              </OutsideDetector>
            </div>
          )}

          <Switch>
            <ProtectedRoute exact path="/" granted={BASIC_ROLES} component={DashboardPage} />
            <ProtectedRoute
              exact
              path="/candidates/all"
              granted={BASIC_ROLES}
              component={SubmissionsPage}
            />
            <ProtectedRoute
              exact
              path="/submission/:id"
              granted={BASIC_ROLES}
              component={SubmissionPage}
            />
            <ProtectedRoute
              exact
              path="/submission/create/:positionId/:candidateMatchId"
              granted={BASIC_ROLE.SUPPLIER}
              customProps={{ candidate: true }}
              component={CreateSubmissionPage}
            />
            <ProtectedRoute
              exact
              path="/proposal/:id"
              granted={BASIC_ROLES}
              component={ProposalPage}
            />
            <ProtectedRoute
              exact
              path="/proposals/all"
              granted={BASIC_ROLES}
              component={ProposalsPage}
            />
            <ProtectedRoute
              exact
              path="/proposal/create/:positionId"
              granted={[BASIC_ROLE.SUPPLIER, BASIC_ROLE.MATCH_MANAGER]}
              component={CreateProposalPage}
            />
            <ProtectedRoute
              exact
              path="/proposal/edit/:proposalId/:positionId"
              granted={[BASIC_ROLE.SUPPLIER, BASIC_ROLE.MATCH_MANAGER]}
              customProps={{ edit: true }}
              component={CreateProposalPage}
            />
            <ProtectedRoute
              exact
              path="/proposal/draft/:draftId/:positionId"
              granted={[BASIC_ROLE.SUPPLIER, BASIC_ROLE.MATCH_MANAGER]}
              component={CreateProposalPage}
            />
            <ProtectedRoute
              exact
              path="/candidate/create"
              granted={BASIC_ROLE.SUPPLIER}
              component={CreateCandidatePage}
            />
            <ProtectedRoute
              exact
              path="/candidate/edit/:candidateId"
              granted={BASIC_ROLE.SUPPLIER}
              component={EditCandidatePage}
            />
            <ProtectedRoute
              exact
              path="/candidate/create/:positionId/:reportId/:userId?"
              granted={[BASIC_ROLE.SUPPLIER, BASIC_ROLE.MATCH_MANAGER]}
              component={CreateSubmissionPage}
            />
            <ProtectedRoute
              exact
              path="/candidate/edit/:submissionId/:positionId"
              granted={[BASIC_ROLE.SUPPLIER, BASIC_ROLE.MATCH_MANAGER]}
              customProps={{ edit: true }}
              component={CreateSubmissionPage}
            />
            <ProtectedRoute
              exact
              path="/candidate/draft/:draftId/:positionId/:reportId/:userId?"
              granted={BASIC_ROLES}
              customProps={{ draft: true }}
              component={CreateSubmissionPage}
            />
            <ProtectedRoute
              exact
              path="/candidate/:candidateId/:positionId?"
              granted={BASIC_ROLE.SUPPLIER}
              component={CandidatePage}
            />
            <ProtectedRoute
              exact
              path="/candidates"
              granted={BASIC_ROLE.SUPPLIER}
              component={CandidatesPage}
            />
            <ProtectedRoute
              exact
              path="/positions/all"
              granted={BASIC_ROLES}
              component={PositionsPage}
            />
            <ProtectedRoute
              exact
              path="/positions/mine"
              granted={[BASIC_ROLE.MATCH_MANAGER, BASIC_ROLE.SUPPLIER]}
              customProps={{ mine: true }}
              component={PositionsPage}
            />
            <ProtectedRoute
              exact
              path="/position/create"
              granted={BASIC_ROLE.PROJECT_MANAGER}
              component={CreateNewPosition}
            />
            <ProtectedRoute
              exact
              path="/position/edit/:positionId"
              granted={BASIC_ROLE.PROJECT_MANAGER}
              customProps={{ isEdit: true }}
              component={CreateNewPosition}
            />
            <ProtectedRoute
              exact
              path="/position/duplicate/:positionId"
              granted={BASIC_ROLE.PROJECT_MANAGER}
              customProps={{ isDuplicate: true }}
              component={CreateNewPosition}
            />
            <ProtectedRoute
              exact
              path="/position/draft/:draftId"
              granted={BASIC_ROLE.PROJECT_MANAGER}
              customProps={{ isDraft: true }}
              component={CreateNewPosition}
            />
            <ProtectedRoute
              exact
              path="/position/imports"
              granted={BASIC_ROLE.PROJECT_MANAGER}
              component={ImportsPage}
            />
            <ProtectedRoute
              exact
              path="/position/import/:importId"
              granted={BASIC_ROLE.PROJECT_MANAGER}
              customProps={{ isImport: true }}
              component={CreateNewPosition}
            />
            <ProtectedRoute
              exact
              path="/position/:positionId"
              granted={BASIC_ROLES}
              component={PositionPage}
            />
            <ProtectedRoute
              exact
              path="/projects"
              granted={SYSTEM_ROLE.SUPER_ADMIN}
              component={ProjectsPage}
            />
            <ProtectedRoute
              exact
              path="/project/create/:clientId"
              granted={SYSTEM_ROLE.SUPER_ADMIN}
              component={CreateNewProject}
            />
            <ProtectedRoute
              exact
              path="/project/edit/:projectId"
              customProps={{ edit: true }}
              granted={SYSTEM_ROLE.SUPER_ADMIN}
              component={CreateNewProject}
            />
            <ProtectedRoute
              exact
              path="/project/draft/:draftId/:clientId"
              customProps={{ draft: true }}
              granted={SYSTEM_ROLE.SUPER_ADMIN}
              component={CreateNewProject}
            />
            <ProtectedRoute
              exact
              path="/project/:projectId/users"
              granted={SYSTEM_ROLE.SUPER_ADMIN}
              component={ProjectUsersPage}
            />
            <ProtectedRoute
              exact
              path="/users"
              granted={SYSTEM_ROLE.SUPER_ADMIN}
              component={UsersPage}
            />
            <ProtectedRoute
              exact
              path="/user/create"
              granted={SYSTEM_ROLE.SUPER_ADMIN}
              component={CreateNewUser}
            />
            <ProtectedRoute
              exact
              path="/suppliers"
              granted={SYSTEM_ROLE.SUPER_ADMIN}
              component={SuppliersPage}
            />
            <ProtectedRoute
              exact
              path="/client/create"
              granted={SYSTEM_ROLE.SUPER_ADMIN}
              component={CreateNewClient}
            />
            <ProtectedRoute exact path="/settings" granted={BASIC_ROLES} component={SettingsPage} />
            <ProtectedRoute
              exact
              path="/report/position/:reportId"
              granted={BASIC_ROLES}
              customProps={{ type: POSITION_REPORT_TYPE }}
              component={CognitiveReportPage}
            />
            <ProtectedRoute
              exact
              path="/report/analysis/:reportId"
              granted={[BASIC_ROLE.SUPPLIER, BASIC_ROLE.MATCH_MANAGER]}
              customProps={{ type: ANALYSIS_TYPE }}
              component={CognitiveReportPage}
            />
            <ProtectedRoute
              exact
              path="/report/preview/:reportId/:submissionId?"
              granted={[BASIC_ROLE.SUPPLIER, BASIC_ROLE.MATCH_MANAGER]}
              customProps={{ type: PREVIEW_TYPE }}
              component={CognitiveReportPage}
            />
            <ProtectedRoute
              exact
              path="/report/match/:reportId"
              granted={BASIC_ROLES}
              customProps={{ type: MATCH_TYPE }}
              component={CognitiveReportPage}
            />
            <ProtectedRoute exact path="/drafts" granted={BASIC_ROLES} component={DraftPage} />

            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/signup" component={SignUpPage} />
            <Route exact path="/forgot-password" component={ForgotPasswordPage} />
            <Route exact path="/password-reset" component={ResetPassword} />
            <Route exact path="/email-verification" component={EmailVerificationPage} />
            <Route exact path="/reset-success" component={ResetPasswordSuccessPage} />
            <Route exact path="/legal/terms" component={LegalPage} />
            <Route exact path="/legal/privacy" component={LegalPage} />
            <Route exact path="/legal/cookies" component={LegalPage} />
            <Route exact path="/legal" render={() => <Redirect to="/legal/terms" />} />

            <Route
              render={() => {
                if (isPublicRoute(location)) {
                  return null;
                }

                return <ErrorPage code={404} />;
              }}
            />
          </Switch>
        </div>
      </div>
    </React.Fragment>
  );
};

App.propTypes = propTypes;

export default App;
