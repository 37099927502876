import React from 'react';
// libraries
import PropTypes from 'prop-types';
// components
import ClickableElement from 'components/atoms/ClickableElement';
import FileIcon from 'components/atoms/FileIcon';
import Icon from 'components/atoms/Icon';
// styles
import colors from 'dependencies/materialStyles/Colors';
import styles from './attachment.module.scss';

const propTypes = {
  filename: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

const defaultProps = {
  onClick: null,
};

const Attachment = ({ filename, onClick }) => {
  return (
    <ClickableElement onClick={onClick} className={styles.attachment}>
      <FileIcon fileExtension={filename} size={24} />
      <div className={styles.filename}>{filename}</div>
      {onClick && (
        <div className={styles.downloadIcon}>
          <Icon color={colors.gray} size={20} name="download" />
        </div>
      )}
    </ClickableElement>
  );
};

Attachment.propTypes = propTypes;
Attachment.defaultProps = defaultProps;

export default Attachment;
