import React, { useState, useContext } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
// services
import logger from 'services/Logger';
// context
import ToastContext from 'context/ToastContext';
// hooks
import useAuth from 'hooks/useAuth';
import useModalProvider from 'hooks/useModalProvider';
// helpers
import { filterSubmissionsByStatus } from 'helpers/Submission';
import { isCandidateSubmittableForPosition } from 'helpers/Position';
// constants
import { POSITION_TAB } from 'constants/pageTabs';
import { SUBMISSION_STATUS } from 'constants/submissionStatus';
import { MANAGER_ROLE, SUPPLIER_ROLE, STAFF_ROLE } from 'constants';
// components
import Loader from 'components/atoms/Loader';
import { PublishPosition, SubmitCandidates } from 'components/organisms/PositionSettings';
import Action from './Action';
import Resolution from './Resolution';

const propTypes = {
  position: PropTypes.object.isRequired,
  positionStatusId: PropTypes.string.isRequired,
};

const MODAL = {
  PUBLISH_POSITION: 'PUBLISH_POSITION',
  SUBMIT_CANDIDATES: 'SUBMIT_CANDIDATES',
};

const RecruitingActions = ({ position, positionStatusId, ...resolutionProps }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { addToast } = useContext(ToastContext);
  const { role, supplierId } = useAuth();
  const { isModalOpen, openModal, closeModal } = useModalProvider();
  const [isLoading, setIsLoading] = useState(false);

  const { report } = position;
  const submissions = position.getSubmissions(role, supplierId);
  const hasAnyNewCandidates =
    filterSubmissionsByStatus(submissions, SUBMISSION_STATUS.NEW).length > 0;

  const handleSubmission = async () => {
    try {
      setIsLoading(true);
      const ableToSubmit = await isCandidateSubmittableForPosition(position.id, role);

      if (!ableToSubmit) {
        addToast.warning(t('positionNotPublished'));
        return;
      }

      if (position.isTimeAndMaterials()) {
        history.push(`/report/analysis/${report.id}`);
      }
      if (position.isFixedPrice()) {
        history.push(`/proposal/create/${position.id}`);
      }
    } catch (error) {
      addToast.error(t('errorPlaceholderText'));
      logger.exception(error);
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loader small fixedSize />;
  }

  if (role === STAFF_ROLE) {
    // some new candidate -> display submit to client action
    if (hasAnyNewCandidates) {
      return (
        <React.Fragment>
          <Action
            label={t('submitToClient')}
            onClick={() => openModal(MODAL.SUBMIT_CANDIDATES)}
            tooltip={t('tooltipSubmitCandidateToClient')}
          />
          <SubmitCandidates
            isOpen={isModalOpen(MODAL.SUBMIT_CANDIDATES)}
            onClose={closeModal}
            onFinish={() => history.push(`/position/${position.id}?tab=${POSITION_TAB.RATES}`)}
            positionId={position.id}
          />
        </React.Fragment>
      );
    }

    // no candidates were submitted yet by partner
    if (position.isPublished(role, supplierId) && !hasAnyNewCandidates) {
      return (
        <Resolution
          tooltip={t('tooltipStaffWaitsForCandidateSubmissions')}
          label={t('resolutionStaffWaitsForCandidateSubmissions')}
          {...resolutionProps}
        />
      );
    }

    // position is not published -> display Publish action
    if (!position.isPublished(role, supplierId)) {
      return (
        <React.Fragment>
          <Action
            label={t('publishPosition')}
            onClick={() => openModal(MODAL.PUBLISH_POSITION)}
            tooltip={t('tooltipActionPublishPosition')}
          />
          <PublishPosition
            isOpen={isModalOpen(MODAL.PUBLISH_POSITION)}
            position={position}
            onClose={closeModal}
          />
        </React.Fragment>
      );
    }
  }

  if (role === MANAGER_ROLE) {
    return (
      <Resolution label={t('resolutionManagerWaitsForCandidateSubmissions')} {...resolutionProps} />
    );
  }

  if (role === SUPPLIER_ROLE) {
    // Position is published to supplier
    if (position.isPublished(role, supplierId)) {
      return (
        <Action
          label={t('uploadSubmission')}
          onClick={handleSubmission}
          tooltip={t('tooltipActionSubmitCandidate')}
        />
      );
    }
    // Position is currently not published to supplier
    return <Resolution label={t('closedForCvSubmission')} {...resolutionProps} />;
  }

  return null;
};

RecruitingActions.propTypes = propTypes;

export default RecruitingActions;
