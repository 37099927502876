import React from 'react';
import type { IconType } from 'react-icons';
import { MdOpenInNew } from 'react-icons/md';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Icon from '@components/Icon';

interface FileRecordActionLinkProps {
  label: string;
  link: string;
  icon?: IconType;
  targetSelf?: boolean;
}

const FileRecordActionLink = ({
  icon,
  label,
  link,
  targetSelf = false,
}: FileRecordActionLinkProps) => (
  <Tooltip title={label} placement="top">
    <IconButton href={link} target={targetSelf ? '_self' : '_blank'}>
      <Icon component={icon || MdOpenInNew} size="small" />
    </IconButton>
  </Tooltip>
);

export default FileRecordActionLink;
