import { HttpLink } from '@apollo/client';
// constants
import { DB_URL } from 'constants/db';

// link for queries and mutations
const httpLink = () =>
  new HttpLink({
    uri: operation => {
      switch (operation.getContext().clientName) {
        case 'server':
          return '/graphql';
        case 'newServer':
          return '/import/graphql';
        default:
          return DB_URL;
      }
    },
  });

export default httpLink;
