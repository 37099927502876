import React, { useState } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// components
import Button from 'components/atoms/Button';
import TextField from 'components/atoms/TextField';
import TextFieldList from 'components/molecules/TextFieldList';

const emailPropTypes = PropTypes.shape({
  value: PropTypes.string,
  error: PropTypes.string,
});

const propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.shape({
    subject: emailPropTypes,
    from: emailPropTypes,
    to: emailPropTypes,
    cc: PropTypes.arrayOf(emailPropTypes),
    bcc: PropTypes.arrayOf(emailPropTypes),
    replyTo: emailPropTypes,
  }),
  showErrors: PropTypes.bool,
  emailOptionsOpen: PropTypes.bool,
};

const defaultProps = {
  options: {
    subject: { value: '', error: '' },
    from: { value: '', error: '' },
    to: { value: '', error: '' },
    cc: [{ value: '', error: '' }],
    bcc: [{ value: '', error: '' }],
    replyTo: { value: '', error: '' },
  },
  showErrors: true,
  emailOptionsOpen: false,
};

const EmailOptions = React.memo(({ onChange, options, showErrors, emailOptionsOpen }) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(emailOptionsOpen);
  const { subject, from, to, cc, bcc, replyTo } = options;

  const toggleOptions = () => {
    setOpen(!isOpen);
  };

  const handleChange = (field, value) => {
    onChange({
      ...options,
      [field]: {
        error: '',
        value,
      },
    });
  };

  const handleArrayChange = (field, value) => {
    onChange({
      ...options,
      [field]: value.map(option => ({ ...option, error: '' })),
    });
  };

  return (
    <div className="emailOptions">
      <div className="emailOptions_header">
        <div className="emailOptions_title">{t('emailOptions')}</div>
        <Button
          type={!isOpen ? 'expandMore' : 'less'}
          tooltip={`${isOpen ? t('collapse') : t('expand')}`}
          onClick={toggleOptions}
        />
      </div>
      {isOpen && (
        <React.Fragment>
          <div className="emailOptions_element">
            <TextField
              label={t('subject')}
              value={subject.value}
              error={showErrors ? subject.error : ''}
              required
              onChange={value => handleChange('subject', value)}
            />
          </div>
          <div className="emailOptions_element">
            <TextField
              label={t('sender')}
              value={from.value}
              error={showErrors ? from.error : ''}
              required
              disabled
              onChange={value => handleChange('from', value)}
            />
          </div>
          <div className="emailOptions_element">
            <TextField
              label={t('recipient')}
              value={to.value}
              error={showErrors ? to.error : ''}
              required
              onChange={value => handleChange('to', value)}
            />
          </div>
          <div className="emailOptions_element">
            <TextFieldList
              label="cc"
              fields={cc}
              showErrors={showErrors}
              onChange={value => handleArrayChange('cc', value)}
            />
          </div>
          <div className="emailOptions_element">
            <TextFieldList
              label="bcc"
              fields={bcc}
              showErrors={showErrors}
              onChange={value => handleArrayChange('bcc', value)}
            />
          </div>
          <div className="emailOptions_element">
            <TextField
              label={t('replyTo')}
              value={replyTo.value}
              error={showErrors ? replyTo.error : ''}
              onChange={value => handleChange('replyTo', value)}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
});

EmailOptions.propTypes = propTypes;
EmailOptions.defaultProps = defaultProps;

export default EmailOptions;
