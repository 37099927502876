import React from 'react';
import InfiniteScrollLib from 'react-infinite-scroll-component';
import Loader from '@components/Loader';
import styles from './infiniteLoader.module.scss';

interface InfiniteScrollProps {
  itemsLength: number;
  hasMore: boolean;
  hasItems: boolean;
  onFetchMore: () => void;
  children: React.ReactNode;
  scrollableTarget?: React.ReactNode;
}

const InfiniteScroll = ({
  hasMore,
  hasItems,
  itemsLength,
  onFetchMore,
  children,
  scrollableTarget,
}: InfiniteScrollProps) => (
  <InfiniteScrollLib
    dataLength={itemsLength}
    next={onFetchMore}
    hasMore={hasMore}
    scrollableTarget={scrollableTarget}
    hasChildren={hasItems}
    loader={
      <div className={styles.loader}>
        <Loader ariaLabel="Loading more items..." />
      </div>
    }
  >
    {children}
  </InfiniteScrollLib>
);

export default InfiniteScroll;
