import { useState, useCallback, useEffect } from 'react';
// constants
import { VIEW_CARD } from 'constants';
// hooks
import { useCookieSettings } from 'components/organisms/CookieSettings';
import usePrevious from './usePrevious';

const initView = (functionalityCookiesAllowed, pageType, defaultView) => {
  if (functionalityCookiesAllowed) {
    return localStorage.getItem(`VIEW_${pageType}`) || defaultView;
  }

  return defaultView;
};

const useViewSwitcher = (pageType, defaultView = VIEW_CARD) => {
  const { isFunctionalityCookiesAccepted } = useCookieSettings();
  const functionalityCookiesAllowed = isFunctionalityCookiesAccepted();
  const prevFunctionalityCookiesAllowed = usePrevious(functionalityCookiesAllowed);
  const [view, setView] = useState(initView(functionalityCookiesAllowed, pageType, defaultView));

  const changeView = useCallback(
    newView => {
      if (functionalityCookiesAllowed) {
        localStorage.setItem(`VIEW_${pageType}`, newView);
      }

      setView(newView);
    },
    [functionalityCookiesAllowed]
  );

  useEffect(() => {
    // remove stored view if functionality cookies are disabled
    if (!functionalityCookiesAllowed && prevFunctionalityCookiesAllowed) {
      Object.keys(localStorage).forEach(key => {
        if (key.startsWith('VIEW_')) {
          localStorage.removeItem(key);
        }
      });
    }
  }, [functionalityCookiesAllowed]);

  return [view, changeView];
};

export default useViewSwitcher;
