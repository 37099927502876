import i18n from 'i18next';
// constants
import { STAFF_ROLE, SUPPLIER_ROLE, MANAGER_ROLE } from 'constants';
import {
  POSITION_STATUS_FILTERS,
  POSITION_SUBMISSION_FILTERS,
  DUE_DATE_FILTERS as DUE_DATE_FILTERS_OPTIONS,
  PUBLISH_STATUS_FILTERS as PUBLISH_STATUS_FILTERS_OPTIONS,
  CONTRACT_TYPE_FILTERS as CONTRACT_TYPE_FILTERS_OPTIONS,
  DELIVERY_OPTION_FILTERS as DELIVERY_OPTION_FILTERS_OPTIONS,
} from 'constants/filters';

export const DUE_DATE_FILTERS = [
  {
    label: i18n.t('dueToday'),
    value: DUE_DATE_FILTERS_OPTIONS.DUE_TODAY,
  },
  {
    label: i18n.t('dueTomorrow'),
    value: DUE_DATE_FILTERS_OPTIONS.DUE_TOMORROW,
  },
  {
    label: i18n.t('dueInNext7Days'),
    value: DUE_DATE_FILTERS_OPTIONS.DUE_NEXT_7_DAYS,
  },
  {
    label: i18n.t('expired'),
    value: DUE_DATE_FILTERS_OPTIONS.EXPIRED,
  },
  {
    label: i18n.t('notExpired'),
    value: DUE_DATE_FILTERS_OPTIONS.NOT_EXPIRED,
  },
];

const DEFAULT_POSITION_STATUS_FILTERS = [
  {
    label: i18n.t('recruiting'),
    value: POSITION_STATUS_FILTERS.RECRUITING,
    items: [],
  },
  {
    label: i18n.t('selection'),
    value: POSITION_STATUS_FILTERS.SELECTION,
    items: [],
  },
  {
    label: i18n.t('onboarding'),
    value: POSITION_STATUS_FILTERS.ONBOARDING,
    items: [
      {
        label: i18n.t('offer'),
        value: POSITION_STATUS_FILTERS.OFFER,
      },
      {
        label: i18n.t('onboardingInProgress'),
        value: POSITION_STATUS_FILTERS.ONBOARDING_IN_PROGRESS,
      },
      {
        label: i18n.t('onboardingCompleted'),
        value: POSITION_STATUS_FILTERS.ONBOARDING_COMPLETED,
      },
    ],
  },
  {
    label: i18n.t('delivery'),
    value: POSITION_STATUS_FILTERS.DELIVERY,
    items: [],
  },
  {
    label: i18n.t('closed'),
    value: POSITION_STATUS_FILTERS.CLOSED,
    items: [
      {
        label: i18n.t('noSubmissions'),
        value: POSITION_STATUS_FILTERS.NO_SUBMISSIONS,
      },
      {
        label: i18n.t('noSelection'),
        value: POSITION_STATUS_FILTERS.NO_SELECTION,
      },
    ],
  },
  {
    label: i18n.t('cancelled'),
    value: POSITION_STATUS_FILTERS.CANCELLED,
    items: [],
  },
];

const PM_POSITION_STATUS_FILTERS = [
  {
    label: i18n.t('recruiting'),
    value: POSITION_STATUS_FILTERS.RECRUITING,
    items: [],
  },
  {
    label: i18n.t('selection'),
    value: POSITION_STATUS_FILTERS.SELECTION,
    items: [
      {
        label: i18n.t('pendingSelection'),
        value: POSITION_STATUS_FILTERS.PENDING_SELECTION,
      },
      {
        label: i18n.t('noCandidateSelected'),
        value: POSITION_STATUS_FILTERS.NO_SELECTION,
      },
    ],
  },
  {
    label: i18n.t('onboarding'),
    value: POSITION_STATUS_FILTERS.ONBOARDING,
    items: [
      {
        label: i18n.t('offer'),
        value: POSITION_STATUS_FILTERS.OFFER,
      },
      {
        label: i18n.t('onboardingInProgress'),
        value: POSITION_STATUS_FILTERS.ONBOARDING_IN_PROGRESS,
      },
      {
        label: i18n.t('onboardingCompleted'),
        value: POSITION_STATUS_FILTERS.ONBOARDING_COMPLETED,
      },
      {
        label: i18n.t('onboardingCancelled'),
        value: POSITION_STATUS_FILTERS.ONBOARDING_CANCELLED,
      },
    ],
  },
  {
    label: i18n.t('delivery'),
    value: POSITION_STATUS_FILTERS.DELIVERY,
    items: [],
  },
  {
    label: i18n.t('cancelled'),
    value: POSITION_STATUS_FILTERS.CANCELLED,
    items: [],
  },
];

export const POSITION_STATUS_FILTERS_MAP = {
  [STAFF_ROLE]: DEFAULT_POSITION_STATUS_FILTERS,
  [SUPPLIER_ROLE]: DEFAULT_POSITION_STATUS_FILTERS,
  [MANAGER_ROLE]: PM_POSITION_STATUS_FILTERS,
};

const SUBMISSION_FILTERS_MM = [
  {
    label: i18n.t('noCandidateReceived'),
    value: POSITION_SUBMISSION_FILTERS.NO_CANDIDATE_RECEIVED,
  },
  {
    label: i18n.t('candidateReceived'),
    value: POSITION_SUBMISSION_FILTERS.CANDIDATE_RECEIVED,
  },
  {
    label: i18n.t('noCandidateSubmitted'),
    value: POSITION_SUBMISSION_FILTERS.NO_CANDIDATE_SUBMITTED,
  },
  {
    label: i18n.t('candidateSubmitted'),
    value: POSITION_SUBMISSION_FILTERS.CANDIDATE_SUBMITTED,
  },
];

const SUBMISSION_FILTERS_SUPPLIER = [
  {
    label: i18n.t('noCandidateSubmitted'),
    value: POSITION_SUBMISSION_FILTERS.NO_CANDIDATE_RECEIVED,
  },
  {
    label: i18n.t('candidateSubmitted'),
    value: POSITION_SUBMISSION_FILTERS.CANDIDATE_RECEIVED,
  },
  {
    label: i18n.t('notSubmittedToClient'),
    value: POSITION_SUBMISSION_FILTERS.NO_CANDIDATE_SUBMITTED,
  },
  {
    label: i18n.t('submittedToClient'),
    value: POSITION_SUBMISSION_FILTERS.CANDIDATE_SUBMITTED,
  },
];

const SUBMISSION_FILTERS_PM = [
  {
    label: i18n.t('noCandidateReceived'),
    value: POSITION_SUBMISSION_FILTERS.NO_CANDIDATE_RECEIVED,
  },
  {
    label: i18n.t('candidateReceived'),
    value: POSITION_SUBMISSION_FILTERS.CANDIDATE_RECEIVED,
  },
  {
    label: i18n.t('noCandidateSelected'),
    value: POSITION_SUBMISSION_FILTERS.NO_CANDIDATE_SUBMITTED,
  },
  {
    label: i18n.t('candidateSelected'),
    value: POSITION_SUBMISSION_FILTERS.CANDIDATE_SUBMITTED,
  },
];

export const SUBMISSIONS_FILTERS_MAP = {
  [STAFF_ROLE]: SUBMISSION_FILTERS_MM,
  [SUPPLIER_ROLE]: SUBMISSION_FILTERS_SUPPLIER,
  [MANAGER_ROLE]: SUBMISSION_FILTERS_PM,
};

export const PUBLISH_STATUS_FILTERS = [
  {
    label: i18n.t('published'),
    value: PUBLISH_STATUS_FILTERS_OPTIONS.PUBLISHED,
  },
  {
    label: i18n.t('unpublished'),
    value: PUBLISH_STATUS_FILTERS_OPTIONS.UNPUBLISHED,
  },
];

export const CONTRACT_TYPE_FILTERS = [
  {
    label: i18n.t('timeAndMaterial'),
    value: CONTRACT_TYPE_FILTERS_OPTIONS.TIME_AND_MATERIAL,
  },
  {
    label: i18n.t('fixedPrice'),
    value: CONTRACT_TYPE_FILTERS_OPTIONS.FIXED_PRICE,
  },
];

export const DELIVERY_OPTION_FILTERS = [
  {
    label: i18n.t('onsite'),
    value: DELIVERY_OPTION_FILTERS_OPTIONS.ONSITE,
  },
  {
    label: i18n.t('offsite'),
    value: DELIVERY_OPTION_FILTERS_OPTIONS.OFFSITE,
  },
];
