import moment from 'moment';
import flatten from 'lodash/flatten';
import intersection from 'lodash/intersection';
import uniq from 'lodash/uniq';
// constants
import { DUE_DATE_FILTERS } from 'constants/filters';
import { SUBMISSION_STATUS } from 'constants/submissionStatus';

const notSubmissionStatus = status => {
  return {
    statuses: {
      none: {
        AND: [
          {
            name: {
              equals: status,
            },
          },
          {
            end: {
              is_empty: true,
            },
          },
        ],
      },
    },
  };
};

export const getFilterForManagerSubmissions = () => ({
  AND: [
    notSubmissionStatus(SUBMISSION_STATUS.NEW),
    notSubmissionStatus(SUBMISSION_STATUS.NOT_SUBMITTED),
  ],
});

export const getFilterForSupplierSubmissions = supplierId => ({
  candidate: {
    supplier: {
      id: { equals: supplierId },
    },
  },
});

export const getFilterForSupplierProposals = supplierId => ({
  supplier: {
    id: { equals: supplierId },
  },
});

/**
 * Get filter by projects id
 * @param {string[]} projects
 * @returns {string}
 */
export const getFilterByProjects = projects => {
  return {
    project: {
      id: {
        in: projects,
      },
    },
  };
};

/**
 * @param {string} option Filter option
 * @param {string} field Field name in DB
 */
export const getFilterByDueDate = (option, field = 'dueOn') => {
  if (option === DUE_DATE_FILTERS.EXPIRED) {
    return [{ [field]: { lt: moment().toISOString() } }];
  }

  if (option === DUE_DATE_FILTERS.NOT_EXPIRED) {
    return [{ [field]: { gte: moment().toISOString() } }];
  }

  if (option === DUE_DATE_FILTERS.DUE_TOMORROW) {
    return [
      {
        [field]: {
          gte: moment()
            .add(1, 'day')
            .set({ hour: 0, minute: 0, second: 0 })
            .toISOString(),
        },
      },
      {
        [field]: {
          lte: moment()
            .add(1, 'day')
            .set({ hour: 23, minute: 59, second: 59 })
            .toISOString(),
        },
      },
    ];
  }

  if (option === DUE_DATE_FILTERS.DUE_TODAY) {
    return [
      { [field]: { gte: moment().toISOString() } },
      {
        [field]: {
          lte: moment()
            .set({ hour: 23, minute: 59, seconds: 59 })
            .toISOString(),
        },
      },
    ];
  }

  // filter by Due in next 7 days
  return [
    { [field]: { gte: moment().toISOString() } },
    {
      [field]: {
        lte: moment()
          .add(7, 'days')
          .set({ hour: 23, minute: 59, seconds: 59 })
          .toISOString(),
      },
    },
  ];
};

/**
 * Get due date filters
 * @param {string[]} options array of due date filters
 * @param {string} field name of field which is used as due date
 */
export const getFiltersByDueDate = (options, field = 'dueOn') => {
  return {
    OR: options.map(filter => {
      return { AND: getFilterByDueDate(filter, field) };
    }),
  };
};

/**
 * Get list of filter options which are allowed to select
 * @param {string[]} selectedOptions
 * @param {string} role
 * @returns {string[]} Array of allowed filter options
 */
export const parseAllowedFilterOptions = (selectedOptions, combinationsMap) => {
  // get allowed options and apply logical OR (merge allowed options) for options in the same group
  const allowedOptionsByGroups = selectedOptions.map(selectedOption =>
    uniq(flatten([].concat(selectedOption).map(option => combinationsMap[option])))
  );

  // apply logical AND (find intersection) between filter groups
  return flatten(intersection(...allowedOptionsByGroups));
};

/**
 * Is given option disabled?
 * @param {object} option filter option
 * @param {string[]} allowedOptions
 * @param {string[]} selectedOptions
 * @param {object} [parentOption] Only for sub-options
 * @returns {boolean}
 */
const isOptionDisabled = (option, allowedOptions, selectedOptions, parentOption = null) => {
  if (parentOption && selectedOptions.includes(parentOption.value)) {
    return false;
  }

  return !allowedOptions.includes(option.value) && !selectedOptions.includes(option.value);
};

/**
 * Apply disabled prop for options
 * @param {object[]} options filter options
 * @param {string[]} allowedOptions
 * @param {string[]} selectedOptions
 * @returns {object}
 */
export const parseFilterOptions = (
  options,
  allowedOptions,
  selectedOptions,
  parentOption = null
) => {
  return options.map(option => {
    // If no filter option selected -> all option is enabled
    if (!selectedOptions.length) {
      const updatedOption = {
        ...option,
        disabled: false,
      };

      // status filters have nested options
      if (option.items) {
        updatedOption.items = option.items.map(item => ({
          ...item,
          disabled: false,
        }));
      }

      return updatedOption;
    }

    const updatedOption = {
      ...option,
      disabled: isOptionDisabled(option, allowedOptions, selectedOptions, parentOption),
    };

    if (option.items) {
      // parse recursively nested options
      updatedOption.items = parseFilterOptions(
        option.items,
        allowedOptions,
        selectedOptions,
        option
      );
    }

    return updatedOption;
  });
};

/**
 * @param {object[]} options
 * @param {string} selectedOption
 * @returns {string}
 */
const findLabelForFilter = (options, selectedOption) => {
  const foundFilter = options.find(filterItem => filterItem.value === selectedOption);

  return foundFilter ? foundFilter.label : selectedOption;
};

/**
 * Get selected filter options label
 * @param {object} selectedOptions
 * @param {object[]} options List of all filter options
 * @returns {object[]}
 */
export const parseSelectedFilterOptionsLabel = (selectedOptions, options) => {
  return Object.keys(selectedOptions).reduce((list, type) => {
    if (Array.isArray(selectedOptions[type])) {
      const items = selectedOptions[type].map(item => ({
        value: item,
        label: findLabelForFilter(options, item),
        type,
      }));

      return [...list, ...items];
    }

    return [
      ...list,
      {
        value: selectedOptions[type],
        label: findLabelForFilter(options, selectedOptions[type]),
        type,
      },
    ];
  }, []);
};
