/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  '\n  query fetchRecord($id: ID!) {\n    record(id: $id) {\n      id\n      createdAt\n      filename\n      downloadLink\n      status\n      parsedData\n    }\n  }\n':
    types.fetchRecordDocument,
  '\n  query fetchRecords($limit: Int!, $after: String, $fullText: String) {\n    recordsList(filter: { fullText: $fullText }, limit: $limit, after: $after) {\n      count\n      items {\n        id\n        createdAt\n        filename\n        downloadLink\n        status\n        parsedData\n      }\n      pageInfo {\n        endCursor\n      }\n    }\n  }\n':
    types.fetchRecordsDocument,
  '\n  subscription onStatusUpdate {\n    statusUpdated {\n      id\n      status\n      parsedData\n    }\n  }\n':
    types.onStatusUpdateDocument,
  '\n  subscription onNewRecord {\n    newRecord {\n      id\n      createdAt\n      filename\n      downloadLink\n      status\n      parsedData\n    }\n  }\n':
    types.onNewRecordDocument,
  '\n  mutation createSupplier($data: NewSupplierCreateInput!) {\n    supplierCreate(data: $data) {\n      success\n    }\n  }\n':
    types.createSupplierDocument,
  '\n  mutation deleteSupplier($id: ID!) {\n    supplierDelete(id: $id) {\n      success\n    }\n  }\n':
    types.deleteSupplierDocument,
  '\n  mutation editSupplier($id: ID!, $data: NewSupplierEditInput!) {\n    supplierEdit(id: $id, data: $data) {\n      success\n    }\n  }\n':
    types.editSupplierDocument,
  '\n  query fetchSupplierRecords($limit: Int!, $skip: Int, $filter: NewSupplierFilter) {\n    suppliersList(filter: $filter, limit: $limit, skip: $skip) {\n      count\n      items {\n        id\n        createdAt\n        deleted\n        category\n        includeCSAFee\n        company {\n          id\n          name\n        }\n      }\n    }\n  }\n':
    types.fetchSupplierRecordsDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query fetchRecord($id: ID!) {\n    record(id: $id) {\n      id\n      createdAt\n      filename\n      downloadLink\n      status\n      parsedData\n    }\n  }\n'
): (typeof documents)['\n  query fetchRecord($id: ID!) {\n    record(id: $id) {\n      id\n      createdAt\n      filename\n      downloadLink\n      status\n      parsedData\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query fetchRecords($limit: Int!, $after: String, $fullText: String) {\n    recordsList(filter: { fullText: $fullText }, limit: $limit, after: $after) {\n      count\n      items {\n        id\n        createdAt\n        filename\n        downloadLink\n        status\n        parsedData\n      }\n      pageInfo {\n        endCursor\n      }\n    }\n  }\n'
): (typeof documents)['\n  query fetchRecords($limit: Int!, $after: String, $fullText: String) {\n    recordsList(filter: { fullText: $fullText }, limit: $limit, after: $after) {\n      count\n      items {\n        id\n        createdAt\n        filename\n        downloadLink\n        status\n        parsedData\n      }\n      pageInfo {\n        endCursor\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  subscription onStatusUpdate {\n    statusUpdated {\n      id\n      status\n      parsedData\n    }\n  }\n'
): (typeof documents)['\n  subscription onStatusUpdate {\n    statusUpdated {\n      id\n      status\n      parsedData\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  subscription onNewRecord {\n    newRecord {\n      id\n      createdAt\n      filename\n      downloadLink\n      status\n      parsedData\n    }\n  }\n'
): (typeof documents)['\n  subscription onNewRecord {\n    newRecord {\n      id\n      createdAt\n      filename\n      downloadLink\n      status\n      parsedData\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation createSupplier($data: NewSupplierCreateInput!) {\n    supplierCreate(data: $data) {\n      success\n    }\n  }\n'
): (typeof documents)['\n  mutation createSupplier($data: NewSupplierCreateInput!) {\n    supplierCreate(data: $data) {\n      success\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation deleteSupplier($id: ID!) {\n    supplierDelete(id: $id) {\n      success\n    }\n  }\n'
): (typeof documents)['\n  mutation deleteSupplier($id: ID!) {\n    supplierDelete(id: $id) {\n      success\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation editSupplier($id: ID!, $data: NewSupplierEditInput!) {\n    supplierEdit(id: $id, data: $data) {\n      success\n    }\n  }\n'
): (typeof documents)['\n  mutation editSupplier($id: ID!, $data: NewSupplierEditInput!) {\n    supplierEdit(id: $id, data: $data) {\n      success\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query fetchSupplierRecords($limit: Int!, $skip: Int, $filter: NewSupplierFilter) {\n    suppliersList(filter: $filter, limit: $limit, skip: $skip) {\n      count\n      items {\n        id\n        createdAt\n        deleted\n        category\n        includeCSAFee\n        company {\n          id\n          name\n        }\n      }\n    }\n  }\n'
): (typeof documents)['\n  query fetchSupplierRecords($limit: Int!, $skip: Int, $filter: NewSupplierFilter) {\n    suppliersList(filter: $filter, limit: $limit, skip: $skip) {\n      count\n      items {\n        id\n        createdAt\n        deleted\n        category\n        includeCSAFee\n        company {\n          id\n          name\n        }\n      }\n    }\n  }\n'];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
