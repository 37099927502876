import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
// components
import Tooltip from 'components/atoms/Tooltip';
import TimezoneDate from 'components/atoms/TimezoneDate';
// helpers
import { getPassedRelativeTime, getTimezoneTime } from 'helpers/RelativeTimeFormatter';
import { formatNumber, prettyNumber } from 'helpers/Number';
// styles
import styles from '../publishBanner.module.scss';
import Audience from '../Audience';

const propTypes = {
  supplierDueDate: PropTypes.string.isRequired,
  unPublishedOn: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
  firstYearRate: PropTypes.number,
  currency: PropTypes.string.isRequired,
  publishAudience: PropTypes.array.isRequired,
  publishedToAll: PropTypes.bool.isRequired,
};

const defaultProps = {
  firstYearRate: null,
};

const Content = ({
  supplierDueDate,
  currency,
  firstYearRate,
  unPublishedOn,
  timezone,
  publishedToAll,
  publishAudience,
}) => {
  const { t } = useTranslation();
  const rate = firstYearRate ? prettyNumber(formatNumber(firstYearRate)) : null;

  const UnPublishInfo = () => (
    <div className={styles.publishInfo}>
      <Tooltip title={getTimezoneTime(unPublishedOn)}>
        <span>
          {t('unpublished')}&nbsp;{getPassedRelativeTime(unPublishedOn)}
        </span>
      </Tooltip>
    </div>
  );

  const DueDate = () => (
    <b>
      <TimezoneDate date={supplierDueDate} timezone={timezone} />
    </b>
  );

  return (
    <div className={styles.content}>
      <div>
        {rate && (
          <Trans
            i18nKey="unpublishedBannerPublishedBefore"
            values={{ rate, currency }}
            components={[
              <Audience publishedToAll={publishedToAll} publishAudience={publishAudience} />,
              <DueDate />,
              <b />,
            ]}
          />
        )}
        {!rate && (
          <Trans
            i18nKey="unpublishedBannerPublishedBeforeNoRate"
            components={[
              <Audience publishedToAll={publishedToAll} publishAudience={publishAudience} />,
              <DueDate />,
            ]}
          />
        )}
      </div>
      <UnPublishInfo />
    </div>
  );
};

Content.propTypes = propTypes;
Content.defaultProps = defaultProps;

export default Content;
