import i18n from 'i18next';

export const SORT_IMB_GP = 'ibmGP';
export const SORT_SCORE = 'score';
export const SORT_SUPPLIER = 'supplier';
export const SORT_RECEIVED = 'received';
export const SORT_STATUS = 'status';

const getSortingOptions = isFixedPrice => {
  const options = [
    {
      label: i18n.t('dateReceived'),
      value: SORT_RECEIVED,
    },
    {
      label: i18n.t('ibmGP'),
      value: SORT_IMB_GP,
    },
  ];

  if (!isFixedPrice) {
    options.push({
      label: i18n.t('score'),
      value: SORT_SCORE,
    });
  }

  options.push(
    {
      label: i18n.t('status'),
      value: SORT_STATUS,
    },
    {
      label: i18n.t('supplier'),
      value: SORT_SUPPLIER,
    }
  );

  return options;
};

export default getSortingOptions;
