import { gql } from '@apollo/client';
import getProperty from 'lodash/get';
import moment from 'moment';
// services
import client from 'services/Client';
import logger from 'services/Logger';
// queries
import { SubmissionStatus, PositionStatus, Note } from 'queries/Fragments';
// helpers
import { getSubmissionCurrentStatus } from 'helpers/Submission';
// constants
import { NOTE_SUBMISSION_STATUS, NOTE_ONBOARDING_CANCELLED } from 'constants';
import { POSITION_STATUS } from 'constants/positionStatus';
import { HIRING_PROCESS_STATUS } from 'constants/onboarding';
import { SUBMISSION_STATUS, SUBMISSION_STATUS_TYPE } from 'constants/submissionStatus';

const FETCH_SUBMISSION = gql`
  query fetchSubmissionDataForOnboardingWithdrawal($id: ID!) {
    submission(id: $id) {
      id
      hiringProcess {
        id
      }
      statuses {
        items {
          ...SubmissionStatus
        }
      }
      position {
        id
        submissions {
          items {
            id
            statuses {
              items {
                ...SubmissionStatus
              }
            }
          }
        }
        statuses {
          items {
            ...PositionStatus
          }
        }
      }
    }
  }
  ${SubmissionStatus}
  ${PositionStatus}
`;

const FETCH_PROPOSAL = gql`
  query fetchProposalDataForOnboardingWithdrawal($id: ID!) {
    proposal(id: $id) {
      id
      hiringProcess {
        id
      }
      statuses {
        items {
          ...SubmissionStatus
        }
      }
      position {
        id
        proposals {
          items {
            id
            statuses {
              items {
                ...SubmissionStatus
              }
            }
          }
        }
        statuses {
          items {
            ...PositionStatus
          }
        }
      }
    }
  }
  ${SubmissionStatus}
  ${PositionStatus}
`;

const CANCEL_HIRING_PROCESS_FOR_SUBMISSION = gql`
  mutation cancelHiringProcessForSubmission($submissionId: ID!, $data: SubmissionUpdateInput!) {
    submissionUpdate(filter: { id: $submissionId }, data: $data) {
      id
      position {
        id
        statuses(sort: { createdAt: DESC }) {
          items {
            ...PositionStatus
          }
        }
      }
      statuses(sort: { createdAt: DESC }) {
        items {
          ...SubmissionStatus
        }
      }
      hiringProcess {
        id
        status
        cancelledStep {
          ...SubmissionStatus
        }
        notes {
          items {
            ...Note
          }
        }
      }
    }
  }
  ${PositionStatus}
  ${SubmissionStatus}
  ${Note}
`;

const CANCEL_HIRING_PROCESS_FOR_PROPOSAL = gql`
  mutation cancelHiringProcessForProposal($submissionId: ID!, $data: ProposalUpdateInput!) {
    proposalUpdate(filter: { id: $submissionId }, data: $data) {
      id
      position {
        id
        statuses(sort: { createdAt: DESC }) {
          items {
            ...PositionStatus
          }
        }
      }
      statuses(sort: { createdAt: DESC }) {
        items {
          ...SubmissionStatus
        }
      }
      hiringProcess {
        id
        status
        cancelledStep {
          ...SubmissionStatus
        }
        notes {
          items {
            ...Note
          }
        }
      }
    }
  }
  ${PositionStatus}
  ${SubmissionStatus}
  ${Note}
`;

/**
 * @param {object} params
 * @param {object} params.submission
 * @param {string} params.reason
 * @param {string} params.userId Logged in user
 * @param {boolean} params.cancelPosition
 * @param {boolean} params.isProposal
 * @returns {Promise}
 */
const cancelHiringProcess = ({ submission, reason, userId, cancelPosition, isProposal }) => {
  const statuses = getProperty(submission, 'statuses.items', []);
  const currentStatus = getSubmissionCurrentStatus(statuses);
  const currentOnboardingStatus = statuses.find(
    status => status.type !== SUBMISSION_STATUS_TYPE.BASIC && !status.end
  );

  const mutationData = {
    hiringProcess: {
      update: {
        status: HIRING_PROCESS_STATUS.CANCELLED,
        cancelledStep: { connect: { id: currentOnboardingStatus.id } },
        notes: {
          create: {
            note: reason,
            noteType: NOTE_ONBOARDING_CANCELLED,
            author: { connect: { id: userId } },
          },
        },
      },
    },
    statuses: {
      update: {
        filter: { id: currentStatus.id },
        data: { end: moment().toISOString() },
      },
      create: {
        start: moment().toISOString(),
        name: SUBMISSION_STATUS.WITHDRAWN,
        note: {
          create: {
            note: reason,
            noteType: NOTE_SUBMISSION_STATUS,
            author: { connect: { id: userId } },
          },
        },
      },
    },
  };

  if (cancelPosition) {
    const position = getProperty(submission, 'position', {});
    const positionStatuses = getProperty(position, 'statuses.items', []);
    const positionStatus = positionStatuses.find(status => !status.end);

    mutationData.position = {
      update: {
        statuses: {
          update: {
            filter: { id: positionStatus.id },
            data: { end: moment().toISOString() },
          },
          create: {
            start: moment().toISOString(),
            name: POSITION_STATUS.ONBOARDING_CANCELLED,
          },
        },
      },
    };
  }

  return client.mutate({
    mutation: isProposal
      ? CANCEL_HIRING_PROCESS_FOR_PROPOSAL
      : CANCEL_HIRING_PROCESS_FOR_SUBMISSION,
    variables: {
      submissionId: submission.id,
      data: mutationData,
    },
  });
};

/**
 * @param {object} params
 * @param {string} params.id
 * @param {string} params.reason
 * @param {string} params.userId Logged in user id
 * @param {boolean} params.isProposal
 * @returns {Promise}
 */
const withdrawSubmission = async ({ id, reason, userId, isProposal }) => {
  try {
    const response = await client.query({
      query: isProposal ? FETCH_PROPOSAL : FETCH_SUBMISSION,
      variables: { id },
    });
    const submission = isProposal
      ? getProperty(response, 'data.proposal', {})
      : getProperty(response, 'data.submission', {});

    // is no other submission in onboarding state -> update position status
    const submissions = isProposal
      ? getProperty(submission, 'position.proposals.items', [])
      : getProperty(submission, 'position.submissions.items', []);
    const hasMoreOnboardingCandidates = submissions.some(submissionItem => {
      const submissionStatus = getProperty(submissionItem, 'statuses.items', []);
      const currentSubmissionStatus = getSubmissionCurrentStatus(submissionStatus);

      return (
        submissionItem.id !== id &&
        [SUBMISSION_STATUS.ONBOARDING, SUBMISSION_STATUS.OFFER].includes(
          currentSubmissionStatus.name
        )
      );
    });

    await cancelHiringProcess({
      submission,
      reason,
      userId,
      cancelPosition: !hasMoreOnboardingCandidates,
      isProposal,
    });
  } catch (error) {
    logger.exception(error, { id, reason });
    throw error;
  }
};

export default withdrawSubmission;
