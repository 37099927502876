import React, { useState, useContext } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
// context
import ToastContext from 'context/ToastContext';
// constants
import { POSITION_TYPE } from 'constants';
// components
import DatePicker from 'components/atoms/DatePickerCustom';
import FieldSection from 'components/molecules/FieldSection';
import CheckboxList from 'components/molecules/CheckboxList';
import Modal from 'components/molecules/ModalWindow';
// helpers
import { getStatusText } from 'helpers/NameMapping';
import { getSubmissionCurrentStatus } from 'helpers/Submission';
import { reopenPosition } from './helpers';

const propTypes = {
  position: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const ReopenPosition = ({ position, isOpen, onClose }) => {
  const { t } = useTranslation();
  const { addToast } = useContext(ToastContext);
  const isFixedPricePosition = position.contractType.value === POSITION_TYPE.FIXED_PRICE;
  const [selectedSubmissions, setSelectedSubmissions] = useState(
    position.getSubmissions().map(({ id }) => id)
  );
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    dueDate: moment(position.dueDate) || moment(),
  });

  const getSubmissionLabel = submission => {
    const currentStatus = getSubmissionCurrentStatus(submission.statuses?.items || []);
    const name = isFixedPricePosition ? submission.name : submission.profile?.fullName;

    return `${name} (${getStatusText(currentStatus?.name)})`;
  };

  const isValid = () => {
    if (!formData.dueDate) {
      return false;
    }

    if (moment(formData.dueDate).isBefore(moment())) {
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    // validate
    if (!isValid()) {
      addToast.warning(t('invalidInput'));
    }

    try {
      setLoading(true);
      const submissionsToRest = position
        .getSubmissions()
        .filter(submission => selectedSubmissions.includes(submission.id));
      await reopenPosition(position, submissionsToRest, formData.dueDate.toISOString());
      addToast.success('Position was successfully re-opened');
      onClose();
    } catch (error) {
      addToast.error(t('errorPlaceholderText'));
    } finally {
      setLoading(false);
    }
  };

  const handleChange = field => value => {
    setFormData(data => ({
      ...data,
      [field]: value,
    }));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md" loading={loading}>
      <Modal.Header>
        {t('reopenPosition')} - {position.rfqLabel}
      </Modal.Header>
      <Modal.Body>
        <FieldSection
          title={
            isFixedPricePosition
              ? t('fixedPricePositionForm.dueOnLabel')
              : t('timeAndMaterialForm.dueOnLabel')
          }
          description={
            isFixedPricePosition
              ? t('fixedPricePositionForm.dueOnDescription')
              : t('timeAndMaterialForm.dueOnDescription')
          }
          classes={{
            content: 'm-t-8',
          }}
        >
          <DatePicker
            id="dueDate"
            value={formData.dueDate}
            onChange={handleChange('dueDate')}
            enableTime
            minDate={moment()}
          />
        </FieldSection>
        <FieldSection
          title="Reset submissions status"
          description='For selected submissions the status will be reset back to "New"'
          classes={{
            root: 'm-t-20',
            content: 'm-t-8',
          }}
        >
          <CheckboxList
            options={position
              .getSubmissions()
              .map(submission => ({ label: getSubmissionLabel(submission), value: submission.id }))}
            selected={selectedSubmissions}
            onChange={setSelectedSubmissions}
          />
        </FieldSection>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Buttons
          primaryLabel={t('submit')}
          primaryAction={handleSubmit}
          secondaryAction={onClose}
        />
      </Modal.Footer>
    </Modal>
  );
};

ReopenPosition.propTypes = propTypes;

export default ReopenPosition;
