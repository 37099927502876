import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
// constants
import { GET_FIELDS_OPTIONS } from 'queries/TableQueries';
// components
import ErrorPlaceholder from 'components/atoms/ErrorPlaceholder';
import { OptionsProvider, OptionsConsumer } from './OptionsContext';
// helpers
import parseOptions from './Helpers';

const propTypes = {
  tableName: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};

const TableOption = ({ tableName, fieldName, children }) => {
  const { loading, data, error } = useQuery(GET_FIELDS_OPTIONS, { variables: { tableName } });

  if (error) {
    return <ErrorPlaceholder />;
  }

  return (
    <OptionsProvider value={{ values: parseOptions(data, fieldName), loading }}>
      <OptionsConsumer>{children}</OptionsConsumer>
    </OptionsProvider>
  );
};

TableOption.propTypes = propTypes;

export default TableOption;
