import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// components
import LinkButton from 'components/atoms/LinkButton';
import DropdownCheckbox from 'components/molecules/DropdownCheckbox';
// helpers
import { getOptionLabel } from 'helpers/Common';
// data
import filteringOptions from './FilteringOptions';

const propTypes = {
  sortingOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedFilterBy: PropTypes.string.isRequired,
  onSelectedFilterBy: PropTypes.func.isRequired,
  selectedSortBy: PropTypes.string.isRequired,
  onSortByChange: PropTypes.func.isRequired,
  numberOfHiddenCandidates: PropTypes.number.isRequired,
  onShowHiddenCandidatesClick: PropTypes.func.isRequired,
};

const CandidateFilter = React.memo(
  ({
    sortingOptions,
    selectedFilterBy,
    onSelectedFilterBy,
    selectedSortBy,
    onSortByChange,
    numberOfHiddenCandidates,
    onShowHiddenCandidatesClick,
  }) => {
    const { t } = useTranslation();

    return (
      <div className="positionCandidates_filter fs-unmask">
        <div className="oneRow">
          <DropdownCheckbox
            name="candidates-sorting"
            selected={selectedSortBy}
            list={sortingOptions}
            checkedLabel={
              <span>
                <span className="checkboxContainer_header-italic">{t('sortedBy')}:</span>
                <span className="p-l-5">{getOptionLabel(sortingOptions, selectedSortBy)}</span>
              </span>
            }
            onChange={item => onSortByChange(item.value)}
            width={180}
          />
          <div className="m-l-12">
            <DropdownCheckbox
              name="candidates-filtering"
              selected={selectedFilterBy}
              list={filteringOptions}
              checkedLabel={
                <span>
                  <span className="checkboxContainer_header-italic">{t('filterByStatus')}:</span>
                  <span className="p-l-5">
                    {getOptionLabel(filteringOptions, selectedFilterBy)}
                  </span>
                </span>
              }
              onChange={item => onSelectedFilterBy(item.value)}
              width={190}
            />
          </div>
        </div>
        <div>
          {numberOfHiddenCandidates > 0 && (
            <LinkButton onClick={onShowHiddenCandidatesClick}>
              {t('showHiddenSubmissions')} ({numberOfHiddenCandidates})
            </LinkButton>
          )}
        </div>
      </div>
    );
  }
);

CandidateFilter.propTypes = propTypes;

export default CandidateFilter;
