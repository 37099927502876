import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// helpers
import { getReadableYearDateTime } from 'helpers/RelativeTimeFormatter';
// components
import Tooltip from 'components/atoms/Tooltip';
// styles
import styles from './positionCard.module.scss';

const propTypes = {
  isPublished: PropTypes.bool.isRequired,
  partnerDueDate: PropTypes.string,
  unPublishedOn: PropTypes.string,
};

const defaultProps = {
  unPublishedOn: null,
  partnerDueDate: null,
};

const PublishStatus = ({ isPublished, partnerDueDate, unPublishedOn }) => {
  const { t } = useTranslation();
  const formattedDate = partnerDueDate ? getReadableYearDateTime(partnerDueDate) : null;
  const unPublishDate = unPublishedOn ? getReadableYearDateTime(unPublishedOn) : null;
  const unPublishTooltip = unPublishDate ? `${t('unPublishedOn')} ${unPublishDate}` : '';

  const title = isPublished ? `${t('publishedUntil')} ${formattedDate}` : unPublishTooltip;
  const label = isPublished ? t('published') : t('unpublished');

  return (
    <Tooltip title={title} placement="bottom">
      <div className={classNames(styles.positionStatus, { [styles.published]: isPublished })}>
        {label}
      </div>
    </Tooltip>
  );
};

PublishStatus.propTypes = propTypes;
PublishStatus.defaultProps = defaultProps;

export default PublishStatus;
