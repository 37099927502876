import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
// constants
import { SUPPLIER_ROLE } from 'constants';
import { MAIN_RECRUITING } from 'constants/positionStatus';
// components
import Nbsp from 'components/atoms/Nbsp';
import Placeholder from 'components/atoms/Placeholder';
import CandidatesMatchRow from './CandidateMatchRow';
// styles
import styles from './candidatesMatchTable.module.scss';

const propTypes = {
  position: PropTypes.object.isRequired,
  supplierId: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const CandidatesMatchTable = ({ position, supplierId, items }) => {
  const { t } = useTranslation();
  const positionStatus = position.getMainStatus(SUPPLIER_ROLE);
  const isPublished = position.isPublished(SUPPLIER_ROLE, supplierId);

  if (!items.length) {
    return (
      <Placeholder className={styles.noResults}>{t('noCandidatesForSelectedFilters')}</Placeholder>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.table}>
        <div className={styles.grid}>
          <div className={classNames(styles.head, styles.center)}>{t('score')}</div>
          <div className={styles.head}>{t('name')}</div>
          <div className={styles.head}>{t('status')}</div>
          <div className={classNames(styles.head, styles.right)}>{t('expectedMinimumRate')}</div>
          <div className={styles.head}>{t('location')}</div>
          <div className={classNames(styles.head, styles.center)}>{t('cvReadability')}</div>
          <div className={classNames(styles.head, styles.right)}>{t('lastUpdated')}</div>
          <div>
            <Nbsp />
          </div>
        </div>
        {items.map((data, index) => (
          <div className={classNames({ 'm-t-2': index })} key={data.id}>
            <CandidatesMatchRow
              data={data}
              position={position}
              allowSubmission={positionStatus.value === MAIN_RECRUITING && isPublished}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

CandidatesMatchTable.propTypes = propTypes;

export default CandidatesMatchTable;
