import React, { useRef, useEffect } from 'react';
// libraries
import PropTypes from 'prop-types';
import AutoSizedTextarea from 'react-textarea-autosize';
import classNames from 'classnames';
// styles
import styles from './textarea.module.scss';

const propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  autofocus: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  rows: PropTypes.number,
  maxRows: PropTypes.number,
  minRows: PropTypes.number,
};

const defaultProps = {
  className: '',
  onBlur: null,
  disabled: false,
  autofocus: false,
  error: false,
  rows: 5,
  maxRows: undefined,
  minRows: undefined,
};

const Textarea = ({
  value,
  className,
  onChange,
  onBlur,
  disabled,
  autofocus,
  error,
  rows,
  maxRows,
  minRows,
  ...props
}) => {
  const element = useRef(null);

  useEffect(() => {
    if (autofocus) {
      element.current.focus();
    }
  }, [autofocus]);

  const handleBlur = event => {
    if (onBlur) {
      onBlur(event.target.value, event);
    }
  };

  return (
    <AutoSizedTextarea
      value={value}
      className={classNames(styles.textarea, className, {
        [styles.disabled]: disabled,
        [styles.error]: !disabled && error,
      })}
      onChange={event => onChange(event.target.value, event)}
      onBlur={handleBlur}
      disabled={disabled}
      rows={rows}
      maxRows={maxRows}
      minRows={minRows || rows}
      ref={element}
      {...props}
    />
  );
};

Textarea.propTypes = propTypes;
Textarea.defaultProps = defaultProps;

export default Textarea;
