import { makeVar } from '@apollo/client';

export const reactiveVar = makeVar([]);

/**
 * @param {string} type
 * @returns {number}
 */
export const getItemCount = type => {
  const foundItem = reactiveVar().find(item => item.type === type);

  return foundItem ? foundItem.count : 0;
};

/**
 * Update or add count for items
 * @param {string} type
 * @param {number} count
 */
export const updateItem = (type, count) => {
  // prevent update if count doesn't change for given type
  if (getItemCount(type) === count) {
    return;
  }

  const items = reactiveVar().filter(item => item.type !== type);
  reactiveVar([...items, { type, count }]);
};
