import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// components
import Flow from './Flow';
// constants
import { STATUS_STATE } from './constants';
// styles
import styles from './statusFlow.module.scss';

const propTypes = {
  statuses: PropTypes.arrayOf(
    PropTypes.shape({
      state: PropTypes.oneOf(Object.values(STATUS_STATE)).isRequired,
      label: PropTypes.string.isRequired,
      start: PropTypes.string,
    })
  ).isRequired,
  color: PropTypes.string.isRequired,
  note: PropTypes.string,
};

const defaultProps = {
  note: '',
};

const StatusFlow = ({ statuses, color, note }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Flow statuses={statuses} color={color} />
      {note && (
        <div className={styles.status}>
          <div className={styles.statusTitle}>
            <b>{t('statusNote')}</b>
          </div>
          {note}
        </div>
      )}
    </React.Fragment>
  );
};

StatusFlow.propTypes = propTypes;
StatusFlow.defaultProps = defaultProps;

export default StatusFlow;
