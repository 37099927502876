import React, { useContext, useState } from 'react';
// libraries
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
// hooks
import useAuth from 'hooks/useAuth';
import useIsMounted from 'hooks/useIsMounted';
// context
import ToastContext from 'context/ToastContext';
// components
import Modal from 'components/molecules/ModalWindow';
import SubmissionRejection, {
  validate as validateReason,
} from 'components/molecules/SubmissionRejection';
// helpers
import { rejectSubmission } from './helpers';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onFinish: PropTypes.func,
  statusId: PropTypes.string.isRequired,
  statusValue: PropTypes.string.isRequired,
  isProposal: PropTypes.bool.isRequired,
  position: PropTypes.object,
};

const defaultProps = {
  onFinish: () => {},
  position: null,
};

const RejectSubmission = ({
  id,
  isOpen,
  name,
  statusId,
  statusValue,
  onClose,
  onFinish,
  isProposal,
  position,
}) => {
  const { id: userId } = useAuth();
  const { addToast } = useContext(ToastContext);
  const { t } = useTranslation();
  const isMounted = useIsMounted();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [note, setNote] = useState('');

  const handleReasonChange = value => {
    if (errors) {
      setErrors([]);
    }

    setNote(value);
  };

  const handleSubmit = async () => {
    const reasonErrors = validateReason(note);

    if (reasonErrors.length) {
      setErrors(reasonErrors);
      return;
    }

    try {
      setLoading(true);

      await rejectSubmission({
        submissionId: id,
        statusId,
        statusValue,
        isProposal,
        reason: note,
        userId,
        position,
      });
      addToast.success(t('candidateRejectionSuccessToast'));
      onFinish();
    } catch (error) {
      addToast.error(t('errorPlaceholderText'));
    } finally {
      if (isMounted()) {
        setLoading(false);
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} loading={loading}>
      <Modal.Header>
        <Trans i18nKey="candidateRejection">Submission {{ name }} rejection</Trans>
      </Modal.Header>
      <Modal.Body>
        <SubmissionRejection reason={note} onChange={handleReasonChange} errors={errors} />
      </Modal.Body>
      <Modal.Footer>
        <Modal.Buttons
          primaryLabel={t('submit')}
          primaryAction={handleSubmit}
          secondaryAction={onClose}
        />
      </Modal.Footer>
    </Modal>
  );
};

RejectSubmission.propTypes = propTypes;
RejectSubmission.defaultProps = defaultProps;

export default RejectSubmission;
