/* eslint-disable import/order */
import React, { useState } from 'react';
// libraries
import PropTypes from 'prop-types';
// hooks
import useAuth from 'hooks/useAuth';
// constants
import { RATES_NTE_RATE, RATES_TARGET_RATE, STAFF_ROLE } from 'constants';
import { DEFAULT_IBM_FEE, DEFAULT_IBM_FEE_FP } from 'constants/rates';
// helpers
import { calculateManYearDiscount } from 'helpers/RatesCalculation';
import { initTargetRates } from './helpers';
// components
import PublishBanner, { showPublishBanner } from 'components/organisms/PublishBanner';
import ErrorPage from 'components/pages/ErrorPage';
import RFQRates from './RFQRates';
import Submissions from './Submissions';
// context
import { MultipliersProvider } from './MultipliersContext';
import { PositionProvider } from './PositionContext';

const propTypes = {
  position: PropTypes.object.isRequired,
};

const Position = React.memo(({ position }) => {
  const [ibmFee, setIbmFee] = useState(
    position.isFixedPrice() ? DEFAULT_IBM_FEE_FP : DEFAULT_IBM_FEE
  );

  const user = useAuth();
  const { role } = user;

  if (role !== STAFF_ROLE) {
    return <ErrorPage code={401} />;
  }

  const nteRates = position.getRates(RATES_NTE_RATE);
  const targetRatesFromDb = position.getRates(RATES_TARGET_RATE);
  const targetRates = initTargetRates(nteRates, targetRatesFromDb);
  const { hours } = position.duration;
  const enableManYearDiscount = position.getConfig('enableManYearDiscount', false);
  const multipliers = {
    ibmFee,
    nteRates,
    manYearDiscount: enableManYearDiscount ? calculateManYearDiscount(hours) : 0,
  };

  return (
    <PositionProvider value={position}>
      <MultipliersProvider value={multipliers}>
        {showPublishBanner(position, user) && <PublishBanner position={position} />}
        <div className="m-b-15">
          <RFQRates
            id={position.id}
            hours={hours}
            onFeeChange={setIbmFee}
            nteRates={nteRates}
            targetRates={targetRates}
            isFixedPrice={position.isFixedPrice()}
          />
        </div>
        <Submissions
          maximumCandidatesAllowed={position.maximumCandidatesAllowed}
          submissions={position.isFixedPrice() ? position.proposals : position.submissions}
          position={position}
        />
      </MultipliersProvider>
    </PositionProvider>
  );
});

Position.propTypes = propTypes;

export default Position;
