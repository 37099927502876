import React from 'react';
// libraries
import PropTypes from 'prop-types';
// components
import HexagonAvatar from './Hexagon';
import CircleAvatar from './Circle';
// constants
import { SHAPE_OPTIONS } from './constants';

const propTypes = {
  shape: PropTypes.oneOf([SHAPE_OPTIONS.CIRCLE, SHAPE_OPTIONS.HEXAGON]),
  children: PropTypes.any,
};

const defaultProps = {
  children: null,
  shape: SHAPE_OPTIONS.CIRCLE,
};

const Avatar = ({ shape, children, ...props }) => {
  if (shape === SHAPE_OPTIONS.HEXAGON) {
    if (children) {
      return <HexagonAvatar {...props}>{children}</HexagonAvatar>;
    }
    return <HexagonAvatar {...props} />;
  }

  if (children) {
    return <CircleAvatar {...props}>{children}</CircleAvatar>;
  }
  return <CircleAvatar {...props} />;
};

Avatar.propTypes = propTypes;
Avatar.defaultProps = defaultProps;

export default Avatar;
