import React, { useState, useEffect, useCallback } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import debounce from 'lodash/debounce';
// queries
import { GET_LOCATION } from 'queries/SearchQueries';
// components
import Input from 'components/atoms/NewInput';
import CheckboxListInput from 'components/molecules/CheckboxListInput';
import RadioListInput from 'components/molecules/RadioListInput';
import AutoCompleteSearch from 'components/molecules/AutoCompleteSearch';
// helpers
import { parseLocationData } from 'helpers/FormDataParser';
// constants
import {
  FILTER_BY,
  SORT_OPTIONS,
  STATUS_FILTER_OPTIONS,
  LAST_CV_UPDATE_OPTIONS,
} from '../constants';
// styles
import styles from './candidatesMatchFilter.module.scss';

const propTypes = {
  filteredBy: PropTypes.object,
  sortedBy: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired,
  clearanceOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
};

const defaultProps = {
  filteredBy: {},
  clearanceOptions: [],
};

const CandidatesMatchFilter = ({
  filteredBy,
  sortedBy,
  onFilterChange,
  onSortChange,
  clearanceOptions,
}) => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState(filteredBy[FILTER_BY.FULL_TEXT] || '');

  const selectedClearances = filteredBy[FILTER_BY.CLEARANCE] || [];
  const selectedStatuses = filteredBy[FILTER_BY.STATUS] || [];

  // update local state for search text when new search text is passed to component via props
  useEffect(() => {
    const incomingSearchText = filteredBy[FILTER_BY.FULL_TEXT] || '';

    if (searchText !== incomingSearchText && searchText.length > 2) {
      setSearchText(incomingSearchText);
    }
  }, [filteredBy[FILTER_BY.FULL_TEXT]]);

  // don't filter list immediately when the text is changed, but rather use debounce
  const debouncedSearchTextChange = useCallback(
    debounce(text => onFilterChange({ ...filteredBy, [FILTER_BY.FULL_TEXT]: text }), 400),
    [filteredBy]
  );

  const handleSearchTextChange = value => {
    setSearchText(value);

    // apply search if at least 3 chars were filled
    if (value.length > 2) {
      debouncedSearchTextChange(value);
    } else if (searchText.length > 2 && value.length <= 2) {
      debouncedSearchTextChange('');
    }
  };

  const handleFilterChange = field => value => {
    onFilterChange({ ...filteredBy, [field]: value });
  };

  return (
    <div className={styles.container}>
      <Grid container spacing={16}>
        {/* Full text search */}
        <Grid item sm={12} md={6} lg={4}>
          <Input
            value={searchText}
            placeholder={t('candidatePoolSearchPlaceholder')}
            onChange={handleSearchTextChange}
            icon="search"
          />
        </Grid>

        {/* Sorting */}
        <Grid item sm={12} md={6} lg={3}>
          <RadioListInput
            label={`${t('sortedBy')}:`}
            selected={sortedBy.value}
            options={SORT_OPTIONS}
            onChange={onSortChange}
            noRemoveButton
          />
        </Grid>

        {/* Clearances */}
        <Grid item sm={12} md={6} lg={5}>
          <CheckboxListInput
            icon="verified"
            placeholder={t('clearance')}
            options={clearanceOptions}
            selected={selectedClearances}
            onChange={handleFilterChange(FILTER_BY.CLEARANCE)}
          />
        </Grid>

        {/* Statuses */}
        <Grid item sm={12} md={6} lg={4}>
          <CheckboxListInput
            icon="calendarAvailable"
            placeholder={t('status')}
            options={STATUS_FILTER_OPTIONS}
            selected={selectedStatuses}
            onChange={handleFilterChange(FILTER_BY.STATUS)}
          />
        </Grid>

        {/* Last CV update */}
        <Grid item sm={12} md={6} lg={4}>
          <RadioListInput
            icon="time"
            label={`${t('cvUpdated')}:`}
            placeholder={t('lastUpdatedCv')}
            options={LAST_CV_UPDATE_OPTIONS}
            selected={filteredBy[FILTER_BY.LAST_CV_UPDATE]}
            onChange={handleFilterChange(FILTER_BY.LAST_CV_UPDATE)}
          />
        </Grid>

        {/* Location */}
        <Grid item sm={12} md={6} lg={4}>
          <AutoCompleteSearch
            type="locationsList"
            placeholder={t('searchByLocation')}
            customQuery={GET_LOCATION}
            customDataParser={data => parseLocationData(data, true)}
            onChange={option => handleFilterChange(FILTER_BY.LOCATION)(option ? option.value : '')}
          />
        </Grid>
      </Grid>
    </div>
  );
};

CandidatesMatchFilter.propTypes = propTypes;
CandidatesMatchFilter.defaultProps = defaultProps;

export default CandidatesMatchFilter;
