import React from 'react';
// libraries
import PropTypes from 'prop-types';
// components
import Chip from 'components/atoms/NewChip';
import ClickableElement from 'components/atoms/ClickableElement';
// constants
import { COLOR } from 'constants/colors';

const propTypes = {
  selected: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  classes: PropTypes.shape({
    root: PropTypes.string,
    chip: PropTypes.string,
  }),
};

const defaultProps = {
  size: 'md',
  disabled: false,
  classes: {},
};

const ToggleChip = ({ selected, onChange, children, classes, size, disabled }) => {
  return (
    <ClickableElement className={classes.root} disabled={disabled} onClick={onChange}>
      <Chip className={classes.chip} size={size} color={selected ? COLOR.BLUE : COLOR.GRAY}>
        {children}
      </Chip>
    </ClickableElement>
  );
};

ToggleChip.propTypes = propTypes;
ToggleChip.defaultProps = defaultProps;

export default ToggleChip;
