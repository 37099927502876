import React from 'react';
// libraries
import PropTypes from 'prop-types';
import isNotNumber from 'lodash/isNaN';

const propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
  yearsOfExperience: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  order: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  highlighted: PropTypes.bool,
  showYears: PropTypes.bool,
};

const defaultProps = {
  className: '',
  yearsOfExperience: null,
  order: null,
  highlighted: false,
  showYears: false,
  title: '',
  id: null,
};

const Level = props => {
  const { className, title, yearsOfExperience, order, id, highlighted, showYears } = props;
  return (
    <div className={`${className} levels levels_empty`}>
      <div className={`${highlighted ? 'highlighted' : ''} levels_levelsItem`} key={id}>
        {order ? `${order} - ` : ''}
        {title}
      </div>
      {!isNotNumber(yearsOfExperience) && showYears ? (
        <div className="levels_description">({yearsOfExperience} years)</div>
      ) : null}
    </div>
  );
};

Level.propTypes = propTypes;
Level.defaultProps = defaultProps;

export default Level;
