import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// components
import Textarea from 'components/atoms/Textarea';
import Field from 'components/molecules/FieldSection';
import CheckboxList from 'components/molecules/CheckboxList';
// helpers
import { isCustomReason, parseReason, buildReason } from './helpers';
// constants
import { NOTE_FIELD, REASON_FIELD, REJECTION_REASONS } from './constants';

const propTypes = {
  onChange: PropTypes.func.isRequired,
  reason: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string.isRequired),
  className: PropTypes.string,
};

const defaultProps = {
  errors: [],
  className: '',
};

const SubmissionRejection = ({ reason, onChange, errors, className }) => {
  const { t } = useTranslation();
  const { [NOTE_FIELD]: note, [REASON_FIELD]: reasons } = parseReason(reason);

  const handleChange = field => value => {
    let reasonText = '';

    if (field === REASON_FIELD) {
      reasonText = buildReason(value, note);
    } else if (field === NOTE_FIELD) {
      reasonText = buildReason(reasons, value);
    }

    onChange(reasonText);
  };

  return (
    <div className={className}>
      <Field
        title={t('selectReasonLabelKey')}
        error={errors.includes(REASON_FIELD)}
        errorMessages={errors.includes(REASON_FIELD) ? t('checkboxListMinOption') : ''}
        classes={{
          content: 'm-t-12',
        }}
      >
        <CheckboxList
          options={REJECTION_REASONS}
          selected={reasons}
          onChange={handleChange(REASON_FIELD)}
        />
      </Field>
      <div className="m-t-30">
        <Field
          title={t('additionalRejectionNoteLabelKey')}
          optional={!isCustomReason(reasons)}
          error={errors.includes(NOTE_FIELD)}
          errorMessages={errors.includes(NOTE_FIELD) ? t('missingValueError') : ''}
          classes={{
            content: 'm-t-12',
          }}
        >
          <Textarea value={note} onChange={handleChange(NOTE_FIELD)} />
        </Field>
      </div>
    </div>
  );
};

SubmissionRejection.propTypes = propTypes;
SubmissionRejection.defaultProps = defaultProps;

export default SubmissionRejection;
