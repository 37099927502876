import React, { useState } from 'react';
// libraries
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
// hooks
import useAuth from 'hooks/useAuth';
// components
import Button from 'components/atoms/Button';
import MenuItem from 'components/atoms/MenuItem';
import Popper from 'components/atoms/Popper';
import RoleChanger from 'components/molecules/RoleChanger';
import User from 'components/molecules/User';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const HeaderSettings = ({ className }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { role, projectRoles } = useAuth();
  const [roleSwitcherOpen, setRoleSwitcherOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onRoleChangeSuccess = () => {
    setRoleSwitcherOpen(false);
    history.push('/');
  };

  const signOut = () => {
    handleClose();
    window.location.replace(`${window.location.origin}/auth/logout`);
  };

  const switchRole = () => {
    handleClose();
    setRoleSwitcherOpen(true);
  };

  const onSettingsClick = () => {
    handleClose();
    history.push('/settings');
  };

  const getLink = param => {
    return `${window.location.origin}${param}`;
  };

  return (
    <div className={className}>
      <Button
        type="more"
        tooltip={t('open')}
        tooltipPosition="bottom"
        onClick={handleClick}
        color="white"
        iconStyle="icon20"
      />
      <Popper
        isOpen={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        placement="bottom-end"
        className="m-t-5"
      >
        <User />
        <div className="headerSettings_buttons fs-unmask">
          {projectRoles.length > 1 && (
            <MenuItem iconName="switch" primaryText={t('switchRole')} onClick={switchRole} />
          )}
          <MenuItem iconName="settings" primaryText={t('settings')} onClick={onSettingsClick} />
          <MenuItem iconName="signout" primaryText={t('signOut')} onClick={signOut} />
        </div>
        <div className="headerSettings_links fs-unmask">
          <a href={getLink('/legal/terms')} target="_blank" rel="noopener noreferrer">
            {t('termsOfUse')}
          </a>
          <span className="headerSettings_circle">•</span>
          <a href={getLink('/legal/privacy')} target="_blank" rel="noopener noreferrer">
            {t('privacyPolicy')}
          </a>
        </div>
      </Popper>
      <RoleChanger
        isOpen={roleSwitcherOpen}
        key={role}
        onClose={() => setRoleSwitcherOpen(false)}
        onSuccess={onRoleChangeSuccess}
      />
    </div>
  );
};

HeaderSettings.propTypes = propTypes;
HeaderSettings.defaultProps = defaultProps;

export default HeaderSettings;
