import client from '@services/client';
import { gql } from '@services/gql';
import { SupplierFormData } from '../types';

const CREATE_SUPPLIER = gql(/* GraphQL */ `
  mutation createSupplier($data: NewSupplierCreateInput!) {
    supplierCreate(data: $data) {
      success
    }
  }
`);

const createRecord = async (data: SupplierFormData): Promise<void> => {
  await client.mutate({
    mutation: CREATE_SUPPLIER,
    variables: { data },
  });
};

export default createRecord;
