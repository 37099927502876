import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
// Libraries
import Creatable from 'react-select/creatable';
import AsyncPaginate from 'react-select-async-paginate';
// helpers
import { addNewOption } from './loadOptions';

const propTypes = {
  onChange: PropTypes.func,
  createNew: PropTypes.bool,
  disabled: PropTypes.bool,
};

const defaultProps = {
  onChange: () => {},
  createNew: false,
  disabled: false,
};

const increaseUniq = uniq => uniq + 1;

const AutoComplete = ({ createNew, onChange, disabled, ...selectProps }) => {
  const [cacheUniq, setCacheUniq] = useState(0);
  const [isAddingInProgress, setIsAddingInProgress] = useState(false);

  const onCreateOption = useCallback(async inputValue => {
    setIsAddingInProgress(true);

    const newOption = await addNewOption(inputValue);

    setIsAddingInProgress(false);
    setCacheUniq(increaseUniq);
    onChange(newOption);
  }, []);

  if (createNew) {
    return (
      <AsyncPaginate
        {...selectProps}
        onChange={selected => onChange(selected)}
        SelectComponent={Creatable}
        isDisabled={isAddingInProgress || disabled}
        cacheUniq={cacheUniq}
        onCreateOption={onCreateOption}
      />
    );
  }
  return (
    <AsyncPaginate
      onChange={selected => onChange(selected)}
      isDisabled={disabled}
      {...selectProps}
    />
  );
};

AutoComplete.propTypes = propTypes;
AutoComplete.defaultProps = defaultProps;

export default AutoComplete;
