/* eslint-disable import/order */
import React, { useState } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import getProperty from 'lodash/get';
// constants
import { SUBMISSION_STATUS } from 'constants/submissionStatus';
import { STAFF_ROLE, SUPPLIER_ROLE, NOTE_CANDIDATE_COMMENT } from 'constants';
// helpers
import { isRejected } from 'helpers/Submission';
import { getSubmissions } from './helpers';
// components
import Button from 'components/atoms/Button';
import TextSection from 'components/atoms/TextSection';
import { WithdrawSubmission, EditRejectionReason } from 'components/organisms/SubmissionSettings';
import SubmittedBy from './SubmittedBy';

const propTypes = {
  submission: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
};

const Notes = ({ submission, role }) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const note = submission.getNote(NOTE_CANDIDATE_COMMENT);
  const status = submission.getStatus();
  const { submitter, reviewer } = getSubmissions(submission);

  const showEditReasonButton = () => {
    if (role === STAFF_ROLE && status.value === SUBMISSION_STATUS.NOT_SUBMITTED) {
      return true;
    }
    if (role === SUPPLIER_ROLE && status.value === SUBMISSION_STATUS.WITHDRAWN) {
      return true;
    }

    return false;
  };

  return (
    <div>
      {isRejected(status.value) && status.note && (
        <div className="m-b-20">
          <div className="verticalCenter">
            <TextSection title={t('disqualificationReason')}>{status.note.text}</TextSection>
            {showEditReasonButton() && (
              <div className="m-l-12">
                <Button tooltip={t('edit')} onClick={() => setModalOpen(true)} type="edit" />
              </div>
            )}
          </div>

          {status.value === SUBMISSION_STATUS.NOT_SUBMITTED && (
            <EditRejectionReason
              name={submission.getName()}
              isOpen={modalOpen}
              onClose={() => setModalOpen(false)}
              note={getProperty(status, 'note.text', '')}
              noteId={getProperty(status, 'note.id', '')}
            />
          )}

          {status.value === SUBMISSION_STATUS.WITHDRAWN && role === SUPPLIER_ROLE && (
            <WithdrawSubmission
              isOpen={modalOpen}
              id={submission.id}
              name={submission.getName()}
              status={submission.getStatus()}
              onClose={() => setModalOpen(false)}
              disqualificationNotes={status.note}
              isProposal={submission.isProposal()}
            />
          )}
        </div>
      )}

      {note && (
        <div className="m-b-20">
          <TextSection title={t('additionalCandidateInfo')}>{note.text}</TextSection>
        </div>
      )}

      <SubmittedBy reviewer={reviewer} submitter={submitter} />
    </div>
  );
};

Notes.propTypes = propTypes;

export default Notes;
