export const colors = {
  success: 'green',
  error: 'red',
  warning: 'yellow',
};

export const icons = {
  success: 'done',
  error: 'error',
  warning: 'warning',
};
