import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// components
import Icon from 'components/atoms/Icon';
// styles
import styles from './textSection.module.scss';

const propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  icon: PropTypes.string,
  classes: PropTypes.shape({
    root: PropTypes.string,
    title: PropTypes.string,
  }),
};

const defaultProps = {
  classes: {},
  icon: '',
};

const TextSection = ({ title, children, classes, icon }) => {
  return (
    <div className={classNames(styles.container, classes.root)}>
      {icon && (
        <div className="m-r-10">
          <Icon name={icon} size={20} />
        </div>
      )}
      <div className={styles.content}>
        <div className={classNames(styles.title, classes.title)}>{title}</div>
        {children}
      </div>
    </div>
  );
};

TextSection.propTypes = propTypes;
TextSection.defaultProps = defaultProps;

export default TextSection;
