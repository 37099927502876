import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
};

const defaultProps = {
  className: '',
};

const Head = ({ className, children }) => {
  return (
    <div
      className={classNames('responsiveTable_item responsiveTable_head', {
        [className]: className,
      })}
    >
      {children}
    </div>
  );
};

Head.propTypes = propTypes;
Head.defaultProps = defaultProps;

export default Head;
