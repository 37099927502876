/* eslint-disable import/order */
import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// hooks
import useAuth from 'hooks/useAuth';
// components
import TimezoneDate from 'components/atoms/TimezoneDate';
import MainInfoRecord from './MainInfoRecord';
// helpers
import { formatLocation } from 'helpers/Position';
import { getRelativeTime, getTimeYear } from 'helpers/RelativeTimeFormatter';
import { getExpectedCandidateStartDate, getPositionEndDate } from '../helpers';
// constants
import { SUPPLIER_ROLE } from 'constants';
import {
  MAIN_RECRUITING,
  MAIN_SELECTION,
  MAIN_ONBOARDING,
  MAIN_DELIVERY,
  MAIN_CANCELLED,
  MAIN_CLOSED,
} from 'constants/positionStatus';

const propTypes = {
  position: PropTypes.object.isRequired,
};

const MainInfo = ({ position }) => {
  const { t } = useTranslation();
  const { role, supplierId } = useAuth();

  const status = position.getMainStatus(role, supplierId);
  const candidates = position.getSubmissions(role, supplierId);
  const dueDate = role === SUPPLIER_ROLE ? position.supplierDueDate : position.dueDate;
  const location = formatLocation(position);

  const renderDate = () => {
    // Recruiting
    if (status.value === MAIN_RECRUITING) {
      const timezoneDate = <TimezoneDate date={dueDate} timezone={position.project.timezone} />;

      return (
        <React.Fragment>
          {t('due')} {getRelativeTime(dueDate)} - {timezoneDate}
        </React.Fragment>
      );
    }

    // Selection + Onboarding
    if ([MAIN_SELECTION, MAIN_ONBOARDING].includes(status.value)) {
      const expectedStartDate = getExpectedCandidateStartDate(position, candidates);
      const formattedDate = getTimeYear(expectedStartDate);
      const relativeDate = getRelativeTime(expectedStartDate);

      return `${t('expectedStart')} ${relativeDate} - ${formattedDate}`;
    }

    // Delivery
    if (status.value === MAIN_DELIVERY) {
      const expectedEndDate = getPositionEndDate(position, candidates);
      const formattedDate = getTimeYear(expectedEndDate);
      const relativeDate = getRelativeTime(expectedEndDate);

      return `${t('expectedEnd')} ${relativeDate} - ${formattedDate}`;
    }

    return '';
  };

  return (
    <React.Fragment>
      {![MAIN_CANCELLED, MAIN_CLOSED].includes(status.value) && (
        <MainInfoRecord icon="calendar">{renderDate()}</MainInfoRecord>
      )}
      {location && (
        <div className="m-t-8">
          <MainInfoRecord
            icon="location"
            tooltip={!position.isOnsite() ? position.delivery.description : ''}
          >
            {location}
          </MainInfoRecord>
        </div>
      )}
      <div className="m-t-8">
        <MainInfoRecord icon="verified">
          {position.clearance || t('noClearanceRequired')}
        </MainInfoRecord>
      </div>
      {[MAIN_ONBOARDING, MAIN_DELIVERY].includes(status.value) && (
        <div className="m-t-8">
          <MainInfoRecord icon="time">
            {t('levelOfEffortLabel')}, {position.duration.hours} {t('hours')}
          </MainInfoRecord>
        </div>
      )}
    </React.Fragment>
  );
};

MainInfo.propTypes = propTypes;

export default MainInfo;
