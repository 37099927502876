import React from 'react';
// Libraries
import PropTypes from 'prop-types';

const propTypes = {
  isRowEven: PropTypes.func,
  increment: PropTypes.number,
  content: PropTypes.any,
  title: PropTypes.string,
};

const defaultProps = {
  isRowEven: () => {},
  increment: null,
  content: null,
  title: null,
};

const TechnicalMeasuresRow = ({ title, content, increment, isRowEven }) => {
  return (
    <div className="technicalMeasuresTable_row">
      <div className="technicalMeasuresTable_column_first">
        {increment} {title}
      </div>
      <div className="technicalMeasuresTable_row_content">
        {content.map((description, descIndex) => {
          const isEven = isRowEven(descIndex);
          return (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={descIndex}
              className={`display-flex-row ${isEven ? 'technicalMeasuresTable_row_gray' : ''}`}
            >
              <div className="technicalMeasuresTable_column_second">
                {increment}.{descIndex + 1}
              </div>
              <div className="technicalMeasuresTable_column_third">{description}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

TechnicalMeasuresRow.propTypes = propTypes;
TechnicalMeasuresRow.defaultProps = defaultProps;

export default TechnicalMeasuresRow;
