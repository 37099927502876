import React from 'react';
import ContentLoader from 'react-content-loader';

const ReportLoader = () => (
  <ContentLoader
    height={670}
    width={1600}
    speed={1}
    primaryColor="#f1f1f1"
    secondaryColor="#e6e6e6"
  >
    <rect x="0" y="0" rx="3" ry="3" width="1600" height="12" />
    <rect x="0" y="30" rx="3" ry="3" width="1600" height="12" />
    <rect x="0" y="60" rx="3" ry="3" width="1600" height="12" />
    <rect x="0" y="190" rx="3" ry="3" width="700" height="12" />
    <rect x="0" y="230" rx="3" ry="3" width="700" height="12" />
    <circle cx="1350" cy="180" r="30" />
    <circle cx="1350" cy="270" r="30" />
    <circle cx="1518" cy="180" r="82" />
    <rect x="1474" y="240" rx="0" ry="0" width="90" height="90" />

    <rect x="0" y="370" rx="3" ry="3" width="220" height="12" />
    <rect x="270" y="370" rx="3" ry="3" width="220" height="12" />
    <rect x="540" y="370" rx="3" ry="3" width="220" height="12" />
    <rect x="810" y="370" rx="3" ry="3" width="220" height="12" />
    <rect x="1070" y="370" rx="3" ry="3" width="220" height="12" />

    <rect x="0" y="440" rx="3" ry="3" width="450" height="15" />
    <rect x="0" y="480" rx="3" ry="3" width="1600" height="12" />
    <rect x="0" y="510" rx="3" ry="3" width="1600" height="12" />
    <rect x="0" y="540" rx="3" ry="3" width="1600" height="12" />

    <rect x="0" y="580" rx="3" ry="3" width="450" height="15" />
    <rect x="0" y="620" rx="3" ry="3" width="1600" height="12" />
    <rect x="0" y="650" rx="3" ry="3" width="1600" height="12" />
  </ContentLoader>
);

export default ReportLoader;
