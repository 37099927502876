import React from 'react';
import PropTypes from 'prop-types';
// style
import './section.scss';

const propTypes = {
  content: PropTypes.any,
  className: PropTypes.string,
  titleClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  title: PropTypes.string,
};

const defaultProps = {
  content: null,
  className: '',
  titleClassName: '',
  contentClassName: '',
  title: null,
};

const Section = ({ content, className, contentClassName, title, titleClassName }) => {
  return (
    <div className={`section ${className}`}>
      {title && <div className={`section_title ${titleClassName}`}>{title}</div>}
      <div className={`section_content ${contentClassName}`}>{content}</div>
    </div>
  );
};

Section.propTypes = propTypes;
Section.defaultProps = defaultProps;

export default Section;
