export const SUBMISSION_STATUS_TYPE = {
  BASIC: 'BASIC_STATUS',
  ONBOARDING_OFFER: 'HIRING_JOB_OFFER_STATUS',
  ONBOARDING_START_DATE: 'HIRING_START_DATE_STATUS',
  ONBOARDING_FORMS: 'HIRING_ONBOARDING_STATUS',
  ONBOARDING_PO: 'HIRING_PO_STATUS',
};

export const SUBMISSION_STATUS = {
  NEW: 'NEW',
  SUBMITTED: 'SUBMITTED_TO_CLIENT',
  NOT_SUBMITTED: 'NOT_SUBMITTED_TO_CLIENT',
  NOT_SELECTED: 'NOT_SELECTED_BY_CLIENT',
  INTERVIEW: 'INTERVIEW',
  OFFER: 'OFFER',
  ONBOARDING: 'ONBOARDING',
  ONBOARDING_CANCELLED: 'ONBOARDING_CANCELLED',
  OFF_BOARDING: 'OFF_BOARDING',
  OFF_BOARDED: 'OFF_BOARDED',
  WITHDRAWN: 'WITHDRAWN',
  DELIVERY: 'DELIVERY',
};
