import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import moment from 'moment';
// components
import DatePicker from 'components/atoms/DatePickerCustom';
import TimezoneDate from 'components/atoms/TimezoneDate';
import Section from 'components/molecules/FieldSection';
// styles
import styles from './publishPosition.module.scss';

const propTypes = {
  positionDueDate: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
  error: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

const PartnerDueDate = ({ positionDueDate, timezone, value, onChange, error, readOnly }) => {
  const { t } = useTranslation();

  const renderDescription = () => {
    return (
      <React.Fragment>
        {t('setPartnerDueDateDescription')}
        <div className={styles.positionDueDateInfo}>
          <Trans i18nKey="setPartnerDueDateValue">
            Due date is{' '}
            <span className={styles.positionDueDate}>
              <TimezoneDate date={positionDueDate} timezone={timezone} tooltipPlacement="right" />
            </span>
          </Trans>
        </div>
      </React.Fragment>
    );
  };

  return (
    <Section title={t('setPartnerDueDate')} description={renderDescription()} error={error}>
      <div className={styles.positionDueDateInput}>
        <DatePicker
          id="partnerDueDate"
          enableTime
          value={value}
          onChange={date => onChange(date.toISOString())}
          minDate={moment()}
          minDateMessage={t('invalidSupplierDueDatePast')}
          maxDate={positionDueDate}
          maxDateMessage={t('invalidSupplierDueDateExceed')}
          disabled={readOnly}
        />
      </div>
    </Section>
  );
};

PartnerDueDate.propTypes = propTypes;

export default PartnerDueDate;
