/* eslint-disable import/order */
import React, { useState, useEffect, useContext } from 'react';
// libraries
import { Prompt } from 'react-router';
import PropTypes from 'prop-types';
import getProperty from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import { useTranslation } from 'react-i18next';
// services
import logger from 'services/Logger';
// helpers
import { getRevisionTypeText } from 'helpers/NameMapping';
import {
  fetchRevision,
  getRevisionType,
  hasError,
  validate,
  submitRevision,
  saveProgress,
} from './helpers';
// contexts
import ToastContext from 'context/ToastContext';
// hooks
import useAuth from 'hooks/useAuth';
import useAnalyseProcessText from 'hooks/useAnalyseProcessText';
// components
import ModalStepper from 'components/molecules/ModalStepper';
import RespondRevisionForm from './RespondRevisionForm';
import SaveProgressModal from './SaveProgressModal';
// styles
import './respondRevisionModal.scss';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  revisionId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

const RespondRevisionModal = ({ isOpen, revisionId, onClose, name }) => {
  const { addToast } = useContext(ToastContext);
  const { t } = useTranslation();
  const { id, supplierId } = useAuth();
  const {
    text: uploadingCvText,
    loading: uploadingCv,
    start: startTimerForCvUploading,
    clean: cleanTimerForCvUploading,
  } = useAnalyseProcessText();

  const [activeTab, setActiveTab] = useState('');
  const [loading, setLoading] = useState(true);
  const [tabs, setTabs] = useState([]);
  const [revision, setRevision] = useState();
  const [formData, setFormData] = useState({});
  const [preventNavigation, setPreventNavigation] = useState(false);
  const [savingProgress, setSavingProgress] = useState(false);
  const [promptModalOpen, setPromptModalOpen] = useState(false);

  const getTabs = revisionData => {
    const types = getProperty(revisionData, 'types.items', []);
    return types.map(({ type }) => ({
      id: type,
      label: getRevisionTypeText(type),
    }));
  };

  const fetchRevisions = async () => {
    setLoading(true);
    try {
      const revisionData = await fetchRevision(revisionId);
      const revisionTypesTabs = getTabs(revisionData);
      const previousFormData = getProperty(revisionData, 'workInProgress');

      if (previousFormData) setFormData(previousFormData);

      setRevision(revisionData);
      setTabs(revisionTypesTabs);
      setLoading(false);
    } catch (error) {
      logger.exception(error);
      addToast.error(t('errorPlaceholderText'));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) fetchRevisions();
  }, [revisionId, isOpen]);

  const cleanState = () => {
    setPreventNavigation(false);
    setPromptModalOpen(false);
    setFormData({});
  };

  const handleClose = () => {
    if (preventNavigation) {
      setPromptModalOpen(true);

      return;
    }
    cleanState();
    onClose();
  };

  const onNavigationPrompt = () => {
    if (preventNavigation) {
      setPromptModalOpen(true);
      return false;
    }
    return true;
  };

  const updateTabs = validatedFormData => {
    setTabs(
      tabs.map(tab => {
        const error = getProperty(validatedFormData, [tab.id, 'error']);
        const requiredSelectionError = getProperty(validatedFormData, [
          tab.id,
          'requiredSelection',
        ]);

        return {
          ...tab,
          error: error || requiredSelectionError,
        };
      })
    );
  };

  const validateForm = (page, selectedTab) => {
    const validatedFormData = validate(formData, tabs, page, revision);

    updateTabs(validatedFormData, selectedTab);
    setFormData(validatedFormData);

    return validatedFormData;
  };

  const handleChange = (newFormData, type) => {
    const formDataCopy = cloneDeep(newFormData);
    const validated = validate(formDataCopy, tabs, type, revision);

    updateTabs(validated);
    setFormData(validated);
    setPreventNavigation(true);
  };

  const handleSubmit = async () => {
    const validatedFormData = validateForm();

    if (hasError(validatedFormData)) {
      addToast.error(t('revisionResponseIncompleteForm'));
      return;
    }

    try {
      setLoading(true);
      await submitRevision(formData, revision, id, supplierId);

      setLoading(false);
      cleanState();
      onClose();
      addToast.success(t('revisionSubmitted'));
    } catch (error) {
      setPreventNavigation(false);
      addToast.error(t('errorPlaceholderText'));
      setLoading(false);
    }
  };

  const onSaveProgress = async () => {
    setSavingProgress(true);
    try {
      await saveProgress(formData, revision);
      cleanState();
      onClose();
      setPromptModalOpen(false);
      addToast.success(t('revisionProgressSaved'));
    } catch (error) {
      logger.exception(error);
      addToast.error(t('errorPlaceholderText'));
    } finally {
      setSavingProgress(false);
    }
  };

  const onDiscard = () => {
    cleanState();
    onClose();
  };

  const content = tabs.reduce((list, current) => {
    const revisionType = getRevisionType(revision, current.id);
    return {
      ...list,
      [current.id]: (
        <RespondRevisionForm
          revisionType={revisionType}
          revision={revision}
          formData={formData}
          setFormData={handleChange}
          onActionChange={setFormData}
          setLoading={setLoading}
          onCvUploadStarted={startTimerForCvUploading}
          onCvUploadFinished={cleanTimerForCvUploading}
        />
      ),
    };
  }, {});

  if (!isOpen) return null;

  if (promptModalOpen) {
    return (
      <SaveProgressModal
        isOpen={promptModalOpen}
        onConfirm={onSaveProgress}
        onClose={onDiscard}
        loading={savingProgress}
      />
    );
  }

  return (
    <React.Fragment>
      <ModalStepper
        isOpen={isOpen}
        title={t('submitRevisionHeader', { name })}
        tabs={tabs}
        loading={loading}
        loadingText={uploadingCv ? uploadingCvText : ''}
        content={content}
        onClose={handleClose}
        onSubmit={handleSubmit}
        onSwitch={setActiveTab}
        activeTab={activeTab}
        submitDisabled={false}
      />
      <Prompt when={preventNavigation} message={onNavigationPrompt} />
    </React.Fragment>
  );
};

RespondRevisionModal.propTypes = propTypes;

export default RespondRevisionModal;
