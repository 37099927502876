// libraries
import i18n from 'i18next';
// constants
import { STAFF_ROLE, SUPPLIER_ROLE, MANAGER_ROLE } from 'constants';
import { ONBOARDING_TAB } from 'constants/onboarding';
import { STEP } from './constants';

const TABS_OPTION = {
  [ONBOARDING_TAB.JOB_OFFER]: {
    id: ONBOARDING_TAB.JOB_OFFER,
    label: i18n.t('jobOffer'),
    icon: 'assignment',
    steps: [
      {
        id: STEP.JOB_OFFER_CONFIRM,
        label: i18n.t('confirmJobOffer'),
        role: SUPPLIER_ROLE,
      },
      {
        id: STEP.JOB_OFFER_APPROVE,
        label: i18n.t('approveJobOffer'),
        role: MANAGER_ROLE,
      },
    ],
  },
  [ONBOARDING_TAB.START_DATE]: {
    id: ONBOARDING_TAB.START_DATE,
    label: i18n.t('offer'),
    icon: 'calendar',
    steps: [
      {
        id: STEP.START_DATE_PROPOSE,
        label: i18n.t('proposeStartDate'),
        role: SUPPLIER_ROLE,
      },
      {
        id: STEP.START_DATE_SUBMIT,
        label: i18n.t('submitForConfirmation'),
        role: STAFF_ROLE,
      },
      {
        id: STEP.START_DATE_APPROVE,
        label: i18n.t('confirmStartDate'),
        role: MANAGER_ROLE,
      },
    ],
  },
  [ONBOARDING_TAB.FORMS]: {
    id: ONBOARDING_TAB.FORMS,
    label: i18n.t('onboardingForms'),
    icon: 'file',
    steps: [
      {
        id: STEP.ONBOARDING_SUBMIT,
        label: i18n.t('submitOnboardingForms'),
        role: SUPPLIER_ROLE,
      },
      {
        id: STEP.ONBOARDING_CONFIRM,
        label: i18n.t('confirmOnboardingForms'),
        role: STAFF_ROLE,
      },
    ],
  },
  [ONBOARDING_TAB.PO]: {
    id: ONBOARDING_TAB.PO,
    icon: 'purchaseOrder',
    label: i18n.t('purchaseOrder'),
    steps: [
      {
        id: STEP.PO_RELEASE,
        label: i18n.t('createPO'),
        role: MANAGER_ROLE,
      },
      {
        id: STEP.PO_CONFIRM,
        label: i18n.t('sendPOConfirmation'),
        role: STAFF_ROLE,
      },
    ],
  },
};

export default TABS_OPTION;
