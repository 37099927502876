import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// styles
import styles from './positionMainInfo.module.scss';

const propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const Section = ({ children, className }) => {
  return <div className={classNames(styles.section, className)}>{children}</div>;
};

Section.propTypes = propTypes;
Section.defaultProps = defaultProps;

export default Section;
