import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// hooks
import useToggle from 'hooks/useToggle';
// components
import Icon from 'components/atoms/Icon';
import TruncatedText from 'components/atoms/TruncatedText';
import ClickableElement from 'components/atoms/ClickableElement';
import OutsideDetector from 'components/atoms/OutsideDetector';
import RadioList from 'components/molecules/RadioList';
// helpers
import { stopPropagation } from 'helpers/HtmlDOM';
// styles
import styles from './radioListInput.module.scss';

const propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    })
  ).isRequired,
  selected: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  noRemoveButton: PropTypes.bool,
};

const defaultProps = {
  icon: '',
  label: '',
  selected: '',
  placeholder: '',
  className: '',
  noRemoveButton: false,
};

const RadioListInput = ({
  placeholder,
  label,
  icon,
  options,
  selected,
  onChange,
  className,
  noRemoveButton,
}) => {
  const [collapsed, toggle] = useToggle(true);
  const selectedOption = options.find(option => option.value === selected);

  const handleClear = () => {
    onChange(null);
  };

  return (
    <OutsideDetector onClick={() => (!collapsed ? toggle() : null)}>
      <ClickableElement onClick={toggle} className={classNames(styles.container, className)}>
        <div className={styles.input}>
          {icon && (
            <div className={classNames(styles.icon, 'm-r-10')}>
              <Icon name={icon} size={16} />
            </div>
          )}
          {selectedOption ? (
            <React.Fragment>
              {label && <div className={styles.label}>{label}</div>}
              {selectedOption ? <TruncatedText>{selectedOption.label}</TruncatedText> : null}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {placeholder && <div className={styles.placeholder}>{placeholder}</div>}
            </React.Fragment>
          )}
        </div>
        {selected && !noRemoveButton && (
          <ClickableElement
            className={classNames(styles.icon, 'm-r-5')}
            onClick={stopPropagation(handleClear)}
          >
            <Icon name="close" size={16} />
          </ClickableElement>
        )}
        <div className={styles.icon}>
          <Icon name={collapsed ? 'expandMore' : 'less'} size={23} />
        </div>
        {!collapsed && (
          <div className={styles.radioList}>
            <RadioList selected={selected} options={options} onChange={onChange} />
          </div>
        )}
      </ClickableElement>
    </OutsideDetector>
  );
};

RadioListInput.propTypes = propTypes;
RadioListInput.defaultProps = defaultProps;

export default RadioListInput;
