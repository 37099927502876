import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// components
import Button from 'components/atoms/Button';
// style
import style from './PositionCallToActions.module.scss';

const propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  tooltip: PropTypes.string,
};

const defaultProps = {
  tooltip: '',
};

const Action = ({ tooltip, label, onClick }) => {
  return (
    <Button
      className={classNames(style.actionButton, 'fs-unmask')}
      label={label}
      onClick={e => {
        e.preventDefault();
        onClick();
      }}
      tooltip={tooltip}
    />
  );
};

Action.propTypes = propTypes;
Action.defaultProps = defaultProps;

export default Action;
