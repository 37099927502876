/* eslint-disable import/order */
import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// hooks
import useAuth from 'hooks/useAuth';
// queries
import { GET_LOCATION } from 'queries/SearchQueries';
// helpers
import { parseLocationData } from 'helpers/FormDataParser';
import { parseFilterOptions } from 'helpers/Filter';
import { getAllowedFilterOptions, getSelectedFilterOptions } from './helper';
// components
import SideFilter from 'components/molecules/SideFilter';
import ToggleChip from 'components/molecules/ToggleChip';
import CheckboxList from 'components/molecules/CheckboxList';
import AutoCompleteSearch from 'components/molecules/AutoCompleteSearch';
import DropdownCheckbox from 'components/molecules/DropdownCheckbox';
import ClearancesFilter from 'components/organisms/ClearancesFilter';
// constants
import { POSITION_FILTERS, DELIVERY_OPTION_FILTERS } from 'constants/filters';
import { STAFF_ROLE } from 'constants';
import {
  DUE_DATE_FILTERS,
  POSITION_STATUS_FILTERS_MAP,
  SUBMISSIONS_FILTERS_MAP,
  PUBLISH_STATUS_FILTERS,
  CONTRACT_TYPE_FILTERS,
  DELIVERY_OPTION_FILTERS as DELIVERY_OPTION_FILTER_OPTIONS,
} from './data/PositionFilter';
// styles
import styles from './positionPage.module.scss';

const propTypes = {
  loading: PropTypes.bool.isRequired,
  clearances: PropTypes.arrayOf(PropTypes.string).isRequired,
  filter: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeParams: PropTypes.func.isRequired,
};

const PositionFilters = ({
  loading,
  clearances,
  filter,
  onClose,
  onClear,
  onChange,
  onChangeParams,
}) => {
  const { t } = useTranslation();
  const { role, projects } = useAuth();

  const projectList = projects.map(({ id, name }) => ({ value: id, label: name }));
  const selectedProject = filter[POSITION_FILTERS.PROJECT];
  const selectedLocation = filter[POSITION_FILTERS.LOCATION];
  const selectedPublishStatus = filter[POSITION_FILTERS.PUBLISH_STATUS];
  const selectedContractType = filter[POSITION_FILTERS.CONTRACT_TYPE];
  const selectedDeliveryOption = filter[POSITION_FILTERS.DELIVERY_OPTION];
  // concat is used for converting/ensuring value to array
  const selectedDueDate = [].concat(filter[POSITION_FILTERS.DUE_DATE] || []);
  const selectedStatuses = [].concat(filter[POSITION_FILTERS.STATUS] || []);
  const selectedSubmissions = [].concat(filter[POSITION_FILTERS.SUBMISSION] || []);
  const selectedClearances = [].concat(filter[POSITION_FILTERS.CLEARANCE] || []);
  // Enable/disable filter options
  const selectedOptions = getSelectedFilterOptions(filter);
  const allowedOptions = getAllowedFilterOptions(filter, role);
  const dueDateOptions = parseFilterOptions(DUE_DATE_FILTERS, allowedOptions, selectedOptions);
  const publishOptions = parseFilterOptions(
    PUBLISH_STATUS_FILTERS,
    allowedOptions,
    selectedOptions
  );
  const statusOptions = parseFilterOptions(
    POSITION_STATUS_FILTERS_MAP[role],
    allowedOptions,
    selectedOptions
  );
  const submissionOptions = parseFilterOptions(
    SUBMISSIONS_FILTERS_MAP[role],
    allowedOptions,
    selectedOptions
  );

  const handleChipClick = (type, value) => {
    if (filter[type] === value) {
      onChange(type, null);
    } else {
      onChange(type, value);
    }
  };

  const handleDeliveryOptionClick = value => {
    const newParams = { ...filter };

    if (filter[POSITION_FILTERS.LOCATION] && value === DELIVERY_OPTION_FILTERS.OFFSITE) {
      delete newParams[POSITION_FILTERS.LOCATION];
    }

    if (selectedDeliveryOption === value) {
      delete newParams[POSITION_FILTERS.DELIVERY_OPTION];
    } else {
      newParams[POSITION_FILTERS.DELIVERY_OPTION] = value;
    }

    onChangeParams(newParams);
  };

  const handleSelect = type => item => {
    if (!item) {
      onChange(type, null);
    } else {
      onChange(type, item.label);
    }
  };

  const handleChecklistSelection = filterType => selectedList => {
    onChange(filterType, selectedList);
  };

  const renderChipLabel = (label, isSelected) => {
    if (isSelected) {
      return (
        <React.Fragment>
          <span className="m-r-5">&#10687;</span>
          {label}
        </React.Fragment>
      );
    }

    return label;
  };

  return (
    <SideFilter onClose={onClose} onClear={onClear} className="fs-unmask">
      {/* Filter by due date */}
      <SideFilter.Group title={t('dueDate')}>
        <CheckboxList
          options={dueDateOptions}
          selected={selectedDueDate}
          onChange={handleChecklistSelection(POSITION_FILTERS.DUE_DATE)}
        />
      </SideFilter.Group>

      {/* Filter by position status */}
      <SideFilter.Group title={t('status')}>
        <CheckboxList
          options={statusOptions}
          selected={selectedStatuses}
          onChange={handleChecklistSelection(POSITION_FILTERS.STATUS)}
        />
      </SideFilter.Group>

      {/* Filter by candidate submissions */}
      <SideFilter.Group title={t('submissions')}>
        <CheckboxList
          options={submissionOptions}
          selected={selectedSubmissions}
          onChange={handleChecklistSelection(POSITION_FILTERS.SUBMISSION)}
        />
      </SideFilter.Group>

      {/* Filter by clearances */}
      <ClearancesFilter
        loading={loading}
        clearances={clearances}
        selected={selectedClearances}
        onChange={handleChecklistSelection(POSITION_FILTERS.CLEARANCE)}
      />

      {/* Filter by contract type */}
      <SideFilter.Group title={t('contractTypeLabel')}>
        <div className={styles.toggleChipContainer}>
          {CONTRACT_TYPE_FILTERS.map((option, index) => {
            const isSelected = selectedContractType === option.value;

            return (
              <ToggleChip
                key={option.value}
                selected={isSelected}
                onChange={() => handleChipClick(POSITION_FILTERS.CONTRACT_TYPE, option.value)}
                classes={{
                  root: classNames({ 'm-l-5': index }),
                  chip: styles.toggleChip,
                }}
              >
                {renderChipLabel(option.label, isSelected)}
              </ToggleChip>
            );
          })}
        </div>
      </SideFilter.Group>

      {/* Filter by delivery option */}
      <SideFilter.Group title={t('deliveryOptionLabel')}>
        <div className={styles.toggleChipContainer}>
          {DELIVERY_OPTION_FILTER_OPTIONS.map((option, index) => {
            const isSelected = selectedDeliveryOption === option.value;

            return (
              <ToggleChip
                key={option.value}
                selected={isSelected}
                onChange={() => handleDeliveryOptionClick(option.value)}
                classes={{
                  root: classNames({ 'm-l-5': index }),
                  chip: styles.toggleChip,
                }}
              >
                {renderChipLabel(option.label, isSelected)}
              </ToggleChip>
            );
          })}
        </div>
      </SideFilter.Group>

      {/* Filter by publish status - MM role */}
      {role === STAFF_ROLE && (
        <SideFilter.Group title={t('publishStatus')}>
          <div className={styles.toggleChipContainer}>
            {publishOptions.map((status, index) => {
              const isSelected = selectedPublishStatus === status.value;

              return (
                <ToggleChip
                  key={status.value}
                  disabled={status.disabled}
                  selected={isSelected}
                  onChange={() => handleChipClick(POSITION_FILTERS.PUBLISH_STATUS, status.value)}
                  classes={{
                    root: classNames({ 'm-l-5': index }),
                    chip: styles.toggleChip,
                  }}
                >
                  {renderChipLabel(status.label, isSelected)}
                </ToggleChip>
              );
            })}
          </div>
        </SideFilter.Group>
      )}

      {/* Filter by project */}
      {projectList.length > 1 && (
        <SideFilter.Group title={t('project')}>
          <DropdownCheckbox
            name={POSITION_FILTERS.PROJECT}
            list={projectList}
            selected={selectedProject}
            onChange={handleSelect(POSITION_FILTERS.PROJECT)}
            label={t('selectProject')}
            checkedLabel={selectedProject}
            showCloseIcon
          />
        </SideFilter.Group>
      )}

      {/* Filter by location */}
      {selectedDeliveryOption !== DELIVERY_OPTION_FILTERS.OFFSITE && (
        <SideFilter.Group title={t('location')}>
          <AutoCompleteSearch
            id="location"
            customQuery={GET_LOCATION}
            customDataParser={data => parseLocationData(data, true)}
            type="locationsList"
            onChange={handleSelect(POSITION_FILTERS.LOCATION)}
            limitSize={5}
            defaultValue={selectedLocation ? { label: selectedLocation } : null}
          />
        </SideFilter.Group>
      )}
    </SideFilter>
  );
};

PositionFilters.propTypes = propTypes;

export default PositionFilters;
