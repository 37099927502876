/* eslint-disable import/order */
import React from 'react';
import PropTypes from 'prop-types';
import getProperty from 'lodash/get';
import setProperty from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
// hooks
import { useTranslation } from 'react-i18next';
// helpers
import { getRevisionTypeText } from 'helpers/NameMapping';
import { getActionButtonTitle } from './helpers';
// components
import Header from './Header';
import RevisionForm from './RevisionForm';
import Rates from './Rates';
import Button from 'components/atoms/Button';
import Field from 'components/molecules/Field';
import Textarea from 'components/atoms/Textarea';
import Banner, { BANNER_TYPE } from 'components/molecules/Banner';
// constants
import { REVISION_TYPES } from 'constants';
import { ACTIONS } from './constants';

const propTypes = {
  revisionType: PropTypes.object.isRequired,
  revision: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  onActionChange: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  onCvUploadStarted: PropTypes.func.isRequired,
  onCvUploadFinished: PropTypes.func.isRequired,
};

const RespondRevisionForm = ({
  revision,
  revisionType,
  formData,
  setFormData,
  setLoading,
  onCvUploadStarted,
  onCvUploadFinished,
  onActionChange,
}) => {
  const { t } = useTranslation();
  const type = getProperty(revisionType, 'type', '');
  const action = getProperty(formData, [type, 'action']);
  const error = getProperty(formData, [type, 'error']);
  const ratesInputValue = getProperty(formData, [type, 'value'], {});
  const actionNotSelected = getProperty(formData, [type, 'requiredSelection']);
  const header = getRevisionTypeText(type);
  const author = getProperty(revisionType, 'reason.author.person.fullName', '');
  const note = getProperty(revisionType, 'reason.note', '');
  const createdAt = getProperty(revisionType, 'reason.createdAt', '');
  const rateAcceptNote = getProperty(formData, [type, 'acceptNote', 'value'], '');
  const showRatesInput = type === REVISION_TYPES.RATES && action === ACTIONS.ACCEPT;

  const setAction = actionType => {
    const newFormData = cloneDeep(formData);
    setProperty(newFormData, type, {
      action: actionType,
      error: false,
      requiredSelection: false,
    });
    onActionChange(newFormData);
  };

  const handleRateInputChange = value => {
    const newFormData = cloneDeep(formData);

    setProperty(newFormData, [type, 'value'], value);
    setFormData(newFormData, type);
  };

  const handleNoteChange = value => {
    const newFormData = cloneDeep(formData);

    setProperty(newFormData, [type, 'acceptNote', 'value'], value);
    setFormData(newFormData, type);
  };

  return (
    <div className="revision-form">
      <Header
        header={`${header} ${t('revision')}`}
        subHeader={
          actionNotSelected && (
            <div className="revision-form_error">
              <Banner type={BANNER_TYPE.ERROR}>
                {t('selectRevisionAction', { type: t(type.toLowerCase()) })}
              </Banner>
            </div>
          )
        }
        author={author}
        note={note}
        className={error ? 'error-tab' : ''}
        createdAt={createdAt}
      />
      {type === REVISION_TYPES.RATES && (
        <React.Fragment>
          <Rates
            revision={revision}
            showRatesInput={showRatesInput}
            onChange={handleRateInputChange}
            ratesValue={ratesInputValue}
            error={error}
          />
          {showRatesInput && (
            <Field
              label={t('additionalNote')}
              className="m-t-20 revision-form_reject-revision-field accept-form"
              content={
                <Textarea
                  id="acceptNote"
                  className="revision-form_note"
                  onChange={handleNoteChange}
                  value={rateAcceptNote}
                />
              }
            />
          )}
        </React.Fragment>
      )}
      {!action && (
        <React.Fragment>
          <div className="revision-form_buttons">
            <Button
              className="revision-form_button"
              label={getActionButtonTitle(type, ACTIONS.REJECT)}
              secondary
              onClick={() => {
                setAction(ACTIONS.REJECT);
              }}
            />
            <span className="m-l-12">
              <Button
                className="revision-form_button"
                label={getActionButtonTitle(type, ACTIONS.ACCEPT)}
                onClick={() => {
                  setAction(ACTIONS.ACCEPT);
                }}
              />
            </span>
          </div>
          {type === REVISION_TYPES.CV && (
            <div className="revision-form_cv-notice">{t('gdprCompliance')}</div>
          )}
        </React.Fragment>
      )}

      <RevisionForm
        formData={formData}
        type={type}
        onChange={setFormData}
        revision={revision}
        setLoading={setLoading}
        onCvUploadStarted={onCvUploadStarted}
        onCvUploadFinished={onCvUploadFinished}
        onActionChange={setAction}
      />
    </div>
  );
};

RespondRevisionForm.propTypes = propTypes;

export default RespondRevisionForm;
