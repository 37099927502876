import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const propTypes = {
  items: PropTypes.array.isRequired,
  lastItemSeparatedWithComma: PropTypes.bool,
};

const defaultProps = {
  lastItemSeparatedWithComma: false,
};

const CommaSeparatedList = ({ items, lastItemSeparatedWithComma }) => {
  const { t } = useTranslation();

  const lastItem = items.pop();
  const list = items.reduce((prev, item) => {
    return !prev.length ? [item] : [...prev, ', ', item];
  }, []);

  if (list.length > 0) {
    list.push(lastItemSeparatedWithComma ? ', ' : ` ${t('and')} `);
    list.push(lastItem);
  } else {
    list.push(lastItem);
  }

  return (
    <React.Fragment>
      {list.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={index}>{item}</React.Fragment>
      ))}
    </React.Fragment>
  );
};

CommaSeparatedList.propTypes = propTypes;
CommaSeparatedList.defaultProps = defaultProps;

export default CommaSeparatedList;
