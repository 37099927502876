import React from 'react';
// Libraries
import PropTypes from 'prop-types';
// helpers
import { getPassedRelativeTime } from 'helpers/RelativeTimeFormatter';
// components
import Chip from 'components/atoms/Chip';
import Rates from 'components/molecules/Rates';
import RevisionNote from './RevisionNote';

const propTypes = {
  className: PropTypes.string,
  versions: PropTypes.array,
};

const defaultProps = {
  className: '',
  versions: [],
};

const RateHistory = ({ className, versions }) => {
  return (
    <div className={`${className} historyModal`}>
      {versions.map((file, index) => {
        const { version, rates, revision, name, date } = file;

        return (
          <div
            key={file.id}
            className={`historyModal_item ${
              versions.length - 1 <= index ? 'border-bottom-none' : ''
            }`}
          >
            <Chip label={`V${version}`} color="blue" />
            <div className="historyModal_content">
              {rates && <Rates rates={rates} className="flex1" />}
              <div className={`historyModal_item_info p-t-10 p-0 ${revision ? 'p-b-5' : ''}`}>
                {name && <div className="historyModal_item_info_name">{name}</div>}
                <div className="historyModal_item_info_date">{getPassedRelativeTime(date)}</div>
              </div>
              {revision && (
                <RevisionNote
                  note={revision.note}
                  requestor={revision.requestor}
                  date={getPassedRelativeTime(revision.date)}
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

RateHistory.propTypes = propTypes;
RateHistory.defaultProps = defaultProps;

export default RateHistory;
