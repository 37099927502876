import React, { useState } from 'react';
// libraries
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
// components
import Button from 'components/atoms/Button';
import LinkButton from 'components/atoms/LinkButton';
import Modal from 'components/molecules/Modal';
import CookieBanner from './CookieBanner';
import CookieType from './CookieType';
import TrackingInfo from './TrackingInfo';
// hooks
import useCookieSettings from './useCookieSettings';
// constants
import { COOKIE_TYPE, TRACKING_INFO_LIST } from './constants';
// styles
import styles from './cookieSettings.module.scss';

const CookieSettings = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    cookieSettings,
    cookiesAccepted,
    hasNewTermsOfUseVersion,
    hasNewPrivacyPolicyVersion,
    hasNewCookiePolicyVersion,
    isCookieSettingsOpen,
    showCookieSettings,
    hideCookieSettings,
    acceptNewPolicies,
    acceptCookieSettings,
  } = useCookieSettings();

  const [settings, setSettings] = useState({
    [COOKIE_TYPE.ESSENTIALS]: true,
    ...cookieSettings,
  });

  const allowedTrackedInfoList = TRACKING_INFO_LIST.filter(({ type }) => settings[type]);
  const notAllowedTrackedInfoList = TRACKING_INFO_LIST.filter(({ type }) => !settings[type]);

  const handleChange = group => checked => {
    setSettings({
      ...settings,
      [group]: checked,
    });
  };

  const handlePrivacyClick = () => {
    if (isCookieSettingsOpen) {
      hideCookieSettings();
    }

    history.push('/legal/cookies');
  };

  const handleSaveCookies = () => {
    acceptNewPolicies();
    acceptCookieSettings(settings);
    hideCookieSettings();
  };

  return (
    <React.Fragment>
      {(!cookiesAccepted ||
        hasNewCookiePolicyVersion ||
        hasNewTermsOfUseVersion ||
        hasNewPrivacyPolicyVersion) && <CookieBanner onSettingsClick={showCookieSettings} />}
      <Modal isOpen={isCookieSettingsOpen} onClose={hideCookieSettings} size="xl">
        <div className={styles.container}>
          <div className={classNames(styles.panel, styles.leftPanel)}>
            <div className={styles.title}>{t('cookieSettings')}</div>
            <CookieType
              className="m-b-30"
              title={t('essentials')}
              description={t('essentialCookiesDescription')}
              checked
              disabled
            />
            <CookieType
              className="m-b-30"
              title={t('functionality')}
              description={t('functionalityCookiesDescription')}
              checked={settings[COOKIE_TYPE.FUNCTIONALITY]}
              onChange={handleChange(COOKIE_TYPE.FUNCTIONALITY)}
            />
            {/* <CookieType
              title={t('analytics')}
              description={t('analyticsCookiesDescription')}
              checked={settings[COOKIE_TYPE.ANALYTICS]}
              onChange={handleChange(COOKIE_TYPE.ANALYTICS)}
            /> */}
          </div>
          <div className={classNames(styles.panel, styles.rightPanel)}>
            <div className="m-b-15">
              <div className={classNames(styles.mainDescription, 'm-b-30')}>
                <Trans i18nKey="cookieSettingsDescription">
                  This website uses cookies so that we can provide you with the best user experience
                  possible. You can decide which cookies you want to allow and can change these
                  settings at any time. Please note, for optimal performance we recommend you allow
                  all cookies. Some functions may no longer be available if certain cookies are
                  disabled. For more information please visit our{' '}
                  <LinkButton onClick={handlePrivacyClick} className={styles.link}>
                    Cookie Policy
                  </LinkButton>
                  .
                </Trans>
              </div>
              <div className={styles.trackedInfoContainer}>
                <div
                  className={classNames({ [styles.fullGrid]: !notAllowedTrackedInfoList.length })}
                >
                  {allowedTrackedInfoList.length > 0 && (
                    <div className={classNames(styles.subtitle, 'm-b-15')}>
                      {t('allowedTrackedInfoTitle')}
                    </div>
                  )}
                  {allowedTrackedInfoList.map(({ label, type }, index) => (
                    <TrackingInfo
                      key={label}
                      label={label}
                      type={type}
                      className={index ? 'm-t-15' : ''}
                      tracked
                    />
                  ))}
                </div>
                {notAllowedTrackedInfoList.length > 0 && (
                  <div className="m-l-40">
                    <div className={classNames(styles.subtitle, 'm-b-15')}>
                      {t('notAllowedTrackedInfoTitle')}
                    </div>
                    {notAllowedTrackedInfoList.map(({ label, type }, index) => (
                      <TrackingInfo
                        key={label}
                        label={label}
                        type={type}
                        className={index ? 'm-t-15' : ''}
                        tracked={false}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="text-right">
              <Button label={t('saveAndClose')} onClick={handleSaveCookies} />
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default CookieSettings;
