import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// components
import Chip from 'components/atoms/NewChip';
// styles
import styles from './chipsList.module.scss';

const propTypes = {
  selected: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    })
  ).isRequired,
  className: PropTypes.shape({
    root: PropTypes.string,
    chip: PropTypes.string,
  }),
};

const defaultProps = {
  className: {
    root: '',
    chip: '',
  },
};

const ChipsList = ({ selected, items, className }) => {
  return (
    <div className={classNames(styles.container, className.root)}>
      {items.map(({ value, label, color }, index) => {
        return (
          <Chip
            key={value}
            color={value === selected ? color : 'gray'}
            className={classNames(styles.chip, { 'm-l-5': index }, className.chip)}
          >
            {label}
          </Chip>
        );
      })}
    </div>
  );
};

ChipsList.propTypes = propTypes;
ChipsList.defaultProps = defaultProps;

export default ChipsList;
