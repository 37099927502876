import SubmissionSettings from './SubmissionSettings';

export { default as ProposalSettings } from './ProposalSettings';
export { default as RejectSubmission } from './RejectSubmission';
export { default as EditRejectionReason } from './EditRejectionReason';
export { default as UploadReleasableCV } from './UploadReleasableCV';
export { default as UploadReleasableAttestation } from './UploadReleasableAttestation';
export { default as WithdrawSubmission } from './WithdrawSubmission';
export { default as ReplaceCV, commitNewCv } from './ReplaceCV';

export default SubmissionSettings;
