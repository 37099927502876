import notificationService from '@alaka/notifications';
// constants
import {
  NOTIFICATION_API_ENDPOINT,
  NOTIFICATION_API_TOKEN,
  NOTIFICATION_DEV_MODE,
  NOTIFICATION_DEV_EMAIL,
} from 'constants/notification';
// services
import logger from './Logger';

const notification = notificationService({
  API_endpoint: NOTIFICATION_API_ENDPOINT,
  API_token: NOTIFICATION_API_TOKEN,
  devMode: NOTIFICATION_DEV_MODE,
  devEmail: NOTIFICATION_DEV_EMAIL,
  failSilent: true,
  onError: error => {
    logger.exception(error);
  },
});

export default notification;
