import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';

const propTypes = {
  symbol: PropTypes.string,
  active: PropTypes.bool,
  empty: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
};

const defaultProps = {
  symbol: '',
  active: true,
  empty: false,
  error: false,
  errorMessage: '',
  className: '',
  children: null,
};

const Text = ({ symbol, active, empty, error, errorMessage, className, children }) => {
  const classes = ['rateTable_cell'];

  if (!active || empty) {
    classes.push('rateTable-disabled');
  }
  if (error) {
    classes.push('rateTable-error');
  }
  if (className) {
    classes.push(...className.split(' '));
  }

  return (
    <div className={classes.join(' ')}>
      <Tooltip title={errorMessage && error ? errorMessage : ''} placement="top-end">
        <div>
          {empty ? '-' : children}
          {symbol && <span className="rateTable_symbol">{symbol}</span>}
        </div>
      </Tooltip>
    </div>
  );
};

Text.propTypes = propTypes;
Text.defaultProps = defaultProps;

export default Text;
