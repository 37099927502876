import { gql } from '@apollo/client';

const typeDefs = gql`
  type ItemCount {
    type: String!
    count: Int!
  }

  extend type Query {
    email: String!
    role: String!
    itemsCount(type: String!): ItemCount
  }
`;

export default typeDefs;
