import React, { useState, useCallback, useRef } from 'react';
import classNames from 'classnames';
import debounceFn from 'lodash/debounce';
import { MdSearch } from 'react-icons/md';
import TextInput from '@components/TextInput';
import styles from './searchInput.module.scss';

interface SearchInputProps {
  id?: string;
  name?: string;
  placeholder?: string;
  autoFocus?: boolean;
  ariaLabel?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  minLength?: number;
  debounce?: boolean;
  debounceDelay?: number;
  onChange: (value: string) => void;
}

const SearchInput = ({
  id,
  name,
  ariaLabel,
  placeholder,
  autoFocus,
  disabled,
  fullWidth = false,
  minLength = 3,
  debounce = true,
  debounceDelay = 300,
  onChange,
}: SearchInputProps) => {
  const [text, setText] = useState('');
  const previousSearch = useRef('');

  const debounceChange = useCallback(
    debounceFn((value: string) => onChange(value), debounceDelay),
    []
  );

  const handleChange = useCallback(
    (value: string) => {
      setText(value);

      if ((value && value.length < minLength) || value === previousSearch.current) {
        return;
      }

      previousSearch.current = value;

      if (!debounce || !value) {
        onChange(value);
      } else {
        debounceChange(value);
      }
    },
    [text, debounce]
  );

  return (
    <div className={classNames(styles.container, { [styles.fullWidth]: fullWidth })}>
      <TextInput
        id={id}
        name={name}
        placeholder={placeholder}
        autoFocus={autoFocus}
        disabled={disabled}
        ariaLabel={ariaLabel}
        value={text}
        onChange={handleChange}
        icon={MdSearch}
      />
    </div>
  );
};

export default SearchInput;
