import React from 'react';
// libraries
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// components
import LinkButton from 'components/atoms/LinkButton';
import Score from 'components/atoms/Score';
import SubmissionStatusFlow from 'components/molecules/SubmissionStatusFlow';
import Rates from 'components/molecules/Rates';
import TooltipIcon from 'components/molecules/TooltipIcon';
import ExcellentMatchIcon from 'components/molecules/ExcellentMatchIcon';
import { MatchMetricsPopper } from 'components/organisms/MatchMetrics';
// constants
import { SUBMISSION_STATUS } from 'constants/submissionStatus';
import { SUBMISSION_TAB } from 'constants/pageTabs';
import {
  SUPPLIER_ROLE,
  FILE_CV,
  FILE_ATTESTATION,
  RATES_SELL_PRICE,
  RATES_SUPPLIER_RATE,
} from 'constants';
// helpers
import { getRevisionsStatusInfoText } from 'helpers/Revision';
import { getPassedRelativeTime } from 'helpers/RelativeTimeFormatter';
import { openLink } from 'helpers/Link';
import { boxFileUrl } from 'helpers/Box';
// styles
import styles from './positionSubmissions.module.scss';

const propTypes = {
  role: PropTypes.string.isRequired,
  position: PropTypes.object.isRequired,
  submission: PropTypes.object.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const Submission = ({ role, position, submission, className }) => {
  const { t } = useTranslation();

  const status = submission.getStatus(role);
  const cv = submission.getDocument(FILE_CV);
  const attestation = submission.getDocument(FILE_ATTESTATION);
  const displayExcellentMatch = submission.isExcellentMatch() && submission.hasEnoughDataPoints();
  const partnerRates = submission.getRates(RATES_SUPPLIER_RATE);
  const sellRates = submission.getRates(RATES_SELL_PRICE);

  const getCandidateDetailLink = () => {
    const link = `/submission/${submission.id}`;

    if ([SUBMISSION_STATUS.OFFER, SUBMISSION_STATUS.ONBOARDING].includes(status.value)) {
      return `${link}?tab=${SUBMISSION_TAB.ONBOARDING}`;
    }

    return link;
  };

  const handleLinkClick = link => event => {
    event.preventDefault();
    openLink(link);
  };

  const getRevisionStatus = () => {
    const revisionStatus = getRevisionsStatusInfoText(submission.revisions, role);

    if (revisionStatus && role === SUPPLIER_ROLE) {
      return (
        <TooltipIcon
          icon="revision"
          tooltip={revisionStatus}
          size={17}
          placement="bottom"
          className="m-l-5 display-inline-flex"
        />
      );
    }

    return null;
  };

  return (
    <Link to={getCandidateDetailLink()}>
      <div className={classNames(styles.row, { [className]: className })}>
        <MatchMetricsPopper
          metrics={submission.getMetrics()}
          score={submission.score}
          candidateName={submission.profile.fullName}
          positionTitle={position.title}
          project={position.project.name}
          occupations={position.getOccupationsName()}
          experienceLevel={position.experienceLevel.value}
          placement="right-start"
        >
          <Score
            score={submission.score}
            icon={displayExcellentMatch ? <ExcellentMatchIcon bordered /> : null}
          />
        </MatchMetricsPopper>

        <div>
          <div className={styles.info}>
            <div className={styles.name}>{submission.profile.fullName}</div>
            <SubmissionStatusFlow submission={submission} />
            {getRevisionStatus()}
          </div>
          {role === SUPPLIER_ROLE && (
            <div className={styles.supplier}>{submission.submittedBy.name}</div>
          )}
          <div className={styles.date}>{getPassedRelativeTime(status.start)}</div>
        </div>

        <Rates rates={role === SUPPLIER_ROLE ? partnerRates : sellRates} />

        <div className="fs-unmask">
          <LinkButton onClick={handleLinkClick(boxFileUrl(cv.boxId))}>{t('viewCv')}</LinkButton>
          <LinkButton onClick={handleLinkClick(boxFileUrl(attestation.boxId))} className="m-t-2">
            {t('attestation')}
          </LinkButton>
          {!position.isFixedPrice() && (
            <LinkButton
              onClick={handleLinkClick(`/report/match/${submission.report.id}`)}
              className="m-t-2"
            >
              {t('matchReport')}
            </LinkButton>
          )}
        </div>
      </div>
    </Link>
  );
};

Submission.propTypes = propTypes;
Submission.defaultProps = defaultProps;

export default Submission;
