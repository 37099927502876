import { gql } from '@apollo/client';
import {
  Rate,
  SubmissionStatus,
  SubmissionRole,
  PositionStatus,
  RevisionStatus,
  MatchMetric,
  MatchReport,
  ExperienceLevel,
} from 'queries/Fragments';

export const GET_SUBMISSIONS_TABLE_VIEW = gql`
  query getSubmissionsForTableView(
    $filter: NewSubmissionFilter!
    $first: Int!
    $skip: Int!
    $orderBy: [SubmissionOrderBy]
    $sort: [SubmissionSort!]
  ) {
    submissionsList(first: $first, skip: $skip, filter: $filter, orderBy: $orderBy, sort: $sort) {
      count
      items {
        id
        createdBy {
          id
        }
        score
        submissionDate
        statuses(sort: { createdAt: DESC }) {
          items {
            ...SubmissionStatus
          }
        }
        submissionRoles {
          items {
            ...SubmissionRole
          }
        }
        rates(filter: { endDate: { is_empty: true } }) {
          items {
            ...Rate
          }
        }
        revisions {
          items {
            id
            statuses {
              items {
                ...RevisionStatus
              }
            }
          }
        }
        candidate {
          id
          supplier {
            id
            company {
              id
              name
            }
          }
        }
        profile {
          id
          fullName
        }
        position {
          id
          title
          rfq {
            id
            name
          }
          statuses(filter: { end: { is_empty: true } }) {
            items {
              ...PositionStatus
            }
          }
          rates(filter: { endDate: { is_empty: true } }) {
            items {
              ...Rate
            }
          }
          dueOn
          supplierDueDate
          project {
            id
            name
            shortName
            config
            timezone
            currency
            client {
              id
              name
              config
            }
          }
          level {
            ...ExperienceLevel
          }
          shOccupations
          report {
            id
            shPositionId
          }
          deliveryOption {
            id
            mode
            description
          }
          location {
            items {
              id
              city
            }
          }
        }
        hiringProcess {
          id
          purchaseOrder {
            id
            start
            end
          }
        }
        reports(sort: { createdAt: DESC }) {
          items {
            ...MatchReport
          }
        }
        metrics {
          items {
            ...MatchMetric
          }
        }
      }
    }
  }
  ${SubmissionStatus}
  ${SubmissionRole}
  ${Rate}
  ${RevisionStatus}
  ${PositionStatus}
  ${ExperienceLevel}
  ${MatchReport}
  ${MatchMetric}
`;
