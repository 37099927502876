import React, { forwardRef } from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  forwardedRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
};

const defaultProps = {
  disabled: false,
  className: '',
  forwardedRef: null,
};

const ClickableElement = ({ onClick, children, className, disabled, forwardedRef, ...props }) => {
  if (disabled) {
    return <div className={className}>{children}</div>;
  }

  return (
    <div
      role="button"
      tabIndex="0"
      onClick={onClick}
      onKeyPress={event => (event.keyCode === 13 ? onClick : null)}
      className={classNames('cp', { [className]: className })}
      ref={forwardedRef}
      {...props}
    >
      {children}
    </div>
  );
};

ClickableElement.propTypes = propTypes;
ClickableElement.defaultProps = defaultProps;

export default forwardRef((props, ref) => <ClickableElement forwardedRef={ref} {...props} />);
