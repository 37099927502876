import { gql } from '@apollo/client';

const draftFragment = gql`
  fragment Draft on Draft {
    id
    updatedAt
    name
    draft
    params
    form {
      id
      type
      project {
        id
        name
        config
      }
    }
  }
`;

export const GET_MY_DRAFTS = gql`
  query draftsList($filter: DraftFilter, $sort: [DraftSort!]) {
    draftsList(filter: $filter, sort: $sort) {
      items {
        ...Draft
      }
      count
    }
  }
  ${draftFragment}
`;

export const GET_DRAFT_BY_ID = gql`
  query getDraftById($id: ID!) {
    draft(id: $id) {
      ...Draft
    }
  }
  ${draftFragment}
`;

export const CREATE_DRAFT = gql`
  mutation createDraft($userId: ID!, $formId: ID!, $draft: JSON!, $name: String!, $params: String) {
    draftCreate(
      data: {
        name: $name
        draft: $draft
        form: { connect: { id: $formId } }
        user: { connect: { id: $userId } }
        params: $params
      }
    ) {
      ...Draft
    }
  }
  ${draftFragment}
`;

export const UPDATE_DRAFT = gql`
  mutation updateDraft($id: ID!, $draft: JSON, $name: String) {
    draftUpdate(filter: { id: $id }, data: { name: $name, draft: $draft }) {
      ...Draft
    }
  }
  ${draftFragment}
`;

export const GET_DRAFT_COUNT = gql`
  query getDraftsCount($userId: ID!, $type: String!) {
    draftsList(
      filter: {
        AND: [{ user: { id: { equals: $userId } } }, { form: { type: { equals: $type } } }]
      }
    ) {
      count
    }
  }
`;
