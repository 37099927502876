import { POSITION_STATUS } from 'constants/positionStatus';
import { SUBMISSION_STATUS } from 'constants/submissionStatus';
import { STAFF_ROLE, SUPPLIER_ROLE, MANAGER_ROLE } from 'constants';

/**
 * Check if given role has permission to view note
 * @param {string} status
 * @param {string} role
 * @returns {bool}
 */
export const isGrantedStatusNote = (status, role) => {
  switch (role) {
    case STAFF_ROLE:
      return true;
    case SUPPLIER_ROLE:
      return [
        SUBMISSION_STATUS.NOT_SUBMITTED,
        SUBMISSION_STATUS.NOT_SELECTED,
        SUBMISSION_STATUS.WITHDRAWN,
        POSITION_STATUS.CANCELLED,
      ].includes(status);
    case MANAGER_ROLE:
      return [SUBMISSION_STATUS.NOT_SELECTED, POSITION_STATUS.CANCELLED].includes(status);
    default:
      return false;
  }
};
