import React from 'react';
import prettyBytes from 'pretty-bytes';
import { MdClose } from 'react-icons/md';
import LinearProgress from '@mui/material/LinearProgress';
import FileRecord, { FileRecordAction } from '@components/FileRecord';
import { BaseFile, UploadedFile, DroppedFile } from './types';
import styles from './fileUpload.module.scss';

interface FilesListProps {
  files: (DroppedFile | UploadedFile)[];
  onRemove: (file: BaseFile) => void;
  disabled: boolean;
}

const isUploadedFile = (file: DroppedFile | UploadedFile): file is UploadedFile => 'link' in file;

const FilesList = ({ files, onRemove, disabled }: FilesListProps) => {
  const handleRemove = (file: DroppedFile | UploadedFile) => () => {
    onRemove(file);
  };

  const getFileDescription = (file: DroppedFile | UploadedFile) => {
    if (!isUploadedFile(file)) {
      return file.loading ? 'Uploading file...' : prettyBytes(file.size);
    }

    return '';
  };

  return (
    <div className={styles.filesList}>
      {files.map((file) => (
        <div key={file.id}>
          <FileRecord
            filename={file.filename}
            description={getFileDescription(file)}
            link={isUploadedFile(file) ? file.link : ''}
            tags={file.tags}
            disabled={disabled}
          >
            {!disabled && (isUploadedFile(file) || !file.loading) && (
              <FileRecordAction icon={MdClose} label="Remove" onClick={handleRemove(file)} />
            )}
          </FileRecord>
          {!isUploadedFile(file) && file.loading && (
            <LinearProgress
              classes={{
                colorPrimary: styles.progressBarColor,
                barColorPrimary: styles.progressBarSecondaryColor,
              }}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default FilesList;
