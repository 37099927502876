import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// components
import TextSection from 'components/atoms/TextSection/TextSection';

const propTypes = {
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      role: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const PositionRoles = ({ roles, className }) => {
  if (!roles.length) {
    return null;
  }

  return (
    <div className={className}>
      {roles.map(({ role, name }, index) => {
        return (
          <div key={role} className={classNames({ 'm-t-15': index })}>
            <TextSection title={role}>{name}</TextSection>
          </div>
        );
      })}
    </div>
  );
};

PositionRoles.propTypes = propTypes;
PositionRoles.defaultProps = defaultProps;

export default PositionRoles;
