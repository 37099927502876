import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import getProperty from 'lodash/get';
// helpers
import { prettyNumber } from 'helpers/Number';
// components
import NumberInput from 'components/atoms/NumberInput';
import Textarea from 'components/atoms/Textarea';
import Field from 'components/molecules/FieldSection';
import RadioList from 'components/molecules/RadioList';
import RejectionFields from './RejectionFields';
// constants
import { FIELD } from './constants';
// styles
import styles from './selectWinner.module.scss';

const propTypes = {
  submissions: PropTypes.arrayOf(PropTypes.object).isRequired,
  hasSubmissions: PropTypes.bool.isRequired,
  formData: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

const SelectWinnerTab = ({ submissions, hasSubmissions, formData, onChange }) => {
  const { t } = useTranslation();
  const hasLost = formData[FIELD.SUBMISSION_WON].value === false;
  const hasWon = formData[FIELD.SUBMISSION_WON].value === true;
  const boolOptions = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ];
  const submissionsListOptions = submissions.map(submission => ({
    value: submission.id,
    label: submission.getName(),
  }));

  const handleChange = field => value => {
    onChange({ ...formData, [field]: { ...formData[field], value } });
  };

  const handleSubmissionSelection = submissionId => {
    const selectedSubmission = submissions.find(submission => submissionId === submission.id);

    onChange({
      ...formData,
      [FIELD.SELECTED_SUBMISSION]: {
        ...formData[FIELD.SELECTED_SUBMISSION],
        value: selectedSubmission,
      },
      [FIELD.NOT_SELECTED_SUBMISSIONS]: {
        ...formData[FIELD.NOT_SELECTED_SUBMISSIONS],
        value: selectedSubmission
          ? submissions.filter(submission => submissionId !== submission.id)
          : [],
      },
    });
  };

  const handleOurCandidateHasWonChange = submissionWon => {
    onChange({
      ...formData,
      [FIELD.SUBMISSION_WON]: {
        ...formData[FIELD.SUBMISSION_WON],
        value: submissionWon,
      },
      [FIELD.SELECTED_SUBMISSION]: {
        ...formData[FIELD.SELECTED_SUBMISSION],
        value: null,
      },
      [FIELD.NOT_SELECTED_SUBMISSIONS]: {
        ...formData[FIELD.NOT_SELECTED_SUBMISSIONS],
        value: submissionWon ? [] : submissions,
      },
      [FIELD.HAS_REJECTION_REASON]: {
        value: null,
      },
      [FIELD.SAME_REJECTION_REASON]: {
        value: null,
      },
      [FIELD.REASON]: {
        value: '',
      },
      [FIELD.REASONS]: {
        value: {},
      },
    });
  };

  return (
    <React.Fragment>
      {hasSubmissions && (
        <Field
          title={t('didOurCandidateWin')}
          classes={{ content: styles.sectionContent }}
          error={formData[FIELD.SUBMISSION_WON].error}
          errorMessages={formData[FIELD.SUBMISSION_WON].errorMessages}
        >
          <RadioList
            options={boolOptions}
            selected={formData[FIELD.SUBMISSION_WON].value}
            onChange={handleOurCandidateHasWonChange}
          />
        </Field>
      )}
      {hasWon && (
        <React.Fragment>
          <div className="m-t-30">
            <Field
              title={t('submissions')}
              description={t('pleaseSelectWinningProposal')}
              classes={{ content: styles.sectionContent }}
              error={formData[FIELD.SELECTED_SUBMISSION].error}
              errorMessages={formData[FIELD.SELECTED_SUBMISSION].errorMessages}
            >
              <div className={styles.checkListWrapper}>
                <RadioList
                  options={submissionsListOptions}
                  selected={getProperty(formData, [FIELD.SELECTED_SUBMISSION, 'value', 'id'])}
                  onChange={handleSubmissionSelection}
                />
              </div>
            </Field>
          </div>
          <RejectionFields formData={formData} onChange={onChange} />
        </React.Fragment>
      )}
      {(!hasSubmissions || hasLost) && (
        <React.Fragment>
          <div className="m-t-30">
            <Field
              title={t('totalEvaluatedPrice')}
              classes={{ content: styles.sectionContent }}
              error={formData[FIELD.TOTAL_EVALUATED_PRICE].error}
              errorMessages={formData[FIELD.TOTAL_EVALUATED_PRICE].errorMessages}
            >
              <NumberInput
                min={0}
                step={1}
                value={formData[FIELD.TOTAL_EVALUATED_PRICE.value]}
                onChange={handleChange(FIELD.TOTAL_EVALUATED_PRICE)}
                className={styles.totalPrice}
                floatLeft
                formatter={prettyNumber}
              />
            </Field>
          </div>
          <RejectionFields formData={formData} onChange={onChange} />
          <div className="m-t-30">
            <Field
              title={t('additionalNotes')}
              description={t('additionalNotesDescription')}
              optional
              classes={{ content: styles.sectionContent }}
            >
              <Textarea
                value={formData[FIELD.NOTES].value}
                onChange={handleChange(FIELD.NOTES)}
                rows={8}
              />
            </Field>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

SelectWinnerTab.propTypes = propTypes;

export default SelectWinnerTab;
