export const SET_PASSWORD_FORM = [
  {
    id: 'password',
    icon: 'lock',
    title: 'password',
    type: 'password',
    required: true,
  },
  {
    id: 'confirmedPassword',
    icon: 'lock',
    title: 'confirmedPassword',
    type: 'password',
    required: true,
  },
];

export const DEFAULT_SET_PASSWORD_STATE = {
  password: {
    value: '',
    error: false,
    errorMessage: '',
    hint: null,
    anchor: null,
  },
  confirmedPassword: {
    value: '',
    error: false,
    errorMessage: '',
  },
};
