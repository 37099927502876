import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
// components
import CommaSeparatedList from 'components/atoms/CommaSeparatedList';
import LinkButton from 'components/atoms/LinkButton';
import Button from 'components/atoms/Button';
// hooks
import useCookieSettings from '../useCookieSettings';
// styles
import styles from './cookieBanner.module.scss';

const propTypes = {
  onSettingsClick: PropTypes.func.isRequired,
};

const CookieBanner = ({ onSettingsClick }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    cookiesAccepted,
    hasNewTermsOfUseVersion,
    hasNewPrivacyPolicyVersion,
    hasNewCookiePolicyVersion,
    acceptAllCookies,
    acceptNewPolicies,
  } = useCookieSettings();

  const redirectTo = route => {
    history.push(route);
  };

  const getButtonLabel = () => {
    if (!cookiesAccepted || hasNewCookiePolicyVersion) {
      return t('acceptAll');
    }

    return hasNewTermsOfUseVersion && hasNewPrivacyPolicyVersion ? t('acceptAll') : t('accept');
  };

  const renderText = () => {
    // new users who have never accepted any policy before
    if (!cookiesAccepted) {
      return (
        <Trans i18nKey="cookieBannerText">
          We use cookies to improve your experience and remember your preferences. For more
          information please visit our{' '}
          <LinkButton className={styles.link} onClick={() => redirectTo('/legal/cookies')}>
            Cookie Policy
          </LinkButton>
          .
        </Trans>
      );
    }

    // cookie policy, privacy policy or terms of use version was updated
    const updatedPoliciesLinks = [];

    if (hasNewPrivacyPolicyVersion) {
      updatedPoliciesLinks.push(
        <LinkButton className={styles.link} onClick={() => redirectTo('/legal/privacy')}>
          {t('privacyPolicy')}
        </LinkButton>
      );
    }
    if (hasNewTermsOfUseVersion) {
      updatedPoliciesLinks.push(
        <LinkButton className={styles.link} onClick={() => redirectTo('/legal/terms')}>
          {t('termsOfUse')}
        </LinkButton>
      );
    }
    if (hasNewCookiePolicyVersion) {
      updatedPoliciesLinks.push(
        <LinkButton className={styles.link} onClick={() => redirectTo('/legal/cookies')}>
          {t('cookiePolicy')}
        </LinkButton>
      );
    }

    return (
      <Trans i18nKey="cookieBannerUpdatedText">
        We have updated our <CommaSeparatedList items={updatedPoliciesLinks} />. Please review and
        accept these changes.
      </Trans>
    );
  };

  const handleAcceptButtonClick = () => {
    // save new cookie settings only if cookies policy was changed
    if (!cookiesAccepted || hasNewCookiePolicyVersion) {
      acceptAllCookies();
    }

    acceptNewPolicies();
  };

  return (
    <div className={styles.banner}>
      <div className={styles.text}>{renderText()}</div>
      <div className={styles.buttons}>
        {(!cookiesAccepted || hasNewCookiePolicyVersion) && (
          <LinkButton onClick={onSettingsClick} className={styles.linkButton}>
            {t('cookieSettings')}
          </LinkButton>
        )}
        <Button
          label={getButtonLabel()}
          color="transparentblue"
          secondary
          onClick={handleAcceptButtonClick}
        />
      </div>
    </div>
  );
};

CookieBanner.propTypes = propTypes;

export default CookieBanner;
