import { useContext } from 'react';
import ToastContext from '@v1/context/ToastContext';

type ToastAction = (message: string) => void;

interface ToastProvider {
  success: ToastAction;
  warning: ToastAction;
  error: ToastAction;
}

// TODO: Refactor toast
const useToast = (): ToastProvider => {
  // @ts-ignore
  const { addToast } = useContext(ToastContext);

  return {
    success: (message: string) => {
      addToast.success(message);
    },
    warning: (message: string) => {
      addToast.warning(message);
    },
    error: (message: string) => {
      addToast.error(message);
    },
  };
};

export default useToast;
