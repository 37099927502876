import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import getProperty from 'lodash/get';
// components
import LinkButton from 'components/atoms/LinkButton';
import Score from 'components/atoms/Score';
import Icon from 'components/atoms/Icon';
import TextIcon from 'components/atoms/TextIcon';
import TruncatedText from 'components/atoms/TruncatedText';
import Button from 'components/atoms/Button';
import Tooltip from 'components/atoms/Tooltip';
import Chip, { COLOR } from 'components/atoms/NewChip';
import ExcellentMatchIcon from 'components/molecules/ExcellentMatchIcon';
import { MatchMetricsPopper } from 'components/organisms/MatchMetrics';
// helpers
import { openLink } from 'helpers/Link';
import { getCurrencyText } from 'helpers/NameMapping';
import { getTimeYear, getRelativeTime } from 'helpers/RelativeTimeFormatter';
import { preventDefault } from 'helpers/HtmlDOM';
// constants
import { RATES_TARGET_RATE } from 'constants';
// styles
import styles from './candidatesMatchTable.module.scss';

const propTypes = {
  match: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
  candidate: PropTypes.object.isRequired,
};

const CandidateMatchRow = ({ match, position, candidate }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const rate = position.getRate(RATES_TARGET_RATE);
  const location = position.getLocation();

  const redirectToSubmissionForm = () => {
    history.push(`/submission/create/${position.id}/${match.id}`);
  };

  const goToReportPreview = () => {
    const reportId = getProperty(match, 'report.id', '');
    openLink(`/report/match/${reportId}`);
  };

  return (
    <React.Fragment>
      <Link to={`/position/${position.id}`}>
        <div className={styles.row}>
          {/* Score */}
          <div className={styles.score}>
            <MatchMetricsPopper
              metrics={match.metrics}
              score={match.report.score}
              candidateName={candidate.name}
              positionTitle={position.title}
              project={position.project.name}
              occupations={position.getOccupationsName()}
              experienceLevel={position.experienceLevel.value}
              placement="right-start"
              offsetX={10}
            >
              <Score
                score={getProperty(match, 'report.score', '')}
                icon={match.displayExcellentMatch ? <ExcellentMatchIcon bordered /> : null}
              />
            </MatchMetricsPopper>
          </div>

          {/* Match report */}
          <div className={styles.columnCell}>
            <LinkButton onClick={preventDefault(goToReportPreview)}>
              <TextIcon icon="fileText" size={16}>
                {t('viewReport')}
              </TextIcon>
            </LinkButton>
          </div>

          {/* Position name */}
          <div className={styles.columnCell}>
            <div className={styles.cell}>
              <span className="bolder">{position.rfqLabel}</span>
              <Tooltip title={`${position.project.name} - ${position.client.name}`}>
                <div className="overflowHidden">
                  <Chip size="sm" color={COLOR.GRAY} className={styles.projectChip}>
                    <TruncatedText>{position.project.shortName}</TruncatedText>
                  </Chip>
                </div>
              </Tooltip>
            </div>
            <TruncatedText>{position.title}</TruncatedText>
          </div>

          {/* Due date */}
          <div className={classNames(styles.columnCell, styles.right)}>
            <div className="bolder">{getTimeYear(position.supplierDueDate)}</div>
            <div className="m-t-2">{getRelativeTime(position.supplierDueDate)}</div>
          </div>

          {/* Target Rate */}
          <div className={classNames(styles.cell, styles.right)}>
            <div className="bolder">{rate.value}</div>
            {position.isFixedPrice() ? (
              <span className={classNames('m-l-2', styles.smaller)}>
                <span className="bolder">{getCurrencyText(rate.currency)}</span>/{t('hour')}
              </span>
            ) : (
              <span className={classNames('m-l-2', 'bolder', styles.smaller)}>
                {getCurrencyText(rate.currency)}
              </span>
            )}
          </div>

          {/* Location */}
          <div className={styles.cell}>
            {location ? (
              <React.Fragment>
                <div className={styles.locationIcon}>
                  <Icon size={15} name="location" />
                </div>
                <TruncatedText>{location}</TruncatedText>
              </React.Fragment>
            ) : (
              <span className={styles.smaller}>
                <i>{t('notProvided')}</i>
              </span>
            )}
          </div>

          {/* Clearance */}
          <div className={styles.cell}>
            <Tooltip title={position.clearance}>
              <div className="overflowHidden">
                <TruncatedText disableTooltip>{position.clearanceShortName}</TruncatedText>
              </div>
            </Tooltip>
          </div>

          {/* Starting date */}
          <div className={classNames(styles.columnCell, styles.right)}>
            {position.duration.start ? (
              <React.Fragment>
                <div className="bolder">{getTimeYear(position.duration.start)}</div>
                <div className="m-t-2">{getRelativeTime(position.duration.start)}</div>
              </React.Fragment>
            ) : (
              t('notApplicable')
            )}
          </div>

          {/* Submit button */}
          <div className={classNames(styles.cell, styles.right)}>
            <Button onClick={preventDefault(redirectToSubmissionForm)} label={t('submit')} />
          </div>
        </div>
      </Link>
    </React.Fragment>
  );
};

CandidateMatchRow.propTypes = propTypes;

export default CandidateMatchRow;
