import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// components
import Icon from 'components/atoms/Icon';
import Button from 'components/atoms/Button';
import Modal from 'components/molecules/Modal';
// styles
import styles from './dialogs.module.scss';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  title: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  label: PropTypes.string,
  secondaryLabel: PropTypes.string,
  onClick: PropTypes.func,
  onSecondaryClick: PropTypes.func,
};

const defaultProps = {
  onClose: null,
  label: '',
  secondaryLabel: '',
  onClick: null,
  onSecondaryClick: null,
};

const ErrorDialog = ({
  isOpen,
  onClose,
  title,
  children,
  label,
  secondaryLabel,
  onClick,
  onSecondaryClick,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose || (() => {})}
      closeOnEsc={!!onClose}
      closeOnBackdrop={!!onClose}
    >
      <div className={classNames(styles.container, styles.error)}>
        {onClose && (
          <div className={styles.close}>
            <Button type="close" size={24} onClick={onClose} />
          </div>
        )}
        <div className={styles.icon}>
          <Icon name="exclamationCircle" size={120} />
        </div>
        <div className={styles.title}>{title}</div>
        <div className={styles.content}>{children}</div>
        <div className={styles.buttons}>
          {secondaryLabel && (
            <div className={label ? 'm-r-12' : ''}>
              <Button label={secondaryLabel} onClick={onSecondaryClick} color="red" secondary />
            </div>
          )}
          {label && <Button label={label} onClick={onClick} color="red" />}
        </div>
      </div>
    </Modal>
  );
};

ErrorDialog.propTypes = propTypes;
ErrorDialog.defaultProps = defaultProps;

export default ErrorDialog;
