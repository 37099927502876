/* eslint-disable react/no-array-index-key */
import React from 'react';
// libraries
import PropTypes from 'prop-types';
// constants
import { COLOR_GRAY, COLOR_BLUE, COLOR_GREEN, COLOR_RED, COLOR_YELLOW } from 'constants/colors';
// components
import Avatar, { SHAPE_OPTIONS } from 'components/atoms/Avatar';
import Tooltip from 'components/atoms/Tooltip';
import TooltipAvatar from './TooltipAvatar';
// styles
import styles from './avatarList.module.scss';

const propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
  limit: PropTypes.number,
  avatarShape: PropTypes.oneOf([SHAPE_OPTIONS.CIRCLE, SHAPE_OPTIONS.HEXAGON]),
  color: PropTypes.oneOf(['', COLOR_BLUE, COLOR_GREEN, COLOR_RED, COLOR_YELLOW, COLOR_GRAY]),
  size: PropTypes.number,
};

const defaultProps = {
  items: [],
  avatarShape: SHAPE_OPTIONS.CIRCLE,
  limit: 2,
  color: '',
  size: 13,
};

const AvatarList = ({ items, limit, color, size, avatarShape }) => {
  if (!items.length) {
    return (
      <Avatar shape={avatarShape} color={COLOR_GRAY} size={size}>
        0
      </Avatar>
    );
  }

  const renderTooltip = () => {
    return (
      <React.Fragment>
        {items.slice(limit).map((name, index) => (
          <div key={name + index}>{name}</div>
        ))}
      </React.Fragment>
    );
  };

  return (
    <div className={styles.avatarList}>
      {items.slice(0, limit).map((item, index) => {
        return (
          <div key={index} className={styles.avatar}>
            <TooltipAvatar name={item} color={color} avatarShape={avatarShape} size={size} />
          </div>
        );
      })}
      {items.length > limit && (
        <Tooltip title={renderTooltip()}>
          <div className={styles.avatar}>
            <Avatar shape={avatarShape} color={COLOR_GRAY} size={size}>
              <span>+{items.length - limit}</span>
            </Avatar>
          </div>
        </Tooltip>
      )}
    </div>
  );
};

AvatarList.propTypes = propTypes;
AvatarList.defaultProps = defaultProps;

export default AvatarList;
