import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// hooks
import useAuth from 'hooks/useAuth';
// context
import { PageFiltersConsumer } from 'context/PageFiltersContext';
// constants
import { VIEW_TABLE, VIEW_CARD } from 'constants';
// components
import Placeholder from 'components/atoms/Placeholder';
import SubmissionListTable from './SubmissionListTable';
// helpers
import { formatFilters, formatSorts } from './helpers';

const propTypes = {
  filteredBy: PropTypes.object,
  tableView: PropTypes.string,
};

const defaultProps = {
  filteredBy: {},
  tableView: VIEW_TABLE,
};

const SubmissionList = ({ filteredBy, tableView }) => {
  const user = useAuth();
  const { t } = useTranslation();

  if (user.projects.length > 0) {
    return (
      <PageFiltersConsumer>
        {({ searchText, sortedBy }) => {
          const filter = formatFilters(searchText, filteredBy, user);
          const sort = formatSorts(sortedBy, user.role);

          if (tableView === VIEW_TABLE) {
            return <SubmissionListTable filter={filter} sort={sort} />;
          }

          if (tableView === VIEW_CARD) {
            return null;
          }

          return null;
        }}
      </PageFiltersConsumer>
    );
  }

  return (
    <Placeholder size="lg" icon="candidates">
      {t('noSubmissions')}
    </Placeholder>
  );
};

SubmissionList.propTypes = propTypes;
SubmissionList.defaultProps = defaultProps;

export default React.memo(SubmissionList);
