import React, { useState, useEffect } from 'react';
// libraries
import PropTypes from 'prop-types';
// components
import NodeComponent from 'components/atoms/NodeComponent';
// chart
import getGaugeChart from './gauge';

const propTypes = {
  score: PropTypes.number.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const GaugeChart = ({ score, className }) => {
  const [node, setNode] = useState();

  useEffect(() => {
    setNode(getGaugeChart(score));
  }, [score]);

  return <NodeComponent node={node} className={className} />;
};

GaugeChart.propTypes = propTypes;
GaugeChart.defaultProps = defaultProps;

export default GaugeChart;
