import React from 'react';
// libraries
import PropTypes from 'prop-types';
// styles
import colors from 'dependencies/materialStyles/Colors';
import styles from './elementLoader.module.scss';

const propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

const defaultProps = {
  color: colors.blue,
  size: 6,
};

const ElementLoader = ({ color, size }) => {
  return (
    <div
      className={styles.loader}
      style={{
        '--color': color,
        '--size': size,
      }}
    >
      <span className={styles.dot}>&nbsp;</span>
      <span className={styles.dot}>&nbsp;</span>
      <span className={styles.dot}>&nbsp;</span>
    </div>
  );
};

ElementLoader.propTypes = propTypes;
ElementLoader.defaultProps = defaultProps;

export default ElementLoader;
