import moment from 'moment-timezone';
import i18n from 'i18next';
import getProperty from 'lodash/get';
import isEqual from 'lodash/isEqual';
// services
import client from 'services/Client';
import logger from 'services/Logger';
import { SAVE_PUBLISH_PROGRESS } from 'queries/PositionSettingQueries';
// constants
import { RATES_TARGET_RATE, RATES_NTE_RATE, NOTE_PUBLISH_NOTES } from 'constants';
import { POSITION_STATUS } from 'constants/positionStatus';
// helpers
import { getYear } from 'helpers/RelativeTimeFormatter';
import { calculateTargetRate } from 'helpers/RatesCalculation';
// constants
import { STEPS } from '../constants';

export const getChangedFields = (
  position,
  {
    publishToAll,
    targetRates,
    partnerDueDate,
    audience,
    publishNote,
    showTargetRatesToPartners,
    showNteRatesToPartners,
    allowSubmissionAboveTargetRates,
  }
) => {
  const changedFields = [];

  const newAudienceIds = audience ? audience.map(({ value }) => value) : [];
  const prevAudienceIds = position.audience.map(({ id }) => id);
  const prevNote = getProperty(position.getNote(NOTE_PUBLISH_NOTES), 'text', '');
  const prevRates = position.getRates(RATES_TARGET_RATE).map(({ value }) => value);
  const newRates = targetRates.map(({ value }) => value);

  if (publishToAll !== position.isPublishedToAll) {
    changedFields.push('audience');
  }

  if (
    !publishToAll &&
    !isEqual(newAudienceIds, prevAudienceIds) &&
    !changedFields.includes('audience')
  ) {
    changedFields.push('audience');
  }

  if (partnerDueDate !== position.supplierDueDate) {
    changedFields.push('dueDate');
  }

  if (prevNote !== publishNote) {
    changedFields.push('note');
  }

  // compare position settings with previous settings
  if (showTargetRatesToPartners !== position.showTargetRatesToPartners) {
    changedFields.push('showTargetRatesToPartners');
  }
  if (showNteRatesToPartners !== position.showNteRatesToPartners) {
    changedFields.push('showNteRatesToPartners');
  }
  if (allowSubmissionAboveTargetRates !== position.allowSubmissionAboveTargetRates) {
    changedFields.push('allowSubmissionAboveTargetRates');
  }

  if (!isEqual(newRates, prevRates)) {
    changedFields.push('rates');
  }

  return changedFields;
};

/**
 * How many times were position published before?
 * @param {object[]} positionStatuses
 * @returns {number}
 */
export const getPositionPublishedCount = positionStatuses => {
  return positionStatuses.filter(status => status.name === POSITION_STATUS.PUBLISHED && status.end)
    .length;
};

/**
 * Calculate default target rates from NTE rates
 * @param {object} position
 * @returns {object[]}
 */
export const getDefaultTargetRates = position => {
  const nteRates = position.getRates(RATES_NTE_RATE);

  return nteRates.map(rate => ({
    value: calculateTargetRate(rate.value),
    year: parseInt(getYear(rate.startDate)),
  }));
};

/**
 * @param {string} partnerDueDate
 * @param {string} positionsDueDate
 * @returns {bool}
 */
export const isPartnerDueDateValid = (partnerDueDate, positionsDueDate) => {
  const isBeforePositionDueDate = moment(partnerDueDate).isBefore(positionsDueDate);
  const isInFuture = moment(partnerDueDate).isAfter(moment());

  return isBeforePositionDueDate && isInFuture;
};

/**
 * @param {string} partnerDueDate
 * @param {string} positionsDueDate
 * @returns {string}
 */
export const getPartnerDueDateErrorMessage = (partnerDueDate, positionsDueDate) => {
  if (!partnerDueDate) {
    return i18n.t('partnerDueDateMissingErrorMessage');
  }

  // partner due date is after position due date
  if (moment(partnerDueDate).isAfter(positionsDueDate)) {
    return i18n.t('invalidSupplierDueDateExceed');
  }

  // partner due date is in past
  if (moment(partnerDueDate).isBefore(moment())) {
    return i18n.t('invalidSupplierDueDatePast');
  }

  return '';
};

/**
 * Helper function calculate supplier due date from position due date
 * @param {string} positionDueDate
 */
export const getSupplierDueDate = positionDueDate => {
  if (positionDueDate && moment(positionDueDate).isValid()) {
    return moment(positionDueDate)
      .tz('Europe/Bucharest')
      .set({ hour: 9, minute: 0, second: 0 })
      .toISOString();
  }

  return null;
};

/**
 * @param {object} data
 * @returns {object}
 */
export const parseProgressData = data => {
  // remove "error" and "highlight" fields from rates
  return {
    ...data,
    [STEPS.TARGET_RATES]: data[STEPS.TARGET_RATES].map(rate => ({
      value: rate.value,
      year: rate.year,
    })),
  };
};

/**
 * Save current progress
 * @param {string} positionId
 * @param {object} data publish data
 * @param {string} currentStep current step Id
 * @returns {Promise}
 */
export const saveProgress = async (positionId, data, currentStep) => {
  try {
    await client.mutate({
      mutation: SAVE_PUBLISH_PROGRESS,
      variables: {
        position: positionId,
        data: {
          data,
          lastStep: currentStep,
        },
      },
    });
  } catch (error) {
    logger.exception(error, { data, currentStep });
    throw error;
  }
};
