import colors from './Colors';

const contrastColors = {
  blue: {
    color: colors.blue8,
    backgroundColor: colors.blue1,
  },
  green: {
    color: colors.green7,
    backgroundColor: colors.green1,
  },
  yellow: {
    color: colors.yellow9,
    backgroundColor: colors.yellow2,
  },
  red: {
    color: colors.red7,
    backgroundColor: colors.red1,
  },
  gray: {
    color: colors.gray8,
    backgroundColor: colors.gray3,
  },
  darkGray: {
    color: colors.white,
    backgroundColor: colors.gray7,
  },
};

export default contrastColors;
