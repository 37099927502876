import i18n from 'i18next';

export const translateKey = value => {
  if (!value) {
    return value;
  }
  const regex = new RegExp(/[^A-Za-z0-9]+/, 'g');
  const newValue = value.replace(regex, '');
  return i18n.t(newValue.toLowerCase());
};

export const trimLabel = (label, numberOfChars) => {
  return `${label.substring(0, numberOfChars)}${label.length > numberOfChars ? '.' : ''}`;
};

export const formatCurrencyRate = (value, curr = 'EUR', translation = 'en-US') => {
  const currency = curr === 'EURO' ? 'EUR' : curr;
  return new Intl.NumberFormat(translation, { style: 'currency', currency }).format(value);
};

export const getInitials = name => {
  const initials = name.match(/\b\w/g) || [];
  return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
};

export const capitalizeWord = text => {
  return text[0].toUpperCase() + text.substr(1);
};

const capitalizeLetters = (rawLabel, char) => {
  const label = rawLabel.split(char);

  const capitalized = [...label].map(value => {
    return capitalizeWord(value);
  });

  return capitalized.join(char);
};

/**
 * Capitalize 1st word in text and other words transform to lowercase
 * @param {string} text
 * @return {string}
 */
export const capitalizeText = text => {
  return text
    .split(' ')
    .map((word, index) => (index ? word.toLowerCase() : capitalizeWord(word)))
    .join(' ');
};

export const removeDots = label => {
  const capitalizedLabel = capitalizeLetters(label, '.');
  return capitalizedLabel.replace(/\./g, '');
};

export const formPossessive = name => {
  return name.slice(-1) === 's' ? `${name}'` : `${name}'s`;
};

export const formatList = strings => {
  const AND = i18n.t('and');

  if (strings.length < 1) return '';

  if (strings.length === 1) return strings[0];

  if (strings.length === 2) return strings.join(` ${AND} `);

  const lastElement = strings.pop();

  return `${strings.join(', ')} ${AND} ${lastElement}`;
};
