/* eslint-disable react/no-array-index-key */
import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// components
import Button from 'components/atoms/Button';
import RateTable from 'components/molecules/RateTable';
import Popover from 'components/molecules/Popover';
// constants
import { MIN_BUSINESS_GP_REQUIREMENT, MIN_BUSINESS_GP_FP_REQUIREMENT } from 'constants/rates';
// helpers
import { prettyNumber } from 'helpers/Number';
import { getYear } from 'helpers/RelativeTimeFormatter';
import { getCurrencyText } from 'helpers/NameMapping';
import {
  calculateSellPricesFromTargetRates,
  calculateSellPriceFromTargetRate,
  calculatePositionGPsFromSellPrice,
  calculatePositionGPFromSellPrice,
} from 'helpers/RatesCalculation';
import { getRatesValue } from '../helpers';
// context
import MultipliersContext from '../MultipliersContext';

const propTypes = {
  currency: PropTypes.string.isRequired,
  nteRates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      startDate: PropTypes.string.isRequired,
    })
  ).isRequired,
  targetRates: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      startDate: PropTypes.string.isRequired,
    })
  ).isRequired,
  sellPrices: PropTypes.arrayOf(PropTypes.number).isRequired,
  onTargetRatesChange: PropTypes.func.isRequired,
  setSellPrices: PropTypes.func.isRequired,
  setIbmGPs: PropTypes.func.isRequired,
  showTargetRates: PropTypes.bool.isRequired,
  ibmGPs: PropTypes.arrayOf(PropTypes.number).isRequired,
  isFixedPrice: PropTypes.bool.isRequired,
};

const Rates = ({
  currency,
  nteRates,
  targetRates,
  onTargetRatesChange,
  sellPrices,
  setSellPrices,
  showTargetRates,
  setIbmGPs,
  ibmGPs,
  isFixedPrice,
}) => {
  const { t } = useTranslation();
  const { ibmFee, manYearDiscount } = useContext(MultipliersContext);

  const targetRateValues = getRatesValue(targetRates);
  const MIN_GP_REQUIREMENT = isFixedPrice
    ? MIN_BUSINESS_GP_FP_REQUIREMENT
    : MIN_BUSINESS_GP_REQUIREMENT;

  useEffect(() => {
    const initialSellPrices = calculateSellPricesFromTargetRates(targetRateValues, ibmFee);
    setSellPrices(initialSellPrices);
    setIbmGPs(
      calculatePositionGPsFromSellPrice(targetRateValues, initialSellPrices, manYearDiscount)
    );
  }, [ibmFee, JSON.stringify(targetRates)]);

  const onTargetRateChange = (index, value) => {
    const newTargetRates = targetRates.map((rate, rateIndex) =>
      rateIndex !== index ? rate : { ...rate, value }
    );
    onTargetRatesChange(newTargetRates);
    const newSellPrices = sellPrices.map((sellPrice, priceIndex) =>
      priceIndex === index
        ? calculateSellPriceFromTargetRate(newTargetRates[index].value, ibmFee)
        : sellPrice
    );
    setSellPrices(newSellPrices);
  };

  const onSellPriceChange = (index, value) => {
    const updatedSellPrices = sellPrices.map((price, priceIndex) =>
      priceIndex === index ? value : price
    );
    setSellPrices(updatedSellPrices);
    const newIbmGPs = ibmGPs.map((ibmGP, ibmGPIndex) =>
      ibmGPIndex === index
        ? calculatePositionGPFromSellPrice(
            targetRateValues[index],
            updatedSellPrices[index],
            manYearDiscount
          )
        : ibmGP
    );
    setIbmGPs(newIbmGPs);
  };

  return (
    <div className="rfqRates_rates">
      <RateTable>
        <RateTable.Row>
          <RateTable.Head align="left">{t('rateTypes')}</RateTable.Head>
          {nteRates.map(({ id, startDate }) => (
            <RateTable.Head key={id}>{nteRates.length > 1 && getYear(startDate)}</RateTable.Head>
          ))}
        </RateTable.Row>
        <RateTable.Row>
          <RateTable.Title className="rfqRates_rates_type" active={false}>
            {t('clientNte')}
          </RateTable.Title>
          {nteRates.map(({ id, value }) => (
            <RateTable.Text key={id} symbol={getCurrencyText(currency)} active={false}>
              {prettyNumber(value)}
            </RateTable.Text>
          ))}
        </RateTable.Row>
        <RateTable.Row>
          <RateTable.Title className="rfqRates_rates_type" active={showTargetRates}>
            {t('partnerTargetRate')}
          </RateTable.Title>
          {targetRates.map((rate, index) => {
            if (!showTargetRates) {
              return <RateTable.Text key={index} symbol={getCurrencyText(currency)} empty />;
            }

            return (
              <RateTable.Input
                id={`test_targetRate_${index}`}
                key={index}
                symbol={getCurrencyText(currency)}
                value={rate.value}
                onChange={val => onTargetRateChange(index, val)}
                error={nteRates[index] && rate.value > nteRates[index].value}
                errorMessage={t('rateExceedClientNTE')}
              />
            );
          })}
        </RateTable.Row>
        <RateTable.Row>
          <RateTable.Title className="rfqRates_rates_type">{t('sellPriceLabel')}</RateTable.Title>
          {sellPrices.map((price, index) => (
            <RateTable.Input
              id={`test_sellPrice_${index}`}
              key={index}
              symbol={getCurrencyText(currency)}
              value={price}
              onChange={val => onSellPriceChange(index, val)}
              error={nteRates[index] && price > nteRates[index].value}
              errorMessage={t('priceExceedClientNTE')}
            />
          ))}
        </RateTable.Row>
        <RateTable.Row>
          <RateTable.Title className="rfqRates_rates_type" active={false}>
            <div className="oneRow">
              <span className="m-r-5">{t('dgp')}</span>
              <Popover
                button={onClick => (
                  <Button type="help" iconStyle="icon0" size={12} onClick={onClick} />
                )}
              >
                <div className="p-15">
                  <p className="m-b-5">
                    <b>{t('mydGPHelp')}</b>
                  </p>
                  <i>
                    {t('operatingProfitFormula')}
                    <br />
                    {t('operatingProfitMarginFormula')}
                  </i>
                </div>
              </Popover>
            </div>
          </RateTable.Title>
          {ibmGPs.map((dGP, index) => (
            <RateTable.Text
              key={index}
              symbol="%"
              active={false}
              error={dGP < MIN_GP_REQUIREMENT}
              errorMessage={t('lowGPErrorText', { minimumGP: MIN_GP_REQUIREMENT })}
            >
              {dGP}
            </RateTable.Text>
          ))}
        </RateTable.Row>
      </RateTable>
    </div>
  );
};

Rates.propTypes = propTypes;

export default Rates;
