/* eslint-disable import/order */
import React, { useState, useContext } from 'react';
// libraries
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import getProperty from 'lodash/get';
// context
import ToastContext from 'context/ToastContext';
// hooks
import useAuth from 'hooks/useAuth';
// constants
import { ON_BOARDING_WITHDRAWN, ON_BOARDING_START_DATE_REJECTED } from 'constants/templates';
import { ONBOARDING_CANCELLED_TYPES } from './constants';
// components
import Button from 'components/atoms/Button';
import EmailModal from '../EmailModal';
// helpers
import { parseEmailTemplateData, notifyCandidateWithdrawal } from '../helpers';

const propTypes = {
  type: PropTypes.oneOf(Object.values(ONBOARDING_CANCELLED_TYPES)).isRequired,
  reason: PropTypes.string,
  submission: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
};

const defaultProps = {
  reason: '',
};

const NotifyPM = ({ type, reason, submission, position }) => {
  const { t } = useTranslation();
  const { addToast } = useContext(ToastContext);
  const user = useAuth();
  const [isOpen, setOpen] = useState(false);

  const projectId = getProperty(submission, 'position.project.id', '');
  const { managerEmail, ...templateData } = parseEmailTemplateData(submission, position, user);
  const templateType =
    type === ONBOARDING_CANCELLED_TYPES.CANDIDATE_WITHDRAWN
      ? ON_BOARDING_WITHDRAWN
      : ON_BOARDING_START_DATE_REJECTED;

  const handleSubmit = async email => {
    try {
      await notifyCandidateWithdrawal(submission, email, user.id);
      setOpen(false);
      addToast.success(t('notificationSent'));
    } catch (error) {
      addToast.error(t('errorWhileNotifyPMCandidateWithdrawn'));
    }
  };

  return (
    <div className="m-t-10 text-center">
      <Button label={t('notifyPM')} onClick={() => setOpen(true)} />
      <EmailModal
        isOpen={isOpen}
        projectId={projectId}
        title={t('notifyPMCandidateWithdrawal')}
        recipient={managerEmail}
        onClose={() => setOpen(false)}
        onSubmit={handleSubmit}
        templateType={templateType}
        templateData={{ ...templateData, reason }}
      />
    </div>
  );
};

NotifyPM.propTypes = propTypes;
NotifyPM.defaultProps = defaultProps;

export default NotifyPM;
