/* eslint-disable import/order */
import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
// components
import AdvancedScore from 'components/molecules/AdvancedScore';
import ChipsList from 'components/molecules/ChipsList';
import ExcellentMatchIcon from 'components/molecules/ExcellentMatchIcon';
// constants
import { SUPPORTED_COLORS } from 'constants/colors';
import { MATCH_TYPE, TYPES } from './constants';
// helpers
import { getReadableYearDateTime } from 'helpers/RelativeTimeFormatter';
import { getQualityByType } from './helpers';
// styles
import styles from './leftSection.module.scss';

const propTypes = {
  metrics: PropTypes.shape({
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    median: PropTypes.number.isRequired,
    percentile: PropTypes.number,
    updatedAt: PropTypes.string.isRequired,
  }).isRequired,
  matchType: PropTypes.oneOf(Object.values(MATCH_TYPE)).isRequired,
  matchColor: PropTypes.oneOf(SUPPORTED_COLORS).isRequired,
  score: PropTypes.number.isRequired,
  candidateName: PropTypes.string.isRequired,
  positionTitle: PropTypes.string.isRequired,
};

const LeftSection = ({ metrics, score, matchType, matchColor, candidateName, positionTitle }) => {
  const quality = getQualityByType(matchType);

  return (
    <div className={styles.container}>
      <div className={styles.candidateName}>{candidateName}</div>
      <div className={styles.position}>{positionTitle}</div>
      <div className={styles.mainContent}>
        <div className={styles.scoreContainer}>
          <AdvancedScore
            score={score}
            min={metrics.min}
            median={metrics.median}
            max={metrics.max}
            color={matchColor}
          />
        </div>
        <div className={styles.matchContainer}>
          {matchType === MATCH_TYPE.EXCELLENT && (
            <div className={styles.matchIcon}>
              <ExcellentMatchIcon size={20} />
            </div>
          )}
          <div className={styles.matchLabel}>{quality}</div>
        </div>
        <div className={styles.chipContainer}>
          <ChipsList selected={matchType} items={TYPES} />
        </div>
      </div>
      <div className={styles.footer}>
        <Trans i18nKey="matchMetricsUpdateInfo">
          The Match comparison reflects <br />
          the current data and is subject to change.
          <br />
          Last updated <b>{{ lastUpdated: getReadableYearDateTime(metrics.updatedAt) }}</b>
        </Trans>
      </div>
    </div>
  );
};

LeftSection.propTypes = propTypes;

export default LeftSection;
