import React from 'react';
// Libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
// constants
import { TAB_NOTIFICATIONS } from 'constants';
// components
import Notification from './Notification';

const propTypes = {
  notifications: PropTypes.array,
  // markAllAsRead: PropTypes.func,
  handleClose: PropTypes.func,
};

const defaultProps = {
  notifications: null,
  // markAllAsRead: () => {},
  handleClose: () => {},
};

const NotificationList = ({
  handleClose,
  // markAllAsRead,
  notifications,
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  if (!notifications) {
    return null;
  }

  const onSettingsClick = () => {
    history.push(`/settings?tab=${TAB_NOTIFICATIONS}`);
    handleClose();
  };

  return (
    <div className="notifications">
      <div className="notification_headerContainer fs-unmask">
        <div className="notification_header">{t('notifications')}</div>
        {/* TODO: finish in future */}
        {/* <div className="notification_markAllAsRead" onClick={markAllAsRead} role="presentation">
          {t('markAllAsRead')}
        </div> */}
        <div className="notification_helpers" onClick={onSettingsClick} role="presentation">
          {t('settings')}
        </div>
      </div>
      {notifications.map(item => {
        return (
          <React.Fragment key={item.id}>
            {item.flag && <div className="notification_group">{t(item.flag)}</div>}
            <Notification data={item} close={handleClose} />
          </React.Fragment>
        );
      })}
    </div>
  );
};

NotificationList.propTypes = propTypes;
NotificationList.defaultProps = defaultProps;

export default NotificationList;
