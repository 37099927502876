import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// hooks
import useAuth from 'hooks/useAuth';
// queries
import { GET_LOCATION, GET_PROJECT_PARTNERS } from 'queries/SearchQueries';
// components
import CheckboxList from 'components/molecules/CheckboxList';
import AutoCompleteSearch from 'components/molecules/AutoCompleteSearch';
import SideFilter from 'components/molecules/SideFilter';
import DropdownCheckbox from 'components/molecules/DropdownCheckbox';
// constants
import { STAFF_ROLE } from 'constants';
import { SUBMISSION_FILTERS } from 'constants/filters';
// helpers
import { parseLocationData, parseSuppliers } from 'helpers/FormDataParser';
import { parseFilterOptions } from 'helpers/Filter';
import { getAllowedFilterOptions, getSelectedFilterOptions } from './helpers';
// data
import { DUE_DATE_FILTERS, SUBMISSION_STATUS_FILTERS_MAP } from './data/filter';

const propTypes = {
  filter: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

const SubmissionsFilters = ({ filter, onClose, onClear, onChange }) => {
  const { t } = useTranslation();
  const { role, projects } = useAuth();

  const projectList = projects.map(({ id, name }) => ({ value: id, label: name }));
  const selectedProject = filter[SUBMISSION_FILTERS.PROJECT];
  const selectedLocation = filter[SUBMISSION_FILTERS.LOCATION];
  const selectedPartner = filter[SUBMISSION_FILTERS.PARTNER];
  // concat is used for converting/ensuring value to array
  const selectedDueDate = [].concat(filter[SUBMISSION_FILTERS.DUE_DATE] || []);
  const selectedStatuses = [].concat(filter[SUBMISSION_FILTERS.STATUS] || []);
  // Enable/disable filter options
  const selectedOptions = getSelectedFilterOptions(filter);
  const allowedOptions = getAllowedFilterOptions(filter, role);
  const dueDateOptions = parseFilterOptions(DUE_DATE_FILTERS, allowedOptions, selectedOptions);
  const statusOptions = parseFilterOptions(
    SUBMISSION_STATUS_FILTERS_MAP[role],
    allowedOptions,
    selectedOptions
  );

  const handleChecklistSelection = filterType => selectedList => {
    onChange(filterType, selectedList);
  };

  const handleSelect = type => item => {
    if (!item) {
      onChange(type, null);
    } else {
      onChange(type, item.label);
    }
  };

  return (
    <SideFilter onClose={onClose} onClear={onClear} className="fs-unmask">
      {/* Filter by due date */}
      <SideFilter.Group title={t('dueDate')}>
        <CheckboxList
          options={dueDateOptions}
          selected={selectedDueDate}
          onChange={handleChecklistSelection(SUBMISSION_FILTERS.DUE_DATE)}
        />
      </SideFilter.Group>

      {/* Filter by position status */}
      <SideFilter.Group title={t('status')}>
        <CheckboxList
          options={statusOptions}
          selected={selectedStatuses}
          onChange={handleChecklistSelection(SUBMISSION_FILTERS.STATUS)}
        />
      </SideFilter.Group>

      {/* Filter by project */}
      {projectList.length > 1 && (
        <SideFilter.Group title={t('project')}>
          <DropdownCheckbox
            name={SUBMISSION_FILTERS.PROJECT}
            list={projectList}
            selected={selectedProject}
            onChange={handleSelect(SUBMISSION_FILTERS.PROJECT)}
            label={t('selectProject')}
            checkedLabel={selectedProject}
            showCloseIcon
          />
        </SideFilter.Group>
      )}

      {/* Filter by location */}
      <SideFilter.Group title={t('location')}>
        <AutoCompleteSearch
          id="location"
          type="locationsList"
          customQuery={GET_LOCATION}
          customDataParser={data => parseLocationData(data, true)}
          onChange={handleSelect(SUBMISSION_FILTERS.LOCATION)}
          limitSize={5}
          defaultValue={selectedLocation ? { label: selectedLocation } : null}
        />
      </SideFilter.Group>

      {/* Filter by partner - only MM */}
      {role === STAFF_ROLE && (
        <SideFilter.Group title={t('partner')}>
          <AutoCompleteSearch
            id="partner"
            type="suppliersList"
            customQuery={GET_PROJECT_PARTNERS}
            customQueryVariables={{ projects: projects.map(project => project.id) }}
            customDataParser={parseSuppliers}
            onChange={handleSelect(SUBMISSION_FILTERS.PARTNER)}
            limitSize={5}
            defaultValue={selectedPartner ? { label: selectedPartner } : null}
          />
        </SideFilter.Group>
      )}
    </SideFilter>
  );
};

SubmissionsFilters.propTypes = propTypes;

export default SubmissionsFilters;
