/**
 * Mac scrollbar by default has 0 width because it adds an overlaid scrollbar on scrolling
 * Option "Always" for scrollbars on Mac (Mac settings -> general -> "Show scroll bars") adds
 * scrollbar which is not overlaid and it takes some width of the element. It means that scrollbar
 * width can be different based on Mac OS setting
 * @returns {number}
 */
export const getScrollbarWidth = () => {
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.width = '100px';
  document.body.appendChild(outer);

  // force scrollbars
  outer.style.overflow = 'scroll';

  // add inner div
  const inner = document.createElement('div');
  inner.style.width = '100%';
  outer.appendChild(inner);

  // store widths in variables before removing elements from DOM
  const widthNoScroll = outer.offsetWidth;
  const widthWithScroll = inner.offsetWidth;

  // remove elements
  outer.parentNode.removeChild(outer);

  return widthNoScroll - widthWithScroll;
};

/**
 * Has element horizontal scrollbar?
 * @param {Element} element HTML element
 * @returns {boolean}
 */
export const hasHorizontalScrollbar = element => {
  return element.scrollWidth > element.clientWidth;
};

/**
 * Has element vertical scrollbar?
 * @param {Element} [element] HTML element. If this arg is omitted -> scroll is checked for window
 * @returns {boolean}
 */
export const hasVerticalScrollbar = element => {
  if (!element || element === window) {
    return document.body.offsetHeight > window.innerHeight;
  }

  return element.scrollHeight > element.clientHeight;
};

/**
 * Get width of the element without scrollbar width
 * @param {Element} element HTML element
 * @returns {number}
 */
export const getElementWidth = element => {
  const sizes = element.getBoundingClientRect();

  if (hasVerticalScrollbar(element)) {
    return sizes.width - getScrollbarWidth();
  }

  return sizes.width;
};

/**
 * Get heigh of the element without scrollbar height
 * @param {Element} element HTML element
 * @returns {number}
 */
export const getElementHeight = element => {
  const sizes = element.getBoundingClientRect();

  if (hasHorizontalScrollbar(element)) {
    return sizes.height - getScrollbarWidth();
  }

  return sizes.height;
};

/**
 * Prevent event default behaviour
 * @param {?function} [callback]
 */
export const preventDefault = callback => event => {
  event.preventDefault();

  if (callback) {
    callback(event);
  }
};

/**
 * Stop event propagation
 * @param {?function} [callback]
 */
export const stopPropagation = callback => event => {
  event.stopPropagation();

  if (callback) {
    callback(event);
  }
};
