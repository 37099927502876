import { gql } from '@apollo/client';
import getProperty from 'lodash/get';
// services
import client from 'services/Client';
import logger from 'services/Logger';

const FETCH_CLEARANCES_DATA = gql`
  query fetchClearancesByProjectId($projectId: ID) {
    clearanceLevelCollectionsList(
      filter: {
        OR: [{ projects: { some: { id: { equals: $projectId } } } }, { default: { equals: true } }]
      }
    ) {
      items {
        id
        default
        clearanceLevels(sort: { priority: DESC }) {
          items {
            id
            name
            shortName
          }
        }
      }
    }
  }
`;

const parseClearances = clearancesList => {
  if (clearancesList.length === 1) {
    return getProperty(clearancesList, '0.clearanceLevels.items', []);
  }

  if (clearancesList.length > 1) {
    const clearanceCollection = clearancesList.find(collection => !collection.default);
    return getProperty(clearanceCollection, 'clearanceLevels.items', []);
  }

  return [];
};

/**
 * Fetch clearances options for submission form clearance field
 * @param {string} [projectId]
 * @param {boolean} [shortNameAsLabel=false]
 * @return {Promise<object[]>}
 */
export const fetchClearances = async (projectId, shortNameAsLabel = false) => {
  try {
    const response = await client.query({ query: FETCH_CLEARANCES_DATA, variables: { projectId } });
    const clearancesList = getProperty(response, 'data.clearanceLevelCollectionsList.items', []);
    const clearances = parseClearances(clearancesList);

    return clearances.map(({ id, name, shortName }) => ({
      id,
      value: id,
      label: shortNameAsLabel ? shortName : name,
      title: name,
    }));
  } catch (error) {
    logger.exception(error, { projectId });
    throw error;
  }
};
