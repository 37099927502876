import { gql } from '@apollo/client';
import getProperty from 'lodash/get';
import moment from 'moment';
// services
import client from 'services/Client';
import logger from 'services/Logger';
import notification from 'services/Notification';
import { upload } from 'services/Box';
// queries
import { SubmissionStatus, PurchaseOrder } from 'queries/Fragments';
// helpers
import { boxFileDir } from 'helpers/Box';
import { getSubmissionLink } from 'helpers/Link';
// constants
import { SUBMISSION_STATUS_TYPE } from 'constants/submissionStatus';
import { SUBMISSION_TAB } from 'constants/pageTabs';
import { ONBOARDING_TAB, ONBOARDING_STATUS_PO } from 'constants/onboarding';
import { FILE_PURCHASE_ORDER } from 'constants';
import { PURCHASE_ORDER_RATE, PURCHASE_ORDER_TOTAL_BUDGET } from '../constants';

const parseFile = async (formData, position, candidateName, userId) => {
  const folder = boxFileDir(
    { rfq: position.rfq, project: position.project.name, client: position.client.name },
    'onboarding'
  );
  const file = getProperty(formData, 'file.0.file');
  const extension = file.name.split('.').pop();
  const filename = `${candidateName}_PURCHASE_ORDER.${extension}`;
  const boxId = await upload(file, filename, folder);

  return {
    boxId,
    name: filename,
    type: FILE_PURCHASE_ORDER,
    author: { connect: { id: userId } },
  };
};

const CREATE_PURCHASE_ORDER_SUBMISSION = gql`
  mutation createPurchaseOrderSubmission($submissionId: ID!, $data: SubmissionUpdateInput!) {
    submissionUpdate(filter: { id: $submissionId }, data: $data) {
      id
      statuses(sort: { createdAt: DESC }) {
        items {
          ...SubmissionStatus
        }
      }
      hiringProcess {
        id
        purchaseOrder {
          ...PurchaseOrder
        }
      }
    }
  }
  ${SubmissionStatus}
  ${PurchaseOrder}
`;

const CREATE_PURCHASE_ORDER_PROPOSAL = gql`
  mutation createPurchaseOrderProposal($submissionId: ID!, $data: ProposalUpdateInput!) {
    proposalUpdate(filter: { id: $submissionId }, data: $data) {
      id
      statuses(sort: { createdAt: DESC }) {
        items {
          ...SubmissionStatus
        }
      }
      hiringProcess {
        id
        purchaseOrder {
          ...PurchaseOrder
        }
      }
    }
  }
  ${SubmissionStatus}
  ${PurchaseOrder}
`;

/**
 * Create Purchase Order by Project Manager
 * Step: Purchase Order - Create PO
 * Role: Project Manager
 *
 * Actions:
 * 1. Create Purchase Order
 * 2. Put end date to current hiring status
 * 3. Create new hiring status
 *
 * @param {object} data
 * @param {object} data.formData
 * @param {object} data.submission
 * @param {string} data.currentOnboardingStatusId Latest hiring status id
 * @param {string} data.position
 * @param {string} data.userId Logged in user id
 */
const createPurchaseOrder = async ({
  formData,
  submission,
  currentOnboardingStatusId,
  position,
  userId,
}) => {
  try {
    const number = getProperty(formData, 'number.value');
    const amendment = getProperty(formData, 'amendment.value');
    const rate = getProperty(formData, 'rate.value');
    const totalBudget = getProperty(formData, 'totalBudget.value');
    const effort = getProperty(formData, 'effort.value');
    const start = getProperty(formData, 'start.value');
    const end = getProperty(formData, 'end.value');
    const mutationData = {
      statuses: {
        update: {
          filter: { id: currentOnboardingStatusId },
          data: { end: moment().toISOString() },
        },
        create: {
          start: moment().toISOString(),
          name: ONBOARDING_STATUS_PO.CONFIRM,
          type: SUBMISSION_STATUS_TYPE.ONBOARDING_PO,
        },
      },
      hiringProcess: {
        update: {
          purchaseOrder: {
            create: {
              number,
              amendment,
              file: {
                create: await parseFile(formData, position, submission.getName(), userId),
              },
              rate: {
                create: {
                  value: rate,
                  currency: position.project.currency,
                  startDate: moment().format('YYYY-MM-DD'),
                  rateType: PURCHASE_ORDER_RATE,
                },
              },
              totalBudget: {
                create: {
                  value: totalBudget,
                  currency: position.project.currency,
                  startDate: moment().format('YYYY-MM-DD'),
                  rateType: PURCHASE_ORDER_TOTAL_BUDGET,
                },
              },
              start: moment(start).format('YYYY-MM-DD'),
              end: moment(end).format('YYYY-MM-DD'),
              effort,
            },
          },
        },
      },
    };

    await client.mutate({
      mutation: submission.isProposal()
        ? CREATE_PURCHASE_ORDER_PROPOSAL
        : CREATE_PURCHASE_ORDER_SUBMISSION,
      variables: {
        submissionId: submission.id,
        data: mutationData,
      },
      update: cache => {
        cache.evict({ fieldName: ['submissionsList', 'positionsList', 'proposalsList'] });
        cache.gc();
      },
    });

    const link = getSubmissionLink(submission.id, submission.isProposal(), {
      queryParams: {
        step: ONBOARDING_TAB.PO,
        tab: SUBMISSION_TAB.ONBOARDING,
      },
    });

    notification.onboardingPurchaseOrderCreated({
      submission: submission.id,
      link,
      createdBy: userId,
      isProposal: submission.isProposal(),
    });
  } catch (error) {
    logger.exception(error);
    throw error;
  }
};

export default createPurchaseOrder;
