import { gql } from '@apollo/client';
import getProperty from 'lodash/get';
import moment from 'moment';
// services
import client from 'services/Client';
import logger from 'services/Logger';
import notification from 'services/Notification';
// queries
import { SubmissionStatus, PositionStatus, File, OnboardingStartDate } from 'queries/Fragments';
// helpers
import { formatDate } from 'helpers/RelativeTimeFormatter';
import { getSubmissionLink } from 'helpers/Link';
// constants
import { SUBMISSION_STATUS, SUBMISSION_STATUS_TYPE } from 'constants/submissionStatus';
import { POSITION_STATUS } from 'constants/positionStatus';
import { SUBMISSION_TAB } from 'constants/pageTabs';
import { ONBOARDING_TAB, START_DATE_TYPE, START_DATE_STATUS } from 'constants/onboarding';
import { FILE_SIGNED_JOB_OFFER } from 'constants';
import { STEP, START_DATE_NOTE } from '../constants';

const CONFIRM_START_DATE_BY_SUPPLIER_SUBMISSION = gql`
  mutation confirmStartDateByPartnerSubmission($submissionId: ID!, $data: SubmissionUpdateInput!) {
    submissionUpdate(filter: { id: $submissionId }, data: $data) {
      id
      position {
        id
        statuses(sort: { createdAt: DESC }) {
          items {
            ...PositionStatus
          }
        }
      }
      statuses(sort: { createdAt: DESC }) {
        items {
          ...SubmissionStatus
        }
      }
      hiringProcess {
        id
        dates {
          items {
            ...OnboardingStartDate
          }
        }
        files(sort: { updatedAt: DESC }) {
          items {
            ...File
          }
        }
      }
    }
  }
  ${SubmissionStatus}
  ${PositionStatus}
  ${OnboardingStartDate}
  ${File}
`;

const CONFIRM_START_DATE_BY_SUPPLIER_PROPOSAL = gql`
  mutation confirmStartDateByPartnerProposal($submissionId: ID!, $data: ProposalUpdateInput!) {
    proposalUpdate(filter: { id: $submissionId }, data: $data) {
      id
      position {
        id
        statuses(sort: { createdAt: DESC }) {
          items {
            ...PositionStatus
          }
        }
      }
      statuses(sort: { createdAt: DESC }) {
        items {
          ...SubmissionStatus
        }
      }
      hiringProcess {
        id
        dates {
          items {
            ...OnboardingStartDate
          }
        }
        files(sort: { updatedAt: DESC }) {
          items {
            ...File
          }
        }
      }
    }
  }
  ${SubmissionStatus}
  ${PositionStatus}
  ${OnboardingStartDate}
  ${File}
`;

/**
 * Confirm Start Date / Job Offer by Supplier to Match Manager
 * Step: Start Date Propose Start Date
 * Role: Supplier
 *
 * Actions:
 * 1. Update Hiring Process object
 * 2. Create Start Date Object assigned to Hiring Process with provided start date and note
 * 3. Create next hiring status
 * 4. Put end date to previous hiring status
 * 5. If signed offer provided, add it to files of hiring process
 *
 * @param {object} data
 * @param {string} data.submissionId
 * @param {object} data.position
 * @param {string} data.currentOnboardingStatusId Latest hiring status id
 * @param {string} data.currentStatusId Latest submission status id
 * @param {object} data.formData Form data with start date and note
 * @param {string} data.userId Logged in supplier user id
 */
const confirmStartDateBySupplier = async ({
  submission,
  position,
  currentOnboardingStatusId,
  currentStatusId,
  formData,
  userId,
}) => {
  try {
    const startDate = formatDate(getProperty(formData, 'startDate.value'));
    const file = getProperty(formData, `${FILE_SIGNED_JOB_OFFER}.0`);
    const note = getProperty(formData, 'note.value');
    const positionStatus = position.getStatus();
    const hiringProcessMutationData = {
      dates: {
        create: {
          date: startDate,
          type: START_DATE_TYPE.SUPPLIER,
          status: START_DATE_STATUS.PENDING,
        },
      },
    };

    if (note) {
      hiringProcessMutationData.dates.create.note = {
        create: {
          note,
          noteType: START_DATE_NOTE,
          author: { connect: { id: userId } },
        },
      };
    }

    if (file && file.boxId) {
      hiringProcessMutationData.files = {
        create: {
          boxId: file.boxId,
          name: getProperty(file, 'file.name'),
          type: FILE_SIGNED_JOB_OFFER,
          author: { connect: { id: userId } },
        },
      };
    }

    const mutationData = {
      hiringProcess: { update: hiringProcessMutationData },
      statuses: {
        update: [
          {
            filter: { id: currentStatusId },
            data: { end: moment().toISOString() },
          },
          {
            filter: { id: currentOnboardingStatusId },
            data: { end: moment().toISOString() },
          },
        ],
        create: [
          {
            start: moment().toISOString(),
            name: SUBMISSION_STATUS.ONBOARDING,
          },
          {
            start: moment().toISOString(),
            name: STEP.START_DATE_SUBMIT,
            type: SUBMISSION_STATUS_TYPE.ONBOARDING_START_DATE,
          },
        ],
      },
    };

    if (positionStatus.value === POSITION_STATUS.OFFER) {
      mutationData.position = {
        update: {
          statuses: {
            update: {
              filter: { id: positionStatus.id },
              data: { end: moment().toISOString() },
            },
            create: {
              start: moment().toISOString(),
              name: POSITION_STATUS.ONBOARDING,
            },
          },
        },
      };
    }

    await client.mutate({
      mutation: submission.isProposal()
        ? CONFIRM_START_DATE_BY_SUPPLIER_PROPOSAL
        : CONFIRM_START_DATE_BY_SUPPLIER_SUBMISSION,
      variables: {
        submissionId: submission.id,
        data: mutationData,
      },
      update: cache => {
        cache.evict({ fieldName: ['submissionsList', 'positionsList', 'proposalsList'] });
        cache.gc();
      },
    });

    // Notification for MM
    const link = getSubmissionLink(submission.id, submission.isProposal(), {
      queryParams: {
        step: ONBOARDING_TAB.START_DATE,
        tab: SUBMISSION_TAB.ONBOARDING,
      },
    });

    notification.onboardingStartDateAcceptedBySupplier({
      submission: submission.id,
      link,
      createdBy: userId,
      isProposal: submission.isProposal(),
    });
  } catch (error) {
    logger.exception(error);
    throw error;
  }
};

export default confirmStartDateBySupplier;
