import getProperty from 'lodash/get';
import setProperty from 'lodash/set';
import i18n from 'i18next';
// constants
import { REVISION_TYPES } from 'constants';
import { REVISION_TYPE_COMPONENT } from '../constants';

const getUsedTypes = formData => {
  return Object.values(formData).map(type => {
    return getProperty(type, 'value.type.value');
  });
};

export const getAvailableRevisionTypes = (openRevisionTypes, formData) => {
  const openRevisionsTypesValues = openRevisionTypes.map(({ type }) => type);
  const usedValues = getUsedTypes(formData);

  return REVISION_TYPE_COMPONENT.values.filter(({ value }) => {
    if (openRevisionsTypesValues.includes(value) || usedValues.includes(value)) {
      return false;
    }

    return true;
  });
};

const validateRates = revisionType => {
  const numberOfYears = getProperty(revisionType, 'rates.numberOfYears');
  const rateValues = getProperty(revisionType, 'value.rates.value');
  const selectValue = getProperty(revisionType, 'value.type.value');

  if (selectValue !== REVISION_TYPES.RATES) return false;
  if (!rateValues) return true;

  const { requiredError, ...rateValuesWithoutErrorProp } = rateValues;
  if (numberOfYears !== Object.values(rateValuesWithoutErrorProp).length) return true;

  return false;
};

const validateFormPartial = (formData, key) => {
  const [componentKey, subItem] = key.split('.');
  const newFormData = { ...formData };
  const revisionType = getProperty(formData, componentKey);
  const revisionTypeValue = getProperty(revisionType, ['value', subItem, 'value']);
  const values = Object.values(getProperty(newFormData, [key, 'value'], {}));

  const setError = (type, value) => {
    setProperty(newFormData, [componentKey, 'value', type, 'error'], value);
    setProperty(newFormData, [componentKey, 'error'], value);
  };

  if (!revisionTypeValue) {
    setError(subItem, true);
  }
  if (values.every(({ error }) => error === false)) {
    setProperty(newFormData, [componentKey, 'error'], revisionTypeValue);
  }

  return newFormData;
};

const validateFormFull = formData => {
  const newFormData = { ...formData };
  const values = Object.values(formData);

  const setError = (key, type) => {
    setProperty(newFormData, [key, 'value', type, 'error'], true);
    setProperty(newFormData, [key, 'value', 'error'], true);
  };

  values.forEach(revisionType => {
    const key = getProperty(revisionType, 'id');
    const selectValue = getProperty(revisionType, 'value.type.value');
    if (!selectValue) setError(key, 'type');

    const noteValue = getProperty(revisionType, 'value.note.value');
    if (!noteValue) setError(key, 'note');

    const rateError = validateRates(revisionType);
    if (rateError) {
      setError(key, 'rates');
    } else {
      setProperty(newFormData, [key, 'value', 'rates', 'error'], false);
    }
  });

  return newFormData;
};

/**
 * @param {object} formData
 * @param {string} key - if set function validates a single field in the formData object
 * @returns {FormData} - formData object enhanced with possible error objects
 */
export const validate = (formData, key = null) => {
  if (!key) {
    return validateFormFull(formData);
  }

  return validateFormPartial(formData, key);
};

export const getErrorFromFormData = formData => {
  const errorObject = Object.values(formData).find(revisionType => {
    if (
      getProperty(revisionType, 'value.type.error') ||
      getProperty(revisionType, 'value.note.error') ||
      getProperty(revisionType, 'value.rates.error')
    ) {
      return true;
    }

    return false;
  });

  if (!errorObject) return false;
  if (getProperty(errorObject, 'value.type.error')) return i18n.t('revisionTypeNotSelected');
  if (getProperty(errorObject, 'value.note.error')) return i18n.t('revisionReasonNotSelected');
  if (getProperty(errorObject, 'value.rates.error')) return i18n.t('revisionRatesNotSelected');

  return false;
};

export const closePreviouslyOpen = formData => {
  const newFormData = { ...formData };

  Object.values(newFormData).forEach(({ id }) => {
    setProperty(newFormData, [id, 'collapsed'], true);
    setProperty(newFormData, [id, ''], true);
  });

  return newFormData;
};

export const getHeaderName = value => {
  const type = getProperty(value, 'value.type.value');
  const reason = getProperty(value, 'value.note.value');

  if (!type) return 'Please select revision type';

  return `${i18n.t(type)}${reason ? ` - ${reason}` : ''}`;
};
