import React from 'react';
// libraries
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';
// component
import TextSection from 'components/atoms/TextSection';
import TextIcon from 'components/atoms/TextIcon';
// constants
import { STAFF_ROLE, SUPPLIER_ROLE, MANAGER_ROLE } from 'constants';
// styles
import styles from './submissionsCount.module.scss';

const propTypes = {
  className: PropTypes.string,
  tooltipPlacement: PropTypes.string,
  role: PropTypes.oneOf([STAFF_ROLE, SUPPLIER_ROLE, MANAGER_ROLE]),
  received: PropTypes.number.isRequired,
  submitted: PropTypes.number,
  allowed: PropTypes.number.isRequired,
  delimiter: PropTypes.string,
  textBefore: PropTypes.string,
  textAfter: PropTypes.string,
  children: PropTypes.any,
  inlineView: PropTypes.bool,
};

const defaultProps = {
  className: '',
  tooltipPlacement: 'top',
  role: STAFF_ROLE,
  submitted: 0,
  delimiter: '/',
  textBefore: '',
  textAfter: '',
  children: '',
  inlineView: true,
};

const SubmissionsCount = ({
  tooltipPlacement,
  role,
  delimiter,
  textBefore,
  textAfter,
  received,
  submitted,
  allowed,
  children,
  inlineView,
  className,
}) => {
  const { t } = useTranslation();

  const getSubmissionsCount = () => {
    switch (role) {
      case STAFF_ROLE:
        return [
          {
            icon: 'candidatesReceived',
            value: received,
            label: t('received'),
          },
          {
            icon: 'candidatesSubmitted',
            value: submitted,
            label: t('submissionsMade'),
          },
          {
            icon: 'candidatesAllowed',
            value: allowed,
            label: t('allowed'),
          },
        ];
      case SUPPLIER_ROLE:
        return [
          {
            icon: 'candidatesReceived',
            value: received,
            label: t('submittedToReview'),
          },
          {
            icon: 'candidatesSubmitted',
            value: submitted,
            label: t('submittedToClient'),
          },
        ];
      case MANAGER_ROLE:
        return [
          {
            icon: 'candidatesReceived',
            value: submitted,
            label: t('received'),
          },
          {
            icon: 'candidatesAllowed',
            value: allowed,
            label: t('allowed'),
          },
        ];
      default:
        return [];
    }
  };

  const submissions = getSubmissionsCount();

  if (inlineView) {
    const tooltipDelimiter = ` - `;
    const textParts = submissions.map(({ value }) => value);
    const tooltipText = submissions
      .map(({ value, label }) => `${value} ${label}`)
      .join(tooltipDelimiter);

    return (
      <Tooltip
        title={
          <span>
            {t('submissions')}
            <br />
            {tooltipText}
          </span>
        }
        placement={tooltipPlacement}
      >
        {children || (
          <span>
            {textBefore}
            {textParts.join(delimiter)}
            {textAfter}
          </span>
        )}
      </Tooltip>
    );
  }

  return (
    <div className={className}>
      <TextSection title={t('submissions')}>
        {submissions.map(({ value, label, icon }) => (
          <TextIcon key={icon} icon={icon} classes={{ root: 'm-t-2', icon: styles.icon }}>
            {value} {label}
          </TextIcon>
        ))}
      </TextSection>
    </div>
  );
};

SubmissionsCount.propTypes = propTypes;
SubmissionsCount.defaultProps = defaultProps;

export default SubmissionsCount;
