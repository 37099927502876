import i18n from 'i18next';

export const NOTE_FIELD = 'note';
export const REASON_FIELD = 'reasons';
export const REJECTION_REASONS = [
  {
    label: i18n.t('nonCompliantSecurityClearanceLevel'),
    value: 'Non-compliant Security Clearance Level',
  },
  { label: i18n.t('nonCompliantAttestation'), value: 'Non-compliant Attestation' },
  { label: i18n.t('lateStartDate'), value: 'Late Start Date' },
  { label: i18n.t('unsuitableSkillSet'), value: 'Unsuitable skill-set' },
  {
    label: i18n.t('lackMandatoryCertificationDiplomas'),
    value: 'Lack of Mandatory Certifications or Diplomas',
  },
  {
    label: i18n.t('higherProvidedRates'),
    value: 'The selected proposal has a better price',
  },
  { label: i18n.t('betterQualifiedCandidate'), value: 'Better Qualified Candidates' },
  { label: i18n.t('notEligibleRehire'), value: 'Not Eligible to Rehire' },
  { label: i18n.t('banned'), value: 'Banned' },
  { label: i18n.t('custom'), value: 'Custom' },
];
