import client from '@services/client';
import { gql } from '@services/gql';
import { ImportRecord } from '../types';
import IMPORT_STATUS_MAP from '../importStatusMap';

interface FetchOptions {
  limit?: number;
  after?: Nullable<string>;
  fullText?: string;
}

interface ImportRecordsResponse {
  count: number;
  items: ImportRecord[];
  pageInfo: {
    endCursor: Nullable<string>;
  };
}

const FETCH_RECORDS = gql(/* GraphQL */ `
  query fetchRecords($limit: Int!, $after: String, $fullText: String) {
    recordsList(filter: { fullText: $fullText }, limit: $limit, after: $after) {
      count
      items {
        id
        createdAt
        filename
        downloadLink
        status
        parsedData
      }
      pageInfo {
        endCursor
      }
    }
  }
`);

const fetchRecords = async (options: FetchOptions = {}): Promise<ImportRecordsResponse> => {
  const response = await client.query({
    query: FETCH_RECORDS,
    variables: { limit: options.limit || 20, after: options.after, fullText: options.fullText },
    fetchPolicy: 'no-cache',
  });

  return {
    count: response?.data.recordsList.count,
    pageInfo: response?.data.recordsList.pageInfo,
    items: response?.data.recordsList.items.map((item) => ({
      id: item.id,
      createdAt: item.createdAt,
      filename: item.filename,
      downloadLink: item.downloadLink,
      status: IMPORT_STATUS_MAP[item.status],
      parsedData: item.parsedData,
    })),
  };
};

export default fetchRecords;
