import React from 'react';
import PropTypes from 'prop-types';
import { ModalProvider as ModalContextProvider } from 'context/ModalContext';

const propTypes = {
  children: PropTypes.element.isRequired,
};

class ModalProvider extends React.PureComponent {
  state = {
    isModalOpen: false,
    modalId: '',
  };

  openModal = id => {
    this.setState({
      isModalOpen: true,
      modalId: id,
    });
  };

  closeModal = () => {
    this.setState({
      isModalOpen: false,
      modalId: '',
    });
  };

  isModalOpen = id => {
    const { isModalOpen, modalId } = this.state;

    return isModalOpen && modalId === id;
  };

  render() {
    const { children } = this.props;

    return (
      <ModalContextProvider
        value={{
          openModal: this.openModal,
          closeModal: this.closeModal,
          isModalOpen: this.isModalOpen,
        }}
      >
        {children}
      </ModalContextProvider>
    );
  }
}

ModalProvider.propTypes = propTypes;

export default ModalProvider;
