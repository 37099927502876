import React, { useState } from 'react';
import PropTypes from 'prop-types';
// Libraries
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
// Components
import Button from 'components/atoms/Button';
import Tooltip from 'components/atoms/Tooltip';
import { PublishPosition } from 'components/organisms/PositionSettings';
// Constants
import { MAIN_CANCELLED, MAIN_CLOSED, MAIN_RECRUITING } from 'constants/positionStatus';
// Style
import styles from '../publishBanner.module.scss';

const propTypes = {
  position: PropTypes.object.isRequired,
  supplierId: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  targetRates: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      year: PropTypes.number.isRequired,
    })
  ),
};

const defaultProps = {
  targetRates: [],
};

const Actions = ({ position, role, supplierId, targetRates }) => {
  const { t } = useTranslation();
  const [isPublishOpen, setIsPublishOpen] = useState(false);
  const { value: mainStatus } = position.getMainStatus(role, supplierId);
  const isDisabled =
    position.isAfterDueDate() || [MAIN_CLOSED, MAIN_CANCELLED].includes(mainStatus);

  const disabledTooltip =
    mainStatus === MAIN_RECRUITING ? t('extendPositionDueDate') : t('positionNotPublishable');

  return (
    <div className={classNames(styles.actionButtons, 'fs-unmask')}>
      <Tooltip title={isDisabled ? disabledTooltip : null}>
        <span>
          <Button
            label={t('publishPosition')}
            disabled={isDisabled}
            onClick={() => setIsPublishOpen(true)}
          />
        </span>
      </Tooltip>
      <PublishPosition
        isOpen={isPublishOpen}
        position={position}
        onClose={() => setIsPublishOpen(false)}
        targetRates={targetRates}
      />
    </div>
  );
};

Actions.propTypes = propTypes;
Actions.defaultProps = defaultProps;

export default Actions;
