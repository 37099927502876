import PropTypes from 'prop-types';
import Loadable from 'react-loadable';
// components
import Loading from './Loading';

const propTypes = {
  page: PropTypes.element.isRequired,
};

const LazyPageLoader = page => {
  return Loadable({
    loader: page,
    loading: Loading,
    delay: 300,
    timeout: 10000,
  });
};

LazyPageLoader.propTypes = propTypes;

export default LazyPageLoader;
