/* eslint-disable import/order */
import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// hooks
import useAuth from 'hooks/useAuth';
// context
import { PageFiltersConsumer } from 'context/PageFiltersContext';
// constants
import { VIEW_TABLE, VIEW_CARD } from 'constants';
// components
import Placeholder from 'components/atoms/Placeholder';
import PositionListCard from './PositionListCard';
import PositionListTable from './PositionListTable';
import { formatFilters, formatSorts } from './helpers';

const propTypes = {
  filteredBy: PropTypes.object.isRequired,
  tableView: PropTypes.string,
};

const defaultProps = {
  tableView: VIEW_TABLE,
};

const PositionList = ({ filteredBy, tableView }) => {
  const { t } = useTranslation();
  const user = useAuth();
  const customVariables = {};

  if (user.projects.length > 0) {
    return (
      <PageFiltersConsumer>
        {({ searchText, sortedBy }) => {
          const sort = formatSorts(sortedBy, user.role);
          const filter = formatFilters(searchText, filteredBy, user);

          if (tableView === VIEW_TABLE) {
            return (
              <PositionListTable filter={filter} sort={sort} customVariables={customVariables} />
            );
          }
          if (tableView === VIEW_CARD) {
            return (
              <PositionListCard filter={filter} sort={sort} customVariables={customVariables} />
            );
          }

          return null;
        }}
      </PageFiltersConsumer>
    );
  }

  return (
    <Placeholder size="lg" icon="assignment">
      {t('noPositions')}
    </Placeholder>
  );
};

PositionList.propTypes = propTypes;
PositionList.defaultProps = defaultProps;

export default React.memo(PositionList);
