import { gql } from '@apollo/client';

const TemplateGroup = gql`
  fragment TemplateGroup on TemplateGroup {
    id
    templates(
      sort: { order: ASC }
      filter: { OR: [{ category: { equals: $category } }, { category: { is_empty: true } }] }
    ) {
      count
      items {
        id
        boxId
        type
        required
      }
    }
  }
`;

export const GET_TEMPLATES_BY_PROJECT = gql`
  query templates($category: String, $projectId: ID) {
    project(id: $projectId) {
      templateGroup {
        ...TemplateGroup
      }
      client {
        id
        templateGroup {
          ...TemplateGroup
        }
      }
    }
  }
  ${TemplateGroup}
`;

export const GET_EMAIL_TEMPLATE = gql`
  query getEmailTemplate($type: String!) {
    emailTemplatesList(filter: { type: { equals: $type } }) {
      items {
        id
        templateId
        isDefault
        projects {
          items {
            id
          }
        }
        userGroup {
          id
          emails
          users {
            items {
              id
              user {
                id
                email
              }
              person {
                id
                fullName
                phone
                mobilePhone
              }
            }
          }
        }
      }
    }
  }
`;
