import React from 'react';
// libraries
import PropTypes from 'prop-types';
// constants
import { STAFF_ROLE, SUPPLIER_ROLE } from 'constants';
import { useTranslation } from 'react-i18next';
import { POSITION_STATUS } from 'constants/positionStatus';
// components
import Resolution from './Resolution';

const propTypes = {
  positionStatus: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
};

const ClosedActions = ({ role, positionStatus, ...resolutionProps }) => {
  const { t } = useTranslation();

  if ([STAFF_ROLE, SUPPLIER_ROLE].includes(role)) {
    if (
      [
        POSITION_STATUS.NO_SUBMISSIONS,
        POSITION_STATUS.UNPUBLISHED,
        POSITION_STATUS.PUBLISHED,
      ].includes(positionStatus)
    ) {
      return (
        <Resolution
          {...resolutionProps}
          label={t('resolutionClosedNoSubmissions')}
          tooltip={t('tooltipResolutionClosedNoSubmissions')}
        />
      );
    }

    if (positionStatus === POSITION_STATUS.NO_SELECTION) {
      return (
        <Resolution
          {...resolutionProps}
          label={t('resolutionClosedNoSelection')}
          tooltip={t('tooltipResolutionClosedNoSelection')}
        />
      );
    }

    if (positionStatus === POSITION_STATUS.ONBOARDING_CANCELLED) {
      return (
        <Resolution
          {...resolutionProps}
          label={t('resolutionClosedOnboardingCanceled')}
          tooltip={t('tooltipResolutionClosedOnboardingCanceled')}
        />
      );
    }
  }

  return null;
};

ClosedActions.propTypes = propTypes;

export default ClosedActions;
