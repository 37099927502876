import i18n from 'i18next';
// constants
import { NOTE_PUBLISH_NOTES, MANAGER_ROLE, NOTE_POSITION, NOTE_CLEARANCE } from 'constants';

export const parsePositionDetails = position => {
  const details = [];

  if (position.delivery.label) {
    details.push({
      label: i18n.t('deliveryOption'),
      value: position.delivery.label,
      tooltip: !position.isOnsite() ? position.delivery.description : '',
    });
  }
  if (position.workArrangement.label) {
    details.push({ label: i18n.t('workArrangement'), value: position.workArrangement.label });
  }
  if (position.evaluationMethod.label) {
    details.push({ label: i18n.t('evaluationMethod'), value: position.evaluationMethod.label });
  }
  if (position.languages.length > 0) {
    details.push({
      label: i18n.t('languages'),
      value: position.languages.join(', '),
    });
  }
  if (position.travel.label) {
    details.push({ label: i18n.t('travelRequirements'), value: position.travel.label });
  }
  if (position.clearance) {
    details.push({ label: i18n.t('clearanceRequirements'), value: position.clearance });
  }
  if (position.contractType.value) {
    details.push({ label: i18n.t('contractType'), value: position.contractType.label });
  }

  return details;
};

/**
 * @param {object} position
 * @param {string} role
 * @returns {object[]}
 */
export const getPositionNotes = (position, role) => {
  const notes = [];
  const publishNote = position.getNote(NOTE_PUBLISH_NOTES);
  const positionNote = position.getNote(NOTE_POSITION);
  const clearanceNote = position.getNote(NOTE_CLEARANCE);

  if (positionNote) {
    notes.push({ label: i18n.t('positionNotes'), ...positionNote });
  }

  if (clearanceNote) {
    notes.push({ label: i18n.t('clearanceNotes'), ...clearanceNote });
  }

  if (publishNote && role !== MANAGER_ROLE) {
    notes.push({
      label: i18n.t('publishNotes'),
      ...publishNote,
    });
  }

  return notes;
};
