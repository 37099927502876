import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
// hooks
import useAuth from 'hooks/useAuth';
// constants
import { BASIC_ROLES, SYSTEM_ROLES } from 'constants/roles';
// components
import ErrorPage from 'components/pages/ErrorPage';

const oneOrArrayOf = items =>
  PropTypes.oneOfType([PropTypes.oneOf(items), PropTypes.arrayOf(PropTypes.oneOf(items))]);

const propTypes = {
  granted: oneOrArrayOf([...BASIC_ROLES, ...SYSTEM_ROLES]).isRequired,
  component: PropTypes.any.isRequired,
  customProps: PropTypes.object,
};

const defaultProps = {
  customProps: {},
};

const ProtectedRoute = ({ component: Component, customProps, granted, ...rest }) => {
  const { role, systemRole } = useAuth();

  // check if user is granted to access the route
  const grantedRoles = Array.isArray(granted) ? granted : [granted];

  if (grantedRoles.includes(role) || grantedRoles.includes(systemRole)) {
    return <Route {...rest} render={props => <Component {...customProps} {...props} />} />;
  }

  return <ErrorPage code={401} />;
};

ProtectedRoute.defaultProps = defaultProps;
ProtectedRoute.propTypes = propTypes;

export default ProtectedRoute;
