import { gql } from '@apollo/client';
// services
import client from 'services/Client';
import logger from 'services/Logger';
// constants
import { METRICS_TYPE } from 'constants';
// helpers
import { roundNumber } from './Number';

/**
 * Get metrics by type
 * @param {object[]} metrics
 * @param {string} [type]
 * @returns {?object}
 */
export const getMetrics = (metrics, type = METRICS_TYPE.BASIC) => {
  return metrics.find(metric => metric.type === type);
};

/**
 * @param {number} percentile from match metrics
 * @returns {boolean}
 */
export const isExcellentMatch = percentile => {
  return percentile ? roundNumber(percentile, 1) >= 75 : false;
};

/**
 * @param {number} count from match metrics
 * @returns {boolean}
 */
export const hasEnoughDataPoints = count => {
  return count >= 5;
};

const UPDATE_METRICS = gql`
  mutation updateMetrics($submissionId: ID!, $type: String) {
    updateMetrics(submissionId: $submissionId, type: $type) {
      success
    }
  }
`;

/**
 *
 * @param {string} submissionId Id of created candidate
 * @param {string} [type=METRICS_TYPE.BASIC] type of metrics
 */
export const updateMetrics = async (submissionId, type = METRICS_TYPE.BASIC) => {
  try {
    await client.mutate({
      mutation: UPDATE_METRICS,
      variables: {
        submissionId,
        type,
      },
    });
  } catch (error) {
    logger.exception(error);
  }
};
