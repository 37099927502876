import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// constants
import { COLOR } from './constants';
// styles
import styles from './chip.module.scss';

const propTypes = {
  children: PropTypes.any.isRequired,
  color: PropTypes.oneOf(Object.values(COLOR)),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  className: PropTypes.string,
};

const defaultProps = {
  color: COLOR.GRAY,
  size: 'md',
  className: '',
};

// INFO: Replacement for old "Chip" component. This new component intend to be way more simple and more generic.
// It already implements the new design colors and it has better sizing options. This new chip component
// not intend to support options like notes. For it a new component like "ChipNote" should be created.
const Chip = ({ children, color, size, className }) => {
  return (
    <div className={classNames(styles.container, styles[size], styles[color], className)}>
      {children}
    </div>
  );
};

Chip.propTypes = propTypes;
Chip.defaultProps = defaultProps;

export default Chip;
