import { useState, useCallback, useReducer } from 'react';
// libraries
import moment from 'moment';
import { useBetween } from 'use-between';
// constants
import { COOKIE_POLICY_VERSION, PRIVACY_POLICY_VERSION, TERMS_OF_USE_VERSION } from 'constants';
import { COOKIE_TYPE } from './constants';

const LEGACY_COOKIE_POLICY_KEY = 'cookiesAccepted';
const COOKIES_POLICY_ACCEPTED_KEY = 'cookiesPolicyAcceptedVersion';
const PRIVACY_POLICY_ACCEPTED_KEY = 'privacyPolicyAcceptedVersion';
const TERMS_OF_USE_ACCEPTED_KEY = 'termsOfUseAcceptedVersion';
const COOKIES_POLICY_ACCEPTED_DATE_KEY = 'cookiesPolicyAcceptanceDate';
const COOKIE_POLICY_SETTINGS_KEY = 'cookiesPolicySettings';

const ACTIONS = {
  ACCEPT_COOKIES_SETTINGS: 'ACCEPT_COOKIES_SETTINGS',
  ACCEPT_NEW_POLICIES: 'ACCEPT_NEW_POLICIES',
};

const initPoliciesState = () => {
  const acceptedCookieSettings = localStorage.getItem(COOKIE_POLICY_SETTINGS_KEY);
  const acceptedCookiePolicyVersion = localStorage.getItem(COOKIES_POLICY_ACCEPTED_KEY);
  const acceptedPrivacyPolicyVersion = localStorage.getItem(PRIVACY_POLICY_ACCEPTED_KEY);
  const acceptedTermsOfUseVersion = localStorage.getItem(TERMS_OF_USE_ACCEPTED_KEY);

  return {
    cookiePolicyVersion: parseFloat(acceptedCookiePolicyVersion) || 0,
    privacyPolicyVersion: parseFloat(acceptedPrivacyPolicyVersion) || 0,
    termsOfUseVersion: parseFloat(acceptedTermsOfUseVersion) || 0,
    cookieSettings: acceptedCookieSettings
      ? JSON.parse(acceptedCookieSettings)
      : {
          [COOKIE_TYPE.FUNCTIONALITY]: false,
          // [COOKIE_TYPE.ANALYTICS]: false,
        },
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.ACCEPT_COOKIES_SETTINGS:
      localStorage.setItem(COOKIES_POLICY_ACCEPTED_KEY, COOKIE_POLICY_VERSION);
      localStorage.setItem(COOKIE_POLICY_SETTINGS_KEY, JSON.stringify(action.settings));
      localStorage.setItem(COOKIES_POLICY_ACCEPTED_DATE_KEY, moment().format('L'));

      return {
        ...state,
        cookiePolicyVersion: COOKIE_POLICY_VERSION,
        cookieSettings: action.settings,
      };
    case ACTIONS.ACCEPT_NEW_POLICIES:
      localStorage.setItem(PRIVACY_POLICY_ACCEPTED_KEY, PRIVACY_POLICY_VERSION);
      localStorage.setItem(TERMS_OF_USE_ACCEPTED_KEY, TERMS_OF_USE_VERSION);

      return {
        ...state,
        privacyPolicyVersion: PRIVACY_POLICY_VERSION,
        termsOfUseVersion: TERMS_OF_USE_VERSION,
      };
    default:
      return state;
  }
};

const useCookieSettings = () => {
  const [isCookieSettingsOpen, setIsCookieSettingsOpen] = useState(false);
  const [acceptedPolicies, dispatch] = useReducer(reducer, initPoliciesState());

  const acceptNewPolicies = useCallback(() => {
    dispatch({ type: ACTIONS.ACCEPT_NEW_POLICIES });
  }, []);

  const acceptCookieSettings = useCallback(settings => {
    dispatch({ type: ACTIONS.ACCEPT_COOKIES_SETTINGS, settings });
  }, []);

  const acceptAllCookies = useCallback(() => {
    acceptCookieSettings({
      [COOKIE_TYPE.FUNCTIONALITY]: true,
      // [COOKIE_TYPE.ANALYTICS]: true,
    });
  }, []);

  const showCookieSettings = useCallback(() => {
    setIsCookieSettingsOpen(true);
  }, []);

  const hideCookieSettings = useCallback(() => {
    setIsCookieSettingsOpen(false);
  }, []);

  const isFunctionalityCookiesAccepted = useCallback(() => {
    return acceptedPolicies.cookieSettings[COOKIE_TYPE.FUNCTIONALITY] || false;
  }, [acceptedPolicies.cookieSettings]);

  const isAnalyticCookiesAccepted = useCallback(() => {
    return acceptedPolicies.cookieSettings[COOKIE_TYPE.ANALYTICS] || false;
  }, [acceptedPolicies.cookieSettings]);

  // users must provide consent for cookie policy at least once per year
  const hasConsentExpired = () => {
    const acceptedDate = localStorage.getItem(COOKIES_POLICY_ACCEPTED_DATE_KEY);

    if (!acceptedDate) {
      return false;
    }

    return moment().diff(moment(acceptedDate), 'months', true) > 12;
  };

  const hasBeenCookiePolicyAcceptedBefore = () => {
    // old stored cookie policy consent before policy versioning
    const legacyAcceptedCookiesPolicy = localStorage.getItem(LEGACY_COOKIE_POLICY_KEY);

    return legacyAcceptedCookiesPolicy === 'true' || acceptedPolicies.cookiePolicyVersion > 0;
  };

  const hasNewCookiePolicyVersion = () => {
    return (
      hasBeenCookiePolicyAcceptedBefore() &&
      acceptedPolicies.cookiePolicyVersion < COOKIE_POLICY_VERSION
    );
  };

  return {
    cookieSettings: acceptedPolicies.cookieSettings,
    cookiesAccepted: hasBeenCookiePolicyAcceptedBefore() && !hasConsentExpired(),
    hasNewCookiePolicyVersion: hasNewCookiePolicyVersion(),
    hasNewPrivacyPolicyVersion: acceptedPolicies.privacyPolicyVersion < PRIVACY_POLICY_VERSION,
    hasNewTermsOfUseVersion: acceptedPolicies.termsOfUseVersion < TERMS_OF_USE_VERSION,
    isCookieSettingsOpen,
    acceptNewPolicies,
    acceptAllCookies,
    acceptCookieSettings,
    isFunctionalityCookiesAccepted,
    isAnalyticCookiesAccepted,
    showCookieSettings,
    hideCookieSettings,
  };
};

// return shared cookie settings state
export default () => useBetween(useCookieSettings);
