import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// components
import TextSection from 'components/atoms/TextSection';
import RatesList from 'components/molecules/Rates';
// constants
import { SUPPLIER_ROLE, MANAGER_ROLE } from 'constants';

const propTypes = {
  partnerRates: PropTypes.arrayOf(PropTypes.object).isRequired,
  sellRates: PropTypes.arrayOf(PropTypes.object).isRequired,
  role: PropTypes.string.isRequired,
  hideYears: PropTypes.bool,
};

const defaultProps = {
  hideYears: false,
};

const Rates = ({ partnerRates, sellRates, role, hideYears }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {role !== MANAGER_ROLE && (
        <TextSection title={role === SUPPLIER_ROLE ? t('supplierSellRate') : t('supplierRate')}>
          <RatesList rates={partnerRates} className="m-t-5" hideYears={hideYears} />
        </TextSection>
      )}
      {role !== SUPPLIER_ROLE && sellRates.length > 0 && (
        <div className={classNames({ 'm-t-20': role !== MANAGER_ROLE })}>
          <TextSection title={role === MANAGER_ROLE ? t('sellPrice') : t('mySellPrice')}>
            <RatesList rates={sellRates} className="m-t-5" hideYears={hideYears} />
          </TextSection>
        </div>
      )}
    </React.Fragment>
  );
};

Rates.propTypes = propTypes;
Rates.defaultProps = defaultProps;

export default Rates;
