import React, { useEffect } from 'react';
// Libraries
import PropTypes from 'prop-types';
import times from 'lodash/times';
import getProperty from 'lodash/get';
import omit from 'lodash/omit';
// helpers
import { getCurrentYear } from 'helpers/RelativeTimeFormatter';
import { areRatesFilled, isRequiredConditionMet, getComparedRatesArray } from './Helpers';
// components
import Rate from './Rate';

const propTypes = {
  values: PropTypes.object,
  currency: PropTypes.string,
  numberOfYears: PropTypes.number,
  requiredError: PropTypes.bool,
  startYear: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  rates: PropTypes.object,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  staticValues: PropTypes.array,
  highlight: PropTypes.bool,
  hideNames: PropTypes.bool,
  compareToRate: PropTypes.object,
  disabled: PropTypes.bool,
};

const defaultProps = {
  values: null,
  requiredError: false,
  currency: null,
  numberOfYears: null,
  startYear: getCurrentYear(),
  rates: null,
  className: '',
  staticValues: null,
  highlight: false,
  hideNames: false,
  compareToRate: null,
  disabled: false,
};

const Rates = props => {
  const {
    className,
    numberOfYears,
    startYear,
    name,
    onChange,
    staticValues,
    values,
    requiredError,
    rates,
    currency,
    highlight,
    compareToRate,
    hideNames,
    disabled,
  } = props;

  const filledRates = values ? Object.values(values || {}).length : 0;

  const someRatesNotFilled = !areRatesFilled(numberOfYears, filledRates, rates);
  if (!requiredError && someRatesNotFilled) {
    onChange({ ...values, requiredError: true }, name, true);
  }

  const onRateChange = (value, key) => {
    const rateValues = values ? { ...values, [key]: value } : { [key]: value };
    onChange({ ...rateValues, requiredError: false }, name);
  };

  const onRateBlur = (key, rate) => {
    const comparedRatesArray = getComparedRatesArray(compareToRate);
    const ratesHaveRequiredError = !isRequiredConditionMet(
      comparedRatesArray,
      Object.values(values)
    );

    if (
      values &&
      rate &&
      values[key] &&
      values[key].value &&
      comparedRatesArray.some(({ rateKey }) => {
        const rateValue = getProperty(rate, [rateKey, 'value']);
        return rateValue && rate[rateKey].value < values[key].value;
      })
    ) {
      const isWarning = comparedRatesArray.find(({ rateKey, required }) => {
        const rateValue = getProperty(rate, [rateKey, 'value']);
        if (!rateValue) return false;

        return rate[rateKey].value < values[key].value && !required;
      });

      const isRequired = comparedRatesArray.find(({ rateKey, required }) => {
        const rateValue = getProperty(rate, [rateKey, 'value']);
        if (!rateValue) return false;

        return rate[rateKey].value < values[key].value && required;
      });

      if (isWarning && !isRequired) {
        const newValue = {
          ...values[key],
          error: true,
          errorMessageKey: isWarning.errorMessageKey,
        };
        const newValues = { ...values, [key]: newValue };
        const valuesLength = Object.keys(newValues).length;
        const areNotRatesFilled = !areRatesFilled(numberOfYears, valuesLength, rates);

        const rateValues = values
          ? { ...newValues, requiredError: areNotRatesFilled || ratesHaveRequiredError }
          : newValue;

        onChange(rateValues, name);
      }

      if (isRequired) {
        const newValue = {
          ...values[key],
          error: true,
          errorMessageKey: isRequired.errorMessageKey,
        };
        const rateValues = values ? { ...values, [key]: newValue, requiredError: true } : newValue;

        onChange(rateValues, name);
      }
    } else if (values && !values[key]) {
      const newValues = omit(values, [key]);
      const valuesLength = Object.keys(newValues).length;
      const areNotRatesFilled = !areRatesFilled(numberOfYears, valuesLength - 1, rates);
      let finalValues = newValues;
      if (valuesLength === 0) {
        finalValues = null;
      } else if (areNotRatesFilled) {
        finalValues = { ...finalValues, requiredError: true };
      }
      onChange(finalValues, name);
    } else if (someRatesNotFilled || ratesHaveRequiredError) {
      onChange({ ...values, requiredError: true }, name);
    }
  };

  useEffect(() => {
    if (areRatesFilled(numberOfYears, Object.keys(values || {}).length, rates)) {
      onChange({ ...values, requiredError: false }, name, true);
    } else {
      onChange({ ...values, requiredError: true }, name);
    }
  }, [numberOfYears]);

  const getContent = () => {
    if (numberOfYears) {
      return (
        <React.Fragment>
          {times(numberOfYears, index => {
            const rateYear = startYear + index;
            const rateId = `${name}_${rateYear}`;
            return (
              <Rate
                year={rateYear}
                key={rateId}
                id={rateId}
                onChange={onRateChange}
                value={values ? values[rateId] : null}
                currency={currency}
                onBlur={onRateBlur}
                highlight={highlight}
                hideNames={hideNames}
                disabled={disabled}
                className={index < numberOfYears - 1 ? 'p-r-5' : ''}
              />
            );
          })}
        </React.Fragment>
      );
    }
    if (rates) {
      return (
        <React.Fragment>
          {Object.keys(rates).map((rate, index) => {
            const rateId = `${name}_${rate}`;
            return (
              <Rate
                rate={rates[rate]}
                year={rate}
                key={rateId}
                id={rateId}
                onChange={onRateChange}
                value={values ? values[rateId] : null}
                staticValues={staticValues}
                onBlur={onRateBlur}
                currency={currency}
                highlight={highlight}
                compareToRate={Object.keys(compareToRate)}
                hideNames={hideNames}
                disabled={disabled}
                className={index < Object.keys(rates).length - 1 ? 'p-r-5' : ''}
              />
            );
          })}
        </React.Fragment>
      );
    }
    return null;
  };

  return <div className={`${className}`}>{getContent()}</div>;
};

Rates.propTypes = propTypes;
Rates.defaultProps = defaultProps;

export default Rates;
