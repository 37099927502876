import colors from 'dependencies/materialStyles/Colors';
import { createMuiTheme } from '@material-ui/core';

const themeColor = colors.blue;
const secondaryColor = 'white';
const datePickerTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  cssUnderline: {
    '&:after': {
      border: 'none',
    },
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: themeColor,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // color: "white",
      },
    },
    MuiPickersDay: {
      isSelected: {
        backgroundColor: themeColor,
        '&:hover': {
          backgroundColor: themeColor,
        },
      },
      current: {
        color: themeColor,
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: themeColor,
      },
    },
    MuiIconButton: {
      root: {
        color: colors.gray,
      },
    },
    MuiButton: {
      root: {
        fontFamily: 'Lato',
      },
    },
    MuiButtonBase: {
      root: {
        fontFamily: 'Lato',
      },
    },
    MuiPrivateTabIndicator: {
      colorSecondary: {
        backgroundColor: secondaryColor,
      },
    },
    MuiTypography: {
      h2: {
        fontFamily: 'Lato-Light',
      },
      h3: {
        fontFamily: 'Lato-Light',
      },
      h4: {
        fontFamily: 'Lato-Light',
      },
      h5: {
        fontFamily: 'Lato-Light',
      },
      body1: {
        fontFamily: 'Lato-Light',
      },
      body2: {
        fontFamily: 'Lato',
      },
      subtitle1: {
        fontFamily: 'Lato',
      },
      colorPrimary: {
        color: themeColor,
      },
      caption: {
        fontFamily: 'Lato-Light',
      },
    },
    MuiFormControl: {
      root: {
        display: 'flex',
        flex: 1,
      },
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: themeColor,
      },
    },
    MuiFormHelperText: {
      error: {
        color: `${colors.red} !important`,
      },
      root: {
        fontFamily: 'Lato-Bold',
        fontSize: '12px',
        marginTop: '0',
        position: 'absolute',
        top: '41px',
      },
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: themeColor,
      },
      noPoint: {
        backgroundColor: themeColor,
      },
      thumb: {
        borderColor: themeColor,
      },
    },
    MuiInputAdornment: {
      positionStart: {
        marginRight: 0,
      },
    },
    MuiPickerDTTabs: {
      tabs: {
        backgroundColor: themeColor,
      },
    },
    MuiPickersYear: {
      root: {
        '&:focus': {
          color: themeColor,
        },
      },
    },
    MuiInputBase: {
      root: {
        fontFamily: 'Lato-Light',
        display: 'flex',
        flex: 1,
        border: `1px solid ${colors.gray4}`,
        padding: '2px 10px 2px 0',
        '&$disabled': {
          color: 'inherit',
          backgroundColor: colors.gray2,
        },
      },
      error: {
        borderColor: colors.red,
        marginBottom: '16px',
      },
      input: {
        fontSize: '13px',
      },
    },
    MuiInput: {
      underline: {
        borderBottom: 'none',
        '&:after': {
          borderBottom: 'none',
        },
        '&:before': {
          borderBottom: 'none',
        },
      },
    },
  },
});

export default datePickerTheme;
