import React, { useEffect } from 'react';
// libraries
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import getProperty from 'lodash/get';
// components
import InfiniteScroll from 'components/atoms/InfiniteScroll';
// helpers
import { updateListCount } from 'helpers/ListCount';

const propTypes = {
  query: PropTypes.object.isRequired,
  variables: PropTypes.object.isRequired,
  controller: PropTypes.object,
  children: PropTypes.func.isRequired,
  size: PropTypes.number,
  className: PropTypes.string,
  scrollableTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Element)]),
  clientName: PropTypes.string,
};

const defaultProps = {
  className: '',
  size: 8,
  scrollableTarget: null,
  clientName: '',
  controller: {},
};

const OffsetBasedList = ({
  query,
  variables,
  controller,
  size,
  children,
  className,
  scrollableTarget,
  clientName,
}) => {
  const { error, data, fetchMore, networkStatus, refetch } = useQuery(query, {
    notifyOnNetworkStatusChange: true,
    variables: { ...variables, first: size, skip: 0 },
    fetchPolicy: 'cache-first',
    context: { clientName },
    onCompleted: responseData => {
      // update positions count in apollo cache
      updateListCount(responseData, query);
    },
  });

  useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    controller.refetch = refetch;
  }, []);

  const list = data ? getProperty(Object.values(data), '0') : [];
  const fetchedItems = getProperty(list, 'items', []);
  const totalCount = getProperty(list, 'count', 0);

  const handleFetchMore = () => {
    fetchMore({ variables: { ...variables, skip: fetchedItems.length, first: size } });
  };

  return (
    <InfiniteScroll
      className={className}
      dataLength={fetchedItems.length}
      hasMore={totalCount > fetchedItems.length}
      scrollableTarget={scrollableTarget || 'scrollableContent'}
      next={handleFetchMore}
    >
      {children({
        data: fetchedItems,
        error,
        loading: networkStatus === 1 || networkStatus === 2 || networkStatus === 4,
      })}
    </InfiniteScroll>
  );
};

OffsetBasedList.propTypes = propTypes;
OffsetBasedList.defaultProps = defaultProps;

export default OffsetBasedList;
