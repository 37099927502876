import React, { useMemo } from 'react';
import MuiTable from '@mui/material/Table';
import MuiTableContainer from '@mui/material/TableContainer';
import TableContext from './TableContext';

interface TableProps {
  children: React.ReactNode;
  stickyHeader?: boolean;
  hoverable?: boolean;
  classes?: {
    root?: string;
    row?: string;
  };
}

const Table = ({ children, stickyHeader = false, hoverable = true, classes = {} }: TableProps) => {
  const context = useMemo(() => ({ hoverable, classes }), [hoverable]);

  return (
    <MuiTableContainer>
      <TableContext.Provider value={context}>
        <MuiTable
          component="div"
          stickyHeader={stickyHeader}
          classes={{
            root: classes.root,
          }}
        >
          {children}
        </MuiTable>
      </TableContext.Provider>
    </MuiTableContainer>
  );
};

export default Table;
