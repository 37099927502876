import React, { useState, useRef, useEffect } from 'react';
// libraries
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
// constants
import { DEFAULT_IBM_FEE, DEFAULT_IBM_FEE_FP } from 'constants/rates';
import { PUBLISH_POSITION_RATES_FORMULA_LINK } from 'constants/box';
// helpers
import {
  calculateYearPurchased,
  calculateManYearDiscount,
  calculateTargetRates,
  getTargetMargin,
  calculateSellPricesFromTargetRates,
  calculatePositionGPsFromSellPrice,
} from 'helpers/RatesCalculation';
// components
import SimulationLegend from 'components/molecules/SimulationLegend';
import Allowance from './Allowance';
import Rates from './Rates';

const propTypes = {
  hours: PropTypes.number,
  currency: PropTypes.string.isRequired,
  years: PropTypes.arrayOf(PropTypes.number).isRequired,
  nteRates: PropTypes.arrayOf(PropTypes.number).isRequired,
  targetRates: PropTypes.arrayOf(PropTypes.number).isRequired,
  onApplyRates: PropTypes.func.isRequired,
  isFixedPrice: PropTypes.bool.isRequired,
  enableManYearDiscount: PropTypes.bool,
};

const defaultProps = {
  hours: 0,
  enableManYearDiscount: false,
};

const RatesSimulation = ({
  hours,
  currency,
  years,
  nteRates,
  targetRates,
  onApplyRates,
  isFixedPrice,
  enableManYearDiscount,
}) => {
  const defaultTargetMargin = getTargetMargin(nteRates, targetRates);
  const manYearDiscount = enableManYearDiscount ? calculateManYearDiscount(hours) : 0;
  const yearPurchased = calculateYearPurchased(hours);

  const [localTargetRates, setTargetRates] = useState(targetRates);
  const [targetMargin, setTargetMargin] = useState(defaultTargetMargin);
  const [myMargin, setMyMargin] = useState(isFixedPrice ? DEFAULT_IBM_FEE_FP : DEFAULT_IBM_FEE);
  const initialTargetRates = useRef(targetRates);
  const initialSellPrices = useRef(calculateSellPricesFromTargetRates(targetRates, myMargin));
  const [sellPrices, setSellPrices] = useState(initialSellPrices.current);
  const [ibmGPs, setIbmGPs] = useState([]);

  useEffect(() => {
    setTargetRates(targetRates);
    const newSellPrices = calculateSellPricesFromTargetRates(targetRates, myMargin);
    setSellPrices(newSellPrices);
    setIbmGPs(calculatePositionGPsFromSellPrice(targetRates, newSellPrices, manYearDiscount));
  }, [targetRates]);

  const applyTargetMargin = targetMarginVal => {
    setTargetMargin(targetMarginVal);
    const newTargetRates = calculateTargetRates(nteRates, targetMarginVal);
    setTargetRates(newTargetRates);
    const newSellPrices = calculateSellPricesFromTargetRates(newTargetRates, myMargin);
    setSellPrices(newSellPrices);
    setIbmGPs(calculatePositionGPsFromSellPrice(newTargetRates, newSellPrices, manYearDiscount));
  };

  const isMyMarginDirty = () => {
    if (isFixedPrice) {
      return myMargin !== DEFAULT_IBM_FEE_FP;
    }

    return myMargin !== DEFAULT_IBM_FEE;
  };

  const isDiscardActive = () => {
    if (
      !isMyMarginDirty() &&
      targetMargin === defaultTargetMargin &&
      isEqual(initialTargetRates.current, localTargetRates) &&
      isEqual(initialSellPrices.current, sellPrices)
    ) {
      return false;
    }

    return true;
  };

  const resetChanges = () => {
    setTargetRates(initialTargetRates.current);
    setTargetMargin(defaultTargetMargin);
    setMyMargin(isFixedPrice ? DEFAULT_IBM_FEE_FP : DEFAULT_IBM_FEE);
    setSellPrices(initialSellPrices.current);
    setIbmGPs(
      calculatePositionGPsFromSellPrice(
        initialTargetRates.current,
        initialSellPrices.current,
        manYearDiscount
      )
    );
  };

  return (
    <div>
      <Allowance
        targetMargin={targetMargin}
        onTargetMarginChange={applyTargetMargin}
        targetMarginChanged={targetMargin !== defaultTargetMargin}
        myMargin={myMargin}
        onMyMarginChange={setMyMargin}
        myMarginChanged={isMyMarginDirty()}
        yearPurchased={yearPurchased}
        manYearDiscount={manYearDiscount}
        onApplyRates={() => onApplyRates(localTargetRates)}
        resetChanges={resetChanges}
        isDiscardActive={isDiscardActive()}
        hours={hours || 0}
        className="m-b-8"
      />
      <Rates
        currency={currency}
        nteRates={nteRates}
        targetRates={localTargetRates}
        sellPrices={sellPrices}
        initialSellPrices={initialSellPrices.current}
        setSellPrices={setSellPrices}
        ibmGPs={ibmGPs}
        setIbmGPs={setIbmGPs}
        initialTargetRates={initialTargetRates.current}
        onTargetRatesChange={setTargetRates}
        years={years}
        manYearDiscount={manYearDiscount}
        myMargin={myMargin}
        isFixedPrice={isFixedPrice}
      />
      <SimulationLegend link={PUBLISH_POSITION_RATES_FORMULA_LINK} className="m-b-24 space-v-16" />
    </div>
  );
};

RatesSimulation.propTypes = propTypes;
RatesSimulation.defaultProps = defaultProps;

export default RatesSimulation;
