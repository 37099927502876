import React from 'react';

interface LoadingWrapperProps {
  loading: boolean;
  ariaLabel: string;
  children: React.ReactNode;
}

const LoadingWrapper = ({ loading, ariaLabel, children }: LoadingWrapperProps) => {
  if (!loading) {
    return children;
  }

  return (
    <div aria-label={ariaLabel} aria-live="assertive" role="status">
      {children}
    </div>
  );
};

export default LoadingWrapper;
