import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import queryString from 'query-string';
import shortid from 'shortid';
// libraries
import { useTranslation, Trans } from 'react-i18next';
// context
import ToastContext from 'context/ToastContext';
// services
import {
  getPasswordRules,
  setPassword,
  verifyContext as isContextValid,
} from 'services/Authentication';
// components
import Field from 'components/molecules/Field';
import Loader from 'components/atoms/Loader';
import Input from 'components/atoms/Input';
import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
// styles
import colors from 'dependencies/materialStyles/Colors';
// constants
import { SET_PASSWORD_FORM, DEFAULT_SET_PASSWORD_STATE } from './Constants';

const SetPassword = () => {
  const { addToast } = useContext(ToastContext);

  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { context, uuid } = queryString.parse(location.search);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(DEFAULT_SET_PASSWORD_STATE);
  const [passwordRequirements, setPasswordRequirements] = useState(null);
  const [invalidResetPassword, setInvalidResetPassword] = useState(false);

  useEffect(() => {
    setLoading(true);
    const verifyContext = async () => {
      const verifiedContext = await isContextValid(context);
      if (verifiedContext) {
        const newParams = {
          ...queryString.parse(location.search),
          uuid: verifiedContext.uuid,
        };
        history.push(`${location.pathname}?${queryString.stringify(newParams)}`);
        setInvalidResetPassword(false);
      } else {
        setInvalidResetPassword(true);
        history.push('/forgot-password?linkexpired=true');
      }
      setLoading(false);
    };
    const fetchPasswordRules = async () => {
      try {
        const passwordRequirementsResponse = await getPasswordRules();
        setPasswordRequirements(passwordRequirementsResponse);
        setData({
          ...data,
          password: {
            ...data.password,
            hint: (
              <div>
                <div className="b p-5">
                  <b>{t('passwordMustContain')}</b>
                </div>
                <ul>
                  {passwordRequirementsResponse.rules.map(rule => (
                    <li key={shortid()} className="p-5">
                      - {rule}
                    </li>
                  ))}
                </ul>
              </div>
            ),
          },
        });
      } catch (error) {
        setPasswordRequirements({ ...passwordRequirements, passwordRules: null });
      } finally {
        setLoading(false);
      }
    };

    if (!uuid) verifyContext();
    fetchPasswordRules();
  }, []);

  const validate = showToast => {
    const { regex, rules } = passwordRequirements;

    if (regex && regex.test(data.password.value)) {
      return true;
    }
    if (showToast) addToast.error(t('passwordIssue'));
    setData({
      ...data,
      password: {
        ...data.password,
        error: true,
        errorMessage: t('passwordRequirementsNotMet'),
        popoverErrors: rules,
      },
    });
    return false;
  };

  const compare = showToast => {
    if (
      data.password.value &&
      data.confirmedPassword.value &&
      data.password.value !== data.confirmedPassword.value
    ) {
      if (showToast) addToast.error(t('passwordNotMatch'));
      setData({
        ...data,
        password: {
          ...data.password,
          error: true,
          errorMessage: t('passwordNotMatch'),
        },
        confirmedPassword: {
          ...data.confirmedPassword,
          error: true,
          errorMessage: t('passwordNotMatch'),
        },
      });
      return false;
    }

    return true;
  };

  const validatePasswords = (id, showToast = false) => {
    switch (id) {
      case 'password':
        return validate(showToast);
      case 'confirmedPassword':
        return compare(showToast);
      default:
        return validate(showToast) && compare(showToast);
    }
  };

  const validateForm = () => {
    let someFieldIsMissing = false;

    Object.keys(data).forEach(field => {
      const formField = SET_PASSWORD_FORM.find(({ id }) => id === field);
      const isRequired = formField ? formField.required : true;

      if (!data[field].value && isRequired) {
        someFieldIsMissing = true;
        setData({
          ...data,
          [field]: {
            ...data[field],
            error: true,
          },
        });
      }
    });

    if (someFieldIsMissing) {
      addToast.error(t('missingField'));
      return false;
    }
    if (!validatePasswords(null, true)) return false;

    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    try {
      setLoading(true);

      const { password, confirmedPassword } = data;

      await setPassword({
        uuid,
        password: password.value,
        confirmedPassword: confirmedPassword.value,
      });
      history.push('/login');
      addToast.success(t('passwordChanged'));
    } catch (error) {
      if (error.type) {
        addToast.error(t(error.type));
        setLoading(false);
      } else {
        addToast.error(t('errorPlaceholderText'));
        setLoading(false);
      }
    }
  };

  const handleChange = (value, id) => {
    setData({
      ...data,
      [id]: {
        ...data[id],
        value,
        error: false,
        errorMessage: '',
      },
    });
  };

  const onBlur = (id, value) => {
    if (value) validatePasswords(id);
  };

  const Header = () => {
    return <div className="authForm_header">{t('resetPassword')}</div>;
  };

  const FormBody = () => {
    return SET_PASSWORD_FORM.map(({ id, icon, title, type, required }) => {
      return (
        <React.Fragment key={id}>
          <Field
            key={id}
            label={t(title)}
            required={required}
            error={data[id].error}
            popoverErrors={data[id].popoverErrors}
            errorMessage={data[id].errorMessage}
            fieldNotFilled={data[id].error && !data[id].value}
            className={`authForm_input ${data[id].error ? 'redForm' : ''}`}
            content={
              <Input
                error={data[id].error}
                id={id}
                focusedHint={data[id].hint}
                hintPlacement="top-end"
                viewPassword
                displayClearButton
                icon={<Icon color={colors.gray} size={18} name={icon} />}
                value={data[id].value}
                onBlur={() => {
                  onBlur(id, data[id].value);
                }}
                inputType={type}
                onChange={handleChange}
              />
            }
          />
        </React.Fragment>
      );
    });
  };

  const Footer = () => {
    if (loading) {
      return (
        <div className="space-12">
          <Loader />
        </div>
      );
    }
    if (!loading && !invalidResetPassword) {
      return (
        <div className="authForm_button">
          <Button label={t('changePassword')} onClick={handleSubmit} />
        </div>
      );
    }
    return null;
  };

  return (
    <React.Fragment>
      <div className="authForm">
        <Header />
        {FormBody()}
        <Footer />
        <div className="authForm_statement">
          <Trans i18nKey="genericRedirectLogin">
            Return to{' '}
            <Link to="/login" className="authLink">
              Sign in
            </Link>
          </Trans>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SetPassword;
