/* eslint-disable import/order */
import React from 'react';
// libraries
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
// hooks
import useAuth from 'hooks/useAuth';
// components
import TextIcon from 'components/atoms/TextIcon';
import CardSection from 'components/molecules/CardSection';
import Notes from './Notes';
import Rates from './Rates';
import ProposalDocuments from './ProposalDocuments';
// helpers
import { getTime, getTimeYear } from 'helpers/RelativeTimeFormatter';
// constants
import { RATES_SUPPLIER_RATE, RATES_SELL_PRICE } from 'constants';
// styles
import styles from './submissionDetails.module.scss';

const propTypes = {
  proposal: PropTypes.object.isRequired,
};

const ProposalDetails = ({ proposal }) => {
  const { t } = useTranslation();
  const { role } = useAuth();

  const { availability } = proposal;
  const partnerRates = proposal.getRates(RATES_SUPPLIER_RATE);
  const sellRates = proposal.getRates(RATES_SELL_PRICE);

  return (
    <Grid container spacing={16}>
      <Grid item xs={12} md={12} lg={4}>
        <div className={styles.section}>
          <CardSection bordered title={t('contactInfo')}>
            <TextIcon icon="email">{proposal.email}</TextIcon>
            {proposal.phone && (
              <div className="m-t-5">
                <TextIcon icon="phone">{proposal.phone}</TextIcon>
              </div>
            )}
          </CardSection>
        </div>
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <div className={styles.section}>
          <CardSection bordered title={t('availability')}>
            <TextIcon icon="calendar">
              {availability.value && (
                <Trans i18nKey="submissionAvailability">
                  Submission available in <b>{{ availableInDate: availability.label }}</b>
                </Trans>
              )}
              {availability.start && (
                <React.Fragment>
                  {availability.value ? ', ' : ''}
                  {t('earliestAvailableDate')} <b>{getTime(availability.start)}</b>
                </React.Fragment>
              )}
            </TextIcon>
          </CardSection>
        </div>
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <div className={styles.section}>
          <CardSection bordered title={t('clearanceInformation')}>
            <TextIcon icon="info">
              {t('clearanceType')}: <b>{proposal.clearance.type}</b>
            </TextIcon>
            {proposal.clearance.validUntil && (
              <div className="m-t-5">
                <TextIcon icon="info">
                  {t('clearanceValidUntil')}: <b>{getTimeYear(proposal.clearance.validUntil)}</b>
                </TextIcon>
              </div>
            )}
            {proposal.clearance.note && (
              <div className="m-t-5">
                <TextIcon icon="info">
                  {t('clearanceNotes')}: {proposal.clearance.note}
                </TextIcon>
              </div>
            )}
          </CardSection>
        </div>
      </Grid>
      <Grid container item xs md={12} lg>
        <Grid container spacing={16}>
          <Grid item xs={12} md={12} lg={6}>
            <div className={styles.section}>
              <CardSection bordered title={t('rates')}>
                <Rates
                  partnerRates={partnerRates}
                  sellRates={sellRates}
                  role={role}
                  hideYears={partnerRates.length === 1}
                />
              </CardSection>
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <div className={styles.section}>
              <CardSection bordered title={t('attachments')}>
                <div className={styles.documentsContainer}>
                  <ProposalDocuments proposal={proposal} role={role} />
                </div>
              </CardSection>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <div className={styles.section}>
          <CardSection bordered title={t('notes')}>
            <Notes submission={proposal} role={role} />
          </CardSection>
        </div>
      </Grid>
    </Grid>
  );
};

ProposalDetails.propTypes = propTypes;

export default ProposalDetails;
