import { gql } from '@apollo/client';
// queries
import {
  SubmissionStatus,
  SubmissionRole,
  Rate,
  Note,
  File,
  PurchaseOrder,
  ExperienceLevel,
  PositionStatus,
  PositionActor,
} from 'queries/Fragments';

const GET_PROPOSAL = gql`
  query getProposal($id: ID!) {
    proposal(id: $id) {
      id
      name
      email
      phone
      submissionDate
      createdBy {
        id
      }
      clearance {
        id
        type {
          id
          name
        }
        validUntil
        notes {
          items {
            id
            note
          }
        }
      }
      availability {
        id
        start
        leadTimeFromSelection
      }
      supplier {
        id
        category
        includeCSAFee
        company {
          id
          name
        }
      }
      position {
        id
        title
        description
        rfq {
          id
          name
        }
        dueOn
        supplierDueDate
        contractType
        project {
          id
          name
          currency
          config
          timezone
          levels(sort: { order: ASC }) {
            items {
              id
              order
              yearsOfExperience
              title
            }
          }
          client {
            id
            name
            config
          }
        }
        workArrangement {
          id
          arrangement
        }
        deliveryOption {
          id
          mode
          description
        }
        level {
          ...ExperienceLevel
        }
        location {
          items {
            id
            country {
              id
              name
            }
            city
          }
        }
        duration {
          id
          start
          end
          hours
        }
        statuses(sort: { createdAt: DESC }) {
          items {
            ...PositionStatus
          }
        }
        actors {
          items {
            ...PositionActor
          }
        }
        documents(sort: { createdAt: DESC }) {
          items {
            ...File
          }
        }
        notes {
          items {
            ...Note
          }
        }
        requirements {
          items {
            id
            requirementType
            label
            description
            order
            importance
            percentage
            color
          }
        }
        evaluationMethod
        languages
        travel {
          id
          option
        }
        clearance {
          id
          name
        }
        rates(filter: { endDate: { is_empty: true } }) {
          items {
            ...Rate
          }
        }
      }
      hiringProcess {
        id
        status
        cancelledStep {
          ...SubmissionStatus
        }
        dates {
          items {
            id
            date
            status
            type
            note {
              ...Note
            }
          }
        }
        files(sort: { updatedAt: DESC }) {
          items {
            ...File
          }
        }
        notes {
          items {
            ...Note
          }
        }
        purchaseOrder {
          ...PurchaseOrder
        }
        withdrawalNotified
      }
      documents(sort: { createdAt: DESC }) {
        items {
          ...File
        }
      }
      statuses(sort: { createdAt: DESC }) {
        items {
          ...SubmissionStatus
        }
      }
      rates(filter: { endDate: { is_empty: true } }) {
        items {
          ...Rate
        }
      }
      roles {
        items {
          ...SubmissionRole
        }
      }
      notes {
        items {
          ...Note
        }
      }
    }
  }
  ${SubmissionStatus}
  ${SubmissionRole}
  ${Rate}
  ${Note}
  ${File}
  ${PurchaseOrder}
  ${ExperienceLevel}
  ${PositionStatus}
  ${PositionActor}
`;

export default GET_PROPOSAL;
