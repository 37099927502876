import i18n from 'i18next';
import getProperty from 'lodash/get';
// constants
import { CANDIDATE_STATUS } from 'constants/candidateStatus';
import { SUBMISSION_STATUS } from 'constants/submissionStatus';
import {
  MAIN_RECRUITING,
  MAIN_SELECTION,
  MAIN_ONBOARDING,
  MAIN_DELIVERY,
  MAIN_CLOSED,
  MAIN_CANCELLED,
} from 'constants/positionStatus';
import {
  ADMIN_ROLE,
  STAFF_ROLE,
  SUPPLIER_ROLE,
  MANAGER_ROLE,
  REVISION_STATUS,
  REVISION_TYPES,
  USER_ACTIVE,
  USER_PENDING,
  SKILLS_IMPORTANCE,
  SUPPLIER_IBM,
  SUPPLIER_DIRECT,
  SUPPLIER_NOT_DIRECT,
  SH_EXPERIENCE_LEVEL,
  ONSITE,
  OFFSITE,
  REMOTE,
  HYBRID,
} from 'constants';

export const getRoleText = role => {
  switch (role) {
    case ADMIN_ROLE:
      return i18n.t('admin');
    case STAFF_ROLE:
      return i18n.t('matchManager');
    case SUPPLIER_ROLE:
      return i18n.t('supplier');
    case MANAGER_ROLE:
      return i18n.t('projectManager');
    default:
      return 'IBM';
  }
};

export const getPositionMainStatusText = status => {
  const map = {
    [MAIN_RECRUITING]: i18n.t('recruiting'),
    [MAIN_SELECTION]: i18n.t('selection'),
    [MAIN_ONBOARDING]: i18n.t('onboarding'),
    [MAIN_DELIVERY]: i18n.t('delivery'),
    [MAIN_CLOSED]: i18n.t('closed'),
    [MAIN_CANCELLED]: i18n.t('cancelled'),
  };

  return getProperty(map, status, status);
};

/**
 * Get submission status label by role
 * @param {string} status
 * @param {string} [role]
 */
export const getStatusText = (status, role = 'default') => {
  const statusMap = {
    [SUBMISSION_STATUS.NEW]: i18n.t('new'),
    [SUBMISSION_STATUS.SUBMITTED]: {
      [MANAGER_ROLE]: i18n.t('new'),
      default: i18n.t('submittedToClient'),
    },
    [SUBMISSION_STATUS.NOT_SUBMITTED]: i18n.t('notSubmittedToClient'),
    [SUBMISSION_STATUS.NOT_SELECTED]: {
      [MANAGER_ROLE]: i18n.t('rejected'),
      default: i18n.t('notSelectedByClient'),
    },
    [SUBMISSION_STATUS.INTERVIEW]: i18n.t('interview'),
    [SUBMISSION_STATUS.OFFER]: i18n.t('offer'),
    [SUBMISSION_STATUS.ONBOARDING]: i18n.t('onboarding'),
    [SUBMISSION_STATUS.ONBOARDING_CANCELLED]: i18n.t('onboardingCancelled'),
    [SUBMISSION_STATUS.DELIVERY]: i18n.t('delivery'),
    [SUBMISSION_STATUS.OFF_BOARDING]: i18n.t('offBoarding'),
    [SUBMISSION_STATUS.OFF_BOARDED]: i18n.t('offBoarded'),
    [SUBMISSION_STATUS.WITHDRAWN]: i18n.t('withdrawn'),
  };

  return (
    getProperty(statusMap, [status, role]) ||
    getProperty(statusMap, [status, 'default']) ||
    getProperty(statusMap, status, '')
  );
};

export const getCurrencyText = currencyKey => {
  const LABELS = {
    EURO: '€',
    USD: '$',
    GBP: '£',
  };

  return getProperty(LABELS, currencyKey, '');
};

export const getRevisionStatusText = (statusKey, role) => {
  const { PENDING, IN_PROGRESS, COMPLETED, ACCEPTED, REJECTED } = REVISION_STATUS;

  const LABELS = {
    [PENDING]: i18n.t('pending'),
    [IN_PROGRESS]: i18n.t('inProgress'),
    [COMPLETED]: i18n.t('completed'),
    [ACCEPTED]: i18n.t('accepted'),
    [REJECTED]: i18n.t('rejected'),
  };

  if (role !== SUPPLIER_ROLE && statusKey === IN_PROGRESS) {
    return getProperty(LABELS, PENDING, '');
  }

  return getProperty(LABELS, statusKey, '');
};

export const getRevisionTypeText = typeKey => {
  const { ATTESTATION, CV, RATES } = REVISION_TYPES;
  const LABELS = {
    [ATTESTATION]: i18n.t('attestation'),
    [CV]: i18n.t('proposal'),
    [RATES]: i18n.t('rates'),
  };

  return getProperty(LABELS, typeKey, '');
};

export const getUserStatusText = status => {
  const LABELS = {
    [USER_ACTIVE]: i18n.t('active'),
    [USER_PENDING]: i18n.t('pending'),
  };

  return getProperty(LABELS, status, '');
};

export const getSkillImportanceLabel = importance => {
  const LABELS = {
    [SKILLS_IMPORTANCE.REQUIRED]: i18n.t('required'),
    [SKILLS_IMPORTANCE.DESIRED]: i18n.t('desired'),
  };

  return getProperty(LABELS, importance, '');
};

export const getPartnerCategoryText = category => {
  const LABELS = {
    [SUPPLIER_IBM]: i18n.t('ibmPartner'),
    [SUPPLIER_DIRECT]: i18n.t('directPartner'),
    [SUPPLIER_NOT_DIRECT]: i18n.t('notDirectPartner'),
  };

  return getProperty(LABELS, category, '');
};

export const getCandidateAvailabilityText = availability => {
  const LABELS = {
    IMMEDIATELY: i18n.t('immediately'),
    '1-2_WEEKS': i18n.t('12weeks'),
    '2-3_WEEKS': i18n.t('23weeks'),
    '3-4_WEEKS': i18n.t('34weeks'),
    LONGER_THAN_4_WEEKS: i18n.t('longerThan4weeks'),
  };

  return getProperty(LABELS, availability, '');
};

export const getExperienceLevelText = shLevel => {
  const LABELS = {
    [SH_EXPERIENCE_LEVEL.STUDENT]: i18n.t('student'),
    [SH_EXPERIENCE_LEVEL.ENTRY_LEVEL]: i18n.t('entryLevel'),
    [SH_EXPERIENCE_LEVEL.RECENT_GRADUATE]: i18n.t('recentGraduate'),
    [SH_EXPERIENCE_LEVEL.JUNIOR]: i18n.t('junior'),
    [SH_EXPERIENCE_LEVEL.MID_CAREER]: i18n.t('midCareer'),
    [SH_EXPERIENCE_LEVEL.SENIOR]: i18n.t('senior'),
    [SH_EXPERIENCE_LEVEL.EXPERIENCED_NON_MANAGER]: i18n.t('experiencedNonManager'),
    [SH_EXPERIENCE_LEVEL.MANAGER]: i18n.t('manager'),
    [SH_EXPERIENCE_LEVEL.SENIOR_EXECUTIVE]: i18n.t('seniorExecutive'),
  };

  return getProperty(LABELS, shLevel, '');
};

/**
 * Get candidate status label
 * @param {string} status
 * @returns {string}
 */
export const getCandidateStatusText = status => {
  const LABELS = {
    [CANDIDATE_STATUS.AVAILABLE]: i18n.t('available'),
    [CANDIDATE_STATUS.UNAVAILABLE]: i18n.t('unavailable'),
    [CANDIDATE_STATUS.AVAILABLE_FROM]: i18n.t('availableFrom'),
  };

  return getProperty(LABELS, status, '');
};

export const getDeliveryText = delivery => {
  const map = {
    [ONSITE]: i18n.t('onsite'),
    [OFFSITE]: i18n.t('offsite'),
    [REMOTE]: i18n.t('remote'),
    [HYBRID]: i18n.t('hybrid'),
  };

  return getProperty(map, delivery, delivery);
};
