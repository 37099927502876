import { useReducer, useCallback } from 'react';
import type { DroppedFile } from '@components/FileUpload';
import uploadDocument from './helpers/uploadDocument';

interface Options {
  onDocumentUploaded: (id: string) => void;
  onDocumentUploadFailed: (file: DroppedFile) => void;
}

interface State {
  items: DroppedFile[];
}

interface ActionUpload {
  type: 'UPLOAD';
  payload: {
    file: DroppedFile;
  };
}

interface ActionUploaded {
  type: 'UPLOADED';
  payload: {
    id: string;
  };
}

interface ActionUploadFailed {
  type: 'UPLOAD_FAILED';
  payload: {
    id: string;
  };
}

interface ActionReset {
  type: 'RESET';
}

type FilesAction = ActionUpload | ActionUploaded | ActionUploadFailed | ActionReset;

const reducer = (state: State, action: FilesAction): State => {
  switch (action.type) {
    case 'UPLOAD':
      return {
        items: [...state.items, { ...action.payload.file, loading: true }],
      };
    case 'UPLOADED':
      return {
        items: state.items.map((item) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              tags: [{ value: 'Uploaded', color: 'green' }],
              loading: false,
            };
          }

          return item;
        }),
      };
    case 'UPLOAD_FAILED':
      return {
        items: state.items.map((item) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              tags: [{ value: 'Failed', color: 'red' }],
              loading: false,
            };
          }

          return item;
        }),
      };
    case 'RESET':
      return {
        items: [],
      };
    default:
      return state;
  }
};

const useFiles = ({ onDocumentUploaded, onDocumentUploadFailed }: Options) => {
  const [state, dispatch] = useReducer(reducer, {
    items: [],
  });

  const reset = useCallback(() => {
    dispatch({ type: 'RESET' });
  }, []);

  const upload = useCallback(async (document: DroppedFile) => {
    dispatch({ type: 'UPLOAD', payload: { file: document } });

    try {
      const uploadedDocumentId = await uploadDocument(document.file);
      dispatch({ type: 'UPLOADED', payload: { id: document.id } });
      onDocumentUploaded(uploadedDocumentId);
    } catch (error) {
      dispatch({ type: 'UPLOAD_FAILED', payload: { id: document.id } });
      onDocumentUploadFailed(document);
    }
  }, []);

  return { files: state.items, upload, reset, uploading: state.items.some((file) => file.loading) };
};

export default useFiles;
