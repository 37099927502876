import React from 'react';
// libraries
import PropTypes from 'prop-types';
// styles
import styles from './detailPage.module.scss';

const propTypes = {
  children: PropTypes.any.isRequired,
};

const Content = ({ children }) => {
  return (
    <div id="js-scrollable-content" className={styles.content}>
      {children}
    </div>
  );
};

Content.propTypes = propTypes;

export default Content;
