import React from 'react';
// libraries
import PropTypes from 'prop-types';
import classNames from 'classnames';
// helpers
import { getInitials } from 'helpers/StringParser';
import { getColorFromLetters } from 'helpers/Colors';
// constants
import { COLOR_BLUE, COLOR_GREEN, COLOR_RED, COLOR_YELLOW, COLOR_GRAY } from 'constants/colors';
// styles
import styles from './hexagonAvatar.module.scss';

const propTypes = {
  children: PropTypes.any,
  name: PropTypes.string,
  color: PropTypes.oneOf(['', COLOR_BLUE, COLOR_GREEN, COLOR_RED, COLOR_YELLOW, COLOR_GRAY]),
  size: PropTypes.number,
};

const defaultProps = {
  children: null,
  name: '',
  color: '',
  size: 30,
};

const HexagonAvatar = ({ children, name, color, size }) => {
  if (!name && !children) {
    return null;
  }

  if (!name && children) {
    return (
      <div
        className={classNames(styles.container, styles[color || COLOR_GREEN])}
        style={{
          '--size': size,
        }}
      >
        <div className={styles.hexagon} />
        <div className={styles.text}> {children}</div>
      </div>
    );
  }

  const initials = getInitials(name);
  const avatarColor = color || getColorFromLetters(initials);

  return (
    <div className={classNames(styles.container, styles[avatarColor])} style={{ '--size': size }}>
      <div className={styles.hexagon} />
      <div className={styles.text}>{initials}</div>
    </div>
  );
};

HexagonAvatar.propTypes = propTypes;
HexagonAvatar.defaultProps = defaultProps;

export default HexagonAvatar;
