import { gql } from '@apollo/client';

export const File = gql`
  fragment File on Attachment {
    id
    type
    name
    boxId
    createdAt
    updatedAt
    author {
      id
      person {
        id
        fullName
      }
    }
  }
`;

export const Rate = gql`
  fragment Rate on Rate {
    id
    currency
    value
    rateType
    startDate
    endDate
  }
`;

export const PositionActor = gql`
  fragment PositionActor on PositionRole {
    id
    role
    endDate
    user {
      id
      user {
        id
        email
      }
      person {
        id
        fullName
        phone
        mobilePhone
      }
    }
  }
`;

export const SubmissionStatus = gql`
  fragment SubmissionStatus on SubmissionStatus {
    id
    name
    type
    start
    end
    note {
      id
      note
      noteType
    }
  }
`;

export const Note = gql`
  fragment Note on Note {
    id
    note
    noteType
    createdAt
    updatedAt
    author {
      id
      person {
        id
        fullName
      }
    }
  }
`;

export const PositionStatus = gql`
  fragment PositionStatus on PositionStatus {
    id
    name
    start
    end
    note {
      ...Note
    }
  }
  ${Note}
`;

export const SubmissionRole = gql`
  fragment SubmissionRole on SubmissionRole {
    id
    role
    user {
      id
      person {
        id
        fullName
        preferredName
      }
      supplier {
        id
      }
      user {
        id
        email
      }
      organization {
        id
        company {
          id
          name
        }
      }
    }
  }
`;

export const User = gql`
  fragment User on User {
    id
    alakaUser {
      id
      person {
        id
        fullName
      }
    }
  }
`;

export const RevisionStatus = gql`
  fragment RevisionStatus on RevisionStatus {
    id
    start
    end
    status
    createdBy {
      id
      alakaUser {
        id
        person {
          id
          fullName
        }
      }
    }
  }
`;

export const Revision = gql`
  fragment Revision on Revision {
    id
    createdAt
    createdBy {
      ...User
    }
    revisedAt
    revisedBy {
      person {
        id
        fullName
      }
    }
    types {
      items {
        id
        type
        reason {
          ...Note
        }
        rejectionNote {
          ...Note
        }
        additionalNote {
          ...Note
        }
        status {
          ...RevisionStatus
        }
        rates {
          items {
            ...Rate
          }
        }
      }
    }
    statuses {
      items {
        ...RevisionStatus
      }
    }
    workInProgress
  }
  ${User}
  ${Note}
  ${Rate}
  ${RevisionStatus}
`;

export const PositionRatesSettings = gql`
  fragment PositionRatesSettings on Position {
    project {
      showTargetRatesToPartners
      showNteRatesToPartners
      allowSubmissionAboveTargetRates
    }
    showTargetRatesToPartners
    showNteRatesToPartners
    allowSubmissionAboveTargetRates
  }
`;

export const MatchMetric = gql`
  fragment MatchMetric on Metric {
    id
    type
    min
    max
    mean
    median
    percentile
    count
    dataPoints
    updatedAt
  }
`;

export const ExperienceLevel = gql`
  fragment ExperienceLevel on ExperienceLevel {
    id
    title
    order
    yearsOfExperience
    shLevel
  }
`;

export const MatchReport = gql`
  fragment MatchReport on MatchReport {
    id
    createdAt
    score
  }
`;

export const Profile = gql`
  fragment Profile on Profile {
    id
    profession
    description
    fullName
    preferredName
    email
    phone
    skype
    linkedIn
    exIBM
    citizenship {
      id
      name
    }
    clearance {
      id
      type {
        id
        name
      }
      validFrom
      validUntil
      transferRequired
      country {
        id
        name
      }
      notes {
        items {
          id
          note
        }
      }
    }
  }
`;

export const OnboardingStartDate = gql`
  fragment OnboardingStartDate on StartDate {
    id
    date
    type
    status
    note {
      ...Note
    }
  }
  ${Note}
`;

export const PurchaseOrder = gql`
  fragment PurchaseOrder on PurchaseOrder {
    id
    number
    amendment
    file {
      ...File
    }
    rate {
      ...Rate
    }
    totalBudget {
      ...Rate
    }
    start
    end
    effort
  }
  ${File}
  ${Rate}
`;
