import { useRef, useCallback, useReducer } from 'react';
// libraries
import last from 'lodash/last';

// opened (stacked) modals
let modals = [];

const useModalManager = () => {
  const modal = useRef();
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  const registerModal = useCallback(modalElement => {
    // if no modal is opened -> make body fixed
    if (!modals.length) {
      document.body.style.overflow = 'hidden';
    }

    modals.push(modalElement);
    modal.current = modalElement;
    // focus is needed to be set on modal element to enable event listening on "keyDown"
    modalElement.focus({ preventScroll: true });

    forceUpdate();
  });

  const unregisterModal = useCallback(() => {
    if (modals.find(modalElement => modalElement === modal.current)) {
      modals = modals.filter(modalElement => modalElement !== modal.current);
      // move focus to the last opened modal
      if (modals.length > 0) {
        last(modals).focus({ preventScroll: true });
      }

      // if no more modal is opened -> make body scrollable
      if (!modals.length) {
        document.body.style.overflow = 'unset';
      }

      forceUpdate();
    }
  });

  const getZIndex = useCallback(() => {
    return 1040 + modals.indexOf(modal.current) * 100;
  });

  return { registerModal, unregisterModal, getZIndex };
};

export default useModalManager;
